<!-- <app-tenancy-dropdown class="w-100 bg-main d-block" (tenancyEmitter)="getTenancyUpdate($event)"
  [selectedDt]="selectedDt" [DTs]="DTs">
</app-tenancy-dropdown> -->
<!-- <h3 class="mt-1 mb-0">Snapshot</h3> -->
<ul class="m-0 p-2">
  <li class="cl__card-counter" *ngFor="let td of data">
    <span [inlineSVG]="td.icon" class="card-icon" [ngClass]="td.iconClass"></span>
    <span class="foz18">{{td.name}} </span>
    <span class="foz22 justify-self-end">{{td.count | number}} </span>
    <!-- <cl-readout class="cl-digit-readouts justify-self-end" [totalVal]="td.count" [name]="td.name"></cl-readout> -->

  </li>
</ul>