import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppNotification, NotificationStatusCount } from "@cl/models";
import { AppState } from "@cl/ngxs/state";
import { Select,Store } from "@ngxs/store";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from 'lodash';
import { AppGetNotifications } from "@cl/ngxs/actions";

@Component({
  selector: 'cl-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @Select(AppState.infoNotifications) info$: Observable<AppNotification[]>;
  notifications: AppNotification[] = [];
  statusCounts: NotificationStatusCount;
  tabSelectedIndex = 0;

  private destroyed$ = new Subject<boolean>();

  constructor(private appState: AppState,private store : Store) {}

  ngOnInit(): void {
    this.listenToNotificationUpdates();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private listenToNotificationUpdates(): void {
    this.info$.pipe(takeUntil(this.destroyed$)).subscribe((notifications) => {
      this.notifications = notifications;
      this.statusCounts = this.appState.getReadUnreadCounts(notifications);
    });
  }
  updateNotifications(data){
    setTimeout(() => {
      this.store.dispatch(new AppGetNotifications());
   },1500)
  }
}
