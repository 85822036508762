<div class="role-panel">
  <cl-side-panel class="role-side-panel summary-view" (closePanel)="closePanel()">
    <section class="cl-side-panel-title side-panel-header-bg">
      <span class="black font-bold">Profile Summary</span>
      <span class="close-icon" (click)="closePanel()">
        <i class="fa fa-times-circle-o mr-4"></i>
      </span>
    </section>
    <section class="cl-side-panel-content mt-2 slider">
      <div class="user-summary">
        <div class="user-summary-content">
          <div class="form-label">Name</div>
          <div class="mb-3">{{profileDetails['eventProfileName']}}</div>
        </div>
        <div class="user-summary-content">
          <div class="form-label">Organization</div>
          <div class="mb-3">{{profileDetails['organizationName']}}</div>
        </div>
        <div class="user-summary-content mb-3">
          <div class="form-label">Sensor Type</div>
          <div class="mb-3">{{profileDetails['sensorType']}}</div>
        </div>
        <div class="user-summary-content mb-3">
          <div class="form-label" *ngIf="!groupObject?.default">Device Configuration</div>
          <div *ngFor="let service of servicesData | keyvalue">
            <div *ngIf="service.value['metadata'] && service.value['metadata']['supports']">
              <div>
                <span>
                  <label>
                    <mat-slide-toggle [(ngModel)]="service['value']['eventConfig']['excursion']['configured']"
                      [disabled]="true"></mat-slide-toggle>
                  </label>
                  <span class="ml-4 font-bold">{{service['value']['metadata']['name']}} Thresholds (in
                    {{service['value']['metadata']['unit']}})</span>
                </span>
              </div>
              <div *ngIf="service.value['eventConfig']['excursion']['configured']">
                <p class="mt-2">
                <span class="mr-2"
                  *ngIf="service.value['eventConfig']['excursion']['min'] || service.value['eventConfig']['excursion']['min'] === 0">Below
                  {{service.value['eventConfig']['excursion']['min']}}</span>
                <span
                  *ngIf="service.value['eventConfig']['excursion']['max'] || service.value['eventConfig']['excursion']['max'] === 0">Above
                  {{service.value['eventConfig']['excursion']['max']}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="user-summary-content mb-3" *ngIf="deviceConfigData.length">
          <div *ngFor="let deviceConfig of deviceConfigData">
            <div
              *ngIf="deviceConfig.name !== 'Shock' || (deviceConfig.name === 'Shock' && servicesData['Shock'].eventConfig.excursion.configured)">
              <div *ngFor="let characteristic of deviceConfig.characteristics" class="mt-2">
                <div>
                  <span class="config">
                    <label>
                      <mat-slide-toggle [(ngModel)]="characteristic.configParams.enabled" [disabled]="true">
                      </mat-slide-toggle>
                    </label>
                    <span class="font-bold">{{ characteristic.metadata.uiLabel }}</span>
                  </span>
                </div>
                <div *ngIf="characteristic.configParams.enabled">
                  <p *ngIf="characteristic.configParams.uom === 'multiSelect'">
                    <span>{{characteristic.configParams.value}}</span>
                  </p>
                  <p
                    *ngIf="characteristic.configParams.uom !== 'multiSelect' && characteristic.configParams.uom !== 'toggle'">
                    <span>
                      {{ fnGetInputLabelBySensorType(characteristic.metadata.uiLabel,profileDetails['sensorType']) }}
                       every {{characteristic.configParams.value}}&nbsp;{{characteristic.configParams.uom}}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>
