<div class="fs24">{{ shipmentData?.shipmentNode.properties.name }}</div>

<!-- <span
    class="cursor-pointer color4 list-page_filter-toggle items-center ml-2"
    (click)="showManageTags = !showManageTags"
    ><i class="fa fa-tag ml-2"></i>&nbsp;<span>Tags</span></span
  >

  <div style="position: relative; margin-left: 60px; margin-top: 10px">
    <cl-shipment-managetags
      *ngIf="!showManageTags"
      (sendDataToListaction)="eventFromManageTags($event)"
      (sendDataToapply)="applyTags($event)"
      [ShipmentDetails]="shipmentData"
      [selectedTags]="tags"
    ></cl-shipment-managetags>
  </div>
  <div *ngIf="tags && tags.length" class="chips">
    <button
      class="btn-colour-1 btn-sm slected"
      *ngFor="let x of tags; let idx = index">
      <i  (click)="removetag(idx)" style="cursor: pointer;" class="fa fa-close fontspace mr-1"></i>
      <span>{{x}}</span>
    </button>
  </div> -->
<div class="flex items-center my-1.5">
  <button
    [clIsValidAction]="actionBtnPolicies.terminate"
    [title]="
      currentAction.action === 'terminate'
        ? 'Mark this shipment as Terminated'
        : 'Mark this shipment as Completed'
    "
    class="p-4-10 border-general br2 mr-2"
    [disabled]="currentAction.isDisable"
    [ngClass]="[
      currentAction.isDisable
        ? 'color37 bcolor36 bgcolor38'
        : 'color4 bcolor4 bgcolor32'
    ]"
    (click)="confirmDialog()"
  >
    <i
      class="fa"
      [ngClass]="
        currentAction.action === 'complete' ? 'fa-flag-checkered' : 'fa-ban'
      "
    ></i>
    {{ currentAction.action | titlecase }}
  </button>
  <span class="custom-btn">
    <button
      (click)="fnToggle()"
      [clIsValidAction]="actionBtnPolicies.share"
      type="button"
      class="p-4-10  border-general br2 mr-2 color4 bcolor4 bgcolor32"
    >
      <i class="fa fa-share"></i> {{'shipmentDetails.share'|translate}}
    </button>
    <div *ngIf="toggle" class="custom-content shadow-sm">
      <cl-shipment-details-share
        [entityId]="shipmentData.shipmentNode.properties.id"
        [entityName]="shipmentData.shipmentNode.properties.name"
        [entityTypeKey]="shipmentData.shipmentNode.properties.entityTypeKey"
        (data)="getData($event)"
        (close)="onClose($event)"
        [parentEntityTypeKey]="shipmentData?.shipmentNode?.properties?.parentEntityTypeKey"
      ></cl-shipment-details-share>
    </div>
  </span>
  <div class="flex items-center">
    <button
      *ngIf="
        (shipmentData.shipmentNode.properties.statusCode === 'Completed' ||
        shipmentData.shipmentNode.properties.statusCode === 'At Destination') && !(isDTPShipment && isDTPDownloadRestricted)"
      [disabled]="status === 'In-Progress' || isReportRequesting"
      [ngClass]="
        (status === 'In-Progress' || isReportRequesting) ?  'color37 bcolor36 bgcolor38'
        : 'color4 bcolor4 bgcolor32'
      "
      class="px-2 py-1 ml-0 border-general"
      (click)="downloadShipmentReport()"
    >
      {{ status == "Completed" ? "Regenerate PDF" : "Download PDF" }}
      <span *ngIf="status && status !== 'Not Found' && status !== 'Completed'"
        >-- {{ status }}</span
      >
    </button>
    <div class="download-status ml-2" *ngIf="status === 'Completed'">
      Your shipment report is ready.
      <a class="download" (click)="downloadPDF()">&nbsp;Download here.</a>
    </div>
  </div>
  <div class="ml-auto">
    <cl-recipe-actions
      [entityTypeId]="shipmentData?.shipmentNode.properties.entityTypeId"
      [entityId]="shipmentData?.shipmentNode.properties.id"
      [parentEntityType]="
        shipmentData?.shipmentNode.properties.parentEntityTypeKey
      "
    ></cl-recipe-actions>
  </div>
</div>
