import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { AppAuthService } from '@cl/authenticate/app-auth.service';
import { UserService } from '@cl/user/user.service';

@Component({
  selector: 'cl-shipment-details-summary',
  templateUrl: './shipment-details-summary.component.html',
  styleUrls: ['./shipment-details-summary.component.scss'],
})
export class ShipmentDetailsSummaryComponent implements OnInit {
  @Input() shipmentData: any;
  @Output() refreshShipment = new EventEmitter<void>();
  @Input() winkedAssetId: string;

  selectedAssetIndex: string;
  currentTab: 'progress' | 'collaborate' = 'progress';
  collaborateURI: SafeResourceUrl = '';

  isDTPUser = this._userService.isDTPUser();
  isDTPShipment = true;

  constructor(
    private _userService: UserService,
    public sanitizer: DomSanitizer,
    private _appAuthService: AppAuthService,
    private _apiProvider:ApiProviderService
  ) {}

  ngOnInit(): void {

    //For DTP restrictions
    // FIXME: Currently dont have dtp implementation on backend side so lets assume parcel shipments as dtp shipments
    this.isDTPShipment = this.shipmentData.shipmentNode.properties.parentEntityTypeKey == 'dtpshipment';

    const domain = this._apiProvider.getDomainPrima();
    const safe_url = `https://${domain}-collaborate.parkoursc.com/collaboration?_tenantId=${this._userService.getTenantId()}&_userId=${this._userService.getUserId()}&_instanceid=${
      this.shipmentData.shipmentNode.id
    }&_token=${this._appAuthService.getAuthToken()}`;

    this.collaborateURI =
      this.sanitizer.bypassSecurityTrustResourceUrl(safe_url);

  }
}
