import {Component, OnInit, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PropertyService} from '@cl/property/property.service';
import {ThemingService} from "@cl/common/services/theming.service";
import {ApiProviderService} from "@cl/@core/shell/api-provider.service";
import {ToastService} from '@cl/common/services/toast.service';
import {MatDialog} from "@angular/material/dialog";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel
} from "../../common/components/confirm-dialog/confirm-dialog.component";
import {environment} from "@cl/env";
import {TenantService} from "@cl/tenant/tenant.service";
import AppSettingsJson from "../../@data/app-settings.json";
import {UrlUtilsService} from "@cl/common/services/url-utils.service";


@Component({
  selector: 'cl-property-core',
  templateUrl: './property-core.component.html',
  styleUrls: ['./property-core.component.scss']
})
export class PropertyCoreComponent implements OnInit {

  scope_type: boolean = true;
  @Input() authoring_type = ''
  @Input() default_data: any

  domain_name = '';
  //propertyForm!: FormGroup;
  propertyForm!: FormGroup;
  tenant_list: any;
  tenant_name: any;
  tenant_id: any;
  domainThemeData: any;
  tenantThemeData: any;
  basethem: any;
  togglers = {
    bgcss: true,
    custcss: false
  };
  dataArray: object[] = [];
  isLoading = false;
  //inputFields:any;


  defaultTheme = []
  defaultAppSettings = []
  defaultTranslations = []


  constructor(
    private pService: PropertyService,
    private themeService: ThemingService,
    private formBuilder: FormBuilder,
    private apiProvider: ApiProviderService,
    private _toastService: ToastService,
    public dialog: MatDialog,
    private tenantService: TenantService,
    private urlUtils: UrlUtilsService
  ) {
    this.domain_name = this.getDomainName();
  }

  ngOnChanges(changes: any) {
  }

  ngOnInit(): void {

    this.getTenantListData();
    this.setDefultData("", 'domain');
    this.loadData('domain');
    if (this.scope_type) {
      this.getDomainName()
    }

  }

  loadData(scopeType?) {
    let scope = scopeType ? scopeType: 'domain';
    if (this.authoring_type == 'theme') {
      this.getCustomizedTheme(scope);
    } else if (this.authoring_type == 'appSettings') {
      this.getAppSettingsData(scope);
    }else if(this.authoring_type=='translations'){
      this.getTranslationsData(scope);
    } else {
      this.getCustomizedTheme(scope);
    }
  }

  setDefultData(setdata: any, scop: string) {
    let forcntData = {};
    if (this.authoring_type == 'theme') {
      this.basethem = this.themeService.getBaseThemeProps();
    } else if (this.authoring_type == 'appSettings') {
      this.basethem = AppSettingsJson;
    } else if (this.authoring_type == 'translations') {
      this.basethem = "";
    } else {
      this.basethem = this.themeService.getBaseThemeProps();
    }

    if (scop == 'domain') {
      //let self = this;properties
      this.default_data.forEach(elm => {
        elm.properties.forEach(item => {
          item["reset"] = false;
          if (setdata) {
            var setObj = setdata.find(obj => obj.key == `${elm.keyprefix}.` + item.key);
            if (setObj) {
              item["value"] = setObj.value;
              item["isallowDelete"] = true;
            } else {
              item["value"] = this.basethem[item.key] ? this.basethem[item.key] : item.value;
              item["isallowDelete"] = false;
            }
          } else {
            item["value"] = this.basethem[item.key] ? this.basethem[item.key] : item.value;
            item["isallowDelete"] = false;
          }
        });
      });
      this.domainThemeData = setdata;
    } else {
      this.default_data.forEach(elm => {
        elm.properties.forEach(item => {
          item["reset"] = false;
          if (setdata) {
            var setObj = setdata.find(obj => obj.key == `${elm.keyprefix}.` + item.key);
            if (setObj) {
              item["value"] = setObj.value;
              item["isallowDelete"] = true;
            } else {
              if (this.domainThemeData) {
                let setDomainObj = this.domainThemeData.find(obj => obj.key == item.key);
                if(setDomainObj) {
                  item["value"] = setDomainObj.value;
                  item["isallowDelete"] = true;
                } else {
                  item["value"] = this.basethem[item.key] ? this.basethem[item.key] : item.value;
                  item["isallowDelete"] = false;
                }
              } else {
                item["value"] = this.basethem[item.key] ? this.basethem[item.key] : item.value;
                item["isallowDelete"] = false;
              }

            }
          } else {
            item["value"] = this.basethem[item.key] ? this.basethem[item.key] : item.value;
            item["isallowDelete"] = false;
          }
        });
      });
    }

    this.default_data.forEach(elm => {
      elm.properties.forEach(function (item) {
        forcntData[item['key']] = new FormControl(item.value);

      })
    });

    this.propertyForm = new FormGroup(forcntData);
  }

  getDomainData() {
    this.tenant_id = null;
    this.getCustomizedTheme('domain');
  }

  getDomainName() {
    return this.urlUtils.getBaseURL().prima;
  }

  getTenantListData() {
    this.tenantService.getAllTenants()
      .subscribe((data: any) => {
        this.tenant_list = data;
        this.tenant_name = this.tenant_list[0].name;
        if (!this.scope_type) {
          this.tenant_id = data[0].identifier;
          this.loadData('tenant');
        }

      })
  }

  onSave() {
  }

  formateSubmitPayload() {
    let scopeType = "domain";
    let scopeId = "";

    if (this.scope_type) {
      scopeType = 'domain';
      scopeId = this.getDomainName();
    } else {
      scopeType = 'tenant';
      scopeId = this.tenant_id;
    }
    let p_data = {
      "value": "",
      "locale": "NA",
      "prefix1": this.default_data[0].prefix1 ? this.default_data[0].prefix1 : "appCommon",
      "prefix2": this.default_data[0].prefix2 ? this.default_data[0].prefix2 : "appCSS",
      "scopeType": scopeType,
      "scopeId": scopeId,
      "valueType": "string"
    };
    return p_data;

  }

  submitData() {
    this.isLoading = true;

    let self = this;
    self.dataArray = [];

    this.default_data.forEach(elm => {
      elm.properties.forEach(function (item) {
        if (self.propertyForm.controls[item.key].touched) {
          let p_load = self.formateSubmitPayload();
          p_load.value = self.propertyForm.controls[item.key].value;
          p_load['key'] = elm.keyprefix + "." + item.key;
          if (elm.prefix1) {
            p_load['prefix1'] = elm.prefix1;
          }
          if (elm.prefix2) {
            p_load['prefix2'] = elm.prefix2;
          }
          if (elm.locale) {
            p_load['locale'] = elm.locale;
          }
          self.dataArray.push(p_load)
        }
      })
    });

    this.pService.SetBulkProperties(self.dataArray)
      .then(() => {
        this.isLoading = false;
        this.scope_type ? this.loadData('domain') : this.loadData('tenant')
        this._toastService.success(
          'Saved successfully'
        );
      })
      .catch(() => {
        this.isLoading = false;
        this._toastService.error(
          'Internal server error'
        );
      });
  }

  actionDialog(event, field, action) {
    let msg = '';
    if (action == 'delete') {
      msg = `Are you sure to reset this field to earlier value?`;
    } else {
      msg = `Are you sure to remove this field value and reinstate with the default value?`
    }
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          message: msg,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (action == 'delete') {
            this.deletefields(field);
          } else {
            this.resetfields(field);
          }

        }
      });
  }

  resetfields(field) {
    let ischange = false;
    this.default_data.forEach(elm => {
      elm.properties.forEach((item) => {
        if (item.key == field) {
          item.reset = false;
          this.propertyForm.controls[field].setValue(item.value);
        }
        if (item.reset) {
          ischange = true;
        }
      });
    })

    if (!ischange) {
      this.propertyForm.markAsPristine()
    }
  }

  deletefields(field) {
    let prefix1 = "";
    let prefix2 = "";
    let keyprefix = "";
    let domain_name = this.getDomainName();
    this.default_data.forEach(elm => {
      elm.properties.forEach((item) => {
        if (item.key == field) {
          prefix1 = elm.prefix1;
          prefix2 = elm.prefix2;
          keyprefix = elm.keyprefix;
        }
      });
    });
    this.pService.deleteProperty(`${keyprefix}.${field}`, this.tenant_id, domain_name, 'NA', prefix1, prefix2)
      .then(() => {
        this.isLoading = false;
        this.scope_type ? this.loadData('domain') : this.loadData('tenant')
        this._toastService.success(
          'Property is deleted successfully'
        );
      })
      .catch(() => {
        this.isLoading = false;
        this._toastService.error(
          'Internal server error'
        );
      });

  }

  displayFn(item) {
    if (item) {
      if (item.name) {
        return item.name;
      }
    }
  }

  onSelectTenant(value) {
    var result = this.tenant_list.find(item => item.name == value.option.value);
    this.tenant_id = result.identifier;
    this.getCustomizedTheme('tenant');
  }

  dyanamicFormGroup(fields: any) {
    const group: any = {};
    fields.forEach((field) => {
      const validator = [];
      group[field.key] = new FormControl(
        {
          value: field.value,
          disabled: field.isDisabled,
        },
        validator
      );
    });

    return new FormGroup(group);
  }

  getCustomizedTheme(scop: string) {
    this.isLoading = true;
    const domainName = this.domain_name;
    this.pService.getBulkProperties('appCommon', 'appCSS', domainName, this.tenant_id)
      .then((response: any) => {
        this.isLoading = false;
        this.setDefultData(response, scop);
      })
      .catch((err) => {
        this.isLoading = false;
        this.setDefultData("", scop);
      });

  }

  getAppSettingsData(scopeType: string) {
    this.isLoading = true;
    const domainName = this.domain_name;
    const loginSettings = this.pService.getBulkProperties('common', 'appLoginSettings', domainName, this.tenant_id);
    const commonSettings = this.pService.getBulkProperties('common', 'appSettings', domainName, this.tenant_id);
    Promise.all([commonSettings, loginSettings])
      .then((response) => {
        this.isLoading = false;
        this.setDefultData(response[0].concat(response[1]), scopeType);
      })
      .catch((err) => {
        this.isLoading = false;
        this.setDefultData("", scopeType);
      });
  }

  getTranslationsData(scopeType: string) {
    this.isLoading = true;
    const domainName = this.domain_name;
    this.pService.getBulkProperties('dvp2', 'tenant.labels', domainName, this.tenant_id)
      .then((response: any) => {
        this.isLoading = false;
        this.setDefultData(response, scopeType);
      })
      .catch((err) => {
        this.isLoading = false;
        this.setDefultData("", scopeType);
      });
  }

  onValueChangeEvent(event: any, field) {
    //event.target.value === field.value ? field.markAsPristine() : ''
    let ischange = false;
    this.default_data.forEach(elm => {
      elm.properties.forEach((item) => {
        if (item.key == field) {
          if (event.target.value != item.value) {
            item.reset = true;
          } else {
            item.reset = false;
          }

        }
        if (item.reset) {
          ischange = true;
        }
      });
    });

    if (!ischange) {
      this.propertyForm.markAsPristine()
    }
  }

}
