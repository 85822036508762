import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ThemingService } from "@cl/common/services/theming.service";

@Component({
  selector: 'cl-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.scss']
})
export class MenuIconComponent implements OnInit, OnChanges{
  @Input() disabled;
  @Input() type;
  color;
  constructor(private themeService: ThemingService) {
    let colourObj = this.themeService.getUpdatedTheme();
    this.color = colourObj?.anchorLinksColor ? colourObj.anchorLinksColor : '#2879de';
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.disabled) {
      this.color = '#aaaaaa';
    }
  }

}
