import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Injectable({
  providedIn: 'root'
})
export class DeviceProfileService {
  constructor(private http: HttpClient, private apiProvider: ApiProviderService) { }

  getAllDeviceProfiles(){
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/intransit/eventprofiles`);
  }

  getProfileDetails(profileName, tenantId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/intransit/eventprofile/${profileName}?tenantId=${tenantId}`);
  }

  getServicesBySensorType(sensorType) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/meta/ptypes/${sensorType}/config`);
  }

  getSensorTypes() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/meta/pTypes`);
  }

  deleteDeviceProfile(profileName){
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/2/intransit/eventprofile/${profileName}`);
  }

  getTenantSpecificSensorTypes(tenantId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/${tenantId}?listpTypesWithConfigAllowed=true`);
  }

  saveEventProfile(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/2/intransit/eventprofile/`, payload);
  }

  updateEventProfile(payload) {
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/2/intransit/eventprofile/${payload.eventProfileName}`, payload);
  }

  deviceProfileFilter(data){
    let filterText = '';
    let filterType = '';
    data.forEach(item=>{
      filterText = item.filterText;
      filterType = item.filterType;
    })
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/intransit/eventprofiles/?filterType=${filterType}&filterText=${filterText}`);
  }

  getDeviceProfileFilter(){
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/intransit/eventprofiles/filter`);
  }
  
}
