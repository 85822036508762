import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@cl/user/user.service';
import { Subscription } from 'rxjs';
import countryCodeList from '../../../@data/country-with-iso-code.json';

@Component({
  selector: 'cl-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit, OnDestroy {

  currentUser:any;
  editDisabled = true;
  timeZoneList = {};
  timeZoneKeys = [];
  private subscriptions: Subscription[] = [];
  isLoading =false;
  filteredCodeList = [];
  openDropDoen = false;

  constructor(private userService: UserService,private fb: FormBuilder) { }

  personalDetailForm = this.fb.group({
    id: [''],
    name: ['' ,[Validators.required]],
    email: ['' ,[Validators.required]],
    optInEnabled:[false],
    timeZone:[''],
    countryCode:[''],
    phoneNumber:['',[Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
});

  ngOnInit(): void {
    console.log(this.userService.getUser());
    this.currentUser = this.userService.getUser();
    this.getTimeZoneList();
    this.getCountryCodeList();

    const countyCodeFormSubs:Subscription = this.personalDetailForm.controls['countryCode'].valueChanges.subscribe((data:any)=>{
      this.checkForTypeahead(data);
    });

    this.subscriptions.push(countyCodeFormSubs);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  checkForTypeahead(data){
    let temp = [];
    if(data){
      temp = countryCodeList.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(data.toLowerCase()) !== -1 || d.Iso.toLowerCase().indexOf(data.toLowerCase()) !== -1);
      });
      this.filteredCodeList = [...temp];
    }else{
      this.filteredCodeList = [...countryCodeList];
    }
  }

  getEmail(){
    let email = '';
    if(this.currentUser.userDevices && this.currentUser.userDevices.length>0){
      this.currentUser.userDevices.forEach(device => {
        if(device.transport == 'email'){
          email = device.deviceIdentifier;
        }
      });
    }
    return email;
  }

  getPhoneNumber(){
    let phoneNumber = 'No Phone number';
    if(this.currentUser.userDevices && this.currentUser.userDevices.length>0){
      this.currentUser.userDevices.forEach(device => {
        if(device.transport == 'sms'){
          phoneNumber = device.deviceIdentifier?device.deviceIdentifier:'No Phone number';
        }
      });
    }
    return phoneNumber;
  }

  getNameInitials(){
    let initials = '';
    if(this.currentUser){
      let nameArray = this.currentUser.name.split(' ');
      initials = nameArray[0].charAt(0).toUpperCase();
      if(nameArray.length>1){
        initials +=nameArray[1].charAt(0).toUpperCase();
      }
    }
    return initials;
  }

  getTimeZoneList(){
   const timezoneSubs:Subscription = this.userService.getUserTimezoneList().subscribe((data)=>{
    this.timeZoneList = data;
    this.timeZoneKeys = Object.keys(this.timeZoneList);
    }, (error) => {
      console.error(error);
    });
    this.subscriptions.push(timezoneSubs);
  }

  getCountryCodeList(){
   this.filteredCodeList = [...countryCodeList];
  }

  enableFormEdit(){
    this.patchUserForm();
    this.editDisabled = false;
  }

  patchUserForm(){
    let optInEnabled = false;
    let cCode = '';
    let pNumber = '';
    let combinedPhoneNumber = this.getPhoneNumber();
    if(this.currentUser.properties && this.currentUser.properties.isoptedforsms){
      optInEnabled = true;
    }
    if(combinedPhoneNumber && combinedPhoneNumber != 'No Phone number'){
      const isoCodes = this.filteredCodeList.map(item => item.Iso);
      const iso = isoCodes.find(item => combinedPhoneNumber.startsWith(item));
      pNumber = combinedPhoneNumber.split(iso)[1];
      cCode = iso;
    }

    this.personalDetailForm.patchValue({
        id: this.currentUser.id,
        name: this.currentUser.name,
        email: this.getEmail(),
        timeZone:this.currentUser.timezone,
        optInEnabled:optInEnabled,
        countryCode:cCode,
        phoneNumber:pNumber,
    });

    this.personalDetailForm.controls['id'].disable();
  }

  setCountryCode(code){
    this.personalDetailForm.patchValue({
      countryCode:code.Iso
    });
    this.openDropDoen = false;
  }

  saveUser(){
    let formValue = this.personalDetailForm.value;
    this.currentUser.name = formValue.name;
    this.currentUser.timezone = formValue.timeZone;
      if(this.currentUser.properties){
        this.currentUser.properties.isoptedforsms = formValue.optInEnabled;
      }else{
        this.currentUser.properties = new Object({
          isoptedforsms:formValue.optInEnabled
        });
      }

      if(this.currentUser.userDevices && this.currentUser.userDevices.length>0){
        this.currentUser.userDevices.forEach(device => {
          if(device.transport == 'sms'){
            device.deviceIdentifier = formValue.countryCode + '' + formValue.phoneNumber;
          }

          if(device.transport == 'email'){
            device.deviceIdentifier = formValue.email;
          }
        });
      }

    console.log(this.currentUser);
    const updateUserProfileSubs:Subscription = this.userService.updateUserProfile(this.currentUser.id,this.currentUser).subscribe(data=>{
      this.editDisabled = true;
    }, error=>{
      console.log(error);
    });
  }
}
