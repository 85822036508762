import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TimerSnackComponent } from "@cl/common/components/timer-snack/timer-snack.component";
import { OrgMismatchSnackbarComponent } from '../components/org-mismatch-snackbar/org-mismatch-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(public _snackBar: MatSnackBar) { }

  error(message: string, name?: any, duration?: any){
    this.openSnackBar(message, name?name:'', ['toast-error', 'font-bold'], duration);
  }
   warning(message: string, name?: any, duration?: any){
    this.openSnackBar(message, name?name:'', ['toast-warning', 'font-bold'], duration);
  }
   info(message: string, name?: any, duration?: any){
    this.openSnackBar(message, name?name:'', ['toast-info', 'font-bold'], duration);
  }
   success(message: string, name?: any, duration?: any){
    this.openSnackBar(message, name?name:'', ['toast-success', 'font-bold'], duration);
  }

  openTimerSnackBar(message, timer) {
    return this._snackBar.openFromComponent(TimerSnackComponent, { data: {
        timer: timer,
        message: message
      },
      duration: timer * 1000
    });
  }

   openSnackBar(action: any, name?: any, panelClass?:any[], duration?: number) {
    return this._snackBar.open(action, name?name:'', {
      duration: duration || 3000,
      panelClass: panelClass,
      verticalPosition: 'bottom',
      horizontalPosition: 'end'
    });
  }

  openCloseHandlerSnackBar(errors: any[],heading?:any, panelClass:string[] = ['toast-error'], isAutoClose = false) {
    this._snackBar.openFromComponent(OrgMismatchSnackbarComponent,{
      data:{
        action:'Ok',
        org_errors: errors,
        heading: heading,
        isAutoClose: isAutoClose
      },
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      panelClass:panelClass,
      duration: isAutoClose ? 3000 : undefined
    });
  }

}
