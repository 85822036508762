import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';
import { PropertyService } from '@cl/property/property.service';
import { ShipmentService } from '@cl/shipments/shipment.service';
import moment from 'moment';
import { TitleCasePipe } from '@angular/common';
import { UserService } from '@cl/user/user.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import _ from 'lodash';

@Component({
  selector: 'cl-shipment-summary-data',
  templateUrl: './shipment-summary-data.component.html',
  styleUrls: ['./shipment-summary-data.component.scss'],
})
export class ShipmentSummaryDataComponent implements OnInit, OnChanges {
  @Input() shipmentData: any;
  @Input() isShowAllFields: boolean = true;
  @Input() selectedAssetIndex: string;

  summaryData: {id?:string; label: string; value: string; link?: string; isDtpRestricted?: boolean }[] = [];

  depatureTime: string;
  arrivalTime: string;
  dateDisplayFormat:any;
  depatureDiff: string;
  arrivalDiff: string;
  isDTPUser = this._userService.isDTPUser();
  isDTPShipment = true;

  notes = {
    note: '',
    notesModifiedAt: null,
    notesModifiedBy: '',
  };
  dateFormatsMap = {}
  constructor(
    private _shipmentUtils: ShipmentUtilsService,
    private _shipmentService: ShipmentService,
    private pService: PropertyService,
    private _dynamicFormService: DynamicFormService,
    public datePipe : FormateDatePipe,
    private titlecasePipe:TitleCasePipe,
    private _userService: UserService,
    private utilService: UtilsService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    // Initinalized data in ngOnChnages because in summary component data needs to update while @input changes
    if(!this.dateDisplayFormat){
      this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
      this.dateDisplayFormat= this.dateDisplayFormat.replace('dd', 'DD'); // dd is refered as day(mon ,tue ,wed ...) in moment so replaced with DD to get (1,2,3....31)
      this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');  // replacing a(am ,pm) with A(AM ,PM) because only this format will work in moment()
    }
   
    this.renderData();
    this.getExtendedAttributes();
  }

  ngOnInit(): void {}

  renderData(){
    const shipment = this.shipmentData.shipmentNode;
    const shipmentProps = this.shipmentData.shipmentNode.properties;
    const route = this.shipmentData.routeNode?.properties;
    const destinationArea = this.shipmentData.destinationNode?.properties;
    const sourceArea = this.shipmentData.sourceNode?.properties;  
    this.isDTPShipment = shipmentProps.parentEntityTypeKey == 'dtpshipment';
    
    this.summaryData = [
      {
        id:'plannedDeparture',
        label: 'Planned Departure',
        value: this.convertDateFormat(+shipmentProps?.plannedDeparture, 'plannedDeparture'),
      },
      {
        id:'plannedArrival',
        label: 'Planned Arrival',
        value: this.convertDateFormat(+shipmentProps?.plannedArrival, 'plannedArrival'),
      },
      {
        label: 'Type',
        value: shipmentProps?.entityType
      },
      {
        label: 'Catalog Type',
        value: shipmentProps?.parentEntityType
      },
      {
        label: 'Name',
        value: shipmentProps?.name,
      },
      {
        label: 'External ID',
        value: shipmentProps?.externalId,
      },
      {
        label: 'Order Number',
        value: shipmentProps?.orderNum,
      },
      {
        label: 'Shipper Name',
        value: shipmentProps?.shipperName,
        isDtpRestricted: true
      },
      {
        label: 'Carrier Name',
        value: shipmentProps?.carrierName,
      },
      {
        label: 'Customer Name',
        value: shipmentProps?.custName,
        isDtpRestricted: true,
      },
      {
        label: 'Customer Code',
        value: shipmentProps?.custCode,
      },
      {
        label: 'Organization',
        value: shipmentProps?.organizationName,
        isDtpRestricted: true,
      },
      {
        label: 'Mode of Transport',
        value: shipmentProps?.modeOfTransport,
      },
      {
        label: 'Shipment Direction',
        value: shipmentProps?.direction,
      },
      {
        label: 'Customer Order Ref',
        value: shipmentProps?.custOrdRef,
      },
      {
        label: 'Recipient Name',
        value: shipmentProps?.recipientName,
        isDtpRestricted: true
      },
      {
        label: 'Direct to Residence',
        value:
          shipmentProps?.directToResidence === 'true' ||
          shipmentProps?.directToResidence === true
            ? 'Yes'
            : 'No',
      },
      {
        label: 'Route',
        value: shipmentProps.routeHidden ? shipmentProps?.dynamicRouteLabel : shipmentProps?.routeName,
        link:  !shipmentProps.routeHidden && '#/locations?rid='+shipmentProps?.routeId,
        isDtpRestricted: true
      },
      {
        label: 'Rule Set',
        value: shipmentProps?.ruleSetName,
        link: '#/rule-sets?rid='+shipmentProps?.ruleSet,
        isDtpRestricted: true
      },
      {
        label: 'Device Profile',
        value: shipmentProps?.sensorProfile,
        link: '#/deviceprofiles?did='+shipmentProps?.sensorProfile,
      },
      {
        label: 'Origin',
        value: sourceArea?.dynamicArea ? sourceArea?.fullAddress : sourceArea?.name,
        isDtpRestricted: true
      },
      {
        label: 'Destination',
        value: destinationArea?.dynamicArea ? destinationArea?.fullAddress : shipmentProps?.destinationAreaName,
        isDtpRestricted: true
      },
      {
        label: 'Number of Assets',
        value: this.shipmentData?.assetNodes?.length,
      },
      {
        label: 'Master AWB',
        value: shipmentProps?.mawb,
      },
      // {
      //   label: 'Tags',
      //   value: shipmentProps?.tags?.map((e) => e).join(', '),
      // },
      {
        label: 'Created At',
        value: this.convertDateFormat(+shipmentProps?.createdAt, 'createdAt'),
      },
      {
        label: 'Created By',
        value: shipmentProps?.createdBy,
      },
      {
        label: 'Modified At',
        value: this.convertDateFormat(+shipmentProps?.modifiedAt, 'modifiedAt'),
      },
      {
        label: 'Modified By',
        value: shipmentProps?.modifiedBy,
      },
    ];

    if(!_.isEmpty(shipmentProps?.notes)){
      if (shipmentProps?.notes.trim() !== '') {
        this.notes = {
          note: shipmentProps?.notes,
          notesModifiedAt: this.convertDateFormat(
            +shipmentProps?.notesModifiedAt, 'notesModifiedAt'),
          notesModifiedBy: shipmentProps?.notesModifiedBy,
        };
      }
    }

    if (['parcelshipment', 'dtpshipment'].includes(shipmentProps.parentEntityTypeKey)) {
      const trackingIDs: string[] = shipmentProps?.assets.map(
        (asset) => asset?.trackingNumber
      );

      const distinctArray = trackingIDs.filter((n, i) => trackingIDs.indexOf(n) === i);
      // Push tracking id and proof of deliveries before created At
      this.summaryData.splice(this.summaryData.length - 4, 0, {
        label: 'Tracking IDs',
        value: distinctArray.join(','),
      });

      this.getAssetMileStones();
    }
    this.depatureDiff = null;
    this.arrivalDiff = null;
    if (shipmentProps?.actualDepTime) {
      this.depatureTime = this.convertDateFormat(+shipmentProps.actualDepTime, 'actualDepTime');

      const depatureDiff = this.getDiffTimes(
        +shipmentProps.plannedDeparture,
        +shipmentProps.actualDepTime
      );

      if (depatureDiff) {
        this.depatureDiff = this.momentHumanize(depatureDiff, 'minutes');
        let d = depatureDiff.toString();
        if (d[0] == '-') {
          this.depatureDiff = '+' + this.depatureDiff;
        } else {
          this.depatureDiff = '-' + this.depatureDiff;
        }
        // this.depatureDiff =
        //   (depatureDiff > -10 && depatureDiff < 10 ? '0' : '') +
        //   Math.abs(depatureDiff);
        // this.depatureDiff =
        //   (depatureDiff < 0 ? '-' : '+') + this.depatureDiff + 'm';
      }
    }

    const actualArrival = this.isArrival
      ? shipmentProps?.actualArrivalTime
      : shipmentProps?.eta;
    if (actualArrival) {
      this.arrivalTime = this.convertDateFormat(+actualArrival, 'actualArrivalTime');
      const arrivalDiff = this.getDiffTimes(
        +shipmentProps.plannedArrival,
        +actualArrival
      );

      if (arrivalDiff) {
        this.arrivalDiff = this.momentHumanize(arrivalDiff, 'minutes');
        let a = arrivalDiff.toString();
        if (a[0] == '-') {
          this.arrivalDiff = '+' + this.arrivalDiff;
        } else {
          this.arrivalDiff = '-' + this.arrivalDiff;
        }

        // this.arrivalDiff =
        //   (arrivalDiff > -10 && arrivalDiff < 10 ? '0' : '') +
        //   Math.abs(arrivalDiff);
        // this.arrivalDiff =
        //   (arrivalDiff < 0 ? '-' : '+') + this.arrivalDiff + 'm';
      }
    }
    this.checkIsInCompositeShipment();
  }
  
  checkIsInCompositeShipment() {
    const shipmentProps = this.shipmentData.shipmentNode.properties;

    if (shipmentProps.payloadOf) {
      const compoisteData = [
        {
          label: 'Composite Shipment Name',
          value: shipmentProps.compositeShipmentName,
          link: `#/shipmentdetails/${shipmentProps?.id}?compositeShipmentId=${shipmentProps?.payloadOf}&type=${shipmentProps.entityTypeKey}&cstype=${shipmentProps.compositeShipmentType}`,
        },
        {
          label: 'Composite Shipment ID',
          value: shipmentProps.compositeShipmentExtId,
          link: `#/shipmentdetails/${shipmentProps?.id}?compositeShipmentId=${shipmentProps?.payloadOf}&type=${shipmentProps.entityTypeKey}&cstype=${shipmentProps.compositeShipmentType}`,
        },
      ];
      this.summaryData.splice(this.summaryData.length - 4, 0, ...compoisteData);
    }
  }

  async getExtendedAttributes(){
    this.dateFormatsMap = {};
    const shipment = this.shipmentData.shipmentNode;
    const shipmentCatalog = await this._shipmentUtils.getShipmentExtendedAttributes(shipment.type);
    const fields = shipmentCatalog.cdmFields.filter(field => field.group === 'User' && field.displayable);
    const systemDateFields = ['plannedDeparture','plannedArrival','modifiedAt', 'actualArrivalTime', 'actualDepTime', 'createdAt', 'notesModifiedAt']
    const systemDatecdmFields = shipmentCatalog.cdmFields.filter(field => field.group === 'System' && systemDateFields.includes(field.name));
    systemDatecdmFields.forEach(field=>{
      const value = this.checkValue(field.type, shipment.properties[field.name]);
      this.dateFormatsMap[field.name] = value
    })
    const orderedFields = this._dynamicFormService.orderFields([...fields],'order');
    if(orderedFields.length > 0){
      orderedFields.forEach(field=>{
       let arryField = {
            label: field.displayLabel,
            value: this.checkValue(field.type ,shipment.properties[field.name]),
          }
       this.summaryData.splice(this.summaryData.length - 4, 0, arryField);
      })
    }
  }
  checkValue(type:any, value:any){
    if(value === undefined) return value;
    else if(type.toLowerCase()==='date') {
      return this.datePipe.transform(value, 'excludeTime');
    }else if(type.toLowerCase()==='datetime') {
      return this.datePipe.transform(value, 'default');
    }
    else if(type.toLowerCase()==='boolean') {
        if(value.toString().toLowerCase() =="true") return "Yes";
        if(value.toString().toLowerCase() =="false") return 'No';
    }else return value;
  }
  get isArrival(): boolean {
    const { statusCode, subStatusCode } =
      this.shipmentData?.shipmentNode?.properties;

    if (!statusCode) return false;

    if (statusCode === 'At Destination') return true;

    if (statusCode === 'Completed') {
      return subStatusCode === 'Completed';
    }

    return false;
  }

  async getAssetMileStones() {
    const shipmentDateRange = this._shipmentUtils.getShipmentDateRange({
      ...this.shipmentData.shipmentNode.properties,
    });

    let asset;
    if (this.selectedAssetIndex) {
      asset = this.shipmentData.assetNodes.find(
        (asset) => asset.id === this.selectedAssetIndex
      );
    } else {
      asset = this.shipmentData.assetNodes[0];
    }

    if (!asset) return this.generateProofOfDelivery('');

    const [assetMileStones] = await this._shipmentService.getAssetMileStones({
      assetID: asset.properties.taggedAssetId,
      from: shipmentDateRange.dateRange.startDate,
      to: shipmentDateRange.dateRange.endDate,
    });

    if (!assetMileStones || !assetMileStones.data)
      return this.generateProofOfDelivery('');

    let podInfo = '';
    let isHavingPod = false;
    let isPodGenerated = false;
    assetMileStones.data.forEach((milestone) => {
      const milestoneJson = JSON.parse(milestone.value);
      if (milestoneJson.tag === 'Delivered' && milestoneJson.signed_by && !isPodGenerated) {
        const timezone = milestoneJson.checkpointLocalTime ? this.utilService.getTimezone(milestoneJson.checkpointLocalTime) : null;
        const checkpointTime = milestoneJson.checkpointLocalTime ? this.datePipe.transform(milestoneJson.checkpointLocalTime, 'default') + (timezone ? ' ('+timezone+')' : "") : ''
        isPodGenerated = true;
        podInfo = (this.isDTPShipment && this.isDTPUser)
          ? checkpointTime
          : `Signed by: ${milestoneJson.signed_by} [ ${checkpointTime} ]`;
        this.generateProofOfDelivery(podInfo);
        isHavingPod = true;
      }
    });

    if (!isHavingPod) return this.generateProofOfDelivery('');
  }

  generateProofOfDelivery(podInfo: string) {
    this.summaryData.splice(this.summaryData.length - 4, 0, {
      label: 'Proof of Delivery Info',
      value: podInfo &&((this.isDTPUser && this.isDTPShipment) ? '(Restricted)' : podInfo),
      isDtpRestricted: true
    });
  }

  convertDateFormat(planned: any, id:string) {
    if(this.dateFormatsMap[id]){
      return this.dateFormatsMap[id];
    }
    else if(planned){
      return moment(planned).format(this.dateDisplayFormat);
    }
    return ''
  }

  getDiffTimes(planned: any, actual: any) {
    if (!planned || !actual) return null;
    return Math.round((planned - actual) / (60 * 1000));
  }
  momentHumanize(event, unit) {
    let sting = event.toString();
    let min = event;
    if (sting[0] == '-') {
      min = event.toString().substring(1);
    }
    var eventMDuration = moment.duration(+min, unit);
    var eventDurationArray = [];

    // Hack moment will give into years, months and days format, its hard to convert into days, so simply dividing by per day with 24hrs & 60 minutes
    const days = Math.floor(+min/(24*60))
    if(days > 0){
      eventDurationArray.push(days + ' d');
    }
    if(eventMDuration.get('hours')){
      eventDurationArray.push(eventMDuration.get('hours') + ' hrs');
    }
    if(eventMDuration.get('minutes')){
      eventDurationArray.push(eventMDuration.get('minutes') + ' mins');
    }
    

    // if (eventMDuration.years() > 0) {
    //   eventDurationArray.push(eventMDuration.years() + ' y');
    //   eventMDuration.subtract(eventMDuration.years(), 'years');
    // }
    // if (eventMDuration.months() > 0) {
    //   eventDurationArray.push(eventMDuration.months() + ' m');
    //   eventMDuration.subtract(eventMDuration.months(), 'months');
    // }
    // if (eventMDuration.weeks() > 0) {
    //   eventDurationArray.push(eventMDuration.weeks() + ' w');
    //   eventMDuration.subtract(eventMDuration.weeks(), 'weeks');
    // }
    // if (
    //   eventMDuration.years() > 0 ||
    //   eventMDuration.months() > 0 ||
    //   eventMDuration.weeks() ||
    //   eventMDuration.days() > 0
    // ) {
    //   eventDurationArray.push(
    //     eventMDuration.days() +
    //       eventMDuration.years() * 365 +
    //       eventMDuration.months() * 30 +
    //       eventMDuration.weeks() * 7 +
    //       ' d'
    //   );
    //   eventMDuration.subtract(eventMDuration.days(), 'days');
    // }
    // if (eventMDuration.hours() > 0) {
    //   eventDurationArray.push(eventMDuration.hours() + ' hrs');
    //   eventMDuration.subtract(eventMDuration.hours(), 'hours');
    // }
    // if (eventMDuration.minutes() > 0) {
    //   eventDurationArray.push(eventMDuration.minutes() + ' mins');
    // }
    return eventDurationArray.length === 1
      ? eventDurationArray[0]
      : eventDurationArray.join(', ');
  }
  stringTruncate(value: string, size: number = 15): string {
    if (!value) return '';

    const limit = size > 0 ? size : 15;
    return value.length > limit
      ? value.substring(0, limit).trim() + '...'
      : value;
  }
  catalogTypeTitleCase(catalogType){
    return this.titlecasePipe.transform(catalogType);
  }
}
