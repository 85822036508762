import { Component, OnDestroy } from "@angular/core";
import { AppNotification, NotificationStatusCount } from "@cl/models";
import { AppState } from "@cl/state/app.state";
import { Select,Store } from "@ngxs/store";
import { combineLatest, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as _ from 'lodash';
import { AppGetNotifications } from "@cl/ngxs/actions";

@Component({
  selector: 'cl-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnDestroy {
  @Select(AppState.criticalNotifications) criticalNotifications$: Observable<AppNotification[]>;
  criticalNotifications: AppNotification[] = [];
  criticalStatusCounts: NotificationStatusCount;

  @Select(AppState.warningNotifications) warningNotifications$: Observable<AppNotification[]>;
  warningNotifications: AppNotification[] = [];
  warningStatusCounts: NotificationStatusCount;

  statusCounts: NotificationStatusCount;

  tabSelectedIndex = 0;

  private destroyed$ = new Subject<boolean>();

  constructor(private appState: AppState,private store: Store) {}

  ngOnInit(): void {
    this.listenToNotificationUpdates();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private listenToNotificationUpdates(): void {
    combineLatest([this.criticalNotifications$, this.warningNotifications$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([criticalNotifications, warningNotifications]) => {
        this.criticalNotifications = criticalNotifications;
        this.criticalStatusCounts = this.appState.getReadUnreadCounts(criticalNotifications);

        this.warningNotifications = warningNotifications;
        this.warningStatusCounts = this.appState.getReadUnreadCounts(warningNotifications);

        this.statusCounts = {
          READ: this.warningStatusCounts.READ + this.criticalStatusCounts.READ,
          UNREAD: this.warningStatusCounts.UNREAD + this.criticalStatusCounts.UNREAD,
        };
      });
  }

  updateNotifications(data){
    setTimeout(() => {
       this.store.dispatch(new AppGetNotifications());
    },1500)
  }
}
