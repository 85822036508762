import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputField } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { UserService } from '@cl/user/user.service';
import * as moment from 'moment';

@Component({
  selector: 'cl-dynamic-form-renderer',
  templateUrl: './dynamic-form-renderer.component.html',
  styleUrls: ['./dynamic-form-renderer.component.scss'],
})
export class DynamicFormRendererComponent implements OnInit, OnChanges {
  @Input() inputFields: InputField[] = [];
  @Input('form') parentForm!: FormGroup;
  @Input() columnMode: 'One' | 'Two' = 'One';
  @Input() displayErrors = true;
  @Input() showAnyOrg = false;
  @Output() onInputBlur = new EventEmitter();
  submitted = false;
  isOrgAware:boolean;
  form!: FormGroup;

  temporaryDropDownValues: any = {};

  constructor(private _dynamicFormService: DynamicFormService, private userService: UserService,) {}

  ngOnInit(): void {
    this.form.controls['type']?.valueChanges.subscribe(value => {
      this.form.controls['rssiRange'].patchValue(undefined);
    }) 
    this.isOrgAware = this.userService.getUser()?.orgAware;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputFields']) {
      if (!this.parentForm) {
        this.form = this._dynamicFormService.createFormGroup(this.inputFields);
      } else {
        this.form = this.parentForm;
      }
    }
  }

  onDateChange(updatedDate, field: string) {
    if (!updatedDate?.startDate) return;

    this.form.get(field).patchValue(moment(updatedDate.startDate).valueOf());
  }

  preventDecimals(evt, field) {
    let value = evt.target.value;
    if (/[^0-9]+/.test(value)) {
      value = value.replace(/[^0-9]*/g, '');
    }
    this.form.get(field).patchValue(value);
  }

  displayFn(item: any) {
    if(item=="ANY") return '(Any)'
    return item && item.label ? item.label : item;
  }

  onSelect(evt: any, fieldId: string) {
    this.form.get(fieldId)?.patchValue(evt);
    this.temporaryDropDownValues[fieldId] = undefined;
  }

  onMatInputBlur(fieldId: string) {
    setTimeout(() => {
      if (this.temporaryDropDownValues[fieldId] != undefined) {
        this.form
          .get(fieldId)
          ?.patchValue(this.temporaryDropDownValues[fieldId]);
        this.onInputBlur.emit(this.temporaryDropDownValues);
        this.temporaryDropDownValues[fieldId] = undefined;
      }
    }, 250);
  }

  onMatOpen(field: InputField) {
    if (this.temporaryDropDownValues[field.id] == undefined) {
      if (field.isDynamicFetching) {
        field.options = [];
      }
      if(field && field.options){        
        field.options.sort(function (a,b) {
          if(a.label){
          return a.label.toLowerCase()>b.label.toLowerCase()?1:-1;
          }
          else{
          return a.toLowerCase()>b.toLowerCase()?1:-1;
          }
      })
      }
      this.temporaryDropDownValues[field.id] = this.form.get(field.id)?.value;
      this.form.get(field.id)?.patchValue('');
    }
  }

  async onMatSearch(field: InputField) {
    if (
      !field.isDynamicFetching ||
      !field.apiUrl ||
      !field.apiKey ||
      !field.apiLabel
    )
      return;
    const value = this.form.get(field.id).value;
    if (value.length < 3) {
      field.options = [];
      return;
    }

    const endpoint = field.apiUrl.replace('<text>', value);
    field.options = await this._dynamicFormService.getFieldData(
      field,
      endpoint
    );
  }

  onMatClear(field: InputField) {
    if (field.dropdownConfig.isClearable) {
      this.form.get(field.id)?.patchValue('');
      this.temporaryDropDownValues[field.id] = undefined;
    }
  }

  public onSubmit() {
    this.submitted = true;
    return this.form;
  }  
}
