import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ShipmentService } from '@cl/shipments/shipment.service';
import moment from 'moment';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';
import { ShipmentDetailsEnvConditionsComponent } from '../shipment-details-env-conditions/shipment-details-env-conditions.component';
import { Router } from '@angular/router';
import { PropertyService } from '@cl/property/property.service';
import { SensorService } from '@cl/sensor-fabric/sensor/sensor.service';
import { UserService } from '@cl/user/user.service';

@Component({
  selector: 'cl-shipment-details-map',
  templateUrl: './shipment-details-map.component.html',
  styleUrls: ['./shipment-details-map.component.scss'],
})
export class ShipmentDetailsMapComponent implements OnInit {
  @Output() emitExcursions = new EventEmitter<any>();
  @Output() emitCurrentLocation = new EventEmitter<any>();
  @ViewChild('envcndts') envcndts: ShipmentDetailsEnvConditionsComponent;
  selectedDateRange: { startDate: moment.Moment; endDate: moment.Moment };
  ranges: any;
  planedRoute: any = [];
  actualRoute: any = [];
  excursions: any = [];
  pressureUnits ="hPa"
  lat: number;
  lng: number;
  taggedAssetId: any;
  showMap: boolean = true;
  @Input() shipmentData: any;
  @Input() winkedAssetId: any;
  @Input() assetData: any;
  tempValue:any;
  excursionObj: any;
  actualRouteObj: any = {};
  isLoading: boolean = false;
  map: any;
  zoom: number = 3;
  minZoom = 2;
  maxZoom = 20;
  isHavingZoomControls = true;
  isRestrictedScroll:boolean =true;
  isShowEnv: boolean = false;
  selectedAsset: any;
  currentAssetLocation: any;
  tenantTempType: any;
  dateFormat: any;
  mapOptions: any = {
    gestureHandling: 'cooperative',
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    positionOfActions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
  };
  datePickerClass: any = 'details-date-picker';
  isDTPUser = this._userService.isDTPUser();
  isDTPShipment = true;
  constructor(
    private shipmentsService: ShipmentService,
    private shipmentUtilsService: ShipmentUtilsService,
    private router: Router,
    private propertyService: PropertyService,
    private sensorService: SensorService,
    private _userService: UserService,
    private cdr:ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.isDTPShipment = this.shipmentData.shipmentNode.properties.parentEntityTypeKey == 'dtpshipment';
    // Configure zoom options based upon dtp restrictions
    if(this.isDTPUser && this.isDTPShipment){
      this.zoom = this.minZoom = this.maxZoom = 2;
      this.isHavingZoomControls = false;
    }
    if (this.router.url.includes('shipmentdetails')) {
      this.isShowEnv = true;
    }
    this.selectedAsset = this.assetData;
    this.tempValue = this.selectedAsset;
    this.getPressureUnits();
    const shipmentDateRange = this.shipmentUtilsService.getShipmentDateRange(
      this.shipmentData.shipmentNode.properties, true
    );
    this.selectedDateRange = {
      startDate: moment(+shipmentDateRange.dateRange.startDate),
      endDate: moment(+shipmentDateRange.dateRange.endDate),
    };
    this.ranges = {
      'Last 4 Hours': [moment().subtract(4, 'hours'), moment()],
      'Last 8 Hours': [moment().subtract(8, 'hours'), moment()],
      'Last 16 Hours': [moment().subtract(16, 'hours'), moment()],
      'Last 24 Hours': [moment().subtract(24, 'hours'), moment()],
    };
    this.dateFormat = this.propertyService.getSetting(
      'ui.dateFormat',
      false,
      'MM/DD/YYYY hh:mm A'
    );
    this.dateFormat = this.dateFormat.replace('dd', 'DD').replace(' a', ' A');
    if (!this.isShowEnv) {
      this.reload();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    this.tenantTempType = '°' + this.propertyService.getSetting('ui.temp.unit');
    if(!changes['shipmentData'].firstChange) {
      this.selectedAsset = this.shipmentData.assetNodes.length > 0?this.shipmentData.assetNodes[0]:null;
      this.tempValue = this.selectedAsset
      this.onChangeAsset(this.selectedAsset);
    }
    if (
      changes['winkedAssetId'] &&
      !changes['winkedAssetId'].firstChange &&
      this.winkedAssetId
    ) {
      this.shipmentData.assetNodes.forEach((assetNode) => {
        if (assetNode.id == this.winkedAssetId) {
          this.selectedAsset = assetNode;
          this.tempValue = this.selectedAsset
          this.onChangeAsset(this.selectedAsset);
        }
      });
    }
  }
  changeMapZoom(event) {
    this.zoom = event;
  }

  reload() {
    if(!this.selectedAsset) return;
    this.actualRoute = [];
    this.planedRoute = [];
    let originIcon = '../assets/svgs/map-icons/site-pin-origin.svg';
    let destinationIcon = '../assets/svgs/map-icons/route-destination.svg';
    let wayPointIcon = '../assets/svgs/organization.svg';
    this.getActualRoute();
    this.getExcursions();
    if(this.shipmentData.routeNode.properties.locationDetails && typeof this.shipmentData.routeNode.properties.locationDetails == 'string'){
      this.shipmentData.routeNode.properties.locationDetails = JSON.parse(this.shipmentData.routeNode?.properties?.locationDetails)
    }
    this.shipmentData.routeNode.properties.locationDetails?.forEach(
      (element) => {
        if(element.coordinates) {
          const obj = {
            lat: (this.isDTPUser && this.isDTPShipment) ? Math.floor(+element.coordinates.lat) : +element.coordinates.lat,
            lng: (this.isDTPUser && this.isDTPShipment) ? Math.floor(+element.coordinates.lng) : +element.coordinates.lng,
            label: element.areaType,
            draggable: true,
            iconUrl: wayPointIcon,
            content: 'InfoWindow for Start',
          };
          this.planedRoute.push(obj);
        } else if(JSON.parse(element.routeGeojson).geometry.coordinates){
          const coordinates = JSON.parse(element.routeGeojson).geometry.coordinates; 
          const obj = {
            lat: (this.isDTPUser && this.isDTPShipment) ? Math.floor(coordinates[1]) : coordinates[1],
            lng: (this.isDTPUser && this.isDTPShipment) ? Math.floor(coordinates[0]) : coordinates[0],
            label: element.areaType?element.areaType:(element.areaItem?element.areaItem.type:''),
            draggable: true,
            iconUrl: wayPointIcon,
            content: 'InfoWindow for Start',
          };
          this.planedRoute.push(obj);
        } else if(element.areaItem.coordinates && JSON.parse(element.areaItem.coordinates).geometry.coordinates) {
          const coordinates = JSON.parse(element.areaItem.coordinates).geometry.coordinates;
          const obj = {
            lat: (this.isDTPUser && this.isDTPShipment) ? Math.floor(coordinates[1]) : coordinates[1],
            lng: (this.isDTPUser && this.isDTPShipment) ? Math.floor(coordinates[1]) : coordinates[1],
            label: element.areaType?element.areaType:(element.areaItem?element.areaItem.type:''),
            draggable: true,
            iconUrl: wayPointIcon,
            content: 'InfoWindow for Start',
          };
          this.planedRoute.push(obj);
        }

      }
    );
    (this.planedRoute[0].iconUrl = originIcon),
      (this.planedRoute[this.planedRoute.length - 1].iconUrl = destinationIcon);
  }

  datesUpdated(datesUpdated) {
    this.selectedDateRange = datesUpdated;
    if (datesUpdated) {
      this.reload();
    }
  }
  onChangeAsset(event: any) {
    if(event){
      this.tempValue = event;
      this.getExcursions();
      this.getActualRoute();
      this.getPressureUnits();
    }else{
      this.showMap = false;
    }
  }
  getPressureUnits(){
      if(this.tempValue?.properties?.sensor){
      let sensors =this.tempValue.properties.sensor.join()
    this.sensorService.getSensorTypeDetails(sensors).then((response) => {
      var sensorServices = response;
      var x = sensorServices.services.filter(
        (e) => e.identifier == 'Pressure'
      );
    this.pressureUnits= x[0].characteristics[0]?.configParams?.unit ? x[0].characteristics[0].configParams.unit : "hPa"
  });
  }else{
    let uniq :String[]=[];
    let sLength = this.tempValue?.properties?.sensorIds?.length;
    this.tempValue?.properties?.sensorIds.forEach((element2 , index) => {
      this.sensorService
        .getSensorInformation(element2)
        .then((response) => {
          const sensorType = response;
          uniq.push(sensorType.properties.type)
           if(sLength-1 == index){
              uniq = [...new Set(uniq)];
              this.sensorService.getSensorTypeDetails(uniq.join()).then((response) => {
                var sensorServices = response;
                this.tempValue.services = response.services;
                var x = sensorServices.services.filter(
                  (e) => e.identifier == 'Pressure'
                );
              this.pressureUnits= x[0]?.characteristics[0]?.configParams?.unit ? x[0].characteristics[0].configParams.unit : "hPa"
              });
            }
          })
        .catch((err) => {});
    });
  }
}
  getExcursions() {
    this.shipmentsService
      .getShipmentExcursions(
        this.shipmentData.shipmentNode.properties.id,
        this.selectedAsset?.properties?.taggedAssetId
      )
      .subscribe((data) => {
        this.showMap = true;
        this.excursions = [];
        this.excursionObj = data;
        this.emitExcursions.emit(this.excursionObj);
        let excursionIcon = '../assets/svgs/map-icons/infraction.svg';

        let exKeys = Object.keys(this.excursionObj.ViolationMap);
        exKeys.forEach((element) => {
          this.excursionObj.ViolationMap[element].forEach((ex) => {
            let duplicateCheck = this.excursions.filter(function (test: any) {
              return test.lat === ex.lat && test.lon === ex.lon;
            });
            if (duplicateCheck.length != 0) {
              let excursionDetails = {
                canonicalPropertyName:ex.canonicalPropertyName,
                excursionType: ex.violationType,
                propertyValue: ex.violationTypeGroup=='Carrier Milestone'? JSON.parse(ex.propertyValue) : ex.propertyValue,
                violated: (ex.violated && ex.alertStatus=="Active")?true:false,
                violationType: ex.violationType,
                deviceTime: ex.deviceTime,
                violationTypeGroup:ex.violationTypeGroup
              };
              if(ex.violated && ex.alertStatus=="Active"){
                duplicateCheck[0].excursionDetails.push(excursionDetails);
              }
            
            } else {
              let obj = {
                lat:
                  +ex.lat ||
                  this.shipmentData.routeNode.properties.locationDetails[0]
                    .coordinates.lat,
                lon:
                  +ex.lon ||
                  this.shipmentData.routeNode.properties.locationDetails[0]
                    .coordinates.lng,
                label: ex.violationType,
                draggable: true,
                iconUrl: excursionIcon,
                excursionDetails: [
                  {
                    canonicalPropertyName:ex.canonicalPropertyName,
                    excursionType: ex.violationType,
                    propertyValue: ex.violationTypeGroup=='Carrier Milestone'? JSON.parse(ex.propertyValue) : ex.propertyValue,
                    violated: (ex.violated && ex.alertStatus=="Active")?true:false,
                    violationType: ex.violationType,
                    deviceTime: ex.deviceTime,
                    violationTypeGroup:ex.violationTypeGroup
                  },
                ],
              };
              if(ex.violated && ex.alertStatus=="Active"){
              this.excursions.push(obj);}
            }
          });
        });
        this.cdr.detectChanges();
      });
  }

  getIndividualExcursions(excursionDetails: any[]): any[]{
    if(!excursionDetails?.length) return [];

    const groupedExcursions = {};
    excursionDetails.forEach(excursion => {
      if(!groupedExcursions[excursion?.canonicalPropertyName]){
        groupedExcursions[excursion?.canonicalPropertyName] = excursion;
      }
    })

    return Object.values(groupedExcursions)
  }

  getActualRoute() {

    let geoPointIcon = '../assets/icons-svgs/gps-ping.svg';
    let wayPointIcon = '../assets/icons-svgs/ioc-round.svg';
    let originIcon = '../assets/svgs/map-icons/site-pin-origin.svg';
    let obj = {
      shipmentId: this.shipmentData.shipmentNode.properties.id,
      taggedAssetId: this.selectedAsset?.properties?.taggedAssetId,
      startDate: moment(this.selectedDateRange?.startDate).valueOf(),
      endDate: moment(this.selectedDateRange?.endDate).valueOf(),
    };
    //if(this.selectedAsset.properties.physicalSensorIds && this.selectedAsset.properties.physicalSensorIds.length > 0) {
      this.isLoading = true;
      this.shipmentsService.getShipmentActualRoute(obj).subscribe((data: any) => {
        this.isLoading = false;
        let actualRouteGeoPoints = [];

        let uniqueGeoPoints = [];
        let uniqueLatestGeoPoints = {};

        data[0].data.forEach(element => {
          if(!uniqueLatestGeoPoints[element.GeoPoint]){
              uniqueLatestGeoPoints[element.GeoPoint] = element;
          }else if(uniqueLatestGeoPoints[element.GeoPoint]?.Date < element.Date){
            uniqueLatestGeoPoints[element.GeoPoint] = element;
          }
        });
        uniqueGeoPoints = Object.values(uniqueLatestGeoPoints)
        
        const totalRecords = uniqueGeoPoints.length;
        if (totalRecords > 101) {
          let records = [];
          const reminder = Math.floor(totalRecords / 100);
          for (let pointer = 0; pointer < totalRecords; pointer++) {
            if (records.length >= 100) break;
            records.push(uniqueGeoPoints[pointer * reminder]);
          }
          records.push(uniqueGeoPoints[totalRecords - 1]);
          uniqueGeoPoints = [...records];
        }

        uniqueGeoPoints.forEach((element) => {
          let geoPoints = element.GeoPoint.split(',');
          const obj = {
            lat: (this.isDTPUser && this.isDTPShipment) ? Math.floor(+geoPoints[0]) : +geoPoints[0],
            lng: (this.isDTPUser && this.isDTPShipment) ? Math.floor(+geoPoints[1]) : +geoPoints[1],
            label: data.assetName,
            date: element.Date,
            draggable: true,
            iconUrl: geoPointIcon,
            content: 'InfoWindow for Start',
          };
          actualRouteGeoPoints.push(obj);
        });
        let origin = {
          lat: Number(this.planedRoute[0].lat),
          lng: Number(this.planedRoute[0].lng),
          label: this.planedRoute[0].assetName,
          draggable: true,
          iconUrl: originIcon,
          content: 'InfoWindow for Start',
        };
        actualRouteGeoPoints.unshift(origin);
        this.actualRoute = actualRouteGeoPoints;
        this.emitCurrentLocation.emit(this.actualRoute);
        if (this.actualRoute.length > 1) {
          this.actualRoute[0].iconUrl = originIcon;
          this.actualRoute[this.actualRoute.length - 1].iconUrl = wayPointIcon;
        }
        if (!this.isShowEnv) {
          let latLong =
            this.actualRoute[this.actualRoute.length - 1].lat +
            ',' +
            this.actualRoute[this.actualRoute.length - 1].lng;

          this.shipmentsService
            .bulkReverseCoding([
              {
                y: +this.actualRoute[this.actualRoute.length - 1].lat,
                x: +this.actualRoute[this.actualRoute.length - 1].lng,
              },
            ])
            .subscribe((location) => {
              this.currentAssetLocation =
                location[0].features[0]?.properties?.city ||
                location[0].features[0]?.properties?.name;
            });
        }
      });
    //}
  }
  refreshClicked() {
    this.reload();
    this.envcndts.reload();
  }
  displayFn(item) {
    return item? item.properties?.name : ""
  }
  matClose(){
     this.selectedAsset=this.tempValue;
  }
  matOpen(){
    this.selectedAsset=undefined;
 }
 formatValues(value){
  if(isNaN(Number(value))) return value;
    return Number(value)
  }
  violationIcon(measure) {
    return  this.shipmentUtilsService.getExcursionIcon(measure.toLowerCase());
  }
  scrolLeave(){
    this.isRestrictedScroll =true
  }
  scrolhover(){
    this.isRestrictedScroll =false
  }
}
