import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { ToastService } from '@cl/common/services/toast.service';

@Component({
  selector: 'cl-bulk-action-panel',
  templateUrl: './bulk-action-panel.component.html',
  styleUrls: ['./bulk-action-panel.component.scss']
})
export class BulkActionPanelComponent implements OnInit {
  @Input() panelObj: BulkPanelModel;
  @Output() closeSidePanel = new EventEmitter();
  @Output() refreshParentList = new EventEmitter();
  @Input() fields: string[] = [];
  @Input() downloadedCSVFileName: string = '';
  @Input() successMessage = 'Uploaded status will be sent to mail'
  isUploadInProgress: boolean = false;
  uploadClicked: boolean = false;
  files: NgxFileDropEntry[] = [];
  droppedFile: boolean;
  selectedFileName: string;
  enableUploadBtn: boolean;
  formData: FormData;
  showResponse: boolean;
  bulkResponseArr: any[];
  constructor(
    private toastService: ToastService,
    private bulkService: BulkActionsService
  ) { }

  ngOnInit(): void {
  }


  closePanel() {
    this.closeSidePanel.emit();
  }

  getFormData(event) {
    this.formData = event;
  }

  uploadBulk() {
    const payload = {
      file: this.formData,
      ...this.panelObj
    }
    this.isUploadInProgress = true;
    this.bulkService.uploadBulkActionFile(payload).subscribe({
      next: (res: any) => {
        this.isUploadInProgress = false;
        this.uploadClicked = true;
        this.bulkResponseArr = [];
        this.toastService.success(this.successMessage)
        this.closePanel();
      },
      error: (err) => {
        let errMessage: string = err && err.error && err.error['error-message'];
        this.toastService.error(errMessage);
        this.isUploadInProgress = false;
      }
    })
  }

}
