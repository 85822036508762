<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title side-panel-header-bg justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/entry_exit.svg" />
            <span class="black font-bold" *ngIf="newOrEdit === 'new' || newOrEdit === 'clone'">New Rule Set</span>
            <span class="black font-bold" *ngIf="newOrEdit === 'edit'">Update Rule Set</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content flex flex-col p-3 color16">
        <form [formGroup]="rulesetForm">
            <div class="mb-4">
                <div class="form-label font-12-bold">Rule Set Name<span class="required color21">*</span></div>
                <input type="text" class="basic-input w-100 m0 font-12-normal" placeholder="My Rule Set Name"
                    [disabled]="newOrEdit === 'edit'"
                    formControlName="ruleName">
            </div>

            <!-- organization -->
            <div class="mt-2">
                <div class="form-label font-12-bold">Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog></div>
                 <div class="relative">
                   <mat-select formControlName="rulesetOrgName" placeholder="----Select Organization----"
                   class="mat-select w-100 m0 font-12-normal" (selectionChange)="_fnGetSelectedOrg()" [value]="selectedOption">
                   <mat-option [value]="''" *ngIf="userType">----Select Organization----</mat-option>
                   <mat-option  *ngIf="!isOrgRestrictedUser" [value]="'ANY'" [textContent]="'(Any)'" ></mat-option>
                     <mat-option *ngFor="let org of orgList" [value]="org.id" [textContent]="org.name">
                     </mat-option>
                   </mat-select>
                   <mat-icon  *ngIf="(rulesetForm.value.rulesetOrgName || selectedOption) && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
                 </div>
           </div>
           <p *ngIf="rulesetForm.value.rulesetOrgName=='ANY'" class="orgcontent"><span>Warning</span>: Data will be seen by all users including those outside your org</p>

            <div class="mb-2 mt-4">
                <div class="font-14-bold color16">Add One or More Rules</div>
                <div class="selected-value">
                    <div class="flex justify-between mb-1" *ngFor="let rule of selectedRules">
                        <span class="font-12-normal" [title]="rule.title">{{rule.title | truncate:24}}</span><span class="remove-value"
                            (click)="removeSelected(rule, 'rules')"> <i
                                class="fa fa-solid fa-trash color4"></i></span>
                    </div>
                </div>
                <div class="font-12-bold color16">Available Rules</div>
                <div class="relative">
                    <div class="spin-pos top-0" *ngIf="rulesLoading"><i class="fa fa-circle-o-notch fa-pulse fa-1x color4"></i></div>
                    <input type="text" class="basic-input w-100 m0 font-12-normal" value="" placeholder="Select or Find a Rule"
                    (focus)="showRulesDropdown = true"
                    formControlName="ruleSearchTerm">
                    <span class="dropdown-marker bgcolor38 flex items-center justify-center">
                        <i class="fa fa-angle-down cursor-pointer font-18-bold" *ngIf="!showRulesDropdown" (click)="showRulesDropdown = !showRulesDropdown"></i>
                        <i class="fa fa-times cursor-pointer" *ngIf="showRulesDropdown" (click)="onSearchCancel('ruleSearchTerm')"></i>
                    </span>
                </div>
                <div class="multi-select-dropdown" *ngIf="showRulesDropdown">
                    <div class="item" *ngFor="let rule of availableRules; let i = index;">
                        <mat-checkbox #ruleCheckboxes (change)="toggleSelection($event, rule, 'rules', i)"
                            [id]="rule.id"
                            [checked]="rule.checked">
                            <label class="ml10" [title]="rule.title">{{rule.title | truncate: 24}}</label>
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="mb-2">
                <div class="font-14-bold color16">Apply To...</div>
                <div class="selected-value">
                    <div class="flex justify-between mb-1" *ngFor="let assetType of selectedAssetTypes">
                        <span class="font-12-normal" [title]="assetType.name">{{assetType.name | truncate: 24}}</span><span class="remove-value"
                            (click)="removeSelected(assetType, 'CATEGORY')"> <i
                                class="fa fa-solid fa-trash color4"></i></span>
                    </div>
                </div>
                <div class="font-12-bold color16">Asset Type(s)</div>
                <div class="relative">
                    <div class="spin-pos top-0" *ngIf="assetTypeLoading"><i class="fa fa-circle-o-notch fa-pulse fa-1x color4"></i></div>
                    <input type="text" class="basic-input w-100 m0 font-12-normal" value="" placeholder="Search or Select Asset Type"
                    (focus)="showAssetTypesDropdown = true"
                    formControlName="assetTypeSearchTerm">
                    <span class="dropdown-marker bgcolor38 flex items-center justify-center">
                        <i class="fa fa-angle-down cursor-pointer font-18-bold" *ngIf="!showAssetTypesDropdown" (click)="showAssetTypesDropdown = !showAssetTypesDropdown"></i>
                        <i class="fa fa-times cursor-pointer" *ngIf="showAssetTypesDropdown" (click)="onSearchCancel('assetTypeSearchTerm')"></i>
                    </span>
                </div>
                <div class="multi-select-dropdown" *ngIf="showAssetTypesDropdown">
                    <div class="item" *ngFor="let assetType of availableAssetTypes; let i = index;">
                        <mat-checkbox #assetTypeCheckboxes (change)="toggleSelection($event, assetType, 'assetTypes', i)"
                            [id]="assetType.id"
                            [checked]="assetType.checked">
                            <label class="ml10" [title]="assetType.name">{{assetType.name | truncate: 24}}</label>
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div class="mb-2">
                <div class="selected-value">
                    <div class="flex justify-between mb-1" *ngFor="let location of selectedLocations">
                        <span class="font-12-normal" [title]="location.name">{{location.name | truncate:24}}</span><span class="remove-value"
                            (click)="removeSelected(location, 'locations')"> <i
                                class="fa fa-solid fa-trash color4"></i></span>
                    </div>
                </div>
                <div class="font-12-bold color16">Location(s)</div>
                <div class="relative">
                    <div class="spin-pos top-0" *ngIf="locationLoading"><i class="fa fa-circle-o-notch fa-pulse fa-1x color4"></i></div>
                    <input type="text" class="basic-input w-100 m0 font-12-normal" value="" placeholder="Search or Select Location"
                    (focus)="showLocationsDropdown = true"
                    formControlName="locationSearchTerm">
                    <span class="dropdown-marker bgcolor38 flex items-center justify-center">
                        <i class="fa fa-angle-down cursor-pointer font-18-bold" *ngIf="!showLocationsDropdown" (click)="showLocationsDropdown = !showLocationsDropdown"></i>
                        <i class="fa fa-times cursor-pointer" *ngIf="showLocationsDropdown" (click)="onSearchCancel('locationSearchTerm')"></i>
                    </span>
                </div>
                <div class="multi-select-dropdown" *ngIf="showLocationsDropdown">
                    <div class="item" *ngFor="let location of availableLocations; let i = index;">
                        <mat-checkbox #locationCheckboxes (change)="toggleSelection($event, location, 'locations', i)"
                            [id]="location.id"
                            [checked]="location.checked">
                            <label class="ml10" [title]="location.name">{{location.name | truncate: 24}}</label>
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <!-- shipments -->
            <div class="mb-2">
                <div class="selected-value">
                    <div class="flex justify-between mb-1" *ngFor="let shipment of selectedShipments">
                        <span class="font-12-normal" [title]="shipment.name">{{shipment.name | truncate:24}}</span><span class="remove-value"
                            (click)="removeSelected(shipment, 'shipments')"> <i
                                class="fa fa-solid fa-trash color4"></i></span>
                    </div>
                </div>
                <div class="font-12-bold color16">Shipment(s)</div>
                <div class="relative">
                    <div class="spin-pos top-0" *ngIf="shipmentLoading"><i class="fa fa-circle-o-notch fa-pulse fa-1x color4"></i></div>
                    <input type="text" class="basic-input w-100 m0 font-12-normal" value="" placeholder="Search or Select Shipments"
                    (focus)="showShipmentsDropdown = true"
                    formControlName="shipmentSearchTerm">
                    <span class="dropdown-marker bgcolor38 flex items-center justify-center">
                        <i class="fa fa-angle-down cursor-pointer font-18-bold" *ngIf="!showShipmentsDropdown" (click)="showShipmentsDropdown = !showShipmentsDropdown"></i>
                        <i class="fa fa-times cursor-pointer" *ngIf="showShipmentsDropdown" (click)="onSearchCancel('shipmentSearchTerm')"></i>
                    </span>
                </div>
                <div class="multi-select-dropdown" *ngIf="showShipmentsDropdown">
                    <div class="item" *ngFor="let shipment of availableShipments; let i = index;">
                        <mat-checkbox #shipmentCheckboxes (change)="toggleSelection($event, shipment, 'shipments', i)"
                            [id]="shipment.id"
                            [checked]="shipment.checked">
                            <label class="ml10" [title]="shipment.name">{{shipment.name | truncate: 24}}</label>
                        </mat-checkbox>
                    </div>
                </div>
            </div><!-- shipments -->

        </form>
    </section>
    <section class="cl-side-panel-footer bgcolor33 pr-3 pl-3">
        <div class='flex justify-end items-center'>
            <a formnovalidate class="m-2" (click)="closePanel()">Cancel</a>
            <button *ngIf="newOrEdit === 'new'" class="panel-button white bgcolor4" (click)="createOrUpdate()">
                Save
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="saveInProgress"></i>
            </button>
            <button *ngIf="newOrEdit === 'edit'" class="panel-button white bgcolor4" (click)="createOrUpdate()" title="Save ruleset. Ruleset changes may take up to 1 hour to take effect">
                Save
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="saveInProgress"></i>
            </button>
        </div>
    </section>
</div>
