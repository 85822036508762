import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { VendorAccountService } from '../vendor-account.service';
import { CredentialsDialogComponent } from '../vendor-account-credentials/account-credentials-dialog.component';
import { UserService } from '@cl/user/user.service';
import { ToastService } from '@cl/common/services/toast.service';
import { PersistenceService } from '@cl/common/services/persistence.service';

@Component({
  selector: 'cl-vendor-account-list',
  templateUrl: './vendor-account-list.component.html',
  styleUrls: ['./vendor-account-list.component.scss']
})
export class VendorListComponent implements OnInit, AfterViewInit {
  @ViewChild('nameTemplate') nameTemplate: TemplateRef<any>;
  @ViewChild('table') table;

  public tableRowData: any = [];
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  private event = new Subject<any>();
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showSummaryPanel = false;
  mode = 'add';
  editDisabled = true;
  groupObject = {};
  selectedAccount = {};
  allUserObject = [];
  columnKeys: string[] = [];
  actionBtnPolicies = {
    add: ['VENDOR_CREDENTIALS_CREATE'],
    edit: ['VENDOR_CREDENTIALS_UPDATE'],
    delete: ['VENDOR_CREDENTIALS_UPDATE'],
    summary: ['VENDOR_CREDENTIALS_SUMMARY']
  };
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  searchText ='';
  mainWidth:number;
  tableWidth:number;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    public dialog: MatDialog,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private vendorAccountService: VendorAccountService,
    private userService: UserService,
    private toastService: ToastService,
    private persistenceStore:PersistenceService
  ) {
  }

  ngOnInit(): void {
   this.setContainerWidth();
    this.getVendorAccounts();
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.mode = 'view';
        this.showSummaryPanel = true;
        this.selectedAccount = evt.accountObject;
      }
    });
    this.subscriptions.push(eventSub);
  }

  ngAfterViewInit(): void {
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: false,
        checkboxable: true,
        width: 30,
        visible: true
      },
      { name: 'Name', prop: 'nickname', width: 180, cellTemplate: this.nameTemplate, visible: true },
      { name: 'Account Type', prop: 'accountType', width: 180, visible: true },
      { name: 'Vendor ID', prop: 'vendorId', width: 180, visible: true }
    ];
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('account', [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
    // this._changeDetectorRef.detectChanges();
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'account', this.table._internalColumns);   
  }

  getVendorAccounts(): void {
    this.isLoading = true;
    let groupData:any = [];
    const vendorDataSubscription: Subscription = this.vendorAccountService.getAccountList(this.searchText).subscribe((data:any)=>{
      data.forEach((d:any) => {
        groupData.push(d);
      });
      this.rows =[...groupData];
      this.tableRowData = [...this.rows];
      this.isLoading = false;

    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(vendorDataSubscription);
  }

  openDialog() {
    const dialogRef = this.dialog.open(CredentialsDialogComponent, {
      width: '600px',
      data: this.selectedAccount,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.delayedRefreshStart = true;
        this.resetSelection();
      }
    });
  }

  onSelect(rowData: any) {
    this.selected = [...rowData.selected];
    if(this.selected.length == 1 && (this.selected[0].tenantId == this.userService.getEffectiveTenantId() || this.isSuperAdmin())){
      this.editDisabled = false;
    }else{
      this.editDisabled = true;
    }
    this.selectedAccount = this.selected[0];
  }

  private isSuperAdmin() {
    return this.userService.getPolicies().includes('SCOPE_ALL');
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'account-list', _.map(this.columns, 'id'));
    }
  }
  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    this.tableWidth = this.mainWidth - 12;
  }
  updateFilter(event:any) {
    this.searchText = event.target.value.toLowerCase().trim();
    this.getVendorAccounts();

    // let temp;
    // const tableRowData = [...this.tableRowData];
    // // filter our data
    // if(val){
    //    temp = tableRowData.filter(function (d:any) {
    //     return (d.name.toLowerCase().indexOf(val) !== -1);
    //   });
    // }else{
    //   temp = tableRowData;
    // }
    // // update the rows
    // this.rows = temp;
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }


  loadSummaryPanel(accountObject:any, event:any){
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', accountObject: accountObject });
  }

  closeSidePanel(cbAction?){
    this.showSummaryPanel = false;
    this.resetSelection();
    if(cbAction && cbAction.action == 'reloadAccounts') {
      this.delayedRefreshStart = true;
    }
  }

  addVendorAccount(){
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  private resetSelection() {
    this.selected = [];
    this.editDisabled = true;
  }

  editVendorAccount(){
    this.mode = 'edit';
    this.showSummaryPanel = true;
    this.selectedAccount = this.selected[0];
  }

  deleteVendorAccount(){
    this.isLoading = true;
    let message = `Are you sure you want to delete this account?: `+this.selected[0].nickname;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
       let accountId = this.selected[0].id;
       const deleteVendorAccountSub:Subscription = this.vendorAccountService.deleteVendorAccount(accountId).subscribe(data=>{
          this.delayedRefreshStart = true;
          let action = 'Vendor account deleted successfully';
          this.toastService.success(action, this.selected[0].nickname);
          this.selected = [];
          this.isLoading = false;
          this.resetSelection();
        }, (error) => {
            let action = error?.error && error?.error['error-message'] ? error?.error['error-message'] : 'Something went wrong' ;
            this.toastService.error(action);
            this.isLoading = false;
          });

          this.subscriptions.push(deleteVendorAccountSub);
      }else{
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
