import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggedInUserType } from '../@types/logged-in-user.type';
import { environment } from '../../environments/environment';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Injectable({
  providedIn: 'root'
})
export class SensorFabricService {
  constructor(private http: HttpClient, private apiProvider: ApiProviderService) {
  }

  public variableMapping ={
    "type":"Type",
    "status":"Bind Status",
    "overallStatus" : "Device Health",
    "active" : "Bound",
    "ready" : "Unbound",
    "unmonitored" : "Unmonitored",
    "g" : "Online",
    "r" : "Offline",
    "y" : "Attention"
  }

  getNetTraffic(val: any): string {
    if (val === 'N/A' || !val) {
      return 'N/A';
    }

    if (val < 1000) {
      return val + 'B';
    } else if (val < 1000000) {
      return Math.round(val / 1000) + 'KB';
    } else {
      return Math.round(val / 1000000) + 'MB';
    }
  }

  postSensorList(payload:any) {
    if(!payload){
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`, payload);
  }


  postSesorListFilters(payload) {
    if(!payload){
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/aggregate?aggField=type,status,overallStatus&clfMappingType=sensor`, payload);
  }

  getRecievers(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/search/receivers`, payload);
  }

}
