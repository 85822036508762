import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { InputField, InputOption } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { SensorService } from '../../sensor.service';
import { ToastService } from '@cl/common/services/toast.service';
import * as _ from 'lodash';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { FormGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { BehaviorSubject, finalize } from 'rxjs';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { CatalogType } from '@cl/@types/extended-attributes.types';

@Component({
  selector: 'cl-device-add',
  templateUrl: './device-add.component.html',
  styleUrls: ['./device-add.component.scss'],
})
export class DeviceAddComponent implements OnInit, AfterViewInit {
  @Input() mode: any;
  @Input() classType: any;
  @Input() deviceObject: any;
  @Output() closeSidePanel = new EventEmitter();
  @ViewChild('formRenderer') formRenderer!: DynamicFormRendererComponent;
  @ViewChild('staticSelectRef', { static: true })
  staticSelectRef: TemplateRef<any>;

  @Input() deviceType: any;
  isEdit: boolean = false;

  form: FormGroup = new FormGroup({});

  deviceCatalog!: CatalogType;
  fields: any[] = [];
  inputFields: InputField[] = [];
  temporaryDropDownValues: any = {};
  submitted = false;

  staticOptions = {
    sensorType: [],
    accountId: [],
    eventProfileName: [],
  };

  defaultAvatar = 'assets/svgs/circle-icons-blue/category.svg';
  assetAvatar: any = this.defaultAvatar;
  deviceProps: any;
  isDetailsFetching: boolean = false;
  isCreatingDevice: boolean;
  accountListsPromise: any = '';
  eventProfilePromise: any = '';

  tabSelectedEvent = new BehaviorSubject<number>(0);
  bulkFormData: FormData;

  bulkConfig: BulkPanelModel = {
    fileType: 'CSV',
    appName: 'clfgraphapp',
    method: 'POST',
    templateUrl: '',
    uploadUrl: '', //Based upon the CatalogType selection it will change
    isDynamicFile: true,
  };
  downloadedCSVFields: string[] = [];
  uploadedAvatar: any;
  accountProfileOptn: boolean = false;
  assetId: any;
  status: any;
  catalogTypesList: CatalogType[] = [];
  catalogType: CatalogType;
  deviceCatalogType: any;
  isCatalogTypeError = false;
  withField:boolean = true;

  constructor(
    private _dynamicFormService: DynamicFormService,
    private sensorService: SensorService,
    private toastService: ToastService,
    private _extendedAttributeService: ExtendedCatalogAttributeService,
    private _dialog: MatDialog,
    private _bulkService: BulkActionsService
  ) {}

  ngOnInit(): void {
    this.tabSelectedEvent.subscribe((tab) => {
      this.submitted = false;
      if (tab == 1) {
        this.bulkConfig.uploadUrl = `api/2/bulkDataJob/${this.catalogType.objectType}/create/file`;
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.mode === 'add') {
      // this.changeCatalogType();
      this.getDeviceTypesList();
    } else if (!_.isEmpty(this.deviceObject) && this.mode === 'edit') {
      this.sensorService.getSensorInformation(this.deviceObject.id, this.deviceObject?.entityTypeKey).then((res:any)=>{
        this.deviceProps = res;
        this.assetAvatar = (res.properties.imageURL)?res.properties.imageURL: this.defaultAvatar; 
        this.assetId = (res.properties.assetId)?res.properties.assetId: '';
        this.status = res.properties.status;
        this.isEdit = true;
        this.getDeviceDetails();
      })
      
    }
  }

  async getDeviceDetails() {
    this.isDetailsFetching = true;
    await this.changeCatalogType(this.deviceProps.type);
    this.isDetailsFetching = false;
  }

  async changeCatalogType(objectType: string) {
    this.deviceCatalog =
      await this._extendedAttributeService.getCatalogExtendedColumns(
        objectType
      );

    if (
      this.isEdit &&
      this.deviceProps?.properties &&
      this.classType === 'Tracker'
    ) {
      await this.getAccountAndProfileList(
        this.deviceProps?.properties?.sensorType
      );
    }

    const fields = this._dynamicFormService.getRenderableFields(
      this.deviceCatalog.cdmFields
    );

    this.fields = this._dynamicFormService.orderFields([...fields], 'order');

    this.downloadedCSVFields = this.fields.map(field => {
      let label = field.displayLabel;
      if(field.required){
        label += '*'
      }else if(field.displayLabel.endsWith('*')){
        label += '*'
      }
      return `"${label}"`
    })
    
    const inputFields: InputField[] =
      await this._dynamicFormService.generateInputFields(
        this.fields,
        this.isEdit,
        this.deviceProps?.properties
      );
    inputFields.forEach((inputField) => {
      
      if (
        ['sensorType', 'accountId', 'eventProfileName'].includes(
          inputField.id
        ) &&
        inputField.group === 'System'
      ) {
        inputField.isDynamicField = false;
        if (
          this.isEdit &&
          ['accountId', 'eventProfileName'].includes(inputField.id)
        ) {
          inputField.value = this.staticOptions[inputField.id].find(
            (item) => item.key == inputField.value
          );
          inputField.options = this.staticOptions[inputField.id];
        }
        this.staticOptions[inputField.id] = inputField.options;
        inputField.template = this.staticSelectRef;
      }
    });

    this.form = this._dynamicFormService.createFormGroup(inputFields);
    this.inputFields = [...inputFields];
  }

  onMatInputBlur(fieldId: string) {
    setTimeout(() => {
      if (this.temporaryDropDownValues[fieldId] != undefined) {
        this.form
          .get(fieldId)
          ?.patchValue(this.temporaryDropDownValues[fieldId]);
        this.temporaryDropDownValues[fieldId] = undefined;
      }
    }, 250);
  }

  onMatOpen(field: InputField) {
    if (this.temporaryDropDownValues[field.id] == undefined) {
      if (field.isDynamicFetching) {
        field.options = [];
      }
      this.temporaryDropDownValues[field.id] = this.form.get(field.id)?.value;
      this.form.get(field.id)?.patchValue('');
    }
  }

  onMatClear(field: InputField) {
    if (field.dropdownConfig.isClearable) {
      this.form.get(field.id)?.patchValue('');
      this.temporaryDropDownValues[field.id] = undefined;
    }
  }

  onSelect(evt: InputOption, fieldId: string) {
    this.form.get(fieldId)?.patchValue(evt);
    this.temporaryDropDownValues[fieldId] = undefined;
    
    if (fieldId == 'sensorType' && this.classType == 'Tracker') {
      this.staticOptions.accountId = [];
      this.staticOptions.eventProfileName = [];
      this.form.get('accountId').patchValue(undefined);
      this.form.get('eventProfileName').patchValue(undefined);
      this.getAccountAndProfileList(evt.key);
    }
  }

  async getAccountAndProfileList(sensorType: string) {
    this.accountProfileOptn = true;
    const accountListsPromise =
      this.sensorService.getAccountNameList(sensorType);
    const eventProfilePromise =
      this.sensorService.getProfileNameList(sensorType);
    return Promise.all([accountListsPromise, eventProfilePromise]).then(
      (data) => {
        this.staticOptions.accountId = data[0].map((element) => {
          return { label: element.nickname, key: element.id };
        });
        this.staticOptions.eventProfileName = data[1].map((element) => {
          if(element.default === true){
            this.form?.get('eventProfileName')?.patchValue(element.eventProfileName);
          }
          return {
            label: element.eventProfileName,
            key: element.eventProfileName,
          };
        });
      }
    );
  }

  displayFn(item: any) {
    return item && item.label ? item.label : item;
  }

  onAvatarChange(event) { 
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      
      var allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg'];  
      if (allowedExtensions.indexOf(file.type) == -1) {
        this.toastService.error('Invalid file type');
          // this.assetAvatar = this.defaultAvatar;
          // this.uploadedAvatar = undefined;
      }else{
        const reader = new FileReader();
      reader.onload = (e) => {
        this.assetAvatar = reader.result;
      };
        this.uploadedAvatar = file;
        reader.readAsDataURL(file);
      }
      
    } else {
      this.assetAvatar = this.deviceCatalog?.imageURL;
      this.uploadedAvatar = undefined;
    }
  }

  get tabSelected() {
    return this.tabSelectedEvent.getValue();
  }

  onTabClick(tab: MatTabGroup) {
    if (tab.selectedIndex == this.tabSelected) return;
    const currentIndex = tab.selectedIndex;
    const form = this.formRenderer?.onSubmit();
    if (form?.touched || this.bulkFormData) {
      tab.selectedIndex = this.tabSelected;
      this._dialog
        .open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Discard',
            message: `Are you sure want discard   ${
              this.bulkFormData ? 'File' : this.classType
            } ?`,
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            tab.selectedIndex = currentIndex;
            this.tabSelectedEvent.next(currentIndex);
            return;
          }
          tab.selectedIndex = this.tabSelected;
        });
      return;
    }
    this.tabSelectedEvent.next(currentIndex);
  }

  closePanel(statusAction?: string) {
    this.closeSidePanel.emit(statusAction);
  }

  async onBulkUpload() {
    const payload: any = {
      file: this.bulkFormData,
      ...this.bulkConfig,
    };
    if (!payload.file) {
      this.toastService.info('select file to upload');
      return;
    }
    this.submitted = true;
    this._bulkService
      .uploadBulkActionFile(payload)
      .pipe(finalize(() => (this.submitted = false)))
      .subscribe({
        next: (res: any) => {
          this.closePanel();
          this.toastService.openCloseHandlerSnackBar(
            [`${this.classType} data is being processed and will load in the background. A notification will be sent when the data processing and loading is completed.`]
          ,'',['toast-success']);  
        },
      });
  }

  async onSave() {
    if (this.tabSelected === 1) {
      this.onBulkUpload();
      return;
    }
    this.submitted = true;
    const formData = this.formRenderer?.onSubmit();
    if (formData?.valid) {
      const dynamicFormPayload = this._dynamicFormService.getDynamicFormPayload(
        formData,
        this.inputFields
      );
      const payload = {
        ...dynamicFormPayload,
        imageURL: this.assetAvatar,
        type: formData.value?.sensorType?.key
          ? formData.value.sensorType.key
          : this.deviceCatalog.name,
        typeId: this.deviceCatalog.id,
      };
      
      this.isCreatingDevice = true;
      try {
        if (this.mode === 'add') {
          this.addDevice(payload)
        }
        if (this.mode === 'edit') {
          this.updateDevice(payload)
        }
        if (this.uploadedAvatar && this.deviceObject.id) {
          const formData = new FormData();
          formData.append('file', this.uploadedAvatar);
          await this.sensorService.uploadAvatar(formData, this.deviceObject.id, this.classType.toLowerCase());
          this.closePanel('updateSuccess');
          this.toastService.success(
            `${this.classType} ${this.isEdit ? 'updated' : 'created'} successfully`
          );
        }
      } catch (error) {
      }
      this.isCreatingDevice = false;
    }
  }

  private addDevice(payload) {
    // payload.classType = 'Sensor';
    this.sensorService
      .addDevice(this.classType.toLowerCase(), payload, this.catalogType.objectType)
      .subscribe(
        (data) => {
          this.closePanel('updateSuccess');
          let action = `${this.classType} created successfully`;
          this.toastService.success(action, payload.name);
        },
        (error) => {
          // this.isSaveInProgress = false;
          let action =
            error?.error && error?.error['error-message']
              ? error?.error['error-message']
              : 'Something went wrong';
          this.toastService.error(action);
        }
      );
  }

  private updateDevice(payload) {
    const updatePayload = {
      ...payload,
      assetId:this.assetId,
      status:this.status 
    }
    this.sensorService
      .updateDevice(updatePayload, this.deviceObject.id, this.classType.toLowerCase(), this.deviceObject.entityTypeKey)
      .subscribe(
        (data) => {
          this.closePanel('updateSuccess');
          let action = `${this.classType} updated successfully`;
          this.toastService.success(action, payload.name);
        },
        (error) => {
          // this.isSaveInProgress = false;
          let action =
            error?.error && error?.error['error-message']
              ? error?.error['error-message']
              : 'Something went wrong';
          this.toastService.error(action);
        }
      );
  }

  async getDeviceTypesList() {
    this.catalogTypesList =
      await this._extendedAttributeService.getCatalogExtendedDeviceColumns('device',this.withField,
        this.classType.toLowerCase()
      );
      this.catalogTypesList.sort(function (a,b) {
        return a.name.toLowerCase()>b.name.toLowerCase()?1:-1
    })
  }

  onNext() {
    if(!this.catalogType){
      this.isCatalogTypeError = true;
      return;
    }
    this.isCatalogTypeError = false;
    this.changeCatalogType(this.catalogType.objectType);
    this.tabSelectedEvent.next(0);
  }

  ngOnDestroy(): void {
    this.tabSelectedEvent.unsubscribe();
  }
}
