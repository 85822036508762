<div class="bg-block rounded-md mb-4">
  <div class="flex justify-between pt-1 pb-1">
    <div class="action-panel-left self-start flex items-center color4 grow">
      <div class="included-assets">{{'shipmentDetails.includedAssets'|translate}}</div>
      <button style="width: 90px" [disabled]="bindDisabled" [ngClass]="[
          bindDisabled
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]" [clIsValidAction]="actionBtnPolicies.bind" class="p-4-10 border-general br2 mr-2" (click)="bindAsset()">
        <i class="fa fa-link"></i>
        {{'shipmentDetails.bind'|translate}}
      </button>

      <button style="width: 90px" [disabled]="unBindDisabled" [ngClass]="[
          unBindDisabled
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]" [clIsValidAction]="actionBtnPolicies.unbind" class="p-4-10 border-general br2 mr-2"
        (click)="confirmDialog('Unbind')">
        <i class="fa" [ngClass]="bindIcon"></i>
        {{'shipmentDetails.unbind'|translate}}
      </button>

      <button 
         [clIsValidAction]="actionBtnPolicies.terminate"
         [disabled]="terminateDisabled" [ngClass]="[
          terminateDisabled
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]" class="p-4-10 border-general br2 mr-2" (click)="confirmDialog(terminateAction)">
        <i class="fa" [ngClass]="terminateIcon"></i>
        {{ terminateAction }}
      </button>
      <button *ngIf="showBtn" [clIsValidAction]="actionBtnPolicies.delete" [disabled]="deleteDisabled" [ngClass]="[
          deleteDisabled
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]" class="p-4-10 border-general br2 mr-2" (click)="deleteAsset()">
        <i class="fa" [ngClass]="'fa-trash'"></i>
        {{'common.delete'|translate}}
      </button>
      <button
        *ngIf="isMoveToIntransitShown"
        [clIsValidAction]="actionBtnPolicies.intransit"
        [disabled]="intransitDisabled"
        [ngClass]="[
          intransitDisabled
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]" class="p-4-10 border-general br2 mr-2" (click)="confirmDialog('Intransit')">
        <i class="fa" [ngClass]="'fa fa-flag-checkered'"></i>
        {{'shipmentDetails.moveTotransit'|translate}}
      </button>
    </div>
    <div class="pull-right">
      <span class="fa fa-th fs35" [ngClass]="[gridview ? 'color-6d' : 'color-4e']" (click)="gridFun()"></span><span
        class="bar mr-1 ml-1">|</span>
      <span class="fa fa-bars fs35" [ngClass]="[!gridview ? 'color-6d' : 'color-4e']" (click)="listFun()"></span>
    </div>
  </div>
  <br />
  <div *ngIf="!gridview">
    <ngx-datatable #myTable style="height: 200px" class="h-calc-40" [rows]="assetNodeDetails" [columns]="columns"
      [columnMode]="'force'" [scrollbarH]="true" [scrollbarV]="true" [headerHeight]="headerHeight"
      [rowHeight]="rowHeight" [sorts]="[{ prop: 'name', dir: 'desc' }]" [selectionType]="SelectionType.checkbox"
      (activate)="onActivate()" (select)="onSelect($event)" [selected]="multiSelectedAssets" [rowClass]="getRowClass"[reorderable]="false">

      <ngx-datatable-row-detail #myDetailRow [rowHeight]="DetailRowHeight" (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div style="
              padding-left: 60px;
            " class="summary-section-bg">
            <table>
              <th></th>
              <th></th>
              <th colspan="10">
                <table class="table table-condensed fs12">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Exception</th>
                      <th scope="col">Value</th>
                      <th scope="col">Time of Event</th>
                      <th>Alert Status</th>
                      <th>Severity</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody class="tbl-bold">
                    <tr *ngFor="let x of row?.tableViolation">
                      <td *ngIf="['Conditions','Predictive Metric','Carrier Milestone'].includes(x.violationTypeGroup)">
                        <span>
                          <img width="18" height="18" [src]="svgSrcFinder(x.canonicalPropertyName.toLowerCase())" />
                        </span>
                      </td>
                      <td *ngIf="x.violationTypeGroup == 'Time Delays'">
                        <img width="18" height="18" [src]="svgSrcFinder('Time')" />
                      </td>
                      <!-- <td *ngIf="x.violationTypeGroup == 'Predictive Metric'">
                        <span>
                          <img width="18" height="18" [src]="svgSrcFinder(x.canonicalPropertyName.toLowerCase())" />
                        </span>
                      </td> -->
                      <td>{{ x.violationType }}</td>
                      <td>
                        <ng-template #otherConditions>
                          <span>
                            {{ checkDecimal(x.propertyValue, x.violationTypeGroup)
                            }}{{ getMeasureUnits(x.propertyName, row?.capabilities) }}
                          </span>
                        </ng-template>
                        <span *ngIf="['Carrier Milestone'].includes(x.violationTypeGroup); else otherConditions">
                             {{x.propertyValue.message}}
                             <ul class="">
                              <li class="list-disc list-inside">Milestone Date/Time: {{x.propertyValue.checkpointTime | formateDate}}</li>
                              <li class="list-disc list-inside">Milestone Location: {{x.propertyValue.location}}</li>
                              <li class="list-disc list-inside">Milestone Info: {{x.propertyValue.tag}}-{{x.propertyValue.subtagMessage}}</li>
                             </ul>
                        </span>
                      </td>
                      <td>
                        {{ x.deviceTime | formateDate: "default" }}
                      </td>
                      <td>{{ x.alertStatus }}</td>
                      <td>{{ x.severity }}</td>
                      <td *ngIf="x.actionRequired">
                        <button
                          class="pl-3 pr-3 pt-1 pb-1 cursor-pointer bgcolor4 color2 rounded-start rounded-end ng-star-inserted"
                          (click)="openResolve(x, row.properties.taggedAssetId)">
                          <i class="fa fa-check-circle-o"></i>
                          <span> Resolve</span>
                        </button>
                      </td>
                      <td *ngIf="!x.actionRequired">{{ x.notes }}</td>
                    </tr>
                  </tbody>
                </table>
              </th>
            </table>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>
    </ngx-datatable>




  </div>
  <div *ngIf="gridview && !['parcelshipment', 'dtpshipment'].includes(shipmentType)">
    <div>
      <div class="row p-1 -mr-1.5 overflow-auto">
        <div class="col-2 rectangle m-1" *ngFor="let x of assetNodeDetails; let idx = index">
          <div class="flex items-center assetSticky">
            <mat-checkbox class="mat-checkbox" (change)="getBindActionData($event, x, idx)" [(ngModel)]="x.checked">
              <label class="mat-checkbox-layout" for="mat-checkbox-1-input"><span
                  class="mat-checkbox-label"></span></label>
            </mat-checkbox>
            <span class="shipping-crate-jl-01" *ngIf="x.properties.monitoredStatus == 'MONITORED' || x?.properties.statusCode == 'Completed'" [matTooltipPosition]="'above'" [matTooltip]="x.properties.name">
              <a  href="#/assetdetails/{{ x.properties.id }}?sid={{shipmentData.shipmentNode.id}}">{{ stringTruncate(x.properties.name, 15) }}</a>
            </span>
            <span class="shipping-crate-jl-01" [matTooltipPosition]="'above'" [matTooltip]="x.properties.name" *ngIf="x.properties.monitoredStatus != 'MONITORED' && x?.properties.statusCode != 'Completed'">{{stringTruncate(x.properties.name, 15)}}</span>
            <span (click)="popoverTimeDelayExcursion(x)" class="cursor-pointer"
              *ngIf="x.timeDelayCount != 0 && x.excursions != undefined">
              <img width="18" height="18" [src]="svgSrcFinder('Time')" />
            </span>
            <span (click)="popoverExcursion(x)" class="cursor-pointer ml-1" *ngIf="x.count > 1">
              <img width="18" height="18" [src]="svgSrcFinder('multi')" />
            </span>
            <span (click)="popoverExcursion(x)" class="cursor-pointer ml-1" *ngIf="x.count == 1">
              <span>
                <img width="18" height="18" [src]="
                    svgSrcFinder(
                      formatAlertSingle(x.violations | keyvalue).measure
                    )
                  " />
              </span>
            </span>

            <span style="cursor: pointer" (click)="statuspop = !statuspop" style="margin-right: 4px">
              <a><i class="fa fa-eye font-16 color4 ml-1" (click)="passAssetValue(x.id)"></i></a>
            </span>
          </div>
          <div class="popover__wrapper" *ngIf="x.id === currentMarkAsResolvedPop">
            <div class="popover__content" [ngClass]="status ? 'display-content' : ''">
              <div class="container__arrow container__arrow--tc"></div>
              <div class="pop">
                <div class="excultionScroll">
                  <div class="fs12 pb10" *ngFor="let z of ExcurstionsDetails">
                    <div *ngIf="['Conditions','Predictive Metric'].includes(z.violationTypeGroup)">
                      <div class="font-bold" style="color: #757575">
                        Conditional Excursion
                      </div>
                      <span>at {{ z.deviceTime | formateDate: "default" }}</span><br />
                      <span>{{ z.violationType }} :
                        {{ checkDecimal(z.propertyValue, z.violationType) }}
                        <span *ngIf="z.eventUnit">
                          {{
                          z.eventUnit == "C" || z.eventUnit == "F"
                          ? "°" + z.eventUnit
                          : " " + z.eventUnit
                          }}</span> </span><br />
                      <span class="mr-5" *ngIf="z.threshold.min || z.threshold.max != null">Threshold :
                        <span *ngIf="z.threshold.min != null">{{ z.threshold.min
                          }}{{
                          z.threshold.unit == "C" || z.threshold.unit == "F"
                          ? "°" + z.threshold.unit
                          : " " + z.threshold.unit
                          }}</span>
                        <span *ngIf="z.threshold.min && z.threshold.max != null">
                          -
                        </span>
                        <span *ngIf="z.threshold.max != null">{{ z.threshold.max
                          }}{{
                          z.threshold.unit == "C" || z.threshold.unit == "F"
                          ? "°" + z.threshold.unit
                          : " " + z.threshold.unit
                          }}</span>
                      </span>
                      <button *ngIf="z.actionRequired" (click)="openResolve(z, x.properties.taggedAssetId)"
                        class="pl-3 pr-3 pt-1 pb-1 cursor-pointer bgcolor4 color2 rounded-start rounded-end ng-star-inserted">
                        <i class="fa fa-check-circle-o"></i>
                        <span> Resolve</span>
                      </button>
                    </div>
                    <div *ngIf="['Carrier Milestone'].includes(z.violationTypeGroup)">
                      <div class="font-bold" style="color: #757575">
                        Carrier Exception
                      </div>
                      <span>at {{ z.deviceTime | formateDate: "default" }}</span><br />
                      <span>{{ z.violationType }} :{{z.propertyValue.message}}
                        <span>Milestone Date/Time: {{z.propertyValue.checkpointLocalTime | formateDate}}</span>
                        <span>Milestone Location : {{z.propertyValue.location}}</span>
                        <span>Milestone Info : {{z.propertyValue.tag}}-{{z.propertyValue.subtagMessage}}</span>
                   </span><br />
                      <button *ngIf="z.actionRequired" (click)="openResolve(z, x.properties.taggedAssetId)"
                        class="pl-3 pr-3 pt-1 pb-1 cursor-pointer bgcolor4 color2 rounded-start rounded-end ng-star-inserted">
                        <i class="fa fa-check-circle-o"></i>
                        <span> Resolve</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="popover__wrapper" *ngIf="x.tenantId === timeMarkAsResolvedPop">
            <div  [ngClass]="[status2 ?  'display-content_time' : '',!singleExcersion ? 'popover__content_time':'popover__content']">
              <div class="container__arrow container__arrow--tc"></div>
              <div class="pop">
                <div class="excultionScroll">
                  <div class="fs12 pb10" *ngFor="let z of timeDelayDetails | keyvalue">
                    <div *ngIf="z.value[0].violationTypeGroup == 'Time Delays'">
                      <div class="font-bold" style="color: #757575">
                        Conditional Excursion
                      </div>
                      <span>at
                        {{
                        z.value[0].deviceTime | formateDate: "default"
                        }}</span><br />
                      <span>{{ z.value[0].violationType }} :
                        {{
                        checkDecimal(
                        z.value[0].propertyValue,
                        z.value[0].violationType
                        )
                        }}</span><br />
                      <button *ngIf="z.value[0].actionRequired" (click)="
                          openResolve(z.value[0], x.properties.taggedAssetId)
                        "
                        class="pl-3 pr-3 pt-1 pb-1 cursor-pointer bgcolor4 color2 rounded-start rounded-end ng-star-inserted">
                        <i class="fa fa-check-circle-o"></i>
                        <span> Resolve</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex ml-1" *ngFor="let cap of x.capabilities">
           <cl-alert-icon [alertIconObject]="formatAlertObjectNew(cap)"></cl-alert-icon>
            <span class="temperature-humidity" [matTooltipPosition]="'right'" [matTooltip]="cap.measureLabel">{{
              stringTruncate(cap.measureLabel, 15)}}</span>
            <span class="temp">{{
              cap.value
              }} {{cap.uom}}</span>
          </div>
          <div *ngIf="x.capabilities != undefined && x.properties.feedDetails.remaningTripTime != undefined" class="flex ml-1">
            <cl-alert-icon [alertIconObject]="
                formatAlertObject(
                  'remaningtriptime',
                  false
                )
              "></cl-alert-icon>
            <span class="temperature-humidity" [matTooltipPosition]="'right'" [matTooltip]="'Remaining Trip Time'">{{
                stringTruncate("Remaining Trip Time", 10).trim() }}</span>
            <span class="temp" [matTooltipPosition]="'right'" [matTooltip]="x.properties.feedDetails.remaningTripTime">{{  stringTruncate(x.properties.feedDetails.remaningTripTime, 12).trim()}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="gridview && ['parcelshipment', 'dtpshipment'].includes(shipmentType)" class="horizontal">
    <div>
      <div class="row p-1 tracking-number-1-zxx" *ngIf="['parcelshipment', 'dtpshipment'].includes(shipmentType)">
        <div *ngFor="let asset of assetDetails" class="mb-2">
          <div style="margin-left: 5px">
            Tracking ID
            <span class="font-bold">{{ asset.trackingNumber}}</span>
          </div>
          <div class="inline-block" *ngFor="let w of asset.data">
          <div class="col-2 rectangle relative overflow-visible m-1 mt-3" *ngFor="let x of w.assetNodeDetails; let idx = index">
            <div class="overflow-scroll h-36">
            <div class="flex items-center assetSticky">
              <mat-checkbox class="mat-checkbox" [(ngModel)]="x.checked" (change)="getBindActionData($event, x, idx)">
                <label class="mat-checkbox-layout" for="mat-checkbox-1-input"><span
                    class="mat-checkbox-label"></span></label>
              </mat-checkbox>
              <span class="shipping-crate-jl-01" *ngIf="x.properties.monitoredStatus == 'MONITORED' || x?.properties.statusCode == 'Completed'" [matTooltipPosition]="'above'" [matTooltip]="x.properties.name">
                <a href="#/assetdetails/{{ x.properties.id }}?sid={{shipmentData.shipmentNode.id}}">{{ stringTruncate(x.properties.name, 15) }}</a>
              </span>
              <span *ngIf="x.properties.monitoredStatus != 'MONITORED' && x?.properties.statusCode != 'Completed'" class="shipping-crate-jl-01" [matTooltipPosition]="'above'" [matTooltip]="x.properties.name">{{stringTruncate(x.properties.name, 15)}}</span>
              <span (click)="popoverTimeDelayExcursion(x)" class="cursor-pointer"
                *ngIf="x.timeDelayCount != 0 && x.excursions != undefined">
                <span>
                  <img width="18" height="18" [src]="svgSrcFinder('Time')" />
                </span>
              </span>
              <span (click)="popoverExcursion(x)" class="cursor-pointer ml-1" *ngIf="x.count > 1">
                <img width="18" height="18" [src]="svgSrcFinder('multi')" />
              </span>
              <span (click)="popoverExcursion(x)" class="cursor-pointer" *ngIf="x.count == 1">
                <span>
                  <img width="18" height="18" [src]="
                      svgSrcFinder(
                        formatAlertSingle(x.violations | keyvalue).measure
                      )
                    " />
                </span>
              </span>

              <span style="cursor: pointer" (click)="statuspop = !statuspop" style="margin-right: 4px">
                <a><i class="fa fa-eye font-16 color-4e ml-1" (click)="passAssetValue(x.id)" col-2 rectangle m-1
                    ng-star-inserted></i></a>
              </span>
            </div>
            <div class="popover__wrapper" *ngIf="x.id === currentMarkAsResolvedPop">
              <div class="popover__content" [ngClass]="status ? 'display-content' : ''">
                <div class="container__arrow container__arrow--tc"></div>
                <div class="pop">
                  <div class="excultionScroll">
                    <div class="fs12 pb10" *ngFor="let z of ExcurstionsDetails">
                      <div *ngIf="['Conditions','Predictive Metric'].includes(z.violationTypeGroup)">
                        <div class="font-bold" style="color: #757575">
                          Conditional Excursion
                        </div>
                        <span>at {{ z.deviceTime | formateDate: "default" }}</span><br />
                        <span>{{ z.violationType }} :
                          {{ checkDecimal(z.propertyValue, z.violationType)
                          }}{{
                          z.eventUnit == "C" || z.eventUnit == "F"
                          ? "°" + z.eventUnit
                          : " " + z.eventUnit
                          }}</span><br />
                        <button *ngIf="z.actionRequired" (click)="openResolve(z, x.properties.taggedAssetId)"
                          class="pl-3 pr-3 pt-1 pb-1 cursor-pointer bgcolor4 color2 rounded-start rounded-end ng-star-inserted">
                          <i class="fa fa-check-circle-o"></i>
                          <span>Resolve</span>
                        </button>
                      </div>
                      <div *ngIf="['Carrier Milestone'].includes(z.violationTypeGroup)">
                        <div class="font-bold" style="color: #757575">
                          Carrier Exception
                        </div>
                        <span>at {{ z.deviceTime | formateDate: "default" }}</span><br />
                        <span>{{ z.violationType }} :{{z.propertyValue.message}}<br />
                          <span>Milestone Date/Time: {{z.propertyValue.checkpointLocalTime | formateDate}}</span><br />
                          <span>Milestone Location : {{z.propertyValue.location}}</span><br />
                          <span>Milestone Info : {{z.propertyValue.tag}}-{{z.propertyValue.subtagMessage}}</span><br />
                     </span><br />
                        <button *ngIf="z.actionRequired" (click)="openResolve(z, x.properties.taggedAssetId)"
                          class="pl-3 pr-3 pt-1 pb-1 cursor-pointer bgcolor4 color2 rounded-start rounded-end ng-star-inserted">
                          <i class="fa fa-check-circle-o"></i>
                          <span>Resolve</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="popover__wrapper" *ngIf="x.tenantId === timeMarkAsResolvedPop">
              <div [ngClass]="[status2 ?  'display-content_time' : '',!singleExcersion ? 'popover__content_time':'popover__content']">
                <div class="container__arrow container__arrow--tc"></div>
                <div class="pop">
                  <div class="excultionScroll">
                    <div class="fs12 pb10" *ngFor="let z of timeDelayDetails | keyvalue">
                      <div *ngIf="z.value[0].violationTypeGroup == 'Time Delays'">
                        <div class="font-bold" style="color: #757575">
                          Conditional Excursion
                        </div>
                        <span>at
                          {{
                          z.value[0].deviceTime | formateDate: "default"
                          }}</span><br />
                        <span>{{ z.value[0].violationType }} :
                          {{
                          checkDecimal(
                          z.value[0].propertyValue,
                          z.value[0].violationType
                          )
                          }}</span><br />
                        <button *ngIf="z.value[0].actionRequired" (click)="
                            openResolve(z.value[0], x.properties.taggedAssetId)
                          "
                          class="pl-3 pr-3 pt-1 pb-1 cursor-pointer bgcolor4 color2 rounded-start rounded-end ng-star-inserted">
                          <i class="fa fa-check-circle-o"></i>
                          <span> Resolve</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex ml-1" *ngFor="let cap of x.capabilities">
              <cl-alert-icon [alertIconObject]="formatAlertObjectNew(cap)"></cl-alert-icon>
               <span class="temperature-humidity" [matTooltipPosition]="'right'" [matTooltip]="cap.measureLabel">{{
                 stringTruncate(cap.measureLabel, 15)}}</span>
               <span class="temp">{{
                 cap.value
                 }} {{cap.uom}}</span>
             </div>
             <div *ngIf="x.capabilities != undefined && x.properties.feedDetails.remaningTripTime != undefined" class="flex ml-1">
              <cl-alert-icon [alertIconObject]="
                  formatAlertObject(
                    'remaningtriptime',
                    false
                  )
                "></cl-alert-icon>
              <span class="temperature-humidity" [matTooltipPosition]="'right'" [matTooltip]="'Remaining Trip Time'">{{
                  stringTruncate("Remaining Trip Time", 10).trim() }}</span>
              <span class="temp" [matTooltipPosition]="'right'" [matTooltip]="x.properties.feedDetails.remaningTripTime">{{  stringTruncate(x.properties.feedDetails.remaningTripTime, 12).trim()}}</span>
            </div>
          </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="sidePanelsObj.bind">
  <app-asset-binder [assetObject]="selectedAsset" (closeSidePanel)="closeSidePanel()"></app-asset-binder>
</div>

<ng-template #assetNameTemplate let-row="row" let-value="value" let-i="index" let-expanded="expanded" let-rowIndex="rowIndex">
  <div class="flex items-center">
    <div class="mr-2">
      <div class="w-[18px]" *ngIf="
          row?.count > 1 ||
          row?.timeDelayCount > 1 ||
          (row?.count == 1 && row?.timeDelayCount == 1)
        ">
        <img width="18" height="18" [src]="svgSrcFinder('multi')" />
      </div>
      <div class="w-[18px]" *ngIf="row?.count == 1 && row?.timeDelayCount == 0">
        <img width="18" height="18" [src]="
            svgSrcFinder(
              formatAlertSingle(row?.violations | keyvalue).measure
            )
          " />
      </div>
      <div class="w-[18px]" *ngIf="row?.timeDelayCount == 1 && row?.count == 0">
        <img width="18" height="18" [src]="svgSrcFinder('Time')" />
      </div>
      <div class="w-[18px]" *ngIf="row?.timeDelayCount == 0 && row?.count == 0">
        <i class="fa fa-check-circle color-lightgreen foz22"></i>
      </div>
    </div>
    <div class="font-12-bold color4 truncate">
      <ng-container *ngIf="row?.properties.monitoredStatus == 'MONITORED' || row?.properties.statusCode == 'Completed'">
        <a href="#/assetdetails/{{ row?.properties.id }}?sid={{shipmentData.shipmentNode.id }}" class="truncate block">{{ value}}</a>
     </ng-container>
     <ng-container *ngIf="row?.properties.monitoredStatus != 'MONITORED' && row?.properties.statusCode != 'Completed'">
      {{ value}}
    </ng-container>
    </div>
    <div class="ml-auto" *ngIf="(row?.excursions | json) != ({} | json)"
      (click)="toggleExpandRow(row, rowIndex)">
      <i *ngIf="(row?.excursions | json) != (undefined | json)" [ngClass]="
          expanded
            ? 'fa fa-chevron-down float-right'
            : 'fa fa-chevron-right float-right'
        " style="margin-top: 5px"></i>
    </div>
  </div>
</ng-template>


<ng-template #assetBatteryTemplate let-row="row" let-value="value" let-i="index" let-expanded="expanded" let-rowIndex="rowIndex">
  <span *ngIf="row?.properties.feedDetails">
    <span class="cell" *ngIf="
        row?.properties.monitoredStatus === 'MONITORED' &&
        row?.properties.monitoredStatus !== 'Completed'
      ">&nbsp;
      <span *ngIf="row?.properties.feedDetails.battery">{{ value}} %</span>
    </span>
    <span *ngIf="
        row?.properties.monitoredStatus !== 'MONITORED' ||
        row?.properties.monitoredStatus === 'Completed'
      ">&nbsp; N/A</span>
  </span>
</ng-template>

<ng-template #assetLastUpdatedTemplate let-row="row" let-value="value" let-i="index" let-expanded="expanded" let-rowIndex="rowIndex">
  {{ value | formateDate: "default" }}
</ng-template>

<ng-template #etaArrivalProp let-row="row" let-value="value" let-i="index" let-expanded="expanded" let-rowIndex="rowIndex">
  {{ value | formateDate: "default" }}
</ng-template>

<ng-template #residualTime let-row="row" let-value="value" ngx-datatable-cell-template>
  <span class="cell" *ngIf="row?.properties.feedDetails">{{ residualThermalTimeConversion(row?.properties.feedDetails.residualthermaltime)  }}
    </span>
</ng-template>

<ng-template #statusCumSubStatus let-row="row" let-value="value" let-i="index" let-expanded="expanded" let-rowIndex="rowIndex">
  {{ row.properties?.statusCode }} ({{row.properties?.subStatusCode}})
</ng-template>
