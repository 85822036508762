import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { UserService } from '../user.service';

@Component({
  selector: 'cl-sftp-user',
  templateUrl: './sftp-user.component.html',
  styleUrls: ['./sftp-user.component.scss']
})
export class SftpUserComponent implements OnInit, AfterViewInit {
  @ViewChild('sftpUserDateTemplate', { static: true }) sftpUserDateTemplate: TemplateRef<any>;
  public tableRowData: any = [];
  public rows: any = [];
  public row: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.single;
  public sortType: SortType = SortType.multi;
  showSummaryPanel = false;
  mode = 'add';
  editDisabled = true;
  groupObject = {};
  allUserObject = [];
  columnKeys: string[] = [];
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  mainWidth:number;
  tableWidth:number;
  public showToggleContainer: boolean = false;
  userName: any;
  userId:any;
  userCreated:boolean = false;
  status: boolean = true;
  createdUserId:any;
  password:any
  isSaveInProgress: boolean = false;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private uerService: UserService
  ) {
  }

  ngOnInit(): void {
    this.getSFTPUsers();
  }

  ngAfterViewInit(): void {
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: false,
        checkboxable: true,
        width: 30,
        visible: true,
      },
      { name: 'User ID', prop: 'accessId', width: 180, visible: true },
      { name: 'Name', prop: 'name', width: 180, visible: true },
      { name: 'Created Date', prop: 'createdAt', cellTemplate: this.sftpUserDateTemplate, width: 180, visible: true }
    ];
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('tenant', [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
    // this._changeDetectorRef.detectChanges();
    this.setContainerWidth();
  }

  getSFTPUsers(){
    this.isLoading = true;
    let groupData:any = [];
    this.uerService.getAllSFTPUsers().subscribe((data:any)=>{
      data.forEach((d:any) => {
        groupData.push(d);
      });
      this.rows =[...groupData];
      this.tableRowData = [...this.rows];
      this.isLoading = false;

    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    this.tableWidth = this.mainWidth - 12;
  }

  addSFTPUser(){
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  deleteUser(){
    this.isLoading = true;
    let message = `Are you sure you want to delete selected SFTP User?: `+this.selected[0].name;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if(dialogResult){
        this.uerService.deleteSftpUser(this.userId).subscribe(res =>{
          let action = 'User deteled successfully';
            this.openSnackBar(action, '');
            this.editDisabled = true;
          this.getSFTPUsers();
        })
      }
    })
    
  }

  onSelect(rowData: any) {
    this.selected = [...rowData.selected];
    this.userId = this.selected[0].accessId
    if(this.selected.length == 1){
      this.editDisabled = false;
    }else{
      this.editDisabled = true;
    }
    this.groupObject = this.selected[0];
  }

  closeSidePanel(cbAction?){
    this.showSummaryPanel = false;
    this.selected = [];
    this.editDisabled = true;
    if(cbAction && cbAction.action == 'reloadUsers') {
      this.delayedRefreshStart = true;
    }
  }

  toggleContainer() {
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  createSftpUser(){
    if(this.userName){
      this.isLoading = true;
      let payload = {
        "tenantId": this.uerService.getEffectiveTenantId(),
        "name": this.userName
      }
      this.uerService.addSFTPUser(payload).subscribe((res:any) =>{
        this.row.push(res);
        this.createdUserId = res.accessId;
        this.password = res.password;
        this.closeSidePanel(true);
        this.isLoading = false;
        this.userCreated = true;
        this.userName= '';
      })
    }
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  download(){
    let column = [{ name: 'SFTP User Name', prop: 'name', width: 180, visible: true },
    { name: 'SFTP User ID', prop: 'accessId', width: 180, visible: true },
    { name: 'Password', prop: 'password', width: 180, visible: true }]
    const csvData = this._utilsService.getSelectedElementsFromArray(this.row, _.map(column, 'prop'));
    this.csvExport.formatAndDownloadCSVForGrid(csvData, 'sftp-user-detail', _.map(column, 'name'));
  }

  closemodel() {
    this.userCreated = !this.userCreated;
    this.getSFTPUsers();
  }
}
