import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const bounceIn = trigger("enterExit", [
  state(
    "orig",
    style({
      transform: "translateY(-10px)",
    })
  ),
  state(
    "dropIn",
    style({
      transform: "translateY(0)",
    })
  ),
  transition("* => *", animate("500ms bounce")),
]);

export const listAnimation = trigger("listAnimation", [
  transition("* => *", [
    // each time the binding value changes
    // query(':leave', [
    //   stagger(30, [
    //     animate('0.05s', style({ opacity: 0, transform: 'translateX(-20px) translateY(0px)' }))
    //   ])
    // ], { optional: true }),
    query(
      ":enter",
      [
        style({ opacity: 0, transform: "translateX(-20px) translateY(0px)" }),
        stagger(30, [
          animate(
            "0.05s",
            style({ opacity: 1, transform: "translateX(0) translateY(0px)" })
          ),
        ]),
      ],
      { optional: true }
    ),
  ]),
]);

export const floatButtonRotate = trigger("floatButtonRotate", [
  state("default", style({ transform: "rotate(0)" })),
  state("active", style({ transform: "rotate(-45deg)" })),
  transition("active => default", animate("100ms ease-out")),
  transition("default => active", animate("100ms ease-in")),
]);
