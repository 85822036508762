import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { ApiSubscriptionService } from '../api-subscription.service';
import { ToastService } from "@cl/common/services/toast.service";
import { HelpDialogComponent, helpDialogModel } from '@cl/common/components/help-dialog/help-dialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'cl-add-api-subscription',
  templateUrl: './add-api-subscription.component.html',
  styleUrls: ['./add-api-subscription.component.scss']
})
export class AddApiSubscriptionComponent implements OnInit {
  @Input() groupObject: any;
  @Input() mode: any;
  @Output() closeSidePanel = new EventEmitter<any>();
  @Input() subscriptionObject: any;
  @Input() column = [];
  helpTextCallBackUrl = 'ParkourSC validates the callback URL. Upon the creation of the subscription, a test message is posted to the provided URL. If a successful 2XX status response is not returned, the API subscription cannot be saved.';
  helpTextSubscriberType = 'Entity data is posted to registered callback URL for selected events (i.e. Create, Update or Delete) for the API subscription.';
  helpTextWebhookSecretKey = 'ParkoursSC uses the HMAC approach for source client verification using the webhook secret. ParkourSC uses the webhook secret to generate the HMAC hash signature (using sha-256), which is included as a parameter in the HTTP request header when posting data. Upon creation of the API subscription, the system generates the webhook secret and assigns it to the tenant. If you wish to provide your own webhook secret, this can be updated via the API Subscription screen.';
  isLoading = false;
  isSaveInProgress: boolean = false;
  selectedOption: any = '';
  subscriberType: any;
  apiType: string = 'labels';
  apiWebhook: string = 'macid';
  webhookSecretKey: string = '';
  selectedentityOption: any = '';
  updatedEntities: any;
  enableEntityEvent: boolean = false;
  submitted: boolean = false;
  enableKeyvalSelection: boolean = false;
  enableEntitySelection: boolean = false;
  updatesecretKey: boolean = false;
  togglers = {
    subscriberTypeField: false,
    customHeadersField: false
  };
  entityEvents = [];
  APIevents = ['CREATE', 'UPDATE', 'DELETE'];
  constructor(private form: FormBuilder, 
    private apiSubscriptionService: ApiSubscriptionService,
    private toaster: ToastService,
    private dialog: MatDialog) { }

  apiSubscriberForm = this.form.group({
    subscriberName: ['', Validators.required],
    callbackURL: ['', Validators.required],
    subscriberType: ['', Validators.required],
    customHeaders: this.form.array([]),
    selectedEntities: this.form.array([]),
    webhooksecret: [''],
  });
  ngOnInit(): void {
    if (!_.isEmpty(this.subscriptionObject) && this.mode === 'edit'){
      const entities = {};
      this.apiSubscriptionService.getApiSubscriptionData(this.subscriptionObject?.id).then((res:any)=>{
        this.apiSubscriberForm.controls["subscriberName"].setValue(res?.subscriptionName);
        this.apiSubscriberForm.controls["callbackURL"].setValue(res?.registeredUrls[0]);
        this.apiSubscriberForm.controls["subscriberType"].setValue(res?.webhookType);
        if(res?.macId === null){
          this.getWebhookSecretKey(this.apiWebhook);
        }else{
          this.apiSubscriberForm.controls["webhooksecret"].setValue(res?.macId);
          this.webhookSecretKey = res?.macId;
        }

        if(res?.customHeaders){
          var customheaderData = Object.entries(res?.customHeaders).map((item) => 
          Object.assign({ key: item[0], value: item[1] },{})
          );
          this.setCustomHeaders(customheaderData || []);
          this.togglers.customHeadersField = true;
          this.enableKeyvalSelection = true;
        }
        if(res?.registeredEvents){
          this.enableEntityEvent = true;
          this.enableEntitySelection = true;
          this.togglers.subscriberTypeField = true;
          let entityArray = [];
          res?.registeredEvents.forEach((d: string) => {
            let entity = '';
            let action = '';
            if (d.includes('_CREATE')) {
              entity = d.split('_CREATE')[0];
              action = 'CREATE';
            }
      
            if (d.includes('_UPDATE')) {
              entity = d.split('_UPDATE')[0];
              action = 'UPDATE';
            }
      
            if (d.includes('_DELETE')) {
              entity = d.split('_DELETE')[0];
              action = 'DELETE';
            }
      
            if (entities[entity]) {
              entities[entity].push(action);
            } else {
              entities[entity] = [action];
            }
          });
      
          this.entityEvents = Object.entries(entities);
          
          let events = {}
          let absentevents = {}
          this.entityEvents.forEach(element=>{
            let entity = {'entity':element[0]}
            var absent = this.APIevents.filter(e=>!element[1].includes(e));
            if(absent){
              for(let i=0; i<absent.length; i++){
                absentevents = Object.assign(entity, { [absent[i]]: false });
              }
              if(!_.isEmpty(absentevents)){
                entityArray.push(absentevents);
              }
            }
            for(let i=0; i<element[1].length; i++){
              events = Object.assign(entity, { [element[1][i]]: true });
            }
            entityArray.push(events);
          })
          
          const dataArrWithSet = new Set(entityArray);
          const resultArr = [...dataArrWithSet];
          this.setselectedEntities(resultArr);
        }
      })
      // this.apiSubscriberForm.patchValue(this.subscriptionObject);
    }
    if(this.mode != 'edit'){
      this.getWebhookSecretKey(this.apiWebhook);
    }
    this.getSubscriberType(this.apiType);
  }

  closePanel(reload?) {
    if (reload) {
      this.closeSidePanel.emit({ action: 'apisubscriptions' });
    } else {
      this.closeSidePanel.emit(null);
    }
  }

  submitApiSubscriber(){
    this.submitted = true;
    if(this.apiSubscriberForm.valid){
      this.submitted = false;
    const subscriberName = this.apiSubscriberForm.value.subscriberName;
    const calbackurl = this.apiSubscriberForm.value.callbackURL;
    const subscriberType = this.apiSubscriberForm.value.subscriberType;
    const customHeaders = this.apiSubscriberForm.value.customHeaders;
    const webhooksecret = this.apiSubscriberForm.value.webhooksecret
    const registredEntities = this.apiSubscriberForm.value.selectedEntities;

    let itemArray = [];
    let mapedEntities = registredEntities.map(item=>{
      if(item.CREATE == true){
        itemArray.push(item.entity+'_CREATE');
      }
      if(item.UPDATE == true){
        itemArray.push(item.entity+'_UPDATE');
      }
      if(item.DELETE == true){
        itemArray.push(item.entity+'_DELETE');
      } 
       return itemArray 
    })

    let mapedCustomHeader = customHeaders.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

    const payload = {
      "subscriptionName":subscriberName,
      "registeredUrls":[calbackurl],
      "webhookType":subscriberType,
      "registeredEvents":mapedEntities ? mapedEntities[0]: [],
      "customHeaders":mapedCustomHeader,
      "macId": webhooksecret? webhooksecret : null,
      "status":"Active",
    }
    this.isSaveInProgress = true;
    if(this.mode == 'add'){
      this.apiSubscriptionService.createSubscriber(payload).subscribe(res=>{
        this.toaster.success(`Subscription created successfully`);
        this.isSaveInProgress = false;
        this.closePanel(true);
       },(error)=>{
        let action =
          error?.error && error?.error['error-message']
            ? error?.error['error-message']
            : 'Something went wrong';
        this.toaster.error(action);
        this.isSaveInProgress = false;
       })
    }
    if(this.mode == 'edit'){
      const Editpayload = {
        "id":this.subscriptionObject.id,
        "tenantId":this.subscriptionObject.tenantId,
        "version":this.subscriptionObject.version
      }
      let updateData = Object.assign(payload, Editpayload);
      this.apiSubscriptionService.updateSubscriber(updateData, this.subscriptionObject.id).subscribe(res=>{
        this.toaster.success(`Subscription updated successfully`);
        this.isSaveInProgress = false;
        this.closePanel(true);
      },
      (error) => {
        // this.isSaveInProgress = false;
        let action =
          error?.error && error?.error['error-message']
            ? error?.error['error-message']
            : 'Something went wrong';
        this.toaster.error(action);
        this.isSaveInProgress = false;
      })
    }
    }else{
      this.toaster.error(`Please fill require fields`);
    }
  }

  getSubscriberType(val){
    this.apiSubscriptionService.getSubscriberType(val).then(res=>{
      this.subscriberType = res.webhookTypes;
      this.updatedEntities = res.cdmTypes;
    })
  }

  getWebhookSecretKey(val){
    this.apiSubscriptionService.getSubscriberType(val).then(res=>{
      this.webhookSecretKey = res.macId;
      this.apiSubscriberForm.controls["webhooksecret"].setValue(res.macId);
    })
  }

  customHeaders() : FormArray {  
    return this.apiSubscriberForm.get("customHeaders") as FormArray  
  } 

  selectedEntities() : FormArray {  
    return this.apiSubscriberForm.get("selectedEntities") as FormArray  
  } 

  newCustomHeader(): FormGroup {  
    return this.form.group({  
      key: '',  
      value: '',  
    })  
  }

  newSelectedEntities(): FormGroup {  
    return this.form.group({  
      entity: '',  
      CREATE: '', 
      UPDATE: '',
      DELETE: '',
    })  
  }

  addCustomHeader() {  
    this.enableKeyvalSelection = true;
    this.customHeaders().push(this.newCustomHeader());  
  } 

  removeCustomHeader(i:number) { 
    if(this.customHeaders().length == 1){
      this.enableKeyvalSelection = false;
    } 
    this.customHeaders().removeAt(i);  
  }

  removeAllCustomHeader(){
    while (this.customHeaders().length !== 0) {
      this.customHeaders().removeAt(0);
    } 
    this.enableKeyvalSelection = false;
  }

  removeAllEntity(){
    while (this.selectedEntities().length !== 0) {
      this.selectedEntities().removeAt(0);
    } 
    this.enableEntitySelection = false;
  }

  addSelectedEntity(){
    this.enableEntitySelection = true;
    this.selectedEntities().push(this.newSelectedEntities());
  }

  removeselectedEntity(i:number){
    this.selectedEntities().removeAt(i);
    if(this.selectedEntities().length == 0){
      this.enableEntitySelection = false;
    }
  }

  checkType(event:any){
    if(event == 'ENTITY'){
      this.enableEntityEvent = true;
    }else{
      this.enableEntityEvent = false;
    }
  }

  newSecretKey(){
    this.updatesecretKey = true;
  }

  cancelUpdatesecretKey(){
    this.updatesecretKey = false;
  }

  setCustomHeaders(customHeaders: any[]) {
    const customHeadersGroupCtrls = customHeaders.map(x => this.form.group(x));
    this.apiSubscriberForm.setControl('customHeaders', this.form.array(customHeadersGroupCtrls));
  }

  setselectedEntities(selectedEntities: any[]) {
    const selectedEntitiesGroupCtrls = selectedEntities.map(x => this.form?.group(x));
    this.apiSubscriberForm.setControl('selectedEntities', this.form?.array(selectedEntitiesGroupCtrls));
  }

  openHelpDialog(helpText){
    const dialogData = new helpDialogModel("API Subscription Info", helpText, this.column);
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

  }

}