import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ShipmentService } from '@cl/shipments/shipment.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {  Subscription } from 'rxjs';
import {  finalize, startWith,  switchMap, takeWhile } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';
import { ToastService } from '@cl/common/services/toast.service';
import { AuthorizeService } from '@cl/@authorize/authorize.service';
import { UserService } from '@cl/user/user.service';
@Component({
  selector: 'cl-shipment-details-actions',
  templateUrl: './shipment-details-actions.component.html',
  styleUrls: ['./shipment-details-actions.component.scss'],
})
export class ShipmentDetailsActionsComponent implements OnInit, OnChanges {
  @Input() shipmentData: any;
  @Output() refreshShipment = new EventEmitter<void>();

  showManageTags = true;
  tags = [];
  aviltags = [];
  public isLoading: boolean = false;
  shipmentId:any;
  shipmentType:any;
  timeInterval:Subscription;
  status: string;
  toggle: boolean = false;
  currentAction: { isDisable: boolean; action: 'complete' | 'terminate' } = {
    isDisable: true,
    action: 'complete',
  };
  actionBtnPolicies = {
    terminate: ['SHIPMENT_TERMINATE'],
    share: ['SHIPMENT_SHARE'],
    update: ['SHIPMENT_UPDATE'],
  };

  isDTPUser = this._userService.isDTPUser();
  isDTPShipment = true;
  isDTPDownloadRestricted = true;
  isReportRequesting = false;

  constructor(
    private shipmentService: ShipmentService,
    public dialog: MatDialog,public toastService: ToastService,
    private _dialog: MatDialog,
    private _authService: AuthorizeService,
    private _userService: UserService
  ) {}
  
  ngOnChanges(changes: SimpleChanges): void {
    // Default setting
    this.currentAction = {
      isDisable: true,
      action: 'complete',
    };

    if (this.shipmentData?.shipmentNode?.properties?.statusCode == 'At Destination') {
      this.currentAction = {
        isDisable: false,
        action: 'complete',
      };
    }else if (!['At Destination', 'Completed'].includes(this.shipmentData?.shipmentNode?.properties?.statusCode)) {
      this.currentAction = {
        isDisable: false,
        action: 'terminate',
      };
    }
  }

  ngOnInit(): void {
    this.isDTPDownloadRestricted = this._authService.isValidAction(['CDMINSTANCE_RESTRICT_PDFDOWNLOAD'])
    this.tags = this.shipmentData.shipmentNode.properties.tags;
    this.shipmentId = this.shipmentData.shipmentNode.properties.id;
    this.shipmentType = this.shipmentData.shipmentNode.properties.type;
    this.startPollingReports();
    //For DTP restrictions
    // FIXME: Currently dont have dtp implementation on backend side so lets assume parcel shipments as dtp shipments
    this.isDTPShipment = this.shipmentData.shipmentNode.properties.parentEntityTypeKey == 'dtpshipment';
  }

  startPollingReports(){
    if (
      this.shipmentData.shipmentNode.properties.statusCode === 'Completed' ||
      this.shipmentData.shipmentNode.properties.statusCode === 'At Destination'
    ) {
      this.timeInterval = interval(30000)
        .pipe(
          startWith(0),
          switchMap(() =>{
            this.isReportRequesting = true;
            return this.shipmentService.getReportStatus(this.shipmentId)}
          ),
          takeWhile(
            (res: any, index) =>
              res.body.status && res.body.status !== 'Completed',
            true
          )
        )
        .subscribe((res: any) => {
          this.isReportRequesting = false;
          this.status = res.body.status;
        });
    }
  }

  eventFromManageTags(data) {
    this.showManageTags = true;
  }
  private subscriptions: Subscription[] = [];

  removetag(index) {
    this.isLoading = true;
    let message = `Are you sure to remove this tag?: `;
    const dialogData = new ConfirmDialogModel('', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.tags.splice(index,1);
        var obj = {
          type: this.shipmentType,
          tags: this.tags,
        };
        const removeTag: Subscription = this.shipmentService
          .applyAndRemoveManageTags(obj,[this.shipmentId])
          .subscribe((data) => {
            this.toastService.success(`Tag(s) removed successfully`);
            this.isLoading = false;
          });
        this.subscriptions.push(removeTag);
      } else {
        this.isLoading = false;
      }
    });
  }
  applyTags(y) {
    this.aviltags = y;
    var x = this.aviltags.filter((e) => e.checked == true);
    this.tags = x.map((tag) => tag.tagName);
  }

  downloadPDF(){
    this.shipmentService.downloadReport(this.shipmentId).subscribe((data:any)=>{
      let fileName = 'file';
      const contentDisposition = data.headers.get('Content-Disposition');
      if (contentDisposition) {
        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
    const downloadURL = window.URL.createObjectURL(data.body);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
    }, error => {
      console.log(error);
   })
  }

  downloadShipmentReport(){
    this.isReportRequesting = true;
    this.shipmentService.createShipmentReport(this.shipmentId).pipe(finalize(() => this.isReportRequesting = false)).subscribe((data:any)=>{
      this.startPollingReports();
      if(data.status.toLowerCase()==='in-progress'){
        this.status = data.status;
        this.toastService.info('The shipment report is being generated. We will let you know when it is ready.','',5000)
      }
    });
  }

  ngOnDestroy(){
    this.timeInterval?.unsubscribe();
  }
  public getData(data: any) {}

  public onClose(close: boolean) {
    if (close && this.toggle) {
      this.toggle = false;
    } else {
      this.toggle = true;
    }
  }
  public fnToggle() {
    this.toggle = !this.toggle;
  }
  confirmDialog() {
    this._dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          message: `Are you sure want to ${this.currentAction.action} shipment?`,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.currentAction.action === 'terminate'
            ? this.terminateShipment()
            : this.completeShipment();
        }
      });
  }

  async terminateShipment() {
    const [res, error] = await this.shipmentService.terminateShipment(
      this.shipmentData.shipmentNode.id,
      this.shipmentData.shipmentNode?.properties?.entityTypeKey
    );

    if (error) return;
    this.toastService.openTimerSnackBar('Page will reloaded in ', 10);
    setTimeout(() => {
      this.refreshShipment.emit();
    }, 10000);
  }

  async completeShipment() {
    const [res, error] = await this.shipmentService.completeShipment(
      this.shipmentData.shipmentNode.id,
      this.shipmentData.shipmentNode?.properties?.entityTypeKey
    );

    if (error) return;
    this.toastService.openTimerSnackBar('Page will reloaded in ', 10);
    setTimeout(() => {
      this.refreshShipment.emit();
    }, 10000);
  }

}
