import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { CatalogType, CdmField } from '@cl/@types/extended-attributes.types';
import { InputField } from '@cl/@types/form-rendering-engine.type';
import { BulkActionComponent } from '@cl/common/components/bulk-action/bulk-action.component';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { ToastService } from '@cl/common/services/toast.service';
import { BehaviorSubject, finalize } from 'rxjs';
import { AssetService } from '../asset.service';
import { TranslatePipe } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import _ from 'lodash';

@Component({
  selector: 'cl-asset-add-panel',
  templateUrl: './asset-add-panel.component.html',
  styleUrls: ['./asset-add-panel.component.scss'],
})
export class AssetAddPanelComponent implements OnInit, OnDestroy {
  @Input() isEdit: boolean = false;
  @Input() assetId!: string;
  @Output() closeSidePanel = new EventEmitter<boolean>();
  @ViewChild('formRenderer') formRenderer!: DynamicFormRendererComponent;
  @ViewChild(BulkActionComponent, { static: false }) bulk: BulkActionComponent;
  @ViewChild('qrBarRFIDCode') qrBarRFIDCode: TemplateRef<any>;

  bulkConfig: BulkPanelModel = {
    fileType: 'CSV',
    appName: 'clfgraphapp',
    method: 'POST',
    templateUrl: '',
    uploadUrl: '', //Based upon the CatalogType selection it will change
    isDynamicFile: true
  };
  downloadedCSVFields:string[] = []

  assetDetailsObj: any;
  isCreatingAsset = false;
  isDetailsFetching = false;

  assetCatalog!: CatalogType;
  catalogTypesList: CatalogType[] = [];
  catalogType: CatalogType;

  fields: CdmField[] = [];
  inputFields: InputField[] = [];

  defaultAvatar = 'assets/svgs/asset.svg';
  assetAvatar: string | ArrayBuffer = '';
  uploadedAvatar: File;
  tabSelectedEvent = new BehaviorSubject<number>(0);
  bulkFormData: FormData;

  isCatalogTypeError = false;
  fieldTypes: string[] = [];
  assetForm: FormGroup;
  codeScanned: boolean = false;
  qrBarCode:any;
  scanCode:any;
  scannedCodelist:any = [];
  isOptionSelected:boolean = false;
  scannedCodeText:any;
  allScanCode:any;
  showScanSearchbox:boolean = false;
  constructor(
    private _dynamicFormService: DynamicFormService,
    private _assetService: AssetService,
    private _extendedAttributeService: ExtendedCatalogAttributeService,
    private _toastService: ToastService,
    private _bulkService: BulkActionsService,
    private _dialog: MatDialog,
    private translatePipe: TranslatePipe,

  ) {}

  ngOnInit(): void {
    this.assetForm = new FormGroup({
      qrBarCode: new FormControl(),
      scanCode: new FormControl()
    })
    this.tabSelectedEvent.subscribe((tab) => {
      if (tab == 1) {
        this.bulkConfig.uploadUrl = `api/2/bulkDataJob/${this.assetCatalog.objectType}/create/file`;
      }
    });

    if (this.isEdit && this.assetId) {
      this.getAssetDetails();
    } else {
      this.getAssetTypesList();
    }
  }

  get tabSelected() {
    return this.tabSelectedEvent.getValue();
  }

  async getAssetDetails() {
    this.isDetailsFetching = true;
    this.assetDetailsObj = await this._assetService.getAssetDetails(
      this.assetId
    );
    await this.changeCatalogType(this.assetDetailsObj.type);
    this.isDetailsFetching = false;
  }

  async getAssetTypesList() {
    this.catalogTypesList =
      await this._extendedAttributeService.getCatalogExtendedMultiColumns(
        'asset',
        false
      );
      this.catalogTypesList.sort(function (a,b) {
        return a.name.toLowerCase()>b.name.toLowerCase()?1:-1
    })
  }

  async changeCatalogType(objectType: string) {
    this.assetCatalog =
      await this._extendedAttributeService.getCatalogExtendedColumns(
        objectType
      );
    if (this.isEdit) {
      this.assetCatalog.imageURL = this.assetDetailsObj?.properties?.imageURL;
      this.qrBarCode = this.assetDetailsObj?.properties?.qrBarCode;
    }
    this.assetAvatar = this.assetCatalog?.imageURL + '?' + new Date().getTime();
    const fields = this._dynamicFormService.getRenderableFields(
      this.assetCatalog.cdmFields
    ); 

    this.fields = this._dynamicFormService.orderFields([...fields], 'order');

    this.downloadedCSVFields = this.fields.map(field => {
      let label = field.displayLabel;
      if(field.required){
        label += '*'
      }else if(field.displayLabel.endsWith('*')){
        label += '*'
      }
      return `"${label}"`
    })

    this.inputFields = await this._dynamicFormService.generateInputFields(
      this.fields,
      this.isEdit,
      this.assetDetailsObj?.properties
    );

    this.inputFields.forEach((inputField) => {
      if (inputField.id == 'qrBarCode') {
        inputField.isDynamicField = false;
        inputField.required = false;
        inputField.template = this.qrBarRFIDCode;
      }
    }) 
    
  }

  onAvatarChange(event) {
    if (event.target.files && event.target.files[0]) {
      const file:File = event.target.files[0];
      if(!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)){
        const errorMessage = 'Image should be png/jpg format';
        this._toastService.error(errorMessage)
        this.assetAvatar = this.assetCatalog?.imageURL + '?' + new Date().getTime();
        this.uploadedAvatar = undefined;
        return;
      }
      this.uploadedAvatar = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.assetAvatar = reader.result;
      };

      reader.readAsDataURL(file);
    } else {
      this.assetAvatar = this.assetCatalog?.imageURL + '?' + new Date().getTime();
      this.uploadedAvatar = undefined;
    }
  }

  closePanel(isReload) {
    this.closeSidePanel.emit(isReload);
  }

  onNext() {
    if(!this.catalogType){
      this.isCatalogTypeError = true;
      return;
    }
    this.isCatalogTypeError = false;
    this.changeCatalogType(this.catalogType.objectType);
    this.tabSelectedEvent.next(0);
  }

  onTabClick(tab: MatTabGroup) {
    if (tab.selectedIndex == this.tabSelected) return;
    const currentIndex = tab.selectedIndex;
    const form = this.formRenderer?.onSubmit();
    if (form?.touched || this.bulkFormData) {
      tab.selectedIndex = this.tabSelected;
      this._dialog
        .open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Discard',
            message: `Are you sure want discard   ${
              this.bulkFormData ? 'File' : 'Asset'
            } ?`,
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            tab.selectedIndex = currentIndex;
            this.tabSelectedEvent.next(currentIndex);
            return;
          }
          tab.selectedIndex = this.tabSelected;
        });
      return;
    }
    this.tabSelectedEvent.next(currentIndex);
  }

  async onBulkUpload() {
    const payload: any = {
      file: this.bulkFormData,
      ...this.bulkConfig,
    };
    if (!payload.file) {
      this._toastService.info('select file to upload');
      return;
    }
    this.isCreatingAsset = true;
    this._bulkService
      .uploadBulkActionFile(payload)
      .pipe(finalize(() => (this.isCreatingAsset = false)))
      .subscribe({
        next: (res: any) => {
          this.closePanel(false);
          this._toastService.openCloseHandlerSnackBar(
            [`Asset data is being processed and will load in the background. A notification will be sent when the data processing and loading is completed.`]
          ,'',['toast-success']);  
        },
      });
  }

  async onSave() {
    if (this.tabSelected === 1) {
      this.onBulkUpload();
      return;
    }
    const formData = this.formRenderer.onSubmit();
    if (formData.valid) {
      const dynamicFormPayload = this._dynamicFormService.getDynamicFormPayload(
        formData,
        this.inputFields
      );

      let assetId = this.assetId;

      const payload = {
        ...dynamicFormPayload,
        qrBarCode:this.qrBarCode,
        imageURL: this.assetCatalog.imageURL,
        type: this.assetCatalog.name,
        typeId: this.assetCatalog.id,
      };
      this.isCreatingAsset = true;

      try {
        if (this.isEdit) {
          const preDefinedPayload = {
            entityTypeId: this.assetDetailsObj?.properties?.entityTypeId,
            entityType:this.assetDetailsObj?.properties?.entityType,
            entityTypeKey:this.assetDetailsObj?.properties?.entityTypeKey,
            parentEntityTypeKey:this.assetDetailsObj?.properties?.parentEntityTypeKey
          }
          await this._assetService.updateAsset(
            {...payload,...preDefinedPayload},
            this.assetCatalog.objectType,
            this.assetId
          );
        } else {
          const createdAsset = await this._assetService.createAsset(
            payload,
            this.assetCatalog.objectType
          );
          assetId = createdAsset.id;
        }

        if (this.uploadedAvatar && assetId) {
          const formData = new FormData();
          formData.append('file', this.uploadedAvatar);
          await this._assetService.uploadAvatar(formData, assetId,this.assetCatalog.objectType);
        }

        this.closePanel(true);
        this._toastService.success(
          `${this.isEdit ? (this.translatePipe.transform('assets.assetUpdateMessage')) : (this.translatePipe.transform('assets.assetCreateMessage'))}`
        );
      } catch (error) {}

      this.isCreatingAsset = false;
    }
  }

  ngOnDestroy(): void {
    this.tabSelectedEvent.unsubscribe();
  }

  handleScannedValue(){
    let formatedData:any;
    let scannedData:any = this.qrBarCode;
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':' '"\\|,.<>\/?~]/;
    if(!_.isEmpty(scannedData)){
      if(format.test(scannedData)){
        formatedData = this.formatScanCode(scannedData);
        if(formatedData.length>1){
          formatedData.forEach(searchText =>{
            if(this.scannedCodelist.indexOf(searchText.trim()) === -1) {
              this.scannedCodelist.push(searchText.trim());
            }
          })
          this.allScanCode = this.scannedCodelist;
          this.codeScanned = true;
          if(this.scannedCodelist.length>5){
            this.showScanSearchbox = true;
          }
        }
      }
    }
    
  }

  closeScanCodeModel(){
    this.codeScanned = false;
    this.scanCode = '';
    this.scannedCodelist = [];
  }

  formatScanCode(input){
    let updatedData:any[] = [];
    let removedSpecialCharachter='';
      input.trim();
      removedSpecialCharachter = input.replaceAll(/[\s+&\/\\#,,+,,,()$~%.'";*?<>{}/\t/\r]/g,',');
      // split comma separated value and remove duplicate
      updatedData = [...new Set(removedSpecialCharachter.split(','))];
      // remove empty value
      const data = updatedData.filter((x) => x!= '' && x!= ' ');
      return data
  }

  fnScanCodeChanged(){
    let checkedValue = this.scanCode;
    this.qrBarCode = checkedValue;
    this.isOptionSelected = false;
    // this.assetForm.get('qrBarCode').setValue(checkedValue);
  }

  fnScanCodeSelect(){
    this.isOptionSelected = true;
  }

  tiggerScannedCodeSearch(){
    var searchText = this.scannedCodeText
    let searchedValue = this.allScanCode.filter((item) => {
      return item.includes(searchText)
    });
    if(searchedValue.length>0){
      this.scannedCodelist = searchedValue;
    }else{
      this.scannedCodelist = this.allScanCode;
    } 
  }
}