<div (click)="resetMap()" class="reset-button reset-map grid grid-25-75">
    <i class="fa fa-refresh"></i>
    <span>reset map</span>
</div>
<!-- <div *ngIf="atLastState"  class="reset-button step-back grid grid-25-75">
    <i class="fa fa-step-backward" (click)="stepBack()"></i>
    <span>step back</span>
</div>
<div *ngIf="!atLastState" class="reset-button step-back grid grid-25-50-25">
    <i class="fa fa-step-backward" (click)="stepBack()"></i>
    <span>step</span>
    <i class="fa fa-step-forward" (click)="stepForward()"></i>
</div> -->