import { Injectable } from "@angular/core";
import { DigitalTwinList, DtAnalysis, NavigatorConfiguration, PartialOf } from "@cl/models";
import { CcUpdateClientLogoUrl, LvHidePanel, LvShowPanel } from "@cl/ngxs/actions";
import { DtApiService } from "../../common/services/dt-api.service";
import { NavigatorApiService } from "../../common/services/navigator-api.service";
import { AppState } from "@cl/state/app.state";
import { Action, Actions, Selector, State, StateContext, Store } from "@ngxs/store";
import _ from "lodash";
import { finalize, switchMap, tap } from "rxjs/operators";
import { NavigatorCardsService } from "../service/navigator-cards.service";
import {
  NavigatorFilteredNodes,
  NavigatorLastAction,
  NavigatorLoadDtAnalysis,
  NavigatorLoadDts,
  NavigatorLoadFilterConfig,
  NavigatorResetMap,
  NavigatorSetMapCenter,
  NavigatorUpdateCards,
  NavigatorUpdateFilterEsQuery,
  NavigatorUpdateHeaderMenu,
  NavigatorUpdateLoadingStatus,
  NavigatorUpdateMapDetails,
} from "./navigator.actions";
import { NavigatorStateModel } from "./navigator.state.model";


const DEFAULT_STATE: NavigatorStateModel = {
  dtAnalysisData: {},
  dtCards: [],
  dts: [],
  selectedDtId: "",
  loading: {
    dts: false,
    dtAnalysis: false,
    filters: false,
    map: false,
  },
  assetId: '',
  locationId: '',
  shipmentId:'',
  filteredNodes: 0
};

@State<NavigatorStateModel>({
  name: "navigator_state",
  defaults: DEFAULT_STATE,
})
@Injectable()
export class NavigatorState {
  @Selector()
  static selectedDt(state: NavigatorStateModel) {
    return state.dts.find((dt) => dt.id === state.selectedDtId);
  }

  @Selector()
  static filteredNodes(state: NavigatorStateModel) {
    return state.filteredNodes;
  }

  @Selector()
  static lastAction(state: NavigatorStateModel) {
    return state.lastAction;
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private dtApi: DtApiService,
    private navigatorCards: NavigatorCardsService,
    private navigatorApi: NavigatorApiService,
  ) {}

  @Action(NavigatorLoadDtAnalysis)
  navigatorLoadDtAnalysis({ patchState, getState, dispatch }: StateContext<NavigatorStateModel>,
                          action: NavigatorLoadDtAnalysis) {
    let { loading } = getState();

    // Update DT analysis progress
    patchState({
      loading: {
        ...loading,
        dtAnalysis: true,
      },
    });

    return this.dtApi.getKPIData(action.dtId, false).pipe(
      finalize(() => {
        // Set loading status of dt analysis as false
        loading = getState().loading;
        patchState({
          loading: {
            ...loading,
            dtAnalysis: false,
          },
        });
      }),

      tap((dtAnalysisData: DtAnalysis) => {
        // Update state with latest analysis response
        patchState({ dtAnalysisData });
      }),

      switchMap((dtAnalysis) => dispatch(new NavigatorUpdateCards(dtAnalysis))),
    );
  }

  @Action(NavigatorUpdateCards)
  updateCards({ getState, patchState }: StateContext<NavigatorStateModel>, action: NavigatorUpdateCards) {
    const kpi = action.dtAnalysis;
    const dtCards = this.navigatorCards.mapDtAnalysisToCards(kpi);
    patchState({ dtCards });
  }

  // TODO: Remove reference of DtApi.getDts() use this instead
  @Action(NavigatorLoadDts)
  loadDts({ getState, patchState, dispatch }: StateContext<NavigatorStateModel>, action: NavigatorLoadDts) {
    // Update loading status
    let { loading } = getState();
    patchState({ loading: { ...loading, dts: true } });

    return this.dtApi.getDTs().pipe(
      // Turn off loading status
      finalize(() => {
        loading = getState().loading;
        patchState({ loading: { ...loading, dts: false } });
      }),

      tap((res: DigitalTwinList) => {
        const dts = _.cloneDeep(res);
        // Fix dt name coming deep inside properties
        dts.forEach((dt) => {
          dt.name = dt?.name ?? dt?.properties["properties"]?.name ?? "";
        });

        const selectedDtId = dts.length > 0 ? dts[0].id : "";
        patchState({ dts, selectedDtId });

        dispatch(new NavigatorLoadDtAnalysis(selectedDtId));
      }),
    );
  }

  @Action(NavigatorLoadFilterConfig)
  loadFilterConfig({ getState, patchState, dispatch }: StateContext<NavigatorStateModel>,
                   action: NavigatorLoadFilterConfig) {
    // Set the filter loading state
    let { loading } = getState();
    patchState({ loading: { ...loading, filters: true } });

    return this.store.selectOnce(AppState.configurations).pipe(
      finalize(() => {
        loading = getState().loading;
        patchState({ loading: { ...loading, filters: false } });
      }),
      tap((navigatorConfig) => {
        // Create a index for config name and its details
        const configIndex: PartialOf<NavigatorConfiguration> = navigatorConfig.reduce((acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        }, {});

        // MapUI config
        const mapUIConfig = configIndex["map-ui"];
        const config = mapUIConfig?.value;
        patchState({
          navigatorConfig: config,
          filterEsQuery: config?.initialData?.payload as any,
          configurations: navigatorConfig,
        });

        // Check for header menu config if found,
        // Dispatch action to update header menu
        const headerMenuConfig = configIndex["headerExtraMenus"];
        if (headerMenuConfig) {
          dispatch(new NavigatorUpdateHeaderMenu(headerMenuConfig?.value));
        }

        // Check for client logo url and update state if found
        const clientLogoUrl = configIndex["branding_image_url"];
        if (clientLogoUrl) {
          dispatch(new CcUpdateClientLogoUrl(clientLogoUrl.value));
        }
      }),
    );
  }

  @Action(NavigatorUpdateFilterEsQuery)
  updateFilterEsQuery({ getState, patchState, dispatch }: StateContext<NavigatorStateModel>,
                      action: NavigatorUpdateFilterEsQuery) {
    const state = getState();
    const filterEsQueryNew = action.searchRequest ?? <any>state.navigatorConfig.initialData.payload;
    const filterEsQuery = {
      ...state.filterEsQuery,
      ...filterEsQueryNew,
    };

    patchState({ filterEsQuery });
  }

  @Action(NavigatorUpdateLoadingStatus)
  updateLoadingStatus({ getState, patchState }: StateContext<NavigatorStateModel>,
                      action: NavigatorUpdateLoadingStatus) {
    const { loading } = getState();
    patchState({
      loading: {
        ...loading,
        ...action.loadingStatus,
      },
    });
  }

  @Action(NavigatorUpdateMapDetails)
  updateMapDetails({ getState, patchState, dispatch }: StateContext<NavigatorStateModel>,
                   action: any) {
    const { mapDetails, filterEsQuery } = getState();
    patchState({
      mapDetails: {
        ...mapDetails,
        ...action.mapDetails,
      },
      assetId: action.assetId?.assetId,
      locationId: action.locationId?.locationId,
      shipmentId:action.shipmentId?.shipmentId
    });

    if (action.mapDetails?.bounds) {
      const payload = {
        ...filterEsQuery,
        ...action.mapDetails?.bounds,
      };

      dispatch(new NavigatorUpdateFilterEsQuery(payload));
    }

    // if (action.mapDetails?.activeEntity) {
    //   dispatch(new LvShowPanel("inspector", "NavigatorUpdateMapDetails"));
    // }
  }

  @Action(NavigatorResetMap)
  resetMap({ getState, patchState, dispatch }: StateContext<NavigatorStateModel>, action: NavigatorResetMap) {
    const state = getState();

    patchState({
      mapDetails: {
        ...state.mapDetails,
        activeEntity: null,
      },
    });

    // Reset active entity and right side panel
    return dispatch([new LvHidePanel("rightList"), new LvHidePanel("inspector")]);
  }

  @Action(NavigatorUpdateHeaderMenu)
  updateHeaderMenu({ patchState }: StateContext<NavigatorStateModel>, action: NavigatorUpdateHeaderMenu) {
    patchState({ headerMenus: _.cloneDeep(action.menuGroup) });
  }

  @Action(NavigatorFilteredNodes)
  filteredNodes({ patchState }: StateContext<NavigatorStateModel>, action: NavigatorFilteredNodes) {
    patchState({ filteredNodes: action.filteredNodes});
  }

  @Action(NavigatorLastAction)
  lastAction({ patchState }: StateContext<NavigatorStateModel>, action: NavigatorLastAction) {
    patchState({ lastAction: action.lastAction});
  }

  @Action(NavigatorSetMapCenter)
  setMapCenter({ patchState }: StateContext<NavigatorStateModel>, action: NavigatorSetMapCenter) {
  }
}
