import { Component, OnInit } from '@angular/core';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Component({
  selector: 'cl-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  extURL: string;
  constructor(private apiProvider: ApiProviderService) { }

  ngOnInit(): void {
    this.constructExternalAPIURL();
  }

  constructExternalAPIURL() {
    let hostName = this.apiProvider.getBaseHost();
    this.extURL = hostName.replace('-graph', '-api') + '/external-api';
  }

}
