<div class="user-summary-panel">
    <cl-side-panel>
      <section class="cl-side-panel-title side-panel-header-bg">
        <span class="black font-bold"> {{ isEdit ? "Edit" : "New" }} Inventory </span>
      </section>

      <section class="cl-side-panel-content px-1 py-2">
      <div class="sku-type__item p-2" *ngIf="!inventoryCatalog?.name">
        <label class="form-label font-bold" *ngIf="!isEdit">Select InventoryType</label>
        <div *ngIf="!isEdit">
          <mat-select
            class="basic-input w-full"
            placeholder="Select Inventory Type"
            [(ngModel)]="catalogType"
          >
            <mat-option
              [value]="catalogType"
              *ngFor="let catalogType of catalogTypesList"
              >{{ catalogType.name }}</mat-option
            >
          </mat-select>
          <span *ngIf="isCatalogTypeError" class="text-danger">Please Select Inventory Type</span>
        </div>
      </div>

        <ng-container *ngIf="!isDetailsFetching || (catalogType && !isEdit)">
          <div>
            <cl-dynamic-form-renderer
              *ngIf="inputFields.length > 0"
              [inputFields]="inputFields"
              columnMode="Two"
              #formRenderer
            ></cl-dynamic-form-renderer>
          </div>
        </ng-container>
      </section>

      <section class="cl-side-panel-footer bgcolor33 p-2">
        <div class="flex">
          <div class="flex-col grow text-right">
            <a
              class="m-2"
              (click)="closePanel(false)"
              title="cancels the changes and closes the form"
              >Cancel</a
            >
            <button class="panel-button white bgcolor4 ml-2" (click)="inventoryCatalog ? onSave('inventory') : onNext()">
            {{ inventoryCatalog ?  "Save" : "Next" }}
            <span
              *ngIf="isCreating"
              class="fa fa-circle-o-notch fa-pulse white"
              [ngClass]="{
                visible: isCreating,
                hidden: !isCreating
              }"
            ></span>
          </button>
          </div>
        </div>
      </section>
    </cl-side-panel>
  </div>

