import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '@cl/@core/shell/nav.service';
import * as _ from 'lodash';

export interface BreadCrumbItem{
  name: string;
  path: string;
  isBack?: boolean;
}
@Component({
  selector: 'cl-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input('menu') menuList: BreadCrumbItem[];
  constructor(private router: Router, private navService: NavService) { }
  navigate(menuItem : BreadCrumbItem){
    if(!_.isEmpty(menuItem.path)){
      this.router.navigateByUrl(menuItem.path);
    }
    if(_.isEmpty(menuItem.path) && menuItem.isBack){
      if(window.history.state.navigationId && window.history.state.navigationId == 1) {
        this.navService.navigateToDefaultRoute()
      } else {
        window.history.back();
      }
    }
  }
}
