<div class="mt-2 mb-2 notification-title">
    <div class="mb-4 -mt-1">
        <cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb>
      </div>
    <!-- <span class="page-title">Notifications</span> -->
</div>
<!-- <cl-table-surtitle [rows]="rows.length" [selected]="selected.length" [total]="totalRowCount" [pageName]="'Notifications'"></cl-table-surtitle> -->
<div class="wt-ngx-grid-wrapper relative h-calc-40 list-page">
    <div class="flex justify-between pt-1 pb-1">
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer color4 list-page_filter-toggle flex items-center">
                <img class="filter-img mr-2 ml-2" src="assets/svgs/filter-options.svg" />filter options</span>
            <cl-list-search [placeholder]="'Search by notification Name'" (search)="updateFilter($event)"
                [columns] = "['Name']"></cl-list-search>
        </div>
        <div class="notification__action-panel-right action-panel-right self-end flex items-center mb10 mr10">
            <cl-date-picker class="action-separator list-date-picker" [dateRange]="selectedDateRange" [ranges]="ranges" (dateChanged)="datesUpdated($event)"></cl-date-picker>

            <cl-export-panel class="action-separator" (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'notification'">
            </cl-grid-column-toggle>
        </div>
    </div>
    <div class="h-calc-60">
        <main class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth - 10">
            <div class="grow-0 nav panel scroll-y" *ngIf="showFilterPanel">
                <cl-list-filter [hideTitle]="true" [showCounts]="false" [title]="''" heightClass="none"
                    (toggleFilterPanel)="toggleFilterPanel()" (panelsEmitter)="getPanelUpdate($event)"
                    [filters]="filters" [actionCount]="actionCount">
                </cl-list-filter>
            </div>
            <div class="grid-container h-100 grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div class="h-100" [style.width.px]="tableWidth" *ngIf="isShowTable">
                    <ngx-datatable class="h-calc-40" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [selectAllRowsOnPage]="false" (select)='onSelect($event)' (reorder) = "columnReordered($event)" [reorderable]="false">
                    </ngx-datatable>
                </div>
            </div>
        </main>

        <!-- <ng-template #notificationListTemplate let-row="row" let-value="value" let-i="index">
            <a class="font-12-bold" href="/asset_detail/{{value}}">{{value}}</a>
        </ng-template> -->

    </div>

</div>
