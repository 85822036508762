<div class="block">
  <div class="scroll-y pos-rel">
    <div class="middle-border"></div>
    <div class="overflow-hidden ml-4">
      <nav
        class="filter-list"
        [ngClass]="[
          listFilters[0].list?.length > 3
            ? 'h-[100px] overflow-y-auto'
            : 'h-100'
        ]"
      >
        <ng-container>
          <div class="col-12 mb10" style="padding-right:1px">
            <cl-date-picker
              [dateRange]="selectedDateRange"
              placeholder="Select date range"
              [ranges]="ranges"
              (dateChanged)="datesUpdated($event)"
              [datePickerClass]="datePickerClass"
              [(ngModel)]="selectedDateRange"
              ngDefaultControl
            ></cl-date-picker>
          </div>
        </ng-container>

        <div *ngFor="let filter of listFilters[0].list">
          <span class="css-grid mr10" [class.grid-cols-1]="true">
            <mat-checkbox
              class="foz12 capitalize mb-1 mt-0"
              [(ngModel)]="filter.checked"
              (change)="toggleFilter(filter, listFilters[0])"
            >
              <span
                class="font-12-normal"
                *ngIf="filter?.displayLabel && filter?.displayLabel.length > 20"
                matTooltip="{{ filter?.displayLabel }}"
                matTooltipClass="tooltip-styles"
              >
                {{ filter?.displayLabel | truncate: 24 }}
              </span>
              <span
                class="font-12-normal"
                *ngIf="
                  filter?.displayLabel && filter?.displayLabel.length <= 20
                "
              >
                {{ filter?.displayLabel }}
              </span>
              <span
                class="font-12-normal"
                *ngIf="filter?.name.length > 20 && !filter?.displayLabel"
                matTooltip="{{ filter?.label }}"
                matTooltipClass="tooltip-styles"
              >
                {{ filter?.label || filter?.name | truncate: 24 }}
              </span>
              <span
                class="font-12-normal"
                *ngIf="filter?.name.length <= 20 && !filter?.displayLabel"
              >
                {{ filter?.label || filter?.name }}
              </span>
            </mat-checkbox>
          </span>
        </div>
      </nav>
    </div>
  </div>
</div>
