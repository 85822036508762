import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { DeviceProfileService } from '../device-profile.service';
import { debounceTime, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { UserService } from '@cl/user/user.service';
import { TemperaturePipe } from '@cl/common/pipes/temperature.pipe';
import { PropertyService } from '@cl/property/property.service';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TenantService } from '../../../tenant/tenant.service';
import { ClFormValidators } from '@cl/common/validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastService } from '@cl/common/services/toast.service';

import {
  UomTypeCustom,
  SensorTypeUomCustom,
  SensorTypeUom,
  SensorTypeUomTime,
  SensorTypeUomValues,
  SensorDeviceConfigParams,
  SensorDeviceCharacteristic,
  SensorDeviceConfig,
} from '@cl/@types/device-profile';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { toggleFullScreen } from 'projects/clf-dvp2/lib/keylines-6.6.1-69050/ts/keylines';
import { OrganizationService } from '@cl/common/services/organization.service';

@UntilDestroy()
@Component({
  selector: 'cl-edit-device-profile-panel',
  templateUrl: './edit-device-profile-panel.component.html',
  styleUrls: ['./edit-device-profile-panel.component.scss'],
})
export class EditDeviceProfilePanelComponent
  implements OnInit, OnChanges, OnDestroy
{
  readonly UomTypeCustom = UomTypeCustom;
  @Input() groupObject: any;
  @Input() mode: any;
  @Output() closeSidePanel = new EventEmitter<any>();
  isLoading = false;
  private subscriptions: Subscription[] = [];
  tenantId = '';
  profileDetails = {};
  editTenantPayload = {};
  servicesData: any = {};
  sensorSupportedServices = [];
  emptyCapabilityData = {
    excursion: {
      configured: false,
      min: null,
      max: null,
    },
    critical: {
      configured: false,
      min: null,
      max: null,
    },
    warning: {
      configured: false,
      min: null,
      max: null,
    },
  };
  deviceConfigData = [];
  measures = {};
  sensorTypes = [];
  eProfiler = {};
  selectedTenant = {};
  tenantList = [];
  hasScopeAllPolicy: boolean = false;
  isSaveInProgress: boolean = false;
  orgList: any;
  isOrgAware:boolean;
  isAccSamplingInterval : boolean = false;
  private get defaultConfigParamsValue(): SensorDeviceConfigParams {
    return {
      enabled: false,
      uom: '',
      value: '',
    };
  }

  get fcEventProfileName() {
    return this.deviceProfileForm.get('eventProfileName') as FormControl;
  }

  get fcSensorType() {
    return this.deviceProfileForm.get('sensorType');
  }

  get fcTenantId() {
    return this.deviceProfileForm.get('tenantId');
  }

  get fcDeviceConfig() {
    return this.deviceProfileForm.get('deviceConfig') as FormArray;
  }

  get fcEventConfig() {
    return this.deviceProfileForm.get('eventConfig') as FormGroup;
  }

  get deviceOrgName(): FormControl {
    return this.deviceProfileForm.get("deviceOrgName") as FormControl;
  }
  selectedOption: any = '';
  orgCount: number;

  constructor(
    private deviceProfileService: DeviceProfileService,
    private userService: UserService,
    private temperaturePipe: TemperaturePipe,
    private propertyService: PropertyService,
    private fb: FormBuilder,
    private tenantService: TenantService,
    private toastService: ToastService,
    private organizationService: OrganizationService
  ) {}

  deviceProfileForm = this.fb.group({
    eventProfileName: ['', Validators.required],
    sensorType: ['', Validators.required],
    deviceConfig: this.fb.array([]),
    eventConfig: this.fb.group({}),
    deviceOrgName: ['']
  });

  private get newDeviceConfigForm(): FormGroup {
    return this.fb.group({
      characteristics: this.fb.array([]),
      identifier: ['', [Validators.required]],
      name: ['', [Validators.required]],
      order: [
        this.fcDeviceConfig.length > 0 ? this.fcDeviceConfig.length - 1 : 0,
        [Validators.required],
      ],
    });
  }

  private get newDeviceCharacteristicsForm() {
    return this.fb.group({
      configParams: this.fb.group({
        enabled: [true],
        value: ['', [Validators.required]],
        uom: ['', [Validators.required]],
      }),
      identifier: ['', [Validators.required]],
      name: ['', [Validators.required]],
      _deviceConfig: [{}],
    });
  }

  ngOnInit(): void {
    // this.deviceProfileForm.valueChanges.subscribe(console.log);
    this.isOrgAware = this.userService.getUser()?.orgAware;
    this.getOrgList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setTenantDetails();
    if (this.mode === 'edit') {
      this.getDeviceProfileDetails();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private temperature(value, unit): any {
    return this.temperaturePipe.transform(value, unit);
  }

  private setTenantDetails() {
    if (this.isSuperAdmin) {
      this.tenantId = this.userService.getEffectiveTenantId();
    } else {
      this.tenantId = this.groupObject?.tenantId;
    }
    if (
      this.isSuperAdmin() &&
      this.userService.getPolicies().includes('TENANT_SUMMARY')
    ) {
      this.hasScopeAllPolicy = true;
      // this.deviceProfileForm.addControl(
      //   'tenantId',
      //   new FormControl('', Validators.required)
      // );
      // vm.tenantListLoading = true;
      this.tenantService.getAllTenants().subscribe((res: any) => {
        // vm.tenantListLoading = false;
        this.tenantList = res;
        if (this.mode === 'add') {
          let effectiveTenantId = this.userService.getEffectiveTenantId();
          let index = _.findIndex(
            this.tenantList,
            (tenant: any) => tenant.identifier === effectiveTenantId
          );
          if (index > -1) {
            this.selectedTenant = this.tenantList[index];
          }
        }
        this.getSensorTypes();
      });
    } else {
      this.getSensorTypes();
    }
  }

  getSensorTypes(tenant?) {
    this.deviceProfileService
      .getTenantSpecificSensorTypes(this.tenantId)
      .subscribe((data: any) => {
        this.sensorTypes = _.sortBy(data?.pTypes);
      });
  }

  formatEventConfig() {
    if(this.profileDetails){
      _.forOwn(this.profileDetails['eventConfig'], (val, key) => {
        if(val === 'true' || val === 'false') {
          this.profileDetails['eventConfig'][key] = JSON.parse(val);
        }
      });
    }
  }

  private getDeviceProfileDetails() {
    this.deviceProfileService
      .getProfileDetails(this.groupObject?.eventProfileName, this.tenantId)
      .subscribe((data: any) => {
        this.profileDetails = data;
        this.formatEventConfig();
        if (data && data?.eventConfig) {
          let isShockConfigured = data.eventConfig.isShockConfigured
            ? JSON.parse(data.eventConfig.isShockConfigured)
            : false;
          this.configureCapabilityData('Shock', isShockConfigured);
          let isLightConfigured = data.eventConfig.isLightConfigured
            ? JSON.parse(data.eventConfig.isLightConfigured)
            : false;
          this.configureCapabilityData('Light', isLightConfigured);
          let isPressureConfigured = data.eventConfig.isPressureConfigured
            ? JSON.parse(data.eventConfig.isPressureConfigured)
            : false;
          this.configureCapabilityData('Pressure', isPressureConfigured);
          let isTemperatureConfigured = data.eventConfig.isTemperatureConfigured
            ? JSON.parse(data.eventConfig.isTemperatureConfigured)
            : false;
          this.configureCapabilityData('Temperature', isTemperatureConfigured);
          let isAmbienttemperatureConfigured = data.eventConfig
            .isAmbienttemperatureConfigured
            ? JSON.parse(data.eventConfig.isAmbienttemperatureConfigured)
            : false;
          this.configureCapabilityData(
            'Ambienttemperature',
            isAmbienttemperatureConfigured
          );
          let isCargotemperatureConfigured = data.eventConfig
            .isCargotemperatureConfigured
            ? JSON.parse(data.eventConfig.isCargotemperatureConfigured)
            : false;
          this.configureCapabilityData(
            'Cargotemperature',
            isCargotemperatureConfigured
          );

          let isHumidityConfigured = data.eventConfig.isHumidityConfigured
            ? JSON.parse(data.eventConfig.isHumidityConfigured)
            : false;
          this.configureCapabilityData('Humidity', isHumidityConfigured);
          let isBatteryConfigured = data.eventConfig.isBatteryConfigured
            ? JSON.parse(data.eventConfig.isBatteryConfigured)
            : false;
          this.configureCapabilityData('Battery', isBatteryConfigured);
          let isTiltConfigured = data.eventConfig.isTiltConfigured
            ? JSON.parse(data.eventConfig.isTiltConfigured)
            : false;
          this.configureCapabilityData('Tilt', isTiltConfigured);
        }
        this.getServicesBySensorType(data?.sensorType);
        this.patchFormData();
      });
  }

  configParamsRadioChanged(fcCharacteristics: FormGroup) {
    // Get it a delay to update form values
    setTimeout(() => {
      const val = fcCharacteristics.value as SensorDeviceCharacteristic;

      const fcVal = fcCharacteristics.get('configParams.value');
      const fcUom = fcCharacteristics.get('configParams.uom');
      const uom = val._deviceConfig.uom[fcUom.value] as SensorTypeUomTime;

      if (!uom) {
        return;
      }

      const validators: ValidatorFn[] = [
        Validators.required,
        ClFormValidators.integer,
      ];

      if (this.hasRangeUomValidator(uom)) {
        validators.push(ClFormValidators.range(+uom.min, +uom.max));
      } else {
        if (this.hasMaxUomValidator(uom)) {
          validators.push(Validators.max(+uom.max));
        } else if (this.hasMinUomValidator(uom)) {
          validators.push(Validators.min(+uom.min));
        }
      }

      fcVal.clearValidators();
      fcVal.setValidators(validators);
      fcVal.updateValueAndValidity();
    }, 0);
  }

  configParamsSelectionChanged(fcCharacteristics: FormGroup) {
    // Get it a delay to update form values
    setTimeout(() => {
      const val = fcCharacteristics.value as SensorDeviceCharacteristic;

      const fcUom = fcCharacteristics.get('configParams.uom');
      fcUom.patchValue(val._deviceConfig._uom.uomKey);
    }, 0);
  }

  configParamsCheckboxChanged(fcCharacteristics: FormGroup, event) {
    // Get it a delay to update form values
    setTimeout(() => {
      const val = fcCharacteristics.value as SensorDeviceCharacteristic;

      const fcUom = fcCharacteristics.get('configParams.uom');
      fcUom.patchValue(val._deviceConfig._uom.uomKey);
    }, 0);
  }

  private patchFormData() {
    if (!this.deviceProfileForm) {
      console.warn('Device profile form not initialized');
      return;
    }

    /**
     * NOTE: Don't patch entire form values directly as the nested forms contains array.
     * Patching values directly may patch the values at wrong array index.
     * Hence patch values to each individual form groups (controls) and refrain from
     * patching directly to array
     */
    // this.sensorProfileForm.patchValue(profile);

    // PATCH

    if (this.mode === 'edit') {
      if (!_.isEmpty(this.groupObject)) {
        if (this.fcEventProfileName) {
          this.fcEventProfileName.patchValue(this.groupObject.eventProfileName);
        }

        // PATCH
        if (this.fcSensorType) {
          this.fcSensorType.patchValue(this.groupObject.sensorType);
        }

        if (this.fcTenantId) {
          this.fcTenantId.patchValue(this.groupObject.tenantId);
        }

        // PATCH
        if (this.fcEventConfig) {
          this.fcEventConfig.patchValue(this.profileDetails['eventConfig']);
        }

        if (this.deviceOrgName) {
          this.deviceOrgName.patchValue(this.groupObject.organization);
        }
      }

      const fcDeviceConfigValue = this.fcDeviceConfig
        .value as SensorDeviceConfig[];
      if (this.profileDetails['deviceConfig']) {
        this.profileDetails['deviceConfig'].forEach((d) => {
          const fcCurrDeviceConfigIndex = fcDeviceConfigValue?.findIndex(
            (c) => c.identifier === d.identifier
          );

          if (fcCurrDeviceConfigIndex < 0) {
            return;
          }

          const fcSelectedDeviceConfig = this.fcDeviceConfig.at(
            fcCurrDeviceConfigIndex
          ) as FormGroup;
          const fcCharacteristics = fcSelectedDeviceConfig.get(
            'characteristics'
          ) as FormArray;
          const fcCharacteristicsValue =
            fcCharacteristics.value as SensorDeviceCharacteristic[];

          if (!fcCharacteristicsValue?.length) {
            console.warn(
              `Characteristics array was not found in the form with service identifier ${d.identifier}`
            );
            return;
          }

          d.characteristics.forEach((c) => {
            const fcSelectedCharacteristicsIndex =
              fcCharacteristicsValue.findIndex(
                (_c) => _c.identifier === c.identifier
              );

            if (fcSelectedCharacteristicsIndex < 0) {
              console.warn(
                `Characteristics was not found in the form array with ServiceIdentifier(${d.identifier}) Identifier(${c.identifier})`
              );
              return;
            }

            const fcSelectedCharacteristics = fcCharacteristics.at(
              fcSelectedCharacteristicsIndex
            ) as FormGroup;

            const isToggleField =
              (
                fcSelectedCharacteristics.value?._deviceConfig.uom
                  ?.type as string
              )?.toLowerCase() === 'toggle';

            const configParamsCopy = { ...c.configParams };
            if (isToggleField) {
              configParamsCopy['uom'] =
                fcSelectedCharacteristics.value?._deviceConfig.uom?.type;
              configParamsCopy['enabled'] = JSON.parse(
                configParamsCopy['enabled']
              );
            } else {
              configParamsCopy['enabled'] = JSON.parse(
                configParamsCopy['enabled']
              );
            }

            const fcConfigParams = fcSelectedCharacteristics.get(
              'configParams'
            ) as FormGroup;

            // PATCH
            fcConfigParams.patchValue(configParamsCopy);

            // Patch multiSelecetFormGroup
            const fcConfigParamsValue = fcConfigParams.value;
            if (fcConfigParamsValue.uom === 'multiSelect') {
              const multiSelectFormGroup =
                fcConfigParamsValue.multiSelectFormGroup;
              const value = fcConfigParamsValue.value;

              const multiSelectFormValues = value.split(',') as string[];
              _.forOwn(multiSelectFormGroup, (val, key) => {
                multiSelectFormGroup[key] =
                  !!multiSelectFormValues.includes(key);
              });

              fcConfigParams
                .get('multiSelectFormGroup')
                .patchValue(multiSelectFormGroup);
              fcConfigParams
                .get('multiSelectFormGroup')
                .updateValueAndValidity();
            }
          });
        });
      }
    }
  }

  private isSuperAdmin() {
    return this.userService.getPolicies().includes('SCOPE_ALL');
  }

  onDeviceConfigToggle(
    event: MatSlideToggleChange,
    fcCharacteristics: FormGroup
  ) {
    // Ensure some delay so that form value gets updated
    setTimeout(
      () => this.updateConfigParamsValidators(fcCharacteristics, event.checked),
      0
    );
  }

  private configureCapabilityData(capability, isConfigured) {
    let capabilityData;
    let eventConfig = this.profileDetails['eventConfig'];
    if (isConfigured) {
      let excursionMin: any =
        eventConfig['min' + capability] == '0' ||
        parseFloat(eventConfig['min' + capability])
          ? parseFloat(eventConfig['min' + capability])
          : 0;
      let excursionMax: any =
        eventConfig['max' + capability] == '0' ||
        parseFloat(eventConfig['max' + capability])
          ? parseFloat(eventConfig['max' + capability])
          : null;
      let criticalMin: any =
        eventConfig['min' + capability + 'Critical'] == '0' ||
        parseFloat(eventConfig['min' + capability + 'Critical'])
          ? parseFloat(eventConfig['min' + capability + 'Critical'])
          : null;
      let criticalMax: any =
        eventConfig['max' + capability + 'Critical'] == '0' ||
        parseFloat(eventConfig['max' + capability + 'Critical'])
          ? parseFloat(eventConfig['max' + capability + 'Critical'])
          : null;
      let warningMin: any =
        eventConfig['min' + capability + 'Warning'] == '0' ||
        parseFloat(eventConfig['min' + capability + 'Warning'])
          ? parseFloat(eventConfig['min' + capability + 'Warning'])
          : null;
      let warningMax: any =
        eventConfig['max' + capability + 'Warning'] == '0' ||
        parseFloat(eventConfig['max' + capability + 'Warning'])
          ? parseFloat(eventConfig['max' + capability + 'Warning'])
          : null;
      let warningConfigured: any = warningMin || warningMax ? true : false;
      let criticalConfigured: any = criticalMin || criticalMax ? true : false;

      if (!_.has(eventConfig, 'tempUnitType')) {
        eventConfig = _.extend({}, eventConfig, {
          tempUnitType: this.propertyService.getSetting('ui.temp.unit'),
        });
      }
      if (
        capability === 'Temperature' ||
        capability === 'Ambienttemperature' ||
        capability === 'Cargotemperature'
      ) {
        excursionMin =
          excursionMin == 0 || parseFloat(excursionMin)
            ? this.temperature(
                parseFloat(excursionMin),
                eventConfig.tempUnitType
              )
            : null;
        excursionMax =
          excursionMax == 0 || parseFloat(excursionMax)
            ? this.temperature(
                parseFloat(excursionMax),
                eventConfig.tempUnitType
              )
            : null;
        criticalMin =
          criticalMin == 0 || parseFloat(criticalMin)
            ? this.temperature(
                parseFloat(criticalMin),
                eventConfig.tempUnitType
              )
            : null;
        criticalMax =
          criticalMax == 0 || parseFloat(criticalMax)
            ? this.temperature(
                parseFloat(criticalMax),
                eventConfig.tempUnitType
              )
            : null;
        warningMin =
          warningMin == 0 || parseFloat(warningMin)
            ? this.temperature(parseFloat(warningMin), eventConfig.tempUnitType)
            : null;
        warningMax =
          warningMax == 0 || parseFloat(warningMax)
            ? this.temperature(parseFloat(warningMax), eventConfig.tempUnitType)
            : null;
      }

      capabilityData = {
        excursion: {
          configured: true,
          min: excursionMin,
          max: excursionMax,
        },
        critical: {
          configured: criticalConfigured,
          min: criticalMin,
          max: criticalMax,
        },
        warning: {
          configured: warningConfigured,
          min: warningMin,
          max: warningMax,
        },
      };
    } else {
      capabilityData = this.emptyCapabilityData;
    }
    if (!this.servicesData[capability]) {
      this.servicesData[capability] = {};
    }
    this.servicesData[capability]['eventConfig'] = capabilityData;
  }

  getServicesBySensorType(sensorType) {
    this.deviceProfileService.getServicesBySensorType(sensorType).subscribe(
      (data: any) => {
        let metadata = data;
        let services = metadata.services;
        this.deviceConfigData = [];
        let deviceConfig = metadata.deviceConfig;
        if (services.length) {
          this.configureServicesMetaData(services);
        }
        if (deviceConfig.length) {
          this.configureDeviceConfigMetadata(deviceConfig);
          if (this.profileDetails['deviceConfig']) {
            this.configureDeviceConfigData(this.profileDetails['deviceConfig']);
          }
        }
        this.addFormControls();
      },
      (response) => {
        console.log('Error', response);
      }
    );
  }

  // DVP2-3227 label change
  fnGetInputLabelBySensorType(uiLabel : string, sensorType: string) {
    let lowerCaseUILabel = uiLabel.toLocaleLowerCase();
    let valuesToCheck = lowerCaseUILabel.includes('recording interval') || lowerCaseUILabel.includes('sampling interval');
    if (valuesToCheck) {
         return 'record every';
      }  else if (lowerCaseUILabel.includes('accelerometer mode')){
        return 'select mode';
      } else {
        return 'send every';
      }
  }

  private newEventConfigFormControl(
    value: string | number | boolean,
    min?: number,
    max?: number
  ) {
    const control = this.fb.control(value);

    const validators: ValidatorFn[] = [];

    // min and max validators are set hence add range validator
    if (!isNaN(min) && !isNaN(max) && min !== max) {
      validators.push(ClFormValidators.range(min, max), Validators.required);
    } else if (!isNaN(min)) {
      validators.push(Validators.min(min));
    } else if (!isNaN(max)) {
      validators.push(Validators.max(max));
    }

    control.setValidators(validators);

    return control;
  }

  private prepareEventConfigForm(): FormGroup {
    const availableServices = Object.values(this.servicesData);

    const eventConfig = this.fb.group({
      tempUnitType: [this.propertyService.getSetting('ui.temp.unit')],
    });

    availableServices.forEach((service: any) => {
      if (service?.eventConfig) {
        const serviceName = service?.metadata?.name;
        const { critical, excursion, warning } = service?.eventConfig;
        
        const controlsMap = {
          ['is' + serviceName + 'Configured']: this.newEventConfigFormControl(
            excursion.configured
          ),
          ['is' + serviceName + 'CriticalConfigured']:
            this.newEventConfigFormControl(critical.configured),
          ['is' + serviceName + 'WarningConfigured']:
            this.newEventConfigFormControl(warning.configured),
        };

        if (service?.metadata?.limits === 'max') {
          let excursionVal = excursion.max>0? excursion.max:0;
          controlsMap['max' + serviceName] = this.newEventConfigFormControl(
            excursionVal,
            service?.metadata?.min,
            service?.metadata?.max
          );
          controlsMap['is' + serviceName + 'Configured'].valueChanges.pipe(startWith(excursion.configured),
          untilDestroyed(this),debounceTime(200)).subscribe(val=>{
            if(!val){
              controlsMap['max' + serviceName].reset();
              controlsMap['max' + serviceName].clearValidators();
            } else {
              this.updateEventConfigValidator(service?.metadata?.min, service?.metadata?.max, controlsMap['max' + serviceName]);
            }
            controlsMap['max' + serviceName].updateValueAndValidity();
          })
        } else if (service?.metadata?.limits === 'min') {
          controlsMap['min' + serviceName] = this.newEventConfigFormControl(
            0,
            service?.metadata?.min,
            service?.metadata?.max
          );
          controlsMap['is' + serviceName + 'Configured'].valueChanges.pipe(startWith(excursion.configured),
          untilDestroyed(this),debounceTime(200)).subscribe(val=>{
            if(!val){
              controlsMap['min' + serviceName].reset();
              controlsMap['min' + serviceName].clearValidators();
            } else {
              this.updateEventConfigValidator(service?.metadata?.min, service?.metadata?.max, controlsMap['min' + serviceName]);
            }
            controlsMap['min' + serviceName].updateValueAndValidity();
          })
        } else if (
          service?.metadata?.min === null &&
          service?.metadata?.max === null
        ) {
          controlsMap['min' + serviceName] = this.newEventConfigFormControl(0);
          controlsMap['max' + serviceName] = this.newEventConfigFormControl(0);
        }

        // Push all the current service controls to the all event config form controls
        _.forOwn(controlsMap, (val, key) => {
          eventConfig.addControl(key, val)
        });
      }
    });

    return eventConfig;
  }

  private updateEventConfigValidator(min, max, control) {
    const validators: ValidatorFn[] = [];
    if (!isNaN(min) && !isNaN(max) && min !== max) {
      validators.push(ClFormValidators.range(min, max), Validators.required);
    } else if (!isNaN(min)) {
      validators.push(Validators.min(min));
    } else if (!isNaN(max)) {
      validators.push(Validators.max(max));
    }

    control.setValidators(validators);
  }

  private updateConfigParamsValidators(
    fcCharacteristics: FormGroup,
    enabled: boolean
  ) {
    // If the toggle is not checked then clear the config params value
    const fcConfigParams = fcCharacteristics.get('configParams');
    const fcDeviceConfig = fcCharacteristics.get('_deviceConfig');
    // const fcDeviceConfigValue = fcDeviceConfig.value;
    // if(fcDeviceConfigValue?.type.toLowerCase() === 'toggle') {
    //   let toggleInput: SensorDeviceConfigParams = {
    //     enabled: enabled,
    //     uom: 'toggle',
    //     value : enabled ? "true" : "false"
    //   }
    //   fcConfigParams.patchValue(toggleInput);
    // }

    if(fcDeviceConfig.value.serviceIdentifier === 'Accelerometer'){
      if(fcConfigParams.value.enabled){
        this.isAccSamplingInterval = true;
      } else {
        this.isAccSamplingInterval = false;
      }
    }
    const fcValue = fcConfigParams.get('value');
    const fcUom = fcConfigParams.get('uom');

    // Ensure to clear all things before setting or resetting
    fcValue.clearValidators();
    fcUom.clearValidators();

    if (fcUom?.value !== 'toggle') {
      if (enabled) {
        // Enable validators
        fcValue.setValidators([Validators.required]);
        fcUom.setValidators([Validators.required]);
      } else {
        fcConfigParams.patchValue(this.defaultConfigParamsValue);
      }
    }

    fcValue.updateValueAndValidity();
    fcUom.updateValueAndValidity();
  }

  private prepareSensorDeviceConfigForm(): FormGroup[] {
    const form = this.deviceConfigData.map((config) => {
      const deviceConfigForm = this.newDeviceConfigForm;
      deviceConfigForm.patchValue(config);

      const characteristicsFa = deviceConfigForm.get(
        'characteristics'
      ) as FormArray;

      config.characteristics.forEach((characteristics: any) => {
        const formGroup = this.newDeviceCharacteristicsForm;


        if (characteristics?._deviceConfig?._uom?.type === 'checkbox') {
          const multiSelectFormGroup = this.fb.group({});
          characteristics?._deviceConfig?._uom?.list.forEach((c) => {
            const fc = new FormControl(c.defaultEnabled);
            // if (!c.canBeDisabled) {
              // fc.disable();
            // }
            multiSelectFormGroup.addControl(c.value, fc);
          });

          (formGroup.get('configParams') as FormGroup).addControl(
            'multiSelectFormGroup',
            multiSelectFormGroup
          );
          multiSelectFormGroup.valueChanges
            .pipe(startWith(multiSelectFormGroup.value), debounceTime(500), untilDestroyed(this))
            .subscribe((obj) => {
              let arr = [];
              _.forOwn(obj, (value, key) => {
                if (value === true) {
                  arr.push(key);
                }
              });
              formGroup
                .get('configParams')
                .get('value')
                .patchValue(arr.join(','));
              formGroup
                .get('configParams')
                .get('value')
                .updateValueAndValidity();
            });
        } else if (characteristics?._deviceConfig?._uom?.type === 'toggle') {
          characteristics.configParams.uom = 'toggle';
          characteristics.configParams.value =
            characteristics.configParams.enabled;
          formGroup
            .get('configParams.enabled')
            .valueChanges.pipe(debounceTime(500), untilDestroyed(this))
            .subscribe((val) => {
              formGroup.get('configParams.value').patchValue(val);
              formGroup.get('configParams.uom').patchValue('toggle');
            });
        } else {
          formGroup
          .get('configParams.enabled')
          .valueChanges.pipe(debounceTime(500), untilDestroyed(this))
          .subscribe((val) => {
            if(val){
              formGroup.get('configParams.value').patchValue(characteristics.configParams.value+'');
            }else {
              formGroup.get('configParams.value').patchValue(0+'');
            }
          });
        }
        formGroup.patchValue(characteristics);

        this.updateConfigParamsValidators(
          formGroup,
          Boolean(characteristics.configParams.enabled)
        );

        // const uom = formGroup.get('configParams.uom')
        // if (characteristics._deviceConfig._uom.type === UomTypeCustom.Select) {
        //   uom.patchValue(characteristics._deviceConfig._uom.uomKey);
        // }

        characteristicsFa.push(formGroup);
      });

      return deviceConfigForm;
    });

    return form;
  }

  private addFormControls() {
    // Remove all previous form controls
    this.fcDeviceConfig.clear();
    const configFg = this.prepareSensorDeviceConfigForm();

    configFg.forEach((configFc) => this.fcDeviceConfig.push(configFc));

    const eventConfigForm = this.prepareEventConfigForm();
    if (this.fcEventConfig) {
      this.deviceProfileForm.removeControl('eventConfig');
    }
    this.deviceProfileForm.addControl('eventConfig', eventConfigForm);

   // this.patchFormData();
    this.deviceProfileForm.updateValueAndValidity();
  }

  private hasRangeUomValidator(uom: SensorTypeUomTime): boolean {
    return this.hasMaxUomValidator(uom) && this.hasMinUomValidator(uom);
  }

  private hasMaxUomValidator(uom: SensorTypeUomTime): boolean {
    return !!(typeof uom.max !== 'undefined' || uom.max !== null);
  }

  private hasMinUomValidator(uom: SensorTypeUomTime): boolean {
    return !!(typeof uom.min !== 'undefined' || uom.min !== null);
  }

  resetEventValue(value, capability, threshold) {
    if (value == false) {
      switch (threshold) {
        case 'excursion':
          this.servicesData[capability]['eventConfig'] = {
            excursion: {
              configured: false,
              min: null,
              max: null,
            },
            critical: {
              configured: false,
              min: null,
              max: null,
            },
            warning: {
              configured: false,
              min: null,
              max: null,
            },
          };
          break;

        case 'critical':
          this.servicesData[capability]['eventConfig'].critical = {
            configured: false,
            min: null,
            max: null,
          };
          break;

        case 'warning':
          this.servicesData[capability]['eventConfig'].warning = {
            configured: false,
            min: null,
            max: null,
          };
          break;

        default:
          break;
      }
    }
  }

  private configureServicesMetaData(services) {
    services.forEach((service, index) => {
      let serviceName = service.identifier;
      if (!_.isEmpty(serviceName)) {
        if (
          serviceName === 'Temperature' ||
          serviceName === 'Ambienttemperature' ||
          serviceName === 'Cargotemperature'
        ) {
          service.minVal =
            service.minVal == '0'
              ? 0
              : parseFloat(service.minVal)
              ? this.temperature(parseFloat(service.minVal), service.unit)
              : null;
          service.maxVal =
            service.maxVal == '0'
              ? 0
              : parseFloat(service.maxVal)
              ? this.temperature(parseFloat(service.maxVal), service.unit)
              : null;
          this.setServiceMetadata(serviceName, service, 'both');
          // this.createObjForSlider(serviceName);
        } else if (serviceName.includes('Pressure')) {
          this.setServiceMetadata('Pressure', service, 'both');
          // this.createObjForSlider('Pressure');
        } else if (
          serviceName.includes('GPS') ||
          serviceName.includes('Location')
        ) {
          // Do nothing
        } else if (serviceName.includes('Battery')) {
          this.setServiceMetadata('Battery', service, 'min');
          // this.createObjForSlider('Battery');
        } else if (serviceName === 'Tilt') {
          this.setServiceMetadata('Tilt', service, 'min');
          // this.createObjForSlider('Tilt');
        } else if (serviceName.includes('Humidity')) {
          this.setServiceMetadata('Humidity', service, 'both');
          // this.createObjForSlider('Humidity');
        } else if (serviceName.toLowerCase() === 'shock') {
          this.setServiceMetadata('Shock', service, 'max');
          // this.createObjForSlider('Shock');
        } else if (serviceName.includes('Light')) {
          this.setServiceMetadata('Light', service, 'both');
          // this.createObjForSlider('Light');
        }
      }
    });

    // Remove the service which is not in the current sensor
    let availableServices = Object.keys(this.servicesData);
    var removeServices = _.difference(
      availableServices,
      this.sensorSupportedServices
    );
    if (removeServices.length) {
      removeServices.forEach((service) => {
        delete this.servicesData[service];
      });
    }
  }

  private setServiceMetadata(capability, serviceData, thresholdLimits) {
    this.sensorSupportedServices.push(capability);
    var sensor = {
      supports: true,
      name: capability,
      min:
        serviceData.minVal == '0'
          ? 0
          : parseFloat(serviceData.minVal)
          ? parseFloat(serviceData.minVal)
          : 0,
      max:
        serviceData.maxVal == '0'
          ? 0
          : parseFloat(serviceData.maxVal)
          ? parseFloat(serviceData.maxVal)
          : 0,
      unit: serviceData.unit,
      limits: thresholdLimits,
    };
    if (!this.servicesData[capability]) {
      this.servicesData[capability] = {};
    }
    this.servicesData[capability]['metadata'] = sensor;
    if (this.mode == 'add') {
      this.configureCapabilityData(capability, false);
    }
  }

  private configureDeviceConfigMetadata(customConfig) {
    var shockIndex = _.findIndex(customConfig, (config: any) => {
      return config.name === 'shock_sample';
    });

    if (shockIndex > -1) {
      var spliceArr = customConfig.splice(shockIndex, 1);
      customConfig.unshift(spliceArr[0]);
    }

    var accIndex = _.findIndex(customConfig, (config: any) => {
      return config.name === 'accelerometerMode';
    });
    if (accIndex > -1) {
      var spliceArr = customConfig.splice(accIndex, 1);
      customConfig.unshift(spliceArr[0]);
    }

    customConfig.forEach((deviceConfig, customIndex) => {

      var index = _.findIndex(this.deviceConfigData, (config: any) => {
        return config.name === deviceConfig.serviceIdentifier;
      });
      let uomKeys = Object.keys(deviceConfig.uom);
      let uomkey = uomKeys[0];
      let valueSets = [];
      let hasMultiSelect = false;
      let toggleOnly = false;
      let uomArray;

      if (
        deviceConfig.uom.hasOwnProperty('type') &&
        deviceConfig.uom.type === 'toggle'
      ) {
        toggleOnly = true;
        uomArray = null;
      } else {
        uomArray = uomKeys.map(function (key) {
          var val = deviceConfig.uom[key];
          if (typeof val === 'string') {
            val = JSON.parse(val);
          }
          if (val.hasOwnProperty('defaultEnabled')) {
            if (!hasMultiSelect) hasMultiSelect = true;
            val.checked = val.defaultEnabled;
          }
          return {
            key: key,
            value: val,
          };
        });
      }
      if (uomKeys.length === 1) {
        var hasvalueSet = deviceConfig.uom[uomkey].hasOwnProperty('valueSet');
        if (hasvalueSet) {
          valueSets = deviceConfig.uom[uomkey].valueSet.map((valueSet) => {
            return {
              label: valueSet + ' ' + uomkey,
              value: parseInt(valueSet, 10),
            };
          });
        }
      }

      let characteristicsObj = {
        identifier: deviceConfig.name,
        name: deviceConfig.name,
        metadata: {
          canBeDisabled: deviceConfig.canBeDisabled,
          defaultEnabled: deviceConfig.defaultEnabled,
          uom: uomArray,
          uiLabel: deviceConfig.uiLabel,
          hasvalueSet: hasvalueSet,
          hasMultiSelect: hasMultiSelect,
          valueSets: valueSets,
          min: '0',
        },
        configParams: {
          enabled: deviceConfig.defaultEnabled,
          value: hasvalueSet ? '' : 0,
          uom: hasvalueSet ? uomKeys[0] : '',
        },
        _deviceConfig: {},
      };

      characteristicsObj._deviceConfig = _.cloneDeep(deviceConfig);
      characteristicsObj._deviceConfig['_uom'] = this.prepareCustomUom(
        characteristicsObj._deviceConfig['uom']
      );

      if (hasMultiSelect) {
        characteristicsObj.configParams.uom = 'multiSelect';
        characteristicsObj.configParams.value = null;
      } else if (toggleOnly) {
        characteristicsObj.configParams.uom = 'toggle';
        characteristicsObj.configParams.value = null;
      }

      if (index === -1) {
        var configObj = {
          name: deviceConfig.serviceIdentifier,
          identifier: deviceConfig.serviceIdentifier,
          order: customIndex,
          characteristics: [characteristicsObj],
        };
        this.deviceConfigData.push(configObj);
      } else {
        this.deviceConfigData[index].characteristics.push(characteristicsObj);
      }
    });
  }

  private prepareCustomUom(_uom: SensorTypeUom): SensorTypeUomCustom {
    // Don't use the existing object
    const uom = _.cloneDeep(_uom);

    const config: SensorTypeUomCustom = {
      type: UomTypeCustom.Radio,
      list: [],
      uomKey: null,
    };

    const totalUom = _.size(uom);
    const uomItems: (SensorTypeUomTime | SensorTypeUomValues)[] =
      Object.values(uom);
    const firstItem = totalUom >= 1 ? uomItems[0] : null;

    if (totalUom === 1) {
      _.forOwn(uom, (val, key) => {
        // console.log("🚀 ~ file: sensor-profile-adder.component.ts ~ line 456 ~ SensorProfileAdderComponent ~ prepareSensorDeviceConfig ~ config", config)
        // console.log("🚀 ~ file: sensor-profile-adder.component.ts ~ line 454 ~ SensorProfileAdderComponent ~ prepareSensorDeviceConfig ~ config", config)
        config.type = val?.valueSet
          ? UomTypeCustom.Select
          : UomTypeCustom.Radio;
        config.uomKey = key;

        if (val.valueSet) {
          let labelKey = key != 'mode' ? key : ''
          config.list = val.valueSet.map((v, index) => ({
            label: `${v} ${labelKey}`,
            value: v,
            order: +(val?.sequenceNo || index),
          }));
        } else if ((val as any) === 'toggle') {
          config.type = UomTypeCustom.Toggle;
        } else {
          config.list.push({
            label: key,
            value: key,
            max: +(val as SensorTypeUomTime).max,
            min: +(val as SensorTypeUomTime).min,
            order: +(val?.sequenceNo || 0),
          });
        }
      });
    } else if (totalUom > 1) {
      config.type = firstItem.valueSet
        ? UomTypeCustom.Select
        : UomTypeCustom.Radio;

      try {
        const _firstItem = JSON.parse(firstItem as any);
        if (_firstItem.hasOwnProperty('defaultEnabled')) {
          config.type = UomTypeCustom.Checkbox;
        }
      } catch {}

      _.forOwn(uom, (val, key) => {
        try {
          val = JSON.parse(val as any);
        } catch {}
        config.list.push({
          label: key,
          value: key,
          max: +(val as SensorTypeUomTime).max,
          min: +(val as SensorTypeUomTime).min,
          order: +(val?.sequenceNo || 0),
          defaultEnabled: val.defaultEnabled,
          canBeDisabled: val.canBeDisabled,
        });
      });
    }

    // Order items by sequence number
    config.list = config.list.sort((a, b) =>
      a.order > b.order ? 1 : a.order < b.order ? -1 : 1
    );

    return config;
  }

  private configureDeviceConfigData(deviceConfigArr) {
    deviceConfigArr.forEach((deviceConfig, i) => {
      var index = _.findIndex(this.deviceConfigData, (config: any) => {
        return config.name === deviceConfig.name;
      });
      deviceConfig._deviceConfig = _.cloneDeep(deviceConfig);
      if (deviceConfig._deviceConfig.uom) {
        deviceConfig._deviceConfig._uom = this.prepareCustomUom(
          deviceConfig._deviceConfig.uom
        );
      }
      if (index > -1) {
        var selectedConfig = this.deviceConfigData[index];
        deviceConfig.characteristics.forEach((characteristic) => {
          var charIndex = _.findIndex(
            selectedConfig.characteristics,
            (char: any) => {
              return char.name === characteristic.name;
            }
          );
          if (charIndex > -1) {
            this.deviceConfigData[index].characteristics[
              charIndex
            ].configParams = {
              value: parseInt(characteristic.configParams.value),
              uom: characteristic.configParams.uom,
              enabled: characteristic.configParams.enabled
                ? JSON.parse(characteristic.configParams.enabled)
                : false,
            };
            if (characteristic.configParams.uom === 'multiSelect') {
              this.deviceConfigData[index].characteristics[
                charIndex
              ].configParams.value = characteristic.configParams.value;
            }
          }
        });
      }
    });
  }

  deviceConfigRadioChecked(unitVal, deviceConfigIndex, characteristicIndex) {
    if (
      typeof unitVal === 'object' &&
      !_.has(unitVal, 'min') &&
      !_.has(unitVal, 'max')
    ) {
      this.deviceConfigData[deviceConfigIndex].characteristics[
        characteristicIndex
      ].metadata.min = 0;
      this.deviceConfigData[deviceConfigIndex].characteristics[
        characteristicIndex
      ].metadata.max = null;
    } else if (
      typeof unitVal === 'object' &&
      _.has(unitVal, 'min') &&
      _.has(unitVal, 'max')
    ) {
      this.deviceConfigData[deviceConfigIndex].characteristics[
        characteristicIndex
      ].metadata.min = parseInt(unitVal.min, 10);
      this.deviceConfigData[deviceConfigIndex].characteristics[
        characteristicIndex
      ].metadata.max = parseInt(unitVal.max, 10);
    }
  }

  deviceConfigToggleClick(
    value,
    elementId,
    deviceConfigIndex,
    characteristicIndex
  ) {
    let elem = document.getElementById('deviceConfigValue' + elementId);
    if (value) {
      if (elem) elem.focus();
    } else {
      this.deviceConfigData[deviceConfigIndex].characteristics[
        characteristicIndex
      ].configParams = {
        enabled: false,
        value: '',
        uom: '',
      };
    }
  }

  formEventConfig(eventConfig) {
    var supportedServices = Object.keys(this.servicesData).filter(
      (key) =>
        this.servicesData[key].metadata &&
        this.servicesData[key].metadata.supports
    );

    supportedServices.forEach((service) => {
      if (this.servicesData[service].eventConfig) {
        if (this.servicesData[service].eventConfig.excursion) {
          eventConfig['min' + service] = eventConfig['min' + service]
            ? eventConfig['min' + service]
            : 0;
          eventConfig['max' + service] = eventConfig['max' + service]
            ? eventConfig['max' + service]
            : 0;
        }
      }
    });
  }

  submitEventProfiler() {
    this.isSaveInProgress = true;
    const deviceProfile = this.deviceProfileForm.value;
    deviceProfile['organization'] = this.deviceOrgName ? this.deviceProfileForm.value.deviceOrgName : this.groupObject.organization;
    this.formEventConfig(deviceProfile?.eventConfig);
    deviceProfile.deviceConfig.forEach((d) =>
      d.characteristics.forEach((c: any) => {
        delete c._deviceConfig;
        if (c.configParams && c.configParams.multiSelectFormGroup)
          delete c.configParams.multiSelectFormGroup;
      })
    );

    if (this.mode === 'add') {
      // deviceProfile['tenantId'] =this.deviceProfileForm?.controls['tenantId']
      //   ? this.deviceProfileForm.controls['tenantId'].value
      //   : this.userService.getEffectiveTenantId();
      this.deviceProfileService.saveEventProfile(deviceProfile).subscribe(
        (data) => {
          this.closePanel(true);
          let action = 'Device Profile created successfully';
          this.toastService.success(action, this.deviceProfileForm.value.eventProfileName);
        },
         (error) => {
          console.error(error);
          this.isSaveInProgress = false;
          let action = 'Something went wrong';
          this.toastService.error(action, this.deviceProfileForm.value.eventProfileName);
        }
      );
    } else {
      this.deviceProfileService.updateEventProfile(deviceProfile).subscribe(
        (data) => {
          this.closePanel(true);
          let action = 'Device Profile updated successfully';
          this.toastService.success(action, this.deviceProfileForm.value.eventProfileName);
        },
        function (error) {
          this.mode == 'Edit';
          console.error(error);
          this.isSaveInProgress = false;

          let action = 'Something went wrong';
          this.toastService.error(action, this.deviceProfileForm.value.eventProfileName);
        }
      );
    }
    this.mode = '';
  }

  closePanel(reload?) {
    if (reload) {
      this.closeSidePanel.emit({ action: 'reloadProfiles' });
    } else {
      this.closeSidePanel.emit(null);
    }
  }

  getOrgList(){
    this.tenantList = JSON.parse(sessionStorage.getItem('tenantList'));
    this.organizationService.getOrgList().subscribe((data: any) => {
      this.orgList = data;
      if(this.mode === 'add' && this.orgList.length>0 && this.isOrgAware){
        this.selectedOption = this.orgList[0].id;
      }
    });
  }

  onOrgclose(){
    this.deviceProfileForm.get('deviceOrgName').setValue('');
  }
}