<div class="mt-2 mb-2">
  <span class="page-title">App Settings</span>
</div>
<form #tenentSttingForm="ngForm" (ngSubmit)="onSubmitTenentForm(tenentSttingForm.value)">
  <div class="example-action-buttons mb-[10px]">
    <button class="color4 bcolor4 bgcolor32 p-4-10 border-general br2 mr-2" (click)="resetToFactoryDefaults()">
      <i class="fa fa-circle-o-notch fa-pulse" *ngIf="resetFactoryClicked"></i> Reset Options to Default
    </button>
    <button type="submit" [disabled]='!tenentSttingForm.valid'
      [ngClass]="[(!enableSave || !tenentSttingForm.valid) ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
      class="p-4-10 border-general br2 mr-2">
      Save
      <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="saveClicked"></i>
    </button>
  </div>
  <mat-accordion multi>
    <mat-expansion-panel [expanded]="true" *ngFor="let settings of AppSettingsDefinition">
      <mat-expansion-panel-header class="tenant-setting-expansion-header panelheader">
        <mat-panel-title>
          <div class="font-bold color-555">{{getKey(settings)[0]}}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="tanant-item__container mt10 ">
        <div class="tanant-item" *ngFor="let settingItem of settings[getKey(settings)[0]]">
          <div class="tanant-item__content">
            <div class="tanant-item__content__title">{{settingItem.displayLabel}}</div>
            <div class="tanant-item__content__description">{{settingItem.description}}</div>
          </div>
          <div class="tanant-item__form-field m-auto">
            <span *ngIf="settingItem.type == 'text'">
              <input type="text" class="basic-input" [minLength]="settingItem.minLength"
                [maxLength]="settingItem.maxLength" ngModel [name]="settingItem.mappingKey"
                (change)="checkForSaveEnabling($event, settingItem.mappingKey)"
                [required]="settingItem.required?settingItem.required:false">
            </span>
            <span *ngIf="settingItem.type == 'number'">
              <input type="number" class="basic-input" [required]="settingItem.required?settingItem.required:false"
                [min]="settingItem.min" [max]="settingItem.max" [step]="settingItem.step" ngModel
                [name]="settingItem.mappingKey" (change)="checkForSaveEnabling($event, settingItem.mappingKey)">
              <span class="error-message">Value should be <span *ngIf="settingItem.min != undefined">minimum
                  {{settingItem.min}}</span> <span *ngIf="settingItem.max != undefined"> and maximum
                  {{settingItem.max}}</span></span>
            </span>
            <span *ngIf="settingItem.type == 'mail'">
              <input type="email" class="basic-input" [required]="settingItem.required?settingItem.required:false"
                ngModel [name]="settingItem.mappingKey" (change)="checkForSaveEnabling($event, settingItem.mappingKey)"
                email>
              <span class="error-message">Please enter valid e-mail address</span>
            </span>
            <span *ngIf="settingItem.type == 'select'">
              <select (change)="checkForSaveEnabling($event, settingItem.mappingKey)" ngModel
                [required]="settingItem.required?settingItem.required:false" [name]="settingItem.mappingKey">
                <option *ngFor="let option of settingItem.options" [value]="option.value">{{option.label}}</option>
              </select>
            </span>

            <span *ngIf="settingItem.type == 'ruleSet'">
              <select (change)="checkForSaveEnabling($event, settingItem.mappingKey)" ngModel
                [required]="settingItem.required?settingItem.required:false" name="rulesetId">
                <option *ngFor="let option of rulesetOption" [value]="option.id">{{option.name}}</option>
              </select>
            </span>

            <span *ngIf="settingItem.type == 'radio'">
              <mat-radio-group class="foz12 capitalize" aria-label="Select an option" ngModel
                [name]="settingItem.mappingKey" [required]="settingItem.required?settingItem.required:false">
                <mat-radio-button *ngFor="let option of settingItem.options" [value]="option.value"
                  (change)="checkForSaveEnabling($event, settingItem.mappingKey)">{{option.label}}</mat-radio-button>
              </mat-radio-group>
            </span>

            <div *ngIf="settingItem.type == 'arrivalDelay'">
              <input type="text" hidden ngModel [name]="settingItem.mappingKey">
              <input type="number" ngModel name="arrival-delay-input" class="basic-input arrival-delay-input"
                [required]="settingItem.required?settingItem.required:false" [min]="settingItem.min"
                [max]="settingItem.max" [step]="settingItem.step"
                (change)="checkForSaveEnabling($event, settingItem.mappingKey)">
              <span class="error-message">Value should be <span *ngIf="settingItem.min != undefined">minimum
                  {{settingItem.min}}</span> <span *ngIf="settingItem.max != undefined"> and maximum
                  {{settingItem.max}}</span></span>
              <select class="arrival-delay-input" ngModel name="arrival-delay-select"
                (change)="checkForSaveEnabling($event, settingItem.mappingKey)"
                [required]="settingItem.required?settingItem.required:false">
                <option *ngFor="let option of settingItem.options" [value]="option.value">{{option.label}}</option>
              </select>
            </div>
          </div>
          <div class="m-auto-ml-5px">
            <button title="Reset" *ngIf="settingItem.reset" (click)="resetProperty(settingItem.mappingKey)">
              <i class="fa fa-history color4"></i>
            </button>
          </div>
        </div>
      </div>
      
    </mat-expansion-panel>
  </mat-accordion>
</form>