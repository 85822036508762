import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TenantService } from '../tenant.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'cl-tenant-side-panel',
  templateUrl: './tenant-side-panel.component.html',
  styleUrls: ['./tenant-side-panel.component.scss'],
})
export class TenantSidePanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() groupObject: any;
  @Input() mode: any;
  @Output() closeSidePanel= new EventEmitter<any>();
  isLoading = false;
  private subscriptions: Subscription[] = [];
  isSaveInProgress = false;
  tenantId = '';
  tenantDetails = {};
  statusEnums = ['ACTIVE', 'SUSPENDED'];
  editTenantPayload = {};

  addTenantPayLoad = {
    customer: {
      type: 'ORGANIZATION',
      status: 'ACTIVE',
      name: ''
    },
    userDTO: {
      userDevices: [{
        transport: "email",
        deviceIdentifier: ""
      }],
      id: '',
      name: ''
    }
  }

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private tenantService: TenantService
  ) { }
  tenantForm = this.fb.group({
    identifier: [{value : '', disabled: true}],
    name: ['', Validators.required],
    status: ['ACTIVE'],
    id: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    userName: ['', Validators.required]
  });

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.mode == 'edit') {
      this.tenantId = this.groupObject?.identifier;
      this.removeFormControls();
      this.getTenantDetails();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private removeFormControls() {
    if (this.tenantForm.get('id')) {
      this.tenantForm.removeControl('id');
    }
    if (this.tenantForm.get('email')) {
      this.tenantForm.removeControl('email');
    }
    if (this.tenantForm.get('userName')) {
      this.tenantForm.removeControl('userName');
    }
    this.tenantForm.updateValueAndValidity();
  }

  private getTenantDetails() {
    this.tenantService
      .getTenantDetails(this.tenantId)
      .subscribe((data: any) => {
        this.tenantDetails = data;
        this.tenantForm.patchValue(data);
      });
  }

  closePanel(reload?) {
    if(reload){
      this.closeSidePanel.emit({action: 'reloadUsers' });
    } else {
      this.closeSidePanel.emit(null)
    }
  }


  private createPayload() {
    if(this.mode === 'add'){
      this.addTenantPayLoad.customer.name = this.tenantForm.value.name;
      this.addTenantPayLoad.userDTO.userDevices[0].deviceIdentifier = this.tenantForm.value.email;
      this.addTenantPayLoad.userDTO.name = this.tenantForm.value.userName;
      this.addTenantPayLoad.userDTO.id = this.tenantForm.value.id;
    } else if(this.mode === 'edit'){
      this.editTenantPayload = _.cloneDeep(this.tenantDetails);
      delete this.editTenantPayload['appRoles'];
      delete this.editTenantPayload['pTypes'];
      this.editTenantPayload['tenantName'] = this.tenantForm.value.name;
      this.editTenantPayload['name'] = this.tenantForm.value.name;
      this.editTenantPayload['identifier'] = this.tenantForm.value.identifier;
      this.editTenantPayload['status'] = this.tenantForm.value.status;
    }
  }

  saveTenant() {
    this.createPayload();
    this.isSaveInProgress = true;
    const createTenantSubs: Subscription = this.tenantService
      .createTenant(this.addTenantPayLoad)
      .subscribe(
        (data) => {
          this.closePanel(true);
          let action = 'Tenant created successfully';
          this.openSnackBar(action, this.tenantForm.value.name);
        },
        (error) => {
          console.error(error);
          this.isSaveInProgress = false;

          let action = 'Something went wrong';
          this.openSnackBar(action, 'Tenant');
        }
      );
    this.subscriptions.push(createTenantSubs);
  }



  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  updateTenant() {
    this.createPayload();
    this.isSaveInProgress = true;
    const createTenantSubs: Subscription = this.tenantService
      .updateTenant(this.tenantId, this.editTenantPayload)
      .subscribe(
        (data) => {
          this.closePanel(true);
          let action = 'Tenant updated successfully';
          this.openSnackBar(action, this.tenantForm.value.name);
        },
        (error) => {
          console.error(error);
          this.isSaveInProgress = false;

          let action = 'Something went wrong';
          this.openSnackBar(action, 'Tenant');
        }
      );
    this.subscriptions.push(createTenantSubs);
  }
}
