import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { SimulateDataService } from '@cl/simulate/simulate-data.service';
import { Router } from "@angular/router"

@Component({
  selector: 'cl-simulate-list',
  templateUrl: './simulate-list.component.html',
  styleUrls: ['./simulate-list.component.scss']
})
export class SimulateListComponent implements OnInit {

  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  @ViewChild('simulationList') simulationList: any;
  @ViewChild('simuNameTemplate') simuNameTemplate: TemplateRef<any>;
  md: any;
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  public isLoading: boolean = false;
  public allLoaded: boolean = false;
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;

  scrollSize = 50;
  selectedSimulate: any;
  mainWidth: any;
  tableWidth: number;
  catalogType: string;
  catalogsTypeList: any = [];
  simulateDisabled: boolean = true;
  selectedType: any;
  limit: any = 50;
  offset: any = 0

  constructor(
    private el: ElementRef,
    private formateDatePipe: FormateDatePipe,
    private _simulateService: SimulateDataService,
    private router: Router
  ) { }


  ngOnInit(): void {
    let obj = []
    sessionStorage.setItem("userstreamData", JSON.stringify(obj))
    this.setcatalogList();
    this.setContainerWidth();
  }

  ngAfterViewInit(): void {
    this.columns = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true,
        frozenLeft: true
      },
      { name: "Name", prop: "properties.name" },
      { name: "Id", prop: "id" },
      { name: "Catalog Name", prop: "properties.catalogName" },
      { name: "Created By", prop: "properties.createdBy" },
      { name: "Created At", prop: "properties.createdAt",cellTemplate: this.dateTemplate },
      { name: "Modified By", prop: "properties.modifiedBy" },
      { name: "Modified At", prop: "properties.modifiedAt", cellTemplate: this.dateTemplate },
      { name: "Status", prop: "properties.status" }
    ];
  }

 

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    this.tableWidth = this.mainWidth;
  }

  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
      //get list
    }
  }

  getLoadMoreUpdate() {
    this.limit = 50;
    this.offset = this.offset + 50;
    this.getListOfSelectedInstance(this.selectedType);
  }

  goToSimulateDetails() {
    
    this.router.navigate(['/simulatedetails/' + this.selectedSimulate?.id])
  }

  public deselectRows() {
    this.selected = [];
    this.onSelect({ selected: [] });
  }

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selectedRows);

    this.simulateDisabled = true;
    if (this.selected.length == 1) {

      this.simulateDisabled = false;
      let selectedRow = selectedRows[0];
      this.selectedSimulate = selectedRow;
    }
  }

  getId(row) {
    return row.id;
  }

  expandTab(ind) {
    
    this.isLoading=true;
    
    let isopen = false;
    let type = ""
    this.rows = [];
    this.catalogsTypeList.forEach(function (item, index) {
      if (index == ind && !item.open) {
        item.open = true;
        isopen = true;
        type = item.type;
      } else {
        item.open = false;
      }
    })
    
    if (isopen) {
      this.limit = 50;
      this.offset = 0
      this.selectedType = type;
      this.getListOfSelectedInstance(this.selectedType);
    }

  }


  setcatalogList() {
    this._simulateService.getSimulateTypeCounts()
      .subscribe((data: any) => {
        let tempdata = data;
        tempdata.forEach(function (item, index) {
          if (index == 0) {
            item.open = true;
          } else {
            item.open = false;
          }

        })
        this.catalogsTypeList = tempdata;
        this.selectedType = this.catalogsTypeList[0].type;
        this.limit = 50;
        this.offset = 0
        this.rows = [];
        this.isLoading=true;
        this.getListOfSelectedInstance(this.catalogsTypeList[0].type);

      })


  }
  getTypeAndCountData() {
    this._simulateService.getSimulateTypeCounts()
      .subscribe((data: any) => {
      })
  }

  getListOfSelectedInstance(type) {
    
    this._simulateService.getSimulateTypeInstanceList(type, this.limit, this.offset)
      .subscribe((data: any) => {
        this.isLoading=false;
        
        this.rows = [...this.rows, ...data]; 
        this.allLoaded = false;
        if(this.rows.length<50){
          this.allLoaded = true;
        }     
        //this.rows =[...data];
        
      },error=>{this.isLoading=false})

  }

}
