
import { Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'cl-recipe-details',
  templateUrl: './recipe-details.component.html',
  styleUrls: ['./recipe-details.component.scss']
})
export class RecipeDetailsComponent implements OnInit {

  breadCrumbItems = [
    {'name': 'Previous', isBack: true, path: null},
    {'name': 'Recipe Details', path: null}
  ];
  topicList:any;
  triggerList:any;
  trigger:any
  isCatalogTypeError:boolean=false;
  

  constructor() { }

  ngOnInit(): void {  

   
    this.topicList =[
      {
        label: "STREAM",
        event: "STREAM",
        key: undefined
    },
    {
        label: "VIOLATION",
        event: "VIOLATION",
        key: undefined
    }
    ]
    
    this.triggerList = [
      {
        label: "FUNCTION",
        event: "function",
        key: null
      },
      {
        label: "UPDATE",
        event: "postConfigUpdate",
        key: null
      },
      {
        label: "CREATE",
        event: "postCreate",
        key: null
      },
      {
        label: "PROPERTIES",
        event: "postConfigUpdate",
        key: "<provided value>"
      },
      {
        label: "TIMER",
        event: "alarm",
        key: null,
        timer: "<provided value>"
      },
      {
        label: "MESSAGE",
        event: "message",
        key: null
      },
      {
        label: "UI",
        event: "UITrigger",
        key: undefined
    },
    // {
    //     label: "BRE",
    //     event: "bre",
    //     key: undefined,
    //     topic: "<provided value>"
    // }
      
    ];

  }
  

}
