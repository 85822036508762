import { Injectable } from '@angular/core';
import { ClGridColumnDefs, ESSortFields } from "../models/index";
import _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class SortUtilService {
  constructor() {}

  /**
   * Prepares sort fields used by ES from the current column definitions
   */
  prepareSortFields(columnDefs: ClGridColumnDefs[]): ESSortFields[] {
    let sortField: any[] = [];

    sortField = columnDefs
      .filter((c) => !_.isEmpty(c.sort?.direction))
      .map((c) => ({ fieldName: c.property, sortOrder: c.sort?.direction }));

    return sortField;
  }

  getSortFn(token: any, col: any): any {
    switch (token) {
      case "reverse_date":
        return (a: any, b: any) => {
          return a.properties[col.property] - b.properties[col.property];
        };
        break;
      case "forward_date":
        return (a: any, b: any) => {
          return b.properties[col.property] - a.properties[col.property];
        };
        break;
      case "forward_string":
        return (a: any, b: any) => {
          return (a?.properties[col.property] || "").localeCompare(b.properties[col.property] || "");
        };
        break;
      case "reverse_string":
        return (a: any, b: any) => {
          return (b?.properties[col.property] || "").localeCompare(a.properties[col.property] || "");
        };
    }
  }
  sortByColumn(list: any[], col: any) {
    let self = this;

    const dir = col.reverseSort ? "forward" : "reverse";
    const type = col.isDate ? "date" : "string";

    return list.sort(self.getSortFn(dir + "_" + type, col));
  }
}
