import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PropertyService } from "@cl/property/property.service";

@Pipe({
  name: 'formateDate'
})
export class FormateDatePipe extends DatePipe implements PipeTransform {
  dateFormat: any;
  constructor(
    private propertyService: PropertyService
  ) {
    super('en-US');
  }
  override transform(value: any, args?: any): any {
    this.dateFormat = this.propertyService.getSetting('ui.dateFormat');
    if(args == 'excludeTime'){
      if(this.dateFormat.includes('h')){
        this.dateFormat = this.dateFormat.split(' h')[0];
      }else{
        this.dateFormat = this.dateFormat.split(' H')[0];
      }
    }
     return super.transform(value, this.dateFormat);
  }
}
