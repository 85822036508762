import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private http: HttpClient, private apiProvider: ApiProviderService,) { }

  getOrgList(){
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/graph/org/list`
    );
  }
}
