<div class="mt-2 mb-2">
    <span class="page-title">Rule Sets</span>
</div>
<cl-table-surtitle [rows]="showingHits" [selected]="selected.length" [total]="totalHits" [pageName]="'Rule sets'"></cl-table-surtitle>
<div class="wt-ngx-grid-wrapper list-page-wrapper relative list-page px-2">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list">
            <div class="add-button" (click)="addRuleSet()"
                [clIsValidAction]="actionBtnPolicies.add"
                *ngIf="!hidePlusButton">
                <span class="fa fa-plus"></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
            <form [formGroup]="searchFormGroup" novalidate>
                <input type="search" class="list-page__search-field" placeholder="Search by Rule Set Name"
                formControlName="searchTerm" />
            </form>
            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2" (click)="editRuleSet()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>
            <button [disabled]="deleteDisabled"
                [clIsValidAction]="actionBtnPolicies.delete"
                [ngClass]="[deleteDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general ml-2 br2" (click)="deleteRuleSet()">
                <i class="fa fa-circle-o-notch fa-pulse" *ngIf="deleteInProgress"></i>
                <i class="fa fa-trash" *ngIf="!deleteInProgress"></i>
                Delete
            </button>
            <button [disabled]="cloneDisabled"
                [clIsValidAction]="actionBtnPolicies.clone"
                [ngClass]="[cloneDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general ml-2 br2" (click)="cloneRuleSet()">
                <i class="fa fa-copy"></i>
                Clone
            </button>
        </div>
        <div class="sensor-febric__action-panel-right action-panel-right self-end flex items-center">
            <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'ruleSet'">
            </cl-grid-column-toggle>
        </div>
    </div>
    <div class="h-calc-40">
        <main class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth - 10">
            <div class="grid-container grow relative">
                <div *ngIf="delayedRefreshStart">
                    <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                    (refreshList)="getRuleSetsData()"
                    ></cl-refresh-countdown>
                </div>
                <div class="h-100 w-100">
                    <ngx-datatable class="h-calc-40 " #table [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" (reorder) = "columnReordered($event)" [sortType]="sortType" [selectionType]="selection"
                        [rowIdentity]="getId" [selectAllRowsOnPage]="false" (select)='onSelect($event)' [reorderable]="false">
                    </ngx-datatable>
                </div>
                <div class="h-100" *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
            </div>
        </main>
    </div>
    <ng-template #ruleNameTemplate let-row="row" let-value="value" let-i="index">
        <a class="font-12-bold" href (click)="loadRulesetSummary(row.id, $event)">{{value}}</a>
    </ng-template>

    <div *ngIf="sidePanelsObj.add || sidePanelsObj.edit || sidePanelsObj.clone">
        <cl-add-rule-set [ruleSetObject]="selectedRuleSet"
            [newOrEdit]="newOrEdit"
            (closeSidePanel)="closeSidePanel($event)"></cl-add-rule-set>
    </div>

    <div *ngIf="sidePanelsObj.summary">
        <cl-ruleset-summary [selectedRulesetId]="selectedRulesetId" (closeSidePanel)="closeSidePanel();selectedRulesetId = null"></cl-ruleset-summary>
    </div>
</div>

<div class="popover__wrapper" *ngIf="ruleSetUpdateInfo">
    <div class="popover__content display-content">
        <div class="pop">
            <div class="model-body">
                <div class="icon-alignment"><i class="fa fa-times-circle-o mr-4" (click)="closemodel()"></i></div>
                <div class="lease-summary">
                  <div class="grid-30-70 topmargin ml-2">
                    <!-- <span class="font-14 font-bold"><i class="fa fa-info-circle help-icon color4 text-base mr-4" style="font-size:medium"></i></span> -->
                    <span class="font-14">Ruleset changes may take up to 1 hour to take effect.</span>
                 </div>
                </div>
            </div>
        </div>
    </div>
</div>
