<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title side-panel-header-bg justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/asset.svg" />
            <span class="summary-title font-bold">{{'assets.assetSummary'|translate}}</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content relative mt-4">
        <a href="#/assetdetails/{{assetId}}" class="fs14 ml-4 mb-2 mt10 font-bold">{{assetObject?.name}}</a>
        <div *ngIf="activePane === 'summary'">
            <div class="flex h-16 px-2">
                <img [src]="assetAvatar || defaultAssetImage" class="h-16 w-16" />
                <div class="h-full flex flex-col justify-center ml-4">
                    <label
                    for="assetAvatar"
                    class="cursor-pointer font-bold"
                    [ngClass]="isUploading ? 'color37' : 'color4'"
                    >{{'assets.replaceImage'|translate}}<i class="fa fa-info-circle ml-2 color13" title="Acceptable file formats: JPG, PNG, max. 2mb"></i></label
                    >
                    <input
                    [disabled]="isUploading"
                    type="file"
                    class="hidden"
                    accept="image/png, image/jpg, image/jpeg"
                    id="assetAvatar"
                    (change)="onAvatarChange($event)"
                    />
                </div>
            </div>

            <div class="p-4">
                <div class="flex fs14 color16 mt-1">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Name</div>
                    <div class="basis-2/4 ellipsis" style="word-break:break-word" [title]="assetObject?.properties?.name">{{assetObject?.properties?.name}}</div>
                </div>
                <div class="flex fs14 color16 mt-1">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Asset ID</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.externalId}}</div>
                </div>
                <div class="flex fs14 color16 mt-1">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Asset Type</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.categoryName}}</div>
                </div>
                <div class="flex fs14 color16 mt-1">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Catalog Type</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.properties?.parentEntityTypeKey | titlecase}}</div>
                </div>
                <div class="flex fs14 color16 mt-1">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Monitored Status</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.monitoredStatus}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.status">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Status</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.status}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.location">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Location</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.location}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.monitoredStatus?.toLowerCase() === 'monitored' && assetObject?.location === 'Out Of Coverage' && assetObject?.lastLocation">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Last Location</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.lastLocation}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="(assetObject?.properties?.eventPublisherMap | keyvalue)?.length > 0">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Device ID</div>
                    <div class="basis-2/4" style="word-break:break-word">
                      <span *ngFor="let sensor of assetObject?.properties?.eventPublisherMap | keyvalue ;let i=index; let last = last">
                        <span [routerLink]="'/sensordetails/' + sensor.key">
                          <a href="javascript:void(0)" class="grow" style="word-break: break-word;" >{{sensor.key}}  
                            <ng-container *ngIf="sensor.value?.['componentName']?.trim()">({{sensor.value?.['componentName']?.trim()}})</ng-container>
                        </a>
                        </span><span *ngIf="!last">, </span>
                      </span>
                    </div>
                </div>

                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.shipment">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Active Shipment</div>
                    <div class="basis-2/4 ellipsis"style="word-break:break-word" [title]="assetObject?.shipment?.name">
                        <a href="#/shipmentdetails/{{assetObject?.shipment?.id}}?type={{assetObject?.shipment?.type}}" >{{assetObject?.shipment?.name}}</a>
                    </div>
                   </div>
                  
                  <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.sensorBindedDate">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Bound</div>
                    <div class="basis-2/4" style="word-break:break-word" [textContent]="datePipe.transform(assetObject?.sensorBindedDate)"></div>
                  </div>
                  
                <!-- <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyStatus">
                    <div class="basis-2/4 font-bold">Custody Status</div>
                    <div class="basis-2/4">{{assetObject?.custodyStatus}}</div>
                </div>                
                
                
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.properties?.organizationName">
                    <div class="basis-2/4 font-bold">Organization</div>
                    <div class="basis-2/4">{{assetObject?.properties?.organizationName}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.qrBarCode">
                    <div class="basis-2/4 font-bold">QR code</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.qrBarCode}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.sku">
                    <div class="basis-2/4 font-bold">SKU</div>
                    <div class="basis-2/4">{{assetObject?.sku}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.itemCategory && isCustodyAsset">
                  <div class="basis-2/4 font-bold">Item Category</div>
                  <div class="basis-2/4">{{assetObject?.itemCategory}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.cost">
                    <div class="basis-2/4 font-bold">Cost</div>
                    <div class="basis-2/4">{{assetObject?.cost}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.condition">
                    <div class="basis-2/4 font-bold">Condition</div>
                    <div class="basis-2/4">{{assetObject?.condition}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.lifecycleStatus">
                    <div class="basis-2/4 font-bold">Lifecycle Status</div>
                    <div class="basis-2/4">{{assetObject?.lifecycleStatus}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.checkedoutBy">
                    <div class="basis-2/4 font-bold">Checked Out By</div>
                    <div class="basis-2/4">{{assetObject?.checkedoutBy}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.checkedoutToUserId">
                    <div class="basis-2/4 font-bold">Checked Out To</div>
                    <div class="basis-2/4">{{assetObject?.checkedoutToUserId}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.checkedoutTo">
                    <div class="basis-2/4 font-bold">Checked Out To User</div>
                    <div class="basis-2/4">{{assetObject?.checkedoutTo}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.checkedoutAt">
                    <div class="basis-2/4 font-bold">Checked Out At</div>
                    <div class="basis-2/4">{{datePipe.transform(assetObject?.checkedoutAt)}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.fromParty">
                    <div class="basis-2/4 font-bold">From Party</div>
                    <div class="basis-2/4">{{assetObject?.fromParty}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.toParty">
                    <div class="basis-2/4 font-bold">To Party</div>
                    <div class="basis-2/4">{{assetObject?.toParty}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.itemCategory">
                    <div class="basis-2/4 font-bold">Item Category</div>
                    <div class="basis-2/4">{{assetObject?.itemCategory}}</div>
                </div>
                  <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditId">
                    <div class="basis-2/4 font-bold">Custody Audit Id</div>
                    <div class="basis-2/4">{{assetObject?.custodyAuditId}}</div>
                  </div>
                  <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditVersion">
                    <div class="basis-2/4 font-bold">Custody Audit Version</div>
                    <div class="basis-2/4">{{assetObject?.custodyAuditVersion}}</div>
                  </div>
                  <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditStatus">
                    <div class="basis-2/4 font-bold">Custody Audit Status</div>
                    <div class="basis-2/4">{{assetObject?.custodyAuditStatus}}</div>
                  </div>
                  <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditBy">
                    <div class="basis-2/4 font-bold">Custody Audit By</div>
                    <div class="basis-2/4">{{assetObject?.custodyAuditBy}}</div>
                  </div>
                  <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditPending">
                    <div class="basis-2/4 font-bold">Custody Audit Pending</div>
                    <div class="basis-2/4">{{assetObject?.custodyAuditPending}}</div>
                  </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditInitiatedAt">
                  <div class="basis-2/4 font-bold">Custody Audit Initiated At</div>
                  <div class="basis-2/4">{{assetObject?.custodyAuditInitiatedAt | date: 'MM/dd/yyyy hh:mm:ss a'}}</div>
                </div>

                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditBegunAt">
                  <div class="basis-2/4 font-bold">Custody Audit Begun At</div>
                  <div class="basis-2/4">{{assetObject?.custodyAuditBegunAt | date: 'MM/dd/yyyy hh:mm:ss a'}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.custodyAuditCompletedAt">
                    <div class="basis-2/4 font-bold">Custody Audit Completed At</div>
                    <div class="basis-2/4">{{assetObject?.custodyAuditCompletedAt | date: 'MM/dd/yyyy hh:mm:ss a'}}</div>
                  </div>
                  <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.lastCustodyAuditId">
                    <div class="basis-2/4 font-bold">Last Custody Audited Id</div>
                    <div class="basis-2/4">{{assetObject?.lastCustodyAuditId}}</div>
                  </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.lastCustodyAuditBy">
                  <div class="basis-2/4 font-bold">Last Custody Audited By</div>
                  <div class="basis-2/4">{{assetObject?.lastCustodyAuditBy}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.lastCustodyAuditAt">
                    <div class="basis-2/4 font-bold">Last Custody Audited At</div>
                    <div class="basis-2/4">{{datePipe.transform(assetObject?.lastCustodyAuditAt)}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.lastCustodyStatusUpdateAt">
                    <div class="basis-2/4 font-bold">Last Custody Update At</div>
                    <div class="basis-2/4">{{datePipe.transform(assetObject?.lastCustodyStatusUpdateAt)}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.manufacturer">
                    <div class="basis-2/4 font-bold">Manufacturer</div>
                    <div class="basis-2/4">{{assetObject?.manufacturer}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.warehouseLocation">
                    <div class="basis-2/4 font-bold">Storage Location</div>
                    <div class="basis-2/4">{{assetObject?.warehouseLocation}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.maintenanceDate">
                    <div class="basis-2/4 font-bold">Maintenance Date</div>
                    <div class="basis-2/4">{{datePipe.transform(assetObject?.maintenanceDate)}}</div>
                </div> -->
                <ng-container *ngFor="let cdmField of systemFields">
                    <div class="flex fs14 color16 mt-1" *ngIf="getExtendedAttributeValue(cdmField, assetObject?.properties)">
                      <div class="basis-2/4 font-bold" style="word-break:break-word" [textContent]="cdmField?.displayLabel"></div>
                      <div class="basis-2/4" style="word-break:break-word" [textContent]="getExtendedAttributeValue(cdmField, assetObject?.properties)"></div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let cdmField of userFields">
                    <div class="flex fs14 color16 mt-1" *ngIf="getExtendedAttributeValue(cdmField, assetObject?.properties)">
                      <div class="basis-2/4 font-bold" style="word-break:break-word" [textContent]="cdmField?.displayLabel"></div>
                      <div class="basis-2/4" style="word-wrap:break-word"[textContent]="getExtendedAttributeValue(cdmField, assetObject?.properties)"></div>
                    </div>
                </ng-container>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.createdAt">
                    <div class="basis-2/4 font-bold"style="word-break:break-word">Created At</div>
                    <div class="basis-2/4" style="word-wrap:break-word">{{datePipe.transform(assetObject?.createdAt)}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.createdBy">
                    <div class="basis-2/4 font-bold"style="word-break:break-word">Created By</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.createdBy}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.modifiedAt">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Modified At</div>
                    <div class="basis-2/4" style="word-break:break-word">{{datePipe.transform(assetObject?.modifiedAt)}}</div>
                </div>
                <div class="flex fs14 color16 mt-1" *ngIf="assetObject?.modifiedBy">
                    <div class="basis-2/4 font-bold" style="word-break:break-word">Modified By</div>
                    <div class="basis-2/4" style="word-break:break-word">{{assetObject?.modifiedBy}}</div>
                </div>
            </div>
            <cl-asset-capabilities [asset]="assetObject" [pane]="'vertical'"></cl-asset-capabilities>
        </div>
        <div class="spin-pos" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
        <div *ngIf="activePane === 'history'">
            <div class="p-4">
                <div *ngIf="assetObject?.monitoredStatus?.toLowerCase() === 'monitored'">
                    <div class="mb-2 fs14 font-bold">Location</div>
                    <div class="bgcolor32 mb-4 scroll-wrapper">
                        <cl-asset-location [asset]="assetObject"></cl-asset-location>
                    </div>
                </div>

                <div>
                    <div class="mb-2 fs14 font-bold">Custody</div>
                    <div class="bgcolor32 mb-4 scroll-wrapper">
                        <cl-asset-custody [asset]="assetObject" [panel]="'summary'"></cl-asset-custody>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <div class="flex justify-end m-2">
        <button class="panel-button color4 bgcolor2 bcolor4 bw-1">
            <a href="#/assetdetails/{{assetId}}?type={{assetType}}" class="color4">
                Asset Details
                <i class="fa fa-arrow-right ml-1 color4"></i>
            </a>
          </button>
    </div>
    <section class="cl-side-panel-footer bgcolor33">
        <div class="flex mt-2">
            <div class="flex-col grow fs11 text-center" (click)="activePane = 'summary'; toggleActivePane('summary')">
                <img src="assets/svgs/circle-icons-blue/message.svg" class="m-auto"
                    [ngClass]="{'hidden': activePane !== 'summary'}" />
                <img src="assets/svgs/circle-icons-white/message.svg" class="m-auto"
                    [ngClass]="{'hidden': activePane == 'summary'}" />
                <label>{{'assets.summary'|translate}}</label>
            </div>
            <div class="flex-col grow fs11 text-center" (click)="activePane = 'history';toggleActivePane('history')">
                <img src="assets/svgs/circle-icons-blue/dwelltime.svg" class="m-auto"
                    [ngClass]="{'hidden': activePane !== 'history'}" />
                <img src="assets/svgs/cl-icons/CLF_UI_Icons__pin-route_blue.svg" class="m-auto"
                    [ngClass]="{'hidden': activePane == 'history'}" />
                <label>{{'assets.history'|translate}}</label>
            </div>
        </div>
    </section>
    <cl-coc-panel *ngIf="showCocPanel" (closeCOCPanel)="closeCOCPanel()"></cl-coc-panel>

</div>
