<!-- <cl-carousel-controls [hidden]="!numScreens" (onDotSelected)="controlsDotSelected($event)"
  (onRightArrowClicked)="controlsRightArrowClicked($event)" (onLeftArrowClicked)="controlsLeftArrowClicked($event)"
  [screenNum]="screenNum" [numScreens]="numScreens"></cl-carousel-controls> -->
<div class="wt__carousel-container">
  <div class="wt__carousel-slider">
    <div class="wt__carousel wt__info-cards pb-1">
      <div class="wt__full-frame" #fullframe [ngClass]='numCardsClass'>
        <cl-card *ngFor="let card of cards" class="wt__info-card" [style]="{ 'flex-basis':'calc(' + cardSize + 'px - 16px)' }" (onFaveToggled)="onFaveToggled($event)" [card]="card"
          [selectedDt]="selectedDt" [DTs]="DTs" [tenancyData]="tenancyData" (tenancyEmitter)="getTenancyUpdate($event)">
        </cl-card>
      </div>
    </div>
  </div>
</div>
