import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/common/constants';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, of, Subject, Subscription, takeUntil, firstValueFrom } from 'rxjs';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { RulesService } from '../rules.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import _ from 'lodash';
import { RuleUtilService } from '../rule-util.service';
import { RuleTypes } from '@cl/@types/rule.type';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FilterList, RuleFilterListItem } from '@cl/@types/filter-list.type';
import { ToastService } from '@cl/common/services/toast.service';
import { TranslatePipe } from '@ngx-translate/core';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { UserService } from '@cl/user/user.service';

@Component({
  selector: 'cl-rule-list',
  templateUrl: './rule-list.component.html',
  styleUrls: ['./rule-list.component.scss']
})
export class RuleListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ruleNameTemplate') ruleNameTemplate: TemplateRef<any>;
  @ViewChild('enableDisableRuleTemplate') enableDisableRuleTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  selectedRows: any[] = [];
  showingHits: number;
  totalHits: number;
  hidePlusButton: boolean = false;
  sidePanelsObj: any = {
    add: false,
    edit: false
  };
  actionBtnPolicies = {
    add: ['RECIPE_CREATE'],
    edit: ['RECIPE_UPDATE'],
    delete: ['RECIPE_DELETE']
  };
  showFilterPanel = true;
  searchFormGroup: FormGroup;
  searchTerm: string = '';
  editDisabled: boolean = true;
  deleteDisabled: boolean = true;
  mainWidth: any;
  tableWidth: number;
  public rows: any = [];
  tableRowData = [];
  filters: FilterList[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;

  private readonly destroyed$ = new Subject<boolean>();
  selectedRule: any;
  triggerTemplates: any;
  columnKeys: any = [];
  triggers: string[];
  isOrgRestrictedUser:boolean;
  delayedRefreshStart = false;
  refreshTimer = 5;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private csvExport: CsvExportsService,
    private rulesService: RulesService,
    private ruleUtilService: RuleUtilService,
    private _utilsService: UtilsService,
    public dialog: MatDialog,
    private toastService: ToastService,
    private translatePipe: TranslatePipe,
    private persistenceStore: PersistenceService,
    private formateDatePipe: FormateDatePipe,
    public _userService:UserService,

  ) { }

  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get("searchTerm") as FormControl;
  }
  enableOrDisable(rowObject){
    this.isLoading = true;
    let isEnabled = rowObject.active;
    this.ruleUtilService.updateRule(rowObject).then(() => {
      if(isEnabled){
        this.toastService.success(this.translatePipe.transform('rules.SUCCESS_ENABLE'));
      }else{
        this.toastService.success(this.translatePipe.transform('rules.SUCCESS_DISABLE'));
      }
      this.isLoading = false;
    }).catch(() => {
      rowObject.active = !rowObject.active;
      if(isEnabled){
        this.toastService.warning(this.translatePipe.transform('rules.FAILED_ENABLE'));
      }else{
        this.toastService.warning(this.translatePipe.transform('rules.FAILED_DISABLE'));
      }
      this.isLoading = false;
    });
  }
  ngOnInit(): void {
    this.isOrgRestrictedUser = this._userService.getUser()?.orgAware;
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
    this.loadRulesData();
    this.setContainerWidth();
  }

  ngAfterViewInit(): void {

      this.rawColumns = [
        { prop: 'selected', name: '', sortable: false, canAutoResize: false, draggable: false, resizable: false, checkboxable: true, width: 30, visible: true, headerCheckboxable:true },
        { name: 'Rule Name', prop: 'title', minWidth: 180, cellTemplate: this.ruleNameTemplate, visible: true },
        { name: 'Organization', prop: 'organizationName', minWidth: 180, visible: true },
        { name: 'Rule Set(s)', prop: 'rulesetNameFormatted', minWidth: 150, visible: true },
        { name: 'Created At', prop: 'createdAtFormatted', minWidth: 150, visible: true },
        { name: 'Created By', prop: 'userId', minWidth: 150, visible: true },
        { name: 'Modified At', prop: 'modifiedAtFormatted', minWidth: 150, visible: true },
        { name: 'Modified By', prop: 'modifiedBy', minWidth: 150, visible: true },
        { name: 'Enable', prop: 'active', minWidth: 60, cellTemplate: this.enableDisableRuleTemplate, visible: true },
      ];
      const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('rule', [...this.rawColumns]);
      this.rawColumns = [...rawColumns];
      this.columns = [...gridColumns];
      this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
      this._changeDetectorRef.detectChanges();
  }

  getRulesTotalCount() {
    const totalSub: Subscription = this.rulesService.getRules().subscribe({
      next: (res: any[]) => {
        this.totalHits = (res && res.length) || 0;
      },
      error: err => {
        console.error(err);
      }
    });
    this.subscriptions.push(totalSub);
  }

  loadRulesData(){
    if(this.delayedRefreshStart){
      this.delayedRefreshStart = false
    }
    this.getRulesTotalCount();
    this.getRulesData();
  }

  getRulesData() {
    this.isLoading = true;
    const rulesData = this.rulesService.getRules(this.triggers ?? []).pipe(
      catchError((err) => {
        console.warn("Error getting Rules list data", err);
        return of([] as any[]);
      })
    );

    this.ruleUtilService.getTriggerTemplates()
    .then((result: RuleTypes)=>{
      this.triggerTemplates = result; 
    });

    this.ruleUtilService.getFilterTriggerTemplates().then((result: RuleTypes)=>{
      this.filters = this.ruleUtilService.getRulesFilterList(result);
    })


    const rulesSub: Subscription = combineLatest([rulesData]).subscribe(
      (data: any) => {
        this.setRules(data[0]);
      });
    this.subscriptions.push(rulesSub);
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'rule', this.table._internalColumns);   
  }

  setRules(data : any[]){
    this.isLoading = true;
    this.showingHits = _.isEmpty(data) ? 0 : data.length;
    let rulesList = data.map(rule => {
      rule.createdAtFormatted = rule.createdAt ? this.formateDatePipe.transform(rule.createdAt) : '';
      rule.modifiedAtFormatted = rule.modifiedAt ? this.formateDatePipe.transform(rule.modifiedAt) : '';
      rule.rulesetNameFormatted = rule.rulesetName.join(',');
      return rule;
    });
    this.rows = [...rulesList];
    this.tableRowData = [...this.rows];
    this.onSearchInput(this.searchTerm);
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
    setTimeout(() => {
      this.table?.recalculate();
    }, 10);
  }

  onSearchInput(term) {
    this.searchTerm = term.toLowerCase();
    let temp;
    const tableRowData = [...this.tableRowData];
    if(this.searchTerm){
       temp = tableRowData.filter( (d:any)=> {
        return (d.title.toLowerCase().indexOf(this.searchTerm) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    this.showingHits = _.isEmpty(temp) ? 0 : temp.length;
    this.rows = temp;
  }

  addRule() {
    this.selectedRule = null;
    this.hidePlusButton = true;
    this.sidePanelsObj.add = true;
  }

  editRule() {
    if(!_.isEmpty(this.selectedRows)){
      this.selectedRule = this.selectedRows[0];
    }
    this.hidePlusButton = true;
    this.sidePanelsObj.add = true;
  }
  cloneRule(){
    if(!_.isEmpty(this.selectedRows)){
      this.selectedRule = this.selectedRows[0];
      delete this.selectedRule['id'];
    }
    this.hidePlusButton = true;
    this.sidePanelsObj.add = true;
  }
  deleteRule() {
    if(!_.isEmpty(this.selectedRows)){
      this.selectedRule = this.selectedRows[0];
    }
    let message = `Are you sure you want to delete this rule?: ` + this.selectedRule.title;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.ruleUtilService.deleteRule(this.selectedRule.id)
        .then((result: boolean)=>{
          if(result){
            this.selectedRule = null;
            this.selected = [];
            this.editDisabled = true;
            this.deleteDisabled = true;
            this.delayedRefreshStart = true;
          }
        });
      }else{
        this.isLoading = false;
      }
    });
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 268;
    } else {
      this.tableWidth = this.mainWidth - 18;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  downloadReport(reportType: string) {
    if (reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Rules-list', _.map(this.columns, 'name'));
    }
  }

  getPanelUpdate(event: any) {
    if(event.action === 'clearFilters'){
      this.triggers = [];
      let temp = this.filters.map((item : FilterList)=>{
        item.list = item.list.map((filterItem: RuleFilterListItem)=>{
          filterItem.checked = false;
          return filterItem;
        });
        return item;
      });
      this.filters = temp;

    }else{
      if(event.filter){
        if(event.filter.checked){
          this.triggers =  _.union(event.filter.triggers, this.triggers);
        }else{
          this.triggers = _.without(this.triggers, ...event.filter.triggers);
        }
      }else{
        this.triggers = [];
      }
    }
    const obs$ = this.rulesService.getRules(this.triggers ?? []);
    firstValueFrom(obs$).then((result : any)=>{
      this.setRules(result);
    });
  }

  getId(row) {
    return row.id;
  }

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selectedRows = selectedRows;
    if (selectedRows.length === 1) {
      this.selectedRule = rowData.selected[0];
      if(rowData.selected[0].organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
        this.deleteDisabled = true;
        this.editDisabled = true;
     }else{
       this.deleteDisabled = false;
       this.editDisabled = false;
     }
    } else {
      this.selectedRule = null;
      this.editDisabled = true;
      this.deleteDisabled = true;
    }
  }

  closeSidePanel(refresh?: boolean) {
    if(!_.isEmpty(this.selectedRows) && !refresh){
      this.selectedRule = this.selectedRows[0];
    }
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    this.hidePlusButton = false;
    if(refresh){
      this.editDisabled = true;
      this.deleteDisabled = true;
      this.selected = [];
      this.delayedRefreshStart = true;
    }
  }
  updateFilter(term){
    this.onSearchInput(term);
  }
}
