import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizeService } from '@cl/@authorize/authorize.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
import { EntityService } from '@cl/common/services/entity-list.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import _ from 'lodash';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { BulkOperaionsService } from '../bulk-operations-service';

@Component({
  selector: 'cl-bulk-operation-list',
  templateUrl: './bulk-operation-list.component.html',
  styleUrls: ['./bulk-operation-list.component.scss']
})
export class BulkOperationListComponent implements OnInit, AfterViewInit{
  @ViewChild('BulkNameTemplate') bulkNameTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  // @ViewChild('NotificationTo', { static: true }) NotificationTo: TemplateRef<any>;
  // @ViewChild('NotificationOn', { static: true }) NotificationOn: TemplateRef<any>;
  @ViewChild('table') table;
  private event = new Subject<any>();
  mainVal:any;
  mainWidth: any;
  systemColumns = []
  public isLoading: boolean = false;
  tableWidth: number;
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  public rows: any = [];
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  allLoaded: boolean = false;
  showFilterPanel = true;
  filters: any[] = [];
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  list:any;
  showSidePanel:boolean=false;
  bulkObject:any ={};
  masterData:any;
  showingHits: number;
  totalHits: number;
  private subscriptions: Subscription[] = [];
  public tableRowData: any = [];
  initialFilters: any[] = [];
  selectedFilters: any = {};
  private payLoadForBulkList = {
    "status":[],
    "source":null,
    "fromDate":null,
    "toDate":null,
    "entityType":[],
    "searchText":null,
  }
  dateRangeFilter: {
    range: { startDate: moment.Moment; endDate: moment.Moment };
    options: string[];
  } = {
    range: null,
    options: [],
  };

  private dateFilterObj={
    "defaultRange": null,
    "collapsed": false,
    "expand": true,
    "enableCollapsing": false,
    "filterType": "dateRange",
    "label": "Date Range",
    "type": "dateRangeFilter",
}
actionBtnPolicies = {
  showSummary: ['BULK_DATA_JOB_DETAIL']
}
isShowSummary=false;
  constructor(
    private _authService:AuthorizeService,
    private router: Router,
    public bulkService:BulkOperaionsService,
    private el: ElementRef, 
    private entityService: EntityService,
    private formateDatePipe: FormateDatePipe,
    private _utils: UtilsService,
    private csvExport: CsvExportsService,
    ) { }

  ngOnInit(): void {
    this.mainVal=this.router.url;
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        // this.showSummaryPanel = true;
        // this.roleObject = evt.roleObject;
      }
    });
     this.isShowSummary = this._authService.isValidAction(
          this.actionBtnPolicies.showSummary
        )
  }
  ngAfterViewInit(): void {
    this.columns =  [
      {
        prop: 'selected',
        resizable: false,
        canAutoResize: false,
        sortable: false,
        name: '',
        width: 30,
        visible: true,
        headerCheckboxable: true,
        checkboxable: true,
        frozenLeft: true,
      },
      { name: 'Job ID', prop: 'id', width: 250, cellTemplate: this.bulkNameTemplate, visible: true, frozenLeft: true },
      { name: 'Source', prop: 'source', width: 100, visible: true },
      { name: 'Entity', prop: 'entityType', width: 150, visible: true },
      { name:'Operation',prop:'operation',width:150,visible:true},
      { name: 'Status', prop: 'status', width: 150, visible: true },
      { name: 'Input File', prop: 'inputFileUrl', width: 300, visible: true },
      { name: 'Row Count', prop: 'totalRecordsCount', width: 200, visible: true },
      { name: 'Successful Rows', prop: 'successRecordsCount', width: 200, visible: true },
      { name: 'Failed Rows', prop: 'failedRecordsCount', width: 200, visible: true },
      // { name: 'Notification Sent To', prop: 'moreInfo',cellTemplate:this.NotificationTo,width: 200, visible: true },
      // { name: 'Notification Time', prop: 'moreInfo',cellTemplate: this.NotificationOn, width: 200, visible: true },
      { name: 'Created At', prop: 'createdAt', cellTemplate: this.dateTemplate, width: 200, visible: true },
      { name: 'Created By', prop: 'createdBy', width: 200, visible: true },
      { name: 'Modified At', prop: 'modifiedAt',cellTemplate: this.dateTemplate, width: 200, visible: true },
      { name: 'Modified By', prop: 'modifiedBy', width: 200, visible: true },
    ];    
    this.rawColumns = [...this.columns]
    this.getFilters()
  }
  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
      // this.getLocationList();
    }
  }
  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }
  
  getBulkOperationsList(resetData: boolean = false){
    if(this.delayedRefreshStart) {
      this.delayedRefreshStart = false;
      this.tableRowData = [];
    }
    let data = this.payLoadForBulkList;
    this.isLoading = true;
    const userPolicyListData: Subscription = this.bulkService.getBulkOperationsList(data).subscribe({
      next: (data: any) => {
      this.allLoaded = false;
      if (resetData) {
        this.rows = [...data];
      } else {
        this.rows = [...this.rows, ...data];
      }         
      this.tableRowData = [...this.rows];      
      this.isLoading = false;
    },
    error: (err) => {
        this.isLoading = false;
    }
  });
    this.subscriptions.push(userPolicyListData);
  }

  getFilters(){
    this.bulkService.getBulkFilters().subscribe((data:any)=>{
      this.initialFilters = [];
      data.forEach((element) => {
        let obj = {
          collapsed: false,
          expand: true,
          enableCollapsing: false,
          filterType: element.labelKey,
          label: element.label,
          type: 'multiselect',
          list: element.options
        };
        this.initialFilters.push(obj);
      });

      if (this.dateRangeFilter.range) {
        this.dateFilterObj.defaultRange = this.dateRangeFilter.range;
      }      
      this.initialFilters.unshift(this.dateFilterObj);
      this.initialFilters.forEach((filter) => {
        const { filterType, list } = filter;
        if (
          this.selectedFilters[filterType] &&
          this.selectedFilters[filterType].length > 0
        ) {
          list.forEach((item) => {
            if (this.selectedFilters[filterType].includes(item.id)) {
              item.checked = true;
            }
          });
        }
      });
      let filters = [...this.initialFilters];
      this.initialFilters = _.cloneDeep(filters);
      this.filters = filters;   
    })
  }


  onSelect(rowData: any, event?: any) {
    // this.selected = rowData.selected;
    // if(this.selected.length == 1){
    //   this.isEnableEdit = false;
    //   this.isEnableDelete = false;
    // } else {
    //   this.isEnableEdit = true;
    //   this.isEnableDelete = true;
    // }
  }
 
  getPanelUpdate(event:any){    
    let filterEvent = event.filter;
    if (event.action === 'clearFilters') {
      this.selectedFilters = {};
      this.filters = _.cloneDeep(this.initialFilters);
    } else {      
      if (filterEvent.filterGroupName === 'dateRange')
      {
        this.dateRangeFilter.range = filterEvent.selectedDateRange;
      }
      else if (filterEvent.checked) {
        if (!this.selectedFilters[filterEvent.filterGroupName]) {
          this.selectedFilters[filterEvent.filterGroupName] = [];
        }
        this.selectedFilters[filterEvent.filterGroupName].push(filterEvent.id);
      } else {        
        let index = this.selectedFilters[filterEvent.filterGroupName].indexOf(filterEvent.id);
        this.selectedFilters[filterEvent.filterGroupName].splice(index, 1);
      }
    }    
    this.createPayloadAndReloadBulkList()
  }
  createPayloadAndReloadBulkList() {    
    Object.keys(this.selectedFilters).forEach(key => {
      if (this.selectedFilters[key].length > 0 ) {
          this.payLoadForBulkList[key]=this.selectedFilters[key];
        }
    });
    if(this.dateRangeFilter?.range){
      this.payLoadForBulkList['fromDate']=moment(this.dateRangeFilter.range.startDate).valueOf();
      this.payLoadForBulkList['toDate']=moment(this.dateRangeFilter.range.endDate).valueOf();
    }
   this.getBulkOperationsList(true);
  }
  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
     setTimeout(() => {
       this.table?.recalculate();
     }, 10);
  }
  formateDownloadData(data:any[]){
    let tempAssetData = [...data];
    let dateFieldArray = ['modifiedAt', 'createdAt'];
    tempAssetData.forEach(sensor => {
      dateFieldArray.forEach(element => {
        if(sensor[element]){
          sensor[element] = this.formateDatePipe.transform(sensor[element],'default');
        }
      });
    });
    return tempAssetData;
  }

  downloadReport(reportType: string) {
    let downloadData = this.formateDownloadData(this.rows);
    if (reportType === 'csv') {
      const csvData = this._utils.getSelectedElementsFromArray(downloadData, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData,'Bulkoperations-list', _.map(this.columns, 'name'));
    }
  }
  setContainerWidth() {    
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 268;
    } else {
      this.tableWidth = this.mainWidth - 18;
    }
  }
  loadSummaryPanel(roleObject:any, event:any){
    this.showSidePanel=true;
    this.bulkObject.id=roleObject.id;
    this.bulkObject.classType=roleObject.entityType;
  }
  closeSidePanel(cbAction?){
    this.showSidePanel = false;
  }
  updateFilter(term) {
    const val = term.toLowerCase().trim();
    this.payLoadForBulkList.searchText = val;
    this.getBulkOperationsList(true);
  }
  setEvent(state: any) {
    this.event.next(state);
  }
  getEvent(): Observable<any> {
    return this.event.asObservable();
  }
}
