export interface SensorDeviceConfigParams {
  uom: string;
  enabled: string | boolean;
  value: string;
}

export interface MappedDeviceProperties {
  mfgdate: string;
}

export interface SensorMappedDevice {
  id: string;
  type: string;
  status: string;
  createdAt: any;
  modifiedAt: any;
  tenantId: string;
  properties: MappedDeviceProperties;
  eventProfileName: string;
}

export interface SensorProfileEventConfig {
  minShockWarning: string;
  minShockCritical: string;
  minShock: string;
  isShockCriticalConfigured: string;
  tempUnitType: string;
  maxShock: string;
  isShockConfigured: string;
  isShockWarningConfigured: string;
}

export interface SensorProfile {
  deviceConfig: SensorDeviceConfig[];
  eventProfileName: string;
  tenantId: string;
  deleted: boolean;
  sensorType: string;
  mappedDevices: SensorMappedDevice[];
  default: boolean;
  eventConfig?: SensorProfileEventConfig;
}

export interface SensorTypeUomValues {
  sequenceNo?: string;
  valueSet: string[];
  defaultEnabled?: boolean;
  canBeDisabled?:boolean;
}

export interface SensorTypeUomTime extends SensorTypeUomValues {
  min: string;
  max: string;
}

export interface SensorTypeUom {
  mins?: SensorTypeUomTime;
  hours?: SensorTypeUomTime;
  days?: SensorTypeUomTime;
  secs?: SensorTypeUomTime;
  samples?: SensorTypeUomValues;
  GPS?: SensorTypeUomTime;
  g?: SensorTypeUomTime;
}

export enum UomTypeCustom {
  Select = "select",
  Radio = "radio",
  Checkbox = "checkbox",
  Toggle = "toggle"
}

export interface SensorTypeUomCustom {
  type: UomTypeCustom;
  list: {
    label: string;
    value: string;
    order?: number;
    min?: number;
    max?: number;
    defaultEnabled?:boolean,
    canBeDisabled?:boolean
  }[];
  uomKey?: string;
  min?: number;
  max?: number;
}

export interface SensorTypeDeviceConfig {
  type: string;
  name: string;
  uiLabel: string;
  uom: SensorTypeUom;
  canBeDisabled: boolean;
  defaultEnabled: boolean;
  serviceIdentifier: SensorProfileDeviceConfigTypes;

  // For ui purpose only
  _uom?: SensorTypeUomCustom;
}


export interface SensorDeviceCharacteristic {
  identifier: string;
  name: string;
  configParams: SensorDeviceConfigParams;

  // For UI purpose
  _deviceConfig?: SensorTypeDeviceConfig;
}

export enum SensorProfileDeviceConfigTypes {
  Shock = "Shock",
  LoggerService = "LoggerService",
  GPS = "GPS",
}

export interface SensorDeviceConfig {
    identifier: SensorProfileDeviceConfigTypes;
    name: string;
    characteristics: SensorDeviceCharacteristic[];
}
