<div class="user-search">
    <form class="relative">
      <input class="p-2 basic-input" type="search" [ngClass]="{ 'disabled': disabled }" [formControl]="searchFc"
        placeholder="Employee Name or ID" [disabled]="disabled" />

        <button [disabled]="loading"
            [ngClass]="[loading ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
            class="p-4-10 border-general br2 mr-2 mb-2" (click)="selectAllUser()">Select All
        </button>

        <a class="font-14-normal justify-self-end mt-1 float-right" (click)="clearAllUser()">Clear All</a>

      <div class="spin-pos" *ngIf="loading"><i class="fa fa-spinner fa-pulse fa-4x color4"></i></div>
      <cdk-virtual-scroll-viewport (scrolledIndexChange)="listScrolled($event)" itemSize="22"
        [@listAnimation]="usersList?.length" class="list-viewport">
  
        <div class="search-list-item-container ba" [ngClass]="{ 'disabled': disabled }"
          *cdkVirtualFor="let user of usersList" (click)="selectUser(user)">
          <span class="search-list-item justify-between flex items-center"
            [ngClass]="{ 'item-select': getSelectedStatus(user?.id)}">
            <span class="ellipsis" matTooltip="Select user" matTooltipClass="tooltip-styles">
              {{user?.name}} ({{user?.id}})
            </span>
            <i class="fa fa-check mr10 color4" aria-hidden="true" *ngIf="getSelectedStatus(user?.id)"></i>
          </span>
        </div>
      </cdk-virtual-scroll-viewport>
  
      <button class="button mat-button dark w-100" [ngClass]="{ 'disabled-button': selectedUserFc.length == 0 }"
        [disabled]="selectedUserFc.length == 0 || disabled" (click)="confirmUserSelection()">
        AUDIT USER
        <i class="fa fa-spinner fa-pulse" *ngIf="progress"></i>
      </button>
    </form>
  </div>