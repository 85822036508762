<div
  [formGroup]="form"
  class="dynamic-form grid"
  [ngClass]="columnMode == 'One' ? 'grid-cols-1' : 'grid-cols-2'"
>
  <ng-container *ngFor="let field of inputFields">
    <ng-container *ngIf="field.isDynamicField">
      <ng-container [ngSwitch]="field.type">
        <div
          class="user-detail__item"
          style="width: 100%"
          *ngSwitchCase="'text'"
        >
          <label class="form-label" [attr.for]="field.id"
            >{{ field.displayLabel }}
            <span class="mandatory text-red-500" *ngIf="field.required"
              >*</span
            ></label
          >
          <input
            type="text"
            class="basic-input w-full text-xs pl-1.5 pr-5 h-[30px]"
            [placeholder]="'Enter ' + field.displayLabel + ' as a string value'"
            [formControlName]="field.id"
            [id]="field.id"
          />
          <div
            class="text-danger"
            *ngIf="submitted && displayErrors && form.get(field.id).errors"
          >
            <span *ngIf="form.get(field.id).getError('required')"
              >{{ field.displayLabel }} is required</span
            >
            <span *ngIf="form.get(field.id).getError('pattern')"
              >Please enter valid {{ field.displayLabel }}</span
            >
          </div>
        </div>

        <div
          class="user-detail__item"
          style="width: 100%"
          *ngSwitchCase="'number'"
        >
          <label class="form-label" [attr.for]="field.id"
            >{{ field.displayLabel }}
            <span class="mandatory text-red-500" *ngIf="field.required"
              >*</span
            ></label
          >
          <input
            type="number"
            class="basic-input w-full text-xs pl-1.5 pr-1 h-[30px]"
            [placeholder]="'Enter ' + field.displayLabel"
            [formControlName]="field.id"
            [id]="field.id"
          />
          <div
            class="text-danger"
            *ngIf="submitted && displayErrors && form.get(field.id).errors"
          >
            <span *ngIf="form.get(field.id).getError('required')"
              >{{ field.displayLabel }} is required</span
            >
            <span *ngIf="form.get(field.id).getError('pattern')"
              >Please enter valid {{ field.displayLabel }}</span
            >
          </div>
        </div>

        <div
          class="user-detail__item"
          style="width: 100%"
          *ngSwitchCase="'decimal'"
        >
          <label class="form-label" [attr.for]="field.id"
            >{{ field.displayLabel }}
            <span class="mandatory text-red-500" *ngIf="field.required"
              >*</span
            ></label
          >
          <input
            type="number"
            class="basic-input w-full text-xs pl-1.5 pr-1 h-[30px]"
            [placeholder]="'Enter ' + field.displayLabel + 'as a decimal value'"
            [formControlName]="field.id"
            [id]="field.id"
          />
          <div
            class="text-danger"
            *ngIf="submitted && displayErrors && form.get(field.id).errors"
          >
            <span *ngIf="form.get(field.id).getError('required')"
              >{{ field.displayLabel }} is required</span
            >
            <span *ngIf="form.get(field.id).getError('pattern')"
              >Please enter valid {{ field.displayLabel }}</span
            >
          </div>
        </div>

        <div
          class="user-detail__item"
          style="width: 100%"
          *ngSwitchCase="'integer'"
        >
          <label class="form-label" [attr.for]="field.id"
            >{{ field.displayLabel }}
            <span class="mandatory text-red-500" *ngIf="field.required"
              >*</span
            ></label
          >
          <input
            (input)="preventDecimals($event, field.id)"
            type="number"
            class="basic-input w-full text-xs pl-1.5 pr-1 h-[30px]"
            [placeholder]="
              'Enter ' + field.displayLabel + 'as an integer value'
            "
            [formControlName]="field.id"
            [id]="field.id"
          />
          <div
            class="text-danger"
            *ngIf="submitted && displayErrors && form.get(field.id).errors"
          >
            <span *ngIf="form.get(field.id).getError('required')"
              >{{ field.displayLabel }} is required</span
            >
            <span *ngIf="form.get(field.id).getError('pattern')"
              >Please enter valid {{ field.displayLabel }}</span
            >
          </div>
        </div>

        <div
          class="user-detail__item"
          style="width: 100%"
          *ngSwitchCase="'select'"
        >
          <label class="form-label font-bold" [attr.for]="field.id"
            >{{ field.displayLabel
            }}<span class="mandatory text-red-500" *ngIf="field.required"
              >*</span
            ><cl-org-info-icon-dialog *ngIf="field.displayLabel=='Organization'"></cl-org-info-icon-dialog></label
          >
          <div class="dropdown-input">
            <div class="relative">
              <input
                [matAutocomplete]="carrier"
                class="basic-input w-full drp-search text-xs pl-1.5 pr-5 h-[30px]"
                [placeholder]="'Select ' + field.displayLabel"
                [formControlName]="field.id"
                type="text"
                (blur)="onMatInputBlur(field.id)"
                (focus)="onMatOpen(field)"
                (input)="onMatSearch(field)"
              />
              <i
                class="fa fa-close absolute top-2 right-2 cursor-pointer"
                (click)="onMatClear(field)"
                *ngIf="form.get(field.id)?.value && field?.dropdownConfig?.isClearable"
              ></i>
            </div>
            <mat-autocomplete
              #carrier="matAutocomplete"
              (optionSelected)="onSelect($event.option.value, field.id)"
              (opened)="onMatOpen(field)"
              [displayWith]="displayFn"
            >
              <ng-container>
                <mat-option *ngIf="!isOrgAware && field.displayLabel=='Organization' && showAnyOrg" [value]="'ANY'">(Any)</mat-option>
                <mat-option
                  *ngFor="
                    let option of field.options
                      | search: form.get(field.id)?.value:'label'
                  "
                  [value]="option"
                  >{{ option.label ? option.label : option }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </div>
          <div
            class="text-danger"
            *ngIf="submitted && displayErrors && form.get(field.id).errors"
          >
            <span *ngIf="form.get(field.id).getError('required')"
              >{{ field.displayLabel }} is required</span
            >
            <span *ngIf="form.get(field.id).getError('pattern')"
              >Please select valid {{ field.displayLabel }}</span
            >
          </div>
          <p *ngIf="!isOrgAware && showAnyOrg && form.get(field.id).value=='ANY'" class="orgcontent"><span>Warning</span>: data will be seen by all users including those outside your org</p>
        </div>

        <div
          class="user-detail__item"
          style="width: 100%"
          *ngSwitchCase="'date'"
        >
          <label class="form-label" [attr.for]="field.id"
            >{{ field.displayLabel }}
            <span class="mandatory text-red-500" *ngIf="field.required"
              >*</span
            ></label
          >
          <cl-date-picker
            [dateRange]="field?.dateConfig?.date || null"
            (dateChanged)="onDateChange($event, field.id)"
            [ranges]="null"
            [singleDatePicker]="true"
            [datePickerClass]="'formSingleDatePickerFull'"
            [timePicker]="false"
            [placeholder]="'Select ' + field.displayLabel"
          ></cl-date-picker>
          <div
            class="text-danger"
            *ngIf="submitted && displayErrors && form.get(field.id).errors"
          >
            <span *ngIf="form.get(field.id).getError('required')"
              >{{ field.displayLabel }} is required</span
            >
            <span *ngIf="form.get(field.id).getError('pattern')"
              >Please select valid {{ field.displayLabel }}</span
            >
          </div>
        </div>

        <div class="user-detail__item" *ngSwitchCase="'toggle'">
          <label class="form-label mr-1.5 " [attr.for]="field.id"
            >{{ field.displayLabel }}
            <span class="mandatory text-red-500" *ngIf="field.required">*</span>
          </label>
          <mat-slide-toggle [formControlName]="field.id"> </mat-slide-toggle>
          <div
            class="text-danger"
            *ngIf="submitted && displayErrors && form.get(field.id).errors"
          >
            <span *ngIf="form.get(field.id).getError('required')"
              >{{ field.displayLabel }} is required</span
            >
            <span *ngIf="form.get(field.id).getError('pattern')"
              >Please select valid {{ field.displayLabel }}</span
            >
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!field.isDynamicField && field.template">
      <ng-template
        let-field
        ngFor
        [ngForOf]="[field]"
        [ngForTemplate]="field.template"
      ></ng-template>
    </ng-container>
    <ng-container *ngIf="!field.isDynamicField && !field.template">
      <ng-content [select]="'#' + field.id"></ng-content>
    </ng-container>
  </ng-container>
</div>
