import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'cl-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {
  currentItem = 'theme';
  default_data = [{
    "section": "common",
    "keyprefix": "dvp2",
    "prefix1" : "appCommon",
    "prefix2": "appCSS",
    "properties": [
      {
        "key": "primaryActionColor",
        "displayLabel": "Primary Action Color",
        "description": "Primary Action Color",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "primaryActionColorGradient",
        "displayLabel": "Primary Action Color Gradient",
        "description": "Primary Action Color Gradient",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "primaryActionColorMediumLight",
        "displayLabel": "Primary Action Color MediumLight",
        "description": "Primary Action Color MediumLight",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "primaryActionColorLighter",
        "displayLabel": "Primary Action Color Lighter",
        "description": "Primary Action Color Lighter",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "primaryActionColorLightest",
        "displayLabel": "Primary Action Color Lightest",
        "description": "Primary Action Color Lightest",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "actionButtonBg",
        "displayLabel": "Action Button Bg",
        "description": "Action Button Background",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "anchorLinksColor",
        "displayLabel": "Anchor Links Color",
        "description": "Anchor Links Color",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "mainMenuButtonGradientBg1",
        "displayLabel": "Main Menu Button Gradient Bg1",
        "description": "Main Menu Button Gradient Bg1",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "mainMenuButtonGradientBg2",
        "displayLabel": "Main Menu Button Gradient Bg2",
        "description": "Main Menu Button Gradient Bg1",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "mainMenuFontColor",
        "displayLabel": "Main menu font color",
        "description": "Main menu font color",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "headerNotifIconColor",
        "displayLabel": "Header Notif Icon Color",
        "description": "Header Notif Icon Color",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "formElementsBg",
        "displayLabel": "Form Elements Bg",
        "description": "Form Elements Background",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "sectionBg",
        "displayLabel": "Section Bg",
        "description": "Section Background",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
      {
        "key": "sectionBorder",
        "displayLabel": "Section Border",
        "description": "Section Border",
        "reset": false,
        "isallowDelete": false,
        "displayType": "text"
      },
    ]
  },
  {
    "section": "customCSS",
    "keyprefix": "dvp2",
    "prefix1" : "appCommon",
    "prefix2": "appCSS",
    "properties": [
      {
        "key": "customCSS",
        "isSaparator": true,
        "value": "",
        "displayLabel": "customCSS",
        "description": "customCSS",
        "reset": false,
        "isallowDelete": false,
        "displayType": "textArea"
      }
    ]
  }

  ];
  constructor() { }

  ngOnInit(): void {
  }

}
