import { Component, Input, OnChanges, Output, SimpleChanges, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { EventTab, Gateway, GatewayEvent, GatewayEvents } from '@cl/@types/gateway.type';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { PropertyService } from '@cl/property/property.service';
// import { GatewayService } from '../../gateway.service';

export interface DatePickerRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

@Component({
  selector: 'cl-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})

export class DatePickerComponent{
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  @Input('dateRange') selectedDateRange: DatePickerRange;
  @Input('ranges') ranges: any;
  @Input() datePickerClass: any;
  @Input() displayFormat: any;
  @Input() singleDatePicker: any;
  @Input() disablePreviousdates: any;
  timePicker24Hour: boolean = false;
  @Input() timePicker = true;
  @Input ('minDate') minDate: moment.Moment;
  @Input ('maxDate') maxDate: moment.Moment;
  @Input() placeholder = '';

  
  addClass: any;

  locale : any = {
    format: 'MM/DD/YYYY',
    displayFormat: 'MM/DD/YYYY hh:mm:ss',
    weekLabel: 'W',
    customRangeLabel: 'Custom range',
    direction: 'ltr'
  };
  @Output('dateChanged') onDateChange: EventEmitter<DatePickerRange> = new EventEmitter<DatePickerRange>();

  constructor(private pService: PropertyService) {
  }

  datesUpdated(daterange: any){
    // console.log('daterange', daterange, 'selectedDateRange', this.selectedDateRange);
    this.onDateChange.emit(this.selectedDateRange);// this is modified to selectedDateRange, since it is not passing the updated value in dateRange
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  ngOnChanges(changes: any) {
    let dateformat:any = this.pService.getSetting('ui.dateFormat');
    let updatedDate = dateformat.replace('dd', 'DD')
    
    if(dateformat){
      this.timePicker24Hour = !updatedDate.includes('a');
      this.locale.displayFormat = _.cloneDeep((this.displayFormat || updatedDate).slice());
      this.addClass = _.cloneDeep((this.datePickerClass || []).slice());
      if(this.disablePreviousdates){
        this.minDate = moment(this.disablePreviousdates);
      }else{
        this.minDate = null
      }
    }
  }
}
