<div class="flex justify-between pt-1 pb-1">
  <ng-content select=".add-shipment-btn"></ng-content>
  <div class="action-panel-left self-start flex items-center">
    <span
      class="cursor-pointer color4 list-page_filter-toggle flex items-center"
      (click)="toggleFilterPanel()">
      <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
      {{'common.filterOptions'|translate}}
    </span>
    <form [formGroup]="searchFormGroup" novalidate>
      <cl-list-search
        [defalutSearchTerm]="searchTerm"
        [placeholder]="'Search by Name or ID'"
        [columns]="searchInfoAttributes"
        (search)="updateFilter($event)"
      ></cl-list-search>
    </form>
    <span class="action-separator mr-2" [clIsValidAction]="actionBtnPolicies.edit || actionBtnPolicies.ruleset || actionBtnPolicies.terminate || actionBtnPolicies.delete"></span>
    <!-- <div class="action-separator-2 mr-2 ml-1">
      <cl-shipment-tag-filters
        [selectedTags]="tagsList"
      ></cl-shipment-tag-filters>
    </div> -->

    <button
      [clIsValidAction]="actionBtnPolicies.edit"
      [disabled]="isEditBtnDisabled"
      title="Edit the selected shipment"
      [ngClass]="[
        isEditBtnDisabled
          ? 'color37 bcolor36 bgcolor38'
          : 'color4 bcolor4 bgcolor32'
      ]"
      class="p-4-10 border-general br2"
      (click)="showEditPanel('basic')"
    >
      <i class="fa fa-pencil"></i>
      {{'common.edit'|translate}}
    </button>
    <button
      mat-button
      [matMenuTriggerFor]="multiEdit"
      [clIsValidAction]="actionBtnPolicies.edit"
      title="Edit the selected shipment"
      [disabled]="isEditBtnDisabled || !shipmentData?.payloadOf"
      [ngClass]="[
        isEditBtnDisabled || !shipmentData?.payloadOf
          ? 'color37 bcolor36 bgcolor38'
          : 'color4 bcolor4 bgcolor32'
      ]"
      class="border-general br2 mr-2 edit-button"
    >
      <i class="font-bold fa fa-caret-down -mt-1.5"></i>
    </button>

    <mat-menu #multiEdit="matMenu">
      <div>
        <button
          mat-menu-item
          class="color4"
          (click)="showEditPanel('composite')"
        >
          Edit Composite Shipment
        </button>
      </div>
    </mat-menu>
    <button
      [clIsValidAction]="actionBtnPolicies.ruleset"
      *ngIf="!isDTPUser"
      [disabled]="!isEnableManageTags"
      [ngClass]="[
        !isEnableManageTags
          ? 'color37 bcolor36 bgcolor38'
          : 'color4 bcolor4 bgcolor32'
      ]"
      class="p-4-10 border-general br2 mr-2"
      (click)="showRuleset = !showRuleset"
    >
      <i class="fa" [ngClass]="'fa-edit'"></i>
       {{'logistics.ruleSet'|translate}}
    </button>
    <cl-shipment-rule-set
      *ngIf="!showRuleset"
      [RulesListInput]="[]"
      (SendRuleSetAction)="eventFromRuelSet()"
      (applyRuleSet)="applyRuleSet($event)"
    ></cl-shipment-rule-set>
    <button
      [clIsValidAction]="actionBtnPolicies.delete"
      [disabled]="!isEnableDelete"
      title="Delete all selected shipments. The selected shipments should be in Draft or Planned status"
      [ngClass]="[
        !isEnableDelete
          ? 'color37 bcolor36 bgcolor38'
          : 'color4 bcolor4 bgcolor32'
      ]"
      class="p-4-10 border-general br2 mr-2"
      (click)="onDelete()"
    >
      <i class="fa" [ngClass]="'fa-trash'"></i>
      {{'common.delete'|translate}}
    </button>

    <button
      [clIsValidAction]="actionBtnPolicies.terminate"
      [disabled]="!isEnableTerminate"
      title="Force complete selected shipments. The selected shipments should not be at the destination"
      [ngClass]="[
        !isEnableTerminate
          ? 'color37 bcolor36 bgcolor38'
          : 'color4 bcolor4 bgcolor32'
      ]"
      class="p-4-10 border-general br2 mr-2"
      (click)="onTerminate()"
    >
      <i class="fa" [ngClass]="'fa-ban'"></i>
      {{'logistics.terminate'|translate}}
    </button>

    <button
      [clIsValidAction]="actionBtnPolicies.terminate"
      [disabled]="!isEnableComplete"
      title="Complete all selected shipments. The selected shipments should be at the destination"
      [ngClass]="[
        !isEnableComplete
          ? 'color37 bcolor36 bgcolor38'
          : 'color4 bcolor4 bgcolor32'
      ]"
      class="p-4-10 border-general br2 mr-2"
      (click)="onComplete()"
    >
      <i class="fa" [ngClass]="'fa-flag-checkered'"></i>
      {{'logistics.complete'|translate}}
    </button>

    <!-- <button
      [disabled]="!isEnableManageTags"
      [ngClass]="[
        !isEnableManageTags
          ? 'color37 bcolor36 bgcolor38'
          : 'color4 bcolor4 bgcolor32'
      ]"
      class="p-4-10 border-general br2 mr-2"
      (click)="showManageTags = !showManageTags"
    >
      <i class="fa" [ngClass]="'fa-tag'"></i>
      Manage Tags
    </button>
    <cl-shipment-managetags
      *ngIf="!showManageTags"
      (sendDataToListaction)="eventFromManageTags($event)"
    >
    </cl-shipment-managetags> -->
  </div>
  <div
    class="action-panel-right self-end flex items-center mr-16"
    (click)="onCloseSidePanel()"
  >
    <div>
      <button class="toggler-example align-grid-menu p-2 border-0" (click)="toggleSearchColumns()">
        <span class="dark-blue-text" title="{{'common.searchByColumn'|translate}}"><i class="fa fa-search-plus color4"></i></span>
      </button>
    </div>

    <cl-export-panel
      (exportEmitter)="downloadReport($event)"
    ></cl-export-panel>
    <cl-grid-column-toggle
      [columnList]="rawColumns"
      (toggleColumns)="gridColumnToggle($event)"
      [page]="catalogType"
    >
    </cl-grid-column-toggle>
  </div>
</div>
