import { Component, OnInit, Input } from '@angular/core';
import { CdmField } from '@cl/@types/asset.type';
import { Gateway } from '@cl/@types/gateway.type';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { SortPipe } from '@cl/common/pipes/sort.pipe';

@Component({
  selector: 'cl-gateway-summary',
  templateUrl: './gateway-summary.component.html',
  styleUrls: ['./gateway-summary.component.scss']
})
export class GatewaySummaryComponent implements OnInit {
  @Input('gateway') gatewayObject:any;
  @Input('lastUpdated') lastUpdated: string;
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  constructor(public datePipe : FormateDatePipe) {
   }

  ngOnInit(): void {}

  getExtendedAttributeValue(cdmField: CdmField, properties){
    if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
      if(cdmField?.type?.toUpperCase()==='DATE'){
        let data = properties[cdmField.name];
        return this.datePipe.transform(data, 'default');
      } else {
        return properties[cdmField.name];
      }
    }else{
      return '';
    }
  }

}
