import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HidePanel } from '@cl/common/actions';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/common/constants';
import {
  CL_INPUT_DEBOUNCE_TIME,
  CL_INPUT_DEBOUNCE_TIME_EXTREME,
} from '@cl/constants';
import {
  NavigatorConfig,
  NavigatorViewModes,
  PartialOf,
  SearchQueryRequest,
} from '@cl/models';
import { LvHidePanel, LvShowPanel, LvTogglePanel } from '@cl/ngxs/actions';
import { LvPanelsState } from '@cl/ngxs/state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionSuccessful, Store, Select } from '@ngxs/store';
import _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
  tap,
} from 'rxjs/operators';
import { AvailableMode } from '../horizontal-filters/mode-switch/mode-switch.component';
import { NavigatorFiltersService } from '../service/navigator-filters.service';
import {
  NavigatorLastAction,
  NavigatorResetMap,
  NavigatorSetMapCenter,
  NavigatorUpdateFilterEsQuery,
  NavigatorUpdateMapDetails,
} from '../state/navigator.actions';
import { NavigatorState } from '../state/navigator.state';
import { DomainFilterListComponent } from './domain-filter-list/domain-filter-list.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";

const ViewModeMap: PartialOf<AvailableMode> = {
  [NavigatorViewModes.MAP]: {
    id: NavigatorViewModes.MAP,
    label: 'View Map',
    tooltip: 'View Map',
    svgIconUrl: '/assets/svgs/cl-circle-icons/map.svg',
  },
  [NavigatorViewModes.NETWORK]: {
    id: NavigatorViewModes.NETWORK,
    label: 'View Network Graph',
    tooltip: 'View Network Graph',
    svgIconUrl: '/assets/svgs/cl-circle-icons/hub.svg',
  },
};

@UntilDestroy()
@Component({
  selector: 'app-navigator-filters',
  templateUrl: './navigator-filters.component.html',
  styleUrls: ['./navigator-filters.component.scss'],
  host: {
    class: 'app-navigation-filters',
  },
})
export class NavigatorFiltersComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild(DomainFilterListComponent)
  domainFilterListRef: DomainFilterListComponent;
  @ViewChild(FilterListComponent) filterListRef: FilterListComponent;
  @Input() navigatorConfig: NavigatorConfig;
  @Input() esSearchParams: SearchQueryRequest;
  // @Output() filtersCountChange = new EventEmitter<number>();
  @Select(NavigatorState.filteredNodes) filteredNodes$ : Observable<number>;
  @Select(LvPanelsState) lvPanels$: Observable<string[]>;

  viewModes: AvailableMode[] = [];

  form: FormGroup;

  activeFiltersCount: number = 0;
  activeDomainFiltersCount: number = 0;
  hasFilters = false;
  hasDomainFilters = false;
  chipElements: any = [];
  chipData: {} = {
    domain: '',
    subType: '',
    value: '',
    active: false,
    children: '',
  };
  private filterResponse: {} = {};
  searchTerm: string = '';
  recCount:number = 0;
  filterSelected: boolean = false;
  p;
  allFilterData = [];

  currentActiveDomain = ''

  private isSubscribedToFormChanges = false;

  private get newNavigatorFilterForm(): FormGroup {
    return this.fb.group({
      selectedMode: [],
      searchTerm: [''],
      filters: [{}],
      domains: [{}],
    });
  }

  private get fcSelectedMode() {
    return this.form.get('selectedMode');
  }

  private get fcSearchTerm() {
    return this.form.get('searchTerm');
  }

  /**
   * `true` if the current route url is of map mode, otherwise `false`.
   */
  private get isMapMode() {
    return this.router.url.includes('/map');
  }

  constructor(
    private store: Store,
    private actions$: Actions,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private navigatorFilters: NavigatorFiltersService,
    private webTracker: WebtrackerService
  ) {
    this.form = this.newNavigatorFilterForm;
    // this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((v) => {
    //   let a = _.cloneDeep(v);
    //   if (a.domains?.asset?.type?.arn?.$$active && !a?.domains?.asset?.type?.asset?.$$active) {
    //     a.domains.asset.type.asset = { $$active: true };
    //     this.form.patchValue(a);
    //   }
    // });
  }

  ngOnInit(): void {
    // Depends on child component hence moved to ngAfterViewInit
    // this.watchForFormChanges();
    this.watchPanels();
    this.watchForModeChanges();
    this.watchForSearchTermChanges();
    this.watchForResetActionChanges();
    this.formFilterChips();
  }

  ngAfterViewInit(): void {
    this.watchVisibleDomainChanges()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['navigatorConfig'] && this.navigatorConfig)
      this.initNavigatorFilters();
  }

  private watchPanels() {
    this.lvPanels$.pipe(untilDestroyed(this)).subscribe((p: any) => {
      this.p = { ...p };
    });
  }

  formFilterChips() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((response) => {
      if (response.searchTerm) {
        this.searchTerm = response.searchTerm;
        this.webTracker.captureEvent('navigator_search', {'searchTerm': this.searchTerm});
      } else {
        this.searchTerm = '';
      }
      this.toggleFilterSelection();
      this.chipElements = [];
      let domains = response['domains'];
      this.filterResponse = _.clone(response);
      if (Object.keys(domains).length !== 0) {
        for (let key in domains) {
          this.chipData['domain'] = key;
          let domainTypes = domains[key];
          for (let subKey in domainTypes) {
            this.chipData['subType'] = subKey;
            let domainValues = domainTypes[subKey];
            for (let subValue in domainValues) {
              let domainChildren = [];
              this.chipData['value'] = subValue;
              this.chipData['active'] = domainValues[subValue]['$$active'];
              domainChildren = domainValues[subValue]['childFilters'];
              this.chipData['children'] = '';
              if (domainChildren.length) {
                domainChildren.forEach((child, index) => {
                  if (index === 0 && this.chipData['active']) {
                    this.chipElements.push(_.clone(this.chipData));
                  }
                  this.chipData['children'] = child;
                  this.chipElements.push(_.clone(this.chipData));
                });
              } else {
                this.chipElements.push(_.clone(this.chipData));
              }
            }
          }
        }
      }
    });
  }

  clearSearch() {
    this.searchTerm = '';
    this.filterResponse["searchTerm"] = '';
    this.form.patchValue(this.filterResponse);
  }

  activeFiltersCountChange(count: number) {
    this.activeFiltersCount = count;
    // this.showSidePanelsOnFilterSelection();
  }

  toggleFilterSelection() {
    if ((this.activeDomainFiltersCount === 1 || this.searchTerm) && !this.p.filtersSelected) {
      this.store.dispatch(new LvTogglePanel('filtersSelected'));
    } else if (this.activeDomainFiltersCount === 0 && !this.searchTerm && this.p.filtersSelected) {
      this.store.dispatch(new LvHidePanel('filtersSelected'));
    }
  }

  activeDomainsFiltersCountChange(obj) {
    this.activeDomainFiltersCount = obj['count'];
    this.toggleFilterSelection();
    this.filterSelected = obj['filterSelected'];
    // this.showSidePanelsOnFilterSelection();
  }

  totalRecordsCount(count: number) {
    this.recCount += count;
  }

  private showSidePanelsOnFilterSelection() {
    if (
      this.activeFiltersCount > 0 ||
      this.activeDomainFiltersCount > 0 ||
      this.fcSearchTerm.value
    ) {
      this.store.dispatch(
        new LvShowPanel('rightList', 'navigatorFilterUpdate')
      );
    } else {
      this.store.dispatch(new LvTogglePanel('rightList'));
    }
  }

  private watchForModeChanges() {
    this.fcSelectedMode.valueChanges
      .pipe(untilDestroyed(this), skip(1), distinctUntilChanged())
      .subscribe((mode) => this.onSelectedModeChange(mode));
  }

  /**
   *
   */
  private watchForSearchTermChanges() {
    this.fcSearchTerm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME_EXTREME),
        distinctUntilChanged(),
        filter((searchTerm) => !!searchTerm)
      )
      .subscribe((_) => this.showSidePanelsOnFilterSelection());
  }

  private watchVisibleDomainChanges() {
    this.domainFilterListRef.fcVisible.valueChanges.subscribe(res => {
      this.currentActiveDomain = res?.[0]
    })
  }

  private watchForFormChanges() {
    const targetAndVisibilityChange$ =
      this.domainFilterListRef.targetAndVisibilityChange$;

    const formValueChange$ = this.form.valueChanges.pipe(
      untilDestroyed(this),
      tap(() => {
        if (this.activeDomainFiltersCount > 0)
          this.store.dispatch(
            new LvShowPanel('rightList', 'navigatorFilterUpdate')
          );
        else this.store.dispatch(new LvHidePanel('rightList'));
      })
      // debounceTime(CL_INPUT_DEBOUNCE_TIME)
    );

    combineLatest([targetAndVisibilityChange$, formValueChange$])
      .pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH))
      .subscribe(([targetAndVisibleDomain, filterFormValues]) => {
        const domainsSubFiltersMap =
          this.domainFilterListRef.getDomainsSubFiltersMap();

        const searchParams = this.navigatorFilters.toEsQuery(
          this.navigatorConfig,
          filterFormValues,
          this.navigatorConfig.initialData.payload as any,
          targetAndVisibleDomain.target,
          targetAndVisibleDomain.visible,
          domainsSubFiltersMap
        );

        //If eye icon is on with out any filters selected
        searchParams.searchQueries.map(query => {
          this.allFilterData.map(domainData => {
            if(targetAndVisibleDomain.visible.indexOf(domainData.domainId)>-1 && !query.childFilters && domainData?.domainId === query.queryText?.toLocaleLowerCase()){
              query['childFilters'] = [];
              domainData?.filterData.map(filters => {
              if(domainData.filterData.length) {
                query.childFilters.push({'fieldName': filters?.name,
                'queryOperator': "should",
                'queryText':  filters?.filters})
              }
             })
            }
          })
        })


       this.store.dispatch(new NavigatorSetMapCenter());

       if(this.filterSelected){
         setTimeout(() => {
          this.store.dispatch(new NavigatorLastAction('filterSelected'))
         }, 1000);
       }

        // Update es query in state
          if (!_.isEqual(this.esSearchParams, searchParams)) {

            this.store.dispatch(new NavigatorUpdateFilterEsQuery(searchParams));

            // Hide summary panel
            this.store.dispatch([
              new LvHidePanel('inspector'),
              new NavigatorUpdateMapDetails({
                activeEntity: null,
              }),
            ]);

            // Show right panel whenever there is filter update
            // this.store.dispatch(new LvShowPanel("rightList", "navigatorFilterUpdate"));
          }

      });
      this.domainFilterListRef.toggleVisibility('shipment', true);
      // this.domainFilterListRef.patchAllDomainstoVisible();
   }

  private setAllDomainsVisible() {
    this.domainFilterListRef.toggleVisibility('location', true);
    this.domainFilterListRef.toggleVisibility('asset', true);
    this.domainFilterListRef.toggleVisibility('shipment', true);
  }

  getAllFilters(filterData) {
    this.allFilterData.push(filterData);
  }

  /**
   * Subscribes to the reset action updates.
   */
  private watchForResetActionChanges() {
    this.actions$
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),
        ofActionSuccessful(NavigatorResetMap)
      )
      .subscribe((_) => {
        this.domainFilterListRef.clearAll();
        // Order of clear target and visibility is important as clear target domain emits event to reload data
        this.domainFilterListRef.toggleAllVisibility(true);
        this.domainFilterListRef.clearTargetDomain();
        this.setAllDomainsVisible();
        this.filterListRef?.clearAll();

      });
  }

  /**
   * Subscribes to the targeted domain and visibility changes.
   */
  private watchForTargetAndVisibilityChange() {
    this.domainFilterListRef.domainsForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME))
      .subscribe((val: { target: string; visible: string[] }) => {});
  }

  /**
   * Event handler on view mode switch.
   */
  private onSelectedModeChange(mode: AvailableMode) {
    let url = '';

    const isFullScreen = this.route.snapshot.queryParams['fullScreen'];
    const queryParams: any = {};
    if (isFullScreen) queryParams.fullScreen = true;

    switch (mode.id) {
      case NavigatorViewModes.MAP:
        url = '/navigator/map';
        break;
      case NavigatorViewModes.NETWORK:
        url = '/navigator/graph';
        break;
    }

    this.router.navigate([url], { queryParams });
  }

  /**
   * Initialize filter, search and view modes UI config objects.
   */
  private initNavigatorFilters(): void {
    // Initialize view modes and current active view mode
    this.viewModes = this.prepareViewModes(
      this.navigatorConfig.viewModes ?? []
    );

    // Get active view mode from the route url
    let selectedViewMode = ViewModeMap[NavigatorViewModes.MAP];
    if (!this.isMapMode)
      selectedViewMode = ViewModeMap[NavigatorViewModes.NETWORK];
    this.fcSelectedMode.patchValue(selectedViewMode);

    // Initialize if filters and domains filters are present
    this.hasFilters = _.size(this.navigatorConfig.filter) > 0;
    this.hasDomainFilters = _.size(this.navigatorConfig.domain) > 0;

    if (!this.isSubscribedToFormChanges) {
      // Timeout is needed as the ViewChild might not have initialized
      setTimeout(() => {
      this.watchForFormChanges()}
      , 10);
      this.isSubscribedToFormChanges = true;
    }
  }

  remove(chip: any, index: number): void {
    this.chipElements.splice(index, 1);
    if (chip.children) {
      this.filterResponse['domains'][chip['domain']][chip['subType']][
        chip['value']
      ][chip['children']] = false;
    } else {
      this.filterResponse['domains'][chip['domain']][chip['subType']][
        chip['value']
      ]['$$active'] = false;
    }
    this.form.patchValue(this.filterResponse);
  }

  /**
   * Prepares array of view modes
   */
  private prepareViewModes(viewModes: NavigatorViewModes[]): AvailableMode[] {
    return viewModes
      .filter((mode) => ViewModeMap[mode])
      .map((mode) => ViewModeMap[mode]);
  }
}
