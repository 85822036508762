import { Component, ElementRef,  EventEmitter, Input, OnInit, ViewChild ,Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { switchMap, debounceTime, tap, finalize, filter, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { ToastService } from '@cl/common/services/toast.service';
import { formatDate } from '@angular/common';
import { PropertyService } from '@cl/property/property.service';
import { OrganizationService } from '@cl/common/services/organization.service';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { UserService } from '@cl/user/user.service';
import { CatalogType } from '@cl/@types/extended-attributes.types';
import _ from 'lodash';
import { HelpDialogComponent, helpDialogModel } from '../../../../common/components/help-dialog/help-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ContentDialogComponent } from '../../../../common/components/content-dialog/content-dialog.component';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/constants';

@Component({
  selector: 'cl-shipment-standard',
  templateUrl: './shipment-standard.component.html',
  styleUrls: ['./shipment-standard.component.scss'],
})
export class ShipmentStandardComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() shipmentID: string;
  @Input() shipmentCatalog: CatalogType;

  @Output() isDraftHideEmitter = new EventEmitter<boolean>();

  @ViewChild('mawbInput') mawbInput!: ElementRef;
  @ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;

  // UI Handlers
  datePickerClass: any = 'formSingleDatePicker';
  actionBtnPolicies = {
   org: ['CDMINSTANCE_SUMMARY'],
  };
  isOrgRestrictedUser;
  togglers = {
    notes: true,
    tags: true,
    assetTypes: true,
    assets: false,
    includedAssets: false,
    additionalFields: true
  };
  customerCodeAddEnable=false;
  dynamicCustomerCode=[];
  datePickerOpenedArrival=false;
  datePickerOpenedDeparture=false;
  dateDisplayFormat:any;
  arrivalValue =''
  preventBeforeDepartureDate = moment();
  preventBeforeArrivalDate = moment();
  preventBeforeCurrentDate = moment();
  departureDateRange =  { startDate: moment(), endDate: moment() }
  arrivalDateRange = { startDate: moment(), endDate: moment() };

  initializedData: any = {};

  shipmentName ='';
  shipmentExternalId = '';
  isPreventChangingAssets = false;
  isDetailsLoading = false;

  // Error text
  nameErrorText = '';
  nameErrorValidation: boolean = false;
  externalIdErrorText = '';
  externalIdErrorValidation: boolean = false;
  regularExpression = /^[A-Za-z0-9@&()_:',.;+"/\s-]+$/;

  // AWB data
  dataAWB:any[]=[];
  enableArrival:boolean =true;
  enableDeparture:boolean =true;
  plannedArrivalValue:any;
  plannedDepartureValue:any;
  routeAWB=[];
  dateforETA:any;

  // Loaders
  assetTypesLoading: boolean = false;
  isLoading: boolean = false;
  routeisLoading:boolean =false;

  // Shipment Data Handlers
  subscriptions: Subscription[] = [];

  shipmentForm: FormGroup;

  // List forms data
  carrierCodesList: any[] = [];
  ruleSetsList: any[] = [];
  areasList: any[] = [];
  sensorProfilesList: any[] = [];
  routesList: any = [];

  assetTypesList: any = [];
  assetObjList: any = [];

  customerNamesList: string[] = [];
  coustomerCodesList: string[] = [];
  shipperNamesList: string[] = [];

  // Route Handlers
  mawb: string = '';
  routeValue = '';
  selectedRoute: any;
  flightDetails = [];
  destination: any[] = [];

  // Assets Handlers
  selectedCategories: any[] = [];
  assetType: 'MONITORED' | 'UNMONITORED' | 'ALL' = 'MONITORED';
  assetsScrollId: any;
  searchAssetTypes: any;
  helpText = 'Below columns are used for search';

  parcelShipTaggedAssetList: any = [];

  inventoryAssets = [];
  selectedAssets: any = [];
  submitted: boolean = false;

  sensorsList: any = [];
  sensorsListSubject = new Subject();

  finishAssetsAdding: boolean = false;
  allComplete: boolean = false;
  orgList: any = [];
  assetsLoading: boolean;
  totalAssets: number = 0;
  tagstobeSelected: any;
  shipmentData: any;
  shipment = {
    name: '',
    externalId: '',
    orderNum: '',
    shipperName: '',
    carrierCode: '',
    modeOfTransport: '',
    custName: '',
    custCode: '',
    custOrdRef: '',
    recipientName: '',
    organization: '',
    directToResidence: false,
    ruleset: '',
    direction: 'Outbound',
    route: null,
    shipmentRouteSegments: [],
    mawb: '',
    plannedDeparture: null,
    plannedArrival: null,
    assets: [],
    tags: [],
    notes: '',
    type: 'standardshipment',
    sensorProfile: ''
  };
  dynamicInputFields:InputField[] = [];

  buildForm = () => {
    this.shipmentForm = this.formBuilder.group({
      name: [this.shipment.name, Validators.required],
      externalId: this.shipment.externalId,
      orderNum: [this.shipment.orderNum],
      shipperName: [this.shipment.shipperName],
      carrierCode: [this.shipment.carrierCode],
      modeoftransport: this.shipment.modeOfTransport,
      custName: [this.shipment.custName],
      custCode: [this.shipment.custCode],
      custOrdRef: [this.shipment.custOrdRef],
      recipientName: [this.shipment.recipientName],
      organization: [this.shipment.organization],
      directToResidence: [this.shipment.directToResidence, Validators.required],
      ruleset: [this.shipment.ruleset],
      sensorProfile: [this.shipment.sensorProfile],
      direction: [this.shipment.direction],
      route: [this.shipment.route, Validators.required],
      shipmentRouteSegments: [this.shipment.shipmentRouteSegments],
      mawb: [this.shipment.mawb],
      plannedDeparture: [this.shipment.plannedDeparture],
      plannedArrival: [this.shipment.plannedArrival],
      assets: [],

      tags: [this.shipment.tags],
      notes: [this.shipment.notes],
      type: [this.shipment.type],
    });
    this.initializeTypeAheads();
  };

  constructor(
    private shipmentsService: ShipmentService,
    private pService: PropertyService,
    private formBuilder: FormBuilder,
    public toast: ToastService,
    public _userService:UserService,
    private organizationService: OrganizationService,
    private _shipmentUtils: ShipmentUtilsService,
    private _dynamicFormService: DynamicFormService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.initSensorsByLazy()
    if (this.shipmentID && this.isEdit) {
      this.isDetailsLoading = true;
      this.getShipmentDetails();
    }else{
      this.getExtendedAttributes();
    }
    this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
    this.dateDisplayFormat= this.dateDisplayFormat.replace('dd', 'DD'); // dd is refered as day(mon ,tue ,wed ...) in moment so replaced with DD to get (1,2,3....31)
    this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');  // replacing a(am ,pm) with A(AM ,PM) because only this format will work in moment()
    this.buildForm();
    this.initializePreLoadedList();
    this.initializedData = { ...this.shipmentForm.value };
    this.isOrgRestrictedUser = this._userService.getUser()?.orgAware;
  }
  // draftval:any
    getShipmentDetails() {
    this.shipmentsService
      .getShipmentData(this.shipmentID,this.shipmentCatalog.additionalProperties.entityTypeKey)
      .subscribe((data: any) => {
      //  this.draftval=data.shipmentNode?.properties?.isDraft
        if (!data.shipmentNode?.properties?.isDraft)
          this.isDraftHideEmitter.emit(true);
        this.shipmentName = data.shipmentNode?.properties?.name;
        this.shipmentExternalId = data.shipmentNode?.properties?.externalId;
        this.shipmentForm.get('externalId').disable();
        this.isDetailsLoading = false;
        this.isPreventChangingAssets = !['Ready to Ship','Drafts','Planned', 'Pending'].includes(data.shipmentNode?.properties?.statusCode);
        this.shipment = {
          name: data.shipmentNode?.properties?.name,
          externalId: data.shipmentNode?.properties?.externalId,
          orderNum: data.shipmentNode?.properties?.orderNum,
          shipperName: data.shipmentNode?.properties?.shipperName,
          carrierCode: data.shipmentNode?.properties?.carrierCode,
          modeOfTransport: data.shipmentNode?.properties?.modeOfTransport,
          custName: data.shipmentNode?.properties?.custName,
          custCode: data.shipmentNode?.properties?.custCode,
          custOrdRef: data.shipmentNode?.properties?.custOrdRef,
          recipientName: data.shipmentNode?.properties?.recipientName,
          organization: data.shipmentNode?.properties?.organization,
          directToResidence:
            data.shipmentNode?.properties?.directToResidence === 'true',
          ruleset: data.shipmentNode?.properties?.ruleSetName,
          direction: data.shipmentNode?.properties?.direction,
          route: data.routeNode?.properties?.id,
          shipmentRouteSegments: data.shipmentNode?.properties?.shipmentRouteSegments ? data.shipmentNode?.properties?.shipmentRouteSegments :[],
          mawb: data.shipmentNode?.properties?.mawb,
          plannedDeparture: this.checkForPlanned(
            +data.shipmentNode?.properties?.plannedDeparture
          ),
          plannedArrival: this.checkForPlanned(
            +data.shipmentNode?.properties?.plannedArrival
          ),
          assets: [],
          tags: data.shipmentNode?.properties?.tags,
          notes: data.shipmentNode?.properties?.notes,
          type: 'standardshipment',
          sensorProfile: data.shipmentNode?.properties?.sensorProfile
        };
        this.editSelectRoute(data.routeNode)
        this.patchRoute(data.shipmentNode?.properties?.routeId);
        this.tagstobeSelected = this.shipment;
        this.shipmentData = data;
        this.datePickerOpenedArrival =true;
        this.datePickerOpenedDeparture =true;
        this.departureDateRange = {
          startDate: this.checkForPlanned(+data.shipmentNode?.properties?.plannedDeparture),
          endDate: this.checkForPlanned(+data.shipmentNode?.properties?.plannedDeparture),
        };
        this.arrivalDateRange = {
          startDate: this.checkForPlanned(+data.shipmentNode?.properties?.plannedArrival),
          endDate: this.checkForPlanned(+data.shipmentNode?.properties?.plannedArrival),
        };
        this.dateforETA =this.checkForPlanned(+data.shipmentNode?.properties?.plannedArrival)
        this.selectedAssets =
          data.assetNodes?.map((node) => node.properties) || [];
        this.getExtendedAttributes();
        this.addToShipment();
        this.selectedAssets = []
        this.buildForm();
      });
  }
  patchRoute(id) {
    this.shipmentsService.getRouts(id).subscribe((data: any) => {
      let routeData = [...data.hits].filter((route) => route.name);
      const route = routeData.find(
        (route) =>
          route.id === id
      );
      this.shipmentForm.get('route').patchValue(route);
    });
  }
  checkForPlanned(value){
    if(value){
      return moment(value)
    }else{
      return null
    }
  }
  ruleIdUpdate(){
    if(this.isEdit){
      const ruleSet = this.ruleSetsList.find(
        (ruleSet) => ruleSet.name === this.shipment.ruleset
      );
     this.shipment.ruleset = ruleSet;
    }
  }
  // TODO: REFACTORED CODE

  // UI handlers
  /**
   * This is for check the user is change any of the field data or not.
   * If user changed the data then we need to display a alert message
   *  */
  public isFormChanged() {
    let currentData = { ...this.shipmentForm.value };
    let isChange = false;
    for (let key in this.initializedData) {
      if (currentData[key] !== this.initializedData[key]) {
        if (key !== 'tags') isChange = true;
      }
    }
    return isChange;
  }

  // Form Lists

  /**
   * This method is used for fetch the list of api's which we need before form is opening
   *
   * carrierCode, ruleSet,areas, sensorProfiles, route and asset Types are loaded before form initialization
   *
   */
  initializePreLoadedList = () => {
    const carrierCodesAPI = this.shipmentsService.getCarrierCodes();
    const rulesetsAPI = this.shipmentsService.getRuleSetByTenant();
    const areasAPI = this.shipmentsService.getAreas();
    const sensorProfilesAPI = this.shipmentsService.getSensorProfiles();
    const routesAPI = this.shipmentsService.getRouts();
    const orgListAPI = this.organizationService.getOrgList();

    this.getAssetTypes();

    const shipmentLists = combineLatest({
      carrierCodes: carrierCodesAPI,
      ruleSets: rulesetsAPI,
      areas: areasAPI,
      sensorProfiles: sensorProfilesAPI,
      routes: routesAPI,
      orgList: orgListAPI
    }).subscribe(
      (data: any) => {
        this.carrierCodesList = [...data.carrierCodes];
        this.ruleSetsList = [...data.ruleSets];
        this.areasList = [...data.areas];
        this.sensorProfilesList = [...data.sensorProfiles.map(profile=>profile.eventProfileName)];
        this.routesList = [...data.routes.hits].filter((route) => route.name);
        this.orgList = [...data.orgList];
        this.shipmentForm.get('organization').setValue(this.orgList && this.orgList[0]?.id);
        if (this.isEdit) {
          const carrierCode = this.carrierCodesList.find(
            (carrier) => carrier.carrierCode === this.shipment.carrierCode
          );

          // const ruleSet = this.ruleSetsList.find(
          //   (ruleSet) => ruleSet.name === this.shipment.ruleset
          // );

          const route = this.routesList.find(
            (route) => route.id === this.shipment.route?.id
          );

          // this.shipment.ruleset = ruleSet;
          this.shipment.carrierCode = carrierCode;
          this.shipment.route = route;
          this.ruleIdUpdate();
          this.buildForm();
        }
      },
      (error) => {
        console.error(error);
      }
    );

    this.subscriptions.push(shipmentLists);
  };

  getAssetTypes() {
    this.assetTypesLoading = true;
    const assetTypesSubscription: Subscription = this.shipmentsService
      .getAssetTypes()
      .subscribe((data: any) => {
        this.assetTypesList = data[0].hits[0].buckets;
        this.assetTypesList.forEach((element) => {
          element.checked = false;
          element.subBucket.forEach((elemStatus) => {
            element[elemStatus.key.toUpperCase()] = elemStatus.value;
          });
        });
        this.assetTypesLoading = false;
        this.getSelectedAssetTypes();
      });
    this.subscriptions.push(assetTypesSubscription);
  }

  getSelectedAssetTypes() {
    const assetsTypesData: Subscription = this.shipmentsService
      .getAllAssetTypes()
      .subscribe((res) => {
        this.assetObjList = res;
        this.assetObjList.forEach((asset) => {
          asset.value = 0;
          asset['MONITORED'] = 0;
          asset['UNMONITORED'] = 0;
          const aggregateAsset = this.assetTypesList.find(
            (aggAsset) => aggAsset.key === asset.id
          );
          if (aggregateAsset) {
            asset.value = aggregateAsset.value || 0;
            asset['MONITORED'] = aggregateAsset?.['MONITORED'] || 0;
            asset['UNMONITORED'] = aggregateAsset?.['UNMONITORED'] || 0;
          }
        });
      });
    this.subscriptions.push(assetsTypesData);
  }

  /**
   * These methods are typeaheads. They will invoke while changing the input field.
   * And the condition is if the input length is greater than 3 then only those respective apis will invoked
   */
  initializeTypeAheads = () => {
    this.getCustomerCodes();
   };


  getCustomerCodes() {
   const coustomerCodesData: Subscription = this.shipmentForm
      .get('custCode')
      .valueChanges.pipe(
        debounceTime(500),
        filter((value: string) => value.length > 2),
        tap(() => (this.isLoading = true)),
        switchMap((value) =>
        this._userService
        .getEntityAttributeValues('SHIPMENT','custCode',value)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe((data) => {
        this.coustomerCodesList = Object.values(data);
        this.coustomerCodesList = this.dynamicCustomerCode.concat(this.coustomerCodesList)
        if((this.coustomerCodesList.length == 0 || this.coustomerCodesList.filter(item=>item===this.shipmentForm.value.custCode).length ===0) && !_.isEmpty(this.shipmentForm.value.custCode)){
          this.customerCodeAddEnable =true
        }else{
          this.customerCodeAddEnable =false
        }
      });
    this.subscriptions.push(coustomerCodesData);
  }

  displayFn(item) {
    if (item) {
      if (item.name) {
        return item.name;
      }
      if (item.carrierName) {
        return item.carrierName;
      } else {
        return item;
      }
    }
  }

  depDateUpdated(datesUpdated, status: any) {
    if (status == 'plannedArrival') {
      if (datesUpdated && datesUpdated.startDate) {
        this.shipmentForm.get('plannedArrival').patchValue(datesUpdated.startDate);
        this.dateforETA =moment(datesUpdated.startDate)
      }
    } else {
      if (datesUpdated && datesUpdated.startDate) {
        this.preventBeforeArrivalDate = moment(datesUpdated.startDate);
        this.shipmentForm.get('plannedDeparture').patchValue(datesUpdated.startDate);
        this.getEstimateArrival()
      }
    }
  }
  convertDateforETA(date:any){
    if(!date) {
      return ""
    }else{
     return moment(date).format('MMM')+" ,"+moment(date).format('DD')+" "+moment(date).format('YYYY')+" at "+moment(date).format('hh:mm A')
    }
  }

  getEstimateArrival(){
    if(this.selectedRoute?.id){
      this.datePickerOpenedArrival =false;
      const ETA: Subscription = this.shipmentsService
      .getEstimateArrival(this.selectedRoute.id, moment(this.shipmentForm.value.plannedDeparture).format("MM/DD/YYYY HH:mm:ss")) // for Api request need to change in this format
      .subscribe((c: any) => {
        if(c.computedETA?.length){
          this.dateforETA =moment(c.computedETA)
          this.datePickerOpenedArrival =true;
          this.arrivalDateRange = { startDate: this.dateforETA, endDate: moment() };
          this.shipmentForm.get('plannedArrival').patchValue(this.dateforETA);
          }else{
          this.toast.warning("Planned Arrival is not available for the selected route")
        }
      });
    this.subscriptions.push(ETA);
    this.enableArrival =false;
    }
  }

  // ROUTE HANDLERS
  public onCloseRoute() {
    if (!this.shipmentForm.value.route) {
      this.selectedRoute = null;
      this.flightDetails = [];
      this.destination = [];
    }
    if(!this.routesList.length){
      this.shipmentForm.get('route').patchValue('');
      this.getRoutesbySearch();
    }
  }
  public getRoutesbySearch() {
    if (this.shipmentForm.value.route.length > 2 || this.shipmentForm.value.route.length == 0) {
      this.isLoading = true
      const RoutesbySearch: Subscription = this.shipmentsService
        .getRouts(this.shipmentForm.value.route)
        .subscribe((data: any) => {
          this.isLoading = false;
          this.routesList = [...data.hits].filter((route) => route.name);
        });
      this.subscriptions.push(RoutesbySearch);
    }
  }
  public onSelectRoute(value) {
    this.selectedRoute = null;
    this.flightDetails = [];
    this.destination = [];

    if (this.shipmentForm.value.route.length > 25) {
      this.shipmentForm
        .get('route')
        .patchValue(this.shipmentForm.value.route.substring(0, 24) + '...');
    }
    this.routesList.forEach((el) => {
      if (el.name == value.option.value.name) {
        this.selectedRoute = el;
        if(typeof this.selectedRoute.locationDetails == 'string'){
          this.selectedRoute.locationDetails = JSON.parse(
            this.selectedRoute.locationDetails
          );
        }
        this.selectedRoute.locationDetails.sort(function(a, b) {
          return a.loc_seq_order_no - b.loc_seq_order_no  });
        this.selectedRoute.locationDetails.forEach((el, i) => {
          const Area: Subscription = this.shipmentsService
          .getFlighAreaDetails(this.selectedRoute.locationDetails[i].areaId).subscribe((res:any)=> el.properties= res.properties)
          this.subscriptions.push(Area);
          if (el.travel_mode == 'Air' && this.selectedRoute.locationDetails[i + 1]?.areaType =="Airport") {
            this.flightDetails.push(el);
             let selectData =this.selectedRoute.locationDetails[i + 1];
             this.destination.push(selectData);
          }
        });
      }
    });
  }
  public editSelectRoute(value) {
    this.selectedRoute = null;
    this.flightDetails = [];
    this.destination = [];
    if(!value) return;
    this.selectedRoute = value.properties;
    if(typeof this.selectedRoute.locationDetails == 'string'){
      this.selectedRoute.locationDetails = JSON.parse(
        this.selectedRoute.locationDetails
      );
    }
    this.selectedRoute.locationDetails.sort(function(a, b) {
      return a.loc_seq_order_no - b.loc_seq_order_no  });
    this.selectedRoute.locationDetails.forEach((el, i) => {
      const Area: Subscription = this.shipmentsService
      .getFlighAreaDetails(this.selectedRoute.locationDetails[i].areaId).subscribe((res:any)=> el.properties= res.properties)
      this.subscriptions.push(Area);
      if (el.travel_mode == 'Air' && this.selectedRoute.locationDetails[i + 1]?.areaType =="Airport") {
        this.flightDetails.push(el);
         let selectData =this.selectedRoute.locationDetails[i + 1];
         this.destination.push(selectData);
      }
    });
    if(this.isEdit && this.shipment.shipmentRouteSegments){
      if(typeof this.shipment.shipmentRouteSegments == 'string'){
        this.shipment.shipmentRouteSegments = JSON.parse(
          this.shipment.shipmentRouteSegments
        );
      }
      if(this.shipment.shipmentRouteSegments.length){
        this.shipment.shipmentRouteSegments.forEach((seg , i) => {
          this.flightDetails[seg.properties.segmentIndex].text = seg.carrierId
          this.flightDetails[seg.properties.segmentIndex].date = moment(seg['carrierDeptTime']).tz(seg.properties.timeZone).format('YYYY-MM-DD')
          this.flightDetails[seg.properties.segmentIndex].flightTime = moment(seg['carrierDeptTime']).tz(seg.properties.timeZone).format('h:mm:ss A')
        })
      }
    }
  }
  // MAWB HANDLERS
  onMawbChange(evt: any) {
    let value = evt.target.value as string;
    if(!value){
      this.routeAWB=[];
      this.routeisLoading =false;
    }
    value = value.replace(/ /g, '');
    if (value.length > 11) value = value.slice(0, 11);

    if (isNaN(+value)) {
      value = value
        .split('')
        .filter((val) => !isNaN(+val))
        .join('');
    }

    value = (
      value.slice(0, 3) +
      ' ' +
      value.slice(3, 7) +
      ' ' +
      value.slice(7, 11)
    ).trim();

    this.mawb = value;
    this.mawbInput.nativeElement.value = value;
  }
getAWBdata(){
  this.routeAWB=[];
  this.routeisLoading =true;
     const MAWB: Subscription = this.shipmentsService
    .getAwbData(this.mawb.replace(/ /g, ""))
    .subscribe((c: any) => {
      this.routeisLoading =false;
      this.dataAWB = c
      if(this.dataAWB.length){
        this.enableDeparture =false;
        if(this.dataAWB.length==1){
          this.routeAWB.push(this.dataAWB[0].segSrcCode)
          this.routeAWB.push(this.dataAWB[0].segDestCode)
        }else{
          this.dataAWB.forEach((route,i)=> {
            if(i==0){
              this.routeAWB.push(route.segSrcCode)
              this.routeAWB.push(route.segDestCode)
            }
            else{
              this.routeAWB.push(route.segDestCode)
            }
          })
        }
      }else{
        this.toast.warning("No data available for this Master Airway Bill");
      }
    });
  this.subscriptions.push(MAWB);
  }

  //CAlENDER EVENTS
  reIntializeCalendar(){
    this.shipmentForm.get("plannedArrival").setValue(null);
    this.shipmentForm.get("plannedDeparture").setValue(null);
    this.departureDateRange= { startDate: moment(), endDate: moment() };
    this.arrivalDateRange={ startDate: moment(), endDate:moment() };
    this.plannedArrivalValue='';
    this.plannedDepartureValue='';
    this.enableArrival =true;
    this.enableDeparture =true;
  }
  // ASSET HANDLERS

  updateFilter(event: any, type: any) {
    if (type == 'assetTypes') {
      this.searchAssetTypes = event;
    } else {
      this.assetsScrollId = null;
    }
  }
  checkNames() {
      if (this.shipmentID && this.isEdit && this.shipmentName == this.shipmentForm.value.name){
             this.nameErrorValidation = false ;
       }else{
        const shipmentNames: Subscription = this.shipmentsService
        .getShipmentNames(this.shipmentForm.value.name, 'name', this.shipmentCatalog?.catalogType)
        .subscribe((c: any) => {
          if (c.totalHits) {
            this.nameErrorValidation = true;
            this.nameErrorText = 'Shipment name is already exists';
          } else {
            this.nameErrorValidation = false;
          }
        });
      this.subscriptions.push(shipmentNames);
      }
  }
  checkExternelID() {
    if (this.shipmentForm.value.externalId){
      if (this.shipmentID && this.isEdit && this.shipmentExternalId == this.shipmentForm.value.externalId){
          this.externalIdErrorValidation = false;
   }else{
        const externalIDs: Subscription = this.shipmentsService
        .getShipmentNames(this.shipmentForm.value.externalId, 'externalId', this.shipmentCatalog?.catalogType)
        .subscribe((c: any) => {
          if (c.totalHits) {
            this.externalIdErrorValidation = true;
            this.externalIdErrorText = 'External ID is already exists';
          } else {
            this.externalIdErrorValidation = false;
          }
        });
      this.subscriptions.push(externalIDs);
   }
     } else{
      this.externalIdErrorValidation = false;
     }
  }
  public validateName(name: string) {
    return this.regularExpression.test(String(name));
  }
  onChangeMonitorAssetType() {
    this.selectedAssets =
      this.selectedCategories =
      this.inventoryAssets =
      this.assetTypesList =
        [];
    this.totalAssets = 0;
    this.getAssetTypes();
  }

  getInventoryAssets(
    event: any,
    category: any,
    searchTerm: any,
    loadmore: boolean
  ) {
    this.assetsLoading = true;
    if (!loadmore) {
      this.selectedAssets = this.inventoryAssets = [];
      this.assetsScrollId = null;
      this.allComplete = false;
    }
    if (searchTerm == '') {
      searchTerm = null;
    }
    if (event) {
      this.togglers.assets = true;
      if (event.checked == true) {
        this.selectedCategories.push(category.id);
      } else {
        this.selectedCategories = this.selectedCategories.filter(function (
          id: any
        ) {
          return id !== category.id;
        });
      }
    }
    let payload = {
      match: 'must',
      scrollId: this.assetsScrollId,
      scrollSize: 50,
      globalQueryText: searchTerm,
      searchQueries: [
        {
          fieldName: 'baseClass',
          queryText: 'Asset',
          queryOperator: 'should',
          queryType: 'match',
          childFilters: [
            {
              fieldName: 'typeId',
              queryText: this.selectedCategories.toString(),
              queryOperator: 'must',
            },
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: 'monitoredStatus',
              queryText:
                this.assetType === 'ALL'
                  ? 'monitored,unmonitored'
                  : this.assetType.toLowerCase(),
              queryOperator: 'should',
            },
          ],
        },
      ],
    };
    const assetsData: Subscription = this.shipmentsService
      .globalSearch(payload)
      .subscribe((res) => {
        this.assetsLoading = false;
        this.totalAssets = res.totalHits;
        res.hits.slice().forEach((element) => {
          element.trackingId = null;
          element.checked = false;
          element.sensorId = element.sensorId;
          element.isAdded = false;
          this.parcelShipTaggedAssetList.forEach((asset) => {
            if (asset.id == element.id) {
              element.isAdded = true;
            }
          });
        });
        if (
          searchTerm ||
          this.inventoryAssets.length == 0 ||
          loadmore == false
        ) {
          this.inventoryAssets = res.hits.slice();
        } else {
          this.inventoryAssets = this.inventoryAssets.concat(res.hits);
          this.allComplete = false;
        }
        this.assetsScrollId = res._scroll_id;
      });
    this.subscriptions.push(assetsData);
  }

  async getExtendedAttributes(){
    const fields = this.shipmentCatalog.cdmFields.filter(field => field.group === 'User' && field.instanceUserCreatable);

    const orderedFields = this._dynamicFormService.orderFields([...fields],'order');

    this.dynamicInputFields = await this._dynamicFormService.generateInputFields(orderedFields,this.isEdit,this.shipmentData?.shipmentNode?.properties);
  }

  // FIXME: NEED TO BE REFACTOR
  public getSensorProfiles() {
    this.shipmentsService.getSensorProfiles().subscribe((res) => {});
  }

  getSensors(string: any) {
    this.sensorsListSubject.next(string)
  }

  initSensorsByLazy(){
    this.sensorsListSubject.pipe(debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged()).subscribe(searchValue => {
      this.isLoading = true;
      this.shipmentsService
      .getSensorTages(searchValue)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((c:any) => (this.sensorsList = c.hits));
    })
  }

  onFinish() {
    this.finishAssetsAdding = true;

    this.togglers.assetTypes = false;
    this.togglers.assets = false;
    let close = this.parcelShipTaggedAssetList.filter(function (item: any) {
      return !item.trackingNumber;
    });
    this.parcelShipTaggedAssetList.forEach((element) => {
      if (element.trackingNumber) {
        element.isEdit = false;
      }
    });
    this.assetTypesList.forEach((element) => {
      element.checked = false;
    });
    this.assetObjList.forEach((t) => (t.checked = false));
    this.inventoryAssets = [];
    this.totalAssets = 0;
    this.selectedAssets = [];
    this.selectedCategories = [];
  }
  editIncludedAsset(item) {
    item.isEdit = !item.isEdit;
  }
  deleteIncludedAsset(asset: any) {
    this.allComplete = false;
    this.assetTypesList.forEach((element) => {
      element.checked = false;
    });
    if (asset != 'deleteAll') {
      this.inventoryAssets.forEach((element) => {
        if (element.id == asset.id) {
          element.isAdded = false;
        }
      });
      this.parcelShipTaggedAssetList = this.parcelShipTaggedAssetList.filter(
        function (item: any) {
          return item.assetName !== asset.assetName;
        }
      );
      this.selectedAssets = this.selectedAssets.filter(item => item.id != asset.id)
    } else {
      this.inventoryAssets.forEach((element) => {
        element.isAdded = false;
      });
      this.parcelShipTaggedAssetList = [];
      this.selectedAssets = [];
    }
  }
  onChangeAssets(event: any, asset) {
    if (event.checked == true) {
      this.selectedAssets.push(asset);
    } else {
      asset.checked = false;
      this.selectedAssets = this.selectedAssets.filter(function (obj: any) {
        return obj.id !== asset.id;
      });
    }
    this.allComplete =
      this.inventoryAssets != null &&
      this.inventoryAssets.every((t) => t.checked);
  }
  addToShipment() {
    this.inventoryAssets.forEach((element) => {
      if (element.checked == true) {
        element.isAdded = true;
      }
      element.checked = false;
    });
    this.selectedAssets.forEach((element) => {
      let newAsset = {
        id: element.id,
        assetName: element.assetName || element.name,
        assetId: element?.externalId,
        sensorId: element.sensorId,
        categoryName: element.type,
        carrierAssetType: element.monitoredStatus,
        trackingNumber: element?.trackingId,
        catalogType: element?.entityTypeKey,
        isEdit: true,
      };
      this.parcelShipTaggedAssetList = this.parcelShipTaggedAssetList
        .filter((obj) => obj.id !== element.id)
        .concat(newAsset);
    });
    this.togglers.includedAssets = true;


  }

  onAllInventoryAssets(completed) {
    if (!completed) {
      this.inventoryAssets.forEach((element) => {
        element.checked = false;
        element.assetId = null;
        element.trackingId = null;
        element.sensorId = element.sensorId;
      });
      this.selectedAssets = [];
    } else {
      this.selectedAssets = [];
      this.inventoryAssets.forEach((element) => {
        if (element.isAdded == false) {
          element.checked = true;
          this.selectedAssets.push(element);
        }
      });
    }
    this.allComplete = completed;
    this.inventoryAssets.forEach((t) => (t.checked = completed));
  }
  someComplete(): boolean {
    return (
      this.inventoryAssets.filter((t) => t.checked).length > 0 &&
      !this.allComplete
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onSubmit(type: 'draft' | 'save') {
    const dynamicFormGroup = this.dynamicForm?.onSubmit();

    this.checkNames();
    this.checkExternelID();
    this.submitted = true;
    if(this.mawb.length==13){
      this.shipmentForm.get("route").clearValidators();
      this.shipmentForm.get("route").updateValueAndValidity();
    }
    if(type == 'save'){

      if (
        this.shipmentForm.invalid ||
        this.parcelShipTaggedAssetList.length == 0 ||
        this.nameErrorValidation ||
        this.externalIdErrorValidation ||
        (dynamicFormGroup && dynamicFormGroup.invalid) || 
        (this.customerCodeAddEnable && this.shipmentForm.value.custCode.length>2)
      ) {
        if(this.customerCodeAddEnable && this.shipmentForm.value.custCode.length>2){
          this.toast.warning('New Customer Code value must be added')
        }
        return null;
      }
    }else{
      if (
        this.nameErrorValidation ||
        this.externalIdErrorValidation
      ) {
        return null;
      }
    }


    let assets = this.parcelShipTaggedAssetList.map((asset) => {
      return {
        catalogType: asset.catalogType,
        externalId: asset.assetId,
      };
    });

    let obj = {
      name: this.shipmentForm.value.name,
      externalId: this.shipmentForm.value.externalId,
      custName: this.shipmentForm.value.custName,
      custCode: this.shipmentForm?.value?.custCode,
      modeOfTransport: this.shipmentForm.value.modeoftransport,
      carrierCode: this.shipmentForm.value.carrierCode?.carrierCode || '',
      directToResidence: this.shipmentForm.value.directToResidence,
      orderNum: this.shipmentForm.value.orderNum,
      custOrdRef: this.shipmentForm.value.custOrdRef,
      organization: this.shipmentForm.value.organization,
      assets: assets,
      plannedDeparture: moment(
        this.shipmentForm.value.plannedDeparture
      ).valueOf(),
      plannedArrival: moment(this.shipmentForm.value.plannedArrival).valueOf(),
      // type: 'standardshipment',
      ruleSet: this.shipmentForm.value.ruleset?.id || '',
      notes: this.shipmentForm.value.notes,
      direction: this.shipmentForm.value.direction,
      shipperName: this.shipmentForm.value.shipperName,
      route: {
        id: this.shipmentForm.value.route?.id || '',
        catalogType: this.shipmentForm.value.route?.entityTypeKey
      },
      mawb: this.mawb.split(' ').join(''),
      recipientName: this.shipmentForm.value.recipientName,
      tags: this.shipmentForm.value.tags,
      shipmentRouteSegments: [],
      sensorProfile: this.shipmentForm.value.sensorProfile
    };
    if(this.flightDetails){
      this.flightDetails.forEach((ele ,i) => {
        if(ele.properties){
          let seg ={
            segSrcCode: ele.properties.carrierHubCode,
            segDestCode: this.destination[i].properties.carrierHubCode,
            segSrcAreaId: ele.areaId,
            segDestAreaId: this.destination[i].areaId,
            carrierDeptTime: ele.flightTimeMilli,
            carrierId: ele.carrierId,
            carrierType: "FLIGHT",
            properties: {
                timeZone: ele.properties.timeZone,
                segmentIndex: i
            }

          }
          obj.shipmentRouteSegments.push(seg)
        }
       })
    }

    if(dynamicFormGroup){
      obj = {...obj, ...dynamicFormGroup.getRawValue() }
    }

    return obj;
  }

  tagsfun(tags) {
    // const tagNames = tags.map((tag) => tag.tagname);
    this.shipmentForm.get('tags').patchValue([...tags]);
  }
//manupulating input values for flight details
flightNumber(index,text){
  this.flightDetails[index]['text'] =text.target.value
}
flightDeparture(index,date){
  let timeZone;
  if(this.flightDetails[index].properties?.timeZone){
    timeZone = this.flightDetails[index].properties?.timeZone
  }
  let selectedDate =moment(date.target.value , "YYYY-MM-DD" , timeZone).valueOf();
  let nextDate =moment(date.target.value, "YYYY-MM-DD" , timeZone).add(1, 'days').valueOf()-1000;
  this.flightDetails[index]['date'] =date.target.value
  let segDetails = {
    carrierId: this.flightDetails[index].text,
    originCarrierHub: this.flightDetails[index].properties.carrierHubCode,
    destCarrierHub: this.destination[index].properties.carrierHubCode
  }
 const flightDeparture: Subscription = this.shipmentsService
  .getFlightDeparture(segDetails ,selectedDate.toString(),nextDate.toString())
  .subscribe((c) => {
   if(c['departureTime']){
    this.flightDetails[index]['flightTimeMilli'] = c['departureTime']
    this.flightDetails[index]['carrierId'] = c['carrierId']
    this.flightDetails[index]['flightTime'] = moment(c['departureTime']).tz(timeZone).format('h:mm:ss A')
   }
  });
this.subscriptions.push(flightDeparture);
}
// organization handlers
onOrgclose(){
  this.shipmentForm.get('organization').setValue('');
}
  allowAlphaNumericOnly(event) {
    var kcode = event.keyCode;

    if (kcode == 8 ||
        kcode == 9 ||
        kcode == 46 ||
        kcode == 95 ||
        (kcode > 47 && kcode < 58) ||
        (kcode > 64 && kcode < 91) ||
        (kcode > 96 && kcode < 123))
    {
        return true;
    }
    else
    {
        return false;
    }
}
restrictedCharactersOnly(event) {
  var kcode = event.key;
 if(this.validateName(kcode)){
    return true
  }else{
    return false
  }
}
onCloseField(type){
  setTimeout(() => {
    if(this.shipmentForm.value[type] !=this.shipment[type]){
      this.shipmentForm.get(type).patchValue('');
      this.shipment[type] ='';
    }
  }, 100);
}
onSelectField(value, type){
  this.shipment[type] = value.option.value
  this.shipmentForm.get(type).patchValue(value.option.value);
}
onCloseCustField(type){
  setTimeout(() => {
    if(this.shipmentForm.value[type].length<=2){
      this.shipmentForm.get(type).patchValue('');
      this.shipment[type] ='';
    }
  }, 100);
}
addCustomerCode(){
   let custcode = this.shipmentForm.value.custCode
   this.shipmentForm.get('custCode').patchValue(custcode);
   this.shipment['custCode'] = custcode
   this.dynamicCustomerCode.unshift(custcode)
   this.coustomerCodesList = this.dynamicCustomerCode.concat(this.coustomerCodesList)
   this.customerCodeAddEnable =false
}
openHelpDialog(){
  // const dialogData = new helpDialogModel("Search Help Info", this.helpText, this.helpText);
  const dialogRef = this.dialog.open(ContentDialogComponent, {
    maxWidth: "400px",
    data:{
      message: 
      '<div class="pb-1">'+
      '<i class="fa fa-info-circle help-icon color4 text-base mr-1 absolute top-1.5 left-0"></i>'+
      '<span class="font-bold">Note</span>: list of customer codes is visible to all users who can create/edit shipments. New customer codes will be added to list of values automatically. Customer codes are case insensitive'+
      '</div>',
      buttonText: 'Close'
  }
  });

} 
}
