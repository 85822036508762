import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShipmentListStateService } from '../shipment-list-state.service';
import { ShipmentService } from '../../shipment.service';

@Component({
  selector: 'cl-shipments-fliter',
  templateUrl: './shipments-fliter.component.html',
  styleUrls: ['./shipments-fliter.component.scss'],
})
export class ShipmentsFliterComponent implements OnInit {
  filters: any[] = [];
  mainWidth: any;
  tableWidth: number;
  columnKeys: string[] = [];
  filterArray = [];
  actionCount: number = 0;
  DisableCustomCounts= [] //add filterType from filter group to remove counts 
  monitoredFilters = [
    'statusCode',
    'direction',
    'originAreaId.keyword',
    'directToResidence',
    'destinationAreaId.keyword',
    'carrierName',
    'conditionalExcursions',
    'delays',
    'deliveryPerformance.keyword',
    'excursions',
    'excursionByLocation',
    'routeId',
    'entityTypeKey'
  ];

  selectedFilters = {
    statusCode: [],
    direction: [],
    'originAreaId.keyword': [],
    directToResidence: [],
    'destinationAreaId.keyword': [],
    carrierName: [],
    conditionalExcursions: [],
    delays: [],
    'deliveryPerformance.keyword': [],
    excursions: [],
    excursionByLocation: [],
    routeId: [],
    entityTypeKey:[]
  };

  dateRangeFilter: {
    range: { startDate: moment.Moment; endDate: moment.Moment };
    options: string[];
  } = {
    range: null,
    options: [],
  };

  private dateRangeType: {
    collapsed: boolean;
    expand: boolean;
    enableCollapsing: boolean;
    filterType: 'dateRange';
    label: string;
    type: 'dateRangeFilter';
    defaultRange: { startDate: moment.Moment; endDate: moment.Moment };
    list: {
      name: string;
      id: string;
      checked?: boolean;
    }[];
  } = {
    defaultRange: null,
    collapsed: false,
    expand: true,
    enableCollapsing: false,
    filterType: 'dateRange',
    label: 'Date Range',
    type: 'dateRangeFilter',
    list: [
      {
        name: 'Planned Departure',
        id: 'plannedDeparture',
      },
      {
        name: 'Planned Arrival',
        id: 'plannedArrival',
      },
      {
        name: 'Actual Departure',
        id: 'actualDepTime',
      },
      {
        name: 'Actual Arrival',
        id: 'actualArrivalTime',
      },
      {
        name: 'Completed Time',
        id: 'completedTime',
      },
      {
        name: 'Last Carrier Update At',
        id: 'lastCarrierUpdateAt',
      },
    ],
  };
  private subscriptions: Subscription[] = [];

  constructor(
    private shipmentsService: ShipmentService,
    private _shipmentListState: ShipmentListStateService
  ) {}

  ngOnInit(): void {
    this.getEntityFilters();
  }
  toggleFilterPanel() {
    this._shipmentListState.toggleFilters();
  }
  private getEntityFilters(): void {
    const getEntityFilterData: Subscription = this.shipmentsService
      .getShipmentFilters()
      .subscribe(
        (data: any) => {
          this.filterArray = [];

          data.forEach((element) => {
            if (element.labelKey === 'directToResidence') {
              element.options.forEach((option) => {
                if (option.id === 'false') {
                  option.name = 'No';
                } else if (option.id === 'true') {
                  option.name = 'Yes';
                }
              });
            }

            let obj = {
              collapsed: false,
              expand: true,
              enableCollapsing: false,
              filterType: element.labelKey,
              label: element.label,
              type: 'multiselect',
              list: element.options,
            };
            this.filterArray.push(obj);
          });

          const stateFilters =
            this._shipmentListState.settings.filters.childFiltersQuerys;
          if (stateFilters.length > 0) {
            stateFilters.forEach((stateFilter) => {
              if (stateFilter.id === 'dateRange') {
                this.dateRangeFilter.options = [...stateFilter.values];
                this.dateRangeFilter.range = stateFilter.date;
              } else {
                this.selectedFilters[stateFilter.id] = [...stateFilter.values];
              }
            });
          }

          this.filterArray.forEach((filter) => {
            const { filterType, list } = filter;
            if (
              this.selectedFilters[filterType] &&
              this.selectedFilters[filterType].length > 0
            ) {
              list.forEach((item) => {
                if (this.selectedFilters[filterType].includes(item.id)) {
                  item.checked = true;
                }
              });
            }
          });

          this.dateRangeType.list.forEach((item) => {
            if (this.dateRangeFilter.options.includes(item.id)) {
              item.checked = true;
            }
          });

          if (this.dateRangeFilter.range) {
            this.dateRangeType.defaultRange = this.dateRangeFilter.range;
          }

        this.filterArray.forEach(filter=>{
          if(filter.filterType.toLowerCase() == "daterange"){
          filter.defaultRange =this.dateRangeType.defaultRange
          filter.list =this.dateRangeType.list
          filter.type =this.dateRangeType.type
          }
        })

          this.filters = [...this.filterArray];
        },
        (error) => {
          console.error(error);
        }
      );

    this.subscriptions.push(getEntityFilterData);
  }

  getPanelUpdate(event: any) {
    if (event.action && event.action == 'clearFilters') {
      this.clearFilters();
    }
    if (event.action && event.action === 'filterByType') {
      const { filter } = event;
      const { id, filterGroupName, checked } = filter;

      if (filterGroupName === 'dateRange') this.createDateRangeFilter(filter);

      if (!this.monitoredFilters.includes(filterGroupName)) return;
      if (checked) {
        this.selectedFilters[filterGroupName].push(id);
      } else {
        let index = this.selectedFilters[filterGroupName].indexOf(id);
        if (index > -1) {
          this.selectedFilters[filterGroupName].splice(index, 1);
        }
      }
    }
    this.createPayloadAndReloadShipmentsList();
  }

  clearFilters() {
    this.filterArray.forEach((filter) => {
      const { filterType, list } = filter;
      if (
        this.selectedFilters[filterType] &&
        this.selectedFilters[filterType].length > 0
      ) {
        list.forEach((item) => {
          item.checked = false;
        });
      }
    });

    for (let key in this.selectedFilters) {
      this.selectedFilters[key] = [];
    }
    this.dateRangeFilter = {
      options: [],
      range: null,
    };

    this.filters = [...this.filterArray];
  }

  createDateRangeFilter({ id, filterGroupName, checked, selectedDateRange }) {
    // NOTE: id for changing date is 'dateSelected'
    this.dateRangeFilter.range = selectedDateRange;
    if (id === 'dateSelected' && this.dateRangeFilter.options.length === 0)
      return;

    if (id !== 'dateSelected') {
      if (checked) this.dateRangeFilter.options.push(id);
      else {
        let index = this.dateRangeFilter.options.indexOf(id);

        if (index > -1) this.dateRangeFilter.options.splice(index, 1);
      }
    }

    this.createPayloadAndReloadShipmentsList();
  }

  createPayloadAndReloadShipmentsList() {
    const childFilters = [];

    for (let filter in this.selectedFilters) {
      if (this.selectedFilters[filter].length > 0) {
        childFilters.push({
          id: filter,
          values: this.selectedFilters[filter],
        });
      }
    }

    // For looping date filters
    if (this.dateRangeFilter.options.length > 0 && this.dateRangeFilter.range) {
      childFilters.push({
        id: 'dateRange',
        values: [...this.dateRangeFilter.options],
        date: this.dateRangeFilter.range,
      });
    }

    this._shipmentListState.updateFilters(childFilters);
  }
}
