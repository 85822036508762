import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CustodyActions, CustodyChainResponse } from "../../common/models/index";
import {
  HidePanel,
  MalOpenBindPanel,
  MalToggleCheckoutPanel,
  MalToggleTransferPanel,
} from "../../common/actions/index";
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../common/components/confirm-dialog/confirm-dialog.component';
import { concat, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Asset } from '@cl/@types/asset.type';
import * as _ from 'lodash';
import { AssetUtilService } from '../asset-util.service';
import { AssetService } from '../asset.service';
import { AssetStatus } from '@cl/@enums/asset-status.enum';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import * as moment from 'moment';
import { ChainOfCustodyService } from '@cl/common/services/chain-of-custody.service';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";
import { ToastService } from '@cl/common/services/toast.service';
import { AssetsCOCStateService } from '../asset-coc-state.service';
import { ASSET_COC_ACTIONS } from '@cl/@types/asset-coc-state.type';
import { AssetsStateService } from '../asset-state.service';
import { ASSET_ACTIONS } from '@cl/@types/asset-state.type';

@Component({
  selector: 'cl-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit {
  readonly custodyActions = CustodyActions;
  breadCrumbItems: BreadCrumbItem[];
  assetsState$: Observable<string[]>;
  bindIcon = "fa-unlink";
  bindAction = "bind";
  assetObject: Asset;
  assetType = ''
  mapOptions: any = {
    gestureHandling: 'cooperative',
    styles: [ { "featureType": "poi", "elementType": "labels", "stylers": [ { "visibility": "off" } ] } ],
    positionOfActions: { position: google.maps.ControlPosition.RIGHT_BOTTOM }
  };
  activeEntity: any;
  asset: any;
  md: any;
  inspectorOffset: any;
  currentAction = "swapActive";
  Excursions:any;

  custodyList: CustodyChainResponse = [];

  stateActionMap: { [key: string]: { visible: boolean; enabled: boolean } };

  clientConfig$;
  cc: any;
  showChainOfCustody = true;
  loadingChainOfCustody = true;
  custodyActionInProgress: CustodyActions;
  lastReportedGPSTimeFormatted: any;

  private readonly destroyed$ = new Subject<boolean>();
  subscriptions: Subscription[] = [];
  selectedAsset: any;
  shipmentDetails: any;
  loadingShimpentsMap: boolean = true;
  sidePanelsObj: any = {
    coc: false,
    bind: false
  };
  actionBtnPolicies = {
    bind: ['TAGGEDASSET_CREATE'],
    unbind: ['TAGGEDASSET_DELETE']
  }
  assetDetils: any;
  checkoutTransferInProgress: boolean;
  selectedDateRange: any;
  unBindDisabled = true;
  isLoading = false;
  includeShipmentSensorIds:any;
  includeShipmentTaggedAssetId:any;
  includeShipmentShipmentId:any;
  shipmentType:any;
  excursionObj: any;
  ruleSetId:any;
  taggedAssetId:string;
  typeId:string;
  excursionViolatedCheck: any;
  assetCOCStateSubject: Subscription;
  currentLocation: any;
  constructor(
    private actions$: Actions,
    private store: Store,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private assetUtilService: AssetUtilService,
    private assetService: AssetService,
    private cocService: ChainOfCustodyService,
    private shipmentsService:ShipmentService,
    private shipmentUtilsService: ShipmentUtilsService,
    private webTracker : WebtrackerService,
    private toastService : ToastService,
    private assetCOCState: AssetsCOCStateService,
    private assetState: AssetsStateService
  ) {
    this.breadCrumbItems = [
      {'name': 'Previous', isBack: true, path: null},
      {'name': 'Asset Details', path: null}
    ];
    this.assetObject = {id : null, externalId : '', name: '', status : null };
    this.route.queryParams.subscribe(params=> {
      this.includeShipmentShipmentId = params['sid'];
      this.assetType = params['type']
    })
    this.route.paramMap.subscribe((params) => {
      let temp = params.get('id');
      this.assetObject.id = temp;

    });
    this.assetsState$ = this.store.select((state) => state.manage_assets_state);
    this.clientConfig$ = this.store.select((state) => state.client_config.settings);
  }

  ngOnInit(): void {
    this.watchAssetState();
    this.watchCCState();
    this.loadAssetsOnUpdate();
    this.listenToCOCEvents();
    if(this.includeShipmentShipmentId) {
      this.shipmentTypeExtendedcall(this.includeShipmentShipmentId)  
    } else {
      this.getAsset();
      const eventSub: Subscription = this.assetUtilService.getBaseAsset().subscribe((baseAsset: any) => {
        if(!this.assetDetils && baseAsset?.properties){
          baseAsset['properties']['includedInShipments'] = (typeof baseAsset?.properties?.includedInShipments == 'string') ? JSON.parse(baseAsset?.properties?.includedInShipments): baseAsset?.properties?.includedInShipments;
          this.taggedAssetId = baseAsset?.properties?.taggedAssetId;
          this.typeId = baseAsset?.properties?.typeId;
          if(this.typeId && this.taggedAssetId){
            this.getAssetExcursions(this.typeId, this.taggedAssetId);
          }
          if(baseAsset?.properties?.includedInShipments && typeof baseAsset.properties.includedInShipments == 'string'){
            baseAsset.properties.includedInShipments = JSON.parse(baseAsset.properties.includedInShipments)
          }
          if(baseAsset?.properties?.includedInShipmentsByType && typeof baseAsset.properties.includedInShipmentsByType == 'string'){
            baseAsset.properties.includedInShipmentsByType = JSON.parse(baseAsset.properties.includedInShipmentsByType)
          }
          if(baseAsset?.properties?.includedInShipmentsByType?.length){
            baseAsset?.properties?.includedInShipmentsByType.forEach(sId=>{
              this.getShipmentData(sId.id,sId.type);
            })
          }else if(baseAsset?.properties?.includedInShipments?.length){
            baseAsset?.properties?.includedInShipments.forEach(sId=>{
              this.shipmentTypeExtendedcall(sId)  
            })
          } else if(this.includeShipmentShipmentId) {
            this.shipmentTypeExtendedcall(this.includeShipmentShipmentId)  
          } else{
            this.loadingShimpentsMap = false;
            this.assetDetils = baseAsset
            this.selectedAsset = baseAsset?.properties;

          }
          this.getBindActionData(baseAsset);
        }

      });
      this.subscriptions.push(eventSub);
    }

    const closePanelSub: Subscription = this.cocService.closeSidePanel.subscribe(data=>{
      this.closeSidePanel();
    });
    this.subscriptions.push(closePanelSub);
  }
  getShipmentData(shipmentId: any,type) {
    if(!type){
      type =this.shipmentType
    }
    this.isLoading = true;
    this.shipmentsService.getShipmentData(shipmentId,type).subscribe((data) => {

      this.shipmentDetails = data;
      this.ruleSetId = this.shipmentDetails.shipmentNode.properties.ruleSet;
      // NOTE: This range will be only if we have shipment 
      const shipmentDateRange = this.shipmentUtilsService.getShipmentDateRange(
        this.shipmentDetails.shipmentNode.properties, true
      );

      this.selectedDateRange = {
        startDate: moment(+shipmentDateRange.dateRange.startDate),
        endDate: moment(+shipmentDateRange.dateRange.endDate),
      };
      this.loadingShimpentsMap = false
      this.shipmentDetails.assetNodes.forEach(assetNode=> {
        if(assetNode.id == this.assetObject.id){
          this.includeShipmentSensorIds = assetNode.properties.sensorIds.join(',');
          this.includeShipmentTaggedAssetId = assetNode.properties.taggedAssetId;
          this.assetDetils = assetNode
          this.selectedAsset = assetNode?.properties;
        }
      })
      this.getAsset();
      this.isLoading = false;
    }, error=>{
      this.loadingShimpentsMap = false;
    });
  }
  private getBindActionData(baseAsset: any) {
    if (baseAsset?.properties?.monitoredStatus) {
      if (baseAsset.properties.monitoredStatus === AssetStatus.MONITORED) {
        this.unBindDisabled = false;
      } else if (baseAsset.properties.monitoredStatus === AssetStatus.UNMONITORED) {
        this.unBindDisabled = true;
      }
    }
  }

  getExcursions(excusrions:any) {
    if(excusrions){
      this.excursionObj = excusrions;
    }
  }

  getAssetExcursions(typeId, taggedAssetId){
    this.assetService.getAssetExcursions(typeId, taggedAssetId).subscribe((res:any)=>{
      this.excursionObj = res;
      const data:any = Object.values(res?.ViolationMap);
      if(!_.isEmpty(data)){
        this.excursionViolatedCheck = data[0][0].violated;
      }
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.subscriptions.forEach(sub => { sub.unsubscribe(); })
    const obs = [
      this.store.dispatch(new HidePanel("inspector")),
      this.store.dispatch(new MalToggleCheckoutPanel(null, false)),
      this.store.dispatch(new MalToggleTransferPanel(null, false)),
    ];

    concat(...obs).subscribe();
    this.assetCOCStateSubject?.unsubscribe()
  }

  watchCCState() {
    this.clientConfig$.subscribe((cc: any) => {
      this.cc = { ...cc };
      this.showChainOfCustody = this.cc && this.cc.features && this.cc.features["chain_of_custody"] ? true : false;
    });
  }

  getAsset() {
    if(!_.isEmpty(this.assetObject.id)){
      this.assetUtilService.getAsset(this.assetObject.id,this.shipmentDetails?this.assetDetils:'', this.assetType)
      .then((response: Asset) => {

        this.assetObject = response;
        if(this.assetObject?.properties?.eventPublisherMap && typeof this.assetObject?.properties?.eventPublisherMap == 'string'){
          this.assetObject.properties.eventPublisherMap = JSON.parse(this.assetObject?.properties?.eventPublisherMap)
        }
        if(this.assetObject?.properties?.includedInShipments && typeof this.assetObject?.properties?.includedInShipments == 'string'){
          this.assetObject.properties.includedInShipments = JSON.parse(this.assetObject?.properties?.includedInShipments)
        }

        if(!this.shipmentDetails){
          this.selectedDateRange = {
            startDate: this.assetObject?.properties?.boundAt
            ? moment(+this.assetObject.properties.boundAt)
            : moment().subtract(24, 'hours'),
            endDate: moment(),
          };
        }
        this.selectedAsset = this.assetObject?.properties;
        // We dont want to display as hyperlink 'Device Id' if user navigating from shipment details page under these conditions
        // 1. If asset is unmonitored
        // 2. If shipment is completed or terminated
        if(this.selectedAsset && this.shipmentDetails){
          if(this.includeShipmentShipmentId && this.shipmentDetails?.shipmentNode?.properties?.statusCode == 'Completed') {
            this.assetObject.monitoredStatus = AssetStatus.UNMONITORED;
          }
          if(
            this.shipmentDetails?.shipmentNode?.properties?.statusCode == 'Completed' ||
            this.selectedAsset?.monitoredStatus?.toUpperCase() == 'UNMONITORED'){
              this.assetObject.isSensorNotHavingLink = true
          }
          if(!this.assetObject.shipment){
            this.assetObject.shipment = {
              id: this.shipmentDetails.shipmentNode.id,
              name: this.shipmentDetails.shipmentNode.properties.name,
              externalId: this.shipmentDetails.shipmentNode.properties.externalId
            }
            this.assetObject.sensorIds = this.assetDetils.properties.sensorIds;
          }

        }

        if(this.includeShipmentShipmentId) {
          this.filterEmptyMeasures()
        }
        if(response.lastReportedGPSTime) {
          this.lastReportedGPSTimeFormatted = moment(response.lastReportedGPSTime).format('MM/DD/yyyy hh:mm:ss a');
        }
      });
    }
  }

  // formate messages
  formatMessages(){
    let successMessages:any = [];
    let tempSuccessKey:any = [];
    let errorMessages:any = [];
    const regExp = /^SUCCESS/;
    let activeEntity:any = this.activeEntity;
    activeEntity?.forEach((msg) => {
      for (let [key, value] of Object.entries(msg)) {
        let tempData:any = value;
        const check = regExp.test(tempData);
        if(check){
          tempSuccessKey.push(key);
          if(successMessages.length >= 1){
            successMessages = [];
            successMessages.push(`${tempSuccessKey.join(' ,')} : sensors have been bound successfully.`)
          }  else {
             successMessages.push(`${ key} : sensors have been bound successfully.`)
          }
        } else {
          errorMessages.push(`${ key} : ${value }`)
        }
      }
    });
    let finalMessage = `${successMessages}   ${errorMessages}`;
    if((successMessages.length === 0 && errorMessages.length) || (successMessages.length && errorMessages.length)){
     this.toastService.error(finalMessage,'Error',10000);
    } else if(successMessages.length && errorMessages.length === 0){
     finalMessage = 'All the sensors have been bound to asset successfully.';
     this.toastService.success(finalMessage,'Success',10000);
    }
 }
  watchAssetState() {
    this.assetState.assetStateObservable.subscribe((md) => {
      this.md = { ...md };
      this.activeEntity = md.activeEntity;
      this.custodyActionInProgress = this.md.custodyActionInProgress;
      if (md.currentAction === ASSET_ACTIONS.ASSET_BOUND || md.currentAction === ASSET_ACTIONS.ASSET_UNBOUND) {
        let name = this.md.nodeToEdit.name || this.md.nodeToEdit.assetId;
        this.bindIcon = 'fa-unlink';
        this.formatMessages();
        this.closeSidePanel();
        this.getAsset()
      }
    });

    this.assetCOCStateSubject = this.assetCOCState.assetCOCStateObservable.subscribe(state => {
      if (state.currentAction === ASSET_COC_ACTIONS.TOGGLE_COC_PANEL) {
        if(state.showCocPanel){
          this.sidePanelsObj.coc = true; 
        }else{
          this.getAsset();
        }
        this.closeSidePanel();
      }
    })
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  closeSidePanel(event?) {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
  }
  bindOrUnbind(bindAction) {
    let assetToUnbound = this.selectedAsset;

    if (bindAction === "Unbind") {
      this.unbindAsset();
    } else {
      this.closeSidePanel();
      this.sidePanelsObj.bind = true;
      this.store.dispatch(new MalOpenBindPanel(assetToUnbound, ""));
      this.webTracker.captureEvent('bindAsset_bindSelect', {'assetId': assetToUnbound.externalId});
    }
  }

  unbindAsset() {
    let message = `Are you sure you want to unbind this asset?: ` + this.selectedAsset.externalId;
    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.bindIcon = 'fa-circle-o-notch fa-pulse';
        this.unBindDisabled = true;
        this.assetState.unbindAsset([this.selectedAsset])
      }
    });
  }
  private loadAssetsOnUpdate(): void {
      this.assetState.assetStateObservable.subscribe(res => {
        if(res.currentAction == ASSET_ACTIONS.ASSET_BOUND || res.currentAction == ASSET_ACTIONS.ASSET_UNBOUND){
          this.getAsset()
        }
      })
  }

  listenToCOCEvents() {
    const eventSub: Subscription = this.cocService.getCOCEvent().subscribe(evt => {
      if (evt.actionInProgress) {
        this.checkoutTransferInProgress = true;
      } else {
        this.checkoutTransferInProgress = false;
      }
    });
    this.subscriptions.push(eventSub);
  }

  filterEmptyMeasures() {
    if(this.assetObject.measures && this.assetObject.measures.length > 0){
      this.assetObject.measures = this.assetObject.measures.filter(e=> {
        if(e.value){
          return true
        } else {
          if(!this.selectedAsset.feedDetails) return false;
          switch (e.measureCharacteristicId){
            case 'cargoTemp':
              e.value = this.selectedAsset.feedDetails.cargotemperature;
              e.timeOfCapture = this.selectedAsset.feedDetails.cargotemperatureTime;
              break;
              case 'ambientTemp':
              e.value = this.selectedAsset.feedDetails.ambienttemperature;
              e.timeOfCapture = this.selectedAsset.feedDetails.ambienttemperatureTime;
              break;
              case 'temperature':
              e.value = this.selectedAsset.feedDetails.temperature;
              e.timeOfCapture = this.selectedAsset.feedDetails.temperatureTime;
              break;
              case 'doorStatus':
              e.value = this.selectedAsset.feedDetails.doorstatus;
              e.timeOfCapture = this.selectedAsset.feedDetails.doorstatusTime;
              break;
              case 'rxShockMagnitude':
              e.value = this.selectedAsset.feedDetails.shock;
              e.timeOfCapture = this.selectedAsset.feedDetails.shockTime;
              break;
              case 'rxTilt':
              e.value = this.selectedAsset.feedDetails.tilt;
              e.timeOfCapture = this.selectedAsset.feedDetails.tiltTime;
              break;
              case 'rxPressure':
              e.value = this.selectedAsset.feedDetails.pressure;
              e.timeOfCapture = this.selectedAsset.feedDetails.pressureTime;
              break;
              case 'AA21':
              e.value = this.selectedAsset.feedDetails.humidity;
              e.timeOfCapture = this.selectedAsset.feedDetails.humidityTime;
              break;
              case 'rxLight':
              e.value = this.selectedAsset.feedDetails.light;
              e.timeOfCapture = this.selectedAsset.feedDetails.lightTime;
              break;
              case 'cargoTemp':
              e.value = this.selectedAsset.feedDetails.cargotemperature;
              e.timeOfCapture = this.selectedAsset.feedDetails.cargotemperatureTime;
              break;
              case 'residualThermalCharge':
              e.value = this.selectedAsset.feedDetails.residualthermalcharge;
              e.timeOfCapture = this.selectedAsset.feedDetails.residualthermalchargeTime;
              break;
              case 'residualThermalTime':
              e.value = this.selectedAsset.feedDetails.residualthermaltime;
              e.timeOfCapture = this.selectedAsset.feedDetails.residualthermaltimeTime;
              break;
              default:
              break;
          }
          return e.value !== undefined;
        }
      });

    }

  }
  async getShipmentType(id: string) {
    if (!this.shipmentType) {
      let data = await this.shipmentsService.getShipmentType(id);
      this.shipmentType = data[0].entityTypeKey;
    }
    return this.shipmentType;
  }
  async shipmentTypeExtendedcall(id: string) {
    let type = await this.getShipmentType(id);
    this.shipmentType = type;
    this.getShipmentData(this.includeShipmentShipmentId,type);
    return type;
  }

  getCurrentLocation(location:any){
    this.currentLocation = location;
  }
}