<form name="ruleManager" autocomplete="off" #ruleForm="ngForm" (submit)="saveOrUpdateRule(ruleForm)" class="cl-rule">
  <div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title side-panel-header-bg justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/entry_exit.svg" />
            <span *ngIf="isNew" class="black font-bold">New Business Rule</span>
            <span *ngIf="!isNew" class="black font-bold">Update Business Rule</span>
        </div>
        <span class="fa fa-times-circle-o mr-4" (click)="closeEditor()" [ngClass]="{'no-pointer': loading}"></span>
    </section>
    <section class="text-center relative top-1/3 h-0" [ngClass]="{'visible': loading, 'invisible': !loading}">
      <span class="fa fa-circle-o-notch fa-pulse color4 fa-4x"></span>
    </section>
    <section class="cl-side-panel-content flex flex-col p-3">
      <div>
        <span [textContent]="'rules.RULE_NAME'|translate"></span><span class="color21">*</span>
        <input name="ruleName" #ruleName="ngModel" type="text" class="m-0 rounded basic-input" [(ngModel)]="rule.title" required="true"/>
        <div class="color21 font-11-normal" *ngIf="ruleName.invalid && isSaveTriggered">Rule name is mandatory</div>
      </div>
      <div class="mt-2">
        <div>
          <span [textContent]="'rules.ORGANIZATION'|translate"></span><cl-org-info-icon-dialog></cl-org-info-icon-dialog>
          &nbsp; <span *ngIf="!rule.organization" class="color10 pl-1 h5" [textContent]="'rules.SELECT_ORGANIZATION'|translate"></span>
          <div class="mt-2 p-4 rounded bgcolor32 relative">
            <mat-select [(ngModel)]="rule.organization" (selectionChange)="_fnGetSelectedOrg()"
            name="organization" #organization="ngModel" [value]="selectedOption" placeholder="----Select Organization----">
              <mat-option [value]="''" *ngIf="userType">----Select Organization----</mat-option>
              <mat-option  *ngIf="!isOrgRestrictedUser" [value]="'ANY'" [textContent]="'(Any)'" ></mat-option>
              <mat-option *ngFor="let org of orgList" [value]="org.id" [textContent]="org.name">
              </mat-option>
            </mat-select>
            <mat-icon  *ngIf="(rule.organization || selectedOption) && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
          </div>
        </div>
      </div>
      <p *ngIf="rule.organization=='ANY'" class="orgcontent"><span>Warning</span>: Data will be seen by all users including those outside your org</p>
      <div class="mt-2">
        <div class="pt-1">
          <span [textContent]="'rules.RULE_TYPE'|translate"></span>
          <span class="color21">*</span>
        </div>
        <div class="color21 font-11-normal" *ngIf="ruleType.invalid && isSaveTriggered">Rule Type is mandatory</div>
        <mat-radio-group [(ngModel)]="rule.ruleType" name="ruleType" #ruleType="ngModel" (change)="fnRuleTypeChanged()" required="true">
          <div *ngFor="let ruleTypeName of ruleTypeNames" class="pt-2">
            <mat-radio-button [value]="ruleTypeName" [disabled]="disableRuleType">
              <span class="capitalize" [textContent]="ruleTypeName"></span>
              <span>&nbsp;</span>
              <span [textContent]="'rules.RULE'|translate"></span>
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
      <div class="mt-2">
        <div>
          <span [textContent]="'rules.EVENT'|translate"></span>
          &nbsp;<span *ngIf="!rule.ruleType" class="color10 pl-1 h5" [textContent]="'rules.SELECT_RULE_TYPE'|translate"></span>
        </div>
        <div class="p-4 rounded bgcolor32">
          <div>
            <span [textContent]="'rules.TRIGGER_TYPE'|translate"></span>
            <span class="color21">*</span>
          </div>
          <mat-select [(ngModel)]="rule.triggerGroup" (selectionChange)="fnTriggerGroupChanged()" required="true" name="triggerGroup" #triggerGroup="ngModel">
            <mat-option *ngFor="let triggerGroup of listOfTriggerGroups" [value]="triggerGroup.key" [textContent]="triggerGroup.name">
            </mat-option>
          </mat-select>
          <div class="color21 font-11-normal" *ngIf="triggerGroup.invalid && isSaveTriggered">Trigger Type is mandatory</div>
          <div class="mt-2">
            <span [textContent]="'rules.EVENT'|translate"></span>
            <span class="color21">*</span>
          </div>
          <mat-select [(ngModel)]="rule.trigger" (selectionChange)="fnTriggerChanged()" required="true" name="trigger" #trigger="ngModel">
            <mat-option *ngFor="let trigger of listOfTriggers" [value]="trigger" [textContent]="trigger.metricName">
            </mat-option>
          </mat-select>
          <div class="color21 font-11-normal" *ngIf="trigger.invalid && isSaveTriggered">Event Trigger is mandatory</div>
        </div>
      </div>
      <div class="mt-2" *ngIf="rule.trigger && ruleContextType">
        <div class="pt-1">
          <span [textContent]="'rules.RULE_CONTEXT'|translate"></span>
          <span class="color21">*</span>
        </div>
        <div class="color21 font-11-normal" *ngIf="ruleType.invalid && isSaveTriggered">Rule context is mandatory</div>
        <mat-radio-group [(ngModel)]="ruleContext" [ngModelOptions]="{standalone: true}" (change)="_fnConfigureRuleContext($event.value)" required="true">
          <div *ngFor="let ruleContextName of ruleContextNames" class="pt-2">
            <mat-radio-button [value]="ruleContextName" [disabled]="disableRuleType">
              <span class="capitalize" [textContent]="ruleContextName"></span>
              <!-- <span>&nbsp;</span>
              <span [textContent]="'rules.RULE_CONTEXT'|translate"></span> -->
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
      <div class="mt-2">
        <div>
          <span [textContent]="'rules.OPTIONS'|translate"></span>
          &nbsp; <span *ngIf="!rule.trigger" class="color10 pl-1 h5" [textContent]="'rules.SELECT_EVENT'|translate"></span>
        </div>
        <div class="p-4 rounded bgcolor32">
          <div *ngIf="rule.trigger">
            <div *ngFor="let triggerParam of rule.trigger.inputFormJSON" [ngSwitch]="triggerParam.type">
              <div *ngSwitchCase="'select'">
                <div>
                  <span [textContent]="triggerParam.templateOptions.tlabel | translate"></span>
                  <span class="color21" *ngIf="triggerParam.templateOptions.required">*</span>
                </div>
               <mat-select *ngIf="!isNew && !isUpdate && triggerParam.key =='area'" [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnTriggerParamsSelectChange(triggerParam.key, true)" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option">{{option.name}}
                  </mat-option>
                </mat-select>
                  <mat-select *ngIf="!isNew && !isUpdate &&(triggerParam.key =='eventSelect' || triggerParam.key =='shipmenttype' || triggerParam.key =='distanceMeasure')" [(value)]="rule.triggerParams[triggerParam.key].value" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnTriggerParamsSelectChange(triggerParam.key, true)" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option.value">{{option.name}}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="!isNew && !isUpdate && (triggerParam.key !=='eventSelect' && triggerParam.key !=='shipmenttype' && triggerParam.key !=='area' && triggerParam.key !=='distanceMeasure')" [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnTriggerParamsSelectChange(triggerParam.key, true)" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option">{{option.name}}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="!isNew && isUpdate &&(triggerParam.key =='eventSelect' || triggerParam.key =='shipmenttype')" [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnTriggerParamsSelectChange(triggerParam.key, true)" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option">{{option.name}}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="!isNew && isUpdate && (triggerParam.key !=='eventSelect' && triggerParam.key !=='shipmenttype')" [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnTriggerParamsSelectChange(triggerParam.key, true)" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option" >{{option.name}}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="isNew && !isUpdate && (triggerParam.key =='eventSelect' || triggerParam.key =='shipmenttype' || triggerParam.key =='area' || triggerParam.key =='route')" [(value)]="rule.triggerParams[triggerParam.key].value" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnTriggerParamsSelectChange(triggerParam.key, true)" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option.value" >{{option.name}}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="isNew && isUpdate" [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnTriggerParamsSelectChange(triggerParam.key, true)" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option">{{option.name}}
                  </mat-option>
                </mat-select>
                <input type="hidden" [(ngModel)]="rule.triggerParams[triggerParam.key]" [name]="triggerParam.key" [required]="triggerParam.templateOptions.required" />
              </div>
              <div *ngSwitchCase="'multi-select'">
                <div>
                  <span [textContent]="triggerParam.templateOptions.tlabel | translate"></span>
                  <span class="color21" *ngIf="triggerParam.templateOptions.required">*</span>
                </div>
                <mat-select *ngIf="triggerParam.key=='shipmentState'" multiple [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                [required]="triggerParam.templateOptions.required">
                <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option.value">
                  {{option.name}}
                </mat-option>
              </mat-select>
                <mat-select *ngIf="triggerParam.key!='shipmentState'" multiple [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                  [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-select>
                <input type="hidden" [(ngModel)]="rule.triggerParams[triggerParam.key]" [name]="triggerParam.key" [required]="triggerParam.templateOptions.required" />
              </div>
              <div *ngSwitchCase="'input'">
                <div>
                  <span [textContent]="triggerParam.templateOptions.tlabel | translate"></span>
                  <span class="color21" *ngIf="triggerParam.templateOptions.required">*</span>
                </div>
                <input type="text" [name]="triggerParam.key" [(ngModel)]="rule.triggerParams[triggerParam.key]" placeholder="{{triggerParam.templateOptions.placeholder}}"
                  [required]="triggerParam.templateOptions.required" class="form-control" [pattern]="triggerParam.templateOptions.pattern"/>
              </div>
              <div *ngSwitchCase="'Duration'">
                <div>
                  <span>
                    <span [textContent]="triggerParam.templateOptions.tlabel | translate"></span>
                    <span class="color21" *ngIf="triggerParam.templateOptions.required">*</span>
                  </span>
                  <cl-duration [show]="durationMeasuresToShow" [is]="rule.triggerParams[triggerParam.key]" (selection-change)="fnUpdateDelayValue(rule.triggerParams, triggerParam.key, $event)"
                    [placeholder]="triggerParam.templateOptions.placeholder" [required]="triggerParam.templateOptions.required">
                  </cl-duration>
                  <input type="hidden" [(ngModel)]="rule.triggerParams[triggerParam.key]" [name]="triggerParam.key" [required]="triggerParam.templateOptions.required" />
                </div>
              </div>
              <div *ngSwitchCase="'select-and-customize'"><!--TODO select and customize-->
                <div>
                  <span [textContent]="triggerParam.templateOptions.tlabel | translate"></span>
                  <span class="color21" *ngIf="triggerParam.templateOptions.required">*</span>
                </div>
                <mat-select [(value)]="rule.triggerParams[triggerParam.key]" [placeholder]="triggerParam.templateOptions.placeholder"
                  (selectionChange)="fnGetThresholdUI()" [required]="triggerParam.templateOptions.required">
                  <mat-option *ngFor="let option of triggerParam.templateOptions.options" [value]="option" [textContent]="option.name">
                  </mat-option>
                </mat-select>
                <div *ngIf="thresholdMeasure">
                  <cl-threshold *ngIf="thresholdMeasure[0].Levels"
                    [input-threshold]="thresholdMeasure" [selected-threshold]="selectedThresholdMeasure"
                    (on-update-threshold)="fnUpdateThreshold"></cl-threshold>
                  <div *ngIf="thresholdMeasure[0].status">
                    <div class="p-4 mt-2 mb-2 border-general bcolor36 rounded-sm" *ngIf="thresholdMeasure[0].label !== '' && thresholdMeasure[0].label !== null">
                      <pre [textContent]="thresholdMeasure[0].label"></pre>
                    </div>
                    <div *ngFor="let durationConfigObject of thresholdMeasure[0].status">
                      <div *ngFor="let item of $any(durationConfigObject|keyvalue)">
                        <div *ngIf="item?.value?.type === 'Duration'">
                          <div class="wt_flex_box wt_flex-space">
                               <span>
                                <span [textContent]="item?.value?.templateOptions?.tlabel | translate"></span>
                                <span class="color21" *ngIf="item?.value?.templateOptions?.required">*</span>
                              </span>
                              <span class="pl10">
                                <cl-duration [show]="durationMeasuresToShow" [placeholder]="item?.value?.templateOptions?.placeholder"
                                (selection-change)="fnUpdateDelayValue(rule.triggerParams, item?.value?.key, $event)"
                                [is]="rule.triggerParams[item?.value?.key]" [required]="item.value.templateOptions.required"></cl-duration>
                              </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="'threshold'">
                <cl-threshold *ngIf="triggerParam.threshold[0].Levels"
                  [input-threshold]="triggerParam.threshold" [selected-threshold]="selectedThresholdMeasure"
                  (on-update-threshold)="fnUpdateThreshold($event)"></cl-threshold>
                <input type="hidden" [(ngModel)]="rule.triggerParams.criteria" name="criteria" required="true">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6">
        <div>
          <span [textContent]="'rules.ACTIONS'|translate"></span>
          &nbsp; <span *ngIf="!rule.trigger" class="color10 pl-1 h5" [textContent]="'rules.SELECT_EVENT'|translate"></span>
        </div>
        <div class="p-4 rounded bgcolor32">
          <div *ngIf="rule.trigger" class="mb-2">
            <div>
              <span [textContent]="'rules.SELECT_ACTION'|translate"></span>
              <span class="color21">*</span>
            </div>
            <mat-select multiple [(ngModel)]="rule.action" #action="ngModel" [placeholder]="'rules.SELECT_ACTION'|translate"
              [disabled]="!rule.trigger" (selectionChange)="fnActionTypeChanged()" [required]="true" name="action">
              <mat-option *ngFor="let actionTemplate of actionTemplates" [value]="actionTemplate">
                <span [textContent]="actionTemplate.name"></span>
              </mat-option>
            </mat-select>
            <div class="color21 font-11-normal" *ngIf="action.invalid && isSaveTriggered">Action is mandatory</div>
          </div>
          <div *ngFor="let actionParam of rule.actionFormList" class="mb-2">
            <div *ngIf="actionParam.type==='select'">
              <div>
                <span [textContent]="actionParam.templateOptions.tlabel | translate"></span>
                <span class="color21" *ngIf="actionParam.templateOptions.required">*</span>
              </div>
              <!--group-by="'group'"-->
              <mat-select [(value)]="rule.actionParams[actionParam.key]" [placeholder]="actionParam.templateOptions.placeholder"
                [required]="actionParam.templateOptions.required">
                <mat-option *ngFor="let actionData of actionParam.templateOptions.options" [value]="actionData" [textContent]="actionData.name">
                </mat-option>
              </mat-select>
              <input type="hidden" *ngIf="actionParam.templateOptions.required" [(ngModel)]="rule.actionParams[actionParam.key]" [name]="actionParam.key" [required]="actionParam.templateOptions.required" />
            </div>
            <div *ngIf="actionParam.type==='multi-select'">
              <div>
                <span [textContent]="actionParam.templateOptions.tlabel | translate"></span>
                <span class="color21" *ngIf="actionParam.templateOptions.required">*</span>
              </div>
              <mat-select multiple [(value)]="rule.actionParams[actionParam.key]" [compareWith]="compareActionParams" [placeholder]="actionParam.templateOptions.placeholder"
                [disabled]="!rule.trigger" [required]="actionParam.templateOptions.required">
                <mat-option *ngFor="let actionData of actionParam.templateOptions.options" [value]="actionData">
                  <span [textContent]="actionData.name"></span>
                </mat-option>
              </mat-select>
              <input type="hidden" *ngIf="actionParam.templateOptions.required" [(ngModel)]="rule.actionParams[actionParam.key]" [name]="actionParam.key" [required]="actionParam.templateOptions.required" />
            </div>
            <div *ngIf="actionParam.type==='input'">
              <div>
                <span [textContent]="actionParam.templateOptions.tlabel | translate"></span>
                <span class="color21" *ngIf="actionParam.templateOptions.required">*</span>
              </div>
              <input type="text" [name]="actionParam.key" [(ngModel)]="rule.actionParams[actionParam.key]" placeholder="{{actionParam.templateOptions.placeholder}}"
                [required]="actionParam.templateOptions.required"
                [pattern]="actionParam.templateOptions.pattern"/>
            </div>
            <div *ngIf="actionParam.type === 'checkbox'">
              <div>
                <mat-checkbox [(ngModel)]="rule.actionParams[actionParam.key]" [name]="actionParam.key" [required]="actionParam.templateOptions.required">
                    <span>
                      <span [textContent]="actionParam.templateOptions.tlabel | translate"></span>
                      <span class="color21" *ngIf="actionParam.templateOptions.required">*</span>
                    </span>
                </mat-checkbox>
              </div>
            </div>
            <div *ngIf="actionParam.type==='textarea'">
              <div>
                <span [textContent]="actionParam.templateOptions.tlabel | translate"></span>
                <span class="color21" *ngIf="actionParam.templateOptions.required">*</span>
              </div>
              <textarea [(ngModel)]="rule.actionParams[actionParam.key]"  placeholder="{{actionParam.templateOptions.placeholder}}"
                [required]="actionParam.templateOptions.required" [name]="actionParam.key"
                rows="{{actionParam.templateOptions.rows}}"></textarea>
            </div>
          </div>
        </div>
      </div>

    </section>
    <section class="cl-side-panel-footer bgcolor33 pr-3 pl-3">
        <div class='flex justify-end items-center'>
            <a formnovalidate class=" m-2" (click)="closeEditor()"  [ngClass]="{'no-pointer': loading}">Cancel</a>
            <button class="panel-button white bgcolor4" type="submit" [disabled]="loading">
                Save
                <span *ngIf="isSaveTriggered" class="fa fa-circle-o-notch fa-pulse white" [ngClass]="{'visible': loading, 'hidden': !loading}"></span>
            </button>
        </div>
    </section>
  </div>
</form>