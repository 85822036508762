import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ShipmentTrackPoint } from '@cl/@types/shipment.types';
import { PropertyService } from '@cl/property/property.service';
import * as moment from 'moment';
@Component({
  selector: 'cl-shipment-tracker',
  templateUrl: './shipment-tracker.component.html',
  styleUrls: ['./shipment-tracker.component.scss'],
})
export class ShipmentTrackerComponent implements OnInit, OnChanges {
  @Input('data') trackingData: ShipmentTrackPoint[];

  trackingInfo: ShipmentTrackPoint[] = [];
  dateDisplayFormat:any
  @Input('isReverse') isReverse: boolean = false;
  @Input() currentMileStone:any;

  constructor(private pService: PropertyService) {}

  ngOnInit(): void {
    this.trackingInfo = [...this.trackingData];
    this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
    this.dateDisplayFormat = this.dateDisplayFormat.replace('dd', 'DD'); // replacing dd with DD because only this format will work in date picker
    this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');  // replacing a(am ,pm) with A(AM ,PM) because only this format will work in moment()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.trackingInfo = this.isReverse
      ? [...this.trackingData].reverse()
      : [...this.trackingData];
  }
  convertDateFormat(planned: any) {
    if(this.currentMileStone?.name != "User Setting") return planned
    else if(moment(planned).format(this.dateDisplayFormat) == "Invalid date") return planned;
    else return moment(planned).format(this.dateDisplayFormat);
  }
}
