import { Component, Inject, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomRoute } from '../../@types/custom-route.type';
import { PropertyService } from '../../property/property.service';
import { UserService } from '../../user/user.service';
import { NavService } from './nav.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AppGetNotifications, AppLoadConfigurations } from '@cl/ngxs/actions';
import { AppAuthService } from "../../authenticate/app-auth.service";
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { AuthorizeService } from '@cl/@authorize/authorize.service';
import { AppState } from "@cl/ngxs/state";
import { Observable, Subject } from 'rxjs';
import { NavigatorConfiguration } from '@cl/common/models/navigator';
import { NavigatorApiService } from '@cl/common/services/navigator-api.service';
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";
import _ from 'lodash';
import { TenantUtilService } from '@cl/tenant/tenant-util.service';
import { UrlUtilsService } from "@cl/common/services/url-utils.service";
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { environment } from '@cl/env';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { OrganizationService } from '@cl/common/services/organization.service';
declare var newrelic: any;

@Component({
  selector: 'cl-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent {
  loaded: boolean = false;
  loadLogin: boolean = false;
  noMargin: boolean = false;
  menuList: CustomRoute[];
  hash: string;
  buildInfo: any;

  defaultTitleConfig = {
    title: 'ParkourSC',
    favicon: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/favicon.ico'
  }

  titleConfig: typeof this.defaultTitleConfig = {
    title: '',
    favicon: ''
  }
  options:any;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router : Router,
    private store: Store,
    private userService: UserService,
    private navService: NavService,
    private authorizeService: AuthorizeService,
    private propertyService: PropertyService,
    private translate: TranslateService,
    private appAuth: AppAuthService,
    private apiProvider: ApiProviderService,
    private navigatorApiService: NavigatorApiService,
    private webTracker: WebtrackerService,
    private tenantUtilService: TenantUtilService,
    private urlUtils: UrlUtilsService,
    private renderer: Renderer2,
    private titleService: Title,    
    private organizationService:OrganizationService,
  ) {
    this.menuList = [];
    let self = this;
    let preHash = window.location.hash;
    if(
      preHash !== '#/'
      && preHash !== '#/login'
      && (preHash.indexOf('resetpassword') === -1)
      && _.isNull(localStorage.getItem('preHash'))
      && preHash !=''
      && preHash.indexOf('#access_token') !== 0
    ) {
      localStorage.setItem('preHash', preHash);
    }
    this.urlUtils.getBaseURL();
    this.appAuth.getAuth0Config().subscribe(() => {
      this.hash = window.location.hash;
      if(this.hash && this.hash.indexOf('#access_token') > -1){
        this.appAuth.preserveAuth();
        this.hash = '';
      }
      this.appAuth.isAuthenticated().subscribe((authenticated:any)=>{
        if(authenticated) {
          this.loadLogin = false;
          this.checkHavingTenentId(window.location.hash)
          this.processInitialFlow();
          this.appAuth.setupPeriodicAuthCheck();
        } else {
          this.loadLogin = true;
          if(this.router.url.indexOf('/resetpassword') === -1 ) {
            this.router.navigateByUrl('/login');
          }
        }
      });
    })
    this.loadScripts();
  }

  loadScripts() {
    this.urlUtils.getAuth0Domain().subscribe((data:any)=>{
      if(data.auth0Domain.indexOf('qaproddvp2-login')>-1 || data.auth0Domain.indexOf('qaprod-dt-login')>-1 ||data.auth0Domain.indexOf('test2-login')>-1){      
        this.options = {
          init: {distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}, // NREUM.init
          info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-67f45d78fd81f265dff",applicationID:"1120264013",sa:1}, // NREUM.info
          loader_config: {accountID:"3219358",trustKey:"2574099",agentID:"1120264013",licenseKey:"NRJS-67f45d78fd81f265dff",applicationID:"1120264013"} // NREUM.loader_config
        }
          new BrowserAgent(this.options);        
        }
      else if(data.auth0Domain.indexOf('prod-login')>-1){
        this.options = {
          init: {distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}, // NREUM.init
          info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-720838b91d1f0b20fe4",applicationID:"1120264012",sa:1}, // NREUM.info
          loader_config: {accountID:"3465810",trustKey:"2574099",agentID:"1120264012",licenseKey:"NRJS-720838b91d1f0b20fe4",applicationID:"1120264012"} // NREUM.loader_config
        }
        new BrowserAgent(this.options);
      }else if(data.auth0Domain.indexOf('prod-np-login')>-1){
        this.options = {
          init: {distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}}, // NREUM.init
          info: {beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-94286361491f615257d",applicationID:"1120264627",sa:1}, // NREUM.info
          loader_config: {accountID:"3053328",trustKey:"2574099",agentID:"1120264627",licenseKey:"NRJS-94286361491f615257d",applicationID:"1120264627"} // NREUM.loader_config
        }
        new BrowserAgent(this.options);
      } 

  })
  
  }


  async initLoginSettings(){
    try {
      const response = await this.propertyService.getLoginBulkProperties();
      if(response && response.length) {
        response.map((item)=>{
          if(item.key === 'domain.applicationName') {
            this.defaultTitleConfig.title = item.value;
          } else if(item.key === 'domain.applicationFavicon') {
            this.defaultTitleConfig.favicon = item.value;
          }
        })
      }
      this.applyTitleConfig()
    } catch (error) {
      this.applyTitleConfig()      
    }
  }

  applyTitleConfig(){
    this.titleConfig = this.defaultTitleConfig;
    this.titleService.setTitle(this.titleConfig.title);
    const faviconElem = document.getElementById('favicon');
    this.renderer.setAttribute(faviconElem, 'href', this.titleConfig.favicon);
  }
  checkHavingTenentId(hash:string){
    /*
    * This crude way has to be implemented as the search params were not being detected by the time the execution context is here at the first time.
    * */
    let qPrms = hash.split('?');
    if(qPrms[1]) {
      const params = JSON.parse('{"' + decodeURI(qPrms[1].replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
      if(params && params['tenentId']){
        let value = params['tenentId'];
        this.tenantUtilService.setPersistedTenant(value);
      }
    }
  }
  init(): void {
      this.initLoginSettings();
      this.menuList = this.navService.getMainMenuList();
      let preLoginHashes = ['/', '/login', '/resetpassword'];
      let preHash = localStorage.getItem('preHash');
      if(preHash){
        this.hash = preHash;
        localStorage.removeItem('preHash');
      }
      if(this.hash){
        this.hash = this.hash.split('#')[1];
        if(preLoginHashes.indexOf(this.hash) > -1) {
          this.navigateToDefaultRoute();
        } else {
          // Removing tenenId from prehash
          if(this.hash.includes('tenentId')){
            this.hash = this.hash.split('?')[0];
          }
          this.router.navigateByUrl(this.hash);
        }
      }else{
        if(this.router.url === '/'){
          this.navigateToDefaultRoute();
        }
      }

      if(this.router.url.indexOf('/navigator') > -1){
        this.noMargin = true;
      }

      this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
        if(e.url.indexOf('/navigator') > -1){
          this.noMargin = true;
        } else {
          this.noMargin = false;
        }
      })
      var buildInfo = this.propertyService.getSetting('app.version.number');
      this.buildInfo = (buildInfo !== 'unknown') ? buildInfo : '';
  }

  navigateToDefaultRoute() {
    let defaultRoute;
    if(this.userService.isFieldTechUser()) {
      defaultRoute = this.navService.getRouteByPath('assets')
    } else {
      defaultRoute = this.menuList[0];
    }
    this.router.navigateByUrl(this.navService.getDefaultRoute(defaultRoute));
  }

  processInitialFlow() {
    this.apiProvider.getAPIProviders().then(() => {
      this.userService
        .getLoggedInUserData()
        .then((user: any) => {
          try {
            newrelic.setCustomAttribute('loggedInUser',user.id, true)
            newrelic.setCustomAttribute('tenantId',user.tenantId, true)
            newrelic.setCustomAttribute('tenantName',user.tenantName, true)
            if(this.userService.getUser()?.orgAware){
              this.organizationService.getOrgList().subscribe((data:any)=>{
                if(data.length>0){
                  newrelic.setCustomAttribute('organization_id',data[0].id, true)
                  newrelic.setCustomAttribute('organization_name',data[0].name, true)
                }
              })
            }
          } catch (error) {
            
          }
          this.tenantUtilService
            .getTenants(this.userService.getUserId())
            .then((response) => {
              if (
                response &&
                response.length &&
                _.isEmpty(this.tenantUtilService.getPersistedTenant())
              ) {
                this.userService.getLoggedInUserData().then(() => {
                  this.processSubFlow();
                });
              } else {
                this.processSubFlow();
              }
            })
            .catch((error) => {
              console.error(error);
            });

          this.webTracker.addGAScript();
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }


  private processSubFlow() {
    this.propertyService.getTenantSettingsData().then(()=>{
      Promise.all([
        this.propertyService.getTranslations(),
        this.propertyService.getCustomizedTheme(),
        this.propertyService.getAppSettings(),
        this.navigatorApiService.loadNavigatorConfig()
      ])
      .then(() => {
        this.translate.use(this.userService.getUserLocale());
        this.authorizeService.setUserData();
        this.store.dispatch(new AppGetNotifications());
        this.loaded = true;
        this.init();
      })
      .catch( error => {
        console.error(error)
      });
    })
    .catch( error => {
      console.error(error)
    });
  }

}
