<div class="wt__loader wt__flex-row" [hidden]="!loadingStats">
  <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
</div>
<div *ngIf="data.emptyMessage" class="wt__no-data-message py-6">
  <div>{{ data.emptyMessage }}</div>
</div>
<div
  *ngIf="!data.emptyMessage"
  class="readouts-container px-1 mt-2 pt-2 gap-2 css-grid"
  [ngClass]="data.isHavingIcons ? 'four-col' : 'three-col'"
>
  <ng-container *ngFor="let item of data?.list">
    <span
      class="leading-5 justify-self-end truncate w-100 text-right"
      matTooltip="{{ item.name }}"
      matTooltipClass="tooltip-styles"
    >
      {{ item.name }}
    </span>
    <img *ngIf="item.icon" class="leading-5" [src]="item.icon" width="19" height="19" />
    <a class="justify-self-end leading-5" *ngIf="item.link" [href]="item.link">{{ item.value }}{{ item.unit }}</a>
    <span *ngIf="!item.link" class="leading-[21px]">{{ item.value }}{{ item.unit }}</span>
    
    <div
      class="w-full h-3 leading-5"
      [ngClass]="item.link ? 'cursor-pointer' : ''"
      [style]="{
        width: (+item.value / data.total) * 100 + '%',
        background: item.color
          ? item.color
          : data?.color
          ? data.color
          : '#2879DE'
      }"
    >
    <a *ngIf="item.link" [href]="item.link" class="block w-full max-h-full"></a>
      &nbsp;
    </div>
  </ng-container>
</div>
