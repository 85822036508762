import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';

import {
  DatatableComponent,
  SelectionType,
  SortType,
} from '@swimlane/ngx-datatable';
import { Subject, Subscription, debounceTime } from 'rxjs';
import {
  LIST_ACTION,
  ListSettingsInf,
  ShipmentListStateService,
} from '../shipment-list-state.service';
import { UserService } from '@cl/user/user.service';
import { ToastService } from "@cl/common/services/toast.service";

@Component({
  selector: 'cl-shipment-list-table',
  templateUrl: './shipment-list-table.component.html',
  styleUrls: ['./shipment-list-table.component.scss'],
})
export class ShipmentListTableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input('shipmentList') shipmentList = [];

  public headerHeight = 30;
  readonly rowHeight = 25;

  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  public sortType: SortType = SortType.single;
  public selection: SelectionType = SelectionType.checkbox;

  isFiltersOpen: boolean;

  private filterSubject: Subject<string> = new Subject();
  public columnFilters = {};
  public searchFieldFilter = [];

  @ViewChild('table') table: DatatableComponent;
  @Output() showShipmentSummary = new EventEmitter<any>();
  @Output() editShipment = new EventEmitter<{
    isDisabled: boolean;
    selectedRow: any;
  }>();
  @Output() enableTerminate = new EventEmitter<{
    isEnable: boolean;
    selectedRows: any;
  }>();
  @Output() enableComplete = new EventEmitter<{
    isEnable: boolean;
    selectedRows: any;
  }>();
  @Output() enableDelete = new EventEmitter<{
    isEnable: boolean;
    selectedRows: any;
  }>();

  @Output() enableManageTags = new EventEmitter<boolean>();

  @ViewChild('shipmentNameTemplate', { static: true })
  shipmentNameTemplate: TemplateRef<any>;

  @ViewChild('shipmentDateTemplate', { static: true })
  shipmentDateTemplate: TemplateRef<any>;

  @ViewChild('shipmentAssetCount', { static: true })
  shipmentAssetCount: TemplateRef<any>;

  @ViewChild('compositeShipmentTemplate', { static: true })
  compositeShipmentTemplate: TemplateRef<any>;

  @ViewChild('catalogTypeTemplate', { static: true })
  catalogTypeTemplate: TemplateRef<any>;

  @ViewChild('clearSearchTemplate', { static: true })
  clearSearchTemplate: TemplateRef<any>;

  @ViewChild('searchBoxTemplate', { static: true })
  searchBoxTemplate: TemplateRef<any>;

  @ViewChild('shipmentUserDefineDateTemplate', { static: true })
  shipmentUserDefineDateTemplate: TemplateRef<any>;

  showColumnSearch = false;

  listSubscription: Subscription;
  selectedRows = new Subject();

  offsetX = 0;
  settings: ListSettingsInf;
  constructor(
    public _shipmentListState: ShipmentListStateService,
    private _shipmentUtils: ShipmentUtilsService,
    private el: ElementRef,
    private persistenceStore: PersistenceService,
    private _userService: UserService,
    private toaster: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['shipmentList']?.currentValue){
      this.updateShipmentData();
      setTimeout(() => {
        this.scrollToOffsetX(this.offsetX)
      }, 10);
    }
  }

  ngAfterViewInit(): void {
    this.table._offsetX.subscribe(res => {
      if(res != undefined){
        this.offsetX = res
      }      
    })
  }

  scrollToOffsetX(offsetX: number) {
    const tableContainer = this.el.nativeElement.querySelector('.datatable-body');
    if (tableContainer) {
      tableContainer.scrollLeft = offsetX;
      this.table.recalculate();
    }
  }


  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.filter();
    });
    this.selectedRows.subscribe((res) => {
      this._shipmentListState.selectedRowsForCS.next(this.selected);
    });
    this.rawColumns = this._shipmentListState.settings.list.rawColumns;
    this.columns = this._shipmentListState.settings.list.selectedColumns;
    this.showColumnSearch =
      this._shipmentListState.settings.filters.showColumnSearch;
    this.headerHeight = this.showColumnSearch ? 60 : 30;
    this._shipmentListState.getExtendedColumnList();
    this.updateColumnsConfig();

    this.listSubscription =
      this._shipmentListState.listSettingsObservable.subscribe((settings) => {
        this.settings = settings;
        this.updateShipmentData();
        if (settings.action == LIST_ACTION.SEARCH_TOGGLED) {
          this.showColumnSearch = settings.filters.showColumnSearch;
          this.columnFilters = settings.filters.searchFieldFilter;
          this.headerHeight = this.showColumnSearch ? 60 : 30;
          setTimeout(() => {
            this.table.recalculate();
          }, 10);
        }
        if (settings.action === LIST_ACTION.COLUMNS_TOGGLED) {
          this.rawColumns = settings.list.rawColumns;
          this.columns = settings.list.selectedColumns;
          this.updateColumnsConfig();
        }
        if (settings.action === LIST_ACTION.FILTERS_TOGGLED) {
          setTimeout(() => {
            this.isFiltersOpen = settings.filters.isOpen;
            this.table.recalculate();
          }, 100);
        }
      });
  }

  private updateShipmentData() {
    // console.log(settings.extendedColumns);
    if (this.settings?.extendedColumns) {
      const findBoolleanObj = this.settings?.extendedColumns.filter((item) => item.type === "BOOLEAN");
  
      findBoolleanObj.forEach((item1) => {
  
        this.shipmentList.map((shipment: any) => {
  
          if(item1['prop'] && shipment[item1['prop']] && (shipment[item1['prop']]!=='Yes' && shipment[item1['prop']]!=='No')) {
          shipment[item1['prop']] = JSON.parse(shipment[item1['prop']]) ? 'Yes' : 'No';
          }
        })
      
      });
    }
  }

  getId(row) {
    return row.id;
  }
  columnReordered(e) {
    this.persistenceStore.setColumnOrder(
      e.column.prop,
      e.prevValue,
      e.newValue,
      'shipment',
      this.table._internalColumns
    );
  }

  onScroll(offsetY: number) {
    const viewHeight =
      this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;

    if (offsetY + viewHeight >= this.shipmentList.length * this.rowHeight) {
      this._shipmentListState.loadMoreRecords();
    }
  }

  updateColumnsConfig() {
    const dateTemplateProps = [
      'plannedDeparture',
      'plannedArrival',
      'eta',
      'actualArrivalTime',
      'actualDepTime',
      'createdAt',
      'modifiedAt',
      'completedTime',
      'lastCarrierUpdateAt'
    ];

    this.rawColumns.forEach((column) => {
      if (column.prop == 'selected') {
        column.headerTemplate = this.clearSearchTemplate;
      }
      if(column.prop === 'name'){
        column['cellTemplate'] = this.shipmentNameTemplate;
      }
      if(column.prop === 'compositeShipmentName' || column.prop === 'compositeShipmentExtId'){
        column['cellTemplate'] = this.compositeShipmentTemplate;
      }
      if (column.prop === 'assetCount') {
        column['cellTemplate'] = this.shipmentAssetCount;
      }
      if (column.searchable) {
        column['headerTemplate'] = this.searchBoxTemplate;
      }

      if (dateTemplateProps.includes(column.prop) || column?.type?.toLowerCase() == 'datetime') {
        column['cellTemplate'] = this.shipmentDateTemplate;
      }

      if((column?.type?.toLowerCase() == 'date') && !dateTemplateProps.includes(column.prop)){
        column['cellTemplate'] = this.shipmentUserDefineDateTemplate;
      }
    });
  }

  public deselectRows() {
    this.selected = [];
    this.onSelect({ selected: [] });
  }

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selected = rowData.selected;

    this.selectedRows.next('');
    // For EDIT SHIPMENT
    if (selectedRows.length === 1) {
      let selectedRow = selectedRows[0];
      this.editShipment.emit({ isDisabled: false, selectedRow });
    } else {
      this.editShipment.emit({ isDisabled: true, selectedRow: undefined });
    }

    // FOR MANAGE TAGS BUTTON
    if (selectedRows.length > 0) {
      this.enableManageTags.emit(true);
      let selectedRow = selectedRows;
      this._shipmentListState.settings.tags.selectedtags = selectedRow;
    } else {
      this.enableManageTags.emit(false);
    }

    // FOR TERMINATE
    /**
     * For terminate we can apply to multiple shipments but now the api supports single shipment
     */
    let isTerminateEnable = true;
    if (selectedRows.length === 1) {
      const disableConditions = ['Completed', 'At Destination'];
      selectedRows.forEach((row) => {
        if (disableConditions.includes(row.statusCode))
          isTerminateEnable = false;
      });
      if (isTerminateEnable)
        this.enableTerminate.emit({
          isEnable: isTerminateEnable,
          selectedRows,
        });
      else
        this.enableTerminate.emit({
          isEnable: isTerminateEnable,
          selectedRows: undefined,
        });
    } else {
      this.enableTerminate.emit({ isEnable: false, selectedRows: undefined });
    }

    // FOR COMPLETE
    /**
     * For complete we can apply to multiple shipments but now the api supports single shipment
     */
    let isCompleteEnable = true;
    if (selectedRows.length === 1) {
      const enableConditions = ['At Destination'];
      selectedRows.forEach((row) => {
        if (!enableConditions.includes(row.statusCode))
          isCompleteEnable = false;
      });
      if (isCompleteEnable)
        this.enableComplete.emit({
          isEnable: isCompleteEnable,
          selectedRows,
        });
      else
        this.enableComplete.emit({
          isEnable: isCompleteEnable,
          selectedRows: undefined,
        });
    } else {
      this.enableComplete.emit({ isEnable: false, selectedRows: undefined });
    }

    // FOR DELETE
    /**
     * For delete we can apply to multiple shipments but now the api supports single shipment
     */
    let isDeleteEnable = true;
    if (selectedRows.length === 1) {
      const enableConditions = ['Drafts', 'Planned', 'Pending', 'Ready to Ship'];
      selectedRows.forEach((row) => {
        if (!enableConditions.includes(row.statusCode)) isDeleteEnable = false;
      });
      if (isDeleteEnable)
        this.enableDelete.emit({
          isEnable: isDeleteEnable,
          selectedRows,
        });
      else
        this.enableDelete.emit({
          isEnable: isDeleteEnable,
          selectedRows: undefined,
        });
    } else {
      this.enableDelete.emit({ isEnable: false, selectedRows: undefined });
    }
  }

  showSummary(value: any, event:any) {
    if(this._userService.getPolicies().includes('SHIPMENT_DETAIL')){
      event.preventDefault();
      if (value.isDraft === 'true') return;
        this.showShipmentSummary.emit({ value: value, event: event });
    }else{
      this.toaster.error('Access not allowed')
    }
  }

  ngOnDestroy(): void {
    this.closeFilter()
    this.listSubscription.unsubscribe();
  }
  showIcon(value: any) {
    if (value.excursions.length > 1) {
      return this._shipmentUtils.getExcursionIcon('multi');
    } else {
      return this._shipmentUtils.getExcursionIcon(
        value.excursions[0].toLowerCase()
      );
    }
  }
  booleanFormate(value) {
    if (typeof value == 'boolean') {
      return value ? 'Yes' : 'no';
    } else {
      return value;
    }
  }

  closeFilter() {
    this._shipmentListState.toggleSearchColumns(false);
    if(this._shipmentListState.checkSearchColumnExist()){
      this.columnFilters ={};
      this.filter();
    }
    
  }

  filterInput($event) {
    this.filterSubject.next('');
  }

  filter() {
    this.searchFieldFilter = [];
    Object.keys(this.columnFilters).forEach((key) => {
      if(this.columnFilters[key]?.trim()){
        this.searchFieldFilter.push({
          name: key,
          value: this.columnFilters[key],
        });
      }
    });

    this._shipmentListState.updateSearchFilters(this.searchFieldFilter);
  }
}
