
<div class="mb-4 -mt-1"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>
<div class="simulate-main-container">
  <div class="details-page-wrapper location-details-wrapper" >
    <div class="details-header-wrapper">
      <div class="flex items-stretch flex-wrap">
        <div class="details-flex-card bgcolor2 ml0 mr10">
          <div class="cl-location-summary overflow-y-auto">
            <div class="btn-group simulate_btn_group attr_group" role="group">
              <button type="button" class="btn btn-primary p-1 cl-menu-item" [ngClass]="
                  currentAttrTab === 'attributes' ? 'bgcolor4 color2' : 'bgcolor9 color4'
                " (click)="currentAttrTab = 'attributes'">
                <span>Attributes</span>
              </button>
              <button type="button" class="btn btn-primary p-1 cl-menu-item" (click)="currentAttrTab = 'recipes'; getRecipe()"
                [ngClass]="
                currentAttrTab === 'recipes' ? 'bgcolor4 color2' : 'bgcolor9 color4'
                ">
                <span>Recipes</span>
              </button>
            </div>
            <div class="add-button" (click)="closeSidePanel()" *ngIf="showSidePanel===true">
              <span class="fa fa-close"></span>
            </div>
            <div *ngIf="currentAttrTab === 'attributes'">
              <div class="font-bold"></div>
              <div class="recipe_tab_head_parent">
                <div class="recipe_tab_head_stycky head_width_95">
                  <div  class="font-bold tab-header recipe_col_25">Name</div>
                  <div  class=" font-bold tab-header recipe_col_25">Key</div>
                  <div  class=" font-bold tab-header recipe_col_50">Value</div>
                </div>
                <div class="recipe_tab_head "  *ngFor="let Field of systemFields">
                  <div class="font-bold recipe_col_25 txt-ellipsis txt_capitalize" title="{{Field.displayLabel}}">{{Field.displayLabel}}</div>
                  <div class="font-bold recipe_col_25 txt-ellipsis" title="{{Field.name}}">{{Field.name}}</div>
                  <div  class="recipe_col_50 txt_word-wrap">{{Field.value}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="currentAttrTab === 'recipes'">
              <div class="font-bold"></div>
              <div class="recipe_tab_head_parent">
                <div class="recipe_tab_head_stycky" >
                  <div  class="font-bold tab-header recipe_col_30">Recipe Name</div>
                  <div  class="font-bold tab-header recipe_col_20">Trigger</div>
                  <div  class="font-bold tab-header recipe_col_20">Mode</div>
                  <div  class="font-bold tab-header recipe_col_20">Status</div>
                  <div  class="font-bold tab-header recipe_col_5">action</div>
                </div>
                <div class="recipe_tab_head" *ngFor="let Field of recipeList">
                  <div  class="font-bold recipe_col_30 recipe_row_padding txt-ellipsis" title="{{Field.name}}">{{Field.name}}</div>
                  <div  class="recipe_col_20 recipe_row_padding">{{Field.triggerName}}</div>
                  <div  class="recipe_col_20 recipe_row_padding">
                    <div class="form-check form-switch toggle" id="Field.active+Field.recipeId">
                          
                              <mat-slide-toggle (change)="updateRecipeDialog(Field,$event,'mode')" [checked]="Field.recipeMode=='prod'?true:false"></mat-slide-toggle>
                          <label class="form-check-label txt_capitalize"  id="Field.active+Field.recipeId" for="Field.recipeMode+Field.recipeId">{{Field.recipeMode==='prod'?'Production':'Test'}}</label>
                    </div>
                    
                     
                  </div>
                  <div  class="recipe_col_20 recipe_row_padding">
                    
                    <div class="toggle">
                      <mat-slide-toggle (change)="updateRecipeDialog(Field,$event,'active')" [checked]="Field.active"></mat-slide-toggle>
                      <label class="form-check-label txt_capitalize"  for="Field.active+Field.recipeId">{{Field.active ==true?'Enabled':'Disabled'}}</label>
                    </div>
                    
                      
                  </div>
                  <div  (click)="openRecipEdit(Field)" class="recipe_col_5 recipe_row_padding recipe_action"><i  class="fa fa-pencil"></i></div>
                </div>
                <div class="no-data" *ngIf="recipeList.length===0">
                  No data to display
                </div>
              </div>
            </div>
          </div>
          <div class="cl-location-summary overflow-y-auto">
          </div>
        </div>
        <div class="details-flex-card bgcolor2 mr0 ml10">
          <div class="cl-location-summary overflow-y-auto overflow-x-hidden max-h-[300px]" style="display: flex;">
            <div class="recipe_col_20">
              <mat-select
                class="basic-input w-full"
                placeholder="Select Action"
                [(ngModel)]="action"
                (selectionChange)="setActionUrl($event.value)"
                >
                <mat-option
                  [value]="item.value"
                  *ngFor="let item of simulateAction"
                  >{{ item.name }}</mat-option
                >
              </mat-select>
            </div>
            <div class="recipe_ui_dll" *ngIf="action==='ui'" >
              <mat-select
                class="basic-input w-full"
                placeholder="Select Recipe"
                [(ngModel)]="actionRecipe"
                (selectionChange)="selectRecipeForAction($event.value)"
                >
                <mat-option
                  [value]="item.recipeId"
                  *ngFor="let item of recipeList"
                  >{{ item.name }}</mat-option
                >
              </mat-select>

             
            </div>
            <div class="recipe_col_70">
              
              
            </div>
          </div>
          <div class="cl-location-summary overflow-y-auto overflow-x-hidden max-h-[300px] editor_parent" >
            <div *ngIf="isLoading" class="spin-pos pos_absolute"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
            <div class="recipe_col_100">
              <div class="app-ace-editor editor_main" #editor ></div>
            </div>
            
          </div>
          <div class="cl-location-summary overflow-y-auto overflow-x-hidden max-h-[300px] editor_parent" >
            <div class="recipe_col_15">
              
            </div>
            <div class="recipe_col_70">
              <input type="text" [value]="postUrl"
                class="basic-input w-full text-xs pl-1.5 pr-5 h-[30px] postAction-input hidden_input" [placeholder]="'Enter action url'"
                id="actionUrl" />
            </div>
            <div class="recipe_col_15">
              <button class="panel-button white bgcolor4 ml-2 recipe_col_80"  (click)="PostAction()">Execute</button>
            </div>
          </div>
          <div class="summery-panel" *ngIf="showSidePanel===true">
            <cl-recipe-add-panel [showLeftPanel]="false" [recipeObject]="recipeObject" [showPanel]="showPanel" [selectedEntityType]="selectedEntityType" [isAdd]="isAdd" [assetId]="" (closeSidePanel)="closeSidePanel();"></cl-recipe-add-panel> 
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="details-page-wrapper location-details-wrapper output_main" >
    <div class="details-header-wrapper height-88" >
      <div class="flex items-stretch flex-wrap height-100" >
        <div class="details-flex-card bgcolor2 ml0 ">
          <div class=" flex flex-col  wt-ngx-grid-wrapper h-full rounded-md recipe-flex-direction"
            >
            <div class="cl-secondary-header text-center pt5 flex p-2 gap-4 bg_trans"
              >
              <div class="btn-group simulate_btn_group" role="group">
                <button type="button" class="btn btn-primary p-1 cl-menu-item" [ngClass]="
                    currentTab === 'stream' ? 'bgcolor4 color2' : 'bgcolor9 color4'
                  " (click)="setTab('stream')">
                  <span>Data stream</span>
                </button>
                <button type="button" class="btn btn-primary p-1 cl-menu-item" (click)="setTab('logs')" [ngClass]="
                    currentTab === 'logs' ? 'bgcolor4 color2' : 'bgcolor9 color4'
                  ">
                  <span>Console</span>
                </button>
  
              </div>
            </div>
            <div class="h-48 pb-2 grow output_group flex-1"  *ngIf="currentTab === 'stream'" >
              <div class="empty_section" [ngClass]="streamSeesiondata.length>0?'hide_section':''">
                <span>No data to display</span>
              </div>
              <div class="stream_left" [ngClass]="streamSeesiondata.length<=0?'hide_section':''">
                <ul *ngFor="let streamData of streamSeesiondata">
                  <li [ngClass]="
                    streamData.isActive===true ? 'active' : ''" (click)="showStream(streamData)">{{streamData.date |
                    date: 'dd/MM/yyyy HH:mm a'}}</li>
                </ul>
              </div>
              <div class="stream_rigth" [ngClass]="streamSeesiondata.length<=0?'hide_section':''" >
                <div class="app-ace-editor height-100" #editor2 >
                </div>
              </div>
            </div>
            <div class="p-2 log-container"  *ngIf="currentTab === 'logs'">
              <div class="empty_section" [ngClass]="logsList.length>0?'hide_section':''">
                <span>No data to display</span>
              </div>
              <div *ngFor="let rep of logsList" [ngClass]="logsList.length<=0?'hide_section':''">
                <div class="respie_log_head">
                 {{rep.recipe}}
                </div>
                <div class="respie_logs_list">
                  <div *ngFor="let log of rep.logs" >{{log}}</div>
                  
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  
    
    
  </div>
</div>

