import { BoundStatus } from '@cl/@enums/bound-status.enum';
import { AssetStatus } from '@cl/constants';
import { Asset, CdmField } from './asset.type';
export interface SensorDeviceEvent {
  level: string;
  module: string;
  time: number;
  name: string;
  type: string;
  value: string;
}

export interface SensorDeviceEvents {
  deviceId: string;
  deviceType: string;
  events: SensorDeviceEvent[];
}
export interface SensorProperties{
  rssi: any;
  rssiCapturedAt : Date;
  rssiUom : string;
  battery: number;
  batteryCapturedAt: Date;
  batteryUom : string;
  overallStatus: string;
  overallStatusCapturedAt: Date;
}
export interface UnparsedSensorTaggedAssetAssignment{
  assetExternalId?: string,
  assetId?: string,
  assetName?: string,
  createdBy?: string,
  id?: string,
  status?: string,
  tag_id?: string,
  updatedDt?: number
}
export interface SensorTaggedAssetAssignment{
  assetExternalId?: string,
  assetId?: string,
  assetName?: string,
  createdBy?: string,
  taggedAssetId?: string,
  status?: BoundStatus,
  sensorId?: string,
  updatedDate?: Date
}
export interface Sensor{
  id: string,
  name?: string,
  imageUrl?: string,
  accountId?: string,
  accountName?: string,
  owner?: string,
  type?: string,
  boundDate?: Date,
  status?: AssetStatus,
  eventProfileName?: string,
  asset?: Asset,
  model?: string,
  gatewayId?: string,
  gatewayName?: string,
  assetId?: string,
  attributes?: SensorProperties,
  assignments: SensorTaggedAssetAssignment[];
  fwVersion?: string;
  watchListStatus?: string;
  vendor?: string;
  account?: string;
  isTracker?: boolean;
  sku?: string;
  cdmFields?: CdmField [];
  properties?: SensorBasicInformationProperties;
  deviceType?: string;
  organization?:string;
  organizationName?:string;
}

export interface SensorBasicInformationProperties {
  eventProfileName: string;
  "tag.batterylevel": string;
  modifiedAt: string;
  baseClass: string;
  type: string;
  version: string;
  assetExternalId: string;
  isPublised: string;
  createdAt: string;
  accountId: string;
  accountName: string;
  deleted: boolean;
  createdBy: string;
  taggedAssetId: string;
  vendor: string;
  assetId: string;
  tenantId: string;
  name: string;
  model: string;
  fwrev: string;
  id: string;
  sku: string;
  classType: string;
  overallStatus: string;
  status: string;
  topLevelNamespace: string;
  zoneNamespace: string;
  assignedTo: string;
  sensorId: string;
  overallStatusUpdatedTime: string;
  sensorType: string;
  assetSerialNo: string;
  modifiedBy: string;
  owner: string;
  batteryUpdatedTime: string;
  assetType: string;
  productNamespace: string;
  versionNamespace: string;
  gatewayName?: string;
  qopperId: string;
  namespace: string;
  assetName: string;
  locationNs: string;
  properties:{}
  organization:string;
  organizationName?:string;
  entityTypeId?:string;
  parentEntityTypeKey?:string;
}

export interface SensorBasicInformation {
  type: string;
  graphType: string;
  id: string;
  baseType: string;
  properties: SensorBasicInformationProperties;
  locationId: string;
}

export enum SensorStatusLabels {
	all ="All",
	r = "Offline",
	g = "Online",
	na ="N/A",
	y = "Attention",
  G = 'Online',
  Y = 'Attention',
  R = 'Offline',
  NA = 'N/A'
}

export enum SensorBatteryRanges {
  below5="0:5",
  between515="5:15",
  above15="15:101"
}
