<div class="container css-grid">
    <div class="range-picker-wrapper">
      <i class="fa fa-usd usd-icon"></i>
      <input
        class="basic-input m-0"
        [class.border-red]="(!from?.value && from?.value !== 0 && to?.value) || from?.errors"
        type="number"
        autocomplete="off"
        placeholder="From"
        [formControl]="from"
        [min]="intMin"
        [max]="to?.value ? to.value : intMax - 1"
      />
    </div>
    <p class="font-bold m-0 w-100 align-center">to</p>
    <div class="range-picker-wrapper">
      <i class="fa fa-usd usd-icon"></i>
      <input
        class="basic-input m-0"
        [class.border-red]="(from?.value && !to?.value) || to?.errors"
        type="number"
        autocomplete="off"
        placeholder="To"
        [formControl]="to"
        [min]="from?.value ? from.value : 0"
        [max]="intMax"
      />
    </div>
  </div>
  <mat-error class="foz13 mx-2" *ngIf="from?.errors || to?.errors">
    <ng-container *ngIf="from?.errors?.['min']; else otherError">
      <strong>From</strong> must be greater than or equal to <strong>0</strong>.
    </ng-container>
    <ng-template #otherError> <strong>From</strong> must be less than <strong>to cost</strong>. </ng-template>
  </mat-error>
