import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { UtilsService } from '@cl/common/utils/utils.service';
import { Observable, Subject,debounceTime, Subscription } from "rxjs";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { SearchApiService } from '@cl/common/services/search-api.service';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { FormControl, FormGroup } from '@angular/forms';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { EntityService } from '@cl/common/services/entity-list.service';
import { FilterGroup } from "../common/models/index";
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { BulkActionComponent } from '@cl/common/components/bulk-action/bulk-action.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { SkuService } from './sku.service';
import { ToastService } from '@cl/common/services/toast.service';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';

@Component({
  selector: 'cl-sku-list',
  templateUrl: './sku-list.component.html',
  styleUrls: ['./sku-list.component.scss']
})
export class SkuListComponent implements OnInit, AfterViewInit {
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  @ViewChild('skuList') skuList: any;
  @ViewChild(BulkActionComponent, { static: false }) bulk:BulkActionComponent;
  @ViewChild('skuNameTemplate') skuNameTemplate: TemplateRef<any>;
  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;
  @ViewChild('clearTemplate') clearTemplate: TemplateRef<any>;
  private filterSubject: Subject<string> = new Subject();
  public columnFilters = {};

  private event = new Subject<any>();
  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  initialFilters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public allLoaded: boolean = false;
  public headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  actionCount: number = 0;
  private selectedFilters: any = {};
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  searchFieldFilter:any=[];
  sidePanelsObj: any = {
    bind: false,
    bulk: false,
    replace: false
  };
  actionBtnPolicies = {
    create: ['SKU_CREATE'],
    edit: ['SKU_UPDATE'],
    delete: ['SKU_DELETE'],
  }

  scrollSize = 50;
  private payLoadForSkuList = {
    "scrollId": "",
    "scrollSize": this.scrollSize,
    "globalQueryText": "",
    "searchFieldFilter":[],
    "searchQueries": [
      {
        "fieldName": "parentEntityTypeKey",
        "queryText": "sku",
        "queryOperator": "should",
        "queryType": "match",
        "childFilters": []
      }
    ],
    "sortField": [

    ]
  };
  editDisabled: boolean = true;
  deleteDisabled: boolean = true;
  selectedSku: any;
  mainWidth: any;
  tableWidth: number=0;
  bulkPanelObj: BulkPanelModel = {fileType:'CSV', appName:'clfgraphapp', method:'POST', uploadUrl:'api/2/product/bulk', icon:this.bulkActionsService.icon['sku'], title:'Bulk Add',isDynamicFile:true};
  totalHits: number=0;
  showingHits: number=0;
  columnKeys: string[] = [];
  searchFormGroup: FormGroup;
  isTracker: boolean = false;
  pageName: string;
  extendedColumns: any;
  catalogType: string;
  showSidePanel: boolean = false;
  roleObject:any ={};
  showSPanel = false;
  mode: 'edit' | 'create' | 'summary' | '' = '';
  cdmFields;
  showBulkAdd = false;
  skuCreateColumns: any = [];
  skuCatalogCDMFields = [];
  showSummaryPanel=false;
  filterOption: any;
  skuId: any;
  downloadedCSVFields:string[] = [];
  showFilter:boolean=false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _searchApiService: SearchApiService,
    private el: ElementRef,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private formateDatePipe: FormateDatePipe,
    private entityService: EntityService,
    private bulkActionsService: BulkActionsService, 
    private csvDownload: CsvExportsService, 
    private extendedAttributeService: ExtendedCatalogAttributeService,
    private _dialog: MatDialog,
    private _skuService: SkuService,
    private _toastService: ToastService,
    private persistenceService: PersistenceService,
    private _dynamicFormService: DynamicFormService
  ) {
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
  }

  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get("searchTerm") as FormControl;
  }

  ngOnInit(): void {
    this.pageName = 'SKU';
    this.filterSubject.pipe( debounceTime( 1000) ).subscribe( () => {
      this.filter();
    });
    this.getSkuTotalCount();;
    this.getSkuList();
    this.getFilters();
    this.setContainerWidth();
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.showSummaryPanel = true;
        this.roleObject = evt.roleObject;        
      }
    });

    let filterObj = this.persistenceService.getFilterOption(this.pageName);
    if(!_.isEmpty(filterObj)){
      this.filterOption = JSON.parse(filterObj);
      if(this.filterOption.pageName == this.pageName){
        this.showFilterPanel = this.filterOption.filterPanel;
        this.setContainerWidth();
      }
    }
  }

  ngAfterViewInit(): void {
    this.entityService.getEntityCdmFields('sku').subscribe((data: any) => {
      this.skuCatalogCDMFields = data?.cdmFields;
      let fields = this._dynamicFormService.getRenderableFields(
        this.skuCatalogCDMFields
      );
  
      fields = this._dynamicFormService.orderFields([...fields], 'order');
      this.downloadedCSVFields = fields.map(field => {
        let label = field.displayLabel;
        if(field.required){
          label += '*'
        }else if(field.displayLabel?.endsWith('*')){
          label += '*'
        }
        return `"${label}"`
      })

      this.rawColumns = this.entityService.formColumnList(data?.cdmFields, this.dateTemplate);
      this.rawColumns.forEach((element,index) => {
        this.rawColumns[1].cellTemplate=this.skuNameTemplate;
      });
      const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('sku', [...this.rawColumns]);
      let self =this;
      this.rawColumns.forEach(function(item){
        let searchIndex = self.skuCatalogCDMFields.findIndex(
          (field) => field.name === item.prop &&  field.searchable == true
        );
        if(item.prop=="selected"){
          item.headerTemplate= self.clearTemplate;
        }
        if (searchIndex>-1) {
            item.headerTemplate= self.hdrTpl;
        }
      })
      
      this.rawColumns = [...rawColumns];
      this.columns = [...gridColumns];
      this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
      this._changeDetectorRef.detectChanges();
    })
  }


  private getSkuTotalCount() {
    const payload = {
      "scrollSize": 1,
      "searchQueries": [
        {
          "fieldName": "parentEntityTypeKey",
          "queryText": 'sku',
          "queryOperator": "should",
          "queryType": "match",
          "childFilters": []
        }
      ]
    }
    const totalCountSub: Subscription = this._searchApiService.globalSearch(payload, false).subscribe({
      next: res => {
        this.totalHits = res.totalHits;
      },
      error: err => {
        console.log(err);
      }
    });
    this.subscriptions.push(totalCountSub);
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 268;
    } else {
      this.tableWidth = this.mainWidth - 18;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
      this.getSkuList();
    }
  }

  getLoadMoreUpdate() {
    this.getSkuList();
  }

  getSkuList(resetData: boolean = false): void {
    if(this.delayedRefreshStart) {
      this.delayedRefreshStart = false;
      this.tableRowData = [];
      this.payLoadForSkuList.scrollId = null;
    }
    let data: any = this.payLoadForSkuList;
    this.isLoading = true;
    const skuListData: Subscription = this.entityService.globalEntitySearch('product',data, false).subscribe({
      next: (data: any) => {
        this.allLoaded = false;
        if (!this.payLoadForSkuList.scrollId) {
          this.rows = [...[]];
        }
        this.payLoadForSkuList.scrollId = data._scroll_id;
        // let rowData = data.hits;
        // rowData.forEach(d => {
        //   if (!d.name) {
        //     d.name = d.id;
        //   }
        // });
        if (resetData) {
          this.rows = [...data.hits];
        } else {
          this.rows = [...this.rows, ...data.hits];
        }
        this.showingHits = this.rows.length;
        if (this.showingHits === this.totalHits || data.totalHits < this.scrollSize) {
          this.allLoaded = true;
        }
        this.tableRowData = [...this.rows];
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      }
    });


    this.subscriptions.push(skuListData);
  }

  private formFilters(data) {
    this.filters = data.map((entry: any) => {
      let filter: FilterGroup = {
        list: [],
        filterType: entry.labelKey,
        label: entry.label,
        type: "multiselect",
      };
      filter.list = entry.options.map(list => ({
        name: list.id,
          checked: !!false,
          count: list.count,
          displayLabel: list.name,
          label: list.name
      }));
      filter.enableCollapsing = filter.list.length > 10;
      filter.collapsed = filter.list.length > 10;
      filter.expand = true;
      return filter;
    })
    this.initialFilters = _.cloneDeep(this.filters);
  }

  private getFilters(): void {
    this.entityService.getEntityFilters('sku').subscribe((data: any) => {
      this.formFilters(data);
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  deleteSkuDialog() {
    this._dialog
    .open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        message: `Are you sure want to delete SKU?`,
      },
    })
    .afterClosed()
    .subscribe((res) => {
      if (res && this.selectedSku?.id) {
       this.deleteSku();
      }
    });
  }

  public deselectRows() {
    this.selected = [];
    this.onSelect({ selected: [] });
  }

  async deleteSku(){
    const deletedSKUids = this.selected.map(item => item.id);
    try {
      await this._skuService.deleteSku({ids: deletedSKUids});
      this.delayedRefreshStart = true;
      this.reloadSkuList();
      this._toastService.success(`SKU deleted successfully`);
    } catch (error) {
      
    }
    this.deselectRows();
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000, 
    });
  }

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selectedRows);
    this.editDisabled = true;
    if(this.selected.length==1){
      this.editDisabled = false;
      let selectedRow = selectedRows[0];
      this.selectedSku = selectedRow;
      if(rowData.selected[0].status.toLowerCase() !== 'active'){
        this.deleteDisabled = false;
     }else{
       this.deleteDisabled = true;
     }
    }else if(this.selected.length > 1){
      this.deleteDisabled = false;
      rowData.selected.forEach(sel => {
          if(!['draft'].includes(sel.status.toLowerCase())){
            this.deleteDisabled = true;
          }
      })
    }
  }

  formatDownloadData(data:any[]){
    let tempAssetData = [...data];
    let dateFieldArray = ['modifiedAt', 'createdAt'];
    tempAssetData.forEach(sensor => {
      dateFieldArray.forEach(element => {
        if(sensor[element]){
          sensor[element] = this.formateDatePipe.transform(sensor[element],'default');
        }
      });
    });
    return tempAssetData;
  }

  downloadReport(reportType: string) {
    let downloadData = this.formatDownloadData(this.rows);
    if (reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(downloadData, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'SKU-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    this.payLoadForSkuList.scrollId = '';
    this.payLoadForSkuList.globalQueryText = val;
    this.getSkuList(true);
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.persistenceService.setFilterOption(this.pageName, this.showFilterPanel);
    this.setContainerWidth();
    setTimeout(() => {
      this.skuList?.recalculate();
    }, 10);
  }

  getPanelUpdate(event: any) {
    this.payLoadForSkuList.scrollId = '';
    let filterEvent = event.filter;
    if (event.action === 'clearFilters') {
      this.selectedFilters = {};
      this.filters = _.cloneDeep(this.initialFilters);
    } else {
      if (filterEvent.checked) {
        if (!this.selectedFilters[filterEvent.filterGroupName]) {
          this.selectedFilters[filterEvent.filterGroupName] = [];
        }
        this.selectedFilters[filterEvent.filterGroupName].push(filterEvent.name);
      } else {
        let index = this.selectedFilters[filterEvent.filterGroupName].indexOf(filterEvent.name);
        this.selectedFilters[filterEvent.filterGroupName].splice(index, 1);
      }
    }
    this.createPayloadAndReloadSkuList();
  }


  createPayloadAndReloadSkuList() {
    let childFilters = [];
    Object.keys(this.selectedFilters).forEach(key => {
      if (this.selectedFilters[key].length > 0) {
        childFilters.push(new Object({
          "fieldName": key,
          "queryText": this.selectedFilters[key].join(),
          "queryOperator": "must"
        }));
        }
    });
    this.payLoadForSkuList.searchQueries[0].childFilters = childFilters;
    this.getSkuList(true);
  }

  getId(row) {
    return row.id;
  }

  showBulk() {
    this.showBulkAdd = true;
  }
  closeBulkSidePanel() {
    this.showBulkAdd = false;
  }
  closeSidePanel(isReload:boolean = false) {
    this.showSidePanel = false;
    this.mode = '';
    if(isReload){
      this.delayedRefreshStart = true;
      this.reloadSkuList();
      this.deselectRows();
    }
  }
  reloadSkuList() {
    // this.showBulkAdd = false;
    this.delayedRefreshStart = true;
    // this.reloadSkuList();
  }

  createSKUColumnsArray() {
    let obj = {};
    this.skuCatalogCDMFields.forEach(inventoryAttribute => {
      if(inventoryAttribute.instanceUserCreatable) {
        obj[inventoryAttribute.id] = '';
      }
      
    });
    this.skuCreateColumns.push(obj);
  }
  downloadTemplate(event:any ) {
    event.preventDefault();
    this.createSKUColumnsArray();
    this.csvDownload.formatAndDownloadCSVForGrid(this.skuCreateColumns, 'SKU_Add_Template')
  }

  closeSummaryPanel(){
    this.showSummaryPanel = false;
    this.roleObject = {};
    this.showBulkAdd = false;
  }

  loadSummaryPanel(roleObject:any, event:any){    
    console.log(roleObject)
    roleObject.organization = JSON.parse(roleObject?.organization)?.label;
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', roleObject: roleObject });
  }
  setEvent(state: any) {
    this.event.next(state);    
  }
  getEvent(): Observable<any> {
    return this.event.asObservable();
  }
  openCreateSidePanel(isEdit:boolean){
    this.mode = isEdit ? 'edit' : 'create';
    this.showSidePanel = true;
    this.skuId = this.selectedSku?.id;
  }

  filterInput( $event ) {
    this.filterSubject.next('');
  }

  closeFilter(){
    this.showFilter = false;
    this.headerHeight = 30;
    this.columnFilters = {}
    Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
    this.filter()
  }

  showHideFilter(){
    this.showFilter = !this.showFilter;
    if(this.showFilter){
      this.headerHeight = 65;
    }else{
      this.headerHeight = 30;
      Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
      this.filter();
    }
    setTimeout(() => {
      this.table.recalculate();
    }, 10);
  }


  filter() {
    console.log(this.columnFilters);
    this.searchFieldFilter=[]
      Object.keys( this.columnFilters ).forEach(key => {
        this.searchFieldFilter.push({
          "name":key=="nameFormated"?"name":key,
          "value":this.columnFilters[key]
        })
        console.log(`${key}: ${this.columnFilters[key]}`)
      });
      console.log("final",this.searchFieldFilter);
      this.payLoadForSkuList.scrollId = '';
      this.payLoadForSkuList.searchFieldFilter = this.searchFieldFilter;
      this.getSkuList();
  }

}
