<div class="cl-side-panel bcolor9 border flex top-fix flex-col">
  <section class="cl-side-panel-title side-panel-header-bg">
    <div class="grow flex items-center">
      <img src="assets/svgs/circle-icons-white/location.svg" />
      <span class="black font-bold">Location Summary</span>
    </div>
    <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
  </section>
  <section class="cl-side-panel-content">
    <div *ngIf="activePane === 'summary'">
      <div class="p-4" *ngIf="locationType.toLowerCase() !== 'route'">
        <ng-container *ngFor="let cdmField of attributes">
          <div  *ngIf="cdmField?.isDisplayableOnSummary && getExtendedAttributeValue(cdmField, locationObject?.summary.properties)" >
          <div class="flex fs14 color16 mt-1" *ngIf="!customFieldsLocation.includes(cdmField?.name) && !modifiedFields.includes(cdmField?.name)">
            <div class="basis-2/4 font-bold" [textContent]="cdmField?.displayLabel"></div>
            <div class="basis-2/4" [textContent]="getExtendedAttributeValue(cdmField, locationObject?.summary.properties)"></div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="cdmField.name=='shipmentIB' && locationObject?.summary?.type != 'gatewayzone' && locationObject?.IBCount">
            <div class="basis-2/4 font-bold">Inbound Shipments</div>
            <div class="basis-2/4" ><span class="color4 cursor-pointer" (click)="navigateToShipments('inbound')"> {{locationObject?.IBCount}} </span></div>
          </div>
          <div class="flex fs14 color16 mt-1"  *ngIf="cdmField.name=='shipmentOB' && locationObject?.summary?.type != 'gatewayzone' && locationObject?.OBCount">
            <div class="basis-2/4 font-bold">Outbound Shipments</div>
            <div class="basis-2/4" ><span class="color4 cursor-pointer" (click)="navigateToShipments('outbound')"> {{locationObject?.OBCount}} </span></div>
          </div>
          <div class="flex fs14 color16 mt-1"  *ngIf="cdmField.name=='returnShipments' && locationObject?.summary?.type != 'gatewayzone' && locationObject?.returnCount">
            <div class="basis-2/4 font-bold">Return Shipments</div>
            <div class="basis-2/4" ><span class="color4 cursor-pointer" (click)="navigateToShipments('return')"> {{locationObject?.returnCount}} </span></div>
          </div>
          <div class="flex fs14 color16 mt-1"  *ngIf="cdmField.name=='assets'">
            <div class="basis-2/4 font-bold">Assets</div>
            <div class="basis-2/4">
              {{assetCount}}
            </div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="cdmField.name=='areaFenceType'">
            <div class="basis-2/4 font-bold">Area Fence Type</div>
            <div *ngIf="locationObject?.summary.properties?.areaFenceType.toLowerCase()=='circle'" class="basis-2/4"> Radius </div>
            <div *ngIf="locationObject?.summary.properties?.areaFenceType.toLowerCase()!='circle'" class="basis-2/4"> {{locationObject?.summary.properties?.areaFenceType}} </div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="cdmField.name=='geoFenceType'">
            <div class="basis-2/4 font-bold">Geo fence type</div>
            <div *ngIf="locationObject?.summary.properties?.areaFenceType.toLowerCase()=='circle'" class="basis-2/4"> Radius </div>
            <div *ngIf="locationObject?.summary.properties?.areaFenceType.toLowerCase()!='circle'" class="basis-2/4"> {{locationObject?.summary.properties?.areaFenceType}} </div>
          </div>
        </div>
        </ng-container>
        <div class="flex fs14 color16 mt-1" *ngIf="locationObject?.summary?.properties?.createdAt">
          <div class="basis-2/4 font-bold">Created At</div>
          <div class="basis-2/4" ><span class="cursor-pointer"> {{datePipe.transform(locationObject?.summary.properties?.createdAt)}} </span></div>
        </div>
        <div class="flex fs14 color16 mt-1" *ngIf="locationObject?.summary?.properties?.createdBy">
          <div class="basis-2/4 font-bold">Created By</div>
          <div class="basis-2/4" ><span class="cursor-pointer"> {{locationObject?.summary.properties?.createdBy}} </span></div>
        </div>
        <div class="flex fs14 color16 mt-1" *ngIf="locationObject?.summary?.properties?.modifiedAt">
          <div class="basis-2/4 font-bold">Modified At</div>
          <div class="basis-2/4" ><span class="cursor-pointer"> {{datePipe.transform(locationObject?.summary.properties?.modifiedAt)}} </span></div>
        </div>
        <div class="flex fs14 color16 mt-1" *ngIf="locationObject?.summary?.properties?.modifiedBy">
          <div class="basis-2/4 font-bold">Modified By</div>
          <div class="basis-2/4" ><span class="cursor-pointer"> {{locationObject?.summary.properties?.modifiedBy}} </span></div>
        </div>
      </div>

      <div class="route_blk route_dtl" *ngIf="locationType.toLowerCase() === 'route' && !loading ">
        <div class="p-4">
          <ng-container *ngFor="let cdmField of attributes">
            <div  *ngIf="cdmField?.isDisplayableOnSummary && getExtendedAttributeValue(cdmField, arrRouteSummary)">
              <div *ngIf="!customFields.includes(cdmField.name) &&  !modifiedFields.includes(cdmField?.name)" class="flex fs14 color16 mt-1">
                <div class="basis-2/4 font-bold" [textContent]="cdmField?.displayLabel"></div>
                <div class="basis-2/4" [textContent]="getExtendedAttributeValue(cdmField,arrRouteSummary)"></div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="cdmField.name=='name'">
                <div class="basis-2/4  font-bold">Route Name</div>
                <div class="basis-2/4">{{arrRouteSummary?.name}} ({{arrRouteSummary?.transportMode}})</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="cdmField.name=='source'">
                <div class="basis-2/4  font-bold">Origin</div>
                <div class="basis-2/4 ml-3 font-semibold"><a href="javascript:void(0)" (click)="getSummary(arrRouteSummary?.originId, originEntityTypeKey)">
                  {{arrRouteSummary?.source}} 
                </a>({{getFullAddress(arrRouteSummary?.locationDetails,arrRouteSummary?.source)}})</div><a><i  href="#" (click)="copyMessage(getFullAddress(arrRouteSummary?.locationDetails,arrRouteSummary?.source))" 
                  class="fa fa-copy color4 bcolor4 bgcolor32"></i></a> 
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="cdmField.name=='destination'">
                <div class="basis-2/4  font-bold">Destination</div>
                <div class="basis-2/4 ml-3 font-semibold"><a href="javascript:void(0)" (click)="getSummary(arrRouteSummary?.destinationId, destinationEntityKey)">
                  {{arrRouteSummary?.destination}} 
                </a>({{getFullAddress(arrRouteSummary?.locationDetails,arrRouteSummary?.destination)}})</div><a><i href="#" (click)="copyMessage(getFullAddress(arrRouteSummary?.locationDetails,arrRouteSummary?.destination))" class="fa fa-copy color4 bcolor4 bgcolor32"></i></a>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="cdmField.name=='shipments' && arrRouteSummary?.shipmentsLength">
                <div class="basis-2/4 font-bold">No.Shipments</div>
                <div class="basis-2/4"><a href="javascript:void(0)">{{arrRouteSummary?.shipmentsLength}}</a></div>
              </div>
            </div>

          </ng-container>
            <div class="flex fs14 color16 mt-1" *ngIf="arrRouteSummary?.createdAt">
              <div class="basis-2/4 font-bold">Created At</div>
              <div class="basis-2/4" ><span class="cursor-pointer"> {{datePipe.transform(arrRouteSummary?.createdAt)}} </span></div>
            </div>
            <div class="flex fs14 color16 mt-1" *ngIf="arrRouteSummary?.createdBy">
              <div class="basis-2/4 font-bold">Created By</div>
              <div class="basis-2/4" ><span class="cursor-pointer"> {{arrRouteSummary?.createdBy}} </span></div>
            </div>
            <div class="flex fs14 color16 mt-1" *ngIf="arrRouteSummary?.modifiedAt">
              <div class="basis-2/4 font-bold">Modified At</div>
              <div class="basis-2/4" ><span class="cursor-pointer"> {{datePipe.transform(arrRouteSummary?.modifiedAt)}} </span></div>
            </div>
            <div class="flex fs14 color16 mt-1" *ngIf="arrRouteSummary?.modifiedBy">
              <div class="basis-2/4 font-bold">Modified By</div>
              <div class="basis-2/4" ><span class="cursor-pointer"> {{arrRouteSummary?.modifiedBy}} </span></div>
            </div>
          <div class="flex fs14 color16 mt-1">
            <div class="basis-2/4  font-bold">Route Details</div>
            <div class="basis-2/4 "></div>
          </div>
         
        </div>
        <div class="timeline-centered">
          <article class="timeline-entry"
            *ngFor="let areaDetails of arrRouteSummary.locationDetails; let i = index; let last = last; let first = first">
            <div class="timeline-entry-inner">
              <div
                [ngClass]="i > 0 ? (last?'timeline-icon bg-completed':'timeline-icon bg-progress') : 'timeline-icon bg-start' ">
                <img src="assets/svgs/map-icons/site-pin-origin.svg" *ngIf="first">
                <img src="assets/svgs/map-icons/route-destination.svg" *ngIf="last">
                <img src="assets/svgs/organization.svg" *ngIf="!last && !first">
                <i class="entypo-feather"></i>
              </div>
              <div class="routeTransport" [hidden]="last">
                <i class="fa fa-train" *ngIf="areaDetails.travel_mode=='Train'"></i>
                <i class="fa fa-bus" *ngIf="areaDetails.travel_mode=='Road'"></i>
                <i class="fa fa-ship" *ngIf="areaDetails.travel_mode=='Ship'"></i>
                <i class="fa fa-fighter-jet fa-rotate-90" *ngIf="areaDetails.travel_mode=='Air'"></i>
              </div>
              <div class="timeline-label">
                <div class="rut_txt">
                  <p class="routehead">{{ areaDetails.areaItem.name }}</p>
                  <p class="font-semibold" [hidden]="last">
                    Travel Time: {{ areaDetails.travel_time }}
                  </p>
                  <p *ngIf="(last && first) && areaDetails.layover">
                    Layover: {{ areaDetails.layover }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p class="routetravelHead font-semibold" [hidden]="!last" *ngIf="formattedTotalTravelHrs">
                Total travel time: {{ formattedTotalTravelHrs }}
              </p>
            </div>
          </article>
        </div>       
      </div>
    </div>
    <div class="spin-pos mt-10" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
  </section>
  <div class="assign-details-btn" *ngIf="locationType.toLowerCase() !== 'route'">
    <button class="panel-button color4 bgcolor2 bcolor4 bw-1">
      <a class="color4" href="#/locationdetails/{{locationId}}?type={{entityTypeKey || ''}}">Location Details
        <i class="fa fa-arrow-right ml-1 color4"></i>
      </a></button>
  </div>
  <div class="assign-details-btn" *ngIf="locationType.toLowerCase() == 'route'">
    <button class="panel-button color4 bgcolor2 bcolor4 bw-1">
      <a class="color4" href="#/routedetails/{{locationId}}?type={{entityTypeKey || ''}}">Route Details
        <i class="fa fa-arrow-right ml-1 color4"></i>
      </a></button>
  </div>
  <!-- <section class="cl-side-panel-footer bgcolor33">
    <div class="flex">
      <div class="flex-col grow fs11 text-center" (click)="activePane = 'summary'; toggleActivePane('summary')">
        <img src="assets/svgs/circle-icons-blue/message.svg" class="m-auto"
          [ngClass]="{'hidden': activePane !== 'summary'}" />
        <img src="assets/svgs/circle-icons-white/message.svg" class="m-auto"
          [ngClass]="{'hidden': activePane == 'summary'}" />
        Summary
      </div>
      <div class="flex-col grow fs11 text-center" (click)="activePane = 'history';toggleActivePane('history')">
        <img src="assets/svgs/circle-icons-blue/dwelltime.svg" class="m-auto"
          [ngClass]="{'hidden': activePane !== 'history'}" />
        <img src="assets/svgs/cl-icons/CLF_UI_Icons__pin-route_blue.svg" class="m-auto"
          [ngClass]="{'hidden': activePane == 'history'}" />
        Shipments</div>
    </div>
  </section> -->
</div>
