<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title side-panel-header-bg">
      <div class="grow flex items-center">
        <img src="assets/svgs/circle-icons-white/inventory.svg" />
        <span class="black font-bold">Inventory - Summary</span>
      </div>
      <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content pl-2 pr-2 pb-1.5 pt-1.5">
      <ng-container *ngFor="let cdmField of inventoryCatalogCDMFields">
        <div class="flex fs14 color16 mt-1" *ngIf="cdmField?.displayable && getInventoryValue(cdmField, inventoryObject)">
          <div class="basis-2/5 font-bold" [textContent]="cdmField?.displayLabel"></div>
          <div class="basis-3/5" [textContent]="getInventoryValue(cdmField, inventoryObject)"></div>
        </div>
      </ng-container>
      <div class="flex fs14 color16 mt-1" *ngIf="data">
        <div class="basis-2/5 font-bold">Created By</div>
        <div class="basis-3/5">{{inventoryObject.createdBy}}</div>
      </div>
      <div class="flex fs14 color16 mt-1" *ngIf="data">
        <div class="basis-2/5 font-bold">Created At</div>
        <div class="basis-3/5">{{inventoryObject.createdAt | formateDate: "default" }}</div>
      </div>
      <div class="flex fs14 color16 mt-1" *ngIf="data">
        <div class="basis-2/5 font-bold">Modified By</div>
        <div class="basis-3/5">{{inventoryObject.modifiedBy}}</div>
      </div>
      <div class="flex fs14 color16 mt-1" *ngIf="data">
        <div class="basis-2/5 font-bold">Modified At</div>
        <div class="basis-3/5">{{inventoryObject.modifiedAt | formateDate: "default" }}</div>
      </div>
    </section>
    <div class="flex justify-end fixedbtn">
      <button class="panel-button color4 bgcolor2 bcolor4 bw-1 m-3 z-10">
        <a class="color4" href="#/inventorydetails/{{inventoryId}}">Inventory Details
          <i class="fa fa-arrow-right ml-1 color4"></i>
        </a></button>
    </div>
  </div>
