import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  throwError,
} from 'rxjs';
import _ from 'lodash';
import { CustodyActions } from '@cl/common/models/assets';
import { AssetListService } from './asset-list/asset-list.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from '@cl/common/utils/utils.service';
import {
  ASSET_COC_ACTIONS,
  AssetCOCState,
} from '@cl/@types/asset-coc-state.type';

@Injectable({
  providedIn: 'root',
})
export class AssetsCOCStateService {
  private _state: AssetCOCState = {
    currentAction: ASSET_COC_ACTIONS.INITIALIZED,
    custodyActionInProgress: null,
    showCocPanel: false,
  };

  assetCOCStateObservable = new BehaviorSubject<AssetCOCState>(this._state);

  constructor(
    private _assetListService: AssetListService,
    private _utils: UtilsService
  ) {}

  public get state() {
    return _.cloneDeep(this._state);
  }

  private handleCustodyActionError(
    err: any,
    errorMessage: string,
    assetId: string
  ): Observable<any> {
    if (err instanceof HttpErrorResponse && err.error['error-message']) {
      errorMessage = err.error['error-message'];
    }
    this._utils.showMessage(errorMessage, assetId);
    return throwError(err);
  }

  public hideCOCSidePanel() {
    this._state.showCocPanel = false;
    this._state.currentAction = ASSET_COC_ACTIONS.TOGGLE_COC_PANEL;
    this.assetCOCStateObservable.next({ ...this._state });
  }

  public showCOCSidePanel(custodyAction: CustodyActions) {
    this._state.showCocPanel = true;
    this._state.currentCocAction = custodyAction;
    this._state.currentAction = ASSET_COC_ACTIONS.TOGGLE_COC_PANEL;
    this.assetCOCStateObservable.next({ ...this._state });
  }

  public onCustodyActionEmit(payload: any) {
    this._state.dialogPayload = payload;
    this._state.currentAction = ASSET_COC_ACTIONS.CUSTODY_ACTION_EMIT;
    this.assetCOCStateObservable.next({ ...this._state });
  }

  public onReturnAsset(assetId: string, externalId: string) {
    const payload = {
      assetId,
    };

    this._state.custodyActionInProgress = CustodyActions.Return;
    this._state.currentAction = ASSET_COC_ACTIONS.COC_STARTED;
    this.assetCOCStateObservable.next({ ...this._state });

    return this._assetListService.returnAsset(payload).pipe(
      finalize(() => {
        this._state.custodyActionInProgress = null;
        this._state.currentAction = ASSET_COC_ACTIONS.COC_COMPLETED;
        this.assetCOCStateObservable.next({ ...this._state });
      }),
      catchError((err) =>
        this.handleCustodyActionError(err, 'Return failure', externalId)
      )
    );
  }

  public onCheckoutAsset(assetId: string, userId: string, externalId: string) {
    const payload = {
      assetId,
      userId,
    };

    this._state.custodyActionInProgress = CustodyActions.Checkout;
    this._state.currentAction = ASSET_COC_ACTIONS.COC_STARTED;
    this.assetCOCStateObservable.next({ ...this._state });

    return this._assetListService.checkoutAsset(payload).pipe(
      finalize(() => {
        this._state.custodyActionInProgress = null;
        this._state.currentAction = ASSET_COC_ACTIONS.COC_COMPLETED;
        this.assetCOCStateObservable.next({ ...this._state });
      }),
      catchError((err) =>
        this.handleCustodyActionError(err, 'Checkout failure', externalId)
      )
    );
  }

  public onTransferAsset(assetId: string, userId: string, externalId: string) {
    const payload = {
      assetId,
      userId,
    };

    this._state.custodyActionInProgress = CustodyActions.Transfer;
    this._state.currentAction = ASSET_COC_ACTIONS.COC_STARTED;
    this.assetCOCStateObservable.next({ ...this._state });

    return this._assetListService.transferAsset(payload).pipe(
      finalize(() => {
        this._state.custodyActionInProgress = null;
        this._state.currentAction = ASSET_COC_ACTIONS.COC_COMPLETED;
        this.assetCOCStateObservable.next({ ...this._state });
      }),
      catchError((err) =>
        this.handleCustodyActionError(err, 'Transfer failure', externalId)
      )
    );
  }

  public onTransferInServiceAsset(
    assetId: string,
    userId: string,
    externalId: string
  ) {
    const payload = {
      assetId,
      userId,
    };

    this._state.custodyActionInProgress = CustodyActions.InService;
    this._state.currentAction = ASSET_COC_ACTIONS.COC_STARTED;
    this.assetCOCStateObservable.next({ ...this._state });

    return this._assetListService.transferAssetInService(payload).pipe(
      finalize(() => {
        this._state.custodyActionInProgress = null;
        this._state.currentAction = ASSET_COC_ACTIONS.COC_COMPLETED;
        this.assetCOCStateObservable.next({ ...this._state });
      }),
      catchError((err) =>
        this.handleCustodyActionError(
          err,
          'Transfer in service failure',
          externalId
        )
      )
    );
  }

  onConfirmReceiptAsset(
    assetId: string,
    condition: boolean,
    externalId: string
  ) {
    const payload = {
      assetId,
      condition,
    };

    this._state.custodyActionInProgress = CustodyActions.Confirm;
    this._state.currentAction = ASSET_COC_ACTIONS.COC_STARTED;
    this.assetCOCStateObservable.next({ ...this._state });

    return this._assetListService.confirmReceiptAsset(payload).pipe(
      finalize(() => {
        this._state.custodyActionInProgress = null;
        this._state.currentAction = ASSET_COC_ACTIONS.COC_COMPLETED;
        this.assetCOCStateObservable.next({ ...this._state });
      }),
      catchError((err) =>
        this.handleCustodyActionError(
          err,
          'Confirm receipt failure',
          externalId
        )
      )
    );
  }

  onCheckInAsset(assetId: string, condition: boolean, externalId: string) {
    const payload = {
      assetId,
      condition,
    };

    this._state.custodyActionInProgress = CustodyActions.CheckIn;
    this._state.currentAction = ASSET_COC_ACTIONS.COC_STARTED;
    this.assetCOCStateObservable.next({ ...this._state });

    return this._assetListService.checkInAsset(payload).pipe(
      finalize(() => {
        this._state.custodyActionInProgress = null;
        this._state.currentAction = ASSET_COC_ACTIONS.COC_COMPLETED;
        this.assetCOCStateObservable.next({ ...this._state });
      }),
      catchError((err) =>
        this.handleCustodyActionError(err, 'Check in failure', externalId)
      )
    );
  }

  onRevokeAsset(
    assetId: string,
    assetOperation: CustodyActions,
    externalId: string
  ) {
    const payload = {
      assetId,
      assetOperation,
    };

    const lastOperation = assetOperation
      .substring(assetOperation.indexOf('_') + 1)
      .toLowerCase();
    const errorMessage = `Failed to cancel ${lastOperation}`;

    this._state.custodyActionInProgress = assetOperation;
    this._state.currentAction = ASSET_COC_ACTIONS.COC_STARTED;
    this.assetCOCStateObservable.next({ ...this._state });

    return this._assetListService.revokeAsset(payload).pipe(
      finalize(() => {
        this._state.custodyActionInProgress = null;
        this._state.currentAction = ASSET_COC_ACTIONS.COC_COMPLETED;
        this.assetCOCStateObservable.next({ ...this._state });
      }),
      catchError((err) =>
        this.handleCustodyActionError(err, errorMessage, externalId)
      )
    );
  }
}
