import { Injectable } from '@angular/core';
import _ from 'lodash';
import { AssetCategory, ElasticSearchQuery, Filter, FilterGroupList, FilterList, Range, SearchChildFilter } from "../models/index";


@Injectable({
  providedIn: "root",
})
export class FiltersUtilService {
  constructor() {}

  /**
   * Toggles checked state of a filter and returns the filter object and total
   * selected filter
   *
   * @param filters
   * @param filterName
   * @returns
   */
  toggleFilter(filters: FilterGroupList, filterName: string): { filters: FilterGroupList; selected: number } {
    filters = _.cloneDeep(filters);

    // Prepare a list of all filters
    const allFilters = this.flattenFilter(filters);

    // Get the selected filter to toggle
    const selectedFilter = allFilters.find((filter) => filter.name === filterName);

    // Oh, no selected filter does not exist something wrong.
    if (!selectedFilter) {
      console.warn("Selected filter in the UI does not exist in the state.");
    } else {
      // Toggle selected filter state
      selectedFilter.checked = !selectedFilter.checked;
    }

    // Get total of selected filter
    const { selected } = this.countFilters(allFilters);

    return {
      filters,
      selected,
    };
  }

  /**
   * Toggles checked state of a range filter and returns the filter object and total
   * selected filter
   *
   * @param filters
   * @param filterType Selected filter group name
   * @param range Selected range to update with the toggling of range filter
   * @returns
   */
  toggleRangeFilter(filters: FilterGroupList, filterType: string, range: Range): { filters: FilterGroupList; selected: number } {
    filters = _.cloneDeep(filters);

    // Get the selected filter to toggle
    const selectedFilter = filters.find((filter) => filter.filterType === filterType);

    // Oh, no selected filter does not exist something wrong.
    if (!selectedFilter) {
      console.warn("Selected filter in the UI does not exist in the state.");
    } else {
      // Toggle selected filter state and range

      let isRangeSelected = !!(range.from || range.to);
      let { from = "", to = "" } = range;

      if (!selectedFilter.list || selectedFilter.list.length == 0) {
        selectedFilter.list = [
          {
            checked: false,
            count: 0,
            name: "",
            type: "range",
          }
        ];
      }

      selectedFilter.list[ 0 ].checked = isRangeSelected;
      selectedFilter.list[ 0 ].name = isRangeSelected ? from + ":" + to : "";
    }

    // Prepare a list of all filters
    const allFilters = this.flattenFilter(filters);

    // Get total of selected filter
    const { selected } = this.countFilters(allFilters);

    return {
      filters,
      selected,
    };
  }

  prepareSearchFieldsForCategories(
    categories: AssetCategory[],
    fieldName: string,
    queryOperator: "must" | "should" = "must",
    match: "must" | "should" = "must"
  ): SearchChildFilter | null {
    const selectedCategories = categories.filter((cat) => cat.checked === true);
    const selectedCategoryIds = selectedCategories.map((cat) => cat?.properties?.id);

    return selectedCategoryIds?.length > 0
      ? {
          fieldName,
          match,
          queryOperator,
          queryText: selectedCategoryIds.join(","),
        }
      : null;
  }

  /**
   * Prepares elastic search query request structure based on the selected
   * filter.
   */
  prepareSearchFields(filterList: FilterGroupList, fieldName: string, queryText: string): ElasticSearchQuery[] {
    const childFilters: SearchChildFilter[] = filterList.reduce((acc: SearchChildFilter[], group) => {
      const groupFieldName = group.filterType;

      // Get selected filter in this group
      const selectedFilter = this.getSelectedFilter(group.list);

      // Map all filters to elastic search childFilters array
      const selectedChildFiltersInCurrentGroup = selectedFilter.map((f) => this.filterToElasticChildFilter(f, groupFieldName));

      const joinedQueryText = selectedChildFiltersInCurrentGroup.map((f) => f.queryText).join(",");

      let childFilter: any[] = [];

      if (selectedChildFiltersInCurrentGroup?.length > 0) {
        childFilter = [
          {
            ...selectedChildFiltersInCurrentGroup[0],
            queryText: joinedQueryText,
          },
        ];
      }

      // Combine previous group list with the current group filters
      return [
        ...acc,
        ...childFilter,
        // ...selectedChildFiltersInCurrentGroup,
      ];
    }, []);

    // Prepare and return elastic search query object
    return [
      {
        fieldName, //: 'clfMappingType',
        queryText, //: 'Sensor',
        queryOperator: "should",
        queryType: "match",
        childFilters: childFilters,
      },
    ];
  }

  /**
   * Extracts all filters from different groups to single array.
   * In simple flattens the nested filter list.
   */
  private flattenFilter(filters: FilterGroupList): FilterList {
    return filters.reduce((acc: Filter[], curr) => [...acc, ...curr.list], []);
  }

  /**
   * Gets total, selected and unselected filters.
   */
  private countFilters(filterList: FilterList) {
    const total = filterList.length;
    const selected = this.getSelectedFilter(filterList)?.length;
    const unSelected = total - selected;

    return {
      total,
      selected,
      unSelected,
    };
  }

  /**
   * Gets list of all selected filter from the filter set
   */
  private getSelectedFilter(allFilters: FilterList): FilterList {
    return allFilters.filter((filter) => filter?.checked);
  }

  /**
   * Maps a filter object to elastic search childFilter field object.
   */
  private filterToElasticChildFilter(filter: Filter, fieldName: string): SearchChildFilter {
    let childFilter: SearchChildFilter = {
      fieldName,
      queryText: filter.name,
      queryOperator: "must",
    };

    if (filter.type === "range") {
      childFilter.queryType = "range";
    }

    return childFilter;
  }

  removeFromString(filtString: any, toRemove: any) {
    let filtArray = filtString.split(",");
    return filtArray
      .filter((el: any) => {
        return el !== toRemove;
      })
      .join(",");
  }

  areNoneChecked(filters: any) {
    let isChecked = false;
    filters.forEach((filterItem: any) => {
      if (filterItem.list && filterItem.list.length) {
        filterItem.list.forEach((listItem: any) => {
          if (listItem.checked) {
            isChecked = true;
          }
        });
      }
    });

    return isChecked ? false : true;
  }

  getSearchFields(mapGroups: any, selectedUsers = {}, selectedLocations = {}, queryOperator: "should" | "must" = "should") {
    let searchFields: any[] = [],
      tags: any[] = [];
    mapGroups.forEach((mg: any, i: any) => {
      let noneChecked: boolean;
      if (mg.enabled) {
        searchFields.push({
          fieldName: "baseClass",
          queryText: _.capitalize(mg.class),
          queryOperator: queryOperator,
          match: "must",
          childFilters: [],
        });
      }
      if (mg.enabled && mg.filters && mg.filters.length) {
        noneChecked = this.areNoneChecked(mg.filters);
        mg.filters.forEach((filt: any) => {
          if (Array.isArray(filt.list)) {
            // noneChecked = this.areNoneChecked(filt.list)
            let filtString = "",
              skuString = "",
              skuGroups: any[] = [];
            filt.list.forEach((cat: any) => {
              if (cat.checked) {
                filtString += filtString.length ? "," + cat.name : cat.name;
                tags.push({
                  name: cat.name,
                  mapGroup: mg.name,
                  class: mg.class,
                });
              }
              if (cat.subList && cat.subList.length) {
                skuString = "";
                cat.subList.forEach((subFilt: any) => {
                  if (subFilt.checked) {
                    skuString += skuString.length ? "," + subFilt.name : subFilt.name;
                    tags.push({
                      parentTag: cat.name,
                      name: subFilt.name,
                      mapGroup: mg.name,
                      class: mg.class,
                    });
                  }
                });
                if (skuString) {
                  skuGroups.push({
                    parent: cat.name,
                    skuString: skuString,
                  });
                }
              }
            });
            if (skuGroups.length) {
              skuGroups.forEach((sg) => {
                searchFields[i].childFilters.push({
                  fieldName: filt.filterType || mg.filterType,
                  queryText: sg.parent,
                  queryOperator: "should",
                  childFilters: [
                    {
                      fieldName: "sku",
                      queryOperator: "should",
                      queryText: sg.skuString,
                    },
                  ],
                });
                filtString = this.removeFromString(filtString, sg.parent);
              });
            }
            let numFilts = filtString.split(",").length;
            let filtername = filt.filterType || mg.filterType;
            if (filtString && numFilts) {
              searchFields[i].childFilters.push({
                fieldName: filt.filterType || mg.filterType,
                queryText: filtString,
                queryOperator: filtername == "type" ? "should" : "must",
              });
            }
          }
        });
      }
      if (mg.enabled && mg.class === "users" && _.size(selectedUsers)) {
        let userString = "";
        _.forOwn(selectedUsers, (userObj, userName) => {
          if (userObj) {
            userString += userString.length ? "," + userName : userName;
            tags.push({
              name: userObj["name"],
              id: userObj["id"],
              mapGroup: mg.name,
              class: mg.class,
            });
          }
        });
        searchFields[i].childFilters.push({
          fieldName: "toParty",
          queryText: userString,
          queryOperator: "must",
        });
      } else if (mg.enabled && mg.class === "location" && _.size(selectedLocations)) {
        let locString = "";
        _.forOwn(selectedLocations, (locObj, loc) => {
          if (locObj) {
            locString += locString.length ? "," + loc : loc;
            tags.push({
              name: locObj["name"],
              id: locObj["properties"]["id"],
              mapGroup: mg.name,
              class: mg.class,
            });
          }
        });

        searchFields[i].childFilters.push({
          fieldName: "locationId",
          queryText: locString,
          queryOperator: "must",
        });
      }
    });
    return { searchFields, tags };
  }
}
