import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CustodyActions, UserCategory } from '@cl/@types/asset.type';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { ChainOfCustodyService } from '@cl/common/services/chain-of-custody.service';
import { AssetsCOCStateService } from '@cl/asset/asset-coc-state.service';

@Component({
  selector: 'cl-coc-panel',
  templateUrl: './coc-panel.component.html',
  styleUrls: ['./coc-panel.component.scss']
})
export class CocPanelComponent implements OnInit, OnDestroy {
  @Output() closeCOCPanel = new EventEmitter();
  expandCOCPanel: boolean = false;
  showAssetCondition: boolean = false;
  conditionsForm = new FormGroup({
    condition: new FormControl(null, Validators.required),
  });
  subscriptions: Subscription[] = [];
  readonly availableConditions = [
    { label: "Good", value: true },
    { label: "Damaged", value: false },
  ];
  readonly userTypes = [UserCategory.FieldServiceManager, UserCategory.FieldTechnician];
  showUserSearch: boolean = false;
  showMultiSelectUserSearch: boolean = false;
  userSearchTitle = {
    [CustodyActions.Checkout]: 'Check out to',
    [CustodyActions.Transfer]: 'Transfer to',
    [CustodyActions.InService]: 'Transfer in service to'
  };
  userSearchTitleText: string = '';
  showCocPanel: any;
  checkoutTransferInProgress: boolean;
  assetCOCStateSubject: Subscription;

  constructor(
    private cocService: ChainOfCustodyService,
    private assetCOCState: AssetsCOCStateService) { }

  ngOnInit(): void {
    this.watchMdList();
    this.listenToCOCEvents();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.assetCOCStateSubject?.unsubscribe();
  }

  watchMdList() {
    this.assetCOCStateSubject = this.assetCOCState.assetCOCStateObservable.subscribe(state => {
      this.showCocPanel = state.showCocPanel;
      if (this.showCocPanel) {
        this.expandCOCPanel = true;
      }

      this.checkoutTransferInProgress = !_.isEmpty(state.custodyActionInProgress);

      let cocAction: CustodyActions = state.currentCocAction;
      if (cocAction) {
        if (cocAction === CustodyActions.Confirm || cocAction === CustodyActions.CheckIn) {
          this.showAssetCondition = true;
        } else {
          this.showAssetCondition = false;
        }
        if (cocAction === CustodyActions.Checkout || cocAction === CustodyActions.Transfer || cocAction === CustodyActions.InService) {
          this.showUserSearch = true;
          this.userSearchTitleText = cocAction;
        } else {
          this.showUserSearch = false;
        }

        if (cocAction === CustodyActions.AuditUser) {
          this.showMultiSelectUserSearch = true;
          this.userSearchTitleText = cocAction;
        } else {
          this.showMultiSelectUserSearch = false;
        }
      }
    })
  }


  confirmAssetCondition() {
    const payload = {
      condition: this.conditionsForm?.value?.condition,
    };
    this.assetCOCState.onCustodyActionEmit(payload);
  }

  confirmSelection(selectedUser) {
    const payload = {
      condition: selectedUser
    };
    this.assetCOCState.onCustodyActionEmit(payload);
  }

  confirmMultiSelectUserSelection(selectedUser) {
    const payload = [];
    selectedUser.forEach(element => {
      payload.push(element.id);
    });
    this.assetCOCState.onCustodyActionEmit(payload);
  }

  closeCoc() {
    this.showCocPanel = false;
    this.closeCOCPanel.emit();
  }

  listenToCOCEvents() {
    const eventSub: Subscription = this.cocService.getCOCEvent().subscribe(evt => {
      if (evt.actionInProgress) {
        this.checkoutTransferInProgress = true;
      } else {
        this.checkoutTransferInProgress = false;
      }
    });
    this.subscriptions.push(eventSub);
  }
}
