import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '@cl/user/user.service';
import { VendorAccountService } from '../vendor-account.service';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import * as _ from 'lodash';
import { ClFormValidators } from '@cl/common/validator';

@Component({
  selector: 'cl-account-credentials-dialog',
  templateUrl: './account-credentials-dialog.component.html',
  styleUrls: ['./account-credentials-dialog.component.scss'],

})
export class CredentialsDialogComponent {
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  properties = [];
  constructor(
    private fb: FormBuilder,
    private vendorAccountService: VendorAccountService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CredentialsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  credentialForm = this.fb.group({});
  authorizationType = '';
  locale: any = {
    format: 'MM/DD/YYYY',
    displayFormat: 'MM/DD/YYYY hh:mm:ss'
  };
  up = 'up';
  visibleFields = ['password', 'confirmPassword', 'apikey', 'confirmApiKey', 'tokenCreatedAt', 'tokenExpiryTime'];

  ngOnInit(): void {
    this.getVendorList(this.data);
  }

  private getVendorList(accountDetails) {
    let tenantId = this.userService.getEffectiveTenantId();
    this.vendorAccountService.getVendorList(tenantId).subscribe((data: any) => {
      this.authorizationType = data.find(
        (vendor) => vendor.id === accountDetails.vendorId
      ).vendorAuthorizationType;
      this.getAuthProps(accountDetails.vendorId);
    });
  }

  passwordMatchValidator(frm: FormGroup) {
    if(this.authorizationType === 'Username_Password_Based'){
      frm.setValidators(ClFormValidators.confirmPassword('password', 'confirmPassword'));
    }
    else if(this.authorizationType === 'API_Key_Based') {
      frm.setValidators(ClFormValidators.confirmPassword('apikey', 'confirmApiKey'));
    }
  }

  private getAuthProps(vendorId) {
    this.vendorAccountService.getProperties(vendorId).subscribe((data: any) => {
      let props = data;
      let additionalProp = _.cloneDeep(props.find(prop => (prop.name === 'password' || prop.name === 'apikey')));
      additionalProp.name = (additionalProp.name === 'password') ? 'confirmPassword' : 'confirmApiKey';
      let propValue = additionalProp.value;
      propValue = JSON.parse(propValue);
      propValue.displayName = 'Confirm ' + propValue.displayName;
      additionalProp.value = JSON.stringify(propValue);
      props.push(additionalProp);
      props.forEach((prop: any) =>{
        prop.value = JSON.parse(prop.value);
        if(prop.value.uiRequired) {
          if(this.visibleFields.indexOf(prop.name) > -1){
            this.addControls(prop.name, prop.value)
          }
        }
      })

      let finalProps = [];
      props.forEach(prop => {
        if(this.visibleFields.indexOf(prop.name) > -1){
          finalProps.push(prop);
        }
      })

      this.properties = _.orderBy(finalProps, ['value.order'],['asc']);
      this.passwordMatchValidator(this.credentialForm);
      this.credentialForm.updateValueAndValidity();

    })
  }

  private addControls(key, value) {
    if(key){
      if(!value.optional)
       this.credentialForm.addControl(key, new FormControl('', Validators.required));
      else
       this.credentialForm.addControl(key, new FormControl(''));
    }

    if(value.conditions?.length || value.conditions?.allowedChars?.length) {
      let min = value.conditions?.allowedChars?.length.min ? value.conditions?.allowedChars?.length.min : value.conditions?.length.min;
      let max = value.conditions?.allowedChars?.length.max ? value.conditions?.allowedChars?.length.max : value.conditions?.length.max;
      this.credentialForm.get(key).addValidators(Validators.minLength(min));
      this.credentialForm.get(key).addValidators(Validators.maxLength(max));
    }
    // if(value.conditions?.allowedChars?.regex) {
    //   let regex = value.conditions?.allowedChars?.regex;
    //   this.credentialForm.get(key).addValidators(Validators.pattern(regex));
    // }
    this.credentialForm.updateValueAndValidity();
  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  updateAccount() {
    let payload = {};
    this.properties.forEach(prop => {
      if(!prop.name.includes('confirm')) {
        payload[prop?.name] = this.credentialForm.value[prop?.name];
      }
    })

    this.vendorAccountService
      .updateVendorAccount(this.data.id, payload)
      .subscribe(
        (data: any) => {
          let action = 'Account updated successfully';
          this.openSnackBar(action, this.data.nickname);
          this.dialogRef.close('success');
        },
        (error) => {
          console.error(error);
          let action = 'Something went wrong';
          this.openSnackBar(action, 'Account');
        }
      );
  }

  datesUpdated(datesUpdated, key){
    if(this.credentialForm.controls[key]){
      this.credentialForm.controls[key].setValue(datesUpdated.startDate?.valueOf());
    }
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
