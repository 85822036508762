import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private http: HttpClient,
    private apiProvider: ApiProviderService) {
   }

   // get list of inventory items

  getInventoryList(key: string): Promise<any> {
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/catalog/${key}`
    );
    return firstValueFrom(obs$);
  }

  postInventoryList(payload:any) {
    if(!payload){
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`, payload);
  }
  createInventory(payload, type) {
    const obs$ = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product?type=${type || ''}`,
      payload
    );
    return firstValueFrom(obs$);
  }

  updateInventory(payload,inventoryId, type) {
    const obs$ = this.http.put(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product/${inventoryId}?type=${type || ''}`,
      payload
    );
    return firstValueFrom(obs$);
  }

  getInventory(inventoryId) {
    const obs$ = this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/inventory/${inventoryId}`
    );
    return firstValueFrom(obs$);
  }

  deleteInventory(payload){
    const obs$ = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/inventory/delete`,payload
    );
    return firstValueFrom(obs$);
  }

  getInventorySummary(inventoryId: string) {
    const URL = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/inventory/${inventoryId}`;
    return this.http.get(URL);
  }

}
