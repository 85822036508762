<section [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="ml-2 -mt-2 mr-2.5" *ngIf="dynamicInputFields.length > 0">
        <cl-dynamic-form-renderer [columnMode]="'One'" [inputFields]="dynamicInputFields" #dynamicForm></cl-dynamic-form-renderer>
    </div>
    <ng-template #orgTemp>
    <div class="input">
          <label class="form-label ml-0">Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog></label>
          <div class="relative">
            <mat-select
              class="basic-input org"
              placeholder="Select organization"
              formControlName="organization"
            >
              <ng-container>
                <!-- <mat-option  *ngIf="!isOrgRestrictedUser" [value]="'ANY'" [textContent]="'(Any)'" ></mat-option> -->
                <mat-option *ngFor="let org of orgList"
                [value]="org.id">{{org.name}}</mat-option>
              </ng-container>
            </mat-select>
            <mat-icon  *ngIf="form.value.organization && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
          </div>
    </div>
    <p *ngIf="form.value.organization=='ANY'" class="orgcontent"><span>Warning</span>: Data will be seen by all users including those outside your org</p>
    </ng-template>
    <ng-template #sourceTemp >
        <div class="timeline-centered mt-1">
            <article class="timeline-entry" formArrayName="address">
                <div class="timeline-entry-inner"
                    *ngFor="let addressGroup of form.get('address')['controls']; let i = index; let last = last; let first = first"
                    [formGroupName]="i">
                    <span class="index-number">{{i + 1}}</span>
                    <div
                        [ngClass]="i > 0 ? (last?'timeline-icon bg-completed':'timeline-icon bg-progress') : 'timeline-icon bg-start' ">
                        <!-- <img src="assets/svgs/map-icons/site-pin-origin.svg" *ngIf="first"> -->
                        <span class="destination-dot bg-green-400" *ngIf="first"></span>
                        <!-- <img src="assets/svgs/map-icons/route-destination.svg" *ngIf="last"> -->
                        <span class="destination-dot bg-red-500" *ngIf="last"></span>
                        <img src="assets/svgs/organization.svg" *ngIf="!last && !first">
                    </div>
                    <div class="timeline-label">
                        <div class="rut_txt">
                            <div class="input">
                                <label class="form-label source" *ngIf="first">Source (Areas Only)<div class="text-red-700 ml-0 inline">*</div></label>
                                <label class="form-label" *ngIf="!last && !first">Waypoint (Areas Only)<div class="text-red-700 ml-0 inline">*</div></label>
                                 <label class="form-label" *ngIf="last">Destination (Areas Only)<div class="text-red-700 ml-0 inline">*</div></label>
                                <div class="tracking-flex">
                                    <input type="search" [placeholder]="first ? 'Source' :last ? 'Destination': (!last && !first) ? 'waypoint':''" [matAutocomplete]="auto" name="{{i}}" class="basic-input"
                                        formControlName="sourceArea"  (input)="getAreas(i)" [ngClass]="last?'distance':''">
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                        (optionSelected)="onSelectionChange(i, 'address')">
                                        <mat-option *ngIf="isLoading" class="is-loading">Loading...
                                        </mat-option>
                                        <ng-container *ngIf="!isLoading">
                                            <mat-option *ngFor="let area of areas[i]" [value]="area">{{ area.name }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                    <div class="text-red-600 ml-3" *ngIf="form.value.address[i].isNotAirport">Area should be of Type Airport</div>
                                    <i *ngIf="!last && !first" title="delete Waypoint" (click)="deleteAddressGroup(i)" class="fa fa-times-circle-o waypoint-close" aria-hidden="true"></i>
                                    <div *ngIf="first && submitted && addressGroup.get('sourceArea').hasError('required')" class="text-danger ml-2"> Source is required.</div>
                                    <div *ngIf="last && submitted && addressGroup.get('sourceArea').hasError('required')" class="text-danger ml-2"> Destination is required.</div>
                                    <div *ngIf="!last && !first && submitted && addressGroup.get('sourceArea').hasError('required')" class="text-danger ml-2"> Waypoint is required.</div>
                                </div>
                            </div>
                            <div class="cursor-pointer color4 ml-2 mt-1" (click)="setLayOverDuration(addressGroup,true)" *ngIf="!last && !first">
                                <i class="fa fa-clock-o tagclass"></i>Set layover duration
                                <span *ngIf="form.value.address[i].setDays > 0"> {{form.value.address[i].setDays + 'days,'}}
                                </span>
                                <span *ngIf="form.value.address[i].setHours > 0"> {{form.value.address[i].setHours + 'hours,'}}
                                </span>
                                <span *ngIf="form.value.address[i].setMins > 0"> {{form.value.address[i].setMins + 'mins'}}
                                </span>
                            </div>
                            <div class="popover__wrapper">
                                <div class="popover__content"
                                    [ngClass]="form.value.address[i].isSetLayOverDuration ? 'display-content' : ''">
                                    <div class="container__arrow container__arrow--tc"></div>
                                    <div class="pop">
                                            <div class="row">
                                            <div class="col px-1">
                                                <label for="days">Days</label>
                                                <input type="number" class="basic-input m-0 px-1" formControlName="setDays">
                                            </div>
                                            <div class="col px-1">
                                                <label for="hours">Hours</label>
                                                <input type="number" class="basic-input m-0 px-1" formControlName="setHours">
                                            </div>
                                            <div class="col px-1">
                                                <label for="mins">Mins</label>
                                                <input type="number" class="basic-input m-0 px-1" formControlName="setMins">
                                            </div>
                                        </div>
                                        <h4 class="text-center pointer mt-2 color4"
                                            (click)="setLayOverDuration(addressGroup,false)">SET</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="input" *ngIf="!last">
                                <label class="form-label">Travel Mode</label><div class="text-red-700 ml-0 inline">*</div>
                                <div class="relative">
                                    <mat-select class="basic-input route" formControlName="travelMode"
                                        (selectionChange)="onSelectionChange(i, 'travelMode')">
                                        <ng-container>
                                            <mat-option value="Road">Road</mat-option>
                                            <mat-option value="Air">Air</mat-option>
                                            <mat-option value="Train">Train</mat-option>
                                            <mat-option value="Custom">Custom</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </div>
                            </div>
                            <label *ngIf="!form.value.address[i].isShowOverrideTravelMode && !last" class="form-label ml-3"><strong>Distance:</strong> {{form.value.address[i].travelDistance}}</label>
                            <p *ngIf="form.value.address[i].isShowOverrideTravelMode" class="text-danger">No route found
                                using that travel mode</p>
                            <div class="px-2" *ngIf="form.value.address[i].isShowOverrideTravelMode">
                                <mat-checkbox id="{{i}}" [checked]="form.value.address[i].isShowDistance"
                                    (change)="toggleShowDistance($event,addressGroup,i)">
                                    <label class="ml-1 action__item__label">Overeride travel mode</label>
                                </mat-checkbox>
                            </div>
                            <div class="input" *ngIf="form.value.address[i].isShowDistance && form.value.address[i].isShowOverrideTravelMode">
                                <label class="form-label">Distance</label>
                                <input type="text" class="basic-input distance" id="travelDistance" placeholder="Distance"
                                    formControlName="travelDistance">
                                <label class="form-label"><strong>Distance:</strong> {{form.value.address[i].travelDistance}}</label>
                            </div>
                            <div class="cursor-pointer color4 ml-2 mt-1" (click)="closepopover(addressGroup,true)" *ngIf="!last">
                                <i class="fa fa-clock-o tagclass"></i>Set travel time duration
                                <span *ngIf="form.value.address[i].days > 0"> {{form.value.address[i].days + 'days,'}}
                                </span>
                                <span *ngIf="form.value.address[i].hours > 0"> {{form.value.address[i].hours + 'hours,'}}
                                </span>
                                <span *ngIf="form.value.address[i].mins > 0"> {{form.value.address[i].mins + 'mins'}}
                                </span>
                            </div>
                            <div class="popover__wrapper">
                                <div class="popover__content"
                                    [ngClass]="form.value.address[i].isSelectTravelTime ? 'display-content' : ''">
                                    <div class="container__arrow container__arrow--tc"></div>
                                    <div class="pop">
                                            <div class="row">
                                            <div class="col px-1">
                                                <label for="days">Days</label>
                                                <input type="number" class="basic-input m-0 px-1" formControlName="days">
                                            </div>
                                            <div class="col px-1">
                                                <label for="hours">Hours</label>
                                                <input type="number" class="basic-input m-0 px-1" formControlName="hours">
                                            </div>
                                            <div class="col px-1">
                                                <label for="mins">Mins</label>
                                                <input type="number" class="basic-input m-0 px-1" formControlName="mins">
                                            </div>
                                        </div>
                                        <h4 class="text-center pointer mt-2 color4"
                                            (click)="closepopover(addressGroup,false)">SET</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                     <i class="fa fa-plus-circle ml-4 pointer text-xl text-gray-400" title="add Waypoint" *ngIf="!last" (click)="addNewAddressGroup(i)" aria-hidden="true"></i>
                    </div>
                    <div class="timeline-label mt--25" *ngIf="!last">
                        <div class="rut_txt ml-2 input">
                            <label class="form-label"> Add waypoint (Optional) </label>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </ng-template>
</section>