<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title bgcolor32 justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/sensor.svg" />
            <span class="black font-bold">Replace {{sensorObject.isTracker ? 'Tracker' : 'Sensor'}}</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content flex flex-col">
        <a href="#/sensordetails/{{sensorObject?.id}}" class="fs14 mt-4 ml-4 mb-2">{{sensorObject?.id}}</a>
        <div class="fs14 pl-4 pr-4">With selected {{sensorObject.isTracker ? 'tracker' : 'sensor'}}</div>
        <div class="pl-4 pr-4">
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Name</div>
                <div class="basis-2/4">{{selectedSensor?.name}}</div>
            </div>
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Type</div>
                <div class="basis-2/4">{{selectedSensor?.type}}</div>
            </div>
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Status</div>
                <div class="basis-2/4">
                    {{selectedSensor?.status | startCase}}
                </div>
            </div>
        </div>
        <form [formGroup]="replaceForm" novalidate class="pl-4 pr-4 grow relative">
            <input type="search" formControlName="searchTerm" class="basic-input mt5 pt5 pr2"
            placeholder="Search Available {{sensorObject.isTracker ? 'Trackers' : 'Sensors'}}" value="">
            <div class="spin-pos" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
            <cdk-virtual-scroll-viewport itemSize="20" class="list-viewport h-calc-120 mx-2">
                <div class="search-list-item-container ba" *cdkVirtualFor="let item of availableSensors" (click)="selectListItem(item)">
                    <span class="search-list-item justify-between flex items-center" [class.item-select]="selectedSensor?.id === item?.id">
                        <span>{{item.id | truncate:24}}</span>
                        <i class="fa fa-check mr10 color4" aria-hidden="true" *ngIf="selectedSensor?.id === item?.id"></i>
                    </span>
                </div>
            </cdk-virtual-scroll-viewport>
        </form>
    </section>
    <section class="cl-side-panel-footer bgcolor33 pr-4 pl-4">
        <div class='flex justify-end items-center'>
            <a formnovalidate class=" m-2" (click)="closePanel()">Cancel</a>
            <button class="panel-button white bgcolor4" [disabled]="!selectedSensor?.id" (click)="replaceSensor()">
                Replace
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="replaceClicked"></i>
            </button>
        </div>
    </section>
</div>
