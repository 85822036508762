import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { SensorFabricService } from '../../sensor-fabric.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { GraphAPIService } from '@cl/common/services/graph-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { DeviceStatusLabels, GatewayFilterLabels, GatewayMappedFilters, GatewayTypeLabels } from '@cl/@types/gateway.type';
import { SearchApiService } from '@cl/common/services/search-api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/constants';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { TimesincePipe } from "@cl/common/pipes/timesince.pipe";
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { GatewayService } from '../gateway.service';
import { ToastService } from '@cl/common/services/toast.service';
import { FilterGroup } from '@cl/models';

@Component({
  selector: 'cl-gateway-list',
  templateUrl: './gateway-list.component.html',
  styleUrls: ['./gateway-list.component.scss']
})
export class GatewayListComponent implements OnInit, AfterViewInit {
  @ViewChild('lastUpdatedTemplate') lastUpdatedTemplate: TemplateRef<any>;
  @ViewChild('gatewayStatusTemplate') gatewayStatusTemplate: TemplateRef<any>;
  @ViewChild('locationNameTemplate') locationNameTemplate: TemplateRef<any>;
  @ViewChild('trafficTemplate') trafficTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  @ViewChild('gatewayNameTemplate') gatewayNameTemplate: TemplateRef<any>;
  @ViewChild('catalogTypeTemplate', {static: true}) catalogTypeTemplate: TemplateRef<any>;
  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;
  @ViewChild('clearTemplate') clearTemplate: TemplateRef<any>;
  private filterSubject: Subject<string> = new Subject();
  public columnFilters = {};

  private event = new Subject<any>();
  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  initialFilters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public dateColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public allLoaded: boolean = false;
  public scrollId: string = '';
  public headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  searchFieldFilter:any=[];
  sidePanelsObj = {
    summary: false,
    bind: false,
    bulk: false,
    create: false,
    edit: false,
  };
  actionCount: number = 0;
  actionBtnPolicies = {
    bind: ['USER_CREATE'],
    unbind: ['USER_CREATE'],
    delete:['RECEIVER_DELETE'],
    edit:['RECEIVER_UPDATE'],
    add:['RECEIVER_CREATE']
  }

  private overallStatusFilter = [
    { key: "all", value: 0, subBucket: [] },
    { key: "green", value: 0, subBucket: [] },
    { key: "red", value: 0, subBucket: [] },
    { key: "yellow", value: 0, subBucket: [] },
    { key: "N/A", value: 0, subBucket: [] }
  ];

  private rawFilters = {
    deviceClass: [{
      'key': 'CLOUD_CONNECTOR',
      'name': 'Gateway',
      'checked': false
    },
    {
      'key': 'MICRO_CLOUD_CONNECTOR',
      'name': 'Zone Sensor(powered)',
      'checked': false
    }],
    status: [{
      'key': 'UNPROVISIONED',
      'name': 'Unprovisioned',
      'checked': false
    },
    {
      'key': 'PROVISIONED',
      'name': 'Provisioned',
      'checked': false
    }]
  }
  scrollSize = 50;
  private payLoadForSensorList = {
    "scrollId": "",
    "scrollSize": this.scrollSize,
    "globalQueryText": "",
    "searchFieldFilter":[],
    "searchQueries": [
      {
        "fieldName": "parentEntityTypeKey",
        "queryText": "gateway",
        "queryOperator": "should",
        "queryType": "match",
        "childFilters": []
      }
    ],
    "sortField": [

    ]
  };
  bindDisabled: boolean = true;  
  deleteDisabled:boolean = true;  
  editDisabled:boolean = true;
  showBindPanel: boolean;
  selectedGateway: any;
  mainWidth: any;
  tableWidth: number;
  bindAction: string = "Bind/Unbind";
  bindIcon: string = "";
  selectedFilters: any = {};
  totalHits: number;
  showingHits: number;
  columnKeys: string[] = [];
  searchFormGroup: FormGroup;
  private destroyed$ = new Subject<boolean>();
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  catalogType: string = 'gateway';
  extendedColumns: any;
  showSidePanel: boolean = false;
  roleObject:any ={};
  showSPanel = false;
  mode: string = '';
  showFilter:boolean=false;
  scrollPositionX: any;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private sensorFabricService: SensorFabricService,
    private _searchApiService: SearchApiService,
    private el: ElementRef,
    private _utils: UtilsService,
    private dialog: MatDialog,
    private _graphAPIService: GraphAPIService,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private formateDatePipe: FormateDatePipe,
    private timeSincePipe: TimesincePipe,
    private persistenceStore: PersistenceService,
    private extendedattributeService: ExtendedCatalogAttributeService,
    private gatewayService:GatewayService,
    private _toastService: ToastService,
  ) {
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
  }
  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get("searchTerm") as FormControl;
  }

  ngOnInit(): void {
    this.getGatewayTotalCount();
    // this.getGatewayFilters();
    this.filterSubject.pipe( debounceTime( 1000) ).subscribe( () => {
      this.filter();
    });
    this.getFilter();
    this.getGatewayList();
    this.setContainerWidth();
    this.getExtendedColumnList();
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.showSidePanel = true;
        this.mode = 'view'
        this.roleObject = evt.roleObject;
      }
    });
    this.subscriptions.push(eventSub);
  }

  ngAfterViewInit(): void {
    // this.fcSearchTerm.valueChanges
    //   .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
    //   .subscribe((evt) => this.onSearchInput(evt));
    this.table._offsetX.subscribe(res => {
      if(res != undefined){
        this.scrollPositionX = res
      }      
    })

    this.rawColumns = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true,
        frozenLeft: true,
        headerTemplate: this.clearTemplate,
      },
      { name: 'Name', prop: 'nameFormated',  width: 180, visible: true, cellTemplate: this.gatewayNameTemplate, frozenLeft: true },
      { name: 'MAC Address', prop: 'physicalId', width: 200, visible: true },
      { name: 'Gateway Type', prop: 'entityType',  width: 150, cellTemplate:this.catalogTypeTemplate, visible: true },
      { name: 'Catalog Type', prop: 'parentEntityTypeKey', width: 150, cellTemplate:this.catalogTypeTemplate, visible: true },
      { name: 'Organization', prop: 'organizationName', width: 200, visible: true },
      { name: 'Location', prop: 'location', width: 150,  cellTemplate: this.locationNameTemplate, visible: true },
      { name: 'Gateway Zone', prop: 'gatewayZone',  width: 150, visible: true },
      { name: 'Overall Status', prop: 'overallStatus',  width: 140, visible: true, cellTemplate: this.gatewayStatusTemplate },
      { name: 'Net Status', prop: 'netStatus', width: 120,  visible: true, cellTemplate: this.gatewayStatusTemplate },
      { name: 'Status', prop: 'status', width: 150,  visible: false },
      { name: 'BLE Status', prop: 'bleStatus', width: 120,  visible: true, cellTemplate: this.gatewayStatusTemplate },
      { name: 'Load', prop: 'systemLoad', width: 100,  visible: true },
      { name: 'Traffic', prop: 'networkTrafficUp', width: 100,  visible: true, cellTemplate: this.trafficTemplate },
      { name: 'Version', prop: 'systemVersionBuild', width: 100,  visible: true },
      { name: 'Updated', prop: 'systemUptimeEventTime', width: 120,  visible: true, cellTemplate: this.lastUpdatedTemplate },
    ];
    this.dateColumns = [
      { name: 'Created At', prop: 'createdAt', cellTemplate: this.dateTemplate, width: 180, visible: true },
      { name: 'Created By', prop: 'createdBy', width: 150, visible: true },
      { name: 'Modified At', prop: 'modifiedAt', cellTemplate: this.dateTemplate, width: 180, visible: true },
      { name: 'Modified By', prop: 'modifiedBy', width: 150, visible: true },
    ]
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('gateway', [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    this.columnKeys = this.columns.map(col => {
      let prop = col.prop;
      if (col.name === 'Name') {
        prop = 'name';
      }
      return { prop, name: col.name };
    });
    this._changeDetectorRef.detectChanges();
  }

  private getGatewayTotalCount() {
    const payload = {
      "scrollSize": 1,
      "searchQueries": [
        {
          "fieldName": "parentEntityTypeKey",
          "queryText": "gateway",
          "queryOperator": "should",
          "queryType": "match",
          "childFilters": []
        }
      ]
    }
    const totalCountSub: Subscription = this._searchApiService.globalSearch(payload, false).subscribe({
      next: res => {
        this.totalHits = res.totalHits;
      },
      error: err => {
        console.error(err);
      }
    });
    this.subscriptions.push(totalCountSub);
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, this.catalogType, this.table._internalColumns);   
  }

  filterInput( $event ) {
    this.filterSubject.next('');
  }

  closeFilter(){
    this.showFilter = false;
    this.headerHeight = 30;
    Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
    this.filter();
  }

  showHideFilter(){
    this.showFilter = !this.showFilter;
    if(this.showFilter){
      this.headerHeight = 65;
    }else{
      this.headerHeight = 30;
      Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
      this.filter();
    }
  }


  filter() {
    console.log(this.columnFilters);
    this.searchFieldFilter=[]
      Object.keys( this.columnFilters ).forEach(key => {
        this.searchFieldFilter.push({
          "name":key=="nameFormated"?"name":key,
          "value":this.columnFilters[key]
        })
        console.log(`${key}: ${this.columnFilters[key]}`)
      });
      console.log("final",this.searchFieldFilter);
      this.payLoadForSensorList.scrollId = '';
      this.payLoadForSensorList.searchFieldFilter = this.searchFieldFilter;
      this.getGatewayList(true);
  }


   /*private getGatewayFilters() {
    this._searchApiService
      .getCounts({
        aggField: "overallStatus,type,location.keyword",
        baseClass: "gateway",
      }).subscribe({
        next: (response) => {
          let mergedList = this.overallStatusFilter.map((bucket: any) => {
            bucket.displayLabel = DeviceStatusLabels[bucket.key];
            return ({ ...bucket, ...response[0]['hits'][0].buckets.find(bkt => bkt.key === bucket.key) });
          });
          response[0]['hits'][0].buckets = mergedList;

          let filters = this._searchApiService.parseAggregateCountAsFilter(response, "gateway", [""]);
          filters = filters.map(filter => {
            if (filter.filterType === 'overallStatus') {
              filter.type = 'singleselect';

            }
            return filter;
          });
          this.initialFilters = _.cloneDeep(filters);
          this.filters = filters;
        },
        error: (err) => {
          console.error(err);
        }
      })
  } */

  setGatewayFilters() {
    let filterArr = [];
    for (let key in this.rawFilters) {
      filterArr.push({
        'expand': true,
        'filterType': key,
        'label': GatewayFilterLabels[key],
        'type': 'multiselect',
        'list': this.rawFilters[key]
      });
    }

    this.filters = [...filterArr];
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 266;
    } else {
      this.tableWidth = this.mainWidth - 18;
    }
  }
  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  getGatewayTypeLabels(gatewayType: string): string {
    return GatewayTypeLabels[gatewayType];
  }

  getGatewayStatusLabels(status: string): string {
    if (!status) {
      return '';
    }
    return DeviceStatusLabels[status.toLowerCase()];
  }

  getTrafficLabel(rowData: any): string {
    let netTrafficDown = this.sensorFabricService.getNetTraffic(rowData.networkTrafficDown);
    let netTrafficUp = this.sensorFabricService.getNetTraffic(rowData.networkTrafficUp);
    if (netTrafficDown === 'N/A' && netTrafficUp === 'N/A') {
      return 'N/A';
    } else {
      return `${netTrafficDown} | ${netTrafficUp}`;
    }
  }

  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
      this.getGatewayList();
    }
  }

  getLoadMoreUpdate() {
    this.getGatewayList();
  }

  getGatewayList(resetData: boolean = false) {
    if(this.delayedRefreshStart) {
      this.delayedRefreshStart = false;
      this.tableRowData = [];
      this.payLoadForSensorList.scrollId = null;
    }
    let data = this.payLoadForSensorList;
    this.isLoading = true;
    const userPolicyListData: Subscription = this._searchApiService.globalEntitySearch('gateway', data, false).subscribe({
      next: (res: any) => {
        this.allLoaded = false;
        if (!this.payLoadForSensorList.scrollId) {
          this.rows = [...[]];
        }
        this.payLoadForSensorList.scrollId = res._scroll_id;
        let rowData = res.hits;
        rowData = rowData.map((row) => {
          if (row.status === 'UNPROVISIONED') {
            row.nameFormated =  row.name;
          } else if (row.status === 'PROVISIONED') {
            row.nameFormated = row.name;
          }
          return row;
        });
        if (resetData) {
          this.rows = [...rowData];
        } else {
          this.rows = [...this.rows, ...rowData];
        }
        this.tableRowData = [...this.rows];
        this.showingHits = this.rows.length;
        setTimeout(() => {
          this.scrollToOffsetX(this.scrollPositionX)
        }, 10);
        if (this.showingHits === this.totalHits || res.totalHits < this.scrollSize) {
          this.allLoaded = true;
        }
        this.isLoading = false;
      },
      error: (err) => {
        console.warn("error getting gateway list data", err);
      }
    }
    );

    this.subscriptions.push(userPolicyListData);
  }

  scrollToOffsetX(offsetX: number) {
    const tableContainer = this.el.nativeElement.querySelector('.datatable-body');
    if (tableContainer) {
      tableContainer.scrollLeft = offsetX;
      this.table.recalculate();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selectedRows);
    if (selectedRows.length === 1) {
      this.bindDisabled = false;
      this.editDisabled = false;
      let selectedRow = selectedRows[0];
      this.selectedGateway = selectedRow;
      if (selectedRow.status === 'PROVISIONED') {
        this.bindAction = "Unbind";
        this.bindIcon = "fa-unlink";
      } else {
        this.bindAction = "Bind";
        this.bindIcon = "fa-link";
      }
    } else {
      this.bindDisabled = true;
      this.deleteDisabled = true;
      this.editDisabled = true;
      this.bindAction = "Bind/Unbind";
      this.bindIcon = "";
    }
    if(selectedRows.length>0){
      this.deleteDisabled = false;
    }
  }

  formateDownloadData(data:any[]){
    let tempAssetData = [...data];
    let dateFieldArray = ['modifiedAt', 'createdAt'];
    tempAssetData.forEach(gw => {
      dateFieldArray.forEach(element => {
        if(gw[element]){
          gw[element] = this.formateDatePipe.transform(gw[element],'default');
        }
      });
      gw.systemUptimeEventTime = this.timeSincePipe.transform(gw.systemUptimeEventTime);
      gw.overallStatus = this.getGatewayStatusLabels(gw.overallStatus);
    });
    return tempAssetData;
  }

  downloadReport(reportType: string) {
    let downloadData = this.formateDownloadData(this.rows);
    if (reportType === 'csv') {
      const csvData = this._utils.getSelectedElementsFromArray(downloadData, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Gateways-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    this.payLoadForSensorList.scrollId = '';
    this.payLoadForSensorList.globalQueryText = val;
    this.getGatewayList(true);
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
     setTimeout(() => {
       this.table?.recalculate();
     }, 10);
  }

  getPanelUpdate(event: any) {
    this.payLoadForSensorList.scrollId = '';
    let filterEvent = event.filter;
    if (event.action === 'clearFilters') {
      this.selectedFilters = {};
      this.filters = _.cloneDeep(this.initialFilters);
    } else {
      if (filterEvent.selectionType === 'singleselect') {
        if (filterEvent.name === 'all') {
          delete this.selectedFilters[filterEvent.filterGroupName];
        } else if (filterEvent.name === 'N/A') {
          this.selectedFilters[filterEvent.filterGroupName] = ['na'];
        } else {
          this.selectedFilters[filterEvent.filterGroupName] = [filterEvent.name];
        }
      } else {
        if (filterEvent.checked) {
          if (!this.selectedFilters[filterEvent.filterGroupName]) {
            this.selectedFilters[filterEvent.filterGroupName] = [];
          }
          this.selectedFilters[filterEvent.filterGroupName].push(filterEvent.name);
        } else {
          let index = this.selectedFilters[filterEvent.filterGroupName].indexOf(filterEvent.name);
          this.selectedFilters[filterEvent.filterGroupName].splice(index, 1);
        }
      }
    }
    this.createPayloadAndReloadPolicyList();
  }


  createPayloadAndReloadPolicyList() {
    let childFilters = [];
    Object.keys(this.selectedFilters).forEach(key => {
      if (this.selectedFilters[key].length > 0) {
        childFilters.push(new Object({
          "fieldName": key,
          "queryText": this.selectedFilters[key].join(),
          "queryOperator": "must"
        }));
      }
    });
    this.payLoadForSensorList.searchQueries[0].childFilters = childFilters;
    this.getGatewayList(true);
  }

  closeSidePanel(currentAction?: string) {
    this.showBindPanel = false;
    if (currentAction === 'bindSuccess') {
      //this.getGatewayList();
      this.delayedRefreshStart = true;
    }
    this.showSidePanel = false;
    this.roleObject = {};
  }
  bindOrUnbind() {
    if (this.bindAction === "Unbind") {
      this.unbindSelected();
    } else {
      this.showBindPanel = true;
    }
  }

  unbindSelected() {
    const selectedGateway = this.selected[0];
    let message = `Are you sure you want to unbind this Gateway?: ` + selectedGateway?.name;
    // }

    const dialogData = new ConfirmDialogModel("", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let currentIcon = this.bindIcon;
        this.bindIcon = 'fa-circle-o-notch fa-pulse';
        this.bindDisabled = true;
        this._graphAPIService.unbindGateway(selectedGateway.id).subscribe({
          next: (res: any) => {
            let selectedId = selectedGateway.id;
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
            this.openSnackBar("Unbind Gateway", selectedGateway.name);
            this.delayedRefreshStart = true;
            /*if (res['id'] === selectedId) {
              this.openSnackBar("Unbind Gateway", res.properties.name);
              //this.getGatewayList();
            } else {
              this._utils.showMessage("Failed to unbind Gateway");
            }*/
          },
          error: (err) => {
            console.error(err);
            this._utils.showMessage("Failed to unbind Gateway");
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
          }
        });
      }
    });
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  getId(row) {
    return row.id;
  }

//   get extended columns
getExtendedColumnList() {
    let storeColumnsList = this.persistenceStore.getGridColumns(
      this.catalogType
    );

    this.extendedattributeService.getCatalogExtendedColumns(this.catalogType) // asset047
      .then((response: any) => {
        this.extendedColumns = response.cdmFields.filter((field) => field?.group === 'User' && field.isDisplayableOnList === true)
          .map((field) => {
            return {
              name: field.displayLabel,
              prop: field.name,
              width: 120,
              visible: false,
              order: field.order,
              searchable: field.searchable
            };
          });

        let sortedExtendedColumn = this.sortCdmFields(this.extendedColumns);    
        if (storeColumnsList.length) {
          let filteredColumns = [];
          sortedExtendedColumn.forEach((item) => {
            let index = storeColumnsList.findIndex(
              (column) => column === item.name
            );
            if (index > -1) {
              item.visible = true;
              filteredColumns.push(item);
            } else {
              item.visible = false;
              filteredColumns.push(item);
            }
          });
          this.rawColumns = [...this.rawColumns, ...filteredColumns, ...this.dateColumns];
        } else {
          this.rawColumns = [...this.rawColumns, ...sortedExtendedColumn,...this.dateColumns];
        }

        let self =this;
        this.rawColumns.forEach(function(item){
          let prop ="";
          if(item.prop=="nameFormated"){
            prop = "name";
          }else{
            prop = item.prop;
          }
          let searchIndex = response.cdmFields.findIndex(
            (field) => field.name === prop &&  field.searchable == true
          );

          if (searchIndex>-1) {
             item.headerTemplate= self.hdrTpl;
          }
        })
        const columns = this.rawColumns.filter((col: any) => col.visible);
        this.columns = [...columns];
        this.table?.recalculate();
      });
  }

  // load summery side panel
  loadSummaryPanel(roleObject:any, event:any){
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', roleObject: roleObject });
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  sortCdmFields(cdmFields) {
    let negativeOrderedFields = _.filter(cdmFields, {'order': -1});
    let positiveOrderedFields = _.filter(cdmFields, function(o) { return o.order>-1; });
    positiveOrderedFields = _.sortBy(positiveOrderedFields, 'order');
    let result = [...positiveOrderedFields, ...negativeOrderedFields];
    return result;
  }

  openAddGatewayPanel(isEdit){
    this.closeSidePanel();
    this.sidePanelsObj.create = !isEdit;
    this.sidePanelsObj.edit = isEdit;
  }

  closeGatewaySidePanel() {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
  }
  closeAddGatewayPanel(isReload = false){
    this.closeGatewaySidePanel();
    if(isReload){
      this.delayedRefreshStart = true;
      this.getGatewayTotalCount();
      setTimeout(()=>{
        this.getGatewayList();
      },1000)
      
    }
  }
  
  deleteGateway(){    
    this.isLoading = true;
    const payload =[];
    console.log(this.selected);
    let message = `Are you sure you want to delete this Gateway?: ` + this.selectedGateway.name;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    this.selected.forEach(item=>{
      payload.push({
        id: item.id,      
        name: item.name,
        classType: item.classType
      })
    })
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {        
        this.gatewayService.deleteGateway(payload)
        .then((result: boolean)=>{
          if(result){
            this.selectedGateway = null;
            this.selected = [];
            this.editDisabled = true;
            this.deleteDisabled = true;
            this.delayedRefreshStart = true;
            this.isLoading = false;
            this.getGatewayTotalCount();
            setTimeout(()=>{
              this.getGatewayList();
            },1000);  
            if(result[0] && result[0].status==='Success')    {     
            this._toastService.success(
              `Gateway deleted successfully`
            );
            }else{
              this._toastService.error(
                result[0] && result[0].errorMessage?result[0].errorMessage:"Something went wrong"
              );
            }
          }
        });
      }else{
        this.isLoading = false;
      }
      this.bindDisabled = true;
      this.deleteDisabled = true;
      this.editDisabled = true;
    });
  }

  private formFilters(data) {
    this.filters = data.map((entry: any) => {
      let filter: FilterGroup = {
        list: [],
        filterType: entry.labelKey,
        label: entry.label,
        type: entry.uiSelectType? entry.uiSelectType:"multiselect",
      };
      filter.list = entry.options.map(list => ({
        name: list.id,
          checked: !!false,
          count: (list.count>0?list.count:0),
          displayLabel: list.name,
          label: list.name
      }));
      filter.enableCollapsing = filter.list.length > 10;
      filter.collapsed = filter.list.length > 10;
      filter.expand = true;
      return filter;
    })
    this.initialFilters = _.cloneDeep(this.filters);
  }

  getFilter(){
    this._searchApiService.getFilters(this.catalogType).then((res:any)=>{
      this.formFilters(res);
    })
  }

}
