import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { Observable, Subject, Subscription } from "rxjs";
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { RecipeService } from '../recipe.service';
import { UserService } from '@cl/user/user.service';
import { ToastService } from '@cl/common/services/toast.service';


@Component({
  selector: 'cl-recipes-list',
  templateUrl: './recipes-list.component.html',
  styleUrls: ['./recipes-list.component.scss']
})
export class RecipesListComponent implements OnInit {
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  @ViewChild('skuList') skuList: any;
  @ViewChild('skuNameTemplate') skuNameTemplate: TemplateRef<any>;

  private event = new Subject<any>();
  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  initialFilters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public allLoaded: boolean = false;
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showFilterPanel = false;
  actionCount: number = 0;
  private selectedFilters: any = {};
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  sidePanelsObj: any = {
    bind: false,
    bulk: false,
    replace: false
  };  

  scrollSize = 50; 
  editDisabled: boolean = true;
  deleteDisabled: boolean = true;
  selectedRecipe: any;
  mainWidth: any;
  tableWidth: number;
  totalHits: number;
  showingHits: number;
  columnKeys: string[] = [];
  searchFormGroup: FormGroup;
  isTracker: boolean = false;
  pageName: string;
  catalogType: string;
  showSidePanel: boolean = false;
  roleObject:any ={};
  showSPanel = false;
  mode: 'edit' | 'create' | 'summary' | '' = '';
  cdmFields;
  showSummaryPanel=false;
  filterOption: any;
  catalogsTypeList:any=[]; 
  selectedEntity:any;
  isAdd:boolean;
  selectedEntityType:string="";  
  recipeReadOnly:boolean=false;
 
  constructor(
    private el: ElementRef,
    private _snackBar: MatSnackBar, 
    private _dialog: MatDialog,
    private recipeService:RecipeService,
    private userService: UserService,
    private _toastService : ToastService
  ) { }

  ngOnInit(): void {
    
    this.getRecipeList();
    this.setContainerWidth();  
    this.recipeService.updateRecipeList.subscribe(data=>{
      if(data===true){
        this.getRecipeList();
        this.closeSidePanel(false);
      }
    }) 
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 40;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 260;
    } else {
      this.tableWidth = this.mainWidth;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
     
    }
  }

  getLoadMoreUpdate() {
    
  }

  getRecipeList(resetData: boolean = false): void {
    this.recipeService.getRecipeList(this.userService.getTenantId()).subscribe((res:any)=>{
      if(res){
        var data= res.recipes;
        // cache our list
        
        this.rows = [...data];
        this.tableRowData = data;
        this.isLoading = false;
        // this.temp = [...data];
        // this.rows=data;  
      }
    })
  }

  viewEntityDetails(val){
    // this.selectedEntityType = this.tableRowData.filter(item=>item.entityName===val)[0].qcardName;
     this.showSidePanel = true;
    this.isAdd = false
    this.selectedEntityType = this.tableRowData.filter(item=>item.entityName===val)[0].qcardName;
    this.selectedRecipe = this.tableRowData.filter(item=>item.entityName===val)[0];
  }
  viewRecipeDetails(val){
    this.showSidePanel = true;
    this.isAdd = false
    //this.selectedEntityType = this.tableRowData.filter(item=>item.entityName===val)[0].name;
    this.selectedRecipe = this.tableRowData.filter(item=>item.name===val)[0];
    this.recipeReadOnly = true;
  }
  onActivate(event){

  }
  
  
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  deleteRecipeDialog() {
    this._dialog
    .open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        message: `Are you sure want to delete this recipe?`,
      },
    })
    .afterClosed()
    .subscribe((res) => {
      if (res && this.selectedRecipe?.recipeId) {
       this.deleteRecipe();
      }
    });
  }
  public deselectRows() {
    this.selected = [];
    this.onSelect({ selected: [] });
  }

  async deleteRecipe(){
    let reqObj={
      tenantId:this.userService.getTenantId(),
      user:this.userService.getUserId(),
      controllers:[
         
      ],
      cloudleafId:this.selected[0].catalogItemId
  };   
    this.selected.forEach(element => {
      reqObj.controllers.push({
        name: element.name,
        qcardName:element.qcardName
      })          
    });
    
    this.recipeService.deleteRecipe(reqObj).subscribe((res:any)=>{
      if(res.status==='SUCCESS'){
        this.selected = [];
        this.selectedRecipe = [];
        this.editDisabled = true;
        this.deleteDisabled = true;
        this.getRecipeList();
        this._toastService.success("Recipe deleted successfully");
        this.isLoading=false;
      }
    },error=>{
      this._toastService.error("Something went wrong");
      this.isLoading=false;
    })
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000, 
    });
  }

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selectedRows);
    if (selectedRows.length === 1) {
      this.editDisabled = false;
      this.deleteDisabled = false;
      let selectedRow = selectedRows[0];
      this.selectedRecipe = selectedRow;     
    } else {
      this.deleteDisabled = true;
      this.editDisabled = true;
    }
    
  }

  getId(row) {
    return row.id;
  }

 
  closeSidePanel(isReload:boolean = false) {
    this.showSidePanel = false;
    this.recipeReadOnly = false;
    this.mode = '';
    if(isReload){
      this.delayedRefreshStart = true;     
    }
    this.selected =[];
    this.selectedRecipe = [];
    this.editDisabled = true;
    this.deleteDisabled = true;
  }
 

  closeSummaryPanel(){
    this.showSummaryPanel = false;
    this.roleObject = {};
  }

  loadSummaryPanel(roleObject:any, event:any){    
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', roleObject: roleObject });
  }
  setEvent(state: any) {
    this.event.next(state);    
  }
  getEvent(): Observable<any> {
    return this.event.asObservable();
  }
  openCreateSidePanel(isEdit:boolean){
    this.mode = isEdit ? 'edit' : 'create';
    this.showSidePanel = true;
    this.isAdd = !isEdit;
    if(isEdit){
      this.selectedEntityType = this.selected[0].qcardName;
      
    }
  }

  
  openAddAssetPanel(isEdit){
    this.closeSidePanel();
    this.sidePanelsObj.create = !isEdit;
    this.sidePanelsObj.edit = isEdit;
  }

  closePopupAssetPanel(event){

  }

  updateFilter(value){
    const val = value.toLowerCase();

    // filter our data
    const temp = this.tableRowData.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
  }
}
