import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserSearchResult, CustodyActions } from '@cl/@types/asset.type';
import { Observable, Subject, Subscription } from "rxjs";
import { delay, filter, switchMap, takeUntil } from "rxjs/operators";
import _ from 'lodash';
import { CheckoutTransferConfirmDialogComponent } from '../checkout-transfer-confirm-dialog/checkout-transfer-confirm-dialog.component';
import { CL_API_DELAY_SHORT } from '@cl/constants';
import { UtilsService } from '../../../common/utils/utils.service';
import { ChainOfCustodyService } from '@cl/common/services/chain-of-custody.service';
import { ToastService } from '@cl/common/services/toast.service';
import { UserService } from '@cl/user/user.service';
import { Router } from '@angular/router';
import { AssetsCOCStateService } from '@cl/asset/asset-coc-state.service';
import { ASSET_COC_ACTIONS } from '@cl/@types/asset-coc-state.type';
import { AssetsStateService } from '@cl/asset/asset-state.service';

@Component({
  selector: 'cl-coc-menu',
  templateUrl: './coc-menu.component.html',
  styleUrls: ['./coc-menu.component.scss']
})
export class CocMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assets: any[];
  @Input() renderIn: string;
  @Output() closeSidePanel = new EventEmitter();
  assetObject: any[] = [];
  nextOperations: any[] = [];
  stateActionMap: any[] = [];
  disableCOC: boolean = true;
  activeOption: CustodyActions;
  private readonly destroyed$ = new Subject<boolean>();
  checkoutTransferInProgress: boolean;
  custodyActionInProgress: any;
  dialogPayload: any = {};
  readonly MAX_SELECTION_ALLOWED = 500;
  multiSelected: boolean;
  loggedInUser: any;
  policies: any[] = [];
  assetCOCStateSubject: Subscription;
  constructor(
    private _matDialog: MatDialog,
    private _utils: UtilsService,
    private cocService: ChainOfCustodyService,
    private toastService: ToastService,
    private userService: UserService,
    private router: Router,
    private assetCOCState: AssetsCOCStateService,
    private assetState: AssetsStateService
  ) {
    this.loggedInUser = this.userService.user;
    this.policies = this.userService.getPolicies();
  }

  ngOnInit(): void {
    this.subscribeToStateChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const assetArray: SimpleChange = changes['assets'];
    if (assetArray && assetArray.currentValue && assetArray.currentValue.length) {
      if (JSON.stringify(this.assetObject) != JSON.stringify(assetArray.currentValue)) {
        this.assetObject = assetArray.currentValue;
        this.disableCOC = true;
        if (!_.isEmpty(this.assetObject)) {
          if (this.assetObject.length === 1) {
            // this.assetObject = this.assetObject[0];
            this.multiSelected = false;
            this.stateActionMap = this.cocService.getActionStateMap(this.assetObject[0]);
            const atLeastOneCOCEnabled = this.stateActionMap.some(item => item.enabled);
            if (atLeastOneCOCEnabled) {
              this.disableCOC = false;
            }
          } else if (this.assetObject.length > 1) {
            this.multiSelected = true;
            if (this.assetObject.length > this.MAX_SELECTION_ALLOWED) {
              const title = `Multi-Tool Actions are not available as more than ${this.MAX_SELECTION_ALLOWED} tools have been selected`;
              this.toastService.error(title);
            } else {
              this.handleMultipleAssetSelection();
            }
          }
        }
      }
    } else {
      const isAuditUserEnabled = this.policies.includes('CUSTODYASSET_COC_AUDIT_USER') ? true : false;
      const isAssetDetailsPage = this.router.url.indexOf('assetdetails')>-1? true : false;
      if(isAuditUserEnabled && !isAssetDetailsPage){
        this.disableCOC = false;
        this.stateActionMap = [{name: 'AUDIT_USER', label: 'Audit User', enabled: true}];
      }else{
        this.disableCOC = true;
        this.stateActionMap = [];
      }

      this.assetObject = [];
    }
  }

  handleMultipleAssetSelection() {
    const statues = this.assetObject.map((entity) => this.cocService.getActionStateMap(entity));

    let stateActionMapObj = statues.reduce((acc, curr) => {
      curr.forEach((item, index) => {
        if (!acc[item.name]) acc[item.name] = item;
        acc[item.name].enabled = acc[item.name].enabled && item.enabled;
      });
      return acc;
    }, {});
    this.stateActionMap = Object.values(stateActionMapObj);
    const atLeastOneCOCEnabled = this.stateActionMap.some(item => item.enabled);
    if (atLeastOneCOCEnabled) {
      this.disableCOC = false;
    }
  }

  /**
   * Common method to open checkout and transfer dialog
   * @param dialogData Data to pass to the dialog
   */
  private openConfirmDialog(dialogData?: any) {
    return this._matDialog
      .open(CheckoutTransferConfirmDialogComponent, {
        hasBackdrop: true,
        // height: '275px',
        width: "280px",
        data: dialogData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),

        filter((data) => data?.accepted === true)
      );
  }

  cocAction(custodyAction: CustodyActions) {
    /* if (this.activeOption && this.activeOption === custodyAction) {
      this.activeOption = null;
    } else {
      this.activeOption = custodyAction;
    } */
    this.activeOption = custodyAction;

    if (
      this.activeOption === CustodyActions.Return
      || this.activeOption === CustodyActions.CancelledCheckout
      || this.activeOption === CustodyActions.CancelledReturn
      || this.activeOption === CustodyActions.CancelledTransfer
      || this.activeOption === CustodyActions.AuditAsset
    ) {
      this.confirmSelection();
    } else {
      this.assetCOCState.showCOCSidePanel(custodyAction);
    }
  }

  confirmSelection(user?: UserSearchResult) {
    switch (this.activeOption) {
      case CustodyActions.Checkout:
        this.confirmCheckout(user);
        break;

      case CustodyActions.Transfer:
        this.confirmTransfer(user);
        break;

      case CustodyActions.InService:
        this.confirmTransferInService(user);
        break;

      case CustodyActions.Confirm:
        this.confirmReceiptDialog();
        break;

      case CustodyActions.Return:
        this.confirmReturn();
        break;

      case CustodyActions.CheckIn:
        this.confirmCheckIn();
        break;

      case CustodyActions.CancelledCheckout:
        this.confirmRevoke(CustodyActions.CancelledCheckout);
        break;

      case CustodyActions.CancelledReturn:
        this.confirmRevoke(CustodyActions.CancelledReturn);
        break;

      case CustodyActions.CancelledTransfer:
        this.confirmRevoke(CustodyActions.CancelledTransfer);
        break;

      case CustodyActions.AuditAsset:
        this.confirmAudit(CustodyActions.AuditAsset);
        break;

      case CustodyActions.AuditUser:
          this.confirmAudit(CustodyActions.AuditUser);
          break;
    }
  }

  private generateBulkEntityPayload(entities: any[], user: UserSearchResult) {
    const payload = entities.map((entity) => ({
      assetId: entity.id,
      userId: user.id,
      externalId: entity.externalId,
    }));

    return payload;
  }

  private handleBulkAction(payload: any[], operation: CustodyActions) {
    this.cocService.setCOCEvent({actionInProgress: true});
    const successMessage = {
      [CustodyActions.Checkout]: "Checkout success",
      [CustodyActions.Transfer]: "Tools transferred",
      [CustodyActions.InService]: "Tools transferred in service",
      [CustodyActions.Confirm]: "Tools confirmed",
      [CustodyActions.CheckIn]: "CheckIn success",
      [CustodyActions.Return]: "Tools returned",
      [CustodyActions.CancelledCheckout]: "Cancelled checkout",
      [CustodyActions.CancelledReturn]: "Cancelled return",
      [CustodyActions.CancelledTransfer]: "Cancelled transfer",
    };

    const failureMessage = {
      [CustodyActions.Checkout]: "Failed to checkout",
      [CustodyActions.Transfer]: "Failed to transfer tools",
      [CustodyActions.InService]: "Failed to transfer tools in service",
      [CustodyActions.Confirm]: "Failed to confirm tools",
      [CustodyActions.CheckIn]: "Failed to CheckIn",
      [CustodyActions.Return]: "Failed to return tool",
      [CustodyActions.CancelledCheckout]: "Failed to cancel checkout",
      [CustodyActions.CancelledReturn]: "Failed to cancel return",
      [CustodyActions.CancelledTransfer]: "Failed to cancel transfer",
    };
    this.cocService.bulkCoc(payload, operation).subscribe({
      next: data => {
        this.toastService.success(successMessage[operation]);
        this.onCustodySuccess(data);
      },
      error: err => {
        this.cocService.setCOCEvent({actionInProgress: false});
        let errorMessage = (err && err['error'] && err['error']['error-message']) || failureMessage[operation];
        this.toastService.error(errorMessage);
      }
    })
  }

  private confirmCheckout(user: UserSearchResult) {
    if (this.multiSelected) {
      const payload = this.generateBulkEntityPayload(this.assetObject, user);
      const title = `You are checking out ${this.assetObject.length} tool/s to ${user.name}`;
      this.openConfirmDialog({ title }).subscribe((_) => this.handleBulkAction(payload, CustodyActions.Checkout));
    } else {
      const { type, name, id, externalId } = this.assetObject[0];
      const payload = {
        assetId: id,
        userId: user.id,
        externalId,
      };
      const title = `You are checking out tool ID ${name} to ${user.name}`;

      this.openConfirmDialog({
        selectedAsset: this.assetObject[0],
        selectedUser: user,
        title,
      }).subscribe((_) => this.checkOut(payload));
    }
  }

  /**
   * Shows transfer confirmation dialog
   */
  private confirmTransfer(user: UserSearchResult) {
    if (this.multiSelected) {
      const payload = this.generateBulkEntityPayload(this.assetObject, user);
      const title = `You are transferring ${this.assetObject.length} tool/s to ${user.name}`;
      this.openConfirmDialog({ title }).subscribe((_) => this.handleBulkAction(payload, CustodyActions.Transfer));
    } else {
      const { type, name, id, externalId } = this.assetObject[0];
      const payload = {
        assetId: id,
        userId: user.id,
        externalId,
      };

      const title = `You are transferring tool ID ${name} to ${user.name}`;

      this.openConfirmDialog({
        selectedAsset: this.assetObject[0],
        selectedUser: user,
        title,
      }).subscribe((_) => this.transfer(payload));

    }
  }

  /**
   * Shows transfer in service confirmation dialog
   */
  private confirmTransferInService(user: UserSearchResult) {
    if (this.multiSelected) {
      const payload = this.generateBulkEntityPayload(this.assetObject, user);
      const title = `You are transferring in service ${this.assetObject.length} tool/s to ${user.name}`;
      this.openConfirmDialog({ title })
      .subscribe((_) => this.handleBulkAction(payload, CustodyActions.InService));
      // .pipe(switchMap((_) => this.handleBulkAction(payload, CustodyActions.InService)));
    } else {

      const { type, name, id, externalId } = this.assetObject[0];
      const payload = {
        assetId: id,
        userId: user.id,
        externalId,
      };

      const title = `You are transferring in service tool ID ${name} to ${user.name}`;

      this.openConfirmDialog({
        selectedAsset: this.assetObject[0],
        selectedUser: user,
        title,
      }).subscribe((_) => this.transferInService(payload));
    }
  }

  /**
   * Shows confirm receipt confirmation dialog
   */
  private confirmReceiptDialog() {
    if (this.multiSelected) {
      const dialogPayload = this.dialogPayload;
      const payload = this.assetObject.map((entity) => ({
        assetId: entity.id,
        condition: dialogPayload.condition,
        userId: this.loggedInUser.id,
        externalId: entity.externalId,
      }));
      const title = `You are adding ${this.assetObject.length} tool/s to your toolbox`;
      this.openConfirmDialog({ title }).subscribe((_) => this.handleBulkAction(payload, CustodyActions.Confirm));
    } else {

      const { type, name, id, externalId } = this.assetObject[0];
      const dialogPayload = this.dialogPayload;
      const payload = {
        assetId: id,
        condition: dialogPayload.condition,
        externalId
      };

      const title = `You are adding tool ID ${name} to your toolbox`;

      this.openConfirmDialog({
        selectedAsset: this.assetObject[0],
        title,
      }).subscribe((_) => this.confirmReceipt(payload));
    }
  }

  /**
   * Shows check in confirmation dialog
   */
  private confirmCheckIn() {
    if (this.multiSelected) {
      const dialogPayload = this.dialogPayload;
      const payload = this.assetObject.map((entity) => ({
        assetId: entity.id,
        condition: dialogPayload.condition,
        userId: this.loggedInUser.id,
        externalId: entity.externalId,
      }));
      const title = `You are adding ${this.assetObject.length} tool/s to your toolbox`;

      this.openConfirmDialog({ title }).subscribe((_) => this.handleBulkAction(payload, CustodyActions.CheckIn));
    } else {
      const { type, name, id, externalId } = this.assetObject[0];
      const dialogPayload = this.dialogPayload;
      const payload = {
        assetId: id,
        condition: dialogPayload.condition,
        externalId
      };

      const title = `You are adding tool ID ${name} to your toolbox`;

      this.openConfirmDialog({
        selectedAsset: this.assetObject[0],
        title,
      }).subscribe((_) => this.checkIn(payload));
    }
  }

  /**
   * Shows return confirmation dialog
   */
  private confirmReturn() {
    if (this.multiSelected) {
      const payload = this.assetObject.map((entity) => ({
        assetId: entity.id,
        externalId: entity.externalId,
      }));
      const title = `You are returning ${this.assetObject.length} tool/s to your toolbox`;

      this.openConfirmDialog({ title }).subscribe((_) => this.handleBulkAction(payload, CustodyActions.Return));
    } else {

      const { type, name, id, externalId, checkedoutByInitially } = this.assetObject[0];
      const payload = {
        assetId: id,
        externalId,
      };

      const title = `You are returning tool ID ${name} to ${checkedoutByInitially}`;

      this.openConfirmDialog({
        selectedAsset: this.assetObject[0],
        title,
      }).subscribe((_) => this.returnAsset(payload));
    }
  }

  private returnAsset(payload) {
    this.cocService.setCOCEvent({actionInProgress: true});
    this.assetCOCState.onReturnAsset(payload.assetId, payload.externalId).subscribe(res => {
      this.onCustodySuccess(res);
      this.toastService.success("Return success", payload.externalId);
    });
  }

  /**
   * Shows revoke confirmation dialog in other words displays last operation
   * cancel confirmation box.
   */
  private confirmRevoke(operation: CustodyActions) {
    if (this.multiSelected) {
      const payload = this.assetObject.map((entity) => ({
        assetId: entity.id,
        externalId: entity.externalId,
        assetOperation: operation,
      }));
      const lastOperation = operation.substring(operation.indexOf("_") + 1)?.toLowerCase();
      const title = `Are you sure to cancel ${lastOperation} for all ${this.assetObject.length} tool/s?`;
      this.openConfirmDialog({ title }).subscribe((_) => this.handleBulkAction(payload, operation));
    } else {
      const { type, name, id, externalId, checkedoutBy } = this.assetObject[0];
      const payload = {
        assetId: id,
        externalId,
      };
      const lastOperation = operation.substring(operation.indexOf("_") + 1)?.toLowerCase();
      const title = `Are you sure to cancel ${lastOperation}?`;
      this.openConfirmDialog({
        selectedAsset: this.assetObject[0],
        title,
      }).subscribe((_) => this.revoke(payload, operation));
    }
  }

  private confirmAudit(operation: CustodyActions) {
    let payload = {};
    if(operation == CustodyActions.AuditAsset){
      let assetIdArray = [];
      this.assetObject.forEach(element => {
        if(element.custodyStatus === "IN POSSESSION" || element.custodyStatus == "IN TRANSFER"){
          assetIdArray.push(element.id);
        }
      });
      payload = {'assetIds':assetIdArray};
    }else if(operation == CustodyActions.AuditUser){
      payload = {'userIds':[...this.dialogPayload]};
    }

    const lastOperation = operation.substring(operation.indexOf("_") + 1)?.toLowerCase();

    const title = `Are you sure to initiate audit for ${lastOperation}?`;
    this.activeOption = null;
    
    this.openConfirmDialog({
      selectedAsset: this.assetObject,
      title,
    }).subscribe((_) => {
      this.initializeAudit(payload, operation)
    });
  }

  private checkOut(payload) {
    this.cocService.setCOCEvent({actionInProgress: true});
    this.assetCOCState.onCheckoutAsset(payload.assetId, payload.userId, payload.externalId).subscribe(res => {
      this.onCustodySuccess(res);
      this.toastService.success("Checkout success", payload.externalId);
    });
  }


  private transfer(payload) {
    this.cocService.setCOCEvent({actionInProgress: true});
    this.assetCOCState.onTransferAsset(payload.assetId, payload.userId, payload.externalId).subscribe(res => {
      this.onCustodySuccess(res);
      this.toastService.success("Transfer success", payload.externalId);
    });
  }

  private transferInService(payload) {
    this.cocService.setCOCEvent({actionInProgress: true});
    this.assetCOCState.onTransferInServiceAsset(payload.assetId, payload.userId, payload.externalId).subscribe(res => {
      this.onCustodySuccess(res);
      this.toastService.success("Transfer in service success", payload.externalId);
    });
  }

  private confirmReceipt(payload) {
    this.cocService.setCOCEvent({actionInProgress: true});
    this.assetCOCState.onConfirmReceiptAsset(payload.assetId, payload.condition, payload.externalId).subscribe(res => {
      this.onCustodySuccess(res);
      this.toastService.success("Confirm receipt success", payload.externalId);
    });
  }

  private checkIn(payload) {
    this.cocService.setCOCEvent({actionInProgress: true});
    this.assetCOCState.onCheckInAsset(payload.assetId, payload.condition, payload.externalId).subscribe(res => {
      this.onCustodySuccess(res);
      this.toastService.success("Check in success", payload.externalId);
    });
  }

  private revoke(payload, operation: CustodyActions) {
    this.cocService.setCOCEvent({actionInProgress: true});
    const lastOperation = operation.substring(operation.indexOf("_") + 1).toLowerCase();
    const successMessage = `Cancelled ${lastOperation}`;

    this.assetCOCState.onRevokeAsset(payload.assetId, operation, payload.externalId).subscribe(res => {
      this.onCustodySuccess(res);
      this.toastService.success(successMessage, payload.externalId);
    });
  }

  private initializeAudit(payload, operation){

    this.userService.initializeAudit(payload).subscribe(data=>{
      let action = 'Audit initialization successful';
      this.toastService.success(action,'');
      this.cocService.closeSidePanel.next(true);
      this.activeOption = null;
    }, error=>{
      let action = 'Something went wrong';
      this.toastService.error(error.error['error-message'],'');
      this.cocService.closeSidePanel.next(true);
    });
  }

  private onCustodySuccess(res: any) {
    this.activeOption = null;
    this.cocService.setCOCEvent({actionCompleted: true});
    this.assetCOCState.hideCOCSidePanel();
    // this.store.dispatch(new MalSearchByFilter(true))
    // this.assetState.searchByFilter(true);
    this.closeSidePanel.next(true)
    // this.store
    //   .dispatch(new MalHideSidePanel())
    //   .pipe(
    //     delay(CL_API_DELAY_SHORT)
    //   )
    //   .subscribe((_) => );
  }

  private subscribeToStateChanges() {
    this.assetCOCStateSubject = this.assetCOCState.assetCOCStateObservable.subscribe(state => {
      if (!state) return;

      this.checkoutTransferInProgress = !_.isEmpty(state.custodyActionInProgress);
      this.custodyActionInProgress = state.custodyActionInProgress;

      if (state.currentAction === ASSET_COC_ACTIONS.CUSTODY_ACTION_EMIT) {
        this.dialogPayload = state.dialogPayload;
        this.confirmSelection(state.dialogPayload.condition);
      }
    })
  }

  ngOnDestroy(): void {
    this.assetCOCStateSubject?.unsubscribe()
  }
}
