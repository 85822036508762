<div class="cl-side-panel bcolor9 border flex flex-col" [ngClass]="viewMode">
  <div class="font-bold absolute w-full z-10 top-11 px-2 bg-white">
    <a
      href="#/shipmentdetails/{{ shipmentData?.shipmentNode.properties.id }}?type={{shipmentType}}"
      >{{ shipmentData?.shipmentNode.properties.name }}</a
    >
  </div>
  <section class="cl-side-panel-title side-panel-header-bg justify-between items-center">
    <div class="grow flex items-center">
      <img src="assets/svgs/circle-icons-white/shipment.svg" />
      <span class="text-slate-600 text-base font-bold" *ngIf="activePane === 'summary'"
        >{{'logistics.shipmentSummary'|translate}}</span
      >
      <span class="black font-bold" *ngIf="activePane === 'content'"
        >Shipment - Included Assets</span
      >
      <span class="black font-bold" *ngIf="activePane === 'tracking'"
        >Shipment - Tracking</span
      >
    </div>
    <i class="fa fa-times-circle-o text-xl text-slate-600" (click)="closePanel()"></i>
  </section>
  <section class="cl-side-panel-content relative top-7 mb-6">
    <div *ngIf="activePane === 'summary'" class="relative">
      <div *ngIf="isLoading">
        <div class="mt-3 flex justify-center">
          <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
        </div>
      </div>
      <div *ngIf="!isLoading" class="px-1">
        <cl-shipment-summary-data
          [shipmentData]="shipmentData"
          [isShowAllFields]="false"
        ></cl-shipment-summary-data>
      </div>
    </div>
    <div *ngIf="activePane === 'content'">
      <div *ngIf="isLoading">
        <div class="mt-3 flex justify-center">
          <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
        </div>
      </div>
      <div
        *ngIf="shipmentData?.shipmentNode?.properties?.parentEntityTypeKey == 'standardshipment' && !isLoading"
        class="p-2"
      >
        <div
          class="border p-2 font-bold"
          *ngFor="let asset of shipmentData?.assetNodes"
        >
          <a href="#/assetdetails/{{ asset.id }}?sid={{ shipmentID }}">{{
            asset.properties.name
          }}</a>
        </div>
      </div>
      <div
        *ngIf="['parcelshipment', 'dtpshipment'].includes(shipmentData?.shipmentNode?.properties?.parentEntityTypeKey) && !isLoading"
        class="p-2"
      >
        <div *ngFor="let includedAsset of parcelIncludedAssets">
          <div
            class="border card-header px-2 color4 font-bold"
            (click)="includedAsset.isOpen = !includedAsset.isOpen"
          >
            Tracking ID {{ includedAsset.trackingID }}
            <i
              class="fa fa-chevron-right float-right mt-1"
              [ngClass]="{
                'rotate-Icon': includedAsset.isOpen
              }"
            ></i>
          </div>
          <div class="card-body bg-white p-0" *ngIf="includedAsset.isOpen">
            <div
              class="border p-2 font-bold"
              *ngFor="let asset of includedAsset.assets"
            >
              <a href="#/assetdetails/{{ asset.assetId }}?sid={{ shipmentID }}">{{
                asset.assetName
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="activePane === 'tracking'" class="h-full overflow-hidden">
      <div *ngIf="isLoading">
        <div class="mt-3 flex justify-center">
          <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
        </div>
      </div>
      <cl-shipment-router-plan
        [shipmentDetails]="shipmentData"
        *ngIf="shipmentData?.shipmentNode?.properties?.parentEntityTypeKey === 'standardshipment' && !isLoading"
      ></cl-shipment-router-plan>
      <cl-shipment-tracking-progress
        [shipmentDetails]="shipmentData"
        *ngIf="['parcelshipment', 'dtpshipment'].includes(shipmentData?.shipmentNode?.properties?.parentEntityTypeKey) && !isLoading"
      ></cl-shipment-tracking-progress>
    </div>
  </section>
  <section>
    <div class="flex justify-end">
      <button class="panel-button color4 bgcolor2 bcolor4 bw-1 m-3 z-10">
        <a
          href="#/shipmentdetails/{{
            shipmentID
          }}?type={{shipmentType}}"
          class="color4"
        >
          {{'logistics.shipmentDetails'|translate}}
          <i class="fa fa-arrow-right ml-1 color4"></i>
        </a>
      </button>
    </div>
  </section>
  <section class="cl-side-panel-footer pt-2 bgcolor33">
    <div class="flex flex-row">
      <div
        class="basis-1/3 fs11 text-center pointer"
        (click)="activePane = 'summary'; toggleActivePane('summary')"
      >
        <img
          src="assets/svgs/circle-icons-blue/message.svg"
          class="m-auto"
          [ngClass]="{ hidden: activePane !== 'summary' }"
        />
        <img
          src="assets/svgs/circle-icons-white/message.svg"
          class="m-auto"
          [ngClass]="{ hidden: activePane == 'summary' }"
        />
        {{'logistics.summary'|translate}}
      </div>

      <div
        class="basis-1/3 fs11 text-center pointer"
        (click)="activePane = 'tracking'; toggleActivePane('tracking')"
      >
        <img
          src="assets/svgs/circle-icons-blue/location.svg"
          class="m-auto"
          [ngClass]="{ hidden: activePane !== 'tracking' }"
        />
        <img
          src="assets/svgs/circle-icons-white/location.svg"
          class="m-auto"
          [ngClass]="{ hidden: activePane == 'tracking' }"
        />
        {{'logistics.tracking'|translate}}
      </div>

      <div
        class="basis-1/3 fs11 text-center pointer"
        (click)="activePane = 'content'; toggleActivePane('content')"
      >
        <img
          src="assets/svgs/circle-icons-blue/asset.svg"
          class="m-auto"
          [ngClass]="{ hidden: activePane !== 'content' }"
        />
        <img
          src="assets/svgs/circle-icons-white/asset.svg"
          class="m-auto"
          [ngClass]="{ hidden: activePane == 'content' }"
        />
        {{'logistics.includedAssets'|translate}}
      </div>
    </div>
  </section>
</div>
