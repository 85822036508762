import { Bisector, Deserializable, Entity } from '@cl/models';
import * as _ from 'lodash';

interface SearchMetadata {
  completed_in: number;
  max_id: number;
  max_id_str: string;
  next_results: string;
  query: string;
  refresh_url: string;
  count: number;
  since_id: number;
  since_id_str: string;
}

export class GraphResponseDT implements Deserializable {
  nodes: Entity[] = [];
  links: Bisector[] = [];
  searchMetadata: SearchMetadata;

  deserialize(res: any) {
    let node;
    _.forOwn(res, (val, key) => {
      if (val.data && val.data.nodes && val.data.nodes.length) {
        this.nodes = this.nodes.concat(val.data.nodes.map((vertice: Entity) => {
          node = new Entity().deserialize(vertice);
          node.nodeClass = (key === 'excursion') ? 'incident' : key;
          return node;
        })
        );
      }
      if (val.data && val.data.edges && val.data.edges.length) {
        this.links = this.links.concat(val.data.edges.map((link: Bisector) =>
          new Bisector().deserialize(link)
        ));
      }
    })
    return Object.assign({}, res, this);
  }

}
