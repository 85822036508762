<div>
  <agm-map  *ngIf="selectedTabIndex == 0 && showMap && locationType !== 'Location'" [latitude]="lat" [longitude]="lng" [minZoom]="2" [(zoom)]="zoom"
[mapTypeControl]="true"
(mapClick)="mapClick($event)" (mapReady)="map = $event" [styles]="mapOptions.styles" [streetViewControl]="false" [mapTypeControl]='false' [zoomControlOptions]="mapOptions.positionOfActions" [fullscreenControl]="true">
      <agm-marker *ngIf="lat && lng" [latitude]="lat" [longitude]="lng"></agm-marker>
      <agm-circle *ngIf="markerType == 'circle' && lat && lng" [latitude]="lat" [longitude]="lng"
      [radius]="radiusInMeters"
      [fillColor]="'red'"
      [circleDraggable]="true"
      [editable]="true"
      (radiusChange)="getChangedRadius($event)">

  </agm-circle>
  <agm-polygon *ngIf="markerType == 'polygon' && initialPolygonPts.length > 0"  [paths]="initialPolygonPts" [editable]="true" (polyPathsChange)="polyChange($event)">
  </agm-polygon>
</agm-map>
<agm-map *ngIf="selectedTabIndex == 0 && showMap && locationType == 'route'"  [fullscreenControl]='true' [latitude]="lat" [longitude]="lng"
[streetViewControl]="false" [zoomControl]="true" [fullscreenControlOptions]="mapOptions.positionOfActions"
[zoomControlOptions]="mapOptions.positionOfActions" [styles]="mapOptions.styles" [mapTypeControl]='false'
[minZoom]="2" [maxZoom]="20" [zoom]="zoom" [fitBounds]="true" >
<agm-direction
*ngFor="let dir of routeDirections"
[renderOptions]="options"
[origin]="dir.origin"
[destination]="dir.destination"
[visible]="dir.visible"
>
</agm-direction>
<agm-polyline  *ngFor="let dir of lineDirections" [geodesic]="dir.travelMode=='Air'"  [strokeWeight]="3" [strokeColor]="'#0099dc'">
<agm-polyline-point  [latitude]="dir.origin.lat" [longitude]="dir.origin.lng">
</agm-polyline-point>
<agm-polyline-point  [latitude]="dir.destination.lat" [longitude]="dir.destination.lng">
</agm-polyline-point>
</agm-polyline>
<agm-marker *ngFor="let coordinate of pointDirections" [latitude]="coordinate.lat" [longitude]="coordinate.lng"
[iconUrl]="coordinate.iconUrl" [agmFitBounds]="true">
</agm-marker>
</agm-map>

<div *ngIf="markerType == 'polygon'" class="refresh-polygon" (click)="clearPolygons()">
  <i class="fa fa-refresh text-lg" title="refresh Polygon"></i>
</div>
<div *ngIf="showMap && locationType != 'Location' && selectedTabIndex == 0" class="close-map" (click)="showMap = !showMap">
  <i class="fa fa-close text-lg" title="close map"></i>
</div>
<cl-side-panel>

    <section class="cl-side-panel-title side-panel-header-bg">
        <span *ngIf="mode === 'edit'" class="black font-bold"
    >Edit Location</span
  >
  <span *ngIf="mode === 'add'" class="black font-bold"
    >New Location</span
  >
    </section>

    <section class="cl-side-panel-content" *ngIf="mode === 'add' || mode === 'edit'">
      <div *ngIf="locationType != 'Location'" class="pl-4 pt-2 field-sticky sticky-header">
        <span><span *ngIf="mode == 'add'" class="black font-bold text-lg mr-2 pointer color4" (click)="goBack()"><i class="fa fa-chevron-circle-left"></i></span><label class="black text-sm">{{mode == 'edit'?'Edit':'New'}} {{ locationType }}</label></span>
      </div>

      <container-element [ngSwitch]="locationType">
        <div *ngSwitchCase="'Location'" class="ml-4 mt-2">
          <label class="black text-sm" id="location-radio-group-label">Select Location Type</label>
          <mat-select class="basic-input" id="areatype" disableOptionCentering
          placeholder="Select Location Type*"
          [(ngModel)]="selectedLocation">
          <ng-container>
            <mat-option *ngFor="let locationType of locationTypes.slice(1)" [value]="locationType">{{locationType.name}}</mat-option>
          </ng-container>
        </mat-select>
        <span *ngIf="isCatalogTypeError" class="text-danger">Please Select Location Type</span>
        </div>
        <div *ngSwitchCase="'area'">
          <cl-location-area [objectType]='selectedLocation.objectType'[orgList]="orgList" [isOrgRestrictedUser]="isOrgRestrictedUser" (closePanel)="closePanel()" (selectedPosition)="selectedPosition($event)" (markerChanged)="onMarkerChanged($event)" (emitSelectedRadius)="getSelectedRadius($event)" (emitSelectedPolygonPoints)="getSelectedPolygon($event)" [changedRadius]="changedRadius" [polygonPts]="polygonPts" [zoom]="zoom" [mode]="mode" [editArea]="editLocation" [mapClickedPosition]="mapClickedPosition" (emitTabIndex)="getSelectedTabIndex($event)" (emitFormData)="getUploadFormData($event)" [bulkObj]="bulkAreas"></cl-location-area>
         </div>
         <div *ngSwitchCase="'site'">
           <cl-location-site [objectType]='selectedLocation.objectType' [orgList]="orgList" [isOrgRestrictedUser]="isOrgRestrictedUser" (closePanel)="closePanel()" (selectedPosition)="selectedPosition($event)" (markerChanged)="onMarkerChanged($event)" (emitSelectedRadius)="getSelectedRadius($event)" (emitSelectedPolygonPoints)="getSelectedPolygon($event)" [changedRadius]="changedRadius" [polygonPts]="polygonPts" [zoom]="zoom" [mode]="mode" [editSite]="editLocation" [mapClickedPosition]="mapClickedPosition" (emitTabIndex)="getSelectedTabIndex($event)" (emitFormData)="getUploadFormData($event)" [bulkObj]="bulkSites"></cl-location-site>
         </div>
         <div *ngSwitchCase="'route'">
           <cl-location-route [objectType]='selectedLocation.objectType' [orgList]="orgList" [isOrgRestrictedUser]="isOrgRestrictedUser" [mode]="mode" [editRoute]="editLocation" (selectedRoute)="onSelectedRoute($event)"></cl-location-route>
         </div>
         <div *ngSwitchCase="'gatewayzone'">
           <cl-location-gateway-zone [objectType]='selectedLocation.objectType' [orgList]="orgList" [isOrgRestrictedUser]="isOrgRestrictedUser" (closePanel)="closePanel()" (selectedPosition)="selectedPosition($event)" (markerChanged)="onMarkerChanged($event)" (emitSelectedRadius)="getSelectedRadius($event)" (emitSelectedPolygonPoints)="getSelectedPolygon($event)"  [zoom]="zoom" [mode]="mode" [editGateway]="editLocation" [mapClickedPosition]="mapClickedPosition" (emitTabIndex)="getSelectedTabIndex($event)" (emitFormData)="getUploadFormData($event)" [bulkObj]="bulkGatewayZones"></cl-location-gateway-zone>
         </div>
        <cl-location-add-edit *ngSwitchDefault (closePanel)="closePanel()" (selectedPosition)="selectedPosition($event)" [zoom]="zoom" [mode]="mode" [editLocation]="editLocation" [mapClickedPosition]="mapClickedPosition" [catalogTypes]="catalogTypes" [locationType]="locationType"></cl-location-add-edit>
      </container-element>
     </section>
    <section
  class="cl-side-panel-footer bgcolor33 p-2"
  *ngIf="mode === 'edit' || mode === 'add'"
>
  <div class="flex">
    <div *ngIf="selectedTabIndex == 0 && !showMap && locationType != 'Location'" class="text-left"><i class="fa fa-map text-xl color4" title="show map" (click)="showMap = !showMap"></i></div>
    <div class="flex-col grow text-right">
      <a class="m-2" (click)="closePanel()" title="cancels the changes and closes the form">Cancel</a>
      <button
      *ngIf="locationType == 'Location'"
      class="panel-button white bgcolor4 ml-2"
      (click)="onNext()"
    >
    Next
    </button>
      <button
      *ngIf="locationType != 'Location'"
        class="panel-button white bgcolor4 ml-2"
        (click)="onSave()"
      >
        {{selectedTabIndex == 0?'Save':'Upload'}}
        <span *ngIf="this.isLoading" class="fa fa-circle-o-notch fa-pulse white"></span>
      </button>
    </div>
  </div>
</section>
</cl-side-panel>
</div>
