<div class="alerts-widget relative" style=" font-size: large;">
    <div *ngIf="criticalStatusCounts.UNREAD" class="alert-count flex items-center justify-center">{{criticalStatusCounts.UNREAD > 9 ? '9+' : criticalStatusCounts.UNREAD}}</div>
    <div *ngIf="warningStatusCounts.UNREAD" class="warning-count flex items-center justify-center">{{warningStatusCounts.UNREAD > 9 ? '9+' : warningStatusCounts.UNREAD}}</div>
    <div [matMenuTriggerFor]="alertsMenu" class="trigger-container menu-icon">
        <i class="fa fa-exclamation-triangle header-alerts-icon"></i>
    </div>
    <mat-menu #alertsMenu="matMenu" class="w-[350px] p-2 no-padded-menu">
        <div class="dropdown-arrow right"></div>
        <div class="w-100 relative">
            <div *ngIf="criticalStatusCounts.UNREAD" class="alert-count flex items-center justify-center">{{criticalStatusCounts.UNREAD > 9 ? '9+' : criticalStatusCounts.UNREAD}}</div>
            <div *ngIf="warningStatusCounts.UNREAD" class="warning-count flex items-center justify-center">{{warningStatusCounts.UNREAD > 9 ? '9+' : warningStatusCounts.UNREAD}}</div>
            <button-tab-group [(selectedIndex)]="tabSelectedIndex">
                <button-tab tabTitle="Critical" faIcon="fa fa-exclamation-triangle fa-lg">

                    <ng-container *ngIf="tabSelectedIndex === 0">
                        <div *ngIf="criticalNotifications.length <= 0" class="m-4">
                            <p class="align-center"><i class="fa fa-info-circle mr-2"></i>You have no new notifications to review</p>
                        </div>
                        <ul class="notification-list">
                            <li *ngFor="let notification of criticalNotifications">
                                <cl-notification-item [notification]="notification" (onCloseNotificationPanel)="updateNotifications($event)"></cl-notification-item>
                            </li>
                        </ul>
                    </ng-container>
                </button-tab>
                

                <button-tab tabTitle="Warning" faIcon="fa fa-exclamation fa-lg">
                    <ng-container *ngIf="tabSelectedIndex === 1">
                        <div *ngIf="warningNotifications.length <= 0" class="m-4">
                            <p class="align-center"><i class="fa fa-info-circle mr-2"></i>You have no new notifications to review</p>
                        </div>
                        <ul class="notification-list">
                            <li *ngFor="let notification of warningNotifications">
                                <cl-notification-item [notification]="notification" (onCloseNotificationPanel)="updateNotifications($event)"></cl-notification-item>
                            </li>
                        </ul>
                    </ng-container>
                </button-tab>
            </button-tab-group>
        </div>
        <div class="p-3 align-center bt foz14">
            <a [routerLink]="['/notifications', tabSelectedIndex === 0 ? 'EXCURSION' : 'CRITICAL']">
                My Notifications
            </a>
        </div>
    </mat-menu>
</div>
