import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss']
})
export class ListCardComponent implements OnInit, OnChanges {
  loadingStats = false;
  gotData = false;

  @Input() data;
  // @Input() tenancyData;

  constructor() { }

  ngOnInit() {
  }
  ngOnChanges() {
  }
}
