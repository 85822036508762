<agm-map
  [disableDefaultUI]="true"
  [zoomControl]="true"
  (mapReady)="mapReady($event)"
  [latitude]="+currentCenter?.lat"
  [longitude]="+currentCenter?.lng"
  [controlSize]="26"
  [clickableIcons]="false"
  [backgroundColor]="'#abdbfe'"
  [minZoom]="1"
  [maxZoom]="mapZoom?.max || 14"
  [zoom]="2">
  <!-- [zoom]="mapZoom?.default || 6"> -->

  <app-server-clustered-map-marker *ngFor="let cluster of clusters"
    [cluster]="cluster"
    (clusterClicked)="zoomInToCluster(cluster)">
  </app-server-clustered-map-marker>
</agm-map>
