import { LatLngBounds } from "@agm/core";
import { Injectable } from "@angular/core";
import { EsGeoBoundRequest, NodeGroup } from "@cl/models";
import * as _ from "lodash";
import * as mapFilters from "../../common/json/mapFilters.json";
import { MapService } from "./map.service";

@Injectable({
  providedIn: "root",
})
export class MapFilterService {
  entityTypes;
  templateFilters = {};
  mainFilters = {};
  allFilters = {};
  noSubFiltersSelected = true;
  selectedSubFilters = {};
  selectedTargetedFilters = {};
  filterLocUnion = false;
  filterShipmentUnion = false;
  targetedFilter = "";
  counters = {};
  constructor(private _mapService: MapService) {}

  parseLabel(key) {
    const parseObj = {
      type: "category",
      cid: "alert type",
    };
    let label = parseObj[key] || key;
    return _.capitalize(label);
  }
  formatBoundsForSearch(mapBounds: LatLngBounds): EsGeoBoundRequest {
    const ne = mapBounds.getNorthEast();
    const sw = mapBounds.getSouthWest();
    const nw = new google.maps.LatLng(ne.lat(), sw.lng());
    const se = new google.maps.LatLng(sw.lat(), ne.lng());
    
    let finalNw = nw.lat();
    let finalSe = se.lat();
    if(finalNw == finalSe){
      finalNw = finalNw + 10;
    }
    return {
      topLeftLat:finalNw,
      topLeftLon: nw.lng(),
      bottomRightLat: finalSe,
      bottomRightLon: se.lng(),
    };
  }

  getMapGroupsEs(data, clientMapGroups) {
    let mgJson = (mapFilters as any).default;
    let mapGroups = mgJson.slice();
    const dataObj = {};
    data.forEach((dg) => {
      dataObj[dg.class] = dg;
    });
    return mapGroups
      .filter((mg) => {
        return clientMapGroups[mg.class];
      })
      .map((mg) => {
        return {
          ...mg,
          filters: dataObj[mg.class] && dataObj[mg.class].filters ? dataObj[mg.class].filters : [],
          count: dataObj[mg.class] && dataObj[mg.class].count ? dataObj[mg.class].count : 0,
        };
      });
  }
  // This was for parsing MapGroups from graph API,
  // still used for mapping admin... to be deprecated
  getMapGroups(data, clientMapGroups) {
    let mapGroups = [],
      dataObj = {},
      j;
    if (!data || !data.length) {
      return [];
    }

    let mgJson = (mapFilters as any).default;
    mapGroups = mgJson.slice();

    dataObj = {};
    data.forEach((item) => {
      if (item.baseType) {
        dataObj[item.baseType] = dataObj[item.baseType] || [];
        dataObj[item.baseType].push(item);
      }
    });
    mapGroups.forEach((mg, i) => {
      mapGroups[i].filters = [];

      mapGroups[i].count = 0;
      if (dataObj[mg.class]) {
        dataObj[mg.class].forEach((dat) => {
          mapGroups[i].count += dat.count ? dat.count : 0;
          j = 0;
          _.forOwn(dat.filters, (valsObj, key) => {
            mapGroups[i].filters[j] = mapGroups[i].filters[j] || {};
            mapGroups[i].filters[j].list = mapGroups[i].filters[j].list || [];
            mapGroups[i].filters[j].filterType = key.split(".")[1];
            mapGroups[i].filters[j].label = this.parseLabel(mapGroups[i].filters[j].filterType);
            _.forOwn(valsObj, (val, name) => {
              mapGroups[i].filterType = key.split(".")[1];
              mapGroups[i].filters[j].list.push({
                name: name,
                checked: false,
                count: typeof val === "number" ? val : val.count,
              });
              if (typeof val !== "number") {
                _.forOwn(val, (val, name, h) => {
                  mapGroups[i].filters[j].list.subFilters = mapGroups[i].filters[j].list.subFilters || [];
                  mapGroups[i].filters[j].list.subFilters.push({
                    name: name,
                    checked: false,
                    count: val,
                  });
                });
              }
            });
            j++;
          });
        });
      }
      mapGroups[i].count += "";
    });
    return mapGroups;
  }
  clearCounts(mapGroups) {
    mapGroups.forEach((mg, i) => {
      mapGroups[i].filters = [];
      mapGroups[i].count = 0;
    });
    return mapGroups;
  }
  getFilters() {
    return this.mainFilters;
  }
  getSearchFields(mapGroups, selectedUsers = {}, selectedLocations = {}, queryOperator: "should" | "must" = "should") {
    let searchFields = [],
      tags = [];
    mapGroups.forEach((mg, i) => {
      let noneChecked: boolean;
      if (mg.enabled) {
        searchFields.push({
          fieldName: "baseClass",
          queryText: _.capitalize(mg.class),
          queryOperator: queryOperator,
          match: "must",
          childFilters: [],
        });
      }
      if (mg.enabled && mg.filters && mg.filters.length) {
        noneChecked = this.areNoneChecked(mg.filters);
        mg.filters.forEach((filt) => {
          if (Array.isArray(filt.list)) {
            // noneChecked = this.areNoneChecked(filt.list)
            let filtString = "",
              skuString = "",
              skuGroups = [];
            filt.list.forEach((cat) => {
              if (cat.checked) {
                filtString += filtString.length ? "," + cat.name : cat.name;
                tags.push({
                  name: cat.name,
                  mapGroup: mg.name,
                  class: mg.class,
                });
              }
              if (cat.subList && cat.subList.length) {
                skuString = "";
                cat.subList.forEach((subFilt) => {
                  if (subFilt.checked) {
                    skuString += skuString.length ? "," + subFilt.name : subFilt.name;
                    tags.push({
                      parentTag: cat.name,
                      name: subFilt.name,
                      mapGroup: mg.name,
                      class: mg.class,
                    });
                  }
                });
                if (skuString) {
                  skuGroups.push({
                    parent: cat.name,
                    skuString: skuString,
                  });
                }
              }
            });
            if (skuGroups.length) {
              skuGroups.forEach((sg) => {
                searchFields[i].childFilters.push({
                  fieldName: filt.filterType || mg.filterType,
                  queryText: sg.parent,
                  queryOperator: "should",
                  childFilters: [
                    {
                      fieldName: "sku",
                      queryOperator: "should",
                      queryText: sg.skuString,
                    },
                  ],
                });
                filtString = this.removeFromString(filtString, sg.parent);
              });
            }
            let numFilts = filtString.split(",").length;
            let filtername = filt.filterType || mg.filterType;
            if (filtString && numFilts) {
              searchFields[i].childFilters.push({
                fieldName: filt.filterType || mg.filterType,
                queryText: filtString,
                queryOperator: filtername == "type" ? "should" : "must",
              });
            }
          }
        });
      }
      if (mg.enabled && mg.class === "users" && _.size(selectedUsers)) {
        let userString = "";
        _.forOwn(selectedUsers, (userObj, userName) => {
          if (userObj) {
            userString += userString.length ? "," + userName : userName;
            tags.push({
              name: userObj["name"],
              id: userObj["id"],
              mapGroup: mg.name,
              class: mg.class,
            });
          }
        });
        searchFields[i].childFilters.push({
          fieldName: "toParty",
          queryText: userString,
          queryOperator: "must",
        });
      } else if (mg.enabled && mg.class === "location" && _.size(selectedLocations)) {
        let locString = "";
        _.forOwn(selectedLocations, (locObj, loc) => {
          if (locObj) {
            locString += locString.length ? "," + loc : loc;
            tags.push({
              name: locObj["name"],
              id: locObj["properties"]["id"],
              mapGroup: mg.name,
              class: mg.class,
            });
          }
        });

        searchFields[i].childFilters.push({
          fieldName: "locationId",
          queryText: locString,
          queryOperator: "must",
        });
      }
    });
    return { searchFields, tags };
  }
  removeFromString(filtString, toRemove) {
    let filtArray = filtString.split(",");
    return filtArray
      .filter((el) => {
        return el !== toRemove;
      })
      .join(",");
  }
  areNoneChecked(filters) {
    let isChecked = false;
    filters.forEach((filt) => {
      if (filt.list && filt.list.length) {
        filt.list.forEach((item) => {
          if (item.checked) {
            isChecked = true;
          }
        });
      }
    });

    return isChecked ? false : true;
  }
  setFilterObjects(mapGroups) {
    this.targetedFilter = "";
    let numSelected = 0,
      token,
      addSubFilters;
    this.noSubFiltersSelected = true;
    this.selectedSubFilters = {};
    this.selectedTargetedFilters = {};
    mapGroups = mapGroups.map((mg) => {
      let noneSelected = true; //(this.noneSelected(mg.selectedFilters)) ? true : false;
      // if (!mg.noneSelected) {
      //   this.noSubFiltersSelected = false;
      // }
      if (mg.enabled) {
        numSelected++;
      }
      if (mg.targeted) {
        this.targetedFilter = mg.class;
      }
      this.templateFilters[mg.class] = mg.enabled;
      this.allFilters[mg.class] = this.allFilters[mg.class] || {};
      if (mg.filters && mg.filters.length) {
        mg.filters.forEach((filt) => {
          this.allFilters[mg.class].filterType = mg.filterType;
          this.allFilters[mg.class].noneSelected = mg.noneSelected;
          addSubFilters = mg.enabled && !mg.noneSelected ? true : false;
          filt.list.forEach((item) => {
            token = item.name;
            this.allFilters[mg.class][token] = item.checked && mg.enabled ? true : false;
            if (item.checked) {
              this.noSubFiltersSelected = false;
              noneSelected = false;
              if (mg.targeted) {
                this.selectedTargetedFilters[item.name] = mg.class;
              } else {
                this.selectedSubFilters[item.name] = mg.class;
              }
            }
          });
          this.allFilters[mg.class].noneSelected = noneSelected;
        });
      }
      if (mg.class === "location" && mg.targeted) {
        this.filterLocUnion = !mg.noneSelected && mg.enabled ? true : false;
      }
      if (mg.class === "shipment") {
        this.filterShipmentUnion = !mg.noneSelected && mg.enabled ? true : false;
      }
      return { ...mg, noneSelected };
    });
    // console.log('selectedSubFilters', this.selectedSubFilters);
    // console.log('selectedTargetedFilters', this.selectedTargetedFilters);
    // console.log('this.filterShipmentUnion', this.filterShipmentUnion);
    // console.log('this.filterLocUnion', this.filterLocUnion);
  }

  filterNodesByTerm(nodes, searchTerm) {
    let isFiltered,
      coords,
      mapBounds = new google.maps.LatLngBounds();

    let markers = nodes.filter((marker) => {
      isFiltered = !searchTerm || (marker.name && marker.name.toLowerCase().indexOf(searchTerm) > -1) ? true : false;

      if (isFiltered && this._mapService.validateCoords(marker)) {
        coords = new google.maps.LatLng(marker.locus.lat, marker.locus.lon);
        mapBounds.extend(coords);
      }
      return isFiltered;
    });

    return [markers, mapBounds];
  }
  filterSearchList(fullList, searchTerm, filtersObj, showAll) {
    let resultList,
      lTerm,
      mapBounds = new google.maps.LatLngBounds();
    const noSelections = this.noneSelected(filtersObj);

    let termFiltered = true,
      typeFiltered = true,
      locusFiltered,
      coords;

    if (!fullList && !fullList.length) {
      console.warn("list missing!", fullList);
      return [[], mapBounds];
    }
    resultList = fullList.filter((item) => {
      lTerm = searchTerm.toLowerCase();
      if (searchTerm) {
        termFiltered = item.name && item.name.toLowerCase().indexOf(lTerm) > -1 ? true : false;
      }
      if (noSelections) {
        typeFiltered = true;
      } else {
        typeFiltered = filtersObj[item.category] ? true : false;
      }
      locusFiltered = item.locus && item.locus.lat && item.locus.lon && item.locus.lat !== -360 ? true : false;

      if (showAll || (this._mapService.validateCoords(item) && termFiltered && typeFiltered)) {
        coords = new google.maps.LatLng(item.locus.lat, item.locus.lon);
        mapBounds.extend(coords);
      }
      return locusFiltered && termFiltered && typeFiltered ? true : false;
    });
    return [resultList, mapBounds];
  }

  initCounters() {
    return {
      incident: 0,
      incident_subfilters: {},
      inventory: 0,
      inventory_subfilters: {},
      shipment: 0,
      shipment_subfilters: {},
      location: 0,
      location_subfilters: {},
      asset: 0,
      asset_subfilters: {},
    };
  }

  filterMapNodes(mapGroups, allNodes, nodesByType, isMapMode, activeSelected, graphData, showAllLocations, individualAssets, searchTerm) {
    let resultList = <any>[];
    this.setFilterObjects(mapGroups);
    this.counters = this.initCounters();
    this.mainFilters = Object.assign({}, this.templateFilters, {
      targeted: this.targetedFilter,
    });
    // console.log('this.allFilters', this.allFilters);
    // console.log(' this.templateFilters', this.templateFilters);
    // console.log('this.targetedFilter', this.targetedFilter);
    // console.log('this.noSubFiltersSelected', this.noSubFiltersSelected);
    // if (!this.targetedFilter && this.allEnabled(mapGroups) && this.noSubFiltersSelected) {
    //   return [allNodes, this.counters, this.targetedFilter, this.mainFilters];
    // }
    // if (individualAssets) {
    //   resultList = this.filterAssets(allNodes, nodesByType, graphData);
    // } else
    if (this.targetedFilter) {
      allNodes = nodesByType[this.targetedFilter];
      resultList = this.filterTargeted(allNodes, nodesByType, graphData);
    } else {
      resultList = this.filterForEs(allNodes);
      // resultList = this.filterAll(allNodes, showAllLocations);
    }
    if (!this.allFilters["location"].noneSelected) {
      resultList = this.filterByLocs(resultList);
    }
    resultList = resultList.map((nodeObj) => {
      nodeObj = this.setFilteredAlerts(nodeObj);
      return nodeObj;
    });
    // In canvas mode, add nodes for attached
    if (!isMapMode && !activeSelected) {
      resultList = this.addAttachedNodes(resultList, graphData, mapGroups);
    }
    // console.log('this.mapGroups', mapGroups);
    return [resultList, this.counters, this.targetedFilter, this.mainFilters];
  }
  filterByLocs(resultList) {
    let locObj = {};
    resultList.forEach((el) => {
      if (el.nodeClass === "location") {
        locObj[el.uid] = true;
      }
    });
    return resultList.filter((el) => {
      if (el.nodeClass === "location" || locObj[el.properties.locationId] || locObj[el.properties.at]) {
        return true;
      }
      if (
        el.nodeClass === "shipment" &&
        (locObj[el.properties.shipment_consignee_receiver] || locObj[el.properties.shipment_consignee_supplier])
      ) {
        return true;
      }

      return false;
    });
  }
  addAttachedNodes(resultList, graphData, mapGroups) {
    const subNodes = [],
      tokens = [];
    let uid, token;
    mapGroups.forEach((mg) => {
      if (mg.enabled && !mg.targeted) {
        token = mg.class === "incident" ? "alertsObj" : mg.class + "Obj";
        tokens.push(token);
      }
    });
    resultList.forEach((node) => {
      tokens.forEach((token) => {
        if (node[token] && node[token].list) {
          node[token].list.forEach((item) => {
            uid = item.uid || item.id;
            if (graphData.nodesObj[uid] && this.filterAttachedList(graphData.nodesObj[uid])) {
              subNodes.push(graphData.nodesObj[uid]);
            }
          });
        }
      });
    });
    return [...resultList, ...subNodes];
  }
  filterAttachedList(nodeObj) {
    let isIncluded = false,
      hasSelection = false;
    _.forOwn(this.selectedSubFilters, (val, key) => {
      if (val === nodeObj.nodeClass) {
        hasSelection = true;
        if (
          (nodeObj.category && this.selectedSubFilters[nodeObj.category]) ||
          (nodeObj.properties.cid && this.selectedSubFilters[nodeObj.properties.cid])
        ) {
          isIncluded = true;
        }
      }
    });

    return !hasSelection || isIncluded ? true : false;
  }
  getNumSelected() {
    let count = 0;
    _.forOwn(this.templateFilters, (val, key) => {
      if (val) {
        count++;
      }
    });
    return count;
  }
  addShipments(nodes, addedTypes, graphData) {
    let addedNodes = {};
    if (!this.mainFilters["shipment"]) {
      return [[], addedTypes];
    }
    if (this.targetedFilter === "location") {
      nodes.forEach((node) => {
        if (node.shipmentOrigins && node.shipmentOrigins.length) {
          node.shipmentOrigins.forEach((org) => {
            addedNodes[org.uid] = org;
          });
        }
        if (node.shipmentDestinations && node.shipmentDestinations.length) {
          node.shipmentDestinations.forEach((org) => {
            addedNodes[org.uid] = org;
          });
        }
      });
    } else if (this.targetedFilter === "inventory") {
      nodes.forEach((node) => {
        if (
          node.properties.at &&
          graphData.nodesObj[node.properties.at] &&
          graphData.nodesObj[node.properties.at].nodeClass === "shipment"
        ) {
          addedNodes[node.properties.at] = graphData.nodesObj[node.properties.at];
        }
      });
    } else if (this.targetedFilter === "incident") {
      nodes.forEach((node) => {
        if (
          node.properties.fromId &&
          graphData.nodesObj[node.properties.fromId] &&
          graphData.nodesObj[node.properties.fromId].nodeClass === "shipment"
        ) {
          addedNodes[node.properties.fromId] = graphData.nodesObj[node.properties.fromId];
        }
      });
    }
    let resList = Object.values(addedNodes);
    if (resList && resList.length) {
      let subFiltered = this.filterGroupBySubFilters(resList, this.selectedSubFilters, false);
      addedTypes["shipment"] = true;
      return [subFiltered, addedTypes];
    } else {
      return [[], addedTypes];
    }
  }
  addLocations(nodes, addedTypes, graphData) {
    let addedNodes = {};
    if (!this.mainFilters["location"]) {
      return [[], addedTypes];
    }

    if (this.targetedFilter === "shipment") {
      nodes.forEach((node) => {
        if (
          node.properties.shipment_consignee_receiver &&
          graphData.nodesObj[node.properties.shipment_consignee_receiver] &&
          graphData.nodesObj[node.properties.shipment_consignee_receiver].nodeClass === "location"
        ) {
          addedNodes[node.properties.shipment_consignee_receiver] = graphData.nodesObj[node.properties.shipment_consignee_receiver];
        }
        if (
          node.properties.shipment_consignee_supplier &&
          graphData.nodesObj[node.properties.shipment_consignee_supplier] &&
          graphData.nodesObj[node.properties.shipment_consignee_supplier].nodeClass === "location"
        ) {
          addedNodes[node.properties.shipment_consignee_supplier] = graphData.nodesObj[node.properties.shipment_consignee_supplier];
        }
      });
    } else if (this.targetedFilter === "inventory") {
      nodes.forEach((node) => {
        if (
          node.properties.at &&
          graphData.nodesObj[node.properties.at] &&
          graphData.nodesObj[node.properties.at].nodeClass === "location"
        ) {
          addedNodes[node.properties.at] = graphData.nodesObj[node.properties.at];
        }
      });
    } else if (this.targetedFilter === "incident") {
      nodes.forEach((node) => {
        if (
          node.properties.fromId &&
          graphData.nodesObj[node.properties.fromId] &&
          graphData.nodesObj[node.properties.fromId].nodeClass === "location"
        ) {
          addedNodes[node.properties.fromId] = graphData.nodesObj[node.properties.fromId];
        }
      });
    } else if (this.targetedFilter === "asset") {
      nodes.forEach((node) => {
        if (
          node.properties.locationId &&
          graphData.nodesObj[node.properties.locationId] &&
          graphData.nodesObj[node.properties.locationId].nodeClass === "location"
        ) {
          addedNodes[node.properties.locationId] = graphData.nodesObj[node.properties.locationId];
        }
      });
    }

    // console.log('addedNodes', addedNodes);
    let resList = Object.values(addedNodes);
    if (resList && resList.length) {
      let subFiltered = this.filterGroupBySubFilters(resList, this.selectedSubFilters, false);
      addedTypes["location"] = true;
      return [subFiltered, addedTypes];
    } else {
      return [[], addedTypes];
    }
  }
  addInventory(nodes, addedTypes, graphData) {
    let addedNodes = {};
    if (!this.mainFilters["inventory"]) {
      return [[], addedTypes];
    }

    if (this.targetedFilter === "incident") {
      nodes.forEach((node) => {
        if (
          node.properties.at &&
          graphData.nodesObj[node.properties.at] &&
          graphData.nodesObj[node.properties.at].nodeClass === "inventory"
        ) {
          addedNodes[node.properties.at] = graphData.nodesObj[node.properties.at];
        }
      });
    }

    // console.log('addedNodes', addedNodes);
    let resList = Object.values(addedNodes);
    if (resList && resList.length) {
      let subFiltered = this.filterGroupBySubFilters(resList, this.selectedSubFilters, false);
      addedTypes["inventory"] = true;
      return [subFiltered, addedTypes];
    } else {
      return [[], addedTypes];
    }
  }
  filterTargeted(allTargetedNodes, nodesByType, graphData) {
    let resultList = [],
      isIncluded = true,
      addedTypes = {},
      subList = [];
    const subFiltersSelected = _.size(this.selectedSubFilters);
    const numSelected = this.getNumSelected();
    if (!allTargetedNodes || !allTargetedNodes.length) {
      return [];
    }
    resultList = this.filterGroupBySubFilters(allTargetedNodes, this.selectedTargetedFilters, true);
    [subList, addedTypes] = this.addShipments(resultList, addedTypes, graphData);
    resultList = resultList.concat(subList);
    [subList, addedTypes] = this.addLocations(resultList, addedTypes, graphData);
    resultList = resultList.concat(subList);
    [subList, addedTypes] = this.addInventory(resultList, addedTypes, graphData);
    resultList = resultList.concat(subList);
    resultList.forEach((nodeObj) => {
      nodeObj = this.setAttachedMarkers(nodeObj);
      // Update targeted node counters
      if (nodeObj.nodeClass === "inventory") {
        this.counters[nodeObj.nodeClass] += +nodeObj.properties.quantity;
      } else {
        this.counters[nodeObj.nodeClass]++;
      }
      // Update attached item counters
      if (this.targetedFilter !== "incident" && nodeObj.alertsObj) {
        // console.log("attached alert", nodeObj, nodeObj.alertsObj);
        this.counters["incident"] += this.countAttachedItems(nodeObj, "incident", "alertsObj", "filterCounts");
        this.addAttachedFilters(nodeObj, "incident", "alertsObj", "filterCounts", numSelected);
      }
      if (this.targetedFilter !== "inventory" && nodeObj.inventoryObj) {
        this.counters["inventory"] += this.countAttachedItems(nodeObj, "inventory", "inventoryObj", "typeCounts");
        this.addAttachedFilters(nodeObj, "inventory", "inventoryObj", "typeCounts", numSelected);
      }
      if (this.targetedFilter !== "asset" && nodeObj.assetObj) {
        // this.counters['asset'] += this.countAttachedItems(nodeObj, 'asset', 'assetObj', 'typeCounts');
        this.counters["asset"] += nodeObj.assetObj.count;
        this.addAttachedFilters(nodeObj, "asset", "assetObj", "typeCounts", numSelected);
      }
      if (this.targetedFilter !== "location" && nodeObj.locationObj) {
        this.counters["location"] += this.countAttachedItems(nodeObj, "location", "locationObj", "typeCounts");
        this.addAttachedFilters(nodeObj, "location", "locationObj", "typeCounts", numSelected);
      }
      if (this.targetedFilter !== "shipment" && nodeObj.shipmentObj) {
        this.counters["shipment"] += this.countAttachedItems(nodeObj, "shipment", "shipmentObj", "typeCounts");
        this.addAttachedFilters(nodeObj, "shipment", "shipmentObj", "typeCounts", numSelected);
      }
      if (subFiltersSelected && this.targetedFilter !== nodeObj.nodeClass) {
        this.addSubfilterCounts(nodeObj);
      }
    });
    if (subFiltersSelected) {
      resultList = resultList.filter((nodeObj) => {
        return this.targetedFilter === nodeObj.nodeClass || addedTypes[nodeObj.nodeClass] || this.checkSubFilters(nodeObj);
      });
    }
    return resultList;
  }

  isSubFilteredNode(nodeObj, selectedFilters) {
    // TODO: reduce selected filters to true set before looping
    let isIncluded = false;
    _.forOwn(selectedFilters, (val, key) => {
      if (
        val &&
        ((nodeObj.properties && key === nodeObj.properties.cid) ||
          (nodeObj.properties && key === nodeObj.properties.type) ||
          (nodeObj.properties && key === nodeObj.properties.state) ||
          (nodeObj.properties && key === nodeObj.properties.status) ||
          key === nodeObj.name)
      ) {
        isIncluded = true;
      }
    });
    return isIncluded;
  }
  filterGroupBySubFilters(allNodes, selectedFilters, addCounts) {
    let isIncluded = false;
    if (!_.size(selectedFilters)) {
      if (addCounts) {
        allNodes.forEach((nodeObj) => {
          this.addSubfilterCounts(nodeObj);
        });
      }
      return allNodes;
    } else {
      return allNodes.filter((nodeObj) => {
        if (addCounts) {
          this.addSubfilterCounts(nodeObj);
        }
        isIncluded = false;
        _.forOwn(selectedFilters, (val, key) => {
          if (
            nodeObj.nodeClass === val &&
            ((nodeObj.properties && key === nodeObj.properties.cid) ||
              (nodeObj.properties && key === nodeObj.properties.type) ||
              (nodeObj.properties && key === nodeObj.properties.state) ||
              (nodeObj.properties && key === nodeObj.properties.status) ||
              key === nodeObj.name)
          ) {
            isIncluded = true;
          }
        });
        return isIncluded;
      });
    }
  }
  ugger;

  setFilteredAlerts(nodeObj) {
    if (nodeObj.alertsObj && this.allFilters["incident"]) {
      nodeObj.alertsObj.filteredSubCounts = {};
      nodeObj.alertsObj.noneSelected = this.allFilters["incident"].noneSelected;
      _.forOwn(nodeObj.alertsObj.filterCounts, (val, key) => {
        if (this.allFilters["incident"][key]) {
          nodeObj.alertsObj.filteredSubCounts[key] = val;
        }
      });
    }
    return nodeObj;
  }
  addSubfilterCounts(nodeObj) {
    // TODO adjust for multi faceted search
    const mainType = nodeObj.nodeClass;
    // let keys =  ['type', 'state', ]
    let key = nodeObj.properties.type;
    if (mainType === "shipment") {
      key = nodeObj.properties.status;
    } else if (mainType === "incident") {
      key = nodeObj.properties.cid;
    }
    this.counters[mainType + "_subfilters"][key] = this.counters[mainType + "_subfilters"][key] || 0;
    if (mainType === "inventory") {
      this.counters[mainType + "_subfilters"][key] += +nodeObj.properties.quantity;
    } else {
      this.counters[mainType + "_subfilters"][key]++;
    }
  }
  addAttachedFilters(nodeObj, mainType, counterObj, filterCountObj, numSelected) {
    const mainKey = mainType + "_subfilters";
    if (numSelected < 2) {
      return;
    }
    _.forOwn(nodeObj[counterObj][filterCountObj], (val, key) => {
      if (val) {
        this.counters[mainKey][key] = this.counters[mainKey][key] ? this.counters[mainKey][key] + val : val;
      }
    });
  }
  // Add subcounts to set main counter for type
  countAttachedItems(nodeObj, mainType, counterObj, filterCountObj) {
    if (!this.allFilters[mainType]) {
      //
      return nodeObj[counterObj].count;
    }
    if (this.allFilters[mainType].noneSelected) {
      return nodeObj[counterObj].count;
    } else if (nodeObj[counterObj][filterCountObj]) {
      let count = 0;
      _.forOwn(this.allFilters[mainType], (val, key) => {
        if (val) {
          count += nodeObj[counterObj][filterCountObj][key] ? nodeObj[counterObj][filterCountObj][key] : 0;
        }
      });
      return count;
    }
    return 0;
  }

  setAttachedMarkers(nodeObj) {
    if (nodeObj.inventoryObj) {
      nodeObj.inventoryObj.show =
        nodeObj.nodeClass !== "inventory" && this.templateFilters["inventory"] && this.targetedFilter !== "inventory" ? true : false;
    }
    if (nodeObj.alertsObj) {
      nodeObj.alertsObj.show =
        nodeObj.nodeClass !== "incident" && this.templateFilters["incident"] && this.targetedFilter !== "incident" ? true : false;
    }
    if (nodeObj.assetObj) {
      nodeObj.assetObj.show =
        nodeObj.nodeClass !== "asset" && this.templateFilters["asset"] && this.targetedFilter !== "asset" ? true : false;
    }
    if (nodeObj.locationObj) {
      nodeObj.locationObj.show =
        nodeObj.nodeClass !== "location" && this.templateFilters["location"] && this.targetedFilter !== "location" ? true : false;
    }
    if (nodeObj.shipmentObj) {
      nodeObj.shipmentObj.show =
        nodeObj.nodeClass !== "shipment" && this.templateFilters["shipment"] && this.targetedFilter !== "shipment" ? true : false;
    }
    return nodeObj;
  }
  checkSubFilters(nodeObj) {
    let isIncluded = true;
    _.forOwn(this.selectedSubFilters, (val, key) => {
      if (val === "incident") {
        isIncluded = nodeObj.alertsObj && this.hasFilterObj(nodeObj.alertsObj.filterCounts) ? true : false;
      } else if (val === "inventory") {
        isIncluded = nodeObj.inventoryObj && this.hasFilterObj(nodeObj.inventoryObj.typeCounts) ? true : false;
      } else if (val === "location") {
        isIncluded = nodeObj.locationObj && this.hasFilterObj(nodeObj.locationObj.typeCounts) ? true : false;
      } else if (val === "shipment") {
        isIncluded = nodeObj.shipmentObj && this.hasFilterObj(nodeObj.shipmentObj.typeCounts) ? true : false;
      } else if (val === "asset") {
        isIncluded = nodeObj.assetObj && this.hasFilterObj(nodeObj.assetObj.typeCounts) ? true : false;
      }
      if (!isIncluded) {
        return false;
      }
      return null;
    });

    return isIncluded;
  }

  hasFilterObj(filtersObj) {
    let found = false;
    _.forOwn(filtersObj, (val, key) => {
      if (this.selectedSubFilters[key]) {
        found = true;
      }
    });
    return found;
  }
  filterForEs(allNodes) {
    let resultList = [],
      isIncluded,
      total = 0;
    this.mainFilters = Object.assign({}, this.templateFilters, {
      targeted: this.targetedFilter,
    });
    resultList = allNodes.filter((nodeObj) => {
      isIncluded = false;
      nodeObj.mainFilters = this.mainFilters;
      if (nodeObj.nodeClass === "location" && nodeObj.assetObj && nodeObj.assetObj.count && nodeObj.assetObj.show) {
        isIncluded = true;
        total += nodeObj.assetObj.count;
      }
      return isIncluded;
    });
    // console.log("total", total);

    return resultList;
  }
  filterAll(allNodes, showAllLocations) {
    let resultList = [],
      numIncidents = 0,
      isIncluded;
    // Save these values for filter loop
    const showInventory = this.templateFilters["inventory"];
    let token = "token",
      mainToken = "incident";
    this.mainFilters = Object.assign({}, this.templateFilters, {
      targeted: this.targetedFilter,
    });
    resultList = allNodes.filter((nodeObj) => {
      isIncluded = false;

      // Set flags for using actual markers on these types if loc markers arent used
      if (nodeObj.nodeClass !== "inventory" && nodeObj.inventoryObj) {
        nodeObj.inventoryObj.show = false; //showInventory;  - hide for now
      }
      if (nodeObj.nodeClass !== "incident" && nodeObj.alertsObj) {
        nodeObj.alertsObj.show = !this.targetedFilter;
      }
      // if ((nodeObj.nodeClass !== 'asset') && nodeObj.assetObj) {
      //   nodeObj.assetObj.show = true;
      // }
      nodeObj.mainFilters = this.mainFilters;

      if (
        nodeObj.nodeClass === "location" &&
        !showAllLocations &&
        (!nodeObj.alertsObj || !nodeObj.alertsObj.count) &&
        (!nodeObj.assetObj || !nodeObj.assetObj.count) &&
        (!nodeObj.inventoryObj || !nodeObj.inventoryObj.count)
      ) {
        isIncluded = false;
      } else if (nodeObj.nodeClass === "incident" && !this.targetedFilter && this.templateFilters["incident"]) {
        // if (nodeObj.nodeClass === 'incident' && this.templateFilters['incident']) {
        token = nodeObj.category.split(":")[1];
        mainToken = nodeObj.category.split(":")[0];
        if (
          (this.mainTypeSelected(mainToken) || !showInventory) &&
          ((this.allFilters["incident"] && this.allFilters["incident"].noneSelected) || this.allFilters["incident"][token])
        ) {
          numIncidents++;
          isIncluded = true;
        } else {
          isIncluded = false;
        }
      } else if (nodeObj.nodeClass === "inventory" && this.templateFilters["inventory"]) {
        isIncluded =
          (showInventory && this.allFilters["inventory"] && this.allFilters["inventory"].noneSelected) ||
          (nodeObj.name && this.allFilters[nodeObj.nodeClass][nodeObj.name]) ||
          (nodeObj.category && this.allFilters["inventory"][nodeObj.category])
            ? true
            : false;
        numIncidents += isIncluded && nodeObj.alertsObj ? nodeObj.alertsObj.count : 0;
      } else if (
        (nodeObj.nodeClass === "asset" || nodeObj.nodeClass === "shipment" || nodeObj.nodeClass === "location") &&
        this.templateFilters[nodeObj.nodeClass]
      ) {
        isIncluded =
          (this.allFilters[nodeObj.nodeClass] && this.allFilters[nodeObj.nodeClass].noneSelected) ||
          this.isSubFilteredNode(nodeObj, this.allFilters[nodeObj.nodeClass])
            ? true
            : false;
        numIncidents += isIncluded && nodeObj.alertsObj ? nodeObj.alertsObj.count : 0;
      }
      // Filter by location subfilters

      if (this.filterLocUnion && nodeObj.sourceClass === "location") {
        isIncluded =
          isIncluded && (this.allFilters["location"][nodeObj.sourceType] || this.allFilters["location"][nodeObj.targetType]) ? true : false;
      }
      // Filter by shipment subfilters
      if (this.filterShipmentUnion && nodeObj.sourceClass === "shipment") {
        isIncluded = isIncluded && this.allFilters["shipment"][nodeObj.sourceStatus] ? true : false;
      }

      if (isIncluded) {
        if (nodeObj.nodeClass === "inventory") {
          this.counters[nodeObj.nodeClass] += +nodeObj.properties.quantity;
        } else {
          this.counters[nodeObj.nodeClass]++;
        }
      }

      return isIncluded;
    });
    this.counters["incident"] = numIncidents;
    // console.log('resultList', resultList);
    // console.log('this.filterLocUnion', this.filterLocUnion);
    return resultList;
  }

  mainTypeSelected(mainToken) {
    if (mainToken === "Inventory") {
      return this.templateFilters["inventory"] ? true : false;
    }
    return null
  }

  noneSelected(filters) {
    let hasSelection = false;
    _.forOwn(filters, (val, key) => {
      if (val) {
        hasSelection = true;
      }
    });
    return !hasSelection;
  }

  // setCount(mapGroups, mapGroupName, count) {
  //   return mapGroups.map(group => {
  //     if (group.name === mapGroupName) {
  //       group.count = count;
  //     }
  //     return group;
  //   })
  // }
  getCategoriesFromList(mapGroups, mapGroupName, items) {
    let categories = {},
      catList = [];
    // console.log('items', items);
    return mapGroups.map((group) => {
      if (group.name === mapGroupName) {
        items.forEach((item) => {
          if (categories[item.category]) {
            categories[item.category]++;
          }
          categories[item.category] = categories[item.category] || 1;
        });
        _.forOwn(categories, (val, key) => {
          catList.push({
            name: key,
            checked: false,
            count: val,
          });
        });
        group.filters.push({
          label: "Categories",
          list: catList,
        });
        group.selectedFilters = this.initFiltersObj(group.filters, {});
        group.count = items.length;
      }
      return group;
    });
  }
  initFiltersObj(filters, filtersObj) {
    filters.forEach((filterGroup, i) => {
      filterGroup.list.forEach((filt, j) => {
        filtersObj[filt.name] = false;
        filt.checked = false;
      });
    });
    return filtersObj;
  }
  toggleFilter(mapGroups, filterName, mapGroupName, checked) {
    mapGroups = mapGroups.map((group) => {
      if (group.name === mapGroupName) {
        group.selectedFilters[filterName] = !checked;
      }
      return group;
    });
    return mapGroups;
  }

  singleFilter(mgs, mapGroupName) {
    let mapGroups = _.cloneDeep(mgs);
    let setAll = false;

    mapGroups = mapGroups.map((group) => {
      group.enabled = setAll || group.name === mapGroupName ? true : false;
      group.targeted = setAll || group.name === mapGroupName ? true : false;
      return group;
    });
    return mapGroups;
  }

  toggleFilterType(mgs, mapGroupName) {
    let mapGroups = _.cloneDeep(mgs);
    let numToggled = 0;

    numToggled = 0;
    mapGroups = mapGroups.map((group) => {
      if (group.name === mapGroupName) {
        group.enabled = !group.enabled;
        if (!group.enabled) {
          group.targeted = false;
        }
      }
      numToggled += group.enabled ? 1 : 0;
      return group;
    });
    if (numToggled === 0) {
      mapGroups = mapGroups.map((group) => {
        group.enabled = true;
        return group;
      });
    }
    return mapGroups;
  }
  // Keeps track of checked filters for resetting in dom and keeps selected filter memos
  toggleFilterCategory(mgs, mapGroupName, category, subFilter) {
    let mapGroups = _.cloneDeep(mgs);
    mapGroups = mapGroups.map((mg) => {
      if (mg.name === mapGroupName && Array.isArray(mg.filters)) {
        mg.selectedFilters["count"] = 0;
        if (Array.isArray(mg.filters)) {
          mg.filters = mg.filters.map((filt) => {
            if (Array.isArray(filt.list)) {
              filt.list = filt.list.map((cat) => {
                if (!subFilter && cat.name === category) {
                  cat.checked = !cat.checked;
                }
                mg.selectedFilters[cat.name] = cat.checked;
                mg.selectedFilters["count"] += cat.checked ? 1 : 0;
                if (cat.name === category && subFilter && cat.subList && cat.subList.length) {
                  cat.subList.map((subCat) => {
                    if (subCat.name === subFilter) {
                      subCat.checked = !subCat.checked;
                    }
                    mg.selectedSubFilters = mg.selectedSubFilters || {};
                    mg.selectedSubFilters[cat.name + "__" + subCat.name] = subCat.checked;
                    mg.selectedFilters["count"] += subCat.checked ? 1 : 0;

                    return subCat;
                  });
                }
                return cat;
              });
            }
            return filt;
          });
        }
      }
      return mg;
    });
    return mapGroups;
  }
  enableFilterType(mgs, mapGroupName) {
    let mapGroups = _.cloneDeep(mgs);
    let numToggled = 0;
    mapGroups.forEach((group) => {
      numToggled += group.enabled ? 1 : 0;
    });
    if (numToggled === 0) {
      return mapGroups;
    }
    numToggled = 0;
    mapGroups = mapGroups.map((group) => {
      if (group.name === mapGroupName) {
        group.enabled = true;
      }
      numToggled += group.enabled ? 1 : 0;
      return group;
    });
    if (numToggled === 0) {
      mapGroups = mapGroups.map((group) => {
        group.enabled = true;
        return group;
      });
    }
    return mapGroups;
  }
  isEnabled(mapGroups, type) {
    let result = false;
    mapGroups.forEach((group) => {
      if (group.name === type) {
        result = group.enabled;
      }
    });
    return result;
  }
  allEnabled(mapGroups) {
    let result = true;
    mapGroups.forEach((group) => {
      if (!group.enabled) {
        result = false;
      }
    });
    return result;
  }
  clearFilters(mgs, mapGroupName) {
    let mapGroups = _.cloneDeep(mgs);
    return mapGroups.map((group) => {
      if (group.name === mapGroupName || mapGroupName === "includeSubFilters") {
        group.filters.forEach((filterGroup, i) => {
          filterGroup.list.forEach((filt, j) => {
            filterGroup.list[j].checked = false;
            group.selectedFilters[filt.name] = false;
            group.selectedFilters["count"] = 0;
            if (filt.subList && filt.subList.length) {
              filt.subList.forEach((subFilt, j) => {
                subFilt.checked = false;
              });
            }
          });
        });
      }
      if (mapGroupName === "all") {
        group.enabled = true;
        group.targeted = false;
        group.toggled = false;
      }
      return group;
    });
  }

  getCanvasArrays(markers, graphData, called_from) {
    // console.log('getCanvasArrays', called_from);
    const markersObj = {},
      graphLinks = <any>[],
      links = [],
      nodeGroups: NodeGroup = {
        inventory: [],
        shipment: [],
        asset: [],
        incident: [],
        location: [],
      };
    let legs = [];
    markers.forEach((node, i) => {
      markersObj[node.uid] = {
        i: i,
        uid: node.uid,
      };
      if (nodeGroups[node.nodeClass]) {
        nodeGroups[node.nodeClass].push(node);
      } else {
        console.log("not found", node);
      }
    });
    _.forOwn(graphData.bisectorsObj, (val, key, i) => {
      legs = key.split("__");
      if (legs[1] && markersObj[legs[0]] && markersObj[legs[1]]) {
        links.push({
          ...val,
          path: [graphData.nodesObj[legs[0]].locus, graphData.nodesObj[legs[1]].locus],
        });
        graphLinks.push(Object.assign({ source: markersObj[legs[0]].uid }, { target: markersObj[legs[1]].uid }));
        val.id = i;
      }
    });

    return [graphLinks, nodeGroups, links];
  }
  filterByType(nodes, type) {
    return nodes.filter((node) => {
      return node.nodeClass === type ? true : false;
    });
  }
  getLocMarkers(nodes) {
    let markers = this.filterByType(nodes, "location");
    return markers.map((marker) => {
      marker.mapRadius = marker.radius * 20000;
      return marker;
    });
  }
  filterBySearchFields(nodes, searchFields) {
    searchFields = searchFields.filter((sf) => {
      return sf.childFilters.length;
    });
    if (!searchFields || !searchFields.length) {
      return nodes;
    } else {
      return nodes.filter((node) => {
        let included = true;
        searchFields.forEach((sf) => {
          sf.childFilters.forEach((cf) => {
            if (!node.properties[cf.fieldName]) {
              // console.warn('property not found', cf.fieldName, node.properties)
            }
            if (!node.properties[cf.fieldName] || cf.queryText.indexOf(node.properties[cf.fieldName].toLowerCase()) === -1) {
              included = false;
            }
          });
        });
        return included;
      });
    }
  }
  filterMonitoredAssets(nodes, searchTerm, searchFields) {
    nodes = this.filterBySearchFields(nodes, searchFields);
    return this.filterNodesByTerm(nodes, searchTerm);
  }
}
