<div class="notifications-widget" style="font-size: large;">
    <div *ngIf="statusCounts.UNREAD" class="notification-count">{{ statusCounts.UNREAD > 9 ? '9+' : statusCounts.UNREAD }}</div>
    <div [matMenuTriggerFor]="notificationsMenu" class="trigger-container menu-icon">
        <i class="fa fa-bell header-notif-icon"></i>
    </div>
    <mat-menu #notificationsMenu="matMenu" class="w-[350px] p-2 no-padded-menu">
        <div class="dropdown-arrow right"></div>
        <div class="w-100 relative">
            <div *ngIf="statusCounts.UNREAD" class="notification-count">{{ statusCounts.UNREAD > 9 ? '9+' : statusCounts.UNREAD }}</div>
            <button-tab-group [(selectedIndex)]="tabSelectedIndex">
                <button-tab tabTitle="Info" faIcon="fa fa-bell-o fa-lg">

                    <div *ngIf="notifications.length <= 0" class="m-4">
                        <p class="align-center"><i class="fa fa-info-circle mr-2"></i>You have no new notifications to review</p>
                    </div>

                    <ul class="notification-list">
                        <li *ngFor="let notification of notifications">
                            <cl-notification-item [notification]="notification" (onCloseNotificationPanel)="updateNotifications($event)"></cl-notification-item>
                        </li>
                    </ul>
                </button-tab>
            </button-tab-group>
        </div>

        <div class="p-3 align-center bt foz14">
            <a [routerLink]="['/notifications','WARNING']">My Notifications</a>
        </div>
    </mat-menu>
</div>
