<div class="mt-2 mb-2">
    <span class="page-title">App Settings</span>
</div>
<div class="mb-1 list-page-count" *ngIf="rows.length > 0">Showing <span class="font-bold">{{rows.length}}</span> of {{rows.length}} vendor accounts</div>
<div class="wt-ngx-grid-wrapper relative list-page px-2">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list" [clIsValidAction]="actionBtnPolicies.add" *ngIf="!showSummaryPanel">
            <div class="add-button" (click)="addVendorAccount()">
              <span class="fa fa-plus" ></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
            <input type="text" class="list-page__search-field" placeholder="Search"
                (input)="updateFilter($event)" />
            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 mr-2" (click)="editVendorAccount()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>

            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.delete"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 mr-2" (click)="deleteVendorAccount()">
                <i class="fa fa-trash"></i>
                Delete
            </button>

            <button [disabled]="editDisabled"
            [clIsValidAction]="actionBtnPolicies.edit"
            [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
            class="p-4-10 border-general br2 mr-2" (click)="openDialog()">Edit Credentials
        </button>
        </div>
        <div class="user__action-panel-right action-panel-right self-end flex items-center mr10 mb10">
            <cl-export-panel  (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'account'">
            </cl-grid-column-toggle>
        </div>
    </div>
    <div class="h-calc-60">
        <main class="h-100 overflow-y-hidden">
            <div class="grid-container h-100 grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div *ngIf="delayedRefreshStart">
                  <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                  (refreshList)="delayedRefreshStart = false; getVendorAccounts()"
                  ></cl-refresh-countdown>
              </div>
                <div class="h-100 " [style.width.px]="tableWidth">
                    <ngx-datatable class="h-100" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" (reorder) = "columnReordered($event)" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [selectAllRowsOnPage]="false" (select)='onSelect($event)' [reorderable]="false" #table>
                    </ngx-datatable>
                </div>
            </div>
        </main>
        <ng-template #nameTemplate let-row="row" let-value="value" let-i="index">
          <a class="font-12-bold" href (click)="loadSummaryPanel(row, $event)">{{value}}</a>
      </ng-template>
    </div>

    <div class="summery-pannel" *ngIf="showSummaryPanel === true">
        <cl-vendor-account-side-panel [selectedAccount]="selectedAccount" [mode]="mode" (closeSidePanel)="closeSidePanel($event)">
        </cl-vendor-account-side-panel>
    </div>
</div>
