<div>
  <div class="mb-4">
    <cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb>
  </div>

  <cl-shipment-composite-cards
    *ngIf="compositeShipmentId"
    [compositeShipmentData]="compositeShipmentData"
    [shipmentId]="shipmentId"
    (refreshShipment)="getShipmentData(); getCompositeShipmentData()"
  ></cl-shipment-composite-cards>

  <div class="grid {{ mac }}">
    <div *ngIf="isInitialLoad">
      <div class="mt-2 flex justify-center">
        <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
      </div>
    </div>
    <div *ngIf="isDraftShipment" class="m-2 text-center">Cannot show details for Draft shipments</div>
    <ng-container *ngIf="!isInitialLoad && !isDraftShipment">
      <cl-shipment-details-actions
        [shipmentData]="shipmentDetails"
        *ngIf="shipmentDetails"
        (refreshShipment)="getShipmentData();getCompositeShipmentData();"
      ></cl-shipment-details-actions>
      <div class="relative grid gap-3">
        <cl-shipment-details-summary
          [winkedAssetId]="winkedAssetId"
          [shipmentData]="shipmentDetails"
          *ngIf="shipmentDetails"
        ></cl-shipment-details-summary>

        <cl-shipment-details-included-assets
          [shipmentData]="shipmentDetails"
          (sendDataToapply)="getWinkedAssetId($event)"
          (refreshShipment)="getShipmentData();getCompositeShipmentData();"
          *ngIf="shipmentDetails"
        ></cl-shipment-details-included-assets>
      </div>
      <cl-shipment-details-map
        [shipmentData]="shipmentDetails"
        [assetData]="shipmentDetails.assetNodes[0]"
        [winkedAssetId]="winkedAssetId"
        *ngIf="shipmentDetails"
      ></cl-shipment-details-map>
    </ng-container>
  </div>
</div>
