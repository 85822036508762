<div *ngIf="paneView == 'horizontal'" class="flex">
  <div class="flex flex-wrap ad-capabilities-wrapper overflow-y-auto overflow-x-hidden max-h-[300px] mt-2" [ngClass]="[capabilitiesData?.length === 1 ? 'ad-1-cap' :
  capabilitiesData?.length === 2 ? 'ad-2-cap' : 
  capabilitiesData?.length >= 3 ? 'ad-3-cap' : 'ad-3-cap']">
    <div class="flex " *ngFor="let cap of capabilitiesData">
      <div class="cap-icon-wrapper">
        <cl-alert-icon [alertIconObject]="formatAlertObject(cap)"></cl-alert-icon>
      </div>
      <div class="flex-col" >
        <span class="cap-title font-bold foz16">{{cap.measureLabel}}</span>
        <div class="wt-flex wt_flex-space">
          <span class="cap-value font-bold foz16" *ngIf="cap.value">{{cap.value}} {{cap.uom}}</span>
          <!-- <span ng-if="cap.baseTitle" class="cap-value color-57 wt__capitalize" data-ng-bind-html="cap.baseTitle"></span> -->
        </div>
        <div class="wt-flex wt_flex-space cl-dependency" *ngFor="let dependency of cap.dependencies">
            <span class="cap-value font-bold foz16">{{dependency.value}} {{dependency.uom}}</span>
            <span class="cap-title foz16 font-bold"> {{dependency.config.dependencyTitle}}</span>
        </div>
        <!-- <span class="font-12" ng-if="cap.location.site !== 'Out Of Coverage' && cap.location.zone.length">at {{cap.location.zone}}
            <br/> </span> -->
        <!-- <span class="font-12" ng-if="cap.location.site && cap.location.site.length">in {{cap.location.site}}</span> -->
        <span class="fs12" *ngIf="cap.timeOfCapture">(as of <span >{{cap.timeOfCapture | formateDate}}</span>)</span>
        <span class="fs12" *ngIf="!cap.timeOfCapture">(as of <span >{{cap.data[0].time | formateDate}}</span>)</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="paneView == 'vertical'">
  <div class="flex flex-wrap ad-capabilities-wrapper inline vertical-window w-100">
    <div class="vertical-window bgcolor32">
      <div  *ngFor="let cap of assetObject?.measures">
        <div class="grid grid-15-60-25 items-center">
            <div class="m-auto">
              <cl-alert-icon [alertIconObject]="formatAlertObject(cap)"></cl-alert-icon>
            </div>
            <div> <span class="cap-title color-77 font-bold font-12">{{cap.measureLabel}}</span> </div>
            <div> <span class="cap-value color-57"></span>{{cap.value}} {{cap.uom}} </div>
        </div>
        <!-- <div class="wt-flex wt-flex-align-center" ng-if="cap.dependencies.length" ng-repeat="dependency in cap.dependencies">
            <div class="col-md-1"></div>
            <div class="col-md-6"><span class="cap-value color-57 wt__capitalize pl10 ml10">{{dependency.title}}</span></div>
            <div class="col-md-5"><span class="cap-value color-57 wt__capitalize">{{dependency.value.value}}{{dependency.unit}}</span></div>
        </div> -->
    </div>
    </div>
  </div>
</div>