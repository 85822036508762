import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AlertIcon } from '@cl/@types/measure.type';

@Component({
  selector: 'cl-alert-icon',
  templateUrl: './alert-icon.component.html',
  styleUrls: ['./alert-icon.component.scss']
})
export class AlertIconComponent implements OnInit, OnChanges {
  @Input() alertIconObject: AlertIcon;
  formattedMeasure: string;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.alertIconObject) {
      this.alertIconObject.measure = this.alertIconObject.measure.toLowerCase().replace(/\s/g, "")
    }
  }


}
