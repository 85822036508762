import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggedInUserType } from '../@types/logged-in-user.type';
import { environment } from '../../environments/environment';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  user: LoggedInUserType;
  tokenResponse: any;
  token: any;
  constructor(private http: HttpClient, private apiProvider: ApiProviderService) {
    this.user = { id: '', tenantId: '', enabled: true, name: '', type: '', roles: [], policies: [], permissions: [], properties: { emailActivationStatus: '', manager: '', profileType: '', phone: '', locationId: '', 'reporting_region': '', appRole: '', 'encoded.cred.archive': '', category: '', manager_email: '', email: '', isoptedforsms: '', 'ui.monitor.refreshInterval': '' }, userDevices: [{ id: '', userId: '', deviceIdentifier: '', deviceName: '', transport: '', appPlatform: '', status: '', providerDetails: '', confirmationKey: '' }], modifiedTime: 0, groups: [], locked: false, timezone: '', tenantName: '', module: '', uiProfileId: '', uiProfileName: '', uiPages: [], proxyLogin: false, userTenantAccessType: '' };
  }

  postNotificationList(payLoad) {
    if(!payLoad){
      payLoad = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/notify/filter`, payLoad);
  }

  resolveAlerts(payLoad) {
    if(!payLoad){
      payLoad = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/resolve/violations`, payLoad);
  }



  getNotificationFilters() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/alertFilters?source=notification`);
  }

}
