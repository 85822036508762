<div class="filter-box-wrapper domain-filter-box-wrapper cursor-pointer {{domain?.id}} target-enabled p1" matRipple
  [matMenuTriggerFor]="filterMenu" (menuOpened)="menuOpened = true; footerLabel = domain.label" (menuClosed)="menuOpened = false">

  <div class="count-box grid h-100 just-center {{domain?.id}}">
    <ng-container *ngIf="!apiParams?.loading">
      <span class="align-self-end mb-1n">
        <ng-container *ngIf="totalDomainItemCount > 9999">{{9999 | number}}+</ng-container>
        <ng-container *ngIf="totalDomainItemCount <= 9999">{{totalDomainItemCount | number}}</ng-container>
      </span>
      <i class="filter-arrow align-self-start align-center fa fa-angle-down foz20"></i>
    </ng-container>
  </div>
  <div class="filter-content align-items-center grid justify-start mid-column pl-10px" [class.menu-open]="menuOpened">
    <h5 class="m-0 capitalize darkerType">{{domain.label}}</h5>
    <!-- <p class="m-0 foz-13">
      {{activeFiltersCount > 0 ? activeFiltersCount + ' selection' : 'All'}}
    </p> -->
  </div>
  <div class="right-buttons" (click)="$event.stopPropagation()">
    <i title="Enable domain"
      class="fa"
      [class.fa-eye]="visible"
      [class.fa-eye-slash]="!visible"
      (click)="toggleVisibility()">
    </i>
    <!-- <span [class.targeted]="targeted" (click)="resetAndToggleTargeted()" title="Target domain">
      <i class="fa fa-bullseye"></i>
    </span> -->
  </div>
</div>

<mat-menu #filterMenu="matMenu" class="filter-box-menu-wrapper">
  <div class="filter-box-menu {{domain.id}}">
    <div class="filter-stripe {{domain.id}}">
    </div>
    <div class="filter-container gap-1 css-grid p0" (click)="$event.stopPropagation()">
      <form [formGroup]="searchForm" novalidate class="filter-form domain-multi-col-form grid"
        [style.width]="'calc(' + uiLayoutColumns.length * 250 + 'px) !important'"
        [style.grid-template-columns]="'repeat(' + uiLayoutColumns.length + ', 1fr)'">

        <div class="domain-scroll-list-container relative" [class.left-divider]="!first"
          *ngFor="let columns of uiLayoutColumns; let first = first;">

          <div class="w-100" [class.h-100]="columns.rows.length === 1"
            *ngFor="let row of columns.rows" formGroupName="domains">
            <div class="capitalize filter-header">{{row.displayLabel}}</div>

            <div class="filter-content-container" [formGroupName]="row.name">
              <ng-container *ngIf="columns.rows.length > 1">
                <div *ngFor="let item of row.buckets;"
                  class="filter-item css-grid grid-flow-col align-items-center"
                  [formGroupName]="item.key">
                  <ng-container *ngIf="item?.key">
                    <mat-checkbox class="list-checkbox level-{{item._level}}" [disabled]="disabledFields.includes(item.key)" formControlName="$$active" (change)="findEvent($event)">
                      <div class="grid-auto-max-content css-grid gap-1">
                        <span class="filter-name ellipsis capitalize">{{item.key}}</span>
                        <span>{{item?.value | number}}</span>
                      </div>
                    </mat-checkbox>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="columns.rows.length === 1">
                <div class="h-100">
                  <div *ngFor="let item of row.buckets;"
                    class="filter-item grid align-items-center"
                    [formGroupName]="item.parentName ? item.parentName : item.key">
                    <!-- <ng-container *ngTemplateOutlet="listItem; context: {item: item}"></ng-container> -->

                    <ng-container *ngIf="item?.key">
                      <mat-checkbox class="list-checkbox level-{{item._level}}" [disabled]="disabledFields.includes(item.key)" (change)="findEvent($event)"
                        [formControlName]="item.parentName ? item.key : '$$active'">
                        <div class="grid-auto-max-content css-grid gap-1">
                          <span class="filter-name ellipsis capitalize">{{item.key}}</span>
                          <span>{{item?.value | number}}</span>
                        </div>
                      </mat-checkbox>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

            </div>

          </div>
        </div>
      </form>
      <div class="filter-footer" *ngIf="footerLabel === 'Asset' || footerLabel === 'Assets'"><a class="vertical-align-center color4" [routerLink]="['../assets']">all assets
        <i class="fa fa-arrow-right ml-1 color4 fs12"></i>
      </a></div>
      <div class="filter-footer" *ngIf="footerLabel === 'Locations' || footerLabel === 'Location'"><a class="vertical-align-center color4" [routerLink]="['../locations']">all locations
        <i class="fa fa-arrow-right ml-1 color4 fs12"></i>
      </a></div>
      <div class="filter-footer" *ngIf="footerLabel === 'Shipments' || footerLabel === 'Shipment'"><a class="vertical-align-center color4" [routerLink]="['../shipments']">all shipments
        <i class="fa fa-arrow-right ml-1 color4 fs12"></i>
      </a></div>
    </div>
  </div>
</mat-menu>

<ng-template let-item="item" #listItem>
  <ng-container *ngIf="item?.key">
    <mat-checkbox class="list-checkbox level-{{item._level}}" [disabled]="disabledFields.includes(item.key)" [formControlName]="item.key">
      <div class="grid-auto-max-content css-grid gap-1">
        <span class="filter-name ellipsis">{{item.key}}</span>
        <span>{{item?.value | number}}</span>
      </div>
    </mat-checkbox>
  </ng-container>

  <ng-container *ngIf="item?.subBucket">
    <ng-container *ngFor="let subItem of item.subBucket">
      <ng-container *ngTemplateOutlet="listItem; context: {item: subItem}"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>
