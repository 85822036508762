<div>
  <div>
    <div class="flex gap-2">
      <div> 
        <div [innerHTML]="data?.heading"></div> 
      </div>
      
    </div>
    <div class="pl-3 max-h-36 overflow-auto content-body flex">
      <div *ngFor="let error of data.org_errors">{{ error }}</div>
      <div class="ml-auto" *ngIf="!data?.isAutoClose">
        <button (click)="snackBarRef.dismiss()"><i class="fa fa-times-circle-o"></i></button>
      </div>
    </div>
  </div>
</div>