import { FilterGroup } from "../models/index";

export enum AssetCustodyState {
  Available    = 'AVAILABLE',
  InPossession = 'IN_POSSESSION',
  InTransfer   = 'IN_TRANSFER',
  InService    = 'IN_SERVICE',
}

export enum AssetStatus {
  Monitored   = 'MONITORED',
  UnMonitored = 'UNMONITORED',
}

export const assetCostFilter: FilterGroup = {
  label: "Cost",
  filterType: "cost",
  type: "currencyrange",
  list: [
    {
      checked: false,
      count: 0,
      name: "",
      type: "range",
    },
  ],
};

export const assetMaintenanceDateFilter: FilterGroup = {
  label: "Maintenance Date",
  filterType: "maintenanceDate",
  type: "daterange",
  list: [
    {
      checked: false,
      count: 0,
      name: "",
      type: "range",
    },
  ],
};