<div class="cl-activity mt10">
  <div class="row justify-content-start">
    <div class="col-6 text-start">
      <span *ngFor="let tab of gatewayObject?.eventTabs; index as i;" class="position-relative">
        <span class="pl-4 pr-4 pt-1 pb-1 pl10 pr10 cursor-pointer" (click)="navigate(i, tab)"
          [ngClass]="{'bgcolor4 color2': activeTabIndex === i , 'bgcolor9 color4': activeTabIndex !== i, 'rounded-start': i === 0, 'rounded-end': gatewayObject?.eventTabs.length-1 === i}"
          [textContent]="tab.name"></span>
        <i class="fa fa-lg position-absolute end-0 bgcolor2 br3" style="z-index: 1;top: -10px;"
          [ngClass]="{'fa-check-circle color20': tab.tabColor === 'G','fa-exclamation-circle color22':tab.tabColor === 'Y','fa-exclamation-circle color21':tab.tabColor === 'R'}"
          aria-hidden="true"></i>
      </span>
    </div>
    <div class="col-2 text-end">
      <span class="color4 hidden"><span class="fa fa-download"></span><span class="ml5 rt1 mr20">export</span></span>
    </div>
    <div class="col-4 text-start">
        <cl-date-picker [dateRange]="selectedDateRange" [ranges]="ranges" (dateChanged)="datesUpdated($event)"></cl-date-picker>
    </div>
  </div>
  <div>
    <div  class="pt20 mt20">
      <div class="cl-characteristic-list">
        <div class="row align-items-start h300">
          <div class="col-2 h300 overflow-auto">
            <div class="p10 cursor-pointer cl-active-indication font-14-bold"
              *ngFor="let event of activeTab?.events" (click)="gatewayEventChanged(event)"
              [ngClass]="{'bgcolor4 color2 cl-active-indicator rounded-end': currentEvent?.displayName === event?.displayName, 'bgcolor2 color4': currentEvent?.displayName !== event?.displayName}"
                 [style.display]="event?.name !== 'system.restart.reason' ? 'block' : 'none'">
              <span [textContent]="event?.displayName" class="text-break"></span><br/>
              <span class="rt1 font-11-normal" [ngClass]="{'color2': currentEvent?.displayName === event?.displayName, 'color34': currentEvent?.displayName !== event?.displayName}">
                <span *ngIf="event?.filterSpec" class="text-break" [innerHTML]="event?.value | meta : event?.filterSpec"></span>
                <span *ngIf="!event?.filterSpec" class="text-break" [innerHTML]="event?.value"></span>
                as of
                <span [textContent]="event?.time| date:'MM/dd/yyyy'"></span>
                <br/>
              </span>
              <!--  Need more understanding why we need this commented block
                <b *ngIf="!event?.showOnlyTimeStamp">
                <span *ngIf="event?.filterSpec" [textContent]="event?.value | meta : event?.filterSpec"></span>
                <span *ngIf="!event?.filterSpec" [textContent]="event?.value"></span>
              </b>
              <span *ngIf="!event?.showOnlyValue">
                <span *ngIf="!event?.showOnlyTimeStamp">as of</span> <span [textContent]="timeStampUtil(event?.time)"></span>
              </span> -->
            </div>
          </div>
          <div class="col-10">
            <div *ngIf="currentEvent?.displayType === 'graph'">
              <cl-line-chart [label]="getGraphYAxesLabel(currentEvent?.name)" [data]="data"
                             [unit]="currentEvent?.name !== 'system.restart.time' ? '' : 'systemReboot'"
                             [tooltip]="tooltipCustomizer" *ngIf="data?.length>0">
              </cl-line-chart>
              <div *ngIf="data?.length===0" class="h2">No Data Available</div>
            </div>
            <div *ngIf="currentEvent?.displayType === 'text'">
              <span *ngIf="currentEvent?.filterSpec" class="h2">
                Current Value: <span [textContent]="currentEvent.value | meta :currentEvent.filterSpec"></span>
              </span>
              <span *ngIf="!currentEvent?.filterSpec" class="h2">
                Current Value: <span [textContent]="currentEvent?.value"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
