import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '@cl/common/services/toast.service';
import _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'cl-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  selectedDateRange: { startDate: moment.Moment; endDate: moment.Moment };
  ranges: any;
  data: any[];
  listFilters: any = [];
  toggledFilter: any = {};
  datePickerClass: any = 'sideFilterDatePicker';

  @Input() dateRangeFilters: any;
  @Output() panelsEmitter: EventEmitter<any> = new EventEmitter();
  constructor(public toastService: ToastService) {}
  ngOnInit(): void {
    this.ranges = {
      Today: [moment().startOf('day'), moment()],
      Yesterday: [
        moment().subtract(1, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day'),
      ],
      'Last 7 Days': [moment().subtract(7, 'days'), moment()],
      'Last 30 Days': [moment().subtract(30, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };
  }
  datesUpdated(dateRange) {
    this.selectedDateRange = dateRange;
    this.toggledFilter = {
      filterGroupName: this.listFilters[0].filterType,
      id: 'dateSelected',
      checked: true,
      name: 'date selection',
    };
    this.emitSearch();
  }
  ngOnChanges(changes: any) {
    this.listFilters = _.cloneDeep((this.dateRangeFilters || []).slice());

    if (this.listFilters[0].defaultRange) {
      this.selectedDateRange = this.listFilters[0].defaultRange;
    }
  }

  toggleFilter(filter: any, filterGroup?: any) {
    // Select date range before selecting the checkbox
    if (!this.selectedDateRange.startDate || !this.selectedDateRange.endDate) {
      this.toastService.error(
        `Select date range before selecting ${filter.name}`
      );
      setTimeout(() => {
        filter.checked = false;
      }, 10);
      return;
    }

    this.toggledFilter = { ...filter };
    this.toggledFilter.filterGroupName = filterGroup.filterType;

    this.emitSearch();
  }

  emitSearch() {
    this.panelsEmitter.emit({
      ...this.toggledFilter,
      selectedDateRange: this.selectedDateRange,
    });
  }
}
