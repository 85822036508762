
export const mainColorRGBA = "rgba(0,153,221, .75)";
export const complementColorRGBA = "rgba(255,202,27,.75)";
export const lightGreyRGBA = "rgba(128,128,128,.75)";

export const cloudleafBlue = "#2879DE";
export const mainColor = "#2879DE";
export const blue_gradient1 = "#33ade4";
export const mainSoft = "#33ade4";
export const blue_gradient2 = "#66c2eb";
export const blue_gradient3 = "#98d6f1";
export const blue_gradient4 = "#b9e3f7";
export const blue_gradient5 = "#f3faff";
export const blue_trans = "rgba(0,153,221, .5)";

export const cloudleafOrange = "#ee5511";
export const orange_gradient1 = "#f17740";
export const orange_trans = "rgba(238, 85, 17, .5)";
export const complementColor = "#f17740";

export const cloudleafGreen = "#99cc33";
export const green_gradient1 = "#acd65b";
export const green_trans = "rgba(153, 204,  51, .5)";

export const cloudleafRed = "#ff2525";
export const ruleColor = "#ff2525";
export const incidentColor = "#ff2525";
export const cloudleafYellow = "rgb(255, 202, 27)";

export const zoneColor = "#9b9b9b";
export const assetColor = "#054ea7";
export const assetColorLight = "#b9e3f7";
export const shipmentColor = "#4a5c6d";
export const shipmentColorLight = "#e3e3e3";
export const supplierColor = "#a89668";
export const supplierColorLight = "#fcf1d6";
export const locationColor = "#80a1c1";
export const locationColorLight = "#e6e6e6";
export const inventoryColor = "#F7863D";
export const inventoryColorLight = "#f8e1d2";

export const DomainColors = {
  asset: "#004ea7",
  shipment: "#4a5c6d",
  supplier: "#a89668",
  location: "#385BF6",
  inventory: "#fd863c",
  zone: "#9b9b9b",
  default: "#004ea7",
};
