import { Bisector, Deserializable, Entity } from '../common/models/index';

interface SearchMetadata {
  completed_in: number;
  max_id: number;
  max_id_str: string;
  next_results: string;
  query: string;
  refresh_url: string;
  count: number;
  since_id: number;
  since_id_str: string;
}

export class GraphResponse implements Deserializable {
  nodes: Entity[];
  links: Bisector[];
  searchMetadata: SearchMetadata;

  deserialize(res: any) {
    if (res.nodes) {
      // console.log('vertices', res.nodes);
      this.nodes = res.nodes.map((vertice: Entity) =>
        new Entity().deserialize(vertice)
      );
    }
    if (res.edges) {
      this.links = res.edges.map((link: Bisector) =>
        new Bisector().deserialize(link)
      );
    }
    return this;
  }
  deserializeVertices(vertices: any) {
    if (vertices) {
      this.nodes = vertices.map((vertice: Entity) =>
        new Entity().deserialize(vertice)
      );
    }
    return this;
  }
}
