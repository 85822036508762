import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LocationsService } from "@cl/locations/locations.service";
import { takeUntil, Subject, debounceTime, distinctUntilChanged } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { SelectionType, SortType } from "@swimlane/ngx-datatable";
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from "@cl/common/services/toast.service";
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { UtilsService } from '../../common/utils/utils.service';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/common/constants';
import { LatLngLiteral } from '@agm/core';
import { LocationUtilsService } from '../location-utils.service';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { CdmField } from '@cl/@types/extended-attributes.types';
import { AssetBasicInformationProperties } from '@cl/@types/asset.type';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { PersistenceService } from '@cl/common/services/persistence.service';

@Component({
  selector: 'cl-location-route-details',
  templateUrl: './location-route-details.component.html',
  styleUrls: ['./location-route-details.component.scss']
})
export class LocationRouteDetailsComponent implements OnInit, AfterViewInit {
  routeDeatils: any;
  assetDist: any = [];
  assetDistArray: any = [];
  loading = true;
  headerHeight = 25;
  rowHeight = 25;
  selection: SelectionType = SelectionType.checkbox;
  sortType: SortType = SortType.multi;
  id: string;
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  showFilterPanel = true;
  mainWidth: any;
  tableWidth: number;
  searchFormGroup: FormGroup;
  attributes:any;
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  customFields=['name','shipments']
  modifiedFields = ['createdBy','createdAt','modifiedBy','modifiedAt'];
  modifiedOrder=[];
  mapOptions: any = {
    gestureHandling: 'cooperative',
    styles: [
      {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
        ]
      }
    ],
    positionOfActions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    }
  };
  location: any = {
    position: {lat: null, lon: null},
    areas: []
  };
  showGraph = false;
  private readonly destroyed$ = new Subject<boolean>();
  masterData: any[] = [];
  breadCrumbItems: BreadCrumbItem[];
  columnKeys: string[] = [];
  selected = -1;
  assetDistributionDropDown='Category: All';
  assetDistributionDropDownOpen=false;
  selectedDistributionList = [];
  connectedLocations: any = []
  lat: number = 51.673858;
  lng: number = 7.815982;
  paths: LatLngLiteral[] = [];
  circlePoligons:any = [];
  poligons: any = [];
  actualRoute: any = []
  routeDirections:any =[];
  lineDirections:any =[];
  options = {
    suppressMarkers: true
  }
  chart:any={
    type:{
      parcelshipment:{value:0,label:'Parcel'},
      standardshipment:{value:0,label:'Standard'}
    },
    direction:{Outbound:{value:0,label:'OutBound'},Inbound:{value:0,label:'InBound'},Return:{value:0,label:'Return'}}
  };
  chartData: any;
  chartXData: any = [];
  value: any;
  selectedFilters: string[] = [];
  filters:any;
  initialFilters:any;
  rawFilters: any = {
    assetCategories: []
  };
  assetCategories: any = {};
  locationEntityTypeKey = '';
  searchTerm:string=''
    constructor(
    private locationService: LocationsService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private _locationUtils:LocationUtilsService,
    private _dynamicFormService: DynamicFormService,
    public datePipe : FormateDatePipe,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private persistenceStore: PersistenceService) {
      this.breadCrumbItems = [
        {'name': 'Previous', isBack: true, path: null},
        {'name': 'Route Details', path: null}
      ];

      this.route.queryParams.subscribe(params=> {
        this.locationEntityTypeKey = params['type']
      })
    }



  ngOnInit(): void {
    //this.selectedAssetId = '61f4b2e2-13bc-40a6-98c4-63d14ad94599';
    this.getLocationAndAssets();
    this.setContainerWidth();
  }

  ngAfterViewInit(): void {
    this.rawColumns = [
      { name: 'Shipment Name', prop: 'name', minWidth: 180, visible: true },
      { name: 'Shipment ID', prop: 'externalId', minWidth: 200, visible: true },
      { name: 'Date Added', prop: 'createdAt', minWidth: 150, visible: true },
      { name: 'Category', prop: 'type', minWidth: 150, visible: true },
      { name: 'Status', prop: 'status', minWidth: 100, visible: true }
    ];
    let columns: any = _.cloneDeep(this.rawColumns);
    this.columns = columns.filter((col: any) => col.visible);
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
  }

  getLocationAndAssets() {
    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      this.id = params.get("id");
      this.locationService.getLocationsAndRoute(this.id, this.locationEntityTypeKey).subscribe((response: any) => {
        this.routeDeatils = response;
      //  this.origDistrib = response;
        this.prepareCoordinatesObj(response)
        if(response?.summary?.type){
          this.getExtendedAttributes(response.summary.type)
        }else{
          this.getExtendedAttributes(response?.type)
        } 
        this.getShipments(response);
        this.showGraph = true;
        this.renderChartData("type")

        // this.prepareLatLngStr(this.routeDeatils.properties.locus);             
      })
    })
  }
  allAssetsDistrib: any;

  prepareCoordinatesObj(responce){
    let originIcon = '../assets/svgs/map-icons/site-pin-origin.svg';
    let destinationIcon = '../assets/svgs/map-icons/route-destination.svg';
    let wayPointIcon = '../assets/svgs/organization.svg';
    if(typeof responce.summary.properties.locationDetails == 'string'){
      responce.summary.properties.locationDetails = JSON.parse(
        responce.summary.properties.locationDetails
      );
    }
    responce.summary.properties.locationDetails.forEach((element) => {
      const obj = {
        lat: +element.coordinates.lat,
        lng: +element.coordinates.lng,
        label: element.areaName,
        draggable: true,
        iconUrl: wayPointIcon,
        content: element.areaType,
      };
      this.actualRoute.push(obj);
    });
    this.actualRoute[0].iconUrl = originIcon;
    this.actualRoute[this.actualRoute.length - 1].iconUrl = destinationIcon;
    this.loading = false;
    this.lineDirections=[]
    this.routeDirections =[]
    let position =responce.summary?.properties.locationDetails
    let length = position.length -1;
    position.forEach((pos,index) => {
       if(pos.areaItem?.coordinates){
        if(index !=length){
          let data ={
            travelMode :pos.travel_mode,
            override:pos.override,
            origin :{
              lat: Number(JSON.parse(position[index].areaItem.coordinates).geometry?.coordinates[1]),
              lng: Number(JSON.parse(position[index].areaItem.coordinates).geometry?.coordinates[0]),
            },
            destination :{
              lat: Number(JSON.parse(position[index+1].areaItem.coordinates).geometry?.coordinates[1]),
              lng: Number(JSON.parse(position[index+1].areaItem.coordinates).geometry?.coordinates[0]),
            },
            visible: setTimeout(() => { true }, 2000)
          }
         if(pos.travel_mode =='Road' && !pos.override){
          this.routeDirections.push(data)
         } else{
          this.lineDirections.push(data)
         }
         }
        }
    })
  }
  getShipments(responce){
    let tempAssetsArr = [];
    let newTempFilter=[];
    let tmpAssetCats = {};
    let tempAreaAsetCount = 0;
      responce.connectedShipments.nodes.forEach((item) => {
          var props = item.properties;          
          var tmpObj = {
            id: props.id,
            name: props.name,
            externalId: props.externalId,
            createdAt: this.datePipe.transform(props.createdAt, 'default'),
            type: props.type,
            state: props.state,
            status: props.statusWithAssetCount
          };
          this.assetDist.push(tmpObj)  
          this.assetDistArray =[...this.assetDist]
          newTempFilter.push(tmpObj)
          this.masterData = _.cloneDeep(newTempFilter);
          if(tmpAssetCats.hasOwnProperty(props.type)) {
            tmpAssetCats[props.type] += 1;
          } else {
            tmpAssetCats[props.type] = 1;
          }
          tempAssetsArr.push(tmpObj);
          tempAreaAsetCount++;
          this.chart.type[props.type].value++;
          this.chart.direction[props.direction].value++;
      });
      this.assetCategories = {'list':Object.keys(tmpAssetCats), 'counts': tmpAssetCats};    

      this.setLocationDetailsFilters();
  }
  SelectedType:any;
  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get("searchTerm") as FormControl;
}
  renderChartData(chartType){
    this.SelectedType=chartType
    let preChartData=this.chart[chartType]    
    let xData=[];
    let yData=[];
    for (const key in preChartData) {
    xData.push(preChartData[key].value)
    yData.push(preChartData[key].label)
    }

    this.chartData = [
      {
        name: "Shipments",
        data: xData
      }
    ];
    this.chartXData = [...yData];
    this.showGraph = false;
    setTimeout(() => {
      this.showGraph = true
    }, 1);
  }
  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 280;
    } else {
      this.tableWidth = this.mainWidth - 20;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const fileName = `Assets-in-${this.routeDeatils?.summary?.properties?.name ? this.routeDeatils?.summary?.properties?.name : 'Location'}`;
      const csvData = this._utilsService.getSelectedElementsFromArray(this.assetDist, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, fileName, _.map(this.columns, 'name'));
    }
  }

onSearchInput(evt: any) {
    const val = evt.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.assetDistArray];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(val) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.assetDist = temp;
  }
  updateFilter(term){
    this.onSearchInput(term)
    this.searchTerm = term
    this.searchFormGroup.patchValue({
      'searchTerm':term.toLowerCase().trim()
    });
  }

  setLocationDetailsFilters() {            
    for (let cat in this.assetCategories.counts) {      
      this.rawFilters['assetCategories'].push({
        key: cat,
        name: cat,
        count: this.assetCategories.counts[cat],
        checked: false
      })      
    }

    let filterArr = [];
    for (let key in this.rawFilters) {
      filterArr.push({
        'expand': true,
        'filterType': key,
        'label': 'Shipment Categories',
        'type': 'multiselect',
        'list': this.rawFilters[key]
      });
    }

    let filters = [...filterArr];
    this.initialFilters = _.cloneDeep(filters);
    this.filters = filters;    
  }

  getPanelUpdate(filterAction: any) {    
    if (filterAction.action === 'clearFilters') {
      this.selectedFilters = [];
      this.filters = _.cloneDeep(this.initialFilters);
    } else if (filterAction.action === 'filterByType') {
      if (filterAction.checked) {
        this.selectedFilters.push(filterAction.name);
      } else {
        let index = this.selectedFilters.indexOf(filterAction.name);
        if (index > -1) {
          this.selectedFilters.splice(index, 1);
        }
      }
    }    
    this.filterMasterData();
  }
  filterMasterData() {
    let masterData = _.cloneDeep(this.masterData);                
    if (this.selectedFilters.length === 0) {
      this.assetDist = masterData;
    } else {
      this.assetDist = masterData.filter((rowData) => this.selectedFilters.includes(rowData.type));            
    }
  }
  async getExtendedAttributes(route){
    let storeColumnsList = this.persistenceStore.getGridColumns('route');
    const locationCatalog = await this._locationUtils.getLocationExtendedAttributes(route);    
    const fields = locationCatalog.cdmFields 
    this.attributes= this._dynamicFormService.orderFields([...fields],'order');
    this.attributes.forEach(inp => {
      if(this.modifiedFields.includes(inp.name)){
         this.modifiedOrder.push(inp.order)
      }
    })
    this.modifiedOrder.sort()
    let order = this.modifiedOrder[0]-1;
    this.attributes.push({
      name:"shipments",
      order:order,
      isDisplayableOnDetails:true,
      modifiedField:true
     })
    this.attributes= this._dynamicFormService.orderFields([...this.attributes],'order');
    const filteredRawColumn = this.columns.map(item=>{
      if( item.visible && storeColumnsList &&  storeColumnsList.filter(ele=>ele==item.name).length>0){
        item.visible=true;
        return {
          ...item          
        }
      }else{
         item.visible = false;
         return{
          ...item
         }
      }
    })
    
    const filtered = this.columns.filter(item=>
       item.visible && storeColumnsList.filter(ele=>ele==item.name).length>0
    )
    this.columns = [...filtered];
    this.rawColumns = [...filteredRawColumn];
  }
  isStaticPropertyAvailable(id: string){
    let staticProperties : string[] = this._locationUtils.locationStaticProperties;
    return !_.includes(staticProperties, id);
  }

  getExtendedAttributeValue(cdmField : CdmField, properties: AssetBasicInformationProperties){
    if(cdmField['modifiedField']){
      return true
    }else if(cdmField){
      if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
        if(cdmField?.type?.toUpperCase()==='DATE'){
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        } else {
          if(cdmField.name == 'position'){
            let lat = Number(properties[cdmField.name].split(',')[0])
            let lan = Number(properties[cdmField.name].split(',')[1])
            return lat.toFixed(6)+","+lan.toFixed(6)
          }
          if(cdmField.name == 'organization'){
            return properties['organizationName']
          }else{
            return properties[cdmField.name];
          }
        }
      }else{
        return '';
      }
    } else {
      return '';
    }
  }
}
