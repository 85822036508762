import { Injectable } from '@angular/core';
import {
  LvHidePanel,
  LvInitPanels,
  LvShowPanel,
  LvTogglePanel,
  LvUpdatePanels,
  LvUpdatePanelValue,
  LvUpdateTags,
} from '@cl/ngxs/actions';
import { Action, State, StateContext, Store } from '@ngxs/store';

import { PanelStatesService } from '../common/utils/panel-states.service';


@State<any>({
  name: "lv_panels_state",
  defaults: {
    entities: false,
    rightList: false,
    leftList: false,
    inspector: false,
    footer: false,
    browserWidth: 0,
    contentWidth: 0,
    mainWidth: 0,
    panelHeight: 0,
    middleStripHeight: 50,
    maHeight: 0,
    mainHeight: 0,
    leftOffset: 0,
    inspectorOffset: 0,
    rightListOffset: 0,
    leftListOffset: 0,
    footerOffset: 0,
    showEditForm: false,
    carousel: true,
    filters: true,
    mainTop: 0,
    filtersTop: 0,
    showTags: false,
    filtersSelected: false
  },
})
@Injectable()
export class LvPanelsState {
  constructor(
    private store: Store,
    private panelStatesService: PanelStatesService
  ) {}
  @Action(LvInitPanels)
  lvInitPanels(ctx: StateContext<any>, action: LvInitPanels) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      ...this.panelStatesService.getPanelPositions(state),
    });
  }
  @Action(LvUpdatePanelValue)
  LvUpdatePanelValue(ctx: StateContext<any>, action: LvUpdatePanelValue) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      middleStripHeight: action.value + 10,
    });
  }
  @Action(LvUpdatePanels)
  lvUpdatePanels(ctx: StateContext<any>, action: LvUpdatePanels) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      ...this.panelStatesService.getPanelPositions(state),
    });
  }
  @Action(LvUpdateTags)
  lvUpdateTags(ctx: StateContext<any>, action: LvUpdateTags) {
    const showTags = action.tags && action.tags.length ? true : false;
    ctx.patchState({
      showTags: showTags,
    });
    return ctx.dispatch(new LvUpdatePanels([]));
  }
  @Action(LvShowPanel)
  lvShowPanel(ctx: StateContext<any>, action: LvShowPanel) {
    ctx.patchState({
      [action.name]: true,
    });
    return ctx.dispatch(new LvUpdatePanels([]));
  }
  @Action(LvHidePanel)
  lvHidePanel(ctx: StateContext<any>, action: LvShowPanel) {
    ctx.patchState({
      [action.name]: false,
    });

    return ctx.dispatch(new LvUpdatePanels([]));
  }
  @Action(LvTogglePanel)
  lvTogglePanel(ctx: StateContext<any>, action: LvTogglePanel) {
    const state = ctx.getState();
    ctx.patchState({
      [action.name]: !state[action.name],
    });
    return ctx.dispatch(new LvUpdatePanels([]));
  }
}

