<div>
  <form [formGroup]="locationForm"  (ngSubmit)="submitForm()">
    <div class="ml-4 mb-4  mt-4 flex flex-col">
        <label for="name" class="form-label">
            Name<span>*</span>
        </label>
        <input id="name" class="basic-input te" formControlName="name"
        [class.is-invalid]="locationForm.controls['name'].invalid && locationForm.controls['name'].touched" >
    </div>
    <div class="ml-4 mb-4 flex flex-col">
        <label for="position" class="form-label">Position (lat,Ion)</label>
        <input id="position" class="basic-input" formControlName="position" [class.is-invalid]="locationForm.controls['position'].invalid && locationForm.controls['position'].touched" >
    </div>
    <div class="ml-2 -mt-2" *ngIf="dynamicInputFields.length > 0">
        <cl-dynamic-form-renderer [columnMode]="'One'" [inputFields]="dynamicInputFields" #dynamicForm></cl-dynamic-form-renderer>
    </div>
</form>
</div>