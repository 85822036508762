<!-- 
  FIXME: Need to remove after thermofisher approval
  <section class="reset-password-container prelogin-container">
  <section>
    <div class="login-logo flex align-center">
      <img id="logoImg" src="{{logoURL}}" />
    </div>
  </section>
  <section class="mw1440">
    <div class="flex widget-section">
      <div class="tablet"
           [ngStyle]="{'background':'url('+bannerUrl+') 0% 0% / cover no-repeat'}">
      </div>
      <div class="widget-wrapper flex-center-align">
        <div class="flex align-center form-box">
          <div id="reset-container" class="p10">
            <section class="verifyEmail" *ngIf="verifyEmail">
              <div *ngIf="error" class="flex-center-align center-align mt20 alert alert-danger">
                {{error}}
              </div>
              <div class="flex-center-align center-align mt20">
                <label for="userId" class="control-label">User ID</label>
                <input type="text" class="form-control form-div-wrapper" name="userId"
                       [(ngModel)]="userId" placeholder="Enter your User Id" />
              </div>
              <div class="flex-center-align center-align mt20">
                <label for="primaryEmail" class="control-label">Email</label>
                <input type="email" class="form-control form-div-wrapper" name="primaryEmail"
                       [(ngModel)]="primaryEmail" placeholder="Enter your E-mail" />
              </div>
              <div class="wt-flex space-between center-align mt20">
                <button type="button" class="btn btn-primary w100p" [disabled]="disableSubmit()"
                        (click)="verifyPrimaryEmail()">
                  Submit
                </button>
                <a href="#/login" class="clAnchor nav-link-color">Back to Login</a>
              </div>
            </section>
            <section class="w320p" *ngIf="showResetInstructions">
              <div>
                Instructions to reset your password have been emailed to <strong>{{primaryEmail}}</strong>.
                Please check your email for these next steps.
              </div>
            </section>
            <section *ngIf="showResetFlow">
              <div class="text-center color-gray">
                <h3 class="font-normal">Change Your Password</h3>
                <span>Enter a new password below to change your password.</span>
              </div>
              <div *ngIf="error" class="flex-center-align center-align mt20 alert alert-danger">
                {{error}}
              </div>
              <div class="form-div-wrapper flex-center-align center-align mt20">
                <input type="{{showPassword ? 'text' : 'password'}}" class="form-control" name="newSecret"
                       [(ngModel)]="userCredModifier.newSecret" placeholder="New Password" (keyup)="validatePassword($event)" />
                <span (click)="togglePassword('password')" class="fa fa-fw toggle-update-password"
                      [ngClass]="[showPassword ? 'fa-eye-slash' : 'fa-eye']"></span>
              </div>
              <div class="form-div-wrapper flex-center-align center-align mt20">
                <input type="{{showRetypePassword ? 'text' : 'password'}}" class="form-control" name="newSecretAgain"
                       [(ngModel)]="userCredModifier.newSecretAgain" placeholder="Re-enter new password" />
                <span (click)="togglePassword('passwordAgain')" class="fa fa-fw toggle-update-password"
                      [ngClass]="[showRetypePassword ? 'fa-eye-slash' : 'fa-eye']"></span>
              </div>
              <div class="form-group mt20">
                <div class="password-validation" *ngIf="enableValidationContainer">
                  <div class="mt10"> Your password must contain: </div>
                  <ul class="mt5">
                    <li *ngFor="let validation of validationCheck">
                      <i *ngIf="!validation.isEnabled" class="fa fa-circle-o color" aria-hidden="true"></i>
                      <i *ngIf="validation.isEnabled" class="fa fa-check-circle color" aria-hidden="true"></i>
                      &nbsp; {{validation.label}}
                      <ul class="pl20" *ngIf="validation.subCheck">
                        <li *ngFor="let subValidation of validation.subCheck">
                          <i *ngIf="!subValidation.isEnabled" class="fa fa-circle-o color" aria-hidden="true"></i>
                          <i *ngIf="subValidation.isEnabled" class="fa fa-check-circle color" aria-hidden="true"></i>
                          &nbsp; {{subValidation.label}}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <button type="button" class="btn btn-primary mt20" [disabled]="disableUpdate()"
                        (click)="changePassword()">
                  Reset password
                </button>
              </div>
            </section>

          </div>
        </div>
      </div>
    </div>
  </section>
</section> -->
<section class="login-container prelogin-container reset-password-container"
[ngStyle]="appliedTheme.showFullPageBackground ? {'background':'url('+appliedTheme.bannerUrl+') no-repeat', 'width': '100%', 'background-size': 'cover', 'min-height': '100vh'} : {}">
  <section>
    <div class="login-logo flex align-center">
      <img id="logoImg" src="{{appliedTheme.logoURL}}" />
    </div>
  </section>
  <section class="mw1440">
    <div class="flex widget-section">
      <div class="tablet hidden">
      </div>
      <div class="widget-wrapper flex-center-align">
        <div class="flex align-center form-box">
          <div id="reset-container" class="p10">
            <section class="verifyEmail" *ngIf="verifyEmail">
              <div *ngIf="error" class="flex-center-align center-align mt20 alert alert-danger">
                {{error}}
              </div>
              <div class="flex-center-align center-align mt20">
                <label for="userId" class="control-label">User ID</label>
                <input type="text" class="form-control form-div-wrapper" name="userId"
                       [(ngModel)]="userId" placeholder="Enter your User Id" />
              </div>
              <div class="flex-center-align center-align mt20">
                <label for="primaryEmail" class="control-label">Email</label>
                <input type="email" class="form-control form-div-wrapper" name="primaryEmail"
                       [(ngModel)]="primaryEmail" placeholder="Enter your E-mail" />
              </div>
              <div class="wt-flex space-between center-align mt20">
                <button type="button" class="btn w100p" [style]="{backgroundColor: appliedTheme.actionButtonColor}" [disabled]="disableSubmit()"
                        (click)="verifyPrimaryEmail()">
                  Submit
                </button>
                <a href="#/login" class="clAnchor nav-link-color">Back to Login</a>
              </div>
            </section>
            <section class="w320p" *ngIf="showResetInstructions">
              <div>
                Instructions to reset your password have been emailed to <strong>{{primaryEmail}}</strong>.
                Please check your email for these next steps.
              </div>
            </section>
            <section *ngIf="showResetFlow">
              <div class="text-center color-gray">
                <h3 class="font-normal">Change Your Password</h3>
                <span>Enter a new password below to change your password.</span>
              </div>
              <div *ngIf="error" class="flex-center-align center-align mt20 alert alert-danger">
                {{error}}
              </div>
              <div class="form-div-wrapper flex-center-align center-align mt20">
                <input type="{{showPassword ? 'text' : 'password'}}" class="form-control" name="newSecret"
                       [(ngModel)]="userCredModifier.newSecret" placeholder="New Password" (keyup)="validatePassword($event)" />
                <span (click)="togglePassword('password')" class="fa fa-fw toggle-update-password"
                      [ngClass]="[showPassword ? 'fa-eye-slash' : 'fa-eye']"></span>
              </div>
              <div class="form-div-wrapper flex-center-align center-align mt20">
                <input type="{{showRetypePassword ? 'text' : 'password'}}" class="form-control" name="newSecretAgain"
                       [(ngModel)]="userCredModifier.newSecretAgain" placeholder="Re-enter new password" />
                <span (click)="togglePassword('passwordAgain')" class="fa fa-fw toggle-update-password"
                      [ngClass]="[showRetypePassword ? 'fa-eye-slash' : 'fa-eye']"></span>
              </div>
              <div class="form-group mt20">
                <div class="password-validation" *ngIf="enableValidationContainer">
                  <div class="mt10"> Your password must contain: </div>
                  <ul class="mt5">
                    <li *ngFor="let validation of validationCheck">
                      <i *ngIf="!validation.isEnabled" class="fa fa-circle-o color" aria-hidden="true"></i>
                      <i *ngIf="validation.isEnabled" class="fa fa-check-circle color" aria-hidden="true"></i>
                      &nbsp; {{validation.label}}
                      <ul class="pl20" *ngIf="validation.subCheck">
                        <li *ngFor="let subValidation of validation.subCheck">
                          <i *ngIf="!subValidation.isEnabled" class="fa fa-circle-o color" aria-hidden="true"></i>
                          <i *ngIf="subValidation.isEnabled" class="fa fa-check-circle color" aria-hidden="true"></i>
                          &nbsp; {{subValidation.label}}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <button type="button" class="btn mt20" [style]="{backgroundColor: appliedTheme.actionButtonColor}" [disabled]="disableUpdate()"
                        (click)="changePassword()">
                  Reset password
                </button>
              </div>
            </section>

          </div>
        </div>
      </div>
    </div>
  </section>
</section>
