import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { GraphAPIService } from './graph-api.service';
import { IconSvgService } from './icon-svg.service';
import { LinkService } from './link.service';
import { TemplateService } from './template.service';
import { UtilsService } from '../utils/utils.service';

// import { Entity } from "@cl/models";
@Injectable({
  providedIn: "root"
})
export class CloudleafService {
  nodeTemplate;
  linkTemplate;
  screenHeight = 500;
  screenWidth = 800;
  defaultLocus = {
    lat: 37.436449,
    lng: -121.89155
  };
  allNodeClasses = {};
  // numNonPositioned = 0;
  numAlerts = 0;
  typeCounters = {};
  extents = { east: 0, west: 0, north: 0, south: 0, diffX: 1000, diffY: 1000 };
  categorysObj = {};
  inventorysObj = {};
  locationsObj = {};
  nodeClassList = {
    "party": "location",
    "organization": "location",
    "rule": "rule",
    "class": "rule",
    "ruletemplate": "rule",
    "category": "category",
    "site": "location",
    "geofence": "location",
    "zone": "location",
    "location": "location",
    "shipment": "shipment",
    "inventory": "inventory",
    // "excursion": "incident",
    "locationnode": "location",
    "asset": "asset",
    "vehicle": "asset",
    "container": "asset",
    "sensor": "device",
    "gateway": "device",
    "email": "action",
    "inapp": "action",
    "sms": "action",
    "messagequeue": "action",
    "message": "action",
    "webhook": "action",
    "notification": "action",
    "ruleaction": "action",
    "action": "action",
    "cloudapp": "action",
    "plasma": "inventory",
    "pharmaceutical": "inventory",
    "pharmaceuticals": "inventory",
    "inmarketnode": "inventory",
    "manufacturingfactory": "location",
    "statenode": "incident",
    // "shipmentstatusnode": "incident",
    "collectioncenter": "location",
    "headquarters": "location",
    "distributioncenter": "location"
  }

  // propClassList = {
  //   party: 'locationProp',
  //   organization: 'locationProp',
  //   rule: 'ruleProp',
  //   ruletemplate: 'ruleProp',
  //   category: 'categoryProp',
  //   site: 'locationProp',
  //   geofence: 'locationProp',
  //   zone: 'locationProp',
  //   location: 'locationProp',
  //   asset: 'assetProp',
  //   vehicle: 'assetProp',
  //   shipment: 'assetProp',
  //   sensor: 'deviceProp',
  //   gateway: 'deviceProp',
  //   email: 'actionProp',
  //   sms: 'actionProp',
  //   webhook: 'actionProp',
  //   notification: 'actionProp',
  //   cloudapp: 'actionProp'
  // }
  constructor(
    private _linkService: LinkService,
    private _iconSvgService: IconSvgService,
    private _utilsService: UtilsService,
    private _graphAPIService: GraphAPIService,
    private _templateService: TemplateService
  ) {
    this.nodeTemplate = this._templateService.getTemplate("node");
    this.linkTemplate = this._templateService.getTemplate("connection");
  }
  getDefaultLocus() {
    return this.defaultLocus;
  }
  getGraphData(graphData) {
    return this._graphAPIService.getAllGraphData().subscribe(res => {
      // console.log("getGraphData got res", res);
      this.formatGraphData(res, graphData, {});
      // this._appDataService.setGraphData(graphData);
    });
  }
  subscribeToGraph() {
    interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this._graphAPIService.getAllGraphData())
      )
      .subscribe(res => {
        // console.log("res", res);
      });
  }

  formatGraphData(resData, graphData, args) {
    // graphData = this.importNodes(resData.nodes, graphData); //for Canvas only

    graphData = this.importLinks(resData.links, graphData);
    graphData = this.importLinkedNodes(graphData);
    graphData = this.setConnectionMemos(graphData, resData);
    graphData = this.addGroupNodes(graphData, resData);
    // console.log("imported", graphData);
    return graphData;
  }

  addGroupNodes(graphData, resData) {
    let newAsset, newAlert;
    if (!resData.location || resData.location === undefined) {
      console.warn('No location', resData);
      return graphData;
    }
    if (resData.location.filters && resData.location.filters.asset) {
      _.forOwn(resData.location.filters.asset, (val, key) => {
        if (graphData.nodesObj[key]) {
          newAsset = {
            ...graphData.nodesObj[key],
            uid: this._utilsService.getUUID(),
            baseType: 'AssetGroup',
            type: 'AssetGroup',
            name: 'assets',
            nodeClass: 'asset',
            count: val,
            svgName: 'asset',
            category: 'Asset'
          }
          graphData.nodesObj[newAsset.uid] = newAsset;
        }
      })
    }
    if (resData.location.filters && resData.location.filters.incident) {
      _.forOwn(resData.location.filters.incident, (val, key) => {
        if (graphData.nodesObj[key]) {
          newAlert = {
            ...graphData.nodesObj[key],
            uid: this._utilsService.getUUID(),
            baseType: 'AlertGroup',
            type: 'AlertGroup',
            name: 'Alerts',
            nodeClass: 'incident',
            count: val,
            svgName: 'alert',
            category: 'Eta'
          }
          graphData.nodesObj[newAlert.uid] = newAlert;
        }
      })
    }
    return graphData
  }
  // After the initial data load, count excursions on shipment and location nodes
  setConnectionMemos(graphData, resData) {
    const weekAgo = moment().valueOf() - 604800000;
    const timeOfInterest = moment('20200827', 'YYYYMMDD').valueOf();
    // const locCounts = resData.location.filters;
    if (!_.size(graphData.nodesObj)) {
      return graphData;
    }
    _.forOwn(graphData.nodesObj, (node, key) => {
      graphData = this.addLocusAndValuesToIncidentNodes(node, graphData, key);
      graphData = this.addLocusToAssetNodes(node, graphData, key);
      graphData = this.addLocusToInventoryNodes(node, graphData, key);

      graphData = this.addNodeConnections(node, graphData, key);

      graphData = this.addCountsToMapNodes(node, graphData, key, weekAgo);
      // } else {
      //   graphData = this.addCountsToMapNodesFromTotals(node, graphData, locCounts);
      // }
    });

    _.forOwn(graphData.nodesObj, (node, key) => {
      graphData = this.setFilterTypes(node, graphData, key);
    });
    // console.log('categorysObj', this.categorysObj);
    // console.log('inventorysObj', this.inventorysObj);
    // console.log('locationsObj', this.locationsObj);
    // console.log('numAlerts', this.numAlerts);
    return graphData;
  }

  // For adding asset counts from totals in API to scale for large sets of Assets > 2K
  addCountsToMapNodesFromTotals(node, graphData, locCounts) {
    // console.log('add assetObj', locCounts['asset'], locCounts['asset'][node.uid], locCounts, node);
    if (locCounts['asset'] && locCounts['asset'][node.uid]) {
      graphData.nodesObj[node.uid].assetObj = graphData.nodesObj[node.uid].assetObj || {}
      graphData.nodesObj[node.uid].assetObj.count = locCounts['asset'][node.uid];
      graphData.nodesObj[node.uid].assetObj.show = true;
    }
    if (locCounts['incident'] && locCounts['incident'][node.uid]) {
      graphData.nodesObj[node.uid].alertsObj = graphData.nodesObj[node.uid].alertsObj || {}
      graphData.nodesObj[node.uid].alertsObj.count = locCounts['incident'][node.uid];
      graphData.nodesObj[node.uid].alertsObj.show = true;
      // console.log('allert count', graphData.nodesObj[node.uid].alertsObj.count, graphData.nodesObj[node.uid]);
    }
    return graphData;
  }
  // countListObjs(node, graphData, key) {
  //   if (node.nodeClass === 'inventory' && node.locationObj) {
  //     console.log('inv node', node.locationObj.listObj, node);
  //   }
  //   if (node.nodeClass === 'inventory' && node.shipmentObj) {
  //     console.log('inv node ship', node.shipmentObj.listObj, node);
  //   }
  //   return graphData;
  // }
  addCountsToMapNodes(node, graphData, key, timeOfInterest) {
    let alertType, filterType, invType, linkID, dateKey, vals, locKey;
    if (node.nodeClass === 'shipment') {

      // console.log('incident source id not found', node, locKey, graphData);
      const originId = node.properties.shipment_consignee_supplier;
      if (graphData.nodesObj[originId]) {
        graphData.nodesObj[originId].shipmentOrigins = graphData.nodesObj[originId].shipmentOrigins || [];
        graphData.nodesObj[originId].shipmentOrigins.push(node);

      } else {
        console.log('not found', node, originId, graphData.nodesObj);
      }
      const destinationId = node.properties.shipment_consignee_receiver;
      if (graphData.nodesObj[destinationId]) {
        graphData.nodesObj[destinationId].shipmentDestinations = graphData.nodesObj[destinationId].shipmentDestinations || [];
        graphData.nodesObj[destinationId].shipmentDestinations.push(node);

      } else {
        console.log('not found', node, destinationId, graphData.nodesObj);
      }

    } else if (node.nodeClass === 'inventory' && node.properties.at) {
      locKey = node.properties.at;
      if (graphData.nodesObj[locKey]) {
        graphData.nodesObj[locKey].inventoryObj = graphData.nodesObj[locKey].inventoryObj || {}
        graphData.nodesObj[locKey].inventoryObj.count = graphData.nodesObj[locKey].inventoryObj.count || 0;
        graphData.nodesObj[locKey].inventoryObj.count += +graphData.nodesObj[key].properties['quantity'];
        // graphData.nodesObj[locKey].inventoryObj.count += +graphData.nodesObj[key].properties[graphData.nodesObj[key].name];
        // invType = graphData.nodesObj[key].name.split(':')[0].toLowerCase();
        graphData.nodesObj[locKey].inventoryObj.list = graphData.nodesObj[locKey].inventoryObj.list || [];
        graphData.nodesObj[locKey].inventoryObj.list.push({
          uid: graphData.nodesObj[key].properties.id,
          name: graphData.nodesObj[key].properties.name
        });
        invType = graphData.nodesObj[key].properties.type;
        graphData.nodesObj[locKey].inventoryObj.typeCounts = graphData.nodesObj[locKey].inventoryObj.typeCounts || {};
        graphData.nodesObj[locKey].inventoryObj.typeCounts[invType] = graphData.nodesObj[locKey].inventoryObj.typeCounts[invType] || 0;
        graphData.nodesObj[locKey].inventoryObj.typeCounts[invType] += +graphData.nodesObj[key].properties['quantity'];
        // graphData.nodesObj[locKey].inventoryObj.typeCounts[invType] += +graphData.nodesObj[key].properties[graphData.nodesObj[key].name];
        // graphData = this.addLocOrShipment(node, graphData, graphData.nodesObj[locKey]);

        // graphData.nodesObj[node.uid].locationObj = graphData.nodesObj[node.uid].locationObj || {}
        // graphData.nodesObj[node.uid].locationObj.count = graphData.nodesObj[node.uid].locationObj.count ? graphData.nodesObj[node.uid].locationObj.count + 1 : 1;

      } else {
        console.log('inv source id not found', node, locKey, graphData);
      }
    } else if (node.nodeClass === 'incident') {
      locKey = node.properties.fromId || node.properties.at || String(node.properties.id).split(':')[1];
      if (graphData.nodesObj[locKey]) {
        graphData.nodesObj[locKey].alertsObj = graphData.nodesObj[locKey].alertsObj || {}
        graphData.nodesObj[locKey].alertsObj.list = graphData.nodesObj[locKey].alertsObj.list || [];
        graphData.nodesObj[locKey].alertsObj.list.push({
          id: graphData.nodesObj[key].properties.id,
          name: graphData.nodesObj[key].properties.name.split(':')[0],
          value: graphData.nodesObj[key].properties.value
        });
        graphData.nodesObj[locKey].alertsObj.count = graphData.nodesObj[locKey].alertsObj.count + 1 || 1;
        alertType = graphData.nodesObj[key].name.split(':')[0].toLowerCase();
        // filterType = graphData.nodesObj[key].name.split(':')[1];
        filterType = graphData.nodesObj[key].properties.cid;
        graphData.nodesObj[locKey].alertsObj.typeCounts = graphData.nodesObj[locKey].alertsObj.typeCounts || {};
        graphData.nodesObj[locKey].alertsObj.typeCounts[alertType] = graphData.nodesObj[locKey].alertsObj.typeCounts[alertType] + 1 || 1;
        graphData.nodesObj[locKey].alertsObj.filterCounts = graphData.nodesObj[locKey].alertsObj.filterCounts || {};
        graphData.nodesObj[locKey].alertsObj.filterCounts[filterType] = graphData.nodesObj[locKey].alertsObj.filterCounts[filterType] + 1 || 1;
        this.numAlerts++;
        // graphData = this.addLocOrShipment(node, graphData, graphData.nodesObj[locKey]);

        // graphData.nodesObj[node.uid].locationObj = graphData.nodesObj[node.uid].locationObj || {}
        // graphData.nodesObj[node.uid].locationObj.count = graphData.nodesObj[node.uid].locationObj.count ? graphData.nodesObj[node.uid].locationObj.count + 1 : 1;

        linkID = locKey + '__' + key;
        if (graphData.bisectorsObj[linkID]) {
          vals = this.parseValues(graphData.bisectorsObj[linkID].value, timeOfInterest);
          if (vals && vals.length) {
            graphData.nodesObj[locKey].alertsObj.days = graphData.nodesObj[locKey].alertsObj.days || {};
            vals.forEach(val => {
              dateKey = "" + val.date;
              graphData.nodesObj[locKey].alertsObj.days[dateKey] = graphData.nodesObj[locKey].alertsObj.days[dateKey] ?
                graphData.nodesObj[locKey].alertsObj.days[dateKey] + 1 : 1;
            })
          }
        } else {
          console.warn('bisector not found', linkID);
        }
      }

    } else if (node.nodeClass === 'asset') {
      locKey = node.properties.locationId || node.properties.container_consignee_assignee;
      if (graphData.nodesObj[locKey]) {
        graphData.nodesObj[locKey].assetObj = graphData.nodesObj[locKey].assetObj || {}
        graphData.nodesObj[locKey].assetObj.count = graphData.nodesObj[locKey].assetObj.count ? +graphData.nodesObj[locKey].assetObj.count + 1 : 1;
        // graphData = this.addLocOrShipment(node, graphData, graphData.nodesObj[locKey]);
        // alertType = graphData.nodesObj[key].name.split(':')[0].toLowerCase();
        // graphData.nodesObj[locKey].assetObj.typeCounts = graphData.nodesObj[locKey].assetObj.typeCounts || {};
        // graphData.nodesObj[locKey].assetObj.typeCounts[alertType] = graphData.nodesObj[locKey].assetObj.typeCounts[alertType] + 1 || 1;
        graphData.nodesObj[locKey].assetObj.typeCounts = graphData.nodesObj[locKey].assetObj.typeCounts || {};
        graphData.nodesObj[locKey].assetObj.stateCounts = graphData.nodesObj[locKey].assetObj.stateCounts || {};
        filterType = graphData.nodesObj[key].properties.state;
        graphData.nodesObj[locKey].assetObj.typeCounts[filterType] = graphData.nodesObj[locKey].assetObj.typeCounts[filterType] + 1 || 1;
        graphData.nodesObj[locKey].assetObj.stateCounts[filterType] = graphData.nodesObj[locKey].assetObj.stateCounts[filterType] + 1 || 1;
        filterType = graphData.nodesObj[key].properties.status;
        graphData.nodesObj[locKey].assetObj.typeCounts[filterType] = graphData.nodesObj[locKey].assetObj.typeCounts[filterType] + 1 || 1;
        filterType = graphData.nodesObj[key].properties.type;
        graphData.nodesObj[locKey].assetObj.typeCounts[filterType] = graphData.nodesObj[locKey].assetObj.typeCounts[filterType] + 1 || 1;

        graphData.nodesObj[locKey].assetObj.list = graphData.nodesObj[locKey].assetObj.list || [];
        graphData.nodesObj[locKey].assetObj.list.push({
          uid: graphData.nodesObj[key].properties.id,
          name: graphData.nodesObj[key].properties.name
        });
      }

    }
    return graphData;
  }
  // addLocOrShipment(node, graphData, locNode) {
  //   let filterType = graphData.nodesObj[locNode.uid].properties.type, typeObj;
  //   if (locNode.nodeClass === 'shipment') {
  //     filterType = graphData.nodesObj[locNode.uid].properties.status;
  //     typeObj = 'shipmentObj';
  //   } else if (locNode.nodeClass === 'location') {
  //     filterType = graphData.nodesObj[locNode.uid].properties.type;
  //     typeObj = 'locationObj';
  //   }
  //   graphData.nodesObj[node.uid][typeObj] = graphData.nodesObj[node.uid][typeObj] || {}
  //   graphData.nodesObj[node.uid][typeObj].list = graphData.nodesObj[node.uid][typeObj].list || [];
  //   graphData.nodesObj[node.uid][typeObj].listObj = graphData.nodesObj[node.uid][typeObj].listObj || {};
  //   graphData.nodesObj[node.uid][typeObj].listObj[locNode.uid] = locNode;
  //   graphData.nodesObj[node.uid][typeObj].list.push(locNode);
  //   graphData.nodesObj[node.uid][typeObj].count = graphData.nodesObj[node.uid][typeObj].count ? +graphData.nodesObj[node.uid][typeObj].count + 1 : 1;
  //   graphData.nodesObj[node.uid][typeObj].typeCounts = graphData.nodesObj[node.uid][typeObj].typeCounts || {};
  //   graphData.nodesObj[node.uid][typeObj].typeCountsObj = graphData.nodesObj[node.uid][typeObj].typeCountsObj || {};
  //   graphData.nodesObj[node.uid][typeObj].typeCountsObj[locNode.uid] = locNode;
  //   graphData.nodesObj[node.uid][typeObj].typeCounts[filterType] = graphData.nodesObj[node.uid][typeObj].typeCounts[filterType] || 0;
  //   graphData.nodesObj[node.uid][typeObj].typeCounts[filterType]++;
  //   return graphData
  // }
  parseValues(vals, timeOfInterest) {
    let arrVals = [], dateSlot, slotUnit = 1000 * 60 * 60 * 24;
    _.forOwn(vals, (val, millis) => {
      if (!isNaN(val)) {
        dateSlot = Math.round((timeOfInterest - +millis) / slotUnit);
        arrVals.push({
          date: dateSlot,
          val: +val
        })
      }
    })
    return arrVals;
  }
  addLocusToAssetNodes(node, graphData, key) {
    if (node.nodeClass === 'asset') {
      if (node.properties.locationId && graphData.nodesObj[node.properties.locationId]
        // && _.size(graphData.nodesObj[node.properties.locationId].locus)
      ) {
        graphData.nodesObj[key].locus = { ...graphData.nodesObj[node.properties.locationId].locus };
      }
    }
    return graphData
  }
  addLocusAndValuesToIncidentNodes(node, graphData, key) {
    let linkID,
      weekAgo = moment().valueOf() - 604800000
      ;
    if (node.nodeClass === 'incident') {
      this.categorysObj[node.name] = this.categorysObj[node.name] ? this.categorysObj[node.name] + 1 : 1;
      // graphData.nodesObj[key].template = 'incident';
      graphData.nodesObj[node.uid].svgName = this._iconSvgService.getRuleIcon(node.properties.sid);//node.name.split(':')[0].toLowerCase();
      _.forOwn(node.sourceIDs, (val, id) => {
        if (graphData.nodesObj[id] && graphData.nodesObj[id].locus) {
          graphData.nodesObj[key].locus = graphData.nodesObj[id].locus;
          graphData.nodesObj[key].position = graphData.nodesObj[id].position;
        }
        linkID = id + '__' + key;
        graphData.nodesObj[key].time = graphData.bisectorsObj[linkID] ? graphData.bisectorsObj[linkID].time : '';
        if (graphData.bisectorsObj[linkID]) {
          graphData.nodesObj[key].values = graphData.nodesObj[key].values || [];
          graphData.nodesObj[key].values.push(this.parseValues(graphData.bisectorsObj[linkID].value, weekAgo));
        }
      })
    }
    return graphData;
  }
  addLocusToInventoryNodes(node, graphData, key) {
    if (node.nodeClass === 'inventory') {
      this.inventorysObj[node.category] = this.inventorysObj[node.category] ? this.inventorysObj[node.category] + 1 : 1;
      const id = (node.properties) ? node.properties.at : '';
      if (graphData.nodesObj[id] && graphData.nodesObj[id].locus) {
        graphData.nodesObj[key].locus = graphData.nodesObj[id].locus;
        graphData.nodesObj[key].position = graphData.nodesObj[id].position;
        graphData.nodesObj[key].sourceType = graphData.nodesObj[id].category;
      }
    }
    return graphData;
  }
  addNodeConnections(node, graphData, key) {
    if (!node.properties) {
      return graphData;
    }
    if (node.properties.container_consignee_assignee) {
      graphData = this.addSource(graphData, key, node.properties.container_consignee_assignee);
    } else if (node.properties.at) {
      graphData = this.addSource(graphData, key, node.properties.at);
    } else if (node.properties.fromId) {
      graphData = this.addSource(graphData, key, node.properties.fromId);
    }
    if (node.properties.shipment_consignee_supplier) {
      graphData = this.addSource(graphData, key, node.properties.shipment_consignee_supplier);
    }
    if (node.properties.shipment_consignee_receiver) {
      graphData = this.addTarget(graphData, key, node.properties.shipment_consignee_receiver);
    }
    return graphData;
  }
  addSource(graphData, key, id) {
    if (!graphData.nodesObj[key].sourceIDs[id]) {
      graphData.nodesObj[key].sourceIDs[id] = true;
      graphData = this.addLink(id, key, graphData);
    }
    if (graphData.nodesObj[id]) {
      graphData.nodesObj[key].sourceType = graphData.nodesObj[id].category;
      graphData.nodesObj[key].sourceClass = graphData.nodesObj[id].nodeClass;
      graphData.nodesObj[key].sourceStatus = graphData.nodesObj[id].properties.status;
    }
    return graphData;
  }
  addTarget(graphData, key, id) {
    if (!graphData.nodesObj[key].targetIDs[id]) {
      graphData.nodesObj[key].targetIDs[id] = true;
      graphData = this.addLink(key, id, graphData);
    }
    if (graphData.nodesObj[id]) {
      graphData.nodesObj[key].targetType = graphData.nodesObj[id].category;
    }
    return graphData;
  }
  addLink(from, to, graphData) {
    const edge = {
      from: from,
      to: to
    }
    let newLink = this.convertEdge(edge, graphData);
    newLink = Object.assign({}, this.linkTemplate, newLink);
    graphData.bisectorsObj[newLink.uid] = newLink;
    return graphData;
  }
  setFilterTypes(node, graphData, key) {
    if (!graphData) {
      console.warn('no graph data', graphData);
      return graphData;
    }
    graphData.nodesObj[key].filterTypes = {
      ...graphData.nodesObj[key].filterTypes,
      [node.nodeClass]: true
    }
    _.forOwn(node.sourceIDs, (val, sourceKey) => {
      if (graphData.nodesObj[sourceKey]) {
        graphData.nodesObj[key].filterTypes[graphData.nodesObj[sourceKey].nodeClass] = true;
      }
    })
    _.forOwn(node.targetIDs, (val, sourceKey) => {
      if (graphData.nodesObj[sourceKey]) {
        graphData.nodesObj[key].filterTypes[graphData.nodesObj[sourceKey].nodeClass] = true;
      }
    })
    if (node.alertsObj && node.alertsObj.count) {
      graphData.nodesObj[key].filterTypes['incident'] = true;
    }
    if (node.inventoryObj && node.inventoryObj.count) {
      graphData.nodesObj[key].filterTypes['inventory'] = true;
    }
    if (node.properties && node.properties.container_consignee_assignee &&
      graphData.nodesObj[node.properties.container_consignee_assignee]) {
      graphData.nodesObj[node.properties.container_consignee_assignee].filterTypes[node.nodeClass] = true;
    }
    if (node.properties && node.properties.shipment_consignee_supplier && graphData.nodesObj[node.properties.shipment_consignee_supplier]) {
      graphData.nodesObj[node.properties.shipment_consignee_supplier].filterTypes[node.nodeClass] = true;
    }
    if (node.properties && node.properties.shipment_consignee_receiver && graphData.nodesObj[node.properties.shipment_consignee_receiver]) {
      graphData.nodesObj[node.properties.shipment_consignee_receiver].filterTypes[node.nodeClass] = true;
    }
    return graphData;
  }

  importNodesForMap(vertices, graphData) { // Use this for nodes on canvas for relative positioning
    if (!vertices || !vertices.length) {
      return graphData;
    }
    let id;
    vertices.forEach(node => {
      id = node.uid || node.id
      graphData.nodesObj[id] = Object.assign({}, node);
      graphData.nodesObj[id].svgName = this._iconSvgService.getSVGName(node, node.type);

    });

    return graphData;
  }
  importNodes(vertices, graphData) { // Use this for nodes on canvas
    if (!vertices || !vertices.length) {
      return graphData;
    }
    vertices.forEach(vertice => {
      if (vertice.position) {
        this.setExtents(vertice.position);
      }
    });

    this.setScale();
    vertices.forEach(node => {
      node = this.setXY(node);
      node.svgName = this._iconSvgService.getSVGName(node, node.type);
      if (!graphData.nodesObj[node.uid]) {
        graphData.nodesObj[node.uid] = node;
      } else {
        graphData.nodesObj[node.uid] = Object.assign(
          {},
          graphData.nodesObj[node.uid],
          node,
          {
            x: graphData.nodesObj[node.uid].x || 50,
            y: graphData.nodesObj[node.uid].y || 50
          }
        );
      }
    });

    return graphData;
  }
  getNodeClass(node, template) {
    let orgToken = node.category ? node.category.toLowerCase() : ''
    let nodeClass = this.nodeClassList[template] || this.nodeClassList[orgToken] || 'trigger';

    return nodeClass;
  }
  resetNodeClasses() {
    this.allNodeClasses = {};
  }
  getKLNodeClass(vertice) {
    let nodeClass = vertice.template;
    if (vertice.d) {
      if (vertice.d.baseType === 'BaseNode') {
        nodeClass = this.nodeClassList[vertice.d.category.toLowerCase()] || vertice.d.category;
      } else if (vertice.d.baseType && this.nodeClassList[vertice.d.baseType.toLowerCase()]) {
        nodeClass = this.nodeClassList[vertice.d.baseType.toLowerCase()];
      } else if (vertice.d.classType && this.nodeClassList[vertice.d.classType.toLowerCase()]) {
        nodeClass = this.nodeClassList[vertice.d.classType.toLowerCase()];
      } else if (vertice.clfEntityType && this.nodeClassList[vertice.clfEntityType.toLowerCase()]) {
        nodeClass = this.nodeClassList[vertice.clfEntityType.toLowerCase()];
      }
    } else if (this.nodeClassList[vertice.template]) {
      nodeClass = this.nodeClassList[vertice.template];
    }
    this.allNodeClasses[nodeClass] = this.allNodeClasses[nodeClass] ? this.allNodeClasses[nodeClass] + 1 : 1;
    // console.log('this.allNodeClasses', this.allNodeClasses);
    nodeClass = nodeClass ? nodeClass.toLowerCase() : '';
    return [nodeClass, this.allNodeClasses];
  }
  importLinks(edges, graphData) {
    if (!edges || !edges.length) {
      return graphData;
    }
    edges.forEach(link => {
      if (
        !graphData.nodesObj[link.sourceID] ||
        !graphData.nodesObj[link.targetID]
      ) {
        return;
      }
      const resultObj = this._linkService.configureLink(link, graphData);
      link = resultObj.linkObj;

      if (resultObj.linkAdded && link && link.uid && !graphData.bisectorsObj[link.uid]) {
        graphData.bisectorsObj[link.uid] = link;
      } else if (link && link.uid) {
        graphData.bisectorsObj[link.uid] = Object.assign(
          {},
          graphData.bisectorsObj[link.uid],
          link
        );
      }
    });

    return graphData;
  }
  convertNodes(vertices, graphData) {
    // this.numNonPositioned = 0;
    const newNodes = vertices.map(vertice => {
      if (vertice && vertice.properties && vertice.properties.position) {
        this.setExtents(vertice.properties.position);
      }
      return this.convertVertice(vertice);
    });
    this.setScale();
    newNodes.forEach(node => {
      node = this.setXY(node);
      if (node.uid && !graphData.nodesObj[node.uid]) {
        graphData.nodesObj[node.uid] = node;
        // if (node.position) {
        // graphData.nodes.push(node);
        // }
      }
    });
    return graphData;
  }
  setScale() {
    this.extents.diffX = Math.abs(this.extents.east - this.extents.west);
    this.extents.diffY = Math.abs(this.extents.south - this.extents.north);
    this.extents.diffX = this.extents.diffX || this.screenWidth;
    this.extents.diffY = this.extents.diffY || this.screenHeight;
    // console.log("this.extents", this.extents);
  }
  getX = function (posX) {
    let newX = (
      100 +
      ((posX - this.extents.west) * this.screenHeight) / this.extents.diffX
    ) || 250;
    newX = !isNaN(newX) ? newX : 250;
    return newX;
  };
  getY = function (posY) {
    let newY = 100 + ((this.extents.south - posY) * 300) / this.extents.diffY || 50;
    newY = !isNaN(newY) ? newY : 250;
    return newY < this.screenHeight ? newY : this.screenHeight;
  };
  setXY(item) {
    let node = { ...item };
    if (!node.locus) {
      const typeCount = node.nameCount || this.typeCounters[node.template] || 1;
      node.x = 50 + 100 * ((typeCount - 1) % 10);
      node.y = 50 + 100 * Math.floor((typeCount - 1) / 10);
      this.updateTypeCount(node);

      return node;
    }
    node.x = this.getX(+node.locus.lon);
    node.y = this.getY(+node.locus.lat);
    return node;
  }
  updateTypeCount(node) {
    this.typeCounters[node.template] = this.typeCounters[node.template] || 1;
    this.typeCounters[node.template]++;
    return this.typeCounters[node.template];
  }
  convertLinks(edges, graphData) {
    const newLinks = edges.forEach(edge => {
      let newLink = this.convertEdge(edge, graphData);
      newLink = Object.assign({}, this.linkTemplate, newLink);

      graphData.bisectorsObj[newLink.uid] = newLink;
      if (
        graphData.nodesObj[newLink.sourceID] &&
        graphData.nodesObj[newLink.sourceID].position &&
        graphData.nodesObj[newLink.targetID] &&
        graphData.nodesObj[newLink.targetID].position
      ) {
        graphData.links.push(newLink);
        // graphData.bisectors.push(newLink);
      }
    });
    return graphData;
  }
  importLinkedNodes(graphData) {
    _.forOwn(graphData.bisectorsObj, (link, key) => {
      if (graphData.nodesObj[link.targetID]) {
        graphData.nodesObj[link.targetID].sourceIDs = {
          ...graphData.nodesObj[link.targetID].sourceIDs,
          [link.sourceID]: true
        }
      }
      if (graphData.nodesObj[link.sourceID]) {
        graphData.nodesObj[link.sourceID].targetIDs = {
          ...graphData.nodesObj[link.sourceID].targetIDs,
          [link.targetID]: true
        }
      }
    });
    return graphData;
  }

  convertEdge(edge, graphData) {
    const newUID = edge.from + '__' + edge.to;
    edge = this.getConnectionDetails(edge, graphData);
    const link = {
      name: "connection",
      uid: newUID,
      bisectorID: newUID,
      type: "link",
      sourceID: edge.from,
      targetID: edge.to,
      connectionType: edge.connectionType,
      x: edge.x,
      y: edge.y,
      dx: 0,
      dy: 0,
      midX: 0,
      midY: 0
    };
    return link;
  }
  getConnectionDetails(edge, graphData) {
    let connectionType = "";

    if (
      edge.from &&
      edge.to &&
      graphData.nodesObj[edge.from] &&
      graphData.nodesObj[edge.to]
    ) {
      const sourceNode = graphData.nodesObj[edge.from];
      const targetNode = graphData.nodesObj[edge.to];
      edge.x = (sourceNode.x + targetNode.x) / 2;
      edge.y = (sourceNode.y + targetNode.y) / 2;
      if (sourceNode.hierarchy === targetNode.hierarchy) {
        connectionType = "peer-connection";
      } else if (sourceNode.template === "rule") {
        connectionType = "rule-connection";
      } else if (sourceNode.hierarchy < targetNode.hierarchy) {
        connectionType = "child-connection";
      }
    }
    edge.connectionType = connectionType;
    return edge;
  }


  convertVertice(vertice) {
    const node = Object.assign({}, this.nodeTemplate);
    // node.rid = vertice.rid;
    node.hierarchy = vertice.properties.hierarchy || 3;
    node.uid = vertice.id;
    node.name = vertice.properties.name;
    node.template = vertice.properties.type;
    node.category = vertice.properties.category;
    node.address = vertice.properties.address;
    if (vertice.properties.position) {
      const latLon = vertice.properties.position.split(",");
      if (!latLon[0].isNan() && !latLon[1].isNan()) {
        node.position = vertice.properties.position;

        node.locus = {
          lat: latLon[0],
          lon: latLon[1]
        };
      }
    }
    return node;
  }
  setExtents(position) {

    const pos = (typeof position === 'string') ? position.split(",") : position;

    this.extents.north =
      !this.extents.north || +pos[0] < +this.extents.north
        ? +pos[0]
        : +this.extents.north;
    this.extents.south =
      !this.extents.south || +pos[0] > +this.extents.south
        ? +pos[0]
        : +this.extents.south;
    this.extents.east =
      !this.extents.east || +pos[1] > +this.extents.east
        ? +pos[1]
        : +this.extents.east;
    this.extents.west =
      !this.extents.west || +pos[1] < +this.extents.west
        ? +pos[1]
        : +this.extents.west;
  }

}
