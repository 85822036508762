<div class="cl-activity mt10">
  <div class="row justify-content-start">
    <div class="col-4 text-start">
      <span class="pl-4 pr-4 pt-1 pb-1 cursor-pointer" style="margin-right:1px" (click)="navigate(i, tab)" *ngFor="let tab of tabs; index as i;"
      [ngClass]="{'bgcolor4 color2': activeTabIndex === i , 'bgcolor9 color4': activeTabIndex !== i, 'rounded-start': i === 0, 'rounded-end': tabs.length-1 === i}"
      [textContent]="tab.name"></span>
    </div>
    <div class="col-4 text-end">
      <span *ngIf="currentMeasure?.measureCharacteristicId === rssiCharacteristic">
        <div class="dropdown inline-block" *ngIf="receivers?.length>1">
          <button class="mr-[20px] cursor-pointer btn-link" (click)="isGatewayMenuOpen=true">
            <img src="assets/svgs/filter-options.svg" class="inline-block">
            <span class="color4 relative top-[3px]">Gateway filters</span>
          </button>
            <span class="dropdown-menu p-[10px]" [ngClass]="{'show': isGatewayMenuOpen}" (mouseleave)="isGatewayMenuOpen=false;">
              <div class="min-h-[20px] max-h-[100px] overflow-auto">
                <ul class="list-unstyled" class="list-unstyled truncate ...">
                  <li [title]="receiver.name" *ngFor="let receiver of receivers; let i = index">
                    <mat-checkbox [(ngModel)]="receiver.checked" (change)="setRssiGraphData()"></mat-checkbox>
                    <span [style.color]="receiver.color" class="fa fa-circle pl-1"></span>
                    <span [textContent]="receiver.name" class="ml-[5px]"></span>
                  </li>
                </ul>
              </div>
          </span>
        </div>
        <div class="dropdown inline-block" *ngIf="receivers?.length>0">
          <button class="mr-[10px] cursor-pointer btn-link" (click)="isEventsMenuOpen=true">
            <img src="assets/svgs/filter-options.svg" class="inline-block">
            <span class="color4 relative top-[3px]">Event filters</span>
          </button>
          <span class="dropdown-menu p-[10px]" [ngClass]="{'show': isEventsMenuOpen}" (mouseleave)="isEventsMenuOpen=false;">
            <ul class="list-unstyled">
              <li *ngFor="let eventType of eventTypes; let i = index">
                <mat-checkbox [(ngModel)]="eventType.checked" (change)="setRssiGraphData()"></mat-checkbox>
                <span [style.color]="eventType?.graphProps?.fillColor" class="fa ml-[5px]" [ngClass]="['fa-'+eventType?.graphProps?.shape]"></span>
                <span [textContent]="eventType.name" class="capitalize ml-[5px]"></span>
              </li>
            </ul>
          </span>
        </div>
      </span>
      <span class="color4 invisible"><span class="fa fa-download"></span><span class="ml5 rt1 mr20">export</span></span>
    </div>
    <div class="col-4 text-start" *ngIf="activeTab?.name !== assetsTabName">
      <cl-date-picker [disablePreviousdates]="preventDates"  [dateRange]="selectedDateRange"   [maxDate]="maxDate"  [ranges]="ranges" (dateChanged)="datesUpdated($event)"></cl-date-picker>
    </div>
  </div>
  <div>
    <div class="h-0" *ngIf="loading">
      <div class="relative inset-x-1/2 inset-y-1/2"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
    </div>
    <div *ngIf="activeTab?.measures?.length !== 0" class="pt20 mt20">
      <cl-characteristic-list [measures]="activeTab?.measures" [data]="data"
        (change)="measureChanged($event)" [tooltip]="tooltipCustomizer"></cl-characteristic-list>
    </div>
    <div *ngIf="activeTab?.name === assetsTabName" class="pt20 mt20 w-100">
      <ngx-datatable class="h-calc-40 w-100" [rows]="sensorObject?.assignments" [columns]="columnDefs"
      [columnMode]="'force'" [reorderable]="false">
      </ngx-datatable>
    </div>
  </div>
</div>
<ng-template #taggedAssetBoundDate let-row="row" let-value="value" let-i="index">
  <span class="font-12-bold" [textContent]="value | date : 'dd.MM.YYYY'"></span>
</ng-template>
<ng-template #taggedAssetBoundTime let-row="row" let-value="value" let-i="index">
  <span class="font-12-bold" [textContent]="value | date:'shortTime'"></span>
</ng-template>
<ng-template #assetName let-row="row" let-value="value" let-i="index">
  <span *ngIf="row.status==='Bound'" [routerLink]="'/assetdetails/' + row?.assetId" [title]="value">
    <a class="font-12-bold" href="javascript:void(0)">{{value}}</a>
  </span>
  <span class="color36" *ngIf="row.status==='Unbound'" [title]="value">{{value}}</span>
</ng-template>
