import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { EventTab, Gateway, GatewayEvent, GatewayEvents } from '@cl/@types/gateway.type';
import * as _ from 'lodash';
import * as moment from 'moment';
import { GatewayUtilService } from '../../gateway-util.service';
import { FormateDatePipe } from "@cl/common/pipes/formate-date.pipe";

@Component({
  selector: 'cl-gateway-activity',
  templateUrl: './gateway-activity.component.html',
  styleUrls: ['./gateway-activity.component.scss']
})
export class GatewayActivityComponent implements OnChanges{
  private isFirstTimeExecution: boolean;
  activeTabIndex: number;
  activeTab: EventTab;
  currentEvent: GatewayEvent;
  @Input('gateway') gatewayObject:Gateway;
  selectedDateRange: {startDate: moment.Moment, endDate: moment.Moment};
  ranges: any;
  data: any[];
  tooltipCustomizer : any =  null;
  reasonCodes: any;
  constructor(private gatewayUtilService: GatewayUtilService, private formateDatePipe: FormateDatePipe) {
    this.isFirstTimeExecution = true;
    this.ranges = {
      'Last 1 Hour' : [moment().subtract(1, 'hour'), moment()],
      'Last 2 Hours': [moment().subtract(2, 'hour'), moment()],
      'Last 4 Hours': [moment().subtract(4, 'hour'), moment()],
      'Last 30 Minutes': [moment().subtract(30, 'minute'), moment()]
    };
    this.selectedDateRange = { startDate: moment().subtract(4, 'hours'), endDate: moment()};
    this.reasonCodes = this.gatewayUtilService.getReasonCodes();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('gatewayObject') && !_.isEmpty(this.gatewayObject?.name) && this.isFirstTimeExecution){
      this.isFirstTimeExecution = false;
      this.navigate(0, this.gatewayObject.eventTabs[0])
    }
  }
  navigate(index: number, tab: EventTab){
    this.activeTabIndex = index;
    this.activeTab = tab;
    this.currentEvent = this.activeTab.events[0];
    this.gatewayEventChanged(this.currentEvent);
  }
  datesUpdated(datesUpdated){
    this.selectedDateRange = datesUpdated;
    this.getChangedGatewayEventData();
  }
  gatewayEventChanged(event: GatewayEvent){
    this.currentEvent = event;
    this.getChangedGatewayEventData();
  }
  getGraphYAxesLabel(keyName: string) {
    return this.gatewayUtilService.getYAxeslabel(keyName);
  }
  private getGatewayGraphData(currentEvent, startDate, endDate) {
    if(currentEvent.name !== 'system.restart.time') {
      return this.gatewayUtilService.getGatewaySelectedAttributeEventData(currentEvent, startDate, endDate);
    } else {
      return this.getDeviceRebootData(currentEvent, startDate, endDate);
    }
  }

  private getDeviceRebootData(currentEvent, startDate, endDate) {
    return new Promise( (resolve) =>{
      this.gatewayUtilService.getGatewaySelectedAttributeEventData(currentEvent, startDate, endDate)
        .then((result : [number, string][]) => {
          if(!_.isEmpty(result)){
            //this.data = [ { name: this.currentEvent.displayName, data: result } ];
            let sysTab = _.find(this.gatewayObject.eventTabs, {name: "SYSTEM"});
            let rebootEvent = _.find(sysTab.events, {name: currentEvent.dependencyMetric});
            this.gatewayUtilService.getGatewaySelectedAttributeEventData(rebootEvent, startDate, endDate)
              .then((response : [number, string][]) => {
                let finalData = [];
                let restartTimeData = _.map(result, (datum) => {
                  let restartCodeMatch = _.find(response, (rebootCode) => {
                    if(rebootCode[0] === datum[0]) {
                      finalData.push([datum[0], 1, rebootCode[1]]);
                    }
                  })
                })
                resolve(finalData);
              })
          }
        })
    });
  }
  private getChangedGatewayEventData(){
    if(this.isFirstTimeExecution === false && !_.isEmpty(this.currentEvent) && this.currentEvent.displayType === 'graph'){
      this.data = [];
      this.getGatewayGraphData(this.currentEvent, this.selectedDateRange.startDate, this.selectedDateRange.endDate)
      .then((result : [number, string][]) => {
        if(!_.isEmpty(result)){
          this.data = [ { name: this.currentEvent.displayName, data: result } ];
          this.customToolTip();
        }
      });
    }
  }

  customToolTip(){
    if(this.currentEvent.name !== 'system.restart.time') {
      this.tooltipCustomizer = null;
    } else {
      this.tooltipCustomizer = ({series, seriesIndex, dataPointIndex, w}) =>{
        let tooltipHtml = null;
        if(!_.isEmpty(this.data)){
          let tempData: any = this.data[seriesIndex].data[dataPointIndex];
          let desc = this.reasonCodes[tempData[2]] ? (this.reasonCodes[tempData[2]].desc || this.reasonCodes[tempData[2]].shortDesc) : "";
          tooltipHtml = '<div class=" ">'
            + '<div class="font-bold bgcolor38 p-2 text-center">Reboot Details</div>'
            + '<div class="p-2 flex items-center"><span class="inline-block w-3 rounded-full bgcolor4"></span> <span class="pl-2">'+ desc +' [</span><span class="font-bold">'+ tempData[2] + ']</span></div>'
            + '<div class="p-2 flex items-center"><span class="pl-5">Time :</span><span class="font-bold pl-2">'+ this.formateDatePipe.transform(tempData[0], 'default') +'</span></div>'
            + '</div>';
        }
        return tooltipHtml;
      }
    }
  }
}
