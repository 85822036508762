import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';

@Injectable({
  providedIn: 'root'
})
export class BulkActionsService {

  bindMenu = [
    'Bulk bind',
    'Bulk unbind'
  ];
  addMenu = [
    'Bulk add',
    'Bulk delete'
  ];
  icon = {
    'asset': 'assets/svgs/circle-icons-white/asset.svg',
    'sensor': 'assets/svgs/circle-icons-white/sensor.svg',
    'user': 'assets/svgs/circle-icons-white/users.svg',
    'sku': 'assets/svgs/circle-icons-white/CLF_UI__sensor-1_blue.svg',
    'inventory': 'assets/svgs/circle-icons-white/inventory.svg'
  }
  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService
  ) { }

  uploadBulkActionFile(payload) {
    if (payload.method === 'POST') {
      let headers = new HttpHeaders({ 'Accept': '*/*'});
      return this.http.post(`${this.apiProvider.getAPIUrl(payload.appName)}${payload.uploadUrl}`, payload.file);
    } else if (payload.method === 'DELETE') {
      return this.http.delete(`${this.apiProvider.getAPIUrl(payload.appName)}${payload.uploadUrl}`, {body: payload.file});
    }
    return null;
  }
}
