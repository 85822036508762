<div class="sensor-list">
<div class="mt-2 mb-2">
    <span class="page-title">{{isTracker ? 'Trackers' : 'Sensors'}}</span>
</div>
<cl-table-surtitle [rows]="showingHits" [selected]="selected?.length" [total]="totalHits" [pageName]="pageName"></cl-table-surtitle>
<!-- <div class="red alert-msg" *ngIf="attentionSensors">{{attentionSensors}} {{isTracker ? 'Trackers' : 'Sensors'}} needs your attention</div> -->
<div class="sensor-febric wt-ngx-grid-wrapper h-calc-40 list-page-wrapper relative list-page px-2">
    <div class="flex justify-between pt-1 pb-1">
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer color4 list-page_filter-toggle flex items-center">
              <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
              filter options
            </span>
            <cl-list-search [placeholder]="'Search by Name or ID'" (search)="updateFilter($event)"
                [columns] = "['Device ID']"></cl-list-search>
            <button [disabled]="editDisabled" [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 mr10" (click)="updateDevice()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>
            <button [disabled]="deleteDisabled" [clIsValidAction]="actionBtnPolicies.delete"
                [ngClass]="[deleteDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 mr10" (click)="deleteDevice()">
                <i class="fa fa-trash"></i>
                Delete
            </button>
            <button [disabled]="bindDisabled"
                [ngClass]="[bindDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                [clIsValidAction]="bindAction === 'Bind' ? actionBtnPolicies.bind : actionBtnPolicies.unbind"
                class="p-4-10 border-general br2" (click)="bindOrUnbind()">
                <i class="fa" [ngClass]='bindIcon'></i>
                {{bindAction}}
            </button>
            <button [disabled]="bindAction !== 'Unbind'"
                [clIsValidAction]="actionBtnPolicies.replace"
                [ngClass]="[bindAction !== 'Unbind' ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 ml10" (click)="replaceSensor()">
                <i class="fa fa-retweet"></i>
                Replace
            </button>
        </div>
        <div class="sensor-febric__action-panel-right action-panel-right self-end flex items-center mr-16">
            <!--temp hide-->
            <!-- <span class="dark-blue-text action-separator colimn-serach-btn" (click)="showHideFilter()"><i
                class="fa fa-search-plus color4"></i></span>  -->
                <div><button (click)="showHideFilter()" class="toggler-example align-grid-menu p-2 border-0 py-2 colsearch-menu">
                    <span class="dark-blue-text" title="Search data by table columns">
                      <i _ngcontent-bum-c250="" class="fa fa-search-plus color4"></i>
                    </span>
                  </button>
                </div>
            <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="catalogType">
            </cl-grid-column-toggle>
        </div>
        <!-- {{sidePanelsObj | json}} -->
        <div
        class="md-add-button list add-shipment-btn" style="z-index: 999" [clIsValidAction]="actionBtnPolicies.add">
        <div class="add-button"
          *ngIf="
                !sidePanelsObj.add &&
                !sidePanelsObj.bind &&
                !sidePanelsObj.bulk &&
                !sidePanelsObj.replace &&
                !sidePanelsObj.edit &&
                !sidePanelsObj.summary"
          (click)="addDevice()"
        >
          <span class="fa fa-plus"></span>
        </div>
        <div class="add-button" *ngIf="sidePanelsObj.add || sidePanelsObj.edit" (click)="closeSidePanel()">
          <span class="fa fa-close"></span>
        </div>
      </div>
    </div>
    <div class="h-calc-40">
        <main class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth - 10">
            <div class="grow-0" *ngIf="showFilterPanel">
                <cl-list-filter class="nav panel scroll-y grow-0" [hideTitle]="true" [showCounts]="true" [title]="''"
                    heightClass="none" (panelsEmitter)="getPanelUpdate($event)" (toggleFilterPanel)="toggleFilterPanel()" [filters]="filters"
                    [caseSenstiveFilter]="'type'" [actionCount]="actionCount">
                </cl-list-filter>
            </div>
            <div class="grid-container grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div *ngIf="delayedRefreshStart">
                    <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                    (refreshList)="getSensorsList()"
                    ></cl-refresh-countdown>
                </div>
                <div class="h-100" [style.width.px]="tableWidth">
                    <ngx-datatable #sensorList class="h-calc-40" [rows]="rows" [columns]="columns" [columnMode]="'force'" [rowClass]="getRowClass"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" (reorder)="columnReordered($event)" [scrollbarH]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [rowIdentity]="getId" [selectAllRowsOnPage]="false" (scroll)="onScroll($event.offsetY)" (select)='onSelect($event)'[reorderable]="false" #table>
                    </ngx-datatable>
                
                <ng-template #clearTemplate>
                                   <span class="fa fa-times-circle-o mr-4 clear-filter" *ngIf="showFilter" (click)="closeFilter()"></span>
                </ng-template>
                <ng-template #hdrTpl let-column="column" let-sort="sortFn">
                    <span 
                        class="header-sort-span" 
                        (click)="sort()">
                        {{column.name}}
                    </span>
                    <span class="header-filter-span input-filter-parent">
                        <input *ngIf="showFilter"
                        [(ngModel)]="columnFilters[column.prop]" 
                        (input)="filterInput($event)"
                        class="input-filter"
                        type="{{ column.prop=='phone_call_date' ? 'date' : 'text' }}" />
                    </span>
                
                </ng-template>

                    <div class="flex flex-row items-center justify-center">
                        <button class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
                          [ngClass]="{'bcolor9': isLoading || allLoaded}" (click)="getLoadMoreUpdate()"
                          [disabled]="isLoading || allLoaded">
                          <span class="mat-button__ripple"></span>
                          <span class="mat-button__label color4" [ngClass]="{'color9': isLoading || allLoaded}">load next
                            50</span>
                        </button>

                      </div>
                </div>
            </div>
        </main>
    </div>
    <ng-template #sensorNameTemplate let-row="row" let-value="value" let-i="index">
        <a class="font-12-bold" href="#/assetdetails/{{row.assetId}}">{{value}}</a>
    </ng-template>
    <ng-template #sensorIdTemplate let-row="row" let-value="value" let-i="index">
        <!-- <a class="font-12-bold" href="#/sensordetails/{{row.id}}">{{value}}</a> -->
        <a class="font-12-bold" href="javascript:void(0)" (click)="loadSummaryPanel(row, $event)">{{value}}</a>
    </ng-template>
    <ng-template #deviceProfileTemplate let-row="row" let-value="value" let-i="index">
      <a class="font-12-bold" [routerLink]="['/deviceprofiles', {name:row.eventProfileName}]">{{value}}</a>
    </ng-template>
    <ng-template #sensorStatusTemplate let-row="row" let-value="value" let-i="index">
        {{ getSensorStatusLabels(value) }}
    </ng-template>
    <ng-template #dateTemplate let-row="row" let-value="value" >
      {{value | formateDate:'default'}}
    </ng-template>
    <ng-template #batteryHealthTemplate let-row="row" let-value="value" let-i="index">
      <div class="flex items-center justify-between">
        <span>{{value >0 ? value : 'N/A'}}<label *ngIf="value>0">%</label></span>
        <span><img [src]="value >15 ? 'assets/svgs/battery/full.svg' : (value <= 5 ? 'assets/svgs/battery/na.svg' : 'assets/svgs/battery/moderate.svg')"/></span>
      </div>
    </ng-template>
    <ng-template #catalogTypeTemplate let-row="row" let-value="value" >
        {{value | titlecase}}
      </ng-template>

    <div *ngIf="sidePanelsObj.bind">
        <cl-sensor-binder [sensorObject]="selectedSensor" (closeSidePanel)="closeSidePanel($event)"></cl-sensor-binder>
    </div>
    <div *ngIf="sidePanelsObj.bulk">
        <cl-bulk-action-panel [panelObj]="bulkPanelObj" (closeSidePanel)="closeSidePanel()"
        (refreshParentList)="delayedRefreshStart = true;"></cl-bulk-action-panel>
    </div>
    <div *ngIf="sidePanelsObj.replace">
        <cl-sensor-replace [sensorObject]="selectedSensor" (closeSidePanel)="closeSidePanel($event)"></cl-sensor-replace>
    </div>
    <div *ngIf="showSidePanel == true">
        <cl-device-summary *ngIf="mode == 'view'" (closeSidePanel)="closeSidePanel()" [sensorId]="roleObject?.id" [sensorType]="roleObject?.deviceType" [device]="roleObject"></cl-device-summary>
    </div>
    <div class="summery-panel" *ngIf="sidePanelsObj.add || sidePanelsObj.edit">
      <cl-device-add [deviceObject]="selectedSensor" [mode]="mode" [classType]="device" (closeSidePanel)="closeSidePanel($event)"></cl-device-add>
    </div>

</div>
</div>