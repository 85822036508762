import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AssetListService } from "@cl/asset/asset-list/asset-list.service";
import { EnvironmentService } from "@cl/core";
import { ESSearchResults, NavigatorConfiguration } from "@cl/models";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigService } from "./config.service";
import { AppLoadConfigurations } from '@cl/ngxs/actions';
import { UserService } from "@cl/user/user.service";

@Injectable({
  providedIn: "root",
})
export class NavigatorApiService {
  actionBtnPolicies = {
    Location: ['LOCATION_SUMMARY'],
    asset: ['ASSET_SUMMARY'],
    shipment: ['SHIPMENT_SUMMARY'],
  };

  constructor(private env: EnvironmentService, private http: HttpClient, private _configService: ConfigService,
              private assetListService: AssetListService,
              private store: Store, private userService: UserService) {}

  private get config() {
    return this.env.clfGraphApp;
  }

  private get headers() {
    return new HttpHeaders({
     // token: this.config.token,
      "Content-Type": "application/json",
    });
  }

  loadNavigatorConfig(): Promise<NavigatorConfiguration[]> {
    const payload = {
      globalQueryText: "",
      searchQueries: [
        {
          fieldName: "clfMappingType",
          queryText: "Configuration",
          queryOperator: "should",
          childFilters: [{
            "fieldName": "deleted",
            "queryText": "true",
            "queryOperator": "must_not"
          }]
        },
      ],
    };
    const url = this.config.baseUrl + "/api/search/navigator/query";
    // const url = "/assets/data/generic-filter-mock.json";
    // return this.http.get<any>(url);
    return new Promise((resolve, reject )=> {
      this.http.post<ESSearchResults<any>>(url, payload, { headers: this.headers }).subscribe({
        next: (res: any) => {
          res.hits.forEach((h) => {
            try {
              h.value = JSON.parse(JSON.parse(h.value));
            } catch (e) {
              try {
                h.value = JSON.parse(h.value);
              } catch (e) {}
            }
          });
          let navigatorConfig = this.validatePolicies(res.hits);
          let uiFiltersIndex: number = navigatorConfig.findIndex((item) => item.name === 'ui-filters');
          if (uiFiltersIndex > -1) {
            if (navigatorConfig[uiFiltersIndex].value.hasOwnProperty('asset')) {
              this.assetListService.setAdditionalAssetFilters(navigatorConfig[uiFiltersIndex].value['asset']);
            }
          }
          this.store.dispatch(new AppLoadConfigurations(navigatorConfig))
          resolve(navigatorConfig as NavigatorConfiguration[]);
        },
        error: ()=>{
          reject();
        }
      })
    });
  }


  validatePolicies(navigatorConfig){
    let queryText = '';
    const policies = this.userService.getPolicies();
    const domains = []
    for (const domain in this.actionBtnPolicies) {
      const valid = this.actionBtnPolicies[domain].every((action) => policies.includes(action));
      if(valid){
        domains.push(domain)
      }
    }
    queryText = domains.join(',')

    if(navigatorConfig.length>0 && navigatorConfig?.[0].value?.initialData?.payload?.searchQueries?.[0]?.queryText){
      navigatorConfig[0].value.initialData.payload.searchQueries[0].queryText = queryText
    }

    return navigatorConfig;
  }
}
