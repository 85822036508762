<div class="ml-6">
  <div
    class="wave-point min-h-fit flex"
    *ngFor="let route of trackingInfo; let last = last; let first = first"
    [ngClass]="[
      last
        ? 'h-auto'
        : trackingInfo.length === 2
        ? 'h-36'
        : trackingInfo.length === 3
        ? 'h-24'
        : 'h-16',
      isReverse ? 'rev-order' : '',
      first ? 'mt-3' : ''
    ]"
    [class]="route.status"
  >
    <div class="flex flex-col">
      <div
        class="dot w-4 h-4 rounded-full wavepoint-border overflow-hidden p-0.5 border-2"
      >
        <div class="wavepoint-dot h-full rounded-full"></div>
        <i
          class="fa fa-check-circle complete-icon text-sm scale-75 -left-0.5 -top-1.5 relative"
        ></i>
      </div>
      <div class="w-0.5 mx-auto grow relative" *ngIf="!last">
        <div
          class="border-l-2 relative h-full border-dashed pending-bar top-0 left-0"
        ></div>
        <div class="border-l-2 complete-bar absolute h-full top-0 left-0"></div>
      </div>
    </div>
    <div class="grow">
      <div class="ml-3">
        <div class="title font-extrabold">
          <span [title]="route.title">{{ route.title | truncate: 30 }}</span>

          <cl-html-tooltip *ngIf="route.tooltip && route.tooltip.length">
            <div class="tooltip-anchor">
              <i class="fa fa-info-circle color4 ml-2 cursor-pointer"></i>
            </div>
            <div class="tooltip-popper text-black font-normal">
              <div *ngFor="let content of route.tooltip">
                <span class="font-bold">{{ content.heading }}</span>
                <span>{{ content.message }}</span>
              </div>
            </div>
          </cl-html-tooltip>
        </div>
        <div class="message" *ngIf="!route.isHTMLMessage">{{ convertDateFormat(route.message) }}</div>
        <div class="message" *ngIf="route.isHTMLMessage" [innerHTML]="route.message"></div>
      </div>
    </div>
  </div>
</div>