import { Injectable, Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({ name: 'sortByOrder' })
export class SortPipe implements PipeTransform {

  transform(cdmFields): any[] {
    let negativeOrderedFields = _.filter(cdmFields, {'order': -1});
    let positiveOrderedFields = _.filter(cdmFields, function(o) { return o.order>-1; });
    positiveOrderedFields = _.sortBy(positiveOrderedFields, 'order');
    let result = [...positiveOrderedFields, ...negativeOrderedFields];
    return result;
  }
}
