import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppAuthService } from "../app-auth.service";
import { Router } from "@angular/router";
import { NavService } from "@cl/@core/shell/nav.service";
import { TenantUtilService } from '@cl/tenant/tenant-util.service';
import { ThemingService } from "@cl/common/services/theming.service";
import { PropertyService } from "@cl/property/property.service";
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{
  defaultTheme = {
    logoURL: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png',
    logoAltText: 'Parkoursc',
    bannerUrl: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_banner.png',
    actionButtonColor: '#0094d0',
    showFullPageBackground: true,
    showFullBanner: false,
    bannerWidth: '100%',
    title: 'ParkourSC',
    favicon: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/favicon.ico'
  };
  appliedTheme: any = {
    logoURL: '',
    logoAltText: '',
    bannerUrl: '',
    actionButtonColor: '#0094d0',
    showFullPageBackground: true,
    showFullBanner: false,
    bannerWidth: '100%'
  };
  constructor(
    private appAuth: AppAuthService,
    private router: Router,
    private navService: NavService,
    private tenentUtils: TenantUtilService,
    private themingSevice: ThemingService,
    private propertyService: PropertyService,
    private apiProvider: ApiProviderService,
    private renderer: Renderer2,
    private titleService: Title
  ) {
  }

  ngOnInit(): void {
    this.appAuth.isAuthenticated().subscribe( (authenticated) => {
      if(authenticated) {
        //TODO::handle the redirection to either route it came from, if not to default route
        const menuList = this.navService.getMainMenuList();
          this.router.navigateByUrl(this.navService.getDefaultRoute(menuList[0]));
      } else {
        this.apiProvider.getAPIProviders(true)
          .then(async () => {
            try {
              const response = await this.propertyService.getLoginBulkProperties();
              if(response && response.length) {
                response.map((item)=>{
                  if(item.key === 'domain.preLoginLogoURL') {
                    this.defaultTheme.logoURL = item.value;
                  } else if(item.key === 'domain.logoAltText') {
                    this.defaultTheme.logoAltText = item.value;
                  } else if(item.key === 'domain.bannerUrl') {
                    this.defaultTheme.bannerUrl = item.value;
                  } else if(item.key === 'domain.actionButtonColor') {
                    this.defaultTheme.actionButtonColor = item.value;
                  } else if(item.key === 'domain.applicationName') {
                    this.defaultTheme.title = item.value;
                  } else if(item.key === 'domain.applicationFavicon') {
                    this.defaultTheme.favicon = item.value;
                  }
                })
              }
              this.applyThemeAndLoadWidget();
            } catch (error) {
              this.applyThemeAndLoadWidget();
            }
          })
          .catch(() => {
            this.applyThemeAndLoadWidget();
          });
        this.tenentUtils.clearPersistedTenant();
      }
    })
  }

  applyThemeAndLoadWidget() {
    this.appliedTheme = this.defaultTheme;
    this.titleService.setTitle(this.appliedTheme.title);
   
    this.appAuth.login();
    const self = this;
    setTimeout(() => {
      /*
      * quick hack as the action button color is passed via lock widget configuration way earlier,
      * and cannot be updated directly later once the lock configuration is set
      * */
      const btnElem: Element = document.getElementsByClassName('auth0-lock-submit')[0];
      this.renderer.setStyle(btnElem, 'background-color', self.defaultTheme.actionButtonColor);
      const faviconElem = document.getElementById('favicon');
      this.renderer.setAttribute(faviconElem, 'href', this.appliedTheme.favicon);
    }, 10)
  }

  ngOnDestroy(): void {
    this.appAuth.hideLockWidget();
  }

}
