import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LocationsService } from "@cl/locations/locations.service";
import { takeUntil, Subject, debounceTime, distinctUntilChanged } from "rxjs";
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionType, SortType } from "@swimlane/ngx-datatable";
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from "@cl/common/services/toast.service";
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { UtilsService } from '../../common/utils/utils.service';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/common/constants';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { LocationUtilsService } from '../location-utils.service';
import { AssetBasicInformationProperties, CdmField } from '@cl/@types/location.types';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { EntityService } from '@cl/common/services/entity-list.service';

@Component({
  selector: 'cl-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('locationAssetTemplate') assetTemplate: TemplateRef<any>;
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  customFields = ['position','areaFenceType','geoFenceType','shipmentIB','shipmentOB','returnShipments','assets'];
  modifiedFields = ['createdBy','createdAt','modifiedBy','modifiedAt'];
  modifiedOrder=[];
  showSummaryPanel = false;
  summaryObj: any;
  assetDist: any = [];
  assetDistArray: any = [];
  assetCategories: any = {};
  loading = true;
  headerHeight = 25;
  rowHeight = 25;
  selectedAsset: any;
  selectedRows: any = [];
  selection: SelectionType = SelectionType.checkbox;
  sortType: SortType = SortType.multi;
  totalAssets: number;
  address: string;
  id: string;
  latLngStr: string = '';
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  showFilterPanel = true;
  searchFormGroup: FormGroup;
  chartData: any;
  chartXData: any = [];
  selectedCategory;
  allAssetsDistrib: any;
  origDistrib: any;
  filters: any[] = [];
  initialFilters: any[] = [];
  selectedFilters: string[] = [];
  mainWidth: any;
  tableWidth: number;
  rawFilters: any = {
    assetCategories: []
  };
  dataFilterInProgress = false;
  mapOptions: any = {
    gestureHandling: 'cooperative',
    styles: [
      {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
        ]
      }
    ],
    positionOfActions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    }
  };
  location: any = {
    position: {lat: null, lon: null},
    areas: []
  };
  showGraph = false;
  private readonly destroyed$ = new Subject<boolean>();
  masterData: any[] = [];
  breadCrumbItems: BreadCrumbItem[];
  columnKeys: string[] = [];
  selected = -1;
  assetDistributionDropDown='Category: All';
  assetDistributionDropDownOpen=false;
  selectedDistributionList = [];
  connectedLocations: any = []
  attributes:any;
  locationEntityTypeKey = '';
  constructor(
    private locationService: LocationsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private csvExport: CsvExportsService,
    public datePipe : FormateDatePipe,
    private _locationUtils:LocationUtilsService,
    private _dynamicFormService: DynamicFormService,
    private _utilsService: UtilsService) {
      this.breadCrumbItems = [
        {'name': 'Previous', isBack: true, path: null},
        {'name': 'Location Details', path: null}
      ];


    this.route.queryParams.subscribe(params=> {
      this.locationEntityTypeKey = params['type']
    })
    }

  get fcSearchTerm(): FormControl {
      return this.searchFormGroup.get("searchTerm") as FormControl;
  }

  ngOnInit(): void {
    //this.selectedAssetId = '61f4b2e2-13bc-40a6-98c4-63d14ad94599';
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
    this.showSummaryPanel = true;
    this.getLocationAndAssets();
    this.setContainerWidth();
  }

  ngAfterViewInit(): void {
    this.fcSearchTerm.valueChanges
      .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
      .subscribe((evt) => this.onSearchInput(evt));

    this.rawColumns = [
      { name: 'Asset Name', prop: 'name', minWidth: 180, cellTemplate: this.assetTemplate, visible: true },
      { name: 'Asset ID', prop: 'externalId', minWidth: 200, cellTemplate: this.assetTemplate, visible: true },
      { name: 'Date Added', prop: 'createdAt', minWidth: 150, visible: true },
      { name: 'Category', prop: 'type', minWidth: 150, visible: true },
      { name: 'Status', prop: 'status', minWidth: 100, visible: true }
    ];
    let columns: any = _.cloneDeep(this.rawColumns);
    this.columns = columns.filter((col: any) => col.visible);
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
  }

  getLocationAndAssets() {
    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      this.id = params.get("id");
      this.locationService.getLocationsAndAssets(this.id, this.locationEntityTypeKey).subscribe((response: any) => {
        this.getPositions(response);
        this.origDistrib = response;        
        let newResponse = this.calculateIBandOB(response);        
        this.summaryObj = newResponse.summary;        
        this.prepareAddressStr(this.summaryObj.properties);
        // this.prepareLatLngStr(this.summaryObj.properties.locus);        
        if(response?.summary?.type){
          this.getExtendedAttributes(response.summary.type)
        }else{
          this.getExtendedAttributes(response?.type)
        }                
        this.getFlatAssets(response.assetDistributionByArea);
      })
    })
  }
  async getExtendedAttributes(location){
    const locationCatalog = await this._locationUtils.getLocationExtendedAttributes(location);    
    const fields = locationCatalog.cdmFields
    this.attributes= this._dynamicFormService.orderFields([...fields],'order');
    this.attributes=  this.attributes.filter(inp=>inp.name != "coordinates")
    this.attributes.forEach(inp => {
      if(this.modifiedFields.includes(inp.name)){
         this.modifiedOrder.push(inp.order)
      }
    })
    this.modifiedOrder.sort()
    let order = this.modifiedOrder[0]-1;
    this.customFields.forEach(field=>{
      if(!["areaFenceType","geoFenceType","position"].includes(field)){
        this.attributes.push({
          name:field,
          order:order,
          isDisplayableOnDetails:true,
          modifiedField:true
         })
      }
    })
    this.attributes= this._dynamicFormService.orderFields([...this.attributes],'order');
  }
  isStaticPropertyAvailable(id: string){
    let staticProperties : string[] = this._locationUtils.locationStaticProperties;
    return !_.includes(staticProperties, id);
  }

  getExtendedAttributeValue(cdmField : CdmField, properties: AssetBasicInformationProperties){
    if(cdmField['modifiedField']){
      return true
    }else if(cdmField){
      if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
        if(cdmField?.type?.toUpperCase()==='DATE'){
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        } else {
          if(cdmField.name == 'position'){
            let lat = Number(properties[cdmField.name].split(',')[0])
            let lan = Number(properties[cdmField.name].split(',')[1])
            return lat.toFixed(6)+","+lan.toFixed(6)
          }
           if(cdmField.name == 'parentLocation'){         
            return properties['buildingName']
          }
          if(cdmField.name == 'organization'){
            return properties['organizationName']
          }else{
            return properties[cdmField.name];
          }
        }
      }else{
        return '';
      }
    } else {
      return '';
    }
  }
  getPositions(response : any){
    if(response?.summary?.properties?.locus && response?.summary?.properties?.locus?.lat && response?.summary?.properties?.locus?.lng){
      let tempPositions = response.summary.properties.locus;
      this.location.position = {lat: parseFloat(tempPositions.lat), lon: parseFloat(tempPositions.lng)};
    }else if(response?.summary?.properties?.position){
        let tempPositions = response.summary.properties.position.split(',');
        this.location.position = {lat: parseFloat(tempPositions[0]), lon: parseFloat(tempPositions[1])};
    }
    if(response?.connectedLocation){
      response.connectedLocation.forEach((element: any) => {
        if(element?.properties?.position){
          let tempPositions = response.summary.properties.position.split(',');
          this.location.areas.push({position: {lat: parseFloat(tempPositions[0]), lon: parseFloat(tempPositions[1])}});
        }
      });
    }
    response.connectedLocation.forEach(element => {
      let geoPoints = element.properties.position.split(',');
      const obj = {
        lat: +geoPoints[0],
        lng: +geoPoints[1],
        date: element.properties.modifiedAt,
        iconUrl: '../assets/svgs/map-icons/gateway-icon.svg',
      };
      this.connectedLocations.push(obj);
    });
  }
  getFlatAssets(areaGroups) {
    let tempAssetsArr = [];
    let tmpAssetGrp: any;
    let tmpAssetCats = {};
    let tempAreaAsetCount = 0;
    let tmpAreaCounts: any = {};
    for (let areaId in areaGroups) {
      tmpAssetGrp = areaGroups[areaId];
      tempAreaAsetCount = 0;
      tmpAssetGrp.forEach((item) => {
        if (item.baseType && item.baseType.toLowerCase() == 'asset') {
          var props = item.properties;
          var tmpObj = {
            id: props.id,
            name: props.name,
            externalId: props.externalId,
            createdAt:  this._utilsService.transformDate(props.createdAt, 'MM/dd/yy'),
            type: props.type,
            state: props.state,
            status: props.status
          };
          if(tmpAssetCats.hasOwnProperty(props.type)) {
            tmpAssetCats[props.type] += 1;
          } else {
            tmpAssetCats[props.type] = 1;
          }
          tempAssetsArr.push(tmpObj);
          tempAreaAsetCount++;
        }

      });
      tmpAreaCounts[tmpAssetGrp[0]?.properties.name] = tempAreaAsetCount;
    }
    this.masterData = _.cloneDeep(tempAssetsArr);
    this.assetDist = tempAssetsArr;
    this.assetDistArray = tempAssetsArr;
    this.assetCategories = {'list':Object.keys(tmpAssetCats), 'counts': tmpAssetCats};
    this.setLocationDetailsFilters();
    if(tempAssetsArr.length) {
      this.showGraph = true;
    }
    this.allAssetsDistrib = tmpAreaCounts;
    this.loading = false;
    this.prepareGraphData(tmpAreaCounts);
    
  }

  setLocationDetailsFilters() {
    for (let cat in this.assetCategories.counts) {
      this.rawFilters['assetCategories'].push({
        key: cat,
        name: cat,
        count: this.assetCategories.counts[cat],
        checked: false
      })
    }

    let filterArr = [];
    for (let key in this.rawFilters) {
      filterArr.push({
        'expand': true,
        'filterType': key,
        'label': 'Asset Categories',
        'type': 'multiselect',
        'list': this.rawFilters[key]
      });
    }

    let filters = [...filterArr];
    this.initialFilters = _.cloneDeep(filters);
    this.filters = filters;    
  }

  getPanelUpdate(filterAction: any) {
    if (filterAction.action === 'clearFilters') {
      this.selectedFilters = [];
      this.filters = _.cloneDeep(this.initialFilters);
    } else if (filterAction.action === 'filterByType') {
      if (filterAction.checked) {
        this.selectedFilters.push(filterAction.name);
      } else {
        let index = this.selectedFilters.indexOf(filterAction.name);
        if (index > -1) {
          this.selectedFilters.splice(index, 1);
        }
      }
    }
    this.filterMasterData();
  }

  filterMasterData() {
    let masterData = _.cloneDeep(this.masterData);
    if (this.selectedFilters.length === 0) {
      this.assetDist = masterData;
    } else {
      this.assetDist = masterData.filter((rowData) => this.selectedFilters.includes(rowData.type));
    }
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 280;
    } else {
      this.tableWidth = this.mainWidth - 20;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
  }

  downloadReport(reportType: string) {
    //console.log('reportType', reportType);
    if(reportType === 'csv') {
      const fileName = `Assets-in-${this.summaryObj?.properties?.name ? this.summaryObj?.properties?.name : 'Location'}`;
      const csvData = this._utilsService.getSelectedElementsFromArray(this.assetDist, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, fileName, _.map(this.columns, 'name'));
    }
  }

  copyLatLng(ev){
    navigator.clipboard.writeText(this.summaryObj?.properties?.position).then(()=>{
      //this.toastService.info('Copied to clipboard');
    });
  }

  prepareAddressStr(locProps) {
    let addressJson;
    let formattedAddress = locProps.fullAddress;
    /* if(locProps.type === 'Site') {
      addressJson = JSON.parse(locProps.locationAddress);
      formattedAddress = this.locationService.getFormattedAddress(addressJson, locProps.type);
    } else if(locProps.type === 'WorkArea' && locProps.coordinates) {
      addressJson = (locProps.coordinates).replace(/\\/g, '');
      addressJson = JSON.parse(addressJson);
      formattedAddress = addressJson.address;
    } else { //TODO:: Need to cover this custom formatting for other location types as well
      formattedAddress = '';
    } */
    this.address = formattedAddress ? formattedAddress.replace(/,/g, '</br>') : '';
    //this.address = formattedAddress;
  }

  // prepareLatLngStr(locus) {
  //   if (locus && locus.lat && locus.lng) {
  //     this.latLngStr = `${locus.lat} / ${locus.lng}`;
  //   }
  // }

  onAssetSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selectedRows = _.cloneDeep(rowData.selected);

    this.selectedAsset.splice(0, this.selectedAsset.length);
    this.selectedAsset.push(...selectedRows);
  }

  changeAssetDistribChart(event, cat){
    if(event.checked == true){
      this.selectedDistributionList.push(cat);
    }else{
      if(this.selectedDistributionList.indexOf(cat)>-1){
        this.selectedDistributionList.splice(this.selectedDistributionList.indexOf(cat), 1);
      }
    }

    if(this.selectedDistributionList.length == 0 || this.selectedDistributionList.length == this.assetCategories.list.length){
      this.assetDistributionDropDown = 'Category: All';
      this.prepareGraphData(this.allAssetsDistrib);
    }else{
      this.assetDistributionDropDown = 'Category: '+this.selectedDistributionList.join(', ');
      let tmpAreaCounts: any = {};
      const orig = this.origDistrib;
      let areaGroups = orig.assetDistributionByArea;
      let tmpAssetGrp;
        let tempAssetCounter = 0;
        for (let areaId in areaGroups) {
          tmpAssetGrp = areaGroups[areaId];
          tempAssetCounter = 0;
          tmpAssetGrp.forEach((item) => {
            if (item.baseType == 'Asset' && this.selectedDistributionList.indexOf(item.properties.type)>-1) {
              tempAssetCounter++;
            }
          });
          tmpAreaCounts[tmpAssetGrp[0].properties.name] = tempAssetCounter;
        }
      this.prepareGraphData(tmpAreaCounts);
    }    
  }


  prepareGraphData(assetDistrib) {
    let xData = [];
    let yData = [];
    for (let area in assetDistrib){
      if(assetDistrib.hasOwnProperty(area)) {
        xData.push(assetDistrib[area]);
        yData.push(area)
      }
    }
    this.chartData = [
      {
        name: "Assets",
        data: xData
      }
    ];
    this.chartXData = yData;
  }

  onSearchInput(evt: any) {
    const val = evt.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.assetDistArray];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(val) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.assetDist = temp;
  }
  updateFilter(term){
    this.searchFormGroup.patchValue({
      'searchTerm':term.toLowerCase().trim()
    });
  }
  calculateIBandOB(obj){
    if(obj.connectedShipments){
    let shipmentDirection = obj.connectedShipments.nodes;
    let shipmentIBCount = shipmentDirection.filter(e=>e.properties.direction == 'Inbound');
    let shipmentOBCount = shipmentDirection.filter(e=>e.properties.direction == 'Outbound');
    let shipmentReturnCount = shipmentDirection.filter(e=>e.properties.direction == 'Return');
    obj.summary.IBCount = shipmentIBCount.length
    obj.summary.OBCount = shipmentOBCount.length
    obj.summary.returnCount = shipmentReturnCount.length
    }
   return obj
 }
 convertString(obj){
  let newObj = JSON.parse(obj)
  let coordinates = newObj.geometry.coordinates
  return coordinates
 }
 convertRadius(obj){
  let newRes = JSON.parse(obj) 
  let radius = newRes.properties.radius;
  return radius;
 }
 geotype:any;
 convertGeoType(obj){  
    let newVar = JSON.parse(obj);
    if(newVar.properties.type)
    {
      this.geotype = newVar.properties.type;
      return this.geotype;
    }
    else
    {
      this.geotype =''
      return this.geotype;
    }
 }
 navigateToShipments(direction:string){
  let url = `shipments?direction=${direction}`;
  if(direction == 'inbound' || direction == 'return'){
    url += `&destinationAreaId.keyword=${this.id}`
  }else if (direction == 'outbound'){
    url += `&originAreaId.keyword=${this.id}`
  }
  this.router.navigateByUrl(url)
 }

}
