import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { ContentDialogComponent } from '@cl/common/components/content-dialog/content-dialog.component';
import { OrganizationService } from '@cl/common/services/organization.service';
import { ToastService } from '@cl/common/services/toast.service';
import { UserService } from '@cl/user/user.service';
import { resolve } from 'dns';
import { reject } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'cl-user-group-side-panel',
  templateUrl: './user-group-side-panel.component.html',
  styleUrls: ['./user-group-side-panel.component.scss']
})
export class UserGroupSidePanelComponent implements OnInit,OnChanges,OnDestroy {
  @Input() groupObject: any;
  @Input() allUserObject: any;
  @Input() mode: any;
  @Output() closeSidePanel = new EventEmitter();
  isLoading = false;
  private subscriptions: Subscription[] = [];
  currentUser = {};
  existingUsers = [];
  localAddedUser = [];
  deleteUserArray = [];
  editUserEnable = false;
  allUsersForSelect = [];
  orgList: any=[];
  tenantList:any;

  userGroupPayload = {
    "userGroupName":"",
    "description":"",
    "tenantId":"",
    "active":true,
    "userids":[],
    "usersListLabel":"",
    "usersList":"",
    "selected":"",
    "organization":""
 }
 userType:boolean;
 isOrgRestrictedUser: boolean;
 orgCount: number;
  constructor(private fb: FormBuilder, private _changeDetectorRef: ChangeDetectorRef, private userService:UserService, private _snackBar: MatSnackBar, private organizationService: OrganizationService, public dialog: MatDialog, private _toastService: ToastService) { }
  groupForm = this.fb.group({
    userGroupName: ['' , Validators.required],
    description: ['' , Validators.required],
    active:[false],
    tenantId : [this.userService.getEffectiveTenantId()],
    organization:""
});

  ngOnInit(): void {    
    this.tenantList = JSON.parse(sessionStorage.getItem('tenantList'));
    this.userType = this.userService.isDirectUser();
    this.organizationService.getOrgList().subscribe(data=>{
      this.orgList = data;
      this.orgCount = this.orgList.length;
      if(this.mode == 'add' && this.orgCount>0)
      this.groupForm.get('organization').setValue(this.orgList && this.orgList[0]?.id);        
    })
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['mode']){
      this.existingUsers = [];
    }
    if(this.mode == 'edit'){
      this.allUsersForSelect = [...this.allUserObject];
      this.patchUserGroupForm();
      this.setExistingUSer();
      this.deleteUserArray = [];
    }

    if(this.mode == 'view'){
      this.setExistingUSer();
    }
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setExistingUSer(){
    let existingUserIds = this.groupObject.userids;
    this.existingUsers = [];
    existingUserIds.forEach(id => {
      this.allUserObject.forEach(user => {
        if(id == user.id){
          this.existingUsers.push(user);
          this.allUsersForSelect.splice(this.allUsersForSelect.indexOf(user),1);
        }
      });
    });
  }
  tiggerChangeDetection(){
    this._changeDetectorRef.detectChanges();
  }
  closePanel(parentReload?: boolean){
    this.closeSidePanel.emit(parentReload);
  }
  saveGroup(){
    this.isLoading = true;
    let action ='';
    const userGroupSubs:Subscription = this.userService.postUserGroup(this.groupForm.value).subscribe((data)=>{
    this.userService.setPageRefresh(true);
    this.closePanel(true);
    action ='User group created successfully';
    this.openSnackBar(action, this.groupForm.value.userGroupName);
    }, (error) => {
      this.isLoading = false;
      action = error?.error?.['error-message'] ? error?.error?.['error-message'] : 'Something went wrong';
      this._toastService.openCloseHandlerSnackBar([action])
    });
    this.subscriptions.push(userGroupSubs);
  }

  patchUserGroupForm(){
    this.groupForm.patchValue(this.groupObject);
    this.groupForm.controls['userGroupName'].disable();
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  editGroup(){    
    this.localAddedUser.forEach(user => {
      this.userGroupPayload.userids.push(user.id);
    });
    this.userGroupPayload.userGroupName = this.groupObject.userGroupName;
    this.userGroupPayload.description = this.groupForm.value.description;
    this.userGroupPayload.tenantId = this.groupForm.value.tenantId;
    this.userGroupPayload.active = this.groupObject.active;
    //this.userGroupPayload.userids = this.groupObject.userids;
    this.userGroupPayload.usersListLabel = this.groupObject.usersListLabel;
    this.userGroupPayload.usersList = this.groupObject.usersList;
    this.userGroupPayload.selected = this.groupObject.selected;
    this.userGroupPayload.organization = this.groupForm.value.organization;
    this.isLoading = true;
    this.userService.validateUserGroup(this.userGroupPayload).subscribe((res:any)=>{
      this.isLoading=false;
      if(res.length>0){
        const message =`<span class="users">${res.join(", ")}</span> user will not be added. Do you still want to continue?`;
        const dialogData = new ConfirmDialogModel("", message);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          maxWidth: "400px",
          data: dialogData,
        });
        dialogRef.afterClosed().subscribe((dialogResult) => {
          if (dialogResult) {
             this.editUserGroup();
          }
        })        
      }else{
        this.editUserGroup();
      }
    }, error => {
      this.isLoading=false;
        this.openSnackBar('Something went wrong', 'Update Group');
        return error
    })     
    
  }
  private editUserGroup(){
    this.isLoading = true;
    let action ='';
    const updateUserGroupPromise = new Promise((resolve, reject)=>{
      if(this.groupObject.description == this.userGroupPayload.description && this.groupObject.organization === this.userGroupPayload.organization){
        resolve('no user to update');
      }else{
        const userGroupPutSubs:Subscription = this.userService.putUserGroup(this.userGroupPayload).subscribe((data)=>{
          resolve('user group updated successfully');
          }, (error) => {
            reject(error);
          });
           this.subscriptions.push(userGroupPutSubs);
      }
    });

   const addUserPromise = new Promise((resolve, reject)=>{
      if(this.localAddedUser.length == 0){
        resolve('no user to add');
      }else{
        let adduserPayload = this.groupForm.value;
        adduserPayload.userids = [];
        this.localAddedUser.forEach(user => {
          adduserPayload.userids.push(user.id);
        });

        adduserPayload.userGroupName = this.groupObject.userGroupName;
        const addUserSubs:Subscription = this.userService.postAddUser(adduserPayload).subscribe((data)=>{
          resolve('user added successfully');
          }, (error) => {
            console.log(error.error);
            reject(error?.error['error-message']);
          });
        this.subscriptions.push(addUserSubs);
      }
    });

    const deleteUserPromise = new Promise((resolve, reject)=>{
      if(this.deleteUserArray.length == 0){
        resolve('no user to delete');
      }else{
        let deleteUserSubs:any[] = [];
        this.deleteUserArray.forEach(user => {
          let payload = this.groupForm.value;
          payload.userId = user.id;
          payload.userGroupName = this.groupObject.userGroupName;
          deleteUserSubs.push(this.userService.postDeleteUser(payload));
          const deleteSubs = combineLatest(deleteUserSubs).subscribe(data=>{
            resolve('user Deleted successfully');
          }, error=>{
            console.error(error);
            reject('user add error');
          });
          this.subscriptions.push(deleteSubs);
        });
      }
    });

    Promise.all([
      updateUserGroupPromise.catch(error => {
        action = error?.error?.['error-message'] ? error?.error?.['error-message'] : 'Something went wrong';
        this._toastService.openCloseHandlerSnackBar([action], '');
        return error
      }),
      addUserPromise.catch(error => {
         action = error;
        this.openSnackBar(action, this.groupObject.userGroupName);
        return error
      }),
      deleteUserPromise.catch(error => {
         action = 'Something went wrong';
        this.openSnackBar(action, this.groupObject.userGroupName);
        return error
      }),
    ]).then(values => {
      this.closePanel(true);
      this.isLoading = false;
      if(action == ''){
        this.openSnackBar('User group updated successfully', this.groupObject.userGroupName);
      }
    })
  }

  addUser(){
    if(this.deleteUserArray.indexOf(this.currentUser) > -1){
      this.deleteUserArray.splice(this.deleteUserArray.indexOf(this.currentUser),1);
      this.existingUsers.push(this.currentUser);
    }else if(this.existingUsers.indexOf(this.currentUser) == -1){
      this.existingUsers.push(this.currentUser);
      this.localAddedUser.push(this.currentUser);
    }
    this.allUsersForSelect.splice(this.allUsersForSelect.indexOf(this.currentUser),1);
    this.currentUser = {};
  }

  deleteUser(user){
    if(this.localAddedUser.indexOf(user)>-1){
      this.localAddedUser.splice(this.localAddedUser.indexOf(user),1);
    }else{
      if(this.deleteUserArray.indexOf(user) == -1){
          this.deleteUserArray.push(user);
      }
    }
    this.existingUsers.splice(this.existingUsers.indexOf(user),1);
    this.allUsersForSelect.push(user);
  }

  getDeleteUserStatus(user){
    if(this.deleteUserArray.indexOf(user)>-1){
      return true;
    }else{
      return false;
    }
  }

  onOrgclose(){
    this.groupForm.get('organization').setValue('');
  }
}
