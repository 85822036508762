import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PersistenceService } from '@cl/common/services/persistence.service';
import * as _ from 'lodash';

@Component({
  selector: 'cl-grid-column-toggle',
  templateUrl: './grid-column-toggle.component.html',
  styleUrls: ['./grid-column-toggle.component.scss']
})
export class GridColumnToggleComponent implements OnInit {
  @Input() columnList: any;
  @Input() page?: string;
  @Output() toggleColumns = new EventEmitter();
  @Input() havingImageColumn = false;
  @Input() showImage: boolean;
  @Output() showImageChange: EventEmitter<boolean> = new EventEmitter()
  public toolTip: string = 'Manage table settings';
  public showToggleContainer: boolean = false;
  constructor(
    private persistenceStore: PersistenceService
  ) { }

  ngOnInit(): void {
  }

  toggleContainer() {
    this.showToggleContainer = !this.showToggleContainer;
  }

  toggleImageColumn(){
    this.showImage = !this.showImage;
    let columnsStore:string[] = this.columnList.filter(item => item.visible).map(item => item.name);
    if(this.showImage){
      columnsStore.push('showImage')
    }
    this.persistenceStore.setGridColumns(this.page, columnsStore);
    this.showImageChange.emit(this.showImage)
  }
  toggleColumn(col: any) {
    col.visible = !col.visible;
    let columnList :any = [...this.columnList];
    if (!col.visible) {
      let index: number = _.findIndex(columnList, (column: any) => col.prop === column.prop);
      columnList.splice(index, 1);
    }
    let columnsStore = columnList.filter(item => item.visible).map(item => item.name);
    this.persistenceStore.setGridColumns(this.page, columnsStore);
    this.toggleColumns.emit(columnList);
  }

}
