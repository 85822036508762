<div>
  <div class="cards-tracker px-2 py-1.5">
    <div
      class="m-0 card-tracker-heading pr-1"
      title="{{ compositeShipmentData?.name }}"
    >
      {{ compositeShipmentData?.name }}
    </div>
    <div class="icons">
      <ng-container
        *ngFor="
          let shipment of compositeShipmentData?.subShipments;
          let index = index;
          last as isLast
        "
      >
        <cl-html-tooltip [position]="'bottom'">
          <div class="tooltip-anchor flex items-center">
            <div
              [ngClass]="{
                readytostart:
                  shipment.status == 'Ready to Ship' ||
                  shipment.status == 'Planned' ||
                  shipment.status == 'Pending' ||
                  shipment.status == 'Incomplete',
                drafts: shipment.status == 'Drafts',
                active:
                  shipment.status == 'In-Transit' ||
                  shipment.status == 'Exception',
                completed:
                  shipment.status == 'At Destination' ||
                  shipment.status == 'Completed'
              }"
              class="circle py-1 px-2 rounded-full font-bold cursor-pointer"
              (click)="onSelect(shipment.id,shipment.entityTypeKey)"
            >
              <span *ngIf="shipment.status !== 'Completed'">{{
                getAlphabet(index)
              }}</span>
              <i
                *ngIf="shipment.status == 'Completed'"
                class="fa fa-check"
              ></i>
            </div>
          </div>
          <div class="tooltip-popper-bottom text-black font-normal z-10">
            <div>
              <div><b>Shipment Name:</b> {{ shipment.name }}</div>
              <div><b>Shipment Status:</b> {{ shipment.status }}</div>
            </div>
          </div>
        </cl-html-tooltip>
        <i class="fa fa-arrow-right mx-2" *ngIf="!isLast"></i>
      </ng-container>
    </div>
  </div>
<div class="relative">
  <div class="custom-card card">
    <div class="flex">
     <span class="mb-1.5 mr-2 h-7 text-sm font-bold">SUMMARY</span>
     <span class="custom-btn ml-auto mt-1">
       <button
         (click)="fnToggle()"
         *ngIf="!isDTPUser"
         [clIsValidAction]="actionBtnPolicies.share"
         type="button"
         class="p-4-10 border-general br2 mr-2 color4 bcolor4 bg-white"
       >
         <i class="fa fa-share"></i>
       </button>
       <div *ngIf="toggle" class="custom-content shadow-sm">
         <cl-shipment-details-share
           [entityId]="compositeShipmentData?.id"
           [entityType]="'COMPOSITE'"
           [entityTypeKey]="compositeShipmentData?.entityTypeKey"
           [entityName]="compositeShipmentData?.name"
           (close)="onClose($event)"
         ></cl-shipment-details-share>
       </div>
     </span>
   </div>
<div class="card-inner">
   <div class="card-content" style="padding: 0px 0px 6px 0px">
     <div class="font-bold text-sm pr-2">Child Shipments:</div>
     <div class="text">
       {{ compositeShipmentData?.subShipments?.length }}
     </div>
     <div class="font-bold text-sm pr-2">Origin:</div>
     <div class="text">
       {{ compositeShipmentData?.originAreaName }}
     </div>
     <div class="font-bold text-sm pr-2">Destination:</div>
     <div class="card-inner">
       {{ compositeShipmentData?.destinationAreaName }}
     </div>
     <div class="font-bold text-sm pr-2">Current Duration:</div>
     <div class="text">
       {{ compositeShipmentData?.duration }}
     </div>
   </div>
   </div>
 </div>
  <div class="cards" (scroll)="onCardsScroll()">
    <div class="card firstcard invisible"></div>
    <div
      class="card sub-cards z-0"
      *ngFor="
        let shipment of compositeShipmentData?.subShipments;
        let index = index
      "
      [ngClass]="{
        'active-card': shipment.id == shipmentId,
        'cursor-pointer': shipment.status !== 'Drafts',
        'pointer-events-none': shipment.status == 'Drafts',
        'hide-card-tip': isHideCardTip
      }"
      (click)="onSelect(shipment.id,shipment.entityTypeKey)"
    >
      <div class="flex items-center  mb-1.5 h-7">
        <div
          [ngClass]="{
            readytostart:
              shipment.status == 'Ready to Ship' ||
              shipment.status == 'Planned' ||
              shipment.status == 'Pending' ||
              shipment.status == 'Incomplete',
            drafts: shipment.status == 'Drafts',
            active:
              shipment.status == 'In-Transit' ||
              shipment.status == 'Exception',
            completed:
              shipment.status == 'At Destination' ||
              shipment.status == 'Completed'
          }"
          class="circle py-1 px-2 mr-1 rounded-full font-bold"
        >
          <span *ngIf="shipment.status !== 'Completed'">{{
            getAlphabet(index)
          }}</span>
          <i *ngIf="shipment.status == 'Completed'" class="fa fa-check"></i>
        </div>
        <div class="heading ml-1" title="{{ shipment.name }}">
          {{ shipment.name }}
        </div>
        <i
          *ngIf="shipment.status == 'Pending'"
          class="fa fa-refresh px-1 ml-auto"
          (click)="refreshData(shipment.id, shipment.entityTypeKey)"
        ></i>
      </div>
     <div class="card-inner">
      <div class="card-content  pb-1.5">
        <div class="font-bold text-sm pr-2">Origin:</div>
        <div class="text">{{ shipment.originAreaName }}</div>
        <div class="font-bold text-sm pr-2">Destination:</div>
        <div class="text">{{ shipment.destinationAreaName }}</div>
        <ng-container *ngIf="['At Destination', 'Completed'].includes(shipment.status); else etaTemp">
          <div class="font-bold text-sm pr-2">Actual Arrival:</div>
          <div>{{ shipment.actualArrivalTime| formateDate}}</div>
        </ng-container>
        <ng-template #etaTemp>
          <ng-container *ngIf="shipment.eta">
            <div class="font-bold text-sm pr-2">ETA:</div>
            <div>{{ shipment.eta | formateDate }}</div>
          </ng-container>
        </ng-template>
        <div class="font-bold text-sm pr-2">Status:</div>
        <div class="text">{{ shipment.status }}</div>
      </div>
    </div>
    </div>
  </div>
</div>

</div>
