<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title bgcolor32 justify-between items-center px-2 pt-1.5 pb-0">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/asset.svg" />
            <span class="black font-bold">{{'assetDetails.bindToasset'|translate}}</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content flex flex-col mr-1">
        <a href="#/assetdetails/{{assetObject?.id}}" class="fs14  px-2 ">{{assetObject?.externalId}}</a>
        <div class="fs14 px-2 ">{{'assetDetails.bindTheAsset'|translate}}</div>
        <div class="cl-senors-section">
            <div class="pl-4 pr-4 cl-sensor-border" *ngFor="let sensor of selectedSensorList; let i = index">
                <div class="flex fs12 color16 mt-1">
                    <div class="basis-2/4 font-bold">Type</div>
                    <div class="basis-2/4">{{sensor?.type}}</div>
                </div>
                <div class="flex fs12 color16 mt-1">
                    <div class="basis-2/4 font-bold">Status</div>
                    <div class="basis-2/4">{{sensor?.status}}</div>
                </div>
                <div class="flex fs12 color16 mt-1">
                    <div class="basis-2/4 font-bold">Device ID</div>
                    <div class="basis-2/4">
                        <!-- TODO: Navigate to sensor detail -->
                        {{sensor?.id}}
                    </div>
                </div>
                <div class="flex fs12 color16 mt-1" *ngIf="showComponent">
                    <div class="basis-2/4 font-bold">Component<span class="mandatory text-red-500">*</span></div>
                    <div class="basis-2/4">
                        <mat-select [(ngModel)]="assetComponent[i]" placeholder="Select Component" [value]="">
                            <mat-option [value]="''"> Select Component </mat-option>
                             <mat-option *ngFor="let cmplist of assetComponentList | keyvalue" [value]="cmplist.key" [textContent]="cmplist.value">
                             </mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="bindForm" novalidate class="pl-2 pr-1 grow relative">
            <input type="search" formControlName="searchTerm" class="basic-input mt5 pt5 pr2"
                placeholder="Search Active Devices" value="">
            <div class="spin-pos" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
            <cdk-virtual-scroll-viewport (scrolledIndexChange)="listScrolled()" itemSize="22" class="list-viewport" *ngIf="!loading">
                <div class="search-list-item-container ba" *cdkVirtualFor="let item of sensorList"
                     (click)="selectListItem(item)">
                    <span class="search-list-item justify-between flex items-center" [ngClass]="{'item-select': item.checked}">
                        <span [matTooltip]="'Select - ' + item?.id" matTooltipClass="tooltip-styles">
                            {{item?.id | truncate:24}}
                        </span>
                        <i class="fa fa-check mr10 color4" aria-hidden="true" *ngIf="item.checked"></i>
                    </span>
                </div>
            </cdk-virtual-scroll-viewport>
        </form>
        <mat-checkbox class="px-2 ml-0 my-2" id="matCheckbox" [(ngModel)]="bindFlowCheck" [checked]="bindflowStatus" (change)="checkUnbind()">{{'assetDetails.unbindAsset'|translate}}</mat-checkbox>
    </section>
    <section class="cl-side-panel-footer bgcolor33 pr-4 pl-4">
        <div class='flex justify-end items-center'>
            <a formnovalidate class=" m-2" (click)="closePanel()">{{'common.cancel'|translate}}</a>
            <button *ngIf="monitoredStatus == 'unmonitored'" class="panel-button white bgcolor4" [disabled]="!selectedSensorList?.length" (click)="bindAsset()">
                {{'assets.bind'|translate}}
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="bindClicked"></i>
            </button>
            <button *ngIf="monitoredStatus == 'monitored'" class="panel-button white bgcolor4" [disabled]="bindDisabled" (click)="bindAsset()">
                {{bindbtnText}}
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="bindClicked"></i>
            </button>
        </div>
    </section>
</div>
