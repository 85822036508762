import { Injectable } from '@angular/core';
import { Bisector, Entity, Rule } from '@cl/models';
import * as _ from 'lodash';

import { LinkService } from './link.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class DataMethodsService {
  nameCounts: any = {};

  constructor(private _linkService: LinkService, private _utilsService: UtilsService) { }
  setNameCounts(graphData, obj) {
    let valName;
    if (_.isEmpty(this.nameCounts)) {
      _.forOwn(graphData.nodesObj, (val, key) => {
        valName = val['template'];
        this.nameCounts[valName] = this.nameCounts[valName] + 1 || 1;
      });
    } else {
      valName = obj['template'];
      this.nameCounts[valName] = this.nameCounts[valName] + 1 || 1;
    }
  }
  addNode(graphData, obj, component) {
    let entityObj;
    this.setNameCounts(graphData, obj);
    const count = this.nameCounts[obj.template] || 1;
    const UID = obj.id || this._utilsService.getUUID();
    const entName = obj.prefix || obj.name;
    const newName = entName + ' ' + count;
    if (!obj.x) {
      obj.x = 100 * count;
      obj.y = 500;
    }
    if (obj.type === "rule") {
      entityObj = new Rule().deserialize(obj);
    } else {
      entityObj = new Entity().deserialize(obj);
    }
    entityObj.uid = UID;
    entityObj.name = newName;
    entityObj.hovered = false;
    entityObj.category = obj.template;
    entityObj.template = entityObj.template || obj.template;
    entityObj.onCanvas = !obj.profileAdd;

    if (component === 'mapping_admin') {
      entityObj = this.setMapProperties(entityObj, obj);
    }
    if (!obj.profileAdd) {
      graphData.nodes.push(entityObj);
      graphData.nodes.sort(function (a, b) {
        return b.hierarchy - a.hierarchy;
      });
    }
    graphData.nodesObj[UID] = entityObj;
    return [graphData, entityObj];
  }

  setMapProperties(entityObj, obj) {
    entityObj.canvasPosition = {
      x: obj.x,
      y: obj.y
    };
    if (obj.latLng) {
      entityObj.locus = {
        lat: obj.latLng.lat(),
        lon: obj.latLng.lng()
      }
    } else if (!obj.locus && obj.type === 'node') {
      entityObj.locus = this.getDefaultLocus(obj);
    }
    // console.log('add', entityObj);
    // let deviceTemplate = this._deviceTemplates[obj.template]
    //   ? _.cloneDeep(this._deviceTemplates[obj.template])
    //   : _.cloneDeep(this._deviceTemplates["node"]);
    // newNode = _.merge(deviceTemplate, entityObj);
    delete entityObj.numToAdd;

    return entityObj;
  }
  getDefaultLocus(obj) {
    const defaultPos = [37.76487, -122.41948];

    let lat = defaultPos[0] + obj.x / 10000;
    let lon = defaultPos[1] + obj.y / 10000;
    // console.log('latlan', lat, lng);
    return {
      lat: +lat.toPrecision(8),
      lon: +lon.toPrecision(8)
    };
  }
  setActiveEntityUID(uid, type, graphData, activeEntity) {
    // console.log("setActiveEntityUID", activeEntity, uid, type);
    // this.lastActiveEntity = Object.assign({}, activeEntity);
    if (activeEntity && graphData.nodesObj[activeEntity.uid]) {
      graphData.nodesObj[activeEntity.uid] =
      {
        ...graphData.nodesObj[activeEntity.uid],
        active: false
      }
    }

    // if (!uid && graphData.nodes && graphData.nodes.length) {
    if (!uid) {
      // activeEntity = graphData.nodes[0];
      activeEntity = {};
    } else if (type === "link") {
      activeEntity = Object.assign({}, graphData.bisectorsObj[uid]);
    } else if (type === "group") {
      // console.log('graphData.groupsObj', graphData.groupsObj);
      activeEntity = Object.assign({}, graphData.groupsObj[uid]);
      // console.log('select gourp', activeEntity);
    } else if (graphData.nodesObj[uid]) {
      activeEntity = Object.assign({}, graphData.nodesObj[uid]);
      graphData.nodesObj[uid] = {
        ...graphData.nodesObj[uid],
        active: true
      }
      // this.nodeIsToggled = true;
    }
    // console.log('set', activeEntity.name);
    graphData.activeEntity = activeEntity;
    return [
      graphData,
      activeEntity
    ]
  }

  addLink(linkSent, graphData) {
    if (linkSent.sourceID && linkSent.sourceID === linkSent.targetID) {
      return {
        graphData: graphData,
        linkAdded: false,
        newLink: null
      };
    }
    let newBisector = new Bisector().deserialize(linkSent);
    console.log('linkSent', linkSent);
    const { linkObj, linkAdded } = this._linkService.configureLink(linkSent, graphData);
    newBisector = Object.assign({}, newBisector, linkObj);
    if (linkAdded) {
      graphData.bisectorsObj[newBisector.bisectorID] = newBisector;
      graphData.links.push(newBisector);
      if (graphData.nodesObj[newBisector.sourceID]) {
        graphData.nodesObj[newBisector.sourceID].targetIDs = graphData.nodesObj[newBisector.sourceID].targetIDs || {}
        graphData.nodesObj[newBisector.sourceID].targetIDs[
          newBisector.targetID
        ] = true;
      }
      if (graphData.nodesObj[newBisector.targetID]) {
        graphData.nodesObj[newBisector.targetID].sourceIDs = graphData.nodesObj[newBisector.targetID].sourceIDs || {}
        graphData.nodesObj[newBisector.targetID].sourceIDs[
          newBisector.sourceID
        ] = true;
      }
    }

    return {
      graphData: graphData,
      linkAdded: true,
      newLink: newBisector
    };
  }
}
