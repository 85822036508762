import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'cl-api-reference',
  templateUrl: './api-reference.component.html',
  styleUrls: ['./api-reference.component.scss']
})
export class ApiReferenceComponent implements OnInit {
  urlSafe: SafeResourceUrl = "";
  constructor( public sanitizer: DomSanitizer ) { }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://devcentral.parkoursc.com');
  }

}
