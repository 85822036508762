export class User {
  _id: string;
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  initials: string;
  role: string;
  avatar: string;
  projects: any[];
}

export interface IUserState  {
  users: GraphUserList;
  allUsersList: GraphUserList;
  activeUser: GraphUser;
  currentUser: GraphUser;
  nodeToAdd: {
    name: string;
    id: string;
    type: string;
    [key: string]: any;
  };
  selectedItems: { [key: string]: GraphUser };
  addingUser: boolean;
  editingUser: boolean;
  currentAction: string;
  headerTitle: string;
  userAdderOffset: number;
  singleUserSelected: boolean;
  allLoaded: boolean;
  loading: boolean;
  deleteInProgress: boolean;
  passwordResetInProgress: boolean;
  saveOrUpdateInProgress: boolean;
  scrollId: string;
  totalUsers: number;
  loadedUsers: number;
  searchTerm: string;
  userProperties?: any;

  [key: string]: any;
}

export interface GraphUser {
  name: string;
  id: string;
  type?: string;
  graphType?: string;
  email?: string;
  phone?: string;
  reporting_region?: string;
  manager?: string;
  manager_email?: string;
  baseType?: string;

  properties?: GraphUserProperties;

  roles?: string[];
  roleName?: string;
  deleted?: boolean;


  // UI properties
  checked?: boolean;
}

export declare type GraphUserList = Array<GraphUser>;

export enum UserCategory {
  ToolRoomManager     = 'TOOL_ROOM_MANAGER',
  FieldTechnician     = 'FIELD_TECHNICIAN',
  FieldServiceManager = 'FIELD_SERVICE_MANAGER'
};

export interface GraphUserProperties {
  modifiedTime?: number;
  passwordExpiry?: number;
  passwordExpiryInMillis?: string;
  roles?: string[];
  module?: string;
  userDevices?: GraphUserDevice[];
  type: string;
  enabled: boolean;
  locationAt?: string;
  passwordexpiryinmillis?: string;
  tenantId: string;
  name: string;
  email?: string;
  details?: GraphUserPropertiesDetails;
  id: string;
  locked?: boolean;
  proxyLogin?: boolean;
  properties?: {
    passwordexpiryinmillis: string;
    [key: string]: string;
  };
  isFederated?: string | boolean;
  category?: UserCategory;
  manager?: string;
  isoptedforsms?: string;
  phone?: string;
  locationId?: string;
  reporting_region?: string;
  appRole?: string;
  manager_email?: string;
  emailActivationStatus?: string;

  // [key: string]: any;
}
interface GraphUserPropertiesDetails {
  failedLoginMillis: string;
  loginError: string;
  failedLoginAttempts: string;
}

interface GraphUserDevice {
  id: string;
  userId: string;
  deviceIdentifier: string;
  transport: string;
  status: string;
}
