import { DatePipe } from "@angular/common";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CustodyActions, CustodyChainResponse, CustodyChainState, Entity, UserCategory, UserSearchResult } from "../../common/models/index";
import { Store } from "@ngxs/store";
import { StateActionMapPipe } from "../../common/pipes/state-action-map.pipe";
import * as FileSaver from "file-saver";
import _ from "lodash";
import { Observable, Subject, throwError } from "rxjs";
import { catchError, shareReplay, switchMap, take, tap } from "rxjs/operators";
import { CheckoutTransferConfirmDialogComponent } from "../../asset/components/checkout-transfer-confirm-dialog/checkout-transfer-confirm-dialog.component";
import { GraphAPIService } from "../../common/services/graph-api.service";
import { UtilsService } from "../../common/utils/utils.service";
import { UserService } from "../../user/user.service";
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ChainOfCustodyService {
  private actionStateMap: CustodyChainState;

  private actionStateMapLoadedSubject = new Subject<CustodyChainState>();
  public closeSidePanel = new Subject<any>();
  loggedInUser: any;
  policies: any[] = [];
  private cocEvent = new Subject<any>();
  constructor(
    private matDialog: MatDialog,
    private graphApi: GraphAPIService,
    private datePipe: DatePipe,
    private util: UtilsService,
    private store: Store,
    private userService: UserService,
    private apiProvider: ApiProviderService,
    private http: HttpClient,
    private router: Router
  ) {
    this.loggedInUser = this.userService.user;
    this.policies = this.userService.getPolicies();
    // this.loadStateChangeActions();
  }

  get actionStateMapLoaded$() {
    return this.actionStateMapLoadedSubject.asObservable().pipe(shareReplay(1));
  }

  setCOCEvent(eventSuccess: any) {
    this.cocEvent.next(eventSuccess);
  }

  getCOCEvent(): Observable<any> {
    return this.cocEvent.asObservable();
  }

  /**
   * Common method to open checkout and transfer dialog
   * @param dialogData Data to pass to the dialog
   */
  private openConfirmDialog(dialogData?: any) {
    return this.matDialog
      .open(CheckoutTransferConfirmDialogComponent, {
        hasBackdrop: true,
        // height: '275px',
        width: "280px",
        data: dialogData,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        tap((data) => {
          if (data?.accepted !== true) throw new Error("Cancelled Coc");
        })
      );
  }

  bulkCoc(payload: any[], operation: CustodyActions) {
    const actionUrlMap = {
      [CustodyActions.Checkout]: "asset/checkout/bulk",
      [CustodyActions.Transfer]: "asset/transfer/bulk",
      [CustodyActions.InService]: "asset/service/bulk",
      [CustodyActions.Confirm]: "asset/accept/bulk",
      [CustodyActions.CheckIn]: "asset/checkin/bulk",
      [CustodyActions.Return]: "asset/return/bulk",
      [CustodyActions.CancelledCheckout]: "asset/revoke/bulk",
      [CustodyActions.CancelledReturn]: "asset/revoke/bulk",
      [CustodyActions.CancelledTransfer]: "asset/revoke/bulk",
    };

    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/${actionUrlMap[operation]}`;
    return this.http.post(url, payload);
  }

  exportChainOfCustody(chainOfCustody: CustodyChainResponse, assetId: string) {
    const csv = this.convertChainOfCustodyToCSV(chainOfCustody);
    const fileName = `${assetId}_custody_${new Date().getTime()}.csv`;

    FileSaver.saveAs(csv, fileName);
  }

  /**
   *
   * @see https://stackoverflow.com/a/51488124/2401088
   */
  private convertChainOfCustodyToCSV(data: CustodyChainResponse): Blob {
    const headerMappings = {
      fromParty: "From",
      toParty: "To",
      locationId: "Location ID",
      eventTime: "Event Time",
      state: "Operation",
      note: "Performed By",
    };

    const replacer = (key: any, value: any) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);

    const csv = data.map((row) =>
      header
        .map((fieldName) => {
          let val = (row as any)[fieldName];

          if (fieldName === "eventTime") {
            val = this.datePipe.transform(row[fieldName], "MM/dd/yyyy hh:mm:ss a");
          } else if (fieldName === "state") {
            val = StateActionMapPipe.transform(row[fieldName]);
          }

          return JSON.stringify(val, replacer);
        })
        .join(",")
    );

    csv.unshift(header.map((h) => (headerMappings as any)[h]).join(","));
    const csvArray = csv.join("\n");

    const blob = new Blob([csvArray], { type: "text/csv" });

    return blob;
  }

  /**
   * Initializes all possible state change action button.
   */
  private loadStateChangeActions() {
    this.graphApi.assetStateValidation$.subscribe((actionStateMap) => {
      this.actionStateMap = actionStateMap;
      this.actionStateMapLoadedSubject.next(actionStateMap);
      this.actionStateMapLoadedSubject.complete();
    });
  }

  getActionStateMap(asset: any) {
    const stateActionMap = [
      {name: 'ACCEPT', label: 'Confirm Receipt', enabled: false},
      {name: 'CHECKIN', label: 'Check In', enabled: false},
      {name: 'CHECKOUT', label: 'Check Out', enabled: false},
      {name: 'POSSESSION', label: 'In Possession', enabled: false},
      {name: 'TRANSFER', label: 'Transfer', enabled: false},
      {name: 'RETURN', label: 'Return', enabled: false},
      {name: 'SERVICE', label: 'Service', enabled: false},
      {name: 'CANCELLED_CHECKOUT', label: 'Cancel Checkout', enabled: false},
      {name: 'CANCELLED_TRANSFER', label: 'Cancel Transfer', enabled: false},
      {name: 'CANCELLED_RETURN', label: 'Cancel Return', enabled: false},
    ]

    /* const stateActionMap = {
      ACCEPT: { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
      : { visible: false, enabled: false },
    }; */

    let nextOperations;

    try {
      nextOperations = _.cloneDeep(asset?.nextOperations);
      nextOperations = JSON.parse(nextOperations);
    } catch (e) {
      // console.warn("Unable to parse nextOperations");
    }

    if (Array.isArray(nextOperations)) {
      nextOperations.forEach((op: CustodyActions) => {
        let opIndex = stateActionMap.findIndex(item => item.name === op);
        let enableOperation = opIndex > -1;
        if (enableOperation) {
          stateActionMap[opIndex].enabled = this.canEnableCustodyAction(op, asset);
        }
      });
    } else {
      _.forOwn(this.actionStateMap, (states: any, op: any) => {
        if (!(stateActionMap as any)[op]) {
          (stateActionMap as any)[op] = { visible: false, enabled: false };
        }

        let enableOperation = states.includes(asset?.properties?.state);

        (stateActionMap as any)[op].enabled = enableOperation && this.canEnableCustodyAction(op, asset);
      });
    }
     /** List of all operations a Tool Room Manager Audit asset can perform */
     const operationsPerformedByTLMAuditAsset = [
      CustodyActions.AuditAsset,
    ];

    /** List of all operations a Tool Room Manager Audit User can perform */
    const operationsPerformedByTLMAuditUser = [
      CustodyActions.AuditUser,
    ];

    const isAuditAssetEnabled = this.policies.includes('CUSTODYASSET_COC_AUDIT_ASSET') ? true : false;
    const isAuditUserEnabled = this.policies.includes('CUSTODYASSET_COC_AUDIT_USER') ? true : false;
    const isAssetDetailsPage = this.router.url.indexOf('assetdetails')>-1? true : false;
    if(isAuditAssetEnabled){
      stateActionMap.push({name: 'AUDIT_ASSET', label: 'Audit Asset', enabled: asset?.custodyStatus == "IN POSSESSION"});
    }

    if(isAuditUserEnabled && !isAssetDetailsPage){
      stateActionMap.push({name: 'AUDIT_USER', label: 'Audit User', enabled: true});
    }

    return stateActionMap;
  }

  private canEnableCustodyAction(operation: CustodyActions, asset: any): boolean {
    let enable = false;

    /** List of all operations a Tool Room Manager can perform */
    const operationsPerformedByTLM = [
      CustodyActions.InService,
      CustodyActions.Checkout,
      CustodyActions.CancelledCheckout,
      CustodyActions.CheckIn,
      CustodyActions.Confirm,
      CustodyActions.Return,
      CustodyActions.CancelledTransfer,
      CustodyActions.CancelledReturn,
      CustodyActions.Transfer,
    ];

    /** List of all operations a Field Service Manager or Field Technician can perform */
    const operationsPerformedByFSMOrFT = [
      CustodyActions.Transfer,
      CustodyActions.Return,
      CustodyActions.InService,
      CustodyActions.CancelledTransfer,
      CustodyActions.CancelledReturn,
      CustodyActions.Confirm,
    ];

    /** List of all cancel operations */
    const cancelOperations = [CustodyActions.CancelledCheckout, CustodyActions.CancelledReturn, CustodyActions.CancelledTransfer];

    /** `true` if the current operation is one of `cancelOperations` */
    const isCancelOperation = cancelOperations.includes(operation);
    const isTRMUser = this.policies.includes('CUSTODYASSET_COC_CHECKOUT') ? true : false;

    const { toParty, fromParty } = asset;
    if (isTRMUser && operationsPerformedByTLM.includes(operation)) {
      enable = true;
    }else if (!isTRMUser && operationsPerformedByFSMOrFT.includes(operation)) {
      if (isCancelOperation) {
        enable = fromParty === this.loggedInUser?.id;
      } else {
        enable = toParty === this.loggedInUser?.id;
      }
    }

    return enable;
  }
}
