import { Component, EventEmitter, Input, OnInit ,Output} from '@angular/core';
import { BulkOperaionsService } from '@cl/bulk-operation/bulk-operations-service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { PropertyService } from '@cl/property/property.service';
import FileSaver from 'file-saver';
@Component({
  selector: 'cl-bulk-summary',
  templateUrl: './bulk-summary.component.html',
  styleUrls: ['./bulk-summary.component.scss']
})
export class BulkSummaryComponent implements OnInit {
  @Input() bulkId: any;
  @Input() bulkType: any;
  @Output() closeSidePanel = new EventEmitter();
  loading: boolean;
  summaryData:any;
  dateDisplayFormat:any;

  constructor(private BulkSerivce:BulkOperaionsService,private pService: PropertyService,public datePipe : FormateDatePipe) { }

  ngOnInit(): void {
    this.loading=true;
    this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
    this.dateDisplayFormat = this.dateDisplayFormat.replace('dd', 'DD');
    this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');
 
  }
  ngOnChanges(){
    this.loading=true;
    this.BulkSerivce.getBulkDetails(this.bulkId).subscribe(data=>{
      console.log(data);
      this.loading=false;
      this.summaryData=data
    })
  }
  closePanel() {
    this.closeSidePanel.emit();
  }
  downloadFile(type,jobid){
    this.BulkSerivce.downloadFile(jobid,type).subscribe((data:any)=>{
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href:data
      }).click();
    })
  }
  stringTruncate(value: string, size: number = 15): string {
    if (!value) return '';

    const limit = size > 0 ? size : 15;
    return value.length > limit
      ? value.substring(0, limit).trim() + '...'
      : value;
  }
}
