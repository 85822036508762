import { GatewayDeviceType } from "@cl/@enums/gateway-device-type.enum";
import { GatewayPower } from "@cl/@enums/gateway-power.enum";
import { GatewayStatus } from "@cl/@enums/gateway-status.enum";
import { GatewayType } from "@cl/@enums/gateway-type.enum";
import { CdmField, Position } from "./asset.type";
export interface GatewayAttribute{
  power?: GatewayPower;
  physicalId?: string;
  backhaul? : string;
  model?: string;
  sku?: string;
  appVersion?: string;
  hardwareVersion?: string;
  overallStatus?: string;
  netStatus?: string;
  bleStatus?: string;
  netTrafficUp?: string;
  netTrafficDown?: string;
  loadScore?: string;
  wifiSSID?:string;
  rssiRange?: string;
}
export interface Gateway{
  id: string;
  name: string;
  status: GatewayStatus;
  imageUrl?: string;
  provisionedAt?: Date;
  unprovisioned?: Date;
  deviceType: GatewayDeviceType;
  isPoib : boolean;
  type: GatewayType;
  area?: GatewayArea;
  building?: GatewayBuilding;
  position?: Position;
  lastReported?: Date;
  tenantId: string;
  tenantName: string;
  healthStatus?: GatewayParsedHealthStatus;
  attribute?: GatewayAttribute;
  eventTabs?: EventTab[];
  properties?: GatewayProperties,
  cdmFields?: CdmField[];
}
export interface GatewayProperties{
  properties?:GatewaySubProperties
}
export interface GatewaySubProperties{
  createdAt:Date;
  createdBy:String;
  modifiedAt:Date;
  modifiedBy:string;
  organizationName:string;
}
export interface GatewayEvent {
  description: string;
  deviceid: string;
  devicetype: string;
  displayName: string;
  displayType: string;
  level: string;
  module: string;
  name: string;
  rank: number;
  time: number;
  type: string;
  value: string;
  filterSpec?: string;
}
export interface EventTab{
  name: string;
  tabColor?: string;
  status?: string;
  cause?: string;
  events?: GatewayEvent[];
}
export interface GatewayAttributeSummary {
  adv_filter: string;
  backhaul: string; //Backend is not sending this now
  batteryPowered: string;
  ble_connect_enabled: string;
  bracelet_connect_interval: string;
  broadcast_time: string;
  dual_radio_scan_enabled: string;
  edge_processing_enabled: string;
  enable_battery_read: string;
  fast_hb_interval: string;
  fwrev: string;
  hwrev: string;
  led_blink_off_duration: string;
  led_blink_on_duration: string;
  led_blink_total_duration: string;
  log_raw_rssi: string;
  macid: string;
  mfgdate: string;
  model: string;
  ooc_timeout_duration: string;
  persist_motion_duration: string;
  ports: string;
  qm_enabled: string;
  qm_preset_mpings: string;
  qm_preset_pings: string;
  qm_preset_time: string;
  qm_sku: string;
  qm_tag_type: string;
  radio1_dev: string;
  radio2_dev: string;
  radio_ids_enabled: string;
  radio_ids_txpower: string;
  rssiRange: string;
  rssiRangeDisplay: string;
  rssiRangeValue: string;
  rssi_bands: string;
  rssi_exp_alpha: string;
  rssi_filter: string;
  rssi_kalman_state_noise: string;
  rssi_msg_frequency: string;
  rssi_pole_pos: string;
  rssi_tag_motion_interval: string;
  rssi_tag_static_interval: string;
  rssi_timeout: string;
  rssi_variance: string;
  s3_sensor_read_interval: string;
  scan_interval: string;
  scan_window: string;
  scheme_db_sync_duration: string;
  sd_record_interval: string;
  sd_report_interval: string;
  sensor_log_read_interval: string;
  shock_enabled: string;
  sku: string;
  slow_hb_interval: string;
  suota_support_enable: string;
  tag_hb_interval: string;
  temperature_read_frequency: string;
  thingy52_adv_interval_duration: string;
  thingy52_adv_timeout_duration: string;
  thingy52_battery_read_frequency: string;
  thingy52_humidity_read_frequency: string;
  thingy52_pressure_read_frequency: string;
  thingy52_temperature_read_frequency: string;
  timestamp: string;
  tz: string;
  unadv_tag_cleanup_timeout: string;
  update: string;
  version: string;
  wifihwaddr: string;
}

export interface GatewayArea {
  type: string;
  identifier: string;
  name: string;
  order: number;
  deleted: boolean;
}

export interface GatewayBuilding {
  type: string;
  identifier: string;
  name: string;
  order: number;
  numFloors: number;
}

export interface GatewaySummary {
  identifier: string;
  type: string;
  name: string;
  physicalId: string;
  tenantId: string;
  status: string;
  lastEdgeState: string;
  edgeSyncState: string;
  attributes: GatewayAttributeSummary;
  provisionedAt: number;
  lastReported: number;
  position: Position;
  area?: GatewayArea;
  building?: GatewayBuilding;
}

export interface GatewayAttributeEvent {
  level: string;
  module: string;
  time: number;
  name: string;
  type: string;
  value: string;
}

export interface GatewayEvents {
  deviceId: string;
  deviceType: string;
  events: GatewayAttributeEvent[];
}
export interface GatewayHealthStatusMetric{

}
export interface GatewayHealthStatus {
  scope: string;
  type: string;
  metrics: GatewayHealthStatusMetric[];
}
export interface GatewayAttributeDetail {
  cssClass : string;
  value: any;
  cause?: string;
}
export interface AllTabs {
  ble: EventTab;
  net: EventTab;
  'net.mqtt'?: EventTab;
  system: EventTab;
}
export interface GatewayParsedHealthStatus {
  allEvents?: GatewayAttributeEvent[];
  backhaulType?: string;
  backhaulValue?: string;
  load?: string;
  type: GatewayDeviceType;
  bleStatus: GatewayAttributeDetail;
  netStatus: GatewayAttributeDetail;
  overallStatus: GatewayAttributeDetail;
  cpu: GatewayAttributeDetail;
  ram: GatewayAttributeDetail;
  hdd: GatewayAttributeDetail;
  netTrafficDown?: string;
  netTrafficUp?: string;
  tabs: AllTabs;
  systemUptime?: number;
  batteryStatusColor?: string;
}
export enum CommonStatus {
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW'
}

export enum GatewayTypeLabels {
  CLOUD_CONNECTOR = 'Gateway',
  MICRO_CLOUD_CONNECTOR = 'Zone Sensor(powered)',
  MOBILE_RECEIVER = 'Soft Gateway',
  Gateway = 'Gateway', 'Zone Sensor' = 'Zone Sensor'
}
export enum GatewayFilterLabels {
  deviceClass = 'Device Class',
  status = 'Status'
}
export enum GatewayMappedFilters {
  deviceClass = 'type',
  status = 'status'
}

export enum DeviceStatusLabels {
  all="All",
  red = "Offline",
  green = "Online",
  yellow = "Attention",
  "N/A"="N/A"
}
