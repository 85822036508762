<h2 mat-dialog-title>Update Credentials</h2>
<mat-dialog-content class="mat-typography">
  <div class="bcolor9 flex flex-col">
    <section>
      <form [formGroup]="credentialForm">
        <div class="p-2" *ngFor="let property of properties">
          <div class="grid-layout fs14 color16 mt-1" *ngIf="property['value']['uiRequired']">
            <div class="font-bold mt-2">{{property.value.displayName}}<span class="red"
                *ngIf="!property['value']['optional']">*</span></div>
            <div *ngIf="property['value']['dataType'] === 'String'">
              <ng-container *ngIf="!property.name.toLowerCase().includes('password') && !property.name.toLowerCase().includes('apikey')">
                <input type="text" class="basic-input" [formControlName]="property.name">
              </ng-container>
              <ng-container *ngIf="property.name.toLowerCase().includes('password') || property.name.toLowerCase().includes('apikey')">
                <input type="password" class="basic-input" [formControlName]="property.name">
              </ng-container>
              <label class="red ml-2" *ngIf="(credentialForm.controls[property['name']].invalid && (credentialForm.controls[property['name']].dirty || credentialForm.controls[property['name']].touched) &&
              credentialForm.controls[property['name']].hasError('required'))">
                Please enter {{property['value']['displayName']}}!
              </label>
              <label class="red ml-2"
                *ngIf="(credentialForm.controls[property['name']].invalid && (credentialForm.controls[property['name']].dirty || credentialForm.controls[property['name']].touched) &&
              (credentialForm.controls[property['name']].hasError('maxlength') || credentialForm.controls[property['name']].hasError('minlength') || credentialForm.controls[property['name']].errors['pattern']))">
                Please enter {{property['value']['description']}}!
              </label>
              <label class="red ml-2" *ngIf="(credentialForm.controls[property['name']].invalid && (credentialForm.controls[property['name']].dirty || credentialForm.controls[property['name']].touched) &&
               credentialForm.controls[property['name']].errors && credentialForm.controls[property['name']].errors['confirmPassword'])">
                {{property['value']['displayName']}} did not match!
              </label>
            </div>

            <div *ngIf="property['value']['dataType'] === 'Date'">
              <input type="text" class="basic-input datepicker-calendar-icon" ngxDaterangepickerMd [locale]="locale"
                [singleDatePicker]="true" (datesUpdated)="datesUpdated($event, property.name)" [drops]="up"
                [formControlName]="property.name" />

              <!-- </div> -->
              <label class="red ml-2" *ngIf="(credentialForm.controls[property['name']].invalid && (credentialForm.controls[property['name']].dirty || credentialForm.controls[property['name']].touched) &&
              credentialForm.controls[property['name']].hasError('required'))">
                Please enter {{property['value']['displayName']}}!
              </label>
              <label class="red ml-2"
                *ngIf="(credentialForm.controls[property['name']].invalid && (credentialForm.controls[property['name']].dirty || credentialForm.controls[property['name']].touched) &&
              (credentialForm.controls[property['name']].hasError('maxlength') || credentialForm.controls[property['name']].hasError('minlength') || credentialForm.controls[property['name']].errors['pattern']))">
                Please enter {{property['value']['description']}}!
              </label>
              <label class="red ml-2"
              *ngIf="(credentialForm.controls[property['name']].invalid && (credentialForm.controls[property['name']].dirty || credentialForm.controls[property['name']].touched) &&
              (credentialForm.controls[property['name']].hasError('max') || credentialForm.controls[property['name']].hasError('min') || credentialForm.controls[property['name']].errors['pattern']))">
              Please enter {{property['value']['description']}}!
              </label>
            </div>
            <div *ngIf="property['value']['dataType'] === 'Date'">
              <span class="relative top-3 right-8"><a class="color4"><span
                    class="fa fa-calendar ngx-daterangepicker-action"></span></a></span>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a class="panel-button m-2" (click)="cancel()">Cancel</a>
  <button class="panel-button white bgcolor4" cdkFocusInitial (click)="updateAccount()"
    [disabled]="!credentialForm.valid">Update</button>
</mat-dialog-actions>
