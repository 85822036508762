<div class="input-group date-picker-font" [ngClass]="addClass">
  <input type="text" class="form-control m0 p5" [placeholder]="placeholder" ngxDaterangepickerMd [(ngModel)]="selectedDateRange" [ranges]="ranges"
    [alwaysShowCalendars]="true"  [singleDatePicker]="singleDatePicker" [showDropdowns]="true" [locale]="locale" [timePicker]="timePicker" [timePicker24Hour]="timePicker24Hour"
    [timePickerSeconds]="false" (datesUpdated)="datesUpdated($event)" [minDate]="minDate" [maxDate]="maxDate" />
  <span class="input-group-text">
    <a class="color4">
      <span class="fa fa-calendar ngx-daterangepicker-action" (click)="openDatepicker()"></span>
    </a>
  </span>
</div>
