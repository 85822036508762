import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, ElementRef, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { UserService } from '../../user.service';
import { ToastService } from '@cl/common/services/toast.service';

@Component({
  selector: 'cl-role-side-panel',
  templateUrl: './role-side-panel.component.html',
  styleUrls: ['./role-side-panel.component.scss']
})
export class RoleSidePanelComponent implements OnInit, OnChanges {
  @Output() closeSidePanel:EventEmitter<void> = new EventEmitter();
  @Input() roleObject: any;
  @Input() mode: any;

  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  public columns: any = [];
  public rawColumns: any =  [
    {
      prop: 'selected',
      name: '',
      sortable: false,
      canAutoResize: false,
      draggable: false,
      resizable: false,
     headerCheckboxable: false,
      checkboxable: true,
      width: 30,
      visible: true
    },
    { name: 'Policy Name', prop: 'name', width: 180, visible: true },
    { name: 'Entity Name', prop: 'entityName', width: 200, visible: true },
    { name: 'Actions', prop: 'actions', width: 150, visible: true }];


  public selectedRows: any = [];
  public selectedRowArray: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  actionCount: number = 0;
  newRoleName = '';
  roleScope = 'TENANT';
  defaultRoleFor = false;
  addRoleDisabled = false;

  roleScopeList = ['TENANT','SHARED'];
  isSuperAdmin = false;
  private selectedPolicyType:any[]=[];
  private selectedEntityType:any[]=[];

  private policyType = {
    'collapsed':false,
    'enableCollapsing':false,
    'filterType':'Policy Type',
    'label':'Policy Type',
    'type':'multiselect',
    'list':[{
      'checked':false,
        'name':'Custom Policies',
        'entityId':'CUSTOMER_MANAGED',
        'filterType':'Policy Type',
    },
    {
      'checked':false,
        'name':'System Policies',
        'entityId':'CLOUDLEAF_MANAGED',
        'filterType':'Policy Type',
    }
  ]
  }

  private addRolePayload = {
    "id":'',
    "name":'',
    "tenantId":"",
    "moduleName":"ALL",
    "policies":[
    ],
    "roleProperties":{
       "roleScope":"TENANT"
    },
    "sharedTenants" : []
   }

  constructor(
    private userService: UserService,
    private el: ElementRef,
    private toastService: ToastService,
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.userService.isSuperAdmin();
    let columns: any = _.cloneDeep(this.rawColumns);
    this.columns = columns.filter((col: any) => col.visible);
    this.getPolicylist(null);
    this.getEntityFilters();
    // if(localStorage.getItem('selectedRow'))
    // this.selected =JSON.parse(localStorage.getItem('selectedRow'));
    if(this.roleObject){
      this.roleScope = this.roleObject?.roleProperties?.roleScope;
      this.defaultRoleFor = this.roleObject?.roleProperties?.defaultRoleFor == 'TENANT_ADMIN' ? true : false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.rows.length>0){
      this.setDefaultData();
    }
   }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  private getPolicylist(data:any): void {
    this.isLoading = true;
    const userPolicyListData: Subscription = this.userService.postUserPolicies(data).subscribe((data:any)=>{
      this.rows = [...data];
      this.tableRowData = [...this.rows];
      this.checkForSlectedRows();
      this.setDefaultData();
      this.isLoading = false;
    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(userPolicyListData);
  }

  private getEntityFilters():void{
    const getEntityFilterData: Subscription = this.userService.getEntityForUserRoleFilters().subscribe((data:any)=>{
    let filterArray:any = [];
    let listArray:any = [];
      data.forEach((d:any) => {
        listArray.push(new Object({
        'checked':false,
        'name':d.uiLabel,
        'entityId':d.entityId,
        'filterType':'Entity Type',
      }));
    });
    filterArray.push(this.policyType);
    filterArray.push(new Object({
      'collapsed':false,
      'enableCollapsing':false,
      'filterType':'Entity Type',
      'label':'Entity Type',
      'type':'multiselect',
      'list':listArray
    }));

      this.filters = [...filterArray];
    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(getEntityFilterData);
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getId(row) {
    return row.id;
  }

  onSelect(rowData: any, event?:any) {
    const {selected} = rowData;
    this.selectedRowArray = [...selected];
  }

  checkForSlectedRows() {
   this.selectedRows.splice(0, this.selectedRows.length);
   this.selectedRows.push(...this.selectedRowArray);
  }

  removeSeletedTag(item){
    this.selectedRows.splice(this.selectedRows.indexOf(item), 1);
    this.selectedRows = [...this.selectedRows];
  }

  getPanelUpdate(event:any){
    let filterEvent = event.filter;
      if(filterEvent.checked){
          if(filterEvent.filterType === 'Policy Type'){
            this.selectedPolicyType.push(filterEvent.entityId);
          }else if(filterEvent.filterType === 'Entity Type'){
            this.selectedEntityType.push(filterEvent.entityId);
          }
      }else{
        if(filterEvent.filterType === 'Policy Type'){
          let index = this.selectedPolicyType.indexOf(filterEvent.entityId);
          this.selectedPolicyType.splice(index, 1);
        }else if(filterEvent.filterType === 'Entity Type'){
          let index = this.selectedEntityType.indexOf(filterEvent.entityId);
          this.selectedEntityType.splice(index, 1);
        }
      }
      this.createPayloadAndReloadPolicyList();
  }


  createPayloadAndReloadPolicyList(){
    let payLoad = new Object({
      entities:this.selectedEntityType,
      policyType:this.selectedPolicyType
    });
    this.getPolicylist(payLoad);
  }

  saveRole(){
    if(!this.newRoleName){
      return;
    }
    this.addRoleDisabled = true;
    this.addRolePayload.name = this.newRoleName;
    this.addRolePayload.roleProperties.roleScope = this.roleScope || 'TENANT';
    if(this.defaultRoleFor){
      this.addRolePayload.roleProperties['defaultRoleFor']= 'TENANT_ADMIN';
    }
    this.addRolePayload.tenantId = this.userService.getEffectiveTenantId();
    this.selectedRows.forEach(element => {
      this.addRolePayload.policies.push(new Object({
        id:element.id
      }));
    });
    if(this.mode == 'add'){
      this.addRolePayload.sharedTenants = null;
      this.addRolePayload.id = null;
      this.addRole();
    }else if(this.mode == 'edit'){
      this.addRolePayload.id = this.roleObject.id;
      this.addRolePayload.roleProperties.roleScope = this.roleScope;
      if(this.defaultRoleFor){
        this.addRolePayload.roleProperties['defaultRoleFor'] = 'TENANT_ADMIN';
      }
      this.addRolePayload.sharedTenants = this.roleObject?.sharedTenants;
      this.editRole();
    }

  }

  addRole(){
    const addRoleSubscription:Subscription = this.userService.postRole(this.addRolePayload).subscribe((data:any)=>{
      this.addRoleDisabled = false;
      this.toastService.success('Role Added successfully', this. addRolePayload.name);
        this.closePanel();
    }, (error) => {
      console.error(error);
      this.addRoleDisabled = false;
    });

    this.subscriptions.push(addRoleSubscription);
  }

  editRole(){
    this.addRolePayload.moduleName = this.roleObject.moduleName;
    const addRoleSubscription:Subscription = this.userService.putRole(this.addRolePayload).subscribe((data:any)=>{
      this.addRoleDisabled = false;
      this.toastService.success('Role Updated successfully', this. addRolePayload.name);
        this.closePanel();
    }, (error) => {
      console.error(error);
      this.addRoleDisabled = false;
    });

    this.subscriptions.push(addRoleSubscription);
  }

  closePanel(){
    this.closeSidePanel.emit();
  }

  setDefaultData(){
    if(this.mode == 'edit'){
      this.newRoleName = this.roleObject.name;
      let seletedRoleArray  = [];
      this.roleObject.policies.forEach(element => {
        this.tableRowData.forEach(row => {
          if(element.id == row.id){
            seletedRoleArray.push(row);
          }
        });
      });
      this.selectedRowArray = [...seletedRoleArray];
      this.checkForSlectedRows();
    }
  }
}
