<button class="navbar-toggler toggler-example align-grid-menu p-2 border-0" type="button" data-toggle="collapse"
    (click)="toggleContainer()"
    data-target="#navbarSupportedContent1" aria-label="Toggle navigation"><span class="dark-blue-text"><i
            class="fa fa-cog fa-1x color4" [title]="toolTip"></i></span></button>

<div id="navbarSupportedContent1" class="toggle-container overflow-hidden" *ngIf="showToggleContainer">
    <div class="toggle-container__header">{{'common.customizeColumns'|translate}}
        <span  (click)="toggleContainer()" class="close-icon fa fa-times-circle-o"></span>
    </div>
    <div class="toggle-container__sub-header">{{'common.columnName'|translate}}</div>
    <div class="report-view" *ngIf="havingImageColumn">
        <section>
            <mat-checkbox [checked]="showImage" (change)="toggleImageColumn()">
            <label class="ml10">Show Image</label>
            </mat-checkbox>
        </section>
    </div>
    <div class="overflow-auto max-h-[440px]">
        <div *ngFor="let col of columnList" class="report-view">
            <section *ngIf="col.name">
                <mat-checkbox [checked]="col.visible" (change)="toggleColumn(col)">
                <label class="ml10">{{col.name}}</label>
                </mat-checkbox>
            </section>
        </div>
    </div>
</div>
