import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';

@Injectable({
  providedIn: 'root'
})
export class TenantSettingService {

  constructor(private http: HttpClient, private apiProvider: ApiProviderService) { }


  getTenantSettingData(){
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/user/properties?inclTenant=true`);
  }

  getFactoryData(){
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/properties/factory`);
  }

  saveTenentSettingData(payload){
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/properties`, payload);
  }
}
