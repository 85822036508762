<div>
  <div class="mb-4 -mt-1"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>
  <div class="flex justify-between">
    <div class="flex flex-row mb-2">
      <button
      [disabled]="bindDisabled"
      [ngClass]="[bindDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32 br2']"
            [clIsValidAction]="bindAction === 'Bind' ? actionBtnPolicies.bind : actionBtnPolicies.unbind"
            class="p-4-10 border-general" (click)="bindOrUnbind()">
            <i class="fa " [ngClass]='bindIcon'></i>
            {{bindAction}}
      </button>
      <button
      [disabled]="bindDisabled"
      [ngClass]="[bindDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
       *ngIf="bindAction === 'Unbind'" [clIsValidAction]="actionBtnPolicies.replace"
        class="p-4-10 border-general br2 ml10" (click)="replaceSensor()">
        <i class="fa fa-retweet"></i>
        Replace
      </button>
      <div *ngIf="delayedRefreshStart">
        <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer" [actionMessage]="'redirecting to sensors'"
                              (refreshList)="navigateToSensorList()"
        ></cl-refresh-countdown>
      </div>
    </div>
    <div>
      <cl-recipe-actions [entityTypeId]="sensorObject?.properties?.entityTypeId"  [entityId]="sensorObject?.properties?.id" [parentEntityType]="sensorObject?.properties?.parentEntityTypeKey" ></cl-recipe-actions>
    </div>
  </div>
  <div class="wt-ngx-grid-wrapper relative" style="background-color: transparent;">
    <div class="asset-header-wrapper">
      <div class="flex items-stretch flex-wrap">
          <div class="asset-flex-card bgcolor2 ml0 mr10">
              <cl-sensor-summary [sensor]="sensorObject"></cl-sensor-summary>
              <div class="font-16-normal mb-2">Current Conditions</div>
              <cl-sensor-conditions [sensor]="sensorObject"></cl-sensor-conditions>
          </div>
          <div class="asset-flex-card bgcolor2 mr0 ml10">
            <div class="row">
              <div class="col text-left" *ngIf="sensorObject?.asset?.location">
                <span class="font-14-bold">Location:</span>&nbsp;<span [textContent]="sensorObject?.asset?.location"></span>
              </div>
              <div class="col text-left" *ngIf="sensorObject?.asset?.location === 'Out Of Coverage' && sensorObject?.asset?.lastLocation">
                <span>Last Location:</span>&nbsp;<span [textContent]="sensorObject?.asset?.lastLocation"></span>
              </div>
              <div class="col text-left">
                <span>LAT/LONG:</span>&nbsp;<span [textContent]="sensorObject?.asset?.position?.lat"></span>&nbsp;<span [textContent]="sensorObject?.asset?.position?.lon ?? '-'"></span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col text-left">
                <mat-checkbox  [checked]="showHistory" (change)="handlehistoryVisibility()">
                  <label class="font-14-bold">Show History</label>
               </mat-checkbox>
              </div>
               <div class="col text-right" *ngIf="showHistory">
                  <cl-date-picker [dateRange]="selectedDateRange" [ranges]="ranges" (dateChanged)="datesUpdated($event)"></cl-date-picker>
              </div>
            </div>
            <agm-map [latitude]="sensorObject?.asset?.position?.lat" [longitude]="sensorObject?.asset?.position?.lon"
              [streetViewControl]="false" [gestureHandling]="mapOptions.gestureHandling" [scrollwheel]="null" [fitBounds]="fitBoundsFlag"
              [fullscreenControl]="true" [fullscreenControlOptions]="mapOptions.positionOfActions"
              [zoomControl]="true" [zoomControlOptions]="mapOptions.positionOfActions" [minZoom]="2" [maxZoom]="20" [zoom]="(sensorObject?.asset?.position?.lat === null || sensorObject.asset?.position?.lon === null ) ? 2 : 20"
              [styles]="mapOptions.styles" [backgroundColor]="'none'" [disableDefaultUI]="true" [showDefaultInfoWindow]="true"
              [keyboardShortcuts]="false">
              <agm-marker *ngIf="!showHistory"  [latitude]="sensorObject?.asset?.position?.lat" [longitude]="sensorObject?.asset?.position?.lon"
                [iconUrl]="'assets/svgs/organization.svg'" [agmFitBounds]="true">
                <agm-info-window>
                  <span>Time: {{lastReportedGPSTimeFormatted}}</span><br/> <span>Latitude: {{sensorObject?.asset?.position?.lat}}</span><br/><span>Longitude: {{sensorObject?.asset?.position?.lon}}</span></agm-info-window>
              </agm-marker>
              <agm-marker *ngFor="let marker of markers;let m=index" [latitude]="marker.lat" [longitude]="marker.lng"
              [iconUrl]="getIconUrl(m)" [agmFitBounds]="true">
                <agm-info-window>
                  <span>Time: {{marker.latestTime}}</span><br/>
                  <span>Latitude: {{marker.lat}}</span><br/>
                  <span>Longitude: {{marker.lng}}</span>
                </agm-info-window>
              </agm-marker>
              <agm-polyline [visible]="true">
                <agm-polyline-point
                *ngFor="let coordinate of markers; let i=index"
                [latitude]="coordinate.lat"
                [longitude]="coordinate.lng">
                </agm-polyline-point>
                <agm-icon-sequence [strokeColor]="'#2879DE'" [repeat]="'10px'" [strokeWeight]="2"  [scale]="2" [strokeOpacity]="100"  [path]="'M 0,-1 0,1'">
                </agm-icon-sequence>
              </agm-polyline>
            </agm-map>
          </div>
      </div>
    </div>
    <div class="mt20 mb5 ml5 page-title">Activity & History</div>
    <div class="asset-flex-card flex-card-additional bgcolor2 mr0 pb-5">
      <cl-sensor-activity [sensor]="sensorObject"></cl-sensor-activity>
    </div>
    <div *ngIf="sidePanelsObj.bind">
      <cl-sensor-binder [sensorObject]="sensorObject" (closeSidePanel)="closeSidePanel($event)"></cl-sensor-binder>
    </div>
    <div *ngIf="sidePanelsObj.replace">
      <cl-sensor-replace [sensorObject]="sensorObject" (closeSidePanel)="closeSidePanel()"></cl-sensor-replace>
    </div>
    <div *ngIf="isLoading">
      <div class="mt-2 flex justify-center loader-pos">
        <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
      </div>
    </div>
  </div>
</div>
