import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { RulesService } from '@cl/business-rules/rules.service';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { ToastService } from '@cl/common/services/toast.service';
import { ShipmentListStateService } from '@cl/shipments/shipment-list/shipment-list-state.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'cl-shipment-rule-set',
  templateUrl: './shipment-rule-set.component.html',
  styleUrls: ['./shipment-rule-set.component.scss'],
})
export class ShipmentRuleSetComponent implements OnInit, OnChanges {
  @Input() RulesListInput: any;
  @Output() SendRuleSetAction = new EventEmitter<boolean>();
  @Output() applyRuleSet = new EventEmitter<{rulesetId: string; shipments: {id: string; entityTypeKey: string}[]}>();
  close: boolean = true;
  status: boolean = true;
  loading: boolean = false;
  rulesetdata: any;
  ruleset: any;
  carriers: any = [];
  Rulename: any;
  showRulesetDropdown: boolean = false;
  rulesLoading: boolean = false;
  subscriptions: any = [];
  
  SelectedShipments: any;
  myControl = new FormControl();
  tempValue :any;

  constructor(
    public shipment_service: ShipmentService,
    public rulesService: RulesService,
    public toastService: ToastService,
    private _shipmentListState: ShipmentListStateService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.getRulesList();
    this.tempValue = this.Rulename
  }

  ngOnInit(): void {
    // this.rulesetdata = require('../../../@data/shipment-rule-set.json');
    // console.log(this.rulesetdata);
  }

  displayFn(item) {
    if (item) {
      if (item.name) {
        return item.name;
      } else {
        return item;
      }
    }
  }

  getRulesList() {
    this.rulesLoading = true;
    const rulesSub: Subscription = this.rulesService
      .getRulesetList()
      .subscribe({
        next: (res: any) => {
          this.rulesLoading = false;
          this.rulesetdata = res;
        },
        error: (err: any) => {
          this.rulesLoading = false;
          console.error(err);
        },
      });

    this.subscriptions.push(rulesSub);
    this.selectedShipment();
  }
  changeRule(value) {
    this.tempValue = value.name
  }
  applyruleset() {
    this.loading = true;
  }
  applyRule(value) {
    var x = this.rulesetdata.filter(e => e.name == value)
    const shipments = this.SelectedShipments.map((shipment) => {return {id: shipment.id, entityTypeKey: shipment?.entityTypeKey}});
    var applyRulesetPayload = {
      rulesetId: x[0].id,
      shipments: shipments,
    };
    this.applyRuleSet.emit(applyRulesetPayload)
  }
  closemodel() {
    this.close = !this.close;
    this.SendRuleSetAction.emit(this.close);
  }
  searchKey() {
    if (this.Rulename != '') {
      var x = this.rulesetdata.filter((res) => {
        return res.name
          .toLocaleLowerCase()
          .match(this.Rulename.toLocaleLowerCase());
      });
      if (x.length == 0) {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }
  selectedShipment() {
    this.SelectedShipments = this._shipmentListState.settings.tags.selectedtags;
    if (this.SelectedShipments.length == 1)
      this.Rulename = this.SelectedShipments[0].ruleSetName;
  }
  matClose(){
    this.Rulename=this.tempValue;
 }
 matOpen(){
   this.Rulename=undefined;
}
}
