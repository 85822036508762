<h2 mat-dialog-title>API User Create Successfully!</h2>
<mat-dialog-content class="mat-typography">
  <div class="p-4">
    <div class="flex fs14 color16 mt-1">
      <div class="basis-1/4 font-bold">User ID</div>
      <div class="basis-1/2">{{data.login}}</div>
    </div>
    <div class="flex fs14 color16 mt-1">
      <div class="basis-1/4 font-bold">Password</div>
      <div class="basis-1/2 break-all">{{data.secret}}</div>
    </div>
    <p class="mt-2">Note: Please download and save your credentials, note that this is the only time you will be
      able to access these credentils, they wont be accessible once the dialog is closed.
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="panel-button white bgcolor4" cdkFocusInitial (click)="download()">Download</button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
