export const DT_UPDATED = "dtUpdated";
export const RELOAD_ENTITIES = "reloadEntities";
export const ENTITY_DRAG_STARTED = "entityDragStarted";
export const ENTITY_DROPPED = "entityDropped";
export const EXISTING_ENTITY_DROPPED = "existingEntityDropped";
export const ENTITY_DRAG_ENTER = "entityDragEnter";
export const ENTITY_CREATED = "entityCreated";
export const ENTITY_EDITED = "entityEdited";
export const ENTITY_DELETED = "entityDeleted";
export const ADD_BLANK_ENTITY = "addBlankEntity";
export const ADD_REGISTERED_ENTITIES = "addRegisteredEntities";
export const EDIT_ENTITY = "editEntity";
export const SWITCH_VIEW_MODE = "switchViewMode"
export const EXPORT_DATASET = "exportDataset";
export const STEP_FORWARD = "stepForward";
export const STEP_BACKWARD = "stepBackward";
export const NEXT_POSITION_MODE = "nextPositionMode";
export const PREV_POSITION_MODE = "prevPositionMode";
export const CLEAR_DATA = "clearData";
export const CLEAR_CANVAS = "clearCanvas";
export const UPDATE_NUM_TO_ADD = "updateNumToAdd";
export const UPDATE_PREFIX = "updatePrefix";
export const LOAD_GRAPH_DATA_FROM_API = "loadGraphDataFromAPI";
export const SELECT_LIST_ITEM = "selectListItem";
export const SELECT_CANVAS_NODE = "selectCanvasNode";
export const SHOW_TOP_LOCS_BY_ALERT = "showTopLocsByAlert";
export const SELECT_SHIPMENT = "selectShipment";
export const SELECT_LOCATION = "selectLocation";
export const ADD_LINK = "addLink";
export const ADD_ATTRIBUTE = "addAttribute";
// export const ADD_EVENT = "addEvent";
// export const ADD_COMMAND = "addCommand";
export const ADD_RULE = "addRule";
export const UPDATE_RULE = "updateRule";
export const UPDATE_RULE_THRESHOLD = "updateRuleThreshold";
export const UPDATE_RULE_ACTION = "updateRuleAction";
// export const ADD_STREAM = "addStream";
export const SWAP_ACTIVE = "swapActive";
export const NODE_TOGGLED = "nodeToggled";
export const DESELECT_ACTIVE_ENTITY = "deselectActiveEntity";
export const INSPECT_LINK = "inspectLink";
export const MOVE_NODE = "moveNode";
export const MOVE_GROUP = "moveGroup";
export const MOVE_BINODE = "moveBinode";
export const CREATE_GROUP = "createGroup";
export const SELECT_GROUP = "selectGroup";
export const TOGGLE_GROUP = "toggleGroup";

export const ADD_RULE_TO_NODE = "addRuleToNode";
export const ADD_RULE_NODE = "addRuleNode";

export const REMOVE_MEMBER_FROM_GROUP = "removeMemberFromGroup";
export const SELECT_NEXT = "selectNext";
export const SELECT_UP = "selectUp";
export const SELECT_PREVIOUS = "selectPrevious";
export const SELECT_DOWN = "selectDown";
export const DELETE_SELECTED_ENTITY = "deleteSelectedEntity";
export const ADD_NODE_TO_GROUP = "addNodeToGroup";

export const SAVE_ENTITY = "saveEntity";
export const SAVE_GEOFENCE = "saveGeofence";

export const DELETE_ENTITY = "deleteEntity";
export const SAVE_RULE = "saveRule";


export const CLUSTER_CLICKED = "clusterClicked";

export const CREATE_ENTITY = "createEntity";
export const CREATE_BISECTOR = "createBisector";
// export const UPDATE_ENTITY = "updateEntity";
export const UPDATE_GROUP = "updateGroup";
export const SAVE_ROUTE = "saveRoute";
export const UPDATE_BISECTOR = "updateBisector";
export const SAVE_BISECTOR = "saveBisector";
export const GET_ID = "getId";
export const ADD_TRAVERSAL_TO_CANVAS = "addTraversalToCanvas";
export const REPLACE_CANVAS_WITH_TRAVERSAL = "replaceCanvasWithTraversal";
export const CANCEL_ADD_FORM = "cancelAddForm";
export const OPEN_EDIT_FORM = "openEditForm";
export const OPEN_CREATE_FORM = "openCreateForm";
export const HOVERED_TARGET = "hoveredTarget";
export const FOOTER_NODE_HOVERED = "footerNodeHovered";

export const TOGGLE_PANEL = "togglePanel";
export const HIDE_PANEL = "hidePanel";
export const SHOW_PANEL = "showPanel";
export const TOGGLE_ALL = "toggleAll";
export const SET_MAIN_VIEW = "setMainView";
export const SELECT_INSTANCE = "selectInstance";
export const SAVE_INSTANCE = "saveInstance";
export const OPEN_LIBRARY = "openLibrary";
export const FILTER_BY_TYPE = "filterByType";
export const SINGLE_FILTER = "singleFilter";
export const FILTER_RULES = "filterRules";
export const FILTERS_UPDATED = "filtersUpdated";
export const CLEAR_ALL_FILTERS = "clearAllFilters";
export const CLEAR_FILTERS = "clearFilters";
export const GO_BACK = "goBack";
export const TOGGLE_BOUNDS = "toggleBounds";
export const TOGGLE_FILTERS = "toggleFilters";
export const TOGGLE_POSITION_MODE = "togglePositionMode";
export const SWITCH_POSITION_MODE = "switchPositionMode";
export const SWITCH_TREE_TYPE = "switchTreeType";
export const SWITCH_TREE_DEPTH = "switchTreeDepth";
export const SHOW_LOCATION_MAP = "showLocationMap";
export const SET_LOCATION_MAP = "setLocationMap";
export const CLOSE_OVERLAY = "closeOverlay";
export const EXPAND_NODE = "expandNode";
export const MARKER_LOCATION_EDITED = "markerLocationEdited";
export const ADDRESS_EDITED = "addressEdited";
export const DRAW_GEOFENCE_ON_MAP = "drawGeofenceOnMap";
export const CANCEL_GEOFENCE_DRAW = "cancelGeofenceDraw";
export const GEOFENCE_EDITED = "geofenceEdited";
export const MAP_READY = "mapReady";
export const TOGGLE_EVENT_STREAM = "toggleEventStream";
export const TOGGLE_AUTO_FOCUS_EVENTS = "toggleAutoFocusEvents";

export const TOGGLE_FILTER_TYPE = "toggleFilterType";
export const ENABLE_FILTER_TYPE = "enableFilterType";
export const FILTER_STATE_LIST = "filterStateList";
export const FILTER_SEARCH_LIST = "filterSearchList";
// Digital Twin Actions
export const SET_COMBO_GROUP = "setComboGroup";
export const TOGGLE_GROUP_BY_CATEGORY = "toggleGroupByCategory";
export const SET_TREE_OPTION = "setTreeOption";
export const RUN_ANALYSIS = "runAnalysis";

// Deprecate?
export const TOGGLE_ATTRIBUTE_META = "toggleAttributeMeta";
export const CHECKBOX_UPDATED = "checkboxUpdated";
export const RADIO_UPDATED = "radioUpdated";
export const EDIT_ATTRIBUTE = "editAttribute";
export const ATTRIBUTE_DELETED = "attributeDeleted";
export const ATTRIBUTE_DROPPED = "attributeDropped";
export const ATTRIBUTE_ADDED = "attributeAdded";
export const CREATE_ATTRIBUTE = "createAttribute";
export const GO_TO_LIBRARY = "goToLibrary";
export const INIT_ATTRIBUTE_FORM = "initAttributeForm";
export const CREATE_DEVICE = "createDevice";
export const START_DEVICE_CREATION = "startDeviceCreation";