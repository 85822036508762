<div class="cl-activity mt10">
  <div class="row justify-content-start">
    <div class="col-6 text-start">
      <span class="pl-4 pr-4 pt-1 pb-1 cursor-pointer" style="margin-right:1px" (click)="navigate(i, tab)" *ngFor="let tab of tabs; index as i;"
      [ngClass]="{'bgcolor4 color2': activeTabIndex === i , 'bgcolor9 color4': activeTabIndex !== i, 'rounded-start': i === 0, 'rounded-end': tabs.length-1 === i}"
      [textContent]="tab.name"></span>
    </div>
    <div class="col-2 text-end">
      <span class="color4 invisible"><span class="fa fa-download"></span><span class="ml5 rt1 mr20">export</span></span>
    </div>
    <div class="col-4 text-end " *ngIf="showDatePicker">
      <div class="width-contol">
      <cl-date-picker [dateRange]="selectedDateRange" [datePickerClass]="datePickerClass" [ranges]="ranges" (dateChanged)="datesUpdated($event)"></cl-date-picker>
    </div>
  </div>
  </div>
  <div class="">
    <div *ngIf="activeTab?.id === 1" class="pt20 mt20">
      <cl-characteristic-list [measures]="assetObject?.measures" [data]="data"
      [tooltip]="tooltipCustomizer" (change)="measureChanged($event)" [annotations]="annotations"></cl-characteristic-list>
    </div>
    <div *ngIf="activeTab?.id === 2" >
      <cl-asset-history [asset]="assetObject" [dateRange]="selectedDateRange" (hideDatePicker)="hideDatePicker($event)"></cl-asset-history>
    </div>
    <div *ngIf="activeTab?.id === 3" >
      Sub Assets
    </div>
  </div>
</div>
