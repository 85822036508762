<mat-tab-group #siteTab *ngIf="mode == 'add'" (click)="onTabClick(siteTab)" (selectedTabChange)="onSelectedTabChange($event)" class="sticky-header pl-1.5">
    <mat-tab label="Single"></mat-tab>
    <mat-tab label="Bulk Add via {{bulkObj.fileType}}"></mat-tab>
</mat-tab-group>
<div *ngIf="tabSelected == 0">
<form [formGroup]="siteForm" (ngSubmit)="submitForm()">
   <div class="mx-2 -mt-2 mb-2" *ngIf="dynamicInputFields.length > 0">
        <cl-dynamic-form-renderer [columnMode]="'One'" [inputFields]="dynamicInputFields" #dynamicForm></cl-dynamic-form-renderer>
    </div>
    <ng-template #selectShapeTool class="mx-2 mb-4">
      <label for="" class="form-label ">
        Select a shape tool<span>*</span>
      </label>
      <mat-radio-group id="areaFenceType"
      aria-labelledby="location-radio-group-label"
      class="location-radio-group"
      formControlName="areaFenceType" (change)="getSelectedMarker($event)">
      <mat-radio-button class="location-radio-button capitalize" *ngFor="let marker of markers" [value]="marker">
        <img *ngIf="marker == 'point'" src="/assets/svgs/cl-icons-blue/gps.svg" class="inline color4" width="25">
        <img *ngIf="marker == 'polygon'" src="/assets/svgs/polygon-geofence.svg" class="inline color4" width="25">
        <img *ngIf="marker == 'circle'" src="/assets/svgs/circle-geofence.svg" class="inline color4" width="25"> 
        <span *ngIf="marker != 'circle'" >{{marker}}</span>
        <span *ngIf="marker == 'circle'" >Radius</span>
        <i *ngIf="marker == 'polygon'" class="fa fa-refresh ml-2" clearPolygons()></i>
      </mat-radio-button>
      </mat-radio-group>
      <div *ngIf="submitted && siteForm.get('areaFenceType').errors?.['required']" class="text-danger">Select a shape tool is required.</div>
      <div *ngIf="submitted && polygonPts?.length==0 && siteForm.value.areaFenceType=='polygon'" class="text-danger">Please select polygon points.</div>  
    </ng-template>
      <ng-template #addressTemp  >
        <div class="mb-2 flex flex-col ">
          <label for="address" class="form-label ">
            Address<span>*</span>
          </label>
          <input id="address" class="basic-input" formControlName="address"
            placeholder="Enter a Location" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" >
            <div
            *ngIf="submitted && siteForm.get('address').errors?.['required']"
              class="text-danger"
            >
            Address is required.
            </div>
        </div>
        </ng-template>
      
      <ng-template  #areaFenceTypeTemp >
        <div *ngIf="siteForm.value.address && siteForm.value.areaFenceType == 'circle'" class="flex flex-col">
         <label for="radius" class="form-label ">Radius (mi)</label>
        <input id="radius" class="basic-input" formControlName="radius" />
        </div>
      </ng-template>
      <ng-template #position>
        <div class="mb-2  flex flex-col">
        <label for="position" class="form-label ">Position (lat,lon)<span>*</span></label>
       <input id="position" class="basic-input" formControlName="position" >
       <div *ngIf="submitted && siteForm.get('position').errors?.['required']" class="text-danger"> Position is required</div>
       </div>
        </ng-template>
        <ng-template #cityTemp >
          <div class="mb-2  flex flex-col">
            <label for="city" class="form-label ">
              City<span>*</span>
          </label>
          <input id="city" class="basic-input" formControlName="city" >
          <div *ngIf="submitted && siteForm.get('city').errors?.['required']" class="text-danger"> City is required</div>
          </div>
      </ng-template>
      <ng-template #stateTemp >
        <div class="mb-2  flex flex-col">
          <label for="state" class="form-label ">
            State<span>*</span>  
          </label>
          <input id="state" class="basic-input" formControlName="state" >
          <div *ngIf="submitted && siteForm.get('state').errors?.['required']" class="text-danger"> State is required</div>
          </div>
      </ng-template>
      <ng-template #zipTemp >
        <div class="mb-2  flex flex-col">
          <label for="postalcode" class="form-label ">
              Postal Code<span>*</span>
          </label>
          <input id="postalcode" class="basic-input" formControlName="postalcode" >
          <div *ngIf="submitted && siteForm.get('postalcode').errors?.['required']" class="text-danger"> Postal Code is required</div>
          </div>
      </ng-template>
      <ng-template #countryTemp >
        <div class="mb-2  flex flex-col">
          <label for="country" class="form-label ">
              Country
          </label>
          <input id="country" class="basic-input" formControlName="country">
          <div *ngIf="submitted && siteForm.get('country').errors?.['required']" class="text-danger"> Country is required</div>
          </div>
      </ng-template>
      <ng-template #orgTemp>
        <div class="input">
              <label class="form-label">Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog></label>
              <div class="relative">
                <mat-select
                  class="basic-input org"
                  placeholder="Select organization"
                  formControlName="organization"
                >
                  <ng-container>
                    <mat-option *ngFor="let org of orgList"
                    [value]="org.id">{{org.name}}</mat-option>
                  </ng-container>
                </mat-select>
                <mat-icon  *ngIf="siteForm.value.organization && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
              </div>
        </div>
        </ng-template>
</form>
</div>
<div *ngIf="tabSelected == 1">
  <cl-bulk-action [panelObj]="bulkObj" [showResponse]="showResponse" [bulkResponseArr]="bulkResponseArr" (emitFormData)="getUploadFormData($event)" [fields]="downloadedCSVFields" [downloadedCSVFileName]="'site_add_template'"></cl-bulk-action>
</div>