import { ErrorHandler, Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ToastService } from '../../common/services/toast.service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class HttpStatusInterceptor implements HttpInterceptor {
  private token: any;
  constructor(private error: ErrorHandler, private toastService:ToastService ) {
  }
  handleError = (error: HttpErrorResponse)=>{
    if(error.name === 'HttpErrorResponse'){
      if(error.status === 401){
        if(error.error?.hasOwnProperty('error-message')){
          this.toastService.error(error.error['error-message']);
        }else{
          this.toastService.error('Something went wrong!');
        }
      } else if(error.status === 403){
        if(error.error?.hasOwnProperty('error-message')){
          this.toastService.error(error.error['error-message']);
        }else{
          this.toastService.error('Something went wrong!');
        }
      } else if(error.status === 500){
        if(error.error?.hasOwnProperty('error-message')){
          this.toastService.warning(error.error['error-message']);
        }else{
          this.toastService.warning('Something went wrong!');
        }
      }else{
        if(error.error?.hasOwnProperty('error-message')){
          this.toastService.error(error.error['error-message']);
        }else{
          this.toastService.error('Something went wrong!');
        }
      }
    }
    return throwError(error);
   }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(this.handleError)
    );
  }
}
