import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/constants';
import { Range } from "@cl/models";
import _ from "lodash";
import { catchError, combineLatest, debounceTime, distinctUntilChanged, EMPTY, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'cl-currency-range',
  templateUrl: './currency-range.component.html',
  styleUrls: ['./currency-range.component.scss']
})
export class CurrencyRangeComponent implements OnInit, OnDestroy {
  readonly intMin = 0;
  readonly intMax = Number.MAX_VALUE;

  @Output() rangeChanged = new EventEmitter<Range>();

  from = new FormControl(null, Validators.min(this.intMin));
  to = new FormControl();

  private readonly destroyed$ = new Subject<boolean>();

  private get hasErrors(): boolean {
    return !!(this.from.errors || this.to.errors);
  }

  constructor() {}

  ngOnInit(): void {
    combineLatest([this.from.valueChanges.pipe(distinctUntilChanged()), this.to.valueChanges.pipe(distinctUntilChanged())])
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH),
        tap((_) => this.updateValidators()),
        tap((_) => {
          if (this.hasErrors) {
            return;
          }

          const from = this.from.value ?? "";
          const to = this.to.value ?? "";

          if ((typeof from === "number" && typeof to === "number") || (from === "" && to === "")) {
            const updatedRange: Range = { from, to };
            this.rangeChanged.emit(updatedRange);
          }
        }),
        catchError((_) => EMPTY)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  resetFrom(): void {
    this.from.reset();
  }

  resetTo(): void {
    this.to.reset();
  }

  private updateValidators(): void {
    if (_.isNumber(this.from.value)) {
      this.to.clearValidators();
      this.to.setValidators(Validators.min(this.from.value + 1));
    }

    if (_.isNumber(this.to.value)) {
      this.from.clearValidators();
      this.from.setValidators([Validators.min(this.intMin), Validators.max(this.to.value - 1)]);
    }

    this.from.updateValueAndValidity();
    this.to.updateValueAndValidity();
  }
}