import { Pipe, PipeTransform } from '@angular/core';
import { PropertyService } from '@cl/property/property.service';
import * as _ from 'lodash';
@Pipe({
  name: 'temaoerature',
})
export class TemperaturePipe implements PipeTransform {
  constructor(private propertyService: PropertyService) {}

  transform(value: any, ...args: any): any {
    var currentUnit = this.propertyService.getSetting('ui.temp.unit');
    if (currentUnit !== args[0]) {
      if (currentUnit == 'F' && args[0] == 'C') {
        value = (value * 9) / 5 + 32;
      } else {
        value = ((value - 32) * 5) / 9;
      }
    }
    return Math.round(value);
  }
}
