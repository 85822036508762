import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { ES_USER_ID_PREFIX } from "@cl/constants";
import { EnvironmentService } from "@cl/core";
import {
  BulkActionResponse,
  BulkUserOperationPayload,
  GraphUser,
  GraphUserList,
  PartialOf,
  ResetPasswordPayload,
  UserCategory,
  UserSearchApiResponse,
} from "@cl/models";
import { TrimStrPipe } from "../../common/pipes/trim-str.pipe";
import FileSaver from "file-saver";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { SearchResponse } from "../search-response";
import { ConfigService } from "./config.service";

@Injectable({
  providedIn: "root",
})
export class UserApiService {
  token;
  loginObj;
  cloudosURL;

  constructor(private env: EnvironmentService, private http: HttpClient, private _configService: ConfigService,
    private apiProvider: ApiProviderService) {}

  private get dateSuffix(): string {
    return new Date().toLocaleString().replace(/[\/\s,:]/g, "");
  }

  private get config() {
    return this.env.clfGraphApp;
  }

  private get configGraphUI() {
    return this.env.clfGraphUI;
  }

  private get configGraphUIApi() {
    return this.env.clfGraphUI;
  }

  private get configCloud() {
    return this.env.cloudOs;
  }

  private get searchConfig() {
    const config = this.env.clfGraphApp;
    config.url = config.baseUrl + "/api/search/";
    return config;
  }

  private get headers() {
    return new HttpHeaders({
      //token: this.config.token || this._configService.getGraphAPIToken(),
      "Content-Type": "application/json",
      accept: "*/*",
    });
  }

  logout() {
    const url = this.configGraphUI.baseUrl + "/logout";

    return this.http.get(url).pipe(
      tap((res) => {
        // remove user from local storage to log user out
        localStorage.removeItem("currentUser");
      })
    );
  }

  login(user, password, app): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let creds = {
      login: user,
      secret: password,
    };

    const params = {
      type: "webui",
    };

    this.loginObj = this._configService.getURLVars(app);
    const url = this.loginObj.url;
    return this.http.post<any>(url, creds, { headers, params }).pipe(
      map((response: any) => {
        // login successful if there's a jwt token in the response
        if (response["token"]) {
          const eToken = window.btoa(response["token"]);
          const resObj = Object.assign({}, response, { eToken: eToken });
          // console.log('resObj', resObj);
          delete resObj.token;
          let userObj = Object.assign({}, { username: user, initials: user.substring(0, 2) }, resObj);
          // store user details and encrypted token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(userObj));
          localStorage.setItem("creds", JSON.stringify(creds));
          setTimeout(() => {
            localStorage.removeItem("creds");
          }, 60 * 1000);
        }
      })
    );
  }

  /**
   * Sets a new password for a user. On successful password resets
   * navigates user to the login page.
   */
  setNewPassword(payload: ResetPasswordPayload): Observable<any> {
    const url = this.config.baseUrl + "/api/1/user/forgotPasswordChange"; // this.config.url + 'user/resetPassword';

    return this.http.post(url, payload, { headers: this.headers });
  }

  verifyEmail(token: string): Observable<any> {
    const url = this.config.baseUrl + "/api/1/user/subscription/confirm/" + token;

    return this.http.get(url, { headers: this.headers });
  }

  getUsers(userType: string = "FIELD_TECHNICIAN"): Observable<GraphUserList> {
    const url = this.config.url + `users/${userType}`;

    return this.http.get<GraphUserList>(url, { headers: this.headers });
  }

  /**
   * Fetches list of users from ES
   *
   * @param text Search keyword
   * @param type Filter user types, if empty will give all users regardless of their roles
   * @param scrollId Offset
   * @param scrollSize Limit records to
   */
  searchUsersInEs(
    text: string = "",
    type: UserCategory[] = [],
    scrollId: string = "",
    scrollSize: number = 50,
    outFields: string = "properties",
    queryParams: PartialOf<string> = {}
  ): Observable<UserSearchApiResponse> {
    // const url = this.configGraphUIApi.url + "users";
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/users`;
    const params = {
      text,
      type: type.join(","),
      scrollId,
      scrollSize: scrollSize.toString(),
      outFields, // If not specified does not gives properties field
      ...queryParams,
    };
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.get<UserSearchApiResponse>(url, { params, headers: headers }).pipe(
      map((res) => {
        // Remove 'user_' prefix from the user id
        res.users.hits.forEach((u) => (u.id = TrimStrPipe.transform(u.id, ES_USER_ID_PREFIX)));
        return res;
      })
    );
  }

  downloadUsers(
    text: string = "",
    type: UserCategory[] = [],
    scrollId: string = "",
    scrollSize: number = 50,
    outFields: string = "properties",
    queryParams: PartialOf<string> = {},
    appendDateSuffix = true
  ) {
    const url = this.configGraphUIApi.url + "users";

    const params = {
      text,
      type: type.join(","),
      scrollId,
      scrollSize: scrollSize.toString(),
      outFields, // If not specified does not gives properties field
      ...queryParams,
      download: "true",
    };

    const fileName = "users-list";

    return this.http.get(url, { params, headers: this.headers, responseType: "blob" }).pipe(
      tap((res) => {
        const fileExtension = res.type.substring(res.type.lastIndexOf("/") + 1);
        const dateSuffix = appendDateSuffix ? this.dateSuffix : "";
        const fullFileName = `${fileName}-${dateSuffix}.${fileExtension}`;
        FileSaver.saveAs(res, fullFileName);
      })
    );
  }

  getUser(userId = "") {
    let headers = new HttpHeaders({
      //token: this.config.token,
      "Content-Type": "application/json",
    });
    const url = this.configCloud.url + `/1/user/${userId}`;

    return this.http.get<any>(url, { headers });
  }

  getUserProperties(): Observable<any> {
    const url = this.configCloud.url + "/1/user/properties";
    const params = { inclTenant: "true" };

    return this.http.get<any>(url, { headers: this.headers, params });
  }

  updateUser(user: GraphUser) {
    const url = ` ${this.configGraphUIApi.url}tool/user/${user.id}`;

    return this.http.put<any>(url, user, { headers: this.headers });
  }

  addUser(user) {
    const url = this.configGraphUIApi.url + "tool/user";

    return this.http.post<any>(url, user, { headers: this.headers });
  }

  deleteUser(userId: string) {
    const url = this.config.url + "tool/user/" + userId;

    return this.http.delete(url, { headers: this.headers });
  }

  deleteUsers(payload: BulkUserOperationPayload) {
    const url = this.configGraphUIApi.url + "tool/user/delete";

    return this.http.post<BulkActionResponse>(url, payload, {
      headers: this.headers,
    });
  }

  resetPassword(id: string) {
    const body = { id };
    const url = this.configGraphUIApi.url + "user/resetAccount";
    return this.http.post<any>(url, body, { headers: this.headers });
  }

  getTimezones = function () {
    return this.http.get("assets/data/timezone.json");
  };
  getCountrywithISOCode = function () {
    return this.http.get("assets/data/countrywithISOCode.json");
  };

  getUserAssets(userId) {
    const params = {
      scrollId: "",
      globalQueryText: "",
      searchQueries: [
        {
          fieldName: "baseClass",
          queryText: "Asset",
          queryOperator: "should",
          match: "must",
          childFilters: [
            {
              fieldName: "toParty",
              queryText: userId,
              queryOperator: "must",
            },
          ],
        },
      ],
    };
    const url = this.searchConfig.url + "navigator/query";
    this.config.token = this.config.token || this._configService.getGraphAPIToken();
    let headers = new HttpHeaders({
      //token: this.config.token || this._configService.getGraphAPIToken(),
      "Content-Type": "application/json",
    });
    return this.http.post<SearchResponse>(url, params, { headers: headers }).pipe(
      map((res) => {
        console.log("res", res);
        return new SearchResponse().deserialize(res);
      })
    );
  }
}
