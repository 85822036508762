import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from "@cl/env";

@Injectable({
  providedIn: 'root'
})
export class UrlUtilsService {
  baseUrl: any ={};
  APP_BASE_URL = {
    MODEL_APP: 'model',
    SIMULATE_APP: 'simulate'
  }
  constructor(private http: HttpClient) { }

  getBaseURL() {
    if(Object.keys(this.baseUrl).length) {
      return this.baseUrl;
    } else {
      var winLoc = window.location;
      var hostname = winLoc.hostname;
      var protocol = winLoc.protocol;
      var port = (winLoc.port == '') ? '' : ":" + winLoc.port;
      var domainDetails;
      var prima;
      if(hostname === 'localhost') {
        hostname = environment.localCluster;
        port = '';
        protocol = 'https:';
        domainDetails = this.getDomainSubdomain(hostname);
        prima = domainDetails.subdomain.split('-graph')[0];
      } else {
        domainDetails = this.getDomainSubdomain(hostname);
        prima = domainDetails.subdomain.split('-web')[0];
      }
      let baseUrl = {'prima': prima, 'protocol': protocol, 'port': port, domain: domainDetails.domain};
      this.baseUrl = baseUrl;
      return baseUrl;
    }
  }

  getBaseHost() {
    let winLoc = window.location;
    let hostname = winLoc.hostname;
    let subdomain;
    let prima;
    let port;
    var finalhostname = '';
    if(hostname !== 'localhost') {
      let domainDetails = this.getDomainSubdomain(hostname);
      prima = domainDetails.subdomain.split('-web')[0];
      finalhostname = prima+'-graph'+domainDetails.domain;
      port = (winLoc.port == '') ? '' : ":" + winLoc.port;
      finalhostname = winLoc.protocol + "//" + finalhostname + port;
    } else {
      finalhostname = environment.localCluster;
      subdomain = finalhostname.split('.parkoursc.com')[0];
      prima = subdomain.split('-graph')[0];
      finalhostname = 'https://'+finalhostname;
    }
    return {'hostName': finalhostname, 'prima': prima};
  }

  getBaseURLDomain() {
    let hostname = window.location.hostname;
    let domain = environment.domain;
    let subdomain;
    if(hostname !== 'localhost') {
      const domainSubDomain = this.parseDomainSubDomain();
      subdomain = domainSubDomain.subdomain;
      domain = domainSubDomain.domain;
      // if(hostname.includes('parkoursc')){
      //   subdomain = hostname.split('.parkoursc.com')[0];
      //   domain = subdomain.split('-')[0];
      // }else if(hostname.includes('qopper')){
      //   subdomain = 'test2-web';
      //   domain = subdomain.split('-')[0];
      // }
    }
    return domain;
  }

  getDomainSubdomain(hostname) {
    //let host = hostname ? hostname : window.location.hostname;
    //host = host.indexOf("rtt11.qopper.com")>-1?"test2.parkoursc.com":host;
    const domainSubdomain = this.parseDomainSubDomain();
    return {domain: domainSubdomain.domain, subdomain:  domainSubdomain.subdomain} ;
  }

  getCloudOSBaseURL() {
    var cloudHostName = `${this.baseUrl.prima}${this.baseUrl.domain}` ;
    cloudHostName = this.baseUrl.protocol + "//" + cloudHostName + this.baseUrl.port;
    return cloudHostName + "/cloudos/";
  }

  getGraphAppBaseURL() {
    var graphHostName = `${this.baseUrl.prima}-graph${this.baseUrl.domain}`;
    graphHostName = this.baseUrl.protocol + "//" + graphHostName + this.baseUrl.port;
    return graphHostName + "/clfgraphapp/";
  }

  getGraphUIBaseURL() {
    var graphHostName = `${this.baseUrl.prima}-app${this.baseUrl.domain}`;
    graphHostName = this.baseUrl.protocol + "//" + graphHostName + this.baseUrl.port;
    return graphHostName + "/graph-ui/";
  }

  getBatchBaseURL() {
    var graphHostName = `${this.baseUrl.prima}-reports${this.baseUrl.domain}`;
    graphHostName = this.baseUrl.protocol + "//" + graphHostName + this.baseUrl.port;
    return graphHostName + "/clfbatch/";
  }

  getExternalAppBaseURL(APPBASEURL) {
    let hostname = window.location.hostname;
    let appHostName = '';
    let port = (window.location.port == '') ? '' : ":" + window.location.port;
    let protocol = window.location.protocol;
    let subdomain;
    let prima;
    let suffix = this.APP_BASE_URL[APPBASEURL];
    const domainSubdomain = this.parseDomainSubDomain();
    if(hostname === 'localhost') {
      hostname = environment.localCluster;
      port = '';
      protocol = 'https:';
      subdomain = hostname.split('.parkoursc.com')[0];
      prima = subdomain.split('-app')[0];
    } else {      
      subdomain = domainSubdomain.subdomain;
      prima = subdomain.split('-web')[0];
      // if(hostname.includes('parkoursc')){
      //   subdomain = hostname.split('.parkoursc.com')[0];
      //   prima = subdomain.split('-web')[0];
      // }else if(hostname.includes('qopper')){
      //   subdomain = 'test2-web';
      //   prima = subdomain.split('-web')[0];
      // }
    }
      // if(hostname.includes('parkoursc')){
      //   appHostName = prima+'-'+suffix+'.parkoursc.com';
      // } else if(hostname.includes('qopper')){
      //   appHostName = prima+'-'+suffix+'.parkoursc.com';
      // }
    appHostName = prima+'-'+suffix+domainSubdomain.domain;
    appHostName = protocol + "//" + appHostName + port;
    return appHostName;
  }

  public parseDomainSubDomain(){
    let result:any = {};
    let regexParse = new RegExp('([a-z\-0-9]{2,63})\.([a-z\.]{2,5})$');
    let urlParts = regexParse.exec(window.location.hostname);
    let domain = urlParts[1];
    result.type = urlParts[2];
    result.subdomain = window.location.hostname.replace(domain + '.' + result.type, '').slice(0, -1);
    result.domain = "."+urlParts[1]+"."+urlParts[2];
    return result;
  }

  public getAuth0Domain(){
    return this.http.get(
      'assets/data/auth-config.json',
      {
        headers: new HttpHeaders({
          'Skip-Auth': 'true',
        })
      })
    }
}
