<div class="mb-4 -mt-1"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>
<div class="details-page-wrapper location-details-wrapper">
  <div class="flex justify-between mt-2 mb-2">
    <div><span class="page-title">{{summaryObj?.properties?.name}}</span></div>
    <div>
      <cl-recipe-actions [entityTypeId]="data?.id" [entityId]="summaryObj?.properties.id" [parentEntityType]="data?.catalogType" ></cl-recipe-actions>
    </div>
  </div>
  <div class="details-header-wrapper">
    <div class="flex items-stretch flex-wrap">
      <div class="details-flex-card bgcolor2 ml0 mr10">
        <div class="cl-location-summary overflow-y-auto overflow-x-hidden max-h-[300px]">
            <ng-container *ngFor="let cdmField of inventoryCatalogCDMFields">
                <div class="flex mt5" *ngIf="cdmField?.displayable">
                  <div class="basis-1/5 font-bold" [textContent]="cdmField?.displayLabel"></div>
                  <div class="grow w-4/5" [textContent]="getSkuValue(cdmField, summaryObj?.properties)"></div>
                </div>
              </ng-container>
              <div class="flex mt5" *ngIf="data">
                <div class="basis-1/5 font-bold">Created By</div>
                <div class="grow w-4/5">{{summaryObj?.properties?.createdBy}}</div>
              </div>
              <div class="flex mt5" *ngIf="data">
                <div class="basis-1/5 font-bold">Created At</div>
                <div class="grow w-4/5">{{summaryObj?.properties?.createdAt | formateDate: "default" }}</div>
              </div>
              <div class="flex mt5" *ngIf="data">
                <div class="basis-1/5 font-bold">Modified By</div>
                <div class="grow w-4/5">{{summaryObj?.properties?.modifiedBy}}</div>
              </div>
              <div class="flex mt5" *ngIf="data">
                <div class="basis-1/5 font-bold">Modified At</div>
                <div class="grow w-4/5">{{summaryObj?.properties?.modifiedAt | formateDate: "default" }}</div>
              </div>
        </div>
      </div>
      <div class="details-flex-card bgcolor2 mr0 ml10">
       
      </div>
    </div>
  </div>


  <ng-template #locationAssetTemplate let-row="row" let-value="value" let-i="index">
    <a class="font-12-bold" href="#/assetdetails/{{row.id}}">{{value}}</a>
  </ng-template>
</div>
