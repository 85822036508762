import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Asset } from '@cl/@types/asset.type';
import { MeasureType } from '@cl/@types/measure.type';
import { Sensor, SensorTaggedAssetAssignment } from '@cl/@types/sensor.type';
import { AssetUtilService } from '@cl/asset/asset-util.service';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { GraphAPIService } from '@cl/common/services/graph-api.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import * as _ from 'lodash';
import { SensorUtilService } from '../sensor-util.service';
import { SensorService } from '../sensor.service';
import { ToastService } from "@cl/common/services/toast.service";
import * as moment from 'moment';
import { AssetService } from '../../../asset/asset.service';
import { UserService } from '../../../../app/user/user.service';

@Component({
  selector: 'cl-sensor-details',
  templateUrl: './sensor-details.component.html',
  styleUrls: ['./sensor-details.component.scss']
})
export class SensorDetailsComponent implements OnInit {

  isLoading: boolean = true;
  sensorObject: Sensor;
  breadCrumbItems: BreadCrumbItem[];
  bindIcon = "fa-link";
  bindAction = "bind";
  bindDisabled: boolean=true;
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  sidePanelsObj: any = {
    bind: false,
    replace: false
  };
  actionBtnPolicies = {
    bind: ['TAGGEDASSET_CREATE'],
    unbind: ['TAGGEDASSET_DELETE'],
    replace: ['SENSORTAG_UPDATE']
  }
  mapOptions: any = {
    gestureHandling: 'cooperative',
    styles: [
      {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
        ]
      }
    ],
    positionOfActions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    }
  };
  isTracker:boolean;
  selectedDateRange: {startDate: moment.Moment, endDate: moment.Moment};
  ranges: any;
  showHistory: boolean = false;
  markers = [];
  lastReportedGPSTimeFormatted: any;
  device: string;
  fitBoundsFlag: boolean = false;
  deviceEntityTypeKey = '';
  lastBoundedAssetId = '';
  isOrgRestrictedUser:boolean;

  constructor(
    private sensorUtilService: SensorUtilService,
    private assetUtilService: AssetUtilService,
    private route: ActivatedRoute,
    private router: Router,
    private _utils: UtilsService,
    private dialog: MatDialog,
    private _graphAPIService: GraphAPIService,
    private _snackBar: MatSnackBar,
    private toaster: ToastService,
    private sensorService: SensorService,
    private assetService: AssetService,
    private userService: UserService
  ) {
    this.sensorObject = {id: null, assignments: []};
    this.getSensorId();
    this.ranges = {};
  }
  private getSensorId() {
    this.route.paramMap.subscribe((params) => {
      this.sensorObject.id = params.get('id');
          });

    this.route.queryParams.subscribe(params=> {
      this.deviceEntityTypeKey = params['type']
    })
  }
  ngOnInit(): void {
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
    this.getSensorObj();
    this.ranges = {
      'Last 4 Hours': [moment().subtract(4, 'hours'), moment()],
      'Last 8 Hours': [moment().subtract(8, 'hours'), moment()],
      'Last 16 Hours': [moment().subtract(16, 'hours'), moment()],
      'Last 24 Hours': [moment().subtract(24, 'hours'), moment()],
    };
  }

  checkDeviceType(device) {
    return device?.deviceType.toLowerCase().includes('tracker')
  }

  private formBreadCrumbItems() {
    this.breadCrumbItems = [];
    if(!this.isTracker){
      this.device = 'Sensor';
      this.breadCrumbItems.push({'name': 'Previous', isBack: true, path: null}, {'name': 'Sensor Details', path: null});
    } else {
      this.device = 'Tracker';
      this.breadCrumbItems.push({'name': 'Previous', isBack: true, path: null}, {'name': 'Tracker Details', path: null});
    }
  }

  getSensorObj() {
    this.sensorUtilService.getSensor(this.sensorObject.id, this.deviceEntityTypeKey)
      .then((sensor: Sensor) => {
        this.isTracker = this.checkDeviceType(sensor);
        this.formBreadCrumbItems();
        if (sensor?.assetId) {
          this.checkForShipment(sensor?.assetId);
          this.assetUtilService.getSensorDetailsAsset(sensor)
            .then((asset: Asset) => {
              sensor.asset = asset;
              this.updateSensorWithRSSIMeasure(sensor);
              this.clearEmptyCharacteristics(sensor);
              this.sensorObject = sensor;
              if(this.sensorObject?.organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
                this.bindDisabled = true;
              }else{
                this.bindDisabled = false;
                this.bindAction = "Unbind";
                this.bindIcon = "fa-unlink";
              }
              this.sensorObject.isTracker = this.isTracker;
              if(this.sensorObject.asset.lastReportedGPSTime ){
                this.lastReportedGPSTimeFormatted = moment(this.sensorObject.asset.lastReportedGPSTime ).format('MM/DD/yyyy hh:mm:ss a');
                }
              this.getDataForSensorAssignments();
              if(this.sensorObject?.type === 'ONA-Sentinel-100L'){
                this.watchListStatus();
              }
              this.isLoading = false;
            });
        } else {
          this.getDataForSensorAssignments();
          this.clearEmptyCharacteristics(sensor);
          this.sensorObject = sensor;
          if(this.sensorObject?.organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
            this.bindDisabled = true;
          }else{
            this.bindDisabled = false;
            this.bindAction = "Bind";
            this.bindIcon = "fa-link";
          }
          this.sensorObject.isTracker = this.isTracker;
          this.isLoading = false;
        }
      })

  }

  private checkForShipment(assetId) {
    this.assetService.getAdditionalAssetDetails(assetId).then((data: any) => {
      if(data.hits[0]?.shipmentList?.length) {
        this.showHistory = true;
      };
    })
  }

  private watchListStatus() {
    this.sensorService.getWatchListStatus(this.sensorObject.id).subscribe((data:any)=>{
      this.sensorObject.watchListStatus = data?.status;
    })
  }

  private clearEmptyCharacteristics(sensor: Sensor){
    let list: MeasureType[] = _.filter(sensor?.asset?.measures, (item) => {
      return !_.isUndefined(item.value) && !_.isNull(item.value);
    });
    sensor.asset.measures = list;
  }
  updateSensorWithRSSIMeasure(sensor: Sensor) {
    if(!_.isEmpty(sensor?.asset?.measures)){
      let rssiMeasureIndex = _.findIndex(sensor.asset.measures, { measureServiceId: 'Location', measureCharacteristicId: 'rxRSSI' });
      if(rssiMeasureIndex > -1){
        let measure: MeasureType = sensor.asset.measures[rssiMeasureIndex];
        measure.uom = ' '+measure.uom;
        sensor.attributes.rssiUom = measure.uom;
        sensor.attributes.rssi = measure.value;
        sensor.attributes.rssiCapturedAt = measure.timeOfCapture;
        if(this.isTracker){
          sensor.asset.measures.splice(rssiMeasureIndex, 1);
        }
      }
    }
  }
  bindOrUnbind() {
    if (this.bindAction === "Unbind") {
      this.unbindSelected();
    } else {
      this.sidePanelsObj.bind = true;
    }
  }

  replaceSensor() {
    this.closeSidePanel();
    this.sidePanelsObj.replace = true;
  }

  closeSidePanel(currentAction?: string) {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    if (currentAction === 'bindSuccess') {
      this.getSensorObj();
    }
  }

  navigateToSensorList() {
    if(this.isTracker){
      this.router.navigateByUrl('/trackers');
    } else {
      this.router.navigateByUrl('/sensors');
    }
  }

  handlehistoryVisibility() {
    this.showHistory = !this.showHistory;
    if(!this.showHistory){
      this.markers = [];
    }
    if(!this.sensorObject?.asset?.position?.lat || !this.sensorObject?.asset?.position?.lon){
      this.fitBoundsFlag = false;
    }
  }

  private historyVisibility() {
    this.markers = [];
    let from = this.selectedDateRange.startDate.valueOf();
    let to = this.selectedDateRange.endDate.valueOf();
    const taggedAssetId = this.sensorObject?.asset?.taggedAssetId || this.lastBoundedAssetId;
    if(this.showHistory && taggedAssetId){
      this.sensorService.getGPSTracker(taggedAssetId, from, to).subscribe((data: any)=> {
        if(data) {
          const markersWithLatestTime = {};

          this.markers = data?.data;
          this.markers.forEach((marker: any) => {
            let point = marker.value.split(",");
            marker['lat'] = parseFloat(point[0]);
            marker['lng'] = parseFloat(point[1]);
            marker['time'] = moment(marker['time']).format('MM/DD/yyyy hh:mm:ss a');
            marker['latestTime'] = markersWithLatestTime[marker.value] || marker['time'];
            markersWithLatestTime[marker.value] = marker['latestTime']
          })
          this.fitBoundsFlag = true;
        } else {
          this.fitBoundsFlag = false;
        }
      })
    }
  }

  getIconUrl(index) {
    return (index===0) ? 'assets/svgs/destination.svg': 'assets/svgs/midpoint.svg';
  }

  unbindSelected() {
    const selectedSensor = this.sensorObject;
    let message = `Are you sure you want to unbind this ${this.device.toLowerCase()}?: ` + selectedSensor?.id;
    // }

    const dialogData = new ConfirmDialogModel("", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let currentIcon = this.bindIcon;
        this.bindIcon = 'fa-circle-o-notch fa-pulse';
        this.bindDisabled = true;
        this._graphAPIService.unBindAssetFromSensor(selectedSensor.id,selectedSensor.asset.taggedAssetId).subscribe(
          (res) => {
            let name = selectedSensor.id;
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
            this.toaster.success(`Unbind of ${this.device.toLowerCase()}: ${name} is successful`);
            this.delayedRefreshStart = true;
            /*
            if (res[name]) {
              this.openSnackBar("Unbind Sensor", name);
              this.getSensorObj();
            } else {
              this._utils.showMessage("Failed to unbind sensor");
            }*/
          },
          (err) => {
            console.log(err);
            this._utils.showMessage("Failed to unbind " + this.device.toLowerCase());
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
          }
        );

      }
    });
  }

  datesUpdated(datesUpdated){
    this.selectedDateRange = datesUpdated;
    this.historyVisibility();
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  private getDataForSensorAssignments(){
    this.sensorUtilService.getSensorAssignments(this.sensorObject.id)
    .then((result: SensorTaggedAssetAssignment[])=>{
      result = _.orderBy(result, 'updatedDate', 'desc');
      if(result?.length > 0){
        const lastBoundedAsset = result.find(item => item.status == 'Bound');
        if(lastBoundedAsset){
          this.selectedDateRange = { startDate: moment(lastBoundedAsset.updatedDate), endDate: moment()};
          this.lastBoundedAssetId = lastBoundedAsset.taggedAssetId;
        }
      }

      if(!this.selectedDateRange){
        this.selectedDateRange = { startDate: moment().subtract(4, 'hours'), endDate: moment()};
      }

      this.historyVisibility();
    });
  }
}
