import { Injectable } from '@angular/core';
import { SearchApiService } from './search-api.service';
import { ToastService } from './toast.service';
import { AssetsStateService } from '@cl/asset/asset-state.service';
import { UtilsService } from '../utils/utils.service';
import _ from 'lodash';
import { CsvExportsService } from './csv-exports.service';
import { ShipmentListStateService } from '@cl/shipments/shipment-list/shipment-list-state.service';

type CSVEntity = 'asset' | 'shipment'; // This entity should match to the global search entity
@Injectable({
  providedIn: 'root',
})
export class AsyncDownloadCsvService {
  private readonly MAX_DOWNLOAD_LIMIT = 1_00_000;

  csvData: {
    [key in CSVEntity]?: {
      payload: any;
      data: any[];
      columns: any[];
    };
  } = {};

  constructor(
    private _searchApiService: SearchApiService,
    private toastService: ToastService,
    private _utilsService: UtilsService,
    private csvExport: CsvExportsService,
    private assetStateService: AssetsStateService,
    private shipmentState: ShipmentListStateService
  ) {}

  initializeDownloading(
    entity: CSVEntity,
    payload: any = {},
    columns: any[] = [],
    currentTotalRecords: number = 0,
    isNew = false
  ) {
    if (isNew) {
      if (this.csvData[entity]?.payload) {
        this.toastService.error(
          'A data download is already in progress, the file is being generated. Please wait for that to complete before downloading data again',
          '',
          10000
        );
        return;
      }

      if (currentTotalRecords > this.MAX_DOWNLOAD_LIMIT) {
        this.toastService.error(
          'Cannot download more than 100,000 records. Please reduce data and try again.',
          '',
          10000
        );
        return;
      }

      this.csvData[entity] = { data: [], payload, columns };
      this.toastService.success(
        'Data file for download is being generated in the background. It will automatically download when ready.',
        '',
        10000
      );
    }

    this._searchApiService
      .globalEntitySearch(entity, this.csvData[entity].payload, false)
      .subscribe((res) => {
        // Edge case for failing es search api
        if (!res?.totalHits || !res?.hits?.length) {
          this.downloadCSV(entity);
          return;
        }

        this.formatBeforeSaving(entity, res.hits);

        if (
          res.totalHits == this.csvData[entity].data.length ||
          this.csvData[entity].data.length >= this.MAX_DOWNLOAD_LIMIT
        ) {
          this.downloadCSV(entity);
          return;
        }

        this.csvData[entity].payload.scrollId = res?._scroll_id;
        this.initializeDownloading(entity);
      });
  }

  formatBeforeSaving(entity: CSVEntity, hits) {
    let formattedData = [];
    if (entity == 'asset') {
      formattedData = this.assetStateService.formateDownloadData(hits);
    } else if (entity == 'shipment') {
      formattedData = this.shipmentState.getShipementDataForExport(
        this.csvData[entity].columns,
        hits
      );
    }
    this.csvData[entity].data.push(...formattedData);
  }

  downloadCSV(entity: CSVEntity) {
    // FIXME: Optimise this for all entites for now only shipments.
    // const csvData = this._utilsService.getSelectedElementsFromArray(
    //   this.csvData[entity].data,
    //   _.map(this.csvData[entity].columns, 'prop')
    // );
    // this.csvExport.formatAndDownloadCSVForGrid(
    //   csvData,
    //   `${entity}-list`,
    //   _.map(this.csvData[entity].columns, 'name')
    // );

    if (entity == 'shipment') {
      this.csvExport.formatAndDownloadCSVForGrid(
        this.csvData[entity].data,
        'ShipmentListOn-' + new Date().toISOString().slice(0, 10),
        this.csvData[entity].columns.map((c) => c.name).filter((e) => e)
      );
    }

    this.clearCSVData(entity);
  }

  clearCSVData(entity: CSVEntity) {
    this.csvData[entity] = { payload: null, data: [], columns: [] };
  }
}
