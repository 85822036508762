<mat-tab-group #siteTab *ngIf="mode == 'add'" (click)="onTabClick(siteTab)" (selectedTabChange)="onSelectedTabChange($event)" class="sticky-header pl-1.5">
    <mat-tab label="Single"></mat-tab>
    <mat-tab label="Bulk Add via {{bulkObj.fileType}}"></mat-tab>
</mat-tab-group>
<div *ngIf="tabSelected == 0">
<form [formGroup]="gatewayForm"  (ngSubmit)="submitForm()">
  <ng-template #orgTemp>
    <div class="mb-2 flex flex-col">
      <label for="organization" class="form-label">
        Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog>
      </label>
          <div class="relative">
          <mat-select
            class="basic-input"
            placeholder="Select organization"
            formControlName="organization"
          >
            <ng-container>
              <mat-option *ngFor="let org of orgList"
              [value]="org.id">{{org.name}}</mat-option>
            </ng-container>
          </mat-select>
          <mat-icon  *ngIf="gatewayForm.value.organization && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
        </div>
  </div>
  </ng-template>
    <ng-template #siteTemp >
      <div class="mb-2 flex flex-col" >
        <label for="site" class="form-label">Parent Location<span>*</span></label>
        <mat-select class="basic-input" id="parentlocation" disableOptionCentering 
            placeholder="Select parent location" (selectionChange)="getSelectedSite()" 
            formControlName="parentlocation"
          >
            <ng-container>
              <mat-option *ngFor="let site of sites" [value]="site">{{site.name}}</mat-option>
            </ng-container>
          </mat-select>
          <div *ngIf="submitted && gatewayForm.get('parentlocation').errors?.['required']" class="text-danger">Parent Location is required</div>
      </div>
        </ng-template>
    <ng-template #addressTemp >
      <div class="mb-2 flex flex-col" >
        <label for="address" class="form-label">
          Address<span>*</span>
      </label>
      <input id="address" class="basic-input" formControlName="address"
          placeholder="Enter a Location" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" >
          <div *ngIf="submitted && gatewayForm.get('address').errors?.['required']" class="text-danger"> Address is required</div>
      </div>
  </ng-template>
   
    <ng-template #positionTemp>
      <div class="mb-2 flex flex-col" >
        <label for="position" class="form-label">Position (lat,Ion)<span>*</span></label>
        <input id="position" class="basic-input" formControlName="position" >
        <div *ngIf="submitted && gatewayForm.get('position').errors?.['required']" class="text-danger"> Position is required</div>
      </div>
    </ng-template>
    <div class="mx-2 -mt-2 mb-2"  *ngIf="dynamicInputFields.length > 0">
        <cl-dynamic-form-renderer [columnMode]="'One'" [inputFields]="dynamicInputFields" #dynamicForm></cl-dynamic-form-renderer>
    </div>
</form>
</div>
<div *ngIf="tabSelected == 1">
  <cl-bulk-action [panelObj]="bulkObj" [showResponse]="showResponse" [bulkResponseArr]="bulkResponseArr" (emitFormData)="getUploadFormData($event)" [fields]="downloadedCSVFields" [downloadedCSVFileName]="'gatewayzone_add_template'"></cl-bulk-action>
</div>