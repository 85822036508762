import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  finalize,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import _ from 'lodash';
import { ASSET_ACTIONS, AssetState } from '@cl/@types/asset-state.type';
import { AssetListService } from './asset-list/asset-list.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { SearchApiService } from '@cl/common/services/search-api.service';
import { AdditionalAssetFilter } from '@cl/@types/asset.type';
import { FiltersUtilService } from '@cl/common/utils/filters-util.service';
import {
  AggregatedCountApiResponse,
  AssetCategory,
  ElasticSearchQuery,
  FilterGroupList,
  Range,
} from '@cl/common/models/index';
import { AssetStatus, CL_API_DELAY_SHORT } from '@cl/common/constants/index';
import { ToastService } from '@cl/common/services/toast.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';

@Injectable({
  providedIn: 'root',
})
export class AssetsStateService {
  private _state: AssetState = {
    searchFieldFilter: [],
    sortField: null,
    searchTerm: '',
    filterSet: [
      {
        name: 'Assets',
        class: 'asset',
        filterType: 'status',
        fieldName: 'clfMappingType',
        count: 0,
        enabled: true,
        filters: [],
        selectedFilters: {},
      },
    ],
    categories: [],
    initialFilterSet: [
      {
        name: 'Assets',
        class: 'asset',
        filterType: 'status',
        fieldName: 'clfMappingType',
        order: 4,
        enabled: true,
        count: 0,
        filters: [],
        selectedFilters: {},
      },
    ],
    searchFields: [
      {
        fieldName: 'baseClass',
        queryOperator: 'should',
        queryText: 'Asset',
        queryType: 'match',
        childFilters: [],
      },
    ],
    scrollId: '',
    currentAction: ASSET_ACTIONS.INITIALIZED,
    nodeToEdit: {
      name: '',
      assetId: '',
      category: '',
      svgName: 'asset',
      uid: '',
      id: '',
    },
    modificationInProgress: false,
    action: ASSET_ACTIONS.INITIALIZED
  };
  assetStateObservable = new BehaviorSubject<AssetState>(this.state);

  constructor(
    private _assetListService: AssetListService,
    private _utils: UtilsService,
    private _searchApiService: SearchApiService,
    private _filtersUtil: FiltersUtilService,
    private _toastService: ToastService,
    private formateDatePipe: FormateDatePipe,
  ) {}

  public get state() {
    return _.cloneDeep(this._state);
  }

  resetAction(){
    this._state.currentAction = ASSET_ACTIONS.INITIALIZED;
    this._state.searchTerm = '';
    this.assetStateObservable.next(this.state)
  }
  getAssets(isScrollLoad: boolean, selectedRows = []) {
    let params: any = {
      scrollId: isScrollLoad ? this._state.scrollId : '',
      match: 'must',
      scrollSize: 50,
      globalQueryText: this._state.searchTerm,
      searchQueries: this._state.searchFields,
      searchFieldFilter: this._state.searchFieldFilter
    };

    if (this._state.sortField) {
      params.sortField = this._state.sortField;
    }

    if (!isScrollLoad && selectedRows.length) {
      const selectedParams = _.cloneDeep(params);

      selectedRows.forEach(id => {  
        selectedParams.searchQueries[0].childFilters.push({
          fieldName: 'id',
          queryText: id,
          queryOperator: 'should',
        });
      })
     
      selectedParams.scrollSize = 10000;
      const unselectedAssetsSearch = this._searchApiService.globalEntitySearch(
        'asset',
        params,
        false
      );
      const selectedAssetSearch = this._searchApiService.globalEntitySearch(
        'asset',
        selectedParams,
        false
      );

      return combineLatest({
        selectedAssets: selectedAssetSearch,
        listingAssets: unselectedAssetsSearch,
      });
    }

    return this._searchApiService
      .globalEntitySearch('asset', params, false)
      .pipe(
        catchError((err) => {
          this._utils.showMessage('Failed to load assets');
          return throwError(err);
        })
      ).pipe(map((data) => {
        return {
          listingAssets: data,
          selectedAssets: null
        }
      }));
  }

  getAssetsByScan(scannedItems: any[]){
    let params = {
      scrollId: '',
      match: 'must',
      scrollSize: 10000,
      globalQueryText: '',
      sortField: [],
      searchQueries: [{
        fieldName: 'baseClass',
        queryText: 'Asset',
        queryOperator: 'should',
        queryType: 'match',
        childFilters: [],
      }],
    };

    const childFilters = [];
    scannedItems.forEach(item => {
      childFilters.push({
          fieldName: 'qrBarCode',
          queryText: item,
          queryOperator: 'should',
      })
    });

    params.searchQueries[0].childFilters = childFilters;

    return this._searchApiService
      .globalEntitySearch('asset', params, false)
      .pipe(
        catchError((err) => {
          this._utils.showMessage('Failed to load scanned assets');
          return throwError(err);
        })
      );
  }

  updateScrollId(scrollId: string) {
    this._state.scrollId = scrollId;
    this._state.currentAction = ASSET_ACTIONS.SCROLLID_UPDATED;
    this.assetStateObservable.next(this.state);
  }

  onSort(sort){
    this._state.sortField = sort;
    this._state.currentAction = ASSET_ACTIONS.FILTERS_UPDATED;
    this.assetStateObservable.next(this.state)
  }

  loadFilters() {
    let additionalAssetFilters: AdditionalAssetFilter[] =
      this._assetListService.getAdditionalAssetFilters() || [];
    let additionalString: string = ',';
    let aggField: string = 'entityType,monitoredStatus,locationName';
    if (additionalAssetFilters && additionalAssetFilters.length) {
      additionalAssetFilters.forEach((item: AdditionalAssetFilter) => {
        additionalString = additionalString + item.fieldName + ',';
      });
      aggField = aggField + additionalString.slice(0, -1);
    }

    const filterCount$ = this._searchApiService
      .getCounts({
        aggField: aggField,
        baseClass: 'asset',
      })
      .pipe(map((res) => res as AggregatedCountApiResponse[]));

    const rawCategories$ = this._assetListService.getCategories('asset').pipe(
      catchError((err) => {
        return of([] as AssetCategory[]);
      })
    );

    return combineLatest([rawCategories$, filterCount$])
      .pipe(
        tap(([categories, counts]) => {
          if (categories && categories.length) {
            const mappedCatIdNames = categories?.reduce(
              (cat: any, curr: any) => ({
                ...cat,
                [curr.properties.name]: curr.properties.name,
              }),
              {}
            );
            for (let key in mappedCatIdNames) {
              let index = _.findIndex(
                counts[0]['hits'][0].buckets,
                (bucket) => {
                  return bucket.key === key;
                }
              );
              if (index > -1) {
                counts[0]['hits'][0].buckets[index].displayLabel =
                  mappedCatIdNames[key];
              } else {
                counts[0]['hits'][0].buckets.push({
                  key,
                  subBucket: [],
                  displayLabel: mappedCatIdNames[key],
                  value: 0,
                });
              }
            }
          }
          additionalAssetFilters.forEach((item: AdditionalAssetFilter) => {
            let index: number = counts.findIndex(
              (hitItem: any) => hitItem.hits[0].name === item.fieldName
            );
            if (index > -1) {
              counts[index].hits[0].displayLabel = item.label;
              if (item.filterType === 'range' && item.datatype === 'double') {
                counts[index].hits[0].filterType = 'currencyrange';
              } else if (
                item.filterType === 'range' &&
                item.datatype === 'date'
              ) {
                counts[index].hits[0].filterType = 'daterange';
              } else {
                counts[index].hits[0].filterType = 'multiselect';
              }
            }
          });

          let state = this.state;

          const previousFilter: any = state.filterSet;

          let freshFilterSet =
            this._searchApiService.parseAggregateCountAsFilter(
              counts,
              'asset',
              ['state']
            );

          const filterSet: any = this._searchApiService.copyFilterStatus(
            previousFilter,
            freshFilterSet
          );
          let res;

          if (filterSet && filterSet.length) {
            res = this._filtersUtil.getSearchFields([filterSet]);
          } else {
            res = this._filtersUtil.getSearchFields([state.initialFilterSet]);
          }

          if (res.searchFields && res.searchFields.length) {
            this._state.searchFields = [{ ...res.searchFields[0] }];
            this.assetStateObservable.next({ ...this.state });
          }

          if (filterSet && filterSet.length) {
            this._state = {
              ...this._state,
              initialFilterSet: _.cloneDeep(filterSet),
              filterSet: _.cloneDeep(filterSet),
              currentAction: ASSET_ACTIONS.INIT_FILTERS,
            };
            this.assetStateObservable.next({ ...this.state });
          }

          delay(CL_API_DELAY_SHORT), switchMap((res) => this.updateCounts());
        })
      )
      .subscribe();
  }

  updateCounts(): any {
    this._searchApiService
      .getCounts()
      .pipe(
        tap((res) => {
          let counts = {
            asset: res,
          };

          const countsObj = this._searchApiService.parseAggregateCounts(counts);

          const state = this._state;

          const filters = this._searchApiService.updateMgCounts(
            countsObj,
            state.filterSet
          );

          this._state = {
            ...this._state,
            initialFilterSet: _.cloneDeep(filters),
            filterSet: _.cloneDeep(filters),
            currentAction: ASSET_ACTIONS.UPDATE_COUNTS,
          };
          this.assetStateObservable.next({ ...this.state });
        })
      )
      .subscribe();
  }

  clearFilters() {
    const state = this._state;

    const initialFilters: any = _.cloneDeep(state.initialFilterSet);
    const searchFields: any = this._filtersUtil.prepareSearchFields(
      initialFilters,
      'baseClass',
      'Asset'
    );

    this._state = {
      ...this._state,
      currentAction: ASSET_ACTIONS.CLEAR_FILTERS,
      searchFields:
        searchFields && searchFields?.length ? [{ ...searchFields[0] }] : [],
      filterSet: initialFilters,
    };

    this.assetStateObservable.next({ ...this.state });
  }

  onToggleFilter(name: string, range?: Range) {
    let filters: FilterGroupList = this._state.filterSet;

    if (!_.isEmpty(name)) {
      filters = range
        ? this._filtersUtil.toggleRangeFilter(
            this._state.filterSet,
            name,
            range
          )?.filters
        : this._filtersUtil.toggleFilter(this._state.filterSet, name)?.filters;
    }

    const searchFields = this.prepareSearchFields(
      filters,
      this._state.categories
    );

    this._state = {
      ...this._state,
      searchFields:
        searchFields && searchFields.length
          ? [{ ...searchFields[0] }]
          : this._state.searchFields,
      currentAction: ASSET_ACTIONS.FILTERS_UPDATED,
      filterSet: _.cloneDeep(filters),
      scrollId: ''
    };
    this.assetStateObservable.next({ ...this.state });
  }

  /**
   * Prepares ES search query fields from selected filters and categories.
   */
  private prepareSearchFields(
    filters: FilterGroupList,
    categories: AssetCategory[]
  ): ElasticSearchQuery[] {
    let searchFields = this._filtersUtil.prepareSearchFields(
      filters,
      'baseClass',
      'Asset'
    );

    // Update: Removed type filter
    // Using categories as type filter
    const categorySearchFields =
      this._filtersUtil.prepareSearchFieldsForCategories(categories, 'entityType');
    if (categorySearchFields) {
      searchFields[0].childFilters.push(categorySearchFields);
    }

    searchFields = _.cloneDeep(searchFields);

    return searchFields;
  }

  onSearchByTerm(searchTerm: string) {
    this._state.searchTerm = searchTerm;
    this._state.currentAction = ASSET_ACTIONS.FILTERS_UPDATED;
    this.assetStateObservable.next(this.state);
  }

  multiBindAsset(node: any) {
    let state = this._state;
    let actionNodes = node;

    let sensorList = [],
      assetId = state.nodeToEdit.id;

    actionNodes.forEach((sensor: any, index: any) => {
      sensorList.push(
        new Object({
          assetId: sensor.assetId,
          sensorId: sensor.sensorId,
          bindflow: sensor.bindflow,
          componentId: sensor.component_id,
        })
      );
    });

    this._state = {
      ...this._state,
      modificationInProgress: true,
      currentAction: ASSET_ACTIONS.PROCESSING
    };

    this.assetStateObservable.next({ ...this.state });

    return this._assetListService.multiBindAsset(sensorList).pipe(
      catchError((err) => {
        let errorMessage = 'Failed to bind asset';
        if (Array.isArray(err?.error)) {
          errorMessage = '';
          err.error.forEach((errorObj) => {
            for (const sensorId in errorObj) {
              errorMessage += errorObj[sensorId] + '\n';
            }
          });
        }
        this._toastService.error(errorMessage);
        return throwError(err); // of(err);
      }),

      tap((res) => {
        state = this._state;

        const deserializedResponse: any = res;

        this._state = {
          ...state,
          currentAction: ASSET_ACTIONS.PROCESSING,
          nodeToEdit: { ...deserializedResponse },
          activeEntity: _.cloneDeep(deserializedResponse),
          modificationInProgress: false,
        };

        this.assetStateObservable.next(this.state);
      }),

      delay(CL_API_DELAY_SHORT),

      finalize(() => {
        this._state.modificationInProgress = false;
        this._state.currentAction = ASSET_ACTIONS.ASSET_BOUND;
        this.assetStateObservable.next(this.state);
      })
    );
  }
  unbindAsset(boundAssets) {
    let state = this._state;
    const taggedAssetId = boundAssets[0].taggedAssetId;

    this._state = {
      ...this._state,
      modificationInProgress: true,
      currentAction: ASSET_ACTIONS.ASSET_UNBOUND_INPROGRESS,
    };

    this.assetStateObservable.next({ ...this.state });

    return this._assetListService.unbindAsset(taggedAssetId).pipe(
      catchError((err) => {
        this._utils.showMessage(
          err.error['error-message'],
          boundAssets[0]?.name
        );
        let unboundAsset = _.cloneDeep(boundAssets[0]);

        this._state = {
          ...this._state,
          currentAction: ASSET_ACTIONS.PROCESSING,
          nodeToEdit: { ...unboundAsset },
          activeEntity: _.cloneDeep(unboundAsset),
          error: err.error['error-message'],
          modificationInProgress: false,
        };

        this.assetStateObservable.next(this.state);

        return throwError(err); // of(err);
      }),

      tap((res) => {
        state = this._state;
        let unboundAsset = _.cloneDeep(boundAssets[0]);
        unboundAsset.status = AssetStatus.UnMonitored;
        delete unboundAsset.taggedAssetId;
        delete unboundAsset.sensorId;

        this._state = {
          ...state,
          currentAction: ASSET_ACTIONS.PROCESSING,
          nodeToEdit: { ...unboundAsset },
          activeEntity: _.cloneDeep(unboundAsset),
          error: '',
          modificationInProgress: false,
        };

        this.assetStateObservable.next(this.state);
      }),

      delay(CL_API_DELAY_SHORT),

      finalize(() => {
        this._state.modificationInProgress = false;
        this._state.currentAction = ASSET_ACTIONS.ASSET_UNBOUND;
        this.assetStateObservable.next(this.state);
      })
    ).subscribe();
  }

  checkSearchColumnExist() {
    if(this._state.searchFieldFilter.length>0){
      return true;
    }else{
      return false
    }
  }

  updateSearchFilters(searchFilters) {
    this._state.searchFieldFilter = searchFilters;
    this._state.currentAction = ASSET_ACTIONS.FILTERS_UPDATED;
    this.assetStateObservable.next(this.state);
  }

  // This method is taken from asset list component.
  public formateDownloadData(data:any[]){
    let tempAssetData = [...data];
    let dateFieldArray = ['checkedoutAt', 'maintenanceDate', 'modifiedAt', 'createdAt', 'lastCustodyAuditAt', 'lastCustodyStatusUpdateAt'];
    tempAssetData.forEach(asset => {
      dateFieldArray.forEach(element => {
        if(asset[element]){
          asset[element] = this.formateDatePipe.transform(asset[element],'default');
        }
      });
      asset.formattedLocationName = this.formatLocationName(asset);
    });
    return tempAssetData;
  }

  // This method is taken from asset list component.
  public formatLocationName(row) {
    let finalLocation = '';
    if(row?.monitoredStatus?.toLowerCase()==='unmonitored') {
      return row?.zoneName || '';
    }
   
    if(row?.shipmentList?.length) {
      if(row?.locationName) {
        return row?.locationName;
      }
  } 
  if((!row?.shipmentList?.length) && row?.monitoredStatus?.toLowerCase()==='monitored'){
      if(row?.locationName && row?.zoneName) {
        finalLocation = row?.locationName === row?.zoneName ? row?.locationName :
                        row?.locationName+ ', ' + row?.zoneName
      }else { 
        let pos = row?.position;
          let tempPositions = pos?.split(',')
       
        finalLocation = tempPositions?.length>0&& pos ? 'Lat: '+parseFloat(tempPositions[0]) + ', Lon: ' + parseFloat(tempPositions[1]): ''
      }
      return finalLocation;
    }
    return '';
    // let finalLocation = '';
    // let lastLocation = this.getLastLocation(row);
    // if(row?.monitoredStatus?.toLowerCase() === 'monitored'){
    //   if((row?.locationName || row?.zoneName) &&
    //   (row?.locationName?.toLowerCase() !== 'out of coverage' &&
    //    row?.zoneName?.toLowerCase() !== 'out of coverage')) {
    //       if(row?.locationName){
    //         finalLocation = row?.locationName + ', ' + row?.zoneName;
    //       } else {
    //         finalLocation = row?.zoneName;
    //       }
    //     }
    //   else if((row?.locationName || row?.zoneName) &&
    //   (row?.locationName?.toLowerCase() == 'out of coverage' ||
    //     row?.zoneName?.toLowerCase() == 'out of coverage')) {
    //       if(row?.locationName == row?.zoneName){
    //         finalLocation = row?.locationName;
    //       }else{
    //         finalLocation = row?.locationName?.toLowerCase() == 'out of coverage' ? row?.zoneName : row?.locationName
    //       }
    //     }
    //   else if(row?.deviceType?.toLowerCase().includes('tracker') && row?.sensorIds && !_.isEmpty(row?.position)) {
    //     let pos = row?.position;
    //     if(row?.shipmentList?.length){
    //       finalLocation = 'Lat: '+ pos.lat + ', Lon: ' + pos.lon;
    //     } else if(!row?.shipmentList?.length && lastLocation) {
    //       finalLocation = 'Out of Coverage' + ' (at Lat: ' + pos.lat + ', Lon: ' + pos.lon + ')';
    //     } else {
    //       finalLocation = row?.zoneName;
    //     }
    //   } else {
    //     finalLocation = row?.zoneName;
    //   }
    // } //else {
    //   finalLocation = row?.zoneName;
    // }
    return finalLocation;
  }
}