import {ErrorHandler, Injectable} from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse,} from '@angular/common/http';
import { AppAuthService } from "../../authenticate/app-auth.service";

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptor implements HttpInterceptor {
  refreshingToken = false;
  waitQueue: any = [];
  constructor(private error: ErrorHandler, private appAuth: AppAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Skip-Auth')) {
      if(this.appAuth.getAuthToken()) {
        request = request.clone({ headers: request.headers.append( 'token', this.appAuth.getAuthToken())});
      } else {
        this.refreshingToken = true;
        this.appAuth.isAuthenticated().subscribe((authenticated) => {
          this.refreshingToken = false;
          if(authenticated) {
            request = request.clone({ headers: request.headers.append( 'token', this.appAuth.getAuthToken())});
          } else {
            this.appAuth.logout();
            request = null;
          }
        })
      }
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if(!this.refreshingToken){
          this.refreshingToken = true;
          this.waitQueue.push({'request': request, 'handler': next});
          this.appAuth.isAuthenticated().subscribe((authenticated) => {
            this.refreshingToken = false;
            if(authenticated){
              request = request.clone({ headers: request.headers.append( 'token', this.appAuth.getAuthToken())});
              return next.handle(request)
              //TODO:: trigger for processing pending requests after this request is handled
              //this.processPendingRequests(false);
            } else {
              //this.processPendingRequests(true);
              this.appAuth.logout();
              return next.handle(null)
            }
          })
        } else {
          this.waitQueue.push({'request': request, 'handler': next});
        }
        //return this.handleError(request, next);
      }

      return throwError(error);
    }));
  }

  processPendingRequests(doCancel){
    //TODO::if refresh is success, then trigger all the requests in queue, else cancel all of them.
    this.waitQueue.forEach((item)=>{
      var request = item.request.clone({ headers: item.request.headers.append( 'token', this.appAuth.getAuthToken())});
      item.handler.handle(request)
    });
    this.waitQueue = [];
  }
}
