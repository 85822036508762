import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { UserService } from '@cl/user/user.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'cl-policy-side-panel',
  templateUrl: './policy-side-panel.component.html',
  styleUrls: ['./policy-side-panel.component.scss']
})
export class PolicySidePanelComponent implements OnInit,OnChanges,OnDestroy {
  @Input() policyObject: any;
  @Input() mode: any;
  @Output() closeSidePanel:EventEmitter<boolean> = new EventEmitter();
  isLoading = false;
  private subscriptions: Subscription[] = [];
  entityList = [];
  currentEntity:any;
  actionList = [];
  attributeArray=[];
  valueArray = [];

  policyPayload = {
    "tenantId":"",
    "name":"",
    "conditions":null,
    "actions":[],
    "entityName":"",
    "moduleName":"INDOOR",
    "effect":"ALLOW",
    "id":'',
    "dataClassification":'DEFAULT'
  }
  searchOrgName: any;
  orgList:any;
  operaterValue:any = '';
  restrictedAttributes: any = ['Carrier Name', 'Destination', 'Origin', 'Route'];
  checkAttributeValue: boolean = false;
  attributeForm:any = '';
  addedAttribute:any = [];
  showDataAccessContorl: boolean = false;
  dataAccessControl:any = [];
  dataAccessDefault:any;
  dataAccessSensitive:any;
  dataAccessRestricted:any;
  dataAccessArray:any;
  isChecked:boolean=false;
  dataAccess: any = ['DEFAULT', 'RESTRICTED', 'DTP_RESTRICTED_DATA'];
  rhsValue:any;
  conditonsValue:any = [];
  prevMode:any;
  selectedOrg:any;
  areaList:any;
  constructor(private fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private userService:UserService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    ) { }
  policyForm = this.fb.group({
    policyName: ['' , Validators.required],
    entityName: ['' , Validators.required],
    actions: this.fb.array([], Validators.required),
    attributes: this.fb.array([])
});

  ngOnInit(): void {
    this.prevMode = this.mode;
    if(this.mode == 'add'){
      this.getEntityList();
    }
  const formValueChangeSubs =  this.attributes.valueChanges.subscribe(x => {
    this.attributes.value.forEach((value, index) => {
      if(value.operator){
        this.operaterValue = value.operator;
        this.searchOrgName = this.attributes.controls[index].get('searchTearm').value;
        this.attributes.controls[index].get('searchTearm').enable({ emitEvent: false });
      }
      else if(value.lhs){
        this.attributes.controls[index].get('operator').enable({ emitEvent: false });
      }
    });
  });
  this.subscriptions.push(formValueChangeSubs);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.mode == 'view'  || this.mode == 'edit'){
     this.getEntityList();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get actions() {
    return this.policyForm.get('actions') as FormArray;
  }

  get attributes() {
    return this.policyForm.get('attributes') as FormArray;
  }

  tiggerChangeDetection(){
    this._changeDetectorRef.detectChanges();
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

 getEntityList(){
   const entityListSubs:Subscription = this.userService.getEntityForCreatingPolicy().subscribe((data:any)=>{
    this.entityList = [...data];
    if(this.mode == 'edit' || this.mode == 'view'){
      this.getOrg();
      this.getAreas();
      this.dataAccessArray = '';
    }
   });
   this.subscriptions.push(entityListSubs);
 }

 getPolicyByID(id){
  const getPolicySubs:Subscription = this.userService.getPoliciesById(id).subscribe((data:any)=>{
    let condValues = []
    this.conditonsValue = [];
    if(this.mode === 'view'){
      data.conditions?.must.forEach(item=>{
        item.should.forEach(cndnItem=>{
          condValues.push(cndnItem)
        })
      })
    }
    if(!_.isEmpty(condValues)){
      this.conditonsValue = condValues.reduce((acc, obj) => {
        const existingObject = acc.find(item => item.lhs === obj.lhs);
        if (existingObject) {
          // If the "lhs" value already exists, append the "rhs" value with a comma
          existingObject.rhs += `, ${obj.rhs}`;
        } else {
          // If the "lhs" value doesn't exist, add a new object to the result array
          acc.push(obj);
        }
        return acc;
      }, []);

      if(!_.isEmpty(this.conditonsValue) && data.entityName === 'CDMINSTANCE'){
        let rhsOrg=[];
        this.conditonsValue[0].rhs.replace(/\s/g, "").split(',').forEach(el=>{
           this.orgList.filter(org=>{
           if(org.id == el){
             rhsOrg.push(org)
           }
          })
        })
        this.selectedOrg = rhsOrg;
      }

      if(!_.isEmpty(this.conditonsValue) && (this.conditonsValue[0].lhs === 'destination' || this.conditonsValue[0].lhs === 'origin')){
        if(!_.isEmpty(this.areaList)){
          let rhsArea=[];
        this.conditonsValue[0].rhs.replace(/\s/g, "").split(',').forEach(el=>{
           this.areaList.filter(org=>{
           if(org.id == el){
             rhsArea.push(org)
           }
          })
        })
        this.selectedOrg = rhsArea;
        }
      }
    }
   this.getEditEntityAttribute(data.entityName, data);
   this.fnDataAccess(data?.dataClassification);
  });
  this.subscriptions.push(getPolicySubs);
}

initializePolicyForm(data){
  let formArrayForActions = [];
  this.entityList.forEach(entity => {
    if(entity.entityId == data.entityName){
      this.setupActionFromArray(entity);
    }
  });
  this.actionList.forEach(element => {
    if(data.actions.indexOf(element)>-1){
      formArrayForActions.push(true);
    }else{
      formArrayForActions.push(false);
    }
  });
  this.policyForm.patchValue({
    policyName: data.name,
    entityName:this.currentEntity,
    actions: [...formArrayForActions],
  });
  if(this.mode == 'edit'){
    this.policyForm.controls['entityName'].disable();
    this.editFormGroupToAttribute(data);
  }
}

 setupActionFromArray(entity){
    this.currentEntity = entity;
    if(this.currentEntity.entityId == 'CDMINSTANCE'){
      this.showDataAccessContorl = true;
    }else{
      this.showDataAccessContorl = false;
    }
    this.actionList = [];
    this.actions.clear();
    this.tiggerChangeDetection();
    entity.actions.forEach(action => {
      this.addAction();
    });
    this.actionList = [...entity.actions];
   // this.getEntityAttribute(entity.entityId);
 }

 getEditEntityAttribute(id, condData){
   this.attributes.clear();
  const attributeSubs = this.userService.getEntityAttributeById(id).subscribe((data:any)=>{
    this.attributeArray = [...data];
    this.initializePolicyForm(condData);
  }, error=>{
    console.log(error);
  });
  this.subscriptions.push(attributeSubs);
 }

 getEntityAttribute(id){
  this.attributes.clear();
 const attributeSubs = this.userService.getEntityAttributeById(id).subscribe((data:any)=>{
   this.attributeArray = [...data];
   if(this.attributeArray.length>0){
     this.addFormGroupToAttribute();
   }
 }, error=>{
   console.log(error);
 });

 this.subscriptions.push(attributeSubs);
}

 addFormGroupToAttribute(){
  let newAttributeArray = [...this.attributeArray];
  this.attributes.value.forEach(value => {
    if(newAttributeArray.indexOf(value.lhs)>-1){
     newAttributeArray.splice(newAttributeArray.indexOf(value.lhs), 1);
    }
  });

  this.attributes.push(this.fb.group({
    lhs: [''],
    operator: [{value: '', disabled: true}],
    rhs: [{value: [], disabled: false}],
    searchTearm:[{value: '', disabled: true}],
    valueArray:[{value: [], disabled: false}],
    formAdded:[false],
    editMode:[false],
    selectedattributeArray:[[...newAttributeArray]],
    operatorArray:[],
    finalAttributeValue:[{}],
    lhsValue:[false],
    addOrg:[false]
    })
  );
 }

 removeselectedCondition(i){
  this.attributes.removeAt(i);
 }

 editFormGroupToAttribute(data){
   if(data.conditions && data.conditions.must){
    if(data.conditions.must.length>0){
      data.conditions.must.forEach(value => {
        let rhs = [];
        value.should.forEach(element => {
         if(this.policyForm?.controls['entityName']?.value?.entityId==='SHIPMENT'){
          let rhsShipperObj=[];
          if(element.lhs === 'destination' || element.lhs === 'origin'){
            let rhsObj = this.areaList.filter(el=>{
              return el.id == element.rhs;
             }).map(area => {
              return {
                 'uilabel':area.name,
                 'id':area.id
              }
              })
              rhs = [...rhs,...rhsObj]
          }else{
            rhsShipperObj.push({
              'uilabel':element.rhs,
               'id':element.rhs
            })
            rhs = [...rhs,...rhsShipperObj]
          }
         }else{
         let rhsObj = this.orgList.filter(el=>{
          return el.id == element.rhs;
         }).map(org => {
          return {
             'uilabel':org.name,
             'id':org.id
          }
          })
          rhs = [...rhs,...rhsObj]
        }
          // rhs.push(element.rhs);
        });

        let lhs = {};
        this.attributeArray.forEach(element => {
          if( value.should[0].lhs == element.name){
            lhs = element;
          }
        });

        let operator = {};
        lhs['operations'].forEach(op => {
          if(op.symbol == value.should[0].operator){
            operator = op;
          }
        });
        let newAttributeArray = [...this.attributeArray];
        this.attributes.value.forEach(value => {
          if(newAttributeArray.indexOf(value.lhs)>-1){
           newAttributeArray.splice(newAttributeArray.indexOf(value.lhs), 1);
          }
        });

        this.attributes.push(this.fb.group({
          lhs: [lhs],
          operator: [{value: operator, disabled: false}],
          rhs: [{value: [...rhs], disabled: false}],
          searchTearm:[{value: '', disabled: false}],
          valueArray:[{value: [], disabled: false}],
          formAdded:[true],
          editMode:[false],
          selectedattributeArray:[[...newAttributeArray]],
          operatorArray:[lhs['operations']],
          finalAttributeValue:[{}],
          addOrg:[false]
          })
        );

      });
    }
   }
 }

 setupOperator(event, index){
  let newAttributeArray = [...this.attributeArray];
  let currentArray = [];
   this.attributes.controls[index].get('operatorArray').setValue(event.operations);
   this.attributes.controls[index].get('operator').setValue('');
   this.attributes.controls[index].get('searchTearm').setValue('');
   this.attributes.controls[index].get('valueArray').setValue('');
   this.attributes.controls[index].get('lhsValue').setValue(true);
   this.operaterValue = '';
   
   if(this.attributes.controls[index].get('rhs').value){
    this.attributes.controls[index].get('rhs').setValue([...currentArray]);
  }
   if(this.restrictedAttributes.indexOf(event.uiLabel) !== -1){
     this.checkAttributeValue = true;
     this.attributes.controls[index].get('addOrg').setValue(false);
   }else{
     this.checkAttributeValue = false;
     this.attributes.controls[index].get('addOrg').setValue(true);
   }
   if(!_.isEmpty(this.attributes.controls[index].get('rhs').value) && this.checkAttributeValue){  
    this.attributes.controls[index].get('addOrg').setValue(true);
  }else{
    this.attributes.controls[index].get('addOrg').setValue(false);
  }
   this.attributes.value.forEach((values, index)=>{
     if(newAttributeArray.indexOf(values.lhs)>-1){
       newAttributeArray.splice(newAttributeArray.indexOf(values.lhs), 1);
     }
   })

   if(this.attributes.value.length > index+1){
      for(var i=index; i<this.attributes.value.length; i++){
        if(i+1 < this.attributes.value.length){
          this.attributes.controls[i+1].get('selectedattributeArray').setValue(newAttributeArray);
        }
      } 
   }
 }

 addAction() {
  this.actions.push(this.fb.control(false));
}

  closePanel(isReload: boolean){
    this.closeSidePanel.emit(isReload);
  }

  createConditions(){
    let conditions = {must:[]};
    this.attributes.value.forEach(value => {
      if(!_.isEmpty(value.finalAttributeValue)){
        let shouldObject = {should:[]};
        value.rhs.forEach((element,index) => {
          shouldObject.should.push(new Object({
            "lhs":value.lhs.name,
            "operator":value.operator.symbol,
            "rhs":element.id
          }))
        });
        
        conditions.must.push(shouldObject);
      }else if(value.formAdded){
        let shouldObject = {should:[]};
        value.rhs.forEach(element => {
          shouldObject.should.push(new Object({
            "lhs":value.lhs.name,
            "operator":value.operator.symbol,
            "rhs":element.id
          }))
        });
        conditions.must.push(shouldObject);
      }
    });
    return conditions.must.length>0?conditions:null;
  }

  savePolicy(){
    this.isLoading = true;
    this.attributes.value.forEach((value, index) => {
      if(value.operator){
        this.attributes.controls[index].get('formAdded').setValue(true);
      }
    });
    let formValues = this.policyForm.value;
    this.policyPayload.name = formValues.policyName;
    this.policyPayload.tenantId = this.userService.getEffectiveTenantId();
    this.policyPayload.moduleName = this.userService.getUser().module;
    this.policyPayload.entityName = formValues.entityName.entityId;
    this.policyPayload.conditions = this.createConditions();
    this.policyPayload.dataClassification = this.dataAccessArray?this.dataAccessArray:this.policyPayload.dataClassification;
    formValues.actions.forEach((action,index) => {
      if(action == "true" || action == true){
        this.policyPayload.actions.push(this.actionList[index]);
      }
    });
    this.policyPayload.id = null;

    const policySubs:Subscription = this.userService.postPolicies(this.policyPayload).subscribe(()=>{
    this.isLoading = false;
        let action = 'Policy added successfully';
        this.openSnackBar(action,  formValues.policyName);
        this.closePanel(true);
    }, error=>{
      this.isLoading = false;
      this.openSnackBar(error.error['error-message'],  formValues.policyName);
    });
    this.subscriptions.push(policySubs);
  }

  editPolicy(){
    this.isLoading = true;
    this.attributes.value.forEach((value, index) => {
      if(value.operator){
        this.attributes.controls[index].get('formAdded').setValue(true);
      }
    });
    let formValues = this.policyForm.value;
    this.policyPayload.name = formValues.policyName;
    this.policyPayload.tenantId = this.policyObject.tenantId;
    this.policyPayload.entityName = this.currentEntity.entityId;
    this.policyPayload.conditions = this.createConditions();
    this.policyPayload.moduleName = this.policyObject.moduleName;
    this.policyPayload.dataClassification = this.dataAccessArray?this.dataAccessArray:this.policyPayload.dataClassification;
    formValues.actions.forEach((action,index) => {
      if(action == "true" || action == true){
        this.policyPayload.actions.push(this.actionList[index]);
      }
    });
    this.policyPayload.id = this.policyObject.id;
    this.policyPayload.moduleName = this.policyObject.moduleName;
   const policySubs:Subscription = this.userService.putPolicies(this.policyPayload).subscribe(()=>{
     this.isLoading = false;
     let action = 'Policy saved successfully';
      this.openSnackBar(action,  formValues.policyName);
      this.closePanel(true);
    }, error=>{
      this.isLoading = false;
      this.openSnackBar(error.error['error-message'],  formValues.policyName);
    });
    this.subscriptions.push(policySubs);
  }

  checkIfFormValid(){
    if(this.policyForm.valid && this.policyForm.value.actions.indexOf(true)>-1 && !this.isLoading){
      return false;
    }else{
      return true;
    }
  }

  onKeySearch(index){
    this.attributeForm = this.attributes.value[index];
    const attributeSubs = this.userService.getEntityAttributeValues(this.attributeForm.lhs.entityId,this.attributeForm.lhs.name, this.attributeForm.searchTearm).subscribe((data:any)=>{
      let dataObjectArray = [];
      Object.keys(data).forEach(key=>{
        dataObjectArray.push(new Object({
          'uilabel':data[key],
          'id':key
        }));
      });
      this.valueArray = dataObjectArray;
      if(this.restrictedAttributes.indexOf(this.attributeForm.lhs.uiLabel) !== -1){
        this.checkAttributeValue = true;
        this.attributes.controls[index].get('addOrg').setValue(false);
       }else{
        this.checkAttributeValue = false;
        this.attributes.controls[index].get('addOrg').setValue(true);
       }

       if(this.policyForm?.controls['entityName']?.value?.entityId==='SHIPMENT'){
          let shipmentValue = this.attributes.controls[index].value.rhs;
          if((this.valueArray.length == 0 || shipmentValue.filter(item=>item.uilabel==this.attributes.value[index].searchTearm).length ===0) && !_.isEmpty(this.attributeForm.searchTearm) && !this.checkAttributeValue){
            this.attributes.controls[index].get('addOrg').setValue(true);
          }else{
            this.attributes.controls[index].get('addOrg').setValue(false);
          }
      } else if(this.policyForm?.controls['entityName']?.value?.entityId!=='SHIPMENT'){
        if((this.valueArray.length == 0 || this.valueArray.filter(item=>item.uilabel.toLowerCase()==this.attributes.value[index].searchTearm.toLowerCase()).length ===0) && !_.isEmpty(this.attributeForm.searchTearm) && !this.checkAttributeValue){
          this.attributes.controls[index].get('addOrg').setValue(true);
        }else{
          this.attributes.controls[index].get('addOrg').setValue(false);
        }
      }
      this.attributes.controls[index].get('valueArray').setValue({value:dataObjectArray, emitEvent: false });
    }, error=>{
      console.log(error);
    });

    this.subscriptions.push(attributeSubs);
  }

  valueChange(event, value, index){
    let currentArray = [...this.attributes.controls[index].get('rhs').value];
    if(event.checked){
      let checkDuplicate = currentArray.findIndex(current => value.id === current.id)
      if(checkDuplicate === -1){
        currentArray.push(value);
        this.attributes.controls[index].get('searchTearm').setValue('');
        this.attributes.controls[index].get('addOrg').setValue(false);
      }else{
        this.attributes.controls[index].get('addOrg').setValue(true);
      }
    }else{
      const deletedIndex = currentArray.findIndex(current => value.id == current.id);
      if(deletedIndex > -1){
        currentArray.splice(deletedIndex,1);
      }
    }
    this.attributes.controls[index].get('rhs').setValue([...currentArray]);
    this.getAttributeValues(index);
  }


  addAttribute(index){
    if(index != 0){
    this.attributes.value.forEach((values, q)=>{
      if(!_.isEmpty(values.lhs) && q!=0){
        this.addedAttribute.push(values.lhs);
      }
    })
  }
    if(this.addedAttribute.length>=1 && index == 0){
        for(var i=index; i<this.attributes.value.length; i++){
          if(i+1 <= this.attributes.value.length){
            if(this.addedAttribute[i]?.name == this.attributes.controls[i+1]?.value.lhs.name){
              let action = "Selected attribute has already added";
              this.openSnackBar(action, '');
              this.addedAttribute = [];
              return;
            }
          }
        } 
    }
    this.attributes.controls[index].get('formAdded').setValue(true);
    this.attributes.controls[index].get('finalAttributeValue').setValue({});
    this.attributes.controls[index].get('finalAttributeValue').setValue(JSON.parse(JSON.stringify(this.attributes.controls[index].value)));
    
  }

  editAttribute(index){
    this.attributes.controls[index].get('formAdded').setValue(false);
    this.attributes.controls[index].get('editMode').setValue(true);
    this.attributes.controls[index].get('finalAttributeValue').setValue({});
    this.attributes.controls[index].get('finalAttributeValue').setValue(JSON.parse(JSON.stringify(this.attributes.controls[index].value)));
  }

  deleteAttribute(index){
    let message = `Are you sure you want to delete this condition?`;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.attributes.removeAt(index);
      }
    });
  }

  addPolicyOrg(i){
    if(this.policyForm?.controls['entityName']?.value?.entityId==='SHIPMENT'){
      let shipmentValue = new Object({
        'uilabel':this.attributes.controls[i].get('searchTearm').value,
        'id':this.attributes.controls[i].get('searchTearm').value
      })
      this.valueChange({'checked':true}, shipmentValue, i);
    }else{
    this.isLoading = true;
    let orgName = this.searchOrgName = this.attributes.controls[i].get('searchTearm').value;//this.searchOrgName;
    this.userService.addPolicyOrg(orgName).subscribe((res:any)=>{
      if(!_.isEmpty(res)){
      let orgAdded = res?.properties;
      let orgValue = new Object({
        'uilabel':orgAdded.name,
        'id':orgAdded.id
      })
      this.valueChange({'checked':true}, orgValue, 0);
      }
      this.attributes.controls[i].get('addOrg').setValue(false);
      this.isLoading = false;
    }, error=>{
      this.isLoading = false;
      console.log(error);
    })
  }
  }

  getOrg(){
    this.userService.getOrg().subscribe(res =>{
      this.orgList = res
      this.getPolicyByID(this.policyObject.id);
    })
  }

  isCheckedRHS(rhsArr, value) {
    return rhsArr.findIndex(rhs => rhs.id == value.id) > -1
  }

  getAttributeValues(index){
    let attributeForm = this.attributes.value[index];
    this.userService.getEntityAttributeValues(attributeForm.lhs.entityId,attributeForm.lhs.name, '').subscribe((data:any)=>{
      let dataObjectArray = [];
      Object.keys(data).forEach(key=>{
        dataObjectArray.push(new Object({
          'uilabel':data[key],
          'id':key
        }));
      });
      this.valueArray = dataObjectArray;
      this.attributes.controls[index].get('valueArray').setValue({value:dataObjectArray, emitEvent: false });
      this.searchOrgName = this.valueArray;
    })
  }

  fnDataAccess(event){
    if(this.mode == 'edit'){
      if(typeof event == 'string'){
        event.split(',').forEach(item=>{
          switch(item) { 
            case "RESTRICTED": { 
              this.isChecked = true;
              this.dataAccessSensitive = true; 
              this.dataAccessControl.push(item);
              this.dataAccessArray = this.dataAccessControl.toString();
               break; 
            } 
            case "DTP_RESTRICTED_DATA": { 
              this.isChecked = true;
              this.dataAccessRestricted = true;
              this.dataAccessControl.push(item);
              this.dataAccessArray = this.dataAccessControl.toString();
               break; 
            } 
            default: { 
              break;              
           }
          }
        })
      }else{
        if(event?.source?.checked == false){
          let newVal = this.dataAccessControl.filter(item => item != event?.source?.value);
          this.dataAccessControl = newVal;
          this.dataAccessArray = newVal.toString();
        }else{
          this.dataAccessControl.push(event?.source?.value); 
          this.dataAccessArray = this.dataAccessControl.toString();
        }
      }
      
    }else{
      if(typeof event == 'string'){
        this.dataAccessArray = "";
        this.dataAccessControl = [];
        event.split(',').forEach(item=>{
          switch(item) { 
            case "RESTRICTED": {  
              this.dataAccessControl.push('Sensitive Data');
              this.dataAccessArray = this.dataAccessControl.toString();
               break; 
            } 
            case "DTP_RESTRICTED_DATA": { 
              this.dataAccessControl.push('DTP Restricted Data');
              this.dataAccessArray = this.dataAccessControl.toString();
               break; 
            } 
            default: { 
              this.dataAccessControl.push('Default');
              this.dataAccessArray = this.dataAccessControl.toString();
              break;              
           }
          }
        })
      }else{
        if(event?.source?._checked == false){
          let newVal = this.dataAccessControl.filter(item => item != event?.source?.value);
          this.dataAccessControl = newVal;
          this.dataAccessArray = newVal.toString();
        }else{ 
          this.dataAccessControl.push(event?.source?.value);
          this.dataAccessArray = this.dataAccessControl.toString();
        }
      }
    }
  }

  getAreas(){
   this.userService.getAreas().then((res:any)=>{
      this.areaList = res.hits;
    });
  }
}
