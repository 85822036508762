<div class="asset-list">
  <div class="mt-2 mb-2">
    <span class="page-title" [textContent]="'common.Assets'|translate"></span>
  </div>
  <!-- <cl-table-surtitle [rows]="loadedAssets" [selected]="selected.length" [total]="totalAssets" [pageName]="'Assets'"></cl-table-surtitle>  -->
  <div class="mb-1 list-page-count h-5">
    <span>
      Showing <span class="font-bold">{{ loadedAssets }}</span> of {{ totalAssets }} rows.
      <span class="font-bold" *ngIf="selected_rows.length">{{fitered_selected_rows.length}} of {{selected_rows.length}} total selected rows visible.</span>
    </span>
  </div>
  
  <div class="wt-ngx-grid-wrapper asset-listing list-page-wrapper list-page relative" [ngClass]="[showScanSelectPanel ? 'h-calc-80' : 'h-calc-50']">
    <div class="flex justify-between pt-1 pb-1 filter-bar">
      <div class="action-panel-left self-start flex items-center color4 grow">
        <span (click)="toggleFilterPanel()" [ngClass]="{'filter-open-state':showFilterPanel}" class="cursor-pointer color4 list-page_filter-toggle flex items-center mr-2">
          <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
          {{'common.filterOptions'|translate}}
        </span>
        <form [formGroup]="searchFormGroup" novalidate>
          <cl-list-search [placeholder]="'Search by Name or ID'" (search)="updateFilter($event)"
                  [columns] = "searchableAttributes"></cl-list-search>
        </form>
        <span class="action-separator mr-2" [clIsValidAction]="actionBtnPolicies.edit || actionBtnPolicies.bind || actionBtnPolicies.unbind || actionBtnPolicies.delete"></span>
        <button [disabled]="bindDisabled" [ngClass]="[bindDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
          [clIsValidAction]="actionBtnPolicies.bind"
          class="p-4-10 border-general  br2  mr-2" (click)="bindOrUnbind('bind')">
          <i class="fa fa-link" ></i>
          {{'assets.bind'|translate}}
        </button>
        <button [disabled]="unBindDisabled" [ngClass]="[unBindDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
          [clIsValidAction]="actionBtnPolicies.unbind"
          class="p-4-10 border-general  br2  mr-2" (click)="bindOrUnbind('Unbind')">
          <i class="fa" [ngClass]='bindIcon'></i>
          {{'assets.unbind'|translate}}
        </button>
        <button
          [clIsValidAction]="actionBtnPolicies.edit"
          [disabled]="!isEnableEdit"
          [ngClass]="[
            !isEnableEdit
              ? 'color37 bcolor36 bgcolor38'
              : 'color4 bcolor4 bgcolor32'
          ]"
          class="p-4-10 border-general br2 mr-2"
          (click)="openAddAssetPanel(true)"
        >
          <i class="fa fa-pencil"></i>
          {{'common.edit'|translate}}
        </button>
        <button
          [clIsValidAction]="actionBtnPolicies.delete"
          [disabled]="deleteDisabled"
          [ngClass]="[
          deleteDisabled
              ? 'color37 bcolor36 bgcolor38'
              : 'color4 bcolor4 bgcolor32'
          ]"
          class="p-4-10 border-general br2 mr-2"
          (click)="deleteAssetDialog()"
        >
          <i class="fa" [ngClass]="'fa-trash'"></i>
          {{'common.delete'|translate}}
        </button>
  
        <span class="action-separator"></span>
        <div>
          <cl-coc-menu [renderIn]="'list'" [assets]="selectedItems" (closeSidePanel)="closePopupAssetPanel($event)"></cl-coc-menu>
        </div>
        <div>
          <cl-bulk-action-menu [entity]="'asset'" [menuOptions]="bulkactions"
            (bulkPanelObject)="bulkPanelHandler($event)"></cl-bulk-action-menu>
        </div>
      </div>
      <div class="action-panel-right self-end asset-listing__action-panel-right flex grow justify-end items-center mr-16 action-item">
        <div>
          <button class="toggler-example align-grid-menu p-2 border-0 py-2 colsearch-menu" [ngClass]="isColored?'searchToggler':''" (click)="toggleSearchColumns()">
            <span class="dark-blue-text" title="{{'common.searchByColumn'|translate}}"><i class="fa fa-search-plus color4"></i></span>
          </button>
        </div>
  
        <div>
          <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
        </div>
        <div>
          <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)" [havingImageColumn]="true" [(showImage)]="isImageVisible"
      [page]="'asset'" (showImageChange)="adjustRowHeight()">
      </cl-grid-column-toggle>
        </div>
      </div>
      <div
        class="md-add-button list add-shipment-btn" style="z-index: 999"
        >
        <div class="add-button" [clIsValidAction]="actionBtnPolicies.create"
          *ngIf="
                !sidePanelsObj.create &&
                !sidePanelsObj.bind &&
                !sidePanelsObj.bulk &&
                !sidePanelsObj.summary &&
                !sidePanelsObj.edit &&
                !sidePanelsObj.scanner"
          (click)="openAddAssetPanel(false)"
        >
          <span class="fa fa-plus"></span>
        </div>
        <div class="add-button" *ngIf="sidePanelsObj.create || sidePanelsObj.edit" (click)="closeSidePanel()">
          <span class="fa fa-close"></span>
        </div>
      </div>
    </div>
    <div class="h-calc-40">
      <main class="flex flex-row h-100 overflow-y-hidden">
        <div class="grow-0" [ngClass]="{'w-0 invisible': !showFilterPanel}">
          <cl-assets-filter class="nav panel scroll-y grow-0" (closeFilterPanel)="toggleFilterPanel()"></cl-assets-filter>
        </div>
        <div class="grid-container grow relative">
          <div *ngIf="isLoading || checkoutTransferInProgress">
            <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
          </div>
          <div *ngIf="delayedRefreshStart">
            <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
            (refreshList)="reloadAssets()"
            ></cl-refresh-countdown>
          </div>
          <div class="h-100" #tableWrapper [ngClass]="{'hide-scroll': isLoading}" [style.width.px]="tableWidth">
            <ngx-datatable class="h-calc-40" #table [rows]="rendered_data" [columns]="columns" [columnMode]="'force'"
              [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight" [scrollbarV]="true"
              [scrollbarH]="true" (reorder) = "columnReordered($event)"
              [selected]="selected" [sortType]="sortType" [selectionType]="selection" [selectAllRowsOnPage]="false"
              [rowIdentity]="getId" (scroll)="onScroll($event.offsetY)" (select)='onSelect($event)' (sort)="onSort($event)" [trackByProp]="'id'" [reorderable]="false">
            </ngx-datatable>          
  
            <div class="flex flex-row items-center justify-center">
              <button class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
                [ngClass]="{'bcolor9': isLoading || isAllLoaded}" (click)="getLoadMoreUpdate()"
                [disabled]="isLoading || isAllLoaded">
                <span class="mat-button__ripple"></span>
                <span class="mat-button__label color4" [ngClass]="{'color9': isLoading || isAllLoaded}">load next
                  50</span>
                <span *ngIf="isLoading"><i class="fa fa-circle-o-notch fa-1x fa-pulse color4"></i></span>
              </button>
  
            </div>
          </div>
  
          <ng-template #assetIdTemplate let-row="row" let-value="value" let-i="index">
            <a class="font-12-bold" href (click)="loadAssetSummary(row.id, $event, row.entityTypeKey)">{{value}}</a>
          </ng-template>
  
          <ng-template #assetNameTemplate let-row="row" let-value="value" let-i="index">
            <div class="flex items-center">
              <img loading="lazy" [src]="row.imageThumbnailURL || row.imageURL || 'assets/svgs/asset.svg'" class="h-9 pr-2" *ngIf="isImageVisible">
              <a class="font-12-bold" href (click)="loadAssetSummary(row.id, $event, row.entityTypeKey)">{{value}}</a>
            </div>
          </ng-template>
  
          <ng-template #deviceIdTemplate let-value="value">
             <a *ngIf="value?.length == 1" class="font-12-bold" href="javascript:void(0)" [routerLink]="'/sensordetails/' + value[0]">{{value[0]}}</a>
             <span *ngIf="value?.length > 1" >{{value.join(', ')}}</span>
          </ng-template>
  
          <ng-template #shipmentIdTemplate let-row="row" let-value="value" let-i="index">
            <a class="font-12-bold" href="javascript:void(0)" [routerLink]="'/shipmentdetails/'+row?.shipmentId" [queryParams]="{type: row.type}">{{value}}</a>
          </ng-template>
  
          <ng-template #assetDateTemplate let-row="row" let-value="value" >
            {{value | formateDate:'default'}}
          </ng-template>
  
          <ng-template #catalogTypeTemplate let-row="row" let-value="value" >
            {{value | titlecase}}
          </ng-template>
        </div>
      </main>
  
    </div>
  
    <div *ngIf="sidePanelsObj.summary">
      <cl-asset-summary [assetType]="selectedAssetType" [assetId]="selectedAssetId" (closeSidePanel)="closeSidePanel();closePopupAssetPanel($event)">
      </cl-asset-summary>
    </div>
  
    <div class="summery-panel" *ngIf="sidePanelsObj.create || sidePanelsObj.edit">
      <cl-asset-add-panel [isEdit]="sidePanelsObj.edit" [assetId]="selectedItems.length > 0 && selectedItems[0].id" (closeSidePanel)="closeSidePanel();closePopupAssetPanel($event)"></cl-asset-add-panel>
    </div>
  
    <div *ngIf="sidePanelsObj.bind">
      <app-asset-binder [assetObject]="selectedAsset" (closeSidePanel)="closeSidePanel();closePopupAssetPanel($event)"></app-asset-binder>
    </div>
  
    <div *ngIf="sidePanelsObj.bulk">
      <cl-bulk-action-panel [panelObj]="bulkPanelObj" [successMessage]="'Uploaded assets '+ bulkCurrentAction + ' assets status will be sent to mail'" (closeSidePanel)="closeSidePanel()" [downloadedCSVFileName]="bulkCurrentAction == 'bind' ? 'asset_bind_template' : 'asset_unbind_template'" [fields]="bulkCurrentAction == 'bind' ? bindFields : unbindFields"
      (refreshParentList)="closePopupAssetPanel()"></cl-bulk-action-panel>
    </div>
  
    <div *ngIf="sidePanelsObj.scanner"><cl-code-scanner [scannedItem]="scannedItems" [pageName]="'Asset'" (closeSidePanel)="closeSidePanel()" (scanCodeEvent)="onScanCode($event)" (codescannerhandler)="codescannerhandler($event)"></cl-code-scanner></div>
  
  </div>
  </div>
  <ng-template #clearSearchTemplate>
    <span class="fa fa-times-circle-o mr-4 clear-filter cursor-pointer" *ngIf="showColumnSearch" (click)="closeFilter()"></span>
  </ng-template>
  <ng-template #headerTemplate let-column="column">
    <div [ngClass]="{'cursor-pointer w-full': column.manualSortable, 'pointer-events-none': !column.manualSortable}" (click)="onSort(column.prop)">
      <span class="font-bold">
        {{ column.name }}
      </span>
      <ng-container *ngIf="column.manualSortable">
        <span class="sort-btn datatable-icon-down sort-desc top-1" *ngIf="sortItem.fieldName == column.prop && sortItem.sortOrder == 'DESC'"></span>
        <span class="sort-btn datatable-icon-up sort-asc top-1" *ngIf="sortItem.fieldName == column.prop && sortItem.sortOrder == 'ASC'"></span>
        <span class="datatable-icon-sort-unset sort-btn top-1" *ngIf="sortItem.fieldName != column.prop"></span>
      </ng-container>
    </div>
    <span class="header-filter-span input-filter-parent" *ngIf="column.searchable">
      <input
        [(ngModel)]="columnFilters[column.prop]"
        (input)="filterInput($event)"
        *ngIf="showColumnSearch"
        class="input-filter"
      />
    </span>
  </ng-template>