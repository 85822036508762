// export namespace Panels {
export class InitPanels {
    static readonly type = '[Global] Init Panels';
    constructor(public panels: any) { }
}
export class UpdatePanels {
    static readonly type = '[Global] Update Panels';
    constructor() { }
}
export class ShowPanel {
    static readonly type = '[Global] Show Panel';
    constructor(public name: string = 'inspector') { }
}
export class HidePanel {
    static readonly type = '[Global] Hide Panel';
    constructor(public name: string = 'inspector') { }
}
export class TogglePanel {
    static readonly type = '[Global] Toggle Panel';
    constructor(public name: string) { }
}
export class ResizeWindow {
    static readonly type = '[Global] Resize Window';
    constructor() { }
}
// }
