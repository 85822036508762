import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'cl-api-user-dialog',
  templateUrl: 'api-user-dialog.component.html',
})
export class ApiUserDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ApiUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  download() {
    var rows = [
      ["User ID", "Password", ]
    ];

    rows.push([this.data.login, this.data.secret]);

    var csvContent = "data:text/csv;charset=utf-8,";

    rows.forEach(function (rowArray) {
      var row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: encodeURI(csvContent),
      download: 'api-user-details.csv'
    }).click();
  }

  // onNoClick(): void {
  //   // this.dialogRef.close();
  // }
}
