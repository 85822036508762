<div class="cl-gateway-summary">
  <div class="page-title"><span>Gateway Details: </span>&nbsp;&nbsp;<span [textContent]="gatewayObject?.attribute?.physicalId"></span></div>
  <div class="row align-items-start pt20 mt20">
    <div class="col-4">
      <img class="h220" [src]="gatewayObject.imageUrl" />
    </div>
    <div class="col-8 summary-props">
      <div class="row align-items-start">
        <div class="col-6 pl0">
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Name: </span><span class="pl5" [textContent]="gatewayObject?.name"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">GW Zone: </span><a class="pl5" href="#/locationdetails/{{gatewayObject?.area?.identifier}}"><span [textContent]="gatewayObject?.area?.name"></span></a></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Model no: </span><span class="pl5" [textContent]="gatewayObject?.attribute?.model"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">SKU: </span><span class="pl5" [textContent]="gatewayObject?.attribute?.sku"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">App Version: </span><span class="pl5" [textContent]="gatewayObject?.attribute?.appVersion"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">HW Version: </span><span class="pl5" [textContent]="gatewayObject?.attribute?.hardwareVersion"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="text-bold">Catalog Type:</span>&nbsp;<span [textContent]="gatewayObject?.properties?.properties?.parentEntityType | titlecase"></span></div>
        </div>
        <div class="col-6 pl0">
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Status: </span><span class="pl5" [textContent]="gatewayObject?.status"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Owner: </span><span class="pl5" [textContent]="gatewayObject?.tenantName"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Updated: </span><span class="pl5" [textContent]="lastUpdated"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Backhaul: </span><span class="pl5 capitalize" [textContent]="gatewayObject?.healthStatus?.backhaulType?gatewayObject?.healthStatus?.backhaulType:gatewayObject?.attribute?.backhaul"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Power: </span><span class="pl5" [textContent]="gatewayObject?.attribute?.power"></span></div>
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Organization: </span><span class="pl5" [textContent]="gatewayObject?.properties?.properties?.organizationName"></span></div>
          <div class="mb-2"><span class="text-bold">Gateway Type:</span>&nbsp;<span [textContent]="gatewayObject?.properties?.properties?.entityType | titlecase"></span></div> 
        </div>
      </div>
      <div class="row align-items-start">
        <div class="col-6 pl0" *ngFor="let cdmField of gatewayObject?.cdmFields | sortByOrder">
          <div class="mb-2">
            <span class="text-bold" [textContent]="cdmField['displayLabel']"></span>:&nbsp;
            <span [textContent]="getExtendedAttributeValue(cdmField, gatewayObject.properties.properties)"></span>
          </div>
        </div>
        <div class="col-6 pl0">
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Created At:</span><span class="pl5" [textContent]="datePipe.transform(gatewayObject?.properties?.properties.createdAt)"></span></div>
        </div>
        <div class="col-6 pl0">
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Modified At:</span><span class="pl5" [textContent]="datePipe.transform(gatewayObject?.properties?.properties.modifiedAt)"></span></div>
        </div>
        <div class="col-6 pl0">
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Created By:</span><span [textContent]="gatewayObject?.properties?.properties.createdBy"></span></div>
        </div>
        <div class="col-6 pl0">
          <div class="rt1 fs14 pt5 pb5"><span class="font-bold">Modified By:</span><span [textContent]="gatewayObject?.properties?.properties.modifiedBy"></span></div>
        </div>
      </div>
  </div>
</div>

