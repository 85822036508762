import {
  Component,
  OnInit,
  AfterViewInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { Observable, Subject, Subscription } from 'rxjs';
import { ApiSubscriptionService } from './api-subscription.service';
import * as _ from 'lodash';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EditCredentialsDialogComponent } from './edit-credentials-dialog/edit-credentials-dialog.component';
import { ToastService } from "@cl/common/services/toast.service";
import { UserService } from "../../../app/user/user.service";
import { PersistenceService } from '@cl/common/services/persistence.service';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'cl-api-subscription',
  templateUrl: './api-subscription.component.html',
  styleUrls: ['./api-subscription.component.scss'],
})
export class ApiSubscriptionComponent implements OnInit, AfterViewInit {
  @ViewChild('subscriberNameTemplate') subscriberNameTemplate: TemplateRef<any>;
  @ViewChild('enableDisableRuleTemplate') enableDisableRuleTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  readonly entityType = 'apiSubscription';

  public sortType: SortType = SortType.single;
  public selection: SelectionType = SelectionType.checkbox;

  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  mainWidth:number;
  tableWidth:any;
  public tableRowData: any = [];
  public rows: any = [];
  public rawColumns: any = [];
  public columns: any = [];
  public selected: any = [];
  public selectedRowArray = [];
  private event = new Subject<any>();
  subscriptionObject: any = {};
  mode: string = '';
  showSummaryPanel: boolean = false;
  editDisabled: boolean = true;
  initialLoad: boolean = true;
  isLoading: boolean = true;
  totalRowCount = 0;
  sidePanelsObj = {
    summary: false,
    create: false,
    edit: false,
    add: false
  };
  activeAction: string = "Pause/Enable";
  pauseDisabled: boolean = true;
  deleteDisabled: boolean = true;
  webhookSecretKey: any;
  selectedRows: any = [];
  isSaveInProgress: boolean = false;
  actionBtnPolicies = {
    add: ['WEBHOOK_EVENT_SUBSCRIPTION_CREATE'],
    edit: ['WEBHOOK_EVENT_SUBSCRIPTION_UPDATE'],
    delete: ['WEBHOOK_EVENT_SUBSCRIPTION_DELETE']
  };
  searchFormGroup: FormGroup;
  searchTerm: string = '';
  totalRows:any;
  constructor(
    private _apiSubscriptionService: ApiSubscriptionService,
    private _utilsService: UtilsService,
    private formateDatePipe: FormateDatePipe,
    private csvExport: CsvExportsService,
    private dialog: MatDialog,
    private toaster: ToastService,
    private userService: UserService,
    private persistenceStore: PersistenceService
  ) {}

  ngOnInit(): void {
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
    this.setContainerWidth();
    const eventSub: Subscription = this.getEvent().subscribe((evt) => {
      if (evt.eventName === 'summary') {
        this.showSummaryPanel = true;
        this.subscriptionObject = evt.subscriptionObject;
      }
    });
    this.getWebhookSecretKey('macid');
  }

  ngAfterViewInit() {
    this.rawColumns = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        checkboxable: true,
        width: 30,
        visible: true,
      },
      {
        name: 'Name',
        prop: 'subscriptionName',
        width: 200,
        cellTemplate: this.subscriberNameTemplate,
        visible: true,
      },
      {
        name: 'Subscription Type',
        prop: 'webhookType',
        width: 200,
        visible: true,
      },
      {
        name: 'Callback URL',
        prop: 'registeredUrls',
        width: 250,
        visible: true,
      },
      // { name: 'Status', prop: 'status', width: 200, visible: true },
      { name: 'Created At', prop: 'createdAt', width: 250, visible: true },
      { name: 'Created By', prop: 'createdBy', width: 230, visible: true },
      { name: 'Modified At', prop: 'modifiedAt', width: 200, visible: true },
      { name: 'Modified By', prop: 'modifiedBy', width: 250, visible: true },
      { name: 'Enable', prop: 'status', minWidth: 60, cellTemplate: this.enableDisableRuleTemplate, visible: true },
    ];
    const { gridColumns, rawColumns } = this._utilsService.updateColumnsList(
      this.entityType,
      [...this.rawColumns]
    );
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];

    this.getApiSubscriptions();
  }
  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    this.tableWidth = this.mainWidth - 12;
  }
  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, this.entityType, this.table._internalColumns);   
  }
  // ngx-data-table
  getId(row) {
    return row.id;
  }

  onSelect(rowData: any) {
    this.selectedRowArray = rowData.selected;
    let rows = [];
    if(this.selectedRowArray.length >=1){
      this.selectedRowArray.forEach(element => {
        rows.push(element?.id)
      });
      this.selectedRows = [...new Set(rows)];
    }
    if (this.selectedRowArray.length != 1) {
      this.showSummaryPanel = false;
    }
    if (this.selectedRowArray.length == 1) {
      this.editDisabled = false;
      this.deleteDisabled = false;
      this.pauseDisabled = false;
    }else if(this.selectedRowArray.length > 1){
      this.editDisabled = true;
    } else {
      this.editDisabled = true;
      this.deleteDisabled = true;
      this.pauseDisabled = true;
    }

    this.subscriptionObject = this.selectedRowArray[0];
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  downloadReport(reportType: string) {
    let formatedSubscriptionData = this.formatDownloadData(this.rows);
    if (reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(
        formatedSubscriptionData,
        _.map(this.columns, 'prop')
      );
      this.csvExport.formatAndDownloadCSVForGrid(
        csvData,
        'api-subscriptions',
        _.map(this.columns, 'name')
      );
    }
  }

  formatDownloadData(data: any[]) {
    let tempsubScriptionData = [...data];
    let dateFieldArray = ['modifiedAt', 'createdAt'];
    tempsubScriptionData.forEach((subscription) => {
      dateFieldArray.forEach((element) => {
        if (subscription[element]) {
          subscription[element] = this.formateDatePipe.transform(
            subscription[element],
            'default'
          );
        }
      });
    });
    return tempsubScriptionData;
  }

  async getApiSubscriptions() {
    if (this.delayedRefreshStart) {
      this.delayedRefreshStart = false;
      this.tableRowData = [];
    }

    this.isLoading = true;
    const data = await this._apiSubscriptionService.getApiSubscriptions();

    this.isLoading = false;
    data.forEach((d) => {
      d.createdAt = d.createdAt
        ? this.formateDatePipe.transform(d.createdAt)
        : '';
      d.modifiedAt = d.modifiedAt
        ? this.formateDatePipe.transform(d.modifiedAt)
        : '';
      d.enabled = (d.status === 'Active')  
    });

    this.rows = [...data];
    this.totalRows = [...data];
    this.totalRowCount = this.rows.length;
    if(!_.isEmpty(this.searchTerm)){
      this.onSearchInput(this.searchTerm);
    }
  }

  searchSubscription(term) {
    const val = term.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.rows];
    if (val) {
      temp = tableRowData.filter(function (d: any) {
        return d.subscriptionName.toLowerCase().indexOf(val) !== -1;
      });
    } else {
      this.getApiSubscriptions();
    }
    this.rows = temp;
    this.onSearchInput(term);
  }

  loadSummaryPanel(subscriptionObject: any, event: any) {
    this.sidePanelsObj.summary = true;
    event.preventDefault();
    this.setEvent({
      eventName: 'summary',
      subscriptionObject: subscriptionObject,
    });
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  closeSidePanel(cbAction?) {
    this.showSummaryPanel = false;
    this.mode = '';
    // this.selected = [];
    // this.editDisabled = true;
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    if(cbAction && cbAction.action == 'apisubscriptions') {
      this.delayedRefreshStart = true;
    }
  }

  async openEditCredentialsDialog(){
    const webhookKey = this._apiSubscriptionService.webhookSecretKey;

    this.dialog.open(EditCredentialsDialogComponent, {
      data: {
        key: webhookKey,
      },
    });
  }

  addApiSubscription(isEdit:boolean){
    this.mode = 'add';
    this.sidePanelsObj.create = !isEdit;
    this.sidePanelsObj.edit = isEdit;
  }

  updateDevice() {
    this.closeSidePanel();
    this.sidePanelsObj.edit = true;
    this.sidePanelsObj.add = false;
    this.mode = 'edit';
    this.editDisabled = false;
  }

  pauseOrEnabled(row){
    this.editDisabled = true;
    this.deleteDisabled = true;
    let APIstatus = (row.enabled === false)? 'InActive' : 'Active';
    let payLoad = {
      "ids": [row.id],
      "status": APIstatus
    }
    this.isSaveInProgress = true;
    this._apiSubscriptionService.updateStatus(payLoad).subscribe(res=>{
      // this.toaster.success(`Status updated successfully`);
      this.isSaveInProgress = false;
    })
  }

  deleteDevice(){
    let message = `Are you sure you want to delete the selected API subscription?`;
    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.editDisabled = true;
        this.pauseDisabled = true;
        this.isLoading = true;
        let payLoad = {
          "ids": this.selectedRows
        }
        this._apiSubscriptionService.deleteSubscriptions(payLoad).subscribe(res=>{
          this.toaster.success(`Selected subscription deleted successfully`);
          this.delayedRefreshStart = true;
          this.isLoading = false;
        })
      }else{
        this.isLoading = false;
      }
  })
  }

  getWebhookSecretKey(val){
    this._apiSubscriptionService.getSubscriberType(val).then(res=>{
      this.webhookSecretKey = res.macId;
    })
  }

  onSearchInput(term) {
    this.searchTerm = term.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.totalRows];
    if(this.searchTerm){
       temp = tableRowData.filter( (d:any)=> {
        return (d.subscriptionName.toLowerCase().indexOf(this.searchTerm) !== -1);
      });
    }else{
      this.getApiSubscriptions();
    }
    this.rows = temp;
  }
}