import { Component, OnInit } from '@angular/core';
import { CustomRoute } from '../../../@types/custom-route.type';
import {Router } from '@angular/router';
import _ from 'lodash';
import { NavService } from '../../shell/nav.service';
@Component({
  selector: 'cl-secondary-header',
  templateUrl: './secondary-header.component.html',
  styleUrls: ['./secondary-header.component.scss']
})
export class SecondaryHeaderComponent implements OnInit{
  activeMenu : string = '';
  groupList: string[] = [];
  isMenuOpen: boolean = false;
  modifiedMenuList: any;
  menuList: CustomRoute[];
  showMenu: boolean;
  activeGroup: string;
  hasUndefinedGroup: boolean = false;
  plainMenuItems: CustomRoute[];
  constructor(private router: Router, private navService: NavService) {
    this.menuList = [];
    this.router.events.subscribe((event: any)=>{
      if(event.toString().indexOf('NavigationEnd')>-1){
        this.setSecondaryMenuList();
      }
    });
  }
  setSecondaryMenuList(){
    let currentRoute : CustomRoute = this.getCurrentRoute();
    this.getSecondaryMenuList(currentRoute);
    this.groupList = _.compact(_.uniq(_.map(this.menuList, 'group')));
    this.modifiedMenuList = _.groupBy(this.menuList, 'group');
    this.showMenu = Object.keys(this.modifiedMenuList).length>0;
    this.hasUndefinedGroup = this.modifiedMenuList.hasOwnProperty('undefined');
    this.plainMenuItems = this.modifiedMenuList['undefined'];
    if(_.isEmpty(this.activeGroup) && !_.isEmpty(currentRoute) && currentRoute.label){
      this.activeGroup = currentRoute.label;
    }
  }
  private getSecondaryMenuList(route: CustomRoute){
    if (!_.isEmpty(route)) {
      this.activeGroup = route.group;
      this.menuList = this.navService.getSideBarMenu(route);
    }
  }
  private getCurrentRoute() {
    let currentPath=this.getUrlWithoutParams().split('/'), currentCustomRoute: any;
    if(this.router.url.indexOf('deviceprofiles')>-1 && this.router.url.indexOf(';')>-1){     
      currentPath[1] = "deviceprofiles"
    }
    if (!_.isEmpty(currentPath[1])) {
      currentCustomRoute = _.find(this.router.config, (routeConfig) => {
        let temp = routeConfig.path.split('/');
        return temp[0].indexOf(currentPath[1]) > -1;
      });
    }
    return currentCustomRoute;
  }

  ngOnInit(): void {
    this.setSecondaryMenuList();
  }

  navigateMenu(menu){
    if(!menu.disabled){
        this.router.navigate([menu.path]);
    }
  }

  getUrlWithoutParams(){
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}; 
    urlTree.fragment = null;
    return urlTree.toString();
 }

}
