import { Injectable } from '@angular/core';
// import deepAssign from 'deep-assign';
// import deepCopy from 'deepcopy';
// import { AttributesService } from './attributes.service';

@Injectable()
export class TemplateService {
    version: 0.5;
    // private _listLibrary;
    private _deviceTemplates;
    constructor() {
        this._deviceTemplates = this.initTemplates();
    }
    // These are now stored in project service
    getTemplates() {
        return this._deviceTemplates;
    }
    getTemplate(type) {
        return this._deviceTemplates[type];
    }
    getAttributeReferences(type, list, listLibrary) {
        list = list.map(item => {
            return listLibrary[type].filter(libItem => {
                return libItem.name.toLowerCase() === item.toLowerCase();
            })[0];
        });
        return list;
    }

    getVersion() {
        return this.version;
    }

    initTemplates() {
        return {
            version: 0.5,
            site: {
                name: 'Site',
                description: 'This is a site',
                template: 'site',
                hierarchy: 3,
                svgIcon: 'site',
                tags: ['site']
            },
            zone: {
                name: 'Zone',
                description: 'This is a zone',
                template: 'zone',
                hierarchy: 5,
                svgIcon: 'gateway',
                tags: ['zone']
            },
            area: {
                name: 'GeoFence',
                description: 'This is a a GeoFence',
                template: 'area',
                hierarchy: 4,
                tags: ['area, GeoFence']
            },
            asset: {
                name: 'Asset',
                description: 'This is an asset',
                template: 'asset',
                hierarchy: 7,
                tags: ['asset']
            },
            sensor: {
                name: 'Sensor',
                description: 'This is an sensor',
                template: 'sensor',
                hierarchy: 6,
                tags: ['sensor']
            },
            gateway: {
                name: 'Gateway',
                description: 'This is a gateway',
                template: 'gateway',
                hierarchy: 5,
                tags: ['gateway']
            },
            rule: {
                description: 'This is a rule',
                template: 'rule',
                hierarchy: 2,
                tags: ['rule']
            },
            connection: {
                description: 'This is an entity connection',
                template: 'connection'
            }
        };
    }
}
