import { Injectable } from '@angular/core';
import { Bisector } from '@cl/models';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  constructor(
  ) {
  }

  configureLink(linkObj, graphData) {
    let newBisector: Bisector;
    let startNode,
      endNode,
      linkAdded = false,
      path = [],
      connectionType;
    if (linkObj.id1 && linkObj.id2) {
      linkObj.sourceID = linkObj.id1;
      linkObj.targetID = linkObj.id2;
    } else {
      linkObj.sourceID = linkObj.startUID ? linkObj.startUID : linkObj.sourceID;
      linkObj.targetID = linkObj.finishUID ? linkObj.finishUID : linkObj.targetID;
    }
    // linkObj.source = linkObj.sourceID;
    // linkObj.target = linkObj.targetID;
    const linkKey = linkObj.sourceID + "__" + linkObj.targetID;
    const exists = graphData.bisectorsObj[linkKey];
    // const exists = graphData.links.filter(item => {

    if (!exists) {
      startNode = graphData.nodesObj[linkObj.sourceID] || {};
      endNode = graphData.nodesObj[linkObj.targetID] || {};
      path = this.getPath(startNode, endNode);

      const linkUID = linkObj.sourceID + "__" + linkObj.targetID;
      if (startNode.template === 'rule') {
        connectionType = 'rule-connection';
      } else if (startNode.hierarchy < endNode.hierarchy) {
        connectionType = 'child-connection';
      } else if (startNode.hierarchy === endNode.hierarchy) {
        connectionType = 'peer-connection';
      } else {
        linkAdded = false;
        return {
          linkObj: linkObj,
          linkAdded: linkAdded
        };
      }
      linkAdded = true;

      newBisector = Object.assign({}, linkObj);

      const sourceX = startNode.x;
      const sourceY = startNode.y;
      const sourceType = startNode.template;
      const targetX = endNode.x;
      const targetY = endNode.y;
      const targetType = endNode.template;

      // const templateType = sourceType + '-' + targetType + '-connection';
      const descriptionText: string =
        'This is a connection from a ' + sourceType + ' to a ' + targetType;
      const bisectorNum: number = graphData.links.length;

      // newBisector.template = templateType;
      newBisector.template = linkObj.template;
      newBisector.description = descriptionText;

      newBisector.id = bisectorNum;
      newBisector.bisectorID = linkUID;
      newBisector.uid = linkUID;
      newBisector.sourceID = linkObj.sourceID || startNode.uid;
      newBisector.targetID = linkObj.targetID || endNode.uid;
      newBisector.path = path;
      newBisector.sourceType = sourceType;
      newBisector.targetType = targetType;
      newBisector.connectionType = connectionType;
      newBisector.x = (sourceX + targetX) / 2;
      newBisector.y = (sourceY + targetY) / 2;
      if (startNode.inGroup) {
        newBisector.groupUID = startNode.groupUID;
        newBisector.inGroup = true;
      }
      // newBisector.sections = [];
      // newBisector.sections[0] = {
      //   type: 'Rules',
      //   formType: 'listWidget',
      //   items: []
      // };
    }
    return { linkObj: newBisector, linkAdded: linkAdded };
  }

  getPath(startNode, endNode) {
    const path = [];
    if (!_.isEmpty(startNode) && !_.isEmpty(endNode) && !_.isEmpty(startNode.locus) && !_.isEmpty(endNode.locus)) {
      path[0] = startNode.locus;
      path[1] = endNode.locus;
    }
    return path;
  }
}
