import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CL_INPUT_DEBOUNCE_TIME } from "@cl/constants";
import { NavigatorFilter, NavigatorFilterList, NavigatorFilterMap, PartialOf } from "@cl/models";
import { IconSvgService } from "../../../common/services/icon-svg.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import _ from "lodash";
import { Subscription } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";
import { FilterBoxComponent } from "../filter-box/filter-box.component";

@UntilDestroy()
@Component({
  selector: "app-filter-list",
  templateUrl: "./filter-list.component.html",
  styleUrls: ["./filter-list.component.scss"],
  host: {
    class: "app-filter-list",
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterListComponent),
      multi: true,
    },
  ],
})
export class FilterListComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChildren(FilterBoxComponent) filterBoxListRef: QueryList<FilterBoxComponent>;
  @Input() filterMap: NavigatorFilterMap;
  @Output() activeFiltersCountChange = new EventEmitter<number>();

  filterList: NavigatorFilterList = [];

  filtersForm: FormGroup = new FormGroup({});

  private updatingFormControlInProgress = false;
  private filtersCountMap: PartialOf<number> = {};

  valueChangesSubscription$: Subscription;

  disabled = false;
  onChange: any = (obj: any) => {};
  onTouch: any = () => {};

  constructor(private iconSvgService: IconSvgService, private fb: FormBuilder) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterMap'] && this.filterMap) {
      this.filterList = this.mapFilterToArray(this.filterMap);
      this.filtersForm = this.prepareFilterForm(this.filterList);
      this.watchForFiltersForm();
    }
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription$) this.valueChangesSubscription$.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * For each domains clears all the selected filter.
   */
  clearAll() {
    for (let i = 0; i < this.filterBoxListRef.length; i++) {
      const filterBox = this.filterBoxListRef.get(i);
      filterBox.clearAll();
    }
  }

  onActiveFilterCountChange(count: number, filterId: string) {
    this.filtersCountMap[filterId] = count;

    let totalFiltersCount = 0;
    _.forOwn(this.filtersCountMap, (val, key) => (totalFiltersCount += val));
    this.activeFiltersCountChange.emit(totalFiltersCount);
  }

  private watchForFiltersForm() {
    if (this.valueChangesSubscription$) {
      this.valueChangesSubscription$.unsubscribe();
      this.valueChangesSubscription$ = null;
    }

    this.valueChangesSubscription$ = this.filtersForm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),
        filter((_) => !this.updatingFormControlInProgress)
      )
      .subscribe((val) => {
        this.onChange(val);
      });
  }

  /**
   * Converts filter map to filter array
   */
  private mapFilterToArray(filterMap: NavigatorFilterMap): NavigatorFilterList {
    const filterList: NavigatorFilterList = [];

    // No filters exists hence return
    if (_.size(filterMap) <= 0) return filterList;

    // For each filters in the filter map create a filter set to be used by UI
    // and push it to the filters list
    _.forOwn(filterMap, (value, key) => {
      const filter: NavigatorFilter = {
        ...value,
        id: key,
        domain: this.iconSvgService.getSVG(key),
        svgIconUrl: "/assets/svgs/cl-circle-icons/" + this.iconSvgService.getSVG(key) + ".svg",
      };

      // Add filter to the filter list
      filterList.push(filter);
    });

    return filterList;
  }

  /**
   * Generates filter form based on the filters list
   */
  private prepareFilterForm(filterList: NavigatorFilterList): FormGroup {
    this.updatingFormControlInProgress = true;

    const formGroup = filterList.reduce((acc, curr) => {
      acc.addControl(curr.id, new FormControl([]));
      return acc;
    }, this.fb.group({}));

    this.updatingFormControlInProgress = false;

    return formGroup;
  }
}
