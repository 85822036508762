<div class="relative">
    <div class="flex justify-end" *ngIf="panel != 'summary'">
        <a href [ngClass]="!assetObject?.custodyList || assetObject?.custodyList.length === 0 ? 'disabled' : ''"
            (click)="downloadCOCCsv($event)">
            <i class="fa fa-download fa-1x "></i> export
        </a>
    </div>
    <div *ngIf="delayedRefreshStart">
        <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
            (refreshList)="delayedRefreshStart = false; getAssetCustody()"></cl-refresh-countdown>
    </div>
    <div *ngFor="let custody of assetObject?.custodyList; let i = index" class="mt-4">
        <div class="flex">
            <div class="list-dot m-2" [ngClass]="[i == 0 ? 'bgcolor20' : 'bgcolor4']"></div>
            <div>
                <div>
                    <span>{{custody.eventTimeFormatted}}</span>
                    <span class="ml10 font-bold">{{custody.stateFormatted}}</span>
                </div>
                <div class="font-12-normal">
                    <span>from {{custody.fromParty}}</span> to <span>{{custody.toParty}} </span>
                    <span *ngIf="custody?.note" [ngClass]="panel === 'summary' ? 'block' : 'inline'">
                        performed by {{custody?.note}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100 p-4 text-center" *ngIf="assetObject?.custodyList && assetObject?.custodyList.length === 0">
        <span>No data to display!</span>
    </div>
</div>