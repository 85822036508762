import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DigitalTwin } from "@cl/models";
import { AppLoadConfigurations, InitMapGroups } from "@cl/ngxs/actions";
import { LvHidePanel, LvTogglePanel } from "@cl/ngxs/actions";
import { LiveMapState, LvPanelsState } from "@cl/ngxs/state";
import { DtApiService } from "../common/services/dt-api.service";
import { DtDataService } from "../common/services/dt-data.service";
import { MapFilterService } from "../common/services/map-filter.service";
import { SearchApiService } from "../common/services/search-api.service";
import { PanelStatesService } from '../common/utils/panel-states.service';
import * as C from "../common/action-list.service";
// import { ChainOfCustodyService } from "@cl/services/chain-of-custody.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import * as _ from "lodash";
import { combineLatest, EMPTY, Observable, of, Subject } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { NavigatorLoadDts, NavigatorLoadFilterConfig } from "./state/navigator.actions";
import { NavigatorState } from "./state/navigator.state";
import { NavigatorStateModel } from "./state/navigator.state.model";


@UntilDestroy()
@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],
})
export class NavigatorComponent implements OnInit, OnDestroy {
  @Select(NavigatorState) navigator$: Observable<NavigatorStateModel>;
  navigatorState: NavigatorStateModel;

  @Select(LvPanelsState) lvPanels$: Observable<string[]>;
  @Select(LiveMapState) lvState$: Observable<string[]>;

  lvClientConfig$: Observable<any>;
  clientMapGroups;
  lv;
  cclv;
  p;
  selectedDt: DigitalTwin;
  loading;
  kpiData;
  tenancyData;
  DTs;
  currentAction;
  metricData;
  activeDomainFilters: number = 0;
  fullScreenMode = false;
  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private _dtDataService: DtDataService,
    private _dtApiService: DtApiService,
    private _panelStatesService: PanelStatesService,
    private _searchApiService: SearchApiService,
    private _mapFilterService: MapFilterService,
    private store: Store,
    private cd: ChangeDetectorRef
    // private chainOfCustodyService: ChainOfCustodyService // Don't remove this its important to load custody default actions
  ) {
    this.lvClientConfig$ = this.store.select(
      (state) => state.client_config.settings.liveMap
    );
    this.store.dispatch(new NavigatorLoadFilterConfig());
    // this.store.dispatch(new AppLoadConfigurations());
}

  ngOnInit(): void {
    this.watchPanels();
    this.watchLVState();
    this.watchCcState();
    this.watchNavigatorState();
    this.loadPage();
    this.cd.detectChanges()
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private watchNavigatorState() {
    this.navigator$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.navigatorState = state;

      if (this.selectedDt?.id !== this.navigatorState?.selectedDtId) {
        this.selectedDt = this.store.selectSnapshot(NavigatorState.selectedDt);
      }
    });
  }

  private watchPanels() {
    this.lvPanels$.pipe(untilDestroyed(this)).subscribe((p: any) => {
      this.p = { ...p };
    });
  }

  /**
   * Trigger events to load digital twins, header analysis cards, filters etc.
   */
  private loadPage() {
    this.store
      .dispatch(new NavigatorLoadDts())
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          console.warn('No DTs loaded', err);
          this.router.navigate(['/home/assets/manage-assets']);
          return EMPTY;
        })
      )
      .subscribe();
    // this.loading = true;

    // this._dtApiService
    //   .getDTs()
    //   .pipe(untilDestroyed(this))
    //   .subscribe(
    //     (data) => {
    //       if (data && data.length) {
    //         this.DTs = data;
    //         data = data.map((dt) => {
    //           if (dt.properties && dt.properties.properties) {
    //             dt.name = dt.properties.properties.name;
    //           }
    //           return dt;
    //         });
    //         this.selectedDt = this.selectedDt || data[0];
    //         // console.log('got dts', data, this.selectedDt);
    //         this.loadDtData(this.selectedDt);
    //         this.loading = false;
    //       } else {
    //         console.warn("No DTs loaded");
    //         this.router.navigate(["/home/assets/manage-assets"]);
    //         this.loading = false;
    //       }
    //     },
    //     (err) => {
    //       console.warn("Get DTs failed", err);
    //       this.loading = false;
    //       this.router.navigate(["/home/assets/manage-assets"]);
    //     }
    //   );
  }

  // /**
  //  * Loads Dt analysis data
  //  */
  // private loadDtData(selectedDt) {
  //   if (!selectedDt) {
  //     return;
  //   }
  //   this.store.dispatch(new NavigatorLoadDtAnalysis(this.selectedDt.id));

  //   this.getTypesForClient();
  // }

  watchCcState() {
    this.lvClientConfig$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((cclv: any) => {
        this.clientMapGroups = cclv?.mapGroups || {};
        this.cclv = cclv || '';
        // this.loadData({ called_from: "cc" });
      });
  }

  watchLVState() {
    this.lvState$.pipe(takeUntil(this.destroyed$)).subscribe((lv: any) => {
      this.lv = { ...lv };
    });
  }

  // loadData(args) {
  //   this.loadDTs();
  // }

  // loadKPIData(selectedDt) {
  //   this._dtApiService
  //     .getKPIData(selectedDt.id)
  //     .pipe(takeUntil(this.destroyed$))
  //     .subscribe((res) => {
  //       this.kpiData = { ...res };
  //       this.tenancyData = this._dtDataService.setTenancyDataFromKPI(this.kpiData["Snapshot"]);
  //     });
  // }

  getCarouselUpdate(message) {
    this.currentAction = message.action;
    // console.log('getCarouselUpdate', message);
    switch (message.action) {
      case C.DT_UPDATED:
        this.selectedDt = this._dtDataService.setSelectedDt(message.value);
        // this.selectedDt.counts = null;
        // Refactor: Commented
        // this.loadDtData(this.selectedDt);

        // this.triggerUpdate();
        break;
    }
  }

  getTypesForClient() {
    let dataGroups = { ...this.clientMapGroups };
    const dataGroupObs = [];

    _.forOwn(dataGroups, (val, key) => {
      let obs = this.loadFilters(key);
      if (obs) {
        dataGroupObs.push(obs);
      }
    });

    combineLatest(dataGroupObs).subscribe((res) => {
      res.forEach((filt) => {
        dataGroups[filt['class']] = filt;
      });
      dataGroups.nodes = [];
      dataGroups.links = [];
      this.initMapGroups(dataGroups);
      this.setMetricData(dataGroups);
    });
  }

  loadFilters(type) {
    switch (type) {
      case 'asset':
        return this._searchApiService
          .getCounts({
            aggField: 'type;sku,status,state',
            clfMappingType: 'asset',
          })
          .pipe(
            catchError((err) => {
              console.warn('error getting asset filters', err);
              return of([]);
            }),
            map((res) => {
              const val = this.parseFilters(res, type);

              /**
               * A monkey patch fix for backend issue, i.e. asset count in the top banner does not
               * matches the count in the filter. Hence grabbing the counts from the top banner
               * and patching the same to the filters.
               */
              if (Array.isArray(this.kpiData?.Snapshot)) {
                const assetItem = (this.kpiData.Snapshot as any[]).find(
                  (f) => f.name === 'Assets'
                );
                val.count = assetItem?.value || val.count;
              }

              return val;
            })
          );
        break;
    }
    return null;
  }
  parseFilters(res, type) {
    let filters = this._searchApiService.parseAggregateCountAsFilter(
      res,
      'asset',
      ['state']
    );
    return {
      baseType: type,
      class: type,
      count: res[0].totalHits,
      data: { nodes: [] },
      filters: filters,
      selectedFilters: {
        count: 0,
      },
    };
  }
  getMonitoredAssets(selectedDt, assetRes) {
    const params =
      '{"scrollId":"","scrollSize":5000,"globalQueryText":"","searchQueries":[{"fieldName":"baseClass","queryText":"Asset","queryOperator":"must","match":"must","childFilters":[{"fieldName":"status","queryText":"monitored","queryOperator":"must"}]}]}';
    this._searchApiService.globalSearch(params).subscribe((res) => {});
  }

  initMapGroups(res) {
    const data = this._dtDataService.mapBaseTypes(res);
    // this.selectedDt.counts = data;

    const mapGroups = this._mapFilterService.getMapGroupsEs(
      data,
      this.clientMapGroups
    );
    // this.initialMapGroups = _.cloneDeep(mapGroups);
    this.store.dispatch(new InitMapGroups(mapGroups, this.selectedDt));
  }
  setMetricData(mapGroups) {
    // if (this.lv && _.size(this.lv.mapGroups)) {
    this.metricData = this._dtDataService.setMetricData(this.lv.mapGroups);
    // }
  }


  toggleFullscreen(changeRoute = true) {
    this.fullScreenMode = !this.fullScreenMode;

    if (this?.p?.carousel) {
      this.store.dispatch(new LvHidePanel('inspector'));
    }
    this.store.dispatch(new LvTogglePanel('carousel'));

    if (changeRoute) {
      const queryParams: any = {};
      if (this.fullScreenMode) queryParams.fullScreen = true;

      this.router.navigate([], { queryParams });
    }
  }
}
