import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { NotificationService } from '../notification.service';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { ActivatedRoute,Router,Event } from '@angular/router';
import { UtilsService } from '@cl/common/utils/utils.service';
import { UserService } from '@cl/user/user.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { NavigationStart, NavigationError, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Store } from "@ngxs/store";
import { AppGetNotifications } from "@cl/ngxs/actions";
import { PersistenceService } from '@cl/common/services/persistence.service';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'cl-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss']
})
export class NotificationListComponent implements OnInit, AfterViewInit {
  // @ViewChild('notificationListTemplate') notificationListTemplate: TemplateRef<any>;
  @ViewChild('notificationTable') notificationTable;
  public tableRowData: any = [];
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  actionCount: number = 0;
  filters: any[] = [];
  selectedNotificationFilter: any[] = [];
  queryFilter: string;
  mainWidth: any;
  tableWidth: number;
  columnKeys: string[] = [];
  filterArray = [];
  excelHeader = ['Name', 'Timestamp', 'Status', 'Type', 'Severity', 'Rule', 'Message'];
  ranges = {
    "Last 24 Hours": [moment().subtract(24, "hours"), moment()],
    "Last 7 Days": [moment().subtract(7, "days"), moment()],
    "Last 30 Days": [moment().subtract(30, "days"), moment()]
  };
  selectedDateRange: { startDate: moment.Moment, endDate: moment.Moment };
  searchText: string = '';
  totalRowCount = 0;
  initialLoad: boolean = true;
  finalFilterList:any = [];
  finalFilterPayload = {};
  updateCount : boolean = false;
  isDateUpdate : boolean = true;
  isShowTable: boolean = true
  breadCrumbItems: BreadCrumbItem[] = [
    { name: 'Previous', isBack: true, path: null },
    { name: 'Notifications', path: null },
  ];
  isReadAll = false;
  isSecondTimeFetched = false;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private notificationService: NotificationService,
    private el: ElementRef,
    private csvExport: CsvExportsService,
    private route: ActivatedRoute,
    private _utilsService: UtilsService,
    private userService: UserService,
    private formateDatePipe: FormateDatePipe,
    private router : Router,
    private store : Store,
    private persistenceStore: PersistenceService
  ) {
    const routeSub: Subscription = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((event: Event) => {
      this.selectedNotificationFilter = [];
      this.finalFilterPayload = {};
      this.finalFilterList = [];
      this.queryFilter = this.route.snapshot.params['filter'];
      if(this.queryFilter){
        this.updateCount = true;
        this.isDateUpdate  = false;
      }
      this.selectedDateRange = { startDate: moment().subtract(7, 'days'), endDate: moment() };
      this.getNotificationFilter();
      this.setContainerWidth();
    });
    if(this.initialLoad){
      this.selectedNotificationFilter = [];
      this.finalFilterPayload = {};
      this.finalFilterList = [];
      this.queryFilter = this.route.snapshot.params['filter'];
      if(this.queryFilter){
        this.updateCount = true;
        this.isDateUpdate  = false;
      }
      this.selectedDateRange = { startDate: moment().subtract(7, 'days'), endDate: moment() };
      this.getNotificationFilter();
      this.setContainerWidth();
    }
   this.subscriptions.push(routeSub);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.table()
  }
  table(){
    this.rawColumns = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true
      },
      { name: 'Name', prop: 'objectName', width: 180, visible: true },
      { name: 'Timestamp', prop: 'formattedTime', width: 150, visible: true },
      { name: 'Status', prop: 'resolved', width: 150, visible: true },
      { name: 'Type', prop: 'type', width: 150, visible: false },
      { name: 'Severity', prop: 'severity', width: 150, visible: true },
      { name: 'Rule', prop: 'rulesetRule', width: 200, visible: true },
      { name: 'Message', prop: 'subject', width: 200, visible: true },
    ];
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('notification', [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name } });
    this._changeDetectorRef.detectChanges();
  }
  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 260;
    } else {
      this.tableWidth = this.mainWidth - 20;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  datesUpdated(datesUpdated) {
    this.selectedDateRange = datesUpdated;
    if(this.isDateUpdate){
      this.createPayloadAndReloadNotificationList();
    }
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'notification', this.notificationTable._internalColumns);   
  }

  private getNotificationList(payLoad): void {
    this.isLoading = true;
    let groupData: any = [];
    const notificationListDataSubscription: Subscription = this.notificationService.postNotificationList(payLoad).subscribe((data: any[]) => {
      this.isReadAll = !data.some(item => item?.status == 'UNREAD');
      if(!this.isReadAll && !this.isSecondTimeFetched){
        this.isSecondTimeFetched = true;
        this.getNotificationFilter();
        this.setContainerWidth();
        return null;
      }
      data.forEach(element => {
        if(element.time){
          element.formattedTime = this.formateDatePipe.transform(element.time, 'default');
        }
      });
      this.rows = [...data];
      this.tableRowData = [...this.rows];
      this.isLoading = false;
      if(this.updateCount){
        this.updateNotifications();
        this.isDateUpdate = true;
      }
      if(this.initialLoad == true){
        this.totalRowCount = this.rows.length;
        this.initialLoad = false;
      }
    }, (error) => {
      console.error(error);
      this.isLoading = false;
    });

    this.subscriptions.push(notificationListDataSubscription);
  }
  fnCreateFilters(filterData){
    this.filterArray = [];
    let initialListObject: any = {};
    let innerListObject: any = {};
    let finalListObject: any = {};
    for (const [key, value] of Object.entries(filterData)) {
      if(key === 'violations'){
        for (const [innerKey, innerValue] of Object.entries(value)) {
          innerValue['filterType'] = key;
        }
        innerListObject = value;
      } else {
        for (const [innerKey, innerValue] of Object.entries(value)) {
          innerValue['filterType'] = key;
        }
        initialListObject = Object.assign({},initialListObject,value);
      }
    }
    finalListObject = {
      ...initialListObject,
      ...innerListObject
    };
    let listArray = [];
    for (const [outerkey, filterValue] of Object.entries(finalListObject)) {
        listArray = [];
        if(finalListObject[outerkey].length){
          finalListObject[outerkey].forEach((d: any) => {
            listArray.push(new Object({
              'checked': (d.key === this.queryFilter) ? true : false,
              'name': d.label,
              'key': d.key
            }));
          });
          this.filterArray.push(new Object({
            'expand': true,
            'collapsed': false,
            'enableCollapsing': false,
            'filterType': finalListObject[outerkey].filterType,
            'label': outerkey,
            'type': 'multiselect',
            'list': listArray
          }));
        }
    };
    this.filters = [...this.filterArray];
    if(this.queryFilter){
      this.fnGetFilterData(this.queryFilter);
    }
  };
  private getNotificationFilter(): void {
    this.isLoading = true;
    const notificationFilterDataSubscription: Subscription = this.notificationService.getNotificationFilters().subscribe((data: any) => {
      if(!_.isEmpty(data)){
        this.fnCreateFilters(data);
      }
    }, (error) => {
      console.error(error);
      this.isLoading = false;
    });
    this.subscriptions.push(notificationFilterDataSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }


  onSelect(rowData: any) {
    console.log('rowData', rowData);
  }

  downloadReport(reportType: string) {
    if (reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Notifications-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    this.searchText = val;
    this.createPayloadAndReloadNotificationList();
  }

  toggleFilterPanel() {
    this.isShowTable = false
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
    this.table();
    this.isShowTable = true;
  }

  getPanelUpdate(event: any) {
    this.updateCount = false;
    if (event.action && event.action == "clearFilters") {
      this.selectedNotificationFilter = [];
      this.finalFilterPayload = {};
      this.finalFilterList = [];
      let initialFilters = _.cloneDeep(this.filterArray);
      initialFilters.forEach((filterItem) => {
        filterItem.list = filterItem.list.map((item) => {
          item.checked = false;
          return item;
        });
      });
      this.filters = [...initialFilters];
    } else {
      let filterEvent = event.filter;
      this.createFilterOptions(filterEvent.checked,event,false);
    }
    this.createPayloadAndReloadNotificationList();
  }
  
  createFilterOptions(checked,currentData,routeParam){
    let currentFilterName  = currentData.filter.filterGroupName || currentData.filterType;
    let index = _.findIndex(this.finalFilterList,{'filterType':currentFilterName});
    if(index === -1 || routeParam){
      if(routeParam){
        this.finalFilterList = [];
      }
      let list = [];
      list.push(currentData.filter.key);
      this.finalFilterList.push(new Object({
        'filterType': currentData.filter.filterGroupName,
        'filterList': list
      }));
    } else {
      if(checked){
        this.finalFilterList[index]['filterList'].push(currentData.filter.key);
      } else {
        let innerIndex = _.findIndex(this.finalFilterList[index]['filterList'], (e) => {
            return e == currentData.filter.key;
        }, 0);
        this.finalFilterList[index]['filterList'].splice(innerIndex,1);
      }
    }
    this.finalFilterList = _.uniqBy(this.finalFilterList, 'filterType');
    this.finalFilterList.forEach(element => {
        let key = element.filterType;
        let value = _.uniq(element.filterList).join();
        let newObject = { [key] : value};
        this.finalFilterPayload =  Object.assign({},this.finalFilterPayload,newObject)
    });
    this.createPayloadAndReloadNotificationList();
  }
  fnGetFilterData(currentFilter){
    let data,obj;
    this.filters.forEach((element,key) => {
        data =  _.find(element.list,{'key': currentFilter});
       if(!_.isEmpty(data)){
         obj = {
          "name": data.name,
          "checked": true,
          "action": "filterByType",
          "searchTerm": "",
          "toggleFilter": true,
          "filter": {
              "checked": true,
              "name": data.name,
              "key": data.key,
              "filterGroupName": element.filterType
          }
        }
        this.createFilterOptions(true,obj,true);
       }
    });
   
  }
  createPayloadAndReloadNotificationList() {
    let payLoad = new Object({
      "from": this.selectedDateRange.startDate.valueOf(),
      "to": this.selectedDateRange.endDate.valueOf(),
      "fromPage": 0,
      "pageSize": 50,
      "markRead": !this.isReadAll,
      "freeText": this.searchText,
      "userId": this.userService.getUserId()
    });
    payLoad = Object.assign({},payLoad,this.finalFilterPayload);
    this.getNotificationList(payLoad);
  }

  updateNotifications(){
    setTimeout(() => {
      this.store.dispatch(new AppGetNotifications());
      this.updateCount = false;
   },1500)
  }
}
