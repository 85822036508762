import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "@cl/core";
import { DigitalTwinGraph, ESSearchResults, PartialOf } from "@cl/models";
import * as _ from "lodash";
import { EMPTY, Observable, Subject } from "rxjs";
import { delay, distinctUntilChanged, map, retryWhen, shareReplay, tap } from "rxjs/operators";
import { GraphResponseDT } from "./graph-response-dt";
import { ConfigService } from "./config.service";
import { SearchApiService } from "./search-api.service";

// import { CountsResponse } from '@cl/models';
const CACHE_SIZE = 1;
@Injectable({
  providedIn: "root",
})
export class DtApiService {
  selectedDTUpdated$ = new Subject<any>();
  public cors_api_url = `${"https://cors-anywhere.herokuapp.com/"}`;

  mapData$: Observable<any>[] = new Array();
  mapNoData$: Observable<any>[] = new Array();
  kpiData$: Observable<any>[] = new Array();
  dts$;
  selectedDt;
  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private _configService: ConfigService,
    private _searchApi: SearchApiService
  ) {}

  get headers() {
    return new HttpHeaders({
      token: this.config.token,
    });
  }

  private get config() {
    return this.env.clfGraphApp;
  }

  unsubAll() {
    if (this.mapData$ && this.mapData$.length) {
      this.mapData$.forEach((md) => {
        md = EMPTY;
      });
    }
    if (this.dts$) {
      this.dts$ = EMPTY;
    }
  }
  getDTs(): Observable<any> {
    if (!this.dts$) {
      this.dts$ = this.requestDTs().pipe(
        shareReplay(CACHE_SIZE),
        tap((dts) => {
          if (Array.isArray(dts) && dts.length > 0) this.updateSelectedDt(dts[0]);
        })
      );
    }
    return this.dts$;
  }

  getDtData(digitalTwinId: string = "", params: PartialOf<string> = {}): Observable<DigitalTwinGraph> {
    const url = this.config.baseUrl + "/api/1/digitaltwin/data" + (digitalTwinId ? `/${digitalTwinId}` : "");
    return this.http.get<DigitalTwinGraph>(url, { headers: this.headers, params }).pipe(
      map((res) => {
        if (params['object'] === "location") {
          res.edges = res.edges.filter((n) => n.baseType === "Network");
          res.nodes = res.nodes.filter((n) => n.baseType === "Location");
        }

        return res;
      })
    );
  }

  searchDtData(params: any): Observable<DigitalTwinGraph> {
    return this._searchApi.elasticSearchWithBounds(params, false).pipe(
      map((res: ESSearchResults<any>) => ({
        edges: [],
        nodes: res.hits.map((m) => ({ ...m, properties: { ...m } })) ?? [],
      }))
    );
  }

  private requestDTs() {
    const url = this.config.url + "digitaltwin";
    return this.http.get<any>(url);
  }

  updateSelectedDt(selectedDt) {
    this.selectedDt = selectedDt;
    this.selectedDTUpdated$.next(selectedDt);
  }

  getToken(id, args) {
    let token = id;
    _.forOwn(args, (val, key) => {
      if (val) {
        token += "_" + key;
      }
    });
    return token;
  }
  getDtMapData(id, args, page, useCache = false): Observable<any> {
    args.loadAssets = args.loadAssets || false;
    const token = id + "_" + page;
    if (!useCache || !this.mapData$ || !this.mapData$[token]) {
      this.mapData$[token] = this.requestDtMapData(id, args).pipe(shareReplay(CACHE_SIZE));
    }
    return this.mapData$[token];
  }
  private requestDtMapData(id, args): Observable<GraphResponseDT> {
    /*let headers = new HttpHeaders({
      token: this.config.token,
    });*/

    const url =
      this.config.url +
      "digitaltwin/domain/" +
      id +
      "?dataFlag=" +
      args.data +
      "&assetFlag=" +
      args.loadAssets +
      "&locationFlag=" +
      args.location +
      "&connectedNodes=" +
      args.connectedNodes +
      "&keylinesformat=false";
    return this.http.get<GraphResponseDT>(url).pipe(
      map((res) => {
        return new GraphResponseDT().deserialize(res);
      })
      // ,
      // retryWhen(errors => errors.pipe(delay(1000), distinctUntilChanged()))
    );
  }
  getDtMapDataParams(id, args, useCache = false): Observable<any> {
    const token = this.getToken(id, args);
    if (!useCache || !this.mapNoData$ || !this.mapNoData$[id]) {
      this.mapNoData$[token] = this.requestDtMapDataParams(id, args).pipe(shareReplay(CACHE_SIZE));
    }
    return this.mapNoData$[id];
  }
  private requestDtMapDataParams(id, args): Observable<GraphResponseDT> {
/*    let headers = new HttpHeaders({
      token: this.config.token,
    });*/
    const url =
      this.config.url +
      "digitaltwin/domain/" +
      id +
      "?dataFlag=" +
      args.data +
      "&locationFlag=" +
      args.location +
      "&connectedNodes=" +
      args.connectedNodes +
      "&keylinesformat=false";
    return this.http.get<GraphResponseDT>(url).pipe(
      map((res) => {
        return new GraphResponseDT().deserialize(res);
      })
      // ,
      // retryWhen(errors => errors.pipe(delay(1000), distinctUntilChanged()))
    );
  }
  getKPIData(id, useCache = false): Observable<any> {
    if (!useCache || !this.kpiData$ || !this.kpiData$[id]) {
      this.kpiData$[id] = this.requestKPIData(id).pipe(shareReplay(CACHE_SIZE));
    }
    return this.kpiData$[id];
  }
  requestKPIData(id): Observable<GraphResponseDT> {
    let headers = new HttpHeaders({
      //token: this.config.token,
      "Content-Type": "application/json",
    });
    const url = this.config.url + "digitaltwin/analysis/" + id;

    let retryCount = 1;
    return this.http.post<any>(url, {}, { headers: headers }).pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(1000),
          distinctUntilChanged(),
          tap((err) => {
            if (retryCount > 5) throw new Error("Failed to load analysis");
            retryCount++;
          })
        )
      )
    );
  }
  // getDomainQuestions(): Observable<any> {
  //   let headers = new HttpHeaders({
  //     "token": this.config.token
  //   });

  //   const url = this.config.url + "digitaltwin/analysis/question";
  //   return this.http
  //     .get<any>(url, { headers: headers });
  // }
  // getDomainQuestion(id, klFormat, fieldName, type): Observable<any> {
  //   let headers = new HttpHeaders({
  //     "token": this.config.token
  //   });

  //   const url = this.config.url + "digitaltwin/process/question/" + id + "?keyinesformat=" + klFormat + "&fieldName=" + fieldName + "&entityClass=" + type;
  //   return this.http
  //     .get<any>(url, { headers: headers });
  // }
}
