<div *ngIf="isLoading" class="relative w-100 h-[200px] p-4 text-center">
    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
</div>
<div *ngIf="!isLoading" class="w-100">
<div *ngFor="let visit of assetObject?.visits" class="mb-4" [ngClass]="{'ml-4': visit?.isSubRegion}" >
    <div class="font-bold fs16 mb-2">{{visit.dwellTime}}</div>
    <div class="fs14">{{visit.regionName}}</div>
</div>
<div class="w-100 p-4 text-center" *ngIf="assetObject?.visits && assetObject?.visits?.length === 0">
    <span>No data to display!</span>
</div>
</div>
