<div class="user-summary-panel">
  <cl-side-panel (closePanel)="closePanel()">
    <section class="cl-side-panel-title side-panel-header-bg">
      <span *ngIf="mode === 'edit'" class="black font-bold">Edit Tenant</span>
      <span *ngIf="mode === 'add'" class="black font-bold">Add Tenant</span>
      <span class="close-icon" (click)="closePanel()">
        <i class="fa fa-times-circle-o mr-4"></i>
      </span>
    </section>
    <section class="cl-side-panel-content" *ngIf="mode === 'edit' || mode === 'add'">
      <form [formGroup]="tenantForm">
        <div class="user-detail">
          <div class="pt-5">
            <div class="user-detail__item py-0">
              <div class="form-label font-bold">Tenant Info:</div>
              <div class="form-label">ID</div>
              <input type="text" class="basic-input" formControlName="identifier">
              <label class="invisible">&nbsp</label>
            </div>
            <div class="user-detail__item py-0">
              <div class="form-label">Name<span class="required">*</span></div>
              <input type="text" class="basic-input" placeholder="Enter name" formControlName="name">
              <label class="red"
                [ngClass]="(tenantForm.controls['name'].invalid && (tenantForm.controls['name'].dirty || tenantForm.controls['name'].touched)) ? 'visible' : 'invisible'">
                Please enter name!
              </label>
            </div>
            <div class="user-detail__item py-0">
              <div class="form-label">Status<span class="required"></span></div>
              <mat-select formControlName="status">
                <mat-option *ngFor="let sts of statusEnums;" [value]="sts">{{sts}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div *ngIf="mode === 'add'" class="pt-5">
            <div class="user-detail__item py-0">
              <div class="form-label font-bold">Admin User:</div>
              <div class="form-label">Admin ID<span class="required">*</span></div>
              <input type="text" class="basic-input" placeholder="Enter ID" formControlName="id">
              <label class="red"
                [ngClass]="(tenantForm.controls['id'].invalid && (tenantForm.controls['id'].dirty || tenantForm.controls['id'].touched)) ? 'visible' : 'invisible'">
                Please enter ID!
              </label>
            </div>
            <div class="user-detail__item py-0">
              <div class="form-label">Admin Primary Email<span class="required">*</span></div>
              <input type="email" class="basic-input" placeholder="Enter Email" formControlName="email">
              <label class="red"
                [ngClass]="(tenantForm.controls['email'].invalid && (tenantForm.controls['email'].dirty || tenantForm.controls['email'].touched)) ? 'visible' : 'invisible'">
                Please enter valid email!
              </label>
            </div>
            <div class="user-detail__item py-0">
              <div class="form-label">Name<span class="required">*</span></div>
              <input type="text" class="basic-input" placeholder="Enter Name" formControlName="userName">
              <label class="red"
                [ngClass]="(tenantForm.controls['userName'].invalid && (tenantForm.controls['userName'].dirty || tenantForm.controls['userName'].touched)) ? 'visible' : 'invisible'">
                Please enter name!
              </label>
            </div>
          </div>
        </div>
      </form>
    </section>
    <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
      <div class="flex">
        <div class="flex-col grow fs11 text-right">
          <a class=" m-2" (click)="closePanel()">Cancel</a>
          <button *ngIf="mode === 'add'" class="panel-button white bgcolor4"
            [disabled]="isSaveInProgress || !tenantForm.valid" (click)="saveTenant()">save
            <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
          </button>
          <button *ngIf="mode === 'edit'" class="panel-button white bgcolor4"
            [disabled]="isSaveInProgress || !tenantForm.valid" (click)="updateTenant()">update
            <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
          </button>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>
