import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { CdmField } from '@cl/@types/asset.type';
import { Sensor, SensorBasicInformationProperties } from '@cl/@types/sensor.type';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { SensorUtilService } from '../../../sensor-fabric/sensor/sensor-util.service';
import { GatewayUtilService } from '../../../sensor-fabric/gateway/gateway-util.service';
import { Gateway } from '@cl/@types/gateway.type';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';

@Component({
  selector: 'cl-device-summary',
  templateUrl: './device-summary.component.html',
  styleUrls: ['./device-summary.component.scss']
})
export class DeviceSummaryComponent implements OnInit, OnChanges {
  @Input() sensorId: any;
  @Input() sensorType: any;
  @Input() device: any;
  @Output() closeSidePanel = new EventEmitter();
  // @Input('sensor') sensorObject: Sensor;
  sensorObject: Sensor;
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  activePane: string = 'summary';
  loading: boolean;
  gatewayObject: Gateway;
  deviceObj:any;
  detailsUrl:string;
  isTracker: boolean = false;
  isGateway: boolean = false

  constructor(public datePipe : FormateDatePipe,
    private sensorUtilService: SensorUtilService, private gatewayUtilService: GatewayUtilService,
    private _dynamicFormService: DynamicFormService) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void{
    if(this.sensorType.toLowerCase().includes('tracker')){
      this.isTracker = true;
    }
    if (this.sensorId && this.sensorType !== 'Route') {
      this.loading = true;
      if(this.device.baseTypeKey == 'gateway'){
        this.detailsUrl = 'gatewaydetail'
        this.getGatewayObj();
        this.isGateway = true;
      }else{
        this.detailsUrl = this.isTracker ? 'trackerdetails' : 'sensordetails';
        this.isGateway = false;
        this.getSensorObj();
      }
    } 
  }

  closePanel() {
    this.closeSidePanel.emit();
  }

  generateZoneForSensor() {
    let zone = '';
    const { zoneName, locationName } = this.deviceObj.properties;
    if (zoneName && locationName) {
      if (zoneName === locationName) zone = locationName;
      else zone = locationName + ' , ' + zoneName;
    } else if (zoneName && !locationName) zone = zoneName;
    else if (!zoneName && locationName) zone = locationName;
    this.device.zoneName = zone;
  }

  getSensorObj() {    
    this.sensorUtilService.getSensor(this.sensorId, this.device?.entityTypeKey)
      .then((sensor: Sensor) => {
        if(sensor !== null){
          this.sensorObject = sensor;
          this.deviceObj = this.sensorObject;
          
          if(this.sensorType === 'Sensor'){
            this.generateZoneForSensor()
          }
          if(this.deviceObj?.cdmFields){
            this.deviceObj.cdmFields = this._dynamicFormService.orderFields(this.deviceObj.cdmFields, 'order')
          }
          this.loading = false;
        }
      })
  }

   getExtendedAttributeValue(cdmField: CdmField, properties: SensorBasicInformationProperties){
    if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
      if(cdmField?.type?.toUpperCase()==='DATE'){
        let data = properties[cdmField.name];
        return this.datePipe.transform(data, 'default');
      } else {
        return properties[cdmField.name];
      }
    }else{
      return '';
    }
  }

  getGatewayObj(){
    this.gatewayUtilService.getGatewayObject(this.sensorId, this.device.entityTypeKey)
    .then((result: Gateway) => {
      if(result !== null){
        this.gatewayObject = result;
        this.deviceObj = this.gatewayObject;
        if(this.deviceObj?.cdmFields){
          this.deviceObj.cdmFields = this._dynamicFormService.orderFields(this.deviceObj.cdmFields, 'order')
        }
        this.loading = false;
      }
    })
  }
}
