<div class="mt-2 mb-2">
  <span class="page-title">Admin - Roles</span>
</div>
  <cl-table-surtitle [rows]="rows.length" [selected]="selected.length" [total]="totalRowCount" [pageName]="'Roles'"></cl-table-surtitle>
<div class="wt-ngx-grid-wrapper relative list-page px-2">
  <div class="flex justify-between w-calc-60 pt-1 pb-1">
    <div class="md-add-button list" [clIsValidAction]="actionBtnPolicies.add" *ngIf="!showSummaryPanel">
      <div class="add-button" (click)="addRole()">
        <span class="fa fa-plus"></span>
      </div>
    </div>
    <div class="action-panel-left self-start flex items-center">
      <cl-list-search [placeholder]="'Search by Role Name'" (search)="updateFilter($event)"
                [columns] = "['Role Name']"></cl-list-search>
      <button [disabled]="editDisabled" [clIsValidAction]="actionBtnPolicies.edit"
        [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
        class="p-4-10 border-general br2 mr-2" (click)="editRole()">
        <i class="fa fa-pencil"></i>
        Edit
      </button>

      <button [disabled]="editDisabled"
        [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
        [clIsValidAction]="actionBtnPolicies.delete" class="p-4-10 border-general br2 mr-2"
        (click)="deleteRole()">
        <i class="fa fa-trash"></i>
        Delete
        <span class="fa fa-circle-o-notch fa-pulse" *ngIf="isLoading"></span>
      </button>

      <button [disabled]="shareDisabled"
        [ngClass]="[shareDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
        [clIsValidAction]="actionBtnPolicies.add" class="p-4-10 border-general br2 mr-2 relative"
        (click)="toggleContainer()" data-toggle="collapse">Share
      </button>

      <div id="shareRoles" class="share-roles" *ngIf="showTenants">
        <div class="share-roles__header">Share with Available Tenancies
          <span (click)="toggleContainer()" class="close-icon fa fa-times-circle-o"></span>
        </div>
        <input type="text" class="share-roles__input" placeholder="Search tenancies" (input)="searchTenancies($event)"/>
        <mat-checkbox *ngIf="tenants?.length>0" [checked]="selectAll" [indeterminate]="selectSome()" (change)="checkAll($event.checked)">
          <label class="ml10">Select All</label>
        </mat-checkbox>
        <div class="share-roles__container">
          <div class="share-roles__list">
            <div *ngFor="let tenant of tenants">
              <section *ngIf="tenant.tenantName">
                <mat-checkbox [(ngModel)]="tenant.checked" (ngModelChange)="updateAllComplete()">
                  <label class="ml10">{{tenant.tenantName}}</label>
                </mat-checkbox>
              </section>
            </div>
          </div>
        </div>
        <div class="share-roles__apply">
          <a (click)="applyRules()">Apply<i class="fa fa-circle-o-notch fa-pulse ml-1" *ngIf="isShareLoading"></i></a>
        </div>
      </div>
    </div>
    <div class="user__action-panel-right action-panel-right self-end flex items-center mr10 mb10">
      <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
      <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
      [page]="'role'">
      </cl-grid-column-toggle>
    </div>
  </div>
  <div class="h-calc-60">
    <main class="h-100 overflow-y-hidden">
      <div class="grid-container h-100 grow relative">
        <div *ngIf="isLoading">
          <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
        </div>
        <div class="h-100 w-100">
          <ngx-datatable class="h-100" [rows]="rows" [columns]="columns" [columnMode]="'force'"
            [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight" [scrollbarV]="true"
            [selected]="selected" [rowIdentity]="getId" [sortType]="sortType" [selectionType]="selection"
            [selectAllRowsOnPage]="false" (reorder) = "columnReordered($event)" (select)='onSelect($event)'[reorderable]="false" #table>
          </ngx-datatable>
        </div>
      </div>
    </main>

        <ng-template #userRoleNameTemplate let-row="row" let-value="value" let-i="index">
            <a class="font-12-bold" href (click)="loadSummaryPanel(row, $event)">{{value}}</a>
        </ng-template>
    </div>

  <div class="summery-pannel" *ngIf="showSummaryPanel == true">
    <cl-role-side-panel (closeSidePanel)="closeSidePanel()" [roleObject]="roleObject" [mode]="mode">
    </cl-role-side-panel>
  </div>

</div>
