<div class="mt-2 mb-2">
    <span class="page-title">Analytics - Library</span>
</div>
<div class="mb-1 list-page-count" *ngIf="rows.length > 0">Showing <span class="font-bold">{{rows.length}}</span> of
    {{rows.length}} dashboards</div>
<div class="wt-ngx-grid-wrapper relative list-page">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list"  *ngIf="!showSummaryPanel && isSuperAdmin">
            <div class="add-button" (click)="addDashboard()">
                <span class="fa fa-plus"></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer list-page_filter-toggle color4 flex items-center">
                <img class="filter-img mr-2 ml-2" src="assets/svgs/filter-options.svg" />filter options</span>
            <input type="text" class="list-page__search-field" placeholder="Search by Dashboard Name"
                (input)="updateFilter($event)" />
            <button [disabled]="selected.length != 1 " *ngIf="isSuperAdmin"
                [ngClass]='[selected.length != 1 ? "color37 bcolor36 bgcolor38" : "color4 bcolor4 bgcolor32"]'
                class="p-4-10 border-general  br2 mr-2" (click)="editDashboard()">Edit
            </button>
            <button [disabled]="selected.length != 1 " *ngIf="isSuperAdmin"
                [ngClass]='[selected.length != 1 ? "color37 bcolor36 bgcolor38" : "color4 bcolor4 bgcolor32"]'
                class="p-4-10 border-general br2 mr-2" (click)="deleteDashboard()">Delete
                <span class="fa fa-circle-o-notch fa-pulse" *ngIf="isDeleteLoading"></span>
            </button>
        </div>
        <div class="user__action-panel-right action-panel-right self-end flex items-center mr10 mb10">
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)">
            </cl-grid-column-toggle>
        </div>
    </div>

    <div class="h-calc-40">
        <main class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth - 10">
            <div class="grow-0 nav panel scroll-y" *ngIf="showFilterPanel">
                <cl-list-filter class="policy__listing__filter" [hideTitle]="true" [showCounts]="false" [title]="''"
                    heightClass="none" (panelsEmitter)="getPanelUpdate($event)"
                    (toggleFilterPanel)="toggleFilterPanel()" [filters]="filters" [actionCount]="actionCount">
                </cl-list-filter>
            </div>
            <div class="grid-container grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>

                <div class="h-100" #tableWrapper [style.width.px]="tableWidth">
                    <ngx-datatable class="h-calc-40" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" [rowIdentity]="getId" [sortType]="sortType"
                        [selectionType]="SelectionType.checkbox" [selectAllRowsOnPage]="false"
                        (select)='onSelect($event)'>
                    </ngx-datatable>
                </div>
                <ng-template #dashboardIdTemplate let-row="row" let-value="value" let-i="index">
                    <a class="font-12-bold" title="{{value}}" href
                        (click)="loadDashboardSummary(row, $event)">{{value}}</a>
                </ng-template>

                <ng-template #isHomePageTemplate let-row="row" let-value="value" let-i="index">
                    <div class="text-center ">
                        <mat-radio-button name="test" [disabled]="!row.disableAnalyticsHome" title="{{row.title}}"
                            (change)="loadSetHomePage(row, $event)" value="value" [checked]="value"></mat-radio-button>
                    </div>
                </ng-template>
            </div>
        </main>

    </div>

    <div *ngIf="sidePanelSummaryObj">
        <cl-library-summary [dashBoard]="selectedDashboard"
            (closeLibrarySummarySidePanel)="closeLibrarySummarySidePanel()">
        </cl-library-summary>
    </div>

    <div class="policy-list summery-pannel" *ngIf="showSummaryPanel === true">
        <cl-library-side-panel [dashboardObject]="SelectedDashboard" [mode]="mode" (closeSidePanel)="closeSidePanel()">
        </cl-library-side-panel>
    </div>
</div>
