<div class="popover-main">
    <div class="row">
        <div class="col-sm-10">
            <span class="pop-head">
                Share Shipment Tracking Info</span>
        </div>
        <div class="col-sm-2 close">
            <span (click)="onClose()" class="fa fa-times-circle-o"></span>
        </div>
    </div>
    <div class="pop-sub">
     <mat-radio-group (change)="onchange($event)" >
        <mat-radio-button name="chec" [disabled]="isDTPuser && isDTPShipment"  [checked]="completeShipment" [value]="'complete'"><span class="m-r-txt">Include complete shipment details</span> </mat-radio-button>
        <mat-radio-button name="chec" [disabled]="parentEntityTypeKey==='parcelshipment' || parentEntityTypeKey==='standardshipment'" [checked]="limitedShipment" [value]="'limited'" > <span class="m-r-txt">Include only limited details</span></mat-radio-button>
      </mat-radio-group> 
    </div>

    <div>
        <span class="pop-head">
            Email Address</span>
        <span class="mandatory">*</span>
        <form>
            <input type="text" [(ngModel)]="userEmail" (focus)="emailFocus()" multiple class="form-control inp-email" name="email"
                placeholder="(separate multiple w/ commas)">
            <div *ngIf="!validEmail" class="mandatory">{{emailError}}</div>
            <div *ngIf="validEmail" class="mandatory"></div>
        </form>

    </div>
   
    <div class="pop-note">
       <span class="note-head">Privacy Note:</span><span class="note-content"> All recipients will receive shipment details according to selection above.</span>
    </div>
    <div class="pop-bottom">
        <label>
            <mat-checkbox
            class="mat-checkbox"
            [(ngModel)]="checkbox"
            ><label class="mat-checkbox-layout" for="mat-checkbox-1-input"
              ><span class="mat-checkbox-label">send me a copy of this email</span
            ></label>
          </mat-checkbox>
        </label>
    </div>
    <div class="pop-send">
        <button  (click)="onCancel()"class="btn btn-cancl">Cancel</button>
        <button (click)="onSend()" class="panel-button white bgcolor4 ml-2">SEND</button>
    </div>
</div>
