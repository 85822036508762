import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timesince'
})
export class TimesincePipe implements PipeTransform {

  transform(tSince: any, ...args: unknown[]): string {
      if (tSince === 'N/A' || !tSince) {
        return 'N/A';
      }
      let date;
      if (typeof tSince === 'object') {
       date = tSince;
      } else {
        date = new Date(parseInt(tSince));
      }

      let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

      let interval = Math.floor(seconds / 31536000);

      /*Added by Diego to manager particular cases that displayed NaN for a brief millisecond*/
      if (isNaN(interval)) interval = 0;
      if (isNaN(seconds)) seconds = 0;

      if (interval > 1) {
        return interval + " years ago";
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + " months ago";
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + " days ago";
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + " hours ago";
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";

      /*var time = Math.floor((new Date() - date));

      var seconds = Math.floor(time / 1000);
      var minutes = Math.floor(seconds / 60);
      var hours = Math.floor(minutes / 60);

      minutes = minutes - (hours * 60);

      return (hours>9?hours:"0"+hours) + ":" + (minutes>9?minutes:"0"+minutes);*/

  };
}

