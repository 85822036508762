import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { switchMap, debounceTime, tap, finalize, filter, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment';
import { OrganizationService } from '@cl/common/services/organization.service';
export type Maps = typeof google.maps;
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { UserService } from '@cl/user/user.service';
import { CatalogType } from '@cl/@types/extended-attributes.types';
import { PropertyService } from '@cl/property/property.service';
import { ToastService } from '@cl/common/services/toast.service';
import _ from 'lodash';
import { ContentDialogComponent } from '../../../../common/components/content-dialog/content-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/constants';

@Component({
  selector: 'cl-shipment-parcel',
  templateUrl: './shipment-parcel.component.html',
  styleUrls: ['./shipment-parcel.component.scss'],
})
export class ShipmentParcelComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() shipmentID: string;
  @Input() shipmentCatalog: CatalogType;

  @Output() isDraftHideEmitter = new EventEmitter<boolean>();
  @ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;

  togglers = {
    additionalFields: true
  };
  customerCodeAddEnable=false;
  dynamicCustomerCode=[]
  shipmentDetails: any;
  assetTypes: any = [];
  existingRoutes: any = [];
  carrierCodesList: any = [];
  customerNames: any = [];
  coustomerCodes: any = [];
  ruleSets: any = [];
  carriers: any = ['a', 'b'];
  areas: any = [];
  parcelShipmentForm: FormGroup;
  parcelShipTaggedAssetList: any = [];
  selectedCategiries: any = [];
  inventoryAssets = [];
  selectedAssets: any = [];
  submitted: boolean = false;
  isLoading: boolean = false;
  sensorsList: any = [];
  sensorsListSubject = new Subject();
  // timeZoneList = {};
  // timeZoneKeys = [];
  shipperNames: any = [];
  datePickerClass: any = 'formSingleDatePicker';
  typesSearchTerm: any;
  searchAssetsTerm: any;
  finishAssetsAdding: boolean = false;
  allComplete: boolean = false;
  depDate: moment.Moment;
  arrDate: any = moment();
  private subscriptions: Subscription[] = [];
  assetsLoading: boolean;
  totalAssets: number = 0;
  assetsScrollId: any;
  searchAssetTypes: any;
  activeSensorProfiles: any = [];
  assetTypesLoading: boolean = false;
  filteredAssetTypes: any = [];
  private map: google.maps.Map;
  @ViewChild('searcha')
  searchElementRef: ElementRef;

  @ViewChild('searchb')
  searchbElementRef: ElementRef;
  dateDisplayFormat:any;
  datePickerOpenedArrival=false;
  datePickerOpenedDeparture=false;
  preventBeforeDepartureDate = moment();
  preventBeforeArrivalDate = moment();
  preventBeforeCurrentDate = moment();
  departureDateRange =  { startDate: moment(), endDate: moment() }
  arrivalDateRange = { startDate: moment(), endDate: moment() };

  shipmentData: any;
  tagstobeSelected: any = [];
  source:any;
  destination:any;
  destinationAreaName:any;
  sourceAreaName:any;

  // Error text
  nameErrorText = '';
  nameErrorValidation: boolean = false;
  externalIdErrorText = '';
  externalIdErrorValidation: boolean = false;
  regularExpression = /^[A-Za-z0-9@&()_:',.;+"/\s-]+$/;
  shipmentName ='';
  shipmentExternalId = '';

  dateforETA: any;
  shipmentNameRequired: boolean = false;
  orgList: any = [];
  isOrgRestrictedUser: boolean;
  constructor(
    private shipmentsService: ShipmentService,
    private formBuilder: FormBuilder,
    private pService: PropertyService,
    public toast: ToastService,
    private ngZone: NgZone,
    private organizationService: OrganizationService,
    private _shipmentUtils: ShipmentUtilsService,
    private _dynamicFormService: DynamicFormService,
    public _userService:UserService,
    private dialog:MatDialog
  ) {}
  startingData: any;
  shipment = {
    name: '',
    orderNum: '',
    shipperName: '',
    carrierCode: '',
    custName: '',
    custCode: '',
    custOrdRef: '',
    recipientName: '',
    organization: '',
    directToResidence: false,
    rulesetId: '',
    direction: 'Outbound',
    route: '',
    shipmentRouteSegments: [],
    mawb: '',
    plannedDeparture: null,
    plannedArrival: null,
    assets: [],
    tags: [],
    notes: '',
    type: 'parcelshipment',
  };
  dynamicInputFields:InputField[] = [];

  ngOnInit(): void {
    this.getSensorsByLazy()
    this.buildForm();
    if (this.shipmentID && this.isEdit) {
      this.getShipmentDetails();
    }else{
      this.getExtendedAttributes();
    }
    this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
    this.dateDisplayFormat= this.dateDisplayFormat.replace('dd', 'DD'); // dd is refered as day(mon ,tue ,wed ...) in moment so replaced with DD to get (1,2,3....31)
    this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');  // replacing a(am ,pm) with A(AM ,PM) because only this format will work in moment()
    //this.getLocations();
    this.initializePreLoadedList();
    this.getAssetTypes();
    this.depDate = moment();
    this.startingData = { ...this.parcelShipmentForm.value };
    this.isOrgRestrictedUser = this._userService.getUser()?.orgAware;
  }

  openHelpDialog(){
    // const dialogData = new helpDialogModel("Search Help Info", this.helpText, this.helpText);
    const dialogRef = this.dialog.open(ContentDialogComponent, {
      maxWidth: "500px",
      data:{
        message: 
        '<div class="pb-1">'+
        '<i class="fa fa-info-circle help-icon color4 text-base mr-1 absolute top-1.5 left-0"></i>'+
        '<span class="font-bold">Note</span>: list of customer codes is visible to all users who can create/edit shipments. New customer codes will be added to list of values automatically. Customer codes are case insensitive'+
        '</div>',
        buttonText: 'Close'
    }
    });
  
  } 
  // draftval: any;
  getShipmentDetails() {
    this.shipmentsService
      .getShipmentData(this.shipmentID,this.shipmentCatalog.additionalProperties.entityTypeKey)
      .subscribe((data: any) => {
        // this.draftval = data.shipmentNode?.properties?.isDraft;
        if (!data.shipmentNode?.properties?.isDraft)
          this.isDraftHideEmitter.emit(true);
        this.shipmentName = data.shipmentNode?.properties?.name;
        this.shipmentExternalId = data.shipmentNode?.properties?.externalId;
        this.parcelShipmentForm.get('externalId').disable();
        this.shipmentDetails = data;
        this.shipmentData = data;
        this.tagstobeSelected = data.shipmentNode.properties.tags;
        this.buildForm();
        this.getExtendedAttributes();
      });
  }
  public getCurrentData() {
    let currentData = { ...this.parcelShipmentForm.value };
    let isChange = false;
    for (let key in this.startingData) {
      if (currentData[key] !== this.startingData[key]) {
        if (key !== 'tags')
          //FIXME: we need to fix this later
          isChange = true;
      }
    }
    return isChange;
  }
  // getLocations() {
  //   this.shipmentsService.api.then((maps) => {
  //     this.initAutocomplete(maps);
  //     this.initAutocomplete1(maps);
  //   });
  // }
  initializePreLoadedList = () => {
    const carrierCodesAPI = this.shipmentsService.getCarrierCodes();
    const rulesetsAPI = this.shipmentsService.getRuleSetByTenant();
    const areasAPI = this.shipmentsService.getAreas();
    const sensorProfilesAPI = this.shipmentsService.getSensorProfiles();
    const routesAPI = this.shipmentsService.getRouts();
    const orgListAPI = this.organizationService.getOrgList();

    this.getAssetTypes();

    const shipmentLists = combineLatest({
      carrierCodes: carrierCodesAPI,
      ruleSets: rulesetsAPI,
      areas: areasAPI,
      sensorProfiles: sensorProfilesAPI,
      routes: routesAPI,
      orgList: orgListAPI
    }).subscribe(
      (data: any) => {
        this.carrierCodesList = [...data.carrierCodes];
        this.ruleSets = [...data.ruleSets];
        this.areas = [...data.areas];
        this.activeSensorProfiles = [
          ...data.sensorProfiles.map((profile) => profile.eventProfileName),
        ];
        this.existingRoutes = [...data.routes.hits].filter(
          (route) => route.name
        );
        this.orgList = [...data.orgList];
        
        this.parcelShipmentForm.get('organization').setValue(this.orgList && this.orgList[0]?.id);
        if (this.isEdit) {
          const ruleSet = this.ruleSets.find(
            (ruleSet) =>
              ruleSet.id === this.shipmentDetails.shipmentNode.properties.ruleSet
          );
          this.buildForm();
          this.parcelShipmentForm.get('rulesetId').patchValue(ruleSet);
        }
      },
      (error) => {
        console.error(error);
      }
    );

    this.subscriptions.push(shipmentLists);
  };
  async getExtendedAttributes(){
    const fields = this.shipmentCatalog.cdmFields.filter(field => field.group === 'User' && field.instanceUserCreatable);

    const orderedFields = this._dynamicFormService.orderFields([...fields],'order');

    this.dynamicInputFields = await this._dynamicFormService.generateInputFields(orderedFields,this.isEdit,this.shipmentData?.shipmentNode?.properties);
  }

  buildForm() {
    const details = this.shipmentDetails?.shipmentNode;
    if (details) {
      this.shipperNames = [details.shipperName];
      // this.orderNumbers = [details.orderNum]
    }
    this.parcelShipmentForm = this.formBuilder.group({
      id: [details?.properties?.id || null],
      custName: [details?.properties?.custName || ''],
      custCode: [details?.properties?.custCode || ''],
      carrierCode: [
        details?.properties?.carrierCode || '',
        Validators.required,
      ],
      name: [details?.properties?.name || '', Validators.required],
      directToResidence: [
        details?.properties?.directToResidence ? String(details?.properties?.directToResidence) : 'false',
        Validators.required,
      ],
      orderNum: [details?.properties?.orderNum || ''],
      shipperName: [details?.properties?.shipperName || ''],
      custOrdRef: [details?.properties?.custOrdRef || ''],
      externalId: [details?.properties?.externalId || ''],
      modeoftransport: [details?.properties?.modeOfTransport || ''],
      recipientName: [details?.properties?.recipientName || ''],
      organization: [details?.properties?.organization || ''],
      assetType: ['UNMONITORED'],
      plannedDeparture: [details?.properties?.plannedDeparture],
      plannedArrival: [details?.properties?.plannedArrival],
      shipmentType: ['Parcel'],
      rulesetId: [details?.properties?.rulesetId || ''],
      notes: [details?.properties?.notes || ''],
      direction: [details?.properties?.direction || 'Outbound'],
      routeId: [''],
      source: [details?.properties?.origin || null],
      destination: [details?.properties?.destination || null],
      sourceArea: [details?.properties?.fromAddress || ''],
      destinationArea: [details?.properties?.toAddress || ''],
      sensorProfile: [details?.properties?.sensorProfile || ''],
      showCategories: [true],
      showAssets: [false],
      showIncludedAssets: [false],
      showNotes: [true],
      showTags: [true],
      isExistingOrigin: [false],
      modeOfTransport: [details?.properties?.modeOfTransport || ''],
      isExistingDestination: [false],
      tags: [details?.properties?.tags || []],
    });
    if (details) {
      this.parcelShipTaggedAssetList = [];
      details.properties.assets.forEach((element) => {
        let newAsset = {
          id: element.id,
          assetName: element.assetName,
          externalId: element.externalId,
          sensorId: Array.isArray(element.sensorIds) ? element.sensorIds[0] : element.sensorIds,
          categoryName: element.sensorType,
          carrierAssetType: element.carrierAssetType,
          trackingNumber: element.trackingNumber,
          catalogType: element?.entityTypeKey,
          isEdit: false,
        };
        this.parcelShipTaggedAssetList.push(newAsset);
      });
        this.parcelShipmentForm.get('showIncludedAssets').patchValue(true);
        const carrierCode = this.carrierCodesList.find(
          (carrier) =>
            carrier.carrierCode ===
            this.shipmentDetails.shipmentNode.properties.carrierCode
        );


        this.datePickerOpenedDeparture = true;
        this.datePickerOpenedArrival = true;
        this.departureDateRange = {
          startDate: this.checkForPlanned(+this.shipmentDetails.shipmentNode?.properties?.plannedDeparture),
          endDate: this.checkForPlanned(+this.shipmentDetails.shipmentNode?.properties?.plannedDeparture),
        };
        this.arrivalDateRange = {
          startDate: this.checkForPlanned(+this.shipmentDetails.shipmentNode?.properties?.plannedArrival),
          endDate: this.checkForPlanned(+this.shipmentDetails.shipmentNode?.properties?.plannedArrival),
        };
        this.parcelShipmentForm.get('carrierCode').patchValue(carrierCode);
        this.dateforETA = moment(this.parcelShipmentForm.value.plannedArrival);
        if(!this.shipmentDetails.shipmentNode.properties.routeHidden){
        this.patchRoute(this.shipmentDetails.shipmentNode.properties.routeId);
        }else{

        if(this.shipmentDetails.shipmentNode.properties.originHidden) {
          this.parcelShipmentForm.get('isExistingOrigin').patchValue(false);
          this.sourceAreaName = this.shipmentDetails.shipmentNode.properties.fromAddress
          this.parcelShipmentForm.get('sourceArea').patchValue(this.shipmentDetails.shipmentNode.properties.fromAddress);
        }else{
          const sourceArea = this.areas.find(area => area.id == this.shipmentDetails.shipmentNode?.properties?.originAreaId); 
          this.source = sourceArea || this.shipmentDetails.shipmentNode.properties.source
          this.parcelShipmentForm.get('isExistingOrigin').patchValue(true);
          this.parcelShipmentForm.get('source').patchValue(sourceArea || this.shipmentDetails.shipmentNode.properties.source);
        }
        if(this.shipmentDetails.shipmentNode.properties.destHidden) {
          this.destinationAreaName =this.shipmentDetails.shipmentNode.properties.toAddress
          this.parcelShipmentForm.get('isExistingDestination').patchValue(false);
          this.parcelShipmentForm.get('destinationArea').patchValue(this.shipmentDetails.shipmentNode.properties.toAddress);
        }else{
          const destinationArea = this.areas.find(area => area.id == this.shipmentDetails.shipmentNode?.properties?.destinationAreaId); 
          this.destination =destinationArea || this.shipmentDetails.shipmentNode.properties.destination
          this.parcelShipmentForm.get('isExistingDestination').patchValue(true);
          this.parcelShipmentForm.get('destination').patchValue(destinationArea || this.shipmentDetails.shipmentNode.properties.destination);
        }
      }


    }
    if(this.shipmentCatalog?.catalogType == 'dtpshipment'){this.parcelShipmentForm.get('modeoftransport').patchValue('Parcel');} 
    this.getCustomerCodes();
  }
  checkForPlanned(value){
    if(value){
      return moment(value)
    }else{
      return null
    }
  }
  getInventoryAssets(
    event: any,
    category: any,
    searchTerm: any,
    loadmore: boolean
  ) {
    this.assetsLoading = true;
    if (!loadmore) {
      this.selectedAssets = this.inventoryAssets = [];
      this.assetsScrollId = null;
      this.allComplete = false;
    }
    if (searchTerm == '') {
      searchTerm = null;
    }
    if (event) {
      this.parcelShipmentForm.get('showAssets').patchValue(true);
      if (event.checked == true) {
        this.selectedCategiries.push(category.id);
      } else {
        this.selectedCategiries = this.selectedCategiries.filter(function (
          id: any
        ) {
          return id !== category.id;
        });
      }
    }
    let payload = {
      match: 'must',
      scrollId: this.assetsScrollId,
      scrollSize: 50,
      globalQueryText: searchTerm,
      searchQueries: [
        {
          fieldName: 'baseClass',
          queryText: 'Asset',
          queryOperator: 'should',
          queryType: 'match',
          childFilters: [
            {
              fieldName: 'typeId',
              queryText: this.selectedCategiries.toString(),
              queryOperator: 'must',
            },
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: 'monitoredStatus',
              queryText:
                this.parcelShipmentForm.value.assetType === 'ALL'
                  ? 'monitored,unmonitored'
                  : this.parcelShipmentForm.value.assetType.toLowerCase(),
              queryOperator: 'should',
            },
          ],
        },
      ],
    };
    const assetsData: Subscription = this.shipmentsService
      .globalSearch(payload)
      .subscribe((res) => {
        this.assetsLoading = false;
        this.totalAssets = res.totalHits;
        res.hits.slice().forEach((element) => {
          element.trackingId = null;
          element.checked = false;
          element.sensorId = element.sensorId;
          element.isAdded = false;
          this.parcelShipTaggedAssetList.forEach((asset) => {
            if (asset.externalId == element.externalId) {
              element.isAdded = true;
            }
          });
        });
        if (
          searchTerm ||
          this.inventoryAssets.length == 0 ||
          loadmore == false
        ) {
          this.inventoryAssets = res.hits.slice();
        } else {
          this.inventoryAssets = this.inventoryAssets.concat(res.hits);
          this.allComplete = false;
        }
        this.assetsScrollId = res._scroll_id;
      });
    this.subscriptions.push(assetsData);
  }
  getSelectedAssetTypes(searchTerm: any) {
    const assetsTypesData: Subscription = this.shipmentsService
      .getAllAssetTypes()
      .subscribe((res) => {
        this.filteredAssetTypes = res;
        this.filteredAssetTypes.forEach((asset) => {
          asset.value = 0;
          asset['MONITORED'] = 0;
          asset['UNMONITORED'] = 0;
          const aggregateAsset = this.assetTypes.find(
            (aggAsset) => aggAsset.key === asset.id
          );
          if (aggregateAsset) {
            asset.value = aggregateAsset.value || 0;
            asset['MONITORED'] = aggregateAsset?.['MONITORED'] || 0;
            asset['UNMONITORED'] = aggregateAsset?.['UNMONITORED'] || 0;
          }
        });
      });
    this.subscriptions.push(assetsTypesData);
  }
  getSensors(string: any) {
    this.sensorsListSubject.next(string);
  }

  getSensorsByLazy(){
    this.sensorsListSubject.pipe(debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged()).subscribe(searchValue => {
      this.isLoading = true;
      this.shipmentsService
      .getSensorTages(searchValue)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((c:any) => (this.sensorsList = c.hits));
    })
  }
  getCustomerCodes() {
    const coustomerCodesData: Subscription = this.parcelShipmentForm
      .get('custCode')
      .valueChanges.pipe(
        debounceTime(500),
        filter((value: string) => value.length > 3),
        tap(() => (this.isLoading = true)),
        switchMap((value) =>
          this._userService
            .getEntityAttributeValues('SHIPMENT','custCode',value)
            .pipe(finalize(() => (this.isLoading = false)))
        )
      )
      .subscribe((data) => {
        this.coustomerCodes = Object.values(data);
        this.coustomerCodes = this.dynamicCustomerCode.concat(this.coustomerCodes)
        if((this.coustomerCodes.length == 0 || this.coustomerCodes.filter(item=>item.toLowerCase()===this.parcelShipmentForm.value.custCode.toLowerCase()).length ===0) && !_.isEmpty(this.parcelShipmentForm.value.custCode)){
          this.customerCodeAddEnable =true
        }else{
          this.customerCodeAddEnable =false
        }
      });
    this.subscriptions.push(coustomerCodesData);
  }

  displayFn(item) {
    if (item) {
      if (item.name || item.id) {
        return item.name;
      }
      if (item.carrierName) {
        return item.carrierName;
      } else {
        return item;
      }
    }
  }
  toggler(type) {
    if (type == 'categories') {
      if (this.parcelShipmentForm.value.showCategories) {
        this.parcelShipmentForm.get('showCategories').patchValue(false);
      } else {
        this.parcelShipmentForm.get('showCategories').patchValue(true);
      }
    } else if (type == 'selected-assets') {
      this.allComplete = false;
      if (this.parcelShipmentForm.value.showAssets) {
        this.parcelShipmentForm.get('showAssets').patchValue(false);
      } else {
        this.parcelShipmentForm.get('showAssets').patchValue(true);
      }
    } else if (type == 'notes') {
      if (this.parcelShipmentForm.value.showNotes) {
        this.parcelShipmentForm.get('showNotes').patchValue(false);
      } else {
        this.parcelShipmentForm.get('showNotes').patchValue(true);
      }
    } else if (type == 'tags') {
      if (this.parcelShipmentForm.value.showTags) {
        this.parcelShipmentForm.get('showTags').patchValue(false);
      } else {
        this.parcelShipmentForm.get('showTags').patchValue(true);
      }
    } else {
      if (this.parcelShipmentForm.value.showIncludedAssets) {
        this.parcelShipmentForm.get('showIncludedAssets').patchValue(false);
      } else {
        this.parcelShipmentForm.get('showIncludedAssets').patchValue(true);
      }
    }
  }

  onFinish() {
    this.finishAssetsAdding = true;
    this.parcelShipTaggedAssetList.forEach((element) => {
      if (element.trackingNumber) {
        element.isEdit = false;
      }
    });
    let close = this.parcelShipTaggedAssetList.filter(function (item: any) {
      return !item.trackingNumber;
    });
    if (close.length == 0) {
      this.parcelShipmentForm.get('showCategories').patchValue(false);
      this.parcelShipmentForm.get('showAssets').patchValue(false);
      this.filteredAssetTypes.forEach((t) => (t.checked = false));
      this.inventoryAssets.forEach((t) => (t.checked = false));
      this.inventoryAssets = [];
      this.totalAssets = 0;
      this.selectedAssets = [];
      this.selectedCategiries = [];
    }
  }
  editIncludedAsset(item) {
    item.isEdit = !item.isEdit;
  }
  deleteIncludedAsset(asset: any) {
    this.allComplete = false;
    this.assetTypes.forEach((element) => {
      element.checked = false;
    });
    if (asset != 'deleteAll') {
      this.inventoryAssets.forEach((element) => {
        if (element.id == asset.id) {
          element.isAdded = false;
        }
      });
      this.parcelShipTaggedAssetList = this.parcelShipTaggedAssetList.filter(
        function (item: any) {
          return item.assetName !== asset.assetName;
        }
      );
      this.selectedAssets = this.selectedAssets.filter(item => item.id != asset.id)
    } else {
      this.inventoryAssets.forEach((element) => {
        element.isAdded = false;
      });
      this.parcelShipTaggedAssetList = [];
      this.selectedAssets = [];
    }
  }
  onChangeAssets(event: any, asset) {
    if (event.checked == true) {
      this.selectedAssets.push(asset);
    } else {
      asset.checked = false;
      this.selectedAssets = this.selectedAssets.filter(function (obj: any) {
        return obj.id !== asset.id;
      });
    }
    this.allComplete =
      this.inventoryAssets != null &&
      this.inventoryAssets.every((t) => t.checked);
  }
  addToShipment() {
    this.inventoryAssets.forEach((element) => {
      if (element.checked == true) {
        element.isAdded = true;
      }
      element.checked = false;
    });
    this.selectedAssets.forEach((element) => {
      let newAsset = {
        id: element.id,
        assetName: element.assetName || element.name,
        externalId: element?.externalId,
        sensorId: this.extractSensorIdFromEventPublisherMap(element?.eventPublisherMap),
        categoryName: element.type,
        carrierAssetType: element.monitoredStatus,
        trackingNumber: element.trackingId,
        isEdit: true,
        catalogType: element?.entityTypeKey
      };
      this.parcelShipTaggedAssetList = this.parcelShipTaggedAssetList
        .filter((obj) => obj.id !== element.id)
        .concat(newAsset);
    });
    this.parcelShipmentForm.get('showIncludedAssets').patchValue(true);
  }
  extractSensorIdFromEventPublisherMap(eventPublisherMap){
    if(!eventPublisherMap) return '';

    let sensorIds = [];
    try {
      let mapToArray = JSON.parse(eventPublisherMap);
      sensorIds = Object.keys(mapToArray)
    } catch (error) {
      
    }
    return sensorIds;
  }
  onChangeMonitorAssetType() {
    this.selectedAssets =
      this.selectedCategiries =
      this.inventoryAssets =
      this.assetTypes =
        [];
    this.totalAssets = 0;
    this.getAssetTypes();
  }
  onAllInventoryAssets(completed) {
    if (!completed) {
      this.inventoryAssets.forEach((element) => {
        element.checked = false;
        element.externalId = null;
        element.trackingId = null;
        element.sensorId = element.sensorId;
      });
      this.selectedAssets = [];
    } else {
      this.selectedAssets = [];
      this.inventoryAssets.forEach((element) => {
        if (element.isAdded == false) {
          element.checked = true;
          this.selectedAssets.push(element);
        }
      });
    }
    this.allComplete = completed;
    this.inventoryAssets.forEach((t) => (t.checked = completed));
  }
  someComplete(): boolean {
    return (
      this.inventoryAssets.filter((t) => t.checked).length > 0 &&
      !this.allComplete
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  
  depDateUpdated(datesUpdated, status: any) {
    if (status == 'plannedArrival') {
      if (datesUpdated && datesUpdated.startDate) {
        this.parcelShipmentForm.get('plannedArrival').patchValue(datesUpdated.startDate);
        this.dateforETA =moment(datesUpdated.startDate)
      }
    } else {
      if (datesUpdated && datesUpdated.startDate) {
        this.preventBeforeArrivalDate = moment(datesUpdated.startDate);
        this.parcelShipmentForm.get('plannedDeparture').patchValue(datesUpdated.startDate);
        this.getEstimateArrival()
      }
    }
  }
  reIntializeCalendar(){
    this.parcelShipmentForm.get("plannedArrival").setValue(null);
    this.parcelShipmentForm.get("plannedDeparture").setValue(null);
    this.departureDateRange= { startDate: moment(), endDate: moment() };
    this.arrivalDateRange={ startDate: moment(), endDate:moment() };
    // this.enableArrival =true;
    // this.enableDeparture =true;
  }
  getEstimateArrival(){
    if(this.parcelShipmentForm.value.routeId){
      this.datePickerOpenedArrival =false;
      const ETA: Subscription = this.shipmentsService
      .getEstimateArrival(this.parcelShipmentForm.value.routeId?.id, moment(this.parcelShipmentForm.value.plannedDeparture).format("MM/DD/YYYY HH:mm:ss")) // for Api request need to change in this format
      .subscribe((c: any) => {
        if(c.computedETA?.length){
          this.dateforETA =moment(c.computedETA)
          this.datePickerOpenedArrival =true;
          this.arrivalDateRange = { startDate: this.dateforETA, endDate: moment() };
          this.parcelShipmentForm.get('plannedArrival').patchValue(this.dateforETA);
          }else{
          this.toast.warning("Planned Arrival is not available for the selected route")
        }
      });
    this.subscriptions.push(ETA);
    // this.enableArrival =false;
    }
  }
  convertDateforETA(date: any) {
    if (!date) {
      return '';
    } else {
      return (
        moment(date).format('MMM') +
        ' ,' +
        moment(date).format('DD') +
        ' ' +
        moment(date).format('YYYY') +
        ' at ' +
        moment(date).format('hh:mm A')
      );
    }
  }
  updateFilter(event: any, type: any) {
    if (type == 'assetTypes') {
      this.searchAssetTypes = event;
    } else {
      this.assetsScrollId = null;
    }
  }
  checkNames() {
    if (this.shipmentID && this.isEdit && this.shipmentName == this.parcelShipmentForm.value.name){
           this.nameErrorValidation = false ;
     }else{
      const shipmentNames: Subscription = this.shipmentsService
      .getShipmentNames(this.parcelShipmentForm.value.name, 'name', this.shipmentCatalog?.catalogType)
      .subscribe((c: any) => {
        if (c.totalHits) {
          this.nameErrorValidation = true;
          this.nameErrorText = 'Shipment name is already exists';
        } else {
          this.nameErrorValidation = false;
        }
      });
    this.subscriptions.push(shipmentNames);
    }
}
checkExternelID() {
  if (this.parcelShipmentForm.value.externalId){
    if (this.shipmentID && this.isEdit && this.shipmentExternalId == this.parcelShipmentForm.value.externalId){
        this.externalIdErrorValidation = false;
 }else{
      const externalIDs: Subscription = this.shipmentsService
      .getShipmentNames(this.parcelShipmentForm.value.externalId, 'externalId', this.shipmentCatalog?.catalogType)
      .subscribe((c: any) => {
        if (c.totalHits) {
          this.externalIdErrorValidation = true;
          this.externalIdErrorText = 'External ID is already exists';
        } else {
          this.externalIdErrorValidation = false;
        }
      });
    this.subscriptions.push(externalIDs);
 }
   } else{
    this.externalIdErrorValidation = false;
   }
}
  public validateName(name: string) {
   return this.regularExpression.test(String(name));
  }

  getAssetTypes() {
    this.assetTypesLoading = true;
    const assetTypesData: Subscription = this.shipmentsService
      .getAssetTypes()
      .subscribe((data: any) => {
        this.assetTypes = data[0].hits[0].buckets;
        this.assetTypes.forEach((element) => {
          element.checked = false;
          element.subBucket.forEach((elemStatus) => {
            element[elemStatus.key.toUpperCase()] = elemStatus.value;
          });
        });
        this.getSelectedAssetTypes(null);
        this.assetTypesLoading = false;
      });
    this.subscriptions.push(assetTypesData);
  }
  async onSubmit(type: 'draft' | 'save') {
    const dynamicFormGroup = this.dynamicForm?.onSubmit();

    this.shipmentNameRequired = this.submitted = false;
    if (type == 'save') {
      if (
        this.parcelShipmentForm.invalid ||
        this.validateDynamicRoutes()||
        this.parcelShipmentForm.value?.carrierCode?.valid == false ||
        this.parcelShipTaggedAssetList.length == 0 ||
        (dynamicFormGroup && dynamicFormGroup.invalid) || 
        (this.customerCodeAddEnable && this.parcelShipmentForm.value.custCode.length>2)
      ) {
        this.submitted = true;
        if(this.customerCodeAddEnable && this.parcelShipmentForm.value.custCode.length>2){
          this.toast.warning('New Customer Code value must be added')
        }
        return null;
      }
    } else {
      if (this.parcelShipmentForm.get('name').invalid) {
        this.shipmentNameRequired = true;
        return null;
      }
    }
    // if (
    //   this.parcelShipmentForm.invalid ||
    //   this.parcelShipTaggedAssetList.length == 0
    // ) {
    //   if (
    //     type !== 'draft' ||
    //     (this.parcelShipmentForm.value.name.trim() === '' && !this.isEdit)
    //   )
    //     return null;
    // }

    this.onFinish();

    let close = this.parcelShipTaggedAssetList.filter(function (item: any) {
      return !item.trackingNumber;
    });
    if (close.length != 0) {
      return null;
    }
    this.parcelShipTaggedAssetList.forEach(function (v) {
      delete v.isEdit;
    });
    // this.parcelShipTaggedAssetList.forEach(element => {
    //   if(element.sensorId && typeof element.sensorId == 'string') {
    //      if(element.carrierAssetType=="UNMONITORED" ){
    //       element.sensorIds = element.sensorId ? [element.sensorId] : [];

    //      }else{
    //       element.sensorIds = JSON.parse(element.sensorId)
    //      }
    //      delete  element.sensorId
    //   }else{
    //     if(element.carrierAssetType=="UNMONITORED" ){
    //       element.sensorIds = element.sensorId ? [element.sensorId] : [];
    //      }else{
    //     element.sensorIds = element.sensorId
    //      }
    //     delete  element.sensorId
    //   }
    // })
    
    const assets = this.parcelShipTaggedAssetList.map(asset => {
      return {
        catalogType: asset.catalogType,
        externalId: asset.externalId,
        sensor: {
          sensorId: asset.carrierAssetType?.toUpperCase() == "UNMONITORED" ?  asset?.sensorId?.trim() : '',
          catalogType: null
        },
        trackingNumber: asset.trackingNumber
      }
    })

    let source;
    let destination;
    if (this.parcelShipmentForm.value.routeId) {
      this.parcelShipmentForm.get('source').patchValue(null);
      this.parcelShipmentForm.get('destination').patchValue(null);
    } else {
      this.parcelShipmentForm.get('routeId').patchValue(null);
      if (this.parcelShipmentForm.value.isExistingOrigin) {
        const res:any = await this.shipmentsService.getLocationDetails(this.source?.id);
        source = {
          name: res?.properties?.name,
          catalogType: res?.properties?.entityTypeKey
        };
        this.parcelShipmentForm.get('sourceArea').patchValue(null);
      } else {
        source = null;
      }
      if (this.parcelShipmentForm.value.isExistingDestination) {
        const res:any = await this.shipmentsService.getLocationDetails(this.destination?.id);
        destination = {
          name: res?.properties?.name,
          catalogType: res?.properties?.entityTypeKey
        };
        this.parcelShipmentForm.get('destinationArea').patchValue(null);
      } else {
        destination = null;
      }
    }
    let obj = {
      // id: this.shipmentDetails?.shipmentNode.properties.id || null,
      name: this.parcelShipmentForm.value.name,
      custName: this.parcelShipmentForm.value.custName,
      custCode: this.parcelShipmentForm.value?.custCode?.toLowerCase(),
      carrierCode:
        this.parcelShipmentForm.value.carrierCode?.carrierCode ||
        this.parcelShipmentForm.value.carrierCode ||
        '',
      directToResidence:
        this.parcelShipmentForm.value.directToResidence === 'true',
      orderNum: this.parcelShipmentForm.value.orderNum,
      custOrdRef: this.parcelShipmentForm.value.custOrdRef,
      externalId: this.parcelShipmentForm.value.externalId,
      modeOfTransport: this.parcelShipmentForm.value.modeoftransport,
      recipientName: this.parcelShipmentForm.value.recipientName,
      organization: this.parcelShipmentForm.value.organization,
      assets: assets,
      plannedDeparture: moment(
        this.parcelShipmentForm.value.plannedDeparture
      ).valueOf(),
      plannedArrival: moment(
        this.parcelShipmentForm.value.plannedArrival
      ).valueOf(),
      // type: 'parcelshipment',
      ruleSet: this.parcelShipmentForm.value.rulesetId?.id,
      notes: this.parcelShipmentForm.value.notes,
      direction: this.parcelShipmentForm.value.direction,
      shipperName: this.parcelShipmentForm.value.shipperName,
      route: this.parcelShipmentForm.value.routeId ? {
        id: this.parcelShipmentForm.value.routeId?.id,
        catalogType: this.parcelShipmentForm.value.routeId?.entityTypeKey
      }: '',
      sensorProfile: this.parcelShipmentForm.value.sensorProfile,
      tags: this.parcelShipmentForm.value.tags,
      originArea: source,
      destinationArea: destination,
      fromAddress: this.parcelShipmentForm.value.sourceArea,
      toAddress: this.parcelShipmentForm.value.destinationArea,
    };

    if(dynamicFormGroup){
      obj = {...obj, ...dynamicFormGroup.getRawValue() }
    }

    return obj;
  }
  
  validateDynamicRoutes(): boolean {
    const { routeId, sourceArea, destinationArea, source, destination ,  isExistingOrigin, isExistingDestination} = this.parcelShipmentForm.value;
  
    if (!routeId &&((sourceArea && destinationArea))) {
      return false;
    }else if (routeId && ((!sourceArea && !destinationArea))) {
      return false;
    }
     else if(!routeId &&(sourceArea &&(isExistingDestination || isExistingOrigin) &&(this.destination))){
      return false;
    } 
    else if(!routeId &&(destinationArea &&(isExistingDestination || isExistingOrigin) &&(this.source))){
      return false;
    } 
    else if(!routeId &&(( isExistingOrigin &&isExistingDestination )&&(this.source && this.destination)) ){
      return false;
    }
    return true;
  }
  handleAddressChange(event, sourceType:string) {
    this.parcelShipmentForm
    .get(sourceType)
    .patchValue(event.formatted_address);
  }

  resetDynamicRoutes(routeType: string) {
    if(routeType == 'source') {
      this.parcelShipmentForm.get('source').patchValue(null);
      this.parcelShipmentForm.get('sourceArea').patchValue(null);
    } else if(routeType == 'destination') {
      this.parcelShipmentForm.get('destination').patchValue(null);
      this.parcelShipmentForm.get('destinationArea').patchValue(null);
    }
  }

  onChangeRoute(type: any) {
    if (type == 'new') {
      this.parcelShipmentForm.get('routeId').patchValue(null);
    } else {
      this.parcelShipmentForm.get('destination').patchValue(null);
      this.parcelShipmentForm.get('source').patchValue(null);
      this.parcelShipmentForm.get('sourceArea').patchValue(null);
      this.parcelShipmentForm.get('destinationArea').patchValue(null);
      this.parcelShipmentForm.get('isExistingOrigin').patchValue(false);
      this.parcelShipmentForm.get('isExistingDestination').patchValue(false);
    }
  }
  tagsfun(tags) {
    // const tagNames = tags.map((tag) => tag.tagname);
    this.parcelShipmentForm.get('tags').patchValue([...tags]);
  }
  selectedOption(event, type) {
    if (type == 'route') {
      this.sourceAreaName=null;
      this.destinationAreaName=null;
      this.parcelShipmentForm.get('destination').patchValue(null);
      this.parcelShipmentForm.get('source').patchValue(null);
      this.parcelShipmentForm.get('sourceArea').patchValue(null);
      this.parcelShipmentForm.get('destinationArea').patchValue(null);
      this.parcelShipmentForm.get('isExistingOrigin').patchValue(false);
      this.parcelShipmentForm.get('isExistingDestination').patchValue(false);
    } else {
      this.parcelShipmentForm.get('routeId').patchValue(null);
      this.parcelShipmentForm.get(type).patchValue(event.option.value);
      // setTimeout(() => {
      // }, 100);
    }
  }
  //route Handlers
  public getRoutesbySearch() {
    if (
      this.parcelShipmentForm.value.routeId.length > 2 ||
      this.parcelShipmentForm.value.routeId.length == 0
    ) {
      this.isLoading = true;
      const RoutesbySearch: Subscription = this.shipmentsService
        .getRouteSearch(this.parcelShipmentForm.value.routeId)
        .subscribe((data: any) => {
          this.isLoading = false;
          this.existingRoutes = [...data.hits].filter((route) => route.name);
        });
      this.subscriptions.push(RoutesbySearch);
    }
  }
  public onCloseRoute() {
    if (!this.existingRoutes.length) {
      this.parcelShipmentForm.get('routeId').patchValue('');
      this.getRoutesbySearch();
    }
  }
  patchRoute(id) {
    this.shipmentsService.getRouts(id).subscribe((data: any) => {
      let routeData = [...data.hits].filter((route) => route.name);
      const route = routeData.find(
        (route) =>
          route.id === this.shipmentDetails.shipmentNode.properties.routeId
      );
      this.parcelShipmentForm.get('routeId').patchValue(route);
    });
  }

  displaySensorIds(sensorIds: string) : string | string[] {
    try {
      const sensorArr = JSON.parse(sensorIds);
      return sensorArr.length == 0 ? '' : sensorArr
    } catch (error) {
      return sensorIds;
    }
  }
  allowAlphaNumericOnly(event) {
    var kcode = event.keyCode;

    if (kcode == 8 ||
        kcode == 9 ||
        kcode == 46 ||
        kcode == 32 ||
        kcode == 95 ||
        (kcode > 47 && kcode < 58) ||
        (kcode > 64 && kcode < 91) ||
        (kcode > 96 && kcode < 123))
    {
        return true;
    }
    else
    {
        return false;
    }
}
restrictedCharactersOnly(event) {
  var kcode = event.key;
if(this.validateName(kcode)){
    return true
  }else{
    return false
  }
}
onOrgclose(){
  this.parcelShipmentForm.get('organization').setValue('');
}
onSourcechange(type){
  setTimeout(() => {
  if(type=='source'){
    if(this.source!=this.parcelShipmentForm.value.source){
      this.source = "";
      this.parcelShipmentForm.get(type).patchValue(null);
    }
  }
  if(type=='destination'){
    if(this.destination!=this.parcelShipmentForm.value.destination){
      this.destination = "";
      this.parcelShipmentForm.get(type).patchValue(null);
    } 
  }
},200);
if(type=='destinationArea'){
  if(this.destinationAreaName!=this.parcelShipmentForm.value.destinationArea){
    this.destinationAreaName = "";
    this.parcelShipmentForm.get(type).patchValue(null);
  }
}
if(type=='sourceArea'){
  if(this.sourceAreaName!=this.parcelShipmentForm.value.sourceArea){
    this.sourceAreaName = "";
    this.parcelShipmentForm.get(type).patchValue(null);
  }
}
}
onCloseField(type){
  setTimeout(() => {
    if(this.parcelShipmentForm.value[type] !=this.shipment[type]){
      if(this.shipment[type]){
        this.parcelShipmentForm.get(type).patchValue('');
      }else{
        this.parcelShipmentForm.get(type).patchValue('');
        this.shipment[type] ='';
      }
     }
  }, 100);
  }
  onSelectField(value, type){
    this.shipment[type] = value.option.value
    this.parcelShipmentForm.get(type).patchValue(value.option.value);
  }
  onCloseCustField(type){
    setTimeout(() => {
      if(this.parcelShipmentForm.value[type].length<=2){
        this.parcelShipmentForm.get(type).patchValue('');
        this.shipment[type] ='';
      }
    }, 100);
  }
  addCustomerCode(){
     let custcode = this.parcelShipmentForm.value.custCode
     this.parcelShipmentForm.get('custCode').patchValue(custcode);
     this.shipment['custCode'] = custcode
     this.dynamicCustomerCode.unshift(custcode)
     this.coustomerCodes = this.dynamicCustomerCode.concat(this.coustomerCodes)
     this.customerCodeAddEnable =false
  }
}
