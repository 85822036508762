import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-checkout-transfer-confirm-dialog',
  templateUrl: './checkout-transfer-confirm-dialog.component.html',
  styleUrls: ['./checkout-transfer-confirm-dialog.component.scss']
})
export class CheckoutTransferConfirmDialogComponent implements OnInit {

  title: string = '';

  constructor(
    private dialogRef: MatDialogRef<CheckoutTransferConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
  ) {

    this.title = this.dialogData.title;
  }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close({
      accepted: true,
    })
  }

  cancel() {
    this.dialogRef.close({
      accepted: false,
    });
  }


}
