import { Component, Input, OnInit } from '@angular/core';
import { Asset } from '@cl/@types/asset.type';
import { AlertIcon, MeasureType } from '@cl/@types/measure.type';
import { UtilsService } from '@cl/common/utils/utils.service';
import * as _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'cl-asset-capabilities',
  templateUrl: './asset-capabilities.component.html',
  styleUrls: ['./asset-capabilities.component.scss']
})
export class AssetCapabilitiesComponent implements OnInit {
  @Input('asset') assetObject: Asset;
  @Input('pane') paneView: string;
  @Input() excursionObj :any
  @Input('violated') excursionViolatedCheck: boolean;
  capabilitiesData : any = [];

  constructor(
    public _utils: UtilsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.assetObject && this.assetObject.measures) {
      this.assetObject.measures = this.assetObject.measures.filter(measure => measure.hasOwnProperty('value'));
      let clonedMeasures = _.cloneDeep(this.assetObject.measures)
      this.filterCapabilities(clonedMeasures);
    }
  }
  formatDependencyString (str) {
    str = str
    .replace(/\\/g, '')
    .replace(/\[/g, '')
    .replace(/\]/g, '')
    .replace(/"/g, '');
    str = str.split(',');
    return str;
}

formatDependencies(measures) {
  const measuresToBeRemoved = [];
  measures.map((temMeasure: any) => {
    temMeasure.dependencies = [];
      if (temMeasure.config.dependencyMetric && !_.isEmpty(temMeasure.measureServiceId)) {
          var dependency = this.formatDependencyString(temMeasure.config.dependencyMetric);
          dependency.forEach(function(innerService){
              var index = _.findIndex(measures, function(originService) {
                  return innerService.split(':')[1] ===  originService['measureCharacteristicId'];
              });
              if (index > -1) {
                temMeasure.dependencies.push(measures[index]);
                measuresToBeRemoved.push(index);
              }
          })
      }
      return temMeasure;
  });
  let newMeasures = [];
  for(var i=0, len = measures.length; i<len; i++){
    if(measuresToBeRemoved.indexOf(i) === -1 ){
      newMeasures.push(measures[i]);
    }
  }
  return newMeasures;
}

filterCapabilities(data) {
  this.capabilitiesData = [];
  var measures = this.formatDependencies(data);
  measures.forEach((measure : any) => {
      if (!_.isEmpty(measure)) {
          this.capabilitiesData.push(measure);
      }
  });

  // for residualThermalTime
  this.capabilitiesData.forEach((capabilities : any) => {
    if (!_.isEmpty(capabilities) && capabilities.cId === 'residualThermalTime') {
        let intoSec = (moment.duration(capabilities.value).asSeconds())*1000;
        capabilities.value = this._utils.dwellTimeFormatter(intoSec);
    }
  });
  
  if(this.capabilitiesData.length > 0){
    this.violationUpdate();
  }
}
  formatAlertObject(measure: any): AlertIcon {
    let alertIconObject: AlertIcon = {
      iconColor: measure.violated ? '#FF2500' : '#2879DE',
      measure:  measure.measureServiceId != 'PredictiveMetric'  ? measure.measureLabel : measure.cId,
      width: 24,
      height: 24
    }
    if (this.paneView === 'horizontal') {
      alertIconObject.width = 40;
      alertIconObject.height = 40;
    }
    return alertIconObject;
  }

  violationUpdate(){
    if (this.excursionObj?.ExcursionSummary && this.capabilitiesData.length >0) {
      
      this.capabilitiesData.forEach(measure => {
        if (
          this.excursionObj.ViolationMap[measure.measureServiceId+':'+measure.measureCharacteristicId] != undefined
        ) {
          this.excursionObj.ViolationMap[measure.measureServiceId+':'+measure.measureCharacteristicId].forEach(excursion=> {
            // excursion.propertyValue == measure.value &&  removed
            if(excursion.violated && excursion.alertStatus == 'Active') {
              measure.violated = true;
            }
          })
          }
      });
     } 
   }

}
