import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AnalyticsService } from '@cl/analytics/analytics.service';
import { AuthorizeService } from '@cl/@authorize/authorize.service';
import { UserService } from '@cl/user/user.service';
import { isNull } from 'lodash';
import { ToastService } from '@cl/common/services/toast.service';
import { TenantService } from '@cl/tenant/tenant.service';

@Component({
  selector: 'cl-library-side-panel',
  templateUrl: './library-side-panel.component.html',
  styleUrls: ['./library-side-panel.component.scss']
})
export class LibrarySidePanelComponent implements OnInit {

  @Input() dashboardObject: any;
  @Input() mode: any;
  @Output() closeSidePanel: EventEmitter<void> = new EventEmitter();
  isLoading = false;
  private subscriptions: Subscription[] = [];
  typeList = [];
  tenantList = [];
  dashboardPayload = {
    "name": "",
    "description": "",
    "owner": "",
    "category": "",
    "url": "",
    "tenant_id": ""
  }
  actionBtnPolicies = {
    isSuperAdmin: ['SCOPE_ALL']
  };
  isLoginUserSuperAdmin: boolean = false;

  constructor(private fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    public dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private _authService: AuthorizeService,
    private userService: UserService,
    private tenantService: TenantService,
  ) { }
  dashboardForm = this.fb.group({
    dashboardName: ['', Validators.required],
    description: ['', Validators.required],
    // type: ['', Validators.required],
    tenant: ['', Validators.required],
    dashboardLink: ['', Validators.required]
  });

  ngOnInit(): void {
    this.isLoginUserSuperAdmin = this._authService.isValidAction(this.actionBtnPolicies.isSuperAdmin);
    if (!this.isLoginUserSuperAdmin) {
      this.getLoginUserTenantDetails(this.userService.getTenantId());
    } else {
      this.getTenantList();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  tiggerChangeDetection() {
    this._changeDetectorRef.detectChanges();
  }

  getTypeList() {
    const typeListSubs: Subscription = this.analyticsService.getDashboardFilters().subscribe((data: any) => {
      this.typeList = [...data];
    });
    this.subscriptions.push(typeListSubs);
  }

  getTenantList() {
    this.tenantService.getAllTenants().subscribe((data: any) => {
      data.forEach(d => {
        d.name = isNull(d.name) ? d.identifier : d.name;
        d.tenantId = d.identifier;
      });
      this.tenantList = [...data];
      if (this.mode == 'edit') {
        this.getDashboardByID(this.dashboardObject.id);
      }
    });
  }

  getLoginUserTenantDetails(tenantId) {
    this.tenantService.getTenantDetails(tenantId).subscribe((data: any) => {
      data.name = isNull(data.name) ? data.identifier : data.name;
      data.tenantId = data.identifier;
      this.tenantList = [data];
      if (this.mode == 'edit') {
        this.getDashboardByID(this.dashboardObject.id);
      }
    });
  }

  getDashboardByID(id) {
    const getDashboardSubs: Subscription = this.analyticsService.getDashboardById(id).subscribe({
      next: res => {
        this.initializeDashboardForm(res);
      }, error: err => {
        this.toastService.error(err.error['error-message']);
      }
    });
    this.subscriptions.push(getDashboardSubs);
  }

  initializeDashboardForm(data) {
    let tenantValue = _.find(this.tenantList, { tenantId: data.tenant_id });
    this.dashboardForm.patchValue({
      dashboardName: data.dashboardName,
      description: data.description,
      // type: data.category,
      dashboardLink: data.dashboardUrl,
      tenant: tenantValue
    });
    // if (this.mode == 'edit') {
    //   this.dashboardForm.controls['dashboardName'].disable();
    // }
  }

  closePanel() {
    this.closeSidePanel.emit();
  }

  saveDashboard() {
    this.isLoading = true;
    let formValues = this.dashboardForm.value;
    this.dashboardPayload.name = formValues.dashboardName;
    this.dashboardPayload.description = formValues.description;
    this.dashboardPayload.category = "dashboard";
    this.dashboardPayload.owner = formValues.tenant ? formValues.tenant.name : "cloudleaf";
    this.dashboardPayload.url = formValues.dashboardLink;
    this.dashboardPayload.tenant_id = formValues.tenant ? formValues.tenant.tenantId : this.userService.getTenantId();
    const dashboardSubs: Subscription = this.analyticsService.addDashboard(this.dashboardPayload).subscribe({
      next: res => {
        this.isLoading = false;
        let action = 'Dashboard added successfully';
        this.toastService.success(action, formValues.dashboardName);
        this.closePanel();
      }, error: err => {
        this.isLoading = false;
        this.toastService.error(err.error['error-message'], formValues.dashboardName);
      }
    });
    this.subscriptions.push(dashboardSubs);
  }

  editDashboard() {
    this.isLoading = true;
    let formValues = this.dashboardForm.value;
    this.dashboardPayload.name = formValues.dashboardName;
    this.dashboardPayload.description = formValues.description;
    this.dashboardPayload.category = "dashboard";
    this.dashboardPayload.owner = formValues.tenant ? formValues.tenant.name : "cloudleaf";;
    this.dashboardPayload.url = formValues.dashboardLink;
    this.dashboardPayload.tenant_id = formValues.tenant ? formValues.tenant.tenantId : this.userService.getTenantId();
    const dashboardSubs: Subscription = this.analyticsService.editDashboard(this.dashboardObject.id, this.dashboardPayload,).subscribe({
      next: res => {
        this.isLoading = false;
        let action = 'Dashboard saved successfully';
        this.toastService.success(action, formValues.dashboardName);
        this.closePanel();
      }, error: err => {
        this.isLoading = false;
        this.toastService.error(err.error['error-message'], formValues.dashboardName);
      }
    });
    this.subscriptions.push(dashboardSubs);
  }

  checkIfFormValid() {
    if (this.dashboardForm.valid && !this.isLoading) {
      return false;
    } else {
      return true;
    }
  }

}
