import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Card, DtAnalysis } from "@cl/models";
import { ClientConfigState } from "@cl/ngxs/state";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Select } from "@ngxs/store";
import * as _ from "lodash";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-navigator-carousel",
  templateUrl: "./navigator-carousel.component.html",
  styleUrls: ["./navigator-carousel.component.scss"],
})
export class NavigatorCarouselComponent implements OnInit, OnChanges {
  @Select(ClientConfigState) ccState$: Observable<string[]>;
  @Input() kpiData: DtAnalysis;
  @Input() DTs;
  @Input() selectedDt;
  @Input() tenancyData;
  @Input() metricData;
  @Input() cards: Card[] = [];

  @Output() tenancyEmitter = new EventEmitter();

  cardList = [];
  constructor() {}

  ngOnInit(): void {
    // this.watchCCState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['kpiData'] && this.kpiData) {
      // this.updateCards(this.kpiData);
      // this.updateKpiValues(this.kpiData);
    }
  }

  private updateCards(kpi: DtAnalysis) {
    // No dt analysis data found
    if (_.size(kpi) <= 0) return;

    _.forOwn(kpi, (value, key) => {});
  }

  // watchCCState() {
  //   this.ccState$.subscribe((cc: any) => {
  //     if (!cc.settings || !cc.settings.cards ||
  //       !cc.settings.cards.list || !cc.settings.cards.list[0] === undefined) {
  //       return
  //     }
  //     this.cards = [...cc.settings.cards.list];
  //     this.updateKpiValues(this.kpiData);
  //   })
  // }

  // private updateKpiValues(kpiData: DtAnalysis) {
  //   let kpiArray;
  //   if (!kpiData || !_.size(kpiData) || !this.cards || !this.cards.length) {
  //     return;
  //   }

  //   this.cards = this.cards.map((el) => {
  //     if (!el) {
  //       return el
  //     }
  //     let data = {
  //       list: []
  //     }
  //     if (kpiData[el.name] && kpiData[el.name].length) {
  //       kpiArray = kpiData[el.name].slice(0, 3);

  //       data.list = kpiArray.map(item => {
  //         // item.name = item.name.replace('Inventory ', '');
  //         if (item.value && Array.isArray(item.value)) {
  //           item.val = item.value[item.value.length - 1].value - item.value[0].value;
  //           item.values = item.value.map((item, j) => {
  //             return [
  //               j, +item.value
  //             ]
  //           });
  //           item.value = item.val;
  //         }
  //         item.status = (item.value < 0) ? 'red' : 'green';
  //         item.status = (item.value === 0) ? ' hide' : item.status;
  //         if (item.unit && item.unit.length > 1) {
  //           item.unit = " " + item.unit;
  //         }
  //         return item;
  //       })
  //     }
  //     return { ...el, data };
  //   })
  // }
  getCarouselUpdate(message) {
    this.tenancyEmitter.emit(message);
  }
}
