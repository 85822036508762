import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  constructor(private http: HttpClient,  private apiProvider: ApiProviderService) {
  }


  postLocationList(payload:any) {
    if(!payload){
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location/global/search`, payload);
  }

  getLocationsAndAssets(locId: string, type?: string) {
    const URL = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/location/${locId}/details?type=${type || ''}`;
    return this.http.get(URL);
  }

  getLocationsAndRoute(locId: string, type?: string) {
    const URL = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/route/${locId}/details?type=${type || ''}`;
    return this.http.get(URL);
  }
  getFormattedAddress(address, type) {
    let formattedAddress = '';
    if(type === 'Site') {
      if(address.streetAddr){
        formattedAddress += address.streetAddr+', ';
      }

      if(address.region){
        formattedAddress += address.region+', ';
      }

      if(address.city){
        formattedAddress += address.city+', ';
      }

      if(address.stateCode){
        formattedAddress += address.stateCode+', ';
      }

      if(address.countryCode){
        formattedAddress += address.countryCode;
      }

      if(address.postalCode){
        formattedAddress += ', '+address.postalCode;
      }
    }
    return  formattedAddress;
  }

  saveLocation(payload:any, type:any) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location?type=${type || ''}`, payload);
  }

  updateLocation(payload:any, type:any, id:any) {
    return this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location/${id}?type=${type || ''}`, payload);
  }

  deleteLocation(type:any, id:any) {
    return this.http.delete(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location/${id}?type=${type || ''}`);
  }

  getSites(key?: string) {
    let params = {
      "scrollId": "",
      "scrollSize": 50,
      "globalQueryText": "",
      "searchQueries": [
        {
          "fieldName": "baseClass",
          "queryText": "Location",
          "childFilters": [
            
            {
              "fieldName": "deleted",
              "queryText": "true",
              "queryOperator": "must_not"
            },
            {
              "fieldName": "parentEntityTypeKey",
              "queryText": "site",
              "queryOperator": "must"
            }
          ]
        }
      ],
      "sortField": []
    }
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      params
    );
  }
  getAreas(searchTerm) {
    const headers: any = {
      'textsearchterm': searchTerm,
      'textSearchFields':'name',
      'pagefrom': '0',
      'pagesize': '50',
    };
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/areas/search`, {
        headers: headers
      }
    );
  }
  saveRoute(payload:any, type:any) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/route?type=${type || ''}`, payload);
  }
  updateRoute(payload:any, type:any) {
    return this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/route/${payload.id}?type=${type || ''}`, payload);
  }
  deleteRoute(type:any, id:any) {
    return this.http.delete(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/route/${id}?type=${type || ''}`);
  }
  getFilters(){
    return this.http.get(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location/filter`)
  }
}
