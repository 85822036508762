import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DatePipe } from "@angular/common";
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from "@agm/core";
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxsModule } from '@ngxs/store';
import { MdListState } from "./asset/state/md-list.state";
import { PanelsState } from './common/state/panels.state';
import { InlineSVGModule } from "ng-inline-svg";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { AssetListComponent } from './asset/asset-list/asset-list.component';
import { AssetListService } from './asset/asset-list/asset-list.service';
import { BulkOperationListComponent } from './bulk-operation/bulk-operation-list/bulk-operation-list.component';
import { RuleListComponent } from './business-rules/rule-list/rule-list.component';
import { RulesetListComponent } from './business-rules/ruleset-list/ruleset-list.component';
import { LeaseListComponent } from './lease/lease-list/lease-list.component';
import { GatewayListComponent } from './sensor-fabric/gateway/gateway-list/gateway-list.component';
import { SensorListComponent } from './sensor-fabric/sensor/sensor-list/sensor-list.component';
import { SensorTypeListComponent } from './sensor-fabric/sensor-type/sensor-type-list/sensor-type-list.component';
import { ApiSubscriptionComponent } from './settings/api-subscription/api-subscription.component';
import { TagListComponent } from './settings/tag/tag-list/tag-list.component';
import { TenantSettingsComponent } from './settings/tenant-settings/tenant-settings.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserPolicyListComponent } from './user/user-policy/user-policy-list/user-policy-list.component';
import { UserGroupListComponent } from './user/user-group/user-group-list/user-group-list.component';
import { UserProfileListComponent } from './user/user-profile/user-profile-list/user-profile-list.component';
import { TenantListComponent } from './tenant/tenant-list/tenant-list.component';
import { TenantSidePanelComponent } from './tenant/tenant-side-panel/tenant-side-panel.component';
import { ShellComponent } from './@core/shell/shell.component';
import { CustomRoute } from './@types/custom-route.type';
import { RouterModule } from '@angular/router';
import { NavService } from './@core/shell/nav.service';
import { UserService } from './user/user.service';
import { PersistenceService } from './common/services/persistence.service';
import { HttpStatusInterceptor } from './@core/shell/http-status.interceptor';
import { ToastService } from './common/services/toast.service';
import { TrackersListComponent } from './sensor-fabric/sensor/trackers-list/trackers-list.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { AuthorizeService } from './@authorize/authorize.service';
import { HeaderComponent } from './@core/layout/header/header.component';
import { SecondaryHeaderComponent } from './@core/layout/secondary-header/secondary-header.component';
import { TranslationLoaderService } from "./@core/translations/translation-loader.service";
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './@core/shell/material.module';
import { NavigatorModule } from './navigator/navigator.module';
import { AppState, ClientConfigState, UserState } from "@cl/ngxs/state";
import { environment } from "@cl/env";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { ConfigService } from "./common/services/config.service";
import { ClientConfigService } from "./common/services/client-config.service";
import { TemplateService } from "./common/services/template.service";
import { UserRoleListComponent } from './user/user-role/user-role-list/user-role-list.component';
import { TenantService } from './tenant/tenant.service';
import { GridColumnToggleComponent } from './common/components/grid-column-toggle/grid-column-toggle.component';
import { ExportPanelComponent } from './common/components/export-panel/export-panel.component';
import { AssetsFilterComponent } from './asset/asset-list/assets-filter/assets-filter.component';
import { ListFilterComponent } from './common/components/list-filter/list-filter.component';
import { BottomSheetComponent } from './common/components/bottom-sheet/bottom-sheet.component';
// import { InspectorComponent } from "./common/components/inspector/inspector.component";
// import { ManageInspectorComponent } from './common/components/manage-inspector/manage-inspector.component';
import { CheckoutTransferConfirmDialogComponent } from './asset/components/checkout-transfer-confirm-dialog/checkout-transfer-confirm-dialog.component';
import { ConfirmDialogComponent } from './common/components/confirm-dialog/confirm-dialog.component';
import { DropdownMenuComponent } from './common/components/dropdown-menu/dropdown-menu.component';
// import { CategoryManagerComponent } from './asset/asset-list/category-manager/category-manager.component';
import { PanelStatesService } from './common/utils/panel-states.service';
import { FiltersUtilService } from './common/utils/filters-util.service';
import { SortUtilService } from './common/utils/sort-util.service';
import { UtilsService } from './common/utils/utils.service';
import { AuthInterceptor } from "./@core/shell/auth.interceptor";
import { CsrfTokenInterceptor } from "./@core/shell/csrf-token.interceptor"

// import { StartCasePipe } from './common/pipes/start-case.pipe';
// import { StateActionMapPipe } from './common/pipes/state-action-map.pipe';
// import { TruncatePipe } from './common/pipes/truncate.pipe';
import { GraphAPIService } from './common/services/graph-api.service';
import { SearchApiService } from './common/services/search-api.service';
import { AssetDetailsComponent } from './asset/asset-details/asset-details.component';
import { DetailsSummaryComponent } from './asset/asset-details/details-summary/details-summary.component';
import { NotificationListComponent } from './notification/notification-list/notification-list.component';
import { LoginComponent } from './authenticate/login/login.component';
import { ResetPasswordComponent } from './authenticate/reset-password/reset-password.component';
import { AssetActivityComponent } from "./asset/asset-details/asset-activity/asset-activity.component";
import { CharacteristicListComponent } from './common/components/characteristic-list/characteristic-list.component';
import { AssetService } from "./asset/asset.service";
import { SensorService } from "./sensor-fabric/sensor/sensor.service";
import { SensorUtilService } from "./sensor-fabric/sensor/sensor-util.service";
import { MeasurePipe } from './common/pipes/measure.pipe';
import { LocationListComponent } from './locations/location-list/location-list.component';
import { AssetUtilService } from './asset/asset-util.service';
import { LineChartComponent } from './common/components/line-chart/line-chart.component';
import { SidePanelComponent } from './common/components/side-panel/side-panel.component';
import { SingleMarkerMapComponent } from './common/components/single-marker-map/single-marker-map.component';
import { SharedModule } from "@cl/shared";
import { AssetHistoryComponent } from './asset/asset-details/asset-history/asset-history.component';
import { AssetBinderComponent } from './asset/asset-list/asset-binder/asset-binder.component';
import { UserDetailSummaryComponent } from './user/user-list/user-detail-summary/user-detail-summary.component';
import { ApiUserDialogComponent } from './user/user-list/api-user-dialog/api-user-dialog.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LocationDetailsComponent } from './locations/location-details/location-details.component';
import { LocationDetailFiltersComponent } from './locations/location-detail-filters/location-detail-filters.component';
import { BarChartComponent } from './common/components/bar-chart/bar-chart.component';
import { CocMenuComponent } from './asset/components/coc-menu/coc-menu.component';
import { SensorDetailsComponent } from './sensor-fabric/sensor/sensor-details/sensor-details.component';
import { SensorActivityComponent } from './sensor-fabric/sensor/sensor-details/sensor-activity/sensor-activity.component';
import { SensorSummaryComponent } from './sensor-fabric/sensor/sensor-details/sensor-summary/sensor-summary.component';
import { GatewayDetailsComponent } from './sensor-fabric/gateway/gateway-details/gateway-details.component';
import { GatewaySummaryComponent } from './sensor-fabric/gateway/gateway-details/gateway-summary/gateway-summary.component';
import { GatewayActivityComponent } from './sensor-fabric/gateway/gateway-details/gateway-activity/gateway-activity.component';
import { GatewayService } from './sensor-fabric/gateway/gateway.service';
import { GatewayUtilService } from './sensor-fabric/gateway/gateway-util.service';
import { TimesincePipe } from './common/pipes/timesince.pipe';
import { TemperaturePipe } from './common/pipes/temperature.pipe';
import { SensorBinderComponent } from './sensor-fabric/sensor/components/sensor-binder/sensor-binder.component';
import { GatewayFilterComponent } from './sensor-fabric/gateway/gateway-filter/gateway-filter.component';
import { GatewayBinderComponent } from './sensor-fabric/gateway/gateway-binder/gateway-binder.component';
import { RoleSidePanelComponent } from './user/user-role/role-side-panel/role-side-panel.component';

import { BulkActionMenuComponent } from './common/components/bulk-action-menu/bulk-action-menu.component';
import { BulkActionPanelComponent } from './common/components/bulk-action-panel/bulk-action-panel.component';
import { PolicySidePanelComponent } from './user/user-policy/policy-side-panel/policy-side-panel.component';
import { UserGroupSidePanelComponent } from './user/user-group/user-group-side-panel/user-group-side-panel.component';
import { MyAccountComponent } from './user/my-account/my-account.component';
import { PersonalDetailsComponent } from './user/my-account/personal-details/personal-details.component';
import { AlertsComponent } from './@core/layout/header/alerts/alerts.component';
import { NotificationsComponent } from './@core/layout/header/notifications/notifications.component';
import { NotificationItemComponent } from './@core/layout/header/notification-item/notification-item.component';
import { BreadcrumbComponent } from './common/components/breadcrumb/breadcrumb.component';
import { DateFromStringPipe } from './common/pipes/date-from-string.pipe';
import { GatewayOverallStatusPipe } from './common/pipes/gateway-overall-status.pipe';
import { UnescapePipe } from './common/pipes/unescape.pipe';
import { SensorReplaceComponent } from './sensor-fabric/sensor/components/sensor-replace/sensor-replace.component';
import { DatePickerComponent } from "./common/components/date-picker/date-picker.component";
import { IsValidActionDirective } from './common/directives/is-valid-action.directive';
// import { NodeInspectorComponent } from "./common/components/node-inspector/node-inspector.component";
// import { CheckoutAndTransferComponent } from "./common/components/checkout-and-transfer/checkout-and-transfer.component";
import { TenentTokenInterceptor } from './@core/shell/tenant-token.interceptor';
import { TenantUtilService } from './tenant/tenant-util.service';
import { RouteGuard } from './@core/shell/route.guard';
import { AddRuleComponent } from './business-rules/rule-list/add-rule/add-rule.component';
import { CategoryService } from './common/services/category.service';
import { AddRuleSetComponent } from './business-rules/ruleset-list/add-rule-set/add-rule-set.component';
import { DurationComponent } from './common/components/duration/duration.component';
import { ThresholdComponent } from './common/components/threshold/threshold.component';
import { MetaPipe } from './common/pipes/meta.pipe';
import { ShipmentListComponent } from './shipments/shipment-list/shipment-list.component';
import { ShipmentsFliterComponent } from './shipments/shipment-list/shipments-fliter/shipments-fliter.component';
import { DateRangeFilterComponent } from './common/components/date-range-filter/date-range-filter.component';
import { ShipmentListTableComponent } from './shipments/shipment-list/shipment-list-table/shipment-list-table.component';
import { ShipmentListActionsComponent } from './shipments/shipment-list/shipment-list-actions/shipment-list-actions.component';
import { ShipmentSidePanelComponent } from './shipments/shipment-list/shipment-side-panel/shipment-side-panel.component';
import { ShipmentStandardComponent } from './shipments/shipment-list/shipment-side-panel/shipment-standard/shipment-standard.component';
import { ShipmentParcelComponent } from './shipments/shipment-list/shipment-side-panel/shipment-parcel/shipment-parcel.component';
import { CurrencyRangeComponent } from './common/components/currency-range/currency-range.component';
import { ListSearchComponent } from './common/components/list-search/list-search.component';
import { HelpDialogComponent } from './common/components/help-dialog/help-dialog.component';
import { ShipmentTagFiltersComponent } from './shipments/components/shipment-tag-filters/shipment-tag-filters.component';
import { TableSurtitleComponent } from './common/components/table-surtitle/table-surtitle.component';
// import { ShipmentSummaryComponent } from './common/components/shipment-summary/shipment-summary.component';
import { ShipmentManagetagsComponent } from './shipments/components/shipment-managetags/shipment-managetags.component';
import { MonitorComponent } from './analytics/analytics-monitor/monitor.component';
import { LibraryComponent } from './analytics/analytics-library/library-list/library.component';
import { LibrarySidePanelComponent } from './analytics/analytics-library/library-side-panel/library-side-panel.component';
import { LibrarySummaryComponent } from './analytics/analytics-library/library-summary/library-summary.component';
import { ShipmentTrackingProgressComponent } from './shipments/components/shipment-tracking-progress/shipment-tracking-progress.component';
import { ShipmentRouterPlanComponent } from './shipments/components/shipment-router-plan/shipment-router-plan.component';
import { RulesetSummaryComponent } from './business-rules/ruleset-list/ruleset-summary/ruleset-summary.component';
import { TimerSnackComponent } from './common/components/timer-snack/timer-snack.component';
import { ShipmentDetailsComponent } from './shipments/shipment-details/shipment-details.component';
import { ShipmentDetailsActionsComponent } from './shipments/shipment-details/shipment-details-actions/shipment-details-actions.component';
import { ShipmentDetailsSummaryComponent } from './shipments/shipment-details/shipment-details-summary/shipment-details-summary.component';
import { ShipmentDetailsMapComponent } from './shipments/shipment-details/shipment-details-map/shipment-details-map.component';
import { ShipmentDetailsEnvConditionsComponent } from './shipments/shipment-details/shipment-details-env-conditions/shipment-details-env-conditions.component';
import { ShipmentDetailsIncludedAssetsComponent } from './shipments/shipment-details/shipment-details-included-assets/shipment-details-included-assets.component';
import { NewShipmentTagsComponent } from './shipments/components/new-shipment-tags/new-shipment-tags.component';
//import { FormateDatePipe } from './common/pipes/formate-date.pipe';
import { ShipmentSummaryDataComponent } from './shipments/components/shipment-summary-data/shipment-summary-data.component';
import { VendorAccountService } from './settings/vendor-accounts/vendor-account.service';
import { VendorListComponent } from './settings/vendor-accounts/vendor-list/vendor-list.component';
import { VendorAccountSidePanelComponent } from './settings/vendor-accounts/vendor-account-side-panel/vendor-account-side-panel.component';
import { CredentialsDialogComponent } from './settings/vendor-accounts/vendor-account-credentials/account-credentials-dialog.component';
import { FormateDatePipe } from './common/pipes/formate-date.pipe';
import { BiWorkbenchComponent } from './analytics/bi-workbench/bi-workbench.component';
import { DeviceProfileListComponent } from './sensor-fabric/device-profiles/device-profile-list/device-profile-list.component';
import { DeviceProfileSummaryPanelComponent } from './sensor-fabric/device-profiles/device-profile-summary-panel/device-profile-summary-panel.component';
import { EditDeviceProfilePanelComponent } from './sensor-fabric/device-profiles/edit-device-profile-panel/edit-device-profile-panel.component';
import { DeviceProfileService } from './sensor-fabric/device-profiles/device-profile.service';
import { SensorConditionsComponent } from './sensor-fabric/sensor/sensor-details/sensor-conditions/sensor-conditions.component';
import { SvgIconComponent } from './common/components/svg-icon/svg-icon.component';
import { ShipmentDetailsShareComponent } from './shipments/shipment-details/shipment-details-share/shipment-details-share.component';
import { AgmOverlays } from 'agm-overlays';
import { ShipmentRuleSetComponent } from './shipments/components/shipment-rule-set/shipment-rule-set.component';
import { NotificationDialogComponent } from './common/components/notification-dialog/notification-dialog.component';
import { SensorInputBindDialogComponent } from './common/components/sensor-input-bind-diolog/sensor-input-bind-diolog';
import { AlertsResolveDialogComponent } from './common/components/alerts-resolve-dialog/alerts-resolve-dialog.component';
import { CustomDatePickerComponent } from './shipments/components/shipment-date-picker/custom-date-picker.component';
import { ApiReferenceComponent } from './common/api-reference/api-reference.component';
import { MlWorkbenchComponent } from './machine-learning/ml-workbench/ml-workbench.component';
import { OrgMismatchSnackbarComponent } from './common/components/org-mismatch-snackbar/org-mismatch-snackbar.component';
// import { ShipmentTrackerComponent } from './shipments/components/shipment-tracker/shipment-tracker.component';
import { LocationSidePanelComponent } from './locations/location-list/location-side-panel/location-side-panel.component';
import { LocationAreaComponent } from './locations/location-list/location-side-panel/location-area/location-area.component';
import { LocationSiteComponent } from './locations/location-list/location-side-panel/location-site/location-site.component';
import { LocationRouteComponent } from './locations/location-list/location-side-panel/location-route/location-route.component';
import { LocationGatewayZoneComponent } from './locations/location-list/location-side-panel/location-gateway-zone/location-gateway-zone.component';
import { BulkActionComponent } from './common/components/bulk-action/bulk-action.component';
import { AgmDirectionModule } from 'agm-direction';
import { LocationAddEditComponent } from './locations/location-list/location-side-panel/location-add-edit/location-add-edit.component';
import { DynamicFormRendererComponent } from './common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { SkuListComponent } from './sku/sku-list.component';
import { InventoryComponent } from './inventory/inventory.component';
import { EntityService } from './common/services/entity-list.service';
import { InventorySummaryComponent } from './common/components/inventory-summary/inventory-summary.component';
import { SkuSummaryComponent } from './common/components/sku-summary/sku-summary.component';
import { SkuAddPanelComponent } from './sku/sku-add-panel/sku-add-panel.component';
import { SkuDetailsComponent } from './sku/sku-details/sku-details.component';
import { InventoryDetailsComponent } from './inventory/inventory-details/inventory-details.component';
import { InventoryCreateEditComponent } from './inventory/inventory-create-edit/inventory-create-edit.component';
import { SortPipe } from './common/pipes/sort.pipe';
import { DeviceAddComponent } from './sensor-fabric/sensor/components/device-add/device-add.component';
import { AssetAddPanelComponent } from './asset/asset-add-panel/asset-add-panel.component';
import { AddApiSubscriptionComponent } from './settings/api-subscription/add-api-subscription/add-api-subscription.component';
import { ApiSubscriptionSummaryPanelComponent } from './settings/api-subscription/api-subscription-summary-panel/api-subscription-summary-panel.component';
import { GatewayAddPanelComponent } from './sensor-fabric/gateway/gateway-add-panel/gateway-add-panel.component';
import {LocationRouteDetailsComponent} from './locations/location-route-details/location-route-details.component';
import { EditCredentialsDialogComponent } from './settings/api-subscription/edit-credentials-dialog/edit-credentials-dialog.component'
import {BulkOperaionsService} from './bulk-operation/bulk-operations-service';
import { BulkSummaryComponent } from './common/components/bulk-summary/bulk-summary.component';
import { ThemeComponent } from './property/theme/theme.component';
import { AppSettingsComponent } from './property/app-settings/app-settings.component';
import { TranslationsComponent } from './property/translations/translations.component';
import { PropertyCoreComponent } from './property/property-core/property-core.component';
import { MenuIconComponent } from './@core/layout/menu-icon/menu-icon.component'
import { ScanActionMenuComponent } from './common/components/scan-action-menu/scan-action-menu.component';
import { CodeScannerComponent } from './common/components/code-scanner/code-scanner.component'
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { AutoFocusDirective } from './common/directives/auto-focus.directive';
import { RecipeActionsComponent } from './common/components/recipe-actions/recipe-actions.component'
import { ShipmentCompositeComponent } from './shipments/shipment-list/shipment-side-panel/shipment-composite/shipment-composite.component';
import { ShipmentCompositeCardsComponent } from './shipments/shipment-details/shipment-composite-cards/shipment-composite-cards.component';
import { SimulateListComponent } from './simulate/simulate-list/simulate-list.component';
import { SimulateDetailsComponent } from './simulate/simulate-details/simulate-details.component';
import { AceEditorModule } from 'ng2-ace-editor';
import { RecipesListComponent } from './recipes/recipes-list/recipes-list.component';
import { RecipeDetailsComponent } from './recipes/recipe-details/recipe-details.component';
import { RecipeAddPanelComponent } from './recipes/recipe-add-panel/recipe-add-panel.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';


import { SftpUserComponent } from './user/sftp-user/sftp-user.component';
import { ContentDialogComponent } from './common/components/content-dialog/content-dialog.component';
import { OrgInfoIconComponent } from './common/components/org-info-icon/org-info-icon.component';
const routes: CustomRoute[] = NavService.getActivatedRoutes();

@NgModule({
  declarations: [
    ShellComponent,
    AssetListComponent,
    BulkOperationListComponent,
    RuleListComponent,
    RulesetListComponent,
    LeaseListComponent,
    GatewayListComponent,
    SensorListComponent,
    SensorTypeListComponent,
    ApiSubscriptionComponent,
    TagListComponent,
    TenantSettingsComponent,
    ShipmentListComponent,
    UserListComponent,
    UserPolicyListComponent,
    UserGroupListComponent,
    UserProfileListComponent,
    TenantListComponent,
    TenantSidePanelComponent,
    TrackersListComponent,
    HeaderComponent,
    SecondaryHeaderComponent,
    UserRoleListComponent,
    GridColumnToggleComponent,
    ExportPanelComponent,
    AssetsFilterComponent,
    ListFilterComponent,
    // CategoryManagerComponent,
    // StartCasePipe,
    // StateActionMapPipe,
    // TruncatePipe,
    BottomSheetComponent,
    CheckoutTransferConfirmDialogComponent,
    ConfirmDialogComponent,
    ContentDialogComponent,
    NotificationDialogComponent,
    OrgInfoIconComponent,
    SensorInputBindDialogComponent,
    // InspectorComponent,
    // ManageInspectorComponent,
    DropdownMenuComponent,
    AssetDetailsComponent,
    DetailsSummaryComponent,
    NotificationListComponent,
    AssetActivityComponent,
    CharacteristicListComponent,
    MeasurePipe,
    NotificationListComponent,
    LoginComponent,
    ResetPasswordComponent,
    LineChartComponent,
    SidePanelComponent,
    LocationListComponent,
    UserDetailSummaryComponent,
    ApiUserDialogComponent,
    LocationListComponent,
    SingleMarkerMapComponent,
    AssetHistoryComponent,
    AssetBinderComponent,
    LocationDetailsComponent,
    LocationRouteDetailsComponent,
    LocationDetailFiltersComponent,
    BarChartComponent,
    CocMenuComponent,
    SensorDetailsComponent,
    SensorActivityComponent,
    SensorSummaryComponent,
    GatewayDetailsComponent,
    GatewaySummaryComponent,
    GatewayActivityComponent,
    TimesincePipe,
    TemperaturePipe,
    SensorBinderComponent,
    GatewayFilterComponent,
    GatewayBinderComponent,
    RoleSidePanelComponent,
    BulkActionMenuComponent,
    BulkActionPanelComponent,
    PolicySidePanelComponent,
    UserGroupSidePanelComponent,
    MyAccountComponent,
    PersonalDetailsComponent,
    AlertsComponent,
    NotificationsComponent,
    NotificationItemComponent,
    BreadcrumbComponent,
    DateFromStringPipe,
    GatewayOverallStatusPipe,
    UnescapePipe,
    SensorReplaceComponent,
    DatePickerComponent,
    IsValidActionDirective,
    AddRuleComponent,
    AddRuleSetComponent,
    DurationComponent,
    ThresholdComponent,
    // NodeInspectorComponent,
    // CheckoutAndTransferComponent
    MonitorComponent,
    LibraryComponent,
    LibrarySidePanelComponent,
    MetaPipe,
    ShipmentsFliterComponent,
    DateRangeFilterComponent,
    ShipmentListTableComponent,
    ShipmentListActionsComponent,
    ShipmentSidePanelComponent,
    ShipmentStandardComponent,
    ShipmentParcelComponent,
    CurrencyRangeComponent,
    ListSearchComponent,
    HelpDialogComponent,
    ShipmentTagFiltersComponent,
    TableSurtitleComponent,
    // ShipmentSummaryComponent,
    TableSurtitleComponent,
    LibrarySummaryComponent,
    ShipmentManagetagsComponent,
    // ShipmentTrackingProgressComponent,
    // ShipmentRouterPlanComponent,
    LibrarySummaryComponent,
    RulesetSummaryComponent,
    TimerSnackComponent,
    BiWorkbenchComponent,
    ShipmentDetailsComponent,
    ShipmentDetailsActionsComponent,
    ShipmentDetailsSummaryComponent,
    ShipmentDetailsMapComponent,
    ShipmentDetailsEnvConditionsComponent,
    ShipmentDetailsIncludedAssetsComponent,
    NewShipmentTagsComponent,
   // FormateDatePipe,
    // ShipmentSummaryDataComponent,
    VendorListComponent,
    VendorAccountSidePanelComponent,
    CredentialsDialogComponent,
    DeviceProfileListComponent,
    DeviceProfileSummaryPanelComponent,
    EditDeviceProfilePanelComponent,
    SensorConditionsComponent,
    SvgIconComponent,
    ShipmentDetailsShareComponent,
    ShipmentRuleSetComponent,
    AlertsResolveDialogComponent,
    CustomDatePickerComponent,
    ApiReferenceComponent,
    DynamicFormRendererComponent,
    OrgMismatchSnackbarComponent,
    MlWorkbenchComponent,
    SkuListComponent,
    InventoryComponent,
    InventorySummaryComponent,
    SkuSummaryComponent,
    SkuAddPanelComponent,
    SkuDetailsComponent,
    InventoryDetailsComponent,
    InventoryCreateEditComponent,
    LocationSidePanelComponent,
    LocationAreaComponent,
    LocationSiteComponent,
    LocationRouteComponent,
    DynamicFormRendererComponent,
    AssetAddPanelComponent,
    LocationGatewayZoneComponent,
    BulkActionComponent,
    LocationRouteDetailsComponent,
    LocationAddEditComponent,
    // ShipmentTrackerComponent,
    SortPipe,
    DeviceAddComponent,
    AssetAddPanelComponent,
    AddApiSubscriptionComponent,
    ApiSubscriptionSummaryPanelComponent,
    GatewayAddPanelComponent,
    LocationRouteDetailsComponent,
    AddApiSubscriptionComponent,
    EditCredentialsDialogComponent,
    BulkSummaryComponent,
    ThemeComponent,
    AppSettingsComponent,
    TranslationsComponent,
    PropertyCoreComponent,
    MenuIconComponent,
    RecipeActionsComponent,
    ShipmentCompositeComponent,
    ScanActionMenuComponent,
    CodeScannerComponent,
    AutoFocusDirective,
    ShipmentCompositeCardsComponent,
    SimulateListComponent,
    SimulateDetailsComponent,
    RecipesListComponent,
    RecipeDetailsComponent,
    RecipeAddPanelComponent,
    SftpUserComponent
    ],
  imports: [
    MaterialModule,
    CommonModule,
    BrowserModule,
    GooglePlaceModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ScrollingModule,
    NgxFileDropModule,
    HttpClientXsrfModule.withOptions({cookieName: 'CSRF-TOKEN', headerName: 'x-csrf-token'}),
    RouterModule.forRoot(routes, {useHash: true}),
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useClass: TranslationLoaderService}
    }),
    NoopAnimationsModule,
    NavigatorModule,
    NgxsModule.forRoot([PanelsState, ClientConfigState, UserState, AppState, MdListState, PanelsState], {
      developmentMode: !environment.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      // Do not collapse log groups
      collapsed: true,
      // Do not log in production mode
      disabled: true, // environment.production,
      // Do not log SomeAction
      // filter: action => getActionTypeFromInstance(action) !== SomeAction.type
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      // Do use devtools in production mode
      disabled: environment.production,
    }),
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBFrngKBmi7UwDVsNcRmOPnOVpaUbJYbn0",
      libraries: ['places', 'drawing', 'geometry']
    }),
    // NgxsModule.forRoot([MdListState, PanelsState]),
    InlineSVGModule.forRoot(),
    SharedModule,
    NgxDaterangepickerMd.forRoot(),
    NgApexchartsModule,
    AgmDirectionModule,
    NgxScannerQrcodeModule,
    AceEditorModule,
    CodemirrorModule
    
  ],
  providers: [
    DatePipe,
    NavService,
    UserService,
    PersistenceService,
    ToastService,
    AuthorizeService,
    PanelsState,
    ClientConfigState,
    UserState,
    AppState,
    PanelStatesService,
    ConfigService,
    ClientConfigService,
    TemplateService,
    AssetListService,
    // ChainOfCustodyService,
    GraphAPIService,
    SearchApiService,
    UtilsService,
    SortUtilService,
    FiltersUtilService,
    { provide: HTTP_INTERCEPTORS, useExisting: HttpStatusInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CsrfTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenentTokenInterceptor, multi: true },
    TenantService,
    AssetService,
    AssetUtilService,
    SensorService,
    SensorUtilService,
    GatewayService,
    GatewayUtilService,
    TimesincePipe,
    TemperaturePipe,
    TranslatePipe,
    DateFromStringPipe,
    TenantUtilService,
    RouteGuard,
    CategoryService,
    VendorAccountService,
    FormateDatePipe,
    DeviceProfileService,
    EntityService,
    SortPipe,
    BulkOperaionsService,
    TitleCasePipe
  ],
  bootstrap: [
    ShellComponent
  ]
})
export class AppModule { }
