import { Component, Input } from "@angular/core";

@Component({
  selector: "button-tab",
  template: `
    <div class="button-tab-container p-2" *ngIf="active">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .button-tab-container {
        position: relative;
      }
    `,
  ],
})
export class ButtonTabComponent {
  @Input() id: string;
  @Input() active = false;
  @Input() tabTitle = "";
  @Input() faIcon = "";
}
