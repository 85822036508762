import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnalyticsService } from '@cl/analytics/analytics.service';
import { UserService } from '@cl/user/user.service';
import _ from 'lodash';

@Component({
  selector: 'cl-library-summary',
  templateUrl: './library-summary.component.html',
  styleUrls: ['./library-summary.component.scss']
})
export class LibrarySummaryComponent implements OnInit, OnChanges {
  @Input() dashBoard: any;
  @Output() closeLibrarySummarySidePanel = new EventEmitter();
  activePane: string = 'librarySummary';
  urlSafe: SafeResourceUrl = "";
  loginUserTenantId: string = '';
  md;
  loading: boolean;
  constructor(
    public sanitizer: DomSanitizer,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnChanges(): void {
  }

  ngOnInit(): void {
    this.loginUserTenantId = this.userService.getEffectiveTenantId();
    if (this.dashBoard) {
      let safe_url = this.analyticsService.getSafeUrl(this.dashBoard.dashboardUrl);
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(safe_url + '/%3Fstandalone%3D2%26tenant_id=' + this.loginUserTenantId);
    }
  }

  closePanel() {
    this.closeLibrarySummarySidePanel.emit();
  }

}
