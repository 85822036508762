<div class="mt-2 mb-2">
    <span class="page-title">Admin - Users</span>
</div>
<cl-table-surtitle [rows]="rows.length" [selected]="selected.length" [total]="rows.length" [pageName]="'Users'"></cl-table-surtitle>
<div class="wt-ngx-grid-wrapper relative h-calc-60 list-page px-2">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list">
            <div class="add-button" [clIsValidAction]="actionBtnPolicies.add" (click)="addUser()" *ngIf="!showSummaryPanel && tenantType">
                <span class="fa fa-plus"></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer list-page_filter-toggle color4 flex items-center">
              <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
              filter options
            </span>
            <cl-list-search [placeholder]="'Search by Name or ID'" (search)="updateFilter($event)"
                 [columns] = "['User ID', 'Name']"></cl-list-search>
            <button
                [clIsValidAction]="actionBtnPolicies.edit"
                [disabled]="editDisabled"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                [disabled]="editDisabled"
                class="p-4-10 border-general br2 mr-2" (click)="editUser()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>

            <button 
                [disabled]="deleteDisabled"
                [ngClass]="[deleteDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                [clIsValidAction]="actionBtnPolicies.delete"
                [disabled]="deleteDisabled"
                class="p-4-10 border-general br2 mr-2" (click)="deleteUser()">
                <i class="fa fa-trash"></i>
                Delete
            </button>

            <button [disabled]="resetDisabled" [ngClass]="[resetDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                [clIsValidAction]="actionBtnPolicies.edit"
                class="p-4-10 border-general br2 mr-2" (click)="resetPassword()"> Reset Password
                <span class="fa fa-circle-o-notch fa-pulse" *ngIf="isResetting"></span>
            </button>
            <div *ngIf="tenantType" class="bulk-menu">
                <cl-bulk-action-menu [entity]="'user'" [menuOptions]="bulkactions"
                    (bulkPanelObject)="bulkPanelHandler($event)"></cl-bulk-action-menu>
            </div>
        </div>
        <div class="user__action-panel-right action-panel-right self-center flex items-center">
            <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'user'">
            </cl-grid-column-toggle>
        </div>
    </div>
    <div class="h-calc-60">
        <main class="flex flex-row h-100" [style.width.px]="mainWidth - 10">
            <div class="grow-0 nav panel scroll-y" *ngIf="showFilterPanel">
            <cl-list-filter class="policy__listing__filter" [hideTitle]="true" [showCounts]="false" [title]="''"
                heightClass="none" (panelsEmitter)="getPanelUpdate($event)"
                (toggleFilterPanel)="toggleFilterPanel()" [filters]="filters" [actionCount]="actionCount">
            </cl-list-filter>
            </div>
            <div class="grid-container h-100 grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div *ngIf="delayedRefreshStart">
                    <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                    (refreshList)="delayedRefreshStart = false; getusers()"
                    ></cl-refresh-countdown>
                </div>
                <div class="h-100" [style.width.px]="tableWidth">
                    <ngx-datatable #listTable class="h-100" (reorder) = "columnReordered($event)" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" [rowIdentity]="getId" [sortType]="sortType"
                        [selectionType]="selection" [selectAllRowsOnPage]="false" (select)='onSelect($event)'[reorderable]="false">
                    </ngx-datatable>
                </div>
            </div>
        </main>

        <ng-template #userIdTemplate let-row="row" let-value="value" let-i="index">
            <a class="font-12-bold" href (click)="loadSummaryPanel(row, $event)">{{value}}</a>
        </ng-template>
    </div>

    <div class="summery-pannel" *ngIf="showSummaryPanel === true">
        <cl-user-detail-summary [userObject]="selectedUser" [mode]="mode" (closeSidePanel)="closeSidePanel($event)" (operationInprogress)="operationInprogressState($event)">
        </cl-user-detail-summary>
    </div>

    <div *ngIf="sidePanelsObj.bulk">
        <cl-bulk-action-panel [panelObj]="bulkPanelObj" (closeSidePanel)="closeSidePanel()"
            (refreshParentList)="delayedRefreshStart = true;"></cl-bulk-action-panel>
    </div>

</div>
