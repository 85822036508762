import * as G from "../colors";


export class ServerSubCluster {
  key: string;
  count: number;

  constructor(key: string, count: number) {
    this.key = key;
    this.count = count;
  }
}

export class ServerCluster {

  private static STACK_SVG_HEIGHT = 17;
  private static STACK_SVG_WIDTH = 28; // 28 - 4
  private static STACK_SVG_HORIZONTAL_PADDING = 7; // 28 - 4
  private static STACK_CHAR_SIZE = 7;
  private static STACK_SVG_RADIUS = 5;

  lat: number;
  lng: number;
  count: number;
  key: string;
  items?: ServerSubCluster[];

  readonly svgIconUrl: string;

  svg = "";

  constructor(key: string, count: number, lat: number, lng: number, items: ServerSubCluster[] = []) {
    this.key = key;
    this.lat = lat;
    this.lng = lng;
    this.count = count;
    this.items = items;
    // this.items = [...items, ...items.map((i, index) => ({key: index === 0 ? "shipment" : "zone", count: i.count + index * 2 }))];
    this.svgIconUrl = this.toSvgIcon();
  }

  private getLabelSvg(label: string, baseClass: string, shift: boolean, digitsLen: number) {
    const len = 22;
    const nameLength = label?.length ?? 0;
    const nameDisplay = nameLength > len ? label.slice(0, len) + "..." : label;
    let nameWidth = nameDisplay && nameDisplay.length ? nameDisplay.length * 3.25 + 75 : 0;
    nameWidth += shift ? 10 : 0;
    let xText = shift && baseClass === "shipment" ? 40 : 32;
    xText += digitsLen * 3;

    return `<g fill="none" fill-rule="evenodd" transform="translate(7 16.5)">` +
        `<rect fill="#fff" stroke="#333" x="12" y="4" width="${ nameWidth }" height="18" rx="4" />` +
        `<text x="${ xText }" y="17" class="nameText">${ nameDisplay }</text>` +
      `</g>`;
  }

  /**
   * Constructs counts svgs for the current cluster
   */
  private getCountsSvg(geoHash: string, items: ServerSubCluster[] = []) {
    if (items.length > 1) {
      return this.getStackedCountSvg(geoHash, items);
    } else {
      return this.getCircleCountSvg(geoHash, items);
    }
  }

  /**
   * Gets the radius circle count requires
   */
  private getCircleRadius(count: number) {
    const digitsLen = count.toString().length;
    return Math.max(digitsLen * 4.5, 12);
  }

  /**
   * Gets circular svg map icon. Use this when there is only one item per cluster.
   */
  private getCircleCountSvg(geoHash: string, items: ServerSubCluster[]) {
    const count = items[0].count;
    const radius = this.getCircleRadius(count);

    const yTextPosition = radius + 3.5;
    const blobX = 7;
    const blobY = 28 - (4.5 * radius) / 5;

    const blobColor = (G.DomainColors as any)[items[0].key] ?? G.DomainColors.default;

    const circleSvg = new Array<string>(
      `<g fill="none" fill-rule="evenodd" >`,
        `<circle fill="${ blobColor }" cx="${ radius }" cy="${ radius }" r="${ radius }" />`,
        `<text x="${ radius }" y="${ yTextPosition }" text-anchor="middle"  class="svg-count-text">${ count }</text>`,
      `</g>`
    );

    return circleSvg.join("");
  }

  /**
   * Constructs stacked svg design
   */
  private getStackedCountSvg(geoHash: string, items: ServerSubCluster[]) {
    const maxCountLength = (Math.max(...items.map(c => c.count)) + "").length;
    const width = Math.max(
      ServerCluster.STACK_CHAR_SIZE * maxCountLength + ServerCluster.STACK_SVG_HORIZONTAL_PADDING,
      ServerCluster.STACK_SVG_WIDTH,
    );

    const clipDefSvgs = new Array<string>("<defs>", "</defs>");
    const rectSvgs = new Array<string>();
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const id = geoHash + item.key;

      let stackIndex = "clip-path-";
      if (i === 0) stackIndex += "first";
      else if (i === items.length - 1) stackIndex += "last";
      else stackIndex += i;

      if (i === 0 || i === items.length - 1)
        clipDefSvgs.splice(1, 0, this.getRectClipPath(stackIndex, 0, (ServerCluster.STACK_SVG_HEIGHT * i), width));

      rectSvgs.push(this.getRect(stackIndex, item.key, item.count, 0, (ServerCluster.STACK_SVG_HEIGHT * i), width));
    }

    return [...clipDefSvgs, ...rectSvgs].join("");
  }

  private getRect(
    id: string,
    type: string,
    count: number,
    x = 0,
    y = 0,
    width = ServerCluster.STACK_SVG_WIDTH,
    height = ServerCluster.STACK_SVG_HEIGHT
  ) {
    const fillColor = (G.DomainColors as any)[type] ?? G.DomainColors.default;

    const digitsLen = count.toString().length;

    const svgs = new Array<string>();

    const clipPathsIds = ["clip-path-first", "clip-path-last"];
    const isFirst = clipPathsIds.indexOf(id) === 0;
    const isLast = clipPathsIds.indexOf(id) === 1;
    const isFirstOrLastStack = isFirst || isLast;

    const rectRadius = isFirstOrLastStack ? ServerCluster.STACK_SVG_RADIUS : 0;

    // const xTextPosition =  Math.round((digitsLen * ServerCluster.STACK_CHAR_SIZE / 2) + (ServerCluster.STACK_SVG_HORIZONTAL_PADDING / 2));
    const xTextPosition =  width / 2;
    const yTextPosition = 12 + y;

    height = isFirstOrLastStack ? height + 2 : height;
    // Modify vertical alignment of svg for first and last stack
    // y = isFirst ? y + 2 : y;
    y = isLast ? y - 4 : y;


    // const label = _.startCase(type) + (count > 1 ? "s" : "");
    // svgs.push(`<g style="z-index: 99"><title>${count} ${label}</title>`);

    if (isFirstOrLastStack) {
      // Line clipping required to hide borders radius
      svgs.push(`<rect x="${x}" y="${y}" rx="${rectRadius}" ry="${rectRadius}" width="${width}" height="${height + 2}" style="fill: ${fillColor};" clip-path="url(#${id})" />`);
    } else {
      // No line clipping required with no border radius
      svgs.push(`<rect x="${x}" y="${y}" width="${width}" height="${height}" style="fill: ${fillColor};"/>`);
    }

    svgs.push(`<text x="${ xTextPosition }" y="${ yTextPosition }" text-anchor="middle"  class="svg-count-text">${ count }</text>`)

    // svgs.push("</g>");

    return svgs.join("");
  }

  private getRectClipPath(id: string, x = 0, y = 0, width = ServerCluster.STACK_SVG_WIDTH, height = ServerCluster.STACK_SVG_HEIGHT) {
    return `<clipPath id="${id}"><rect x="${x}" y="${y}" width="${width}" height="${height}" rx="0" ry="0"/></clipPath>`
  }

  private getViewBox(width: number, height: number, scalingFactor: number, items: ServerSubCluster[] = []) {
    const viewBoxWidth = width - scalingFactor;
    const viewBoxHeight = height - scalingFactor;
    let centeredHeight = 0;
    let centeredWidth = 0;

    if (items.length > 1) {
      // Stacked count
      const totalHeight = ServerCluster.STACK_SVG_HEIGHT * items.length;
      centeredHeight = totalHeight < viewBoxHeight ? -(viewBoxHeight - totalHeight) : totalHeight;
      centeredWidth = Math.ceil((viewBoxWidth - ServerCluster.STACK_SVG_WIDTH) / -2);
    } else {
      // Circular count
      const size = this.getCircleRadius(items[0].count) * 2;
      centeredHeight = -(viewBoxHeight - size);
      centeredWidth = Math.ceil((viewBoxWidth - size) / -2);
    }

    return `${centeredWidth} ${centeredHeight} ${viewBoxWidth} ${viewBoxHeight}`;
  }

  toSvgIcon(): string {
    const svgWidth = 120;
    const svgHeight = 120;
    const viewBox = this.getViewBox(svgWidth, svgHeight, 20, this.items);
    const svgs = new Array<string>(
      `<svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="${viewBox}">`,
      `<style>`,
      `.label-text, .svg-count-text { font-size:10px; font-weight: 500; font-family: 'latoregular', arial, sans-serif; }`,
      `.svg-count-text { fill: #fff; }`,
      `.label-text { fill: #333; }`,
      `</style>`,
      this.getCountsSvg(this.key, this.items),
      `</svg>`
    );

    this.svg = svgs.join("\n");
    return "data:image/svg+xml;charset=utf8," + escape(svgs.join(""));
  }
}

export declare type ServerClusterList = Array<ServerCluster>;