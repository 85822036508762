import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: "root",
})
export class IconSvgService {
  iconTemplates = {
    site: {
      hasSVG: true,
    },
    building: {
      hasSVG: true,
      use: "location",
    },
    airport: {
      hasSVG: true,
      use: "plane",
    },
    location: {
      hasSVG: true,
      use: "site",
    },
    gateway: {
      hasSVG: true,
    },
    asset: {
      hasSVG: true,
    },
    bottle: {
      hasSVG: true,
      use: "asset",
    },
    rkn: {
      hasSVG: true,
      use: "asset",
    },
    rkp: {
      hasSVG: true,
      use: "asset",
    },
    plasma: {
      hasSVG: true,
      use: "inventory",
    },
    pharmaceuticals: {
      hasSVG: true,
      use: "inventory",
    },
    taggedasset: {
      hasSVG: true,
      use: "asset",
    },
    sensor: {
      hasSVG: true,
    },
    route: {
      hasSVG: true,
    },
    routenode: {
      hasSVG: true,
      use: "route",
    },
    group: {
      iconClass: "fa-line-chart",
      iconCode: "\uF201",
      yOffset: 8,
    },
    geofence: {
      hasSVG: true,
    },
    inventory: {
      hasSVG: true,
    },
    pharmaceutical: {
      hasSVG: true,
      use: "inventory",
    },
    container: {
      hasSVG: true,
      use: "inventory",
    },
    maintenance: {
      iconClass: "fa-wrench fa-lg",
      iconCode: "\uF0ad",
      yOffset: 0,
    },
    dwelltime: {
      hasSVG: true,
    },
    state_change: {
      hasSVG: true,
    },
    action: {
      hasSVG: true,
      use: "state_change",
    },
    movement: {
      hasSVG: true,
    },
    shipment: {
      hasSVG: true,
    },
    ship: {
      hasSVG: true,
      use: "shipment",
    },
    shipmentstatus: {
      hasSVG: true,
      use: "condition",
    },
    trailer: {
      hasSVG: true,
    },
    truck: {
      hasSVG: true,
      use: "trailer",
    },
    terrestrial: {
      hasSVG: true,
      use: "trailer",
    },
    vehicle: {
      hasSVG: true,
      use: "trailer",
    },
    condition: {
      hasSVG: true,
    },
    eta: {
      hasSVG: true,
    },
    incident: {
      hasSVG: true,
    },
    shipmentstatusnode: {
      hasSVG: true,
      use: "condition",
    },
    pressure: {
      hasSVG: true,
    },
    statenode: {
      hasSVG: true,
      use: "condition",
    },
    infraction: {
      hasSVG: true,
    },
    shock: {
      hasSVG: true,
    },
    temperature: {
      hasSVG: true,
    },
    humidity: {
      hasSVG: true,
    },
    leak: {
      hasSVG: true,
      use: "humidity",
    },
    waterleak: {
      hasSVG: true,
      use: "humidity",
    },
    rule: {
      hasSVG: true,
    },
    ruletemplate: {
      hasSVG: true,
      use: "category",
    },
    damaged: {
      hasSVG: true,
    },
    outofstock: {
      hasSVG: true,
    },
    glut: {
      hasSVG: true,
      use: "count",
    },
    unproductiveinventory: {
      hasSVG: true,
      use: "asset",
    },
    shipdatedelays: {
      hasSVG: true,
      use: "eta",
    },
    state: {
      hasSVG: true,
      use: "condition",
    },
    rack: {
      hasSVG: true,
      use: "asset",
    },
    mobiletracker: {
      hasSVG: true,
    },
    tracker: {
      hasSVG: true,
      use: "mobiletracker",
    },
    device: {
      hasSVG: true,
      use: "mobiletracker",
    },
    area: {
      hasSVG: true,
      use: "zone",
    },
    zone: {
      hasSVG: true,
      use: "zone",
    },
    organization: {
      hasSVG: true,
    },
    digitaltwin: {
      hasSVG: true,
      use: "organization",
    },
    headquarters: {
      hasSVG: true,
      use: "organization",
    },
    party: {
      hasSVG: true,
      use: "organization",
    },
    notification: {
      hasSVG: true,
    },
    category: {
      hasSVG: true,
    },
    count: {
      hasSVG: true,
    },
    timer: {
      hasSVG: true,
    },
    sms: {
      hasSVG: true,
      use: "notification",
    },
    light: {
      hasSVG: true,
    },
    base: {
      hasSVG: true,
      use: "condition",
    },
    cloudapp: {
      hasSVG: true,
      use: "light",
    },
    messagequeue: {
      hasSVG: true,
      use: "message",
    },
    ruleaction: {
      hasSVG: true,
      use: "rule",
    },
    inapp: {
      hasSVG: true,
      use: "rule",
    },
    email: {
      hasSVG: true,
      use: "message",
    },
    webhook: {
      hasSVG: true,
      use: "state_change",
    },
    sub_assets: {
      hasSVG: true,
    },
  };

  //this should replace iconTemplates
  svgMap = {
    building: "site",
    location: "site",
    sites: "site",
    incident: "alert",
    asset: "category",
    assets: "category",
    ship: "shipment",
    shipments: "shipment",
    airport: "plane",
    fieldtech: "users",
  };
  constructor() {}

  hasSVG(el) {
    const template = this.getSVGName(el, "");
    return this.iconTemplates[template] && this.iconTemplates[template].hasSVG ? true : false;
  }
  getSVG(token) {
    token = token.toLowerCase().replace("_", "").replace("node", "");
    return this.svgMap[token] || token;
  }
  getIcon(token) {
    return this.svgMap[token] || token;
  }

  // Use this but with svgMap
  getIcon2(token, token2) {
    token2 = token2 ? token2.toLowerCase() : "site";

    if (token && this.iconTemplates[token]) {
      return this.iconTemplates[token] && this.iconTemplates[token].use ? this.iconTemplates[token].use : token;
    } else if (token2 && this.iconTemplates[token2]) {
      return this.iconTemplates[token2] && this.iconTemplates[token2].use ? this.iconTemplates[token2].use : token2;
    } else {
      return this.iconTemplates[token2] ? token2 : "site";
    }
  }

  // Get rid of this mess!
  getSVGName(el, type) {
    let svgName = "site";
    if (!el) {
      return "site";
    }
    let token = el.category || el.nodeClass || el.template;
    token = token ? token.toLowerCase() : "";
    if (token && this.iconTemplates[token] && this.iconTemplates[token].use) {
      return this.iconTemplates[token].use;
    } else if (token === "shipment" && el.properties) {
      // console.log('this shipment', el);
      return el.properties.mode === "Ocean" ? "shipment" : el.properties.mode === "Air" ? "plane" : "truck";
    } else if (this.iconTemplates[token]) {
      // console.log('this', token, el);
      return token;
    }
    if (el.type === "link" || el.type === "link") {
      return "link";
    } else if (!el || !this.iconTemplates[el.template] || !this.iconTemplates[el.template].hasSVG) {
      return "site";
    } else if (type === "condition_threshold" && el.br_tab_icon) {
      return el.br_tab_icon;
    } else if (el.template === "rule") {
      if (el.template && el.properties && el.properties.triggerToken) {
        svgName = el.properties.triggerToken;
      } else if (el.subTemplate) {
        svgName = el.subTemplate;
      } else {
        svgName = "rule";
      }
    } else if (el.type === "group") {
      svgName = "route";
    } else if (el.icon) {
      svgName = el.icon;
    } else if (el.objecttype) {
      svgName = el.objecttype.toLowerCase().replace("Metric", "").replace("node", "");
    }
    return svgName;
  }
  getKLSVGName(item) {
    let svgName = "site";
    if (!item || !item.template) {
      return "site";
    }
    // item.template = item.template.toLowerCase().replace('_', '').replace('node', '');
    if (!this.iconTemplates[item.template]) {
      return svgName;
    } else if ((item.template === "incident" || item.template === "location") && this.iconTemplates[item.category.toLowerCase()]) {
      return item.category.toLowerCase();
    } else if (this.iconTemplates[item.template].use) {
      svgName = this.iconTemplates[item.template].use;
    } else {
      svgName = item.template;
    }
    return svgName;
  }
  getRuleIcon(type) {
    if (!type) {
      return "condition";
    }
    if (type === "_Rule" || type === "_RuleTemplate") {
      return "category";
    }
    let template = type.toLowerCase().replace("_", "").replace("template", "").replace(/ /g, "");
    let svgName = "condition";
    if (!this.iconTemplates[template]) {
      svgName = "condition";
    } else if (this.iconTemplates[template].use) {
      svgName = this.iconTemplates[template].use;
    } else {
      svgName = template;
    }
    // console.log('getRuleIcon', type, svgName);
    return svgName;
  }

  getIconCodes(d) {
    let iconClass = "fa-map-marker";
    d.iconCode = "\uf041";
    let codes = {};
    if (d.template === "rule") {
      codes = this.iconTemplates[d.subTemplate];
    } else {
      codes = d.template ? this.iconTemplates[d.template] : this.iconTemplates[d.type];
    }
    if (!_.isEmpty(codes)) {
      iconClass = codes["iconClass"];
      d.iconCode = codes["iconCode"];
      d.yOffset = codes["yOffset"];
    }
    return [d, iconClass];
  }
}
