<div class="role-panel">
    <cl-side-panel class="role-side-panel" (closePanel) = "closePanel()" [ngClass]="{'summary-view': mode==='view'}">
        <section class="cl-side-panel-title side-panel-header-bg">
            <span class="black font-bold" *ngIf="mode!='view'">{{mode ==='add'?'Add Role':'Edit Role'}}</span>
            <span class="black font-bold" *ngIf="mode === 'view'">Role Summary</span>
            <span class="close-icon" (click)="closePanel()">
                <i class="fa fa-times-circle-o mr-4"></i>
            </span>
        </section>
        <section class="cl-side-panel-content role-section">
            <div class="user__main-content" *ngIf="mode!='view'">
                <div class="user__main-content__filter">
                    <div class="filter-section">
                        <div class="grow-0" >
                            <cl-list-filter class="policy__listing__filter" [hideTitle]="true" [showCounts]="false" [title]="''" heightClass="none"
                             (panelsEmitter)="getPanelUpdate($event)" [filters]="filters" [actionCount]="actionCount">
                             </cl-list-filter>
                        </div>
                    </div>
                </div>
                <div class="user__main-content__Listing">
                    <div class="add-role-form">
                        <div class="form-label">Role Name<span class="required">*</span></div>
                        <input type="text" class="add-role-input" [(ngModel)]="newRoleName" [disabled]="mode==='edit'" [ngClass]='{"disabled": mode=="edit"}'>
                    </div>

                    <div class="add-role-form role-scope-block" *ngIf="isSuperAdmin">
                        <div class="role-scope-block-div">
                            <div class="form-label role-scope-label">Role Scope<span class="required">*</span></div>
                            <mat-select [(ngModel)]="roleScope" required="true" class="role-scope-select">
                                <mat-option *ngFor="let scope of roleScopeList" [value]="scope">{{scope}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="role-tenant-block">
                            <mat-checkbox [(ngModel)]="defaultRoleFor">
                                <label class="ml10 user-roles__item__label">Default for TENANT Admin</label>
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="selected-policies-tags">
                        <span class="selected-policy-item" *ngFor="let item of selectedRows">
                            <span class="selected-policy-item__name">{{item.name}}</span>
                            <span class="selected-policy-item__remove fa fa-times mfl" (click)="removeSeletedTag(item)"></span>
                        </span>
                    </div>

                    <div class="form-label">Policies</div>
                    <ngx-datatable [rows]="rows" [columns]="columns" [columnMode]="'force'"
                    [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight" [scrollbarV]="false"
                    [selected]="selectedRows" [rowIdentity]="getId" [sortType]="sortType" [selectionType]="selection" [selectAllRowsOnPage]="false"
                    (select)='onSelect($event)'[reorderable]="false">
                    </ngx-datatable>
                </div>
            </div>

            <div class="user-summary" *ngIf="mode==='view'">
                <div class="user-summary-content">
                    <div class="form-label">Role Name</div>
                    <div class="mb-3">{{roleObject.name}}</div>
                </div>
                <div class="user-summary-content mb-3">
                    <div class="form-label">Policies</div>
                    <div class="policy-grid font-12-normal" *ngFor="let item of roleObject.policies">{{item.name}}
                    </div>
                </div>
                <div class="user-summary-content" *ngIf="roleObject.createdBy">
                    <div class="form-label">Created By</div>
                    <div class="mb-3">{{roleObject.createdBy}}</div>
                </div>

                <div class="user-summary-content" *ngIf="roleObject.createdAtFormatted">
                  <div class="form-label">Created Date</div>
                  <div class="mb-3">{{roleObject.createdAtFormatted}}</div>
                </div>

                <div class="user-summary-content" *ngIf="roleObject.modifiedBy">
                    <div class="form-label">Modified By</div>
                    <div class="mb-3">{{roleObject.modifiedBy}}</div>
                </div>

                <div class="user-summary-content" *ngIf="roleObject.modifiedAtFormatted">
                    <div class="form-label">Modified Date</div>
                    <div class="mb-3">{{roleObject.modifiedAtFormatted}}</div>
                </div>
            </div>
        </section>


        <section class="cl-side-panel-footer bgcolor33" *ngIf="mode !='view'">
            <div class="flex">
                <div class="flex-col grow fs11 text-right" >
                    <a class=" m-2" (click)="closePanel()">Cancel</a>
                    <button class="panel-button white bgcolor4"  (click)="saveRole()" [disabled]="!newRoleName || addRoleDisabled">{{mode=='add'?'Save':'Update'}}
                        <span class="fa fa-circle-o-notch fa-pulse white" *ngIf="addRoleDisabled"></span>
                    </button>
                </div>
            </div>
        </section>
    </cl-side-panel>
</div>

