<h2 mat-dialog-title class="mb-0">Edit Credentials</h2>

<mat-dialog-content class="dynamic-form">
  <div class="user-detail__item" style="width: 250px">
    <label class="form-label"
      >Webhook Secret <span class="mandatory text-red-500">*</span></label
    >
    <div class="relative">
      <input
        type="text"
        class="basic-input w-full text-xs pl-1.5 pr-6 h-[30px]"
        [placeholder]="'Enter Webhook Secret'"
        style="width: 100%"
        [(ngModel)]="subscriptionKey"
      />
      <button
        class="color4 absolute right-2.5 top-1"
        *ngIf="subscriptionKey !== data?.key"
        (click)="subscriptionKey = data?.key"
      >
        <i class="fa fa-history"></i>
      </button>
    </div>
    <div class="text-danger" *ngIf="submitted && subscriptionKey.trim() == ''">
      Webhook Secret is required
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="m-2">Cancel</button>
  <button
    mat-button
    (click)="onConfirm()"
    class="panel-button white bgcolor4 ml-2"
  >
    Save
  </button>
</mat-dialog-actions>
