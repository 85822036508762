<mat-card class="mb-4 pt-3 pb-0" *ngIf="isShowEnv">
    <div class="asset-selection">
        <div class="list">
            <div>
                <h3 class="font-head">{{'shipmentDetails.assetInsights'|translate}}</h3>
            </div>
            <div class="shipment-details-asset-insites-asset-select mb-2">
                <div class="user-detail__item">
                    <div class="dropdown-input">
                    <input
                    [matAutocomplete]="assetSelectInsets"
                    placeholder="Select asset"
                    class="basic-input drp-search"
                    type="search"
                    (click)="matOpen()"
                    [(ngModel)]="selectedAsset" 
                  />
                  <mat-autocomplete 
                  (optionSelected)="onChangeAsset($event.option.value)"
                  (closed)="matClose()"
                   #assetSelectInsets="matAutocomplete" [displayWith]="displayFn">
                    <ng-container >
                      <mat-option
                        *ngFor="let  asset of shipmentData.assetNodes | search : selectedAsset : 'properties.name'" 
                        [value]="asset"
                      >
                        <span>{{asset.properties.name }}</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                  </div>
                </div>
                <!-- <ng-container>
                    <mat-select [(ngModel)]="selectedAsset" (selectionChange)="onChangeAsset($event)">
                        <mat-option [value]="item" *ngFor="let item of shipmentData.assetNodes">{{item.properties.name}}
                        </mat-option>
                    </mat-select>
                </ng-container> -->
            </div>
            <div>
                <cl-date-picker [dateRange]="selectedDateRange" [datePickerClass]="datePickerClass"
                    [ranges]="ranges" (dateChanged)="datesUpdated($event)">
                </cl-date-picker>
                <div class="pl-2 pt-2">
                    <i class="fa fa-refresh color4 pointer" (click)="refreshClicked()"></i>
                </div>
            </div>

        </div>
    </div>
</mat-card>
<mat-card class="p-0">
    <div class="map-header">
        <ul>
            <li (click)="showMap = !showMap">
                <h4 class="mb-0 d-flex items-baseline font-head"> <i
                        class="fa fa-chevron-right float-left color4 mr-4 mt-1" [ngClass]="{
                      'rotate-Icon': showMap === true
                    }"></i>{{'common.Location'|translate}}</h4>
            </li>
            <li class="pt-0.5" *ngIf="actualRoute.length != 0 && !isShowEnv">
                <h6 class="mb-2 font-head color4">Latitude: {{actualRoute[actualRoute.length - 1].lat}}</h6>
            </li>
            <li class="pt-0.5" *ngIf="actualRoute.length != 0 && !isShowEnv">
                <h6 class="mb-2 font-head color4">Longitude: {{actualRoute[actualRoute.length - 1].lng}} </h6>
            </li>
            <li class="pt-0.5" *ngIf="actualRoute.length != 0 && !isShowEnv">
                <h6 class="mb-2 font-head color4">Near: {{currentAssetLocation}} </h6>
            </li>
            <div [ngClass]="{'float-right': !isShowEnv}">
                <li class="pt-0.5">
                    <h6 class="dotted-line mb-2 font-head color4">Planned Route</h6>
                </li>
                <li class="pt-0.5">
                    <h6 class="actual-route mb-2 font-head color4">Actual Route</h6>
                </li>
            </div>
        </ul>
    </div>
    <div *ngIf="isLoading">
        <div class="mt-2 flex justify-center">
            <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
        </div>
    </div>
    <agm-map [fullscreenControl]='true' (zoomChange)="changeMapZoom($event)" [latitude]="lat" [longitude]="lng"
        [streetViewControl]="false" [zoomControl]="isHavingZoomControls" [fullscreenControlOptions]="mapOptions.positionOfActions"
        [zoomControlOptions]="mapOptions.positionOfActions" [styles]="mapOptions.styles" [mapTypeControl]='false'
        [minZoom]="minZoom" [maxZoom]="maxZoom" [zoom]="zoom" [scrollwheel]="isRestrictedScroll" [fitBounds]="true" *ngIf="showMap && !isLoading">
        <!-- <button id="Profile" class="current-location-icon" (click)="getCurrentLocation()">
            <img src="../assets/icons-svgs/my-location.png" alt="" class="cursor-pointer">
        </button> -->
        <!-- (mapReady)="mapReady($event)" -->
        <agm-marker *ngFor="let coordinate of planedRoute" [latitude]="coordinate.lat" [longitude]="coordinate.lng"
            [iconUrl]="coordinate.iconUrl" [agmFitBounds]="true">
            <agm-info-window *ngIf="!(isDTPUser && isDTPShipment)">
                <div class="agm-info-window">
                    <p *ngIf="coordinate.date"><strong>Date:</strong> {{coordinate.date | formateDate
                        }}</p>
                    <p *ngIf="!(isDTPUser && isDTPShipment)"><strong class="bold">Latitude : </strong> {{coordinate.lat}}</p>
                    <p *ngIf="!(isDTPUser && isDTPShipment)"><strong class="bold">Longitude: </strong>{{coordinate.lng}}</p>
                </div>
            </agm-info-window>
            <agm-polyline [visible]="true" [strokeWeight]="2" [strokeColor]="'#0099dc'">
                <agm-polyline-point *ngFor="let a of planedRoute" [latitude]="a.lat" [longitude]="a.lng">
                </agm-polyline-point>
                <agm-icon-sequence [strokeColor]="'#0099dc'" [repeat]="'10px'" [strokeWeight]="3" [scale]="2"
                    [strokeOpacity]="100" [path]="'M 0,-1 0,1'">
                </agm-icon-sequence>
            </agm-polyline>
            <agm-overlay *ngFor="let e of excursions" [latitude]="e.lat" [longitude]="e.lon">
                <!-- <img src="{{e.iconUrl}}" alt=""> -->
                <div class="dropdown pointer" (mouseenter)="scrolhover()" (mouseleave)="scrolLeave()">
                    <img src="{{e.iconUrl}}" class="ex-icon max-width" *ngIf="getIndividualExcursions(e.excursionDetails).length > 1">
                    <span *ngIf="getIndividualExcursions(e.excursionDetails).length == 1">
                        <svg xmlns="http://www.w3.org/2000/svg" class="time-delay-icon max-width" viewBox="0 0 42 42"
                            *ngIf="['Origin Delay','ETA Delay'].includes(e.excursionDetails[0].excursionType)">
                            <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
                                <circle cx="20" cy="20" r="20" fill="#FF2525" stroke="#D3D3D3" />
                                <path fill="#FFF" fill-rule="nonzero"
                                    d="M30.9821679,29.8928582 L10.4464291,29.8928582 C10.1953127,29.8928582 10,30.0881709 10,30.3392873 L10,32.1250037 C10,32.3761201 10.1953127,32.5714328 10.4464291,32.5714328 L30.9821679,32.5714328 C31.2332842,32.5714328 31.428597,32.3761201 31.428597,32.1250037 L31.428597,30.3392873 C31.428597,30.0881709 31.2332842,29.8928582 30.9821679,29.8928582 Z M11.8136182,29 L29.6149787,29 C29.3499115,24.2985435 25.5134114,21.7734289 22.946444,20.0714179 L23.7102265,19.5615627 C26.2117793,17.8686927 29.375156,15.3965346 29.6149787,11.1428359 L11.8136182,11.1428359 C12.0786855,15.8442924 15.9151856,18.369407 18.482153,20.0714179 L17.7183704,20.5812731 C15.2168176,22.2741432 12.053441,24.7463013 11.8136182,29 Z M30.9821679,7.57140303 C31.2332842,7.57140303 31.428597,7.76671576 31.428597,8.01783213 L31.428597,9.80354854 C31.428597,10.0546649 31.2332842,10.2499776 30.9821679,10.2499776 L10.4464291,10.2499776 C10.1953127,10.2499776 10,10.0546649 10,9.80354854 L10,8.01783213 C10,7.76671576 10.1953127,7.57140303 10.4464291,7.57140303 L30.9821679,7.57140303 Z" />
                            </g>
                        </svg>
                        <img *ngIf="!['Origin Delay','ETA Delay'].includes(e.excursionDetails[0].excursionType) && e.excursionDetails[0].violated" [src]="violationIcon(e.excursionDetails[0].canonicalPropertyName)" class="ex-icon max-width" alt="" >

                    </span>
                    <div class="dropdown-content">
                        <h5 class="text-danger pb-0" *ngIf="e.excursionDetails.length > 1">
                            <strong>{{e.excursionDetails.length}} Shipment Alerts</strong>
                        </h5>
                        <h4 class="pb-0 mb-0 text-primary" *ngIf="e.excursionDetails.length == 1">
                            {{tempValue.properties.name}}</h4>
                        <mat-divider></mat-divider>
                        <div class="scrool">
                        <h4 class="pb-0 mb-0 text-primary" *ngIf="e.excursionDetails.length > 1">
                            {{tempValue.properties.name}}</h4>
                        <div *ngFor="let item of e.excursionDetails" class="mt-1">
                            <p class="mt-1">
                                <span> {{item.deviceTime | formateDate}} </span> <br>
                                    <span>{{ item.excursionType }} : 
                                        <div *ngIf="item.violationTypeGroup=='Carrier Milestone'">{{item.propertyValue.message}}</div>
                                        <div  *ngIf="item.violationTypeGroup=='Carrier Milestone'">Milestone Date/Time: {{item.propertyValue.checkpointLocalTime | formateDate}}</div>
                                        <div  *ngIf="item.violationTypeGroup=='Carrier Milestone'">Milestone Location : {{item.propertyValue.location}}</div>
                                        <div  *ngIf="item.violationTypeGroup=='Carrier Milestone'">Milestone Info : {{item.propertyValue.tag}}-{{item.propertyValue.subtagMessage}}</div>
                                    <span *ngIf="item.excursionType != 'Door' && item.violationTypeGroup!='Carrier Milestone'"> {{formatValues(item.propertyValue)}}</span>
                                    <span *ngIf="item.excursionType == 'Door' && item.propertyValue">Opened</span>
                                    <span *ngIf="item.excursionType == 'Door' && !item.propertyValue">Closed</span>
                                </span>
                                <span
                                    *ngIf="item.excursionType == 'Temperature' || item.excursionType == 'Ambient Temperature' ||item.excursionType == 'Cargo Temperature' ">{{tenantTempType}}</span>
                                <span *ngIf="item.excursionType == 'Pressure'">&nbsp;{{pressureUnits}}</span>
                                <span *ngIf="item.excursionType == 'Tilt'">&nbsp;°</span>
                                <span *ngIf="item.excursionType == 'Shock'">&nbsp;g</span>
                                <span *ngIf="item.excursionType == 'Light'">&nbsp;lux</span>
                                <span
                                    *ngIf="item.excursionType == 'Humidity' || item.excursionType == 'Battery'">%</span>
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
            </agm-overlay>
        </agm-marker>
        <agm-marker *ngFor="let coordinate of actualRoute" [latitude]="coordinate.lat" [longitude]="coordinate.lng"
            [iconUrl]="coordinate.iconUrl" [agmFitBounds]="true">
            <agm-info-window *ngIf="!(isDTPUser && isDTPShipment)">
                <div class="agm-info-window">
                    <p *ngIf="coordinate.date"><strong>Date:</strong> {{coordinate.date | formateDate
                        }}</p>
                    <p *ngIf="!(isDTPUser && isDTPShipment)"><strong class="bold">Latitude : </strong> {{coordinate.lat}}</p>
                    <p *ngIf="!(isDTPUser && isDTPShipment)"><strong class="bold">Longitude: </strong>{{coordinate.lng}}</p>
                </div>
            </agm-info-window>
            <agm-polyline [visible]="true" [strokeWeight]="3" [strokeColor]="'#0099dc'">
                <agm-polyline-point *ngFor="let c of actualRoute" [latitude]="c.lat" [longitude]="c.lng">
                </agm-polyline-point>
            </agm-polyline>
        </agm-marker>
    </agm-map>
</mat-card>
<mat-card class="mb-4 pt-2 pb-0  my-4" *ngIf="isShowEnv">
    <cl-shipment-details-env-conditions #envcndts *ngIf="excursionObj" [selectedDateRange]="selectedDateRange"
        [selectedAsset]="tempValue" [shipmentDetails]="shipmentData" [violations]="excursionObj">
    </cl-shipment-details-env-conditions>
</mat-card>