import { Deserializable, Entity } from './models/index';

export class SearchResponse implements Deserializable {
  nodes: Entity[];

  deserialize(res: any) {
    let el;

    if (res && res.hits) {
      res.hits = res.hits.map((vertice: Entity) => {
        el = {
          ...vertice,
          'properties': vertice
        }
        return new Entity().deserialize(el)
      });
    }

    return res;
  }

}
