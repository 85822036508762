<mat-tab-group #areaTab *ngIf="mode == 'add'" (click)="onTabClick(areaTab)" (selectedTabChange)="onSelectedTabChange($event)" class="sticky-header pl-1.5">
  <mat-tab label="Single"></mat-tab>
  <mat-tab label="Bulk Add via {{bulkObj.fileType}}"></mat-tab>
</mat-tab-group>
<div *ngIf="tabSelected == 0">
<form [formGroup]="areaForm"  (ngSubmit)="submitForm()">
<div class="mx-2 -mt-2 mb-2">
<cl-dynamic-form-renderer *ngIf="dynamicInputFields.length > 0" [columnMode]="'One'" [inputFields]="dynamicInputFields" #dynamicForm>
</cl-dynamic-form-renderer>
</div>
<ng-template #selectShapeTool class="mx-2 mb-4">
<label for="" class="form-label ">
  Select a shape tool<span>*</span>
</label>
<mat-radio-group id="markertype"
aria-labelledby="location-radio-group-label"
class="location-radio-group"
formControlName="markertype" (change)="getSelectedMarker()">
<mat-radio-button class="location-radio-button capitalize" *ngFor="let marker of markers" [value]="marker">
  <img *ngIf="marker == 'point'" src="/assets/svgs/cl-icons-blue/gps.svg" class="inline color4" width="25">
  <img *ngIf="marker == 'polygon'" src="/assets/svgs/polygon-geofence.svg" class="inline color4" width="25">
  <img *ngIf="marker == 'circle'" src="/assets/svgs/circle-geofence.svg" class="inline color4" width="25"> 
  <span *ngIf="marker != 'circle'" >{{marker}}</span>
  <span *ngIf="marker == 'circle'" >Radius</span>
  <i *ngIf="marker == 'polygon'" class="fa fa-refresh ml-2" clearPolygons()></i>
</mat-radio-button>
</mat-radio-group>
<div *ngIf="submitted && areaForm.get('markertype').errors?.['required']" class="text-danger">Select a shape tool is required.</div>
<div *ngIf="submitted && polygonPts.length==0 && areaForm.value.markertype=='polygon'" class="text-danger">Please select polygon points.</div>
</ng-template>
<ng-template #addressTemp >
  <div  class="mb-2 flex flex-col ">
    <label for="address" class="form-label ">
      Address<span>*</span>
    </label>
    <input id="address" class="mb-1 basic-input" formControlName="address"
      placeholder="Enter a Location" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" >
      <div
      *ngIf="submitted && areaForm.get('address').errors?.['required']"
        class="text-danger"
      >
      Address is required.
      </div>
  </div>
</ng-template>
<ng-template #orgTemp>
  <div class="mb-2 flex flex-col">
    <label for="organization" class="form-label">
      Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog>
    </label>
        <div class="relative">
        <mat-select
          class="basic-input"
          placeholder="Select organization"
          formControlName="organization"
        >
          <ng-container>
            <mat-option *ngIf="!isOrgRestrictedUser" [value]="'ANY'">(Any)</mat-option>
            <mat-option *ngFor="let org of orgList"
            [value]="org.id">{{org.name}}</mat-option>
          </ng-container>
        </mat-select>
        <mat-icon  *ngIf="areaForm.value.organization && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
      </div>
</div>
<p *ngIf="areaForm.value.organization=='ANY'" class="orgcontent"><span>Warning</span>: Data will be seen by all users including those outside your org</p>
</ng-template>
<ng-template  #markertypeTemp >
  <div *ngIf="areaForm?.getRawValue()?.address && areaForm.value.markertype == 'circle'" class="mb-2 flex flex-col">
   <label for="radius" class="form-label ">Radius (mi)</label>
  <input id="radius" class="basic-input" formControlName="radius" />
  </div>
</ng-template>
<ng-template #areaPlacement>
  <div class="mb-2 flex flex-col">
    <label for="site" class="form-label ">Site(Area Placement)</label>
    <mat-select class="basic-input" id="site" disableOptionCentering 
    placeholder="Select Site(Area Placement)*" (selectionChange)="getSelectedSite()" 
    formControlName="site"
    >
    <ng-container>
      <mat-option *ngFor="let site of sites" [value]="site">{{site.name}}</mat-option>
    </ng-container>
    </mat-select>
  </div>
</ng-template>
<ng-template #position>
  <div class="mb-2 flex flex-col">
<label for="position" class="form-label ">Position (lat,long)<span>*</span></label>
<input id="position" class="basic-input" formControlName="position"  (change)="checkLatLong()">
<div *ngIf="submitted && isLatLong" class="text-danger"> Position is required.</div>
</div>
</ng-template>
<ng-template #areatypeTemp>
  <div  class="mb-2 flex flex-col">
    <label for="areatype" class="form-label ">Area Type<span>*</span></label>
    <mat-select class="basic-input" id="areatype" disableOptionCentering 
    placeholder="Select Area Type*"
    formControlName="areatype"
    (selectionChange)="onAreaTypeChanged()"
    >
    <ng-container>
      <mat-option *ngFor="let areaType of areaTypes" [value]="areaType">{{areaType}}</mat-option>
    </ng-container>
    </mat-select>
    <div *ngIf="submitted && areaForm.get('areatype').errors?.['required']" class="text-danger"> Area Type is required.</div>
    <div  class="flex flex-col" *ngIf="!isCarrierHubCodeHidden">
      <label for="carrierHubCode" class="form-label ">Airport Code<span>*</span></label>
      <input id="carrierHubCode" class="basic-input" formControlName="carrierHubCode">
      <div *ngIf="submitted && areaForm.get('carrierHubCode').errors?.['required']" class="text-danger"> Airport Code is required.</div>
    </div>
  </div>
</ng-template>
</form>
</div>
<div *ngIf="tabSelected == 1">
<cl-bulk-action [panelObj]="bulkObj" [showResponse]="showResponse" [bulkResponseArr]="bulkResponseArr" (emitFormData)="getUploadFormData($event)" [fields]="downloadedCSVFields" [downloadedCSVFileName]="'area_add_template'"></cl-bulk-action>
</div>