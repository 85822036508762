import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(value: any, args?: any, key?: any): any {

    if (!value) return null;
    if (!args) return value;
    let searchVal = '';
    if (typeof args === 'object') {
      searchVal = args[key]?.toLowerCase();
    } else {
      searchVal = args.toLowerCase();
    }

    return value.filter(function (data: any) {
      return JSON.stringify(key ? data[key]:data)?.toLowerCase()?.includes(searchVal);
    });
  }
}
