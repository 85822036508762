import { Component, Input, OnInit, Output,EventEmitter, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CdmField } from '@cl/@types/extended-attributes.types';
import { InputField } from '@cl/@types/form-rendering-engine.type';
import { ContentDialogComponent } from '@cl/common/components/content-dialog/content-dialog.component';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { LocationUtilsService } from '@cl/locations/location-utils.service';
import { LocationsService } from '@cl/locations/locations.service';

@Component({
  selector: 'cl-location-route',
  templateUrl: './location-route.component.html',
  styleUrls: ['./location-route.component.scss'],
})
export class LocationRouteComponent implements OnInit {
  @Input('mode') mode: string;
  @Input('editRoute') editRoute: any;
  @Input() orgList:any;
  @Input() isOrgRestrictedUser: boolean;
  @Input() objectType:any;
  @Output() selectedRoute = new EventEmitter();

  lat: any = 51.678418;
  lng: any = 7.809007;
  form: FormGroup;
  areas: any = [];
  allAreas: any = [];
  isLoading: boolean = false;
  boolen: false;
  routeData: any;
  submitted: boolean = false;
sourceArea: any;
dynamicInputFields:InputField[] = [];
@ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;
@ViewChild('sourceTemp') sourceTemp:  TemplateRef<any>;
@ViewChild('destinationTemp') destinationTemp:  TemplateRef<any>;
@ViewChild('orgTemp') orgTemp:  TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private locationsService: LocationsService,
    private _locationUtils: LocationUtilsService,
    private _dynamicFormService: DynamicFormService, private cd: ChangeDetectorRef,    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.mode == 'add') {
      this.form = this.fb.group({
        organization:new FormControl(this.orgList[0]?.id),
        address: this.fb.array([]),
      });
       this.addNewAddressGroup(0);
       this.addNewAddressGroup(1);
      } else if (this.mode == 'edit' && this.editRoute) { 
      this.form = this.fb.group({
        organization:new FormControl(this.editRoute.organization),
        id: new FormControl(this.editRoute.id),
        address: this.fb.array([]),
      });
      let locations = JSON.parse(this.editRoute.locationDetails);
      const add = this.form.get('address') as FormArray;
      locations.forEach((element) => {
        add.insert(
          this.form.value.address.length,
          new FormGroup({
            sourceArea: new FormControl(
              this.getSourceAreaDetails(
                element.areaName,
                this.form.value.address.length
              )
            ),
            travelMode: new FormControl(element.travel_mode),
            isShowOverrideTravelMode: new FormControl(element.override),
            isSelectTravelTime: new FormControl(false),
            isShowDistance: new FormControl(element.override),
            travelDistance: new FormControl(element.travel_distance),
            isSetLayOverDuration: new FormControl(false),
            travelDuration: new FormControl(''),
            setLayOverDuration: new FormControl(element.layover),
            days: new FormControl(element.travel_time.split(':')[0]),
            hours: new FormControl(element.travel_time.split(':')[1]),
            mins: new FormControl(element.travel_time.split(':')[2]),  
            isNotAirport: new FormControl(false),
            setDays: new FormControl(element.layover?.split(':')[0]),
            setHours: new FormControl(element.layover?.split(':')[1]),
            setMins: new FormControl(element.layover?.split(':')[2]),
          })
        );
      });
    }
    this.getExtendedAttributes()
  }
  getSourceAreaDetails(areaName, index) {
    this.locationsService.getAreas(areaName).subscribe((data: any) => {
      let controlArray = <FormArray>this.form.controls['address'];
      data.forEach(ele=>{
        if(ele.name == areaName){
          controlArray.controls[index].get('sourceArea').patchValue(ele);
          this.onSelectionChange(index, "address")
        }
      })
    });
  }
  getAreas(i) {
    if(this.form.value.address[i]?.sourceArea){
        this.locationsService
      .getAreas(this.form.value.address[i].sourceArea)
      .subscribe((data: any) => {
        this.isLoading = false;
        this.areas[i] = data;
      });
    }else{
      if(this.allAreas.length){
        this.areas[i]=this.allAreas
      }else{
        this.locationsService
        .getAreas('')
        .subscribe((data: any) => {
          this.allAreas = data; 
          this.areas[i]=this.allAreas
        });
      }
     
    }

  }
  addNewAddressGroup(index) {
    const add = this.form.get('address') as FormArray;
    add.insert(
      index + 1,
      new FormGroup({
        sourceArea: new FormControl('',[Validators.required]),
        travelMode: new FormControl('Road',[Validators.required]),
        isShowOverrideTravelMode: new FormControl(false),
        isSelectTravelTime: new FormControl(false),
        isSetLayOverDuration: new FormControl(false),
        isShowDistance: new FormControl(false),
        travelDistance: new FormControl(null),
        travelDuration: new FormControl(null),
        setLayOverDuration: new FormControl(null),
        isNotAirport: new FormControl(false),
        days: new FormControl(''),
        hours: new FormControl(''),
        mins: new FormControl(''),
        setDays: new FormControl(''),
        setHours: new FormControl(''),
        setMins: new FormControl(''),
      })
    );
    this.getAreas(index)
  }

  deleteAddressGroup(index: number) {
    const add = this.form.get('address') as FormArray;
    add.removeAt(index);
    this.selectedRoute.emit(this.form.value.address)
  }
  submitForm() {
    this.submitted = true;
    var locationDeatils = [];
    let index =0;
    this.form.value.address.forEach((element) => {
 if(element.sourceArea){
      index= index+1;
      let coordinates = element.sourceArea.coordinates;
      let routeGeojson = JSON.parse(coordinates)
      let location = {
        layover: element.setDays + ':' + element.setHours + ':' + element.setMins,
        travel_mode: element.travelMode?element.travelMode : "Road",
        loc_seq_order_no: index,
        travel_time: element.days + ':' + element.hours + ':' + element.mins,
        istimeset: true,
        areaName: element.sourceArea.name,
        routeError: false,
        ErrorMsg: element.isNotAirport? 'Area should be of Type Airport' :'',
        override: element.isShowOverrideTravelMode,
        travel_distance: element.travelDistance,
        areaId: element.sourceArea.id,
        routeGeojson: coordinates,
        areaType: element.sourceArea.type,
        tenantId: element.sourceArea.tenantId,
        routeId: '',
        areaItem: {
          type: element.sourceArea.type,
          identifier: element.sourceArea.identifier,
          name: element.sourceArea.name,
          order: index,
          locus: element.sourceArea.locus,
          deleted: false,
          tenantId: element.sourceArea.tenantId,
          coordinates: element.sourceArea.coordinates,
          createdBy: element.sourceArea.createdBy,
          status: element.sourceArea.status,
          id: element.sourceArea.id,
          isHidden: element.sourceArea.isHidden,
          externalId:
            element.sourceArea.externalId,
          classType: routeGeojson?.type,
          fullAddress: routeGeojson?.address,
          baseType: 'Location',
          baseClass: 'Location',
          position: null,
          createdAt: null,
          modifiedAt: null,
          modifiedBy: element?.sourceArea?.modifiedBy || null,
        },
        coordinates: {
          lat: routeGeojson?.geometry?.coordinates[1],
          lng: routeGeojson?.geometry?.coordinates[0],
        },
        travelDuration: {
          seconds: element?.travelDuration,
          nano: 0,
          units: ['SECONDS', 'NANOS'],
          negative: false,
          zero: false,
        },
        address: routeGeojson?.address,
        areaFenceType: routeGeojson?.properties.type,
      };
      locationDeatils.push(location);
    }});
    let payload = {
      organization: this.form.value.organization,     
      destination:
        this.form.value.address[this.form.value.address.length - 1].sourceArea
          .name,
      source: this.form.value.address[0].sourceArea.name,
      type: 'Route',
      destinationId:
        this.form.value.address[this.form.value.address.length - 1].sourceArea
          .id,
      originId: this.form.value.address[0].sourceArea.id,
      id: this.form.value?.id,
      deleted: 'false',
      locationDetails: locationDeatils,
      transportMode: this.checkTransportMode(),
      classType: 'Route',
      catalogType:'Route',
      objectType: this.objectType
    };
    const dynamicFormGroup = this.dynamicForm?.onSubmit();
    if(dynamicFormGroup && dynamicFormGroup.status =="VALID" && this.form.status=="VALID"){
      payload = {...dynamicFormGroup.getRawValue(), ...payload}
      return payload;
    }else{return null}
  }
   
  checkTransportMode(){
    let last = this.form.value.address.length -1
    let travel =this.form.value.address[0].travelMode
    let isMulti = false
    this.form.value.address.forEach((element,index) => {
      if(element.sourceArea){
        if(index !==last){
          if(travel!=element.travelMode){
            isMulti =true;
          }
      }}
    });
   return isMulti?'MULTI' :travel
  }
  closepopover(addressGroup, value) {
    addressGroup.controls.isSelectTravelTime.patchValue(value);
  }
  setLayOverDuration(addressGroup, value) {
    addressGroup.controls.isSetLayOverDuration.patchValue(value);
  }
  toggleShowDistance(event, addressGroup, i) {
    if (event.checked == true) {
      addressGroup.controls.isShowDistance.patchValue(true);
    } else {
      addressGroup.controls.isShowDistance.patchValue(false);
      addressGroup.controls.travelDistance.patchValue('');
    }
    let origin = this.form.value.address[i].sourceArea.coordinates;
    let destination = this.form.value.address[i+1].sourceArea.coordinates;
    let DestCord = JSON.parse(destination)
    let orginCord = JSON.parse(origin)
    const originCoords = new google.maps.LatLng(orginCord?.geometry?.coordinates[1], orginCord?.geometry?.coordinates[0]);
    const destinationCoords = new google.maps.LatLng(  DestCord?.geometry?.coordinates[1],   DestCord?.geometry?.coordinates[0]);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(originCoords, destinationCoords);
    addressGroup.controls.travelDistance.patchValue((distance*0.0006213712).toFixed(1)+'  mi');
    let controlArray = <FormArray>this.form.controls['address'];
    let value = ((distance*0.0006213712)/40)* 3600
    let seconds = Number(value);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    controlArray.controls[i].get('days').patchValue(d);
    controlArray.controls[i].get('hours').patchValue(h);
    controlArray.controls[i].get('mins').patchValue(m);
    controlArray.controls[i].get('travelDuration').patchValue(seconds);
  }

  onSelectionChange(i, type) {
    if (
      i > 0 &&
      this.form.value.address[i - 1].travelMode
    ) {
      let origin = this.form.value.address[i - 1].sourceArea.coordinates;
      let destination = this.form.value.address[i].sourceArea.coordinates;
      let DestCord = JSON.parse(destination)
      let orginCord = JSON.parse(origin)
      let travelMode = this.form.value.address[i - 1].travelMode;
      let originCoords = orginCord?.geometry?.coordinates[1] + ',' + orginCord?.geometry?.coordinates[0];
      let destinationCoords =
      DestCord?.geometry?.coordinates[1] + ',' + DestCord?.geometry?.coordinates[0];
      let index = i;
      if (type == 'address') {
        index = i - 1;
      }
      this.getDirections(
        originCoords,
        destinationCoords,
        [],
        travelMode,
        null,
        index
      );
    }    if (
      this.form.value.address[i + 1]?.sourceArea != '' &&
      this.form.value.address[i].travelMode
    ) {
      let origin = this.form.value.address[i].sourceArea.coordinates
      let destination = this.form.value.address[i + 1].sourceArea.coordinates;

      let DestCord = JSON.parse(destination)
      let orginCord = JSON.parse(origin)
      let travelMode = this.form.value.address[i].travelMode;
      let originCoords = orginCord?.geometry?.coordinates[1] + ',' + orginCord?.geometry?.coordinates[0];
      let destinationCoords =
      DestCord?.geometry?.coordinates[1] + ',' + DestCord?.geometry?.coordinates[0];
      this.getDirections(
        originCoords,
        destinationCoords,
        [],
        travelMode,
        null,
        i
      );
    }
    this.selectedRoute.emit(this.form.value.address)
  }
  displayFn(item) {
    if (item && item.name) {
      return item.name;
    }
  }
  async getExtendedAttributes(){
    const locationCatalog = await this._locationUtils.getLocationExtendedAttributes(this.objectType);

    const fields = this._dynamicFormService.getRenderableFields(
      locationCatalog.cdmFields
    );

    const orderedFields:CdmField[] = this._dynamicFormService.orderFields([...fields],'order');
    orderedFields.forEach(element => {
      if(element.name =="source" || element.name == "destination"){
        element.apiUrl=""
      }
    });
    const userFields = orderedFields.filter(field => field.group == 'User')
    this.dynamicInputFields = await this._dynamicFormService.generateInputFields(orderedFields  ,this.mode == 'edit',this.editRoute)
    this.dynamicInputFields.forEach(inp=>{
      if(inp.id == "organization"){
        inp.isDynamicField =false;
        inp.required =false
        inp.template = this.orgTemp;
    }
      if(inp.id == "source"){
          inp.isDynamicField =false;
          inp.required =false
          inp.template = this.sourceTemp;
      }
      if(inp.id == "destination"){
        inp.isDynamicField =false;
        inp.template = null;
        inp.required =false
      }})
    
    this.cd.detectChanges();
  }
  getDirections(origin, destination, waypoints, travelMode, transitOptions, i) {
    let controlArray = <FormArray>this.form.controls['address'];
    controlArray.controls[i].get('isNotAirport').patchValue(false);
    controlArray.controls[i+1].get('isNotAirport').patchValue(false);
    controlArray.controls[i].get('travelDuration').patchValue('');
    controlArray.controls[i]
    .get('isShowOverrideTravelMode')
    .patchValue(false);
    if(travelMode === 'Custom'){
     return
    }
    if(travelMode === 'Air'){
    
     if(this.form.value.address[i]?.sourceArea.type != "Airport" ) {
       controlArray.controls[i].get('isNotAirport').patchValue(true);
     }
    if(this.form.value.address[i+1]?.sourceArea.type != "Airport"){
      controlArray.controls[i+1].get('isNotAirport').patchValue(true);
      }
      if(this.form.value.address[i]?.sourceArea.type == "Airport" && this.form.value.address[i]?.sourceArea.type == "Airport" ){
        let origin = this.form.value.address[i].sourceArea.coordinates;
        let destination = this.form.value.address[i+1].sourceArea.coordinates;
        let DestCord = JSON.parse(destination)
        let orginCord = JSON.parse(origin)
        const originCoords = new google.maps.LatLng(orginCord?.geometry?.coordinates[1], orginCord?.geometry?.coordinates[0]);
        const destinationCoords = new google.maps.LatLng(  DestCord?.geometry?.coordinates[1],   DestCord?.geometry?.coordinates[0]);
        const distance = google.maps.geometry.spherical.computeDistanceBetween(originCoords, destinationCoords);
        let controlArray = <FormArray>this.form.controls['address'];
        let value = ((distance*0.0006213712)/500)* 3600
        let seconds = Number(value);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor((seconds % (3600 * 24)) / 3600);
        var m = Math.floor((seconds % 3600) / 60);
        controlArray.controls[i].get('days').patchValue(d);
        controlArray.controls[i].get('hours').patchValue(h);
        controlArray.controls[i].get('mins').patchValue(m);
        controlArray.controls[i].get('travelDuration').patchValue(seconds);
        this.selectedRoute.emit(this.form.value.address)
      }
    }else{
      var transitOptions = null;
      if (travelMode === 'Train') {
        travelMode = 'TRANSIT';
        transitOptions = {
          modes: ['TRAIN'],
          routingPreference: 'FEWER_TRANSFERS',
        };
      } else if (travelMode === 'Road') {
        travelMode = 'DRIVING';
      }
      var travelMode = travelMode;
  
       const directionsService = new google.maps.DirectionsService();
       directionsService.route(
        {
          origin: origin,
          destination: destination,
          waypoints: waypoints,
          travelMode: travelMode,
          transitOptions: transitOptions,
        },
        (response, status) => {
          if (status === 'OK') {
            let controlArray = <FormArray>this.form.controls['address'];
            controlArray.controls[i]
              .get('travelDistance')
              .patchValue(response.routes[0].legs[0].distance.text);
            let seconds = Number(response.routes[0].legs[0].duration.value);
            var d = Math.floor(seconds / (3600 * 24));
            var h = Math.floor((seconds % (3600 * 24)) / 3600);
            var m = Math.floor((seconds % 3600) / 60);
            
        if (this.mode == 'add') {
            controlArray.controls[i].get('days').patchValue(d);
            controlArray.controls[i].get('hours').patchValue(h);
            controlArray.controls[i].get('mins').patchValue(m);
        }
            controlArray.controls[i]
              .get('isShowOverrideTravelMode')
              .patchValue(false);
            controlArray.controls[i].get('travelDuration').patchValue(seconds);
            this.selectedRoute.emit(this.form.value.address)
          } else {
            let controlArray = <FormArray>this.form.controls['address'];
            controlArray.controls[i]
              .get('isShowOverrideTravelMode')
              .patchValue(true);
            }
            this.selectedRoute.emit(this.form.value.address)
        }
      );
    }
  }
  onOrgclose(){
    this.form.get('organization').setValue('');
  }
}
