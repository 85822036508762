import { AssetStatus } from "../@enums/asset-status.enum";
import { MeasureType } from "./measure.type";
export interface Position {
  lat: number;
  lon: number;
}

export interface LocationHistory {
  entry?: number;
  regionId: string;
  regionName: string;
  regionType: string;
  exit?: number;
  subRegions?: LocationHistory[];
  dwellTime?: string;
  isSubRegion?: boolean;
}

export interface CustodyEvent {
  eventTime: number;
  fromParty: string;
  note?: string;
  state: string;
  toParty: string;
  eventTimeFormatted?: string;
  stateFormatted?: string;
}

export interface Asset {
  id: string;
  externalId: string;
  name: string;
  monitoredStatus?: AssetStatus;
  state?: string;
  assetImageUrl?: string;
  categoryImageUrl?: string;
  categoryId?: string;
  categoryName?: string;
  taggedAssetId?: string;
  position?: Position;
  sensorId?: string;
  isSensorNotHavingLink?:boolean;
  sensorIds?: string[];
  sensorType?: string;
  sensorBindedDate?: Date;
  zoneName? : string;
  location?: string;
  lastLocation?: string;
  lastSeen?: string | number | Date;
  measures?: MeasureType[];
  exceptions?: any[];
  visits?: LocationHistory[];
  custodyList?: CustodyEvent[];
  lastReportedGPSTime?: string | number | Date;
  qrCode?: string;
  sku?: string;
  cost?: string;
  condition?: string;
  fromParty?: string;
  toParty?: string;
  checkedoutTo?: string;
  checkedoutToUserId?: string;
  checkedoutAt?: string | number | Date;
  manufacturer?: string;
  warehouseLocation?: string;
  maintenanceDate?: string | number | Date;
  modifiedAt?: string | number | Date;
  modifiedBy?: string;
  lifecycleStatus?: string;
  createdAt?: string | number | Date;
  custodyAuditId?: string;
  custodyAuditStatus?: string;
  custodyAuditBy?: string;
  custodyAuditInitiatedAt?: string | number | Date;
  custodyAuditBegunAt?: string | number | Date;
  lastCustodyAuditBy?: string;
  lastCustodyAuditAt?: string | number | Date;
  createdBy?: string;
  organization?: string;
  organizationName?: string;
  itemCategory?: string;
  checkedoutBy?: string;
  custodyAuditCompletedAt?: string | number | Date;
  custodyOperationAt?: string | number | Date;
  assetStatus?: string;
  status?:string;
  qrBarCode?:string;
  lastCustodyStatusUpdateAt?:string;
  custodyStatus?:string;
  properties?: AssetBasicInformationProperties;
  cdmFields?: CdmField[];
  deviceType?: string;
  shipment?: Shipment;
  imageURL?: string;
  finalLocation?: string;
  classType?:string;
  custodyAuditVersion?:string;
  custodyAuditPending?:string;
  lastCustodyAuditId?:string
}

export enum CustodyActions {
  Checkout = "CHECKOUT",
  Transfer = "TRANSFER",
  InService = "SERVICE",
  CheckIn = "CHECKIN",
  Return = "RETURN",
  Confirm = "ACCEPT",
  CancelledCheckout = "CANCELLED_CHECKOUT",
  CancelledTransfer = "CANCELLED_TRANSFER",
  CancelledReturn = "CANCELLED_RETURN",
  AuditAsset = "AUDIT_ASSET",
  AuditUser = "AUDIT_USER",
}

export enum UserCategory {
  ToolRoomManager     = 'TOOL_ROOM_MANAGER',
  FieldTechnician     = 'FIELD_TECHNICIAN',
  FieldServiceManager = 'FIELD_SERVICE_MANAGER'
};


export interface UserSearchResult {
  id: string;
  name?: string;
  roles?: string[];
  roleName?: string;
  deleted?: boolean;
  properties?: any;

  // UI properties
  checked?: boolean;
}


export interface AssetBasicInformationProperties {
  cost: string;
  modifiedAt: string;
  warehouseLocation: string;
  maintenanceDate: string;
  nextOperations: string;
  externalId: string;
  baseClass: string;
  manufacturer: string;
  sensorId: string;
  sensorIds: string[];
  isPublised: string;
  createdAt: string;
  condition: string;
  deleted: boolean;
  qrCode: string;
  createdBy: string;
  taggedAssetId: string;
  name: string;
  tenantId: string;
  typeId: string;
  type: string;
  modifiedBy: string;
  state: string;
  id: string;
  sku: string;
  classType: string;
  position: string;
  status: string;
  topLevelNamespace: string;
  zoneNamespace: string;
  receiverPhysicalId: string;
  assignedTo: string;
  currentConnector: string;
  lastAreaName: string;
  lastReportedGPSTime: string;
  assetSerialNo: string;
  zoneName: string;
  currentZone: string;
  owner: string;
  currentArea: string;
  assetType: string;
  productNamespace: string;
  lastSeen: number;
  versionNamespace: string;
  currentAreaExternalId: string;
  qopperId: string;
  namespace: string;
  assetName: string;
  locationNs: string;
  currentBuildingId: string;
  locationName: string;
  lastBuildingName: string;
  currentSite: string;
  fromParty?: string;
  toParty?: string;
  checkedoutTo?: string;
  checkedoutToUserId?: string;
  checkedoutAt?: string;
  lifecycleStatus?: string;
  organization?: string;
  category?: string;
  checkedoutBy?: string;
  custodyAuditCompletedAt?: string | number | Date;
  custodyOperationAt?: string | number | Date;
  custodyAuditId?: string;
  custodyAuditStatus?: string;
  custodyAuditBy?: string;
  custodyAuditInitiatedAt?: string | number | Date;
  custodyAuditBegunAt?: string | number | Date;
  lastCustodyAuditBy?: string;
  lastCustodyAuditAt?: string | number | Date;
  assetStatus?: string;
  boundAt?: number;
  monitoredStatus?:string;
  qrBarCode?:string;
  lastCustodyStatusUpdateAt?:string;
  custodyStatus?: string;
  imageURL?: string;
  imageThumbnailURL?: string;
  itemCategory?: string;
  organizationName?:string;
  eventPublisherMap?:string;
  custodyAuditVersion?:string;
  custodyAuditPending?:string;
  lastCustodyAuditId?:string;
  parentEntityTypeKey?:string;
  ruleSetName?:string;
  ruleSetId?:string;
  includedInShipments?:string[];
  entityTypeKey?: string;
}

export interface AssetBasicInformation {
  type: string;
  graphType: string;
  id: string;
  baseType: string;
  properties: AssetBasicInformationProperties;
  locationId: string;
}

export interface AdditionalAssetFilter {
  datatype: string;
  fieldName: string;
  filterType: string;
  label: string;
  queryType: string;
}
export interface CdmField {
	id: string;
  name: string;
	type: string;
	size: number;
	required: boolean;
	value: string;
	unique: boolean;
	defaultValue: string;
	autoGenerated: string;
	format: string;
	displayLabel: string;
	tooltip: string;
	searchable: boolean;
	displayable: boolean;
	filterable: boolean;
	editable: boolean;
	instanceUserEditable: boolean;
	instanceUserCreatable: boolean;
	apiFlag: boolean;
	apiUrl: string;
	apiResponseSelectKey: string;
	apiResponseSelectValue: string;
  group?: string,
  isDisplayableOnDetails?: boolean;
  isDisplayableOnSummary?: boolean;
}

export interface Shipment {
  id: string;
  name: string;
  externalId: string;
  type?:string;
}

export interface ExtendedAttributes {
	id: string;
	name: string;
	graphType: string;
	tenantDefault: boolean;
	group: string;
	enabled: boolean;
	type: string;
	cdmFields: CdmField[];
	superClassId: string;
	objectType: string;
	tenantId: string;
	imageURL: string;
}

export enum TABLE_ROW_HEIGH {
  WITH_IMAGE = 50,
  WITHOUT_IMAGE = 25,
}