import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Observable, Subject,debounceTime, Subscription, tap } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { LocationsService } from '../locations.service';
import { SearchApiService } from '@cl/common/services/search-api.service';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { CL_ES_LOCATION_DEFAULT_SEARCH_QUERY } from '@cl/common/constants';
import { UtilsService } from '@cl/common/utils/utils.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { ToastService } from '@cl/common/services/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { LocationUtilsService } from '../location-utils.service';
import { FilterGroup } from '@cl/models';
import { UserService } from '@cl/user/user.service';

@Component({
  selector: 'cl-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit, AfterViewInit {
  @ViewChild('locationNameTemplate') locationNameTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  @ViewChild('catalogTypeTemplate', {static: true}) catalogTypeTemplate: TemplateRef<any>

  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;
  @ViewChild('clearTemplate') clearTemplate: TemplateRef<any>;

  private filterSubject: Subject<string> = new Subject();
  public columnFilters = {};

  private event = new Subject<any>();
  showSPanel = false;
  roleObject:any ={};
  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  initialFilters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  public headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  searchFieldFilter:any=[];
  actionCount: number = 0;
  mainWidth: any;
  tableWidth: number;
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  allLoaded: boolean = false;
  scrollSize = 50;
  showSidePanel: boolean = false;
  mode: string = '';
  hideAdd:boolean=false;
  showFilter:boolean=false;
 private payLoadForLocationList = {
  "scrollId":"",
  "scrollSize":this.scrollSize,
  "globalQueryText":"",
  // "searchQueries": CL_ES_LOCATION_DEFAULT_SEARCH_QUERY,
  "searchFieldFilter":[],
  "searchQueries":[
     {
        "fieldName":"baseClass",
        "queryText":"Location,Route",
        "queryOperator":"should",
        "queryType":"match",
        "childFilters":[{
          "fieldName": "dynamicArea",
          "queryText": "true",
          "queryOperator": "must_not"
      },
      {
        "fieldName": "dynamicRoute",
        "queryText": "true",
        "queryOperator": "must_not"
      },
      {
        "fieldName": "deleted",
        "queryText": "true",
        "queryOperator": "must_not"
      }]
     }
  ],
  "sortField":[

  ]
};
  selectedFilters: any = {};
  columnKeys: string[] = [];
  showingHits: number=0;
  totalHits: number;
  catalogType: string = 'location';
  extendedColumns: any;
  systemColumns = []
  public isEnableCreate = true;
  public isEnableEdit = true;
  public isEnableDelete = true;
  isOrgRestrictedUser:boolean;

  actionBtnPolicies = {
    delete: ['LOCATION_DELETE'],
    create: ['LOCATION_CREATE'],
    edit: ['LOCATION_UPDATE'],
  };
  scrollPositionX: any;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private locationsService: LocationsService,
    private el: ElementRef,
    private _searchApiService: SearchApiService,
    private csvExport: CsvExportsService,
    private _utils: UtilsService,
    private formateDatePipe: FormateDatePipe,
    private route: ActivatedRoute,
    private location: Location,
    private extendedattributeService: ExtendedCatalogAttributeService,
    private persistenceStore: PersistenceService,
    private toastService: ToastService,
    private _dialog: MatDialog,
    public _userService:UserService,
    private _locationUtils: LocationUtilsService
  ) {
    this.route.queryParams.subscribe(params=> {
      if(params['rid']) {
        this.roleObject.id = params['rid'];
        this.roleObject.classType = 'Route'
        this.location.replaceState(this.route.snapshot.url[0].path);
      }
    })
  }

  ngOnInit(): void {
    this.isOrgRestrictedUser = this._userService.getUser()?.orgAware;
    this.filterSubject.pipe( debounceTime( 1000) ).subscribe( () => {
      this.filter();
    });
    this.reload();
    this.getLocationTotalCount();
    this.getLocationList();
    // this.getLocationListFilters();
    this.getFilter();
    this.setContainerWidth();
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.showSidePanel = true;
        this.mode = 'view'
        this.roleObject = evt.roleObject;
      }
      if (evt.eventName === 'edit') {
        this.showSidePanel = true;
        this.mode = 'edit'
        this.roleObject = evt.roleObject;
      }
    });
    this.subscriptions.push(eventSub);
  }

  reload() {
    this.delayedRefreshStart = true;
    this.isEnableEdit = true;
    this.isEnableDelete = true;
    this.selected = [];
    this.getLocationTotalCount();
    // this.getLocationList();
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, this.catalogType, this.table._internalColumns);   
  }

  private getLocationTotalCount() {
    const payload = {
      "scrollSize": 1,
      "searchQueries": [
        {
          "fieldName":"baseClass",
          "queryText":"Location,Route",
          "queryOperator":"should",
          "queryType":"match",
          "childFilters":[{
            "fieldName": "dynamicArea",
            "queryText": "true",
            "queryOperator": "must_not"
        },
        {
          "fieldName": "dynamicRoute",
          "queryText": "true",
          "queryOperator": "must_not"
        },
        {
          "fieldName": "deleted",
          "queryText": "true",
          "queryOperator": "must_not"
        }]
        }
      ]
    }
    const totalCountSub: Subscription = this._searchApiService.globalSearch(payload, false).subscribe({
      next: res => {
        //this.totalHits = res.totalHits;
      },
      error: err => {
        console.error(err);
      }
    });
    this.subscriptions.push(totalCountSub);
  }

  setContainerWidth() {    
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 268;
    } else {
      this.tableWidth = this.mainWidth - 18;
    }
  }

  ngAfterViewInit(): void {
    this.table._offsetX.subscribe(res => {
      if(res != undefined){
        this.scrollPositionX = res
      }      
    })
    this.systemColumns =  [
      {
        prop: 'selected',
        resizable: false,
        canAutoResize: false,
        sortable: false,
        name: '',
        width: 30,
        visible: true,
        headerCheckboxable: true,
        checkboxable: true,
        frozenLeft: true,
        headerTemplate: this.clearTemplate,
      },
      { name: 'Name', prop: 'name', width: 180, cellTemplate: this.locationNameTemplate, visible: true, frozenLeft: true },
      { name: 'Address', prop: 'address', width: 200, visible: true },
      { name: 'Location Type', prop: 'entityType', width: 150, visible: true },
      { name: 'Catalog Type', prop: 'parentEntityType', width: 150,cellTemplate: this.catalogTypeTemplate, visible: true },
      { name: 'Organization', prop: 'organizationName', width: 150, visible: true },
      //{ name: 'Assets', prop: 'location', width: 150, visible: true },
      // { name: 'Shipments In...', prop: 'gatewayZone', width: 150, visible: true },
      // { name: 'Shipments Out...', prop: 'overallStatus', width: 150, visible: true },
      // { name: 'Inventory', prop: 'netStatus', width: 150, visible: true }
      // { name: 'Created By', prop: 'createdBy', width: 150, visible: true },
      // { name: 'Created At', prop: 'createdAt', cellTemplate: this.dateTemplate, width: 180, visible: true },
      // { name: 'Modified By', prop: 'modifiedBy', width: 150, visible: true },
      // { name: 'Modified At', prop: 'modifiedAt', cellTemplate: this.dateTemplate, width: 180, visible: true }
    ];
    this.getExtendedColumnList();
    this.setContainerWidth();
  }

  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
      this.getLocationList();
    }
  }

  getLoadMoreUpdate() {
    this.getLocationList();
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  private formatLocationTypeLables(response) {
    const locationLabels = {"gatewayzone": "Gateway Zone"}
    response[0]?.list.forEach((data)=>{
      data.label = locationLabels.hasOwnProperty(data.label) ? locationLabels[data.label]: data.label;
    })
    return response;
  }

  private getLocationListFilters() {
    this.locationsService.getFilters().subscribe((data:any)=>{
      this.initialFilters = [];
      data.forEach((element) => {
        let obj = {
          collapsed: false,
          expand: true,
          enableCollapsing: false,
          filterType: element.labelKey,
          label: element.label,
          type: 'multiselect',
          list: element.options
        };
        // element.options.forEach(element2 => {
        //     if(!element2.count)
        //     element2.showCounts=null;
        // });
        this.initialFilters.push(obj);
      });
      this.initialFilters.forEach((filter) => {
        const { filterType, list } = filter;
        if (
          this.selectedFilters[filterType] &&
          this.selectedFilters[filterType].length > 0
        ) {
          list.forEach((item) => {
            if (this.selectedFilters[filterType].includes(item.id)) {
              item.checked = true;
            }
          });
        }
      });
      let filters = this.formatLocationTypeLables([...this.initialFilters])
      this.initialFilters = _.cloneDeep(filters);
      this.filters = filters;   
    })
  }
 
  getLocationList(): void {
    if(this.delayedRefreshStart) {
      this.delayedRefreshStart = false;
      this.rows = [];
      this.isEnableEdit = true;
      this.isEnableDelete = true;
      this.selected = [];
      this.payLoadForLocationList.scrollId = null;
    }
    let data = this.payLoadForLocationList;
    this.isLoading = true;
    const userPolicyListData: Subscription = this.locationsService.postLocationList(data).subscribe({
      next: (data: any) => {
      this.allLoaded = false;
      this.totalHits = data.totalHits;
      if (!this.payLoadForLocationList.scrollId) {
        this.rows = [...[]];
      }
      this.payLoadForLocationList.scrollId = data._scroll_id;
      let rowData = data.hits;
      rowData.forEach((d:any) => {
        try {
         let addressJson;
         d.address = d.fullAddress;
         d.classType = d.classType.replace(/([A-Z])/g, ' $1').trim();
         /* if(d.type === 'Site') {
           addressJson = JSON.parse(d.locationAddress);
           d.formattedAddress = this.locationsService.getFormattedAddress(addressJson, d.type);
         } else if(d.type === 'WorkArea' && d.coordinates) {
           addressJson = (d.coordinates).replace(/\\/g, '');
           addressJson = JSON.parse(addressJson);
           d.formattedAddress = addressJson.address;
         } else { //TODO:: Need to cover this custom formatting for other location types as well
           d.formattedAddress = '';
         } */

      } catch (e) {
        
      }
      });
      this.rows = [...this.rows, ...rowData];      
      this.tableRowData = [...this.rows];
      this.showingHits = this.rows.length;
      if (this.showingHits === data.totalHits) {
        this.allLoaded = true;
      }
      this.isLoading = false;
    
      setTimeout(() => {
        this.scrollToOffsetX(this.scrollPositionX)
      }, 10);
      if(this.roleObject.id && this.roleObject.classType){
        this.showSidePanel = true;
        this.roleObject.parentEntityTypeKey = 'route'
        this.mode = 'view';
        this.hideAdd=true;
      }

    },
    error: (err) => {
        console.error(err);
        this.isLoading = false;
    }
  });


    this.subscriptions.push(userPolicyListData);
  }

  scrollToOffsetX(offsetX: number) {
    const tableContainer = this.el.nativeElement.querySelector('.datatable-body');
    if (tableContainer) {
      tableContainer.scrollLeft = offsetX;
      this.table.recalculate();
    }
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  onSelect(rowData: any, event?: any) {
    this.selected = rowData?.selected;
    // if (_.isEmpty(rowData)) {
    //   this.store.dispatch(new MalClearAllSelected());
    // }
    // this.store.dispatch(new MalSelectItem(rowData, false, false)); TODO:: Should handle checkbox selection
    if(this.selected?.length == 1){
      if(rowData.selected[0].organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
      this.isEnableEdit = true;
      this.isEnableDelete = true;
     }else{
      this.isEnableEdit = false;
      this.isEnableDelete = false;
     }
    } else {
      this.isEnableEdit = true;
      this.isEnableDelete = true;
    }
    
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    this.payLoadForLocationList.scrollId = '';
    this.payLoadForLocationList.globalQueryText = val;
    this.getLocationList();
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
     setTimeout(() => {
       this.table?.recalculate();
     }, 10);
  }

  createPayloadAndReloadList() {
    let childFilters = [{
      "fieldName": "dynamicArea",
      "queryText": "true",
      "queryOperator": "must_not"
    },
    {
      "fieldName": "dynamicRoute",
      "queryText": "true",
      "queryOperator": "must_not"
    },
    {
      "fieldName": "deleted",
      "queryText": "true",
      "queryOperator": "must_not"
    }];
    Object.keys(this.selectedFilters).forEach(key => {
      if (this.selectedFilters[key].length > 0) {
          childFilters.push({
            "fieldName": key,
            "queryText": this.selectedFilters[key].join(),
            "queryOperator": "must"
          });
      }
    });
    this.payLoadForLocationList.searchQueries[0].childFilters = childFilters;
    this.getLocationList();
  }

  getPanelUpdate(message: any) {
    let filterEvent = message.filter;
    this.payLoadForLocationList.scrollId = '';
    if (message.action === 'clearFilters') {
      this.selectedFilters = {};
      this.filters = _.cloneDeep(this.initialFilters);
    } else {
        if (filterEvent.checked) {
          if (!this.selectedFilters[filterEvent.filterGroupName]) {
            this.selectedFilters[filterEvent.filterGroupName] = [];
          }
          this.selectedFilters[filterEvent.filterGroupName].push(filterEvent.name);
        } else {
          let index = this.selectedFilters[filterEvent.filterGroupName].indexOf(filterEvent.name);
          this.selectedFilters[filterEvent.filterGroupName].splice(index, 1);
        }
    }
    this.createPayloadAndReloadList();
  }

  formateDownloadData(data:any[]){
    let tempAssetData = [...data];
    let dateFieldArray = ['modifiedAt', 'createdAt'];
    tempAssetData.forEach(loc => {
      dateFieldArray.forEach(element => {
        if(loc[element]){
          loc[element] = this.formateDatePipe.transform(loc[element],'default');
        }
      });
    });
    return tempAssetData;
  }

  downloadReport(reportType: string) {
    let downloadData = this.formateDownloadData(this.rows);
    if(reportType === 'csv') {
      const csvData = this._utils.getSelectedElementsFromArray(downloadData, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Locations-list', _.map(this.columns, 'name'));
    }
  }

  loadSummaryPanel(roleObject:any, event:any){
    if(this._userService.getPolicies().includes('LOCATION_DETAIL')){
      this.hideAdd=true;
      event.preventDefault();
      this.setEvent({ 'eventName': 'summary', roleObject: {...roleObject}});
    }else{
      this.toastService.error('Access not allowed')
    }
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  closeSidePanel(cbAction?){
    this.hideAdd=false;
    this.showSidePanel = false;
    this.roleObject = {};
    if (cbAction) {
      this.reload();
    }
  }

  addLocation() {
    this.mode = 'add';
    this.showSidePanel = true;
  } 

  showEditPanel() {
    if(this.showEditPanel) 
    this.showSidePanel = false;
    setTimeout(() => {
      this.setEvent({ 'eventName': 'edit', roleObject: this.selected[0] });
    },100)
    
  }

  deleteSelectedLocations() {
    if(this.selected.length == 1){
      this._dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          message: `Are you sure want to delete ${this.selected[0].parentEntityType} location?`,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.deleteLocation();
        }
      });
    }
  }

  deleteLocation() {
   this.isLoading = true;
    if(this.selected[0].parentEntityTypeKey.toLowerCase()=="route"){
      this.locationsService.deleteRoute(this.selected[0].parentEntityTypeKey, this.selected[0].id).subscribe(result=>{
        this.isLoading = false;
        this.delayedRefreshStart = true;
        this.toastService.success(this.selected[0].parentEntityTypeKey+' deleted successfully');
      },error=>{
        this.isLoading = false;
      })

    }else{
      this.locationsService.deleteLocation(this.selected[0].parentEntityTypeKey, this.selected[0].id).subscribe(result=>{
        this.isLoading = false;
        this.delayedRefreshStart = true;
        this.toastService.success(this.selected[0].parentEntityTypeKey+' deleted successfully');
      },error=>{
        this.isLoading = false;
      })
    }

  }

  reloadLocationsWithTimer(time = 5) {
    this.toastService.openTimerSnackBar('reloaded in', time);
    setTimeout(() => {
      this.reload();
    }, time * 1000);
  }

  // get extended columns
  async getExtendedColumnList() {
    let catalogTypes = [];
    try {
      catalogTypes = await this.extendedattributeService.getCatalogExtendedMultiColumns(this.catalogType);
    } catch (error) {  
    }

    let userColumnsObj = {};
    let systemColumnsObj = {};
    const preDefinedColumns = ['name','address','type','organization','createdBy','createdAt','modifiedBy','modifiedAt'];

    catalogTypes.forEach((catalogType) => {
      catalogType.cdmFields.forEach((field) => {
        if (field.isDisplayableOnList && !preDefinedColumns.includes(field.name)) {
          if(field.group.toLowerCase() === 'user' && !userColumnsObj[field.name]){
            userColumnsObj[field.name] = {
              name: field.displayLabel,
              prop: field.name,
              width: 180,
              visible: false,
              cellTemplate: field.type.toLowerCase() ==  'date' && this.dateTemplate
            }
          }
          if(field.group.toLowerCase() == 'system' && !systemColumnsObj[field.name]){
            systemColumnsObj[field.name] = {
              name: field.displayLabel,
              prop: field.name,
              width: 180,
              visible: false,
              cellTemplate: field.type.toLowerCase() ==  'date' && this.dateTemplate
            }
          }
        }
      });
    });
    
    const dateColumns = [
      { name: 'Created At', prop: 'createdAt', cellTemplate: this.dateTemplate, width: 180, visible: true },
      { name: 'Created By', prop: 'createdBy', width: 180, visible: true },
      { name: 'Modified At', prop: 'modifiedAt', cellTemplate: this.dateTemplate, width: 180, visible: true },
      { name: 'Modified By', prop: 'modifiedBy', width: 180, visible: true },
    ]
    this.rawColumns = [...this.systemColumns,...Object.values(systemColumnsObj),...Object.values(userColumnsObj), ...dateColumns];
    const {gridColumns, rawColumns} = this._utils.updateColumnsList(this.catalogType, [...this.rawColumns]);
    this.rawColumns = [...rawColumns];

    catalogTypes.forEach((catalogType) => {
      let self =this;
      this.rawColumns.forEach(function(item){
        let searchIndex = catalogType.cdmFields.findIndex(
          (field) => field.name === item.prop &&  field.searchable == true
        );

        if (searchIndex>-1) {
           item.headerTemplate= self.hdrTpl;
        }
      })
    })

    this.columns = [...gridColumns];
    this.columns.forEach(col=>{
      if(col.prop =="parentLocation"){
          col.prop="buildingName"
      }
    })
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});

    this.table?.recalculate();
    this._changeDetectorRef.detectChanges();
  }

  sortCdmFields(cdmFields) {
    let negativeOrderedFields = _.filter(cdmFields, {'order': -1});
    let positiveOrderedFields = _.filter(cdmFields, function(o) { return o.order>-1; });
    positiveOrderedFields = _.sortBy(positiveOrderedFields, 'order');
    let result = [...positiveOrderedFields, ...negativeOrderedFields];
    return result;
  }

  private formFilters(data) {
    this.filters = data.map((entry: any) => {
      let filter: FilterGroup = {
        list: [],
        filterType: entry.labelKey,
        label: entry.label,
        type: entry.uiSelectType? entry.uiSelectType:"multiselect",
      };
      filter.list = entry.options.map(list => ({
        name: list.id,
          checked: !!false,
          count: (list.count>0?list.count:0),
          displayLabel: list.name,
          label: list.name
      }));
      filter.enableCollapsing = filter.list.length > 10;
      filter.collapsed = filter.list.length > 10;
      filter.expand = true;
      return filter;
    })
    this.initialFilters = _.cloneDeep(this.filters);
  }

  getFilter(){
    this._searchApiService.getFilters(this.catalogType).then((res:any)=>{
      this.formFilters(res);
    })
  }

  filterSummary(data:any){
    this.roleObject.id = data.id;
    this.roleObject.parentEntityTypeKey = data.type;    
  }

  filterInput( $event ) {
    this.filterSubject.next('');
  }

  closeFilter(){
    this.showFilter = false;
    this.headerHeight = 30;
    Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
    this.filter()
  }

  showHideFilter(){
    this.showFilter = !this.showFilter;
    if(this.showFilter){
      this.headerHeight = 60;
    }else{
      this.headerHeight = 30;
      Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
      this.filter();
    }
    setTimeout(() => {
      this.table.recalculate();
    }, 10); 
  }


  filter() {
    this.searchFieldFilter=[]
      Object.keys( this.columnFilters ).forEach(key => {
        let newName='';
        if(key=="nameFormated"){
          newName = "name"; 
        }else if(key == "address"){
          newName = "fullAddress"; 
        }
        this.searchFieldFilter.push({
          "name":!_.isEmpty(newName)?newName:key,
          "value":this.columnFilters[key]
        })
        console.log(`${key}: ${this.columnFilters[key]}`)
      });
      this.payLoadForLocationList.scrollId = '';
      this.payLoadForLocationList.searchFieldFilter = this.searchFieldFilter;
      this. getLocationList();
  }
}