import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CatalogType, CdmField } from '@cl/@types/extended-attributes.types';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { ToastService } from '@cl/common/services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { InventoryService } from '../inventory.service';
export enum IN_ACTIVE  {
   IN_ACTIVE = 'Inactive'

}
@Component({
  selector: 'cl-inventory-create-edit',
  templateUrl: './inventory-create-edit.component.html',
  styleUrls: ['./inventory-create-edit.component.scss'],
})
export class InventoryCreateEditComponent implements OnInit, AfterViewInit {
  @Input() isEdit: boolean = false;
  @Input() inventoryId: string;
  @Output() closeSidePanel = new EventEmitter<boolean>();
  @ViewChild('formRenderer') formRenderer!: DynamicFormRendererComponent;
  tabSelectedEvent = new BehaviorSubject<number>(0);

  isCreating = false;
  isDetailsFetching = false;

  fields: CdmField[] = [];
  inputFields: InputField[] = [];

  inventoryCatalog!: CatalogType;
  inventoryDetails;
  catalogType: CatalogType;
  catalogTypesList: any;
  isCatalogTypeError = false;
  withField:boolean = true;
  readonly IN_ACTIVE:string = 'IN-ACTIVE'
  constructor(
    private _dynamicFormService: DynamicFormService,
    private _extendedAttributeService: ExtendedCatalogAttributeService,
    private _toastService: ToastService,
    private _Service: InventoryService,
    private cdref: ChangeDetectorRef 
  ) {}

  ngOnInit(): void {
    // this.renderForm();
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   this.renderForm();
  // }

  ngAfterViewInit(): void {
    if (!this.isEdit) {
      // this.changeCatalogType();
      this.getInventorypesList();
    }else{
      this.isEdit = true;
      this.getInventoryDetails();
    }
  }
  async renderForm(catalogType) {
    this.getCDMFields(catalogType);
  }

  async getCDMFields(inventoryType) {
  this.inventoryCatalog =
      await this._extendedAttributeService.getCatalogExtendedColumns(inventoryType);

    let fields: CdmField[] = this.inventoryCatalog.cdmFields.filter(
      (field) => field.instanceUserCreatable
    );

    this.fields = this._dynamicFormService.orderFields([...fields], 'order');

    this.inputFields = await this._dynamicFormService.generateInputFields(this.fields,this.isEdit,this.inventoryDetails?.properties);
  console.log(this.inputFields,"this.inputFields")
  const status = this.inputFields.filter((item)=>item.id==='status');
 
  const ind:number = status[0].options.findIndex((item)=>item==='IN-ACTIVE');
  status[0].options.splice(ind,1,IN_ACTIVE.IN_ACTIVE)  
  status[0].value = status[0].value=== "IN-ACTIVE" || status[0].value=== "INACTIVE"  ?'Inactive':status[0].value
   
  }

  closePanel(isReload) {
    this.closeSidePanel.emit(isReload);
  }

  async onSave(catalog) {
    const formData = this.formRenderer.onSubmit();
    if (formData.valid) {
      const dynamicFormPayload = formData.getRawValue();
      const customToggleFields = this.inputFields.filter(
        (field) => field.type === 'toggle' && field.options.length == 2
      );

      if (customToggleFields) {
        customToggleFields.forEach((field) => {
          if (dynamicFormPayload[field.id] !== undefined) {
            dynamicFormPayload[field.id] = dynamicFormPayload[field.id]
              ? field.options[0]
              : field.options[1];
          }
        });
      }

      const objectTypeEnumerations = this.inputFields.filter(
        (field) =>
          field.type == 'select' &&
          field.apiUrl &&
          field.apiKey &&
          field.apiLabel
      );

      if(objectTypeEnumerations){
        objectTypeEnumerations.forEach(field => {
          if(typeof dynamicFormPayload[field.id] == 'object'){
            dynamicFormPayload[field.id] = dynamicFormPayload[field.id].key
          }
        })
      }

      const payload = {
        ...dynamicFormPayload,
        type: this.inventoryCatalog.additionalProperties.entityTypeKey,
        typeId: this.inventoryCatalog.id,
      };

      this.isCreating = true;

      try {
        if (this.isEdit) {
          await this._Service.updateInventory(payload, this.inventoryId, payload.type);
        } else {
          await this._Service.createInventory(payload, payload.type);
        }
        this.closePanel(true);
        this._toastService.success(
          `Inventory ${this.isEdit ? 'updated' : 'created'} successfully`
        );
      } catch (error) {}

      this.isCreating = false;
    }
  }

  async changeCatalogType(objectType: string) {
    this.renderForm(objectType);
  }

  async getInventorypesList(){
    this.catalogTypesList = await this._extendedAttributeService.getCatalogExtendedDeviceColumns('product',this.withField,
        'inventory'
      );
      this.catalogTypesList.sort(function (a,b) {
        return a.name.toLowerCase()>b.name.toLowerCase()?1:-1
    })
  }

  async getInventoryDetails(){
    this.isDetailsFetching = true;
    if (this.isEdit) {
      this.inventoryDetails = await this._Service.getInventory(this.inventoryId);
    }
    await this.changeCatalogType(this.inventoryDetails.type);
    this.isDetailsFetching = false;
  }
 

  onNext() {
    if(!this.catalogType){
      this.isCatalogTypeError = true;
      return;
    }
    this.isCatalogTypeError = false;
    this.changeCatalogType(this.catalogType.objectType);
    this.tabSelectedEvent.next(0);
  }
}