import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Injectable({
  providedIn: 'root'
})
export class ThemingService {

  baseTheme: any = {
      primaryActionColor: '#2879de',
      primaryActionColorGradient: '#0b5cc6',
      primaryActionColorMediumLight: '#0b5cc6',
      primaryActionColorLighter: '#f2f7fd',
      primaryActionColorLightest: '#f0f6ff',
      actionButtonBg: '#f5fbff',
      anchorLinksColor: '#2879de',
      mainMenuButtonGradientBg1: '#385BF6',
      mainMenuButtonGradientBg2: '#2F357C',
      mainMenuFontColor: '#ffffff',
      headerNotifIconColor: '#004695',
      formElementsBg: '#e9a824',
      sectionBg: '#f5fbff',
      sectionBorder: '#bfdbfe'
  };
  updatedTheme: any = {};

  constructor(private sanitize: DomSanitizer) { }

  public getBaseThemeProps() {
    return this.baseTheme;
  }

  public setThemeProps(props: any) {
    if(props) {
      if(props.hasOwnProperty('customCSS')) {
        //this.sanitize.bypassSecurityTrustStyle(props['customCSS']);
        let sheet = document.createElement('style');
        sheet.innerHTML = this.sanitize.sanitize(SecurityContext.STYLE, props['customCSS']);
        document.head.appendChild(sheet);
        delete props['customCSS'];
      }
      this.updateColorProps(props);
    }
    this.updatedTheme = {...this.updatedTheme, ...props};
  }

  public getUpdatedTheme() {
    return this.updatedTheme;
  }

  private updateColorProps(newProps: {}) {
    Object.keys(newProps).map(k =>
      document.documentElement.style.setProperty(`--${k}`, newProps[k])
    );
  }
}
