import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@cl/common/services/toast.service';
import { ApiSubscriptionService } from '../api-subscription.service';

@Component({
  selector: 'cl-edit-credentials-dialog',
  templateUrl: './edit-credentials-dialog.component.html',
  styleUrls: ['./edit-credentials-dialog.component.scss'],
})
export class EditCredentialsDialogComponent implements OnInit {
  subscriptionKey = '';
  submitted = false;
  constructor(
    public dialogRef: MatDialogRef<EditCredentialsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiSubscription: ApiSubscriptionService,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.subscriptionKey = this.data?.key || '';
  }

  async onConfirm() {
    this.submitted = true;
    try {
      await this._apiSubscription.updateWebhookSubscriptionKey(
        this.subscriptionKey
      );
      this._apiSubscription.webhookSecretKey = this.subscriptionKey;
      this._toastService.success('Webhook Secret key is updated successfully');
      this.dialogRef.close(true);
    } catch (error) {}
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
