<div class="pl-2 pr-1 py-2 h-full">
  <div class="flex gap-2 items-top">
    <div class="flex flex-col text-left">
      <div class="h-full">{{'shipmentDetails.trackingId'|translate}}</div>
      <div class="h-full">{{'shipmentDetails.selectAsset'|translate}}</div>
    </div>
    <div>
      <div class="font-bold h-6">{{ trackingID }}</div>
      <div class="user-detail__item">
        <div class="dropdown-input">
        <input
        [matAutocomplete]="assetSelect"
        placeholder="Select asset"
        class="basic-input drp-search"
        type="search"
        (click)="matOpen()"
        [(ngModel)]="selectedAsset" 
      />
      <mat-autocomplete 
      (optionSelected)="changeAsset($event.option.value)"
      (closed)="matClose()"
       #assetSelect="matAutocomplete" [displayWith]="displayFn">
        <ng-container >
          <mat-option
            *ngFor="let  asset of assets | search : selectedAsset : 'properties.name'" 
            [value]="asset"
          >
            <span>{{asset.properties.name }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      </div>
    </div>
    </div>
    <div class="ml-auto">
      <div>
        <button
          [matMenuTriggerFor]="menu1"
          class="panel-button white bgcolor4 ml-2"
          [disabled]="!isTimezoneAvailable"
          title="Select timezone in which to show the milestone times"
        >
          <i class="fa fa-clock-o"></i> {{ currentMileStone.name }}
        </button>

        <mat-menu #menu1="matMenu">
          <button
            mat-menu-item
            *ngFor="let mileStoneZone of mileStoneZones"
            (click)="changeMileStone(mileStoneZone)"
          >
            <span>{{ mileStoneZone.name }}</span>
          </button>
        </mat-menu>

        <button
          [matMenuTriggerFor]="menu"
          class="fs14 px-2 py-1 rounded-sm white bgcolor4 ml-2"
        >
          {{'shipmentDetails.sort'|translate}}
          <i
            class="fa"
            [ngClass]="isReverseOrder ? 'fa-caret-down' : 'fa-caret-up'"
          ></i>
        </button>

        <mat-menu #menu="matMenu">
          <div><b class="p-2 font-bold">Sort carrier milestone events</b></div>
          <button mat-menu-item (click)="changeChronologicalOrder(false)">
            <span>In chronological order</span>
          </button>
          <button mat-menu-item (click)="changeChronologicalOrder(true)">
            <span>In reverse chronological order</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="my-2 flex grow h-full gap-3">
    <div class="grow overflow-y-auto" style="height: calc(100% - 66px)">
      <cl-shipment-tracker
      [currentMileStone] ="currentMileStone "
        [data]="trackingInfo"
        [isReverse]="isReverseOrder"
      ></cl-shipment-tracker>
    </div>
  </div>
</div>
