export enum BulkAction {
    bulkbind = 'Bulk bind',
    bulkunbind = 'Bulk unbind',
    bulkadd = 'Bulk add',
    bulkdelete = 'Bulk delete'
}

export enum BulkEntity {
    asset = 'asset',
    user = 'user',
    sensor = 'sensor'
}

export enum FileExtensions {
    Excel = '.xlsx',
    CSV = '.csv',
    PSV = '.psv'
}

export interface BulkPanelModel {
    title?: string,
    icon?: string,
    uploadUrl?: string,
    templateUrl?: string,
    fileType?: string
    bulkAction?: string,
    method?: string,
    appName?: string,
    isDynamicFile?: boolean
}