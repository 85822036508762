<div class="mt-2 mb-2">
    <span class="page-title">Recipes <span class="bgcolor4 text-white p-0.5 px-1 rounded-full text-[10px]">Beta</span></span>
  </div>
  
  <div class="sensor-febric wt-ngx-grid-wrapper list-page-wrapper relative list-page px-2">
    <div class="flex justify-between pt-1 pb-1">
        <div class="action-panel-left self-start flex items-center" *ngIf="showSidePanel==false || recipeReadOnly">
            
            <cl-list-search [placeholder]="'Search Recipe'" (search)="updateFilter($event)"
                [columns] = "['name']"></cl-list-search>
            <button [disabled]="editDisabled"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
               
                class="p-4-10 border-general br2" (click)="openCreateSidePanel(true);closeSummaryPanel()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>
            <button [disabled]="deleteDisabled"
                [ngClass]="[deleteDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 ml10" (click)="deleteRecipeDialog()">
                <i class="fa fa-trash"></i>
                Delete
            </button>
        </div>
       
        <div
        class="md-add-button list add-shipment-btn" style="z-index: 999" *ngIf="!showSummaryPanel"
        
        >
        <div class="add-button" *ngIf="!showSidePanel || mode == 'summary'" (click)="openCreateSidePanel(false)">
          <span class="fa fa-plus"></span>
        </div>
        <div class="add-button" (click)="closeSidePanel()" *ngIf="showSidePanel && mode !== 'summary'">
          <span class="fa fa-close"></span>
        </div>
      </div>
    </div>
    <div class="h-calc-40" *ngIf="showSidePanel==false || recipeReadOnly">
        <main class="flex flex-row h-100" [style.width.px]="mainWidth - 10">
           
            <div class="grid-container grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div *ngIf="delayedRefreshStart">
                    <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                    
                    ></cl-refresh-countdown>
                </div>
                <div class="h-100" [style.width.px]="tableWidth">
                  <ngx-datatable     
                  class="h-calc-40"
                  [rows]="rows"
                  [columnMode]="'force'"
                  [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                  rowHeight="auto"
                  [scrollbarH]="true"
                  [scrollbarV]="true" 
                  [selected]="selected"
                  [selectionType]="selection"
                  (activate)="onActivate($event)"
                  (select)="onSelect($event)"
                  [reorderable]="false"
                >
                  <ngx-datatable-column
                    [width]="30"
                    [sortable]="false"
                    [canAutoResize]="false"
                    [draggable]="false"
                    [resizeable]="false"
                    prop="qcardName"
                  >
                    <ng-template
                      ngx-datatable-header-template
                      let-value="value"
                      let-allRowsSelected="allRowsSelected"
                      let-selectFn="selectFn"
                    >
                    <label class="datatable-checkbox ng-star-inserted">
                      <input type="checkbox" class="select-row-checkbox" [checked]="allRowsSelected" (change)="selectFn($event)" />
                    </label>
                    </ng-template>
                    <ng-template
                      ngx-datatable-cell-template
                      let-value="value"
                      let-isSelected="isSelected"
                      let-onCheckboxChangeFn="onCheckboxChangeFn"
                    >
                    <label class="datatable-checkbox ng-star-inserted">
                      <input type="checkbox" class="select-row-checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event,value)" />
                    </label>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="150" name="Recipe Name" prop="name">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <a  class="nav-link edit" (click)="viewRecipeDetails(value)">{{value}}
                      </a>
                    </ng-template>
                 </ngx-datatable-column>
                 <ngx-datatable-column [width]="250" name="Catalog Entity Type" prop="entityName">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <a  class="nav-link edit" (click)="viewEntityDetails(value)">{{value}}
                    </a>
                  </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column [width]="50" name="Status" prop="active">
                <ng-template let-row="row" let-value="value" #dateTemplate ngx-datatable-cell-template>
                  {{value == true ?'Enabled':'Disabled'}}
                </ng-template>
               </ngx-datatable-column>
               <ngx-datatable-column [width]="80" name="Mode" prop="recipeMode">
                <ng-template let-row="row" let-value="value" #dateTemplate ngx-datatable-cell-template>
                  {{value == 'prod' ?'Production':'Test'}}
                </ng-template>
               </ngx-datatable-column>
                  <ngx-datatable-column [width]="135"  name="Created At" prop="createdAt">
                    <ng-template let-row="row" let-value="value" #dateTemplate ngx-datatable-cell-template>
                      {{value| formateDate:'default'}}
                    </ng-template>
                  </ngx-datatable-column>                 
                  <ngx-datatable-column [width]="100" name="Created By"></ngx-datatable-column>
                  <ngx-datatable-column [width]="135" name="Modified At" prop="updatedAt">
                    <ng-template let-row="row" let-value="value" #dateTemplate ngx-datatable-cell-template>
                      {{value| formateDate:'default'}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column [width]="100" prop="modifiedBy" name="Modified By"></ngx-datatable-column>                 
                </ngx-datatable>
                 
                </div>
            </div>

        
        </main>
    </div>
  
    <ng-template #dateTemplate let-row="row" let-value="value" >
      {{value | formateDate:'default'}}
    </ng-template>
    <ng-template #skuNameTemplate let-row="row" let-value="value" let-i="index">
      <a class="font-12-bold" href (click)="loadSummaryPanel(row, $event);closeSidePanel()">{{value}}</a>
  </ng-template>

  <div class="summery-panel" *ngIf="showSidePanel==true">
    <cl-recipe-add-panel [recipeReadOnly]="recipeReadOnly" [selectedRecipe]="selectedRecipe" [selectedEntityType]="selectedEntityType" [isAdd]="isAdd" [assetId]="" (closeSidePanel)="closeSidePanel();closePopupAssetPanel($event)"></cl-recipe-add-panel> 
  </div>
 
  </div>
  
  