import { AssetCategory, ClGridColumnDefs, CustodyActions, Range } from "../models/index";

export class MalInit {
  static readonly type = "[Manage Assets] Init";
  constructor() {}
}

export class MalUpdateColumnDefs {
  static readonly type = "[Manage Assets] Update Column Defs";
  constructor(public columnDefs: ClGridColumnDefs[]) {}
}

export class MalReset {
  static readonly type = "[Manage Assets] Reset";
  constructor() {}
}

export class MalInitFilterSet {
  static readonly type = "[Manage Assets] Init Filter Set";
  constructor(public filterSet: any) {}
}

export class MalReloadFilterSet {
  static readonly type = "[Manage Assets] Reload Filter Set";

  constructor() {}
}

export class MalSearchByTerm {
  static readonly type = "[Manage Assets] Search by Term";
  constructor(public searchTerm: string, public newSearch: boolean, public filters: any[]) {}
}
export class MalColumnSorted {
  static readonly type = "[Manage Assets] Sort by Column";
  constructor(public sortField: any) {}
}
export class MalSearchByFilter {
  static readonly type = "[Manage Assets] Search by Filter";
  constructor(public newSearch: boolean) {}
}
export class MalBootstrapAssets {
  static readonly type = "[Manage Assets] Bootstrap Assets";
  constructor() {}
}
export class MalDownloadAssets {
  static readonly type = "[Manage Assets] Download Assets";
  constructor(public columns: string[], public columnMapping: { [key: string]: string }) {}
}
export class MalSearchListScrolled {
  static readonly type = "[Manage Assets] Search list scrolled";
  constructor() {}
}
export class MalToggleItem {
  static readonly type = "[Manage Assets] Toggle Item";
  constructor(public node: any, public clickAction: string) {}
}
export class MalUpdateCounts {
  static readonly type = "[Manage Assets] Update Counts";
  constructor() {}
}
export class MalSelectItem {
  static readonly type = "[Manage Assets] Select Item";
  constructor(public node: any, public editNode: boolean, public bindNode: boolean) {}
}
export class MalOpenBindPanel {
  static readonly type = "[Manage Assets] Open Bind asset panel";
  constructor(public node: any, public type?: string) {}
}
export class MalBulkBindSuccess {
  static readonly type = "[Manage Assets] Bulk Bind or Unbind succeeded";
  constructor(public event: any) {}
}

export class MalToggleCheckoutPanel {
  static readonly type = "[Manage Assets] Toggle Checkout panel";
  constructor(public node: any, public showCheckout: boolean = true) {}
}
export class MalToggleTransferPanel {
  static readonly type = "[Manage Assets] Toggle Transfer panel";
  constructor(public node: any, public showTransfer: boolean = true) {}
}

export class MalToggleTransferInServicePanel {
  static readonly type = "[Manage Assets] Toggle Transfer in service panel";
  constructor(public node: any, public showTransferInService: boolean = true) {}
}

export class MalToggleCheckInPanel {
  static readonly type = "[Manage Assets] Toggle Check In panel";
  constructor(public node: any, public showCheckIn: boolean = true) {}
}

export class MalToggleConfirmReceiptPanel {
  static readonly type = "[Manage Assets] Toggle Confirm Receipt panel";
  constructor(public node: any, public showConfirmReceipt: boolean = true) {}
}

export class MalDeleteAssets {
  static readonly type = "[Manage Assets] Delete assets";
  constructor(public assetList: any[], public assetIDs: any[]) {}
}
export class MalUnbindAssets {
  static readonly type = "[Manage Assets] Unbind assets";
  constructor(public boundAssets: any[], public assetList: any[]) {}
}

export class MalToggleFilter {
  static readonly type = "[Manage Assets] Toggle Filter";
  constructor(public name: string, public range?: Range) {}
}

export class MalClearFilters {
  static readonly type = "[Manage Assets] Clear Filters";
  constructor() {}
}
export class MalToggleAddAsset {
  static readonly type = "[Manage Assets] Toggle Add Asset";
  constructor() {}
}
export class MalToggleSingleAsset {
  static readonly type = "[Manage Assets] Toggle Single Asset";
  constructor() {}
}
export class MalEditAsset {
  static readonly type = "[Manage Assets] Edit Asset";
  constructor(public node: any) {}
}
export class MalUpdateAsset {
  static readonly type = "[Manage Assets] Update Asset";
  constructor(public node: any) {}
}

export class MalCreateAsset {
  static readonly type = "[Manage Assets] Create Asset";
  constructor(public node: any) {}
}
export class MalFormUpdated {
  static readonly type = "[Manage Assets] Form Updated";
  constructor(public field: string, public value: string) {}
}
export class MalSelectSensor {
  static readonly type = "[Manage Assets] Select Sensor";
  constructor(public node: any) {}
}
export class MalSelectMultipleSensor {
  static readonly type = "[Manage Assets] Select Multiple Sensor";
  constructor(public node: any) {}
}
export class MalBindAsset {
  static readonly type = "[Manage Assets] Bind Asset";
  constructor() {}
}
export class MalMultiBindAsset {
  static readonly type = "[Manage Multiple Sensors] Bind Asset";
  constructor(public node: any) {}
}
export class MalCancelBind {
  static readonly type = "[Manage Assets] Cancel Bind Asset";
  constructor() {}
}
export class MalInitCategories {
  static readonly type = "[Manage Assets] Init Categories";
  constructor() {}
}
export class MalAddCategory {
  static readonly type = "[Manage Assets] Add Category";
  constructor() {}
}

export class MalSaveCategory {
  static readonly type = "[Manage Assets] Save Category";
  constructor(public category: AssetCategory, public update: boolean = false) {}
}

export class MalToggleCategorySelection {
  static readonly type = "[Manage Assets] Toggle category selection";
  constructor(public category: AssetCategory) {}
}

export class MalToggleAllCategorySelection {
  static readonly type = "[Manage Assets] Toggle all category selection";
  constructor(public selectAll: boolean = true) {}
}

export class MalEditCategory {
  static readonly type = "[Manage Assets] Edit Category";
  constructor(public category: AssetCategory) {}
}

export class MalDeleteCategory {
  static readonly type = "[Manage Assets] Delete Category";
  constructor(public category: AssetCategory) {}
}
export class MalCancelCategoryEdit {
  static readonly type = "[Manage Assets] Cancel Add or Edit Category";
  constructor() {}
}
export class MalGetLocations {
  static readonly type = "[Manage Assets] Get Locations";
  constructor() {}
}
export class MalClearAllSelected {
  static readonly type = "[Manage Assets] Clear all selected";
  constructor() {}
}
export class MalSelectAllItems {
  static readonly type = "[Manage Assets] Select All Items";
}
export class MalAssetDetailNavigation {
  static readonly type = "[Manage Assets] Navigation clicked";
  constructor(public node: any) {}
}
export class MalSortAssetList {
  static readonly type = "[Manage Assets] Sort Asset List";
  constructor(public columnDefs: ClGridColumnDefs[]) {}
}
export class MalMultiScanAsset {
  static readonly type = "[Manage Multiple Asset] Scan Code";
  constructor(public scanAction: any, public newSearch: boolean) {}
}
