import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'cl-readouts-card',
  templateUrl: './readouts-card.component.html',
  styleUrls: ['./readouts-card.component.scss']
})
export class ReadoutsCardComponent implements OnChanges {
  loadingStats = false;
  gotData = false;
  @Input() data;
  @Input() list;
  items = [];
  constructor() { }

  ngOnChanges() {
    if (this.list && this.list.length) {
      this.items = this.list
        // .map(item => {
        // return { ...item, value: Math.round(item.value) }
        // })
        .slice(0, 3)
    }
  }

}
