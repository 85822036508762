import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppAuthService } from "@cl/authenticate/app-auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { PropertyService } from '@cl/property/property.service';
import { TenantUtilService } from '@cl/tenant/tenant-util.service';
import { NavService } from '@cl/@core/shell/nav.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'cl-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  logoURL = 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png';
  logoAltText = 'Parkoursc';
  showFullBanner = false;
  showFullPageBackground = false;
  bannerUrl = 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/cloudleaf_login_banner.png';
  actionButtonColor = '#0094d0';
  bannerWidth = '584px';
  confirmStr: string;
  appliedTheme: any = {
    logoURL: '',
    logoAltText: '',
    bannerUrl: '',
    actionButtonColor: '#0094d0',
    showFullPageBackground: true,
    showFullBanner: false,
    bannerWidth: '100%'
  };
  defaultTheme = {
    logoURL: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png',
    logoAltText: 'Parkoursc',
    bannerUrl: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_banner.png',
    actionButtonColor: '#0094d0',
    showFullPageBackground: true,
    showFullBanner: false,
    bannerWidth: '100%',
    title: 'ParkourSC',
    favicon: 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/favicon.ico'
  };
  public enableValidationContainer: Boolean = false;
  public userCredModifier: any = {};
  public showPassword: Boolean = false;
  public showRetypePassword: Boolean = false;
  public params: any = {};
  public showResetFlow: Boolean = false;
  public verifyEmail: Boolean = false;
  public error: any;
  public validationCheck: any = [
    {
      "key": "noOfChar",
      "label": "At least 8 characters",
      "isEnabled": false
    },
    {
      "key": "atleastThree",
      "label": "At least 3 of the following:",
      "isEnabled": false,
      "subCheck": [{
        "key": "lowerCase",
        "label": "Lower case letters (a-z)",
        "isEnabled": false,
      }, {
        "key": "upperCase",
        "label": "Upper case letters (A-Z)",
        "isEnabled": false,
      }, {
        "key": "numbers",
        "label": "Numbers (0-9)",
        "isEnabled": false,
      }, {
        "key": "splChar",
        "label": "Special characters (ex. !@#$%^&*)",
        "isEnabled": false,
      }]
    },
  ];
  public primaryEmail: string = '';
  public userId: string = '';
  public showResetInstructions: Boolean = false;
  constructor(private appAuth: AppAuthService, private route: ActivatedRoute, private router: Router, 
    private apiProvider: ApiProviderService,
    private propertyService: PropertyService,
    private tenentUtils: TenantUtilService,
    private navService: NavService,
    private renderer: Renderer2,
    private titleService: Title
    ) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
          this.confirmStr = params['confirmStr'];
          if(this.confirmStr) {
            this.showResetFlow = true;
            this.verifyEmail = false;
          } else {
            this.showPassword = false;
            this.verifyEmail = true;
          }
        }
      );

      this.appAuth.isAuthenticated().subscribe( (authenticated) => {
        if(authenticated) {
          //TODO::handle the redirection to either route it came from, if not to default route
          const menuList = this.navService.getMainMenuList();
            this.router.navigateByUrl(this.navService.getDefaultRoute(menuList[0]));
        } else {
          this.apiProvider.getAPIProviders(true)
            .then(async () => {
              try {
                const response = await this.propertyService.getLoginBulkProperties();
                if(response && response.length) {
                  response.map((item)=>{
                    if(item.key === 'domain.preLoginLogoURL') {
                      this.defaultTheme.logoURL = item.value;
                    } else if(item.key === 'domain.logoAltText') {
                      this.defaultTheme.logoAltText = item.value;
                    } else if(item.key === 'domain.bannerUrl') {
                      this.defaultTheme.bannerUrl = item.value;
                    } else if(item.key === 'domain.actionButtonColor') {
                      this.defaultTheme.actionButtonColor = item.value;
                    } else if(item.key === 'domain.applicationName') {
                      this.defaultTheme.title = item.value;
                    } else if(item.key === 'domain.applicationFavicon') {
                      this.defaultTheme.favicon = item.value;
                    }
                  })
                }
                this.applyThemeAndLoadWidget();
              } catch (error) {
                this.applyThemeAndLoadWidget();
              }
            })
            .catch(() => {
              this.applyThemeAndLoadWidget();
            });
          this.tenentUtils.clearPersistedTenant();
        }
      })
  }

  applyThemeAndLoadWidget() {
    this.appliedTheme = this.defaultTheme;
    this.titleService.setTitle(this.appliedTheme.title);
    const faviconElem = document.getElementById('favicon');
    this.renderer.setAttribute(faviconElem, 'href', this.appliedTheme.favicon);
  }
  verifyPrimaryEmail() {
    let payload: any = {
      "primaryEmail": this.primaryEmail,
      "id": this.userId
    };

    this.appAuth.verifyPrimaryEmail(payload).subscribe({
      next: () => {
        this.error = null;
        let data = {
          "id": this.userId
        }
        this.appAuth.sendResetPasswordLink(data).subscribe({
          next: () => {
            this.error = null;
            this.showResetFlow = false;
            this.verifyEmail = false;
            this.showResetInstructions = true;
          },
          error: (res) => {
            this.error = res['error'] ? res['error']['error-message'] : 'Something went wrong!';
          }
        })
      },
      error: (res) => {
        this.error = res['error'] ? res['error']['error-message'] : 'Something went wrong!';
      }
    })
  }

  disableSubmit = () => (this.primaryEmail == null || this.primaryEmail.length < 1 ||this.userId == null);

  disableUpdate = () => {
    return (this.userCredModifier.newSecret == null ||
      this.userCredModifier.newSecret.length < 1 ||
      this.userCredModifier.newSecret != this.userCredModifier.newSecretAgain);
  };

  checkSubtaskCount() {
    var check = false, count = 0;
    this.validationCheck[1].subCheck.forEach((subValidation: any) => {
      if (subValidation.isEnabled) {
        count = count + 1;
      }
    })
    if (count >= 3) {
      check = true;
    }
    return check;
  }

  togglePassword = (category: any) => {
    if (category == 'password') {
      this.showPassword = !this.showPassword;
    } else if (category == 'passwordAgain') {
      this.showRetypePassword = !this.showRetypePassword;
    }
  }

  enableCheck(key: string, subKey: string, flag: Boolean) {
    this.validationCheck.forEach((validation: any) => {
      if (validation.key == key && !subKey) {
        validation.isEnabled = flag;
      }
      if (validation.subCheck) {
        validation.subCheck.forEach((subValidation: any) => {
          if (subValidation.key == subKey) {
            subValidation.isEnabled = flag;
          }
        })
      }
    })
  }

  validatePassword = (event: any) => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    this.enableValidationContainer = true;
    if (this.userCredModifier.newSecret && this.userCredModifier.newSecret.length >= 8) {
      this.enableCheck('noOfChar', '', true);
    } else {
      this.enableCheck('noOfChar', '', false);
    }
    if ((/[a-z]/.test(this.userCredModifier.newSecret))) {
      this.enableCheck('atleastThree', 'lowerCase', true);
    } else {
      this.enableCheck('atleastThree', 'lowerCase', false);
    }
    if ((/[A-Z]/.test(this.userCredModifier.newSecret))) {
      this.enableCheck('atleastThree', 'upperCase', true);
    } else {
      this.enableCheck('atleastThree', 'upperCase', false);
    }
    if ((/[0-9]/.test(this.userCredModifier.newSecret))) {
      this.enableCheck('atleastThree', 'numbers', true);
    } else {
      this.enableCheck('atleastThree', 'numbers', false);
    }
    if ((format.test(this.userCredModifier.newSecret))) {
      this.enableCheck('atleastThree', 'splChar', true);
    } else {
      this.enableCheck('atleastThree', 'splChar', false);
    }
    if (this.checkSubtaskCount()) {
      this.enableCheck('atleastThree', '', true);
    } else {
      this.enableCheck('atleastThree', '', false);
    }
  }

  changePassword = () => {
    this.userCredModifier["oldSecret"] = this.confirmStr;
    this.appAuth.submitNewPassword(this.userCredModifier).subscribe({
      next: () => {
        this.error = null;
        this.router.navigate(['/', 'login'])
      },
      error: (res) => {
        this.error = res['error'] ? res['error']['error-message'] : 'Something went wrong!';
      }
    })
  };

}
