import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import {
  Entity,
  AssetCategory,
  CustodyActions
} from '../../common/models/index';
import { map, retry, shareReplay } from "rxjs/operators";
import { DT_UPDATED } from '@cl/common/action-list.service';
import { AdditionalAssetFilter } from '@cl/@types/asset.type';

@Injectable({
  providedIn: 'root'
})
export class AssetListService {
  graphAppBaseURL: string;
  showSummaryPanel: boolean = false;
  selectedAssetId: string = '';
  additionalFilters: AdditionalAssetFilter[];
  private get headers() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(private http: HttpClient, private apiProvider: ApiProviderService) {
  }

  getAdditionalAssetFilters(): AdditionalAssetFilter[] {
    return this.additionalFilters;
  }

  setAdditionalAssetFilters(filters: AdditionalAssetFilter[]) {
    this.additionalFilters = filters;
  }

  getAssetList(params: any) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`, params);
  }

  updateGraphNode(body: any, type: any, uid: any): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/${type}/${uid}`;
    return this.http.put<Entity>(url, body);
  }
  createGraphNode(entity: any, type: any): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/${type}`;
    return this.http.post<Entity>(url, entity);
  }

  deleteAssets(uids: any[]): Observable<Entity> {
    const body = {
      assetIds: uids,
    };
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/delete`;
    return this.http.post<Entity>(url, body);
  }

  getCategories(type: any): Observable<AssetCategory[]> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/category/type/${type}`;

    return this.http
      .get<AssetCategory[]>(url)
      .pipe(map((res) => res.sort((a, b) => ((a?.properties!.name?.toLowerCase() < b?.properties!.name?.toLowerCase()) ? -1 : 1))));
  }

  getAsset(assetId: string, nodeClass = ""): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/${assetId}`;
    return this.http.get<Entity>(url).pipe(
      map((res) => {
        return new Entity().deserialize(res, nodeClass);
      })
    );
  }

  bindAsset(assetId: string, sensorId: string): Observable<Entity> {
    const body = {
      assetId: assetId,
      sensorId: sensorId,
    };
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset`;
    return this.http.post<Entity>(url, body);
  }

  multiBindAsset(sensorList): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset/bind?assetIdType=id`;
    return this.http.post<Entity>(url, sensorList);
  }

  unbindAsset(taggedAssetId: string): Observable<any> {
    const headerDict = {
      'Content-Type': 'application/json',
    }
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset/${taggedAssetId}`;
    return this.http.delete<any>(url, { headers: new HttpHeaders(headerDict) });
  }

  checkoutAsset(payload: { assetId: string; userId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/checkout`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  transferAsset(payload: { assetId: string; userId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/transfer`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  transferAssetInService(payload: { assetId: string; userId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/service`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  confirmReceiptAsset(payload: { assetId: string; condition: boolean }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/accept`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  checkInAsset(payload: { assetId: string; condition: boolean }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/checkin`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  returnAsset(payload: { assetId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/return`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  revokeAsset(payload: { assetId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/revoke`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  bulkCoc(payload: any[], operation: CustodyActions) {
    const actionUrlMap = {
      [CustodyActions.Checkout]: "asset/checkout/bulk",
      [CustodyActions.Transfer]: "asset/transfer/bulk",
      [CustodyActions.InService]: "asset/service/bulk",
      [CustodyActions.Confirm]: "asset/accept/bulk",
      [CustodyActions.CheckIn]: "asset/checkin/bulk",
      [CustodyActions.Return]: "asset/return/bulk",
      [CustodyActions.CancelledCheckout]: "asset/revoke/bulk",
      [CustodyActions.CancelledReturn]: "asset/revoke/bulk",
      [CustodyActions.CancelledTransfer]: "asset/revoke/bulk",
    };

    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/${actionUrlMap[operation]}`;
    return this.http.post<Entity>(url, payload, { headers: this.headers });
  }

  getAssetCustody(assetId: string, limit: number = 5000) {
    let url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/events/Asset/custody/asset/${assetId}`;
    const params = {
      limit: `${limit}`,
    };
    const headerDict = {
      'Content-Type': 'application/json',
    }
    return this.http.get(url, { params, headers: new HttpHeaders(headerDict) });
  }

  globalSearch<T = any>(searchParams, queryParams = {}) {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`;
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post(url, searchParams, { headers: headers, params: queryParams }).pipe(
      map((res) => {
        return res as T;
      })
    );
  }

  updateAssetBindFlow(body, assetId, bindFlowCheck): Observable<Entity>{
      const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/asset/${assetId}?bindflow=${bindFlowCheck}`;
      return this.http.patch<Entity>(url, body).pipe(
        map((res) => {
          return res;
         })
      );
    }

  getAssetComponents(assetType){
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/catalog/${assetType}`);
    return firstValueFrom(obs$);
  } 
  
  getAssetFilterList(catalogName){
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${catalogName}/filter`
    );
    return firstValueFrom(obs$);
  }


}
