import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LocationsService } from '@cl/locations/locations.service';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { LocationUtilsService } from '@cl/locations/location-utils.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'cl-location-add-edit',
  templateUrl: './location-add-edit.component.html',
  styleUrls: ['./location-add-edit.component.scss']
})
export class LocationAddEditComponent implements OnInit, OnChanges {
  @Output() selectedPosition = new EventEmitter();
  @Input('locationType') locationType:string;
  @Input('catalogTypes') catalogTypes:any[];
  @Input('mode') mode:string;
  @Input('zoom') zoom:number;
  @Input('polygonPts') polygonPts = [];
  @Input('editLocation') editLocation:any;
  @Input('mapClickedPosition') mapClickedPosition: any;
  @ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;
  
  locationForm: FormGroup;
  dynamicInputFields:InputField[] = [];
  regex = "^[A-Za-z0-9 @ & ( ) _  : ' , . ; + \" / -]+$";

  constructor(private locationService: LocationsService, public dialog: MatDialog, private _locationUtils: LocationUtilsService,
    private _dynamicFormService: DynamicFormService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(this.mode == 'add'){
      this.buildEmptyForm();
    } else if(this.mode == 'edit' && this.editLocation){
      let position = this.editLocation.position;
      if(!position) {
        if(this.editLocation.coordinates && JSON.parse(this.editLocation.coordinates).geometry?.coordinates) {
          position = JSON.parse(this.editLocation.coordinates).geometry.coordinates[1] + ',' + JSON.parse(this.editLocation.coordinates).geometry.coordinates[1];
        }
      }
      
      this.locationForm = new  FormGroup({
        name : new FormControl(this.editLocation.name,[Validators.required, Validators.pattern(this.regex)]),
        position : new FormControl(position,[Validators.required, Validators.pattern(this.regex)]),
      });
      this.locationForm.value.position?this.selectedPosition.emit({lat:Number(this.locationForm.value.position.split(',')[0]), lng:Number(this.locationForm.value.position.split(',')[1])}):'';
      this.getExtendedAttributes();
    } 
    this.locationForm.get('position').disable();
  }

  buildEmptyForm() {
    this.locationForm = new  FormGroup({
      name : new FormControl('',[Validators.required, Validators.pattern(this.regex)]),
      position : new FormControl('',[Validators.required, Validators.pattern(this.regex)]),
    });
    this.getExtendedAttributes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['mapClickedPosition']) {
      this.locationForm?.patchValue({position: this.mapClickedPosition.lat + ',' + this.mapClickedPosition.lng});
    }

    // if(Array(changes['catalogTypes']).length > 0) {
    //   this.getExtendedAttributes();
    // }
  }

  submitForm(): any {
    if (this.locationForm.valid ) {
      if(this.dynamicForm){
        if(this.dynamicForm?.onSubmit().valid){
          return this.preparePayload();
        }
      } else {
        return this.preparePayload();
      }
      
    } else {
      this.validateAllFormFields(this.locationForm); 
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }

  preparePayload(): any {
    this.locationForm.get('position').enable();
    let payload = {
      "name": this.locationForm.value.name,
      "position": this.locationForm.value.position,
    }
    const dynamicFormGroup = this.dynamicForm?.onSubmit();
  if(dynamicFormGroup){
    payload = {...payload, ...dynamicFormGroup.getRawValue() }
  }
  return payload;
  }

  async getExtendedAttributes(){
    const areaCatalog = await this._locationUtils.getLocationExtendedAttributes(this.locationType);

    const fields = areaCatalog.cdmFields.filter(field => field.group === 'User' && field.instanceUserCreatable);

    const orderedFields = this._dynamicFormService.orderFields([...fields],'order');

    this.dynamicInputFields = [];

    orderedFields.forEach((field) => {

      const inputField: InputField = {
        type: field.type as InputType,
        value: field?.value || '',
        defaultValue: field?.defaultValue || '',
        required: field.required,
        id: field.id,
        displayLabel: field?.displayLabel || '',
        regex: field?.regex,
        isDynamicField: true,
        isDisabled: this.mode == 'edit' && !field.instanceUserEditable     
      };

      if (field.type.toLowerCase() === 'string') inputField.type = 'text';
      if (field.type.toLowerCase() === 'enumeration') {
        inputField.type = 'select';

        if (typeof inputField.value === 'string') {
          inputField.options = inputField.value.split(',');
        }
        if (Array.isArray(inputField.value)) {
          inputField.options = inputField.value;
        }
        inputField.value = inputField.defaultValue;
      }
      if (field.type.toLowerCase() === 'date') inputField.type = 'date';
      if (
        field.type.toLowerCase() === 'decimal' ||
        field.type.toLowerCase() === 'integer'
      ) {
        inputField.type = 'number';
      }
      if (field.type.toLowerCase() === 'boolean') {
        inputField.type = 'toggle';
        inputField.value = String(field.value) === 'true';
      }

      if(this.mode == 'edit'){
        inputField.value = this.editLocation[field.id]
      }

      if (
        ['text', 'date', 'number', 'select', 'toggle'].includes(inputField.type)
      ) {
        this.dynamicInputFields.push(inputField);
      }
    });
    this.cd.detectChanges();
  }
}
