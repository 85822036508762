import { Component, OnInit, Input } from '@angular/core';
import * as G from '@cl/common/colors';
import { ClientConfigService } from "../../../../../common/services/client-config.service";

@Component({
  selector: 'app-graph-card',
  templateUrl: './graph-card.component.html',
  styleUrls: ['./graph-card.component.scss']
})
export class GraphCardComponent implements OnInit {
  loadingStats = false;
  gotData = false;
  chartType: any = 'LineChart';

  options = {
    colors: [G.mainColor],
    legend: 'none',
    vAxis: {
      gridlines: {
        color: 'transparent'
      },
      ridlines: {
        color: 'transparent'
      },
      baselineColor: 'transparent'
    },
    hAxis: {
      gridlines: {
        color: 'transparent'
      },
      ridlines: {
        color: 'transparent'
      },
      baselineColor: 'transparent'
    },
    enableInteractivity: false,
    chartArea: {
      backgroundColor: {
        stroke: '#fff',
        strokeWidth: 0
      }
    }
  };
  clientTheme;
  @Input() data;
  constructor(private _clientConfigService: ClientConfigService) {
    this.clientTheme = this._clientConfigService.getClientConfig('theme');
    this.options.colors = [this.clientTheme.mainColor]
  }

  ngOnInit(): void {
  }



}
