<div class="align-center">
    <mat-dialog-content class="mat-typography">
    <div mat-dialog-content>
        <div class="p10 alerts-resolve-modal-container">
            <div  class="pl20 pr20 text-left mb10">
                <div class="font-12 wt__white_space_pre_line_text"><b>
                    <time>{{data.time | formateDate:'default'}}</time>
                </b></div>
                <div>{{data?.altBody}}</div>
            </div>
            <hr />
            <div class="text-center color-black font-14 font-bold mb10 mt10">
                <span>Confirm Issue Resolution</span>
            </div>
            <div class="text-center color-black font-14 mb15 lh18">
                <span>Verify you've completed the following to resolve selected alert.</span>
            </div>
            <div class="pos-rel text-left">
                <mat-checkbox  (change)="isFormFieldChanged($event)" [(ngModel)]="NOT_AN_ISSUE">
                    Not an issue
                </mat-checkbox>
            </div>
            <div class="pos-rel mt-4 text-left">
                <h4 class="emphasized mb-0">Notes</h4>
                <textarea (input)="isFormFieldChanged($event)" [(ngModel)]="resolveComment" class="p-2 basic-input w-100 mt-0 mx-0"></textarea>
            </div>
          </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <a class="panel-button m-2" (click)="onDismiss()">Cancel</a>
  <button class="panel-button white bgcolor4" [class.disabled]="!resolveFormValid" [disabled]="!resolveFormValid" cdkFocusInitial  (click)="resolveAlerts()">Confirm</button>
</mat-dialog-actions>
</div>