<div class="cl-side-panel bcolor9 border flex flex-col z-10 top-11">
    <section class="cl-side-panel-title side-panel-header-bg">
      <div class="grow flex items-center">
        <img src="assets/svgs/circle-icons-white/location.svg" />
        <span class="black font-bold">{{device.entityType | titlecase}} Summary</span>
      </div>
      <i class="fa fa-times-circle-o mr-4" (click)="closePanel()" [ngStyle]="{cursor:'pointer'}"></i>
    </section>
    <section class="cl-side-panel-content">
      <div class="row align-items-start">
        <div *ngIf="activePane === 'summary'">
          <div class="p-4">
              <div class="flex fs14 color16 mt-1">
                  <div class="basis-2/4 font-bold"style="word-break:break-word">{{device.entityType | titlecase}} ID:</div>
                  <div class="basis-2/4"style="word-break:break-word"  *ngIf="!isGateway">{{device?.id}}</div>
                  <div class="basis-2/4" style="word-break:break-word" *ngIf="isGateway">{{device?.physicalId}}</div>
              </div>
              <div class="flex fs14 color16 mt-1">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Name:</div>
                <div class="basis-2/4 ellipsis"style="word-break:break-word" [title]="device?.name">{{device?.name}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.zoneName && !isTracker">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Zone:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.zoneName}}</div>
            </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.gatewayZone && isGateway">
                  <div class="basis-2/4 font-bold"style="word-break:break-word">Zone:</div>
                  <div class="basis-2/4"style="word-break:break-word">{{device?.gatewayZone}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.assetExternalId">
                  <div class="basis-2/4 font-bold"style="word-break:break-word">Bound Asset:</div>
                  <div class="basis-2/4"style="word-break:break-word">
                    <a href="javascript:void(0)" [routerLink]="'/assetdetails/' + device?.assetId" [textContent]="device?.assetExternalId">{{device?.assetExternalId}}</a>
                  </div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.model">
                  <div class="basis-2/4 font-bold"style="word-break:break-word">Model Number:</div>
                  <div class="basis-2/4"style="word-break:break-word">{{device?.model}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.status">
                  <div class="basis-2/4 font-bold"style="word-break:break-word">Status</div>
                  <div class="basis-2/4"style="word-break:break-word">{{device?.status}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.lastSeen">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Last Seen:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.lastSeen| date:'MMM d y h:mm:ss a'}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.gatewayName">
                <div class="basis-2/4 font-bold"style="word-break:break-word">GW:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.gatewayName}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.owner">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Owner:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.owner}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.type && !isGateway">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Type:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.type}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.entityType">
                <div class="basis-2/4 font-bold"style="word-break:break-word"> {{device?.baseType | titlecase}} Type:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.entityType | titlecase}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.parentEntityTypeKey">
                <div class="basis-2/4 font-bold"style="word-break:break-word"> Catalog Type:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.parentEntityTypeKey | titlecase}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.organizationName">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Organization:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.organizationName}}</div>
              </div>
              <ng-container *ngFor="let cdmField of deviceObj?.cdmFields">
                <div class="flex fs14 color16 mt-1" *ngIf="cdmField?.isDisplayableOnSummary && getExtendedAttributeValue(cdmField, device)">
                    <div class="basis-2/4 font-bold"style="word-break:break-word"><span class="text-bold" [textContent]="cdmField?.displayLabel"></span>:&nbsp;</div>
                    <div class="basis-2/4"style="word-break:break-word"><span [textContent]="getExtendedAttributeValue(cdmField, device)"></span></div>
                </div>
              </ng-container>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.createdBy">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Created By:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.createdBy}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.createdAt">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Created At:</div>
                <div class="basis-2/4"style="word-break:break-word">{{datePipe.transform(device?.createdAt)}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.modifiedBy">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Modified By:</div>
                <div class="basis-2/4"style="word-break:break-word">{{device?.modifiedBy}}</div>
              </div>
              <div class="flex fs14 color16 mt-1" *ngIf="device?.modifiedAt">
                <div class="basis-2/4 font-bold"style="word-break:break-word">Modified At:</div>
                <div class="basis-2/4"style="word-break:break-word">{{datePipe.transform(device?.modifiedAt)}}</div>
              </div>
          </div>
      </div>
      </div>
      <div class="spin-pos mt-10" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
    </section>
    <div class="assign-details-btn" *ngIf="sensorType !== 'Route'">
      <button class="panel-button color4 bgcolor2 bcolor4 bw-1" *ngIf="!isGateway">
        <a class="color4" href="#/{{detailsUrl}}/{{sensorId}}?type={{this.device?.entityTypeKey || ''}}">{{device.entityType | titlecase}} Details
          <i class="fa fa-arrow-right ml-1 color4"></i>
        </a></button>
      <button class="panel-button color4 bgcolor2 bcolor4 bw-1" *ngIf="isGateway">
        <a class="color4" href="#/{{detailsUrl}}/{{sensorId}}/{{device.entityTypeKey}}">{{device.entityType | titlecase}} Details
          <i class="fa fa-arrow-right ml-1 color4"></i>
        </a></button>  
    </div>
  </div>
