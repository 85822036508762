<div class="parcel-shipment">
  <form #form="ngForm" [formGroup]="shipmentForm" action="">
    <div class="section-border">
      <div class="section-title">General Shipment Details</div>
      <div class="user-detail__item">
        <div class="form-label">Name <span class="mandatory">*</span></div>
        <input
          type="text"
          (keypress)="restrictedCharactersOnly($event)"
          formControlName="name"
          class="basic-input"
          placeholder="Provide a unique name"
          (change)="checkNames()"
          [pattern]="regularExpression"
          (input)="nameErrorValidation = false"
        />
        <div
          *ngIf="submitted && shipmentForm.get('name').errors?.['required']"
          class="text-danger"
        >
          Shipment name is required.
        </div>
        <div
          *ngIf="shipmentForm.get('name').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid shipment name.
        </div>
        <div
          *ngIf="nameErrorValidation && !shipmentForm.get('name').errors"
          class="text-danger"
        >
          {{ nameErrorText }}
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">External ID</div>
        <input
          type="text"
          (keypress)="restrictedCharactersOnly($event)"
          formControlName="externalId"
          class="basic-input"
          placeholder="Enter a unique ID "
          (change)="checkExternelID()"
          [pattern]="regularExpression"
          (input)="externalIdErrorValidation = false"
        />
        <div
          *ngIf="submitted && shipmentForm.get('externalId').errors?.['required']"
          class="text-danger"
        >
          External ID is required.
        </div>
        <div
          *ngIf="shipmentForm.get('externalId').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid external id.
        </div>
        <div
          *ngIf="
            externalIdErrorValidation && !shipmentForm.get('externalId').errors
          "
          class="text-danger"
        >
          {{ externalIdErrorText }}
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Order Number</div>
        <input
          type="text"
          (keypress)="restrictedCharactersOnly($event)"
          formControlName="orderNum"
          class="basic-input"
          [pattern]="regularExpression"
          placeholder="Enter order number"
        />
        <div
          *ngIf="shipmentForm.get('orderNum').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid order number.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Shipper Name</div>
        <input
          class="basic-input"
          placeholder="Enter shipper name"
          [pattern]="regularExpression"
          formControlName="shipperName"
        />
        <div
          *ngIf="shipmentForm.get('shipperName').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid shipper name.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Carrier Name</div>
        <div class="dropdown-input">
          <input
            [matAutocomplete]="carrier"
            class="basic-input drp-search"
            placeholder="Select carrier"
            formControlName="carrierCode"
            type="search"
            (blur)="onCloseField('carrierCode')"
          />
          <mat-autocomplete
            #carrier="matAutocomplete"
            [displayWith]="displayFn"
            cdkScrollable
            (optionSelected)="onSelectField($event, 'carrierCode')"
          >
            <ng-container>
              <mat-option
                *ngFor="
                  let cr of carrierCodesList
                    | search : shipmentForm.value.carrierCode : 'carrierName'
                "
                [value]="cr"
                >{{ cr.carrierName }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Mode of Transport</div>
        <div>
          <mat-select
            class="basic-input"
            placeholder="Select mode of transport"
            formControlName="modeoftransport"
          >
            <ng-container>
              <mat-option value="Parcel">Parcel</mat-option>
              <mat-option value="Air">Air</mat-option>
              <mat-option value="Multimodal">Multimodal</mat-option>
              <mat-option value="Ocean / Marine">Ocean / Marine</mat-option>
              <mat-option value="Rail">Rail</mat-option>
              <mat-option value="Road">Road</mat-option>
              <mat-option value="Road - FTL (Full Truck Load)"
                >Road - FTL (Full Truck Load)</mat-option
              >
              <mat-option value="Road - LTL (Less than Truck Load)"
                >Road - LTL (Less than Truck Load)</mat-option
              >
            </ng-container>
          </mat-select>
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Customer Name</div>
        <input
          class="basic-input"
          placeholder="Enter Customer Name"
          [pattern]="regularExpression"
          formControlName="custName"
        />
        <div
          *ngIf="submitted && shipmentForm.get('custName').errors?.['required']"
          class="text-danger"
        >
          Customer Name is required.
        </div>
        <div
          *ngIf="shipmentForm.get('custName').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid customer name.
        </div>
      </div>
      <div class="user-detail__item relative">
        <div class="form-label">
          Customer Code
          <i
            (click)="openHelpDialog()"
            class="fa fa-info-circle help-icon color4 text-base mr-1 cursor-pointer"
            title="Note: list of customer codes is visible to all users who can create/edit shipments. New customer codes will be added to list of values automatically. Customer codes are case insensitive"
          ></i>
        </div>
        <input
          [matAutocomplete]="auto1"
          placeholder="Start typing to search or add"
          (blur)="onCloseCustField('custCode')"
          [pattern]="regularExpression"
          class="basic-input"
          formControlName="custCode"
        />
        <button
          class="add-org-icon add-button add-org"
          *ngIf="
            customerCodeAddEnable && this.shipmentForm.value.custCode.length > 2
          "
          (click)="addCustomerCode()"
        >
          <span class="fa fa-plus" (click)="addCustomerCode()"></span>
        </button>
        <div
          *ngIf="
            customerCodeAddEnable && this.shipmentForm.value.custCode.length > 2
          "
          class="text-danger"
        >
          Customer Code not found. Add value?
        </div>
        <mat-autocomplete
          #auto1="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onSelectField($event, 'custCode')"
        >
          <mat-option *ngIf="isLoading" class="is-loading"
            >Loading...</mat-option
          >
          <ng-container *ngIf="!isLoading">
            <mat-option
              *ngFor="let coustomer of coustomerCodesList"
              [value]="coustomer"
            >
              <span>{{ coustomer }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Customer Order Reference</div>
        <input
          type="text"
          (keypress)="restrictedCharactersOnly($event)"
          formControlName="custOrdRef"
          class="basic-input"
          [pattern]="regularExpression"
          placeholder="Enter customer order reference"
        />
        <div
          *ngIf="shipmentForm.get('custOrdRef').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid customer order reference.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Recipient Name</div>
        <input
          type="text"
          (keypress)="restrictedCharactersOnly($event)"
          formControlName="recipientName"
          class="basic-input"
          [pattern]="regularExpression"
          placeholder="Enter name"
        />
        <div
          *ngIf="shipmentForm.get('recipientName').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid recipient name.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">
          Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog>
        </div>

        <div class="relative">
          <mat-select
            class="basic-input"
            placeholder="Select organization"
            formControlName="organization"
          >
            <ng-container>
              <mat-option *ngFor="let org of orgList" [value]="org.id">{{
                org.name
              }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-icon
            *ngIf="shipmentForm.value.organization && !isOrgRestrictedUser"
            class="select-icon-close"
            (click)="onOrgclose()"
            >close</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="card" *ngIf="dynamicInputFields.length > 0">
      <div
        class="card-header px-2"
        (click)="togglers.additionalFields = !togglers.additionalFields"
      >
        Additional Shipment Details
        <i
          class="fa fa-chevron-right float-right mt-1 cursor-pointer"
          [ngClass]="{
            'rotate-Icon': togglers.additionalFields
          }"
        ></i>
      </div>
      <div
        class="card-body bg-white p-0"
        [ngClass]="togglers.additionalFields ? '' : 'hidden'"
      >
        <cl-dynamic-form-renderer
          [columnMode]="'Two'"
          [inputFields]="dynamicInputFields"
          #dynamicForm
        ></cl-dynamic-form-renderer>
      </div>
    </div>
    <div class="section-border">
      <div class="section-title">
        Direct to Residence? <span class="mandatory">*</span>
      </div>
      <div class="user-detail__item">
        <mat-radio-group
          class="foz12 capitalize"
          aria-label="Select an option"
          formControlName="directToResidence"
        >
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>
        <div
          *ngIf="submitted && shipmentForm.get('directToResidence').errors"
          class="text-danger"
        >
          Direct to residence is required.
        </div>
      </div>
    </div>
    <div class="section-border">
      <div class="section-title">Rules and Monitoring Options</div>
      <div class="user-detail__item">
        <div class="form-label">Rule Set</div>
        <div class="dropdown-input">
          <input
            [matAutocomplete]="ruleSet"
            class="basic-input drp-search"
            placeholder="Select rule set"
            formControlName="ruleset"
            type="search"
            (blur)="onCloseField('ruleset')"
          />
          <mat-autocomplete
            #ruleSet="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onSelectField($event, 'ruleset')"
          >
            <ng-container>
              <mat-option
                *ngFor="
                  let rs of ruleSetsList | search : shipmentForm.value.ruleset
                "
                [value]="rs"
                >{{ rs.name }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Device Profile</div>
        <div class="dropdown-input">
          <input
            [matAutocomplete]="sensor"
            class="basic-input drp-search"
            placeholder="Select device profile"
            formControlName="sensorProfile"
            (blur)="onCloseField('sensorProfile')"
            type="search"
          />
          <mat-autocomplete
            #sensor="matAutocomplete"
            (optionSelected)="onSelectField($event, 'sensorProfile')"
          >
            <ng-container>
              <mat-option
                *ngFor="
                  let s of sensorProfilesList
                    | search : shipmentForm.value.sensorProfile
                "
                [value]="s"
                >{{ s }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div class="section-border pr-2">
      <div class="section-title">Origin and Destination Details</div>
      <div class="user-detail__item full-width">
        <mat-radio-group
          class="foz12 capitalize"
          formControlName="direction"
          aria-label="Select an option"
        >
          <mat-radio-button value="Outbound">Outbound</mat-radio-button>
          <mat-radio-button value="Inbound">Inbound</mat-radio-button>
          <mat-radio-button value="Return">Return</mat-radio-button>
        </mat-radio-group>
        <div class="flex">
          <div class="user-detail__item pl-0">
            <div class="form-label">Route <span class="mandatory">*</span></div>
            <input
              *ngIf="mawb.length"
              type="text"
              disabled
              class="d-cursor basic-input"
            />
            <div *ngIf="!mawb.length" class="dropdown-input">
              <input
                [matAutocomplete]="exRoute"
                class="basic-input"
                placeholder="Select route"
                formControlName="route"
                [value]="routeValue"
                type="search"
                [title]="shipmentForm.value?.route?.name"
                id="routeSearch"
                (input)="getRoutesbySearch()"
              />
              <div
                *ngIf="submitted && shipmentForm.get('route').errors"
                class="text-danger"
              >
                Route is required.
              </div>
              <mat-autocomplete
                #exRoute="matAutocomplete"
                (closed)="onCloseRoute()"
                (optionSelected)="onSelectRoute($event)"
                [displayWith]="displayFn"
              >
                <mat-option *ngIf="isLoading" class="is-loading"
                  >Loading...</mat-option
                >
                <mat-option
                  *ngIf="!routesList.length && !isLoading"
                  class="is-loading"
                  >No routes found</mat-option
                >
                <ng-container *ngIf="!isLoading">
                  <mat-option *ngFor="let route of routesList" [value]="route"
                    >{{ route.name }}
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </div>
          </div>
          <div class="user-detail__item">
            <div class="form-label">Master Airway Bill</div>
            <input
              type="search"
              class="basic-input inp-mawb"
              placeholder="Enter master airway bill"
              [value]="mawb"
              (input)="onMawbChange($event)"
              [ngClass]="{ 'd-cursor': selectedRoute }"
              [disabled]="selectedRoute"
              #mawbInput
            />
            <span
              *ngIf="mawb.length == 13"
              class="bt-selected cursor-pointer"
              (click)="getAWBdata()"
              ><i class="fa fa-check" aria-hidden="true"></i
            ></span>
          </div>
        </div>
        <div *ngIf="routeisLoading">
          <div class="mt-2 flex justify-center">
            <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
          </div>
        </div>
        <div *ngIf="selectedRoute && !routeisLoading">
          <div class="flex w-full">
            <div
              class="grow"
              *ngFor="
                let route of selectedRoute.locationDetails;
                let last = last;
                let even = even;
                let first = first
              "
            >
              <div class="h-7">
                <div
                  class="grow w-20 text-center mx-auto truncate"
                  *ngIf="!even && !last"
                  title="{{ route.areaName }}"
                >
                  {{ route.areaName }}
                </div>
              </div>
              <div class="flex items-center">
                <div class="grow h-0 relative" *ngIf="!first">
                  <div
                    class="border-t-2 relative h-full border-dashed border-slate-400 top-0 left-0"
                  ></div>
                  <div
                    class="border-t-2 w-full border-gray-500 absolute h-full top-0 left-0"
                  ></div>
                </div>
                <div
                  class="dot h-10 overflow-hidden p-0.5 flex justify-center"
                  [ngClass]="
                    last ? 'p-0' : 'border-gray-500 rounded-full border-2 w-10 '
                  "
                >
                  <img
                    *ngIf="route.travel_mode == 'Air' && !last"
                    src="assets/icons-svgs/plane.svg"
                  />
                  <img
                    *ngIf="route.travel_mode == 'Road' && !last"
                    src="assets/icons-svgs/truck.svg"
                  />
                  <img
                    *ngIf="route.travel_mode == 'Railcar' && !last"
                    src="assets/icons-svgs/railcar.svg"
                  />
                  <img
                    class="w-6"
                    *ngIf="last"
                    src="assets/icons-svgs/pin-destination.svg"
                  />
                </div>
                <div class="grow h-0 relative" *ngIf="!last">
                  <div
                    class="border-t-2 relative h-full border-dashed border-slate-400 top-0 left-0"
                  ></div>
                  <div
                    class="border-t-2 w-full border-gray-500 absolute h-full top-0 left-0"
                  ></div>
                </div>
              </div>
              <div
                class="grow truncate w-20"
                title="{{ route.areaName }}"
                [ngClass]="
                  last
                    ? 'text-right float-right'
                    : first
                    ? 'text-left'
                    : 'text-center'
                "
                *ngIf="even || last"
              >
                {{ route.areaName }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="routeAWB.length > 0">
          <div class="flex w-full">
            <div
              class="grow"
              *ngFor="
                let route of routeAWB;
                let last = last;
                let even = even;
                let first = first
              "
            >
              <div class="h-7">
                <div
                  class="grow w-20 text-center mx-auto truncate"
                  *ngIf="!even && !last"
                  title="{{ route }}"
                >
                  {{ route }}
                </div>
              </div>
              <div class="flex items-center">
                <div class="grow h-0 relative" *ngIf="!first">
                  <div
                    class="border-t-2 relative h-full border-dashed border-slate-400 top-0 left-0"
                  ></div>
                  <div
                    class="border-t-2 w-full border-gray-500 absolute h-full top-0 left-0"
                  ></div>
                </div>
                <div
                  class="dot h-10 overflow-hidden p-0.5 flex justify-center"
                  [ngClass]="
                    last ? 'p-0' : 'border-gray-500 rounded-full border-2 w-10 '
                  "
                >
                  <img *ngIf="!last" src="assets/icons-svgs/plane.svg" />
                  <img
                    class="w-6"
                    *ngIf="last"
                    src="assets/icons-svgs/pin-destination.svg"
                  />
                </div>
                <div class="grow h-0 relative" *ngIf="!last">
                  <div
                    class="border-t-2 relative h-full border-dashed border-slate-400 top-0 left-0"
                  ></div>
                  <div
                    class="border-t-2 w-full border-gray-500 absolute h-full top-0 left-0"
                  ></div>
                </div>
              </div>
              <div
                class="grow truncate w-20"
                title="{{ route }}"
                [ngClass]="
                  last
                    ? 'text-right float-right'
                    : first
                    ? 'text-left'
                    : 'text-center'
                "
                *ngIf="even || last"
              >
                {{ route }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="flightDetails.length > 0">
          <h4 class="flight-details">Flight Details</h4>
          <div class="cl-flight-seperator row">
            <div class="col-sm-2 pl-0 pr-0">
              <label class="lh24">Source</label>
            </div>
            <div class="col-sm-2 pl-0 pr-0">
              <label class="lh24">Destination</label>
            </div>
            <div class="col-sm-2 pl-0 pr-0">
              <label class="lh24">Flight Number</label>
            </div>
            <div class="col-sm-3 pr-0">
              <label class="lh24">
                <span>
                  <span>Departure Date</span>
                </span></label
              >
            </div>
            <div class="col-sm-3 pl-0 pr-0">
              <label class="lh24">Departure Time</label>
            </div>
          </div>
          <div
            *ngFor="let item of flightDetails; let i = index"
            class="cl-flight-data row"
          >
            <div class="col-sm-2 pl-0 pr-0">
              <label
                class="cl-text-ellipsis w-100 pr-1 truncate"
                title="{{ item.areaName }}"
                >{{ item.areaName }}</label
              >
            </div>
            <div class="col-sm-2 pl-0 pr-0">
              <label
                class="cl-text-ellipsis w-100 pr-1 truncate"
                title="{{ destination[i].areaName }}"
                >{{ destination[i].areaName }}</label
              >
            </div>
            <div class="col-sm-2 pl-0 pr-0">
              <input
                type="text"
                class="inp-pad"
                [(ngModel)]="item.text"
                (change)="flightNumber(i, $event)"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div class="col-sm-3 pr-0">
              <input
                type="date"
                class="inp-pad"
                [(ngModel)]="item.date"
                (change)="flightDeparture(i, $event)"
                [ngModelOptions]="{ standalone: true }"
                [ngClass]="[!item.text ? 'disabled' : '']"
              />
            </div>
            <div class="col-sm-3 pl-0 pr-0">
              <label class="cl-text-ellipsis w-100 pr-1 truncate">
                {{ item.flightTime }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-border">
      <div class="section-title">Scheduling</div>
      <div class="user-detail__item">
        <div class="form-label">Planned Departure</div>
        <cl-custom-date-picker
          [dateRange]="departureDateRange"
          [disablePreviousdates]="preventBeforeDepartureDate"
          [displayFormat]="dateDisplayFormat"
          (dateChanged)="depDateUpdated($event, 'plannedDeparture')"
          [ranges]="null"
          [singleDatePicker]="true"
          [datePickerClass]="datePickerClass"
          [opened]="datePickerOpenedDeparture"
        ></cl-custom-date-picker>
        <div
          *ngIf="submitted && shipmentForm.get('plannedDeparture').errors"
          class="text-danger"
        >
          Planned Departure is required.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Planned Arrival</div>
        <cl-custom-date-picker
          [dateRange]="arrivalDateRange"
          [disablePreviousdates]="preventBeforeArrivalDate"
          [displayFormat]="dateDisplayFormat"
          (dateChanged)="depDateUpdated($event, 'plannedArrival')"
          [ranges]="null"
          [singleDatePicker]="true"
          [datePickerClass]="datePickerClass"
          [opened]="datePickerOpenedArrival"
        >
        </cl-custom-date-picker>
        <div
          *ngIf="submitted && shipmentForm.get('plannedArrival').errors"
          class="text-danger"
        >
          Planned Arrival is required.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Planned ETA</div>
        <div class="fs-13 w-break">
          estimate is provided based on shipment details
          {{ convertDateforETA(dateforETA) }}
        </div>
      </div>
    </div>
    <div class="section-border">
      <div class="section-title">
        Add Assets <span class="mandatory">*</span>
        <span
          class="text-danger ml-2 font-normal"
          *ngIf="submitted && parcelShipTaggedAssetList.length == 0"
          >At least one asset is required</span
        >
      </div>
      <div class="px-7">
        <div class="card">
          <div
            class="card-header"
            (click)="togglers.assetTypes = !togglers.assetTypes"
          >
            Asset Types (<span *ngIf="selectedCategories.length != 0"
              >Selected {{ selectedCategories?.length }} of </span
            >{{ assetObjList.length }})

            <i
              class="fa fa-chevron-right float-right mt-1"
              [ngClass]="{
                'rotate-Icon': togglers.assetTypes
              }"
            ></i>
          </div>
          <div
            class="summary-section-bg p-0"
            [ngClass]="togglers.assetTypes ? '' : 'hidden'"
          >
            <div class="user-detail__item full-width">
              <mat-radio-group
                class="foz12 capitalize"
                [(ngModel)]="assetType"
                [ngModelOptions]="{ standalone: true }"
                aria-label="Select an option"
                (ngModelChange)="onChangeMonitorAssetType()"
              >
                <mat-radio-button value="MONITORED">Monitored</mat-radio-button>
                <mat-radio-button value="UNMONITORED"
                  >Unmonitored</mat-radio-button
                >
                <mat-radio-button value="ALL">All</mat-radio-button>
              </mat-radio-group>
            </div>
            <div *ngIf="!assetTypesLoading">
              <div class="user-detail__item p-0 w-47">
                <cl-list-search
                  [placeholder]="'Search types'"
                  (search)="updateFilter($event, 'assetTypes')"
                >
                </cl-list-search>
              </div>
              <div class="card-body-scroll">
                <div class="user-detail__item checkbox">
                  <div
                    class="user-roles__item"
                    *ngFor="
                      let category of assetObjList | search : searchAssetTypes
                    "
                  >
                    <mat-checkbox
                      class="foz12 asset-type-checkbox capitalize mb-1 mt-0"
                      (change)="
                        getInventoryAssets($event, category, null, false)
                      "
                      [(ngModel)]="category.checked"
                      matTooltipClass="tooltip-styles"
                      matTooltip="{{ category?.key }}"
                      matTooltipClass="tooltip-styles"
                      [disabled]="
                        isDetailsLoading || isPreventChangingAssets
                          ? true
                          : assetType === 'ALL'
                          ? category.value === 0
                          : category[assetType] === 0
                      "
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <label class="user-roles__item__label">
                        <span class="asset-key">
                          {{ category.properties.name }}</span
                        >
                        <span class="count">
                          ({{
                            assetType === "ALL"
                              ? category.value
                              : category[assetType]
                          }})</span
                        >
                      </label>
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-row items-center justify-center"
              *ngIf="assetTypesLoading"
            >
              <button
                class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button bcolor9"
                [disabled]="assetTypesLoading"
                (click)="getInventoryAssets(null, null, null, true)"
              >
                <span class="mat-button__ripple"></span>
                <span class="mat-button__label color4 color9">Loading...</span>
                <span *ngIf="assetTypesLoading"
                  ><i class="fa fa-circle-o-notch fa-1x fa-pulse color4"></i
                ></span>
              </button>
            </div>
            <div class="mb-2" *ngIf="assetTypesLoading"></div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" (click)="togglers.assets = !togglers.assets">
            <span>
              Select Assets (<span *ngIf="selectedAssets.length != 0"
                >Selected {{ selectedAssets.length }} of </span
              >{{ totalAssets }})</span
            >
            <i
              class="fa fa-chevron-right float-right mt-1"
              [ngClass]="{
                'rotate-Icon': togglers.assets
              }"
            ></i>
          </div>
          <div
            class="summary-section-bg p-0 px-1"
            [ngClass]="togglers.assets ? '' : 'hidden'"
          >
            <div class="user-detail__item px-0 w-47 pt-0">
              <cl-list-search
                [placeholder]="'Search assets'"
                (search)="getInventoryAssets(null, null, $event, false)"
              >
              </cl-list-search>
            </div>
            <div class="user-detail__item pt-1 w-53">
              <div class="float-right">
                <button
                  [disabled]="selectedAssets.length == 0"
                  [ngClass]="[
                    selectedAssets.length == 0
                      ? 'color37 bcolor36 bgcolor38'
                      : 'color4 bcolor4 bgcolor32'
                  ]"
                  class="p-4-10 border-general br2 mr-2"
                  (click)="addToShipment()"
                >
                  <i class="fa" [ngClass]="'fa-plus-circle'"></i> Add to
                  Shipment
                </button>
              </div>
            </div>
            <div
              class="user-detail__item py-0"
              *ngIf="inventoryAssets.length != 0"
            >
              <mat-checkbox
                class="foz12 capitalize"
                [checked]="allComplete"
                (change)="onAllInventoryAssets($event.checked)"
                [indeterminate]="someComplete()"
              >
                <span
                  class="font-12-normal color4"
                  matTooltipClass="tooltip-styles"
                >
                  Select All
                </span>
              </mat-checkbox>
            </div>
            <div class="card-body-scroll">
              <div class="user-detail__item checkbox pt-0">
                <div
                  class="user-roles__item w-33"
                  *ngFor="let asset of inventoryAssets"
                >
                  <div
                    class="asset-checkbox ml-0"
                    title="{{ asset?.assetName || asset?.name }}"
                    *ngIf="asset.isAdded"
                  >
                    <span class="checked ellipsis"
                      ><i class="fa" [ngClass]="'fa-check-circle'"></i>
                      {{ asset?.assetName || asset?.name }}</span
                    >
                  </div>
                  <div class="asset-checkbox" *ngIf="!asset.isAdded">
                    <mat-checkbox
                      class="foz12 capitalize mb-1 mt-0"
                      (change)="onChangeAssets($event, asset)"
                      [(ngModel)]="asset.checked"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <span
                        class="font-12-normal"
                        matTooltip="{{ asset?.name || asset?.name }}"
                        matTooltipClass="tooltip-styles"
                      >
                        {{ asset?.assetName || asset?.name }}
                      </span>
                    </mat-checkbox>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-row items-center justify-center"
                *ngIf="totalAssets > 50 || assetsLoading"
              >
                <button
                  class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
                  [disabled]="totalAssets <= inventoryAssets.length"
                  [ngClass]="{
                    bcolor9:
                      assetsLoading || totalAssets <= inventoryAssets.length
                  }"
                  (click)="getInventoryAssets(null, null, null, true)"
                >
                  <span class="mat-button__ripple"></span>
                  <span
                    class="mat-button__label color4"
                    [ngClass]="{
                      color9:
                        assetsLoading || totalAssets <= inventoryAssets.length
                    }"
                    >load next 50</span
                  >
                  <span *ngIf="assetsLoading"
                    ><i class="fa fa-circle-o-notch fa-1x fa-pulse color4"></i
                  ></span>
                </button>
              </div>
              <div class="mb-2"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-7" *ngIf="parcelShipTaggedAssetList.length != 0">
        <div class="card">
          <div
            class="card-header"
            (click)="togglers.includedAssets = !togglers.includedAssets"
          >
            Included Shipments ({{ parcelShipTaggedAssetList.length }})
            <i
              class="fa fa-chevron-right float-right mt-1"
              [ngClass]="{
                'rotate-Icon': togglers.includedAssets
              }"
            ></i>
          </div>
          <div
            class="summary-section-bg px-2 pt-2 card-body-scroll"
            [ngClass]="togglers.includedAssets ? '' : 'hidden'"
          >
            <span
              *ngIf="!isPreventChangingAssets"
              (click)="deleteIncludedAsset('deleteAll')"
              class="pointer color4 fs12"
              ><i class="fa" [ngClass]="'fa-trash'"></i> Remove All
            </span>
            <div *ngFor="let item of parcelShipTaggedAssetList">
              <div class="included-shipments mt-1 border-valid">
                <div class="list d-flex flex-row align-items-center">
                  <div>
                    <div>
                      {{ item.assetName || item.name }} <br />
                      ID: {{ item.assetId }} <br />
                    </div>
                  </div>
                  <div *ngIf="!item.isEdit" class="pl-2">
                    Device ID: {{ item.sensorId }}<br />
                    Tracking ID &nbsp;: {{ item.trackingNumber }}
                  </div>
                  <div>
                    <button
                      *ngIf="!isPreventChangingAssets"
                      (click)="deleteIncludedAsset(item)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="user-detail__item full-width"
        *ngIf="parcelShipTaggedAssetList.length != 0"
      >
        <button
          class="p-4-10 border-general br2 mr-2 color4 bcolor4 bgcolor32"
          (click)="onFinish()"
        >
          Finish
        </button>
      </div>
    </div>
    <!-- <div class="card">
      <div class="card-header px-2" (click)="togglers.tags = !togglers.tags">
        Tag this Shipment
        <i
          class="fa fa-chevron-right float-right mt-1 cursor-pointer"
          [ngClass]="{
            'rotate-Icon': togglers.tags
          }"
        ></i>
      </div>
      <div
        class="card-body bg-white px-2"
        [ngClass]="togglers.tags ? '' : 'hidden'"
      >
        <cl-new-shipment-tags
          [selectedTags]="shipment.tags"
          [shipmentTagsData]="shipmentData"
          (sendDataToapply)="tagsfun($event)"
          *ngIf="shipmentData != undefined || draftval == true || shipmentID == undefined"
        ></cl-new-shipment-tags>
      </div>
    </div> -->
    <div class="card">
      <div class="card-header px-2" (click)="togglers.notes = !togglers.notes">
        Notes
        <i
          class="fa fa-chevron-right float-right mt-1 cursor-pointer"
          [ngClass]="{
            'rotate-Icon': togglers.notes
          }"
        ></i>
      </div>
      <div
        class="card-body bg-white p-0"
        [ngClass]="togglers.notes ? '' : 'hidden'"
      >
        <div class="user-detail__item notes">
          <div class="form-label">Add a Note</div>
          <textarea
            name="notes"
            maxlength="5000"
            formControlName="notes"
            class="basic-input full-width"
            id="notes"
            rows="4"
            [pattern]="regularExpression"
          ></textarea>
          <div class="flex">
            <div
              *ngIf="shipmentForm.get('notes').errors?.['pattern']"
              class="text-danger"
            >
              Please enter a valid notes.
            </div>
            <div class="ml-auto">
              <span
                [ngClass]="{
                  'text-danger': shipmentForm.value.notes.length == 5000
                }"
                >{{ shipmentForm.value.notes.length }}</span
              >/5000
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
