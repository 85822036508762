<div class="mt-2 mb-2">
    <span class="page-title">Admin - Groups</span>
</div>
<cl-table-surtitle [rows]="rows.length" [selected]="selected.length" [total]="totalRowCount" [pageName]="'Groups'"></cl-table-surtitle>
<div class="wt-ngx-grid-wrapper relative list-page px-2">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list" [clIsValidAction]="actionBtnPolicies.add" *ngIf="!showSummaryPanel">
            <div class="add-button" (click)="addGroup()">
              <span class="fa fa-plus" ></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
                <cl-list-search [placeholder]="'Search by Group Name'" (search)="updateFilter($event)"
                 [columns] = "['Group Name']"></cl-list-search>

            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 mr-2" (click)="editGroup()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>

            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.delete"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 mr-2" (click)="deleteUserGroup()">
                <i class="fa fa-trash"></i>
                Delete
                <span class="fa fa-circle-o-notch fa-pulse" *ngIf="isLoading"></span>
            </button>
        </div>
        <div class="user__action-panel-right action-panel-right self-end flex items-center mr10 mb10">
            <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'userGroup'">
            </cl-grid-column-toggle>
        </div>
    </div>
    <div class="h-calc-60">
        <main class="h-100 overflow-y-hidden">
            <div class="grid-container h-100 grow relative">
                <div *ngIf="delayedRefreshStart">
                    <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                    (refreshList)="getUserGroups()"
                    ></cl-refresh-countdown>
                </div>
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div class="h-100 " [style.width.px]="tableWidth">
                    <ngx-datatable class="h-100" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [selectAllRowsOnPage]="false" (select)='onSelect($event)' (reorder) = "columnReordered($event)"[reorderable]="false" #table>
                    </ngx-datatable>
                </div>
            </div>
        </main>

        <ng-template #userGoupNameTemplate let-row="row" let-value="value" let-i="index">
            <a class="font-12-bold" href (click)="loadSummaryPanel(row, $event)">{{value}}</a>
        </ng-template>

        <ng-template #userGroupMembersTemplate let-row="row" let-value="value" let-i="index">
            <div class="font-11-normal" [innerHtml]="value"></div>
        </ng-template>
    </div>

    <div class="summery-pannel" *ngIf="showSummaryPanel == true">
        <cl-user-group-side-panel [groupObject]="groupObject" [allUserObject]="allUserObject" [mode]="mode"
            (closeSidePanel)="closeSidePanel($event)">
        </cl-user-group-side-panel>
    </div>
</div>