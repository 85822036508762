import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/common/constants';
import { ESSearchResults } from '@cl/models';
import { SearchApiService } from '@cl/common/services/search-api.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { SensorService } from '../../sensor.service';

@Component({
  selector: 'cl-sensor-replace',
  templateUrl: './sensor-replace.component.html',
  styleUrls: ['./sensor-replace.component.scss']
})
export class SensorReplaceComponent implements OnInit {
  @Input() sensorObject: any;
  @Output() closeSidePanel = new EventEmitter();
  replaceClicked: boolean = false;
  selectedSensor: any;
  availableSensors: any[] = [];

  replaceForm: FormGroup;
  assetList: any[] = [];
  loading = false;

  private scrollId = "";
  private total = 0;
  private subscribedToInputChanges = false;
  private readonly destroyed$ = new Subject<boolean>();

  private get fcSearchTerm(): AbstractControl {
    return this.replaceForm?.get("searchTerm");
  }

  constructor(
    private fb: FormBuilder,
    private _searchApiService: SearchApiService,
    private _sensorService: SensorService,
    private _utilService: UtilsService
  ) {
    this.replaceForm = this.fb.group({
      searchTerm: [""],
    });
  }

  ngOnInit(): void {
    this.subscribeToSearchTermChanges();
  }

  closePanel(statusAction?: string) {
    this.closeSidePanel.emit(statusAction);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  selectListItem(sensor: any) {
    this.selectedSensor = sensor;
  }

  replaceSensor() {
    const oldSensorId = this.sensorObject.id;
    const newSensorId = this.selectedSensor.id;
    const payload = {oldSensorId, newSensorId};

    this.replaceClicked = true;
    this._sensorService.replaceSensor(payload).subscribe({
      next: (res) => {
        console.log(res, 'res');
        this.replaceClicked = false;
        this._utilService.showMessage(oldSensorId + " has been replaced successfully!");
        this.closePanel('bindSuccess');
      },
      error: (err) => {
        this.replaceClicked = false;
        if (err && err.error && err.error['error-message']) {
          this._utilService.showMessage(err.error['error-message']);
        } else {
          this._utilService.showMessage("Unable to replace the sensor");
        }
      }
    })
  }


  private subscribeToSearchTermChanges() {
    if (this.subscribedToInputChanges) {
      return;
    }

    this.fcSearchTerm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        startWith(""),
        debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH),
        distinctUntilChanged(),
        tap((_) => (this.scrollId = "")),
        switchMap((searchTerm) => this.getAvailableSensorsToReplace(searchTerm))
      )
      .subscribe((sensors: any[]) => {
        this.availableSensors = sensors;
      });

    this.subscribedToInputChanges = true;
  }

  private getAvailableSensorsToReplace(searchTerm: string, newSearch: boolean = true): Observable<any[]> {
    this.loading = true;
    let queryText = (this.sensorObject?.deviceType?.toLowerCase().includes('tracker')) ? 'Tracker' : 'Sensor';

    const params = {
      scrollId: this.scrollId,
      scrollSize: 50,
      globalQueryText: searchTerm,
      searchQueries: [
        {
          fieldName: "clfMappingType",
          queryText: queryText,
          queryOperator: "should",
          queryType: "match",
          childFilters: [
            {
              fieldName: "type",
              queryText: this.sensorObject.sensorType || this.sensorObject.type,
              queryOperator: "must"
            },
            {
              fieldName: "status",
              queryText: "READY",
              queryOperator: "must"
            }
          ],
        },
      ],
    };

    return this._searchApiService.globalSearch<ESSearchResults<any>>(params, false).pipe(
      finalize(() => (this.loading = false)),
      catchError((err) => of({ total: 0, _scroll_id: "", hits: [] })),
      tap((res) => {
        this.total += res.hits.length;
        this.scrollId = res._scroll_id;
      }),
      map((res) => res.hits)
    );
  }

}
