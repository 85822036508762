import { Pipe, PipeTransform } from '@angular/core';

import { startCaseTransform } from './start-case.pipe';


export enum StateActionMap {
  // List of all states
  AVAILABLE     = 'Available',
  CHECKED_IN    = 'Available',
  IN_TRANSFER   = 'In-Transfer',
  IN_SERVICE    = 'In Service',
  IN_POSSESSION = 'In Possession',

  // List of all actions
  CHECKIN    = 'Check In',
  CHECKOUT   = 'Check Out',
  RETURN     = 'Return',
  SERVICE    = 'Service',
  TRANSFER   = 'Transfer',
  CONFIRM    = 'In Possession',      // For backward compatibility
  ACCEPT     = 'In Possession',      // For backward compatibility
  POSSESSION = 'In Possession',

  'CHECKED OUT' = 'In Possession',
};


@Pipe({
  name: 'stateActionMap'
})
export class StateActionMapPipe implements PipeTransform {

  static transform(value: string = '', ...args: string[]): string {
    return (StateActionMap as any)[value?.toUpperCase()] || startCaseTransform(value);
  }

  transform(value: string, ...args: string[]): string {
    return StateActionMapPipe.transform(value, ...args);
  }

}
