<div class="clui-shell" *ngIf="loaded">
    <cl-header  class="header-bar" [menu]="menuList"></cl-header>
    <div class="secondheader-bar">
  <cl-secondary-header></cl-secondary-header>
  <div class="clui-shell-content" [ngClass]="{'mt-2': noMargin, 'm10' : !noMargin}">
    <router-outlet></router-outlet>
  </div>
</div>
</div>
<div class="clui-shell" *ngIf="loadLogin">
  <router-outlet></router-outlet>
</div>
<section class="hidden">
<div class="bgcolor4 color2 grid grid-cols-2 font-bold font-12-bold" *ngIf="loaded">
  <div class="text-left">
    <span class="ml-[10px]">
      <span class="mr-[15px]">©</span>
      ParkourSC
    </span>
  </div>
  <div class="text-right">
    <span class="mr-[10px]">{{buildInfo}}</span>
  </div>
</div>
</section>
