<div class="my-account-container">
    <div class="title">MY PROFILE</div>
    <div class="personal-detail col-md-4 col-xs-12 col-sm-12">
        <cl-personal-details></cl-personal-details>
    </div>
    <div class="my-notification"></div>
  <div class="p-4 bgcolor2 mt-2 ml-[36px] col-md-4 col-xs-12 col-sm-12">
    <i class="fa fa-info-circle color4 mr-[5px]"></i>
    <span class="font-12"> External API reference:
      <b>{{extURL}}</b>
  </span>
  </div>
</div>
