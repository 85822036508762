import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { PropertyService } from '@cl/property/property.service';
import * as _ from 'lodash';

@Pipe({
  name: 'meta'
})
export class MetaPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private propertyService : PropertyService){}
  transform(value: string, ...args: string[]): string {
    let parsedValue : string = value;
    if(!_.isEmpty(args) && !_.isEmpty(args[0])){
      let dateFormat : any = this.propertyService.getSetting('ui.dateFormat');
      let filterSpec : string = args[0];
      let filters: string[] = filterSpec.split(';');
      switch(filters[0]){
        case 'momentSecs' :
          let seconds = parseInt(value);
          seconds = seconds * 1000;
          parsedValue = this.datePipe.transform(seconds, dateFormat);
          break;
        case 'moment' :
          let milliseconds = parseInt(value);
          parsedValue = this.datePipe.transform(milliseconds, dateFormat);
      }
    }
    return parsedValue;
  }
}
