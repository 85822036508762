<div class="asset-add-panel">
  <div class="left-box" *ngIf="showLeftPanel && recipeReadOnly===false">
    <div class="top-bar">
      <mat-select
        class="entity-type"
        placeholder="Select Entity Type"
        [(ngModel)]="selectedEntity"
        (selectionChange)="getEntityById(selectedEntity)"
      >
        <mat-option
          [value]="item.productNamespace"
          *ngFor="let item of entitiesList"
          >{{ item.name }}</mat-option
        >
      </mat-select>

      <!-- <div class="search-box">
        <cl-list-search
          [placeholder]="'Search'"
          (search)="updateFilter($event)"
          [columns]="['name']"
        ></cl-list-search>
      </div> -->
    </div>
    <div class="entities-list">
      <table>
        <tr>
          <th style="width: 225px">Name</th>
          <th style="width: 225px">Trigger</th>
          <th style="width: 100px">Edit</th>
          <th style="width: 55px">Delete</th>
        </tr>
        <tr *ngFor="let item of entityDetails">
          <td>
            <a (click)="openRecipe(item)" class="recipe-name">{{ item.name }}</a>
          </td>
          <td class="item-label">{{ item.label }}</td>
          <td>
            <span
              style="cursor: pointer"
              (click)="editRecipe(item.name, item.label, item.qCode, item)"
              class="p-4-10 br2 mr-2"
              ><i style="color: #2879de" class="fa fa-pencil"></i
            ></span>
          </td>
          <td>
            <span
              style="cursor: pointer"
              (click)="openDialog(item)"
              class="p-4-10 br2 mr-2"
              ><i style="color: #2879de" class="fa fa-trash"></i
            ></span>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <cl-side-panel
    *ngIf="showPanel"
    class="side-panel"
    [ngClass]="{ 'simulate-open': recipeObject }"
  >
    <section class="cl-side-panel-title bgcolor32">
      <span class="black font-bold" *ngIf="!readOnly && !recipeReadOnly">
        {{ !isAdd ? "Edit" : "New" }} Recipe
      </span>
      <span class="black font-bold" *ngIf="readOnly || recipeReadOnly">
        View Recipe
      </span>
    </section>

    <section class="cl-side-panel-content px-1">
      <ng-container>
        <div class="right-box-header">
          <div class="user-detail__item" style="width: 50%">
            <div class="label">Name*</div>
            <input
              type="text"
              [ngClass]="{ required: selectedName === '' }"
              [(ngModel)]="selectedName"
              class="basic-input"
              placeholder="Enter a Name"
              [disabled]="recipeReadOnly"
              (keypress)="restrictedCharactersOnly($event)"
            /> 
            
          </div>
          <div class="user-detail__item" style="width: 50%">
            <div class="label">Trigger</div>
            <div class="dropdown-input">
              <mat-select
                class=""
                placeholder="Select Trigger"
                [(ngModel)]="selectedTrigger"
                (ngModelChange)="detectChange($event)"
                (selectionChange)="changeTrigger($event.value)"
                [disabled]="readOnly || recipeReadOnly"
              >
                <mat-option
                  [value]="item.label"
                  *ngFor="let item of triggerList"
                  >{{ item.label }}</mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>

        <div style="display: flex">
          <div class="trigger-type">
            <span class="label">Mode</span><br />
            <div class="toggle">
              <mat-slide-toggle
                (change)="updateRecipeMode($event)"
                (ngModelChange)="detectChange($event)"
                [checked]="recipeMode == 'prod' ? true : false"
                [disabled]="readOnly || recipeReadOnly"
              ></mat-slide-toggle>
              <!-- <mat-slide-toggle (change)="updateRecipeDialog(Field,$event,'mode')" id="Field.active+Field.recipeId" [checked]="Field.recipeMode=='prod'?true:false"></mat-slide-toggle> -->
              <label class="form-check-label toggle-label">{{
                recipeMode === "prod" ? "Production" : "Test"
              }}</label>
            </div>
          </div>
          <div class="trigger-type">
            <span class="label">Status</span><br />
            <div class="toggle">
              <mat-slide-toggle
                [(ngModel)]="active"
                (ngModelChange)="detectChange($event)"
                [checked]="active"
                [disabled]="readOnly || recipeReadOnly"
              ></mat-slide-toggle>
              <label class="form-check-label toggle-label">{{
                active === true ? "Enabled" : "Disabled"
              }}</label>
            </div>
          </div>
          <div class="trigger-type" *ngIf="selectedTrigger === 'TIMER'">
            <span class="label">Time Interval (mins)</span>
            <label class="recipe_icon_tooltip">
              <i
                class="fa fa-info-circle fa-info-icon"
                style="
                  color: #0049b0 !important;
                  cursor: pointer;
                  font-size: 16px !important;
                "
                aria-hidden="true"
              ></i>
              <span class="recipe_text">
                Timer recipes are executed by a Scheduler which run every 60
                mins globally across all Timer recipes. Thus the actual
                execution of this recipe might be delayed by up to 60 mins based
                on the combination of when this recipe became active, the
                recipe's Time Interval and the Scheduler's execution times.
              </span>
            </label>
            <br />
            <input
              class="text basic-input input-type"
              placeholder="Time Interval (mins)"
              (ngModelChange)="detectChange($event)"
              [disabled]="readOnly || recipeReadOnly"
              [ngClass]="{
                required: selectedTrigger === 'TIMER' && timer === ''
              }"
              onkeydown="javascript:return event.keyCode===8 || event.keyCode===46 || event.keyCode===37 || event.keyCode===39
                  || (event.ctrlkey && event.keyCode===67) ||(event.ctrlkey && event.keyCode===86) || event.keyCode===9?true:!isNaN(Number(event.key))"
              type="text"
              [(ngModel)]="timer"
            />
          </div>
          <div class="trigger-type" *ngIf="selectedTrigger === 'PROPERTIES'">
            <span class="label">Key</span><br />
            <input
              class="text"
              class="basic-input"
              (ngModelChange)="detectChange($event)"
              [disabled]="readOnly || recipeReadOnly"
              [ngClass]="{
                required: selectedTrigger === 'PROPERTIES' && topic === ''
              }"
              type="text"
              [(ngModel)]="topic"
            />
          </div>
          <div
            class="trigger-type"
            *ngIf="
              selectedTrigger === 'EVENT' || selectedTrigger === 'VIOLATION'
            "
          >
            <span class="label">Topic<span>*</span></span
            ><br />

            <mat-select
              class="trigger"
              placeholder="Select topic"
              [(ngModel)]="topic"
              (ngModelChange)="detectChange($event)"
            >
              <mat-option [value]="item[0]" *ngFor="let item of topicList">{{
                item[1]
              }}</mat-option>
            </mat-select>
          </div>
        </div>

        <div
          class="col-12 code-mirror"
          [ngClass]="{
            margin:
              selectedTrigger === 'ALARM' ||
              selectedTrigger === 'EVENT' ||
              selectedTrigger === 'BRE'
          }"
        >
          <div class="border">
            <!-- <div class="code-mirror-header">
                  <span class="recipe-review-text ml-2">Recipe Preview</span>
                    <span class="close-icon fa fa-times-circle-o mr-2" (click)="close()">
                    </span>
                  </div> -->
            <div class="editor">
              <span class="info-btn">
                <a href="http://api.parkoursc.com/#recipes" target="_blank">
                  <i class="fa fa-question-circle"></i>
                </a>
              </span>
              <ngx-codemirror
                #codeMirror
                [(ngModel)]="selectedQCode"
                [options]="codeMirrorOption"
                (ngModelChange)="detectChange($event)"
                [disabled]="readOnly || recipeReadOnly"
              ></ngx-codemirror>
            </div>
            <!-- <div class="text-right border-top p-3">
                      <button  type="button" class="cancel-btn mr-3" (click)="discardCodeChanges()">Cancel</button>
                      <button mat-raised-button  type="button" class="btn btn-success save-btn" (click)="saveRecipeQCode()">Save</button>
                  </div> -->
          </div>
        </div>
      </ng-container>
    </section>

    <section class="cl-side-panel-footer bgcolor33 p-2">
      <div class="flex" style="float: right">
        <div class="flex-col grow text-right">
          <a
            class="m-2"
            (click)="closePanel(false)"
            title="cancels the changes and closes the form"
            >Cancel</a
          >
          <button *ngIf="!readOnly && !recipeReadOnly"
            (click)="saveRecipeQCode()"
            [disabled]="
              (this.selectedTrigger === 'ALARM' && this.timer === '') ||
              (this.selectedTrigger === 'EVENT' && this.topic === '') ||
              isSaveBtnDisabled ||
              selectedName === ''
            "
            class="panel-button white bgcolor4 ml-2"
          >
            Save
          </button>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>
