import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cl-banner-icon',
  templateUrl: './banner-icon.component.html',
  styleUrls: ['./banner-icon.component.scss']
})
export class BannerIconComponent implements OnInit {
  @Input() isFave: boolean;
  constructor() { }

  ngOnInit() {
  }

}
