import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import * as _ from 'lodash';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { Category } from '@cl/@types/category.type';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  categories: Category[];

  constructor(private apiProvider: ApiProviderService, private httpClient: HttpClient) { }
  getCategories(): Promise<Category[]>{
    return new Promise((resolve) => {
      if(_.isEmpty(this.categories)) {
        var url = `${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/categories`;
        const obs$ = this.httpClient.get<Category[]>(url);
        firstValueFrom(obs$).then((data: Category[])=>{
          this.categories = _.sortBy(data, 'sortOrder');
          resolve(this.categories);
        });
      }else{
        resolve(this.categories);
      }
    });
  }
}
