<div *ngIf="!p?.rightList" class="open-tab open-inspector-tab" (click)="showPanel('rightList')"
  matTooltip="view list" matTooltipClass="tooltip-styles">
  <i class="fa fa-list" aria-hidden="true"></i>
</div>

<div *ngIf="p?.rightList" class=" h-calc-40">
  <div class="grid-80-20 m-2">
    <!-- <h5>SEARCH RESULTS</h5> -->
    <p class="m-0">Showing {{totalLoaded}} of {{totalHits}} items</p>
    <span class="justify-self-end cursor-pointer mr-2" (click)="togglePanel('entities')">
      <i class="fa fa-times-circle-o" aria-hidden="true"></i>
    </span>
    <!-- <p class="m-0">{{totalLoaded}} of {{totalHits}}</p> -->
  </div>

  <span class="css-grid m-2">
    <form class="w-100" [formGroup]="searchFormGroup" novalidate>
      <input type="search" class="basic-input m-0 w-100 h-5 text-xs" placeholder="Filter list" formControlName="searchTerm">
    </form>
    <!-- <div class="input-group">
        </div> -->
    <!-- <div class="justify-self-end rounded border-light cursor-pointer block p-1-2 mr-2" (click)="sortAlpha()">
              <i class="fa fa-sort"></i>
    </div> -->
    </span>
  <div class="scroll-list-container h-calc-20">
    <div class="list-viewport h-calc-10" style="overflow:auto"
       *ngIf="searchList.length > 0 || loading; else noSearchResults;">
      <div class="search-list-item-container rl" *ngFor="let item of searchList"
        (click)="selectListItem(item)">
        <span *ngIf="item.name && !item.isTitle" class="search-list-item"
          [class.selected]="(item.uid && item?.uid === activeEntity?.uid) || (item?.id && item?.id === activeEntity?.id)">
          <span class="leading-6">
            <span *ngIf="item.name.length > 20" matTooltip="{{item.name}}" matTooltipClass="tooltip-styles">{{item.name | truncate: 20}}</span>
            <span *ngIf="item.name.length <= 20">{{item.name}}</span>
          </span>
          <!-- <span *ngIf="showIconLinks">
            <span *ngIf="item.nodeClass === 'shipment'" class="link-icon" (click)="selectShipment($event, item)"
              matTooltip="View shipment" matTooltipClass="tooltip-styles"
              [inlineSVG]="'/assets/svgs/cl-icons-white/shipment.svg'"></span>
            <span *ngIf="item.nodeClass === 'location' || item.classType === 'Building'" class="link-icon"
              (click)="selectLocation($event, item)" matTooltip="View site" matTooltipClass="tooltip-styles"
              [inlineSVG]="'/assets/svgs/cl-icons-white/gps.svg'"></span>
            <span *ngIf="item.nodeClass === 'location' || item.classType === 'Building'" class="link-icon" (click)="expandNode($event, item)"
              matTooltip="Expand node"
              (mouseover)="itemHovered(item, true);" (mouseout)="itemHovered({}, false);"
              matTooltipClass="tooltip-styles" [inlineSVG]="'/assets/svgs/cl-icons-white/hub.svg'"></span>
          </span> -->
        </span>
        <span *ngIf="item.name && item.isTitle" class="title-list-item">{{item.name }}</span>

      </div>
    </div>

    <ng-template #noSearchResults>
      <span class="text-muted foz14">No search results found</span>
    </ng-template>

    <app-load-more [loading]="loading" [allLoaded]="allLoaded" (loadMoreEmitter)="getLoadMoreUpdate($event)">
    </app-load-more>
  </div>

</div>
