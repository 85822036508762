import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimStr'
})
export class TrimStrPipe implements PipeTransform {

  static transform(value: string, args: string): string {

    try {
      if (value.startsWith(args)) {
        value = value.replace(args, '');
      }
    } catch { }

    return value;
  }

  transform(value: string, args: string): string {
    return TrimStrPipe.transform(value, args);
  }

}
