import { ElasticSearchQuery, SearchQueryRequest } from '../models/index';

/*
 * This file defines common constants used across the application.
 */

// export * from './sensor-fabric';
export * from './assets';
export * from "./http-status-code";
export * from "./regex-patterns";
export * from './sensors';
export * from "./users";


/**
 * Delay time in milliseconds for the GET apis after creating a resource.
 */
export const CL_API_DELAY       = 2000;
export const CL_API_DELAY_SHORT = 1000;

/**
 * Delay time in milliseconds between random inputs
 */
export const CL_INPUT_DEBOUNCE_TIME = 200;
export const CL_INPUT_DEBOUNCE_TIME_HIGH = 400;
export const CL_INPUT_DEBOUNCE_TIME_EXTREME = 1000;

export const CL_ES_ASSET_DEFAULT_SEARCH_QUERY: ElasticSearchQuery[] = [
  {
    fieldName: 'baseClass',
    queryOperator: 'must',
    queryText: 'Asset',
    queryType: 'match',
    childFilters: [],
  },
];

export const CL_ES_SITE_DEFAULT_SEARCH_QUERY: ElasticSearchQuery[] = [
  {
    fieldName: "baseClass",
    queryOperator: "must",
    queryText: "location",
    queryType: "match",
    childFilters: []
  }
]

export const CL_ES_SENSOR_DEFAULT_SEARCH_QUERY: ElasticSearchQuery[] = [
  {
    fieldName: 'clfMappingType',
    queryOperator: 'must',
    queryText: 'Sensor',
    queryType: 'match',
    childFilters: [],
  },
];

export const CL_ES_USER_DEFAULT_SEARCH_QUERY: ElasticSearchQuery[] = [
  {
    fieldName: "clfMappingType",
    queryOperator: "must",
    queryText: "USER",
    queryType: "match",
    childFilters: [],
  },
];

export const CL_ES_LOCATION_DEFAULT_SEARCH_QUERY: ElasticSearchQuery[] = [
  {
    fieldName: 'baseClass',
    queryText: 'Location',
    queryOperator: 'should',
    queryType: 'match',
    childFilters: [],
  },
];

export const CL_ES_LOCATION_LIST_SEARCH_REQUEST: SearchQueryRequest = {
  scrollId: '',
  match: 'must',
  scrollSize: 1000,
  globalQueryText: '',
  searchQueries: CL_ES_LOCATION_DEFAULT_SEARCH_QUERY,
};

/**
 * Cloudleaf logo url
 */
export const CL_LOGIN_LOGO_URL = './assets/svgs/logos/Cloudleaf_Primary_Blue_RGB.svg';

/**
 * List of ericsson tenant ids for different environments
 */
export const ERICSSON_TENANT_IDS = [
  "3031a96d-1db9-43e1-9e71-d8245dd985bb",
  "ee5fa13f-6618-49d4-8b69-fbef17524f2a",
  "0a3296ba-d562-40b1-89e5-26127306aa34",
];