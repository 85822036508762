<div>
  <div class="mb-4"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>
  <div class="fs24 my-1.5" [textContent]="assetObject?.name"></div>
  <div class="flex my-1.5">
    <div *ngIf="!this.includeShipmentShipmentId && !shipmentDetails" class="flex flex-row">
      <button class="p-4-10 color4 bcolor4 border-general bgcolor32 br2"  (click)="bindOrUnbind('bind')"
        [clIsValidAction]="actionBtnPolicies.bind">
            <span class="fa fa-link"></span>
            <span >{{'assets.bind'|translate}}</span>
      </button>
      <button class="p-4-10 border-general  br2  mr-2 ml-2 h-8" [ngClass]="[unBindDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']" [disabled]="unBindDisabled" (click)="bindOrUnbind('Unbind')"
        [clIsValidAction]="actionBtnPolicies.unbind">
            <span class="fa" [ngClass]='bindIcon'></span>
            <span>{{'assets.unbind'|translate}}</span>
      </button>
      <div class="coc-menu detail">
        <cl-coc-menu [renderIn]="'detail'" [assets]="[selectedAsset]"  ></cl-coc-menu>
      </div>
    </div>
    <div class="ml-auto">
      <cl-recipe-actions [entityTypeId]="assetDetils?.properties.entityTypeId" [entityId]="assetDetils?.properties.id" [parentEntityType]="assetDetils?.properties.parentEntityTypeKey" ></cl-recipe-actions>
    </div>
  </div>
  <div class="wt-ngx-grid-wrapper relative wt-inline-block-display" style="background-color: transparent;">
    <div class="asset-header-wrapper">
      <div class="flex items-stretch flex-wrap">
        <div class="asset-flex-card bgcolor2 ml0 mr10">
          <cl-details-summary [asset]="assetObject" [assetCurrentLocation]="currentLocation"></cl-details-summary>
        </div>
        <div class="asset-flex-card bgcolor2 mr0 ml10">
          <cl-asset-capabilities *ngIf="!isLoading" [violated]="excursionViolatedCheck" [excursionObj]="excursionObj" [asset]="assetObject" [pane]="'horizontal'"></cl-asset-capabilities>
          <div *ngIf="isLoading">
            <div class="mt-3 flex justify-center">
              <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="assetObject?.monitoredStatus === 'MONITORED' && (!selectedAsset?.includedInShipments || selectedAsset?.includedInShipments?.length == 0)">
      <div class="rounded bgcolor2">
        <div class="mt20 p10">
          <span class="page-title">{{'common.Location'|translate}}</span>
          <span class="rt1 ml20 pl20">
            <span class="font-12-bold fs13">{{'assetDetails.latitude'|translate}}</span>
            <span class="ml5 font-11-normal" [textContent]="assetObject?.position?.lat ?? '-'"></span>
          </span>
          <span class="rt1 ml10">
            <span class="font-12-bold fs13">{{'assetDetails.longitude'|translate}} </span>
            <span class="ml5 font-11-normal" [textContent]="assetObject?.position?.lon ?? '-'"></span>
          </span>
        </div>
        <agm-map [latitude]="assetObject?.position?.lat" [longitude]="assetObject?.position?.lon"
          [streetViewControl]="false" [gestureHandling]="mapOptions.gestureHandling" [scrollwheel]="null"
          [fullscreenControl]="true" [fullscreenControlOptions]="mapOptions.positionOfActions"
          [zoomControl]="true" [zoomControlOptions]="mapOptions.positionOfActions" [minZoom]="2" [maxZoom]="20" [zoom]="(assetObject?.position?.lat === null || assetObject?.position?.lon === null ) ? 2 : 20"
          [styles]="mapOptions.styles" [backgroundColor]="'none'" [disableDefaultUI]="true" [showDefaultInfoWindow]="true"
          [keyboardShortcuts]="false">
          <agm-marker  [latitude]="assetObject?.position?.lat" [longitude]="assetObject?.position?.lon"
            [iconUrl]="'assets/svgs/organization.svg'" [agmFitBounds]="true">
            <agm-info-window>
              <div *ngIf="lastReportedGPSTimeFormatted">Time: {{lastReportedGPSTimeFormatted}}</div>
              <div>Latitude: {{assetObject?.position?.lat}}</div>
              <div>Longitude: {{assetObject?.position?.lon}}</div>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
    </div>
    <div class="mt-2" *ngIf="includeShipmentShipmentId || selectedAsset?.includedInShipments && selectedAsset?.includedInShipments?.length != 0">
      <div *ngIf="loadingShimpentsMap">
        <div class="mt-2 flex justify-center">
          <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
        </div>
      </div>
        <cl-shipment-details-map *ngIf="shipmentDetails"
        [shipmentData]="shipmentDetails"
        [winkedAssetId]=""
        [assetData]="assetDetils"
        (emitExcursions)="getExcursions($event)" (emitCurrentLocation)="getCurrentLocation($event)"></cl-shipment-details-map>
    </div>
    <div class="relative" *ngIf="checkoutTransferInProgress">
      <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
    </div>
    <div class="page-title mt20 mb5 ml5">{{'assetDetails.assetActivity'|translate}}</div>
    <div class="asset-flex-card flex-card-additional bgcolor2 mr0 mb-5 pb-10">
      <cl-asset-activity [asset]="assetObject" [selectedDateRange]="selectedDateRange" [excursions]="excursionObj" [ruleSetId]="ruleSetId"></cl-asset-activity>
    </div>

    <div *ngIf="sidePanelsObj.bind">
      <app-asset-binder [assetObject]="selectedAsset" (closeSidePanel)="closeSidePanel($event)"></app-asset-binder>
    </div>
    <div *ngIf="sidePanelsObj.coc">
      <div class="cl-side-panel bcolor9 border flex flex-col detail-pane">
        <cl-coc-panel (closeCOCPanel)="closeSidePanel()"></cl-coc-panel>
      </div>
    </div>
  </div>
</div>
