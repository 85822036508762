import { Injectable } from '@angular/core';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { GatewayEvents, GatewayHealthStatus, GatewaySummary } from '@cl/@types/gateway.type';
@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  constructor(private apiProvider: ApiProviderService, private http : HttpClient) { }
  getGatewayDetails(gatewayId: string) : Promise<GatewaySummary>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/receiver/${gatewayId}`);
    return firstValueFrom(obs$);
  }
  getGatewayHealth(gatewayId: string) : Promise<GatewayHealthStatus>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/metrics/${gatewayId}/Receiver:HealthStatus:Event`);
    return firstValueFrom(obs$);
  }
  getGatewayEvents(gatewayId: string) : Promise<GatewayEvents>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/device/${gatewayId}/receiver`);
    return firstValueFrom(obs$);
  }
  getGatewayAttributeEvents(gatewayId: string, attributeName: string, deviceType: string, moduleName: string, from: number, to: number, limit: number) : Promise<GatewayEvents>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/device/single/${gatewayId}/${deviceType}/${attributeName}/${moduleName}?from=${from}&limit=${limit}&to=${to}`);
    return firstValueFrom(obs$);
  }
  getPOIBState(gatewayId): Promise<GatewayEvents> {
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}'api/1/device/${gatewayId}/poi-b'`);
    return firstValueFrom(obs$);
  }
  getGatewayCatalog() {
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/catalog/gateway`);
    return firstValueFrom(obs$);
  }

  getGatewayProps(gatewayId, gatewayType) {
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/gateway/${gatewayId}?type=${gatewayType || ''}`);
    return firstValueFrom(obs$);
  }

  createGateway(payload, gatewayType){
    const obs$ = this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/gateway?type=${gatewayType || ''}`,payload);
    return firstValueFrom(obs$)
  }

  updateGateway(payload, gatewayId, gatewayType){
    const obs$ = this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/gateway/${gatewayId}?type=${gatewayType || ''}`,payload);
    return firstValueFrom(obs$)
  }
  deleteGateway(payload){
    const obs$ = this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}/api/graph/gateway/delete`,payload);
    return firstValueFrom(obs$);
  }  
  getSelectedGatewayDetails(gatewayId: string, gatewayType) : Promise<GatewaySummary>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/gateway/${gatewayId}?type=${gatewayType || ''}`);
    return firstValueFrom(obs$);
  }
}
