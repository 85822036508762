import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { UnparsedSensorTaggedAssetAssignment, SensorBasicInformation, SensorDeviceEvents } from '@cl/@types/sensor.type';
import { SensorType } from '@cl/@types/sensor-type.type';
import { Entity } from '@cl/models';
import { UserService } from '../../user/user.service';
import { CatalogType } from '@cl/@types/extended-attributes.types';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';

@Injectable({
  providedIn: 'root'
})
export class SensorService {
  private sensorMultiCatalogTypesWithCDM:CatalogType[] = [];
  withField:boolean = true;
  constructor(private http : HttpClient, 
    private apiProvider: ApiProviderService,
    private userService: UserService,
    private extendedattributeService: ExtendedCatalogAttributeService) { }
  getSensorTypeDetails(sensorTypeName: string) : Promise<SensorType>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/meta/peripheral/${sensorTypeName}?details=true`);
    return firstValueFrom(obs$);
  }
  getSensorInformation(sensorId: string, type?: string): Promise<SensorBasicInformation>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/sensor/${sensorId}?type=${type || ''}`);
    return firstValueFrom(obs$);
  }
  getTaggedAssetAssignment(sensorId: string): Promise<UnparsedSensorTaggedAssetAssignment[]>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedAsset/assignment/${sensorId}`);
    return firstValueFrom(obs$);
  }
  getSensorDeviceEvents(sensorId: string) : Promise<SensorDeviceEvents>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/device/${sensorId}/tag`);
    return firstValueFrom(obs$);
  }
  getDeviceEventCharacteristicValuesByRange(sensorId: string, deviceType: string, from: number, to: number, sampleSize: number): Promise<SensorDeviceEvents>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/device/single/${sensorId}/tag/${deviceType}/heartbeat?from=${from}&to=${to}&limit=${sampleSize}`);
    return firstValueFrom(obs$);
  }
  replaceSensor(payload: any): Observable<Entity> {
    const headers = {
      "Content-Type": "application/json",
    };
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/sensor/replace`;
    return this.http.post<Entity>(url, payload, { headers: headers });
  }
  getRssiEvents(taggedAssetId: string, eventType: string, from: number, to: number, sampleSize: number):Promise<any>{
    const url: string = `${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${taggedAssetId}/Location/${eventType}?from=${from}&limit=${sampleSize}&to=${to}`;
    const obs$ = this.http.get(url);
    return firstValueFrom(obs$);
  }

  getDeviceFilters(classType) {
    return this.http.get(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/sensor/inventory/filter?clfMappingType=${classType}&getCurrentTenantData=true`);
  }


  getWatchListStatus(id) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/sentinel100l/watchlist/${id}`);
  }

  getGPSTracker(id, from, to) {
    var userData = this.userService.getUser();
    // let timeZone = (userData && userData.timezone) ? userData.timezone : moment.tz.guess();
    let timeZone = userData && userData?.timezone;
    let  headers = {
      zoneId: timeZone
    }
    let params = {
      from: from,
      headers: JSON.stringify(headers),
      to: to
    }
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${id}/GPS_SERVICE/GeoPoint`, {params: params});
  }

  getDeviceCatalog(classType) {
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/catalog/${classType}`);
    return firstValueFrom(obs$);
  }

  getDynamicApiResponse(codebase, url) {
    return this.http.get(`${this.apiProvider.getAPIUrl(codebase)}${url}`);
  }

  addDevice(classType, data, catalogType) {
    if(!data){
      data = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/${classType}?type=${catalogType || ''}`, data);
  }

  updateDevice(data, Id, classType, catalogType) {
    return this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${classType}/${Id}?type=${catalogType || ''}`, data);
  }

  deleteDevice(payload) {
    if(!payload){
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/sensor/delete`, payload);
  }

  getAccountNameList(sensorType){
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/accountsBySensorType/${sensorType}`);
    return firstValueFrom(obs$);
  }

  getProfileNameList(sensorType){
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/eventprofiler/sensortype/${sensorType}`);
    return firstValueFrom(obs$);
  }

  uploadAvatar(payload: FormData, assetId: string, deviceType: string) {
    const obs$ = this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${deviceType}/image/${assetId}`,payload);
    return firstValueFrom(obs$);
  }

  async getSensorMultiCatalogTypesWithCDM(catalogType) {
    let previousType = localStorage.getItem('deviceCatalogType');
    if(previousType !== catalogType){
      this.sensorMultiCatalogTypesWithCDM = [];
    }
    if(!this.sensorMultiCatalogTypesWithCDM.length){      
      this.sensorMultiCatalogTypesWithCDM = await this.extendedattributeService.getCatalogExtendedDeviceColumns('device',this.withField,catalogType);
    }
    return [...this.sensorMultiCatalogTypesWithCDM]
  }
}
