import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import * as _ from 'lodash';
import { map } from 'rxjs';
import { SearchResponse } from '../search-response';

@Injectable({
  providedIn: 'root',
})
export class EntityService {
  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService
  ) {}

  getEntityCdmFields(entity) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/catalog/${entity}`
    );
  }

  getEntityFilters(entity) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product/filter?type=${entity || ''}`
    );
  }

  globalEntitySearch<T = any>(entityType:string, searchParams: any, deserialize = true, queryParams = {}) {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${entityType}/search`;
    searchParams.searchQueries = searchParams.searchQueries.map(query => {
      let childFltsCopy = _.cloneDeep(query.childFilters);
      if (!childFltsCopy) {
        childFltsCopy = [];
      }
      let index = childFltsCopy.findIndex(item => item.fieldName === 'deleted' && item.queryOperator === 'must_not');
      if (index < 0) {
        childFltsCopy.push({
          "fieldName": "deleted",
          "queryText": "true",
          "queryOperator": "must_not"
        });
      }
      return Object.assign({}, query, {childFilters: childFltsCopy});
    });

    // TODO: Use switchmap here for search term update
    return this.http.post<SearchResponse>(url, searchParams).pipe(
      map((res) => {
        // console.log('res', res);
        return (deserialize ? new SearchResponse().deserialize(res) : res) as T;
      })
    );
  }

  formColumnList(cdmFields, dateTemplate?) {
    let rawColumns: any = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true,
        frozenLeft: true
      },
    ];

    let sortedCdmFields = this.sortCdmFields(cdmFields);

    sortedCdmFields.forEach((column: any, index) => {
      if (column.isDisplayableOnList) {
        rawColumns.push({
          name: column.displayLabel,
          prop: column.name,
          width: column.type.toLowerCase() === 'number' ? 100 : 180,
          visible: true,
          cellTemplate: column.type.toLowerCase() === 'date' ? dateTemplate : '',
          frozenLeft: index == 0 ? true : false
        });
      }
    });

    return rawColumns;
  }

  private sortCdmFields(cdmFields) {
    let negativeOrderedFields = _.filter(cdmFields, {'order': -1});
    let positiveOrderedFields = _.filter(cdmFields, function(o) { return o.order>-1; });
    positiveOrderedFields = _.sortBy(positiveOrderedFields, 'order');
    let result = [...positiveOrderedFields, ...negativeOrderedFields];
    return result;
  }
  getSkuSummary(skuId: string) {
    const URL = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product/${skuId}`;
    return this.http.get(URL);
  }
  orderCdmFields(obj){
    let response = this.sortCdmFields(obj)
    return response;
  }

  getEntityFiltersInventory(entity) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${entity}/filter?type=${entity || ''}`
    );
  }
}
