<button class="toggler-example align-grid-menu p-2 border-0" [matMenuTriggerFor]="exportContent">
  <span class="dark-blue-text"><i
    class="fa fa-download fa-1x color4" [title]="toolTip"></i></span>
</button>
<mat-menu #exportContent="matMenu" [xPosition]="'before'">
  <button mat-menu-item class="color4"
          *ngFor="let col of columnList"
          (click)="exportChangeHandler(col)"
          [title]="col.tooltip">{{col.name}}</button>
</mat-menu>
