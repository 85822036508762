<section class="login-container prelogin-container"
[ngStyle]="appliedTheme.showFullPageBackground ? {'background':'url('+appliedTheme.bannerUrl+') no-repeat', 'width': '100%', 'background-size': 'cover', 'min-height': '100vh'} : {}">
  <section>
    <div class="login-logo flex align-center">
      <img id="logoImg" src="{{appliedTheme.logoURL}}" />
    </div>
  </section>
  <section class="mw1440">
    <div class="flex widget-section">
      <div class="tablet hidden">
      </div>
      <div class="widget-wrapper flex-center-align">
        <div class="flex align-center form-box">
          <div id="login-form-container"></div>
          <!--<div id="reset-container" class="p10" *ngIf="showResetPanel">
            <cl-reset-password></cl-reset-password>
          </div>-->
        </div>
      </div>
    </div>
  </section>
</section>
