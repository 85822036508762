import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { EntityService } from '@cl/common/services/entity-list.service';
import { InventoryService } from '../inventory.service';

@Component({
  selector: 'cl-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.scss']
})
export class InventoryDetailsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[];
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  summaryObj: any
  inventoryId: string;
  origDistrib: any;
  inventoryCatalogCDMFields: any;
  data:any;
  customFields=["createdBy","createdAt","modifiedBy","modifiedAt"]
  constructor(private inventoryService: InventoryService, private route: ActivatedRoute, public datePipe: FormateDatePipe,private entityService:EntityService) {
    this.breadCrumbItems = [
      {'name': 'Previous', isBack: true, path: null},
      {'name': 'Inventory Details', path: null}
    ];
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.inventoryId = paramMap.get('id');
      this.getInventorySummary();
    });
  }
  getInventorySummary() {
    this.inventoryService.getInventorySummary(this.inventoryId).subscribe((response: any) => {
      this.summaryObj = response;      
      if (response?.summary?.type) {
        this.getExtendedAttributes(response.summary.type)
      } else {
        this.getExtendedAttributes(response?.type)
      }
    })
  }
  async getExtendedAttributes(sku) {
    this.entityService.getEntityCdmFields(sku).subscribe((data: any) => {
      this.data = data;
      this.inventoryCatalogCDMFields = this.entityService.orderCdmFields(this.data?.cdmFields)
      this.customFields.forEach(element => {
        const removeIndex = this.inventoryCatalogCDMFields.findIndex( item => (item.name === element));
        this.inventoryCatalogCDMFields.splice( removeIndex, 1 );  
      }); 
       this.inventoryCatalogCDMFields.forEach((item)=>{
      if(item.name==='status'){
        item.value=== "IN-ACTIVE" || item.value=== "INACTIVE"  ?'Inactive':item.value
      }
      // dataStatus.value = 
    })
  })
  }
  getSkuValue(cdmField, properties) {    
    if (cdmField.displayable) {
      if (this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1) {
        if (cdmField?.type?.toUpperCase() === 'DATE') {
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        }else if(cdmField.name==='status'){
          
          return properties[cdmField.name]=== "IN-ACTIVE" || properties[cdmField.name]=== "INACTIVE"  ?'Inactive':properties[cdmField.name]
        
        } else {
          return properties[cdmField.name];
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
