import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "@cl/core";
import { AggregatedCountApiResponse, Entity, ESSearchResults, NavigatorAggApi, SearchQueryRequest } from "@cl/models";
import { ConfigService } from "../../common/services/config.service";
import { SearchResponse } from "../../common/search-response";
import _ from "lodash";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "@cl/env";
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { LastNavigatorAction } from "../state/navigator.state.model";
import { NavigatorState } from "../state/navigator.state";
import { Select } from "@ngxs/store";


@Injectable()
export class NavigatorMapService {
  @Select(NavigatorState.lastAction) lastAction$:Observable<LastNavigatorAction>;
  private lastAction: LastNavigatorAction;

  constructor(
    private env: EnvironmentService,
    private http: HttpClient,
    private _configService: ConfigService,
    private apiProvider: ApiProviderService
  ) {
    this.lastAction$.subscribe(l=>this.lastAction=l);
  }

  private get config() {
    return this.env.clfGraphApp;
  }

  private get headers() {
    return new HttpHeaders({
     // token: this.config.token,
      "Content-Type": "application/json",
    });
  }

  mapApi(api: NavigatorAggApi, payload: SearchQueryRequest,
         deserializeRes = true): Observable<ESSearchResults<Entity>> {
    const url = this.config.baseUrl + api.url;
    // const url = "/assets/data/map-mock.json";

    // return this.http.get<ESSearchResults<Entity>>(url).pipe(
    //   map((res) => ({ ...res, hits: res.hits.map((h) => h.properties) })),
    //   map((res) => {
    //     // res.hits = res.hits.map((h) => new GraphNode().deserialize(h) as any);
    //     return deserializeRes ? new SearchResponse().deserialize(res) : res;
    //   })
    // );
    // Remove lat longs if request contains globalSearchText

    // if (!_.isEmpty(payload.globalQueryText)) {
      if(this.lastAction == 'filterSelected'){
        delete payload.bottomRightLat;
        delete payload.bottomRightLon;
        delete payload.topLeftLat;
        delete payload.topLeftLon;
      }
    // }

    return this.http.request<ESSearchResults<Entity>>(api.type, url, { body: payload, headers: this.headers }).pipe(
      map((res) => {
        // res.hits = res.hits.map((h) => new GraphNode().deserialize(h) as any);
        return deserializeRes ? new SearchResponse().deserialize(res) : res;
      }),
    );
  }

  keyLinesApi(api: NavigatorAggApi, payload: SearchQueryRequest): Observable<{ edges: any[]; nodes: any[] }> {
    return this.mapApi(api, payload, false).pipe(
      // map((res) => new SearchResponse().deserialize(res)),
      map((res) => ({
        nodes: res.hits,
        edges: [],
      })),
    );
  }

  serverClusteredMap(payload: SearchQueryRequest): Observable<AggregatedCountApiResponse> {
    //const url = this.config.baseUrl + "/api/search/navigator/geohash/aggregate";
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}/api/search/navigator/geohash/aggregate`;
    return this.http.post<AggregatedCountApiResponse>(url, payload, { headers: this.headers });
  }
}
