<div class="user-summary-panel policy-side-panel">
  <cl-side-panel (closePanel)="closePanel()">
    <section class="cl-side-panel-title side-panel-header-bg">
      <span *ngIf="mode === 'view'" class="black font-bold">Account Summary</span>
      <span *ngIf="mode === 'edit'" class="black font-bold">Edit Account</span>
      <span *ngIf="mode === 'add'" class="black font-bold">Add Account</span>
      <span class="close-icon" (click)="closePanel()">
        <i class="fa fa-times-circle-o mr-4"></i>
      </span>
    </section>
    <section class="cl-side-panel-content" *ngIf="mode === 'view'">
      <div class="summary__item">
        <div class="summary__item-label my-3">System Information</div>
        <div class="summary__item-label">Tenant ID</div>
        <div class="summary__item-value">{{accountDetails?.tenantName}}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-label">Account ID</div>
        <div class="summary__item-value">{{accountDetails?.id}}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-label">Created By</div>
        <div class="summary__item-value">{{accountDetails?.createdBy}}</div>
      </div>
      <div class="summary__item" *ngIf="accountDetails?.modifiedBy">
        <div class="summary__item-label">Modified By</div>
        <div class="summary__item-value">{{accountDetails?.modifiedBy}}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-label">Created Time</div>
        <div class="summary__item-value">{{accountDetails?.createdAtFormatted}}</div>
      </div>
      <div class="summary__item" *ngIf="accountDetails?.modifiedAt">
        <div class="summary__item-label">Modified Time</div>
        <div class="summary__item-value">{{accountDetails?.modifiedAtFormatted}}</div>
      </div>
      <div class="summary__item bl-t">
        <div class="summary__item-label my-3">Account Information</div>
        <div class="summary__item-label">Name</div>
        <div class="summary__item-value">{{accountDetails?.nickname}}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-label">Account Type</div>
        <div class="summary__item-value">{{accountDetails?.accountType}}</div>
      </div>
      <div class="summary__item">
        <div class="summary__item-label">Vendor ID</div>
        <div class="summary__item-value">{{accountDetails?.vendorId}}</div>
      </div>
      <div class="summary__item" *ngIf="accountDetails?.authInfo.username">
        <div class="summary__item-label">User Name</div>
        <div class="summary__item-value">{{accountDetails?.authInfo.username}}</div>
      </div>
      <div class="summary__item" *ngFor="let prop of auxProps | keyvalue">
        <div class="summary__item-label">{{prop.key}}</div>
        <div class="summary__item-value">{{prop.value}}</div>
      </div>
    </section>
    <section class="cl-side-panel-content" *ngIf="mode === 'edit' || mode === 'add'">
      <form [formGroup]="accountForm">
        <div class="user-detail">
          <div class="pt-5">
            <div class="form-label font-bold pl-2">Vendor Account Summary</div>
            <div class="user-detail__item py-0">
              <div class="form-label">Name<span class="required">*</span></div>
              <input type="text" class="basic-input" formControlName="nickname">
              <label class="red"
               *ngIf="accountForm.controls['nickname'].invalid && (accountForm.controls['nickname'].dirty || accountForm.controls['nickname'].touched) &&
               accountForm.controls['nickname'].hasError('required')">
                Please enter name!
              </label>
              <label class="red"
              *ngIf="accountForm.controls['nickname'].invalid && (accountForm.controls['nickname'].dirty || accountForm.controls['nickname'].touched) &&
              (accountForm.controls['nickname'].hasError('maxlength') || accountForm.controls['nickname'].hasError('minlength') || accountForm.controls['nickname'].errors['pattern'])">
              Name supports alphanumeric, special characters and spaces with min 4 to 100 characters!
             </label>
            </div>
            <div class="user-detail__item py-0">
              <div class="form-label">Account Type <span class="required">*</span></div>
              <input type="text" class="basic-input" laceholder="Enter Organization name" formControlName="accountType">
              <label class="invisible">&nbsp</label>
            </div>
            <div class="user-detail__item py-0">
              <div class="form-label">Vendor<span class="required">*</span></div>
              <mat-select formControlName="vendorId" (selectionChange)="onVendorChange($event.value)">
                <mat-option *ngFor="let vendor of vendorList;" [value]="vendor.id" [textContent]="vendor.id">
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="pt-5" *ngIf="properties.length">
            <div class="form-label font-bold pl-2">Account Information:</div>
            <div class="user-detail__item" *ngFor="let property of properties">
              <ng-container *ngIf="property['value']['uiRequired']">
                <div class="form-label">{{property['value']['displayName']}}<span class="required"
                  *ngIf="!property['value']['optional']">*</span></div>
                <div *ngIf="property['value']['dataType'] === 'String' && (property.name.toLowerCase() !== 'password' && property.name.toLowerCase() !== 'apikey')">
                  <input type="text" class="basic-input" [formControlName]="property.name">
                </div>
                <div *ngIf="property['value']['dataType'] === 'String' && (property.name.toLowerCase() === 'password' || property.name.toLowerCase() === 'apikey')">
                  <input type="password" class="basic-input" [formControlName]="property.name">
                </div>
                <div *ngIf="property['value']['dataType'] === 'Number'">
                  <input type="number" class="basic-input" [formControlName]="property.name">
                </div>
                <div *ngIf="property['value']['dataType'] === 'Date'" class="relative">
                  <input type="text" class="basic-input datepicker-calendar-icon" ngxDaterangepickerMd [locale]="locale"
                    [singleDatePicker]="true" (datesUpdated)="datesUpdated($event, property.name)"
                    [formControlName]="property.name" [minDate]='minDate' />
                  <span class="date-picker"><a class="color4"><span
                        class="fa fa-calendar ngx-daterangepicker-action"></span></a></span>
                </div>
                <label class="red" *ngIf="(accountForm.controls[property['name']].invalid && (accountForm.controls[property['name']].dirty || accountForm.controls[property['name']].touched) &&
                accountForm.controls[property['name']].hasError('required'))">
                  Please enter {{property['value']['displayName']}}!
                </label>
                <label class="red"
                  *ngIf="(accountForm.controls[property['name']].invalid && (accountForm.controls[property['name']].dirty || accountForm.controls[property['name']].touched) &&
                (accountForm.controls[property['name']].hasError('maxlength') || accountForm.controls[property['name']].hasError('minlength') || accountForm.controls[property['name']].errors['pattern']))">
                  {{property['value']['description']}}!
                </label>
                <label class="red"
                *ngIf="(accountForm.controls[property['name']].invalid && (accountForm.controls[property['name']].dirty || accountForm.controls[property['name']].touched) &&
                (accountForm.controls[property['name']].hasError('max') || accountForm.controls[property['name']].hasError('min') || accountForm.controls[property['name']].errors['pattern']))">
                {{property['value']['description']}}!
              </label>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </section>
    <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
      <div class="flex">
        <div class="flex-col grow fs11 text-right">
          <a class="m-2 panel-button" (click)="closePanel()">Cancel</a>
          <button *ngIf="mode === 'add'" class="panel-button white bgcolor4"
            [disabled]="isSaveInProgress || !accountForm.valid" (click)="saveAccount()">Save
            <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
          </button>
          <button *ngIf="mode === 'edit'" class="panel-button white bgcolor4"
            [disabled]="isSaveInProgress || !accountForm.valid || !accountForm.dirty" (click)="updateAccount()">Update
            <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
          </button>

        </div>
      </div>
    </section>
  </cl-side-panel>
</div>
