import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { SensorFabricService } from '../../sensor-fabric.service';
import { SensorService } from '../sensor.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from "rxjs/operators";
import { GraphAPIService } from '@cl/common/services/graph-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Select } from '@ngxs/store';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { SearchApiService } from '@cl/common/services/search-api.service';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { SensorBatteryRanges, SensorStatusLabels } from '@cl/@types/sensor.type';
import { AggregatedCountApiResponse, ElasticHit, ElasticHitBucket, FilterGroup } from '@cl/models';
import { FormControl, FormGroup } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/constants';
import { ToastService } from "@cl/common/services/toast.service";
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { Router } from '@angular/router';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { UserService } from '../../../../app/user/user.service';

@Component({
  selector: 'cl-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss']
})
export class SensorListComponent implements OnInit, AfterViewInit {
  @ViewChild('sensorNameTemplate') sensorNameTemplate: TemplateRef<any>;
  @ViewChild('sensorIdTemplate') sensorIdTemplate: TemplateRef<any>;
  @ViewChild('deviceProfileTemplate') deviceProfileTemplate: TemplateRef<any>;
  @ViewChild('sensorStatusTemplate') sensorStatusTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  @ViewChild('sensorList') sensorList: any;
  @ViewChild('batteryHealthTemplate', { static: true }) batteryHealthTemplate: TemplateRef<any>;
  @ViewChild('catalogTypeTemplate', {static: true}) catalogTypeTemplate: TemplateRef<any>;
  
  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;
  @ViewChild('clearTemplate') clearTemplate: TemplateRef<any>;
  private filterSubject: Subject<string> = new Subject();
  public columnFilters = {};

  private event = new Subject<any>();
  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  initialFilters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public allLoaded: boolean = false;
  public headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  actionCount: number = 0;
  bindAction: string = "Bind/Unbind";
  bindIcon: string = "";
  private destroyed$ = new Subject<boolean>();
  private selectedFilters: any = {};
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  sidePanelsObj: any = {
    bind: false,
    bulk: false,
    replace: false,
    add: false,
    edit: false,
    summary: false
  };
  actionBtnPolicies = {
    bind: ['TAGGEDASSET_CREATE'],
    unbind: ['TAGGEDASSET_DELETE'],
    replace: ['SENSORTAG_UPDATE'],
    edit: ['SENSORTAG_UPDATE'],
    delete: ['SENSORTAG_DELETE'],
    add: ["SENSORTAG_CREATE"]
  }

  mode: any = '';
  // attentionSensors: number = 0;

  private overallStatusFilter = [
    { key: "all", value: 0, subBucket: [] },
    { key: "g", value: 0, subBucket: [] },
    { key: "r", value: 0, subBucket: [] },
    { key: "y", value: 0, subBucket: [] },
    { key: "na", value: 0, subBucket: [] }
  ];
  private batteryLevelFilter = [
    { key: "all", value: 0, subBucket: [] },
    { key: "below5", displayLabel: 'Below 5%', value: 0, subBucket: [] },
    { key: "between515", displayLabel: '5 - 15%', value: 0, subBucket: [] },
    { key: "above15", displayLabel: 'Above 15%', value: 0, subBucket: [] }
  ]
  scrollSize = 50;
  private payLoadForSensorList = {
    "scrollId": "",
    "scrollSize": this.scrollSize,
    "globalQueryText": "",
    "searchFieldFilter":[],
    "searchQueries": [
      {
        "fieldName": "parentEntityTypeKey",
        "queryText": "sensor",
        "queryOperator": "should",
        "queryType": "match",
        "childFilters": []
      }
    ],
    "sortField": [

    ]
  };
  bindDisabled: boolean = true;
  editDisabled: boolean = true;
  selectedSensor: any;
  mainWidth: any;
  tableWidth: number;
  bulkPanelObj: BulkPanelModel;
  totalHits: number;
  showingHits: number;
  columnKeys: string[] = [];
  searchFormGroup: FormGroup;
  isTracker: boolean = false;
  pageName: string;
  device: string;
  extendedColumns: any = {};
  catalogType: string;
  showSidePanel: boolean = false;
  roleObject:any ={};
  showSPanel = false;
  // mode: string = '';
  systemSensorColumns = [];
  systemTrackerColumns = [];
  deleteDisabled:boolean = true;
  selectedRows: any = [];

  showFilter:boolean=false;
  searchFieldFilter:any=[];
  tenantType:boolean;
  isOrgRestrictedUser:boolean;
  summaryEvent: { roleObject: any; event: any; };
  scrollPositionX: any;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private sensorFabricService: SensorFabricService,
    private _searchApiService: SearchApiService,
    private el: ElementRef,
    private _utils: UtilsService,
    private dialog: MatDialog,
    private _graphAPIService: GraphAPIService,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private toaster: ToastService,
    private formateDatePipe: FormateDatePipe,
    private router: Router,
    private sensorService: SensorService,
    private extendedattributeService: ExtendedCatalogAttributeService,
    private persistenceStore: PersistenceService,
    private userService: UserService,
    
  ) {
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
  }

  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get("searchTerm") as FormControl;
  }

  ngOnInit(): void {
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
    this.filterSubject.pipe( debounceTime( 1000) ).subscribe( () => {
      this.filter();
    });
    if(this.router.url.toLowerCase().includes('trackers')){
      this.isTracker = true;
      this.payLoadForSensorList.searchQueries.forEach((query: any) => query.queryText = 'tracker')
    }
    this.catalogType = this.isTracker ? 'tracker' : 'sensor';
    this.pageName = this.isTracker ? 'Trackers' : 'Sensors';
    this.device = this.isTracker ? 'Tracker' : 'Sensor';
    this.getSensorTotalCount();;
    this.getSensorsList();
    // this.getFilters();
    this.getFilter();
    this.setContainerWidth();
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.showSidePanel = true;
        this.mode = 'view'
        this.roleObject = evt.roleObject;
      }
    });
    this.subscriptions.push(eventSub);
  }

  ngAfterViewInit(): void {
    // this.fcSearchTerm.valueChanges
    //   .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
    //   .subscribe((evt) => this.onSearchInput(evt));
    this.table._offsetX.subscribe(res => {
      if(res != undefined){
        this.scrollPositionX = res
      }      
    })
    if(!this.isTracker) {
      this.systemSensorColumns = [
        {
          prop: 'selected',
          name: '',
          sortable: false,
          canAutoResize: false,
          draggable: false,
          resizable: false,
          headerCheckboxable: true,
          checkboxable: true,
          width: 30,
          visible: true,
          frozenLeft: true,
          headerTemplate: this.clearTemplate,
        },
        { name: 'Sensor Name', prop: 'name', width: 180, visible: true, cellTemplate: this.sensorIdTemplate, frozenLeft: true },
        { name: 'Sensor ID', prop: 'sensorId',  width: 180, visible: true, cellTemplate: this.sensorIdTemplate },
        { name: 'Asset ID', prop: 'assetExternalId',  width: 200, cellTemplate: this.sensorNameTemplate, visible: true },
        { name: 'Type', prop: 'type', width: 150, visible: true },
        { name: 'Sensor Type', prop: 'entityType', width: 150, cellTemplate: this.catalogTypeTemplate, visible: true },
        { name: 'Catalog Type', prop: 'parentEntityTypeKey', width: 150, cellTemplate: this.catalogTypeTemplate, visible: true },
        { name: 'Organization', prop: 'organizationName', width: 200, visible: true },
        { name: 'Battery Health', prop: 'tagbatterylevel',  width: 150, visible: true, cellTemplate: this.batteryHealthTemplate },
        { name: 'Status', prop: 'overallStatus',  width: 100, visible: true, cellTemplate: this.sensorStatusTemplate },
        { name: 'Bind Status', prop: 'bindStatus',  width: 120, visible: true },
      ];
      this.getExtendedColumnList();
    }
    if(this.isTracker){
      this.systemTrackerColumns = [
        {
          prop: 'selected',
          name: '',
          sortable: false,
          canAutoResize: false,
          draggable: false,
          resizable: false,
          headerCheckboxable: true,
          checkboxable: true,
          width: 30,
          visible: true,
          frozenLeft: true,
          headerTemplate: this.clearTemplate,
        },
        { name: 'Tracker Name', prop: 'name', width: 180, visible: true, cellTemplate: this.sensorIdTemplate, frozenLeft: true  },
        { name: 'Tracker ID', prop: 'sensorId', width: 180, visible: true, cellTemplate: this.sensorIdTemplate },
        { name: 'Asset ID', prop: 'assetExternalId', width: 200, cellTemplate: this.sensorNameTemplate, visible: true },
        { name: 'Device Profile', prop: 'eventProfileName', width: 150, visible: true, cellTemplate: this.deviceProfileTemplate },
        { name: 'Type', prop: 'type', width: 150, visible: true },
        { name: 'Tracker Type', prop: 'entityType', width: 150,cellTemplate: this.catalogTypeTemplate, visible: true },
        { name: 'Catalog Type', prop: 'parentEntityTypeKey', width: 150, cellTemplate: this.catalogTypeTemplate, visible: true },
        { name: 'Organization', prop: 'organizationName', width: 200, visible: true },
        { name: 'Account', prop: 'accountName', width: 150, visible: true },
        { name: 'Vendor', prop: 'vendor', width: 150, visible: true },
        { name: 'FW Version', prop: 'fwrev', width: 120, visible: true },
        { name: 'Battery Health', prop: 'tagbatterylevel', width: 150, visible: true, cellTemplate: this.batteryHealthTemplate },
        { name: 'Status', prop: 'overallStatus', width: 100, visible: true, cellTemplate: this.sensorStatusTemplate },
        { name: 'Bind Status', prop: 'bindStatus', width: 120, visible: true },
      ];
      this.getExtendedColumnList();
    }
  }

  scrollToOffsetX(offsetX: number) {
    const tableContainer = this.el.nativeElement.querySelector('.datatable-body');
    if (tableContainer) {
      tableContainer.scrollLeft = offsetX;
      this.table.recalculate();
    }
  }


  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, this.catalogType, this.table._internalColumns);   
  }
  
  private getSensorTotalCount() {
    let queryText = this.isTracker ? 'tracker' : 'sensor';
    const payload = {
      "scrollId": "",
      "scrollSize": 50,
      "globalQueryText": "",
      "searchQueries": [
          {
              "fieldName": "parentEntityTypeKey",
              "queryText": queryText,
              "queryOperator": "should",
              "queryType": "match",
              "childFilters": [
                  {
                      "fieldName": "deleted",
                      "queryText": "true",
                      "queryOperator": "must_not"
                  }
              ]
          }
      ],
      "sortField": []
    }
    const totalCountSub: Subscription = this._searchApiService.globalEntitySearch(this.catalogType, payload, false).subscribe({
      next: res => {
        this.totalHits = res.totalHits;
      },
      error: err => {
        console.error(err);
      }
    });
    this.subscriptions.push(totalCountSub);
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 272;
    } else {
      this.tableWidth = this.mainWidth - 22;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  onScroll(offsetY: number) {
   
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
      this.getSensorsList();
    }
  }

  getLoadMoreUpdate() {
    this.getSensorsList();
  }
 

  getSensorsList(resetData: boolean = false): void {
    if(this.delayedRefreshStart) {
      this.delayedRefreshStart = false;
      this.tableRowData = [];
      this.payLoadForSensorList.scrollId = null;
    }
    let data: any = this.payLoadForSensorList;
    this.isLoading = true;
    const userPolicyListData: Subscription = this._searchApiService.globalEntitySearch(this.catalogType ,data, false).subscribe({
      next: (data: any) => {
        this.allLoaded = false;
        if (!this.payLoadForSensorList.scrollId) {
          this.rows = [...[]];
        }     
        this.payLoadForSensorList.scrollId = data._scroll_id;
        let rowData = data.hits;    
        // this.attentionSensors += _.filter(rowData, (row) => (row?.overallStatus.toLowerCase() === 'y')).length;
        rowData.forEach(d => {
          if (!d.name) {
            d.name = d.id;
          }
          d.bindStatus = this.sensorFabricService.variableMapping[d.status.toLowerCase()] ? this.sensorFabricService.variableMapping[d.status.toLowerCase()] : d.status;
          d['tag.batterylevel'] = (d['tag.batterylevel'] === '-1') ? 'N/A' : d['tag.batterylevel'];
        });
        if (resetData) {
          this.rows = [...data.hits];
        } else {
          this.rows = [...this.rows, ...data.hits];
        }
       
        setTimeout(() => {
          this.scrollToOffsetX(this.scrollPositionX)
        }, 10);
        this.showingHits = this.rows.length;
        this.totalHits = data.totalHits;
        if (this.showingHits === this.totalHits || data.totalHits < this.scrollSize) {
          this.allLoaded = true;
        }
        this.tableRowData = [...this.rows];
        this.isLoading = false;
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      }
    });


    this.subscriptions.push(userPolicyListData);
  }
  
  getSensorStatusLabels(status: string): string {
    if (!status) {
      return '';
    }
    return SensorStatusLabels[status.toLowerCase()];
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  closeSidePanel(currentAction?: string) {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    if (currentAction === 'bindSuccess' || currentAction === 'updateSuccess') {
      this.delayedRefreshStart = true;
    }

    this.showSidePanel = false;
    this.roleObject = {};
  }
  bindOrUnbind() {
    if (this.bindAction === "Unbind") {
      this.unbindSelected();
    } else {
      this.sidePanelsObj.bind = true;
    }
  }

  unbindSelected() {
    const selectedSensor = this.selected[0];
    let message = `Are you sure you want to unbind this ${this.device.toLowerCase()}?: ` + selectedSensor?.name;
    // }

    const dialogData = new ConfirmDialogModel("", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let currentIcon = this.bindIcon;
        this.bindIcon = 'fa-circle-o-notch fa-pulse';
        this.bindDisabled = true;
        this._graphAPIService.unBindAssetFromSensor(selectedSensor.id,selectedSensor.taggedAssetId).subscribe(
          (res) => {
            let name = selectedSensor.id;
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
            this.toaster.success(`Unbind of ${this.device.toLowerCase()}: ${name} is successful`);
            this.delayedRefreshStart = true;
            // if (res[name]) {
            //   this.openSnackBar("Unbind Sensor", name);
            //   this.getSensorsList();
            // } else {
            //   this._utils.showMessage("Failed to unbind sensor");
            // }
          },
          (err) => {
            const errMsg = (err.error && err.error['error-message']) ?
                            err.error['error-message'] :
                            "Failed to unbind " + this.device.toLowerCase();
            this.toaster.error(errMsg);
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
          }
        );

      }
    });
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  private showUnbindConfirmation(itemsToDelete: number) {
    // const message = `This will delete ${itemsToDelete} sensor${itemsToDelete > 1 ? 's' : ''}`;
    const message = `Are you sure to unbind ${this.device.toLowerCase()}(s)?`;

    const dialogData = new ConfirmDialogModel("", message);

    return this.openConfirmationDialog(dialogData);
  }

  /**
   * Shows delete confirmation dialog box.
   */
  private showDeleteConfirmation(itemsToDelete: number) {
    // const message = `This will delete ${itemsToDelete} sensor${itemsToDelete > 1 ? 's' : ''}`;

    const sensorOrSensors = this.selected.length > 1 ? this.device.toLowerCase()+'s' : this.device.toLowerCase();
    const message = `Are you sure want to delete selected ${sensorOrSensors}?`;

    const dialogData = new ConfirmDialogModel("", message);

    return this.openConfirmationDialog(dialogData);
  }

  private openConfirmationDialog(data: ConfirmDialogModel) {
    return this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data,
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),
        filter((res) => res === true)
      );
  }

  replaceSensor() {
    this.closeSidePanel();
    this.sidePanelsObj.replace = true;
  }

  addDevice(){
    this.closeSidePanel();
    this.sidePanelsObj.add = true;
    this.sidePanelsObj.edit = false;
    this.mode = 'add';
  }

  updateDevice() {
    this.closeSidePanel();
    this.sidePanelsObj.edit = true;
    this.sidePanelsObj.add = false;
    this.mode = 'edit';
  }

  deleteDevice() {
      // let message = `Are you sure you want to delete this device?: `+this.selectedSensor?.id;
      let message = `Are you sure you want to delete the selected device?`;
      const dialogData = new ConfirmDialogModel("", message);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        var payload = [];
        if (dialogResult) {
          this.selected.forEach(element => {
            payload.push(...[{'id':element?.id,
            'name':element?.name,
            'classType':element.classType
          }])
          });
         const deleteuserSub:Subscription = this.sensorService.deleteDevice(payload).subscribe((data:any)=>{
            let action = 'Device deleted successfully';
            let failureCount=0;
            let errorMsg;
            let successCount=0;
            let messages= [];
            data.forEach(item => {
              if(item.status === 'FAILED'){
                failureCount++;
                errorMsg = item.errorMessage;
                let finalErrorMsg = item.id + ' : '+ errorMsg;
                messages.push(finalErrorMsg);
              }else if(item.status === 'Success'){
                successCount++;
                let finalSuccessMsg = item.id + ' : '+ action;
                messages.push(finalSuccessMsg);
              }
            });

            if(!failureCount && successCount > 0){
              this.toaster.success(action);
            }else if(failureCount > 0 && !successCount){
              this.toaster.error(errorMsg);
            }else if(failureCount > 0 && successCount > 0){
             let finalMsg =  messages.join(',')
              this.toaster.info(finalMsg);
            }
            
            this.delayedRefreshStart = true;
          }, (err) => {
              const errMsg = (err.error && err.error['error-message']) ? err.error['error-message'] :
              "Failed to unbind " + this.device.toLowerCase();
              this.toaster.error(errMsg);
            });

            this.subscriptions.push(deleteuserSub);
        }else{
          this.isLoading = false;
        }
        this.bindDisabled = true;
        this.deleteDisabled = true;
        this.editDisabled = true;
      });

  }

  onSelect(rowData: any) {
    this.selectedRows = rowData?.selected;
    this.selected.splice(0, this.selected.length);
    this.selectedRows && this.selected.push(...this.selectedRows);
     if (this.selectedRows?.length === 1) {
      if(rowData.selected[0].organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
        this.bindDisabled = true;
        this.editDisabled = true;
      this.deleteDisabled = true;
        this.bindAction = "Bind/Unbind";
      }else{
        this.bindDisabled = false;
        this.editDisabled = false;
        this.deleteDisabled = false;
        let selectedRow = this.selectedRows[0];
        this.selectedSensor = selectedRow;
        this.selectedSensor['isTracker'] = !!this.isTracker;
        if (selectedRow.bindStatus === 'Bound') {
          this.bindAction = "Unbind";
          this.bindIcon = "fa-unlink";
        } else {
          this.bindAction = "Bind";
          this.bindIcon = "fa-link";
        }
      }
    } else {
      if(this.selectedRows?.length > 1){
        this.deleteDisabled = false;
      }else{
        this.deleteDisabled = true;
      }
      this.bindDisabled = true;
      this.editDisabled = true;
      this.bindAction = "Bind/Unbind";
      this.bindIcon = "";
    }
  }

  formateDownloadData(data:any[]){
    let tempAssetData = [...data];
    let dateFieldArray = ['modifiedAt', 'createdAt'];
    tempAssetData.forEach(sensor => {
      dateFieldArray.forEach(element => {
        if(sensor[element]){
          sensor[element] = this.formateDatePipe.transform(sensor[element],'default');
        }
      });
    });
    return tempAssetData;
  }

  downloadReport(reportType: string) {
    let downloadData = this.formateDownloadData(this.rows);
    if (reportType === 'csv') {
      downloadData.forEach(element => {
        element.overallStatus = this.getSensorStatusLabels(element.overallStatus);
      });
      const csvData = this._utils.getSelectedElementsFromArray(downloadData, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, this.isTracker ? 'Trackers-list': 'Sensors-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    // this.attentionSensors = 0;
    this.payLoadForSensorList.scrollId = '';
    this.payLoadForSensorList.globalQueryText = val;
    this.getSensorsList(true);
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
    setTimeout(() => {
      this.sensorList?.recalculate();
    }, 10);
  }

  getPanelUpdate(event: any) {
    this.payLoadForSensorList.scrollId = '';
    let filterEvent = event.filter;
    if (event.action === 'clearFilters') {
      this.selectedFilters = {};
      this.filters = _.cloneDeep(this.initialFilters);
    } else {
      if (filterEvent.selectionType === 'singleselect') {
        if (filterEvent.name === 'all') {
          delete this.selectedFilters[filterEvent.filterGroupName];
        } else if (filterEvent.name === 'N/A') {
          this.selectedFilters[filterEvent.filterGroupName] = ['na'];
        } else {
          this.selectedFilters[filterEvent.filterGroupName] = [filterEvent.name];
        }
      } else {
        if (filterEvent.checked) {
          if (!this.selectedFilters[filterEvent.filterGroupName]) {
            this.selectedFilters[filterEvent.filterGroupName] = [];
          }
          this.selectedFilters[filterEvent.filterGroupName].push(filterEvent.name);
        } else {
          let index = this.selectedFilters[filterEvent.filterGroupName].indexOf(filterEvent.name);
          this.selectedFilters[filterEvent.filterGroupName].splice(index, 1);
        }
      }
    }
    this.createPayloadAndReloadPolicyList();
  }


  createPayloadAndReloadPolicyList() {
    let childFilters = [];
    Object.keys(this.selectedFilters).forEach(key => {
      if (this.selectedFilters[key].length > 0) {
        if (key === 'tagbatterylevel') {
          childFilters.push(new Object({
            "fieldName": 'tagbatterylevel',
            "queryText": this.selectedFilters[key].join(), //SensorBatteryRanges[this.selectedFilters[key][0]],
            "queryType": "range",
            "queryOperator": "must"
          }));
        } else {
          childFilters.push(new Object({
            "fieldName": key,
            "queryText": this.selectedFilters[key].join(),
            "queryOperator": "must"
          }));
        }
      }
    });
    this.payLoadForSensorList.searchQueries[0].childFilters = childFilters;
    // this.attentionSensors = 0;
    this.getSensorsList(true);
  }

  getId(row) {
    return row.id;
  }

  bulkPanelHandler(panelObj: BulkPanelModel) {
    this.bulkPanelObj = panelObj;
    this.closeSidePanel();
    this.sidePanelsObj.bulk = true;
  }

  // get extended column list
  async getExtendedColumnList() {
      let catalogTypes = []; 
      try {
        catalogTypes = await this.sensorService.getSensorMultiCatalogTypesWithCDM(this.catalogType)
        this.persistenceStore.setDeviceType(this.catalogType)
      } catch (error) {
      }
      catalogTypes.forEach((catalogType) => {
        catalogType.cdmFields.forEach((field)=>{
          if(field?.group === 'User' && field.isDisplayableOnList === true){
            this.extendedColumns[field.name] = {
               name: field.displayLabel,
               prop: field.name,
               width: 180,
               visible: false,
               order: field.order
             }
           }
        })
      })

      console.log("this.rawColumns",this.rawColumns);


        let sortedExtendedColumn = this.sortCdmFields(this.extendedColumns);
        const dateColumns = [
          { name: 'Created At', prop: 'createdAt', cellTemplate: this.dateTemplate, width: 180, visible: true },
          { name: 'Created By', prop: 'createdBy', width: 180, visible: true },
          { name: 'Modified At', prop: 'modifiedAt', cellTemplate: this.dateTemplate, width: 180, visible: true },
          { name: 'Modified By', prop: 'modifiedBy', width: 180, visible: true }
        ]
        if(this.isTracker){
          this.rawColumns = [...this.systemTrackerColumns,...sortedExtendedColumn,...dateColumns];
        }else if(!this.isTracker){
          this.rawColumns = [...this.systemSensorColumns,...sortedExtendedColumn,...dateColumns];
        }

        const {gridColumns, rawColumns} = this._utilsService.updateColumnsList(this.catalogType, [...this.rawColumns]);
        
        this.rawColumns = [...rawColumns];

        catalogTypes.forEach((catalogType) => {
          let self =this;
          this.rawColumns.forEach(function(item){
            let searchIndex = catalogType.cdmFields.findIndex(
              (field) => field.name === item.prop &&  field.searchable == true
            );
  
            if (searchIndex>-1) {
               item.headerTemplate= self.hdrTpl;
            }
          })
        })
        
        this.columns = [...gridColumns];
        this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
        this.table?.recalculate();
        this._changeDetectorRef.detectChanges();
  }

  // load summery side panel
  loadSummaryPanel(roleObject:any, event:any){
    if(this.userService.getPolicies().includes('SENSORTAG_DETAIL')){
      this.sidePanelsObj.summary = true;
      this.summaryEvent = {roleObject, event}
      event.preventDefault();
      this.setEvent({ 'eventName': 'summary', roleObject: roleObject });
    }else{
      this.toaster.error('Access not allowed')
    }
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  sortCdmFields(cdmFields) {
    let negativeOrderedFields = _.filter(cdmFields, {'order': -1});
    let positiveOrderedFields = _.filter(cdmFields, function(o) { return o.order>-1; });
    positiveOrderedFields = _.sortBy(positiveOrderedFields, 'order');
    let result = [...positiveOrderedFields, ...negativeOrderedFields];
    return result;
  }

  getRowClass(row){
    let rowColor = {};
    if(row?.overallStatus.toLowerCase() === 'y'){
      rowColor = {
        'row-color': true
      };
    }
    return rowColor;
  }

  private formFilters(data) {
    this.filters = data.map((entry: any) => {
      let filter: FilterGroup = {
        list: [],
        filterType: entry.labelKey,
        label: entry.label,
        type: entry.uiSelectType? entry.uiSelectType:"multiselect",
      };
      filter.list = entry.options.map(list => ({
        name: list.id,
          checked: !!false,
          count:(list.count>0?list.count:0),
          displayLabel: list.name,
          label: list.name
      }));
      filter.enableCollapsing = filter.list.length > 10;
      filter.collapsed = filter.list.length > 10;
      filter.expand = true;
      return filter;
    })
    this.initialFilters = _.cloneDeep(this.filters);
  }

  getFilter(){
    this._searchApiService.getFilters(this.catalogType).then((res:any)=>{
      this.formFilters(res);
    })
  }

  filterInput( $event ) {
    this.filterSubject.next('');
  }

  
closeFilter(){
    this.showFilter = false;
    this.headerHeight = 30;
    Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
    this.filter();
  }

  
showHideFilter(){
    this.showFilter = !this.showFilter;
    if(this.showFilter){
      this.headerHeight = 65;
    }else{
      this.headerHeight = 30;
      Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
      this.filter();
    }
    setTimeout(() => {
      this.table.recalculate();
    }, 10);
  }


  
filter() {
    this.searchFieldFilter=[]
      Object.keys( this.columnFilters ).forEach(key => {
        this.searchFieldFilter.push({
          "name":key=="nameFormated"?"name":key,
          "value":this.columnFilters[key]
        })
      });
      this.payLoadForSensorList.scrollId ="";
      this.payLoadForSensorList.searchFieldFilter = this.searchFieldFilter;
      this.getSensorsList();

 //here call gobal search API for apply search  same like update filter
 
}
}
