import {AfterViewInit, Component, OnInit,ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { SimulateDataService } from '@cl/simulate/simulate-data.service';
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent, ConfirmDialogModel } from "../../common/components/confirm-dialog/confirm-dialog.component";
import * as ace from "ace-builds";
import { json } from 'd3';
import { settings } from 'cluster';
import { UserService } from '@cl/user/user.service';
import { RecipeService } from '@cl/recipes/recipe.service';

import { DynamicFormService } from '@cl/common/services/dynamic-form.service';

@Component({
  selector: 'cl-simulate-details',
  templateUrl: './simulate-details.component.html',
  styleUrls: ['./simulate-details.component.scss']
})
export class SimulateDetailsComponent implements AfterViewInit,OnInit{
  breadCrumbItems: BreadCrumbItem[];
  origDistrib: any;
  currentTab:any="stream";
  action:string="update";
  currentAttrTab:any="attributes"
  selectedInstanceId:any;
  selectedObject:any;
  selectedRecipes=[];
  recipeList=[];
  postUrl:string="";
  logsList:any=[];
  isLoading:boolean=false;
  selectedEntityType:any;
  isAdd=false;
  assetId="";
  showSidePanel:boolean=false;
  showLeftPanel:boolean=false;
  mode:any='edit'
  skuId:any;
  showPanel:boolean=true;
  recipeObject:any={};
  @ViewChild("editor") private editor: ElementRef<HTMLElement>;
  @ViewChild("editor2") private editor2: ElementRef<HTMLElement>;
  
  actionRecipe:string="";
  
  simulateAction :any=[
    // {
    //   name:"Changelog",
    //   value:"changelog"
    // },
    {
      name:"Update",
      value:"update",
      selected:true
    },
    {
      name:"Create",
      value:"create"
    },
    {
      name:"Stream",
      value:"stream"
    },
    {
      name:"Violation",
      value:"violation"
    },
    {
      name:"UI",
      value:"ui"
    }

  ]
  
  data:any;
 
  aceEditor2 :any;
  aceEditor:any;
  streamSeesiondata:any;
  selectionInstanceData:any;
  userFields:any;
  systemFields:any;
  triggerList = [
    {
      label: "FUNCTION",
      event: "function",
      key: null
    },
    {
      label: "UPDATE",
      event: "postConfigUpdate",
      key: null
    },
    {
      label: "CREATE",
      event: "postCreate",
      key: null
    },
    {
      label: "PROPERTIES",
      event: "postConfigUpdate",
      key: "<provided value>"
    },
    {
      label: "TIMER",
      event: "alarm",
      key: null,
      timer: "<provided value>"
    },
    {
      label: "EVENT",
      event: "event",
      key: undefined,
      topic: "<provided value>"
  },
  {
      label: "VIOLATION",
      event: "violation",
      key: undefined,
      topic: "<provided value>"
  },
    {
      label: "UI",
      event: "UITrigger",
      key: undefined
  },
  // {
  //     label: "BRE",
  //     event: "bre",
  //     key: undefined,
  //     topic: "<provided value>"
  // }
    
  ];
  constructor(
    private apiProvider: ApiProviderService,
    private _simulateService : SimulateDataService,
    public dialog: MatDialog,
    private _userService: UserService,
    private route: ActivatedRoute, public datePipe: FormateDatePipe,
    private _dynamicFormService: DynamicFormService,
    private cdf: ChangeDetectorRef, private recipeService: RecipeService) {
    this.breadCrumbItems = [
      {'name': 'Previous', isBack: true, path: null},
      {'name': 'Simulate Details', path: null}
    ];
    this.route.paramMap.subscribe((params) => {
      let temp = params.get('id');
      this.selectedInstanceId = temp;
      

    });
   }

   

   ngAfterViewInit() {
     ace.config.set("fontSize", "14px");
     ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
     this.aceEditor = ace.edit(this.editor.nativeElement);
     this.aceEditor.session.setMode("ace/mode/json");
     this.aceEditor.setShowInvisibles(false);
   
     
     this.aceEditor2 = ace.edit(this.editor2.nativeElement);
     this.aceEditor2.session.setMode("ace/mode/json");
     this.aceEditor2.setShowInvisibles(false);
     //this.aceEditor2.session.setValue("{param:'xyz'}");
     this.aceEditor2.session.setValue(JSON.stringify(this.streamSeesiondata[0]?.stram,null,'\t'));

  }

  ngOnInit(): void {
    //let data = JSON.parse(sessionStorage.getItem("userstreamData"))
    this.streamSeesiondata = JSON.parse(sessionStorage.getItem("userstreamData")) ;
    this.streamSeesiondata.sort((a,b) => {
      return a.date>b.date?-1:1;
    });
   
    this.getDetailsOfSelectedInstance(this.selectedInstanceId);
    //this.postUrl = `https://test2-behaviorengine.parkoursc.com/api/v1/simulate/execute/changelog`;
    //this.postUrl = `${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/execute/changelog`;
    this.postUrl = `${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/execute/changelog/update`;
   
    
    this.recipeService.updateRecipeList.subscribe(data=>{
      if(data===true){
        
        this.showSidePanel = false;
      }
    })
  }


  getDetailsOfSelectedInstance(id){
    
    this._simulateService.getSelectedInstanceData(id)
      .subscribe((data: any) => {
        
        this.getRecipelist();
        // if(data && data.baseType=="shipment"){
        //   this.simulateAction.push({
        //     name:"Carrier Milestone Events",
        //     value:"Carrier:checkpoint"
        //   },)
        // }
        let newData = [];
        this._simulateService.getCdmData(data.type)
        .subscribe((cdmData: any) => {
         
          if(cdmData?.cdmFields){
            
              let array1 = [data.properties];
              let array2 = cdmData.cdmFields;
              
              if(data.properties){
                for (var x in data.properties){
                  let tempObj = {};
                  if(data.properties.hasOwnProperty(x)){
                    tempObj["name"]=x
                    tempObj["value"]=data.properties[x]
                    if(cdmData.cdmFields){
                      let index = cdmData.cdmFields.findIndex(
                        (column) => column.name === x
                      );
                      if(index>-1){
                        tempObj["displayLabel"]=cdmData.cdmFields[index].displayLabel
                      }else{
                        tempObj["displayLabel"]=x
                      }
                    }
                  }
                  newData.push(tempObj)
                
                }
                
              }
    
            //   cdmData.cdmFields = array2.map(o => Object.assign({}, o, { value: array1[0][o.name] }));
         
            //   console.log("all cdmFields",cdmData.cdmFields)
          
            // const fields =cdmData.cdmFields;
           
            // let userFields = fields.filter(field => field.group?.toLowerCase() == 'user');
            // let systemFields = fields.filter(field => field.group?.toLowerCase() == 'system');
            // this.userFields = this._dynamicFormService.orderFields(userFields, 'order');
            // this.systemFields = this._dynamicFormService.orderFields(systemFields, 'order');
          }
          
          this.systemFields =newData;
          console.log("this.systemFields",this.systemFields)


        },error=>{
          let modifiedData:any=[];      
          for (var x in data.properties){
            if(data.properties.hasOwnProperty(x)){
              modifiedData.push({name:x,value:data.properties[x],displayLabel:x})
            }
             
          }
          this.systemFields =modifiedData;
        });
        this.selectionInstanceData= data;
        let self = this;
        setTimeout(function(){
          data['operation']="UPDATE";
          self.aceEditor.session.setValue(JSON.stringify(data,null,'\t'));
        },500)
             
        let modifiedData:any=[];      
        for (var x in data.properties){
          if(data.properties.hasOwnProperty(x)){
            modifiedData.push({name:x,value:data.properties[x]})
          }
           
        }
        
        this.selectedObject =modifiedData;
      },error=>{
        
      })

  }

  setTab(selectedtab){
  this.currentTab = selectedtab;
  if(selectedtab=='stream'){
    setTimeout(() => {
       this.aceEditor2 = ace.edit(this.editor2.nativeElement);
       this.aceEditor2.session.setMode("ace/mode/json");
       //this.aceEditor2.getSession().setUseWrapMode(true);
       this.aceEditor2.setShowInvisibles(false);

       this.streamSeesiondata.forEach((item,index)=>{
        if(index==0){
         item.isActive = true;
         this.aceEditor2.session.setValue(JSON.stringify(item.stram,null,'\t'));
        }else{
          item.isActive = false;
        }
     })


    
    }, 500);
  }
  
}
  
  showStream(data){
    
    this.streamSeesiondata.forEach((item)=>{
       if(item.date==data.date){
        item.isActive = true;
       }else{
        item.isActive = false;
       }
    })
    sessionStorage.setItem("userstreamData",JSON.stringify(this.streamSeesiondata))
    this.aceEditor2.session.setValue(JSON.stringify(data.stram,null,'\t'));
  }
  stringTruncate(value: string, size: number = 15): string {
    if (!value) return '';

    const limit = size > 0 ? size : 15;
    return value.length > limit
      ? value.substring(0, limit).trim() + '...'
      : value;
  }

  setActionUrl(actionName){
      console.log("call setActionUrl",actionName)
     this.action = actionName
     
     //let formedUrl = `https://test2-behaviorengine.parkoursc.com/api/v1/simulate/execute/${actionName}`;
     let formedUrl = `${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/execute/${actionName}`; //`https://qaproddvp2-behaviorengine.parkoursc.com/simulate/execute/${actionName}`
     
     this.postUrl = formedUrl;
     let payloadobj ={};
     
    if(actionName=="stream"){
      
      let property_name = "";
      let property_value = "";
      let stream_type = "";
      if(this.selectionInstanceData && this.selectionInstanceData.baseType=="shipment"){
        property_name= "Carrier Milestone Events";
        property_value = "Carrier:checkpoint";
      }else if(this.selectionInstanceData && this.selectionInstanceData.baseType=="asset"){
        stream_type="TAGGEDASSET";
      }
      payloadobj = {
          "object_id": this.selectionInstanceData.properties.taggedAssetId?this.selectionInstanceData.properties.taggedAssetId:this.selectionInstanceData.id,
          "tenant_id":  this._userService.getTenantId(),
          "source_id": "",
          "source_type": "",
          "property_name": property_name,
          "property_value": property_value,
          "stream_type": stream_type,
          "time": ""
        }
    }else if(actionName=="violation"){
        payloadobj = {
          "object_id": this.selectionInstanceData.properties.taggedAssetId?this.selectionInstanceData.properties.taggedAssetId:this.selectionInstanceData.id,
          "object_type": "",
          "tenant_id":  this._userService.getTenantId(),
          "context_id": "", 
          "context_type": "",
          "property_name": "",
          "property_value": ""
          
        }
    }else if(actionName=="update"){  //Carrier:checkpoint

      payloadobj =this.selectionInstanceData;
      payloadobj['operation']="UPDATE"
      this.postUrl = `${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/execute/changelog/${actionName}`; 


    }else if(actionName=="create"){

      payloadobj =this.selectionInstanceData;
      payloadobj['operation']="CREATE";
      this.postUrl = `${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/execute/changelog/${actionName}`; 
      
    }else if(actionName=="Carrier:checkpoint"){

      payloadobj =this.selectionInstanceData;
      //payloadobj['operation']="CREATE"
      
    }else if(actionName=="ui"){
      let recipeId ="";
      if(this.recipeList && this.recipeList.length>0){
        recipeId = this.recipeList[0].recipeId;
      }

      payloadobj ={
        recipeId:recipeId,
        entityTypeId:this.selectionInstanceData.properties.entityTypeId,
        objectId:this.selectionInstanceData.properties.id,
        baseClass:this.selectionInstanceData.properties.parentEntityTypeKey,
        tenantId :this._userService.getTenantId()
      }
      this.postUrl = `${this.apiProvider.getAPIUrl('cloudos')}api/1/recipe/execute/UITrigger`; 
      
    }
    else{
      payloadobj =this.selectionInstanceData;
    }
    let self = this;
    setTimeout(function(){
        self.aceEditor.session.setValue(JSON.stringify(payloadobj,null,'\t'));
    },500)
    
  }

  selectRecipeForAction(actionName){
    this.actionRecipe = actionName
    
    
    let formedUrl = `${this.apiProvider.getAPIUrl('cloudos')}api/1/recipe/execute/UITrigger`; 
   
    this.postUrl = formedUrl;
    let payloadobj ={};
    
   if(this.action=="ui"){
     
     payloadobj = {
        recipeId:actionName,
        entityTypeId:this.selectionInstanceData.properties.entityTypeId,
        objectId:this.selectionInstanceData.properties.id,
        baseClass:this.selectionInstanceData.properties.parentEntityTypeKey,
        tenantId :this._userService.getTenantId()
      }
   }
   else{
     payloadobj =this.selectionInstanceData;
   }
   let self = this;
   setTimeout(function(){
       self.aceEditor.session.setValue(JSON.stringify(payloadobj,null,'\t'));
   },500)
 }

  PostAction(){
    
    this.isLoading=true;
    let p_data={
      apiUrl:this.postUrl,
      payload:this.aceEditor.session.getValue()  //"{\"name\":\"rajesh\",\"action\":\"ok\"}"
    }

    this.streamSeesiondata = JSON.parse(sessionStorage.getItem("userstreamData")) ;
    this.streamSeesiondata.forEach(element => {
      element.isActive=false;
    });
    

    this.streamSeesiondata.push({
                              date:Date.now(),
                              stram:JSON.parse(p_data.payload),
                              isActive:true
    })
    this.streamSeesiondata.sort((a,b) => {
      return a.date>b.date?-1:1;
  })

   sessionStorage.setItem("userstreamData",JSON.stringify(this.streamSeesiondata));

   this.aceEditor2.session.setValue(JSON.stringify(JSON.parse(p_data.payload),null,'\t'));

    this._simulateService.simulatePostAction(p_data)
      .subscribe((data: any) => {
        this.isLoading=false;
        
        let modifiedData:any=[];      
        for (var x in data){
          if(data.hasOwnProperty(x)){
            let obj = {};
            obj["recipe"] = x;
            let logArr = [];
            if(data){
              logArr = data[x].split(/\r?\n/);  
            }
            obj["logs"] = logArr;
            
            modifiedData.push(obj);
          }
           
        }
        this.logsList = modifiedData;
        
       
      },error=>{
        this.isLoading = false;
      })
  }

  getRecipelist(){
    this.isLoading = true;
    this._simulateService.getSimulteRecipeList()
      .subscribe((data: any) => {
        this.isLoading = false;
        
        let catalogItemId ="";
        if(this.selectionInstanceData && this.selectionInstanceData.properties && (this.selectionInstanceData.properties.typeId || this.selectionInstanceData.properties.entityTypeId)){
          catalogItemId = this.selectionInstanceData.properties.entityTypeId?this.selectionInstanceData.properties.entityTypeId:this.selectionInstanceData.properties.typeId;
          
        }
        let tempArr:any =[];
        if(data && data.recipes && data.recipes.length>0){
          data.recipes.forEach(function(item){
            
             if(item.catalogItemId==catalogItemId){
              tempArr.push(item)
             }
          })
          this.recipeList = tempArr;
          this.recipeList.forEach(item=>{
            item.triggerName = this.triggerList.filter(elem=>elem.event===item.trigger)[0]?.label;         
          });
          if(this.recipeList && this.recipeList.length>0){
            this.actionRecipe = this.recipeList[0].recipeId;
          }
          
          }
        
       
      },error=>{
        this.isLoading = false;
      })
  }
  
  updateRecipeDialog(recipe,ev, field) {
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          message: `Are you sure want to change mode of recipe?`,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if(res){
          
          this.updateRecipefromSimulate(recipe,ev.checked, field)
        }else{        
        this.getRecipelist();
        }
       
      });
  }

  updateRecipefromSimulate(recipe,flag, field){
    let modeType= recipe.recipeMode;
    let activeType = recipe.active;
    if(field==='mode'){
      modeType = flag===true?'prod':'test';
    }else{
      activeType = flag===true?true:false 
    }
   
    let reqObj={
      "trigger": recipe.trigger,
      "label": recipe.timer,
      "timer": recipe.properties.timer,
      "topic": recipe.properties.topic,
      "key": recipe.properties.topic,
      "name": recipe.name,
      "qcardName": recipe.qcardName,
      "tenantId": this._userService.getTenantId(),
      "qCode": recipe.properties.exec,
      "createdBy": recipe.createdBy,
      "createdAt": recipe.createdAt,
      "updatedAt":  new Date().toLocaleDateString()+' '+new Date().toLocaleTimeString(),
      "isDeleted": false,
      "operation": "",
      "_id":"",
      "cloudleafId": recipe.catalogItemId,
      "recipeId": recipe.recipeId,
      "catalogName":"",
      "recipeMode" : modeType,
      "active" : activeType 
    }
    this._simulateService.updateRecipefromSimulate(reqObj)
    .subscribe((data: any) => {
      this.getRecipelist();     
    });
  }

  closeSidePanel(isReload:boolean = false) {
    this.showSidePanel = false;
    this.mode = '';
   
  }
 
  openRecipEdit(data){
    
    this.selectedEntityType = data.qcardName;
    
    
    this.isAdd=false;
    this.showPanel=true;
    this.showSidePanel = true;
    //this.recipeObject = data;
    
   this.recipeObject['name'] = data.name;
   this.recipeObject['trigger'] =data.trigger;
   this.recipeObject['qCode'] =data.properties.exec;
   this.recipeObject['timer'] = data.properties.timer?data.properties.timer:"";
   this.recipeObject['topic'] = data.properties.topic?data.properties.topic:"";
   this.recipeObject['createdAt'] = data.createdAt?data.createdAt:"";
   this.recipeObject['qcardName'] = data.qcardName;
   this.recipeObject['recipeId'] = data.recipeId;
   this.recipeObject['mode'] = data.recipeMode;
   this.recipeObject['status'] = data.active;    

  }
  getRecipe(){
    this.getRecipelist();
  }

}


