import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { EnvironmentService } from "@cl/core";
import { AppNotification, NotificationRequestPayload, NotificationsFilters } from "@cl/models";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NotificationsApiService {
  get headers() {
    return new HttpHeaders({
      token: this.env.clfGraphApp.token,
    });
  }

  constructor(private http: HttpClient, private env: EnvironmentService, private apiProvider: ApiProviderService) {}

  getNotificationsFilter() {
    const url = this.env.cloudOs.url + "/1/alertFilters";
    const params = {
      app_context: "indoor",
      source: "notification",
    };
    return this.http.get<NotificationsFilters>(url, { params, headers: this.headers }).pipe(
      map((res) => {
        const keys = Object.keys(res);
        const filtersToShow = ["severity"];
        for (const key of keys) {
          if (!filtersToShow.includes(key)) delete res[key];
        }

        return res;
      })
    );
  }

  getNotifications(payload: NotificationRequestPayload = {}): Observable<AppNotification[]> {
    const url = `${this.apiProvider.getAPIUrl('cloudos')}api/1/notify/filter`;

    const body: NotificationRequestPayload = {
      ...payload,
    };
    return this.http.post<AppNotification[]>(url, body, {}).pipe(
      tap((notifications) => {
        notifications.forEach((n) => (n.id = n.elasticDocId));
      })
    );
  }
}
