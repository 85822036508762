import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../analytics.service';
import { UserService } from '@cl/user/user.service';
import _ from 'lodash';

@Component({
  selector: 'cl-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {

  public isLoading: boolean = false;
  private subscriptions: Subscription[] = [];
  urlSafe: SafeResourceUrl = "";
  loginUserTenantId: string = '';
  constructor(
    private analyticsService: AnalyticsService,
    public sanitizer: DomSanitizer,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.getMonitorHomePage();
    this.loginUserTenantId = this.userService.getEffectiveTenantId();
  }

  getMonitorHomePage() {
    this.isLoading = true;
    const dashboardHomePage: Subscription = this.analyticsService.getHomePageDashboard().subscribe({
      next: (res: any) => {
        if (res) {
          let safe_url = this.analyticsService.getSafeUrl(res.dashboardUrl);
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(safe_url + '/%3Fstandalone%3D2%26tenant_id=' + this.loginUserTenantId);
        }
        this.isLoading = false;
      }, error: err => {
        console.error(err);
        this.isLoading = false;
      }
    });
    this.subscriptions.push(dashboardHomePage);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
