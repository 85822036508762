export enum UserSubscriptionStatus {
  UNSUBSCRIBED         = 'UNSUBSCRIBED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  CONFIRMED            = 'CONFIRMED',
  SUBSCRIBED           = 'SUBSCRIBED',
  UNREACHABLE          = 'UNREACHABLE',
}

export enum UserSubscriptionStatusLiteralMap {
  UNSUBSCRIBED         = 'Unsubscribed',
  PENDING_CONFIRMATION = 'Pending',
  CONFIRMED            = 'Confirmed',
  SUBSCRIBED           = 'Subscribed',
  UNREACHABLE          = 'Unreachable'
}

export const ES_USER_ID_PREFIX = 'user_';
