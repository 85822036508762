import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFromString'
})
export class DateFromStringPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): Date {
    try{
      return value ? new Date(JSON.parse(value)) : null;
    }catch(e){
      return null;
    }
  }

}
