import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'cl-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent{
  @Input('classes') classes: string[];
  @Input('name') templateName: string;
  @Input('options') contextObject: any;
  @ViewChild('wifi') wifi:TemplateRef<any>;
  @ViewChild('sensor') sensor:TemplateRef<any>;
  @ViewChild('battery') battery:TemplateRef<any>;
  constructor() { }
}
