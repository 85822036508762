import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvExportsService {

  constructor() { }

  public formatAndDownloadCSVForGrid(data: any, fileName: string, columnKeys?:any[]){
    if(data && data.length){
      let CSVData:any;
      if(columnKeys && columnKeys.length>0){
        columnKeys = columnKeys.filter(key => key !== '');
        CSVData = this.formatForCSV(data, columnKeys);
      }else{
         CSVData = this.formatForCSV(data);
      }
      if(!fileName) {
        fileName = 'downloadedFile';
      }
      this.downloadFile(CSVData, fileName);
    }
  }

  private formatForCSV(data: any, columnKeys?:any[]){
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName =>{ 
      let value = row[fieldName];
      if(value == undefined) return "";
      if(typeof value != 'string'){
        value = JSON.stringify(row[fieldName], replacer);
      }
      return `"${value.replace(/"/g, '""')}"`;
    }).join(','));
    csv.unshift(columnKeys && columnKeys.length>0?columnKeys.map(key => `"${key}"`).join(','):header.map(key => `${key}`).join(','));
    return csv.join('\r\n');
  }

  private downloadFile(csvArray: any, fileName) {
    var a = document.createElement('a');
    var blob = new Blob([csvArray], {type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName+".csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
