import { Injectable } from "@angular/core";
import { LvHidePanel, LvInitPanels, LvShowPanel, LvTogglePanel, LvUpdatePanels, LvUpdatePanelValue, LvUpdateTags } from "@cl/ngxs/actions";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { PanelStatesService } from "../common/utils/panel-states.service";

import { LvPanelsStateModel } from "./lv-panels.state.model";

const DEFAULT_STATE: LvPanelsStateModel = {
  entities: false,
  rightList: false,
  leftList: false,
  inspector: false,
  footer: false,
  browserWidth: 0,
  contentWidth: 0,
  mainWidth: 0,
  panelHeight: 0,
  middleStripHeight: 50,
  maHeight: 0,
  mainHeight: 0,
  leftOffset: 0,
  inspectorOffset: 0,
  rightListOffset: 0,
  leftListOffset: 0,
  footerOffset: 0,
  showEditForm: false,
  carousel: true,
  filters: true,
  mainTop: 0,
  filtersTop: 0,
  showTags: false,
  filtersSelected: false
};

@State<LvPanelsStateModel>({
  name: "lv_panels_state",
  defaults: DEFAULT_STATE,
})
@Injectable()
export class LvPanelsState {
  constructor(private store: Store, private panelStatesService: PanelStatesService) {}

  @Action(LvInitPanels)
  lvInitPanels(ctx: StateContext<any>, action: LvInitPanels) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      ...this.panelStatesService.getPanelPositions(state),
    });
  }

  @Action(LvUpdatePanelValue)
  LvUpdatePanelValue(ctx: StateContext<LvPanelsStateModel>, action: LvUpdatePanelValue) {
    const state = ctx.getState();
    if (action.value && !isNaN(action.value)) {
      ctx.setState({
        ...state,
        middleStripHeight: action.value + 10,
      });
    }
  }

  @Action(LvUpdatePanels)
  lvUpdatePanels(ctx: StateContext<LvPanelsStateModel>, action: LvUpdatePanels) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      ...this.panelStatesService.getPanelPositions(state),
    });
  }

  @Action(LvUpdateTags)
  lvUpdateTags(ctx: StateContext<LvPanelsStateModel>, action: LvUpdateTags) {
    const showTags = action.tags && action.tags.length ? true : false;
    ctx.patchState({
      showTags: showTags,
    });
    return ctx.dispatch(new LvUpdatePanels([]));
  }

  @Action(LvShowPanel)
  lvShowPanel(ctx: StateContext<LvPanelsStateModel>, action: LvShowPanel) {
    ctx.patchState({
      [action.name]: true,
    });
    return ctx.dispatch(new LvUpdatePanels([]));
  }

  @Action(LvHidePanel)
  lvHidePanel(ctx: StateContext<LvPanelsStateModel>, action: LvShowPanel) {
    ctx.patchState({
      [action.name]: false,
    });

    return ctx.dispatch(new LvUpdatePanels([]));
  }

  @Action(LvTogglePanel)
  lvTogglePanel(ctx: StateContext<LvPanelsStateModel>, action: LvTogglePanel) {
    const state = ctx.getState();
    ctx.patchState({
      [action.name]: !state[action.name],
    });
    return ctx.dispatch(new LvUpdatePanels([]));
  }
}
