<div class="mt-2 mb-2">
  <span class="page-title">API Subscriptions</span>
</div>

<div class="mb-1 list-page-count" *ngIf="rows?.length > 0">
  Showing <span class="font-bold">{{ rows.length }}</span> of
  {{ rows.length }} API Subscriptions
</div>

<div class="wt-ngx-grid-wrapper relative list-page px-2">
  <div class="flex justify-between w-calc-60 pt-1 pb-1">
    <div class="md-add-button list" style="z-index: 999">
      <div class="add-button" [clIsValidAction]="actionBtnPolicies.add"  *ngIf="!sidePanelsObj.create && !sidePanelsObj.edit && !sidePanelsObj.summary" (click)="addApiSubscription(false)">
        <span class="fa fa-plus" ></span>
      </div>
      <div class="add-button" *ngIf="sidePanelsObj.create || sidePanelsObj.edit || sidePanelsObj.summary" (click)="closeSidePanel($event)">
          <span class="fa fa-close"></span>
      </div>
    </div>
    <div class="action-panel-left self-start flex items-center">
      <form [formGroup]="searchFormGroup" novalidate>
        <cl-list-search [defalutSearchTerm]="searchTerm" [placeholder]="'Search by Name'" (search)="searchSubscription($event)" [columns]="['Name']"></cl-list-search>
      </form>
      <button [disabled]="editDisabled" [clIsValidAction]="actionBtnPolicies.edit"
          [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
          class="p-4-10 border-general br2 mr10" (click)="updateDevice()">
          <i class="fa fa-pencil"></i>
          Edit
      </button>
      <button [disabled]="deleteDisabled" [clIsValidAction]="actionBtnPolicies.delete"
          [ngClass]="[deleteDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
          class="p-4-10 border-general br2 mr10" (click)="deleteDevice()">
          <i class="fa fa-trash"></i>
          Delete
      </button>     

      <button [clIsValidAction]="actionBtnPolicies.edit"
        class="p-4-10 border-general br2 mr-2 color4 bcolor4 bgcolor32"
        (click)="openEditCredentialsDialog()"
      >
        Edit Credentials
      </button>
    </div>
    <div
      class="user__action-panel-right action-panel-right self-end flex items-center mr10 mb10"
    >
      <cl-export-panel
        (exportEmitter)="downloadReport($event)"
      ></cl-export-panel>
      <cl-grid-column-toggle
        [columnList]="rawColumns"
        (toggleColumns)="gridColumnToggle($event)"
        [page]="entityType"
      >
      </cl-grid-column-toggle>
    </div>
  </div>

  <div class="h-calc-60">
    <main class="h-100 overflow-y-hidden">
      <div class="grid-container h-100 grow relative">
        <div *ngIf="isLoading">
          <div class="spin-pos">
            <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
          </div>
        </div>
        <div *ngIf="delayedRefreshStart">
          <cl-refresh-countdown
            [start]="delayedRefreshStart"
            [timer]="refreshTimer"
            (refreshList)="getApiSubscriptions()"
          ></cl-refresh-countdown>
        </div>
        <div class="h-100 " [style.width.px]="tableWidth">
          <ngx-datatable
            class="h-100"
            [rows]="rows"
            [columns]="columns"
            [columnMode]="'force'"
            [loadingIndicator]="isLoading"
            [headerHeight]="headerHeight"
            [rowHeight]="rowHeight"
            [scrollbarV]="true"
            [selected]="selected"
            [rowIdentity]="getId"
            [sortType]="sortType"
            [selectionType]="selection"
            [selectAllRowsOnPage]="false"
            (select)="onSelect($event)"
            (reorder)="columnReordered($event)"
            [reorderable]="false"
            #table
          ></ngx-datatable>
        </div>
        <ng-template
          #subscriberNameTemplate
          let-row="row"
          let-value="value"
          let-i="index"
        >
          <a
            class="font-12-bold"
            href
            (click)="loadSummaryPanel(row, $event)"
            >{{ value }}</a
          >
        </ng-template>
      </div>
    </main>
  </div>
  <div *ngIf="showSummaryPanel">
    <cl-api-subscription-summary-panel
      (closeSidePanel)="closeSidePanel()"
      [subscriptionObject]="subscriptionObject"
      [mode]="mode"
    ></cl-api-subscription-summary-panel>
  </div>
  <div class="summery-pannel" *ngIf="mode === 'add' || mode === 'edit'">
    <cl-add-api-subscription [mode]="mode" [subscriptionObject]="subscriptionObject" (closeSidePanel)="closeSidePanel($event)">
    </cl-add-api-subscription>
  </div>
  <ng-template #enableDisableRuleTemplate let-row="row" let-value="value" let-i="index">
    <span class="pl-1 relative top-[-1px] status-toggle" [clIsValidAction]="actionBtnPolicies.edit">
      <mat-slide-toggle [(ngModel)]="row.enabled" (change)="pauseOrEnabled(row)"></mat-slide-toggle>
      <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
    </span>
  </ng-template>
</div>
