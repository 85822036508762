import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { TenantAssigned } from '@cl/@types/tenant-assigned.type';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  constructor(private http: HttpClient, private apiProvider: ApiProviderService) { }
  getTenants(userId: string):Promise<TenantAssigned[]>{
    const obs$ = this.http.get<TenantAssigned[]>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/user/${userId}/tenants`);
    return firstValueFrom(obs$);
  }

  getAllTenants(){
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/all`);
  }

  getTenantDetails(tenantId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/${tenantId}`);
  }

  getAppRoles() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/apps`);
  }

  getSensorTypes() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/meta/pTypes`);
  }

  createTenant(payload:any) {
    if(!payload){
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/1/customer/admin`, payload);
  }

  updateTenant(tenantId, payload: any) {
    if(!payload){
      payload = {};
    }
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/${tenantId}`, payload);
  }

  deleteTenant(tenantId){
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/${tenantId}`);
  }

  sharableTenants(tenantId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/tenant/share/list?tenantId=${tenantId}`);
  }

}
