<div class="user-summary-panel policy-side-panel">
    <cl-side-panel (closePanel)="closePanel(false)">
        <section class="cl-side-panel-title side-panel-header-bg">
            <span *ngIf="mode === 'add'" class="black font-bold">Add New Policy</span>
            <span *ngIf="mode === 'edit'" class="black font-bold">Edit Policy</span>
            <span *ngIf="mode === 'view'" class="black font-bold">Policy Summary</span>
            <span class="close-icon" (click)="closePanel(false)">
                <i class="fa fa-times-circle-o mr-4"></i>
            </span>
        </section>
        <section class="cl-side-panel-content" *ngIf="mode != 'view'">
            <form [formGroup]="policyForm">
                <div class="side-pane-form">
                    <div class="side-pane-form__item">
                        <div class="form-label">Policy Name<span class="required">*</span></div>
                        <input type="text" class="basic-input"
                        placeholder="Enter Policy Name" formControlName="policyName">
                    </div>

                    <div class="side-pane-form__item">
                        <div class="form-label">Entity Name<span class="required">*</span></div>
                        <mat-select formControlName="entityName" (selectionChange)="setupActionFromArray($event.value);getEntityAttribute($event.value?.entityId)" [ngClass]='{"disabled": mode=="edit"}'>
                            <mat-option *ngFor="let el of entityList;" [value]="el">{{el.uiLabel}}</mat-option>
                        </mat-select>
                    </div>

                    <div class="side-pane-form__item checkbox" *ngIf="showDataAccessContorl">
                        <div class="form-label">Disallow access to below data classes</div>
                        <div class="action__item"><mat-checkbox [(ngModel)]="dataAccessDefault" value="DEFAULT" (change)="fnDataAccess($event)" [ngModelOptions]="{standalone: true}" [disabled]="true" class="defaultData"> 
                            <label class="ml10 action__item__label default-text">(Default) - <label class="default-text default-info">Access is always allowed</label></label>
                        </mat-checkbox></div>
                        <div class="action__item"><mat-checkbox [(ngModel)]="dataAccessSensitive" value="RESTRICTED" (change)="fnDataAccess($event)" [ngModelOptions]="{standalone: true}">
                            <label class="ml10 action__item__label">Sensitive Data</label>
                        </mat-checkbox></div>
                        <div class="action__item"><mat-checkbox [(ngModel)]="dataAccessRestricted" value="DTP_RESTRICTED_DATA" (change)="fnDataAccess($event)" [ngModelOptions]="{standalone: true}">
                            <label class="ml10 action__item__label">DTP Restricted Data</label>
                        </mat-checkbox></div>
                    </div>

                    <div class="side-pane-form__item checkbox" formArrayName="actions">
                        <div class="form-label" *ngIf="actionList.length>0">Actions<span class="required">*</span></div>
                        <div  class="action__item" *ngFor="let action of actionList; let i=index">
                                <mat-checkbox [formControlName]="i" (change)="tiggerChangeDetection()">
                                    <label class="ml10 action__item__label">{{action}}</label>
                                </mat-checkbox>
                        </div>
                    </div>

                    <div class="side-pane-form__item attributes">
                        <div class="form-label" *ngIf="attributeArray.length>0">Conditions
                            <span class="add-attribute-button add-button" *ngIf="attributeArray.length>0" (click)="addFormGroupToAttribute()">
                                <span class="fa fa-plus"></span>
                            </span>
                        </div>

                        <div  class="attributes" formArrayName="attributes" *ngFor="let line of attributes.controls; let i=index">
                            <div class="attributes__item" [formGroupName]="i">
                                <div class="event-trash" *ngIf="!attributes.controls[i].value.formAdded"><i class="fa fa-trash color4 attribute-delete-icon" (click)="removeselectedCondition(i)"></i></div>
                               <div class="" *ngIf="!attributes.controls[i].value.formAdded">
                                    <div class="attributes__item__field">
                                        <div class="form-label">Attribute</div>
                                        <mat-select formControlName="lhs" (selectionChange)="setupOperator($event.value, i)" >
                                            <mat-option *ngFor="let el of attributes.controls[i].value.selectedattributeArray;" [value]="el">{{el.uiLabel}}</mat-option>
                                        </mat-select>
                                    </div>

                                    <div class="attributes__item__field">
                                        <div class="form-label">Operator</div>
                                        <mat-select formControlName="operator" [ngClass]='{"disabled": !attributes.controls[i].value.lhsValue}' (selectionChange)="getAttributeValues(i)">
                                            <mat-option *ngFor="let el of attributes.controls[i].value.operatorArray;" [value]="el">{{el.uiLabel}}</mat-option>
                                        </mat-select>
                                    </div>

                                    <div class="attributes__item__field">
                                        <div class="form-label">Values</div>
                                        <div class="selected-value">
                                            <div class="selected-value__item" *ngFor="let valueId of attributes.controls[i].value.rhs">
                                                <span class="value-id">{{valueId.uilabel}}</span><span class="remove-value" (click)="valueChange({'checked':false}, valueId, i)">
                                                    <i class="fa fa-solid fa-trash color4"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <input type="text" class="basic-input" value="" placeholder="Search" (input)="onKeySearch(i)" formControlName="searchTearm" [ngClass]='{"disabled": operaterValue==""}'>                                        
                                        <span class="add-org-icon add-button add-org" *ngIf="attributes.controls[i].value.addOrg && (policyForm?.controls['entityName']?.value?.entityId==='CDMINSTANCE' || policyForm?.controls['entityName']?.value?.entityId==='SHIPMENT')" (click)="addPolicyOrg(i)">
                                            <span class="fa fa-plus"></span>
                                        </span>
                                        <input type="hidden" formControlName="rhs">
                                        <div class="multi-select-dropdown" *ngIf="searchOrgName">
                                            <div  class="item" *ngFor="let value of attributes.controls[i].value.valueArray.value; let j=index">
                                                <mat-checkbox (change)="valueChange($event, value, i)" [checked]="isCheckedRHS(attributes.controls[i].value.rhs,value)">
                                                    <label class="ml10 action__item__label">{{value.uilabel}}</label>
                                                </mat-checkbox>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="" *ngIf="attributes.controls[i].value.formAdded">
                                    <div class="attribute__name">
                                        <div class="attribute__name__item">
                                            <div class="label">Condition</div>
                                            <div class="condition-value">{{attributes.controls[i].value.lhs.name}}</div>
                                        </div>
                                        <div class="attribute__name__item">
                                            <div class="label">Operator</div>
                                            <div class="condition-value">{{attributes.controls[i].value.operator.key}}</div>
                                        </div>
                                        <div class="attribute__name__value">
                                            <div class="label">Values</div>
                                            <div class="condition-value" *ngFor="let valueId of attributes.controls[i].value.rhs">{{valueId.uilabel}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="attribute-action text-right" *ngIf="!attributes.controls[i].value.formAdded">
                                    <a class="attribute-action__item" *ngIf="!attributes.controls[i].value.editMode && attributes.controls[i].value.rhs.length>0" (click)="addAttribute(i)">Add Condition</a>
                                    <a class="attribute-action__item disabled" *ngIf="!attributes.controls[i].value.editMode && attributes.controls[i].value.rhs.length==0" >Add Condition</a>
                                    <a class="attribute-action__item" *ngIf="attributes.controls[i].value.editMode && attributes.controls[i].value.rhs.length>0" (click)="addAttribute(i)">Update Condition</a>
                                    <a class="attribute-action__item disabled" *ngIf="attributes.controls[i].value.editMode && attributes.controls[i].value.rhs.length==0" (click)="addAttribute(i)">Update Condition</a>
                                </div>
                                <span class="attribute-action__icon edit-condition" *ngIf="attributes.controls[i].value.formAdded" (click)="editAttribute(i)">
                                    <i class="fa fa-solid fa-pencil color4"></i>
                                </span>
                                <span class="attribute-action__icon delete-condition" *ngIf="attributes.controls[i].value.formAdded" (click)="deleteAttribute(i)">
                                    <i class="fa fa-solid fa-trash color4"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </section>
        <section class="cl-side-panel-content" *ngIf="mode == 'view'">
            <div class="summary__item">
                <div class="summary__item-label">Policy Name</div>
                <div class="summary__item-value">{{policyObject.name}}</div>
            </div>

            <div class="summary__item">
                <div class="summary__item-label">Entity Name</div>
                <div class="summary__item-value">{{policyObject.entityUILabel}}</div>
            </div>

            <div class="summary__item">
                <div class="summary__item-label">Data Classification</div>
                <div class="summary__item-value">{{dataAccessArray}}</div>
            </div>

            <div class="summary__item">
                <div class="summary__item-label">Actions</div>
                <div class="summary__item-grid font-12-normal" *ngFor="let action of policyObject.actions">{{action}}</div>
            </div>

            <div class="summary__item">
                <div class="summary__item-label">Policy Type</div>
                <div class="summary__item-value">{{policyObject.policyType}}</div>
            </div>

            <div class="summary__item" *ngIf="conditonsValue.length>0">
                <div class="summary__item-label">Conditions</div>
                <div class="grid grid-cols-3 gap-3">
                    <div class="label">Attribute</div>
                    <div class="label">Operator</div>
                    <div class="label">Values</div>
                </div>
                <div class="grid grid-cols-3 gap-3" *ngFor="let orgvalue of conditonsValue;">
                        <div class="condition-value">{{orgvalue.lhs}}</div>
                        <div class="condition-value">Equal</div>
                        <div class="condition-value" *ngIf="policyObject.entityName === 'CDMINSTANCE' || orgvalue.lhs ==='destination' || orgvalue.lhs ==='origin'"><div *ngFor="let rhsvalue of selectedOrg;">{{rhsvalue.name}}</div></div>
                        <div class="condition-value" *ngIf="policyObject.entityName !== 'CDMINSTANCE' && orgvalue.lhs !=='destination' && orgvalue.lhs !=='origin'">{{orgvalue.rhs}}</div>
                </div>
            </div>

        </section>
        <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
            <div class="flex">
                <div class="flex-col grow fs11 text-right" >
                    <a class="m-2" (click)="closePanel(false)">Cancel</a>
                    <button class="panel-button white bgcolor4" [disabled]="checkIfFormValid()" (click)="savePolicy()" *ngIf="mode === 'add'">Save <span class="fa fa-circle-o-notch fa-pulse white" *ngIf="isLoading"></span></button>
                    <button class="panel-button white bgcolor4" [disabled]="checkIfFormValid()" (click)="editPolicy()" *ngIf="mode === 'edit'">Update <span class="fa fa-circle-o-notch fa-pulse white" *ngIf="isLoading"></span></button>
                </div>
            </div>
        </section>
    </cl-side-panel>
</div>

