import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash';
import { CustomRoute } from '../../../@types/custom-route.type';
import { UserService } from '../../../user/user.service';
import { NavService } from '../../shell/nav.service';
import { AppAuthService } from "@cl/authenticate/app-auth.service";
import {environment} from "@cl/env";
import { TenantUtilService } from '@cl/tenant/tenant-util.service';
import { TenantAssigned } from '@cl/@types/tenant-assigned.type';
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";
import { PersistenceService } from '@cl/common/services/persistence.service';
import { TenantSettingService } from '../../../../app/settings/tenant-settings/tenant-setting.service';
import { PropertyService } from '@cl/property/property.service';
import { AuthorizeService } from '../../../@authorize/authorize.service';
import { UrlUtilsService } from '@cl/common/services/url-utils.service';

@Component({
  selector: 'cl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges {
  user: any;
  selectedTenant: TenantAssigned;
  groupList: any[] = [];
  modifiedMenuList: Map<string, CustomRoute[]>;
  @Input()
  menu: CustomRoute[];
  isMenuOpen: boolean = false;
  tenant: TenantAssigned;
  tenants: TenantAssigned[];
  // TODO:: Siva - Need to verify the logic to hide and show alerts and notifications
  showAlerts = false;
  showNotifications = false;
  emailId: any;
  APP_BASE_URL = {
    MODEL_APP: 'model',
    SIMULATE_APP: 'simulate'
  };
  menuOpened: boolean = false;
  toggledMenuItem:any=[];
  tenantDetails:any;
  tenantHeaderLogo = '';
  userMenuSupportLogo = '';
  defaultUserMenuSupportLogo = 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png';
  defaultTenantHeaderLogo = 'https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png';
  defaultShowUserMenuParkourSCLogo = true;
  isShowUserMenuParkourSCLogo = null;
  customerSupportEmail:any;
  constructor(
    private router: Router,
    private userService : UserService,
    private tenantUtilService: TenantUtilService,
    private appAuth: AppAuthService,
    private navService: NavService,
    private webTracker: WebtrackerService,
    private persistenceService:PersistenceService,
    private tenantSettingService:TenantSettingService,
    private proprtyService: PropertyService,
    private authorizeService: AuthorizeService,
    private cdr:ChangeDetectorRef,
    private urlUtil:UrlUtilsService
  ) {
    let uiPages = this.userService.getUserUipages();
    if(uiPages.includes('notifications')){
      this.showNotifications = true;
      this.showAlerts = true;
    }
    this.user = this.userService.user;
    this.modifiedMenuList = new Map<string, CustomRoute[]>();
    this.menu = [];
    this.getTenants();
    this.emailId = this.userService.getUserEmailId();
    this.getBuildVersionDetail();
  }
  tenantChanged(data: TenantAssigned){
    if(!_.isEmpty(data)){
      this.selectedTenant = data;
      this.tenantUtilService.setPersistedTenant(data.tenantId);
    }else{
      this.selectedTenant = null;
      this.tenantUtilService.clearPersistedTenant();
    }
    window.location.reload();
  }
  getTenants() {
    let data = this.tenantUtilService.retrieveTenants();
    let tempIndex = _.findIndex(data, {tenantId: this.userService.getEffectiveTenantId()});
    this.tenant = data[tempIndex];
    data.splice(tempIndex, 1);
    this.tenants = data;
    this.selectedTenant = _.find(this.tenants, {tenantId: this.tenantUtilService.getPersistedTenant()});
    sessionStorage.setItem('tenantList',JSON.stringify(data));
  }
  ngOnInit(){
    if(this.proprtyService.mergedBulkSettings && this.proprtyService.mergedBulkSettings.length){
      this.proprtyService.mergedBulkSettings.forEach(setting => {
        if(setting.key == 'common.tenantHeaderLogo'){
          this.defaultTenantHeaderLogo = setting.value;
        }
        if(setting.key == 'common.userMenuSupportLogo'){
          this.defaultUserMenuSupportLogo = setting.value;
        }
        if(setting.key == 'common.showUserMenuParkourSCLogo'){
          this.defaultShowUserMenuParkourSCLogo = setting.value == 'true' ? true : false;
        }
      });
    }
    this.tenantHeaderLogo = this.defaultTenantHeaderLogo;
    this.userMenuSupportLogo = this.defaultUserMenuSupportLogo;
    this.isShowUserMenuParkourSCLogo = this.defaultShowUserMenuParkourSCLogo;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('menu')){
      this.getModifiedMenuList();
    }
  }
  getModifiedMenuList(){
    this.groupList = _.uniq(_.map(this.menu, 'group'));
    const modelAppBaseUrl = this.getExternalAppBaseURL('MODEL_APP');
    const simulateAppBaseUrl = this.getExternalAppBaseURL('SIMULATE_APP');
    const tenantId = this.userService.getTenantId();
    if(!_.isEmpty(this.groupList)){
      this.groupList.forEach((groupListItem: string)=>{
        this.modifiedMenuList.set(groupListItem, []);
        let list: CustomRoute[] = _.filter(this.menu, {group : groupListItem});
        list.forEach((listItem : CustomRoute)=>{
          let extPath = listItem.externalPath;
          if(listItem.replaceTenantId) {
            extPath = extPath.replace('TENANT_ID', tenantId);
          }
          if(listItem.replaceModelApp) {
            listItem.externalPath = modelAppBaseUrl + '/' + extPath;
          }
          if(listItem.replaceSimluateApp) {
            listItem.externalPath = simulateAppBaseUrl + '/' + extPath;
          }
          this.modifiedMenuList.get(groupListItem)?.push(listItem);
        });
      });
    }
    
    let menuState =this.persistenceService.getMenuState();
    if(menuState && Object.keys(menuState).length !== 0){
      menuState = JSON.parse(menuState);
    }
    if(menuState && menuState.length>0){
      menuState.forEach(element => {
        this.modifiedMenuList.forEach((value: any) => {
          const i = value.findIndex(item=>item.label===element.label);
          if(i>-1){           
            value[i].toggled=true;
            if(element.toggled){
              this.toggledMenuItem.push(element);
            }
            this.hideLevel2Menu(element, true);
          }
      });
      });
    }
  }
  navigateOrToggleMenu(event, menu: CustomRoute){
    if(!menu.disabled){
      this.webTracker.captureEvent(`menu_${menu.label}`, {});
      if(menu.externalPath) {
        window.location.href = menu.externalPath;
      } else if(menu.isLevel1Menu) {
        event.preventDefault();
        event.stopPropagation();
        menu.toggled = (menu.toggled === null) ? true: !menu.toggled!
        if(menu.toggled){
          this.toggledMenuItem.push(menu);
        }else{
          const index = this.toggledMenuItem.findIndex(item=>item.label===menu.label);
          if(index !==-1){
            this.toggledMenuItem.splice(index,1);
          }
        }
        this.persistenceService.setMenuState(JSON.stringify(this.toggledMenuItem));
        this.hideLevel2Menu(menu, menu.toggled);
      }else {
        this.router.navigateByUrl(this.navService.getMenuPath(menu));
      }
    }
  }

  menuIconClick() {
    this.isMenuOpen = true;
    this.webTracker.captureEvent(`menu`, {});
  }

  hideLevel2Menu(menuItem, hidden) {
    const menuName = menuItem.label;
    let menu = this.modifiedMenuList.get(menuItem.group);
    menu.forEach((li) => {
      if(li.level1Parent && li.level1Parent === menuName) {
        li.hidden = hidden;
      }
    });
  }

  logout() {
    this.tenantUtilService.clearPersistedTenant();
    this.appAuth.logout();
  }

  getExternalAppBaseURL(APPBASEURL) {
    let hostname = window.location.hostname;
    let appHostName = '';
    let port = (window.location.port == '') ? '' : ":" + window.location.port;
    let protocol = window.location.protocol;
    let subdomain;
    let prima;
    let suffix = this.APP_BASE_URL[APPBASEURL];
    if(hostname === 'localhost') {
      hostname = environment.localCluster;
      port = '';
      protocol = 'https:';
      subdomain = hostname.split('.parkoursc.com')[0];
      prima = subdomain.split('-app')[0];
    } else {
      const domainSubDomain = this.urlUtil.parseDomainSubDomain();
      subdomain = domainSubDomain.subdomain;
      // if(hostname.includes('.parkoursc')){
      //   subdomain = hostname.split('.parkoursc.com')[0];
      // }else if(hostname.includes('.qopper')){
      //   subdomain = 'test2-web';
      // }
       prima = subdomain.split('-web')[0];
      // prima = hostname.split('.com')[0];
      // prima = prima.split('.')[0];
      // prima = prima.split('-web')[0];
    }
   let domainurl:any = hostname.split('.');
    domainurl = domainurl.slice(domainurl.length - 2).join('.');
    console.log(domainurl)
    appHostName = prima+'-'+suffix+"."+domainurl;
    appHostName = protocol + "//" + appHostName + port;
    return appHostName;
  }

  navigateToMyAccount() {
    this.router.navigate([
      "/myaccount"
    ]);
  }

  loadSupport() {
      if(this.customerSupportEmail){
        window.location.href = "mailto:"+this.customerSupportEmail;
      }else{
        window.location.href = "mailto:help@parkoursc.com";
      }
    
  }

  tenantMenuOpened() {
    this.menuOpened = true;
  }
  tenantMenuClosed() {
    this.menuOpened = false;
  }

  getBuildVersionDetail(){
    this.tenantSettingService.getTenantSettingData().subscribe((data:any)=>{
      this.customerSupportEmail = Object.values(data)[Object.keys(data).indexOf('customersupport.contact.email')];
      let appVersion:any = Object.values(data)[Object.keys(data).indexOf('app.version.number')];
      if(/[^:]/.test(appVersion)){
        let version = appVersion.split(":");
        if(version.length>1){
          this.tenantDetails = version[1];
        }else{
          this.tenantDetails = version[0];
        }
      }else{
        this.tenantDetails = appVersion
      }
    })
  }
}
