import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Card } from '@cl/models';

@Component({
  selector: "cl-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CarouselCardComponent implements OnChanges, OnDestroy {
  @Input() selectedDt;
  @Input() DTs;
  @Input() tenancyData;
  @Input() card: Card;
  @Output() onFaveToggled = new EventEmitter();
  @Output() tenancyEmitter = new EventEmitter();
  $dropdownEl: any;
  $dropdownWrapper: any;
  $body: any;
  constructor() { }

  ngOnChanges() {
    // this.selectedDt = Object.assign({}, this.selectedDt);
  }
  ngOnDestroy() {
    if (this.$dropdownEl) {
      this.$dropdownEl.remove();
    }
  }
  hideCard(token) {
    return token !== this.card.component ? true : false;
  }
  toggleFavorite() {
    this.card.isFavorite = !this.card.isFavorite;
    this.onFaveToggled.emit(this.card);

  }
  getTenancyUpdate(message) {
    this.tenancyEmitter.emit(message)
  }
}
