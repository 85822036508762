<div class="cl-characteristic-list mt-4">
    <div class="row align-items-start">
      <div class="col-2">
        <div class="p10 cursor-pointer"
          *ngFor="let tab of historyTabs" (click)="changeActiveTab(tab)"
          [ngClass]="{'bgcolor4 color2 active-indicator rounded-end': this.activeTab === tab, 'bgcolor2 color4': this.activeTab !== tab}">
          <span [textContent]="tab"></span>
        </div>
      </div>
      <div class="col-10">
        <div [ngSwitch]="activeTab" class="m0 p0">
            <div *ngSwitchCase="'Location'" class="max-h-[300px] overflow-auto">
                <cl-asset-location [dateRange]="dateRange" [asset]="assetObject"></cl-asset-location>
            </div>
            <div *ngSwitchCase="'Custody'" class="max-h-[300px] overflow-auto">
                <cl-asset-custody [asset]="assetObject" [panel]="'details'"></cl-asset-custody>
            </div>
        </div>
      </div>
    </div>
  </div>
  