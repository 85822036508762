import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { HttpClient } from '@angular/common/http';
import { Rule, UnParsedActionTemplate, UnParsedTriggerTemplate } from '@cl/@types/rule.type';
import { UserService } from '@cl/user/user.service';
import { firstValueFrom } from 'rxjs';
import { Sites } from '@cl/@types/site.type';
import { WebHook } from '@cl/@types/webhook.type';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService,
    private userService: UserService
  ) { }

  getRules(triggerIds: string[] = [], sortCriteria= {'createdAt' : 'desc' }) {
    let payload: any = {};
    if (!_.isEmpty(triggerIds)) {
      payload.searchFields = {
        triggerIds: triggerIds
      };
    }
    payload.sortFields = sortCriteria;
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/recipes/list`, payload);
  };
  getTagSubtags() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/milestonetags`);
  }
  getTriggerTemplates() {
    return this.http.get<UnParsedTriggerTemplate[]>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/trigger_templates?isSystem=false`);
  }

  getRulesetList(sortCriteria = {'createdAt' : 'desc'}) {
    let tenantId: string = this.userService.getEffectiveTenantId();
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset/bytenant/${tenantId}`, { sortFields : sortCriteria});
  };

  createRuleset(payload: any) {
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset`, payload);
  }
  updateRuleset(payload: any, id: string) {
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset/${id}`, payload);
  }
  detatchContext(payload) {
    const options = {
      body: payload
    }
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset/detach`, options);
  }
  detachRuleFromRuleset(payload) {
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset/recipe?recipeId=${payload.ruleId}&rulesetId=${payload.rulesetId}`);
  }
  getRulesetById(id: string) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset/${id}`);
  }
  deleteRuleset(id: string) {
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset/${id}`);
  }
  fetchLocationsByType(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`, payload);
  }
  getSites(){
    const obs$ = this.http.get<Sites>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/sitedetails?depth=4`);
    return firstValueFrom(obs$);
  }
  getRoutes(){
    const payload = {"scrollId":"","globalQueryText":"","searchFieldFilter":[],"searchQueries":[{"fieldName":"baseClass","queryText":"Location,Route","queryOperator":"should","queryType":"match","childFilters":[{"fieldName":"dynamicArea","queryText":"true","queryOperator":"must_not","queryType":"match"},{"fieldName":"dynamicRoute","queryText":"true","queryOperator":"must_not"},{"fieldName":"deleted","queryText":"true","queryOperator":"must_not"},{"fieldName": "parentEntityTypeKey", "queryText": "route", "queryOperator": "must"}]}],"sortField":[]};
    return firstValueFrom(this.fetchLocationsByRoute(payload));
  }
  getAreas() {
    const payload = {"scrollId":"","globalQueryText":"","searchQueries":[{"fieldName":"baseClass","queryText":"Location,Route","queryOperator":"should","queryType":"match","childFilters":[{"fieldName":"dynamicArea","queryText":"true","queryOperator":"must_not","queryType":"match"},{"fieldName":"dynamicRoute","queryText":"true","queryOperator":"must_not"},{"fieldName":"deleted","queryText":"true","queryOperator":"must_not"},{"fieldName":"parentEntityTypeKey","queryText":"area","queryOperator":"must"}]}],"sortField":[]};
    return firstValueFrom(this.fetchLocationsByRoute(payload));
  }
  getWebHooks(): Promise<WebHook[]>{
    const obs$ = this.http.get<WebHook[]>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/list?webhookType=BRE`);
    return firstValueFrom(obs$);
  }
  getActionTemplatesData(triggerId: string): Promise<UnParsedActionTemplate[]>{
    const obs$ = this.http.get<UnParsedActionTemplate[]>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/action_templates?isSystem=true&triggerTemplateId=${triggerId}`);
    return firstValueFrom(obs$);
  }
  saveRule(ruleObject : any): Promise<any>{
    const obs$ = this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/recipe`, ruleObject);
    return firstValueFrom(obs$);
  }
  updateRule(ruleObject : any): Promise<any>{
    const obs$ = this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/recipe/${ruleObject.id}`, ruleObject);
    return firstValueFrom(obs$);
  }
  deleteRule(ruleId: string): Promise<any>{
    const obs$ = this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/recipe/${ruleId}`);
    return firstValueFrom(obs$);
  }

  getOrgRules(org: any, sortCriteria= {'createdAt' : 'desc' }) {
    let payload: any = {};
    if(!_.isEmpty(org)){
      if(org == 'noOrg'){
        payload.searchFields = {
          organization: ['ANY', '']
        };
      }else{
        payload.searchFields = {
          organization: [org]
        };
      }
    }
    payload.sortFields = sortCriteria;
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/recipes`, payload);
  };


  fetchLocationsByRoute(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location/global/search`, payload);
  }

  getAssetTypeDeleteValidation(payload){
    const options = {
      body: payload
    }
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/ruleset/detach`, options);
  }
}
