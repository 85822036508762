<div class="cl-secondary-header text-center pt5 mt-0.5" style="background-color: transparent !important;" *ngIf="showMenu">
  <div class="btn-group" role="group">
    <button type="button" class="btn btn-primary p-1 cl-menu-item"
      [ngClass]="{'bgcolor4 color2': activeGroup===list, 'bgcolor2 color4': activeGroup!==list, 'rounded-end': !hasUndefinedGroup && (i === groupList.length-1)}"
      (mouseOver)="activeMenu=list" (click)="activeMenu=list;isMenuOpen=true;"
      *ngFor="let list of groupList; index as i;" [matMenuTriggerFor]="submenu">
      <span [textContent]="list" class="rt1"></span>
      &nbsp;<span class="fa fa-caret-down rt1" [ngClass]="{ 'fa-caret-up': activeMenu=== list && isMenuOpen}"></span>
    </button>
  <ng-container *ngFor="let item of plainMenuItems; let i = index;">
    <button *ngIf="item.label !== 'Notifications'" type="button" class="btn btn-primary color4 bgcolor2 rt1 cl-menu-item"
      
      [ngClass]="{'bgcolor4 color2': activeGroup === item.label, 'bgcolor2 color4': activeGroup !==item.label, 'rounded-end': i=== plainMenuItems.length-1, 'color37 cursor-default': item.disabled}"
      [textContent]="item.label" (click)="navigateMenu(item)">
    </button>
  </ng-container>
    <mat-menu #submenu="matMenu" class="mt5 cl-menu-items" (closed)="isMenuOpen=false">
      <div class="mih30 mxh200">
        <div *ngFor="let item of modifiedMenuList[activeMenu]"  class="bgcolor2 p-1 rt1 cl-menu-list-item" [ngClass]="{'color4': !item.disabled, 'color37': item.disabled}"
          mat-menu-item [textContent]="item.label" [routerLink]="item.path"></div>
      </div>
    </mat-menu>
  </div>
</div>
