import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cl-tenancy-card',
  templateUrl: './tenancy-card.component.html',
  styleUrls: ['./tenancy-card.component.scss']
})
export class TenancyCardComponent implements OnInit, OnChanges {
  @Output() tenancyEmitter: EventEmitter<any> = new EventEmitter();
  @Input() selectedDt;
  @Input() DTs;
  @Input() tenancyData;
  @Input() cardData;

    data;
  constructor() { }

  ngOnInit() {
    // console.log('Init tenancyData', this.tenancyData, this.cardData);
    this.data = this.tenancyData || this.cardData;
  }
  ngOnChanges() {
    // console.log('CHanges tenancyData', this.tenancyData);
  }
  getTenancyUpdate(message) {
    this.tenancyEmitter.emit(message)
  }
}
