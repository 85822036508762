import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  private token$ = new BehaviorSubject<string>("");

  get token(): string {
    return this.token$.getValue();
  }

  constructor() {}

  updateToken(value: string): void {
    this.token$.next(value);
  }
}
