// export namespace Panels {
export class LvInitPanels {
  static readonly type = "[LV] Init Panels";
  constructor(public panels: any) {}
}
export class LvShowPanel {
  static readonly type = "[LV] Show Panel";
  constructor(public name: string, public from: string) {}
}
export class LvHidePanel {
  static readonly type = "[LV] Hide Panel";
  constructor(public name: string) {}
}
export class LvTogglePanel {
  static readonly type = "[LV] Toggle Panel";
  constructor(public name: string) {}
}
export class LvUpdatePanels {
  static readonly type = "[LV] Update Panels";
  constructor(public tags: any[]) {}
}
export class LvUpdatePanelValue {
  static readonly type = "[LV] Update Panel Value";
  constructor(public field: string, public value: number) {}
}
export class LvUpdateTags {
  static readonly type = "[LV] Update Tags";
  constructor(public tags: any[]) {}
}
export class LvResizeWindow {
  static readonly type = "[LV] Resize Window";
  constructor() {}
}

// }
