<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title bgcolor32 justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/asset.svg" /> 
            <span class="summary-title font-bold">Scan to Select {{pageName}}(s)</span>
        </div>
        <span class="close-icon"><i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i></span>
    </section>
    <section class="cl-side-panel-content px-1"> <div [hidden]="!hasCameras">
<div class="user-detail__item p-2">
  <label class="form-label font-bold">Select Scan Method</label>
  <div>
    <mat-select
      class="basic-input w-full"
      placeholder="Select Scan Method"
      [(ngModel)]="scanMethod"
      (ngModelChange)="selectMethodAction('methodOptn')"
      disableOptionCentering
    >
    <mat-option *ngFor="let scanmethod of scanMethods" [value]="scanmethod">
      {{ scanmethod.name }}
    </mat-option>
    </mat-select>
  </div>
  <label *ngIf="scannedCodesArray.length === 0" [ngClass]="scannedCodesArray.length === 0 && scanMethod==''?'text-danger':''" class="">{{scanText}}</label>
</div>
<!-- ngx-scanner-qrcode -->
    

    <!-- ------RFID-Scanner-----&----Bar-code------Scanner-->
        <div *ngIf="scannerObj.id =='rfid' || scannerObj.id =='bar_code' || scannerObj.id =='qr_code'">
          <div class="scanner-box" *ngIf="scannerObj.id == 'qr_code'">
            <ngx-scanner-qrcode [ngClass]="enableCameraSection ? 'show-camera-section' : 'hide-camera-section'"  #action="scanner" [config]="config" (event)="onEvent($event)"></ngx-scanner-qrcode>
            <!--  <div class="btnscan" [class.btnscan-info]="!action.isStart" [class.btnscan-warning]="action.isStart" (click)="handle(action, action.isStart ? 'stop' : 'start')">
             <span class="camera-icon" [ngClass]="action.isStart ? 'camera-slash' : 'fa fa-camera'"></span> 
            </div>-->
            <span class="btnscan" (click)="handle(action, action.isStart ? 'stop' : 'start')" title="{{cameratooltip}}" [ngClass]="enableCameraSection ? 'text-margin' : ''">
              <label class="form-label font-bold label-cursor">{{cameraInfo}}</label>
            </span>
          </div> 
                <div class="grow flex items-center pl-2">
                  <label class="form-label font-bold" [ngClass]="scannerObj.id == 'qr_code' ? 'margin' : ''">Scan Result</label>
                  </div>  
              <div class="grow flex items-center">
                  <div class="code-table">
                      <div class="flex fs14 color16">
                          <div class="flex font-bold box-margin">
                            <input type="text" [(ngModel)]="searchScanCode" class="scan-code__search-field" (keyup.enter)="handlePostScanProcessing(this)" clAutoFocus/> 
                            <span class="search-button cursor-pointer fa fa-search color4" (click)="handlePostScanProcessing(this)"></span>
                        </div>
                          <!-- <div class="basis-2/4">
                            
                          </div>
                          <div class="basis-2/4"></div> -->
                      </div>     
              <div class="spin-pos mt-10" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
              <div class="assign-details-btn">
              <!-- <button class="panel-button color4 bgcolor2 bcolor4 bw-1" (click)="handlePostScanProcessing('emit')">
                Send
              </button> -->
            </div>
        </div>
              </div>
        <!-- <h2 *ngIf="!this.selectedDevice">No camera selected.</h2> -->
    </div>
    <div class="flex scanner-scroll">
      <div class="p-4 code-table">
        <div class="flex fs14 color16 mt-1 rfidbox-margin">
          <div class="basis-2/4" *ngIf="scannedCodesArray.length>0"><span class="font-bold">Scanned Codes</span>
            <div *ngIf="scannedCodesArray.length>0" class="mb-1 list-page-count">{{scannedCodesArray.length}} scanned, <span class="selected-rows font-bold"> {{unknownCounter}} unknown</span>
            </div>
          </div>
          <div class="basis-2/4 ellipsis"></div>
        </div>
        <div class="flex flex-col color16 mt-1" *ngIf="scannedCodesArray.length>0">
          <div class="overflow-x-auto code-box-margin">
            <div class="inline-block min-w-full">
              <div>
                <table class="min-w-full">
                  <!-- <thead class="border-b bg-white">
                    <tr>
                      <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-2">
                        Scan Code
                      </th>
                      <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-2">
                      </th>
                    </tr>
                  </thead> -->
                  <tbody *ngIf="scannedItemList.length === 0">
                    <tr class="bg-white border-b"  *ngFor="let scannedCode of scannedCodesArray; let i= index">
                      <td class="text-sm text-gray-900 font-light px-3 py-2 word-break">
                        <span class="mr-2 scanItem-not-found"><i class="fa fa-question-circle" aria-hidden="true"></i></span>
                        {{scannedCode}}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap action-width" *ngIf="scannerObj.id =='rfid' || scannerObj.id =='bar_code'">
                        <span class="close-icon mr-2 float-left color4" (click)="copyCode(scannedCode)"><i class="fa fa-copy" [title]="copyTooltip"></i></span>
                        <span class="close-icon float-left color4"><i class="fa fa-trash" aria-hidden="true"></i></span>
                      </td>
                    </tr>

                  </tbody>
                  <tbody *ngIf="scannedItemList.length>0">
                    <tr class="bg-white border-b"  *ngFor="let scannedCode of scannedItemList; let i= index">
                      <td class="text-sm text-gray-900 font-light word-break">
                        <span class="mr-2" [ngClass]="[scannedCode.itemFound ? 'scanItem-found' : 'scanItem-not-found']">
                          <i class="fa" [ngClass]="[scannedCode.itemFound ? 'fa-check-circle' : 'fa-question-circle']" aria-hidden="true"></i>
                        </span>
                        {{scannedCode.code}}
                      </td>
                      <td class="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap action-width" *ngIf="scannerObj.id =='rfid' || scannerObj.id =='bar_code' || scannerObj.id =='qr_code'">
                        <span class="close-icon mr-2 float-left color4" (click)="copyCode(scannedCode.code)"><i class="fa fa-copy" [title]="copyTooltip"></i></span>
                        <span class="close-icon float-left color4" (click)="deleteCode(scannedCode, i)"><i class="fa fa-trash" aria-hidden="true"></i></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>   
      </div>
    </div>   
  </div></section>
   
  <section class="cl-side-panel-footer bgcolor33 p-2">
      <div class="flex">
        <div class="flex-col grow text-right">
          <button
            class="panel-button white bgcolor4 ml-2"
            (click)="closePanel()"
          >
          {{actionBtnText}}
          </button>
        </div>
      </div>
    </section>
</div>  
