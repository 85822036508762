<div class="cl-sensor-condtions grid grid-cols-3">
    <div class="h-[76px] br-color20 border-r text-center">
      <div class="h-[29px]">
        <cl-svg-icon
          [name]="'sensor'" [classes]="['h-[29px]', 'inline-block']"
          [options]="{fillClass: 'fill4'}"></cl-svg-icon>
        <span class="font-bold">Status</span>
      </div>
      <div class="h-[25px]">
        <span class="fa pl-[5px] ml-[5px] mr-[5px]" [ngClass]="{
          'fa-check-circle color20': sensorObject?.attributes?.overallStatus==='G',
          'fa-exclamation-circle color22': sensorObject?.attributes?.overallStatus==='Y',
          'fa-exclamation-circle color21': sensorObject?.attributes?.overallStatus==='R'
        }"></span>
        <span class="font-bold" [textContent]="getSensorOverallStatus()"></span>
      </div>
    </div>
    <div class="h-[76px] br-color20 text-center" [ngClass]="!sensorObject?.isTracker ? 'border-r': ''" >
      <div class="h-[29px]">
        <cl-svg-icon
          [name]="'battery'" [classes]="['h-[29px]', 'inline-block']"
          [options]="{fillClass: 'fill4', strokeClass: 'stroke4', height: 40}"></cl-svg-icon>
        <span class="font-bold">Battery Health</span>
      </div>
      <div class="h-[25px]">
        <div class="font-bold font-18-bold" [textContent]="getBattery()"></div>
        <div class="font-11-normal color37" *ngIf="sensorObject?.attributes?.batteryCapturedAt">last updated <span [textContent]="sensorObject?.attributes?.batteryCapturedAt | timesince"></span></div>
      </div>
    </div>
    <div class="h-[76px] text-center" *ngIf="!sensorObject?.isTracker">
      <div class="h-[29px]">
        <cl-svg-icon
          [name]="'wifi'" [classes]="['h-[29px]', 'inline-block']"
          [options]="{fillClass: 'fill4', strokeClass: 'stroke4'}"></cl-svg-icon>
        <span class="font-bold">RSSI</span>
      </div>
      <div class="h-[25px]">
        <div class="font-bold font-18-bold" [textContent]="getRSSI()"></div>
        <div class="font-11-normal color37" *ngIf="sensorObject?.attributes?.rssiCapturedAt">last updated <span [textContent]="sensorObject?.attributes?.rssiCapturedAt | timesince"></span></div>
      </div>
    </div>
</div>
