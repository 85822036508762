import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  tenantId:string;
  switchTenantId$ = new BehaviorSubject(null);
  constructor(private httpClient: HttpClient,private apiProvider: ApiProviderService) { }

  getTenantAccessList(userId){
    return this.httpClient.get(`${this.apiProvider.getAPIUrl('cloudos')}/api/v1/tenants/${userId}`)
  }

  setTenantId(tenantId){
    this.tenantId = tenantId;
  }
  getTenantId(){
    return this.tenantId;
  }

  showAnalyticsMenu(token){
    let headers = new HttpHeaders({
      "content-type": "application/json",
      "token": token
     }); 
    return this.httpClient.get(`${this.apiProvider.getAPIUrl('cloudos')}/cloudos/api/1/user`,{ headers: headers })
  }
  showBIWorkbenchMenu(token, tenantId){
    let headers = new HttpHeaders({
      "content-type": "application/json",
      "token": token
     }); 
    //return this.httpClient.get(`${this.apiProvider.getAPIUrl('cloudos')}/cloudos/api/1/property/bulk/common/appSettings?domainName=${environment.name}&locale=NA&tenantId=${tenantId}`,{ headers: headers })
    return this.httpClient.get(`${this.apiProvider.getAPIUrl('cloudos')}/cloudos/api/1/property/bulk/common/appSettings?domainName=name&locale=NA&tenantId=${tenantId}`,{ headers: headers })
  }
}
