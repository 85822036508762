<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title bgcolor32 justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/sensor.svg" />
            <span class="black font-bold">Bind {{sensorObject.isTracker ? 'Tracker' : 'Sensor'}}</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content flex flex-col">
        <a href="#/sensordetails/{{sensorObject?.id}}" class="fs14 mt-4 ml-4 mb-2">{{sensorObject?.id}}</a>
        <div class="fs14 pl-4 pr-4">Bind this device to the below selected asset</div>
        <div class="pl-4 pr-4">
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Name</div>
                <div class="basis-2/4" [title]="selectedAsset?.name">{{selectedAsset?.name | truncate : 20}}</div>
            </div>
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Asset Type</div>
                <div class="basis-2/4">{{selectedAsset?.type}}</div>
            </div>
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Status</div>
                <div class="basis-2/4">
                    {{selectedAsset?.status | startCase}}
                </div>
            </div>
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Asset ID</div>
                <div class="basis-2/4" [title]="selectedAsset?.externalId">
                    {{selectedAsset?.externalId | truncate : 20}}
                </div>
            </div>
            <div class="flex fs12 color16 mt-1" *ngIf="showComponent">
                <div class="basis-2/4 font-bold">Component<span class="mandatory text-red-500">*</span></div>
                <div class="basis-2/4">
                    <mat-select [(ngModel)]="assetComponent" placeholder="Select Component" [value]="">
                        <mat-option [value]="''"> Select Component </mat-option>
                         <mat-option *ngFor="let cmplist of assetComponentList | keyvalue" [value]="cmplist.key" [textContent]="cmplist.value">
                         </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <form [formGroup]="bindForm" novalidate class="pl-4 pr-4 grow relative">
            <input type="search" formControlName="searchTerm" class="basic-input mt5 pt5 pr2"
            placeholder="Search Unmonitored Assets" value="">
            <div class="spin-pos" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
            <cdk-virtual-scroll-viewport itemSize="20" class="list-viewport h-calc-120 m-2">
                <div class="search-list-item-container ba" *cdkVirtualFor="let item of assetList" (click)="selectListItem(item)">
                    <span class="search-list-item justify-between flex items-center" [class.item-select]="selectedAsset?.externalId === item?.externalId">
                        <span class="" [title]="item.name">{{item.name | truncate:30}}</span>
                        <i class="fa fa-check color4" aria-hidden="true" *ngIf="selectedAsset?.externalId === item?.externalId"></i>
                    </span>
                </div>
            </cdk-virtual-scroll-viewport>
        </form>
    </section>
    <section class="cl-side-panel-footer bgcolor33 pr-4 pl-4">
        <div class='flex justify-end items-center'>
            <a formnovalidate class=" m-2" (click)="closePanel()">Cancel</a>
            <button class="panel-button white bgcolor4" [disabled]="!selectedAsset?.id" (click)="bindAsset()">
                Bind
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="bindClicked"></i>
            </button>
        </div>
    </section>
</div>
