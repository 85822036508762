import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ShipmentTrackPoint, WayPointStatus } from '@cl/@types/shipment.types';

@Component({
  selector: 'cl-shipment-router-plan',
  templateUrl: './shipment-router-plan.component.html',
  styleUrls: ['./shipment-router-plan.component.scss'],
})
export class ShipmentRouterPlanComponent implements OnInit, OnChanges {
  // FIXME: This component needs to be updated while getting proper API data
  @Input('shipmentDetails') shipmentDetails: any;
  @Input() winkedAssetId: string;
  tempValue:any;
  assets = [];
  selectedAsset: any;

  originChecks = ['Ready to Ship', 'Planned', 'PLANNED', 'Drafted', 'Pending'];
  destinationChecks = ['At Destination'];
  completedChecks = ['Completed'];

  trackingInfo: ShipmentTrackPoint[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.assets = this.shipmentDetails.assetNodes;
    this.selectedAsset = this.assets[0];
    if (changes['winkedAssetId'] && !changes['winkedAssetId'].firstChange && this.winkedAssetId) {
      this.shipmentDetails.assetNodes.forEach((assetNode) => {
        if (assetNode.id == this.winkedAssetId) {
          this.selectedAsset = assetNode;
        }
      });
    }
    this.tempValue = this.selectedAsset
    this.changeAsset({ ...this.selectedAsset });
  }

  changeAsset(asset) {
    // First check with asset level if it is not present take shipment level status
    if(!asset) return;
    this.tempValue =asset
    const currentStatus =
      asset.properties?.statusCode ||
      this.shipmentDetails.shipmentNode.properties.statusCode;

    let isInTransit = false;

    const lastCheckpointSeq = asset?.properties?.lastWaypointCrossedSeq;

    // This check is for if API does not have these properties we are no longer to support rendering a route plan
    if (!currentStatus || lastCheckpointSeq === undefined) return;
    if(this.shipmentDetails.routeNode.properties.locationDetails && typeof this.shipmentDetails.routeNode.properties.locationDetails == 'string'){
      this.shipmentDetails.routeNode.properties.locationDetails = JSON.parse(this.shipmentDetails.routeNode?.properties?.locationDetails)
    }
    const routePoints: any[] =
      this.shipmentDetails.routeNode.properties.locationDetails.map((route) => {
        const address = JSON.parse(
          route?.routeGeojson?.replace(/\\+/g, '')
        )?.address;
        return {
          name: route.areaName,
          order: route.loc_seq_order_no,
          address: address,
        };
      });

    // If status is in transit we need to add another milestone
    if (currentStatus === 'In-Transit') {
      isInTransit = true;

      // FIXME: It should be changed after valid lastCheckpointSeq is came.
      // Later no need of if condition
      if (lastCheckpointSeq === 0) {
        routePoints.splice(1, 0, {
          address: asset.properties.distance,
          order: -1, // -1 represent intransit
          name: 'In-Transit',
        });
      } else {
        routePoints.splice(lastCheckpointSeq, 0, {
          address: asset.properties.distance,
          order: -1, // -1 represent intransit
          name: 'In-Transit',
        });
      }
    }

    const originPoint = routePoints.shift();
    const destinationPoint = routePoints.pop();
    const shipmentProps = this.shipmentDetails?.shipmentNode?.properties;
    const routeProps = this.shipmentDetails?.routeNode?.properties;

    let originArea = shipmentProps?.routeHidden ? shipmentProps?.originAreaName : routeProps?.source;
    let destinationArea = shipmentProps?.routeHidden ? shipmentProps?.destinationAreaName : routeProps?.destination;

    originArea = `<div class="truncate w-[280px]" title="${originArea}">${originArea}</div>`;
    destinationArea = `<div class="truncate w-[280px]" title="${destinationArea}">${destinationArea}</div>`;
    if(!shipmentProps?.originHidden){

      originArea += ` <div class="text-xs truncate w-[280px]" title="${originPoint.address}">${originPoint.address}</div>`;
    }
    if(!shipmentProps?.destHidden){
      destinationArea += ` <div class="text-xs truncate w-[280px]" title="${destinationPoint.address}">${destinationPoint.address}</div>`;
    }
    // First milestone is Origin
    const mileStones: ShipmentTrackPoint[] = [
      {
        title: 'Origin',
        status: this.originChecks.includes(currentStatus)
          ? WayPointStatus.Current
          : WayPointStatus.Completed,
        message: originArea,
        isHTMLMessage: true,
      },
    ];

    // Based upon the lastWaypointCrossedSeq we need to make other waypoints to be complete / pending
    routePoints.forEach((route) => {
      let mileStone = {
        message: '',
        status:
          route.order <= lastCheckpointSeq ||
          this.destinationChecks.includes(currentStatus) ||
          this.completedChecks.includes(currentStatus)
            ? WayPointStatus.Completed
            : WayPointStatus.Pending,
        title: route.name,
      };

      if (isInTransit && route.order === -1) {
        mileStone.status = WayPointStatus.Current;
        mileStone.message = route.address;
      }

      mileStones.push(mileStone);
    });

    // Last milestone is Destination
    mileStones.push({
      title: 'Destination',
      status: this.destinationChecks.includes(currentStatus)
        ? WayPointStatus.Current
        : this.completedChecks.includes(currentStatus)
        ? WayPointStatus.Completed
        : WayPointStatus.Pending,
      message: destinationArea,
      isHTMLMessage: true,
    });

    this.trackingInfo = [...mileStones];
  }
  displayFn(item) {
    return item? item.properties?.name : ""
  }
  matClose(){
  this.selectedAsset=this.tempValue;
 }
 matOpen(){
  this.selectedAsset=undefined;
}
}
