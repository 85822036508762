<div class="wt__loader wt__flex-row" [hidden]="!loadingStats">
    <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
</div>
<div [hidden]="!gotData" class="wt__no-data-message">
    <div>No data available</div>
</div>
<div class="readouts-container m-10-20">
    <div *ngFor="let item of data?.list" class="graph-grid-item">
        <span *ngIf="item.name.length > 12" class="foz14  justify-self-end" matTooltip="{{ item.name }}" matTooltipClass="tooltip-styles">
            {{ item.name | truncate : 12}}
        </span>
        <span  *ngIf="item.name.length <= 12" class="foz14  justify-self-end">
            {{ item.name}}
        </span>
        <google-chart class="ov-hide" [type]="chartType" style="width: 100%;"
            [data]="item.values" [options]="options">
        </google-chart>
        <span class="foz14">{{item.value}}{{item.unit}}</span>
        <span class="variance-icon {{item?.status}}" [inlineSVG]="'assets/svgs/variance-icon.svg'"></span>
        <!-- <span [ngClass]='item.status'><i class="fa fa-line-chart"></i></span> -->
    </div>
</div>
