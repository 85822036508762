export const environment = {
  production: true,
  appTitle: 'ClientLeaf Main',
  appErrorPrefix: '[clMain Error]',
  version: '1.0.0',
  showServerErrors: true,
  showAppErrors: false,
  domain: 'test21',
  hostName: '.cloudleaf.io',
  localCluster: 'https://dev30-app.cloudleaf.io',
  /*For prod build, Below Config is just placeholder for avoiding code breakage, the auth0 config is picked from @data/auth-config.json*/
  TRACKING_ENABLED: true,
  TRACKING_ID: 'G-EEDTNZX2E3',
  GTM_TRACKER: {},
  auth0Domain: 'qaprod-dt-login.cloudleaf.io',
  auth0ClientId: 'qjd5JpOdGwENdDKzr3IACsACX0HNpZHT',
};
