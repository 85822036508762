
<div class="mb-4 -mt-1"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>



<div class="details-page-wrapper location-details-wrapper">
  <div class="details-header-wrapper">
    <div class="flex items-stretch flex-wrap">
      <div class="details-flex-card bgcolor2 ml0 ">
        <div class="" style="flex-direction: column;">
            
        </div>
      </div>
      <div class="details-flex-card bgcolor2 ml0 " style="background: transparent;">
        <div class="" style="flex-direction: column;">
            <div class="right-box-header">
                <div style="width:50%">
                   <span class="label">Name*</span><br/>
                   <input class="text"   type="text" style="width:95%;height: 30px">
               </div>
               <div style="width:50%">
                <div class="user-detail__item p-2" >
                    <label class="form-label font-bold">Trigger</label>
                    <div>
                      <mat-select
                        class="basic-input w-full"
                        placeholder="Select Trigger"
                        [(ngModel)]="trigger"
                      >
                        <mat-option
                          [value]="trigger"
                          *ngFor="let trigger of triggerList"
                          >{{ trigger.name }}</mat-option
                        >
                      </mat-select>
                      <span *ngIf="isCatalogTypeError" class="text-danger">Please Select Asset Type</span>
                    </div>
                  </div>
               </div>
        </div>
      </div>
    </div>
  </div>
  


  
</div>
