import { Injectable } from '@angular/core';
import { Panels } from '@cl/models';
import { HidePanel, InitPanels, ShowPanel, TogglePanel, UpdatePanels } from '@cl/ngxs/actions';
import { Action, State, StateContext } from '@ngxs/store';

import { PanelStatesService } from '../common/utils/panel-states.service';


@State<Panels>({
    name: 'panels_state',
    defaults: {
        'entities': true,
        'rightList': false,
        'leftList': true,
        'inspector': false,
        'footer': false,
        'browserWidth': 0,
        'contentWidth': 0,
        'mainWidth': 0,
        'panelHeight': 0,
        'maHeight': 0,
        'mainHeight': 0,
        'leftOffset': 0,
        'inspectorOffset': 0,
        'rightListOffset': 0,
        'leftListOffset': 0,
        'maMapOffset': 0,
        'footerOffset': 0,
        'showEditForm': false,
        'carousel': false,
        'filters': false,
        'mainTop': 0,
        'filtersTop': 0,
        'showTags': false,
        'filtersSelected': false
    }
})
@Injectable()
export class PanelsState {
    constructor(private panelStatesService: PanelStatesService) { }
    @Action(InitPanels)
    initPanels(ctx: StateContext<any>, action: InitPanels) {
        const state = action.panels || ctx.getState();
        ctx.patchState({
            ...this.panelStatesService.getPanelPositions(state)
        })
    }
    @Action(UpdatePanels)
    updatePanels(ctx: StateContext<any>, action: UpdatePanels) {
        const state = ctx.getState();
        ctx.patchState({
            ...this.panelStatesService.getPanelPositions(state)
        })
    }
    @Action(ShowPanel)
    showPanel(ctx: StateContext<any>, action: ShowPanel) {
        ctx.patchState({
            [action.name]: true
        })
        return ctx.dispatch(new UpdatePanels())

    }
    @Action(HidePanel)
    hidePanel(ctx: StateContext<any>, action: HidePanel) {
        ctx.patchState({
            [action.name]: false
        })
        return ctx.dispatch(new UpdatePanels())
    }
    @Action(TogglePanel)
    togglePanel(ctx: StateContext<any>, action: TogglePanel) {
        const state = ctx.getState();
        ctx.patchState({
            [action.name]: !state[action.name]
        })
        return ctx.dispatch(new UpdatePanels())
    }
}

