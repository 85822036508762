import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { TranslateLoader} from '@ngx-translate/core';
import BASE_TRANSLATIONS from '../../@data/translations.json';
import { EventComService } from "../shell/event-com.service";
import { PropertyService } from "../../property/property.service";
import { BulkProperty } from '../../@types/bulk-property.type';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService implements TranslateLoader{
  constructor(private propertyService: PropertyService, private eventService: EventComService) {

  }
  getTranslation(lang: string): Observable<any> {
    return of(this.mergeTranslations(BASE_TRANSLATIONS, this.propertyService.translations));
  }
  private mergeTranslations(defaultTranslations : any, updatedTranslations: any): any{
    return _.merge(defaultTranslations, updatedTranslations);
  }
}
