import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { ServerCluster } from "@cl/models";


@Component({
  selector: "app-server-clustered-map-marker",
  templateUrl: "./server-clustered-map-marker.component.html",
  styleUrls: ["./server-clustered-map-marker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ServerClusteredMapMarkerComponent {
  @Input() cluster: ServerCluster;
  @Output() clusterClicked = new EventEmitter<ServerCluster>();
}