<form class="filters-wrapper css-grid h-100 gap-70px" [class.hide]="!navigatorConfig" [formGroup]="form">
  <div>
    <app-domain-filter-list *ngIf="hasDomainFilters"
      [domainMap]="navigatorConfig?.domain"
      formControlName="domains"
      (activeFiltersCountChange)="activeDomainsFiltersCountChange($event)"
      (totalRecords)="totalRecordsCount($event)"
      (allFilters)="getAllFilters($event)">
    </app-domain-filter-list>
  </div>
<!--
  <div class="relative" [class.left-divider]="hasFilters">
    <app-filter-list *ngIf="hasFilters"
      [filterMap]="navigatorConfig?.filter"
      formControlName="filters"
      (activeFiltersCountChange)="activeFiltersCountChange($event)">
    </app-filter-list>
  </div> -->

  <app-search-box *ngIf="navigatorConfig?.search"
    class="left-divider"
    [placeholder]="navigatorConfig?.search?.label"
    formControlName="searchTerm">
  </app-search-box>

  <!-- <app-mode-switch *ngIf="navigatorConfig?.viewModes?.length > 1"
    formControlName="selectedMode"
    [availableModes]="viewModes">
  </app-mode-switch> -->
</form>
<div class="filter-chips">
  <!-- <div class="first-chip" *ngIf="searchTerm || activeDomainFiltersCount > 0">
    <div *ngIf="searchTerm">
      <span>query: {{searchTerm}}</span>
        <i class="fa fa-close ml-1" (click)="clearSearch()"></i>
    </div>
    <div>Showing {{filteredNodes$ | async}} of {{this.recCount}}</div>
  </div> -->
  <mat-chip-list #chipList aria-label="Filter selection">
    <ng-container *ngFor="let chip of chipElements; let i = index">
      <mat-chip (removed)="remove(chip, i)" class="ml10 fs12" *ngIf="chip.domain == currentActiveDomain">
        {{chip["value"] && chip["children"].length  ? chip["children"] : chip["value"]}}
        <button matChipRemove>
          <!-- <mat-icon class="fs14">cancel</mat-icon> -->
          <i class="fa fa-times-circle-o mr-4"></i>
        </button>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>

