import { Injectable } from "@angular/core";
import { DigitalTwinGraph, DtNode, PartialOf } from "@cl/models";
import KeyLines from "keylines";


const NodeColorCodes = {
  Asset: "#004ea7",
  Excursion: "#ff0000",
  Incident: "#ff2525",
  Inventory: "#fd863c",
  Location: "#4cb7e7",
  Shipment: "#4a5c6d",
  Default: "#4d9ade",
};

const NodeIconUrl = {
  asset: "./assets/svgs/circle-icons-blue/category.svg",
  category: "./assets/svgs/circle-icons-blue/category.svg",
  excursion: "./assets/svgs/circle-icons-blue/site.svg",
  incident: "./assets/svgs/circle-icons-blue/alert.svg",
  inventory: "./assets/svgs/circle-icons-blue/asset.svg",
  location: "./assets/svgs/circle-icons-blue/location.svg",
  site: "./assets/svgs/circle-icons-blue/site.svg",
  shipment: "./assets/svgs/circle-icons-blue/truck.svg",
  organization: "./assets/svgs/circle-icons-blue/organization.svg",
  digitaltwin: "./assets/svgs/circle-icons-blue/sub_assets.svg",
  sensor: "./assets/svgs/circle-icons-blue/zone.svg",
};

export const MapZoomDefault = {
  max: 15,
  min: 3,
};

@Injectable()
export class KeylinesUtilService {
  constructor() {}

  getNodeIdsByType(data: KeyLines.ChartData, dataIndex: PartialOf<any>, combineBy = "type"): PartialOf<string[]> {
    const nodeIdsByType = data.items.reduce((acc, curr) => {
      const node: DtNode = dataIndex[curr.id];
      const fieldName = node[combineBy];
      if (!acc[fieldName] && curr.type === "node") {
        acc[fieldName] = [];
      }

      if (curr.type === "node") {
        acc[fieldName].push(curr.id);
      }

      return acc;
    }, {} as PartialOf<string[]>);

    return nodeIdsByType;
  }

  async combine(chart: KeyLines.Chart, ids: string[], dataIndex: PartialOf<any>, combineBy: string) {
    const node: DtNode = dataIndex[ids[0]];
    const label = node[combineBy];
    if (!label) return null;
    const nodeColor = this.getNodeColor(node);
    const nodeIconUrl = this.getNodeIconUrl(node, combineBy);

    return chart.combo().combine(
      {
        ids,
        label,
        open: false,
        style: {
          u: nodeIconUrl,
          t: label,
          c: nodeColor,
          e: 1.2,
        },
      },
      {
        arrange: "lens",
        animate: false,
        select: false,
      },
    );
  }

  toKeyLines(graph: DigitalTwinGraph, dataIndex: PartialOf<any>): KeyLines.ChartData {
    const chartData: KeyLines.ChartData = {
      type: "LinkChart",
      items: [...this.toNode(graph, dataIndex), ...this.toLink(graph, dataIndex)],
    };

    return chartData;
  }

  toLink(graph: DigitalTwinGraph, dataIndex: PartialOf<any>): KeyLines.Link[] {
    const links: KeyLines.Link[] = graph.edges.map<KeyLines.Link>((g) => {
      dataIndex[g.id] = g;

      const linkColor = this.getLinkColor(dataIndex[g.id1], dataIndex[g.id2]);

      const link: KeyLines.Link = {
        type: "link",
        id: g.id,
        id1: g.id1,
        id2: g.id2,
        a1: true,
        a2: false,
        c: linkColor,
        w: 2,
      };

      return link;
    });

    return links;
  }

  toNode(graph: DigitalTwinGraph, dataIndex: PartialOf<any>): KeyLines.Node[] {
    const nodes: KeyLines.Node[] = graph.nodes.map<KeyLines.Node>((g: any) => {
      dataIndex[g.id] = g;
      const nodeColor = this.getNodeColor(g);
      const nodeName: string = (
        g?.properties?.name ||
        g?.name ||
        g?.properties?.assetName ||
        g?.assetName ||
        g?.id
      ) ?? "";

      const node: KeyLines.Node = {
        type: "node",
        id: g.id,
        t: nodeName,
        fb: true,
        b: nodeColor,
        c: nodeColor,
        bw: 2,
        e: 1,
        fbc: "white",
        // g: [
        //   {
        //     p: "ne",
        //     b: nodeColor,
        //     c: nodeColor,
        //   },
        // ],
        d: {
          baseType: g?.baseType ?? "",
        },
      };

      const position = g?.properties?.position || g.position;
      if (position) {
        const pos = position?.split(",");
        node.pos = {
          lat: +pos[0],
          lng: +pos[1],
        };
      }

      return node;
    });

    return nodes;
  }

  getNodeColor(node: DtNode): string {
    return NodeColorCodes[node.baseType] || NodeColorCodes[node.type] || NodeColorCodes[node.classType] || NodeColorCodes.Default;
  }

  getNodeIconUrl(node: DtNode, combineBy: string): string {
    const type = (node[combineBy] || node.baseType || node.type || node.baseClass || "").toLowerCase();
    return NodeIconUrl[type];
  }

  getLinkColor(node1: DtNode, node2: DtNode): string {
    let linkColor: string = NodeColorCodes.Default;
    if (node2) {
      linkColor = NodeColorCodes[node2.baseType] || NodeColorCodes[node2.type];
    } else if (node1) {
      linkColor = NodeColorCodes[node1.baseType] || NodeColorCodes[node1.type];
    }

    if (!linkColor) {
      linkColor = NodeColorCodes.Default;
    }

    return linkColor;
  }
}
