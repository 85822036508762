<div class="asset-add-panel">
  <cl-side-panel>
    <section class="cl-side-panel-title side-panel-header-bg">
      <span class="black font-bold">
        {{ mode === "add" ? "New" : "Edit" }} {{ classType }}
      </span>
    </section>

    <section class="cl-side-panel-content px-1 mx-1">
      <div class="user-detail__item p-2" *ngIf="!deviceCatalog?.name">
        <label class="form-label font-bold" *ngIf="mode === 'add'">Select {{classType}} Type</label>
        <div *ngIf="mode === 'add'">
          <mat-select
            class="basic-input w-full"
            placeholder="Select {{classType}} Type"
            [(ngModel)]="catalogType"
          >
            <mat-option
              [value]="catalogType"
              *ngFor="let catalogType of catalogTypesList"
              >{{ catalogType.name }}</mat-option
            >
          </mat-select>
          <span *ngIf="isCatalogTypeError" class="text-danger">Please Select {{classType}} Type</span>
        </div>
      </div>
      <div *ngIf="deviceCatalog">
        <div class="p-2 sticky top-0 bg-white z-[999]">
          <span class="flex items-center">
            <span
              class="black font-bold text-lg mr-2 pointer color4"
              *ngIf="!isEdit"
              (click)="deviceCatalog = undefined"
            >
              <i class="fa fa-chevron-circle-left"></i>
            </span>
            <label
              class="black text-sm truncate"
              [title]="deviceCatalog?.name"
            >
              {{ isEdit ? "Edit" : "New" }} {{ deviceCatalog?.name }}
            </label>
          </span>
        </div>
        <mat-tab-group
          #tab
          *ngIf="!isEdit"
          (click)="onTabClick(tab)"
          class="sticky top-0 bg-white z-[999]"
        >
          <mat-tab label="Single"></mat-tab>
          <mat-tab label="Bulk Add via CSV"></mat-tab>
        </mat-tab-group>
        <ng-container *ngIf="tabSelected == 0">
          <div class="flex h-12">
            <img [src]="assetAvatar" class="rounded-full h-12 w-12" />
            <div class="h-full flex flex-col justify-between ml-4">
              <label for="assetAvatar" class="color4 cursor-pointer font-bold"
                >Edit Image</label
              >
              <input
                type="file"
                class="hidden"
                accept="image/*"
                id="assetAvatar"
                (change)="onAvatarChange($event)"
              />
            </div>
          </div>

          <ng-container *ngIf="inputFields.length > 0">
            <cl-dynamic-form-renderer
              [inputFields]="inputFields"
              [showAnyOrg]="true"
              [form]="form"
              #formRenderer
            >
            </cl-dynamic-form-renderer>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="tabSelected == 1">
          <cl-bulk-action
            [panelObj]="bulkConfig"
            (emitFormData)="bulkFormData = $event"
            [fields]="downloadedCSVFields"
            [downloadedCSVFileName]="classType + '_add_template'"
          ></cl-bulk-action>
        </ng-container>
      </div>
    </section>
    <section
      class="cl-side-panel-footer bgcolor33 p-2"
      *ngIf="mode === 'edit' || mode === 'add'"
    >
      <div class="flex">
        <div class="flex-col grow text-right">
          <a
            class="m-2"
            (click)="closePanel()"
            title="cancels the changes and closes the form"
            >Cancel</a
          >
          <button class="panel-button white bgcolor4 ml-2" (click)="deviceCatalog ? onSave() : onNext()">
            {{ deviceCatalog ? (tabSelected == 0 ? "Save" : "Upload") : "Next" }}
            <span
              *ngIf="isCreatingDevice"
              class="fa fa-circle-o-notch fa-pulse white"
              [ngClass]="{
                visible: isCreatingDevice,
                hidden: !isCreatingDevice
              }"
            ></span>
          </button>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>

<div [formGroup]="form">
  <ng-template #staticSelectRef let-field>
    <div class="user-detail__item" style="width: 100%" *ngIf="field.id === 'sensorType' || accountProfileOptn">
      <label class="form-label font-bold" [attr.for]="field.id">{{ field.displayLabel }}
        <span class="mandatory text-red-500" *ngIf="field.required">*</span></label>
      <div class="dropdown-input">
        <div class="relative">
          <input
            [matAutocomplete]="carrier"
            class="basic-input w-full drp-search text-xs pl-1.5 pr-5 h-[30px]"
            [placeholder]="'Select ' + field.displayLabel"
            [formControlName]="field.id"
            type="text"
            (blur)="onMatInputBlur(field.id)"
            (focus)="onMatOpen(field)"
          />
          <i
            class="fa fa-close absolute top-2 right-2 cursor-pointer"
            (click)="onMatClear(field)"
            *ngIf="field?.dropdownConfig?.isClearable"
          ></i>
        </div>
        <mat-autocomplete
          #carrier="matAutocomplete"
          (optionSelected)="onSelect($event.option.value, field.id)"
          (opened)="onMatOpen(field)"
          [displayWith]="displayFn"
        >
          <ng-container>
            <mat-option
              *ngFor="
                let option of staticOptions[field.id]
                  | search: form.get(field.id)?.value:'label'
              "
              [value]="option"
            >
              {{ option.label ? option.label : option }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
      <div class="text-danger" *ngIf="submitted && form.get(field.id).errors">
        <span *ngIf="form.get(field.id).getError('required')"
          >{{ field.displayLabel }} is required</span
        >
        <span *ngIf="form.get(field.id).getError('pattern')"
          >Please select valid {{ field.displayLabel }}</span
        >
      </div>
    </div>
  </ng-template>
</div>
