import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, OnChanges, HostListener } from '@angular/core';
import {ScannerQRCodeConfig, ScannerQRCodeSelectedFiles, NgxScannerQrcodeService, ScannerQRCodeResult, NgxScannerQrcodeComponent} from 'ngx-scanner-qrcode';
import onScan from 'onscan.js';
import { debounceTime, fromEvent, Subject } from 'rxjs';
import { ToastService } from '@cl/common/services/toast.service';
import _ from 'lodash';
@Component({
  selector: 'cl-code-scanner',
  templateUrl: './code-scanner.component.html',
  styleUrls: ['./code-scanner.component.scss']
})
export class CodeScannerComponent implements OnInit, OnDestroy, OnChanges {
  scanFilterInfo: string = 'Note: Scanning will reset any filter selection';
  radytoScan: string = 'Ready to Scan...';
  scanText: string;
  scanMethod:string = '';
  scanMethods: any = [
    {'id':'qr_code', 'name':'QR Code'},
    {'id':'bar_code', 'name':'Bar Code'},
    {'id':'rfid', 'name':'RFID'}
  ];
  selectedOption: any = '';
  @Output() closeScannerPanel = new EventEmitter();
  @Output() codescannerhandler = new EventEmitter();
  @Input() selectedScanItem:any;
  scannedTotal:number = 0;
  @Output() closeSidePanel = new EventEmitter();
  @Output() scanCodeEvent = new EventEmitter<typeof this.scanCodeAction>();
  scannerObj: any;
  @ViewChild('action') action: NgxScannerQrcodeComponent;
  @ViewChild('action') actionCamera: NgxScannerQrcodeComponent;
  @Input() pageName: any;
  @Input() scannedItem:any[];

  debounceSubject = new Subject();

  public config: ScannerQRCodeConfig = {
    // fps: 1000,
    vibrate: 400,
    // isAuto: true,
    isBeep: false,
    // decode: 'macintosh',
    deviceActive: 1,
    constraints: {
      audio: false,
      video: {
        width: window.innerWidth
      }
    }
  };
  hasCameras = true;
  hasPermission: boolean = true;
  qrResultString: string;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo;
  barcode: string;
  scanEvent: any;
  scannedCodesArray: string[] = [];
  loading: boolean;
  searchScanCode:any='';
  copyTooltip:string = 'Copy';
  scanCodeAction = {
    code:[],
    action:''
  }
  public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
  scannedItemList:{code:any, itemFound:boolean, id?: string}[] = [];
  unknownCounter:number = 0;
  enableScanner:boolean = false;
  enableCameraSection: boolean= false;
  isCameraStart: boolean = true;
  cameraInfo:any = 'Use Camera';
  cameratooltip ='Use camera to scan the QR Code';
 actionBtnText:string = 'Cancel';
  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent){
    if(event.ctrlKey){
      event.preventDefault();
    }
  }

  constructor(private toastService: ToastService, private qrcode: NgxScannerQrcodeService) { }

  ngOnInit(): void {
    this.initializePostScanProcessor();
    this.scannerObj = this.scanMethod;
    this.scanText = this.scanFilterInfo;
  }

  initializeScanner(){
      if(onScan.isAttachedTo(document)){
        return;
      }
      onScan.attachTo(document);
      this.scanEvent = fromEvent(document, 'scan');
      this.scanEvent.subscribe((barRfidCode:any) =>{
        this.handlePostScanProcessing(barRfidCode);
      });
  }

  ngOnChanges(){
    this.unknownCounter = 0;
    if(this.scannedItem.length>0){
      this.scannedItemList = [];
      this.scannedCodesArray.forEach(element => {
        let asset = this.scannedItem.find(item => item?.qrBarCode === element);
        if (asset) {
            this.scannedItemList.push({code:element, itemFound:true, id: asset.id});
        }else{
            this.scannedItemList.push({code:element, itemFound:false});
            this.unknownCounter++;
        }
      });
    }
  }

onEvent(e: ScannerQRCodeResult[]): void {
  if(e.length && !_.isEmpty(e[0].value)){
    this.qrResultString = e[0].value;
    this.handlePostScanProcessing(e[0].value);
  }
}

handle(action: any, fn: string): void {
  if(this.enableCameraSection === true){
    this.enableCameraSection = false;
    this.cameraInfo = 'Use Camera';
  }else{
    this.enableCameraSection = true;
    this.cameraInfo = 'Close Camera';
  }

}


closePanel() {
  this.closeSidePanel.emit();
}

handlePostScanProcessing(scanCode:any){
  this.debounceSubject.next(scanCode);
}

initializePostScanProcessor(){
  this.debounceSubject.pipe(debounceTime(2000)).subscribe((scanCode: any)=>{
    if(this.scannedCodesArray.indexOf(this.searchScanCode)> -1){
      this.toastService.error('Code is already There','',2000);
    }else{
      const currentScannedArray = [];
      if(this.scannerObj.id == 'rfid' || this.scannerObj.id == 'bar_code' || (this.scannerObj.id == 'qr_code' && !this.enableCameraSection)){
        this.copyTooltip = 'Copy'
        let formatedData:string[];
          if(/[^,]/.test(this.searchScanCode)){
            formatedData = this.formatScanCode(this.searchScanCode, this.scannerObj.id);
            formatedData.forEach(searchText =>{
              if(this.scannedCodesArray.indexOf(searchText.trim()) === -1) {
                this.scannedCodesArray.push(searchText.trim());
                currentScannedArray.push(searchText.trim());
              }
            })
          if(!_.isEmpty(currentScannedArray)){
            this.scanCodeAction = {'code': currentScannedArray,
            'action':'scan'}
            this.scanCodeEvent.emit(this.scanCodeAction);
          }else{
            this.toastService.error('Code is already There','',2000);
          }
          }else{
            formatedData = this.searchScanCode;
            if(this.scannedCodesArray.indexOf(this.searchScanCode) === -1 && !_.isEmpty(this.searchScanCode)) {
              this.scannedCodesArray.push(this.searchScanCode);
              currentScannedArray.push(this.searchScanCode);
              this.scanCodeAction = {'code': currentScannedArray,
            'action':'scan'}
            this.scanCodeEvent.emit(this.scanCodeAction);
            }
          }
      }else{
        if(this.scannedCodesArray.indexOf(scanCode) === -1 && !_.isEmpty(scanCode)) {
          this.scannedCodesArray.push(scanCode);
          currentScannedArray.push(scanCode);
          this.scanCodeAction = {'code': currentScannedArray,
            'action':'scan'}
            this.scanCodeEvent.emit(this.scanCodeAction);
        }
      }
    }
    if(this.scannedItem.length === 0 && this.scannedCodesArray.length>0){
      this.actionBtnText = 'Done';
    }
    this.searchScanCode = '';
  })
}

copyCode(code){
  let selBox = document.createElement('textarea');
  selBox.style.position = 'fixed';
  selBox.style.left = '0';
  selBox.style.top = '0';
  selBox.style.opacity = '0';
  selBox.value = code;
  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();
  document.execCommand('copy');
  document.body.removeChild(selBox);
  this.copyTooltip = 'Copied';
}

deleteCode(scannedCode: {code:any, itemFound:boolean, id?: string}, index: number){
  this.scannedCodesArray.splice(index, 1);
  this.scannedItemList.splice(index, 1);

  if(!scannedCode.itemFound) return;

  this.scanCodeAction = {
    'code': [scannedCode.id],
    'action':'delete',
  }
  this.scanCodeEvent.emit(this.scanCodeAction);
}

formatScanCode(input, type){
  let updatedData:any[] = [];
  let removedSpecialCharachter='';
  if(type === 'rfid' || type === 'bar_code' || type === 'qr_code'){
    removedSpecialCharachter = input?.trim()?.replaceAll(/[&\/\\#,,+,,,()$~%.'":*?<>{}/\t/\r]/g,',');
    
    // split comma separated value and remove duplicate
    updatedData = [...new Set(removedSpecialCharachter.split(','))];
    // remove empty value
    const data = updatedData.filter((x) => x!= '' && x!= ' ');
    return data
  }
}

ngOnDestroy(){
  window.removeEventListener('keydown', this.onKeyDown)
    if(!_.isEmpty(this.scanMethod)){onScan.detachFrom(document);}
}

selectMethodAction(optn) {
  this.scanText = (optn == 'methodOptn'? this.radytoScan: this.scanFilterInfo);
 
  this.scannerObj = this.scanMethod;
  this.initializeScanner();
 this.codescannerhandler.emit(this.scanMethod)
}

}