import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InventoryService } from '@cl/inventory/inventory.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import _ from 'lodash';
import { EntityService } from '@cl/common/services/entity-list.service';

@Component({
  selector: 'cl-inventory-summary',
  templateUrl: './inventory-summary.component.html',
  styleUrls: ['./inventory-summary.component.scss']
})
export class InventorySummaryComponent implements OnInit {
  @Input() inventoryId: any;
  @Input() inventoryObject: any;
  @Output() closeSidePanel = new EventEmitter();
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  activePane: string = 'summary';
  loading: boolean;
  inventoryCatalogCDMFields: any;
  data:any;
  customFields=["createdBy","createdAt","modifiedBy","modifiedAt"]
  constructor(private inventoryService: InventoryService, public datePipe: FormateDatePipe, private entityService: EntityService) { }

  ngOnInit(): void {
    if (this.inventoryId && this.inventoryObject != undefined) {
      this.loading = true;
      this.getExtendedAttributes('inventory');
    }
  }
  getExtendedAttributes(inventory) {
    this.entityService.getEntityCdmFields(inventory).subscribe((res: any) => {
      this.data = res?.cdmFields;
      this.inventoryCatalogCDMFields=this.entityService.orderCdmFields(this.data)
      this.customFields.forEach(element => {
        const removeIndex = this.inventoryCatalogCDMFields.findIndex( item => (item.name === element));
        this.inventoryCatalogCDMFields.splice( removeIndex, 1 );  
      }); 
    });
  }
  closePanel() {
    this.closeSidePanel.emit();
  }
  getValue(x) {
    var z = this.inventoryObject.x;
  }
  getInventoryValue(cdmField, properties) {
    if (cdmField.displayable) {
      if (this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1) {
        if (cdmField?.type?.toUpperCase() === 'DATE') {
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        } else {
          return properties[cdmField.name];
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

}
