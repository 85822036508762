<div class="mt-2 mb-2">
  <span class="page-title">
    Simulate <span class="bgcolor4 text-white p-0.5 px-1 rounded-full text-[10px]">Beta</span></span>
</div>
<div class="sensor-febric wt-ngx-grid-wrapper list-page-wrapper relative list-page" style="background: none;">
  <div class="flex justify-between pt-1 pb-1">
    <div class="action-panel-left self-start flex items-center color4 grow">
      <form novalidate>
        <cl-list-search [placeholder]="'Search by Name or ID'" [columns]="['Asset Name', 'Asset ID']"></cl-list-search>
      </form>
      <button [disabled]="simulateDisabled" (click)="goToSimulateDetails()"
        [ngClass]="[simulateDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
        class="p-4-10 border-general br2 ml10">
        <i class="fa fa-arrow-right"></i>
        Simulate
      </button>
    </div>
    <div class="action-panel-right self-end asset-listing__action-panel-right flex grow justify-end items-center mr-16">
    </div>
    <div class="md-add-button list add-shipment-btn" style="z-index: 999">
    </div>
  </div>
  <div class="h-calc-40">
    <div>
      <mat-accordion multi>
        <mat-expansion-panel [expanded]="true" *ngFor="let item of catalogsTypeList;let indexOfelement=index;"
          [expanded]="item.open">
          <mat-expansion-panel-header (click)="expandTab(indexOfelement)"
            class="tenant-setting-expansion-header panelheader">
            <mat-panel-title>
              <div class="font-bold color-555">{{item.type}} ({{item.count}})</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="">
            <main class="flex flex-row h-100 overflow-y-hidden">
              <div class="grid-container grow relative">
                <div *ngIf="isLoading">
                  <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div class="h-100" [ngClass]="{'hide-scroll': md?.loading}" *ngIf="item.open==true">
                  <ngx-datatable  #simulationList class="h-calc-100" [rows]="rows" [columns]="columns"
                  [loadingIndicator]="md?.loading" [columnMode]="'force'" [loadingIndicator]="isLoading" [headerHeight]="headerHeight"
                    [rowHeight]="rowHeight" [scrollbarV]="true" [scrollbarH]="true" [selected]="selected"
                    [sortType]="sortType" [selectionType]="selection" [rowIdentity]="getId"
                    [selectAllRowsOnPage]="false" (scroll)="onScroll($event.offsetY)" (select)='onSelect($event)'[reorderable]="false"
                    #table>
                  </ngx-datatable>
                  <div class="flex flex-row items-center justify-center load-more">
                    <button class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
                      [ngClass]="{'bcolor9': isLoading || allLoaded}" (click)="getLoadMoreUpdate()"
                      [disabled]="isLoading || allLoaded">
                      <span class="mat-button__ripple"></span>
                      <span class="mat-button__label color4" [ngClass]="{'color9': isLoading || allLoaded}">load next
                        50</span>
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<ng-template #dateTemplate let-row="row" let-value="value">
  {{value | formateDate:'default'}}
</ng-template>
<ng-template #simuNameTemplate let-row="row" let-value="value" let-i="index">
  <a class="font-12-bold" href>{{value}}</a>
</ng-template>