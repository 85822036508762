import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AssetsStateService } from '@cl/asset/asset-state.service';
import { ASSET_ACTIONS } from '@cl/@types/asset-state.type';

@Component({
  selector: 'cl-assets-filter',
  templateUrl: './assets-filter.component.html',
  styleUrls: ['./assets-filter.component.scss']
})
export class AssetsFilterComponent implements OnInit, OnDestroy {
  @Output() closeFilterPanel = new EventEmitter();

  filters: any[] = [];
  actionCount: number = 0;

  assetStateSubject: Subscription;
  constructor(private assetState: AssetsStateService) { }

  ngOnInit(): void {
    this.watchMdList();
  }

  toggleFilterPanel() {
    this.closeFilterPanel.emit();
  }

  watchMdList() {
    this.assetStateSubject = this.assetState.assetStateObservable.subscribe(state => {
      if(state.currentAction == ASSET_ACTIONS.CLEAR_FILTERS || state.currentAction == ASSET_ACTIONS.INIT_FILTERS){
        this.filters = [...state.filterSet];
        this.actionCount++
      }
    })
  }

  getPanelUpdate(message: any) { 
    if (message.action === 'filterByType') {
      this.assetState.onToggleFilter(message.name)
    } else if (message.action === 'filterByRange') {
      this.assetState.onToggleFilter(message.name, message.range)
    } else if (message.action === 'clearFilters') {
      // this.store.dispatch(new MalClearFilters())
      this.assetState.clearFilters();
    }
  }

  ngOnDestroy(): void {
    this.assetStateSubject.unsubscribe()
  }
}
