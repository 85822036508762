import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'cl-html-tooltip',
  templateUrl: './html-tooltip.component.html',
  styleUrls: ['./html-tooltip.component.scss'],
})
export class HtmlTooltipComponent implements AfterViewInit {
  @ViewChild('popperElement') popperElement: ElementRef;
  @Input() position='right';
  private screenWidth: number;
  public tooltipMaxWidth = 500;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  constructor() {}
   
  ngAfterViewInit(): void {
    this.screenWidth = window.innerWidth;

    const { left, width } =
      this.popperElement.nativeElement.getBoundingClientRect();

    const availableSpace = this.screenWidth - left - 40;

    if (width < availableSpace && width < this.tooltipMaxWidth) {
      this.popperElement.nativeElement.style.width = 'max-content';
    } else if (this.tooltipMaxWidth > availableSpace) {
      this.popperElement.nativeElement.style.width = availableSpace + 'px';
    } else {
      this.popperElement.nativeElement.style.width =
        this.tooltipMaxWidth + 'px';
    }
  }
}
