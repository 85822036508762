<div class="user-summary-panel policy-side-panel">
    <cl-side-panel (closePanel) = "closePanel()">
        <section class="cl-side-panel-title side-panel-header-bg">
            <span *ngIf="mode === 'add'" class="black font-bold">Add New Group</span>
            <span *ngIf="mode === 'edit'" class="black font-bold">Edit Group</span>
            <span class="close-icon" (click)="closePanel()">
                <i class="fa fa-times-circle-o mr-4"></i>
            </span>
        </section>
        <section class="cl-side-panel-content">
            <form [formGroup]="groupForm" *ngIf="mode === 'edit' || mode === 'add'">
                <div class="side-pane-form">
                    <div class="side-pane-form__item">
                        <div class="form-label">Name<span class="required">*</span></div>
                        <input type="text" class="basic-input"
                        placeholder="Enter Name" formControlName="userGroupName" [ngClass]='{"disabled": mode === "edit"}'>
                    </div>

                    <div class="side-pane-form__item">
                        <div class="form-label">Description <span class="required">*</span></div>
                        <input type="text" class="basic-input"
                        placeholder="Enter Description " formControlName="description">
                    </div>

                    <div class="side-pane-form__item">
                        <div class="form-label">Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog></div>
                        <div class="relative">
                            <mat-select
                              class="basic-input org-search"
                              placeholder="----Select Organization----"
                              formControlName="organization"
                            >
                              <ng-container>
                                <mat-option value ="" *ngIf="userType">----Select Organization----</mat-option>
                                <mat-option  *ngIf="!isOrgRestrictedUser" [value]="'ANY'" [textContent]="'(Any)'" ></mat-option>
                                <mat-option *ngFor="let org of orgList"
                                [value]="org.id">{{org.name}}</mat-option>
                              </ng-container>
                            </mat-select>
                            <mat-icon  *ngIf="groupForm.value.organization && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
                          </div>
                    </div>
                    <p *ngIf="groupForm.value.organization=='ANY'" class="orgcontent"><span>Warning</span>: Data will be seen by all users including those outside your org</p>
                </div>
            </form>

            <div class="form-label" *ngIf="mode === 'edit'"><span class="title">Add User</span>
                <span *ngIf="!editUserEnable" class="add-user-button add-button enable-add-user-button" (click)="editUserEnable = true">
                    <span class="fa fa-plus"></span>
                  </span>

                  <span *ngIf="editUserEnable" class="hide-add-user-button" (click)="editUserEnable = false">
                    <span class="fa fa-times-circle-o"></span>
                  </span>
            </div>

            <div class="add-user-container" *ngIf="mode === 'edit' && editUserEnable">
                <div class="side-pane-form__item">

                    <mat-select  [(ngModel)] = "currentUser">
                        <mat-option *ngFor="let user of allUsersForSelect;" [value]="user">{{user.name}} ({{user.id}})</mat-option>
                    </mat-select>
                </div>
                <button class="button mat-button dark md-button add-user-button" (click)="addUser()">Add</button>
            </div>

            <div class="existing-user-container" *ngIf="mode === 'edit' && existingUsers.length>0">
                <ul class="existing-users"> <span class="existing-user-title title">Existing Users</span>
                    <li class="existing-users__item" *ngFor="let user of existingUsers"><span class="user-name">{{user.name}} ({{user.id}})</span>
                        <span class="delete-button" (click)="deleteUser(user)">
                            <i class="fa fa-solid color4  fa-trash" *ngIf="!getDeleteUserStatus(user)"></i>
                            <i class="fa fa-circle-o-notch fa-pulse color4" *ngIf="getDeleteUserStatus(user)"></i>
                          </span>
                    </li>
                </ul>
            </div>

            <div class="group-details" *ngIf="mode === 'view'">
                <div class="side-pane-form__item">
                    <div class="form-label">Name</div>
                    <div>{{groupObject.userGroupName}}</div>

                </div>

                <div class="side-pane-form__item">
                    <div class="form-label">Description </div>
                    <div>{{groupObject.description}}</div>
                </div>
                <div class="side-pane-form__item">
                    <div class="form-label">Members </div>
                    <div class="user-grid font-12-normal" *ngFor="let user of existingUsers">{{user.name}} ({{user.id}})</div>
                </div>
                <div class="side-pane-form__item">
                    <div class="form-label">Organization </div>
                    <div>{{groupObject.organizationName}}</div>
                </div>
            </div>
        </section>

        <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
            <div class="flex">
                <div class="flex-col grow fs11 text-right" >
                    <a class="m-2" (click)="closePanel()">Cancel</a>
                    <button class="panel-button white bgcolor4" [disabled]="!groupForm.valid" (click)="saveGroup()" *ngIf="mode === 'add'">Save <span class="fa fa-circle-o-notch fa-pulse white" *ngIf="isLoading"></span></button>
                    <button class="panel-button white bgcolor4" [disabled]="!groupForm.valid" (click)="editGroup()" *ngIf="mode === 'edit'">Update <span class="fa fa-circle-o-notch fa-pulse white" *ngIf="isLoading"></span></button>
                </div>
            </div>
        </section>
    </cl-side-panel>
</div>

