import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Bisector, Entity, Rule, User } from '@cl/models';
import * as _ from 'lodash';

import * as G from '../../common/colors';
import { ConfigService } from './config.service';
import { GraphAPIService } from './graph-api.service';
import { CloudleafService } from './cloudleaf.service';
import { DataMethodsService } from './data-methods.service';
import { IconSvgService } from './icon-svg.service';
import { UtilsService } from '../utils/utils.service';

interface RegisteredDeviceTemplate {
  parentDeviceTemplateId: string;
  state: {
    properties: {
      lifecycleState: string;
      operationalState: string;
    };
  };
}
@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  private activeObj;
  private activeIndex = 0;
  private activeEntity;
  currentUser: User;
  currentInstance;
  dataLoaded = false;
  nameCounts: any = {};
  MAX_STACK_LENGTH = 20;
  _data = {
    allNodes: [],
    nodes: [],
    nodesObj: {},
    links: [],
    klData: [],
    bisectorsObj: {},
    groups: [],
    groupsObj: {},
    colors: [],
    activeObj: {},
    activeEntity: {},
    registryList: []
  };

  _stateStack: any[] = [];
  _stateList: any[] = [];

  _selectedState = 0;
  _stackIndex = 0;
  loadedTypes = {};
  baseUnit = 100;
  basePos = 100;
  nodeSize = 100;
  // private _deviceTemplates;

  templatesAdded = false;
  linkTemplatesAdded = false;

  token;
  headers;
  constructor(
    private http: HttpClient,
    private _iconSvgService: IconSvgService,
    private _configService: ConfigService,
    private _cloudleafService: CloudleafService,
    private _utilsService: UtilsService,
    private _dataMethodsService: DataMethodsService,
    private _graphAPIService: GraphAPIService
  ) {
    this.token = this._configService.getGraphAPIToken();
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
      "accept": "*/*",
      "token": this.token
    });
    this.currentInstance = JSON.parse(localStorage.getItem("currentInstance"));
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }


  initData(graphData) {
    this._data = graphData;
    if (graphData) {
      this.activeEntity = graphData.activeEntity;
    }
    return graphData;
  }

  initState(stateList, stateStack) {
    this._stateList = stateList;
    this._stateStack = stateStack;
    this._stackIndex = this._stateList.length - 1;
    this._selectedState = this._stateList[this._stackIndex]
      ? this._stateList[this._stackIndex].count
      : 0;
    return stateList;
  }

  loadGraphDataFromAPI() {
    const promise = new Promise((resolve, reject) => {
      console.log('this.dataLoaded?', this._data, this._data.allNodes);
      if (this._data.links && this._data.links.length > 0) {
        resolve(this._data);
      } else {
        this._graphAPIService.getAllGraphData().subscribe(res => {
          // console.log('got data', res);
          this.dataLoaded = true;
          const formattedData = this._cloudleafService.formatGraphData(res, this._data, {});
          this._data.allNodes = this.getAllNodes(formattedData.nodesObj);
          this._data = Object.assign({}, this._data, formattedData);
          this.filterComplimentaryLinks(this._data);
          // console.log('get all Nodes data', this._data);
          resolve(this._data);
        });
      }
    });
    return promise;
  }
  loadDataByType(type, refresh) {
    const promise = new Promise((resolve, reject) => {
      if (!refresh && this.loadedTypes[type]) {
        resolve({ listOfType: this.getNodeListOfType(type), graphData: this._data });
      } else {
        this._graphAPIService.getAllNodesByType(type).subscribe(res => {
          // console.log('getAllNodesByType', type, res);
          this.loadedTypes[type] = true;
          let vertices = res as unknown as any[] || [];
          const listRes = {
            location: vertices.map((vertice: Entity) =>
              new Entity().deserialize(vertice)
            )
          }

          resolve(listRes['location']);
        });
      }
    });
    return promise;
  }
  getNodeListOfType(type) {
    let nodeList = [];
    _.forOwn(this._data.nodesObj, (val, key) => {
      if (val['category'] === type) {
        nodeList.push(val);
      }
    });
    return nodeList;
  }
  getAllNodes(nodesObj) {
    let allNodes = [];
    // console.log('getAllLocNodes', nodesObj);
    _.forOwn(nodesObj, (val, key) => {
      // if (val.name && (val.template === 'location' || val.template === 'asset' || val.template === 'device')) {
      if (val.locus && val.locus.lat) {
        allNodes.push(val);
      }
    });
    return allNodes;
  }
  filterComplimentaryLinks(data) {
    let sourceNode, targetNode, deleteList = [], deletedMemo = {}, key1, key2;
    _.forOwn(data.bisectorsObj, (link, key) => {
      sourceNode = data.nodesObj[link.sourceID];
      targetNode = data.nodesObj[link.targetID];
      if (sourceNode && targetNode) {
        key1 = sourceNode.uid + '__' + targetNode.uid;
        key2 = targetNode.uid + '__' + sourceNode.uid;
        if (sourceNode.targetIDs[targetNode.uid] &&
          targetNode.targetIDs[sourceNode.uid] &&
          sourceNode.hierarchy >= targetNode.hierarchy
          && !deletedMemo[key1] && !deletedMemo[key2]
        ) {
          deleteList.push(link);
          deletedMemo[key1] = true;
          deletedMemo[key2] = true;
        }
      }
    });
    if (deleteList && deleteList.length) {
      deleteList.forEach(link => {
        this.deleteLink(link);
      });
    }
  }

  clearCanvas() {
    _.forOwn(this._data.nodesObj, (node, key) => {
      if (this._data.nodesObj[key]) {
        this._data.nodesObj[key].onCanvas = false;
      }
    });
    _.forOwn(this._data.groupsObj, (node, key) => {
      if (this._data.groupsObj[key]) {
        this._data.groupsObj[key].onCanvas = false;
      }
    });
    this._data.nodes = [];
    this._data.groups = [];
    this._data.links = [];
    this.initData(this._data);
    return this._data;
  }
  clearLastHovered(lastHoveredUIDs) {
    if (!this._data || !lastHoveredUIDs || !lastHoveredUIDs.length) {
      return this._data;
    }
    lastHoveredUIDs.forEach((uid, i) => {
      if (this._data.nodesObj[uid]
      ) {
        this._data.nodesObj[uid].hovered = false;
      } else if (this._data.groupsObj[uid]
      ) {
        this._data.groupsObj[uid].hovered = false;
      } else if (this._data.bisectorsObj[uid]
      ) {
        this._data.bisectorsObj[uid].hovered = false;
      }
    });
    this.updateNodeArray();
    return this._data;
  }
  clearAllHovered() {
    if (!this._data) {
      return
    }
    this._data.nodes.forEach((node, i) => {
      this._data.nodes[i].hovered = false;
      if (this._data.nodesObj[node.uid]) {
        this._data.nodesObj[node.uid].hovered = false;
      }
    });
    if (this._data.groups && this._data.groups.length) {
      this._data.groups.forEach((group, i) => {
        this._data.groups[i].hovered = false;
        this._data.groupsObj[group.uid].hovered = false;
      });
    }
    this._data.links.forEach((link, i) => {
      this._data.links[i].hovered = false;
      this._data.bisectorsObj[link.uid].hovered = false;
    });
    // console.log('clearAllHovered', this._data);
  }
  addFiltered(filteredNodes, replace) {
    _.forOwn(this._data.nodesObj, (node, key) => {
      if (filteredNodes[key]) {
        this._data.nodesObj[key].onCanvas = true;
      } else if (replace) {
        this._data.nodesObj[key].onCanvas = false;
      }
    });
    this.initData(this._data);
    return this._data;
  }
  pushState(actionType, entity, instance, actionCount, icon) {
    // this.clearAllHovered();
    instance = instance || this.currentInstance;
    let timestamp, id, newListItem;
    const count =
      this._stateList && this._stateList[this._stateList.length - 1]
        ? this._stateList[this._stateList.length - 1].count + 1
        : 1;
    timestamp = entity.nodeClass === 'incident' && entity.time ? entity.time : new Date();
    id = timestamp.valueOf();
    // this._data.colors = this._data.colors || [];
    // this._data.registryList = this._data.registryList || [];

    let occuredAt = entity.name;
    if (actionType === 'Alert') {
      let uid = entity.uid.split(':')[1];
      occuredAt = this._data.nodesObj[uid] ? this._data.nodesObj[uid].name : '';
    }
    newListItem = _.cloneDeep({
      id: id,
      count: count,
      timestamp: timestamp,
      actionType: actionType,
      nodeClass: entity.nodeClass,
      username: this.currentUser.username,
      occuredAt: occuredAt,
      entityName: entity.name,
      uid: entity.uid,
      icon: icon
    });

    this._stateList = [...this._stateList, newListItem];
    // this._stateStack = [...this._stateStack, newState];
    // while (this._stateList.length > this.MAX_STACK_LENGTH) {
    //   this._stateList.shift();
    // }
    this._selectedState = count;
    this._stackIndex = this._stateList.length - 1;
    // console.log('push', this._data, this._stateList, this._stateStack);

    // console.log('sizeof graphData', this.numberWithCommas(sizeof(this._data)) + " B");
    // console.log('sizeof newState', this.numberWithCommas(sizeof(newState)) + " B");
    // console.log('sizeof stack', this.numberWithCommas(stackSize) + " B");
    // // console.log('sizeof registry', this.numberWithCommas(sizeof(this._data.registryList)) + " B");
    // console.log('sizeof nodesObj', this.numberWithCommas(sizeof(this._data.nodesObj)) + " B");
    // console.log('sizeof nodes', this.numberWithCommas(sizeof(this._data.nodes)) + " B");

    return { selectedState: this._selectedState, stateList: this._stateList };
  }
  clearStateList() {
    this._stateList = [];
    this._stateStack = [];
  }
  filterStateList(searchTerm) {
    if (!searchTerm) {
      return this._stateList.slice();
    }
    let lTerm, stateList = this._stateList.filter(item => {
      lTerm = searchTerm.toLowerCase();
      return (item.entityName && item.entityName.toLowerCase().indexOf(lTerm) > -1) ? true : false;
    });
    stateList = stateList.concat(this.getSpacers());
    return stateList;
  }
  filterSearchList(fullList, searchTerm) {
    if (!searchTerm) {
      return fullList.slice();
    }
    let lTerm, searchList = fullList.filter(item => {
      lTerm = searchTerm.toLowerCase();
      return (item.name && item.name.toLowerCase().indexOf(lTerm) > -1) ? true : false;
    });
    return searchList.concat(this.getSpacers());
  }
  getSpacers() {
    let spacer = {
      nodeClass: 'spacer',
      eventType: 'spacer',
      template: 'spacer'
    }
    return [spacer, spacer, spacer, spacer];
  }
  countNodes(nodes) {
    let count = 0;
    if (nodes) {
      nodes.forEach(node => {
        if (!node.isDeleted) {
          count++;
        }
      });
    }
    return count;
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getStateList() {
    return this._stateList;
  }
  getStateStack() {
    return this._stateStack;
  }
  getStateItem(id) {
    let i; let stateObj;
    const newState = this._stateStack.filter((item, index) => {
      if (item.count === id) {
        i = index;
        return true;
      } else {
        return false;
      }
    })[0];
    if (newState) {
      this._selectedState = id;
      this._stackIndex = i;
      stateObj = { newState: _.cloneDeep(newState), stackIndex: i };
    }

    return stateObj;
  }
  stepBackward() {
    if (this._stackIndex > 1 && this._stateStack.length) {
      this._selectedState--;
      this._stackIndex--;
      return _.cloneDeep(this._stateStack[this._stackIndex]);
    }
  }
  stepForward() {
    if (
      this._selectedState < this._stateStack[this._stateStack.length - 1].count
    ) {
      this._selectedState++;
      this._stackIndex++;
      return _.cloneDeep(this._stateStack[this._stackIndex]);
    }
  }
  setOnCanvas(activeEntity) {
    if (!activeEntity) {
      return null;
    }
    if (!this._data.nodesObj[activeEntity.uid] || !this._data.nodesObj[activeEntity.uid].onCanvas) {
      activeEntity.x = Math.random() * 300 + 200;
      activeEntity.y = Math.random() * 300 + 200;
      activeEntity.onCanvas = true;
      this._data.nodesObj[activeEntity.uid] = activeEntity;
      this._data.nodes.push(activeEntity);
    }
    return [this._data, activeEntity];
  }
  createGroup(members, source, x, y) {
    const numGroups = _.size(this._data.groupsObj);
    const groupId = numGroups || 0;
    const groupUID = this._utilsService.getUUID();
    const groupNum = numGroups + 1;
    const updateObj = {};
    let totalX = 0,
      totalY = 0, segmentKey, segmentsObj = {};

    const colorNum = this.getColorNum();
    if (members.length) {
      members.forEach(member => {
        totalX += member.x;
        totalY += member.y;
        member.colorNum = colorNum;
        member.inGroup = true;
        member.groupNum = groupNum;
        member.groupUID = groupUID;
        this._data.nodesObj[member.uid] = Object.assign(
          {},
          this._data.nodesObj[member.uid],
          member
        );
        updateObj[member.uid] = member;
      });
      // console.log('members', members);
      // console.log('create update', updateObj);
      this.updateNodeArrayValues(updateObj);

      if (members.length === 1) {
        x = members[0].x + 100;
        y = members[0].y - 100;
      } else {
        x = totalX / members.length;
        y = totalY / members.length - 100;
        this.addLink({
          action: "addLink",
          sourceID: members[0].uid,
          targetID: members[1].uid
        });
        segmentKey = members[0].uid + '__' + members[1].uid;
        segmentsObj[segmentKey] = 1;
      }
    }
    x = x || 100;
    y = y || 100;
    const newGroup = {
      id: groupId,
      uid: groupUID,
      type: "group",
      name: "Route " + groupNum,
      groupNum: groupNum,
      colorNum: colorNum,
      members: members,
      segmentsObj: segmentsObj,
      onCanvas: true,
      needsEdit: true,
      isCollapsed: false,
      isDeleted: false,
      x: x,
      y: y
    };

    // const deviceTemplate = _.cloneDeep(this._deviceTemplates["group"]);
    // console.log('newGroup', newGroup);
    // this._data.groups.push(_.merge(deviceTemplate, newGroup));
    this._data.groupsObj[groupUID] = newGroup;
    return groupUID;
  }
  addGroupSegment(o, positionMode) {
    let segmentKey = o.sourceID + '__' + o.targetID
    this._data.groupsObj[o.group.uid].segmentsObj[segmentKey] = o.index;
    return this.setGroupList(positionMode);
  }
  addRuleToNode(node, rule, o) {
    // console.log('addRuleToNode', node, rule);
    if (!o.fromSidebar) {
      this.addLink({
        action: "addLink",
        sourceID: rule.uid,
        targetID: node.uid
      });
    }
    // node.rules[rule.uid] = rule;
    if (this.incompatibleTypes(node, rule)) {
      console.warn('cant add rule to node', node, rule)
      return this._data;

    } else {
      node = this.addAttribute(rule.name, "Rules", rule, node);
      this._data.nodesObj[node.uid] = node;
      const thisNode = this._data.nodesObj[node.uid];
      if (this._data.nodesObj[rule.uid].mappedNodes) {
        this._data.nodesObj[rule.uid].mappedNodes[node.uid] = true;
      }

      // thisNode.rules[rule.uid] = rule;
      this.updateNodeArrayItem(thisNode);
      return this._data;
    }
  }
  incompatibleTypes(node, rule) {
    console.warn('incompatibleTypes?', node, rule)
    if ((node.type === 'link' && rule.ruleType !== 'link') ||
      (node.type === 'node' && rule.ruleType === 'link')) {
      return true;
    } else {
      return false;
    }
  }
  addRuleToLink(link, rule, o) {
    // console.log('addRuleToLink', link, rule, o);
    if (this.incompatibleTypes(link, rule)) {
      console.warn('cant add rule to link', link, rule)
      return this._data;

    } else {
      // if (!this.ruleExists(link, rule)) {
      link = this.addAttribute(rule.name, "Rules", rule, link);
      // link.rules.push(rule);
      this._data.bisectorsObj[link.uid] = link;
      this._data.nodesObj[rule.uid].mappedLinks[link.uid] = true;

      const thisLink = this._data.bisectorsObj[link.uid];
      console.log('added?', thisLink);
      this.updateBisectorArray(thisLink);
      // }
      return this._data;
    }
  }
  ruleExists(el, rule) {
    let found = false;
    if (el.rules && el.rules.length) {
      el.rules.forEach(r => {
        if (r.uid === rule.uid) {
          found = true;
        }
      });
    }
    return found;
  }
  getColorNum() {
    const NUMCOLORS = 9;
    this._data.colors = this._data.colors || [];
    let colors = this._data.colors,
      newColor = Math.ceil(Math.random() * NUMCOLORS);
    let count = NUMCOLORS;
    while (colors.indexOf(newColor) > -1 && count--) {
      newColor = Math.ceil(Math.random() * NUMCOLORS);
    }
    this._data.colors.push(newColor);
    return newColor;
  }
  selectGroup(groupUID) {
    // const id = group - 1;
    this.setActiveEntityUID(groupUID, "group");

    return this._data;
  }
  addNodeToGroup(groupUID, groupNum, nodeUID) {
    const thisNode = this._data.nodesObj[nodeUID];
    // let sourceUID;
    // if (activeEntity.type === 'group') {
    //   sourceUID = this._data.groupsObj[groupUID].members
    // }
    this._data.groupsObj[groupUID].members.push(thisNode);
    this.updateGroupArray(this._data.groupsObj[groupUID]);
    thisNode.inGroup = true;
    thisNode.groupUID = groupUID;
    thisNode.groupNum = groupNum;
    thisNode.colorNum = this._data.groupsObj[groupUID].colorNum;
    this.updateNodeArrayItem(thisNode);

    const members = this._data.groupsObj[groupUID].members;

    let sourceID;
    if (members && members.length) {
      sourceID = members[members.length - 2] ? members[members.length - 2].uid : members[0].uid;
    } else {
      console.warn('node not added', members);
      return [thisNode, this._data];
    }
    const segmentUID = this.getSegmentKey(sourceID, thisNode.uid);
    this._data.groupsObj[groupUID].segmentsObj[segmentUID] = members.length - 1;
    // console.log('segmentsObj', this._data.groupsObj[groupUID].segmentsObj);
    if (sourceID !== thisNode.uid) {
      this.addLink({
        action: "addLink",
        sourceID: sourceID,
        targetID: thisNode.uid,
        addedForRoute: true
      });
    }

    return [thisNode, this._data];
  }

  getSegmentKey(sourceUID, targetUID) {
    return sourceUID + '__' + targetUID;
  }
  toggleGroup(groupNum) {
    const id = groupNum - 1;
    if (this._data.groups[id]) {
      this._data.groups[id].isCollapsed = !this._data.groups[id].isCollapsed;
    }
  }

  clearAllNodeAnims() {
    for (const node of Object.keys(this._data.nodesObj)) {
      delete this._data.nodesObj[node].preAnim;
      // delete this._data.nodesObj[node].postAnim;
    }
  }


  deleteGroup(group) {
    if (!group) {
      return {
        groups: this._data.groups,
        groupsObj: this._data.groupsObj
      }
    }
    if (group.members && group.members.length) {
      group.members.forEach(node => {
        if (this._data.nodesObj[node.uid]) {
          this._data.nodesObj[node.uid].inGroup = false;
          this._data.nodesObj[node.uid].groupNum = -1;
          this._data.nodesObj[node.uid].groupUID = "";
        }
      });
    }
    delete this._data.groupsObj[group.uid];

    this._data.groups = this._data.groups.filter(g => {
      return g.uid !== group.uid;
    });
    return {
      groups: this._data.groups,
      groupsObj: this._data.groupsObj
    };
  }
  removeMemberFromGroup(sentMember, group, positionMode) {
    let callBackActions = [];

    // console.log('sentMember', sentMember, group);
    if (group && this._data.groupsObj[group.uid]) {
      const len = this._data.groupsObj[group.uid].members.length - 1;
      this._data.groupsObj[group.uid].members = group.members.filter((member, i) => {
        if (sentMember.uid === member.uid && i < len && group.members[i - 1] && group.members[i + 1]) {
          // console.log('mid removed len, i', len, i);
          if (group.members[i - 1].uid !== group.members[i + 1].uid) {
            callBackActions.push({
              action: "addLink",
              sourceID: group.members[i - 1].uid,
              targetID: group.members[i + 1].uid,
              addedForRoute: true
            });
            callBackActions.push({
              action: "addGroupSegment",
              sourceID: group.members[i - 1].uid,
              targetID: group.members[i + 1].uid,
              group: group,
              index: i
            })
          }
        }
        return sentMember.uid !== member.uid;
      });
      // console.log('members', this._data.groupsObj[group.uid].members);
      _.forOwn(this._data.groupsObj[group.uid].segmentsObj, (val, key) => {
        if (key.indexOf(sentMember.uid) > -1) {
          delete this._data.groupsObj[group.uid].segmentsObj[key];
          if (this._data.bisectorsObj[key] && this._data.bisectorsObj[key].addedForRoute) {
            this.deleteBinode(this._data.bisectorsObj[key]);
          }
        }
      })
      // this._data.groups.forEach( (g, i) => {
      //   if (g.uid === group) {
      //     this._data.groups[i].members = this._data.groupsObj[group.uid].members;
      //   }
      // })
      // console.log('removeMemberFromGroup', group, this._data.groupsObj);

      this.setGroupList(positionMode);
      this._data.activeEntity = this._data.groupsObj[group.uid];
    }
    return { graphData: this._data, callBackActions: callBackActions };
  }
  setNodeList(positionMode) {
    this._data.nodes = [];
    _.forOwn(this._data.nodesObj, (node) => {
      if ((node && node['onCanvas'] && positionMode === 'canvas') ||
        // (positionMode === 'treeView' && node.inTraversal) ||
        positionMode === 'list') {
        this._data.nodes.push(node);
      }
    });
    return this._data.nodes;
  }
  setLinksList(o) {
    this._data.links = [];
    _.forOwn(this._data.bisectorsObj, (link) => {
      let sourceID = link['sourceID'], targetID = link['targetID']
      if (o.addAll || (this._data.nodesObj[sourceID] && this._data.nodesObj[sourceID]['onCanvas'] &&
        this._data.nodesObj[targetID] && this._data.nodesObj[targetID]['onCanvas'])) {
        this._data.links.push(link);
      }
    });
    return this._data.links;
  }
  setGroupList(positionMode) {
    this._data.groups = [];
    _.forOwn(this._data.groupsObj, (group) => {
      if ((positionMode === 'canvas' && group && group['onCanvas']) ||
        // (positionMode === 'treeView' && group.inTraversal) ||
        positionMode === 'list') {
        this._data.groups.push(group);
      }
    });
    return this._data.groups;
  }
  updateRemovedGroupMembers(group) {
    if (!group) {
      return this._data.nodes;
    }
    return this._data.nodes.map(node => {
      const nodeInGroup = group.members.filter(member => {
        return member.groupNum === node.groupNum;
      });
      if (node && nodeInGroup.length > 0) {
        node.inGroup = false;
        node.groupNum = -1;
        node.groupUID = "";
        node.colorNum = -1;
      }
      return node;
    });
  }
  updateRemovedGroupMember(sentMember, group, positionMode) {
    let node = this._data.nodesObj[sentMember.uid];
    if (
      node &&
      group &&
      node.groupUID === group.uid
    ) {
      node.inGroup = false;
      node.groupUID = "";
      node.groupNum = -1;
      node.colorNum = -1;
    }
    this._data.nodesObj[sentMember.uid] = node;
    return this.setNodeList(positionMode);
  }
  getActiveObj() {
    return this.activeObj;
  }
  getActiveEntity() {
    return Object.assign({}, this.activeEntity);
  }
  getGraphData() {
    return this._data;
  }

  deselectActiveEntity() {
    this.activeObj = {};
    this.activeEntity = {};
  }

  selectNext() {
    if (!this.activeEntity) {
      if (this._data.nodes && this._data.nodes.length) {
        this.setActiveEntityUID(this._data.nodes[0].uid, "node");
        this.activeIndex = 0;
      }
    } else if (this.activeEntity.inGroup) {
      const group = this._data.groupsObj[this.activeEntity.groupUID];

      let memberIndex = 0;
      if (group && group.members && group.members.length) {
        group.members.forEach((member, i) => {
          if (member.uid === this.activeEntity.uid) {
            memberIndex = i;
          }
        });
        if (group.members[memberIndex + 1]) {
          this.activeEntity = group.members[memberIndex + 1];
        } else {
          this.activeEntity = group.members[0];
        }
      }
    } else if (this._data.nodes[this.activeIndex + 1]) {
      this.activeEntity = this._data.nodes[this.activeIndex + 1];
      this.activeIndex++;
    } else {
      this.activeEntity = this._data.nodes[0];
      this.activeIndex = 0;
    }
    return this.activeEntity;
  }
  selectDown() {
    if (!this.activeEntity) {
      if (this._data.nodes && this._data.nodes.length) {
        this.setActiveEntityUID(this._data.nodes[0].uid, "node");
      }
    } else if (
      this.activeEntity.targetIDs &&
      Object.keys(this.activeEntity.targetIDs)[0]
    ) {
      this.setActiveEntityUID(
        Object.keys(this.activeEntity.targetIDs)[0],
        "node"
      );
    }
    return this.activeEntity;
  }
  selectPrevious() {
    if (!this.activeEntity) {
      if (this._data.nodes && this._data.nodes.length) {
        this.setActiveEntityUID(this._data.nodes[0].uid, "node");
        this.activeIndex = 0;
      }
    } else if (this.activeEntity.inGroup) {
      const group = this._data.groupsObj[this.activeEntity.groupUID];
      let memberIndex = 0;
      if (group && group.members && group.members.length) {
        group.members.forEach((member, i) => {
          if (member.uid === this.activeEntity.uid) {
            memberIndex = i;
          }
        });
        if (group.members[memberIndex - 1]) {
          this.activeEntity = group.members[memberIndex - 1];
        } else {
          this.activeEntity = group.members[group.members.length - 1];
        }
      }
    } else if (this._data.nodes[this.activeIndex - 1]) {
      this.activeEntity = this._data.nodes[this.activeIndex - 1];
      this.activeIndex--;
    } else {
      this.activeIndex = this._data.nodes.length - 1;
      this.activeEntity = this._data.nodes[this.activeIndex];
    }
    return this.activeEntity;
  }
  selectUp() {
    if (!this.activeEntity) {
      if (this._data.nodes && this._data.nodes.length) {
        this.setActiveEntityUID(this._data.nodes[0].uid, "node");
      }
    } else if (
      this.activeEntity.sourceIDs &&
      Object.keys(this.activeEntity.sourceIDs)[0]
    ) {
      // console.log(
      //   'Object.keys(this.activeEntity.sourceIDs)[0]',
      //   Object.keys(this.activeEntity.sourceIDs)[0]
      // );
      this.setActiveEntityUID(
        Object.keys(this.activeEntity.sourceIDs)[0],
        "node"
      );
    }
    return this.activeEntity;
  }

  setActiveEntityUID(uid, type) {
    [this._data, this.activeEntity] = this._dataMethodsService.setActiveEntityUID(uid, type, this._data, this.activeEntity);
    // this.moveActiveToTop(uid);
    return this.activeEntity;
  }


  getIndexFromID(arr, id) {
    let index = -1;
    arr.forEach((item, i) => {
      if (item.id === id) {
        index = i;
      }
    });
    return index;
  }
  deleteNode(node) {
    // console.log('deleteNode', node);
    const uid = node.uid;
    this._data.nodes = this._data.nodes.filter(n => {
      return n.uid !== uid;
    });
    this._data.links = this._data.links.filter(link => {
      return link.targetID !== uid && link.sourceID !== uid;
    });
    if (node.inGroup) {
      this._data.groups = this._data.groups.filter(group => {
        if (group.uid === node.groupUID) {
          group.members = group.members.filter(member => {
            return member.uid !== uid;
          });
          if (group.members.length) {
            this._data.groupsObj[node.groupUID].members = group.members;
          } else {
            delete this._data.groupsObj[node.groupUID];
            this._data.groups = [];
          }
        }
        // console.log("this._data.groups", this._data.groups);
        return group.members.length > 0;
      });
    }
    delete this._data.nodesObj[uid];
    return this._data;
  }

  deleteBinode(link) {
    this.deleteLink(link)
    return this.selectPrevious();
  }
  deleteLink(link: Bisector) {
    const uid = link.uid;
    _.forOwn(this._data.bisectorsObj, (link: Bisector, key) => {
      if (link.uid === uid) {
        delete this._data.bisectorsObj[link.uid];
        delete this._data.nodesObj[link.sourceID].targetIDs[link.targetID];
        delete this._data.nodesObj[link.targetID].sourceIDs[link.sourceID];
      }
    });
    this._data.links = this._data.links.filter(link => {
      return link.uid !== uid;
    });
  }
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  addNode(obj) {
    let entityObj, newName;
    this.setNameCounts(obj);
    const count = this.nameCounts[obj.template] || 0;
    const entName = obj.prefix || obj.name;
    // if (count < 1) {
    //   newName = 'New ' + this.capitalize(entName);
    // } else {
    //   newName = this.capitalize(entName) + ' ' + count;
    // }
    newName = '';
    if (!obj.x) {
      obj.x = 100 * count;
      obj.y = 500;
    }
    if (obj.type === "rule") {
      entityObj = new Rule().deserialize(obj);
    } else {
      entityObj = new Entity().deserialize(obj);
    }
    entityObj.nodeClass = this._cloudleafService.getNodeClass(entityObj, entityObj.template);
    entityObj.svgName = this._iconSvgService.getIcon2(entityObj.template, entityObj.nodeClass);

    // if (!excludeUID)
    // entityObj.uid = newUID;
    entityObj.name = newName;
    entityObj.hovered = false;
    entityObj.category = obj.template;
    entityObj.template = entityObj.template || obj.template;
    if (obj.profileAdd) {
      entityObj.onCanvas = false;
    } else {
      entityObj.onCanvas = true;
    }
    entityObj.canvasPosition = {
      x: obj.x,
      y: obj.y
    };
    if (obj.latLng) {
      entityObj.locus = {
        lat: obj.latLng.lat(),
        lon: obj.latLng.lng()
      }
    } else if (!obj.locus && obj.type === 'node') {
      entityObj.locus = this.getDefaultLocus(obj);
    }

    delete entityObj.numToAdd;
    if (!obj.profileAdd) {
      this._data.nodes.push(entityObj);
      this._data.nodes.sort(function (a, b) {
        return b.hierarchy - a.hierarchy;
      });
    }
    // this._data.nodesObj[newUID] = entityObj;
    return entityObj;
  }

  getDefaultLocus(obj) {
    const defaultPos = [37.76487, -122.41948];

    let lat = defaultPos[0] + obj.x / 10000;
    let lon = defaultPos[1] + obj.y / 10000;
    // console.log('latlan', lat, lng);
    return {
      lat: +lat.toPrecision(8),
      lon: +lon.toPrecision(8)
    };
  }

  setNameCounts(obj) {
    let valName;
    if (_.isEmpty(this.nameCounts)) {
      _.forOwn(this._data.nodesObj, (val, key) => {
        valName = val['template'];
        this.nameCounts[valName] = this.nameCounts[valName] + 1 || 1;
      });
    } else {
      valName = obj['template'];
      this.nameCounts[valName] = this.nameCounts[valName] + 1 || 1;
    }
  }
  incompatibleRule(linkObj) {
    let source = this._data.nodesObj[linkObj.sourceID];
    let node = this._data.nodesObj[linkObj.sourceID];
    let binode = this._data.bisectorsObj[linkObj.sourceID];
    return ((source.ruleType === 'link' && node) || (!source.ruleType && binode)) ? true : false;
  }
  addLink(linkObj) {
    const resultObj = this._dataMethodsService.addLink(linkObj, this._data);
    this._data = resultObj.graphData;
    // console.log('addLink', this._data);
    // this.createNewLink(linkObj);
    return resultObj;
  }
  // createNewLink(newLink) {
  //   let sourceObject = this._data.nodesObj[newLink.sourceID];
  //   newLink.type = sourceObject.template;
  //   newLink.classType = sourceObject.template;
  //   this._graphAPIService.createGraphLink(newLink).subscribe(res => {
  //     console.log('node  created?', res, newLink);
  //   })
  // }
  addGroupLink(obj) {
    const exists = this._data.links.filter(item => {
      return item.source === obj.start && item.target === obj.finish;
    });

    if (
      !exists.length ||
      (exists[0].isDeleted &&
        this._data.nodes[obj.start] &&
        this._data.nodes[obj.finish])
    ) {
      if (
        obj &&
        this._data.nodes[obj.start] &&
        this._data.nodes[obj.start].targets
      ) {
        this._data.nodes[obj.start].targets.push(obj.finish);
      }
      if (
        obj &&
        this._data.nodes[obj.finish] &&
        this._data.nodes[obj.finish].sources
      ) {
        this._data.nodes[obj.finish].sources.push(obj.start);
      }
      const sourceX = this._data.nodes[obj.start].x;
      const sourceY = this._data.nodes[obj.start].y;
      const sourceType = this._data.nodes[obj.start].type;
      const targetX = this._data.nodes[obj.finish].x;
      const targetY = this._data.nodes[obj.finish].y;
      const targetType = this._data.nodes[obj.finish].type;
      const templateType = sourceType + "-" + targetType + "-connection";
      const descriptionText: string =
        "This is a connection from a " + sourceType + " to a " + targetType;
      const bID: number = this._data.links.length;
      const bisector: any = {
        name: "connection",
        type: "link",
        template: templateType,
        description: descriptionText,
        id: bID,
        source: obj.start,
        sourceID: obj.startID,
        targetID: obj.finishID,
        sourceType: sourceType,
        targetType: targetType,
        isDeleted: false,
        x: (sourceX + targetX) / 2,
        y: (sourceY + targetY) / 2,
        dx: 0,
        dy: 0,
        midX: 0,
        midY: 0,
        active: false
      };

      // this._data.links.push(
      //   _.merge(this._deviceTemplates[templateType], bisector)
      // );

      return this._data.links;
    }
    return null;
  }
  getBisectors() {
    return this._data.links;
  }
  fixTags(tags) {
    if (typeof tags === "string") {
      tags = tags.replace(",", "").split(" ");
    }
    return tags;
  }
  updateGraphNode(message) {
    // console.log('updateEntity', message);
    if (!message || !message.form) {
      return null;
    }
    const entityForm = message.form;
    // const tags = entityForm.get("tags") ? this.fixTags(entityForm.get("tags").value) : "";
    const newObj = <Entity>{};
    if (entityForm.get("name").touched) {
      newObj.name = entityForm.get("name").value;
    };

    if (entityForm.get("lat") && entityForm.get("lat").touched) {
      newObj.locus = {}
      newObj.locus.lat = parseFloat(entityForm.get("lat").value);
      newObj.locus.lon = parseFloat(entityForm.get("lon").value);
    }
    newObj.needsEdit = false;
    newObj.uid = message.uid;

    // console.log('entityForm.get("streetAddr")', entityForm.get("streetAddr").value, entityForm.get("streetAddr").touched);
    if ((entityForm.get("streetAddr") ||
      entityForm.get("stateCode") ||
      entityForm.get("countryCode")) && (entityForm.get("streetAddr").value ||
        entityForm.get("city").value ||
        entityForm.get("stateCode").value ||
        entityForm.get("countryCode").value ||
        entityForm.get("postalCode").value)) {
      newObj.addressObj = {
        streetAddr: entityForm.get("streetAddr") ? entityForm.get("streetAddr").value : "",
        stateCode: entityForm.get("stateCode") ? entityForm.get("stateCode").value : "",
        city: entityForm.get("city") ? entityForm.get("city").value : "",
        countryCode: entityForm.get("countryCode") ? entityForm.get("countryCode").value : "",
        postalCode: entityForm.get("postalCode") ? entityForm.get("postalCode").value : "",
      }
    } else {
      newObj.addressObj = {};
      newObj.address = '';
    }

    const updatedObj = Object.assign({}, this._data.nodesObj[message.uid], newObj);
    // console.log('updatedObj', updatedObj);
    this._data.nodesObj[message.uid] = updatedObj;
    this.updateNodeArrayItem(updatedObj);
    return [this._data, updatedObj];
  }
  updateRule(message) {
    // console.log('updateRule', message);
    if (!message || !message.form) {
      return null;
    }
    const entityForm = message.form;
    // const tags = entityForm.get("tags") ? this.fixTags(entityForm.get("tags").value) : "";
    const newObj = <Rule>{};
    if (entityForm.get("name").touched) {
      newObj.name = entityForm.get("name").value;
    };

    if (entityForm.get("triggerThreshold")) {
      newObj.properties = {
        triggerToken: message.updatedNode.properties.triggerToken || message.updatedNode.subTemplate,
        thresholdToken: message.updatedNode.properties.thresholdToken,
        triggerType: message.updatedNode.triggerType,
        triggerThreshold: entityForm.get("triggerThreshold") ? entityForm.get("triggerThreshold").value : '',
        actionType: entityForm.get("actionType") ? entityForm.get("actionType").value : '',
        messageTemplate: entityForm.get("messageTemplate") ? entityForm.get("messageTemplate").value : '',
        subject: entityForm.get("subject") ? entityForm.get("subject").value : '',
        otherEmails: entityForm.get("otherEmails") ? entityForm.get("otherEmails").value : '',
        enabled: entityForm.get("enabled") ? entityForm.get("enabled").value : '',
        value: entityForm.get("value") ? entityForm.get("value").value : '',
        unit: entityForm.get("unit") ? entityForm.get("unit").value : '',
        // assetState: entityForm.get("assetState") ? entityForm.get("assetState").value : '',
        // fenceState: entityForm.get("fenceState") ? entityForm.get("fenceState").value : '',
        // shipmentState: entityForm.get("shipmentState") ? entityForm.get("shipmentState").value : '',
        // thresholdValue: entityForm.get("thresholdValue") ? entityForm.get("thresholdValue").value : '',
        // humidityThresholdValue: entityForm.get("humidityThresholdValue") ? entityForm.get("humidityThresholdValue").value : '',
        // temperatureThresholdValue: entityForm.get("temperatureThresholdValue") ? entityForm.get("temperatureThresholdValue").value : '',
        // thresholdDuration: entityForm.get("thresholdDuration") ? entityForm.get("thresholdDuration").value : '',
        // healthState: entityForm.get("healthState") ? entityForm.get("healthState").value : '',
        // tempUnit: entityForm.get("tempUnit") ? entityForm.get("tempUnit").value : '',
        // shockFlag: entityForm.get("shockFlag") ? entityForm.get("shockFlag").value : '',
        // waterLeakFlag: entityForm.get("waterLeakFlag") ? entityForm.get("waterLeakFlag").value : '',
        // violation: entityForm.get("violation") ? entityForm.get("violation").value : '',
        // matchDuration: entityForm.get("matchDuration") ? entityForm.get("matchDuration").value : '',
        // deviceType: entityForm.get("deviceType") ? entityForm.get("deviceType").value : '',
        // state: entityForm.get("state") ? entityForm.get("state").value : '',
      }
    }
    const updatedObj = Object.assign({}, this._data.nodesObj[message.uid], newObj);
    // console.log('updatedObj', updatedObj);
    this._data.nodesObj[message.uid] = updatedObj;
    this.updateNodeArrayItem(updatedObj);
    return [this._data, updatedObj];
  }
  updateNode(newObj) {
    if (!newObj || _.isEmpty(newObj)) {
      return this._data
    }
    const uid = newObj.uid;
    const updatedNode = Object.assign({}, this._data.nodesObj[uid], newObj);
    this._data.nodesObj[uid] = updatedNode;
    this.setActiveEntityUID(uid, "node");

    this.updateNodeArrayItem(updatedNode);
    return this._data;
  }

  updateLinkPath(nodeObj) {
    this._data.links.forEach((link, i) => {
      if (link.sourceID === nodeObj.uid) {
        this._data.links[i].path[0] = nodeObj.locus;
      } else if (link.targetID === nodeObj.uid) {
        this._data.links[i].path[1] = nodeObj.locus;
      }
      this._data.bisectorsObj[link.uid] = this._data.links[i];
    });
    return this._data;

  }
  updateNodeArrayItem(thisNode) {
    this._data.nodes.forEach((node, i) => {
      if (node.uid === thisNode.uid) {
        this._data.nodes[i] = Object.assign({}, node, thisNode);
      }
    });
  }
  updateNodeArrayValues(updateObj) {
    this._data.nodes.forEach((node, i) => {
      if (updateObj[node.uid]) {
        this._data.nodes[i] = Object.assign(
          {},
          this._data.nodes[i],
          updateObj[node.uid]
        );
      }
    });
  }
  clearOnCanvasFlags() {
    _.forOwn(this._data.nodesObj, (node, uid) => {
      this._data.nodesObj[uid].onCanvas = false;
    });
    return this._data;
  }
  updateNodeArray() {
    this._data.nodes.forEach((node, i) => {
      this._data.nodes[i] = Object.assign(
        {},
        this._data.nodes[i],
        this._data.nodesObj[node.uid]
      );
      if (this._data.nodesObj[node.uid]) {
        this._data.nodesObj[node.uid].onCanvas = true;
      }
    });
    return this._data;
  }
  updateGroup(group) {
    const updatedGroup = _.merge(this._data.groupsObj[group.uid], group);
    this.updateGroupArray(updatedGroup);
    this._data.groupsObj[group.uid] = updatedGroup;

    return this._data;
  }
  updateGroupArray(updatedGroup) {
    this._data.groups.forEach((group, i) => {
      if (group.uid === updatedGroup.uid) {
        this._data.groups[i] = Object.assign({}, updatedGroup);
      }
    });
  }
  updateBisector(message) {
    // console.log('updateBisector', message);
    const bisectorModel = message.model;
    const newObj = Object.assign(
      {},
      {
        name: bisectorModel.get("name") ? bisectorModel.get("name").value : "",
        description: bisectorModel.get("description")
          ? bisectorModel.get("description").value
          : ""
      }
    );
    const thisBisector = _.merge(this._data.bisectorsObj[message.uid], newObj);
    this._data.bisectorsObj[message.uid] = thisBisector;
    this.setActiveEntityUID(message.uid, "link");

    this.updateBisectorArray(thisBisector);
    return this._data.links;
  }
  updateLink(link) {
    // console.log('updateLink', link);
    const updatedLink = _.merge(this._data.bisectorsObj[link.uid], link);
    this.updateBisectorArray(updatedLink);
    this._data.bisectorsObj[link.uid] = updatedLink;

    return this._data;
  }
  updateBisectorArray(thisBisector) {
    this._data.links.forEach((bisector, i) => {
      if (bisector.bisectorID === thisBisector.bisectorID) {
        this._data.links[i] = Object.assign({}, thisBisector);
      }
    });
  }
  addEntityId(message) {
    const id = message.entity.id;
    const updatedNode = _.merge(this._data.nodes[id], { _id: message._id });
    this._data.nodesObj[updatedNode.uid] = updatedNode;
    this.updateNodeArrayItem(updatedNode);
    return this._data.nodes;
  }

  updateBinodes(mess) {
    const binode = mess.binode;
    if (binode) {
      this._data.bisectorsObj[binode.bisectorID] = Object.assign(
        {},
        this._data.bisectorsObj[binode.bisectorID],
        mess.binode
      );
      this.updateBisectorArray(this._data.bisectorsObj[binode.bisectorID]);
    }
  }

  addAttribute(name, type, model, node) {
    const entity = node || this.getActiveEntity();
    let added = false;
    let itemList = entity.sections;
    if (!itemList) {
      return false;
    }
    if (typeof model.tags === "string") {
      model.tags = model.tags.replace(",", " ").split(" ");
    }
    if (!this.containsSection(itemList, type)) {
      // If section not found dump into attributes section
      type = "attributes";
    }
    itemList = itemList.map((section, i) => {
      console.log('section', section);
      if (section.type.toLowerCase() === type.toLowerCase()) {
        if (!this.containsItem(section.items, name)) {
          // Don't add if already exists
          const len = section.items.push(_.merge({ name: name }, model));
          added = true;
        }
      }
      return section;
    });
    entity.sections = itemList;
    return entity;
  }
  editAttribute(type, model, obj) {
    const entity = this.getActiveEntity();

    let itemList = entity.sections.slice();
    // console.log(
    //   'app data editAttribute description',
    //   model.get('eventFields').value
    // );
    // console.log('entity', entity);
    if (!itemList) {
      console.log("No sections, nothing edited");
      return this._data.nodes;
    }
    const name = model.get("name") ? model.get("name").value : "";
    if (!name) {
      return this._data.nodes;
    }
    let tags = model.get("tags") ? model.get("tags").value : "",
      description = model.get("description")
        ? model.get("description").value
        : "",
      dataType = model.get("dataType") ? model.get("dataType").value : "",
      eventFields = model.get("eventFields")
        ? model.get("eventFields").value
        : "",
      isActive = model.get("isActive") ? model.get("isActive").value : "",
      acknowledgementRequired = model.get("acknowledgementRequired")
        ? model.get("acknowledgementRequired").value
        : "",
      commandArguments = model.get("commandArguments")
        ? model.get("commandArguments").value
        : "";
    if (typeof tags === "string") {
      tags = tags.replace(",", "").split(" ");
    }
    // console.log('editAttribute eventFields', eventFields);

    const newObj = {
      name: name,
      description: description,
      tags: tags,
      dataType: dataType,
      eventFields: eventFields,
      isActive: isActive,
      acknowledgementRequired: acknowledgementRequired,
      commandArguments: commandArguments
    };

    itemList = itemList.map(section => {
      if (section.type.toLowerCase() === type.toLowerCase()) {
        section.items = section.items.map(item => {
          if (item.name === obj.nameWas) {
            item = newObj;
          }
          return item;
        });
      }
      return section;
    });
    this._data.nodesObj[this.activeEntity.uid].sections = itemList;
    return this._data.nodes;
  }
  deleteAttribute(name, type) {
    const entity = this.getActiveEntity();
    let itemList = entity.sections.slice();

    itemList = itemList.map(section => {
      if (section.type.toLowerCase() === type.toLowerCase()) {
        //remove item where name = name
        section.items = section.items.filter(item => {
          return item.name !== name;
        });
      }
      return section;
    });
  }


  containsItem(arr, name) {
    const result = arr.filter(item => {
      return item.name && name.toLowerCase() === item.name.toLowerCase();
    });
    return result.length;
  }
  containsSection(arr, name) {
    const result = arr.filter(item => {
      return name.toLowerCase() === item.type.toLowerCase();
    });
    return result.length;
  }


  filterForKL(el, filteredNodes) {
    if (_.isEmpty(filteredNodes)) { // filter all
      return (!_.isEmpty(el.sourceIDs) || !_.isEmpty(el.targetIDs) ||
        (el.template === 'site' || el.template === 'organization')) ? true : false;
    } else { // tree filter
      return (filteredNodes[el.uid] && filteredNodes[el.uid] !== undefined) ? true : false;
    }
  }
  getDonut(el) {
    return {
      // the values for each donut segment
      v: [Math.random() * 200, Math.random() * 100, Math.random() * 40],
      // the colour for each donut segment
      c: [G.cloudleafGreen, G.cloudleafYellow, G.cloudleafRed],
      w: (_.size(el.targetIDs) + _.size(el.sourceIDs)),         // the width of the donut segments
      b: '#fff',    // the colour of the border between segments
      bw: 1          // the width of the border between segments
    }
  }
  setKLData(graphData, filteredNodes) {
    let allOrgTypes = {};
    let nodes = [];
    _.forOwn(graphData.nodesObj, (el, key) => {
      if (this.filterForKL(el, filteredNodes)) {
        let svgName = this._iconSvgService.getSVGName(el, "node");
        // console.log('svgIcon', svgIcon, el);
        allOrgTypes[el.category] = true;
        const locus = el.locus
          ? {
            lat: el.locus.lat,
            lng: el.locus.lon
          }
          : {};

        // let time = el.modifiedDate || el.createdDate;
        // time = time || "";
        // time = new Date(time).valueOf();

        // if (isNaN(time)) {
        //   time = isNaN(time) ? new Date().valueOf() : time;
        //   time -= Math.random() * 1000 * 60 * 60 * 24 * 30;
        //   time = Math.round(time);
        // }
        nodes.push({
          type: "node",
          id: el.uid,
          t: el.name,
          d: { category: el.category },
          u: "assets/svgs/circle-icons-blue/" + svgName + ".svg",
          // dt: [time],
          v: [100],
          pos: locus,
          donut: this.getDonut(el)
        })
      }
    });

    let links = [];
    _.forOwn(graphData.bisectorsObj, (link, key) => {
      if (_.isEmpty(filteredNodes) || (filteredNodes[link.sourceID] && filteredNodes[link.targetID])) {
        links.push({
          type: link.type,
          id: link.uid,
          id1: link.sourceID,
          id2: link.targetID,
          t: "",
          w: 3,
          c: G.cloudleafBlue
        })
      }
    });

    this._data.klData = nodes.concat(links);
    return this._data;
  }
}
