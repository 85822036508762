<div class="popover__wrapper" *ngIf="close">
    <div class="popover__content" [ngClass]="status ? 'display-content' : ''">
        <div class="pop">
            <div class="model-header">
                <span class="font-bold fs14 color-38 ng-binding">{{'logistics.rulesetShipments'|translate}}</span>
            </div>
            <div class="model-body">
                <span class="fs14 color-38 ng-binding">{{'logistics.ruleset'|translate}}</span><br>
                    <div class="spin-pos top-0" *ngIf="rulesLoading"><i class="fa fa-circle-o-notch fa-pulse fa-1x color4"></i></div>
                    <div class="dropdown-input">
                        <input [matAutocomplete]="carrier" placeholder="Select Rule Set" class="basic-input drp-search" type="search" (click)="matOpen()" [(ngModel)]="Rulename" (input)="searchKey()"/>
                    <mat-autocomplete #carrier="matAutocomplete"  (optionSelected)="changeRule($event.option.value)" (closed)="matClose()"
                    [displayWith]="displayFn" cdkScrollable>
                        <ng-container class="rulespopover">
                            <mat-option *ngFor="
                        let cr of rulesetdata
                          | search: Rulename
                      " [value]="cr" (click)="applyruleset()">{{ cr.name }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                    </div>
            </div>
            <div class="model-footer">
                <button class="color4 bcolor4 bgcolor32 create-button fs12 m10" href="javascript:void(0)"
                    (click)="closemodel()">{{'common.cancel'|translate}}</button>
                <button [disabled]="!loading" [ngClass]="[
                !loading
                    ? 'color37 bcolor36 bgcolor38'
                    : 'color4 bcolor4 bgcolor32'
                ]" class="create-button fs12 m10" href="javascript:void(0)" (click)="applyRule(Rulename)">{{'logistics.applyRuleset'|translate}}
                    </button>
            </div>
        </div>
    </div>
</div>