import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AuthorizeService } from '@cl/@authorize/authorize.service';
import { BulkAction, BulkEntity, BulkPanelModel } from '@cl/@types/bulk-action.type';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import _ from 'lodash';

@Component({
  selector: 'cl-bulk-action-menu',
  templateUrl: './bulk-action-menu.component.html',
  styleUrls: ['./bulk-action-menu.component.scss']
})
export class BulkActionMenuComponent implements OnInit, OnChanges {
  @Input() menuOptions: any;
  @Input() entity: string;
  @Output() bulkPanelObject = new EventEmitter;
  bulkAction: string;
  bulkObj: BulkPanelModel = {};
  title: string;
  icon: string;
  constructor(private bulkService: BulkActionsService,
    private _authService: AuthorizeService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (!_.isEmpty(this.menuOptions)) {
      this.menuOptions = this.menuOptions.filter((item) => {
        return this._authService.isValidAction(item.policies);
      })
    }
  }

  bulkActionHandler(bulkAction: string) {
    this.bulkAction = bulkAction;
    this.bulkObj.bulkAction = bulkAction;
    this.bulkObj.icon = this.bulkService.icon[this.entity];
    if (this.entity === BulkEntity.asset || this.entity === BulkEntity.sensor) {
      this.bulkObj.fileType = 'CSV';
      let titlePreset: string = this.entity === BulkEntity.asset ? 'Assets' : 'Sensors'
      this.setAssetBulkProperties(titlePreset);
    } else if (this.entity === BulkEntity.user) {
      this.bulkObj.fileType = 'Excel';
      this.setUserBulkProperties();
    }

    this.bulkPanelObject.emit(this.bulkObj);
  }

  setAssetBulkProperties(titleEntity: string) {
    this.bulkObj.appName = 'clfgraphapp';
    this.bulkObj.method = 'POST';

    if (this.bulkAction === BulkAction.bulkbind) {
      this.bulkObj.title = titleEntity + ' - Bulk Bind';
      this.bulkObj.uploadUrl = 'api/2/bulkDataJob/asset/bind/file';
      this.bulkObj.templateUrl = '';
    } else if (this.bulkAction === BulkAction.bulkunbind) {
      this.bulkObj.title = titleEntity + ' - Bulk Unbind';
      this.bulkObj.uploadUrl = 'api/2/bulkDataJob/asset/unbind/file';
      this.bulkObj.templateUrl = '';
    }
  }

  setUserBulkProperties() {
    this.bulkObj.uploadUrl = 'api/1/user/excel';
    this.bulkObj.appName = 'clfgraphapp';
    if (this.bulkAction === BulkAction.bulkadd) {
      this.bulkObj.title = 'Users - Bulk Add';
      this.bulkObj.templateUrl = 'Users_Bulk_Create_Template.xlsx';
      this.bulkObj.method = 'POST';
    } else if (this.bulkAction === BulkAction.bulkdelete) {
      this.bulkObj.title = 'Users - Bulk Delete';
      this.bulkObj.templateUrl = 'Users_Bulk_Delete_Template.xlsx';
      this.bulkObj.method = 'DELETE';
    }
  }

}
