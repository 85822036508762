import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { firstValueFrom } from 'rxjs';
import { UserService } from '@cl/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SimulateDataService {

  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService,
    private _userService: UserService,
  ) { }

  getSimulateTypeCounts() {
    const tenantId = this._userService.getTenantId();
   
    //return this.http.get(`https://test2-behaviorengine.parkoursc.com/api/v1/simulate/types/${tenantId}`);
    return this.http.get(`${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/types/${tenantId}`);
    
   
  }

  getSimulateTypeInstanceList(type,limit,offset) {
    const tenantId = this._userService.getTenantId();
    
    //return this.http.get(`https://test2-behaviorengine.parkoursc.com/api/v1/simulate/types/${tenantId}/${type}/instances?limit=${limit}&offset=${offset}`);
    return this.http.get(`${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/types/${tenantId}/${type}/instances?limit=${limit}&offset=${offset}`);
    
  }

  getSelectedInstanceData(id) {
    const tenantId = this._userService.getTenantId();
    
    //return this.http.get(`https://test2-behaviorengine.parkoursc.com/api/v1/simulate/types/${tenantId}/instance/${id}`);
    return this.http.get(`${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/types/${tenantId}/instance/${id}`);    
  }

  simulatePostAction(p_data) {
    
    const tenantId = this._userService.getTenantId();
    let serverUrl = p_data.apiUrl;
    let payLoad = p_data.payload;
    let header = {
                  'Content-Type': 'application/json'
                 }
    
    return this.http.post(serverUrl,payLoad,{ headers: header });
    
  }
  getSimulteRecipeList() {
    const tenantId = this._userService.getTenantId();
  
    //return this.http.get(`https://test2-behaviorengine.parkoursc.com/api/v1/simulate/types/${tenantId}/recipes`);
    return this.http.get(`${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/types/${tenantId}/recipes`);    
  }

  updateRecipefromSimulate(reqObj) {
    const tenantId = this._userService.getTenantId();
  
    //return this.http.post(`https://test2-model.parkoursc.com/api/v1/receipes`,reqObj)  
    return this.http.post(`${this.apiProvider.getAPIUrl('model')}api/v1/receipes`,reqObj)  
  }

  getCdmData(type) {
    return this.http.get(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/catalog/${type}`);    
  }

  
}
