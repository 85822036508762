import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { Observable, Subject,debounceTime, Subscription } from 'rxjs';
import { InventoryService } from './inventory.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { SearchApiService } from '@cl/common/services/search-api.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
import { EntityService } from '@cl/common/services/entity-list.service';
import { FilterGroup } from '@cl/models';
import { BulkActionComponent } from '@cl/common/components/bulk-action/bulk-action.component';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { ToastService } from '@cl/common/services/toast.service';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';

@Component({
  selector: 'cl-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit, AfterViewInit {
  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  @ViewChild('inventoryNameTemplate') inventoryNameTemplate: TemplateRef<any>;
  @ViewChild('hdrTpl') hdrTpl: TemplateRef<any>;
  @ViewChild('clearTemplate') clearTemplate: TemplateRef<any>;
  private filterSubject: Subject<string> = new Subject();
  public columnFilters = {};

  private event = new Subject<any>();
  roleObject:any ={};
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [{
    prop: 'selected',
    resizable: false,
    canAutoResize: false,
    sortable: false,
    name: '',
    width: 30,
    visible: true,
    headerCheckboxable: true,
    checkboxable: true,
    frozenLeft: true
  }];
  public selected: any = [];
  public selectedRows: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  public headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  systemColumns = []
  showingHits: number;
  totalHits: number;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  actionCount: number = 0;
  mainWidth: any;
  tableWidth: number;
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  allLoaded: boolean = false;
  scrollSize = 50;
  filters: any[] = [];
  initialFilters: any[] = [];
  public tableRowData: any = [];
  columnKeys: string[] = [];
  pageName: string = 'Inventory';
  mode: 'edit' | 'create' | 'summary' | '' = '';
  showSidePanel: boolean = false;
  editDisabled: boolean = true;
  deleteDisabled: boolean = true;
  selectedInventory: any;
  selectedInventoryId: any;
  private selectedFilters: any = {};
  showSummaryPanel=false;
  searchFieldFilter:any=[];

  actionBtnPolicies = {
    create: ['INVENTORY_CREATE'],
    edit: ['INVENTORY_UPDATE'],
    delete: ['INVENTORY_DELETE'],
  }

  private payLoadForInventoryList = {
    "scrollId":"",
    "scrollSize":this.scrollSize,
    "globalQueryText":"",
    "searchFieldFilter":[],
    // "searchQueries": CL_ES_LOCATION_DEFAULT_SEARCH_QUERY,
    "searchQueries":[
       {
          "fieldName":"parentEntityTypeKey",
          "queryText":"inventory",
          "queryOperator":"should",
          "queryType":"match",
          "childFilters":[]
       }
    ],
    "sortField":[

    ]
  };

  @ViewChild(BulkActionComponent, { static: false }) bulk:BulkActionComponent;
  showBulkAdd = false;

  bulkPanelObj: BulkPanelModel = {fileType:'CSV', appName:'clfgraphapp', method:'POST', uploadUrl:'api/2/inventory/bulk', icon:this.bulkService.icon['inventory'], title:'Bulk Add',isDynamicFile:true};
  inventoryColumns: any = [];
  inventoryCatalogCDMFields = [];
  filterOption: any;
  downloadedCSVFields:string[] = [];
  showFilter:boolean=false;

  constructor(private inventoryService: InventoryService,
  private _searchApiService: SearchApiService,
  private _utilsService: UtilsService,
  private _changeDetectorRef: ChangeDetectorRef,
  private formateDatePipe: FormateDatePipe,
  private csvExport: CsvExportsService,
  private el: ElementRef,
  private _snackBar: MatSnackBar,
  private _dialog: MatDialog,
  private entityService: EntityService,
  private _Service: InventoryService,
  private _toastService: ToastService,
  private bulkService: BulkActionsService, 
  private csvDownload: CsvExportsService, 
  private extendedAttributeService: ExtendedCatalogAttributeService,
  private persistenceService: PersistenceService,
  private _dynamicFormService: DynamicFormService) { }

  ngOnInit(): void {
    this.getInventoryTotalCount();
    this.filterSubject.pipe( debounceTime( 1000) ).subscribe( () => {
      this.filter();
    });
    this.getInventoryList();
    this.getFilters();
    this.setContainerWidth();
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.showSummaryPanel = true;
        this.roleObject = evt.roleObject;
      }
    });
    let filterObj = this.persistenceService.getFilterOption(this.pageName);
    if(!_.isEmpty(filterObj)){
      this.filterOption = JSON.parse(filterObj);
      if(this.filterOption.pageName == this.pageName){
        this.showFilterPanel = this.filterOption.filterPanel;
        this.setContainerWidth();
      }
    }
  }

  ngAfterViewInit(): void {
    this.getColumns();
  }

  getColumns(){
    this.entityService.getEntityCdmFields('inventory').subscribe((res: any) => {
      this.inventoryCatalogCDMFields = res?.cdmFields;
      let fields = this._dynamicFormService.getRenderableFields(
        this.inventoryCatalogCDMFields
      );
  
      fields = this._dynamicFormService.orderFields([...fields], 'order');
      this.downloadedCSVFields = fields.map(field => {
        let label = field.displayLabel;
        if(field.required){
          label += '*'
        }else if(field.displayLabel?.endsWith('*')){
          label += '*'
        }
        return `"${label}"`
      })

      this.rawColumns = this.entityService.formColumnList(res?.cdmFields, this.dateTemplate);
      const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('inventory', [...this.rawColumns]);
      this.rawColumns.forEach((element,index) => {
        this.rawColumns[1].cellTemplate=this.inventoryNameTemplate;
      });

      let self =this;
      this.rawColumns.forEach(function(item){
        let searchIndex = self.inventoryCatalogCDMFields.findIndex(
          (field) => field.name === item.prop &&  field.searchable == true
        );
        if(item.prop=="selected"){
          item.headerTemplate= self.clearTemplate;
        }
        if (searchIndex>-1) {
            item.headerTemplate= self.hdrTpl;
        }
      })

      this.rawColumns = [...rawColumns];
      this.columns = [...gridColumns];
      this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
      this.table?.recalculate();
      this._changeDetectorRef.detectChanges();
    })
  }

  getInventoryList(resetData: boolean = false){
    if(this.delayedRefreshStart) {
      this.delayedRefreshStart = false;
      this.tableRowData = [];
      this.payLoadForInventoryList.scrollId = null;
    }
    let data = this.payLoadForInventoryList;
    this.isLoading = true;
    const userPolicyListData: Subscription = this.entityService.globalEntitySearch('inventory',data, false).subscribe({
      next: (data: any) => {
      this.allLoaded = false;
      if (!this.payLoadForInventoryList.scrollId) {
        this.rows = [...[]];
      }
      this.payLoadForInventoryList.scrollId = data._scroll_id;
      if (resetData) {
        this.rows = [...data.hits];
      } else {
        this.rows = [...this.rows, ...data.hits];
      }
      this.rows.map((item)=>
      {
        item.status = item.status==='IN-ACTIVE'|| item.status==='INACTIVE' ? 'Inactive':item.status;
      })
      this.showingHits = this.rows.length;
      if (this.showingHits === this.totalHits || data.totalHits < this.scrollSize) {
        this.allLoaded = true;
      }
      this.tableRowData = [...this.rows];
      this.isLoading = false;
      if(this.roleObject.id && this.roleObject.classType){
        this.showSummaryPanel = true;
      }
    },
    error: (err) => {
        console.error(err);
        this.isLoading = false;
    }
  });


    this.subscriptions.push(userPolicyListData);
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
    this.showBulkAdd = false;
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 268;
    } else {
      this.tableWidth = this.mainWidth - 18;
    }
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.persistenceService.setFilterOption(this.pageName, this.showFilterPanel);
    this.setContainerWidth();
    setTimeout(() => {
      this.table?.recalculate();
    }, 10);
  }

  formateDownloadData(data:any[]){
    let tempAssetData = [...data];
    let dateFieldArray = ['modifiedAt', 'createdAt'];
    tempAssetData.forEach(loc => {
      dateFieldArray.forEach(element => {
        if(loc[element]){
          loc[element] = this.formateDatePipe.transform(loc[element],'default');
        }
      });
    });
    return tempAssetData;
  }

  downloadReport(reportType: string) {
    let downloadData = this.formateDownloadData(this.rows);
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(downloadData, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Inventory-list', _.map(this.columns, 'name'));
    }
    this.showBulkAdd = false;
  }

  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.allLoaded && !this.isLoading && (offsetY + viewHeight) >= this.rows.length * this.rowHeight) {
      this.getInventoryList();
    }
  }

  closeSummaryPanel(){
    this.showSummaryPanel = false;
    this.showBulkAdd = false;
    this.roleObject = {};
  }

  loadSummaryPanel(roleObject:any, event:any){
    event.preventDefault();

    this.setEvent({ 'eventName': 'summary', roleObject: roleObject });
  }
  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selectedRows);
    this.editDisabled = true;
    this.deleteDisabled = true;
    if(this.selected.length==1){
      this.editDisabled = false;
      let selectedRow = selectedRows[0];
      this.selectedInventory = selectedRow;
      if(rowData.selected[0].status.toLowerCase() !== 'active'){
        this.deleteDisabled = false;
     }else{
       this.deleteDisabled = true;
     }
    }else if(this.selected.length > 1){
      this.deleteDisabled = false;
      rowData.selected.forEach(sel => {
          if(!['draft'].includes(sel.status.toLowerCase())){
            this.deleteDisabled = true;
          }
      })
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    this.payLoadForInventoryList.scrollId = '';
    this.payLoadForInventoryList.globalQueryText = val;
    this.getInventoryList(true);
  }

  getId(row) {
    return row.id;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  createPayloadAndReloadInventoryList() {
    let childFilters = [];
    Object.keys(this.selectedFilters).forEach(key => {
      if (this.selectedFilters[key].length > 0) {
        childFilters.push(new Object({
          "fieldName": key,
          "queryText": this.selectedFilters[key].join(),
          "queryOperator": "must"
        }));
        }
    });
    this.payLoadForInventoryList.searchQueries[0].childFilters = childFilters;
    this.getInventoryList(true);
  }

  getPanelUpdate(event: any) {
    this.payLoadForInventoryList.scrollId = '';
    let filterEvent = event.filter;
    if (event.action === 'clearFilters') {
      this.selectedFilters = {};
      this.filters = _.cloneDeep(this.initialFilters);
    } else {
      if (filterEvent.checked) {
        if (!this.selectedFilters[filterEvent.filterGroupName]) {
          this.selectedFilters[filterEvent.filterGroupName] = [];
        }
        this.selectedFilters[filterEvent.filterGroupName].push(filterEvent.name);
      } else {
        let index = this.selectedFilters[filterEvent.filterGroupName].indexOf(filterEvent.name);
        this.selectedFilters[filterEvent.filterGroupName].splice(index, 1);
      }
    }
    this.createPayloadAndReloadInventoryList();
  }

  getLoadMoreUpdate() {
    this.getInventoryList();
  }

  private formFilters(data) {
    this.filters = data.map((entry: any) => {
      let filter: FilterGroup = {
        list: [],
        filterType: entry.labelKey,
        label: entry.label,
        type: "multiselect",
      };
      filter.list = entry.options.map(list => ({
        name: list.id,
          checked: !!false,
          count: list.count,
          displayLabel: list.name,
          label: list.name
      }));
      filter.enableCollapsing = filter.list.length > 10;
      filter.collapsed = filter.list.length > 10;
      filter.expand = true;
      return filter;
    })
    this.initialFilters = _.cloneDeep(this.filters);
  }

  private getFilters(): void {
    this.entityService.getEntityFiltersInventory('inventory').subscribe((data: any) => {
      this.formFilters(data);
    })
  }

  private getInventoryTotalCount() {
    const payload = {
      "scrollSize": 1,
      "searchQueries": [
        {
          "fieldName": "parentEntityTypeKey",
          "queryText": 'inventory',
          "queryOperator": "should",
          "queryType": "match",
          "childFilters": []
        }
      ]
    }
    const totalCountSub: Subscription = this._searchApiService.globalSearch(payload, false).subscribe({
      next: res => {
        this.totalHits = res.totalHits;
      },
      error: err => {
        console.log(err);
      }
    });
    this.subscriptions.push(totalCountSub);
  }

  closeBulkSidePanel() {
    this.showBulkAdd = false;
  }
  closeSidePanel(isReload:boolean = false) {
    this.showSidePanel = false;
    this.mode = '';
    if(isReload){
      this.delayedRefreshStart = true;
      // this.reloadInventoryList();
      this.deselectRows();
    }
  }
  openCreateSidePanel(isEdit:boolean){
    this.mode = isEdit ? 'edit' : 'create';
    this.showSidePanel = true;
    this.selectedInventoryId = this.selectedInventory?.id
  }
  deleteInventoryDialog(){
    this._dialog
    .open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        message: `Are you sure want to delete Inventory?`,
      },
    })
    .afterClosed()
    .subscribe((res) => {
      if (res && this.selectedInventory?.id) {
       this.deleteInventory();
      }
    });
  }

  public deselectRows() {
    this.selected = [];
    this.onSelect({ selected: [] });
  }

  async deleteInventory(){
    const deletedInventoryids = this.selected.map(item => item.id);
    try {
      await this._Service.deleteInventory({ids:deletedInventoryids});
      this.delayedRefreshStart = true;
      this.reloadInventoryList();
      this._toastService.success(`Inventory deleted successfully`);

    } catch (error) {

    }
    this.deselectRows();
  }

  reloadInventoryList() {
    // this.showBulkAdd = false;
    this.delayedRefreshStart = true;
    // this.getInventoryList();
  }

  showBulk() {
    this.showBulkAdd = true;
    setTimeout(()=> {
      this.bulk.downloadRef.nativeElement.addEventListener('click', this.downloadTemplate.bind(this));
    }, 100)

  }

  createSKUColumnsArray() {
    let obj = {};
    this.inventoryCatalogCDMFields.forEach(skuAttribute => {
      if(skuAttribute.instanceUserCreatable) {
        obj[skuAttribute.id] = '';
      }

    });
    this.inventoryColumns.push(obj);
  }
  downloadTemplate(event:any ) {
    event.preventDefault();
    this.createSKUColumnsArray();
    this.csvDownload.formatAndDownloadCSVForGrid(this.inventoryColumns, 'Inventory_Add_Template')
  }
  setEvent(state: any) {
    this.event.next(state);
  }
  getEvent(): Observable<any> {
    return this.event.asObservable();
  }
  filterInput( $event ) {
    this.filterSubject.next('');
  }

  closeFilter(){
    this.showFilter = false;
    this.headerHeight = 30;
    Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
    this.filter();
  }

  showHideFilter(){
    this.showFilter = !this.showFilter;
    if(this.showFilter){
      this.headerHeight = 65;
    }else{
      this.headerHeight = 30;
      Object.keys( this.columnFilters ).forEach(key => {this.columnFilters[key]=''})
      this.filter();
    }
    setTimeout(() => {
      this.table.recalculate();
    }, 10);
  }


  filter() {
    this.searchFieldFilter=[]
      Object.keys( this.columnFilters ).forEach(key => {
        this.searchFieldFilter.push({
          "name":key=="nameFormated"?"name":key,
          "value":this.columnFilters[key]
        })
      });
      this.payLoadForInventoryList.scrollId = '';
      this.payLoadForInventoryList.searchFieldFilter = this.searchFieldFilter;
      this.getInventoryList();
  }

}
