<div class="cards-visibility">
  <button (click)="toggleFullscreen()">
    <span class="anchor-link" *ngIf="p?.carousel">Hide cards<i class='fa fa-angle-down ml-1 fs18' aria-hidden="true"></i></span>
    <span class="anchor-link" *ngIf="!p?.carousel">Show Cards<i class='fa fa-angle-right ml-1 fs18' aria-hidden="true"></i></span>
  </button>
</div>

<app-navigator-carousel
  id="card-carousel"
  [class.show]="p?.carousel"
  (tenancyEmitter)="getCarouselUpdate($event)"
  [selectedDt]="selectedDt"
  [DTs]="navigatorState?.dts"
  [cards]="navigatorState?.dtCards">
</app-navigator-carousel>

<app-navigator-filters
  [style.transform]="'translate(0px, ' + p.filtersTop + 'px)'"
  class="header-filters"
  [navigatorConfig]="navigatorState?.navigatorConfig"
  [esSearchParams]="navigatorState?.filterEsQuery">
</app-navigator-filters>

<!-- <app-horizontal-filters [style.transform]="'translate(0px, ' + p.filtersTop + 'px)'" class="header-filters"
  [isMapMode]="lv.isMapMode">
</app-horizontal-filters> -->

<div *ngIf="loading || navigatorState?.loading.map || navigatorState?.loading?.network " class="loader-anim">
  <span [inlineSVG]="'/assets/svgs/loader.svg'"></span>
</div>
<div class="solution-container map-margin pos-abs" [style.height.px]="p?.mainHeight"
  [style.transform]="'translate3d(0px, ' + p?.mainTop  + 'px, 0px )'">
    <router-outlet></router-outlet>
</div>
