import * as _ from 'lodash';
import { Deserializable } from './deserializable';


export class Entity implements Deserializable {
  id: number;
  uid: string;
  name: string;
  nameCount: number;
  category: string;
  count: number;
  siteType: string;
  address: string;
  addressObj: any = {};
  geofence: any = {};
  geofenceType: string;
  properties: any = {};
  position: string;
  locus: { lat?: number; lng?: number; lon?: number } = {};
  description: string;
  type: string = "node";
  svgName: string;
  // orgType: string;
  nodeClass: string;
  baseType: string;
  graphType: string = "node";
  iconURL: string;
  template: string;
  exists = false;
  onCanvas = false;
  subTemplate: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  lastModified: Date;
  shock: boolean;
  humidity: boolean;
  temperature: string;
  active: boolean;
  needsEdit = true;
  sourceIDs: any = {};
  targetIDs: any = {};
  filterTypes: any = {};
  rules: any = {};
  x: number = 0;
  dx: number = 0;
  y: number = 0;
  dy: number = 0;
  radius = 8;
  canvasPosition: any = {};
  selectedPosition: any = {};
  inGroup: boolean;
  toggled: boolean = false;
  parentToggled: boolean;
  parentPos: any;
  hierarchy: number = 3;
  attributes: any = [];
  sections: any = [];
  preAnim: any = {};
  isDraggable: boolean = false;
  deserialize(input: any, nodeClass = "") {
    const node = this.configNode(input, nodeClass);
    Object.assign(this, node);
    return this;
  }

  configNode(inputObj: any, nodeClass = "") {
    let node = Object.assign({}, this);
    if (inputObj) {
      node = this.convertVertice(node, inputObj, nodeClass);
    }
    if (inputObj.preAnim && !isNaN(inputObj.preAnim.x) && !isNaN(inputObj.preAnim.y)) {
    }
    if (node.name) {
      const nameArr = node.name.split("-");
      if (nameArr[1] && Number.isInteger(+nameArr[1])) {
        node.nameCount = +nameArr[1];
      }
    }
    return node;
  }
  convertVertice(node: any, vertice: any, nodeClass = "") {
    node.uid = node.uid || vertice.id;

    if (vertice.clfMappingType === "USER") {
      node.uid = node.uid.replace("user_", "");
    }

    node.exists = true;
    if (vertice.baseType) {
      node.baseType = vertice.baseType.toLowerCase();
    }
    if (vertice.properties) {
      node.name = vertice.properties.name ? vertice.properties.name.split(":")[0] : vertice.properties.assetName;
      node.name = node.name || vertice.properties.areaName || vertice.properties.clfMappingType;
      if (!node.name) {
        // console.warn("no name?", vertice);
      }
      node.properties = vertice.properties;
      node.nodeClass = vertice.baseClass
        ? vertice.baseClass.toLowerCase()
        : vertice.clfMappingType
        ? vertice.clfMappingType.toLowerCase()
        : nodeClass;
      node.category = vertice.properties.type ? vertice.properties.type.replace("_", "") : node.template;
      node.count = vertice.properties.quantity;
      node.createdBy = vertice.properties.created_by;
      node.modifiedBy = vertice.properties.modified_by;
      node.modifiedDate = vertice.properties.modified_date;
      node.assetId = vertice.properties.externalId;
      node.sku = vertice.properties.sku;
      node.properties.status = vertice.properties.status;
      node.createdDate = vertice.createdDate || new Date();
      node.temperature = vertice.properties["37C7BB40-B06A-11E3-8000-B70F3AB862A4:37C7BBA1-B06A-11E3-8000-B70F3AB862A4"];
      node.shock = vertice.properties["Shock:rxShock"];
      node.humidity = vertice.properties["AA20:AA21"];
      node.addressObj = vertice.properties.addressObjStr ? JSON.parse(vertice.properties.addressObjStr) : {};
      if (vertice.properties.streetAddress || vertice.properties.city || vertice.properties.countryCode) {
        node.addressObj.streetAddress = vertice.properties.streetAddress;
        node.addressObj.city = vertice.properties.city;
        node.addressObj.countryCode = vertice.properties.countryCode;
      }
      node.address = typeof vertice.properties.address === "string" ? vertice.properties.address : this.getAddressString(node);

      if (vertice.properties.position1) {
        vertice.properties.position = vertice.properties.position1;
      }
      node.position = vertice.properties.position;
      node.locus = { ...this.getLocus(vertice) };
      node.geofence = this.getGeofence(vertice);
      node.template = vertice.properties.classType ? vertice.properties.classType.toLowerCase() : "site";
      node.svgName = this.getSvg(vertice);
    }

    node.needsEdit = false;

    return node;
  }
  getSvg(vertice: any) {
    let svg = vertice.properties.classType || vertice.properties.clfMappingType || vertice.properties.baseClass || vertice.baseType;
    if (!svg) {
      // console.warn("no svg! ", vertice);
      return "site";
    }
    return svg.toLowerCase();
  }
  getLocus(vertice: any) {
    let locus = {},
      latLon;

    if (vertice.properties.position && typeof vertice.properties.position === "string") {
      latLon = vertice.properties.position.split(",");

      locus = {
        lat: parseFloat(latLon[0]),
        lon: parseFloat(latLon[1]),
        lng: parseFloat(latLon[1]),
      };
    } else if (vertice.properties.position && vertice.properties.position.length) {
      latLon = vertice.properties.position;
      locus = {
        lat: parseFloat(latLon[0]),
        lon: parseFloat(latLon[1]),
        lng: parseFloat(latLon[1]),
      };
      vertice.properties.locusLat = latLon[0];
      vertice.properties.locusLng = latLon[1];
    } else if (vertice.properties.locusLat) {
      locus = {
        lat: +vertice.properties.locusLat,
        lon: +vertice.properties.locusLng,
        lng: +vertice.properties.locusLng,
      };
    } else if (vertice.properties.locus_lat && vertice.properties.locus_lat !== -360) {
      locus = {
        lat: +vertice.properties.locus_lat,
        lon: +vertice.properties.locus_lng,
        lng: +vertice.properties.locus_lng,
      };
    } else if (vertice.properties.coordinates && vertice.properties.coordinates.split(",").length === 2) {
      latLon = vertice.properties.coordinates.split(",");
      locus = {
        lat: parseFloat(latLon[0]),
        lon: parseFloat(latLon[1]),
        lng: parseFloat(latLon[1]),
      };
    } else if (vertice.properties.coordinates) {
      let coords;
      try {
        coords = JSON.parse(vertice.properties.coordinates);
      }
      catch(t) {
        console.warn('Parse failed', vertice);
        coords = JSON.parse(vertice.properties.coordinates.replaceAll(/\\/gi, ''));
      }
      if (coords.geometry && coords.geometry.coordinates && coords.geometry.type === "Point") {
        latLon = coords.geometry.coordinates;
        if (latLon[0] && latLon[1]) {
          locus = {
            lat: latLon[1],
            lon: latLon[0],
            lng: latLon[0],
          };
        }
      }
    }
    return locus;
  }
  getGeofence(vertice: any) {
    let geofence = {};
    if (vertice.properties.coordinates && typeof vertice.properties.coordinates === "string" && vertice.properties.coordinates[0] === "{") {
      try {
        geofence = JSON.parse(vertice.properties.coordinates);
      } catch (e) {
        console.warn("Parse failed", vertice, e); // error in the above string (in this case, yes)!
        geofence = JSON.parse(vertice.properties.coordinates.replaceAll(/\\/gi, ''));
      }
      if (geofence && (geofence as any)["locationRadius"]) {
        (geofence as any)["geofenceType"] = "circle";
      }
    }
    return geofence;
  }
  getAddressString(node: any) {
    if (!_.size(node.addressObj)) {
      return "";
    } else {
      let addy = node.addressObj.streetAddress ? node.addressObj.streetAddress + ", " : "";
      addy += node.addressObj.city ? node.addressObj.city : "";
      addy += node.addressObj.countryCode ? node.addressObj.countryCode : "";
      return addy;
    }
  }
}
