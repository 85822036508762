import { Component, OnInit } from '@angular/core';
import translationsData from '@cl/@data/translations.json'; 

@Component({
  selector: 'cl-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit {
  currentItem = 'translations';
  default_data=[];
  
  
  constructor() { }

  ngOnInit(): void {
    this.formatData(translationsData)
  }

  formatData(data){
    let formedDataArr = [];
    for(const section in data){
       let obj = {};
       obj['section'] = section;
       obj['keyprefix'] = section;
       obj['prefix1'] = 'dvp2';
       obj['prefix2'] = 'tenant.labels';
       obj['properties'] = [];
       for(const key in data[section]){
           obj['properties'].push({
            "key": `${key}`,
            "displayLabel": `${key}`,
            "description": `${key}`,
            "reset": false,
            "isallowDelete": false,
            "displayType": "text",
            "value": data[section][key]
           })

       }
       formedDataArr.push(obj);
       
    }
    this.default_data = formedDataArr;
    console.log("final data",this.default_data);
    

  }

}
