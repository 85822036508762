import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, AfterViewChecked } from '@angular/core';
import { DatatableComponent, SelectionType, SortType, ColumnMode } from '@swimlane/ngx-datatable';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
  HidePanel,
  InitPanels,
  MalReset,
  MalToggleCheckoutPanel,
  MalToggleTransferPanel,
  MalOpenBindPanel,
  ShowPanel,
  TogglePanel,
  UpdatePanels,
  MalAssetDetailNavigation,
} from '../../common/actions/index';
import { PanelsState } from "../../common/state/panels.state";
import { Select, Store } from '@ngxs/store';
import { PanelStatesService } from '../../common/utils/panel-states.service';
import { UtilsService } from '../../common/utils/utils.service';
import { ChainOfCustodyService } from '../../common/services/chain-of-custody.service';
import { finalize } from 'rxjs/operators';
import { ConfirmDialogComponent, ConfirmDialogModel } from "../../common/components/confirm-dialog/confirm-dialog.component";
import * as _ from "lodash";
import { Subscription, concat, Observable, Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AssetStatus, CL_INPUT_DEBOUNCE_TIME_HIGH } from "../../common/constants/index";
import { UserService } from '../../user/user.service';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { ToastService } from '@cl/common/services/toast.service';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";
import { AssetService } from '../asset.service';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { TABLE_ROW_HEIGH } from '@cl/@types/asset.type';
import { AssetsCOCStateService } from '../asset-coc-state.service';
import { ASSET_COC_ACTIONS } from '@cl/@types/asset-coc-state.type';
import { AssetsStateService } from '../asset-state.service';
import { ASSET_ACTIONS, AssetState } from '@cl/@types/asset-state.type';
import { AsyncDownloadCsvService } from '@cl/common/services/async-download-csv.service';


@Component({
  selector: 'cl-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('assetIdTemplate', { static: true }) assetIdTemplate: TemplateRef<any>;
  @ViewChild('assetNameTemplate', { static: true }) assetNameTemplate: TemplateRef<any>;
  @ViewChild('shipmentIdTemplate', { static: true }) shipmentIdTemplate: TemplateRef<any>;
  @ViewChild('deviceIdTemplate', { static: true }) deviceIdTemplate: TemplateRef<any>;
  @ViewChild('assetDateTemplate', { static: true }) assetDateTemplate: TemplateRef<any>;
  @ViewChild("scroll-viewport") contentView: ElementRef;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableWrapper') tableWrapper: any;
  @ViewChild('catalogTypeTemplate', {static: true}) catalogTypeTemplate: TemplateRef<any>;
  @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
  @ViewChild('clearSearchTemplate', { static: true })
  clearSearchTemplate: TemplateRef<any>;
  @Select(PanelsState) panels$: Observable<string[]>;
  private event = new Subject<any>();
  private filterSubject: Subject<string> = new Subject();
  md: AssetState;
  searchTerm = "";
  allNodes = [];
  categories = [];
  searchFields = {}
  nodesObj: any;
  nodes: any[] = [];
  graphData: any;
  currentAction: any;
  showFilterPanel: boolean = true;
  p = {
    entities: true,
    attributes: false,
    inspector: false,
    footer: false,
    browserWidth: 0,
    contentWidth: 0,
    panelHeight: 0,
    leftOffset: 0,
    inspectorOffset: 0,
    mainWidth: 0
  };
  filters: any;
  filtersObj = {
  };
  dataLoading: any;
  selectedDt: any;
  DTs: any;
  listHeight: any;
  mainWidth: any;
  searchFormGroup: FormGroup;
  deleteDisabled = true;
  editDisabled = true;
  bindDisabled = true;
  unBindDisabled = true;
  isEnableEdit = false;
  bulkCocDisabled = true;
  showBulkCoc = false;
  isCocSidePanelActive = false;
  bindAction: string = "Bind/Unbind";
  bindIcon: string = "fa-unlink";
  scrollHeight: any;
  assetList = [];
  entities: any[] = [];
  activeEntity: any;
  tableWidth: number;
  private currentComponentWidth: number;
  userPolicies: string[] = [];
  selectedAssetId: string = '';
  selectedAssetType = '';
  showBindPanel = false;
  selectedAsset: any;
  sidePanelsObj = {
    summary: false,
    bind: false,
    bulk: false,
    create: false,
    edit: false,
    scanner: false
  };
  cocDisabled: boolean = false;
  actionBtnPolicies = {
    bind: ['TAGGEDASSET_CREATE'],
    unbind: ['TAGGEDASSET_DELETE'],
    audit:['CUSTODYASSET_COC_CHECKOUT'],
    delete: ['ASSET_DELETE'],
    edit: ['ASSET_UPDATE'],
    create: ['ASSET_CREATE']
  };
  bulkactions: any = [
    {name: 'Bulk bind', policies: ['TAGGEDASSET_CREATE']},
    {name: 'Bulk unbind', policies: ['TAGGEDASSET_DELETE']},
    {name: 'Scan to select', policies: []}
  ];
  bulkPanelObj: BulkPanelModel;
  bulkCurrentAction: 'bind' | 'unbind'; 
  readonly bindFields = ['Device ID*', 'Asset ID*', 'Asset Type*', 'Disable Unbind', 'Component Label'];
  readonly unbindFields = ['Device ID*', 'Asset ID*', 'Asset Type*'];

  delayedRefreshStart = false;
  refreshTimer = 5;
  showScanSelectPanel: boolean = false;
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public isAllLoaded = false;
  public headerHeight = 30;
  public rowHeight:TABLE_ROW_HEIGH = TABLE_ROW_HEIGH.WITH_IMAGE;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.single;
  public totalAssets: number;
  public loadedAssets: number;
  private readonly destroyed$ = new Subject<boolean>();
  checkoutTransferInProgress: boolean;
  catalogType: string = 'asset';
  systemColumns = [];
  isImageVisible = false;
  assetCOCStateSubject: Subscription;
  assetStateSubject: Subscription;

  scannedItems = [];
  raw_data = {};
  raw_list_data = {};
  selected_rows: string[] = [];
  unselected_rows: string[] = [];
  fitered_selected_rows: string[] = [];
  rendered_data = []
  sortItem = {
    fieldName: '',
    sortOrder: ''
  }
  searchableAttributes: any=[];

  missingTags:any;
  totalScannedTags:any;
  public columnFilters:any = {};
  showColumnSearch = false;
  isColored: boolean = false;
  scanPolling !: Subscription;
  loadData: boolean = true;
  tockenId:any;
  time: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    private store: Store,
    private _snackBar: MatSnackBar,
    private _panelStatesService: PanelStatesService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private toastService: ToastService,
    private persistenceStore: PersistenceService,
    private cocService: ChainOfCustodyService,
    private formateDatePipe: FormateDatePipe,
    private webTracker: WebtrackerService,
    private _assetService: AssetService,
    private extendedattributeService: ExtendedCatalogAttributeService,
    private assetCOCState: AssetsCOCStateService,
    private assetState: AssetsStateService,
    private asyncDownloadService: AsyncDownloadCsvService
  ) {

    this.store.dispatch(new InitPanels(this.p));
  }

  ngOnInit(): void {
    this.nodes = [];
    this.loadFilters();
    this.watchMdState();
    this.watchPanels();
    this.listenToCOCEvents();
    this.store.dispatch(new ShowPanel("entities"));
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
    
    if(_.isEmpty(this.searchTerm)){
      this.assetState.resetAction();
    }
    this.getAssets(false);
    this.cocService.closeSidePanel.subscribe(data=>{
      this.closeSidePanel();
    });
    this.filterSubject.pipe(debounceTime(1000)).subscribe(() => {
      this.filter();
    });
  }

  getAssets(isScrollLoad: boolean){
    this.isLoading = true;
    this.assetState.getAssets(isScrollLoad, this.selected_rows).pipe(finalize(() => this.isLoading = false)).subscribe(res => {
      const listingAssets = res.listingAssets;
      const selectedAssets = res.selectedAssets;
      
      if(!isScrollLoad){
        this.raw_list_data = {};
        this.raw_data = {};
        this.scrollToOffsetY()
      }

      listingAssets.hits.forEach(asset => {
        this.raw_list_data[asset.id] = {...asset};
        this.raw_data[asset.id] = {...asset};
      });

      if(selectedAssets && selectedAssets?.hits?.length > 0){
        selectedAssets.hits.forEach(asset => {
          if(!this.raw_data[asset.id]) this.raw_data[asset.id] = asset;
        });
      }

      this.assetList = Object.values(this.raw_data);

      this.assetState.updateScrollId(listingAssets._scroll_id)
      this.initializeAssetsTable();
      this.totalAssets = listingAssets.totalHits;
      this.loadedAssets = this.assetList.length;
      this.formatAssetList();
    })
  }

  // This method is for scrolling to top while getting the fresh records to prevent tje multple time hitted by "onScroll" event by ngx-data table
  scrollToOffsetY() {
    const tableContainer = this.el.nativeElement.querySelector('.datatable-body');
    if (tableContainer) {
      tableContainer.scrollTop = 0;
      this.table.recalculate();
    }
  }

  initializeAssetsTable(){
    this.raw_data = {};;
    this.fitered_selected_rows = [];
    this.unselected_rows = [];
    const selected = [];
    this.assetList.forEach(item => {
      this.raw_data[item.id] = item;
      if(this.selected_rows.includes(item.id)){
        this.fitered_selected_rows.push(item.id);
        selected.push(item);
      }else{
        this.unselected_rows.push(item.id)
      }
    })

    // this.unselected_rows =  Object.values(this.raw_data);
    this.rendered_data = [];
    Array.from([...this.fitered_selected_rows,...this.unselected_rows]).forEach(id => {
      this.rendered_data.push(this.raw_data[id]);
    })
    this.selected = [...selected];
    this.setDisabledStates();
  }

  formatAssetList(){
    this.assetList.map((item) => {
      item.imageThumbnailURL =
        item.imageThumbnailURL &&
        item.imageThumbnailURL + '?' + new Date().getTime();
      item.imageThumbnailURL =
        item.imageURL && item.imageURL + '?' + new Date().getTime();
      item.createdAtFormatted = this._utilsService.transformDate(
        item.createdAt,
        'MM/dd/yy'
      );
      try {
        item.formattedSensorIds = Object.keys(JSON.parse(item.eventPublisherMap))
      } catch (error) {
        item.formattedSensorIds = [];
      }
      item.formattedLocationName = this.assetState.formatLocationName(item); // This method is commonised to component and asyncdownloadcsv. So moved to asset state service.
      if (item?.sensorIds) {
        try {
          item.sensorIds = JSON.parse(item.sensorIds);
        } catch (error) {}
      }
      if (item?.shipmentList?.length) {
        item.shipmentName = item?.shipmentList[0]?.name;
        item.shipmentId = item?.shipmentList[0]?.id;
        item.type = item?.shipmentList[0]?.type;
      }
      return item;
    });
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  ngAfterViewInit(): void {
    if (this.contentView) {
      this.scrollHeight = this.contentView.nativeElement.offsetHeight;
    }
    // this.userPolicies = (<any>this.currentUser)?.policies;
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.closeSidePanel();
        this.sidePanelsObj.summary = true;
        this.selectedAssetId = evt.id;
        this.selectedAssetType = evt.type
      }
    });
    this.subscriptions.push(eventSub);

    this.fcSearchTerm.valueChanges
      .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
      .subscribe((evt) => this.onSearchInput(evt));

    this.systemColumns = [
      {
        prop: 'selected',
        resizable: false,
        canAutoResize: false,
        sortable: false,
        name: '',
        width: 30,
        visible: true,
        headerCheckboxable: true,
        checkboxable: true,
        frozenLeft: true,
        manualSortable: false,
      },
      { name: 'Asset Name', prop: 'name', width: 180, frozenLeft: true, cellTemplate: this.assetNameTemplate, visible: true,  searchable: true },
      { name: 'Asset ID', prop: 'externalId', width: 200, cellTemplate: this.assetIdTemplate, visible: true,  searchable: true },
      { name: 'Asset Type', prop: 'entityType', width: 150, visible: true,  searchable: true },
      { name: 'Catalog Type', prop: 'parentEntityType', width: 150, cellTemplate:this.catalogTypeTemplate, visible: true, searchable: true },
      { name: 'Active Shipment', prop: 'shipmentName', width: 200, cellTemplate: this.shipmentIdTemplate, visible: true, searchable: true },
      { name: 'Device ID', prop: 'formattedSensorIds',cellTemplate: this.deviceIdTemplate, width: 180, visible: false, sortable: false, manualSortable: false  },
      { name: 'Monitored Status', prop: 'monitoredStatus', width: 160, visible: true },
      { name: 'Location', prop: 'formattedLocationName', width: 180, visible: false, sortable: false, manualSortable: false, },
    ];
    this.getExtendedColumnList();
    this.setContainerWidth();
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 34;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 260;
    } else {
      this.tableWidth = this.mainWidth - 8;
    }
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
   this.setContainerWidth();
    setTimeout(() => {
      this.table?.recalculate();
    }, 10);
  }

  adjustRowHeight(){
    this.rowHeight = this.isImageVisible ? TABLE_ROW_HEIGH.WITH_IMAGE : TABLE_ROW_HEIGH.WITHOUT_IMAGE;
    setTimeout(() => {
      this.table.recalculate()
    }, 10);
  }

  ngAfterViewChecked() {
    if (this.table && this.table.recalculate && (this.tableWrapper.nativeElement.clientWidth !== this.currentComponentWidth)) {
      this.currentComponentWidth = this.tableWrapper.nativeElement.clientWidth;
      this.table.recalculate();
      this._changeDetectorRef.detectChanges();
    }
  }

  columnReordered(e){
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, this.catalogType, this.table._internalColumns);
  }

  onScroll(offsetY: number) {
    const viewHeight = this.el.nativeElement.getBoundingClientRect().height - this.headerHeight;
    if (!this.isLoading && (offsetY + viewHeight) >= this.assetList.length * this.rowHeight) {
      this.getAssets(true)
    }
  }

  downloadReport(reportType: string) {

    // FIXME: Need to remove this for enabling async download csv in 38.
    let formatedAssetData = this.assetState.formateDownloadData(this.assetList)
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(formatedAssetData, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Assets-list', _.map(this.columns, 'name'));
    }

    // FIXME: Need to uncomment this for enabling async download csv in 38.
    // if(reportType === 'csv') {
    //   const payload: any = {
    //     scrollId: '',
    //     match: 'must',
    //     scrollSize: 10_000,
    //     globalQueryText: this.assetState.state.searchTerm,
    //     searchQueries: this.assetState.state.searchFields,
    //     searchFieldFilter: this.assetState.state.searchFieldFilter
    //   };
  
    //   if (this.assetState.state.sortField) {
    //     payload.sortField = this.assetState.state.sortField;
    //   }

    //   this.asyncDownloadService.initializeDownloading('asset',payload, this.columns, this.totalAssets, true)
    // }
  }

  private getLastLocation(row) {
    let area: string, building: string, temp: string;
    if(row?.lastAreaName !== 'Out Of Coverage'){
      area = row?.lastAreaName;
    }else{
      area = null;
    }
    if(row?.lastBuildingName !== 'Out Of Coverage'){
      building = row?.lastBuildingName;
    }else{
      building = null;
    }
    if(area){
      // Fix for "null, area"
      temp = (building ? `${building}, ` : '') + area;
    }else{
      temp = building;
    }
    return temp;
  }


  ngOnDestroy() {
    this.destroyed$.complete();
    const obs = [
      this.store.dispatch(new HidePanel('inspector')),
      this.store.dispatch(new MalToggleCheckoutPanel(null, false)),
      this.store.dispatch(new MalToggleTransferPanel(null, false)),
      this.store.dispatch(new MalReset()),
    ];
    concat(...obs).subscribe();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.assetCOCStateSubject?.unsubscribe();
    this.assetStateSubject?.unsubscribe();
  }

  trackByIdFn = (_: number, item: any) => item?.properties?.id;

  watchPanels() {
    this.panels$.subscribe((p: any) => {
      this.p = { ...p };
      this.mainWidth = this.p.mainWidth;
      this.listHeight = (this.p as any)['mainHeight'] - 150;
    })
  }


  private get isSingleSelected() {
    return !!(_.size(this.selected_rows) === 1);
  }

  private get isNoneSelected() {
    return !!(_.size(this.selected_rows) === 0);
  }

  get selectedItems() {
    return this.selected_rows.map(id => this.raw_data[id]);
  }

  private get firstSelectedItem(): any {
    const selectedItem = this.selectedItems[0] && (this.selectedItems as any)[0]["name"] ? this.selectedItems[0] : this.md.nodeToEdit;
    return this.isSingleSelected ? selectedItem : null;
  }

  get isActionInProgress(): boolean {
    return this.md.modificationInProgress;
  }

  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get("searchTerm") as FormControl;
  }

  hideSidePanel() {
    this.isCocSidePanelActive = false;
  }


  // formate messages
  formatMessages(){
     let successMessages:any = [];
     let tempSuccessKey:any = [];
     let errorMessages:any = [];
     const regExp = /^SUCCESS/;
     let activeEntity:any = this.activeEntity;
     if(!_.isEmpty(this.activeEntity)){
      activeEntity?.forEach((msg) => {
        for (let [key, value] of Object.entries(msg)) {
          let tempData:any = value;
          const check = regExp.test(tempData);
          if(check){
            tempSuccessKey.push(key);
            if(successMessages.length >= 1){
              successMessages = [];
              successMessages.push(`${tempSuccessKey.join(' ,')} : sensors have been bound successfully.`)
            }  else {
               successMessages.push(`${ key} : sensors have been bound successfully.`)
            }
          } else {
            errorMessages.push(`${ key} : ${value }`)
          }
        }
      });
      let finalMessage = `${successMessages}   ${errorMessages}`;
      if((successMessages.length === 0 && errorMessages.length) || (successMessages.length && errorMessages.length)){
       this.toastService.error(finalMessage,'Error',10000);
      } else if(successMessages.length && errorMessages.length === 0){
       finalMessage = 'All the sensors have been bound to asset successfully.';
       this.toastService.success(finalMessage,'Success',10000);
      }
     }
  }
  watchMdState() {
    this.assetStateSubject = this.assetState.assetStateObservable.subscribe(
      (state) => {
        this.md = { ...state };
        // this.nodes = [...state.searchList];
        this.activeEntity = state.activeEntity;

        // TODO: VALIDATING EVENTS
        if(state.currentAction == ASSET_ACTIONS.ASSET_BOUND){
          this.formatMessages();
          this.closeSidePanel();
          this.bindIcon = 'fa-unlink';
          this.closePopupAssetPanel(true)
        } else if (state.currentAction === ASSET_ACTIONS.ASSET_UNBOUND) {
          if(state.error){
            this.toastService.error(state.error, 'Error', 5000);
            this.unBindDisabled = true;
          }else{
            let finalMessage = 'All the sensors have been unbound successfully.'
            this.toastService.success(finalMessage, 'Success', 5000);
          }
          this.closePopupAssetPanel(true)
        }
        else if(
          state.currentAction == ASSET_ACTIONS.CLEAR_FILTERS ||
          state.currentAction == ASSET_ACTIONS.FILTERS_UPDATED){
          this.getAssets(false)
        }
        this.setDisabledStates();
      }
    );
    this.assetCOCStateSubject =
      this.assetCOCState.assetCOCStateObservable.subscribe((state) => {
        if (state.currentAction === ASSET_COC_ACTIONS.TOGGLE_COC_PANEL) {
          this.closeSidePanel();
          if (state.showCocPanel) {
            this.sidePanelsObj.summary = true;
            this.selectedAssetId = this.firstSelectedItem
              ? this.firstSelectedItem.id
              : '';
          }
        }
      });
  }

  setDisabledStates() {
    this.editDisabled = !this.isSingleSelected || this.isActionInProgress;
    this.bindDisabled = !this.isSingleSelected || this.isActionInProgress
    this.unBindDisabled = !this.isSingleSelected || this.isActionInProgress
    this.isEnableEdit = this.isSingleSelected || this.selected.length === 1;
    this.deleteDisabled = this.isNoneSelected || this.isActionInProgress;
    this.cocDisabled = this.isNoneSelected || this.isActionInProgress;
    // Delete button only enables if all the selected assets are 'UNMONITORED'
    if(!this.isNoneSelected && !this.isActionInProgress && this.selected.length > 0){
      const unmonitoredAssets = this.selected.filter(row => row.monitoredStatus?.toUpperCase() === AssetStatus.UnMonitored);
      this.deleteDisabled = unmonitoredAssets.length !== this.selected.length
    }
    if (this.md.currentAction === ASSET_ACTIONS.ASSET_UNBOUND_INPROGRESS) {
      this.bindIcon = 'fa-circle-o-notch fa-pulse';
      this.unBindDisabled = true;
    } else {
      if(this.selected.length === 1) {
        if (this.selected[0]?.monitoredStatus?.toUpperCase() === AssetStatus.Monitored) {
          this.bindAction = "Unbind";
          this.bindIcon = "fa-unlink";
          this.unBindDisabled = false;
          this.bindDisabled = false;
        } else if (this.selected[0]?.monitoredStatus?.toUpperCase() === AssetStatus.UnMonitored) {
          this.unBindDisabled = true;
          this.bindDisabled = false;
        }
      } else {
        this.bindAction = "Bind/Unbind";
        this.bindIcon = "fa-unlink";
        this.unBindDisabled = true;
        this.bindDisabled = true;
        // this.closeSidePanel()
      }
    }

    if (this.isSingleSelected) {
      this.selectedAsset = this.firstSelectedItem;
    } else {
      this.selectedAsset = {};
    }
    if(this.selected_rows.length == 1 && this.raw_data[this.selected_rows[0]]?.shipmentId){
      this.unBindDisabled = true;
    }
  }
  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  togglePanel(panel: any) {
    this.store.dispatch(new TogglePanel('entities'));
  }

  loadFilters() {
    this.assetState.loadFilters();
  }

  getLoadMoreUpdate() {
    this.getAssets(true);
  }

  initFiltersObj(initialLoad: any) {
    this.filters.forEach((filterGroup: any, i: any) => {
      filterGroup.list = filterGroup.list.filter((filt: any) => {
        filt.name = filt.name.replace(/[^a-zA-Z ]/g, "");
        return filt.name ? true : false;
      });
      filterGroup.list.forEach((filt: any, j: any) => {
        if (initialLoad) {
          filt.checked = (this.filtersObj as any)[filt.name];
        } else {
          (this.filtersObj as any)[filt.name] = false;
          filt.checked = false;
        }
      })
    })
  }
  clearFilters() {
    this.initFiltersObj(false);
  }

  setPanels() {
    this.p = this._panelStatesService.getPanelPositions(this.p);
  }

  onResize(event: any) {
    this.store.dispatch(new UpdatePanels());
  }

  bindOrUnbind(action) {
    if (!this.isSingleSelected) {
      throw new Error("Must have single selection");
    }

    let assetToUnbound = this.firstSelectedItem;
    if(!this.firstSelectedItem.externalId){
      assetToUnbound = this.firstSelectedItem.properties;
    }

    if (action === "Unbind") {
      this.unbindSelected(assetToUnbound);
      this.webTracker.captureEvent('unbind_assetSelect', {'assetId': assetToUnbound.externalId});
      this.bindIcon = 'fa-unlink';
    } else {
      this.closeSidePanel();
      this.sidePanelsObj.bind = true;
      this.selectedAsset = assetToUnbound;
      this.store.dispatch(new MalOpenBindPanel(assetToUnbound, ""));
      this.webTracker.captureEvent('bindAsset_bindSelect', {'assetId': assetToUnbound.externalId});
    }
  }

  openBulkCOCDialog() {
    this.isCocSidePanelActive = true;
    this.updateEntities();
  }

  private updateEntities() {
    this.entities = this.selectedItems as any;
  }

  unbindSelected(selectedAsset: any) {
    let message,
      boundAssets: any = [],
      boundAssetList = "",
      assetList: any = [];

    if (!selectedAsset) {
      console.warn("No node to edit");
      return;
    }
    boundAssets.push(selectedAsset);
    message = `Are you sure you want to unbind this asset?: ` + selectedAsset?.externalId;
    // }

    const dialogData = new ConfirmDialogModel("", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
          this.unBindDisabled = true;
          this.bindIcon = 'fa-circle-o-notch fa-pulse';
         this.webTracker.captureEvent('unbind_yes', {'assetIds': selectedAsset?.externalId});
         this.assetState.unbindAsset(boundAssets);
      }
    });
  }

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    const selectedRowIds = selectedRows.map(row => row.id);
    this.onRowSelection(selectedRowIds);
  }

  onRowSelection(selectedRowIds: string[]){
    this.unselected_rows = [];
    for (const id in this.raw_data) {
      if(selectedRowIds.includes(id)){
        if(!this.selected_rows.includes(id)){
          this.selected_rows.push(id);
          this.fitered_selected_rows.push(id)
        }
      }else{
        const selected_row_index = this.selected_rows.indexOf(id);

        if(selected_row_index > -1){
          const filterd_row_index = this.fitered_selected_rows.indexOf(id);
          if(filterd_row_index > -1){
            this.fitered_selected_rows.splice(filterd_row_index,1);
          }
          this.selected_rows.splice(selected_row_index,1);
          if(this.raw_list_data[id]){
            this.unselected_rows.push(id)
          }else{
            delete this.raw_data[id];
          }
        }else {
          this.unselected_rows.push(id)
        }
      }
    }

    this.rendered_data = [];
    const selected = [];
    this.fitered_selected_rows.forEach(rowId => {
      this.rendered_data.push(this.raw_data[rowId]);
      selected.push(this.raw_data[rowId])
    })
    this.unselected_rows.forEach(rowId => {
      this.rendered_data.push(this.raw_data[rowId])
    })
    this.selected = selected;
    this.assetList = Object.values(this.raw_data);
    this.loadedAssets = this.assetList.length;
    
    this.setDisabledStates();
  }

  clearAllSelection(){
    this.selected_rows = [];
    this.fitered_selected_rows = [];
    this.selected = [];
    this.setDisabledStates()
  }

  navigateToDetails(rowData: any) {
    this.store.dispatch(new MalAssetDetailNavigation(rowData))
    this.router.navigate([
      "/asset-details",
      {
        id: rowData?.id,
        taggedAssetId: rowData?.taggedAssetId,
      },
    ]);
  }

  onSearchInput(evt: any) {
    this.searchTerm = this.searchFormGroup.value.searchTerm;
    this.assetState.onSearchByTerm(this.searchTerm)
    this.webTracker.captureEvent('assetList_search', {'searchTerm': this.searchTerm});
  }

  onSort(prop) {
    let sortParam =  [
      {
        "fieldName": prop,
        "sortOrder": this.sortItem.fieldName == prop && this.sortItem.sortOrder == "ASC" ? "DESC" : "ASC"
      }
    ];
    this.sortItem = sortParam[0];
    this.assetState.onSort(sortParam)
  }

  loadAssetSummary(assetId: string, event: any, type: string) {
    event.preventDefault();
    if(this.userService.getPolicies().includes('ASSET_DETAIL')){
      this.assetCOCState.hideCOCSidePanel()
      this.setEvent({ 'eventName': 'summary', id: assetId, type: type });
      this.webTracker.captureEvent('bindAsset_select', {'assetId': assetId});
      this.assetState.resetAction();
    }else{
      this.toastService.error('Access not allowed')
    }
  }

  closeSidePanel() {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    this.scannedItems = [];
  }

  bulkPanelHandler(panelObj: BulkPanelModel) {
    panelObj.isDynamicFile = true;
    this.bulkCurrentAction = panelObj.bulkAction == 'Bulk bind' ? "bind" : "unbind"; 
    this.bulkPanelObj = panelObj;
    this.closeSidePanel();
    if(panelObj?.bulkAction == 'Scan to select'){
      // this.showScanSelectPanel = true;
      this.sidePanelsObj.scanner = true;
    }else{
      this.sidePanelsObj.bulk = true;
      this.showScanSelectPanel = false;
    }
  }

  getId(row) {
    return row.id;
  }

  reloadAssets() {
    if(this.delayedRefreshStart){
      this.delayedRefreshStart = false
    }
    this.getAssets(false);
  }

  updateFilter(term){
    // this.searchApplied = true;
    this.searchFormGroup.patchValue({
      'searchTerm':term.toLowerCase().trim()
    });
  }
  initializeAudit(){
    let assetIdArray = [];
    let assetNameArray = [];
    this.selected.forEach(row => {
      assetIdArray.push(row.id);
      assetNameArray.push(row.name);
    });
    this.userService.initializeAudit({'assetIds':assetIdArray}).subscribe(data=>{
      let action = 'Audit initialization successful';
      this.toastService.success(action, assetNameArray.join(', '));
    }, error=>{
      let action = 'Something went wrong';
      this.toastService.error(action, assetNameArray.join(', '));
    });
  }

  listenToCOCEvents() {
    const eventSub: Subscription = this.cocService.getCOCEvent().subscribe(evt => {
      if (evt.actionInProgress) {
        this.checkoutTransferInProgress = true;
      } else {
        this.checkoutTransferInProgress = false;
      }
    });
    this.subscriptions.push(eventSub);
  }

  closePopupAssetPanel(isReload = false){
    if(isReload){
      this.delayedRefreshStart = true;
      this.clearAllSelection()
    }
  }

  openAddAssetPanel(isEdit){
    this.closeSidePanel();
    this.sidePanelsObj.create = !isEdit;
    this.sidePanelsObj.edit = isEdit;
  }

  deleteAssetDialog() {
    this.dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          message: `Are you sure want to delete selected Assets?`,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && this.selected) {
          this.deleteAsset();
        }
      });
  }
  async deleteAsset() {
    const deletedAssetesids = this.selected.map((item) => {
      return {
        id: item.id,
        externalId: item.externalId,
        name: item.name,
        entityTypeKey: item.entityTypeKey
      }
    });
    try {
      const response = await this._assetService.deleteAssets(deletedAssetesids) as any[];
      this.delayedRefreshStart = true;
      const messages = response.map(item => `${item?.name}: ${item?.errorMessage || item?.status}`)
      this.toastService.openCloseHandlerSnackBar(messages)
      this.clearAllSelection()
    } catch (error) {}
  }

   // get extended columns

   async getExtendedColumnList() {
    let catalogTypes = [];
    try {
      catalogTypes = await this.extendedattributeService.getCatalogExtendedMultiColumns(this.catalogType);
    } catch (error) {
    }

    let userColumnsObj = {};
    let systemColumnsObj = {};
    const preDefinedColumns = ['name','externalId','type','shipmentName','sensorIds','monitoredStatus', 'createdBy','createdAt','modifiedBy','modifiedAt'];
    const searchInfoSystemAttributes = ['Name', 'External ID', 'Type', 'Shipment Name', 'Sensor ID', 'Monitored Status'];
    const userSearchableFields: string[] = [];
    catalogTypes.forEach((catalogType) => {
      catalogType.cdmFields.forEach((field) => {
        if (field.isDisplayableOnList && !preDefinedColumns.includes(field.name)) {
          if(field.group.toLowerCase() === 'user' && field.searchable){
            if(userSearchableFields.indexOf(field.displayLabel) === -1) {
              userSearchableFields.push(field.displayLabel);
            }
          }
          if(field.group.toLowerCase() === 'user' && !userColumnsObj[field.name]){
            userColumnsObj[field.name] = {
              name: field.displayLabel,
              prop: field.name,
              width: 180,
              visible: false,
              cellTemplate: field.type.toLowerCase() ==  'date' && this.assetDateTemplate,
              sortable: false,
              searchable:field.searchable,
              manualSortable: false,
            }
          }
          if(field.group.toLowerCase() == 'system' && !systemColumnsObj[field.name]){
            if(field.searchable){
              searchInfoSystemAttributes.push(field.displayLabel);
            }
            systemColumnsObj[field.name] = {
              name: field.displayLabel,
              prop: field.name.toLowerCase() == 'organization' ? 'organizationName' : field.name,
              width: 180,
              minWidth: 160,
              visible: false,
              cellTemplate: field.type.toLowerCase() ==  'date' && this.assetDateTemplate,
              searchable:field.searchable
            }
          }
        }
      });
    });
    this.searchableAttributes = [...searchInfoSystemAttributes, ...userSearchableFields]
    const dateColumns = [
      { name: 'Created At', prop: 'createdAt', cellTemplate: this.assetDateTemplate, width: 180, visible: true },
      { name: 'Created By', prop: 'createdBy', width: 180, visible: true },
      { name: 'Modified At', prop: 'modifiedAt', cellTemplate: this.assetDateTemplate, width: 180, visible: true },
      { name: 'Modified By', prop: 'modifiedBy', width: 180, visible: true },
]
    this.rawColumns = [...this.systemColumns,...Object.values(systemColumnsObj),...Object.values(userColumnsObj), ...dateColumns];
    this.rawColumns.forEach(item => {
      item.sortable = false;
      if(item.prop !== 'selected'){
        item.headerTemplate = this.headerTemplate
      }
      if(item.manualSortable !== false){
        item.manualSortable = true;
      }
      if (item.prop == 'selected') {
        item.headerTemplate = this.clearSearchTemplate;
      }
    })
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList(this.catalogType, [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    const persistedColumns = this.persistenceStore.getGridColumns(this.catalogType);    
    this.isImageVisible = Array.isArray(persistedColumns) ? persistedColumns.includes('showImage') : false;
    this.adjustRowHeight();
    this._changeDetectorRef.detectChanges();
  }

  codescannerhandler(methodObj:any){
    if(!_.isEmpty(methodObj)){
      if(this.assetState.state.searchTerm || this.assetState.state.searchFields[0].childFilters.length){
        this.assetState.clearFilters();
        this.updateFilter('');
      }
    }
  }

  onScanCode(scanObj:{code: string[], action: string}){
    const scanedCode = scanObj.code; // code is array of assetid's while action is delete and qrbarcode while scanning
    if(!_.isEmpty(scanedCode) && scanObj.action === 'scan'){
      this.getAssetListByScan(scanedCode)
    }else if(!_.isEmpty(scanedCode) && scanObj.action === 'delete'){
      const selectedIndex = this.selected_rows.indexOf(scanedCode[0]);
      if(selectedIndex > -1){
        const selectedRows = _.cloneDeep(this.selected_rows);
        selectedRows.splice(selectedIndex,1)
        this.onRowSelection(selectedRows);
      }
    }
  }

  getAssetListByScan(code: any[]) {
    this.assetState.getAssetsByScan(code).subscribe(res => {
      const assets = res.hits;
      this.scannedItems.push(...assets);
      this.scannedItems = [...this.scannedItems]
      assets.forEach(asset => {
        if(!this.selected_rows.includes(asset.id)){
          this.selected_rows.unshift(asset.id)
        }
      })
      this.getAssets(false);
    })
  }

  filterInput($event) {
    this.filterSubject.next('');
  }

  filter() {
    let searchFieldFilter: {name: string, value: string}[] = [];
    let seachKey:any='';
    Object.keys(this.columnFilters).forEach((key) => {
      if(this.columnFilters[key]?.trim()){
        searchFieldFilter.push({
          name: key=='shipmentName'?'shipmentList.name':key,
          value: this.columnFilters[key],
        });
        seachKey = this.columnFilters[key];
      }
    });
    this.assetState.updateSearchFilters(searchFieldFilter)
  }

  toggleSearchColumns(){
    this.isColored = !this.isColored
    this.showColumnSearch = !this.showColumnSearch;
    this.headerHeight = this.showColumnSearch ? 60 : 30;
    if(this.assetState.checkSearchColumnExist()){
      this.columnFilters ={};
      this.filter();
    }
    setTimeout(() => {
      this.table.recalculate();
    }, 10);
  }

  closeFilter() {
    this.toggleSearchColumns();
  }
}
