import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompositeShipment } from '@cl/@types/shipment.types';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';
import { ShipmentService } from '../shipment.service';

@Component({
  selector: 'cl-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss'],
})
export class ShipmentDetailsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[] = [
    { name: 'Previous', isBack: true, path: null },
    { name: 'Shipment Details', path: null },
  ];
  shipmentDetails: any;
  shipmentId: string;
  shipmentType: string;
  CSType: string;
  winkedAssetId: string;
  isInitialLoad = false;
  mac = '';
  compositeShipmentId = '';
  compositeShipmentData!: CompositeShipment;
  isDraftShipment = false;

  constructor(
    private shipmentsService: ShipmentService,
    private route: ActivatedRoute,
    private shipmentUtils: ShipmentUtilsService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.shipmentId = paramMap.get('id');
      setTimeout(() => {
        if(this.shipmentId) this.getShipmentData();
      }, 100);
    });
    this.route.queryParams.subscribe((params) => {
      this.compositeShipmentId = params['compositeShipmentId'];
      this.shipmentType = params['type'];
      this.CSType =params['cstype']
      if (this.compositeShipmentId) this.getCompositeShipmentData();
    });

    if (window.navigator.platform.indexOf('Mac') != -1) {
      this.mac = 'mac';
    }
  }

  getCompositeShipmentData() {
    if (!this.compositeShipmentId) return;
    this.shipmentsService
      .getCompositeShipmentDetails(
        this.compositeShipmentId,
        this.CSType
      )
      .subscribe((res: CompositeShipment) => {
        const duration = res.duration
          ? this.shipmentUtils.dwellTimeFormatter(
              res.duration,
              false,
              true,
              true,
              null
            )
          : '';
        this.compositeShipmentData = res;
        this.compositeShipmentData.duration = duration;
      });
  }

  getShipmentData() {
    this.isInitialLoad = true;
    this.shipmentsService.getShipmentData(this.shipmentId,this.shipmentType).subscribe((data) => {
      this.shipmentDetails = data;
      this.isDraftShipment =
        this.shipmentDetails.shipmentNode.properties.statusCode == 'Drafts';
      if (!this.winkedAssetId) {
        this.winkedAssetId = this.shipmentDetails.assetNodes[0]?.id;
      }
      if (!this.isInitialLoad) {
        this.reloadWinkedAsset();
      }
      this.isInitialLoad = false;
    });
  }

  reloadWinkedAsset() {
    const prevWink = this.winkedAssetId;
    this.winkedAssetId = '';
    setTimeout(() => {
      this.winkedAssetId = prevWink.slice(0, prevWink.length);
    }, 1);
  }
  getWinkedAssetId(event) {
    this.winkedAssetId = event;
  }
}
