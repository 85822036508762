<div class="user-summary-panel policy-side-panel">
    <cl-side-panel (closePanel) = "closePanel()">
        <section class="cl-side-panel-title bgcolor32">
            <span *ngIf="mode === 'add'" class="black font-bold">Add New Dashboard</span>
            <span *ngIf="mode === 'edit'" class="black font-bold">Edit Dashboard</span>
            <span class="close-icon" (click)="closePanel()">
                <i class="fa fa-times-circle-o mr-4"></i>
            </span>
        </section>
        <section class="cl-side-panel-content">
            <form [formGroup]="dashboardForm">
                <div class="side-pane-form">
                    <div class="side-pane-form__item">
                        <div class="form-label">Dashboard Name<span class="required">*</span></div>
                        <input type="text" class="basic-input"
                        placeholder="Enter Dashboard Name" formControlName="dashboardName">
                    </div>

                    <div class="side-pane-form__item">
                        <div class="form-label">Description<span class="required">*</span></div>
                        <input type="text" class="basic-input"
                        placeholder="Enter Description" formControlName="description">
                    </div>

                    <!-- <div class="side-pane-form__item">
                        <div class="form-label">Type<span class="required">*</span></div>
                        <mat-select formControlName="type" [ngClass]='{"disabled": mode=="edit"}'>
                            <mat-option *ngFor="let el of typeList;" [value]="el">{{el}}</mat-option>
                        </mat-select>
                    </div> -->

                    <div class="side-pane-form__item">
                        <div class="form-label">Dashboard Link<span class="required">*</span></div>
                        <input type="text" class="basic-input"
                        placeholder="Enter Dashboard link" formControlName="dashboardLink">
                    </div>

                    <div class="side-pane-form__item" >
                        <div class="form-label">Tenant<span class="required">*</span></div>
                        <mat-select formControlName="tenant">
                            <mat-option *ngFor="let el of tenantList;" [value]="el">{{el.name}}</mat-option>
                        </mat-select>
                    </div>

                </div>
            </form>
        </section>

        <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
            <div class="flex">
                <div class="flex-col grow fs11 text-right" >
                    <a class="m-2" (click)="closePanel()">Cancel</a>
                    <button class="panel-button white bgcolor4" [disabled]="checkIfFormValid()" (click)="saveDashboard()" *ngIf="mode === 'add'">Save <span class="fa fa-circle-o-notch fa-pulse white" *ngIf="isLoading"></span></button>
                    <button class="panel-button white bgcolor4" [disabled]="checkIfFormValid()" (click)="editDashboard()" *ngIf="mode === 'edit'">Update <span class="fa fa-circle-o-notch fa-pulse white" *ngIf="isLoading"></span></button>
                </div>
            </div>
        </section>
    </cl-side-panel>
</div>

