import { Pipe, PipeTransform } from '@angular/core';
import { MeasureType } from '@cl/@types/measure.type';
import { PropertyService } from '@cl/property/property.service';
import * as _ from 'lodash';
@Pipe({
  name: 'measure'
})
export class MeasurePipe implements PipeTransform {
  constructor(private propertyService : PropertyService){}

  transform(measure: MeasureType, ...args: unknown[]): string {    
    let temp: string = '';
    if(!_.isUndefined(measure.value) && !_.isNull(measure.value)){
      let tempSidCid = measure.measureServiceId + ':' + measure.measureCharacteristicId;      
      if(_.includes(['37C7BB40-B06A-11E3-8000-B70F3AB862A4:ambientTemp', '37C7BB40-B06A-11E3-8000-B70F3AB862A4:cargoTemp'], tempSidCid)){
        var currentUnit = this.propertyService.getSetting('ui.temp.unit');
        temp = Number(measure.value) + '&#176;' + currentUnit;
      }else if(_.includes(['Door:doorStatus','Door:compartmentDoorDoorStatus', 'Door:compartmentCeilingBackDoorStatus'], tempSidCid)){        
        temp = (measure.value === 'false' || measure.value === false || measure.value === 'closed') ? 'closed' : 'Opened';
      }else if(measure.measureCharacteristicId == 'AA21'){
        temp = Number(measure.value)  + (!_.isEmpty(measure.uom) ? measure.uom : '');       
      }else if(measure.measureCharacteristicId == "rxVibration" || measure.measureCharacteristicId == "rxShock"){
        temp = measure.value  + ' '+ (!_.isEmpty(measure.uom) ? measure.uom : '');       
      }else {
        temp = Number(measure.value)  + ' '+ (!_.isEmpty(measure.uom) ? measure.uom : '');
      }
    }else {
      temp ='';
    }
    return temp;
  }

}
