import { Deserializable } from './deserializable';
import { RuleProps } from './ruleProps';
// import { InspectorSection } from './inspectorSection';
// import { UtilsService } from '../services/utils.service';

export class Rule implements Deserializable {
  id: number;
  uid: string;
  name: string;
  nameCount: number;
  description: string;
  properties: any = {};
  type: string = 'node';
  graphType: string = 'node';
  // cl_type: string;
  ruleType: string;
  token: string;
  template = 'rule';
  subTemplate: string;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  lastModified: Date;
  active: boolean;
  sourceIDs = {};
  targetIDs = {};
  mappedNodes = {};
  mappedLinks = {};
  canvasPosition: any = {};
  selectedPosition = {};
  triggerType: string;
  x: number = 0;
  dx: number = 0;
  y: number = 0;
  dy: number = 0;
  sections: any = [];

  // constructor(private _utilsService: UtilsService) {}
  deserialize(input: any) {
    const rule = this.convertRule(input);
    Object.assign(this, rule);
    return this;
  }

  convertRule(ruleObj: any) {
    if (!ruleObj) {
      return {}
    }
    const rule = Object.assign({}, this, ruleObj);
    rule.ruleType = ruleObj.ruleType;
    rule.triggerType = ruleObj.name;
    // rule.ruleProps = new RuleProps();
    rule.properties.triggerType = ruleObj.name;
    rule.properties.triggerToken = ruleObj.token || ruleObj.subTemplate;

    // console.log('convert new rule', rule, ruleObj);
    if (rule.name) {
      const nameArr = rule.name.split('-');
      if (nameArr[1] && Number.isInteger(+nameArr[1])) {
        rule.nameCount = +nameArr[1];
      }
    }

    return rule;
  }
}
