import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { AssetStatus, CL_INPUT_DEBOUNCE_TIME_HIGH } from "@cl/constants";
import { AssetSearchHit, ESSearchResults, Sensor } from "@cl/models";
import { HidePanel, SfBindSensor, SfCancelBind, SfSelectAsset, SfSensorBound } from "@cl/ngxs/actions";
import { UtilsService } from "@cl/common/utils/utils.service";
import { SearchApiService } from "@cl/common/services/search-api.service";
import { GraphAPIService } from '@cl/common/services/graph-api.service';
import { Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: 'cl-gateway-binder',
  templateUrl: './gateway-binder.component.html',
  styleUrls: ['./gateway-binder.component.scss']
})
export class GatewayBinderComponent implements OnInit {
  @Input() gatewayObject: any;
  @Output() closeSidePanel = new EventEmitter();
  bindClicked: boolean = false;
  selectedLocation: any;

  bindForm: FormGroup;
  locationList: any[] = [];
  loading = false;

  unbindInProgress = false;

  private scrollId = "";
  private total = 0;
  private subscribedToInputChanges = false;
  private readonly destroyed$ = new Subject<boolean>();

  private get fcSearchTerm(): AbstractControl {
    return this.bindForm?.get("searchTerm");
  }

  constructor(
    private fb: FormBuilder,
    private _searchApiService: SearchApiService,
    private _graphApi: GraphAPIService,
    private _utilService: UtilsService
  ) {
    this.bindForm = this.fb.group({
      searchTerm: [""],
    });
  }

  ngOnInit(): void {
    this.subscribeToSearchTermChanges();
  }

  closePanel(statusAction?: string) {
    this.closeSidePanel.emit(statusAction);
  }


  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  selectListItem(location: any) {
    this.selectedLocation = location;
  }

  constructPayload(): any {
    const {type, attributes, tenantId, physicalId} = this.gatewayObject;
    const areaId = this.selectedLocation.id;
    return {
      areaId,
      attributes,
      physicalId,
      tenantId,
      type
    }
  }

  bindGateway() {
    const payload = this.constructPayload();
    this.bindClicked = true;
    this.unbindInProgress = true;
    this._graphApi
      .bindGateway(payload)
      .pipe(finalize(() => (this.unbindInProgress = false)))
      .subscribe(
        (res) => {
          this._utilService.showMessage("Gateway bound", payload.physicalId);
          this.closePanel('bindSuccess');
        },
        (err) => {
          this._utilService.showMessage("Unable to bind Gateway", payload.physicalId);
        }
      );
  }


  private subscribeToSearchTermChanges() {
    if (this.subscribedToInputChanges) {
      return;
    }

    this.fcSearchTerm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        startWith(""),
        debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH),
        distinctUntilChanged(),
        tap((_) => (this.scrollId = "")),
        switchMap((searchTerm) => this.getLocationsList(searchTerm))
      )
      .subscribe((locations) => {
        this.locationList = locations;
      });

    this.subscribedToInputChanges = true;
  }

  private getLocationsList(searchTerm: string, newSearch: boolean = true): Observable<AssetSearchHit[]> {
    this.loading = true;

    const params = {
      scrollId: this.scrollId,
      scrollSize: 50,
      globalQueryText: searchTerm,
      searchQueries: [
        {
          fieldName: "baseClass",
          queryText: "Location",
          queryOperator: "should",
          queryType: "match",
          childFilters: [
            {
              fieldName: "clfMappingType",
              queryText: "gatewayzone",
              queryOperator: "must",
            },
            {
              fieldName: "status",
              queryText: "PROVISIONED",
              queryOperator: "must_not",
            },
            {
              fieldName: "deleted",
              queryText: "false",
              queryOperator: "must",
            }
          ],
        },
      ],
    };

    return this._searchApiService.globalSearch<ESSearchResults<any>>(params, false).pipe(
      finalize(() => (this.loading = false)),
      catchError((err) => of({ total: 0, _scroll_id: "", hits: [] })),
      tap((res) => {
        this.total += res.hits.length;
        this.scrollId = res._scroll_id;
      }),
      map((res) => res.hits)
    );
  }
}
