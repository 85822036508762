import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CL_INPUT_DEBOUNCE_TIME } from "@cl/constants";
import { NavigatorFilter, NavigatorFilterList, NavigatorFilterMap, PartialOf } from "@cl/models";
import { IconSvgService } from "../../../common/services/icon-svg.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import _ from "lodash";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, tap } from "rxjs/operators";
import { NavigatorUpdateMapDetails } from "../../state/navigator.actions";
import { DomainFilterBoxComponent } from "../domain-filter-box/domain-filter-box.component";
import { AuthorizeService } from "@cl/@authorize/authorize.service";

interface TargetAndDomainFormValue {
  target: string;
  visible: string[];
}

@UntilDestroy()
@Component({
  selector: "app-domain-filter-list",
  templateUrl: "./domain-filter-list.component.html",
  styleUrls: ["./domain-filter-list.component.scss"],
  host: {
    class: "app-domain-filter-list",
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainFilterListComponent),
      multi: true,
    },
  ],
})
export class DomainFilterListComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChildren(DomainFilterBoxComponent) domainFilterBoxListRef: QueryList<DomainFilterBoxComponent>;
  @Input()
  domainMap: NavigatorFilterMap;
  @Output() activeFiltersCountChange = new EventEmitter<any>();
  @Output() totalRecords = new EventEmitter<number>();
  @Output() allFilters = new EventEmitter<any>();

  domainList: NavigatorFilterList = [];

  domainsForm: FormGroup = new FormGroup({});

  get newTargetAndVisibilityForm() {
    return this.fb.group({
      target: [""],
      visible: [[]],
    });
  }
  targetAndVisibilityForm: FormGroup = this.newTargetAndVisibilityForm;

  get targetAndVisibilityChange$() {
    return this.targetAndVisibilityForm.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(CL_INPUT_DEBOUNCE_TIME),
      distinctUntilChanged(_.isEqual),
      tap((val: TargetAndDomainFormValue) => {
        this.store.dispatch(new NavigatorUpdateMapDetails({ targetDomain: val.target, visibleDomain: val.visible }));
      })
    );
  }

  private get fcTarget() {
    return this.targetAndVisibilityForm.get("target");
  }

  get fcVisible() {
    return this.targetAndVisibilityForm.get("visible");
  }

  private updatingFormControlInProgress = false;
  private filtersCountMap: PartialOf<number> = {};

  valueChangesSubscription$: Subscription;

  disabled = false;
  onChange: any = (obj: any) => {};
  onTouch: any = () => {};

  actionBtnPolicies = {
    asset: ['ASSET_SUMMARY'],
    location: ['LOCATION_SUMMARY'],
    shipment: ['SHIPMENT_SUMMARY'],
  };

  constructor(private iconSvgService: IconSvgService, private fb: FormBuilder, private store: Store, private authorizeService: AuthorizeService) {}


  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['domainMap'] && this.domainMap) {
      this.domainList = this.mapFilterToArray(this.domainMap);
      this.domainsForm = this.prepareFilterForm(this.domainList);
      this.watchForFiltersForm();
    }
  }

  ngOnDestroy(): void {
    if (this.valueChangesSubscription$) this.valueChangesSubscription$.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.domainsForm.patchValue(obj);
    // console.log("🚀 ~ DomainFilterListComponent ~ writeValue ~ obj", obj);
    // console.log('🚀 ~ DomainFilterListComponent ~ writeValue ~ this.domainsForm', this.domainsForm);
    // console.log('🚀 ~ DomainFilterListComponent ~ writeValue ~ this.domainList', this.domainList);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Toggles visibility for specific domain
   */
   toggleVisibility(domainId: string, val) {
    for (let i = 0; i < this.domainFilterBoxListRef.length; i++) {
      const domainFilterBox = this.domainFilterBoxListRef.get(i);
      if (domainFilterBox.domain.id === domainId) {
        domainFilterBox.toggleVisibility(val);
        return;
      }
    }
  }

  /**
   * For each domains clears all the selected filter.
   */
  clearAll() {
    for (let i = 0; i < this.domainFilterBoxListRef.length; i++) {
      const domainFilterBox = this.domainFilterBoxListRef.get(i);
      domainFilterBox.clearAll();
    }
  }

  /**
   * Toggles visibility for all domains filter boxes
   */
  toggleAllVisibility(val: boolean) {
    for (let i = 0; i < this.domainFilterBoxListRef.length; i++) {
      const domainFilterBox = this.domainFilterBoxListRef.get(i);
      domainFilterBox.setVisibility(val);
    }
  }

  /**
   * Clear target domain if set.
   */
  clearTargetDomain() {
    this.targetDomain("", false);
  }

  /**
   * Clears visible and targeted flag for all other domains and sets the
   * targeted and visible flag for the specified domain.
   */
  targetDomain(domainId: string, target: boolean = true) {
    const visibleDomainList = new Array<string>();

    for (let i = 0; i < this.domainFilterBoxListRef.length; i++) {
      const domainBox = this.domainFilterBoxListRef.get(i);

      // Domain needs to be set as active
      const isTargetedDomain = domainBox.domain.id === domainId && target;
      domainBox.setTargeted(isTargetedDomain);

      // Ensure domains are visible if current domain box is targeted
      // or targeting is disabled.
      const isVisible = target && domainBox.domain.id === domainId;
      domainBox.setVisibility(isVisible);

      if (isVisible) visibleDomainList.push(domainBox.domain.id);
    }

    // Update the target domain form control
    if (target) this.fcTarget.patchValue(domainId);
    else this.fcTarget.patchValue("");

    // Update visibility form control
    this.fcVisible.patchValue(visibleDomainList);
  }

  patchAllDomainstoVisible() {
    this.fcVisible.patchValue(this.domainList.map(d => d.id));
  }

  /**
   * Event handler for visibility domain visibility change
   */
  onVisibleChange(domainId: string, val: boolean) {
    let existingVisibleDomains: string[] = [...this.fcVisible.value];

    if (val) {
      // Add visible domain to the visible domain list
      existingVisibleDomains = existingVisibleDomains.concat(domainId);
    } else {
      // Remove domain from the visible domain list
      const domainIndex = existingVisibleDomains.indexOf(domainId);
      existingVisibleDomains.splice(domainIndex, 1);
    }

    // Patch the value back to the form
    this.fcVisible.patchValue(existingVisibleDomains);
  }

  /**
   * Event handler for visibility domain visibility change toggles only one domain
   */
  onVisibleToggleChange(domainId: string, val: boolean) {
    let existingVisibleDomains: string[] = [...this.fcVisible.value];

    if (val) {
      // Add visible domain to the visible domain list
      existingVisibleDomains = [domainId];
    } else {
      // Remove domain from the visible domain list
      const domainIndex = existingVisibleDomains.indexOf(domainId);
      existingVisibleDomains.splice(domainIndex, 1);
    }

    // Patch the value back to the form
    this.fcVisible.patchValue(existingVisibleDomains);
  }
  /**
   *
   */
  setVisible(domainId: string, val: boolean) {}

  onActiveFilterCountChange(obj: {}, filterId: string) {
    this.filtersCountMap[filterId] = obj['filterCount'];

    let totalFiltersCount = 0;
    _.forOwn(this.filtersCountMap, (val, key) => (totalFiltersCount += val));
    this.activeFiltersCountChange.emit({count:totalFiltersCount, filterSelected: obj['filterSelected']});
  }

  /**
   * Gets, mapping between all domains and its sub filters.
   */
  getDomainsSubFiltersMap() {
    const domainsFilterMap: PartialOf<PartialOf<string>> = {};

    for (let i = 0; i < this.domainFilterBoxListRef.length; i++) {
      const domainBox = this.domainFilterBoxListRef.get(i);

      domainsFilterMap[domainBox.domain.id] = domainBox.prepareSubFilters();
    }

    return domainsFilterMap;
  }

  totalRecordcount(count: number) {
    this.totalRecords.emit(count);
  }

  allFilterData(data) {
    this.allFilters.emit(data);
  }

  private watchForFiltersForm() {
    if (this.valueChangesSubscription$) {
      this.valueChangesSubscription$.unsubscribe();
      this.valueChangesSubscription$ = null;
    }

    this.valueChangesSubscription$ = this.domainsForm.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),
        filter((_) => !this.updatingFormControlInProgress)
      )
      .subscribe((val) => {
        this.onChange(val);
      });
  }

  /**
   * Converts domain map to domain array
   */
  private mapFilterToArray(domainMap: NavigatorFilterMap): NavigatorFilterList {
    const domainList: NavigatorFilterList = [];

    // No domain exists hence return
    if (_.size(domainMap) <= 0) return domainList;

    // For each domains in the domain map create a domain set to be used by UI
    // and push it to the domain list
    _.forOwn(domainMap, (value, key) => {
      if(!this.authorizeService.isValidAction(this.actionBtnPolicies[key])) return;

      const domain: NavigatorFilter = {
        ...value,
        id: key,
        domain: this.iconSvgService.getSVG(key),
        svgIconUrl: "/assets/svgs/cl-circle-icons/" + this.iconSvgService.getSVG(key) + ".svg",
      };

      // Add domain to the domain list
      domainList.push(domain);
    });

    return domainList;
  }

  /**
   * Generates domain form based on the domain list
   */
  private prepareFilterForm(domainList: NavigatorFilterList): FormGroup {
    this.updatingFormControlInProgress = true;

    const formGroup = domainList.reduce((acc, curr) => {
      acc.addControl(curr.id, new FormControl({}));
      return acc;
    }, this.fb.group({}));

    // By default all domains are visible
    // const domainIds = this.domainList.map((domain) => domain.id);
    // this.fcVisible.patchValue(domainIds);

    this.updatingFormControlInProgress = false;

    return formGroup;
  }
}
