import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { UserService } from '../../user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { PersistenceService } from '@cl/common/services/persistence.service';

@Component({
  selector: 'cl-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss']
})
export class UserGroupListComponent implements OnInit, AfterViewInit {
  @ViewChild('userGoupNameTemplate') userGoupNameTemplate: TemplateRef<any>;
  @ViewChild('userGroupMembersTemplate') userGroupMembersTemplate: TemplateRef<any>;
  @ViewChild('table') table;

  public tableRowData: any = [];
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  private event = new Subject<any>();
  showSummaryPanel = false;
  mode = 'add';
  editDisabled = true;
  groupObject = {};
  allUserObject = [];
  columnKeys: string[] = [];
  totalRowCount = 0;
  actionBtnPolicies = {
    add: ['USERGROUP_CREATE'],
    edit: ['USERGROUP_UPDATE'],
    delete: ['USERGROUP_DELETE']
  }
  initialLoad: boolean =true;
  mainWidth:number;
  tableWidth:number;
  isOrgRestrictedUser:boolean;
  delayedRefreshStart = false;
  refreshTimer = 5;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private el: ElementRef,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private persistenceStore: PersistenceService,
  ) {
  }

  ngOnInit(): void {
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
    this.getUserGroups();
    this.getAllUsers();

    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.mode = 'view';
        this.showSummaryPanel = true;
        this.groupObject = evt.groupObject;
      }
    });
    this.subscriptions.push(eventSub);
    this.userService.pageReload.subscribe(val => {
      if(val){this.getUserGroups();}
    })
    this.setContainerWidth();
  }

  ngAfterViewInit(): void {
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true
      },
      { name: 'Group Name',cellTemplate:this.userGoupNameTemplate, prop: 'userGroupName', width: 180, visible: true },
      { name: 'Members', prop: 'members', cellTemplate: this.userGroupMembersTemplate, width: 200, visible: true },
      { name: 'Organization', prop: 'organizationName', width: 200, visible: true },
      { name: 'Description', prop: 'description', width: 150, visible: true },
      { name: 'Subscribed to', prop: 'subscribedTo', width: 150, visible: true }];
      const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('userGroup', [...this.rawColumns]);
      this.rawColumns = [...rawColumns];
      this.columns = [...gridColumns];

      this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
      this._changeDetectorRef.detectChanges();
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }
  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    this.tableWidth = this.mainWidth - 12;
  }
  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'userGroup', this.table._internalColumns);   
  }

  public getUserGroups(): void {
    if(this.delayedRefreshStart){
      this.delayedRefreshStart = false;
    }
    this.isLoading = true;
    let groupData:any = [];
    const userGroupDataSubscription: Subscription = this.userService.getUserGroups()
    .subscribe((data:any)=>{
      data.forEach((d:any) => {
        let members = '';
        if(d.userids && d.userids.length>0){
          if(d.userids.length === 1){
            members = d.userids[0];
          }else if(d.userids.length === 2){
            members = d.userids[0]+', ' + d.userids[1];
          }else if(d.userids.length > 2){
            members = d.userids[0]+', ' + d.userids[1] + ', & <span class="member-others" title="'+ d.userids.join(', ')+'">'+(d.userids.length-2)+' others</span>';
          }
        }
        d.members = members;
        groupData.push(d);
      });
      this.rows =[...groupData].reverse();
      if(this.initialLoad == true){
        this.totalRowCount = this.rows.length;
        this.initialLoad = false;
      }
       this.tableRowData = [...this.rows];
      this.isLoading = false;

    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(userGroupDataSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  onSelect(rowData: any) {
    this.selected = [...rowData.selected];

    if(this.selected.length != 1){
      this.showSummaryPanel = false;
    }
    if(this.selected.length == 1){
      if(rowData.selected[0].organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
        this.editDisabled = true;
      }else{
        this.editDisabled = false;
      }
    }else{
      this.editDisabled = true;
    }
    this.groupObject = this.selected[0];
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'User-groups-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.tableRowData];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.userGroupName.toLowerCase().indexOf(val) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.rows = temp;
  }
  closeSidePanel(reloadList){
    this.showSummaryPanel = false;
    this.selected = [];
    this.editDisabled = true;
    if (reloadList) {
      this.delayedRefreshStart = true;
    }
  }

  addGroup(){
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  editGroup(){
    this.mode = 'edit';
    this.showSummaryPanel = true;
  }
  getAllUsers(){
    const userGroupSubs:Subscription = this.userService.getAllUser(true).subscribe((data:any)=>{
      this.allUserObject = data;
    }, (error) => {
      console.error(error);
    });
    this.subscriptions.push(userGroupSubs);
  }
  deleteUserGroup(){
    this.isLoading = true;
    let message = `Are you sure you want to delete this group?: `+this.selected[0].userGroupName;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let payload = {
          "active":this.selected[0].active,
          "description":this.selected[0].description,
          "tenantId":this.selected[0].tenantId,
          "userGroupName":this.selected[0].userGroupName
       }
       const deleteuserSub:Subscription = this.userService.deleteUserGroup(payload).subscribe(data=>{
          this.delayedRefreshStart = true;
          let action = 'Group deleted successfully';
          this.openSnackBar(action, this.selected[0].userGroupName);
          this.selected = [];
          this.isLoading = false;
        }, (error) => {
            console.error(error);
            let action = 'Something went wrong';
           this.openSnackBar(action, this.selected[0].userGroupName);
           this.isLoading = false;
          });

          this.subscriptions.push(deleteuserSub);
      }else{
        this.isLoading = false;
      }
    });
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  loadSummaryPanel(groupObject:any, event:any){
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', groupObject: groupObject });
  }
}
