import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { UserService } from '../../user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { PersistenceService } from '@cl/common/services/persistence.service';

@Component({
  selector: 'cl-user-policy-list',
  templateUrl: './user-policy-list.component.html',
  styleUrls: ['./user-policy-list.component.scss']
})
export class UserPolicyListComponent implements OnInit, AfterViewInit {
  @ViewChild('policyNameTemplate') policyNameTemplate: TemplateRef<any>;
  @ViewChild('policyTable') policyTable

  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  private event = new Subject<any>();
  showFilterPanel = true;
  actionCount: number = 0;
  SelectedPolicy:any={};
  mode = 'add';
  showSummaryPanel = false;
  private selectedPolicyType:any[]=[];
  private selectedEntityType:any[]=[];
  totalRowCount = 0;
  actionBtnPolicies = {
    add: ['POLICY_CREATE'],
    edit: ['POLICY_UPDATE'],
    delete: ['POLICY_DELETE']
  }


  private policyType = {
    'collapsed':false,
    'enableCollapsing':false,
    'filterType':'Policy Type',
    'label':'Policy Type',
    'type':'multiselect',
    'list':[{
      'checked':false,
        'name':'Custom Policies',
        'entityId':'CUSTOMER_MANAGED',
        'filterType':'Policy Type',
    },
    {
      'checked':false,
        'name':'System Policies',
        'entityId':'CLOUDLEAF_MANAGED',
        'filterType':'Policy Type',
    }
  ]
  };
  mainWidth: any;
  tableWidth: number;
  columnKeys: string[] = [];
  filterArray = [];
  initialLoad: boolean = true;
  isShowTable: boolean = true;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private el: ElementRef,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private persistenceStore : PersistenceService
  ) {
  }

  ngOnInit(): void {
    this.getPolicylist(null);
    this.getEntityFilters();
    this.setContainerWidth();

    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.mode = 'view';
        this.showSummaryPanel = true;
        this.SelectedPolicy = evt.policyObject;
      }
    });
    this.subscriptions.push(eventSub);
  }

  ngAfterViewInit(): void {
    this.table()
  }
  table(){
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true
      },
      { name: 'Policy Name', prop: 'name', cellTemplate:this.policyNameTemplate, width: 180, visible: true },
      { name: 'Entity Name', prop: 'entityUILabel', width: 200, visible: true },
      { name: 'Actions', prop: 'actions', width: 150, visible: true },
      { name: 'Policy Type', prop: 'policyType', width: 150, visible: true }];
      const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('policy', [...this.rawColumns]);
      this.rawColumns = [...rawColumns];
      this.columns = [...gridColumns];

    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
    this._changeDetectorRef.detectChanges();
  }
  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 268;
    } else {
      this.tableWidth = this.mainWidth - 18;
    }
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'policy', this.policyTable._internalColumns);   
  }

  private getPolicylist(data:any): void {
    this.isLoading = true;
    const userPolicyListData: Subscription = this.userService.postUserPolicies(data).subscribe((data:any)=>{
      this.rows = [...data];
      this.tableRowData = [...this.rows];
      this.isLoading = false;
      if(this.initialLoad == true){
        this.totalRowCount = this.rows.length;
        this.initialLoad = false;
      }

    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(userPolicyListData);
  }

  private getEntityFilters():void{
    const getEntityFilterData: Subscription = this.userService.getEntityForUserRoleFilters().subscribe((data:any)=>{
    this.filterArray = [];
    let listArray:any = [];
      data.forEach((d:any) => {
        listArray.push(new Object({
        'checked':false,
        'name':d.uiLabel,
        'entityId':d.entityId,
        'filterType':'Entity Type',
      }));
    });
    this.filterArray.push(this.policyType);
    this.filterArray.push(new Object({
      'expand': true,
      'collapsed':false,
      'enableCollapsing':false,
      'filterType':'Entity Type',
      'label':'Entity Type',
      'type':'multiselect',
      'list':listArray
    }));

      this.filters = [...this.filterArray];
    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(getEntityFilterData);
  }


  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  onSelect(rowData: any) {
    this.selected = rowData.selected;
    if(this.selected.length == 1){
      this.SelectedPolicy = this.selected[0];
    }else{
      this.showSummaryPanel = false;
    }
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'User-policies-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.tableRowData];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(val) !== -1 );
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.rows = temp;
  }

  toggleFilterPanel() {
    this.isShowTable = false;
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
    this.table()
    this.isShowTable = true;
  }

  getPanelUpdate(event:any){
    if(event.action && event.action == "clearFilters"){
      this.selectedPolicyType = [];
      this.selectedEntityType = [];
      this.filters = [...this.filterArray];
    }else{
      let filterEvent = event.filter;
      if(filterEvent.checked){
          if(filterEvent.filterType === 'Policy Type'){
            this.selectedPolicyType.push(filterEvent.entityId);
          }else if(filterEvent.filterType === 'Entity Type'){
            this.selectedEntityType.push(filterEvent.entityId);
          }
      }else{
        if(filterEvent.filterType === 'Policy Type'){
          let index = this.selectedPolicyType.indexOf(filterEvent.entityId);
          this.selectedPolicyType.splice(index, 1);
        }else if(filterEvent.filterType === 'Entity Type'){
          let index = this.selectedEntityType.indexOf(filterEvent.entityId);
          this.selectedEntityType.splice(index, 1);
        }
      }
    }
      this.createPayloadAndReloadPolicyList();
  }


  createPayloadAndReloadPolicyList(){
    let payLoad = new Object({
      entities:this.selectedEntityType.length ? this.selectedEntityType: undefined,
      policyType:this.selectedPolicyType.length ? this.selectedPolicyType: undefined
    });
    this.getPolicylist(payLoad);
  }

  closeSidePanel(isReload = false){
    this.showSummaryPanel = false;
    if(isReload) this.createPayloadAndReloadPolicyList();
  }

  addPolicy(){
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  editPolicy(){
    this.mode = 'edit';
    this.showSummaryPanel = true;
  }

  getId(row) {
    return row.id;
  }

  deletePolicy(){

    this.isLoading = true;
    let message = `Are you sure you want to delete this policy?: `+this.selected[0].name;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
       const deleteSub:Subscription = this.userService.deleteItemFromList('policy',this.selected[0].id).subscribe(data=>{
        this.getPolicylist(null);
          let action = 'policy deleted successfully';
          this.openSnackBar(action, this.selected[0].name);
          this.selected = [];
          this.isLoading = false;
        }, (error) => {
            console.error(error);
            let action = 'Something went wrong';
           this.openSnackBar(action, this.selected[0].name);
           this.isLoading = false;
          });

          this.subscriptions.push(deleteSub);
      }else{
        this.isLoading = false;
      }
    });
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }


  loadSummaryPanel(policyObject:any, event:any){
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', policyObject: policyObject });
  }
}
