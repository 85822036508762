import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AssetStatus, CL_API_DELAY, CL_API_DELAY_SHORT } from "../../common/constants/index";
import {
  AggregatedCountApiResponse,
  AssetCategory,
  CustodyActions,
  ElasticHit,
  ElasticSearchQuery,
  Entity,
  FilterGroup,
  FilterGroupList,
  SearchQueryRequest
} from "../../common/models/index";

import {
  HidePanel,
  MalBindAsset,
  MalBulkBindSuccess,
  MalCancelBind,
  MalClearAllSelected,
  MalClearFilters,
  MalCreateAsset,
  MalDeleteAssets,
  MalDownloadAssets,
  MalEditAsset,
  MalFormUpdated,
  MalInitCategories,
  MalInitFilterSet,
  MalOpenBindPanel,
  MalReset,
  MalSaveCategory,
  MalSearchByFilter,
  MalSearchByTerm,
  MalSearchListScrolled,
  // MalSelectCategoryPanel,
  MalSelectItem,
  MalSelectSensor,
  MalSelectMultipleSensor,
  MalSortAssetList,
  MalToggleAddAsset,
  MalToggleAllCategorySelection,
  MalToggleCategorySelection,
  MalToggleCheckInPanel,
  MalToggleCheckoutPanel,
  MalToggleConfirmReceiptPanel,
  MalToggleFilter,
  MalToggleItem,
  MalToggleSingleAsset,
  MalToggleTransferInServicePanel,
  MalToggleTransferPanel,
  MalUnbindAssets,
  MalUpdateAsset,
  MalUpdateColumnDefs,
  MalUpdateCounts,
  MalSelectAllItems,
  ShowPanel,
  MalAssetDetailNavigation,
  MalColumnSorted,
  MalMultiBindAsset,
  MalMultiScanAsset
} from "../../common/actions/index";
import { Action, Actions, ofActionSuccessful, State, StateContext, Store } from "@ngxs/store";
import { FiltersUtilService } from '../../common/utils/filters-util.service';
import { UtilsService } from '../../common/utils/utils.service';
import { SortUtilService } from '../../common/utils/sort-util.service';
import { AssetListService } from '../asset-list/asset-list.service';
import { SearchApiService } from '../../common/services/search-api.service';
import * as _ from "lodash";
import { combineLatest, EMPTY, Observable, of, throwError, timer } from "rxjs";
import { catchError, delay, finalize, map, switchMap, tap } from "rxjs/operators";
import { AdditionalAssetFilter } from "@cl/@types/asset.type";
import { ToastService } from "@cl/common/services/toast.service";
import { isEmpty } from "lodash";

const DEFAULT_STATE = {
  sortField: null,
  searchTerm: "",
  filterSet: [
    {
      name: "Assets",
      class: "asset",
      filterType: "status",
      fieldName: "clfMappingType",
      count: 0,
      enabled: true,
      filters: [],
      selectedFilters: {},
    },
  ],
  categories: [],
  initialFilterSet: [
    {
      name: "Assets",
      class: "asset",
      filterType: "status",
      fieldName: "clfMappingType",
      order: 4,
      enabled: true,
      count: 0,
      filters: [],
      selectedFilters: {},
    },
  ],
  searchList: [],
  searchFields: [
    {
      fieldName: "baseClass",
      queryOperator: "should",
      queryText: "asset",
      queryType: "match",
      childFilters: [],
    },
  ],
  scrollId: "",
  totalAssets: 0,
  numAssets: 0,
  allLoaded: false,
  activeEntity: null,
  selectedItems: {},
  assetList: [],
  currentAction: "",
  addingAsset: false,
  editingAsset: false,
  bindingAsset: false,
  bulkBind: "",
  headerTitle: "New Asset(s)",
  singleAssetSelected: true,
  nodeToEdit: {
    name: "",
    assetId: "",
    category: "",
    svgName: "asset",
    uid: "",
  },
  selectedSensor: {},
  loading: false,
  addCategory: false,
  editCategory: false,
  catPanelNum: 1,
  modificationInProgress: false,
  assetListColumnDefs: '',
  custodyActionInProgress: null,
  assetBootstrapped: false,
  selectedSensorList : []
};

@State<any>({
  name: "manage_assets_state",
  defaults: DEFAULT_STATE,
})
@Injectable()
export class MdListState {
  params = {};
  filters: any[] = [];
  constructor(
    private actions$: Actions,
    private store: Store,
    private _searchApiService: SearchApiService,
    private _filtersUtil: FiltersUtilService,
    private _assetListService: AssetListService,
    private _sortUtils: SortUtilService,
    private _utils: UtilsService,
    private _toastService: ToastService
  ) {
    this.reloadAssetsListHook();
  }

  // -----------------------------------------------------------
  // #region Hookups
  // -----------------------------------------------------------

  private reloadAssetsListHook() {
    this.actions$
      .pipe(
        ofActionSuccessful(MalSaveCategory, MalSortAssetList),

        catchError(() => EMPTY),

        switchMap((action) => {
          return this.store.dispatch(new MalSearchByFilter(true)).pipe(catchError((err) => of({})));
        })
      )
      .subscribe();
  }

  // -----------------------------------------------------------
  // #endregion
  // -----------------------------------------------------------

  // -----------------------------------------------------------
  // #region Asset CURD
  // -----------------------------------------------------------

  @Action(MalReset)
  malReset({ setState, getState }: StateContext<any>, action: MalReset) {
    const state = getState();

    setState({
      ...getState(),
      ...DEFAULT_STATE,
      currentAction: "malReset",

      // Mention fields you don't want to reset
      filterSet: state.filterSet,
      categories: state.categories,
      initialFilterSet: state.initialFilterSet,
      searchFields: state.searchFields, // this.prepareSearchFields(state.filterSet, state.categories),
      assetListColumnDefs: state.assetListColumnDefs,
    });
  }

  @Action(MalSortAssetList)
  malSortAssetList({ patchState }: StateContext<any>, action: MalSortAssetList) {
    return patchState({
      currentAction: "malSortAssetList",
      assetListColumnDefs: action.columnDefs,
    });
  }

  @Action(MalCreateAsset)
  malCreateAsset(ctx: StateContext<any>, action: MalCreateAsset) {
    let state = ctx.getState();
    let body = {
      externalId: action.node.assetId,
      name: action.node.name || action.node.assetId,
      typeId: action.node.category,
      sku: action.node.sku,
      cost: action.node.cost,
      manufacturer: action.node.manufacturer,
      condition: action.node.condition,
      warehouseLocation: action.node.warehouseLocation,
      // warehouseLocationId: action.node.warehouseLocationId,
      // locationId: action.node.locationId,
      maintenanceDate: action.node.maintenanceDate,
      qrCode: action.node.qrCode,
    };
    // console.log('save', body);

    ctx.setState({
      ...state,
      assetSaveInProgress: true,
    });

    return this._assetListService.createGraphNode(body, "asset").pipe(
      delay(CL_API_DELAY),

      finalize(() => {
        ctx.patchState({ assetSaveInProgress: false });
      }),

      tap((res) => {
        state = ctx.getState();
        // console.log('saved asset', res);

        const newAsset = new Entity().deserialize(res);
        newAsset.nodeClass = "asset";
        newAsset.svgName = "asset";
        ctx.setState({
          ...state,
          currentAction: "Asset created",
          activeEntity: newAsset,
          editingAsset: false,
          addingAsset: false,
          searchList: [newAsset, ...state.searchList],
          numAssets: state.numAssets + 1,
          totalAssets: state.totalAssets + 1,
        });
      }),

      switchMap((_) =>
        combineLatest([ctx.dispatch(new MalSearchByFilter(true)), ctx.dispatch(new HidePanel())])
      )
    );
  }
  @Action(MalUpdateAsset)
  malUpdateAsset(ctx: StateContext<any>, action: MalUpdateAsset) {
    let state = ctx.getState();
    let body = {
      name: action.node.name,
      id: action.node.id,
      cost: action.node.cost,
      condition: action.node.condition,
      manufacturer: action.node.manufacturer,
      sku: action.node.sku,
      warehouseLocation: action.node.warehouseLocation,
      // warehouseLocationId: action.node.warehouseLocationId,
      // locationId: action.node.locationId,
      maintenanceDate: action.node.maintenanceDate,
      qrCode: action.node.qrCode,
    };

    ctx.setState({
      ...state,
      nodeToEdit: _.cloneDeep(action.node),
      assetSaveInProgress: true,
      modificationInProgress: true,
    });

    return this._assetListService.updateGraphNode(body, "asset", action.node.id).pipe(
      tap((res) => {
        state = ctx.getState();
        // console.log('updated node', res);
        const updatedAsset = { ...action.node, ...res };
        ctx.setState({
          ...state,
          currentAction: "Asset updated",
          activeEntity: updatedAsset,
          addingAsset: false,
          nodeToEdit: _.cloneDeep(updatedAsset),
          assetSaveInProgress: false,
          modificationInProgress: false,
          disableActions: false,
          searchList: state.searchList.map((asset: any) => {
            if (asset.id === updatedAsset.id) {
              asset = { ...updatedAsset };
            }
            return asset;
          }),
        });
        // setTimeout(() => {
        //   return ctx.dispatch(new MalSearchByFilter(true));
        // }, 2000);
      }),

      switchMap((_) => ctx.dispatch([new HidePanel(), new MalToggleAddAsset()])),

      delay(CL_API_DELAY),

      switchMap((_) => ctx.dispatch([new MalSearchByFilter(true)])),

      finalize(() =>
        ctx.patchState({
          assetSaveInProgress: false,
          modificationInProgress: false,
        })
      )
    );
  }

  @Action(MalDeleteAssets)
  malDeleteAssets(ctx: StateContext<any>, action: MalDeleteAssets) {
    let state = ctx.getState();
    // const uids = action.assetIDs;
    const numDeleted = action.assetIDs.length;
    const idsObj: any = {};
    action.assetIDs.forEach((id: any) => {
      idsObj[id] = true;
    });

    ctx.patchState({
      modificationInProgress: true,
      actionPerformed: "deleteAsset",
    });

    return this._assetListService.deleteAssets(action.assetIDs).pipe(
      delay(CL_API_DELAY_SHORT),

      finalize(() => {
        ctx.patchState({
          modificationInProgress: false,
        });
      }),

      tap((res) => {
        state = ctx.getState();

        const assetList = _.cloneDeep(action.assetList);

        ctx.setState({
          ...state,
          currentAction: "Asset(s) deleted",
          activeEntity: null,
          addingAsset: false,
          assetList,
          nodeToEdit: null,
          selectedItems: {},
          modificationInProgress: false,
          actionPerformed: "",
          // numAssets: state.numAssets - numDeleted,
          // totalAssets: state.totalAssets - numDeleted,
          // searchList: state.searchList.filter((asset) => {
          //   return !idsObj[asset.id];
          // }),
        });
      }),

      switchMap((_) => combineLatest([ctx.dispatch(new MalSearchByFilter(true)), ctx.dispatch(new MalInitCategories())])),

      finalize(() =>
        ctx.patchState({
          modificationInProgress: false,
          actionPerformed: "",
        })
      )
    );
  }

  @Action(MalFormUpdated)
  malFormUpdated(ctx: StateContext<any>, action: MalFormUpdated) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      nodeToEdit: {
        ...state.nodeToEdit,
        [action.field]: action.value,
      },
      currentAction: "malFormUpdated",
    });
  }

  @Action(MalEditAsset)
  malEditAsset(ctx: StateContext<any>, action: MalEditAsset) {
    const state = ctx.getState();

    const nodeToEdit = _.size(action.node) ? _.cloneDeep(action.node) : state.nodeToEdit;

    ctx.setState({
      ...ctx.getState(),
      addingAsset: false,
      bindingAsset: false,
      editingAsset: true,
      showCheckout: false,
      showTransfer: false,
      showTransferInService: false,
      showCheckIn: false,
      showConfirmReceipt: false,
      showReturn: false,
      nodeToEdit: {
        ...nodeToEdit,
        svgName: "asset",
      },
      headerTitle: "Edit Asset",
      currentAction: "malEditAsset",
    });

    if (!state.addingAsset) {
      ctx.dispatch(new ShowPanel("inspector"));
    }
  }

  @Action(MalToggleAddAsset)
  malToggleAddAsset(ctx: StateContext<any>, action: MalToggleAddAsset) {
    const state = ctx.getState();
    let mode = state.addingAsset || state.bindingAsset;
    ctx.setState({
      ...state,
      addingAsset: !mode,
      editingAsset: false,
      bindingAsset: false,
      currentAction: "malToggleAddAsset",
      headerTitle: "New Asset(s)",
      nodeToEdit: {
        name: "",
        assetId: "",
        category: "",
        svgName: "asset",
        uid: "",
      },
    });
    if (state.addingAsset || state.bindingAsset || state.editingAsset) {
      return ctx.dispatch(new HidePanel("inspector"));
    } else {
      return ctx.dispatch(new ShowPanel("inspector"));
    }
  }

  @Action(MalToggleSingleAsset)
  malToggleSingleAsset(ctx: StateContext<any>, action: MalToggleSingleAsset) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      singleAssetSelected: !state.singleAssetSelected,
      currentAction: "malToggleSingleAsset",
    });
  }

  @Action(MalToggleItem)
  malToggleItem(ctx: StateContext<any>, action: MalToggleItem) {
    const state = ctx.getState();
    let activeEntity = _.cloneDeep(state.activeEntity);
    let totalAssets = state.totalAssets;
    const selectedItems = { ...state.selectedItems };
    let searchList = [...state.searchList];
    let selectedRow = { ...action.node };
    let Obj = Object.values(selectedItems);
    let selectedItemIndex = Obj.findIndex((item:any) => item.id == selectedRow.id);
    if (selectedItems[selectedRow?.id] && action.clickAction !== 'onSelect') {
      delete selectedItems[selectedRow.id];
      if(Object.keys(selectedItems) === undefined){
        delete selectedItems.undefined;
      }
      if (activeEntity?.id === selectedRow.id && selectedItemIndex === -1) {
        activeEntity = null;
        ctx.dispatch(new HidePanel("inspector"));
      }
    } else {
        let sLength = Object.keys(selectedItems).length;
        let currentRowIndex = searchList.findIndex((item:any) => item.id == selectedRow.id);
        if(selectedItemIndex > -1){
          delete selectedItems[selectedItemIndex];
        }else{
        searchList.splice(currentRowIndex,1);
        searchList.splice(sLength,0,selectedRow);
        }
        selectedItems[selectedRow?.id] = selectedRow;
    }
    ctx.setState({
      ...state,
      searchList: [...searchList],
      isFilterAction: false,
      activeEntity: { ...activeEntity },
      currentAction: "malToggleItem",
      // totalAssets: Array.prototype.unshift.apply(state.totalAssets, selectedItems),
      selectedItems: _.cloneDeep(selectedItems),
      // nodeToEdit: { ...state.nodeToEdit, ...action.node },
    });
  }

  @Action(MalAssetDetailNavigation)
  MalAssetDetailNavigation(ctx: StateContext<any>, action: MalSelectItem) {
    const state = ctx.getState();
    const selectedItems = _.cloneDeep(state.selectedItems);

    let activeEntity = state.activeEntity && action.node && action.node.id === state.activeEntity.id ? null : action.node;
    if (!activeEntity && _.size(state.selectedItems) === 1) {
      let selectedItem = {
        ...state.selectedItems[Object.keys(state.selectedItems)[0]],
      };
      activeEntity = state.activeEntity.id === selectedItem.id ? null : selectedItem;
    }
    if (!activeEntity) {
      delete selectedItems[action.node.id];
    } else {
      selectedItems[activeEntity.id] = { ...activeEntity };
    }
    ctx.setState({
      ...state,
      singleAssetSelected: true,
      addingAsset: false,
      editingAsset: action.editNode,
      bindingAsset: action.bindNode,
      nodeToEdit: _.cloneDeep(activeEntity),
      activeEntity: _.cloneDeep(activeEntity),
      selectedItems: { ...selectedItems },
      currentAction: "malAssetDetailNavigation"
    });
  }

  @Action(MalSelectItem)
  malSelectItem(ctx: StateContext<any>, action: MalSelectItem) {
    const state = ctx.getState();
    const selectedItems = _.cloneDeep(state.selectedItems);

    let activeEntity = state.activeEntity && action.node && action.node.id === state.activeEntity.id ? null : action.node;
    if (!activeEntity && _.size(state.selectedItems) === 1) {
      let selectedItem = {
        ...state.selectedItems[Object.keys(state.selectedItems)[0]],
      };
      activeEntity = state.activeEntity.id === selectedItem.id ? null : selectedItem;
    }
    if (!activeEntity) {
      delete selectedItems[action.node.id];
    } else {
      selectedItems[activeEntity.id] = { ...activeEntity };
    }
    ctx.setState({
      ...state,
      singleAssetSelected: true,
      addingAsset: false,
      editingAsset: action.editNode,
      bindingAsset: action.bindNode,
      nodeToEdit: _.cloneDeep(activeEntity),
      activeEntity: _.cloneDeep(activeEntity),
      selectedItems: { ...selectedItems },
      headerTitle: "Edit Asset",
      currentAction: "malSelectItem",
    });
    if (activeEntity) {
      return ctx.dispatch(new ShowPanel("inspector"));
    } else {
      return ctx.dispatch(new HidePanel("inspector"));
    }
  }
  @Action(MalClearAllSelected)
  malClearAllSelected(ctx: StateContext<any>, action: MalClearAllSelected) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      selectedItems: {},
      // toggleAll: toggleAll,
      // searchList: [...searchList],
      currentAction: "malClearAllSelected",
    });
  }

  @Action(MalColumnSorted)
  malColumnSorted(ctx: StateContext<any>, action: MalColumnSorted) {
    let state = ctx.getState();
    ctx.setState({
      ...state,
      sortField: action.sortField,
    })
    ctx.dispatch(new MalSearchByFilter(true));
  }

  @Action(MalSearchByTerm)
  malSearchByTerm(ctx: StateContext<any>, action: MalSearchByTerm) {
    let state = ctx.getState();
    let checkSelectedItem = state.selectedItems;
    let prams: any = {
      scrollId: "",
      match: "must",
      scrollSize: 50,
      globalQueryText: action.searchTerm,
      searchQueries: state.searchFields,
    };
    if(state.sortField) {
      prams.sortField = state.sortField;
    }
    this.params = prams;

    ctx.patchState({
      allLoaded: false,
      loading: true,
    });

    return this._searchApiService.assetEntitySearch(checkSelectedItem,'asset',this.params, false).pipe(
      catchError((err) => {
        this._utils.showMessage("Failed to load assets");
        return throwError(err);
      }),
      finalize(() => ctx.patchState({ loading: false })),
      map((res: any) => {
        // Fetch updated state
        state = ctx.getState();

        const {selectedAssets, listingAssets} = res;
        let selectedRows:any= [];
        if(Object.keys(checkSelectedItem).length > 0){
          selectedRows = selectedAssets?.hits;
        }else{
          selectedRows = [];
        }
        let newList = action.newSearch ? listingAssets.hits.slice() : [...state.searchList, ...listingAssets.hits];

        const totalAssets = listingAssets.totalHits; // || state.totalAssets;
        const numAssets = newList.length + selectedRows.length;
        return ctx.setState({
          ...state,
          searchList: [...selectedRows, ...newList],
          // searchFields: [...searchFields],
          scrollId: listingAssets._scroll_id,
          numAssets,
          totalAssets,
          selectedItems: [...selectedRows],
          currentAction: "malSearchByTerm",
          allLoaded: newList.length >= (listingAssets.totalHits + selectedRows.length)  ? true : false, //FIXME: 
          loading: false,
        });
      })
    );
  }

  @Action(MalSelectAllItems)
  selectAllItems({ getState, patchState }: StateContext<any>, action: MalSelectAllItems) {
    const { searchList = [] } = getState();
    const selectedItems = searchList.reduce((acc: any, curr: any) => {
      acc[curr.id] = curr;
      return acc;
    }, {});

    patchState({ selectedItems, currentAction: "malSelectAllItems" });
  }

  @Action(MalSearchListScrolled)
  malSearchListScrolled({ getState, setState, patchState, dispatch }: StateContext<any>, action: MalSearchListScrolled) {
    let state = getState();
    let checkSelectedItem = [];//state.selectedItems;
    if (state.loading || state.allLoaded) {
      console.warn("Already loading");
      return null;
    }
    if (!state.scrollId) {
      console.warn("no scroll id?");
    }

    patchState({
      loading: true,
    });

    let prams: any = {
      scrollId: state.scrollId,
      match: "must",
      scrollSize: 50,
      globalQueryText: state.searchTerm,
      searchQueries: state.searchFields,
    };

    if(state.sortField) {
      prams.sortField = state.sortField;
    }
    this.params = prams;

    return this._searchApiService.assetEntitySearch(checkSelectedItem,'asset',this.params, false).pipe(
      catchError((err) => {
        this._utils.showMessage("Failed to load assets");
        return throwError(err);
      }),
      finalize(() => patchState({ loading: false })),
      map((res: any) => {
        // Fetch updated state
        state = getState();

        const {selectedAssets, listingAssets} = res;
        let selectedRows:any= [];
        if(Object.keys(checkSelectedItem).length > 0){
          selectedRows = selectedAssets?.hits;
        }else{
          selectedRows = [];
        }
        let newList = [...state.searchList, ...listingAssets.hits];
        const totalAssets = newList.length>=listingAssets.totalHits ? newList.length : listingAssets.totalHits+ (selectedAssets?.totalHits || 0); // || state.totalAssets;
        const numAssets = newList.length + selectedRows.length;
        return setState({
          ...state,
          searchList: [...selectedRows, ...newList],
          // searchFields: [...searchFields],
          scrollId: listingAssets._scroll_id,
          numAssets,
          totalAssets,
          selectedItems: [...selectedRows],
          currentAction: "malSearchListScrolled",
          allLoaded: newList.length >= (listingAssets.totalHits +selectedRows.length)  ? true : false, //FIXME: 
          loading: false,
        });
      })
    );
  }
  @Action(MalSearchByFilter)
  malSearchByFilter(ctx: StateContext<any>, action: MalSearchByFilter) {
    let state = ctx.getState();
    let checkSelectedItem = state.selectedItems;
    // let searchFields;
    // let res = this._mapFilterService.getSearchFields(state.filterSet);
    // if (res.searchFields && res.searchFields.length) {
    //   searchFields = [{ ...res.searchFields[0] }]; // destructuring object at array 0
    // }
    let prams: any = {
      match: "must",
      scrollId: "",
      scrollSize: 50,
      // sortField: this._sortUtils.prepareSortFields(state.assetListColumnDefs),
      globalQueryText: state.searchTerm,
      searchQueries: [...state.searchFields],
    };

    if(state.sortField) {
      prams.sortField = state.sortField;
    }
    this.params = prams;

    ctx.patchState({
      loading: true,
      currentAction: "malSearchByFilter_InProgress",
    });

    return this._searchApiService.assetEntitySearch(checkSelectedItem,'asset',this.params, false).pipe(
      catchError((err) => {
        this._utils.showMessage("Failed to load assets");
        return throwError(err);
      }),
      finalize(() => ctx.patchState({ loading: false })),
      map((res: any) => {
        // Fetch updated state
        state = ctx.getState();

        const {selectedAssets, listingAssets} = res;
        let selectedRows:any= [];
        if(Object.keys(checkSelectedItem).length > 0){
          selectedRows = selectedAssets?.hits;
        }else{
          selectedRows = [];
        }
        let newList = action.newSearch ? listingAssets.hits.slice() : [...state.searchList, ...listingAssets.hits];
        const totalAssets = listingAssets.totalHits + (selectedAssets?.totalHits || 0); // || state.totalAssets;
        const numAssets = newList.length + selectedRows.length;
        return ctx.setState({
          ...state,
          searchList: [...selectedRows, ...newList],
          // searchFields: [...searchFields],
          scrollId: listingAssets._scroll_id,
          numAssets,
          totalAssets,
          selectedItems: [...selectedRows],
          currentAction: "malSearchByFilter",
          allLoaded: newList.length >= (listingAssets.totalHits +selectedRows.length)  ? true : false, //FIXME: 
          loading: false,
        });
      })
    );
  }

  @Action(MalDownloadAssets)
  malDownloadAssets({ patchState, getState }: StateContext<any>, action: MalDownloadAssets) {
    const state = getState();

    let params: SearchQueryRequest = {
      scrollId: "",
      match: "must",
      scrollSize: 50,
      // sortField: this._sortUtils.prepareSortFields(state.assetListColumnDefs),
      globalQueryText: state.searchTerm,
      outFields: action.columns, // Necessary to download specific columns only
      searchAfter: action.columns, // Necessary to download specific columns only
      searchQueries: [...state.searchFields],
    };
    if(state.sortField) {
      params.sortField = state.sortField;
    }

    patchState({
      currentAction: "malDownloadAssets_InProgress",
    });

    return this._searchApiService.globalSearchWithDownload(params, "assets-list", action.columnMapping).pipe(
      tap((res) => {
        return patchState({
          currentAction: "malDownloadAssets",
        });
      })
    );
  }

  /**
   * Prepares ES search query fields from selected filters and categories.
   */
  private prepareSearchFields(filters: FilterGroupList, categories: AssetCategory[]): ElasticSearchQuery[] {
    let searchFields = this._filtersUtil.prepareSearchFields(filters, "baseClass", "Asset");

    // Update: Removed type filter
    // Using categories as type filter
    const categorySearchFields = this._filtersUtil.prepareSearchFieldsForCategories(categories, "typeId");
    if (categorySearchFields) {
      searchFields[0].childFilters.push(categorySearchFields);
    }

    searchFields = _.cloneDeep(searchFields);

    return searchFields;
  }

  // -----------------------------------------------------------
  // #endregion
  // -----------------------------------------------------------

  // -----------------------------------------------------------
  // #region Asset categories
  // -----------------------------------------------------------

  @Action(MalInitCategories)
  malInitCategories({ getState, setState }: StateContext<any>, action: MalInitCategories) {
    const categoriesCount$ = this._searchApiService
      .getCounts({
        aggField: "typeId",
        baseClass: "asset",
      })
      .pipe(map((res) => res as AggregatedCountApiResponse[]));

    const rawCategories$ = this._assetListService.getCategories("asset").pipe(
      catchError((err) => {
        console.warn("error getting categories", err);

        return of([] as AssetCategory[]);
      })

      // map((res) => res as AssetCategory[])
    );

    return combineLatest([rawCategories$, categoriesCount$]).pipe(
      tap(([categories, counts]) => {
        if (categories && categories.length) {
          const typeIdCountMap = counts[0]['hits'][0].buckets?.reduce((acc: any, curr: any) => ({ ...acc, [curr.key]: curr.value }), {});

          const _categories = categories.map((cat: any) => ({
            ...cat,
            checked: false,
            count: typeIdCountMap[cat.id] || 0,
          }));

          setState({
            ...getState(),
            categories: _.cloneDeep(_categories),
            currentAction: "malInitCategories",
          });
        }
      })
    );
  }

  @Action(MalToggleCategorySelection)
  malToggleCategorySelection(ctx: StateContext<any>, action: MalToggleCategorySelection) {
    const state = ctx.getState();

    const categories: AssetCategory[] = _.cloneDeep(state.categories);
    const selectedCategory = categories.filter((cat) => cat.id === action.category.id);

    if (selectedCategory.length > 0) {
      selectedCategory[0].checked = !selectedCategory[0].checked;
    }

    ctx.setState({
      ...state,
      categories,
      currentAction: "malToggleCategorySelection",
    });

    return ctx.dispatch(new MalToggleFilter(""));
  }

  @Action(MalToggleAllCategorySelection)
  malToggleAllCategorySelection(ctx: StateContext<any>, action: MalToggleAllCategorySelection) {
    const state = ctx.getState();

    const categories: AssetCategory[] = _.cloneDeep(state.categories);
    const totalCategories = categories.length;
    const selectionCount = categories.filter((c) => c.checked).length;

    const updatedCategories = categories.map((cat) => ({
      ...cat,
      checked: !(selectionCount === totalCategories),
    }));

    ctx.setState({
      ...state,
      categories: updatedCategories,
      currentAction: "malToggleAllCategorySelection",
    });

    return ctx.dispatch(new MalToggleFilter(""));
  }


  // @Action(MalSelectCategoryPanel)
  // malSelectCategoryPanel(ctx: StateContext<any>, action: MalSelectCategoryPanel) {
  //   if (action.panel) {
  //     ctx.patchState({
  //       currentAction: "malSelectCategoryPanel",
  //       categoryActivePanel: action.panel,
  //     });
  //   }
  // }


  // -----------------------------------------------------------
  // #endregion
  // -----------------------------------------------------------

  // -----------------------------------------------------------
  // #region Sensor bind/unbind
  // -----------------------------------------------------------

  @Action(MalSelectSensor)
  malSelectSensor(ctx: StateContext<any>, action: MalSelectSensor) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      selectedSensor: { ...action.node },
      currentAction: "malSelectSensor",
    });
  }

  @Action(MalSelectMultipleSensor)
  malSelectMultipleSensor(ctx: StateContext<any>, action: MalSelectMultipleSensor) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      selectedSensors: [ ...action.node ],
      currentAction: "malSelectMultipleSensor",
    });
  }

  @Action(MalBindAsset)
  malBindAsset(ctx: StateContext<any>, action: MalBindAsset) {
    let state = ctx.getState();
    let boundAsset,
      selectedSensor,
      sensorId = state.selectedSensor.id,
      assetId = state.nodeToEdit.id;
    // console.log('save', body);

    ctx.setState({
      ...state,
      modificationInProgress: true,
      actionPerformed: "bindUnbind",
    });

    return this._assetListService.bindAsset(assetId, sensorId).pipe(
      catchError((err) => {
        let errorMessage = 'Failed to bind asset';
        if(Array.isArray(err?.error)){
          errorMessage = '';
          err.error.forEach(errorObj => {
            for (const sensorId in errorObj) {
              errorMessage += errorObj[sensorId] + '\n'
            }
          })
        }
      this._toastService.error(errorMessage)
        return throwError(err); // of(err);
      }),

      tap((res) => {
        state = ctx.getState();

        // boundAsset = _.cloneDeep({
        //   ...state.nodeToEdit,
        //   ...res,
        // });
        // selectedSensor = _.cloneDeep(state.selectedSensor);
        // boundAsset.properties.status = AssetStatus.Monitored;
        // selectedSensor.properties.status = SensorStatus.Active;

        const deserializedResponse = res;

        const selectedItems = {
          ...state.selectedItems,
          [res.id]: deserializedResponse,
        };

        ctx.setState({
          ...state,
          currentAction: "Asset bound",
          nodeToEdit: { ...deserializedResponse },
          activeEntity: _.cloneDeep(deserializedResponse),
          bindingAsset: false,
          addingAsset: false,
          editingAsset: false,
          selectedItems,
          // searchList: state.searchList.map((asset) => {
          //   if (asset.id === boundAsset.id) {
          //     asset = { ...boundAsset };
          //   }
          //   return asset;
          // }),
          selectedSensor: null,
          modificationInProgress: false,
          actionPerformed: "",
        });
      }),

      switchMap((_) => ctx.dispatch([new HidePanel()])),

      delay(CL_API_DELAY_SHORT),

      switchMap((_) => ctx.dispatch([new MalSearchByFilter(true)])),

      finalize(() => ctx.patchState({ modificationInProgress: false, actionPerformed: "" }))
    );
  }

  @Action(MalMultiBindAsset)
  malMultiBindAsset(ctx: StateContext<any>, action: MalMultiBindAsset) {
    let state = ctx.getState();
    let actionNodes = action.node;
    let boundAsset,
      selectedSensor,
      assetId = state.nodeToEdit.id,
      sensorList = []
    
    actionNodes.forEach((sensor: any, index: any) => {
      sensorList.push(new Object({
        'assetId' : assetId,
        'sensorId' : sensor.sensorId,
        'bindflow': sensor.bindflow,
        'componentId': sensor.component_id
      }))
    });
    ctx.setState({
      ...state,
      modificationInProgress: true,
      actionPerformed: "bindUnbind",
    });
    return this._assetListService.multiBindAsset(sensorList).pipe(
      catchError((err) => {
        let errorMessage = 'Failed to bind asset';
        if(Array.isArray(err?.error)){
          errorMessage = '';
          err.error.forEach(errorObj => {
            for (const sensorId in errorObj) {
              errorMessage += errorObj[sensorId] + '\n'
            }
          })
        }
      this._toastService.error(errorMessage)
        return throwError(err); // of(err);
      }),

      tap((res) => {
        state = ctx.getState();

        // boundAsset = _.cloneDeep({
        //   ...state.nodeToEdit,
        //   ...res,
        // });
        // selectedSensor = _.cloneDeep(state.selectedSensor);
        // boundAsset.properties.status = AssetStatus.Monitored;
        // selectedSensor.properties.status = SensorStatus.Active;

        const deserializedResponse = res;

        const selectedItems = {
          ...state.selectedItems,
          [res.id]: deserializedResponse,
        };

        ctx.setState({
          ...state,
          currentAction: "Asset bound",
          nodeToEdit: { ...deserializedResponse },
          activeEntity: _.cloneDeep(deserializedResponse),
          bindingAsset: false,
          addingAsset: false,
          editingAsset: false,
          selectedItems,
          // searchList: state.searchList.map((asset) => {
          //   if (asset.id === boundAsset.id) {
          //     asset = { ...boundAsset };
          //   }
          //   return asset;
          // }),
          selectedSensor: null,
          modificationInProgress: false,
          actionPerformed: "",
        });
      }),

      switchMap((_) => ctx.dispatch([new HidePanel()])),

      delay(CL_API_DELAY_SHORT),

      switchMap((_) => ctx.dispatch([new MalSearchByFilter(true)])),

      finalize(() => ctx.patchState({ modificationInProgress: false, actionPerformed: "" }))
    );
  }

  @Action(MalUnbindAssets)
  malUnbindAssets(ctx: StateContext<any>, action: MalUnbindAssets) {
    let state = ctx.getState();

    const taggedAssetId = action.boundAssets[0].taggedAssetId;

    ctx.setState({
      ...state,
      modificationInProgress: true,
      actionPerformed: "bindUnbind",
      currentAction: "unbind inprogress",
    });

    return this._assetListService.unbindAsset(taggedAssetId).pipe(
      catchError((err) => {
        // console.warn("Error binding asset", err); 
        this._utils.showMessage(err.error['error-message'], action.boundAssets[0]?.name);
        let unboundAsset = _.cloneDeep(action.boundAssets[0]);
        ctx.setState({
          ...state,
          currentAction: "Asset unbound",
          nodeToEdit: { ...unboundAsset },
          bindingAsset: false,
          assetList: [...action.assetList],
          addingAsset: false,
          activeEntity: _.cloneDeep(unboundAsset),
          modificationInProgress: false,
          actionPerformed: "",
          error: err.error['error-message']
          // searchList: state.searchList.map((asset) => {
          //   if (asset.id === unboundAsset.id) {
          //     asset = { ...unboundAsset };
          //   }
          //   return asset;
          // }),
        });
        return throwError(err);
      }),

      tap((res) => {
        state = ctx.getState();
        let unboundAsset = _.cloneDeep(action.boundAssets[0]);
        unboundAsset.status = AssetStatus.UnMonitored;
        delete unboundAsset.taggedAssetId;
        delete unboundAsset.sensorId;

        const selectedItems = {
          ...state.selectedItems,
          [unboundAsset.id]: unboundAsset,
        };

        // for multiple use  'Asset(s) unbound'
        ctx.setState({
          ...state,
          currentAction: "Asset unbound",
          nodeToEdit: { ...unboundAsset },
          bindingAsset: false,
          assetList: [...action.assetList],
          addingAsset: false,
          activeEntity: _.cloneDeep(unboundAsset),
          selectedItems,

          modificationInProgress: false,
          actionPerformed: "",
          error: ""
          // searchList: state.searchList.map((asset) => {
          //   if (asset.id === unboundAsset.id) {
          //     asset = { ...unboundAsset };
          //   }
          //   return asset;
          // }),
        });
      }),

      delay(CL_API_DELAY_SHORT),

      switchMap((_) => ctx.dispatch([new MalSearchByFilter(true), new HidePanel()])),

      finalize(() => ctx.patchState({ modificationInProgress: false, actionPerformed: "" }))
    );
  }

  @Action(MalCancelBind)
  malCancelBind(ctx: StateContext<any>, action: MalCancelBind) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bindingAsset: false,
      currentAction: "malCancelBind",
    });
  }

  @Action(MalOpenBindPanel)
  malOpenBindPanel(ctx: StateContext<any>, action: MalOpenBindPanel) {
    const state = ctx.getState();
    const nodeToEdit = action.node || state.nodeToEdit || state.selectedItems[Object.keys(state.selectedItems)[0]];
    ctx.setState({
      ...state,
      addingAsset: false,
      editingAsset: false,
      bindingAsset: true,
      bulkBind: action.type,
      showCheckout: false,
      showTransfer: false,
      showTransferInService: false,
      showCheckIn: false,
      showConfirmReceipt: false,
      showReturn: false,
      nodeToEdit: _.cloneDeep(nodeToEdit),
      headerTitle: "Bind Asset",
      currentAction: "malOpenBindPanel",
    });
  }

  @Action(MalBulkBindSuccess)
  MalBulkBindSuccess({ dispatch }: StateContext<any>, action: MalBulkBindSuccess) {
    return timer(CL_API_DELAY).pipe(switchMap((_) => combineLatest([dispatch(new HidePanel("inspector"))])));
  }

  // -----------------------------------------------------------
  // #endregion
  // -----------------------------------------------------------

  // -----------------------------------------------------------
  // #region Asset filters
  // -----------------------------------------------------------

  @Action(MalInitFilterSet)
  malInitFilterSet({ getState, setState, patchState, dispatch }: StateContext<any>, action: MalInitFilterSet) {
    let additionalAssetFilters: AdditionalAssetFilter[] = this._assetListService.getAdditionalAssetFilters() || [];
    let additionalString: string = ',';
    let aggField: string = 'typeId,monitoredStatus,locationName';
    if (additionalAssetFilters && additionalAssetFilters.length) {
      additionalAssetFilters.forEach((item: AdditionalAssetFilter) => {
        additionalString = additionalString + item.fieldName + ',';
      })
      aggField = aggField + additionalString.slice(0, -1);
    }
    const filterCount$ = this._searchApiService
      .getCounts({
        aggField: aggField,
        baseClass: "asset",
      })
      .pipe(map((res) => res as AggregatedCountApiResponse[]));

    const rawCategories$ = this._assetListService.getCategories("asset").pipe(
      catchError((err) => {
        console.warn("error getting categories", err);

        return of([] as AssetCategory[]);
      })

      // map((res) => res as AssetCategory[])
    );

    return combineLatest([rawCategories$, filterCount$]).pipe(
      tap(([categories, counts]) => {
        if (categories && categories.length) {
          const mappedCatIdNames = categories?.reduce((cat: any, curr: any) => ({...cat, [curr.properties.id]: curr.properties.name}), {});
          for (let key in mappedCatIdNames) {
              let index = _.findIndex(counts[0]['hits'][0].buckets, (bucket) => {
                return bucket.key === key
              })
              if (index > -1) {
                counts[0]['hits'][0].buckets[index].displayLabel = mappedCatIdNames[key];
              } else {
                counts[0]['hits'][0].buckets.push({
                  key,
                  subBucket: [],
                  displayLabel: mappedCatIdNames[key],
                  value: 0
                });
              }
          }
        }
        additionalAssetFilters.forEach((item: AdditionalAssetFilter) => {
          let index: number = counts.findIndex((hitItem: any) => hitItem.hits[0].name === item.fieldName );
          if (index > -1) {
            counts[index].hits[0].displayLabel = item.label;
            if (item.filterType === 'range' && item.datatype === 'double') {
              counts[index].hits[0].filterType = 'currencyrange';
            } else if (item.filterType === 'range' && item.datatype === 'date') {
              counts[index].hits[0].filterType = 'daterange';
            } else {
              counts[index].hits[0].filterType = 'multiselect';
            }
          }
        });

        let state = getState();

        const previousFilter = state.filterSet;

        let freshFilterSet = this._searchApiService.parseAggregateCountAsFilter(counts, "asset", ["state"]);
        // freshFilterSet.splice(3, 0, assetCostFilter, assetMaintenanceDateFilter);

        const filterSet = this._searchApiService.copyFilterStatus(previousFilter, freshFilterSet);
        let res;

        if (filterSet && filterSet.length) {
          res = this._filtersUtil.getSearchFields([filterSet]);
        } else {
          res = this._filtersUtil.getSearchFields([state.initialFilterSet]);
        }

        if (res.searchFields && res.searchFields.length) {
          patchState({
            searchFields: [{ ...res.searchFields[0] }],
          });
        }

        if (filterSet && filterSet.length) {
          setState({
            ...getState(),
            initialFilterSet: _.cloneDeep(filterSet),
            filterSet: _.cloneDeep(filterSet),
            isFilterAction: true,
            isLoaded: true,
            currentAction: "malInitFilterSet",
          });
        }

        delay(CL_API_DELAY_SHORT),

          switchMap((res) => dispatch(new MalUpdateCounts()))
      })
    );

     /* ----------updated-filter-API-call------------------ */
    /*  let state = getState();
     this._assetListService.getAssetFilterList('asset').then((filterData:any)=>{
      this.filters = filterData.map((entry: any) => {
        let filter: FilterGroup = {
          list: [],
          filterType: entry.labelKey,
          label: entry.label,
          type: entry.uiSelectType? entry.uiSelectType:"multiselect",
        };
        filter.list = entry.options.map(list => ({
          name: list.id,
            checked: !!false,
            count:(list.count>0?list.count:0),
            displayLabel: list.name,
            label: list.name
        }));
        filter.enableCollapsing = filter.list.length > 10;
        filter.collapsed = filter.list.length > 10;
        filter.expand = true;
        return filter;
      })

      const filterSet = this.filters;
      let res;

      if (filterSet && filterSet.length) {
        res = this._filtersUtil.getSearchFields([filterSet]);
      } else {
        res = this._filtersUtil.getSearchFields([state.initialFilterSet]);
      }

      if (res.searchFields && res.searchFields.length) {
        patchState({
          searchFields: [{ ...res.searchFields[0] }],
        });
      }
      if (filterSet && filterSet.length) {
        setState({
          ...getState(),
          initialFilterSet: _.cloneDeep(filterSet),
          filterSet: _.cloneDeep(filterSet),
          isFilterAction: true,
          isLoaded: true,
          currentAction: "malInitFilterSet",
        });
      }

    }) */
  }

  @Action(MalToggleFilter)
  malToggleFilter(ctx: StateContext<any>, action: MalToggleFilter) {
    const state = ctx.getState();

    let filters: FilterGroupList = state.filterSet;

    if (!_.isEmpty(action?.name)) {
      filters = action.range
        ? this._filtersUtil.toggleRangeFilter(state.filterSet, action.name, action.range)?.filters
        : this._filtersUtil.toggleFilter(state.filterSet, action.name)?.filters;
    }

    const searchFields = this.prepareSearchFields(filters, state.categories);

    ctx.setState({
      ...state,
      searchFields: searchFields && searchFields.length ? [{ ...searchFields[0] }] : state.searchFields,
      isFilterAction: true,
      currentAction: "malToggleFilter",
      filterSet: _.cloneDeep(filters),
      // tags: res.tags,
      loading: true,
      allLoaded: false,
    });

    return ctx.dispatch(new MalSearchByFilter(true));
  }

  @Action(MalClearFilters)
  malClearFilters(ctx: StateContext<any>, action: MalClearFilters) {
    const state = ctx.getState();

    const initialFilters = _.cloneDeep(state.initialFilterSet);
    const searchFields = this._filtersUtil.prepareSearchFields(initialFilters, "baseClass", "Asset");

    ctx.setState({
      ...state,
      currentAction: "malClearFilters",
      searchFields: searchFields && searchFields?.length ? [{ ...searchFields[0] }] : [],
      filterSet: initialFilters,
      allLoaded: false,
      loading: true,
    });

    return ctx.dispatch(new MalSearchByFilter(true));
  }

  @Action(MalUpdateCounts)
  malUpdateCounts({ getState, setState, patchState }: StateContext<any>, action: MalUpdateCounts) {
    this._searchApiService.getCounts().pipe(
      tap((res) => {
        // console.log('load es counts', res);
        let counts = {
          asset: res,
        };

        const countsObj = this._searchApiService.parseAggregateCounts(counts);

        const state = getState();

        const filters = this._searchApiService.updateMgCounts(countsObj, state.filterSet);

        setState({
          ...getState(),

          initialFilterSet: _.cloneDeep(filters),
          filterSet: _.cloneDeep(filters),
          totalAssets: countsObj["asset"].total > state.totalAssets ? countsObj["asset"].total : state.totalAssets,
          currentAction: "malUpdateCounts",
        });
      })
    );
  }

  // -----------------------------------------------------------
  // #endregion
  // -----------------------------------------------------------

  // -----------------------------------------------------------
  // #region Chain of custody
  // -----------------------------------------------------------

  private handleCustodyActionError(err: any, errorMessage: string, assetId: string): Observable<any> {
    if (err instanceof HttpErrorResponse && err.error["error-message"]) {
      errorMessage = err.error["error-message"];
    }
    this._utils.showMessage(errorMessage, assetId);
    return throwError(err);
  }

  @Action(MalToggleCheckoutPanel)
  malToggleCheckoutPanel({ getState, setState }: StateContext<any>, action: MalToggleCheckoutPanel) {
    setState({
      ...getState(),
      addingAsset: false,
      editingAsset: false,
      bindingAsset: false,
      showCheckout: action.showCheckout,
      showTransfer: false,
      showTransferInService: false,
      showCheckIn: false,
      showConfirmReceipt: false,
      showReturn: false,
      nodeToEdit: _.cloneDeep(action.node),
      headerTitle: "Checkout",
      currentAction: "malToggleCheckoutPanel",
    });
  }

  @Action(MalToggleTransferPanel)
  malToggleTransferPanel({ getState, setState }: StateContext<any>, action: MalToggleTransferPanel) {
    setState({
      ...getState(),
      addingAsset: false,
      editingAsset: false,
      bindingAsset: false,
      showCheckout: false,
      showTransferInService: false,
      showTransfer: action.showTransfer,
      showCheckIn: false,
      showConfirmReceipt: false,
      showReturn: false,
      nodeToEdit: _.cloneDeep(action.node),
      headerTitle: "Transfer",
      currentAction: "malToggleTransferPanel",
    });
  }

  @Action(MalToggleTransferInServicePanel)
  malToggleTransferInServicePanel({ getState, setState }: StateContext<any>, action: MalToggleTransferInServicePanel) {
    setState({
      ...getState(),
      addingAsset: false,
      editingAsset: false,
      bindingAsset: false,
      showCheckout: false,
      showTransfer: false,
      showTransferInService: action.showTransferInService,
      showCheckIn: false,
      showConfirmReceipt: false,
      showReturn: false,
      nodeToEdit: _.cloneDeep(action.node),
      headerTitle: "Transfer in service",
      currentAction: "malToggleTransferInServicePanel",
    });
  }

  @Action(MalToggleCheckInPanel)
  malToggleCheckInPanel({ getState, setState }: StateContext<any>, action: MalToggleCheckInPanel) {
    setState({
      ...getState(),
      addingAsset: false,
      editingAsset: false,
      bindingAsset: false,
      showCheckout: false,
      showTransfer: false,
      showTransferInService: false,
      showCheckIn: action.showCheckIn,
      showConfirmReceipt: false,
      showReturn: false,
      nodeToEdit: _.cloneDeep(action.node),
      headerTitle: "Check in",
      currentAction: "malToggleCheckInPanel",
    });
  }

  @Action(MalToggleConfirmReceiptPanel)
  malToggleConfirmReceiptPanel({ getState, setState }: StateContext<any>, action: MalToggleConfirmReceiptPanel) {
    setState({
      ...getState(),
      addingAsset: false,
      editingAsset: false,
      bindingAsset: false,
      showCheckout: false,
      showTransfer: false,
      showTransferInService: false,
      showCheckIn: false,
      showConfirmReceipt: action.showConfirmReceipt,
      showReturn: false,
      nodeToEdit: _.cloneDeep(action.node),
      headerTitle: "Confirm receipt",
      currentAction: "malToggleConfirmReceiptPanel",
    });
  }

  // -----------------------------------------------------------
  // #endregion
  // -----------------------------------------------------------

  // -----------------------------------------------------------
  // #region Misc
  // -----------------------------------------------------------

  @Action(MalUpdateColumnDefs)
  malUpdateColumnDefs({ patchState }: StateContext<any>, action: MalUpdateColumnDefs) {
    patchState({
      currentAction: "malUpdateColumnDefs",
      assetListColumnDefs: action.columnDefs,
    });
  }
  // -----------------------------------------------------------
  // #endregion
  // -----------------------------------------------------------

  @Action(MalMultiScanAsset)
  malMultiScanAsset(ctx: StateContext<any>, action: MalMultiScanAsset){
    let state = ctx.getState();
    let prams: any = {
      scrollId: "",
      match: "must",
      scrollSize: 50,
      globalQueryText: '',
      searchQueries: state.searchFields,
    };
    if(state.sortField) {
      prams.sortField = state.sortField;
    }
    this.params = prams;

    ctx.patchState({
      loading: true,
      currentAction: "malScan_InProgress",
    });

    return this._searchApiService.assetEntitySearch(action.scanAction,'scanFilter',this.params, false).pipe(
      catchError((err) => {
        this._utils.showMessage("Failed to load assets");
        return throwError(err);
      }),
      finalize(() => ctx.patchState({ loading: false })),
      map((res: any) => {
        // Fetch updated state
        state = ctx.getState();

        const {selectedAssets, listingAssets} = res;
        let newList = action.newSearch ? listingAssets.hits.slice() : [...state.searchList, ...listingAssets.hits];
        const totalAssets = state.totalAssets;
        const numAssets = newList.length; //newList.length + selectedAssets?.hits.length;
        let seletectedObj = {}
         selectedAssets.hits.forEach(item=>{
          seletectedObj[item.id]=item;
        })
        return ctx.setState({
          ...state,
          searchList: [...selectedAssets?.hits, ...newList],
          // searchFields: [...searchFields],
          scrollId: listingAssets._scroll_id,
          numAssets,
          totalAssets,
          selectedItems: seletectedObj,
          currentAction: "malMultiScanAsset",
          allLoaded:false, 
          loading: false,
        });
      })
    );
  }
}
