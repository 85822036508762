import { Injectable } from "@angular/core";

@Injectable()
export class PanelStatesService {
  constructor() { }


  togglePanel(name: any, p: any) {
    p[name] = !p[name];
    return this.getPanelPositions(p);
  }
  showPanel(name: any, p: any) {
    p[name] = true;
    return this.getPanelPositions(p);
  }
  hidePanel(name: any, p: any) {
    p[name] = false;
    return this.getPanelPositions(p);
  }
  toggleAll(p: any) {
    if (
      p["entities"] ||
      p["rightList"] ||
      p["inspector"]
    ) {
      p["entities"] = p[
        "rightList"
      ] = p["inspector"] = false;
      p["footer"] = false;
    } else {
      // p['rightList'] =
      p["entities"] = p["inspector"] = true;
      p["footer"] = true;

    }
    return this.getPanelPositions(p);
  }
  closeAll(p: any) {
    p["entities"] = p[
      "rightList"
    ] = p["inspector"] = false;
    p["footer"] = false;
    return this.getPanelPositions(p);
  }

  getInspectorWidth(p: any) {
    return (p.inspector) ? 300 : 0;
  }

  getInspectorOffset(p: any) {
    if ((p.rightList && p.inspector) ||
      (!p.rightList && p.inspector)) {
      return -300;
    } else if ((p.rightList && !p.inspector) ||
      (!p.rightList && !p.inspector)) {
      return 0;
    }
    return null;
  }
  getRightListOffset(p: any) {
    let width = p.browserWidth;
    if (p.rightList && p.inspector) {
      return -550;
    } else if ((p.rightList && !p.inspector) || (!p.rightList && p.inspector)) {
      return -250;
    } else if (!p.rightList && !p.inspector) {
      return 0;
    }
     return null;
  }
  getLeftListOffset(p: any) {
    if (p.entities && p.leftList) {
      return 250;
    } else if (p.entities && !p.leftList)  {
      return 0;
    } else if  (!p.entities && p.leftList) {
      return 250;
    } else if (!p.entities && !p.leftList) {
      return 0;
    }
    return null;
  }
  getMaMapOffset(p: any) {
    if (p.entities && p.leftList) {
      return 250;
    } else if (p.entities && !p.leftList) {
      return 0;
    } else if (!p.entities && p.leftList) {
      return 250;
    } else if (!p.entities && !p.leftList) {
      return 0;
    }
    return null;
  }
  getLeftOffset(p: any) {
    return (p.entities) ? 0 : -250;
  }
  getMainWidth(p: any) {
    let width = p.browserWidth;
    if (p.entities) {
      width -= 250;
    }
    if (p.rightList) {
      width -= 250;
    }
    if (p.leftList) {
      width -= 250;
    }
    if (p.inspector) {
      width -= 300;
    }

    return width;
  }
  getContentWidth(p: any) {
    let contentWidth = p.browserWidth;
    if (!p.entities) {
      contentWidth += 300;
    }
    if (p.rightList) {
      contentWidth -= 250;
    }
    if (p.inspector) {
      contentWidth -= 300;
    }
    return contentWidth;
  }

  getMainHeight(p: any) {
    let mainHeight = window.innerHeight - 90;
    // if (p.footer) {
    //   mainHeight -= 40;
    // }
    if (p.carousel) {
      mainHeight -= 160;
    }
    if (p.filters) {
      mainHeight -= p.middleStripHeight;
    }
    // if (p.showTags) {
    //   mainHeight -= 30;
    // }
    return mainHeight;
  }
  getMainTop(p: any) {
    let mainTop = 0;
    if (p.filters) {
      // mainTop += 90;
      mainTop += p.middleStripHeight;
    }
    if(p.filtersSelected) {
      mainTop += 40;
    }
    if (p.carousel) {
      mainTop += 160;
    }
    // if (p.showTags) {
    //   mainTop += 30;
    // }
    return mainTop;
  }
  getFiltersTop(p: any) {
    let filtersTop = 0;
    if (!p.filters) {
      filtersTop = -p.middleStripHeight;
    } else if (p.carousel) {
      filtersTop = 160;
    }

    return filtersTop;
  }
  getPanelPositions(p: any) {
    let panels = { ...p } || {};
    panels.browserWidth = window.innerWidth - 20; // get browser width
    panels.panelHeight = this.getMainHeight(panels);
    panels.maHeight = this.getMainHeight(panels) - 55;
    panels.mainHeight = this.getMainHeight(panels);
    // Set inline width - see template
    panels.rightListOffset = this.getRightListOffset(panels);
    panels.contentWidth = this.getContentWidth(panels);

    panels.mainWidth = this.getMainWidth(panels);
    panels.leftOffset = this.getLeftOffset(panels);
    panels.leftListOffset = this.getLeftListOffset(panels);
    panels.maMapOffset = this.getMaMapOffset(panels);

    panels.footerOffset = panels.footer ? 0 : 40;
    panels.mainTop = this.getMainTop(panels);
    panels.filtersTop = this.getFiltersTop(panels);
    panels.inspectorOffset = this.getInspectorOffset(panels);
    //panels.mainHeight = this.getMainHeight(p);
    return panels;
  }
}
