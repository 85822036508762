import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { BulkProperty } from '../@types/bulk-property.type';
import { TenantSettingsType } from '../@types/tenant-settings.type';
import { UserService } from '../user/user.service';
import * as _ from 'lodash';
import AppSettingsJson from '../@data/app-settings.json';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { ThemingService } from "@cl/common/services/theming.service";

import { TenantList } from '../@types/tenant-list.type';
import {UrlUtilsService} from "@cl/common/services/url-utils.service";


@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  private tenantSettings: any;
  propertiesSubject : Subject<TenantSettingsType>;
  private themes: any;
  translations: any;
  private appSettings: any;
  mergedBulkSettings: any;
  private loginTheme:any;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private apiProvider: ApiProviderService,
    private themeService: ThemingService,
    private urlUtils: UrlUtilsService
  ) {
    this.tenantSettings = [];
    this.propertiesSubject = new Subject<TenantSettingsType>();
    this.translations = [];
    this.themes = {};
    this.appSettings = [];
    this.mergedBulkSettings = [];
  }
  getSetting(key: string, isAppSetting?: boolean|undefined, defaultValue?: any): string|boolean|number{
    if(!isAppSetting && defaultValue){
      return defaultValue;
    }else{
    let settingValue = isAppSetting ? this.getConfigurationInBulkSettings(key) : this.tenantSettings[key];
    if(key === 'ui.dateFormat' && settingValue){ // FIXME it is the format of date and DD is not supported for DatePipe
      settingValue = settingValue.replace('DD', 'dd');
    }
    return settingValue ? settingValue : ( (!settingValue && defaultValue) ? defaultValue : '' );
  }
  }
  getConfigurationInBulkSettings(key: string): string|boolean|number {
    let setting  =  _.find(this.mergedBulkSettings, { key: key });
    return setting ? setting?.value : '';
  }
  setThemes(response: any) {
    let themeProps = this.formatForJSONObj(response);
    this.themes = {...themeProps};
    if(themeProps['dvp2'] && Object.keys(themeProps['dvp2']).length) {
      this.themeService.setThemeProps(themeProps['dvp2']);
    }
  }
  setAppSettings(response: BulkProperty[]) {
    this.appSettings = response ?? [];
    this.mergedBulkSettings = AppSettingsJson;
    this.appSettings.forEach((element : BulkProperty) => {
      let setting: any = _.find(this.mergedBulkSettings, { key: element.key });
      if(_.isEmpty(setting)){
        this.mergedBulkSettings.push(element);
      }else {
        setting.value = element.value;
      }
    });
  }
  getTenantSettingsData():Promise<TenantSettingsType>{
    return new Promise((resolve, reject) => {
      this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/user/properties?inclTenant=true`)
      .subscribe({
        next: (response: any)=>{
          this.tenantSettings = response ?? {};
          this.propertiesSubject.next(response);
          resolve(response);
        },
        error: ()=>{
          reject();
        }
      });
    });
  }
  updateTenantSettings(updatedTenantObject){
    for(var key in this.tenantSettings){
      if(updatedTenantObject[key]){
        this.tenantSettings[key]=updatedTenantObject[key];
      }
    }
   }

   formatForJSONObj(iProps: any) {
     let oProps = {}, _tmp, _sTmp;
     if(iProps){
       iProps.map((item) => {
         _sTmp = item.key.split('.');
         if(!oProps.hasOwnProperty(_sTmp[0]) ) {
           oProps[_sTmp[0]] = {};
         }
         if(_sTmp[1]) {
           oProps[_sTmp[0]][_sTmp[1]] = item.value;
         } else {
           oProps[_sTmp[0]] = item.value;
         }
       });
     }
     return oProps;
   }
  async getLoginBulkProperties(){
    if(!this.loginTheme){
      try {
        this.loginTheme = await this.fetchLoginBulkProperties();
      } catch (error) {
        throw error;
      }
    }
    return this.loginTheme;
  }

  fetchLoginBulkProperties(){
    const domainName = this.urlUtils.getBaseURL().prima;
    let url = `${this.apiProvider.getAPIUrl('cloudos')}api/1/property/bulk/common/appLoginSettings?domainName=${domainName}&locale=NA`;
    const obs$ = this.http.get(url, { headers: { 'Skip-Auth': 'true' }});
    return firstValueFrom(obs$);
  }

  getBulkProperties(prefix1: string, prefix2: string, domain: string, tenantId?: string, locale?: string): Promise<BulkProperty[]>{
    return new Promise((resolve, reject )=>{
      let url = `${this.apiProvider.getAPIUrl('cloudos')}api/1/property/bulk/${prefix1}/${prefix2}`;
      url = url + `?domainName=${domain}&locale=${locale ? locale :  'NA'}`;
      if(!_.isEmpty(tenantId)){
        url = url + `&tenantId=${tenantId}`;
      }
      this.http.get(url).subscribe({
        next: (response: any)=>{
          resolve(response);
        },
        error: (err)=>{
          reject(err);
        }
      });
    });
  }
  getTranslations(): Promise<BulkProperty[]>{
    const domainName = this.urlUtils.getBaseURL().prima;
    return new Promise((resolve, reject)=>{
      this.getBulkProperties('dvp2','tenant.labels', domainName, this.userService.getEffectiveTenantId())
      .then((response: any) => {
        this.translations = this.formatForJSONObj(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });
    });
  }
  getCustomizedTheme(): Promise<BulkProperty[]>{
    const domainName = this.urlUtils.getBaseURL().prima;
    return new Promise((resolve, reject)=>{
      this.getBulkProperties('appCommon','appCSS', domainName, this.userService.getEffectiveTenantId())
      .then((response: any) => {
        this.setThemes(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });
    });
  }
  getAppSettings(): Promise<BulkProperty[]>{
    const domainName = this.urlUtils.getBaseURL().prima;
    return new Promise((resolve, reject)=>{
      this.getBulkProperties('common','appSettings', domainName, this.userService.getEffectiveTenantId())
      .then((response: any) => {
        this.setAppSettings(response);
        resolve(response);
      })
      .catch((err)=>{
        reject(err);
      });
    });
  }

  getLoginAppSettings(){
    const domainName = this.urlUtils.getBaseURL().prima;
    return new Promise((resolve, reject)=>{
      this.getBulkProperties('common','appLoginSettings', domainName, null)
        .then((response: any) => {
          //this.setAppSettings(response);
          resolve(response);
        })
        .catch(()=>{
          reject();
        });
    });
  }

  SetBulkProperties(payload): Promise<BulkProperty[]>{
    return new Promise((resolve, reject )=>{
      let url = `${this.apiProvider.getAPIUrl('cloudos')}api/1/property/bulk`;
      //url = url + `?domainName=${domain}&locale=${locale ? locale :  'NA'}`;
      console.log("SetBulkProperties payload",payload);
      this.http.put(url,payload).subscribe({
        next: (response: any)=>{
          resolve(response);
        },
        error: ()=>{
          reject();
        }
      });
    });
  }
  deleteProperty(field,tenantId,domain,locale,prefix1,prefix2){
    return new Promise((resolve, reject) => {
      let url=`${this.apiProvider.getAPIUrl('cloudos')}api/1/property/${prefix1}/${prefix2}/${field}`;
      url = url + `?locale=${locale ? locale :  'NA'}`;
      if(!_.isEmpty(tenantId)){
        url = url + `&tenantId=${tenantId}`;
      }else{
        url = url + `&domainName=${domain}`;
      }
      this.http.delete(url)
      .subscribe({
        next: (response: any)=>{
          resolve(response);
        },
        error: (err)=>{
          reject(err);
        }
      });
    });
  }

}
