<!-- <h5 class="mt-0 ml-1">Select</h5> -->
<ng-container *ngIf="domainsForm">
  <form class="css-grid gap-10px grid-flow-col" [formGroup]="domainsForm">
    <app-domain-filter-box *ngFor="let domain of domainList"
      [domain]="domain"
      [formControlName]="domain.id"
      [visible]="fcVisible.value.includes(domain.id)"
      (activeFiltersCountChange)="onActiveFilterCountChange($event, domain.id)"
      (toggleTargeted)="targetDomain(domain.id, $event)"
      (visibleChange)="onVisibleToggleChange(domain.id, $event)"
      (totalRecords)="totalRecordcount($event)"
      (filterdata)="allFilterData($event)">
    </app-domain-filter-box>
  </form>
</ng-container>
