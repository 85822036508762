import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import _ from 'lodash';
import { LoggedInUserType } from '../@types/logged-in-user.type';
import { TenantUtilService } from '@cl/tenant/tenant-util.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private pagerfresh = new BehaviorSubject<boolean>(false);
  pageReload = this.pagerfresh.asObservable();
  user: LoggedInUserType = {
    id: '',
    tenantId: '',
    enabled: true,
    orgAware:true,
    dtpuser: true,
    name: '',
    type: '',
    roles: [],
    policies: [],
    permissions: [],
    properties: {
      emailActivationStatus: '',
      manager: '',
      profileType: '',
      phone: '',
      locationId: '',
      'reporting_region': '',
      appRole: '',
      'encoded.cred.archive': '',
      category: '',
      manager_email: '',
      email: '',
      isoptedforsms: '',
      'ui.monitor.refreshInterval': ''
    },
    userDevices: [
      {
        id: '',
        userId: '',
        deviceIdentifier: '',
        deviceName: '',
        transport: '',
        appPlatform: '',
        status: '',
        providerDetails: '',
        confirmationKey: ''
      }
    ],
    modifiedTime: 0,
    groups: [],
    locked: false,
    timezone: '',
    tenantName: '',
    module: '',
    uiProfileId: '',
    uiProfileName: '',
    uiPages: [],
    proxyLogin: false,
    userTenantAccessType: ''
  };
  tokenResponse: any;
  token: any;
  isfieldTechUser = false; //TODO::hack for fieldTechUser, remove & replace it once UIProfile implemented
  directUserUiPages: string[] = [];
  constructor(private http: HttpClient, private apiProvider: ApiProviderService, private tenantUtilService: TenantUtilService) {
  }

  getLoggedInUserData(): Promise<LoggedInUserType> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/user`).subscribe(
        {
          next: (response: LoggedInUserType) => {
            if(response.userTenantAccessType == 'INDIRECT' && this.user?.userTenantAccessType == 'DIRECT'){
              this.directUserUiPages = [...this.user.uiPages];
            }
            this.user = response;
            resolve(this.user);
            this.checkForFieldTechUser();
          },
          error: () => {
            reject(this.user);
          }
        }
      );
    });
  }
  getToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.apiProvider.getAPIUrl('clfgraphui')}auth0/token`).subscribe(
        {
          next: (response: any) => {
            this.tokenResponse = response;
            this.token = response.token;
            resolve(this.tokenResponse);
          },
          error: () => {
            reject(this.tokenResponse);
          }
        }
      )
    });
  }

  getUser(): LoggedInUserType {
    return this.user;
  }

  getPolicies(): string[] {
    return this.user?.policies;
  }

  getRoles(): string[] {
    return this.user?.roles;
  }

  getEffectiveTenantId(): string {
    let firstTenantId = this.tenantUtilService.getFirstTenantId();
    let tenantId;
    let persistedTenant: string = this.tenantUtilService.getPersistedTenant();
    if(!_.isEmpty(persistedTenant)){
      tenantId = persistedTenant;
    } else if(firstTenantId){
      tenantId = firstTenantId;
    } else if(this.user){
      tenantId = this.user?.tenantId;
    }
    return tenantId;
  }

  getTimeZone(): string {
    return this.user?.timezone;
  }

  getUserName(): string {
    return this.user?.name;
  }

  getUserId(): string {
    return this.user?.id;
  }

  getUserLocale(): string {
    return 'en';
  }

  getTenantName(): string {
    return this.user?.tenantName;
  }

  getTenantId(): string {
    return this.user?.tenantId;
  }

  isSuperAdmin(): boolean{
    let arr = this.getPolicies();
    return _.includes(arr, 'SCOPE_ALL') && _.includes(arr, 'TENANT_SUMMARY');
  }

  isDirectUser(): boolean {
    return (this.user.userTenantAccessType && this.user.userTenantAccessType === 'DIRECT');
  }

  getUserEmailId() {
    const usrDevices = this.user?.userDevices;
    let emailID = '';
    usrDevices.forEach((device) => {
      if (device.transport === 'email') {
        emailID = device.deviceIdentifier;
      }
    })
    return emailID;
  }

  getUserList() {
    let tenantId = this.getEffectiveTenantId();
    const headerDict = {
      'Content-Type': 'application/json',
    }
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/users?tenantId=${tenantId}`,
      { headers: new HttpHeaders(headerDict) }
    );
  }

  getUserGroups() {
    let tenantId = this.getEffectiveTenantId();
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group/detail/all?tenantId=${tenantId}`);
  }

  getAllUser(sendUserTenancyFlag?) {
    let tenantId = this.getEffectiveTenantId();
    let URL = `${this.apiProvider.getAPIUrl('cloudos')}api/1/users?tenantId=${tenantId}`;
    if(sendUserTenancyFlag) {
      URL += '&isSubTenant='+!this.isDirectUser();
    }
    return this.http.get(URL);
  }

  isFieldTechUser() { //TODO::hack for fieldTechUser, remove & replace it once UIProfile implemented
    return this.isfieldTechUser;
  }

  checkForFieldTechUser() { //TODO::hack for fieldTechUser, remove & replace it once UIProfile implemented
    let userRoles = this.user?.roles;
    let len = userRoles.length;
    const fieldTechRoles = ['2c02ee56-aa6c-11ec-b909-0242ac120002', 'ROLE_ETT_FIELD_TECHNICIAN', '2c02ec1c-aa6c-11ec-b909-0242ac120002', 'ROLE_ETT_FIELD_SERVICE_MANAGER'];
    let tmpPolicy
    while (len--) {
      tmpPolicy = userRoles[len];
      if (fieldTechRoles.indexOf(tmpPolicy) > -1) {
        this.isfieldTechUser = true;
        break;
      }
    }

    return;
  }

  postUserRoles(payload: any, params = { includeSystemRoles: true }) {
    if (!payload) {
      payload = {};
    }

    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/role/list`, payload, { params });
  }

  postUserPolicies(payload: any) {
    if (!payload) {
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/policy/list`, payload);
  }

  getEntityForUserRoleFilters() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/entity`);
  }

  addUser(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/1/user`, payload);
  }

  editUser(payload, userId) {
    return this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/1/user/${userId}`, payload);
  }

  getUserRoleList(params = { includeSystemRoles: true }) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/role`, { params });
  }

  postRole(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/role`, payload);
  }

  putRole(payload) {
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/role`, payload);
  }

  getUserTimezoneList() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}resources/data/timezone.json`);
  }

  getUserTemplateGroup() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/triggerTemplateGroups?application=intransit`);
  }

  getUserDetails(userId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/user/${userId}`);
  }

  getEntityForCreatingPolicy() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/entity?configurable=true`);
  }

  postPolicies(payload: any) {
    if (!payload) {
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/policy`, payload);
  }

  putPolicies(payload: any) {
    if (!payload) {
      payload = {};
    }
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/policy`, payload);
  }

  getPoliciesById(id) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/policy/${id}`);
  }

  postUserGroup(payload) {
    if (!payload) {
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group`, payload);
  }

  putUserGroup(payload) {
    if (!payload) {
      payload = {};
    }
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group`, payload);
  }

  postAddUser(payload) {
    if (!payload) {
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group/usersadd`, payload);
  }

  postDeleteUser(payload) {
    if (!payload) {
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group/userdel`, payload);
  }

  deleteItemFromList(endPoint, userId) {
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/${endPoint}/${userId}`);
  }

  deleteUserGroup(payload) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group`, options);
  }

  updateUserProfile(userid, payload) {
    return this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/1/user/${userid}`, payload);
  }

  getUserRolesList() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/role`);
  }

  resetPasswordUser(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/1/user/resetAccount`, payload);
  }

  getEntityAttributeById(id) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/entity/${id}/attribute`);
  }

  getEntityAttributeValues(entityId, attributeId, searchTearm) {
    const fromPolicy = entityId == 'SHIPMENT' ? '&fromPolicy=true' : ''
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/entity/${entityId}/attribute/${attributeId}/values?textSearchTerm=${searchTearm}${fromPolicy}`);
  }

  shareRoles(payload) {
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/role/share`, payload);
  }

  initializeAudit(payload) {
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/asset/audit/init`, payload);
  }

  addPolicyOrg(orgName){
    let payload = {
      "name": orgName
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/org`, payload);
  }
  getOrgUserGroups(selectedOrg: any) {
    let tenantId = this.getEffectiveTenantId();
    let orgId = '';
    let queryParam: any;
    if(!_.isEmpty(selectedOrg)){
      orgId = selectedOrg
      queryParam = `tenantId=${tenantId}&organization=${orgId}`;
    }else{
      queryParam = `tenantId=${tenantId}`;
    }
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group/detail/all?`+queryParam);
  }

  validateUserGroup(payload) {
    if (!payload) {
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/group/users/validate`, payload);
  }

  getOrg(){
    return this.http.get(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/org/list`);
  }

  setPageRefresh(val){
    this.pagerfresh.next(val);
  }

  getAllRecipes(entityTypeId){
     return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/recipe/${this.user?.tenantId}/${entityTypeId}/UITrigger`);
  }
  activateRecipes(payload){
    payload.tenantId =this.user?.tenantId
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/recipe/execute/UITrigger `, payload);
  }

  getAllSFTPUsers(){
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/sftp/list/?tenantId=${this.user?.tenantId}`);
  }

  addSFTPUser(payload){
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/sftp/user`, payload);
  }

  deleteSftpUser(userId){
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/sftp/user/${userId}`);
  }

  getUserProfileList(){
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/uiProfile/list`, {});
  }

  getUserUipages(){
    if(this.user?.userTenantAccessType == 'DIRECT')
      return this.user?.uiPages;
    return this.directUserUiPages;
  }

  isDTPUser(){
    return this.user?.dtpuser;
  }

  getAreas() {
    const payload = {"scrollId":"","globalQueryText":"","searchQueries":[{"fieldName":"baseClass","queryText":"Location,Route","queryOperator":"should","queryType":"match","childFilters":[{"fieldName":"dynamicArea","queryText":"true","queryOperator":"must_not","queryType":"match"},{"fieldName":"dynamicRoute","queryText":"true","queryOperator":"must_not"},{"fieldName":"deleted","queryText":"true","queryOperator":"must_not"},{"fieldName":"parentEntityTypeKey","queryText":"area","queryOperator":"must"}]}],"sortField":[]};
    return firstValueFrom(this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location/global/search`, payload));
  }
}
