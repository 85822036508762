import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AssetUtilService } from '../../../asset/asset-util.service';
import { UtilsService } from '../../utils/utils.service';
import { AssetListService } from '../../../asset/asset-list/asset-list.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { Asset, AssetBasicInformationProperties, CdmField } from '@cl/@types/asset.type';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { AssetService } from '../../../asset/asset.service';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { ToastService } from '@cl/common/services/toast.service';
import { AssetsCOCStateService } from '@cl/asset/asset-coc-state.service';

@Component({
  selector: 'cl-asset-summary',
  templateUrl: './asset-summary.component.html',
  styleUrls: ['./asset-summary.component.scss']
})
export class AssetSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assetId: any;
  @Input() assetType = '';
  @Output() closeSidePanel = new EventEmitter();
  activePane: string = 'summary';
  assetObject: Asset;
  showCocPanel: boolean = false;
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  isCustodyAsset: boolean = false;
  
  assetAvatar:string | ArrayBuffer;
  uploadedAvatar:File;
  isUploading:boolean = false;
  isImageUpdated = false;
  preDefinedColumns = ['name', 'externalId', 'type', 'imageURL', 'monitoredStatus', 'status', 'createdBy','createdAt','modifiedBy','modifiedAt'];
  userFields = [];
  systemFields = [];

  loading: boolean;
  assetCOCStateSubject: Subscription;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private assetUtilService: AssetUtilService,
    private assetListService: AssetListService,
    private utils: UtilsService,
    private assetService: AssetService,
    private _dynamicFormService: DynamicFormService,
    public datePipe : FormateDatePipe,
    private _toastService: ToastService,
    private assetCOCState: AssetsCOCStateService) { }

  ngOnInit(): void {
    this.watchMdList();
  }

  ngOnChanges(): void {
    if (this.assetId) {
      this.loading = true;
      this.assetUtilService.getAsset(this.assetId, null, this.assetType)
        .then((response: any) => {
          this.loading = false;
          this.assetObject = response;
          if(this.assetObject?.properties?.eventPublisherMap && typeof this.assetObject?.properties?.eventPublisherMap == 'string'){
            this.assetObject.properties.eventPublisherMap = JSON.parse(this.assetObject?.properties?.eventPublisherMap)
          }
           this._changeDetectorRef.detectChanges();
          if(response?.classType === 'CustodyAsset'){
            this.isCustodyAsset = true;
          }
          if(this.assetObject?.cdmFields){
            const fields = this.assetObject.cdmFields.filter(cdmField => cdmField.isDisplayableOnDetails && !this.preDefinedColumns.includes(cdmField.name));
            let userFields = fields.filter(field => field.group?.toLowerCase() == 'user');
            let systemFields = fields.filter(field => field.group?.toLowerCase() == 'system');
            this.userFields = this._dynamicFormService.orderFields(userFields, 'order');
            this.systemFields = this._dynamicFormService.orderFields(systemFields, 'order');
          }
        });
    }
   }


  watchMdList() {
    this.assetCOCStateSubject = this.assetCOCState.assetCOCStateObservable.subscribe(state => {
      this.showCocPanel = state.showCocPanel;
    })
  }

  toggleActivePane(pane: string) {
    this.activePane = pane;
    this._changeDetectorRef.detectChanges();
  }

  onAvatarChange(event) {
    if (event.target.files && event.target.files[0]) {
      const file:File = event.target.files[0];
      if(!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)){
        let errorMessage = 'Image should be png/jpg format';
        this._toastService.error(errorMessage)
        this.assetAvatar = this.defaultAssetImage;
        this.uploadedAvatar = undefined;
        return;
      }
      this.uploadedAvatar = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.assetAvatar = reader.result;
      };

      reader.readAsDataURL(file);
      this.uploadAvatar()
    } else {
      this.assetAvatar = this.defaultAssetImage;
      this.uploadedAvatar = undefined;
    }
  }

  get defaultAssetImage(){
    return this.assetObject?.properties?.imageThumbnailURL || this.assetObject?.properties?.imageURL || 'assets/svgs/asset.svg'
  }

  async uploadAvatar(){
    if(!this.uploadedAvatar) return;
    const formData = new FormData();
    formData.append('file', this.uploadedAvatar);
    this.isUploading = true;
    try {
      const res = await this.assetService.uploadAvatar(formData, this.assetId, this.assetObject?.properties?.entityTypeKey);
      this.assetObject.properties.imageThumbnailURL = undefined; //FIXME: Change it later 'imageThumbnailURL' not getting correctly
      this.assetObject.properties.imageURL = res['imageURL'] + '?v='+ Math.random();
      this._toastService.success(`Image updated successfully`);
      this.isImageUpdated = true;
    } catch (error) {}
    this.isUploading = false;
  }

  closePanel() {
    this.closeSidePanel.emit(this.isImageUpdated);
  }

  closeCOCPanel() {
    this.showCocPanel = false;
  }
  isStaticPropertyAvailable(id: string){
    let staticProperties : string[] = this.assetUtilService.assetStaticProperties;
    return !_.includes(staticProperties, id);
  }
  getExtendedAttributeValue(cdmField : CdmField, properties: AssetBasicInformationProperties){
    if(cdmField.isDisplayableOnSummary){
      if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
        if(cdmField?.type?.toUpperCase()==='DATE'){
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        } else {
          if(cdmField.name == 'organization') return properties['organizationName']
          return properties[cdmField.name];
        }
      }else{
        return '';
      }
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.assetCOCStateSubject?.unsubscribe();
  }
}
