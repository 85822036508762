import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cl-table-surtitle',
  templateUrl: './table-surtitle.component.html',
  styleUrls: ['./table-surtitle.component.scss']
})
export class TableSurtitleComponent implements OnInit {
  @Input() rows:number = 0;
  @Input() selected:number = 0;
  @Input() total:number = 0;
  @Input() pageName = '';
  constructor() { }

  ngOnInit(): void {
  }

}
