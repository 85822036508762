import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { startCaseTransform } from "../pipes/start-case.pipe";
import { StateActionMapPipe } from "../pipes/state-action-map.pipe";
import FileSaver from "file-saver";
import * as _ from "lodash";
import { combineLatest, firstValueFrom, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { API_LABEL_MAP } from "../label-mapping";
import { AggregatedCountApiResponse, ElasticHit, FilterGroup, FilterGroupList, UserSearchApiResponse } from "../models/index";
import { SearchResponse } from "../search-response";
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

const CACHE_SIZE = 1;
@Injectable({
  providedIn: "root",
})
export class SearchApiService {
  public cors_api_url = `${"https://cors-anywhere.herokuapp.com/"}`;

  mapData$: Observable<any>[] = new Array();
  kpiData$: Observable<any>[] = new Array();
  dts$: any;
  counts$: any;
  selectedDt: any;
  fieldNames = {
    Inventory: "type",
    Asset: "classType",
    Incident: "cid",
    Shipment: "status",
    Location: "type",
    Device: "type",
  };
  assetListResult:any[] = [];
  selectedAssetList: any[] = [];
  remainingAssetList: any[] = [];
  filterDataList:any[] = [];
  constructor(private http: HttpClient, private apiProvider: ApiProviderService) {
  }

  /**
   * @param queryParams Any query params if you want to pass
   */
  globalSearch<T = any>(searchParams: any, deserialize = true, queryParams = {}) {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`;
    searchParams.searchQueries = searchParams.searchQueries.map(query => {
      let childFltsCopy = _.cloneDeep(query.childFilters);
      if (!childFltsCopy) {
        childFltsCopy = [];
      }
      let index = childFltsCopy.findIndex(item => item.fieldName === 'deleted' && item.queryOperator === 'must_not');
      if (index < 0) {
        childFltsCopy.push({
          "fieldName": "deleted",
          "queryText": "true",
          "queryOperator": "must_not"
        });
      }
      return Object.assign({}, query, {childFilters: childFltsCopy});
    });

    // TODO: Use switchmap here for search term update
    return this.http.post<SearchResponse>(url, searchParams).pipe(
      map((res) => {
        // console.log('res', res);
        return (deserialize ? new SearchResponse().deserialize(res) : res) as T;
      })
    );
  }

  assetEntitySearch(selectedRows, entityType:string, searchParams: any, deserialize:boolean, queryParams = {}){
    if(entityType === 'scanFilter'){
      const selectedAssetSearch = this.getSelectedAsset(selectedRows);
      const assetListWithoutSelected = this.getAssetListWithoutSelected(searchParams,selectedRows);
      return combineLatest({
        selectedAssets: selectedAssetSearch,
        listingAssets: assetListWithoutSelected
      })
    }
    else if(Object.keys(selectedRows).length > 0){
      const selectedAssetSearch = this.getSelectedAsset(Object.values(selectedRows));
      const assetListWithoutSelected = this.getAssetListWithoutSelected(searchParams,Object.values(selectedRows));
      return combineLatest({
        selectedAssets: selectedAssetSearch,
        listingAssets: assetListWithoutSelected
      })
    }else{
      return combineLatest({
        listingAssets: this.globalEntitySearch(entityType, searchParams, deserialize,queryParams)
      })
     
    }
  }

  getSelectedAsset(selectedRows){
    let childFilterParam:any[] = []
  let selectedSearchparam: any;
  let scrollSize = selectedRows.length>0 ? selectedRows.length : 0;
  if(selectedRows.length>0){
    selectedRows.forEach(item =>{
      selectedSearchparam = {
        "fieldName": "id",
        "queryText":item.id,
        "queryType": "match",
        "queryOperator": "should"
       }
       childFilterParam.push(selectedSearchparam);
    })
  }
    const payload = {
      "scrollId": "",
      "scrollSize": scrollSize,
      "globalQueryText": "",
      "searchQueries": [
          {
              "fieldName": "baseClass",
              "queryText": 'Asset',
              "queryOperator": "should",
              "queryType": "match",
              "childFilters": childFilterParam
          }
      ],
      "sortField": []
    } 
    return this.globalEntitySearch('asset', payload, false);
  }

  getAssetListWithoutSelected(searchParam, selecdtRow){
    let childFilterParam:any[] = [];
    let searchQueryPayload:any; 
    let scrollSize:number = 50;
  let selectedSearchparam: any;
  if(selecdtRow.length>0){
    selecdtRow.forEach(item =>{
      selectedSearchparam = {
        "fieldName": "id",
        "queryText":item.id,
        "queryType": "match",
        "queryOperator": "must_not"
       }
       childFilterParam.push(selectedSearchparam);
    })
  }
  
  if(searchParam.searchQueries[0].childFilters.length > 0){
    searchQueryPayload = [...searchParam.searchQueries[0].childFilters, ...childFilterParam];
  }else{
    searchQueryPayload = childFilterParam;
  }
    const payload = {
      "scrollId": "",
      "scrollSize": scrollSize,
      "globalQueryText": searchParam.globalQueryText,
      "searchQueries": [
          {
              "fieldName": "baseClass",
              "queryText": 'Asset',
              "queryOperator": "should",
              "queryType": "match",
              "childFilters": searchQueryPayload
          }
      ],
      "sortField": []
    } 
    return this.globalEntitySearch('asset', payload, false);
  }

  globalEntitySearch<T = any>(entityType:string, searchParams: any, deserialize = true, queryParams = {}) {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${entityType}/global/search`;
    searchParams.searchQueries = searchParams.searchQueries.map(query => {
     let childFltsCopy = _.cloneDeep(query.childFilters);
      if (!childFltsCopy) {
        childFltsCopy = [];
      }
      let index = childFltsCopy.findIndex(item => item.fieldName === 'deleted' && item.queryOperator === 'must_not');
      if (index < 0) {
        childFltsCopy.push({
          "fieldName": "deleted",
          "queryText": "true",
          "queryOperator": "must_not"
        });
      }
      return Object.assign({}, query, {childFilters: childFltsCopy});
    });

    // TODO: Use switchmap here for search term update
    return this.http.post<SearchResponse>(url, searchParams).pipe(
      map((res) => {
        // console.log('res', res);
        return (deserialize ? new SearchResponse().deserialize(res) : res) as any;
      })
    );
  }

  globalSearchWithDownload(payload: any, fileName: string, queryParams = {}, appendDateSuffix = true) {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}navigator/query`;
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const userTimezone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone || "";

    const params = {
      download: "true",
      userTimezone,
      ...queryParams,
    };

    return this.http.post(url, payload, { headers, params, responseType: "blob" }).pipe(
      tap((res) => {
        const fileExtension = res.type.substring(res.type.lastIndexOf("/") + 1);
        const dateSuffix = appendDateSuffix ? this.dateSuffix : "";
        const fullFileName = `${fileName}-${dateSuffix}.${fileExtension}`;
        FileSaver.saveAs(res, fullFileName);
      })
    );
  }

  private get dateSuffix(): string {
    return new Date().toLocaleString().replace(/[\/\s,:]/g, "");
  }

  elasticSearchWithBounds(searchParams: any, deserialize = true) {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}navigator/geobound/query`;
    // TODO: Use switchmap here for search term update
    return this.http.post<SearchResponse>(url, searchParams).pipe(
      map((res) => {
        return deserialize ? new SearchResponse().deserialize(res) : res;
      })
    );
  }

  getCounts(
    params: { [key: string]: string } = {
      aggField: "status,state,type",
      baseClass: "asset",
    },
    body: { [key: string]: string } = {}
  ): Observable<AggregatedCountApiResponse[]> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/aggregate`;
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.post<AggregatedCountApiResponse[]>(url, body, { headers, params }).pipe(
      map((res) => {
        // Remove hits with empty keys
        res.forEach((r) => r.hits.forEach((h) => (h.buckets = h.buckets.filter((b) => !_.isEmpty(b.key)))));
        return res;
      })
    );
  }

  parseAggregateCounts(counts: any) {
    let countsObj:any = {},
      type: any;
    _.forOwn(counts, (val, key) => {
      val.forEach((group: any) => {
        countsObj[key] = countsObj[key] || {};
        countsObj[key]["total"] = group["totalHits"];
        type = group["hits"][0]["name"];
        countsObj[key][type] = countsObj[key][type] || {};
        group["hits"][0]["buckets"].forEach((cat: any) => {
          countsObj[key][type][cat["key"]] = cat["value"];
        });
      });
    });
    return countsObj;
  }

  /**
   *
   * @param counts
   * @param type
   * @param capitalizeGroup Specify hit.name if you want to remove special characters
   * and capitalize the group label
   * @returns
   */
  parseAggregateCountAsFilter(
    counts: AggregatedCountApiResponse[],
    type: "asset" | "sensor" | "location" | "gateway" | "inventory" | "common" = "common",
    stateActionGroups: string[] = [],
    capitalizeGroup: string[] = []
  ): FilterGroupList {
    const labelMap = API_LABEL_MAP;

    return counts
      .reduce((acc, curr) => [...acc, ...curr.hits], [] as ElasticHit[])
      .map((hit) => {
        let label = startCaseTransform((labelMap[type] as any)[hit.name] || hit.name);

        let filter: FilterGroup = {
          list: [],
          filterType: hit.name,
          label: hit.displayLabel || label,
          type: hit.filterType || "multiselect",
        };
        filter.list = hit.buckets.map((bucket) => ({
          name: bucket.key,
          checked: !!false,
          count: bucket.value,
          displayLabel: bucket.displayLabel,
          label: stateActionGroups.includes(hit.name) ? StateActionMapPipe.transform(bucket.key) : (labelMap[type] as any)[bucket.key] || bucket.key,

          subList: bucket.subBucket.map((subBucket) => ({
            name: subBucket.key,
            checked: !!false,
            count: subBucket.value,

            label: stateActionGroups.includes(hit.name)
              ? StateActionMapPipe.transform(subBucket.key)
              : (labelMap[type] as any)[subBucket.key] || subBucket.key,
          })),
        }));
        if (filter.type === 'daterange') {
          filter.list = [];
        }

        filter.enableCollapsing = filter.list.length > 10;
        filter.collapsed = filter.list.length > 10;
        filter.expand = true;
        return filter;
      });
  }

  copyFilterStatus(_source: FilterGroupList, _destination: FilterGroupList): FilterGroupList {
    const destination = _.cloneDeep(_destination);

    // Reset all checked status of destination filter
    // destination.forEach((set) => set.list.forEach((filter) => (filter.checked = false)));

    // Copy source filter status to destination
    destination.forEach((destinationFilterGroup) => {
      destinationFilterGroup.list.forEach((destinationFilter) => {
        // Reset all checked status of destination filter
        destinationFilter.checked = false;

        // Get source filter checked status for the specific group and filter name
        const sourceFilterGroup = _source.find(
          (sourceSet) => sourceSet?.label?.toLowerCase() === destinationFilterGroup?.label?.toLowerCase()
        );

        // Source filter
        const sourceFilterList = sourceFilterGroup
          ? sourceFilterGroup.list.filter((f) => f.name.toLowerCase() === destinationFilter?.name?.toLowerCase())
          : [];

        let sourceChecked = false;

        if (sourceFilterList.length === 1) {
          // Checked status
          sourceChecked = !!sourceFilterList[0]?.checked;
        }

        destinationFilter.checked = sourceChecked;

        // Collapsed status
        // destinationFilterGroup.collapsed = destinationFilterGroup?.list?.length > 10;
      });
    });

    return destination;
  }

  updateMgCounts(countsObj: any, mgs: any) {
    let mapsGroups = _.cloneDeep(mgs);
    return mapsGroups.map((mg: any) => {
      if (countsObj[mg.class]) {
        // mg.selectedFilters['count'] = countsObj[mg.class]['total'];
        mg["count"] = countsObj[mg.class]["total"];
        mg.filters.forEach((filt: any, i: any) => {
          filt.list.forEach((li: any, j: any) => {
            mg.filters[i].list[j].count = countsObj[mg.class][filt.filterType][li.name.toLowerCase()];
          });
        });
      }
      return mg;
    });
  }
  // updateFilterCounts(countsObj, filterSets) {
  //   return filterSets.map(mg => {
  //     if (countsObj[mg.class]) {
  //       // mg.selectedFilters['count'] = countsObj[mg.class]['total'];
  //       mg['count'] = countsObj[mg.class]['total'];
  //       mg.filters.forEach((filt, i) => {
  //         filt.list.forEach((li, j) => {
  //           mg.filters[i].list[j].count = countsObj[mg.class][filt.filterType][li.name.toLowerCase()]
  //         })
  //       })
  //     }
  //     return mg;
  //   })
  // }
  getSearchFields(filters: any, filtersObj: any) {
    let searchFields = [],
      searchFieldsObj = {};

    filters.forEach((filtGroup: any) => {
      filtGroup.list.forEach((filt: any) => {
        if (filtersObj[filt.name]) {
          if (filt.superClassId === "BaseNode") {
            (searchFieldsObj as any)[filt.name] = {
              fieldName: "baseClass",
              queryOperator: "should",
              queryText: filt.name,
              childFilters: [],
            };
          } else {
            if (!(searchFieldsObj as any)[filt.superClassId]) {
              (searchFieldsObj as any)[filt.superClassId] = {
                fieldName: "baseClass",
                queryOperator: "should",
                queryText: filt.superClassId,
                childFilters: [],
              };
            }
            (searchFieldsObj as any)[filt.superClassId]["childFilters"].push({
              fieldName: (this.fieldNames as any)[filt.superClassId] || "type",
              queryOperator: "should",
              queryText: filt.name,
            });
          }
        }
      });
    });

    searchFields = Object.values(searchFieldsObj);
    return searchFields;
  }

  /**
   * Searches a user based on user id or name
   *
   * @param text User id or name
   * @deprecated Please use `UserApiService.searchUsersInEs`
   */
  searchUsers(text: string, type: string = ""): Observable<UserSearchApiResponse> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}users`;

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const params = {
      text,
      type,
    };

    return this.http.get<UserSearchApiResponse>(url, { headers, params });
  }

  getFilters(catalogName: string): Promise<any> {
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${catalogName}/filter?type=${catalogName || ''}`
    );
    return firstValueFrom(obs$);
  }
}
