import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { DeviceProfileService } from '../device-profile.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UserService } from '@cl/user/user.service';
import { TemperaturePipe } from '@cl/common/pipes/temperature.pipe';
import { PropertyService } from '@cl/property/property.service';


@Component({
  selector: 'cl-device-profile-summary-panel',
  templateUrl: './device-profile-summary-panel.component.html',
  styleUrls: ['./device-profile-summary-panel.component.scss'],
})
export class DeviceProfileSummaryPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() groupObject: any;
  @Output() closeSidePanel= new EventEmitter<any>();
  isLoading = false;
  private subscriptions: Subscription[] = [];
  tenantId = '';
  profileDetails = {};
  editTenantPayload = {};
  servicesData: any = {};
  emptyCapabilityData = {
    excursion: {
      configured: false,
      min: null,
      max: null
    },
    critical: {
      configured: false,
      min: null,
      max: null
    },
    warning: {
      configured: false,
      min: null,
      max: null
    }
  }
  deviceConfigData = [];
  measures = {};

  constructor(
    private deviceProfileService: DeviceProfileService,
    private userService: UserService,
    private temperaturePipe: TemperaturePipe,
    private propertyService: PropertyService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDeviceProfileDetails();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private temperature(value, unit): any {
    return this.temperaturePipe.transform(value, unit);
  }

  private getDeviceProfileDetails() {
    if(this.isSuperAdmin) {
      this.tenantId = this.userService.getTenantId();
    } else {
      this.tenantId = this.groupObject?.tenantId;
    }

    this.deviceProfileService
      .getProfileDetails(this.groupObject?.eventProfileName,this.tenantId)
      .subscribe((data: any) => {
        this.profileDetails = data;
        if(data && data?.eventConfig) {
          let isShockConfigured = data.eventConfig.isShockConfigured ? JSON.parse(data.eventConfig.isShockConfigured) : false;
          this.configureCapabilityData('Shock', isShockConfigured);
          let isLightConfigured = data.eventConfig.isLightConfigured ? JSON.parse(data.eventConfig.isLightConfigured) : false;
          this.configureCapabilityData('Light', isLightConfigured);
          let isPressureConfigured = data.eventConfig.isPressureConfigured ? JSON.parse(data.eventConfig.isPressureConfigured) : false;
          this.configureCapabilityData('Pressure', isPressureConfigured);
          let isTemperatureConfigured = data.eventConfig.isTemperatureConfigured ? JSON.parse(data.eventConfig.isTemperatureConfigured) : false;
          this.configureCapabilityData('Temperature', isTemperatureConfigured);
          let isAmbienttemperatureConfigured = data.eventConfig.isAmbienttemperatureConfigured ? JSON.parse(data.eventConfig.isAmbienttemperatureConfigured) : false;
          this.configureCapabilityData('Ambienttemperature', isAmbienttemperatureConfigured);
          let isCargotemperatureConfigured = data.eventConfig.isCargotemperatureConfigured ? JSON.parse(data.eventConfig.isCargotemperatureConfigured) : false;
          this.configureCapabilityData('Cargotemperature', isCargotemperatureConfigured);

          let isHumidityConfigured = data.eventConfig.isHumidityConfigured ? JSON.parse(data.eventConfig.isHumidityConfigured) : false;
          this.configureCapabilityData('Humidity', isHumidityConfigured);
          let isBatteryConfigured = data.eventConfig.isBatteryConfigured ? JSON.parse(data.eventConfig.isBatteryConfigured) : false;
          this.configureCapabilityData('Battery', isBatteryConfigured);
          let isTiltConfigured = data.eventConfig.isTiltConfigured ? JSON.parse(data.eventConfig.isTiltConfigured) : false;
          this.configureCapabilityData('Tilt', isTiltConfigured);
          }
          this.getServicesBySensorType(data?.sensorType);
      });
  }


  private isSuperAdmin() {
    return this.userService.getPolicies().includes('SCOPE_ALL');
  }

  private configureCapabilityData(capability, isConfigured) {
    let capabilityData;
    let eventConfig = this.profileDetails['eventConfig'];
    if (isConfigured) {
      let excursionMin: any = (eventConfig["min"+capability] == '0' || parseFloat(eventConfig["min"+capability])) ? parseFloat(eventConfig["min"+capability]) : null;
      let excursionMax: any = (eventConfig["max"+capability] == '0' || parseFloat(eventConfig["max"+capability])) ? parseFloat(eventConfig["max"+capability]) : null;
      let criticalMin: any = (eventConfig["min"+capability+"Critical"] == '0' || parseFloat(eventConfig["min"+capability+"Critical"])) ? parseFloat(eventConfig["min"+capability+"Critical"]) : null;
      let criticalMax: any = (eventConfig["max"+capability+"Critical"] == '0' || parseFloat(eventConfig["max"+capability+"Critical"])) ? parseFloat(eventConfig["max"+capability+"Critical"]) : null;
      let warningMin: any = (eventConfig["min"+capability+"Warning"] == '0' || parseFloat(eventConfig["min"+capability+"Warning"])) ? parseFloat(eventConfig["min"+capability+"Warning"]) : null;
      let warningMax: any = (eventConfig["max"+capability+"Warning"] == '0' || parseFloat(eventConfig["max"+capability+"Warning"])) ? parseFloat(eventConfig["max"+capability+"Warning"]) : null;
      let warningConfigured: any = (warningMin || warningMax) ? true : false;
      let criticalConfigured: any = (criticalMin || criticalMax) ? true : false;

      if (!_.has(eventConfig, 'tempUnitType')) {
        eventConfig = _.extend({}, eventConfig, {tempUnitType: this.propertyService.getSetting('ui.temp.unit')});
      }
      if (capability === 'Temperature' || capability === 'Ambienttemperature' || capability === 'Cargotemperature') {
        excursionMin = (excursionMin == 0 || parseFloat(excursionMin)) ?this.temperature(parseFloat(excursionMin), eventConfig.tempUnitType) : null;
        excursionMax = (excursionMax == 0 || parseFloat(excursionMax)) ?this.temperature(parseFloat(excursionMax), eventConfig.tempUnitType) : null;
        criticalMin = (criticalMin == 0 || parseFloat(criticalMin)) ?this.temperature(parseFloat(criticalMin), eventConfig.tempUnitType) : null;
        criticalMax = (criticalMax == 0 || parseFloat(criticalMax)) ?this.temperature(parseFloat(criticalMax), eventConfig.tempUnitType) : null;
        warningMin = (warningMin == 0 || parseFloat(warningMin)) ?this.temperature(parseFloat(warningMin), eventConfig.tempUnitType) : null;
        warningMax = (warningMax == 0 || parseFloat(warningMax,)) ?this.temperature(parseFloat(warningMax), eventConfig.tempUnitType) : null;
      }

      capabilityData = {
        excursion: {
          configured: true,
          min: excursionMin,
          max: excursionMax
        },
        critical: {
          configured: criticalConfigured,
          min: criticalMin,
          max: criticalMax
        },
        warning: {
          configured: warningConfigured,
          min: warningMin,
          max: warningMax
        }
      }
    } else {
      capabilityData = this.emptyCapabilityData;
    }
    if (!this.servicesData[capability]) {
      this.servicesData[capability] = {};
    }
    this.servicesData[capability]['eventConfig'] = capabilityData;
  }

  private getServicesBySensorType(sensorType) {
    this.deviceProfileService.getServicesBySensorType(sensorType).subscribe((data: any) => {
      let metadata = data;
      let services = metadata.services;
      this.deviceConfigData = [];
      let deviceConfig = metadata.deviceConfig;
      if (services.length) {
        this.configureServicesMetaData(services);
      }
      if (deviceConfig.length) {
        this.configureDeviceConfigMetadata(deviceConfig);
        if (this.profileDetails['deviceConfig']) {
          this.configureDeviceConfigData(this.profileDetails['deviceConfig']);
        }
      }
    }, (response) => {
      console.log("Error", response);
    });
  }

  private configureServicesMetaData(services) {
    services.forEach((service, index) => {
      let serviceName = service.identifier;;
      if (!_.isEmpty(serviceName)) {
        if (serviceName === 'Temperature' || serviceName === 'Ambienttemperature' || serviceName === 'Cargotemperature') {
          service.minVal = service.minVal == '0' ? 0 : parseFloat(service.minVal) ? this.temperature(parseFloat(service.minVal), service.unit) : null;
          service.maxVal = service.maxVal == '0' ? 0 : parseFloat(service.maxVal) ? this.temperature(parseFloat(service.maxVal), service.unit) : null;
          this.setServiceMetadata(serviceName, service, 'both');
          this.createObjForSlider(serviceName);
        } else if (serviceName.includes('Pressure')) {
          this.setServiceMetadata('Pressure', service, 'both');
          this.createObjForSlider('Pressure');
        } else if (serviceName.includes('GPS') || serviceName.includes('Location')) {
          // Do nothing
        } else if (serviceName.includes('Battery')) {
          this.setServiceMetadata('Battery', service, 'min');
          this.createObjForSlider('Battery');
        } else if (serviceName === 'Tilt') {
          this.setServiceMetadata('Tilt', service, 'min');
          this.createObjForSlider('Tilt');
        } else if (serviceName.includes('Humidity')) {
          this.setServiceMetadata('Humidity', service, 'both');
          this.createObjForSlider('Humidity');
        } else if (serviceName.toLowerCase() === 'shock') {
            this.setServiceMetadata('Shock', service, 'max');
            this.createObjForSlider('Shock');
        } else if (serviceName.includes('Light')) {
          this.setServiceMetadata('Light', service, 'both');
          this.createObjForSlider('Light');
        }
      }
    });
  }

  private setServiceMetadata(capability, serviceData, thresholdLimits) {
    var sensor = {
      supports: true,
      name: capability,
      min: serviceData.minVal == "0" ? 0 : parseFloat(serviceData.minVal),
      max: serviceData.maxVal == "0" ? 0 : parseFloat(serviceData.maxVal),
      unit: serviceData.unit,
      limits: thresholdLimits
    }
    if (!this.servicesData[capability]) {
      this.servicesData[capability] = {};
    }
    this.servicesData[capability]['metadata'] = sensor;
  }

  private configureDeviceConfigMetadata(customConfig) {
    var shockIndex = _.findIndex(customConfig, (config: any) => {
      return config.name === 'shock_sample';
    });
    if (shockIndex > -1) {
      var spliceArr = customConfig.splice(shockIndex, 1);
      customConfig.unshift(spliceArr[0]);
    }
    customConfig.forEach((deviceConfig, customIndex) => {
      var index = _.findIndex(this.deviceConfigData, (config: any) => {
        return config.name === deviceConfig.serviceIdentifier;
      });
      var characteristicsObj = {
        identifier: deviceConfig.name,
        name: deviceConfig.name,
        metadata: {
          canBeDisabled: deviceConfig.canBeDisabled,
          defaultEnabled: deviceConfig.defaultEnabled,
          uom: deviceConfig.uom,
          uiLabel: deviceConfig.uiLabel
        },
        configParams: {
          enabled: deviceConfig.defaultEnabled,
          uom: deviceConfig.uom[0],
          value: 0
        }
      };

      if (index === -1) {
        var configObj = {
          name: deviceConfig.serviceIdentifier,
          identifier: deviceConfig.serviceIdentifier,
          order: customIndex,
          characteristics : [
            characteristicsObj
          ]
        }
        this.deviceConfigData.push(configObj);
      } else {
        this.deviceConfigData[index].characteristics.push(characteristicsObj);
      }
    });
  }

   private configureDeviceConfigData(deviceConfigArr) {
    deviceConfigArr.forEach((deviceConfig, i) => {
      var index = _.findIndex(this.deviceConfigData, (config:any) => {
        return config.name === deviceConfig.name;
      });
      if (index > -1) {
        var selectedConfig = this.deviceConfigData[index];
        deviceConfig.characteristics.forEach((characteristic) => {
          var charIndex = _.findIndex(selectedConfig.characteristics, (char: any) => {
            return char.name === characteristic.name;
          });
          if (charIndex > -1) {
            this.deviceConfigData[index].characteristics[charIndex].configParams = {
              value: parseInt(characteristic.configParams.value),
              uom: characteristic.configParams.uom,
              enabled: characteristic.configParams.enabled ? JSON.parse(characteristic.configParams.enabled) : false
            }
            if (characteristic.configParams.uom === 'multiSelect') {
              this.deviceConfigData[index].characteristics[charIndex].configParams.value = characteristic.configParams.value;
            }
          }
        });
      }
    });
  }


  private createObjForSlider(measure) {
    this.measures[measure] = this.servicesData[measure];
    var measureMin = this.servicesData[measure].metadata && this.servicesData[measure].metadata.min;
    var measureMax = this.servicesData[measure].metadata && this.servicesData[measure].metadata.max;
    if (!this.servicesData[measure].eventConfig) {
      this.servicesData[measure].eventConfig = this.emptyCapabilityData;
    }
    var min = this.servicesData[measure].eventConfig.excursion.min;
    var max = this.servicesData[measure].eventConfig.excursion.max;
    var isWarning = this.servicesData[measure].eventConfig.warning.configured;
    var isCritical = this.servicesData[measure].eventConfig.critical.configured;
    var minCritical = this.servicesData[measure].eventConfig.critical.min;
    var maxCritical = this.servicesData[measure].eventConfig.critical.max;
    var minWarning = this.servicesData[measure].eventConfig.warning.min;
    var maxWarning = this.servicesData[measure].eventConfig.warning.max;

    this.measures[measure] = {
      bounds: {
        min: measureMin,
        max: measureMax
      },
      excursion: {
        min: min,
        max: max
      },
      isCritical: isCritical,
      critical: {
        min: minCritical,
        max: maxCritical
      },
      isWarning: isWarning,
      warning: {
        min: minWarning,
        max: maxWarning
      }
    }
  }

  closePanel(reload?) {
    if(reload){
      this.closeSidePanel.emit({action: 'reloadUsers' });
    } else {
      this.closeSidePanel.emit(null)
    }
  }

   // DVP2-3227 label change
   fnGetInputLabelBySensorType(uiLabel : string, sensorType: string) {
    let lowerCaseUILabel = uiLabel.toLocaleLowerCase();
    let valuesToCheck = lowerCaseUILabel.includes('recording interval') || lowerCaseUILabel.includes('sampling interval');
    if (valuesToCheck) {
         return 'record';
      }  else {
        return 'send';
      }
  }

}
