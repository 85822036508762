<div  class="h-100 px-1 py-1.5" [ngClass]='{"faded-card": card.isFaded}'>
    <cl-banner-icon *ngIf="card?.showBookmark" [isFave]="card?.isFavorite" (click)="toggleFavorite()"
        class="banner-icon">
    </cl-banner-icon>
    <!-- <cl-card-dropdown [card]="card"></cl-card-dropdown> -->

    <h4 *ngIf="card?.title">{{card.title}}</h4>
    <app-list-card class="h-100" *ngIf="card?.component === 'list-card'" [data]="card?.data"></app-list-card>
    <app-visual-bar-card class="h-100" *ngIf="card?.component === 'visual-bar-card'" [data]="card?.data"></app-visual-bar-card>
    <app-graph-card *ngIf="card?.component === 'graph-card'" [data]="card?.data"></app-graph-card>

    <cl-tenancy-card *ngIf="card?.component === 'tenancy-card'" [tenancyData]="tenancyData" [cardData]="card?.data?.list"
        (tenancyEmitter)="getTenancyUpdate($event)" [selectedDt]="selectedDt" [DTs]="DTs">
    </cl-tenancy-card>
    <cl-monitor-card *ngIf="card?.component === 'monitor-card'"></cl-monitor-card>
    <cl-readouts-card *ngIf="card?.component === 'readouts-card'" [data]="card?.data" [list]="card?.data?.list">
    </cl-readouts-card>
    <div *ngIf="card?.link" class="cl__footer-cta">
        <span routerLink="{{card?.link?.path}}">
            <h4>{{card.link['text']}} <i class="fa fa-arrow-right"></i></h4>
        </span>
    </div>
</div>
