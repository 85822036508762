<div class="asset-add-panel">
  <cl-side-panel>
    <section class="cl-side-panel-title side-panel-header-bg">
      <span class="black font-bold"> {{ isEdit ? "Edit" : "New" }} {{'common.Asset'|translate}}</span>
    </section>

    <section class="cl-side-panel-content px-1">
        <ng-container *ngIf="!isDetailsFetching">
          <div class="user-detail__item p-2" *ngIf="!assetCatalog?.name">
            <label class="form-label font-bold">Select Asset Type</label>
            <div>
              <mat-select
                class="basic-input w-full"
                placeholder="Select Asset Type"
                [(ngModel)]="catalogType"
                [ngModelOptions]="{standalone: true}">
                <mat-option
                  [value]="catalogType"
                  *ngFor="let catalogType of catalogTypesList"
                  >{{ catalogType.name }}</mat-option
                >
              </mat-select>
              <span *ngIf="isCatalogTypeError" class="text-danger">Please Select Asset Type</span>
            </div>
          </div>
          <div *ngIf="assetCatalog">
            <div class="p-2 sticky top-0 bg-white z-[999]">
              <span class="flex items-center">
                <span
                  class="black font-bold text-lg mr-2 pointer color4"
                  *ngIf="!isEdit"
                  (click)="assetCatalog = undefined"
                >
                  <i class="fa fa-chevron-circle-left"></i>
                </span>
                <label
                  class="black text-sm truncate"
                  [title]="assetCatalog?.name"
                >
                  {{ isEdit ? "Edit" : "New" }} {{ assetCatalog?.name }}
                </label>
              </span>
            </div>
            <mat-tab-group
              #tab
              *ngIf="!isEdit"
              (click)="onTabClick(tab)"
              class="sticky top-11 bg-white z-[999]"
            >
              <mat-tab label="Single"></mat-tab>
              <mat-tab label="Bulk Add via CSV"></mat-tab>
            </mat-tab-group>
            <ng-container *ngIf="tabSelected == 0">
              <div class="flex h-16 px-2">
                <img [src]="assetAvatar || defaultAvatar" class="h-16 w-16" />
                <div class="h-full flex flex-col justify-center ml-4">
                  <label
                    for="assetAvatar"
                    class="color4 cursor-pointer font-bold"
                    >{{ assetAvatar ? "Replace Image" : "Upload Image" }} <i class="fa fa-info-circle ml-2 color13" title="Acceptable file formats: JPG, PNG, max. 2mb"></i></label
                  >
                  <input
                    type="file"
                    class="hidden"
                    accept="image/png, image/jpg, image/jpeg"
                    id="assetAvatar"
                    (change)="onAvatarChange($event)"
                  />
                </div>
              </div>
              <div class="px-2">
                <cl-dynamic-form-renderer
                  *ngIf="inputFields.length > 0"
                  [inputFields]="inputFields"
                  #formRenderer></cl-dynamic-form-renderer>
              </div> 
            </ng-container>
            <div *ngIf="tabSelected == 1">
              <cl-bulk-action
                [panelObj]="bulkConfig"
                (emitFormData)="bulkFormData = $event"
                [fields]="downloadedCSVFields"
                [downloadedCSVFileName]="assetCatalog?.objectType+'_add_template'"
              ></cl-bulk-action>
            </div>
          </div>
        </ng-container>
    </section>

    <section class="cl-side-panel-footer bgcolor33 p-2">
      <div class="flex">
        <div class="flex-col grow text-right">
          <a
            class="m-2"
            (click)="closePanel(false)"
            title="cancels the changes and closes the form"
            >{{'common.cancel'|translate}}</a
          >
          <button
            class="panel-button white bgcolor4 ml-2"
            (click)="assetCatalog ? onSave() : onNext()"
          >
            {{ assetCatalog ? (tabSelected == 0 ? ('common.save'|translate) : ('common.upload'|translate)) : ('common.next'|translate)}}
            <span
              *ngIf="isCreatingAsset"
              class="fa fa-circle-o-notch fa-pulse white"
              [ngClass]="{ visible: isCreatingAsset, hidden: !isCreatingAsset }"
            ></span>
          </button>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>
<div>
  <ng-template #qrBarRFIDCode class="mx-2 mb-4">
    <div class="user-detail__item ng-star-inserted" style="width: 100%;">
      <label class="form-label" for="">QR/Bar Code/RFID</label>
      <input type="text" class="basic-input w-full text-xs pl-1.5 pr-5 h-[30px]" 
      placeholder="Enter QR/Bar Code/RFID as a string value" [(ngModel)]="qrBarCode" (keyup.enter)="handleScannedValue()" (focusout)="handleScannedValue()">
    </div>
    <div class="popover__wrapper" *ngIf="codeScanned">
      <div class="popover__content" [ngClass]="codeScanned ? 'display-content' : ''">
          <div class="pop">
              <div class="model-header">
                  <span class="font-bold fs14 header-font-color">Scanned Codes</span>
                  <span class="scanItem-cancel float-right pointer" (click)="closeScanCodeModel()" title="Cancel and close selection panel">
                    <i class="fa fa-times-circle fa-2x" aria-hidden="true"></i>
                  </span>
                  <span class="mr-2 float-right "[ngClass]="[!isOptionSelected ? 'disabled':'scanItem-found pointer']" (click)="fnScanCodeChanged()" title="Use selected value for QR / Bar Code / RFID">
                    <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i>
                  </span>
              </div>
              <div class="model-body scroll">
                <label class="font-bold">Multiple values input? Select option below:</label>
                <div *ngIf="showScanSearchbox"><input type="text" placeholder="Search Scanned Code" class="basic-input w-full text-xs pl-1.5 pr-5 h-[30px] ml-0" [(ngModel)]="scannedCodeText" (input)="tiggerScannedCodeSearch()"/></div>
                  <div class="lease-summary">
                    <div class="grid-30-70 mb15">
                      <mat-radio-group [(ngModel)]="scanCode" (change)="fnScanCodeSelect()" required="true">
                        <div *ngFor="let scannedCode of scannedCodelist" class="pt-2">
                          <mat-radio-button [value]="scannedCode">
                            <span>{{scannedCode}}</span>
                          </mat-radio-button>
                        </div>
                      </mat-radio-group>
                   </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </ng-template>
</div>
 