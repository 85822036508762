import { PartialOf } from "./core";


export interface Coordinates {
  lat: string | number;
  lng: string | number;
}

export interface AggregateApi {
  url: string;
  payload: string;
  type: string;
}

export interface AggApiSearchQuery {
  fieldName: string;
  queryText: string;
  queryOperator: string;
}

export interface AggApiFilterPayload {
  scrollSize: number;
  globalQueryText: string;
  searchQueries: AggApiSearchQuery[];
  scrollId: string;
}

export interface NavigatorAggApi {
  url: string;
  payload: AggApiFilterPayload;
  type: "GET" | "PUT" | "POST" | "DELETE";
}

export interface NavigatorFilterConfig {
  label: string;
  aggregateApi: NavigatorAggApi;
  isSearchable: boolean;
  order: number;
  fieldName: PartialOf<string>;
}

export interface NavigatorFilterMap extends PartialOf<NavigatorFilterConfig> {}

export interface NavigatorFilter extends NavigatorFilterConfig {
  id: string;
  svgIconUrl: string;
  domain: string;
}

export declare type NavigatorFilterList = Array<NavigatorFilter>;

export enum NavigatorViewModes {
  MAP = "MAP",
  NETWORK = "NETWORK",
}

export interface NavigatorMapZoomDetails {
  min: number;
  max: number;
  default: number;
}

export interface NavigatorConfiguration {
  createdAt: string;
  createdBy: string;
  clfMappingType: string;
  name: string;
  tenantId: string;
  baseClass: string;
  id: string;
  value: any;
  classType: string;
}

export interface NavigatorConfig {
  coordinates: Coordinates;
  initialData: NavigatorAggApi;
  domain: NavigatorFilterMap;
  filter: NavigatorFilterMap;
  search: NavigatorFilterConfig;
  viewModes: NavigatorViewModes[];
  defaultViewMode: NavigatorViewModes;
  mapZoom?: NavigatorMapZoomDetails;
}