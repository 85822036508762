import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventComService {
  private event = new Subject<any>();
  private translationsLoaded:boolean = false;
  private themesLoaded:boolean = false;
  private settingsLoaded:boolean = false;
  constructor() { }
  getTranslationsLoadStatus() {
    return this.translationsLoaded;
  };

  setTranslationsLoadStatus(status:boolean) {
    this.translationsLoaded = status;
  };

  getThemeLoadStatus() {
    return this.themesLoaded;
  };

  setThemeLoadStatus(status:boolean) {
    this.themesLoaded = status;
  };

  getSettingsLoadStatus() {
    return this.settingsLoaded;
  };

  setSettingsLoadStatus(status:boolean) {
    this.settingsLoaded = status;
  };

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }
}
