<div class="asset-add-panel">
    <cl-side-panel>
      <section class="cl-side-panel-title side-panel-header-bg">
        <span class="black font-bold"> {{ isEdit ? "Edit" : "New" }} Gateway </span>
      </section>
    <section class="cl-side-panel-content px-1 py-2">
      <div class="user-detail__item p-2" *ngIf="!gatewayCatalog?.name && !isEdit">
        <label class="form-label font-bold">Select Gateway Type</label>
        <div>
          <mat-select
            class="basic-input w-full"
            placeholder="Select Gateway Type"
            [(ngModel)]="catalogType"
          >
            <mat-option
              [value]="catalogType"
              *ngFor="let catalogType of catalogTypesList"
              >{{ catalogType.name }}</mat-option
            >
          </mat-select>
          <span *ngIf="isCatalogTypeError" class="text-danger">Please Select Gateway Type</span>
        </div>
      </div>
      <ng-container *ngIf="!isDetailsFetching">
      <div class="px-2">
        <mat-tab-group
          #tab
          *ngIf="!isEdit && gatewayCatalog?.name"
          (click)="onTabClick(tab)"
          class="sticky top-0 bg-white z-[999]"
        >
          <mat-tab label="Single"></mat-tab>
          <mat-tab label="Bulk Add via CSV"></mat-tab>
        </mat-tab-group>
        <ng-container *ngIf="tabSelected == 0">
        <cl-dynamic-form-renderer
          *ngIf="inputFields && inputFields.length > 0"
          [inputFields]="inputFields"
          [form]="formGroup"
          (onInputBlur)="inputBlur($event)"
          #formRenderer
        >
        <section [formGroup]="formGroup" id="rssiValue" *ngIf="showRSSIValue">
            <div
              class="user-detail__item"
              style="width: 100%"
            >
              <label class="form-label font-bold"> RSSI Value <span class="mandatory text-red-500"
                >*</span> </label>
              <input
                type="number" required
                class="basic-input rssi-val w-full text-xs pl-1.5 pr-5 h-[30px]"
                [placeholder]="
                  'Enter as a number value'
                " 
                formControlName="rssiValue"
                min="-100"
                max="-40"
              />
              <span class="rssi-fs">Min RSSI Value : -100 Max RSSI Value : -40</span>
              <div
              class="text-danger"
              *ngIf="submitted && formGroup.get('rssiValue').errors"
            >
            <span *ngIf="formGroup.get('rssiValue').getError('required')"
              >RSSI value is required</span
            >
            <span *ngIf="formGroup.get('rssiValue').getError('min') || formGroup.get('rssiValue').getError('max')"
              >Please enter valid RSSI value</span
            >
          </div>
            </div>
          </section>
    </cl-dynamic-form-renderer>
  </ng-container>
  <ng-container *ngIf="tabSelected == 1">
    <cl-bulk-action
      [panelObj]="bulkConfig"
      (emitFormData)="bulkFormData = $event"
      [fields]="downloadedCSVFields"
      [downloadedCSVFileName]="'gateway_add_template'"
    ></cl-bulk-action>
  </ng-container>
      </div>
    </ng-container>
</section>
<section class="cl-side-panel-footer bgcolor33 p-2">
    <div class="flex">
      <div class="flex-col grow text-right">
        <a
          class="m-2"
          (click)="closePanel(false)"
          title="cancels the changes and closes the form"
          >Cancel</a
        >
        <button class="panel-button white bgcolor4 ml-2" (click)="gatewayCatalog ? onSave() : onNext()">
          {{ gatewayCatalog ? (tabSelected == 0 ? "Save" : "Upload") : "Next" }}
          <span
            *ngIf="isCreatingGateway"
            class="fa fa-circle-o-notch fa-pulse white"
            [ngClass]="{ visible: isCreatingGateway, hidden: !isCreatingGateway }"
          ></span>
        </button>
      </div>
    </div>
  </section>
</cl-side-panel>
  </div>
