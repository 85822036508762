import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from "@angular/core";
import { ButtonTabComponent } from "./button-tab.component";

@Component({
  selector: "button-tab-group",
  template: `
    <!-- Header -->
    <div class="w-100">
      <mat-button-toggle-group color="primary" class="foz12 w-100" *ngIf="tabs?.length">
        <mat-button-toggle
          class="w-100"
          *ngFor="let tab of tabs; let index = index"
          [value]="tab?.id"
          [class.selected]="tab?.active"
          (click)="selectTab($event, tab, index)"
        >
          <i class="{{ tab?.faIcon }} mr-2" *ngIf="tab?.faIcon"></i>
          <span>{{ tab?.tabTitle }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- Body -->
    <ng-content></ng-content>
  `,
  styleUrls: ["./button-tab-group.component.scss"],
})
export class ButtonTabGroupComponent implements AfterContentInit {
  @ContentChildren(ButtonTabComponent) tabs: QueryList<ButtonTabComponent>;
  @Output() tabChanged = new EventEmitter<number>();

  @Input() selectedIndex: number;
  @Output() selectedIndexChange = new EventEmitter<number>();

  constructor() {}

  ngAfterContentInit() {
    if (this.tabs?.length) {
      this.selectFirstTabIfNoneSelected();
    }
  }

  selectTab(event: MouseEvent, tab: ButtonTabComponent, index: number) {
    this.tabs.forEach((_tab, _index) => {
      if (_index === index) {
        _tab.active = true;
      } else {
        _tab.active = false;
      }
    });

    this.selectedIndexChange.emit(index);

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Deselects all available tabs
   */
  private deSelectAllTabs() {
    this.tabs?.forEach((tab) => (tab.active = false));
  }

  /**
   * Selects the first tab if none of the tab are explicitly selected by the user
   */
  private selectFirstTabIfNoneSelected() {
    if (!this.tabs?.length || this.isAnythingSelected() || isNaN(this.selectedIndex)) {
      return;
    }

    this.selectTab(null, null, this.selectedIndex);
  }

  /**
   * Returns `true` if any tab is currently set as active otherwise `false`.
   */
  private isAnythingSelected(): boolean {
    const tabs = this.tabs.filter((tab) => tab.active === true);

    return tabs?.length > 0;
  }
}
