import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import _ from "lodash";


/**
 * Note: Some of the source copied from Angular github repo
 *
 * @see https://github.com/angular/angular/blob/11.2.12/packages/forms/src/validators.ts
 */



function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

/**
 *
 * @see https://stackoverflow.com/a/14794066/2401088
 */
function isInt(value: any) {
  // tslint:disable-next-line: radix
  return !isNaN(value) && parseInt(value) == value && !isNaN(parseInt(value, 10));
}

/**
 * Validator that requires the control's value to be in range to the provided number.
 * See `ClFormValidators.range` for additional information.
 */
export function rangeValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors|null => {
    if (isEmptyInputValue(control.value) || (isEmptyInputValue(max) && isEmptyInputValue(min))) {
      return null;  // don't validate empty values to allow optional controls
    }
    const value = parseFloat(control.value);
    // Controls with NaN values after parsing should be treated as not having a
    // maximum, per the HTML forms spec: https://www.w3.org/TR/html5/forms.html#attr-input-max
    return !isNaN(value) && (value > max || value < min)
      ? {'range': {'max': max, 'min': min, 'actual': control.value}}
      : null;
  };
}

/**
 * Validator that requires the control's value to be a number without fractions.
 * See `ClFormValidators.integer` for additional information.
 */
export function isIntegerValidator(control: AbstractControl): ValidationErrors | null {
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }

  return !isInt(control.value)
    ? {'integer': true }
    : null;
}

export function confirmPasswordValidator(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn {

  return (group: FormGroup): ValidationErrors | null => {

    if (!group) {
      throw new Error('Confirm password wrapping group is undefined');
    }

    const passwordControl        = group?.controls[passwordControlName];
    const confirmPasswordControl = group?.controls[confirmPasswordControlName];

    if (!passwordControl || !confirmPasswordControl) {
      throw new Error('Invalid password and confirm password form control name');
    }

    const failedValidationError = {
      confirmPassword: {
        matched: false,
        password: passwordControl?.value,
        confirmPassword: confirmPasswordControl?.value,
      }
    };

    if (passwordControl?.value !== confirmPasswordControl?.value) {
      confirmPasswordControl.setErrors({
        ...confirmPasswordControl.errors,
        ...failedValidationError,
      });
    } else if (confirmPasswordControl.hasError('confirmPassword')) {

      const allErrors = confirmPasswordControl.errors;
      delete allErrors['confirmPassword'];

      const errorLength = _.size(allErrors);
      const errors = errorLength === 0 ? null : allErrors;

      confirmPasswordControl.setErrors(errors);
    }

    return null;
  };
}

// export function excludeCharactersRegexValidator(chars: string | RegExp): ValidatorFn {
//   return (control: AbstractControl): { [key: string]: any } | null => {
//     const regex = new RegExp(chars);
//     const charsForbidden =  regex.test(control.value);
//     let unEscapedRegex = unescape(regex.source);    // Unescape
//     unEscapedRegex = unEscapedRegex.substring(1, unEscapedRegex.length - 1);  // Remove starting [ and ending ]
//     return charsForbidden ? { charsForbidden: { value: regex.source, unEscapedRegex, source: unescape(chars.toString()) } } : null;
//   };
// }


/**
 * Custom form validators used across the app
 */
export class ClFormValidators {

  /**
   * @description
   * Validator that requires the control have a value in a numeric range.
   *
   * @usageNotes
   *
   * ### Validate that the field is in a range
   *
   * ```typescript
   * const control = new FormControl('', ClFormValidators.range(1, 100));
   *
   * console.log(control.errors); // {range: {min: 1, max: 100, actual: 500}}
   * ```
   *
   * @returns An error map with the `range` property
   * if the validation check fails, otherwise `null`.
   *
   * @see `updateValueAndValidity()`
   *
   */
  static range(min: number, max: number): ValidatorFn {
    return rangeValidator(min, max);
  }

  /**
   * @description
   * Validator that requires the control have a value in an integer value.
   * Value must not be in fractions
   *
   * @usageNotes
   *
   * ### Validate that the field is integer
   *
   * ```typescript
   * const control = new FormControl('', ClFormValidators.integer);
   *
   * console.log(control.errors); // {integer: true}
   * ```
   *
   * @returns An error map with the `integer` property
   * if the validation check fails, otherwise `null`.
   *
   * @see `updateValueAndValidity()`
   *
   */
  static integer(control: AbstractControl): ValidationErrors | null {
    return isIntegerValidator(control);
  }

  /**
   * @description
   * Validator that requires the control have a value in excluding certain characters.
   *
   * @usageNotes
   *
   * ### Validate that the field is not containing mentioned characters
   *
   * ```typescript
   * const control = new FormControl('', ClFormValidators.excludeCharactersRegex);
   *
   * console.log(control.charsForbidden); // {charsForbidden: "?"}
   * ```
   *
   * @returns An error map with the `charsForbidden` property
   * if the validation check fails, otherwise `null`.
   *
   * @see `updateValueAndValidity()`
   *
   */
  // static excludeCharactersRegex(chars: string | RegExp): ValidationErrors | null {
  //   return excludeCharactersRegexValidator(chars);
  // }

  /**
   * @description
   * Validator that requires the password and confirm password form control value
   * to be same.
   *
   * @usageNotes
   *
   * ### Validate that password and confirm password field is same
   *
   * ```typescript
   * const control = new FormGroup({
   *   password: new FormControl(''),
   *   confirmPassword: new FormControl(''),
   * },
   * [ ClFormValidators.confirmPassword('password', 'confirmPassword') ]
   * );
   *
   * console.log(control.errors); // {confirmPassword: { matched: false }}
   * ```
   *
   * @returns An error map with the `confirmPassword` property
   * if the validation check fails, otherwise `null`.
   *
   * @see `updateValueAndValidity()`
   *
   */
  static confirmPassword(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn | null {
    return confirmPasswordValidator(passwordControlName, confirmPasswordControlName);
  }

  // static confirmPassword()

  constructor() {
    throw new Error('Static class cannot instantiate');
  }
}
