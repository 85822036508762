<div class="filter-box-wrapper cursor-pointer {{filter?.domain}}" matRipple
  [matMenuTriggerFor]="filterMenu" (menuOpened)="onMenuOpened($event)">
  <span class="filter-icon dark-icon {{filter?.domain}}" [inlineSVG]="filter?.svgIconUrl"></span>
  <div class="filter-content">
    <h5 class="m-0 capitalize darkerType">{{filter.label}}</h5>
    <p class="m-0 foz-13">{{activeFilters.length > 0 ? activeFilters.length + ' selection' : 'All'}}</p>
  </div>
  <i class="filter-arrow fa fa-angle-down font-bold main-color foz20"></i>
</div>

<mat-menu #filterMenu="matMenu" class="filter-box-menu-wrapper">
  <div class="filter-box-menu {{filter.domain}}">
    <div class="filter-stripe {{filter.domain}}">
    </div>
    <div class="filter-container gap-1 css-grid pt-2 px-2" (click)="$event.stopPropagation()">

      <a *ngIf="!clearDisabled && !disabled && activeFilters.length > 0" class="foz12 justify-self-end" (click)="clearAll()">clear all</a>
      <span *ngIf="clearDisabled || disabled || activeFilters.length <= 0" class="fade-2 foz12 justify-self-end">clear all</span>

      <form *ngIf="filter.isSearchable" [formGroup]="searchForm" novalidate class="filter-form w-100">
        <input type="search" class="basic-input m-0 w-100" placeholder="Filter list" formControlName="searchTerm" />

        <div class="scroll-list-container">
          <ul class="w-100">
            <cdk-virtual-scroll-viewport itemSize="22" class="h-100"
              (scrolledIndexChange)="listScrolled($event)">
              <li *cdkVirtualFor="let item of filterList; trackBy: trackByIdFn; templateCacheSize: 0"
                class="filter-item css-grid grid-flow-col align-items-center"
                formGroupName="filters">
                <mat-checkbox [formControlName]="item.id" class="list-checkbox">
                  <div class="grid-auto-max-content css-grid">
                    <span class="filter-name ellipsis">{{item?.name}}</span>
                    <span></span>
                  </div>
                </mat-checkbox>
              </li>
            </cdk-virtual-scroll-viewport>
          </ul>
          <app-load-more
            [numToLoad]="filter?.aggregateApi?.payload?.scrollSize || 20"
            [loading]="apiParams.loading"
            [allLoaded]="!apiParams.hasMore"
            (loadMoreEmitter)="loadMore($event)">
          </app-load-more>
        </div>
      </form>


    </div>
  </div>
  </mat-menu>
