import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import "keylines";
import { KlComponent, KlComponents, KlComponentsService } from "./angular-keylines";

@NgModule({
  declarations: [KlComponent, KlComponents],
  imports: [CommonModule],
  providers: [KlComponentsService],
  exports: [KlComponent, KlComponents],
})
export class AngularKeylinesModule {}
