<ng-container [ngTemplateOutlet]="this[templateName]" [ngTemplateOutletContext]="contextObject">
</ng-container>
<ng-template #wifi let-fillColor="fillClass" let-strokeColor="strokeClass">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93 93" [class]="classes">
    <path [class]="fillColor"
      d="M46,53.93a6.36,6.36,0,1,0,6.35,6.36A6.36,6.36,0,0,0,46,53.93Zm0,9a2.69,2.69,0,1,1,2.68-2.68A2.68,2.68,0,0,1,46,63Z"></path>
    <path [class]="[fillColor, strokeColor]"
      d="M35.63,54.09c0-.06,2.11-5.6,10.41-5.6s10.3,5.35,10.4,5.6A1.33,1.33,0,0,0,59,53.18c-.11-.3-2.76-7.35-12.91-7.35s-12.81,7.05-12.91,7.35a1.24,1.24,0,0,0-.08.46,1.34,1.34,0,0,0,.88,1.25A1.32,1.32,0,0,0,35.63,54.09Z"></path>
    <path [class]="[fillColor, strokeColor]"
      d="M46,39.6c9.08,0,14.35,3.43,17.17,6.31A1.34,1.34,0,0,0,65.11,44C61.93,40.8,56,36.93,46,36.93S30.16,40.79,27,44a1.35,1.35,0,0,0-.38.93,1.33,1.33,0,0,0,2.28.94C31.7,43,37,39.6,46,39.6Z"></path>
    <path [class]="[fillColor, strokeColor]"
      d="M46,29.61c12.14,0,19.23,4.6,23,8.46A1.34,1.34,0,0,0,71,36.19C66.8,32,59.1,26.94,46,26.94s-20.75,5-24.91,9.24a1.31,1.31,0,0,0-.38.94,1.33,1.33,0,0,0,2.28.94C26.83,34.2,33.91,29.61,46,29.61Z"></path>
  </svg>
</ng-template>
<ng-template #sensor let-fillColor="fillClass">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 94" [class]="classes">
    <path [class]="fillColor"
      d="M60.72,25A1.83,1.83,0,0,0,58.55,28a21.23,21.23,0,0,1-.16,34.33,1.83,1.83,0,0,0,1.07,3.32,1.78,1.78,0,0,0,1.07-.35A24.89,24.89,0,0,0,60.72,25Z"/>
    <path class="dark-fill"
      d="M33,61.83a21.08,21.08,0,0,1-8.21-16.77,21.3,21.3,0,0,1,8.71-17.14,1.83,1.83,0,0,0-2.16-3,24.9,24.9,0,0,0-.59,39.77,1.83,1.83,0,0,0,1.13.38,1.81,1.81,0,0,0,1.44-.71A1.83,1.83,0,0,0,33,61.83Z"/>
    <path [class]="fillColor"
      d="M63.19,45.06a17.05,17.05,0,0,0-6.91-13.78,1.83,1.83,0,0,0-2.19,2.94,13.42,13.42,0,0,1,5.43,10.84A13.59,13.59,0,0,1,54,56,1.83,1.83,0,0,0,55,59.33,1.78,1.78,0,0,0,56.1,59,17.26,17.26,0,0,0,63.19,45.06Z"/>
    <path [class]="fillColor"
      d="M37.75,55.8a13.53,13.53,0,0,1,0-21.49,1.83,1.83,0,0,0-2.23-2.91,17.21,17.21,0,0,0,0,27.31,1.83,1.83,0,0,0,2.57-.34A1.82,1.82,0,0,0,37.75,55.8Z"/>
    <path [class]="fillColor"
      d="M46,37.38a7.69,7.69,0,1,0,7.68,7.69A7.68,7.68,0,0,0,46,37.38Zm0,11.8a4.12,4.12,0,1,1,4.12-4.11A4.12,4.12,0,0,1,46,49.18Z"/>
  </svg>
</ng-template>
<ng-template #battery let-fillColor="fillClass" let-strokeColor="strokeClass" let-height="height">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 93.6 93.6" width="30" height="40"  [class]="classes">
    <g><g><g>
      <path
        d="M59.31,74.38H35.45a4.2,4.2,0,0,1-4.2-4.2V26.08a4.21,4.21,0,0,1,4.2-4.2h4V18.73a4.21,4.21,0,0,1,4.2-4.2h7.35a4.21,4.21,0,0,1,4.2,4.2v3.15h4.06a4.21,4.21,0,0,1,4.2,4.2v44.1A4.21,4.21,0,0,1,59.31,74.38Zm-23.86-49a.7.7,0,0,0-.7.7v44.1a.7.7,0,0,0,.7.7H59.31a.7.7,0,0,0,.7-.7V26.08a.71.71,0,0,0-.7-.7H51.75V18.73a.7.7,0,0,0-.7-.7H43.7a.71.71,0,0,0-.7.7v6.65Z"
        [class]="fillColor"></path>
      <path
        d="M56,47.24V66.51a1.22,1.22,0,0,1-1.22,1.22H40a1.23,1.23,0,0,1-1.23-1.22V58Z" [class]="fillColor"></path>
    </g></g></g>
  </svg>
</ng-template>
