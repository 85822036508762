import { Injectable } from '@angular/core';
import { UserService } from "@cl/user/user.service";
import * as moment from 'moment';
declare global {
  interface Window{
    dataLayer: any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class WebtrackerService {
  trackingObject: any = {
    trackEnabled: false,
    trackerId: null
  };
  GTM_Tracker: any;
  constructor(private user: UserService) { }

  setTrackingConfig(tracker) {
    if(tracker) {
      this.GTM_Tracker = tracker;
    }
  }

  getTrackingConfig() {
    return this.trackingObject;
  }

  isTrackingEnabled() {
    const tenantId = this.user.getTenantId();
    if(this.GTM_Tracker && this.GTM_Tracker.hasOwnProperty(tenantId)) {
      this.trackingObject.trackerId = this.GTM_Tracker[tenantId];
      this.trackingObject.trackEnabled= true;
    }
    return this.trackingObject.trackEnabled;
  }

  addGAScript() {
    const tracker: any = this.trackingObject;
    if(this.isTrackingEnabled()) {
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = `https://www.googletagmanager.com/gtm.js?id=${tracker.trackerId}`;
      document.head.appendChild(gTagManagerScript);

      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'gtm.start':new Date().getTime(),event:'gtm.js'})`;
      document.head.appendChild(gaScript);
    }
  }

  captureEvent(name, params) {
    if(this.isTrackingEnabled()) {
      let allParams = {...params};
      allParams.userId = this.user.getUserId();
      allParams.tenantName = this.user.getTenantName();
      allParams.applicationName = 'Operate';
      allParams.eventName = name;
      allParams.event = name;
      allParams.eventTime = moment().format('YYYY-MM-DD hh:mm:ss');
      allParams.eventRawTime = moment().valueOf();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(allParams);
    }
  }

}
