import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { AssetBasicInformation } from '@cl/@types/asset.type';
import { CharacteristicValue, TaggedAssetCharacteristicValue } from '@cl/@types/characteristic-value.type';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(private http: HttpClient, private apiProvider: ApiProviderService) { }
  getAssetDetails(assetId: string, assetType?: string) : Promise<AssetBasicInformation>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/${assetId}?type=${assetType || ''}`);
    return firstValueFrom(obs$);
  }
  getAssetExtendedAttributesConfiguration(assetType : string): Promise<any>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/catalog/${assetType}`);
    return firstValueFrom(obs$);
  }
  getTaggedAssetCharacteristicValues(taggedAssetId: string): Promise<CharacteristicValue[]>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${taggedAssetId}`);
    return firstValueFrom(obs$);
  }
  getTaggedAssetCharacteristicValuesByRange(taggedAssetId: string, sid: string, cid: string, from: number, to: number, sampleSize: number): Promise<TaggedAssetCharacteristicValue>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/${taggedAssetId}/${sid}/${cid}?from=${from}&to=${to}&limit=${sampleSize}`);
    return firstValueFrom(obs$);
  }
  getLocationVisits(assetId: string, dateRange?: any) {
    let params = {};
    if (!dateRange) {
      var dt = new Date();
      params = {
        from: new Date(dt.setMonth(dt.getMonth() - 1)).getTime(),
        to: (new Date()).getTime(),
      }
    } else {
      params = dateRange;
    }
    return this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/peripheral/${assetId}/visits`, {params});
  }
  getTaggedAssetLastValidHandOff(taggedAssetId: string): Promise<any>{
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/data/stream/${taggedAssetId}/lastvalidhandoff`);
    return firstValueFrom(obs$);
  }

  getAdditionalAssetDetails(assetId: string){
    const payload = {
      "scrollId": "",
      "match": "must",
      "scrollSize": 50,
      "globalQueryText": "",
      "searchQueries": [
          {
              "fieldName": "baseClass",
              "queryOperator": "should",
              "queryText": "Asset",
              "queryType": "match",
              "childFilters": [
                  {
                      "fieldName": "deleted",
                      "queryText": "true",
                      "queryOperator": "must_not"
                  },
                  {
                      "fieldName": "id",
                      "queryText": assetId,
                      "queryOperator": "must"
                  },
              ]
          }
      ],
      "searchFieldFilter": []
    }
    const obs$ = this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/global/search`, payload);
    return firstValueFrom(obs$);
  }
  getAssetExcursions(typeId: any, taggedAssetId: string) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/violations/` + typeId + '/' + taggedAssetId
    );
  }

  createAsset(payload,objectType):Promise<any> {
    const obs$ = this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/asset?type=${objectType || ''}`,payload);
    return firstValueFrom(obs$)
  }

  updateAsset(payload,objectType,assetId){
    const obs$ = this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/asset/${assetId}?type=${objectType || ''}`,payload);
    return firstValueFrom(obs$)
  }

  deleteAssets(
    payload: {
      id: string;
      externalId: string;
      name: string;
      classType: string;
    }[]
  ) {
    const obs$ = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/asset/delete`,
      payload
    );
    return firstValueFrom(obs$);
  }

  uploadAvatar(payload: FormData, assetId: string, entityType:string) {
    const obs$ = this.http.put(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/${entityType}/image/${assetId}`,payload);
    return firstValueFrom(obs$);
  }
}
