import { Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, } from '@angular/core';  

import { ToastService } from '@cl/common/services/toast.service';
import { UserService } from '@cl/user/user.service';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { RecipeService } from '../recipe.service';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;
@Component({
  selector: 'cl-recipe-add-panel',
  templateUrl: './recipe-add-panel.component.html',
  styleUrls: ['./recipe-add-panel.component.scss']
})
export class RecipeAddPanelComponent implements OnInit {
  @Input() isAdd: boolean = true;
  @Input() showLeftPanel: boolean = true;
  @Input() showPanel: boolean = true;
  @Input() assetId!: string;
  @Input() selectedTrigger:string = '';
  @Input() selectedEntityType:string = '';
  @Input() recipeObject:any=null;
  @Input() selectedRecipe:any=null;  
  @Input() recipeReadOnly:boolean=false;

  

  @Output() closeSidePanel = new EventEmitter<boolean>();

  topicList:any;
  triggerList:any;
  trigger:any
  isCatalogTypeError:boolean=false;
  topic:string="";
  timer:any="";
  selectedQCode:string;
  selectedEntityId:string;
  @ViewChild('codeMirror')
  codeMirror: CodemirrorComponent;
  isSaveBtnDisabled:boolean=false;
  isShowLoading:boolean=false;
  readOnly:boolean=false;
  regularExpression = /^[a-zA-Z0-9_]$/;
  
  codeMirrorOption = {
    lineNumbers: true,
    theme: 'default',
    mode: 'markdown',
    scrollbarStyle: 'null',
    gutters: ["CodeMirror-linenumbers", "CodeMirror-lint-markers", "breakpoints"],
    gutterFold: true,
    lint: true,
    lineWrapping: true,
    foldGutter: true,
    autoCloseBrackets: true,
    matchBrackets: true
  };
  
  entitiesList:any=[];    
  entityDetails:any = [];
  entitiesRows: any;  
  selectedEntity:string='';
  selectedName:string='';

  selectedEntityObj:any
  public isLoading: boolean = false;
  active:boolean = true;
  recipeMode:string=""; 

  constructor(private recipeService: RecipeService,
    private toastr: ToastService, private userService:UserService,
    
    private dialog: MatDialog,) { }

  ngOnInit(): void { 
    
    this.triggerList = [
      {
        label: "FUNCTION",
        event: "function",
        key: null
      },
      {
        label: "UPDATE",
        event: "postConfigUpdate",
        key: null
      },
      {
        label: "CREATE",
        event: "postCreate",
        key: null
      },
      {
        label: "PROPERTIES",
        event: "postConfigUpdate",
        key: "<provided value>"
      },
      {
        label: "TIMER",
        event: "alarm",
        key: null,
        timer: "<provided value>"
      },
      {
        label: "EVENT",
        event: "event",
        key: undefined,
        topic: "<provided value>"
    },
    {
        label: "VIOLATION",
        event: "violation",
        key: undefined,
        topic: "<provided value>"
    },
      {
        label: "UI",
        event: "UITrigger",
        key: undefined
    },
    // {
    //     label: "BRE",
    //     event: "bre",
    //     key: undefined,
    //     topic: "<provided value>"
    // }
      
    ];
    this.recipeService.getEntitiesList(this.userService.getTenantId()).subscribe((res:any)=>{
      this.entitiesList = res.data;
    })

    if(!this.isAdd && this.selectedRecipe){
      this.showPanel = false;
      this.editRecipe(this.selectedRecipe.name, this.selectedRecipe.trigger, this.selectedRecipe.properties?.exec, this.selectedRecipe, true)
    }
    this.getEntityById();

    

    if(this.recipeObject){    
      this.showPanel = true;     
      this.selectedName= this.recipeObject.name;
      this.selectedTrigger = this.triggerList.filter(item=>item.event === this.recipeObject.trigger)[0]?.label;
      this.selectedQCode = this.recipeObject.qCode;
      this.timer = this.recipeObject.timer?this.recipeObject.timer:"";
      this.topic= this.recipeObject.topic?this.recipeObject.topic:"";
      this.recipeMode = this.recipeObject.mode?this.recipeObject.mode:"";
      this.active = this.recipeObject.status===true?true:false;
      //this.selectedEntityObj = this.recipeObject;
      this.isAdd = false;
      if(this.recipeObject?.trigger?.toLowerCase()==="event"){  
        this.recipeService.getTopicList().subscribe(data=>{
          this.topicList = Object.entries(data);
          this.topicList.unshift(["STREAM","STREAM"]);
        })     
     }
      else if(this.recipeObject?.trigger?.toLowerCase()==="violation"){  
        this.topicList=[];   
      this.topicList.push(["VIOLATION","VIOLATION"]);
    }
    }      
  }
  closePanel(isReload) {    
    if(!this.isAdd){
      this.showPanel=false;
    }
    this.readOnly = false;
    this.closeSidePanel.emit();
  }

  detectChange(ev){
    this.isSaveBtnDisabled=false;
  }
  discardCodeChanges(){

  }
  
  saveRecipeQCode(){
    if((this.selectedTrigger==="ALARM" && this.timer==="")||((this.selectedTrigger==="EVENT" || this.selectedTrigger==='PROPERTIES') && this.topic==="")){
      return;
    }
    if(this.selectedEntity){
      
    }else{

      this.isShowLoading=false;
      this.toastr.error("Please Select catalog entity");
      return;
    }
    if(this.selectedTrigger==="TIMER"){
      if(this.timer && this.timer < 60){
        this.isShowLoading=false;
        this.toastr.error("The time interval value must be greater than or equal to 60 mins");
        return;
      }
      
    }
    if(this.selectedEntity && this.selectedName && this.selectedTrigger && this.selectedQCode){
      const trigger = this.triggerList.filter(item=>item.label===this.selectedTrigger);
      const selectedEntity = this.entitiesList.filter(item=>item.productNamespace === this.selectedEntity);
      let reqObj={
            "trigger": trigger.length>0?trigger[0].event:"",
            "label": this.selectedTrigger,
            "timer": this.timer,
            "topic": this.topic,
            "key": null,
            "name": this.selectedName,
            "qcardName": this.selectedEntity,
            "tenantId": this.userService.getTenantId(),
            "qCode": this.selectedQCode,
            "createdBy": this.userService.getUserId(),
            "createdAt": this.isAdd? new Date().toLocaleDateString()+' '+new Date().toLocaleTimeString():this.selectedEntityObj?this.selectedEntityObj.createdAt:this.recipeObject.createdAt,
            "updatedAt": new Date().toLocaleDateString()+' '+new Date().toLocaleTimeString(),
            "isDeleted": false,
            "updatedBy": this.userService.getUserId(),
            "operation": "",
            "_id":this.isAdd ? "": this.selectedEntityId ? this.selectedEntityId: this.selectedEntityObj?this.selectedEntityObj._id:'',
            "cloudleafId": selectedEntity[0].cloudLeafId,
            "recipeId": !this.isAdd && this.recipeObject?this.recipeObject.recipeId:"",
            "catalogName":selectedEntity[0].name,
            "recipeMode" : this.recipeMode || 'test',
            "active" :this.active===false?false:true 
      }
      
      this.validateRecipeQCode(reqObj);     
    }
  }

  validateRecipeQCode(reqObj) {
    let params = {
      qcode: this.selectedQCode,
    }
     this.postService(params,  reqObj);
  }

  postService(params, reqObj) {
    this.isShowLoading=true;
    this.isSaveBtnDisabled=true;
    this.recipeService.validateRecipeQCode(params).subscribe(
      (res:any) => {
        if (res.status.toLowerCase() === "success") {
          if(res.message=='Invalid syntax'){
            this.toastr.error(res.message);
            this.isShowLoading=false;
          }else{            
            //this.codeMirrorOption.readOnly = 'nocursor';
            //this.codeMirror.codeMirror.clearGutter('breakpoints');
            this.recipeService.saveEntity(reqObj).subscribe((response:any)=>{
              if(response.status.toLowerCase()==='success'){
                if(this.isAdd){
                  this.toastr.success("Recipe addedd successfully."); 
                }else{
                  this.toastr.success("Recipe updated successfully."); 
                }
                                      
                this.getEntityById(reqObj.qcardName);
                this.recipeService.updateRecipeList.next(true);
              }
             },
             error => {
              this.isShowLoading=false;
              this.toastr.error(error.error.errors[0].message?error.error.errors[0].message:"Something went wrong !");
              this.isSaveBtnDisabled=false;
              
            },)
          }

          this.highlightError(res.errors);
        } 
      },
      error => {
        this.isShowLoading=false;
        //this.errors = error;
      },
    )
  }

  highlightError(errors) {
    this.codeMirror.codeMirror.clearGutter('breakpoints');
    for(var i=0;i<errors.length;i++) {
      var error = errors[i];
      var errorLine = error['line'] - 1;

      var marker = document.createElement("div");
      marker.setAttribute('class', 'CodeMirror-lint-marker-error');
      marker.setAttribute('data-toggle', 'tooltip');
      marker.setAttribute('data-placement', 'top');
      marker.setAttribute('title', error['data']);

      this.codeMirror.codeMirror.setGutterMarker(errorLine,"breakpoints", marker);
    }
    $('[data-toggle="tooltip"]').tooltip();
  }

  getEntityById(qcardName?){
    qcardName = qcardName || this.selectedEntityType;
    if(qcardName){
      let entity = qcardName ;
      this.isLoading=true;
    this.recipeService.getEntityByEntityId(this.userService.getTenantId(),entity).subscribe((res:any)=>{
      this.isLoading=false;
      var data=res.data;
      this.entitiesRows=[...data];
      this.entityDetails=data;
      this.selectedEntity= qcardName;
      if(this.recipeObject){
      this.selectedEntityId = this.entityDetails.filter(item=>item.sort_name===this.recipeObject.name)[0]?._id;        
      }
    },
    error => {
      this.isLoading=false;
    })
  }  

}
    
  updateFilter(value) {
    
    const val = value.toLowerCase();

    // filter our data
    const temp = this.entitiesRows.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.entityDetails = temp;
  }

  editRecipe(name, trigger, qCode, selectedEntityObj, fromRecipeList?, isReadOnly?){
    this.showPanel = true;    
    this.selectedName=name;
    if(!isReadOnly){
      this.readOnly = false;
    }
    if(fromRecipeList){   
    this.selectedTrigger = this.triggerList.filter(item=>item.event === trigger)[0]?.label;
    this.timer = selectedEntityObj.properties?.frequency || "";
    }
    else{
    this.selectedTrigger=trigger;
    this.timer = selectedEntityObj.timer?selectedEntityObj.timer:"";
    }
    this.selectedQCode= qCode;   
    this.topic= selectedEntityObj.topic?selectedEntityObj.topic:"";
    this.selectedEntityObj = selectedEntityObj;
    this.recipeMode = selectedEntityObj.recipeMode;
    this.active = selectedEntityObj.active;
    this.isAdd = false;
    if(this.selectedTrigger?.toLowerCase()==="event"){  
      this.recipeService.getTopicList().subscribe(data=>{
        this.topicList = Object.entries(data);
        this.topicList.unshift(["STREAM","STREAM"]);
      })     
   }
    else if(this.selectedTrigger?.toLowerCase()==="violation"){  
      this.topicList=[];   
      this.topicList.push(["VIOLATION","VIOLATION"]);
  }
  }
 
  openDialog(element) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete this recipe?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {        
        let reqObj={
          tenantId:this.userService.getTenantId(),
          user:this.userService.getUserId(),
          controllers:[
             
          ]
      };   
       
          reqObj.controllers.push({
            name: element.name,
            qcardName:element.qcardName
          })
        
        this.isShowLoading = true;
        this.recipeService.deleteRecipe(reqObj).subscribe((res:any)=>{
          if(res.status==='SUCCESS'){
            this.getEntityById();
            this.toastr.success("Recipe deleted successfully");
            this.isShowLoading=false;
          }
        },error=>{
          this.toastr.error("Something went wrong");
          this.isShowLoading=false;
        })
      }
    });
  }

  changeTrigger(val){
    if(val.toLowerCase()==="event"){  
      this.recipeService.getTopicList().subscribe(data=>{
        this.topicList = Object.entries(data);
        this.topicList.unshift(["STREAM","STREAM"]);
        this.topic = "STREAM";
      })     
   }
    else{  
      this.topicList=[];   
    this.topicList.push(["VIOLATION","VIOLATION"]);
    this.topic = "VIOLATION";
  }
  }
  
  updateRecipeMode(ev){
    this.recipeMode = ev.checked?'prod':'test';
  }

  openRecipe(item){
    this.editRecipe(item.name, item.label, item.qCode, item, true);
    this.readOnly = true;
  }
  restrictedCharactersOnly(event) {
    var kcode = event.key;
   if(this.validateName(kcode)){
      return true
    }else{
      return false
    }
  }

  public validateName(name: string) {
    return this.regularExpression.test(String(name));
  }
}
