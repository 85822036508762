import { Component, OnInit } from '@angular/core';
import { ContentDialogComponent } from '../content-dialog/content-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cl-org-info-icon-dialog',
  templateUrl: './org-info-icon.component.html',
  styleUrls: ['./org-info-icon.component.scss']
})
export class OrgInfoIconComponent implements OnInit {

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }
  orgInfoClick(){
    const dialogRef = this.dialog.open(ContentDialogComponent, {
      maxWidth: "500px",
      data:{
        message: '<div class="pb-1">'+
        '<i class="fa fa-info-circle help-icon color4 text-base mr-1 absolute top-1.5 left-0"></i>'+
        'The Organization attribute is used to control which users are able to see this data. Depending on the organization selected only users associated with that organization based on their role/policies will be able to see this data. Please select this value carefully as it could lead to data exposure if incorrectly set'+
        '</div>'+
        '<div class="pb-1">'+
        '  <span class="font-bold">Note</span>: If you set the organization attribute to (Any) then <span class="font-bold">any and all</span> users in the application will be able to see this data regardless of what organization those users are associated with as per their roles/policies'+
        '</div>',
        buttonText: 'Close'
    }}
    );
  }
}

