import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { RulesService } from '@cl/business-rules/rules.service';
import { ToastService } from '@cl/common/services/toast.service';
import { Entity } from '@cl/models';
import _ from 'lodash';

@Component({
  selector: 'cl-ruleset-summary',
  templateUrl: './ruleset-summary.component.html',
  styleUrls: ['./ruleset-summary.component.scss']
})
export class RulesetSummaryComponent implements OnInit, OnChanges {
  @Input() selectedRulesetId: any;
  @Output() closeSidePanel = new EventEmitter();
  loading: boolean;
  rulesetObj: any = {};

  constructor(
    private rulesService: RulesService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.selectedRulesetId) {
      this.loading = true;
      this.rulesService.getRulesetById(this.selectedRulesetId).subscribe({
        next: (res: any) => {
          this.loading = false;
          res.contextArr = [];
          res.rulesArr = [];
          if (!_.isEmpty(res.recipeIdNames)) {
            Object.values(res.recipeIdNames).forEach((rule: any) => {
              res.rulesArr.push(rule.recipeIdNames);
            })
          }
          if (!_.isEmpty(res.context)) {
            Object.keys(res.context).forEach((entity: any) => {
              if (res.context[entity].length) {
                res.contextArr.push({
                  name: entity,
                  values: res.context[entity]
                });
              }
            });
          }
          this.rulesetObj = res;

        },
        error: err => {
          if (err && err['error']) {
            this.toastService.error(err['error']['error-message']);
          }
        }
      });
    }
  }

  closePanel() {
    this.closeSidePanel.emit();
  }

}
