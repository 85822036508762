import { Injectable } from '@angular/core';
import { UserService } from '@cl/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {
  localStoreObj: any;
  localStoreMenuObj:any;
  localStoreColumnObj:any;
  loggedInUser: any;
  constructor(
    private userService: UserService
  ) {
    let storeObj = localStorage.getItem('columnsStore');
    this.localStoreObj = storeObj ? JSON.parse(storeObj) : {};
    let menuStoreObj = localStorage.getItem('menuState');
    this.localStoreMenuObj = menuStoreObj ? JSON.parse(menuStoreObj) : {};
    let columnStoreObj = localStorage.getItem('columnOrderState');
    this.localStoreColumnObj = columnStoreObj ? JSON.parse(columnStoreObj) : {};
   }

   setGridColumns(page: string, columns: string[]) {
    this.loggedInUser = this.userService.user;
    if (this.localStoreObj.hasOwnProperty(this.loggedInUser.id)) {
        this.localStoreObj[this.loggedInUser.id][page] = [...columns];
    } else {
      this.localStoreObj[this.loggedInUser.id] = {};
      this.localStoreObj[this.loggedInUser.id][page] = columns;
    }
    localStorage.setItem('columnsStore', JSON.stringify(this.localStoreObj));
   }

   getGridColumns(page) {
    let storeObj = this.localStoreObj;
    this.loggedInUser = this.userService.user;
    if (storeObj.hasOwnProperty(this.loggedInUser.id) && storeObj[this.loggedInUser.id].hasOwnProperty(page)) {
      return storeObj[this.loggedInUser.id][page];
    } else {
      return {};
    }
   }

   setFilterOption(pageName: string, showFilterPanel: boolean){
    var filterPanelObj ={pageName:pageName, filterPanel:showFilterPanel};
    localStorage.setItem(pageName+'filterPanelObj', JSON.stringify(filterPanelObj));
   }

   getFilterOption(page){
    return localStorage.getItem(page+'filterPanelObj');
   }

   setMenuState(menuList: string) {
    this.loggedInUser = this.userService.user;
    if (this.localStoreMenuObj.hasOwnProperty(this.loggedInUser.id)) {
        this.localStoreMenuObj[this.loggedInUser.id] = menuList;
    } else {
      this.localStoreMenuObj[this.loggedInUser.id] = {};
      this.localStoreMenuObj[this.loggedInUser.id] = menuList;
    }
    localStorage.setItem('menuState', JSON.stringify(this.localStoreMenuObj));
   }

   getMenuState() {
    let storeObj = this.localStoreMenuObj;
    this.loggedInUser = this.userService.user;
    if (storeObj.hasOwnProperty(this.loggedInUser.id)) {
      return storeObj[this.loggedInUser.id];
    } else {
      return {};
    }
   }

   setColumnOrder(columnName: any, prevValue?, newValue?,  page?:string, columnDetails?) {

    const loggedInUser = this.userService.user;
    if (this.localStoreColumnObj.hasOwnProperty(loggedInUser.id)) { 
      if(this.localStoreColumnObj[loggedInUser.id].hasOwnProperty(page)) {
        if(!this.localStoreColumnObj[loggedInUser.id][page].hasOwnProperty(columnName)){
          this.localStoreColumnObj[loggedInUser.id][page][columnName]={};
        }
      } else{
        this.localStoreColumnObj[loggedInUser.id][page]={}
        this.localStoreColumnObj[loggedInUser.id][page][columnName]={};
       }   
      } else {
      this.localStoreColumnObj[loggedInUser.id] = {};
      this.localStoreColumnObj[loggedInUser.id][page] ={};
      this.localStoreColumnObj[loggedInUser.id][page][columnName]={};      
    }
    if(columnDetails.length>0){
      const newColumn = columnDetails.filter((item,index)=>prevValue===index);
      if(newColumn.length>0){
      this.localStoreColumnObj[loggedInUser.id][page][newColumn[0].prop]={};
      this.localStoreColumnObj[loggedInUser.id][page][newColumn[0].prop].prevValue = newValue;
      this.localStoreColumnObj[loggedInUser.id][page][newColumn[0].prop].newValue = prevValue;
      } 
    }
    this.sortExistingOrder(prevValue, newValue, page);
    this.localStoreColumnObj[loggedInUser.id][page][columnName].prevValue = prevValue;
    this.localStoreColumnObj[loggedInUser.id][page][columnName].newValue = newValue;    
    localStorage.setItem('columnOrderState', JSON.stringify(this.localStoreColumnObj));
   
   }
   private sortExistingOrder(prevValue, newValue,  page){
    const loggedInUser = this.userService.user;
    for (var key in this.localStoreColumnObj[loggedInUser.id][page]) {
      if (this.localStoreColumnObj[loggedInUser.id][page].hasOwnProperty(key)) {
        if(this.localStoreColumnObj[loggedInUser.id][page][key].newValue===newValue){
          this.localStoreColumnObj[loggedInUser.id][page][key].newValue = prevValue;
        }
      }
     }
   }

   getColumnOrder(page) {
    let storeObj = this.localStoreColumnObj;
    const loggedInUser = this.userService.user;
    if (storeObj.hasOwnProperty(loggedInUser.id)&& storeObj[loggedInUser.id].hasOwnProperty(page)) {
      return storeObj[loggedInUser.id][page];
    } else {
      return {};
    }    
   }

   setDeviceType(deviceType){
    localStorage.setItem('deviceCatalogType', deviceType);
   }
}
