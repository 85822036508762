import { Injectable } from '@angular/core';
import { environment } from '@cl/env';

@Injectable()
export class ConfigService {
  public apiUrl = environment['apiUrl'];
  public cors_api_url = `${"https://cors-anywhere.herokuapp.com/"}`;
  graphURL: string;
  useMocks = false;
  cloudLoggedIn = false;
  useCors = {
    "cloud-login": true,
    login: true,
  };
  creds: any = {
    login: "",
    secret: "",
  };
  tenantId = "";
  paths = {
    login: "/clfgraphapp/api/1/user/login", // Not in use
    "cloud-login": "/cloudos/api/1/user/login", // Not in use
    cloud: "/cloudos/api/1",
    cloud2: "/cloudos/api/2",
    graph: "/clfgraphapp/api/graph/",
    search: "/clfgraphapp/api/search/", // Not in use
    rootGraph: "/clfgraphapp",
    "graph-ui": "/graph-ui",
    "graph-ui-api": "/graph-ui/api/graph/",
  };

  ajsUrl = window.location.hostname + "/cloudos/";
  constructor() {
    this.apiUrl = environment['apiUrl'];
    // console.log('environment', environment);
  }
  getTenantId() {
    return this.tenantId;
  }
  setTenantId(tenantId) {
    // console.log('set tenant', tenantId);
    this.tenantId = tenantId;
  }
  setCloudLoggedIn() {
    this.cloudLoggedIn = true;
  }
  isCloudLoggedIn() {
    return this.cloudLoggedIn;
  }

  corsPrefix(type) {
    return this.useCors[type] ? `${"https://cors-anywhere.herokuapp.com/"}` : "";
  }

  private _getGraphURL(host, apiType = "graph") {
    let arr = host.split(".");
    arr[0] = arr[0].split("-")[0];
    if (apiType !== "none") {
      arr[0] += "-" + apiType;
    }
    this.graphURL = arr.join(".");
    return this.graphURL;
  }
  private _getLocalHostURL(apiType = "graph") {
    /*let host = "dev31"; //TODO: set in command line
    if (apiType !== "none") {
      host += "-" + apiType;
    }
    host += ".cloudleaf.io";
    return host;*/
    return '';
  }
  private _getHostURL(apiType) {
    const host = window.location.hostname;
    return (host === "localhost" ? this._getLocalHostURL(apiType) : ("https://" + this._getGraphURL(host, apiType) ) );
  }

  /**
   * @deprecated Use `EnvironmentService`
   */
  public getURLVars(type, apiType = "graph") {
    let hostURL = this._getHostURL(apiType);
    const url = hostURL + this.paths[type];
    return {
      url: url,
      token: this.getGraphAPIToken(),
      tenantId: this.tenantId,
    };
  }

  /**
   * @deprecated Use `EnvironmentService`
   */
  public getApiUrl(type, apiType = "graph") {
    let hostURL = this._getHostURL(apiType);
    return "https://" + hostURL + this.paths[type];
  }

  /**
   * @deprecated Use `EnvironmentService`
   */
  public getMngApiUrl() {
    // TODO:: remove this and everything that uses it, deprecated API call
    return this.apiUrl;
  }

  /**
   * @deprecated Use `EnvironmentService`
   */
  public getGraphAPIToken() {
    let eToken;
    const userObj = localStorage.getItem("currentUser");
    if (userObj) {
      eToken = JSON.parse(userObj).eToken || JSON.parse(userObj).encryptedToken;
      return eToken ? window.atob(eToken) : JSON.parse(userObj).token;
    } else {
      return "";
    }
  }
}
