import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@cl/env";
import { EnvironmentService } from "@cl/core";
import {UrlUtilsService} from "@cl/common/services/url-utils.service";

@Injectable({
  providedIn: 'root'
})
export class ApiProviderService {

  domainPrima = '';
  apiConfig = {
    cloudos: {fullUrl: ''},
    clfgraphapp: {fullUrl: ''},
    clfbatch: {fullUrl: ''},
    clfgraphui: {fullUrl: ''},
    behaviorengine:{fullUrl: ''},
  };

  constructor(private http: HttpClient, private envService: EnvironmentService, private urlUtils: UrlUtilsService) { }

  getBaseHost() {
    let hostname = window.location.hostname;
    let subdomain;
    let prima;
    let port;
    var finalhostname = '';
    if(hostname !== 'localhost') {
      if(hostname.indexOf('cloudleaf') > -1) {
        subdomain = hostname.split('.cloudleaf.io')[0];
        prima = subdomain.split('-web')[0];

        finalhostname = prima+'-graph.cloudleaf.io';
      } else if(hostname.indexOf('parkoursc') > -1) {
        subdomain = hostname.split('.parkoursc.com')[0];
        prima = subdomain.split('-web')[0];
        finalhostname = prima+'-graph.parkoursc.com';
      } else {
        const domainSubDomain = this.urlUtils.parseDomainSubDomain();
        subdomain = domainSubDomain.subdomain;
        prima = subdomain.split('-web')[0];
        let maindomian = hostname.split(subdomain);
        finalhostname = prima+'-graph'+maindomian[1];
      }
      
      this.domainPrima = prima;
      port = (window.location.port == '') ? '' : ":" + window.location.port;
      finalhostname = window.location.protocol + "//" + finalhostname + port;
    } else {
      finalhostname = environment.localCluster;
      subdomain = finalhostname.split('.parkoursc.com')[0];
      prima = subdomain.split('-graph')[0];
      finalhostname = 'https://'+finalhostname;
      this.domainPrima = prima;
    }
    return finalhostname;
  }

  getBaseURL() {
    return this.getBaseHost() + "/clfgraphapp/";
  }


  getDomainPrima(){
    return this.domainPrima;
  }
  getBaseURLDomain() {
    let hostname = window.location.hostname;
    let domain = environment.domain;
    let subdomain;
    if(hostname !== 'localhost') {
      if(hostname.indexOf('cloudleaf') > -1) {
        subdomain = hostname.split('.cloudleaf.io')[0];
      } else if(hostname.indexOf('parkoursc') > -1) {
        subdomain = hostname.split('.parkoursc.com')[0];
      } else {
        const domainSubDomain = this.urlUtils.parseDomainSubDomain();
        subdomain = domainSubDomain.subdomain;
      }
      domain = subdomain.split('-')[0];
    }
    return domain;
  }

  updateApiConfig(config: any){
    if(config) {
      config.forEach((apiConf: any) => {
        let URL = apiConf.contextPath?`${apiConf.appUrl}/${apiConf.contextPath}/`:`${apiConf.appUrl}/`;
        if(apiConf.contextPath == "behaviorengine" || apiConf.contextPath == "model"){
          URL = `${apiConf.appUrl}/`;
          apiConf.fullUrl = URL;
          this.apiConfig[apiConf.appName] = apiConf;
        }else{
          URL = apiConf.contextPath?`${apiConf.appUrl}/${apiConf.contextPath}/`:`${apiConf.appUrl}/`;
          apiConf.fullUrl = URL;
          this.apiConfig[apiConf.appName] = apiConf;
        }
       
      })
    }
  }

  updateApiConfigFromUrlUtil() {
    this.apiConfig.cloudos.fullUrl = this.urlUtils.getCloudOSBaseURL();
    this.apiConfig.clfgraphapp.fullUrl = this.urlUtils.getGraphAppBaseURL();
    this.apiConfig.clfbatch.fullUrl = this.urlUtils.getBatchBaseURL();
    this.apiConfig.clfgraphui.fullUrl = this.urlUtils.getGraphUIBaseURL();
  }

  getAPIProviders(useURLUtils: boolean = false) {
    if(useURLUtils) {
      return new Promise( (resolve, reject) => {
        this.urlUtils.getBaseURL();
        this.updateApiConfigFromUrlUtil();
        resolve(true);
      });
    } else {
      const URL = `${this.getBaseURL()}auth0/token`;
      return new Promise( (resolve, reject) => {
        this.http.get(URL)
          .subscribe({
            next: (response: any)=>{
              this.updateApiConfig(response.apiProviderApps);
              this.envService.updateBaseApiProviderUrls(response.apiProviderApps);
              resolve(response.apiProviderApps);
            },
            error: (err)=>{
              console.log(err);
              reject();
            }
          });
      })
    }
  }

  getAPIConfig(appName) {
    if(appName){
      return this.apiConfig[appName];
    }
  }

  getAPIUrl(appName) {
    if(appName){
      return this.apiConfig[appName].fullUrl;
    }
  }
}
