import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-visual-bar-card',
  templateUrl: './visual-bar-card.component.html',
  styleUrls: ['./visual-bar-card.component.scss'],
})
export class VisualBarCardComponent implements OnInit, OnChanges {
  loadingStats = false;
  gotData = false;

  /**
   * For using visual bar card it will expect some of the properties.
   * The properties required as below are added in "navigator-card.service.ts"
   *
   * total:number for calculating the total
   * color:string for bar color. The color order should be [item level] -> [data level]
   * emptyMessage:string If we don't have a data we need to display a message in a card
   * isHavingIcons:boolean If we add icons to the card it renders 4 column layout. Otherwise it renders 3 column layout
   * link:string If we are navigating to a route we can add.
   */
  @Input() data;
  // @Input() tenancyData;

  constructor() {}

  ngOnInit() {}
  ngOnChanges() {}
}
