<div class="parcel-shipment">
  <form #form="ngForm" [formGroup]="shipmentForm" action="">
    <div class="section-border">
      <div class="section-title">General Shipment Details</div>
      <div class="user-detail__item">
        <div class="form-label">Name <span class="mandatory">*</span></div>
        <input
          type="text"
          (keypress)="restrictedCharactersOnly($event)"
          formControlName="name"
          class="basic-input"
          placeholder="Provide a unique name"
          [pattern]="regularExpression"
        />
        <!-- (change)="checkNames()" -->
        <!-- (input)="nameErrorValidation = false" -->
        <div
          *ngIf="submitted && shipmentForm.get('name').errors?.['required']"
          class="text-danger"
        >
          Shipment name is required.
        </div>
        <div
          *ngIf="shipmentForm.get('name').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid shipment name.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">External ID</div>
        <input
          type="text"
          (keypress)="restrictedCharactersOnly($event)"
          formControlName="externalId"
          class="basic-input"
          placeholder="Provide a unique ID"
          [pattern]="regularExpression"
        />
        <!-- (change)="checkNames()" -->
        <!-- (input)="nameErrorValidation = false" -->
        <div
          *ngIf="submitted && shipmentForm.get('externalId').errors?.['required']"
          class="text-danger"
        >
          External ID is required.
        </div>
        <div
          *ngIf="shipmentForm.get('externalId').errors?.['pattern']"
          class="text-danger"
        >
          Please enter a valid External ID.
        </div>
      </div>
      <div class="user-detail__item">
        <div class="form-label">Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog></div>

        <div class="relative">
          <mat-select
            class="basic-input"
            placeholder="Select organization"
            formControlName="organization"
          >
            <ng-container>
              <mat-option *ngFor="let org of orgList" [value]="org.id">{{
                org.name
              }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-icon
            *ngIf="shipmentForm.value.organization && !isOrgRestrictedUser"
            class="select-icon-close"
            (click)="onOrgclose()"
            >close</mat-icon
          >
        </div>
      </div>
    </div>
    <div class="section-border"  *ngIf="dynamicInputFields.length > 0">
      <div class="section-title">Additional Shipment Details</div>
      <cl-dynamic-form-renderer [columnMode]="'Two'" [inputFields]="dynamicInputFields" #dynamicForm></cl-dynamic-form-renderer>
    </div>

    <div class="section-border">
      <div class="section-title pb-0">
        Sub Shipments <span class="mandatory">*</span>
        <div
          class="text-danger font-normal"
          *ngIf="submitted && subShipments.length == 0"
          >At least one shipment should be added</div
        >
      </div>
      <div
        class="subshipment-list"
        cdkDropList
        [cdkDropListData]="subShipments"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          cdkDrag
          class="subshipment-card px-2 mx-2 my-1.5 py-1.5 bgcolor32"
          *ngFor="let shipment of subShipments; let index = index"
        >
          <div class="flex items-center gap-2">
            <div   [ngClass]="{
              readytostart:
                shipment.statusCode == 'Ready to Ship' ||
                shipment.statusCode == 'Planned' ||
                shipment.statusCode == 'Pending' ||
                shipment.statusCode == 'Incomplete',
              drafts: shipment.statusCode == 'Drafts',
              active:
                shipment.statusCode == 'In-Transit' ||
                shipment.statusCode == 'Exception',
              completed:
                shipment.statusCode == 'At Destination' ||
                shipment.statusCode == 'Completed'
            }"class="circle py-1 px-2 rounded-full font-bold">
              {{ getAlphabet(index) }}
            </div>
            <div class="font-bold text-base">{{ shipment.name }}</div>
            <div class="ml-auto">
              <div cdkDragHandle class="cursor-move">
                <i class="fa fa-sort"></i>
              </div>
            </div>
            <div>
              <button (click)="removeSubShipment(shipment.id)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
            <div>
              <button (click)="shipment.isExpanded = !shipment.isExpanded">
                <i
                  class="fa fa-chevron-right float-right mt-1"
                  [ngClass]="{
                    'rotate-Icon': shipment.isExpanded
                  }"
                ></i>
              </button>
            </div>
          </div>
          <div class="grid gridcols2 mt-2 gap-2" *ngIf="shipment.isExpanded">
            <div class="font-bold">Origin:</div>
            <div>{{ shipment.origin }}</div>
            <div class="font-bold">Destination:</div>
            <div>{{ shipment.destination }}</div>
            <ng-container *ngIf="['At Destination', 'Completed'].includes(shipment.statusCode); else etaTemp">
              <div class="font-bold text-sm pr-2">Actual Arrival:</div>
              <div>{{ shipment?.actualArrivalTime| formateDate}}</div>
            </ng-container>
            <ng-template #etaTemp>
              <ng-container *ngIf="shipment.eta">
                <div class="font-bold text-sm pr-2">ETA:</div>
                <div>{{ shipment.eta | formateDate }}</div>
              </ng-container>
            </ng-template>
            <div class="font-bold">Status:</div>
            <div>{{ shipment.statusCode }}</div>
          </div>
        </div>
      </div>
      <div class="user-detail__item" style="width: 100%">
        <div class="flex items-center">
          <button
            (click)="addToShipment()"
            [disabled]="isAddShipmentDisabled"
            [ngClass]="[
              isAddShipmentDisabled
                ? 'color37 bcolor36 bgcolor38'
                : 'color4 bcolor4 bgcolor32'
            ]"
            class="p-4-10 border-general br2 mr-2"
          >
            <i class="fa" [ngClass]="'fa-plus-circle'"></i> Add to Shipment
          </button>
          <p class="mb-0">
            Select one or more shipments from the table to add as a sub shipment
          </p>
        </div>
      </div>
    </div>
  </form>
</div>
