import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { UserService } from '@cl/user/user.service';
import moment from 'moment';
import { firstValueFrom, map, of } from 'rxjs';
import { ShipmentListStateService } from './shipment-list/shipment-list-state.service';

export type Maps = typeof google.maps;

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService,
    private _shipmentListState: ShipmentListStateService,
    private _userService: UserService
  ) {}

  getShipmentFilters() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/2/shipment/filter`
    );
  }
  getTagsList(payload) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/tag/list?includeShipmentInfo=false`,
      payload
    );
  }
  applyAndRemoveManageTags(obj: any, shipmentId) {
    let payload = {"tags":obj.tags,"shipmentIds":shipmentId}
    return this.http.put(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/applyTags`,
      payload
    );
  }

  createNewTag(newtagname) {
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/tag`, {
      tagName: newtagname,
    });
  }
  applyRuleSet(payload) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/bulk/ruleset`,
      payload
    );
  }
  terminateAsset(payLoad, entityTypeKey) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/taggedasset/status/terminate?type=${entityTypeKey || ''}`,
      payLoad
    );
  }
  completeAsset(payLoad, type) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/taggedasset/status/complete?type=${type || ''}`,
      payLoad
    );
  }
  getSensorDetails(sensorId) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/sensor${sensorId}`
    );
  }
  bindAsset(payLoad) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/graph/taggedasset/bind?assetIdType=id`,
      payLoad
    );
  }
  unbindAsset(taggedAssetId: string,shipmentId: string) {
    const headerDict = {
      'Content-Type': 'application/json',
    }
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset/${taggedAssetId}?shipmentId=${shipmentId}`;
    return this.http.delete<any>(url, { headers: new HttpHeaders(headerDict) });
  }
  getQuery(queryText: string, key?: string) {
    let params = {
      globalQueryText: key ? key : '',
      match: 'must',
      scrollId: '',
      scrollSize: 50,
      searchQueries: [
        {
          fieldName: 'clfMappingType',
          queryText: queryText,
          queryOperator: 'should',
          childFilters: [
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
          ],
        },
      ],
    };

    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      params
    );
  }
  getSensors(key?: string) {
    let params = {
      scrollId: '',
      scrollSize: 20,
      globalQueryText: key ? key : '',
      searchQueries: [
        {
          fieldName: 'clfMappingType',
          queryText: 'Tracker',
          queryOperator: 'should',
          queryType: 'match',
          childFilters: [
            {
              fieldName: 'status',
              queryText: 'READY',
              queryOperator: 'should',
            },
          ],
        },
      ],
    };
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      params
    );
  }
  getCategoris() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/categories`
    );
  }
  getRouts(key?: string) {
    let params = {
      globalQueryText: key ? key : '',
      match: 'must',
      scrollId: '',
      scrollSize: 50,
      searchQueries: [
        {
          fieldName: 'parentEntityTypeKey',
          queryText: 'route',
          queryOperator: 'should',
          childFilters: [
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: 'dynamicRoute',
              queryText: 'true',
              queryOperator: 'must_not',
            },
          ],
        },
      ],
    };

    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/search/navigator/query`,
      params
    );
  }

  getRouteSearch(key?: string) {
    let params = {
      globalQueryText: '',
      match: 'must',
      scrollId: '',
      scrollSize: 50,
      searchQueries: [
        {
          fieldName: "baseClass",
          queryText: "Location,Route",
          queryOperator: "should",
          queryType: "match",
         
          childFilters: [
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: 'dynamicRoute',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: 'parentEntityTypeKey',
              queryText: 'route',
              queryOperator: 'should',
            }
          ],
        },
      ],
      searchFieldFilter: [
        {
          name: "name",
          value: key
        }
      ],
    };

    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/location/global/search`,
      params
    );
  }
  
  getAreas() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/areas`
    );
  }
  getCarrierCodes() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/carrierCode`
    );
  }
  getFilters() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/filter`
    );
  }
  getSensorProfiles() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/2/intransit/eventprofiles/list?onlyActiveProfiles=true`
    );
  }
  // getCustomerNames(string) {
  //   return this.http.get(
  //     `${this.apiProvider.getAPIUrl(
  //       'clfgraphapp'
  //     )}api/2/search/term/baseClass/shipment/custName?textSearchTerm=` + string
  //   );
  // }
  // getShipperNames(string) {
  //   return this.http.get(
  //     `${this.apiProvider.getAPIUrl(
  //       'clfgraphapp'
  //     )}api/2/search/term/baseClass/shipment/shipperName?textSearchTerm=` +
  //       string
  //   );
  // }
  getUserTimezoneList() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}resources/data/timezone.json`
    );
  }
  getSensorTages(string: any) {
    const payload = {
      "scrollId": "",
      "scrollSize": 50,
      "globalQueryText": "",
      "searchFieldFilter": [
        {
            "name": "sensorId",
            "value": string
        }
      ],
      "searchQueries": [
          {
              "fieldName": "parentEntityTypeKey",
              "queryText": "tracker",
              "queryOperator": "should",
              "queryType": "match",
              "childFilters": [
                  {
                      "fieldName": "deleted",
                      "queryText": "true",
                      "queryOperator": "must_not"
                  },
                  {
                    "fieldName": "status",
                    "queryText": "READY",
                    "queryOperator": "must"
                  }
              ]
          }
      ],
      "sortField": []
    }
  
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/tracker/global/search`,
      payload
    );
  }
  // getCoustomerCodes(string) {
  //   return this.http.get(
  //     `${this.apiProvider.getAPIUrl(
  //       'clfgraphapp'
  //     )}api/2/search/term/baseClass/shipment/custCode?textSearchTerm=` + string
  //   );
  // }
  getIventorys(payload: any) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/intransit/filter`,
      payload
    );
  }
  getRuleSetByTenant() {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/ruleset/bytenant/${this._userService.getEffectiveTenantId()}`, {}
    );
  }
  getRuleSetProfile(ruleId: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/ruleset/profile/${ruleId}`
    );
  }
  getInventoryAssets(payload) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/asset/inventory`,
      payload
    );
  }
  getAssetTypes() {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/search/navigator/aggregate?aggField=typeId;monitoredStatus&baseClass=asset`,
      {}
    );
  }
  getAllAssetTypes() {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/graph/category/type/asset`
    );
  }  

  globalSearch<T = any>(searchParams, queryParams = {}) {
    const url = `${this.apiProvider.getAPIUrl(
      'clfgraphapp'
    )}api/search/navigator/query`;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .post(url, searchParams, { headers: headers, params: queryParams })
      .pipe(
        map((res) => {
          return res as T;
        })
      );
  }

  // getting shipment logger for characterstic data
  getShipmentLogger(args: any) {
    let queryParams = new HttpParams();
    Object.keys(args).forEach(function (k) {
      queryParams = queryParams.append(k, args[k]);
    });
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/data/shipmentLogger`,
      { params: queryParams }
    );
  }

  // to get addresses for bulk geocodes
  bulkReverseCoding(coordinates: any[]) {
    let postBody = { coordinates: coordinates };
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/geo/bulk-reverse-coding`,
      postBody
    );
  }

  //share email notification
  sendEmail(payload) {
    let requestHeaders = new HttpHeaders().append('application', 'dvp2');
    return this.http.post(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/share/emailNotification`,
      payload,
      {
        headers: requestHeaders,
      }
    );
  }
 
  createShipment(payload: any, type: 'standard' | 'parcel' | 'composite' | 'dtp', isDraft: boolean,entityTypeKey: string) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/${type}/?type=${entityTypeKey || ''}${
        isDraft ? '&isDraft=true' : ''
      }`,
      payload
    );
  }

  updateShipment(
    payload: any,
    shipmentID: string,
    type: 'standard' | 'parcel' | 'composite' | 'dtp',
    isDraft: boolean,
    entityTypeKey: string
  ) {
    return this.http.put(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${type}/${shipmentID}/?type=${entityTypeKey || ''}${isDraft ? '&isDraft=true' : ''}`,
      payload
    );
  }

  refreshShipment(shipmentId:string, shipmentType:string){
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/composite/subShipment/${shipmentId}/refresh/?type=${shipmentType || ''}`,{})
  }
  getEstimateArrival(routeid: string, departureDate: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('routeId', routeid);
    queryParams = queryParams.append('departureDate', departureDate);
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/route/computeETAByRoute`,
      { params: queryParams }
    );
  }
  getAwbData(id: any) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/carrier-tracking/air-cargo/` + id
    );
  }
  getShipmentNames(string: string, fieldType: string, entityType: string) {
    let payload = {
      globalQueryText: '',
      match: 'must',
      scrollId: '',
      scrollSize: 50,
      searchQueries: [
        {
          fieldName: 'baseClass',
          queryText: 'Shipment',
          queryOperator: 'should',
          queryType: 'match',
          childFilters: [
            {
              fieldName: 'deleted',
              queryText: 'true',
              queryOperator: 'must_not',
            },
            {
              fieldName: fieldType==='name'?'name.normalize':fieldType,
              queryText: string,
              queryOperator: 'must',
            },
            {
                fieldName: "entityTypeKey",
                queryText: entityType,
                queryOperator: "must"
            }
          ],
        },
      ],
    };
    return this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/global/search?validation=true`,
      payload
    );
  }
  getShipments(isScrollLoad: boolean) {
    const params = this.getShipmentsPayload(isScrollLoad);

    const httpCall = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/global/search`,
      params
    );

    return this.makeHttpRequest(httpCall);
  }

  getShipmentsPayload(isScrollLoad: boolean){
    const {
      scrollId,
      filters: { childFiltersQuerys: filters, searchFieldFilter },
      filterTags,
    } = this._shipmentListState.settings;

    const filterQuerys: {
      fieldName: string;
      queryText: string;
      queryOperator: string;
      queryType?: string;
    }[] = [
      {
        fieldName: 'deleted',
        queryText: 'true',
        queryOperator: 'must_not',
      },
    ];

    filters.forEach((filter) => {
      if (filter.id === 'dateRange') {
        filter.values.forEach((filterId) => {
          filterQuerys.push({
            fieldName: filterId,
            queryText:
              moment(filter.date.startDate).valueOf() +
              ':' +
              moment(filter.date.endDate).valueOf(),
            queryOperator: 'must',
            queryType: 'range',
          });
        });
      } else {
        filterQuerys.push({
          fieldName: filter.id,
          queryOperator: 'must',
          queryText: filter.values.join(','),
        });
      }
    });

    if (filterTags.tags.length > 0) {
       filterTags.tags.forEach(tag=>{
          filterQuerys.push({
            fieldName: 'tags.id',
            queryType: 'match',
            queryOperator: filterTags.condition,
            queryText: tag,
         });
      })
    }

    const params = {
      globalQueryText: this._shipmentListState.settings.filters.search,
      match: 'must',
      scrollId: isScrollLoad ? scrollId : '',
      scrollSize: 50,
      searchFieldFilter: searchFieldFilter,
      searchQueries: [
        {
          fieldName: 'baseClass',
          queryText: 'shipment',
          queryOperator: 'should',
          queryType: 'match',
          childFilters: filterQuerys,
        },
      ],
    };

    return params;
  }

  getShipmentData(id,shipmentType?) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/${id}/?type=${shipmentType || ''}`
    );
  }
  getShipmentType(id: string): Promise<any> {
    let payLoad = {ids:id}
    const obs$ = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/shipment/type`, payLoad
    );
    return firstValueFrom(obs$);
  }
  getShipmentExcursions(shippmentId: any, taggedAssetId: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/violations/` +
        shippmentId +
        '/' +
        taggedAssetId
    );
  }
  getShipmentActualRoute(obj) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/data/shipmentLogger?capabilities=GPS_SERVICE:GeoPoint&from=` +
        obj.startDate +
        '&samplingLimit=500&shipmentid=' +
        obj.shipmentId +
        '&taggedAssetIds=' +
        obj.taggedAssetId +
        '&to=' +
        obj.endDate
    );
  }

  terminateShipment(shipmentID: string, type:string) {
    const httpCall = this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${shipmentID}/status/terminate/?type=${type || ''}`,
      {}
    );
    return this.makeHttpRequest(httpCall);
  }

  completeShipment(shipmentID: string, type:string) {
    const httpCall = this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${shipmentID}/status/complete/?type=${type || ''}`,
      {}
    );
    return this.makeHttpRequest(httpCall);
  }

  deleteShipment(shipmentID: string, type:string) {
    return  this.http.delete(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/${shipmentID}?type=${type || ''}`,
      {}
    );
  }

  getAssetMileStones({ assetID, from, to }) {
    const httpCall = this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/data/carriercheckpoints/${assetID}?from=${from}&to=${to}`
    );
    return this.makeHttpRequest(httpCall);
  }

  getZoneIdByAreaId(areaId: string) {
    const httpCall = this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/area/${areaId}/zoneid`
    );
    return this.makeHttpRequest(httpCall);
  }

  private makeHttpRequest(httpCall): Promise<[response: any, error: any]> {
    return new Promise((resolve, reject) => {
      return httpCall.subscribe(
        (res) => resolve([res, null]),
        (err) => resolve([null, err])
      );
    });
  }
  getFlightDeparture(payload, startDate: string, endDate: string) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/carrier/FLIGHT/carrierInfo/${startDate}/${endDate}`,
      payload
    );
  }
  getFlighAreaDetails(areaID: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/customer/area/${areaID}`
    );
  }

  getViolations(shipmentId: string, taggedAssetId: string): Promise<any> {
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/intransit/violations/${shipmentId}/${taggedAssetId}`
    );
    return firstValueFrom(obs$);
  }

  getLocationDetails(locationId:string){
    const obs$ = this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/location/${locationId}`);
    return firstValueFrom(obs$)
  }

  getCurrentLocationOfAsset(latlang) {
    const headers: any = {
      'Content-Type': 'application/json',
      withCredentials: true,
      secure: false,
    };
    let url =
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      latlang +
      '&key=AIzaSyBFrngKBmi7UwDVsNcRmOPnOVpaUbJYbn0';
    return this.http.get<any>(url, { headers: headers });
  }
  moveToIntransit(value, type){
    const httpCall = this.http.post(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/taggedasset/status/intransit?type=${type || ''}`,
      value
    );
    return httpCall
  }
  deleteAsset(payLoad, type){
    const headers: any = {
      'Content-Type': 'application/json',
    };
    return this.http.delete<any>(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/asset/bulk?type=${type || ''}`, { headers: headers , body:payLoad} 
    );
  } 

  createShipmentReport(shipmentId) {
    return this.http.post(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/2/report/create/${shipmentId}`,{}
    );
  }

  getReportStatus(shipmentId){
   return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/2/report/status/${shipmentId}`,{observe:'response'}
    );
  }

  downloadReport(shipmentId){
    return this.http.get(
       `${this.apiProvider.getAPIUrl(
         'cloudos'
       )}api/2/report/download/${shipmentId}`,{ observe: 'response', responseType: 'blob'}      
     );
   }

 
   getCompositeShipmentDetails(shipmentID: string, shipmentType: string) {
    return this.http.get(
      `${this.apiProvider.getAPIUrl(
        'clfgraphapp'
      )}api/2/shipment/composite/${shipmentID}?type=${shipmentType || ''}`
    );
  }

}
