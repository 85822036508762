export enum WayPointStatus {
  Completed = 'complete',
  Current = 'current',
  Pending = 'pending',
}

export interface ShipmentTrackPoint {
  title: string;
  message: string;
  status: WayPointStatus;
  tooltip?: { heading: string; message: string }[];
  localeTime?: string;
  checkpointTime?: number;
  isHTMLMessage?: boolean;
}

export enum MILESTONE_ZONES {
  LOCALE = 'local',
  ORIGIN = 'origin',
  DESTINATION = 'destination',
  USER = 'user',
}

export interface CompositeShipment {
  entityType: string;
  modifiedAt: number;
  externalId: string;
  baseClass: string;
  parentEntityTypeKey: string;
  type: string;
  originAreaName: string;
  destinationAreaName: string;
  subShipments: SubShipment[];
  duration?: any;
  createdAt: number;
  deleted: string;
  tenantName: string;
  entityTypeKey: string;
  createdBy: string;
  organization: string;
  tenantId: string;
  name: string;
  entityTypeId: string;
  modifiedBy: string;
  id: string;
}

interface SubShipment {
  entityTypeKey: string;
  name: string;
  externalId: string;
  statusWithAssetCount: string;
  id: string;
  originAreaName: string;
  destinationAreaName: string;
  status: string;
  eta?: number;
  actualArrivalTime?: number;
}
