export const API_LABEL_MAP = {
  common: {},
  asset: {
    typeId: "Asset Type",
    "lifecycleStatus.keyword": "Lifecycle Status",
    state: "Custody Status",
    entityType: "Asset Type"
  },
  location: {
    clfMappingType: "Type"
  },
  gateway: {
    type: "Gateway Type",
    "location.keyword": "Location"
  },
  sensor: {
    g: "Online",
    y: "Attention",
    r: "Offline",
    active: "Bound",
    ready: "Unbound",
    overallStatus: "Status",
    type: "Device Type",
    batteryLevel: "Battery Level"
  },
};
