<button mat-button [matMenuTriggerFor]="cocMenu" [disabled]="disableCOC" [ngClass]="{'disable-menu-btn': disableCOC}"
    *ngIf="renderIn == 'list'" class="color4 bg-none pl-2 pr-2">{{'common.chainOfcustody'|translate}}
    <i class="color4 font-bold fa fa-chevron-down -mt-1"></i>
</button>
<button mat-button [matMenuTriggerFor]="cocMenu"  [disabled]="disableCOC" [ngClass]="{'disable-menu-btn': disableCOC}"
    *ngIf="renderIn == 'detail'" class="color4 bg-none pl-2 pr-2 h-8">Chain of Custody
    <i class="color4 font-bold fa fa-chevron-down -mt-1"></i>
</button>
<mat-menu #cocMenu="matMenu">
    <div *ngFor="let action of stateActionMap">
        <button mat-menu-item class="color4" *ngIf="action.enabled" (click)="cocAction(action.name)">
            {{ action.label }}
        </button>
    </div>
</mat-menu>