import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

export interface Duration{
  duration: string;
}
/*
  show expects = ['Y', 'M', 'D', 'h', 'm', 's']
*/
@Component({
  selector: 'cl-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss']
})
export class DurationComponent implements OnChanges {
  @Input('is') is : string;
  @Input('name') durationName: string = (new Date().getTime()).toString();
  @Input('show') show : string[];
  @Input('placeholder') placeholder: string;
  @Input('disabled') disabled: boolean;
  @Input('required') required: boolean;
  @Output('selection-change') changeTriggered : EventEmitter<Duration> = new EventEmitter<Duration>();

  _durationObject: any = { prefix1: 'P', prefix2: null, prefix3: 'Y', prefix4: null, prefix5: 'M', prefix6: null, prefix7: 'D', prefix8 : 'T', prefix9: null, prefix10: 'H', prefix11: null, prefix12: 'M', prefix13: null, prefix14: 'S' };
  _displayElements: any = { 'Y': false, 'M': false, 'D': false, 'h': false, 'm': false, 's': false };
  open : boolean = false;
  selectedDuration : string = null;
  displayElements : any = _.clone(this._displayElements);
  duration: any = _.clone(this._durationObject);
  constructor() { }
  _fnGetDurationData(letter, data, field) {
    if (!_.isEmpty(data)) {
      this.duration[field] = parseInt(data.split(letter)[0], 10);
    } else {
      this.duration[field] = null;
    }
  };
  _fnGetDuration(){
    if(!_.isEmpty(this.is)){
      let s1 = this.is;
      let expression = /^P(\d*Y)?(\d*M)?(\d*D)?(T(\d*H)?(\d*M)?(\d*S)?)?$/g;
      let groups = expression.exec(s1);
      if(!_.isEmpty(groups)){
        this._fnGetDurationData(this._durationObject.prefix3, groups[1], 'prefix2');
        this._fnGetDurationData(this._durationObject.prefix5, groups[2], 'prefix4');
        this._fnGetDurationData(this._durationObject.prefix7, groups[3], 'prefix6');
        this._fnGetDurationData(this._durationObject.prefix10, groups[5], 'prefix9');
        this._fnGetDurationData(this._durationObject.prefix12, groups[6], 'prefix11');
        this._fnGetDurationData(this._durationObject.prefix14, groups[7], 'prefix13');
      }else{
        this.duration = _.clone(this._durationObject);
      }
    }else{
      this.duration = _.clone(this._durationObject);
    }
  };
  fnSetDuration(){
    let items = [];
    items.push(this.duration.prefix1);
    if(_.isNumber(this.duration.prefix2)){
      items.push(this.duration.prefix2);
      items.push(this.duration.prefix3);
    }
    if(_.isNumber(this.duration.prefix4)){
      items.push(this.duration.prefix4);
      items.push(this.duration.prefix5);
    }
    if(_.isNumber(this.duration.prefix6)){
      items.push(this.duration.prefix6);
      items.push(this.duration.prefix7);
    }
    if(_.isNumber(this.duration.prefix9)
      ||  _.isNumber(this.duration.prefix11)
      ||  _.isNumber(this.duration.prefix13)){
        items.push(this.duration.prefix8);
    }
    if(_.isNumber(this.duration.prefix9)){
      items.push(this.duration.prefix9);
      items.push(this.duration.prefix10);
    }
    if(_.isNumber(this.duration.prefix11)){
      items.push(this.duration.prefix11);
      items.push(this.duration.prefix12);
    }
    if(_.isNumber(this.duration.prefix13)){
      items.push(this.duration.prefix13);
      items.push(this.duration.prefix14);
    }
    if(items.length>1){
      this.is = items.join('');
    }else{
      this.is = null;
    }
    this._fnGetDisplayedFieldsDuration();
    this.open = false;
    this.changeTriggered.emit({duration: this.is});
  };
  _fnSetDisplayDataString(field, value, displayFields) {
    if (_.includes(this.show, field)) {
      if (_.isNumber(value)) {
        if (value < 10) {
          displayFields.push('0' + value);
        } else {
          displayFields.push(value);
        }
      } else {
        displayFields.push('00');
      }
    }
  };
  _fnGetDisplayedFieldsDuration(){
    this.selectedDuration = '';
    if(!_.isEmpty(this.is)){
      let temp = [];
      this._fnSetDisplayDataString('Y', this.duration.prefix2, temp);
      this._fnSetDisplayDataString('M', this.duration.prefix4, temp);
      this._fnSetDisplayDataString('D', this.duration.prefix6, temp);
      this._fnSetDisplayDataString('h', this.duration.prefix9, temp);
      this._fnSetDisplayDataString('m', this.duration.prefix11, temp);
      this._fnSetDisplayDataString('s', this.duration.prefix13, temp);
      this.selectedDuration = temp.join(':');
    }
  };
  _fnToggleDurationElements(){
    if(_.isEmpty(this.show)){
      this.show = ['h', 'm', 's'];
    }
    let keys = Object.keys(this.displayElements);
    this.show.forEach((item)=>{
      if(_.includes(keys, item)){
        this.displayElements[item] = true
      }
    });
  };
  ngOnChanges(changes: SimpleChanges): void {
    this._fnToggleDurationElements();
    this._fnGetDuration();
    this._fnGetDisplayedFieldsDuration();
  }
}
