import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Asset, LocationHistory } from '@cl/@types/asset.type';
import { AssetService } from '@cl/asset/asset.service';
import { UtilsService } from '@cl/common/utils/utils.service';

@Component({
  selector: 'cl-asset-location',
  templateUrl: './asset-location.component.html',
  styleUrls: ['./asset-location.component.scss']
})
export class AssetLocationComponent implements OnInit, OnChanges {
  @Input('asset') assetObject: Asset;
  @Input('dateRange') dateRange?: any;
  constructor(private assetService: AssetService, private utils: UtilsService) { }
  isLoading: boolean = false;
  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.assetObject && this.assetObject.taggedAssetId) {
      let assetId = this.assetObject.taggedAssetId;
      let dateParam = {};
      if (this.dateRange) {
        dateParam = {
          from: this.dateRange.startDate.valueOf(),
          to: this.dateRange.endDate.valueOf()
        }
      }
      this.isLoading = true;
      this.assetService.getLocationVisits(assetId, dateParam).subscribe({
        next: (res: LocationHistory[]) => {
          if (res && res.length) {
            this.isLoading = false;
            const data = res;
            let visitArr = [];

           data.forEach( (visit: any) => {
             if (visit.exit == null) {
               visit.dwellTime = (new Date()).getTime() - visit.entry;
             }
             visit.dwellTime = this.utils.dwellTimeFormatter(visit.dwellTime, true, true, true, 4);
             visitArr.push(visit);
             if(visit.subRegions) {
               visit.subRegions.forEach((subR: any) => {
                 if (subR.exit == null) {
                   subR.dwellTime = (new Date()).getTime() - subR.entry;
                 }
                 subR.dwellTime = this.utils.dwellTimeFormatter(subR.dwellTime, true, true, true, 4);
                 subR.isSubRegion = true;
                 visitArr.push(subR);
               })
             } else {
               visit.isSubRegion = false;
             }
           })
            this.assetObject.visits = visitArr;
          } else {
            this.isLoading = false;
            this.assetObject.visits = [];
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.error(error);
        }
      })
    }
  }

}
