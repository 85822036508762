import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CL_INPUT_DEBOUNCE_TIME } from "@cl/constants";
import { NavigatorFilter } from "@cl/models";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import _ from "lodash";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { debounceTime, filter, startWith, switchMap, tap } from "rxjs/operators";
import { NavigatorFiltersService } from "../../service/navigator-filters.service";

export class FilterApiParams {
  loading = false;
  scrollId = "";
  hasMore = true;
}

@UntilDestroy()
@Component({
  selector: "app-filter-box",
  templateUrl: "./filter-box.component.html",
  styleUrls: ["./filter-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterBoxComponent),
      multi: true,
    },
  ],
})
export class FilterBoxComponent<T = any> implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() filter: NavigatorFilter;
  @Output() activeFiltersCountChange = new EventEmitter<number>();

  searchForm: FormGroup;
  private get newSearchForm() {
    return this.fb.group({
      searchTerm: [""],
      filters: this.fb.group({}),
    });
  }

  private get fcSearchTerm() {
    return this.searchForm.get("searchTerm");
  }

  private get fcFilters() {
    return this.searchForm.get("filters") as FormGroup;
  }

  filterList: T[] = [];
  activeFilters: string[] = [];
  inActiveFilters: string[] = [];
  apiParams = new FilterApiParams();

  private updatingFormControlInProgress = false;

  private readonly listScrolled$ = new Subject<boolean>();
  private readonly firstLoad$ = new BehaviorSubject<boolean>(false);

  clearDisabled = false;

  disabled = false;
  onChange: any = (obj: any) => {};
  onTouch: any = () => {};

  constructor(private fb: FormBuilder, private navigatorFilters: NavigatorFiltersService) {
    this.searchForm = this.newSearchForm;
  }

  ngOnInit(): void {
    this.hookFiltersApi();
    this.hookFilterChange();
  }

  ngOnDestroy(): void {
    this.listScrolled$.complete();
    this.firstLoad$.complete();
  }

  trackByIdFn = (_: number, item: any) => item?.id;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: string[]): void {
    // const filtersMap = obj.reduce((acc, curr) => {
    //   acc[curr] = true;
    //   return acc;
    // }, {});
    // this.fcFilters.patchValue({ ...this.fcFilters.value, ...filtersMap });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Clear filter selections
   */
  clearAll(): void {
    // Get current filters
    const filters = this.fcFilters.value;

    // Reset all filters values to false
    Object.keys(filters).forEach((key) => (filters[key] = false));

    // Patch the value back to form group
    this.fcFilters.patchValue(filters);
  }

  /**
   * Event handler for mat menu opened
   */
  onMenuOpened(event: any): void {
    // Its its opened for first time then trigger filters to load
    // Otherwise leave it
    if (!this.firstLoad$.getValue()) {
      this.firstLoad$.next(true);
      this.firstLoad$.complete();
    }
  }

  listScrolled(numShown) {
    // No need to load
    if (this.apiParams.loading || !this.apiParams.hasMore) {
      return;
    }

    if (numShown > this.filterList.length - 30) {
      this.listScrolled$.next(true);
    }
  }

  loadMore(message) {
    if (this.apiParams.loading || !this.apiParams.hasMore) {
      return;
    }
    this.listScrolled$.next(true);
  }

  private hookFilterChange() {
    this.fcFilters.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),
        filter((_) => !this.updatingFormControlInProgress)
      )
      .subscribe((allFilters) => {
        this.activeFilters = [];
        this.inActiveFilters = [];
        Object.keys(allFilters).forEach((filter) => {
          if (allFilters[filter]) this.activeFilters.push(filter);
          else this.inActiveFilters.push(filter);
        });

        // Update active filters
        this.onChange(this.activeFilters);
        this.activeFiltersCountChange.emit(this.activeFilters.length);
      });
  }

  /**
   *
   */
  private hookFiltersApi() {
    const listScrolled$ = this.listScrolled$.pipe(startWith(true));
    const firstLoad$ = this.firstLoad$.pipe(filter((_) => _));

    const search$ = this.fcSearchTerm.valueChanges.pipe(
      startWith(""),
      debounceTime(CL_INPUT_DEBOUNCE_TIME),
      untilDestroyed(this),
      tap((_) => this.reset())
    );

    // On any of these events
    combineLatest([firstLoad$, search$, listScrolled$])
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),
        tap((_) => (this.apiParams.loading = true)),
        switchMap((_) => this.navigatorFilters.filterApi<T>(this.filter, this.apiParams.scrollId, this.fcSearchTerm.value)),
        tap((_) => (this.apiParams.loading = false))
      )
      .subscribe(
        (res) => {
          this.filterList = this.filterList.concat(res.hits);
          this.apiParams.scrollId = res._scroll_id;
          this.apiParams.hasMore = this.filterList.length < res.totalHits;
          this.prepareFilterFormFields(this.filterList);
        },
        (err) => {
          this.apiParams.loading = false;
        }
      );
  }

  private prepareFilterFormFields(filterList: T[]) {
    this.updatingFormControlInProgress = true;

    // Get existing fields and their values
    const existingFilters = this.fcFilters.value;

    // Create new set of filters
    const newFilters = filterList.reduce((acc, curr: any) => {
      acc[curr.id] = existingFilters[curr.id] ?? false;
      return acc;
    }, {});

    // Clear existing filter form and update with the current
    Object.keys(existingFilters).forEach((filter) => this.fcFilters.removeControl(filter));

    // For each new filter create form fields
    _.forOwn(newFilters, (value, key) => {
      this.fcFilters.addControl(key, new FormControl(value));
    });

    this.updatingFormControlInProgress = false;
  }

  private reset() {
    this.apiParams = new FilterApiParams();
    this.filterList = [];
  }
}
