<div class="mt-2 mb-2">
    <span class="page-title">Gateways</span>

</div>
<cl-table-surtitle [rows]="showingHits" [selected]="selected.length" [total]="totalHits" [pageName]="'Gateways'"></cl-table-surtitle>
<div class="sensor-febric wt-ngx-grid-wrapper list-page-wrapper relative list-page px-2">
    <div class="flex justify-between pt-1 pb-1">
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer color4 list-page_filter-toggle flex items-center">
              <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
              filter options
            </span>
            <cl-list-search [placeholder]="'Search by Name or ID'" (search)="updateFilter($event)"
                [columns] = "['Device ID']"></cl-list-search>
            <button [disabled]="bindDisabled"
                [clIsValidAction]="bindAction === 'Bind' ? actionBtnPolicies.bind : actionBtnPolicies.unbind"
                [ngClass]="[bindDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2" (click)="bindOrUnbind()">
                <i class="fa " [ngClass]='bindIcon'></i>
                {{bindAction}}
            </button>
            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 ml10" (click)="openAddGatewayPanel(true)">
                <i class="fa fa-pencil"></i>
                Edit
            </button>
            <button [disabled]="deleteDisabled"
                [clIsValidAction]="actionBtnPolicies.delete"
                [ngClass]="[deleteDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general ml-2 br2" (click)="deleteGateway()">
                <i class="fa fa-trash"></i>
                Delete
            </button>
        </div>
        <div class="sensor-febric__action-panel-right action-panel-right self-end flex items-center setting-margin">
            <!--temp hide-->
            <!-- <span class="dark-blue-text action-separator colimn-serach-btn" (click)="showHideFilter()"><i
                class="fa fa-search-plus color4"></i></span> -->
            <div><button (click)="showHideFilter()" class="toggler-example align-grid-menu p-2 border-0 py-2 colsearch-menu">
                    <span class="dark-blue-text" title="Search data by table columns">
                      <i _ngcontent-bum-c250="" class="fa fa-search-plus color4"></i>
                    </span>
                  </button>
            </div>
            <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'gateway'">
            </cl-grid-column-toggle>
           
        </div>
        <div
        class="md-add-button list add-shipment-btn" style="z-index: 999" [clIsValidAction]="actionBtnPolicies.delete">
        <div class="add-button"
          (click)="openAddGatewayPanel(false)"
          *ngIf="
              !sidePanelsObj.create &&
              !sidePanelsObj.bind &&
              !sidePanelsObj.bulk &&
              !sidePanelsObj.summary &&
              !sidePanelsObj.edit &&
              !showSidePanel"
        >
          <span class="fa fa-plus"></span>
        </div>
        <div class="add-button" *ngIf="sidePanelsObj.create || sidePanelsObj.edit" (click)="closeGatewaySidePanel()">
          <span class="fa fa-close"></span>
        </div>
      </div>
    </div>
    <div class="h-calc-40">
        <main class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth - 10">
            <div class="grow-0" *ngIf="showFilterPanel">
                <cl-list-filter class="nav panel scroll-y grow-0" [hideTitle]="true" [showCounts]="true" [title]="''"
                [fullHeight]="true" (panelsEmitter)="getPanelUpdate($event)" (toggleFilterPanel)="toggleFilterPanel()" heightClass="none" [filters]="filters">
                </cl-list-filter>
            </div>
            <div class="grid-container grow relative">
              <div *ngIf="delayedRefreshStart">
                <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                                      (refreshList)="getGatewayList()"
                ></cl-refresh-countdown>
              </div>
                <div class="h-100"  [style.width.px]="tableWidth">
                    <ngx-datatable class="h-calc-40" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarH]="true" 
                        (reorder) = "columnReordered($event)"
                        [scrollbarV]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [rowIdentity]="getId" [selectAllRowsOnPage]="false" (scroll)="onScroll($event.offsetY)" (select)='onSelect($event)'[reorderable]="false" #table>
                    </ngx-datatable>
                    <ng-template #clearTemplate>
                        <span class="fa fa-times-circle-o mr-4 clear-filter" *ngIf="showFilter" (click)="closeFilter()"></span>
                    </ng-template>
                    <ng-template #hdrTpl let-column="column" let-sort="sortFn">
                        <span 
                          class="header-sort-span" 
                          (click)="sort()">
                          {{column.name}}
                        </span>
                        <span class="header-filter-span input-filter-parent">
                          <input *ngIf="showFilter"
                            [(ngModel)]="columnFilters[column.prop]" 
                            (input)="filterInput($event)"
                            class="input-filter"
                            type="{{ column.prop=='phone_call_date' ? 'date' : 'text' }}" />
                        </span>
                    
                      </ng-template>

                    <div class="flex flex-row items-center justify-center">
                        <button class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
                          [ngClass]="{'bcolor9': isLoading || allLoaded}" (click)="getLoadMoreUpdate()"
                          [disabled]="isLoading || allLoaded">
                          <span class="mat-button__ripple"></span>
                          <span class="mat-button__label color4" [ngClass]="{'color9': isLoading || allLoaded}">load next
                            50</span>
                        </button>
                    </div>
                </div>
                <div class="h-100" *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
            </div>
        </main>
    </div>
    <ng-template #gatewayStatusTemplate let-row="row" let-value="value" let-i="index">
        {{ getGatewayStatusLabels(value) }}
    </ng-template>
    <ng-template #trafficTemplate let-row="row" let-i="index">
        {{ getTrafficLabel(row) }}
    </ng-template>
    <ng-template #lastUpdatedTemplate let-row="row" let-value="value" let-i="index">
        {{ value | timesince }}
    </ng-template>
    <ng-template #gatewayStatusTemplate let-row="row" let-value="value" let-i="index">
        {{ getGatewayStatusLabels(value) }}
    </ng-template>
    <ng-template #locationNameTemplate let-row="row" let-value="value" let-i="index">
        <a class="font-12-bold" href="#/locationdetails/{{row.locationId}}">{{value}}</a>
    </ng-template>
    <ng-template #dateTemplate let-row="row" let-value="value" >
      {{value | formateDate:'default'}}
    </ng-template>
    <ng-template #gatewayNameTemplate let-row="row" let-value="value" let-i="index">
        <span class="font-11-normal" *ngIf="row.status === 'UNPROVISIONED'">{{row.name}}</span>
        <a class="font-12-bold" href (click)="loadSummaryPanel(row, $event)" *ngIf="row.status === 'PROVISIONED'">{{row.name}}</a>
    </ng-template>
    <ng-template #catalogTypeTemplate let-row="row" let-value="value" >
        {{value | titlecase}}
      </ng-template>

    <div *ngIf="showBindPanel">
        <cl-gateway-binder [gatewayObject]="selectedGateway" (closeSidePanel)="closeSidePanel($event)"></cl-gateway-binder>
    </div>
    <div *ngIf="showSidePanel == true">
        <cl-device-summary *ngIf="mode == 'view'" (closeSidePanel)="closeSidePanel()" [sensorId]="roleObject?.id" [sensorType]="roleObject?.entityTypeKey" [device]="roleObject"></cl-device-summary>
    </div>
    <div class="summery-panel" *ngIf="sidePanelsObj.create || sidePanelsObj.edit">
        <cl-gateway-add-panel [isEdit]="sidePanelsObj.edit" [entityTypeKey]="selected[0]?.entityTypeKey" [gatewayId]="selected.length > 0 && selected[0].id" (closeAddGatewaySidePanel)="closeAddGatewayPanel($event)"></cl-gateway-add-panel>
      </div>
</div>
