import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Asset } from '@cl/@types/asset.type';
import { CharacteristicGeoAreaValue } from '@cl/@types/characteristic-value.type';
import { AssetService } from '@cl/asset/asset.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { MeasureType } from '../../../@types/measure.type';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { PropertyService } from '@cl/property/property.service';
import { ShipmentUtilsService } from '@cl/common/utils/shipment-utils.service';

@Component({
  selector: 'cl-asset-activity',
  templateUrl: './asset-activity.component.html',
  styleUrls: ['./asset-activity.component.scss']
})
export class AssetActivityComponent implements OnChanges {
  activeTabIndex: number = 0;
  originalTabs: any[] = [
    { id : 1, name: 'Environmental Conditions'},
    { id : 2, name: 'Asset History'}/* ,
    { id : 3, name: 'Sub Assets'} */
  ];
  tabs: any[];
  activeTab: any;
  @Input('asset') assetObject: Asset;
  private currentMeasure: MeasureType;
  // selectedDateRange: {startDate: moment.Moment, endDate: moment.Moment};
  ranges: any;
  data: ApexAxisChartSeries;
  showDatePicker: boolean = true;
  tooltipCustomizer : Function;
  @Input() selectedDateRange: any;
  @Input('excursions') excursions:any;
  @Input('ruleSetId') ruleSetId:any;
  public thresholdData:any;
  public minThreshold:any;
  public maxThreshold:any;
  public annotations: any;

  datePickerClass: any = 'details-date-picker';
  dateFormat: any;
  constructor(private assetService : AssetService,  private shipmentService: ShipmentService, private formateDatePipe: FormateDatePipe, private propertyService:PropertyService, private cd: ChangeDetectorRef, private _shipmentUtils: ShipmentUtilsService) {
    this.data = [];
    this.tabs = _.cloneDeep(this.originalTabs);
    this.ranges = {};
    if(!this.selectedDateRange){
    this.selectedDateRange = { startDate: moment().subtract(4, 'hours'), endDate: moment()};
    }
    this.dateFormat = this.propertyService.getSetting(
      'ui.dateFormat',
      false,
      'MM/DD/YYYY hh:mm A'
    );
    this.dateFormat = this.dateFormat.replace('dd', 'DD').replace(' a', ' A');
  }

  measureChanged(measure: MeasureType){
    if(!_.isEmpty(measure)){
      this.currentMeasure = measure;
      this.ranges = measure.config.dateRanges;
      this.getMeasureData();
    }
  }
  datesUpdated(datesUpdated){
    this.selectedDateRange = datesUpdated;
    this.getMeasureData();
  }
  private getMeasureData(){
    this.data = [];
    if(this.assetObject?.taggedAssetId && !_.isEmpty(this.currentMeasure)){
      this.assetService.getTaggedAssetCharacteristicValuesByRange(this.assetObject.taggedAssetId,
        this.currentMeasure.measureServiceId,
        this.currentMeasure.measureCharacteristicId,
        moment(this.selectedDateRange.startDate).valueOf(),
        moment(this.selectedDateRange.endDate).valueOf(),
        500)
        .then((response : any) => {
          if(response?.data && !_.isEmpty(response.data)){
            this.updateAndSetData(response);
          }
        });
    }
  }
  private updateAndSetData(response: any) {
    let tempMeasure: MeasureType = _.cloneDeep(this.currentMeasure);
    let tempLoggerData = response.data;
    let loggerData:any = [];
    this.minThreshold = [];
    this.maxThreshold = [];
    let currentMeasureViolations = _.filter(this.excursions?.ViolationMap[`${tempMeasure.measureServiceId}:${tempMeasure.measureCharacteristicId}`], ((violatedMeasure: any) => {
      return (violatedMeasure.violated && violatedMeasure.alertStatus == 'Active');
    }));

    let fakeValue = null;
    if(tempMeasure.config.addSpikes){
      if (tempMeasure.config.isBinary) {
        fakeValue = 0;
      }else{
        fakeValue = tempMeasure.config.YMin;
      }
    }
    tempLoggerData = tempLoggerData.reverse();
    tempLoggerData.forEach((dataItem, i)=> {
      if(tempMeasure.config.addSpikes){
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: (((dataItem.time / 1000) - 5) * 1000),
          value: fakeValue
        });
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: dataItem.time,
          value: dataItem.value
        });
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: (((dataItem.time / 1000) + 5) * 1000),
          value: fakeValue
        });
      }else{
        loggerData.push({
          receiverId : dataItem.receiverId,
          time: dataItem.time,
          value: dataItem.value
        });
      }
    });
    let annotations: any = {};
    let pointAnnots = [];
    let axisAnnots = [];
    let lastDatumTime = 0;
    let lastDatumTimeObj = {
      diff: 0,
      diffCount: 0,
      avg: 0
    };

    let temp = loggerData.map((item: any, index) => {
      let violations = _.find(currentMeasureViolations, (violation) => {
        return (Number(violation.propertyValue) === Number(item.value) && violation.deviceTime === item.time);
      });
      let val = this.getValue(item.value, tempMeasure);
      let icon = "assets/svgs/gateway-adder.svg"
      if(violations) {
        icon = this.currentMeasure.violatedIcon
        pointAnnots.push({
          x: item.time,
          y: val,
          marker: {
            size: 0,
          },
          image: {
            path: this.currentMeasure.violatedIcon,
            offsetY: -18
          }
        })
      }
      if(loggerData[index +1]) {
        lastDatumTimeObj.diff = (loggerData[index +1].time - item.time);
        lastDatumTimeObj.diffCount++;
      }
      return [item.time, this.getValue(item.value, tempMeasure), icon, item.receiverId];
    });

    lastDatumTimeObj.avg = lastDatumTimeObj.diff ? lastDatumTimeObj.diff / lastDatumTimeObj.diffCount : loggerData[loggerData.length - 1].time + 60000;
    lastDatumTime = loggerData[loggerData.length - 1].time + ( (lastDatumTimeObj.avg > 60000) ? 60000 : lastDatumTimeObj.avg );

    if(this.currentMeasure.minThreshold) {
      this.minThreshold.push([lastDatumTime, this.getValue(tempMeasure.minThreshold, tempMeasure)]);
      axisAnnots.push({
        y: this.currentMeasure.minThreshold,
        borderColor: '#ff0b39',
        strokeDashArray: 0
      });
    }
    if(this.currentMeasure.maxThreshold && loggerData) {
      this.maxThreshold.push([lastDatumTime, this.getValue(tempMeasure.maxThreshold, tempMeasure)]);
      axisAnnots.push({
        y: this.currentMeasure.maxThreshold,
        borderColor: '#ff0b39',
        strokeDashArray: 0
      })
    }

    if(axisAnnots.length) {
      annotations.yaxis = axisAnnots;
    }
    if(pointAnnots.length) {
      annotations.points = pointAnnots;
    }
    if(axisAnnots.length || pointAnnots.length) {
      annotations.position = 'back';
    }
    this.annotations = annotations;

    if(_.isEmpty(temp)){
      this.data = [];
    }else{
      this.data = [ { name: this.currentMeasure.measureLabel, data: temp }, { name: this.currentMeasure.measureLabel, data: this.minThreshold, color: '#FF0000' }, { name: this.currentMeasure.measureLabel, data: this.maxThreshold, color: '#FF0000' } ];
      this.customToolTip();
    }
  }

  private customToolTip(){
    this.tooltipCustomizer = ({series, seriesIndex, dataPointIndex, w}) =>{
      let tooltipHtml = null;
      if(!_.isEmpty(this.data)){
        let tempData: any = this.data[seriesIndex].data[dataPointIndex];
        if(this.data[0].name.indexOf('Door') != -1){
          if(tempData[1] == 1) {
            tempData[1] = 'Open'
          }
          if(tempData[1] == 0) {
            tempData[1] = 'Close'
          }
        } else {
          if(w.config?.series?.filter(item=>item.name==='Shock Detection' || item.name==='Vibration Detection').length>0){
            tempData[1] = Boolean(tempData[1])
          }else{
          tempData[1] = Number(tempData[1])
          }
        }
        let measureValue = tempData[1] + this.currentMeasure.uom.trim();
        if(this.currentMeasure.uom && this.currentMeasure.uom.indexOf('C') == -1 && this.currentMeasure.uom.indexOf('F') == -1 && this.currentMeasure.uom.indexOf('%') == -1 && this.currentMeasure.uom !== '') {
          measureValue = tempData[1] + ' ' + this.currentMeasure.uom;
        }
        let roundColor = 'bgcolor4';
        if(tempData[2] !="assets/svgs/gateway-adder.svg"){
          roundColor = "bgcolor21";
        }
        let icon ="assets/svgs/gateway-adder.svg"
        if(tempData[2]){
          icon =tempData[2]
        }
        let relatedSensorId = tempData[3]?tempData[3]:'';
        let sensorTemplate = relatedSensorId?' (' +relatedSensorId+')':'';
        let header =  this.assetObject.name + sensorTemplate;
        let timeLabel = '<div class="p-2  flex items-center"><span>Time :</span><span class="font-bold pl-2">'+ this.formateDatePipe.transform(tempData[0], 'default') +'</span></div>'
        if(seriesIndex ==  1) {
          header = 'Minimum Threshold';
          timeLabel = '';
          roundColor = "bgcolor21";
        }
        if(seriesIndex ==  2) {
          header = 'Maximum Threshold';
          timeLabel = '';
          roundColor = "bgcolor21";
        }
        tooltipHtml = '<div class=" ">'
        + '<div class="font-bold bgcolor38 p-2">'+ header +'</div>'

        + '<div class="p-2  flex items-center"><span class="inline-block w-3 h-3  rounded-full '+roundColor+'"></span> <span class="pl-2">'+ this.data[0].name +' :</span><span class="font-bold pl-2">'+ measureValue +'</span>'+
        ' <span class="px-1 pt-0" ><img width="18" height="18" alt="" src="'+icon
        +'"/></span>'+  '</div>'
        + timeLabel

        + '</div>';
      }
      return tooltipHtml;
    }
  }

  private getValue(value: CharacteristicGeoAreaValue | boolean | string | number,  tempMeasure: MeasureType){
     // TODO need to configure value based on tempMeasure.config
    let temp = value;
    if(typeof value === 'boolean'){
      temp = value ? 1 : 0;
    }else if(typeof value === 'string'){
      if(value === 'false' || value === 'true'){
        temp = JSON.parse(value);
        temp = temp ? 1 : 0;
      }else{
        temp = value;
      }
    }else {
      temp = value;
    }
    return temp;
  }

  private updateTabs() {
    if(!_.isEmpty(this.assetObject)){
      this.tabs = _.cloneDeep(this.originalTabs);
      if(_.isEmpty(this.assetObject?.measures)){
        let environmentIndex = _.findIndex(this.tabs, {id: 1});
        if(environmentIndex > -1){
          this.tabs.splice(environmentIndex, 1);
        }
      }
      if (this.assetObject?.monitoredStatus !== 'MONITORED') {
        this.showDatePicker = false;
      }
    }
    this.navigate(0, this.tabs[0]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('assetObject')){
      this.updateTabs();
    }
     if(!this.selectedDateRange){
       this.selectedDateRange = { startDate: moment().subtract(4, 'hours'), endDate: moment()};
     }
     if(this.excursions){
       this.checkVoilations();
     }
     if(this.ruleSetId) {
       this.getRuleSetProfile(this.ruleSetId);
     }else if(this.assetObject.properties?.ruleSetId){  
     this.getRuleSetProfile(this.assetObject.properties?.ruleSetId);
     }
  }
  navigate(index, tab){
    this.activeTabIndex = index;
    this.activeTab = tab;
    this.hideDatePicker(false);
  }

  hideDatePicker(hideDate: boolean) {
    if (hideDate) {
      this.showDatePicker = false;
    } else {
      this.showDatePicker = true;
    }
    this.cd.detectChanges()
  }

  violationIcon(measure) {
    return  this._shipmentUtils.getExcursionIcon(measure.measureServiceId);
  }

  checkVoilations() {
    Object.keys(this.excursions.ViolationMap).forEach(voilatedMeasure=>{
      this.assetObject.measures?.forEach(measure=>{
        if(voilatedMeasure.split(':')[1] == measure.measureCharacteristicId && (this.excursions.ViolationMap[voilatedMeasure][0].violated && this.excursions.ViolationMap[voilatedMeasure][0].alertStatus == 'Active')) {
          measure.violated = true;
          measure.violatedValue = this.excursions.ViolationMap[voilatedMeasure][0].propertyValue;
          measure.violatedTime = this.excursions.ViolationMap[voilatedMeasure][0].deviceTime;
          measure.violatedIcon = this.violationIcon(measure);
        }
      })
    })
  }

  getRuleSetProfile(rulsetId: string) {
    this.shipmentService.getRuleSetProfile(rulsetId).subscribe(result => {
      this.thresholdData = result;
      this.assetObject.measures?.forEach(measure=>{
        if(this.thresholdData.eventConfig[measure.measureServiceId+':'+measure.measureCharacteristicId]){
          measure.minThreshold = this.thresholdData.eventConfig[measure.measureServiceId+':'+measure.measureCharacteristicId].min
          measure.maxThreshold = this.thresholdData.eventConfig[measure.measureServiceId+':'+measure.measureCharacteristicId].max
        }
      })
      this.measureChanged(this.currentMeasure);
    })
  }
}
