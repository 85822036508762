import { FilterListItem } from "./filter-list.type";

export interface UnParsedActionTemplate {
	id: string;
	name: string;
	description: string;
	type: string;
	tenantId: string;
	status: string;
	preResolvedParams?: any;
	inputForm: string;
	applicableTriggerTemplates?: any;
}
export interface ParsedActionTemplate {
	id: string;
	name: string;
	description: string;
	type: string;
	tenantId: string;
	status: string;
	preResolvedParams?: any;
	inputForm: string;
  inputFormJSON?: any;
	applicableTriggerTemplates?: any;
}
export interface UnParsedTriggerTemplate {
	id: string;
	application: string;
	description: string;
	inputForm: any;
	inputFormStr: string;
	msgDetailMap: any;
	msgSummaryMap: any;
	msgDetail: string;
	msgSummary: string;
	metric: string;
	modifiedAt: number;
	modifiedBy: string;
	status: string;
	preResolvedParams?: any;
	tenantId: string;
	metricGroup: string;
	type: string;
	deleted: boolean;
	createdBy: string;
	createdAt: number;
	actionTemplatesMap: any;
	actionTemplates: string[];
	scope: string;
	metricName: string;
	metricGroupName: string;
	recipeCount: number;
}
export interface RuleTypes{
  asset?: RuleType;
  shipment?: RuleType;
  device?: RuleType;
  location?: RuleType;
}
export interface RuleType{
  key: string;
  /**
   * @deprecated key for legacy code compatibility and it will be revoked
   */
  name: string; //TODO Need to deprecate name
  tlabel: string;
  tfLabel : string;
  /**
   * @deprecated key for legacy code compatibility and instead need to use groups
   */
  data: TriggerGroup[]; //TODO Need to deprecate data and instead need to use groups
  groups: TriggerGroup[];
}
export interface TriggerGroup{
  checked: boolean;
  key: string;
  name: string;
  triggers: UnParsedTriggerTemplate[];
  value: number;
}
export interface Rule{
  id?: string;
  title?: string;
  ruleType?: string;
  triggerGroup?: string;
  trigger?: any;
  organization: any;
  triggerParams?: any;
  action?: any;
  actionParams?: any;
  actionFormList? : any[];
  ruleContext?:any;
}
export enum RulesetContextTypes {
	rules = 'RULE',
	assetTypes = 'CATEGORY',
	Site = 'SITE',
	WorkArea = 'AREA',
	shipments = 'SHIPMENT'
}