import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter,OnChanges, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterGroup, FilterType, Range } from "../../../common/models/index";
import { Store } from '@ngxs/store';
import _ from 'lodash';
import { MatRadioChange } from '@angular/material/radio';
import { TogglePanel } from '../../../common/actions/index';
import moment from 'moment';
import { FilterList } from '@cl/@types/filter-list.type';


@Component({
  selector: 'cl-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
  animations: [
    trigger("itemAnim", [
      transition(":enter", [style({ transform: "translateX(-100%)" }), animate(350)]),
      transition(":leave", [
        animate(
          "0.25s ease",
          style({
            transform: "translateX(-100%)",
          })
        ),
      ]),
    ]),
  ]
})
export class ListFilterComponent implements OnInit {
  @Input() DisableCustomCounts: string[]=[]; //removing counts for selected filterType
  @Input() mgClass: string;
  @Input() filters: FilterList[];
  @Input() title: string;
  @Input() enableEdit: boolean;
  @Input() enableToggle: boolean;
  @Input() enableSearchBox: boolean;
  @Input() heightClass: string;
  @Input() dataLoading: boolean;
  @Input() hideTitle: boolean;
  @Input() showCounts: boolean;
  @Input() showIcon: boolean;
  @Input() actionCount: number;
  @Input() hideFilterBy: any;
  @Input() hideLabel: string;
  @Input() numSelected: any;
  @Input() isNavigator: boolean;
  @Input() fullHeight: boolean;
  @Input() caseSenstiveFilter: any;
  @Output() panelsEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleFilterPanel: EventEmitter<any> = new EventEmitter();
  searchFormGroup: FormGroup;
  searchTerm = "";
  toggledFilter: any = {};
  listFilters: any = [];
  prevCount = -1;
  clearDisabled = true;
  svgName:any;
  dateRangeFilters: any[] = [];
  selectedDateRange: {startDate: moment.Moment, endDate: moment.Moment};
  ranges: any = {};
  datePickerClass: any = 'sideFilterDatePicker pl-[4px] pr-[4px]';

  constructor(private store: Store) {
    this.ranges = {
      'Last 1 Hour': [moment().subtract(1, 'hour'), moment()],
      'Last 2 Hours': [moment().subtract(2, 'hour'), moment()],
      'Last 4 Hours': [moment().subtract(4, 'hour'), moment()],
      'Last 30 Minutes': [moment().subtract(30, 'minute'), moment()]
    };

  }

  ngOnInit() {
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
    this.heightClass = this.heightClass || "h-calc-20";
  }
  ngOnChanges(changes: any) {
    this.clearDisabled = this.numSelected === "All" || this.numSelected > 0 ? true : false;
    if (changes.filters || this.actionCount !== this.prevCount) {
      this.listFilters = _.cloneDeep((this.filters || []).slice());
      this.dateRangeFilters = this.listFilters.filter(
        a => a.type == 'dateRangeFilter'
      );
      this.prevCount = this.actionCount;
      this.listFilters.forEach(filter =>{
         if(this.DisableCustomCounts.includes(filter.filterType)){
          filter.showCount = false
         }else{
          filter.showCount = true
         }
      })
    }
  }
  getFloatClass(fg: any) {
    if (!this.isNavigator) return "";
    return fg.filterType === "type" ? "float-left w-195px" : "float-right w-195px pr-3";
  }
  trackByIdFn = (_: number, item: any) => item?.properties?.id;
  onSearchInput(event: any) {
    this.searchTerm = this.searchFormGroup.value.searchTerm;
    this.emitSearch(false);
  }
  togglePanel() {
    this.toggleFilterPanel.emit();
  }

  fadeZero(count: number) {
    return this.showCounts && !count ? "fade" : "";
  }
  clearAll() {
    this.panelsEmitter.emit({
      action: "clearFilters",
    });
  }
  toggleFilter(filter: any, filterGroup?: any, selectionType?: FilterType, event?: MatRadioChange) {
    this.toggledFilter = { ...filter };
    if (filterGroup.filterType) {
      this.toggledFilter.filterGroupName = filterGroup.filterType;
    }
    if (selectionType === 'singleselect') {
      this.toggledFilter.selectionType = 'singleselect';
    }
    this.emitSearch(true);
  }

  onRangeChanged(event: Range, filterGroup: FilterGroup): void {
    if (event.from && event.to) {
      this.panelsEmitter.emit({
        action: "filterByRange",
        name: filterGroup.filterType,
        toggleFilter: filterGroup,
        range: event,
      });
    }
  }

  datesUpdated(event: any, filterGroup: FilterGroup): void {
    if (!_.isEmpty(event) && event.startDate && event.endDate) {
      const from = event.startDate.valueOf();
      const to = event.endDate.valueOf();
      const range: Range = {from, to};
      this.onRangeChanged(range, filterGroup);
    }
  }

  toggleSubFilter(subFilter: any, filter: any) {
    this.panelsEmitter.emit({
      name: subFilter.name,
      checked: subFilter.checked,
      action: "toggleSubFilter",
      subFilter: subFilter,
      parentFilter: filter,
    });
  }
  emitSearch(toggleFilter: any) {
    this.panelsEmitter.emit({
      name: this.toggledFilter.name,
      checked: this.toggledFilter.checked,
      action: "filterByType",
      searchTerm: this.searchTerm,
      toggleFilter: toggleFilter,
      filter:this.toggledFilter,
      dateRange: this.toggledFilter.selectedDateRange
    });
  }
  editEntity(filter: any) {
    this.panelsEmitter.emit({
      action: "editEntity",
      entity: filter,
    });
  }
  deleteEntity(filter: any) {
    this.panelsEmitter.emit({
      action: "deleteEntity",
      entity: filter,
    });
  }
  getDateRangePanelUpdate(event: any) {
    this.toggledFilter = event;
    this.emitSearch(true);
  }
}
