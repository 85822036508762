import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Injectable({
  providedIn: 'root'
})
export class BulkOperaionsService {
  constructor(private http: HttpClient, private apiProvider: ApiProviderService) {
  }

  getBulkOperationsList(payload:any){
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/bulkDataJob/list`, payload);
  }

  getBulkFilters(){
    return this.http.get(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/bulkDataJob/filters?true`);
  }
  
  getBulkDetails(bulkId){
    return this.http.get(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/bulkDataJob/${bulkId}`)
  }
  getSearch(payload){
    return this.http.post(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/bulkDataJob/list`, payload);
  }
  downloadFile(id,type){
    return this.http.get(`${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/bulkDataJob/${id}/download/${type}`,{ responseType: 'text' });
  }
}
