export class SetClient {
  static readonly type = "[Client Config] Set Client";
  constructor(public user: any) {}
}

export class ToggleClient {
  static readonly type = "[Client Config] Toggle Client";
  constructor() {}
}

export class SetTenantId {
  static readonly type = "[Client Config] Set Tenant Id";
  constructor(public tenantId: string) {}
}

export class CcUpdateClientLogoUrl {
  static readonly type = "[Client Config] CcUpdateClientLogoUrl";
  constructor(public readonly logoUrl: string) {}
}
