<div class="cl-side-panel bcolor9 border flex top-fix flex-col">
    <section class="cl-side-panel-title side-panel-header-bg">
      <div class="grow flex items-center">
        <img src="assets/svgs/circle-icons-white/location.svg" />
        <span class="black font-bold">Bulk Operation Summary</span>
      </div>
      <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content">
      <div class="spin-pos mt-10" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
      <div>
        <div class="p-4">
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.id">
            <div class="basis-2/4 font-bold">Job ID</div>
            <div class="basis-2/4">{{summaryData?.id}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.source">
            <div class="basis-2/4 font-bold">Source</div>
            <div class="basis-2/4">{{summaryData?.source}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.entityType">
            <div class="basis-2/4 font-bold">Entity</div>
            <div class="basis-2/4">{{summaryData?.entityType}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.status">
            <div class="basis-2/4 font-bold">Status</div>
            <div class="basis-2/4">{{summaryData?.status}}</div>
          </div>
          <div class="flex fs14 color16 mt-1">
            <div class="basis-2/4 font-bold">Entity Count</div>
            <div class="basis-2/4">{{summaryData?.totalRecordsCount}}</div>
          </div>
          <div class="flex fs14 color16 mt-1">
            <div class="basis-2/4 font-bold">Successful Entities</div>
            <div class="basis-2/4">{{summaryData?.successRecordsCount}}</div>
          </div>
          <div class="flex fs14 color16 mt-1">
            <div class="basis-2/4 font-bold">Failed Entities</div>
            <div class="basis-2/4">{{summaryData?.failedRecordsCount}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.inputFileType">
            <div class="basis-2/4 font-bold">Input File Type</div>
            <div class="basis-2/4">{{summaryData?.inputFileType}}</div>
          </div>
          <div class="flex fs14 color16 mt-1">
            <div class="basis-2/4 font-bold">Input File</div>
                <button class="color4 mr-2" (click)="downloadFile('input',summaryData?.id)"><i aria-hidden="true" class="fa fa-download mr-2"></i> Download </button>
          </div>
          <div class="flex fs14 color16 mt-1">
            <div class="basis-2/4 font-bold">Result File</div>
              <button     [ngClass]="{'color4 mr-2': summaryData?.errorFileUrl, 'mr-2' : !summaryData?.errorFileUrl}"
              [disabled]="!summaryData?.errorFileUrl" (click)="downloadFile('output',summaryData?.id)"><i aria-hidden="true" class="fa fa-download mr-2"></i> Download </button>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.moreInfo">
            <div class="basis-2/4 font-bold">Notification Sent To</div>
            <div class="basis-2/4">
              <span [title]="summaryData?.moreInfo.notifiedTo">{{stringTruncate(summaryData?.moreInfo.notifiedTo, 20)}}</span>
            </div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.moreInfo">
            <div class="basis-2/4 font-bold">Notification Time</div>
            <div class="basis-2/4">{{datePipe.transform(summaryData?.moreInfo.notifiedOn)}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.createdAt">
            <div class="basis-2/4 font-bold">Created At</div>
            <div class="basis-2/4">{{datePipe.transform(summaryData?.createdAt)}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.createdBy">
            <div class="basis-2/4 font-bold">Created By</div>
            <div class="basis-2/4">{{summaryData?.createdBy}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.modifiedAt">
            <div class="basis-2/4 font-bold">Modified At</div>
            <div class="basis-2/4">{{datePipe.transform(summaryData?.modifiedAt)}}</div>
          </div>
          <div class="flex fs14 color16 mt-1" *ngIf="summaryData?.modifiedBy">
            <div class="basis-2/4 font-bold">Modified By</div>
            <div class="basis-2/4">{{summaryData?.modifiedBy}}</div>
          </div>
        </div>
      </div>
    </section>
  </div>
