import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: "app-load-more",
  templateUrl: "./load-more.component.html",
  styleUrls: ["./load-more.component.scss"],
})
export class LoadMoreComponent implements OnInit {
  @Input() numToLoad = 50;
  @Input() loading = false;
  @Input() allLoaded = false;
  @Input() hideText = false;
  @Input() actionLabel = "load";
  @Output() loadMoreEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }
  loadMore() {
    this.loadMoreEmitter.emit({
      action: "loadMore",
    });
  }
}
