import { Injectable } from '@angular/core';
// import { TokenResolverService } from '@cl/services/resolvers';
import * as _ from 'lodash';
import { AuthorizeService } from '../../@authorize/authorize.service';
import { CustomRoute } from '../../@types/custom-route.type';
import { AssetDetailsComponent } from '../../asset/asset-details/asset-details.component';
import { AssetListComponent } from '../../asset/asset-list/asset-list.component';
import { RuleListComponent } from '../../business-rules/rule-list/rule-list.component';
import { RulesetListComponent } from '../../business-rules/ruleset-list/ruleset-list.component';
// import { LvGraphComponent } from '../../navigator/lv-graph/lv-graph.component';
import { NavigatorMapComponent } from '../../navigator/navigator-map/navigator-map.component';
import { NavigatorComponent } from '../../navigator/navigator.component';
import { GatewayListComponent } from '../../sensor-fabric/gateway/gateway-list/gateway-list.component';
import { SensorListComponent } from '../../sensor-fabric/sensor/sensor-list/sensor-list.component';
import { ShipmentListComponent } from '../../shipments/shipment-list/shipment-list.component';
import { UserGroupListComponent } from '../../user/user-group/user-group-list/user-group-list.component';
import { UserListComponent } from '../../user/user-list/user-list.component';
import { UserPolicyListComponent } from '../../user/user-policy/user-policy-list/user-policy-list.component';
import { UserProfileListComponent } from '../../user/user-profile/user-profile-list/user-profile-list.component';
import { UserRoleListComponent } from '../../user/user-role/user-role-list/user-role-list.component';
import { LoginComponent } from "../../authenticate/login/login.component";
import { ResetPasswordComponent } from "@cl/authenticate/reset-password/reset-password.component";
import { NotificationListComponent } from '@cl/notification/notification-list/notification-list.component';
import { LocationListComponent } from '@cl/locations/location-list/location-list.component';
import { LocationDetailsComponent } from "@cl/locations/location-details/location-details.component";
import { SensorDetailsComponent } from '@cl/sensor-fabric/sensor/sensor-details/sensor-details.component';
import { GatewayDetailsComponent } from '@cl/sensor-fabric/gateway/gateway-details/gateway-details.component';
import { MyAccountComponent } from '@cl/user/my-account/my-account.component';
import { LibraryComponent } from '@cl/analytics/analytics-library/library-list/library.component';
import { MonitorComponent } from '@cl/analytics/analytics-monitor/monitor.component';
import { BiWorkbenchComponent } from '@cl/analytics/bi-workbench/bi-workbench.component';
import { RouteGuard } from './route.guard';
import { TenantListComponent } from '@cl/tenant/tenant-list/tenant-list.component';
import { ShipmentDetailsComponent } from '@cl/shipments/shipment-details/shipment-details.component';
import { TenantSettingsComponent } from '@cl/settings/tenant-settings/tenant-settings.component';
import { VendorListComponent } from '@cl/settings/vendor-accounts/vendor-list/vendor-list.component';
import { DeviceProfileListComponent } from '@cl/sensor-fabric/device-profiles/device-profile-list/device-profile-list.component';
import { MlWorkbenchComponent } from '@cl/machine-learning/ml-workbench/ml-workbench.component';
import { ApiReferenceComponent } from '@cl/common/api-reference/api-reference.component';
import { InventoryComponent } from "@cl/inventory/inventory.component";
import { SkuListComponent } from "@cl/sku/sku-list.component";
import { SkuDetailsComponent } from '@cl/sku/sku-details/sku-details.component';
import { InventoryDetailsComponent } from '@cl/inventory/inventory-details/inventory-details.component';
import { ApiSubscriptionComponent } from '@cl/settings/api-subscription/api-subscription.component';
import { LocationRouteDetailsComponent } from '@cl/locations/location-route-details/location-route-details.component';
import { BulkOperationListComponent } from '@cl/bulk-operation/bulk-operation-list/bulk-operation-list.component';
import { SftpUserComponent } from '@cl/user/sftp-user/sftp-user.component';

import { SimulateListComponent } from '@cl/simulate/simulate-list/simulate-list.component';
import {SimulateDetailsComponent} from '@cl/simulate/simulate-details/simulate-details.component';
import {RecipesListComponent} from '@cl/recipes/recipes-list/recipes-list.component';
import { RecipeDetailsComponent } from '@cl/recipes/recipe-details/recipe-details.component';
import { ThemeComponent } from '@cl/property/theme/theme.component';
import { AppSettingsComponent } from '@cl/property/app-settings/app-settings.component';
import { TranslationsComponent } from '@cl/property/translations/translations.component';
import { UserService } from '@cl/user/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private static allRoutes: CustomRoute[] = [
    {
      path: 'navigator', component: NavigatorComponent, icon: 'focusEye', group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Home', permission: {policies : ['ASSET_SUMMARY', 'SHIPMENT_SUMMARY', 'LOCATION_SUMMARY', 'SENSORTAG_SUMMARY']}, canActivate: [RouteGuard],
      /*resolve: {
        token: TokenResolverService,
      },*/
      children: [
        { path: "map", pathMatch: "full", component: NavigatorMapComponent},
        // { path: "graph", pathMatch: "full", component: LvGraphComponent, },
        { path: "", pathMatch: "full", redirectTo: "map", }
      ],pageKey: 'navigator'
    },
    { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Digital Twin', isLevel1Menu: true, icon: 'reports' },

      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Assets', level1Parent: 'Digital Twin',icon: 'category', hidden: false},
      { path: 'assets', component: AssetListComponent, parent: 'Assets', showInMainMenu: false, showInSecondaryMenu: true, label: 'Assets', level1Parent: 'Digital Twin', permission: {policies : ['ASSET_SUMMARY']}, canActivate: [RouteGuard], pageKey: 'assets' },
      { path: 'packaging', component: AssetListComponent, parent: 'Assets', showInMainMenu: false, showInSecondaryMenu: true, label: 'Packaging', level1Parent: 'Digital Twin', permission: {policies : ['ASSET_SUMMARY']}, canActivate: [RouteGuard], disabled: true , pageKey: 'packaging'},
      { path: 'assetdetails/:id', component: AssetDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Assets', level1Parent: 'Digital Twin', permission: {policies : ['ASSET_DETAIL']} , canActivate: [RouteGuard], pageKey: 'assetdetails'},

      { path: 'shipments', component: ShipmentListComponent, icon: 'truck', group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Logistics', level1Parent: 'Digital Twin', permission: {policies : ['SHIPMENT_SUMMARY']}, canActivate: [RouteGuard], hidden: false, pageKey: 'shipments'},
      { path: 'shipmentdetails/:id', component: ShipmentDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Logistics', permission: {policies : ['SHIPMENT_DETAIL']}, canActivate: [RouteGuard], pageKey: 'shipments'},

      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Products', level1Parent: 'Digital Twin',icon: 'products', hidden: false},
      { path: 'sku', component: SkuListComponent, parent: 'Products', showInMainMenu: false, showInSecondaryMenu: true, label: 'SKU', permission: {policies : ['SKU_SUMMARY','SKU_DETAIL']}, canActivate: [RouteGuard], pageKey: 'skudetails' },
      { path: 'inventory', component: InventoryComponent, parent: 'Products', showInMainMenu: false, showInSecondaryMenu: true, label: 'Inventory', permission: {policies : ['INVENTORY_SUMMARY']}, canActivate: [RouteGuard], pageKey:'inventory' },

      { path: 'locations', component: LocationListComponent, showInMainMenu: true, showInSecondaryMenu: false, group: 'Operate', label: 'Locations', level1Parent: 'Digital Twin', icon: 'mapping-admin', permission: {policies : ['SITE_SUMMARY', 'AREA_SUMMARY', ]} , canActivate: [RouteGuard], hidden: false, pageKey: 'locations'},
      { path: 'locationdetails/:id', component: LocationDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Locations', permission: {policies : ['AREA_DETAIL', 'SITE_DETAIL']} , canActivate: [RouteGuard], pageKey: 'locationdetails'},

      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Devices', level1Parent: 'Digital Twin', icon: 'zone', hidden: false},
      { path: 'sensors', component: SensorListComponent, parent: 'Devices', showInMainMenu: false, showInSecondaryMenu: true, label: 'Sensors', permission: {policies : ['SENSORTAG_SUMMARY', 'TAGGEDASSET_SUMMARY']}, canActivate: [RouteGuard], pageKey: 'sensors'},
      { path: 'sensordetails/:id', component: SensorDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Sensor Detail', permission: {policies : ['SENSORTAG_DETAIL']}, canActivate: [RouteGuard], pageKey: 'sensordetails'},
      { path: 'trackers', component: SensorListComponent, parent: 'Devices', showInMainMenu: false, showInSecondaryMenu: true, label: 'Trackers', permission: {policies : ['SENSORTAG_SUMMARY', 'TAGGEDASSET_SUMMARY']}, canActivate: [RouteGuard], pageKey: 'trackers'},
      { path: 'trackerdetails/:id', component: SensorDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Tracker Details', permission: {policies : ['SENSORTAG_DETAIL']}, canActivate: [RouteGuard], pageKey: 'trackerdetails'},
      { path: 'gateways', component: GatewayListComponent, parent: 'Devices', showInMainMenu: false, showInSecondaryMenu: true, label: 'Gateways', permission: {policies : ['RECEIVER_SUMMARY', 'RECEIVER_DETAIL']} , canActivate: [RouteGuard], pageKey: 'gateways'},
      { path: 'gatewaydetail/:id/:entityTypeKey', component: GatewayDetailsComponent,  showInMainMenu: false, showInSecondaryMenu: false, label: 'Gateway Detail', permission: {policies : ['RECEIVER_DETAIL']}, canActivate: [RouteGuard], pageKey: 'gatewaydetail'},
      { path: 'deviceprofiles', component: DeviceProfileListComponent, parent: 'Devices', showInMainMenu: false, showInSecondaryMenu: true, label: 'Device Profiles', permission: {policies : ['EVENTPROFILER_DETAIL', 'EVENTPROFILER_SUMMARY']}, canActivate: [RouteGuard], pageKey: 'deviceprofiles'},

      
      // Analytics
      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Dashboards', icon: 'light', hidden: false },
      { path: 'monitor', component: MonitorComponent , parent: 'Dashboards', showInMainMenu: false, showInSecondaryMenu: true, label: 'Monitor',  permission: {policies : ['ANALYTICS_DETAIL', 'ANALYTICS_SUMMARY']}, canActivate: [RouteGuard], pageKey: "monitor"},
      { path: 'library', component: LibraryComponent, parent: 'Dashboards', showInMainMenu: false, showInSecondaryMenu: true, label: 'Library',  permission: {policies : ['ANALYTICS_DETAIL', 'ANALYTICS_SUMMARY']}, canActivate: [RouteGuard], pageKey: "library"},

    { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Configure & Manage', isLevel1Menu: true, icon: 'state' },


      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Business Rules', level1Parent: 'Configure & Manage', icon: 'rule', hidden: false},
      { path: 'rules', component: RuleListComponent, parent: 'Business Rules', showInMainMenu: false, showInSecondaryMenu: true, label: 'Business Rules', permission: {policies : ['RECIPE_SUMMARY']} , canActivate: [RouteGuard], pageKey: "rules"},
      { path: 'rule-sets', component: RulesetListComponent, parent: 'Business Rules', showInMainMenu: false, showInSecondaryMenu: true, label: 'Rule Sets', permission: {policies : ['RECIPE_SUMMARY']} , canActivate: [RouteGuard], pageKey: "rules"},
      { path: 'groups', component: UserGroupListComponent, parent: 'Business Rules', showInMainMenu: false, showInSecondaryMenu: true, label: 'Notification Groups', permission: {policies : ['USER_SUMMARY']}, canActivate: [RouteGuard], pageKey: "groups"},

      // { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Manage Tags', level1Parent: 'Configure & Manage', icon: 'tag', disabled: true, hidden: false},
      // { path: 'tags', component: AssetListComponent, parent: 'Manage Tags', showInMainMenu: false, showInSecondaryMenu: false, label: 'Manage Tags', disabled: true, permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard], pageKey: "tags" }, //TODO Need to update exact path and component

      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'User Admin', level1Parent: 'Configure & Manage', icon: 'user-single', hidden: false},
      { path: 'users', component: UserListComponent, parent: 'User Admin', showInMainMenu: false, showInSecondaryMenu: true, label: 'Users', permission: {policies : ['USER_SUMMARY']}, canActivate: [RouteGuard], pageKey:"users"},
      { path: 'roles', component: UserRoleListComponent, parent: 'User Admin', showInMainMenu: false, showInSecondaryMenu: true, label: 'Roles', permission: {policies : ['ROLE_SUMMARY']}, canActivate: [RouteGuard], pageKey:"roles"},
      { path: 'policies', component: UserPolicyListComponent, parent: 'User Admin', showInMainMenu: false, showInSecondaryMenu: true, label: 'Policies', permission: {policies : ['POLICY_SUMMARY', 'POLICY_DETAIL']}, canActivate: [RouteGuard], pageKey:"policies"},
      // { path: 'uiprofiles', component: UserProfileListComponent, parent: 'User Admin', showInMainMenu: false, showInSecondaryMenu: true, label: 'UI Profiles', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard] },
      {  path: 'notifications', component: NotificationListComponent, showInMainMenu: false, showInSecondaryMenu: true, label: 'Notifications', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard], pageKey: "notifications"},
      {  path: 'notifications/:filter', component: NotificationListComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Notifications', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard], pageKey: "notifications"},
      { path: 'tenants', component: TenantListComponent, parent: 'User Admin', showInMainMenu: false, showInSecondaryMenu: true, label: 'Tenants', permission: {policies : ['TENANT_SUMMARY']}, canActivate: [RouteGuard], pageKey: "tenants"},
      { path: 'sftpuser', component: SftpUserComponent, parent: 'User Admin', showInMainMenu: false, showInSecondaryMenu: true, label: 'System Users', permission: {policies : ['SFTPUSERS_CREATE']}, canActivate: [RouteGuard], pageKey: "tenants"},
      
      

      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'App Settings', level1Parent: 'Configure & Manage', icon: 'settings', hidden: false},
      { path: 'tenantsettings', component: TenantSettingsComponent, parent: 'App Settings', showInMainMenu: false, showInSecondaryMenu: true, label: 'Tenant Settings', permission: {policies : ['TENANT_DETAIL']}, canActivate: [RouteGuard], pageKey: 'tenantsettings'}, //TODO Need to update exact path and component
      { path: 'api-subscriptions', component: ApiSubscriptionComponent, parent: 'App Settings', showInMainMenu: false, showInSecondaryMenu: true, label: 'API Subscriptions', permission: {policies : ['WEBHOOK_EVENT_SUBSCRIPTION_SUMMARY']}, canActivate: [RouteGuard], pageKey: 'api-subscriptions'}, //TODO Need to update exact path and component
      { path: 'vendors', component: VendorListComponent, parent: 'App Settings', showInMainMenu: false, showInSecondaryMenu: true, label: 'Vendor Accounts', permission: {policies : ['VENDOR_CREDENTIALS_SUMMARY']}, canActivate: [RouteGuard], pageKey: 'vendors' }, //TODO Need to update exact path and component
      
      { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Bulk Operations', level1Parent: 'Configure & Manage', icon: 'message', hidden: false},
      { path: 'bulklist', component: BulkOperationListComponent, parent: 'Bulk Operations', showInMainMenu: false, showInSecondaryMenu: false, label: 'Operations', permission: {policies : ['BULK_DATA_JOB_SUMMARY']}, canActivate: [RouteGuard], pageKey: 'bulklist'},

    { path: 'solutions', component: AssetListComponent, group: 'Build', showInMainMenu: true, showInSecondaryMenu: false, label: 'Solutions', icon: 'open-box', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, disabled: true, pageKey: 'solutions'},
    { group: 'Build', showInMainMenu: true, showInSecondaryMenu: false, label: 'Catalog', icon: 'message', externalPath: 'TENANT_ID/portfolios', permission: {policies : ['CDM_VIEW']}, replaceModelApp: true, replaceTenantId: true, pageKey: 'solutions'},
    //{ group: 'Build', showInMainMenu: true, showInSecondaryMenu: false, label: 'Model', icon: 'hub', externalPath: 'TENANT_ID/location/DefaultLocation/default', permission: {policies : ['CDMINSTANCE_VIEW']}, replaceModelApp: true, replaceTenantId: true },
    { group: 'Build', showInMainMenu: true, showInSecondaryMenu: false, label: 'Recipes', icon: 'light', hidden: false, isBeta:true},
    { path: 'recipes', component: RecipesListComponent, parent: 'Recipes', showInMainMenu: true, showInSecondaryMenu: true, label: 'Recipes', icon: 'light', permission: {policies : ['CDM_VIEW']}, canActivate: [RouteGuard], isBeta: true, pageKey: 'recipes' },
    { path: 'simulate', component: SimulateListComponent, parent: 'Recipes', showInMainMenu: false, showInSecondaryMenu: true, label: 'Simulate', icon: 'light', permission: {policies : ['CDM_VIEW']}, canActivate: [RouteGuard], isBeta: true ,pageKey: 'simulate'},
    // { group: 'Extend', showInMainMenu: true, showInSecondaryMenu: false, label: 'Dev Central', icon: 'reports', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard] , externalPath: 'http://api.cloudleaf.io'},
    { group: 'Extend',  path: 'apireference', component: ApiReferenceComponent, showInMainMenu: true, showInSecondaryMenu: false, label: 'Dev Central', icon: 'reports', permission: { policies: ['API_ACCESS'] }, canActivate: [RouteGuard], pageKey: 'Restapi_APIList'},
    // { path: 'options', component: AssetListComponent, group: 'Intelligence', showInMainMenu: true, showInSecondaryMenu: false, label: 'Dev Sandbox', icon: 'sandbox', disabled: true, permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard]}, //TODO Need to update exact path and component
    { path: 'mlworkbench', component: MlWorkbenchComponent, group: 'Extend', showInMainMenu: true, showInSecondaryMenu: false, label: 'ML Workbench', icon: 'ml-workbench', permission: {policies : ['ANALYTICS_CREATE', 'SCOPE_ALL']}, canActivate: [RouteGuard], pageKey: 'mlworkbench'}, //TODO Need to update exact path and component
    { path: 'biworkbench', component: BiWorkbenchComponent, group: 'Extend', showInMainMenu: true, showInSecondaryMenu: false, label: 'BI Workbench', icon: 'workbench', permission: {policies : ['ANALYTICS_CREATE', 'SCOPE_ALL']}, canActivate: [RouteGuard], pageKey: 'biworkbench'}, //TODO Need to update exact path and component

    { path: 'myaccount', component: MyAccountComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'My Account' , permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard], pageKey: 'myaccount'},
    { path: 'login', component: LoginComponent, showInMainMenu: false, showInSecondaryMenu: false, label:'', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]} , canActivate: [RouteGuard]},
    { path: 'logout', component: LoginComponent, showInMainMenu: false, showInSecondaryMenu: false, label:'', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard] },
    { path: 'resetpassword', component: ResetPasswordComponent, showInMainMenu: false, showInSecondaryMenu: false, label:'', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]} , canActivate: [RouteGuard],pageKey: 'skudetails'},
    { path: 'skudetails/:id', component: SkuDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Sku', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]} , canActivate: [RouteGuard]},
    { path: 'inventorydetails/:id', component: InventoryDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Inventory', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]} , canActivate: [RouteGuard], pageKey:'inventorydetails'},
    { path: 'routedetails/:id', component: LocationRouteDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Locations', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]} , canActivate: [RouteGuard], pageKey:'locationdetails'},
    { group: 'Operate', showInMainMenu: true, showInSecondaryMenu: false, label: 'Platform Settings', level1Parent: 'Configure & Manage', icon: 'settings', hidden: false},
    { path: 'authsettings', component: AppSettingsComponent, parent: 'Platform Settings', showInMainMenu: false, showInSecondaryMenu: true, label: 'Platform Settings', permission: {policies : ['SCOPE_ALL']}, canActivate: [RouteGuard], pageKey: 'themes'}, //FIXME: HACK: pageKey is used for checking in isValidRoute
    { path: 'auththeme', component: ThemeComponent, parent: 'Platform Settings', showInMainMenu: false, showInSecondaryMenu: true, label: 'Theme', permission: {policies : ['SCOPE_ALL']}, canActivate: [RouteGuard], pageKey: 'themes'}, //FIXME: HACK: pageKey is used for checking in isValidRoute
    { path: 'authtranslate', component: TranslationsComponent, parent: 'Platform Settings', showInMainMenu: false, showInSecondaryMenu: true, label: 'Translate', permission: {policies : ['SCOPE_ALL']}, canActivate: [RouteGuard], pageKey: 'themes'}, //FIXME: HACK: pageKey is used for checking in isValidRoute

    { path: 'simulatedetails/:id', component: SimulateDetailsComponent, parent: 'Recipes', showInMainMenu: false, showInSecondaryMenu: false, label: 'Simulate', permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard], },
    { path: 'recipesdetails/:entityId/:action', component: RecipeDetailsComponent, showInMainMenu: false, showInSecondaryMenu: false, label: 'Recipes',permission: {policies : [AuthorizeService.DEFAULT_PERMISSION]}, canActivate: [RouteGuard], },

  ];
  constructor(private authorizeService: AuthorizeService, private userService: UserService, private router: Router) {
  }
  getDefaultRoute(temp: any): string {
    let destinationRouteToLoad: any;
    if(this.authorizeService.isValidRoute(temp) && !temp.disabled && (temp.path || temp.child[0]) ) {
      if (temp.path) {
        destinationRouteToLoad = temp;
      } else {
        destinationRouteToLoad = temp.child[0];
      }
    } else {
      let mainList = this.getMainMenuList();
      let menuItem;
      for( var i=0, l = mainList.length; i<l; i++) {
        menuItem = mainList[i];
        if(!menuItem.disabled && (menuItem.path || menuItem.child[0])) {
          if(menuItem.path) {
            destinationRouteToLoad = menuItem;
          } else {
            destinationRouteToLoad = menuItem.child[0];
          }
          break;
        }
      }
      if(!destinationRouteToLoad || !destinationRouteToLoad.path) {
        destinationRouteToLoad = { path: 'myaccount'};
      }

    }

    return '/' + destinationRouteToLoad.path;
  }
  navigateToDefaultRoute() {
    let defaultRoute;
    if(this.userService.isFieldTechUser()) {
      defaultRoute = this.getRouteByPath('assets')
    } else {
      defaultRoute = this.getMainMenuList()[0];
    }
    this.router.navigateByUrl(this.getDefaultRoute(defaultRoute));
  }
  getMenuPath(temp: any) {
    let destinationRouteToLoad: any;
    if (temp.path) {
      destinationRouteToLoad = temp;
    } else {
      destinationRouteToLoad = temp.child[0];
    }
    return '/' + destinationRouteToLoad.path;
  }
  getSideBarMenu(temp: CustomRoute): CustomRoute[] {
    let childRoutes = _.filter(NavService.allRoutes, { showInSecondaryMenu: true, parent: temp.parent });
    childRoutes = childRoutes.filter((route: CustomRoute)=>{
      return this.authorizeService.isValidRoute(route);
    });
    return childRoutes;
  }
  getMainMenuList(): CustomRoute[] {
    let topLevelRoutes: CustomRoute[] = _.filter(NavService.allRoutes, { showInMainMenu: true });
    topLevelRoutes = topLevelRoutes.filter((temp) => {
      if (!temp.hasOwnProperty('path')) {
        temp.child = this.getChildRoutes(temp);
      }
      let status = false;
      if ( (temp.path && _.isEmpty(temp.child)) || temp.externalPath) {
        status = this.authorizeService.isValidRoute(temp);
      } else if(temp.isLevel1Menu) {
        status = true;
      }else {
        status = !_.isEmpty(temp.child);
      }
      return status;

    });
    return topLevelRoutes;
  }
  private getChildRoutes(temp: CustomRoute) {
    if (!temp.hasOwnProperty('child') || _.isEmpty(temp.child)) {
      let tempRoutes: CustomRoute[] = _.filter(NavService.allRoutes, { parent: temp.label });
      tempRoutes = _.filter(tempRoutes, (temp: CustomRoute) => {
        return this.authorizeService.isValidRoute(temp);
      });
      return tempRoutes;
    } else {
      return temp.child;
    }
  }
  getRouteByPath(path) {
    let route;
    if(path) {
      route = _.filter(NavService.allRoutes, { path: path })[0];
    }
    return route;
  }

  static getActivatedRoutes(): CustomRoute[] {
    return this.allRoutes.filter((route: CustomRoute) => {
      return route.path;
    });
  }

}
