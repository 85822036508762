import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AssetListService } from '@cl/asset/asset-list/asset-list.service';
import { RulesService } from '@cl/business-rules/rules.service';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/common/constants';
import { UtilsService } from '@cl/common/utils/utils.service';
import { SearchApiService } from '@cl/common/services/search-api.service';
import _ from 'lodash';
import { debounceTime, distinctUntilChanged, Subject, Subscription, takeUntil } from 'rxjs';
import { RulesetContextTypes } from '@cl/@types/rule.type';
import { ToastService } from '@cl/common/services/toast.service';
import { OrganizationService } from '@cl/common/services/organization.service';
import { UserService } from '@cl/user/user.service';
import { ContentDialogComponent } from '@cl/common/components/content-dialog/content-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cl-add-rule-set',
  templateUrl: './add-rule-set.component.html',
  styleUrls: ['./add-rule-set.component.scss']
})
export class AddRuleSetComponent implements OnInit, OnChanges {
  @Input() ruleSetObject: any;
  @Input() newOrEdit: string;
  @Output() closeSidePanel = new EventEmitter();
  @ViewChildren('ruleCheckboxes') private ruleCheckboxes : QueryList<any>;
  @ViewChildren('assetTypeCheckboxes') private assetTypeCheckboxes : QueryList<any>;
  @ViewChildren('locationCheckboxes') private locationCheckboxes : QueryList<any>;
  @ViewChildren('shipmentCheckboxes') private shipmentCheckboxes : QueryList<any>;

  rulesetForm = this.form.group({
    ruleName: ['', Validators.required],
    ruleSearchTerm: new FormControl(),
    rules: this.form.array([]),
    assetTypeSearchTerm: new FormControl(),
    assetTypes: this.form.array([]),
    locationSearchTerm: new FormControl(), 
    locations: this.form.array([]),
    shipmentSearchTerm: new FormControl(),
    rulesetOrgName: new FormControl()
  });
  selectedRules: any[] = [];
  originalAvailableRules: any[] = [];
  availableRules: any[] = [];
  selectedAssetTypes: any[] = [];
  originalAvailableAssetTypes: any[] = [];
  availableAssetTypes: any[] = [];
  showAssetTypesDropdown: boolean = false;
  selectedLocations: any[] = [];
  originalAvailableLocations: any[] = [];
  availableLocations: any[] = [];
  showLocationsDropdown: boolean = false;
  showShipmentsDropdown: boolean = false;
  private readonly destroyed$ = new Subject<boolean>();
  isLoading: boolean;
  showRulesDropdown: boolean = false;
  subscriptions: Subscription[] = [];
  rulesLoading: boolean = false;
  assetTypeLoading: boolean = false;
  locationLoading: boolean = false;

  shipmentLoading:boolean = false;
  selectedShipments: any[] = [];
  originalAvailableShipments: any[] = [];
  availableShipments: any[] = [];

  ruleSetPayload = {
    name: '',
    enable: true,
    context: {},
    recipeIdNames: {}
  }
  saveInProgress: boolean = false;
  organization: any;
  orgList: any;
  orgId: any;
  orgCount: any;
  selectedOption: any = '';
  tenantList: any;
  userType: boolean;
  isOrgRestrictedUser: boolean;
  ruleSetUpdated: boolean = false;
  anyOrgLocationList:any = [];
  nullOrg:any = [];
  isOrgFieldDisable:boolean = false;
  searchFieldFilter:any=[];
  shipmentSearchFieldFilter:any=[];
  constructor(private form: FormBuilder,
    private rulesService: RulesService,
    private assetService: AssetListService,
    private searchApiService: SearchApiService,
    private utils: UtilsService,
    private toastService: ToastService,
    private organizationService: OrganizationService,
    private userService : UserService,
    public dialog: MatDialog
    ) { }

  get ruleSearch(): FormControl {
    return this.rulesetForm.get("ruleSearchTerm") as FormControl;
  }

  get assetTypeSearch(): FormControl {
    return this.rulesetForm.get("assetTypeSearchTerm") as FormControl;
  }

  get locationSearch(): FormControl {
    return this.rulesetForm.get("locationSearchTerm") as FormControl;
  }

  get shipmentSearch(): FormControl {
    return this.rulesetForm.get("shipmentSearchTerm") as FormControl;
  }

  get rulesetName(): FormControl {
    return this.rulesetForm.get("ruleName") as FormControl;
  }

  get rulesetOrgName(): FormControl {
    return this.rulesetForm.get("rulesetOrgName") as FormControl;
  }

  ngOnInit(): void {
    this.getRulesList('noOrg');
    this.ruleSearch.valueChanges
      .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
      .subscribe((evt) => this.onSearchInput(evt));

    this.assetTypeSearch.valueChanges
    .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
    .subscribe((evt) => this.onAssetTypeSearch());

    this.locationSearch.valueChanges
    .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
    .subscribe((evt) => this.onLocationSearch());

    this.shipmentSearch.valueChanges
    .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
    .subscribe((evt) => this.onShipmentSearch());
    this.userType = this.userService.isDirectUser();
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
  }

  ngOnChanges(): void {
    if (!_.isEmpty(this.ruleSetObject)) {
      if (this.newOrEdit === 'clone') {
        this.ruleSetObject.name = `${this.ruleSetObject.name} - copy`;
      }
      this.rulesetName.setValue(this.ruleSetObject.name);
      this.rulesetName.disable();
      this.rulesetOrgName.setValue(this.ruleSetObject.organization);
      const getRuleSub: Subscription = this.rulesService.getRulesetById(this.ruleSetObject.id).subscribe({
        next: (res: any) => {
          if (!_.isEmpty(res.recipeIdNames)) {
            for (let key in res.recipeIdNames) {
              this.selectedRules.push({
                id: key,
                title: res.recipeIdNames[key].recipeIdNames
              });
            }
          }
          if (!_.isEmpty(res.context)) {
            if (!_.isEmpty(res.context.categories)) {
              res.context.categories.forEach(cat => {
                this.selectedAssetTypes.push({
                  id: cat.id,
                  name: cat.name
                });
              });
            }
            if (!_.isEmpty(res.context.sites)) {
              res.context.sites.forEach(loc => {
                this.selectedLocations.push({
                  id: loc.id,
                  name: loc.name,
                  type: 'Site'
                });
              });
            }
            if (!_.isEmpty(res.context.areas)) {
              res.context.areas.forEach(loc => {
                this.selectedLocations.push({
                  id: loc.id,
                  name: loc.name,
                  type: 'WorkArea'
                });
              });
            }
            if (!_.isEmpty(res.context.shipments)) {
              res.context.shipments.forEach(shipments => {
                this.selectedShipments.push({
                  id: shipments.id,
                  name: shipments.name,
                  type: shipments.type
                });
              });
            }
          }

          if(!_.isEmpty(this.ruleSetObject.organization)){
            this.getOrgRulesList(this.ruleSetObject.organization);
            this.getLocationsList(this.ruleSetObject.organization);
            this.getShipmentsList(this.ruleSetObject.organization);
          }else{
            this.getRulesList('noOrg');
            this.getLocationsList();
            this.getShipmentsList();
          }
          
          this.getAssetTypeList();
          this.getOrgList();
          this.checkAnyObjectSelect();
          if(this.isOrgFieldDisable){
            this.rulesetOrgName.disable();
          }
        },
        error: (err) => {
          console.error(err);
        }
      })
    } else {
      this.getRulesList('noOrg');
      this.getAssetTypeList();
      this.getLocationsList();
      this.getShipmentsList();
      this.getOrgList();
    }
  }

  getRulesList(org) {
    this.rulesLoading = true;
    const rulesSub: Subscription = this.rulesService.getOrgRules(org).subscribe({
      next: (res: any) => {
        this.rulesLoading = false;
        res = res.map(item => {
          return {title: item.title, id: item.id};
        });
        this.originalAvailableRules = _.cloneDeep(res);
        this.availableRules = _.cloneDeep(res);
        this.setRuleCheckbox();
      },
      error: (err: any) => {
        this.rulesLoading = false;
        console.error(err);
      }
    })

    this.subscriptions.push(rulesSub);
  }

  getAssetTypeList() {
    this.assetTypeLoading = true;
    const assetSub: Subscription = this.assetService.getCategories("asset").subscribe({
      next: (res: any) => {
        this.assetTypeLoading = false;
        let properties = res.map(item => {
          return {
            name: item.properties.name,
            id: item.properties.id
          }
        });
        this.originalAvailableAssetTypes = _.cloneDeep(properties);
        this.availableAssetTypes = properties;

        this.setAssetTypeCheckbox();
      },
      error: (err: any) => {
        this.assetTypeLoading = false;
        console.error(err);
      }
    })
  }

  getLocationsList(org:any= '') {
    if(org === '' || org !=='ANY'){
      this.getAnyOrgLocation(org);
    }
    let payload = {
      "scrollId": "",
      "scrollSize": 100,
      "globalQueryText": "",
      "searchFieldFilter":this.searchFieldFilter,
      "searchQueries": [
        {
          "fieldName": "baseClass",
          "queryText": "Location",
          "queryOperator": "should",
          "queryType": "match",
          "childFilters": [!_.isEmpty(org)?{
            "fieldName": "organization",
            "queryText": org,
            "queryOperator": "must"
          }:{
            "fieldName": "organization",
            "queryText": "",
            "queryOperator": "exist_not"
          },{
            "fieldName": "deleted",
            "queryText": "true",
            "queryOperator": "must_not"
          },{"fieldName": "dynamicArea", "queryText": "true", "queryOperator": "must_not"},
          {"fieldName":"parentEntityTypeKey","queryText":"gatewayzone,area","queryOperator":"must"}]
        }
      ]
    };
    this.locationLoading = true;
    const locSub: Subscription = this.searchApiService.globalSearch(payload, false).subscribe({
      next: (res: any) => {
        this.locationLoading = false;
        res = res.hits;
        let response = res.map(item => {
          return {
            name: item.name,
            id: item.id,
            type: item.type
          }
        })
        if(_.isEmpty(org) || org === 'ANY'){
          if(org === 'ANY'){
            this.anyOrgLocationList = _.cloneDeep(response);
            this.originalAvailableLocations = [...this.anyOrgLocationList];
            if(org === 'ANY' && this.rulesetForm.getRawValue().rulesetOrgName === 'ANY'){
              this.availableLocations = this.originalAvailableLocations;
            }
          }else{
            this.nullOrg = response;
            this.originalAvailableLocations = [...this.anyOrgLocationList, ...this.nullOrg];
            this.availableLocations = this.originalAvailableLocations;
          }
        }else{
          let orgLocation = response;
          this.availableLocations = [];
          if(!_.isEmpty(this.anyOrgLocationList)){
            this.originalAvailableLocations = [...this.anyOrgLocationList, ...orgLocation];
            this.availableLocations = this.originalAvailableLocations;
          }else{
            this.originalAvailableLocations = _.cloneDeep(orgLocation);
            this.availableLocations = this.originalAvailableLocations;
          }
        }

        if (this.selectedLocations.length) {
          this.selectedLocations.forEach(item => {
            let index = this.availableLocations.findIndex(locItem => locItem.id === item.id);
            if (index > -1) {
              this.availableLocations[index].checked = true;
            }
          });
        }
      },
      error: (err: any) => {
        this.locationLoading = false;
        console.error(err);
      }
    });
  }

  getAnyOrgLocation(org:any){
      this.getLocationsList('ANY')
  }

  getShipmentsList(org:any= '') {
    let payload = {
      "scrollId": "",
      "scrollSize": 100,
      "globalQueryText": "",
      "searchFieldFilter":this.shipmentSearchFieldFilter,
      "searchQueries": [
        {
          "fieldName": "baseClass",
          "queryText": "Shipment",
          "queryOperator": "should",
          "queryType": "match",
          "childFilters": [!_.isEmpty(org)?{
            "fieldName": "organization",
            "queryText": org,
            "queryOperator": "must"
          }:{
            "fieldName": "organization",
            "queryText": "",
            "queryOperator": "exist_not"
          },{
            "fieldName": "deleted",
            "queryText": "true",
            "queryOperator": "must_not"
          },{"fieldName": "statusCode", "queryText": "completed", "queryOperator": "must_not"},
          {"fieldName": "statusCode", "queryText": "drafts", "queryOperator": "must_not"},
          {"fieldName": "statusCode", "queryText": "incomplete", "queryOperator": "must_not"}
        ]}
      ]
    };
    this.shipmentLoading = true;
    const locSub: Subscription = this.searchApiService.globalSearch(payload, false).subscribe({
      next: (res: any) => {
        this.shipmentLoading = false;
        res = res.hits;
        let response = res.map(item => {
          return {
            name: item.name,
            id: item.id,
            type: item.shipmentType
          }
        })
        this.originalAvailableShipments = _.cloneDeep(response);
        this.availableShipments = response;

        if (this.selectedShipments.length) {
          this.selectedShipments.forEach(item => {
            let index = this.availableShipments.findIndex(shipItem => shipItem.id === item.id);
            if (index > -1) {
              this.availableShipments[index].checked = true;
            }
          });
        }
      },
      error: (err: any) => {
        this.shipmentLoading = false;
        console.error(err);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  closePanel(parentReload?: boolean) {
    this.closeSidePanel.emit(parentReload);
  }

  onSearchInput(evt: any) {
    let searchTerm: string = this.rulesetForm.value.ruleSearchTerm;
    if (searchTerm) {
      this.availableRules = this.originalAvailableRules.filter(rule => rule.title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
    } else {
      this.availableRules = _.cloneDeep(this.originalAvailableRules);
    }

    this.setRuleCheckbox();
  }

  setRuleCheckbox() {
    if (this.selectedRules.length) {
      this.selectedRules.forEach(rule => {
        let index = this.availableRules.findIndex(item => item.id === rule.id);
        if (index > -1) {
          this.availableRules[index].checked = true;
        }
      });
    }
  }

  setLocationCheckbox() {
    if (this.selectedLocations.length) {
      this.selectedLocations.forEach(location => {
        let index = this.availableLocations.findIndex(item => item.id === location.id);
        if (index > -1) {
          this.availableLocations[index].checked = true;
        }
      });
    }
  }

  setShipmentCheckbox() {
    if (this.selectedShipments.length) {
      this.selectedShipments.forEach(shipment => {
        let index = this.availableShipments.findIndex(item => item.id === shipment.id);
        if (index > -1) {
          this.availableShipments[index].checked = true;
        }
      });
    }
  }

  setAssetTypeCheckbox() {
    if (this.selectedAssetTypes.length) {
      this.selectedAssetTypes.forEach(item => {
        let index = this.availableAssetTypes.findIndex(assetItem => assetItem.id === item.id);
        if (index > -1) {
          this.availableAssetTypes[index].checked = true;
        }
      });
    }
  }

  onAssetTypeSearch() {
    let searchTerm: string = this.rulesetForm.value.assetTypeSearchTerm;
    if (searchTerm) {
      this.availableAssetTypes = this.originalAvailableAssetTypes.filter(item => item.name.toLowerCase().indexOf(searchTerm) > -1);
    } else {
      this.availableAssetTypes = _.cloneDeep(this.originalAvailableAssetTypes);
    }
    this.setAssetTypeCheckbox();
  }

  onLocationSearch() {
    let searchTerm: string = this.rulesetForm.getRawValue().locationSearchTerm;
    this.searchFieldFilter=[]
    this.searchFieldFilter.push({
      "name":"name",
      "value":searchTerm
    })
    this.getLocationsList(this.orgId);
    this.setLocationCheckbox();
  }


  onShipmentSearch() {
    let searchTerm: string = this.rulesetForm.value.shipmentSearchTerm;
    this.shipmentSearchFieldFilter=[];
    this.shipmentSearchFieldFilter.push({
      "name":"name",
      "value":searchTerm
    })
    this.getShipmentsList(this.orgId);
    this.setShipmentCheckbox();
  }

  toggleSelection(evt: any, entity: any, entityName: string, index: number) {
    if (evt.checked) {
      if (entityName === 'rules') {
        this.selectedRules.push(entity);
        this.availableRules[index].checked = evt.checked;
      } else if (entityName === 'assetTypes') {
        this.selectedAssetTypes.push(entity);
        this.availableAssetTypes[index].checked = evt.checked;
      } else if (entityName === 'locations') {
        this.selectedLocations.push(entity);
        this.availableLocations[index].checked = evt.checked;
      } else if (entityName === 'shipments') {
        this.selectedShipments.push(entity);
        this.availableShipments[index].checked = evt.checked;
      }
    } else {
      if (entityName === 'rules') {
        this.selectedRules = this.selectedRules.filter(rule => rule.id !== entity.id);
        this.availableRules[index].checked = evt.checked;
      } else if (entityName === 'assetTypes') {
        this.selectedAssetTypes = this.selectedAssetTypes.filter(item => item.id !== entity.id);
        this.availableAssetTypes[index].checked = evt.checked;
      } else if (entityName === 'locations') {
        this.selectedLocations = this.selectedLocations.filter(item => item.id !== entity.id);
        this.availableLocations[index].checked = evt.checked;
      } else if (entityName === 'shipments') {
        this.selectedShipments = this.selectedShipments.filter(item => item.id !== entity.id);
        this.availableShipments[index].checked = evt.checked;
      }
    }
  }

  detatchRuleFromRuleset(entity, entityName) {
    const payload = {
      ruleId: entity.id,
      rulesetId: this.ruleSetObject.id
    }
    const ruleDetatchSub: Subscription = this.rulesService.detachRuleFromRuleset(payload).subscribe({
      next: res => {
        this.toastService.success(`${this.toTitleCase(RulesetContextTypes[entityName])} has been removed from Ruleset`, this.ruleSetObject.name);
        this.detatchLocal(entity, entityName);
      },
      error: err => {
        if (err && err['error']) {
          this.toastService.error(err['error']['error-message']);
        }
      }
    });
    this.subscriptions.push(ruleDetatchSub);
  }

  detatchContext(entity, entityName) {
    let entityEnum: string;
    if (entity.type) {
      entityEnum = entity.type;
    } else {
      entityEnum = entityName;
    }
    const payload = {
      objectId: entity.id,
      objectType: RulesetContextTypes[entityEnum],
      ruleSetId: this.ruleSetObject.id,
      tenantId: this.ruleSetObject.tenantId
    }
    const detatchSub: Subscription = this.rulesService.detatchContext(payload).subscribe({
      next: res => {
        this.toastService.success(`${this.toTitleCase(RulesetContextTypes[entityEnum])} has been removed from Ruleset`, this.ruleSetObject.name);
        this.detatchLocal(entity, entityName);
      },
      error: err => {
        if (err && err['error']) {
          this.toastService.error(err['error']['error-message']);
        }
      }
    });
    this.subscriptions.push(detatchSub);
  }

  detatchLocal(entity, entityName) {
    if (entityName === 'rules') {
      let checkboxesArr = this.ruleCheckboxes.toArray();
      this.selectedRules = this.selectedRules.filter(rule => rule.id !== entity.id);
      let index: number = this.availableRules.findIndex(rule => rule.id === entity.id);
      if (index > -1) {
        this.availableRules[index].checked = false;
        // checkboxesArr[index].checked = false;
      }
    } else if (entityName === 'CATEGORY') {
      let checkboxesArr = this.assetTypeCheckboxes.toArray();
      this.selectedAssetTypes = this.selectedAssetTypes.filter(item => item.id !== entity.id);
      let index: number = this.availableAssetTypes.findIndex(item => item.id === entity.id);
      if (index > -1) {
        this.availableAssetTypes[index].checked = false;
        // checkboxesArr[index].checked = false;
      }
    } else if (entityName === 'locations') {
      let checkboxesArr = this.locationCheckboxes.toArray();
      this.selectedLocations = this.selectedLocations.filter(item => item.id !== entity.id);
      let index: number = this.availableLocations.findIndex(item => item.id === entity.id);
      if (index > -1) {
        this.availableLocations[index].checked = false;
        // checkboxesArr[index].checked = false;
      }
    } else if (entityName === 'shipments') {
      let checkboxesArr = this.shipmentCheckboxes.toArray();
      this.selectedShipments = this.selectedShipments.filter(item => item.id !== entity.id);
      let index: number = this.availableShipments.findIndex(item => item.id === entity.id);
      if (index > -1) {
        this.availableShipments[index].checked = false;
        // checkboxesArr[index].checked = false;
      }
    }
    this.checkAnyObjectSelect();
  }

  removeSelected(entity: any, entityName: string) {
    if (this.newOrEdit === 'edit') {
      if (entityName === 'rules') {
        this.detatchRuleFromRuleset(entity, entityName);
      } else if(entityName === 'shipments' || entityName === 'locations'){
        this.detatchContext(entity, entityName)
      }else {
        this.isAssetTypedeletable(entity, entityName);
      }
    } else {
      this.detatchLocal(entity, entityName);
    }
  }

  constructRulesetPayload() {
    let payload: any = {};
    payload.name = this.rulesetForm.getRawValue().ruleName;
    payload.organization = this.rulesetForm.getRawValue().rulesetOrgName;
    payload.context = {};
    payload.recipeIdNames = {};
    if (this.selectedRules.length) {
      this.selectedRules.forEach(rule => {
        payload.recipeIdNames[rule.id] = {
          application: 'indoor',
          recipeIdNames: rule.title
        }
      })
    };
    if (this.selectedAssetTypes.length) {
      payload.context['categories'] = [];
      this.selectedAssetTypes.forEach(assetType => {
        payload.context['categories'].push({
          id: assetType.id,
          name: assetType.name,
          type: "categories"
        });
      });
    }
    if (this.selectedLocations.length) {
      payload.context['sites'] = [];
      payload.context['areas'] = [];
      this.selectedLocations.forEach(location => {
        if (location.type === 'WorkArea') {
          payload.context['areas'].push({
            id: location.id,
            name: location.name,
            type: "areas"
          });
        } else if (location.type === 'Site') {
          payload.context['sites'].push({
            id: location.id,
            name: location.name,
            type: "sites"
          });
        }
      });
    }

    if (this.selectedShipments.length) {
      payload.context['Parcel'] = [];
      payload.context['Standard'] = [];
      this.selectedShipments.forEach(shipment => {
        if (shipment.type === 'Parcel') {
          payload.context['Parcel'].push(shipment);
        } else {
          payload.context['Standard'].push(shipment);
        }
      });
    }

    this.ruleSetPayload = {...this.ruleSetPayload, ...payload};
  }

  createOrUpdate() {
    let name: string = this.rulesetForm.getRawValue().ruleName;
    if (!name) {
      this.toastService.error("Ruleset Name is mandatory!", "", 3000);
      return null;
    }
    this.saveInProgress = true;
    this.constructRulesetPayload();
    if (this.newOrEdit === 'edit') {
      const rulesetSub: Subscription = this.rulesService.updateRuleset(this.ruleSetPayload, this.ruleSetObject.id).subscribe({
        next: (res: any) => {
          this.ruleSetUpdated = true;
          this.toastService.success("Ruleset updated successfully!", name, 3000);
          this.saveInProgress = false;
          this.closePanel(this.ruleSetUpdated);
        },
        error: (err) => {
          this.saveInProgress = false;
          console.error(err);
        }
      })
      this.subscriptions.push(rulesetSub);
    } else {
      const rulesetSub: Subscription = this.rulesService.createRuleset(this.ruleSetPayload).subscribe({
        next: (res: any) => {
          this.toastService.success("Ruleset created successfully!", res.name, 3000);
          this.saveInProgress = false;
          this.closePanel(true);
        },
        error: (err) => {
          console.error(err);
          this.saveInProgress = false;
        }
      })
      this.subscriptions.push(rulesetSub);
    }
  }

  getOrgList(){
    this.tenantList = JSON.parse(sessionStorage.getItem('tenantList'));
    this.organizationService.getOrgList().subscribe((data: any) => {
      this.orgList = data;
      this.orgCount = this.orgList.length;
      if(this.newOrEdit == 'new' && this.orgCount>0){
        this.selectedOption = this.orgList[0].id;
        this.orgId  = this.orgList[0].id;
        this.getOrgRulesList(this.selectedOption);
        this.getLocationsList(this.selectedOption);
        this.getShipmentsList(this.selectedOption);
      }
    });
  }

  getOrgRulesList(org){
    this.rulesLoading = true;
    const rulesSub: Subscription = this.rulesService.getOrgRules(org).subscribe({
      next: (res: any) => {
        this.rulesLoading = false;
        res = res.map(item => {
          return {title: item.title, id: item.id};
        });
        this.originalAvailableRules = _.cloneDeep(res);
        this.availableRules = _.cloneDeep(res);
        this.setRuleCheckbox();
      },
      error: (err: any) => {
        this.rulesLoading = false;
        console.error(err);
      }
    })

    this.subscriptions.push(rulesSub);

  }

  _fnGetSelectedOrg(){
    this.orgId = this.rulesetForm.value.rulesetOrgName;
    this.selectedRules = [];
    this.selectedLocations = [];
    this.selectedShipments = [];
    this.selectedAssetTypes = [];
    this.rulesetForm.get('ruleSearchTerm').setValue('');
    this.rulesetForm.get('locationSearchTerm').setValue('');
    this.rulesetForm.get('shipmentSearchTerm').setValue('');
    this.rulesetForm.get('assetTypeSearchTerm').setValue('');
    this.getOrgRulesList(this.orgId);
    this. getLocationsList(this.orgId);
    this.getShipmentsList(this.orgId);
    this.setRuleCheckbox();
  }

  onOrgclose(){
    this.selectedOption = '';
    this.rulesetForm.get('rulesetOrgName').setValue('');
    this.rulesetForm.get('ruleSearchTerm').setValue('');
    this.rulesetForm.get('locationSearchTerm').setValue('');
    this.rulesetForm.get('shipmentSearchTerm').setValue('');
    this.rulesetForm.get('assetTypeSearchTerm').setValue('');
    this.orgId = '';
    this.selectedRules = [];
    this.selectedLocations = [];
    this.selectedShipments = [];
    this.selectedAssetTypes = [];
    this.getRulesList('noOrg');
    this.showRulesDropdown = !this.showRulesDropdown
    this.setRuleCheckbox();
    this.setLocationCheckbox();
    this.setShipmentCheckbox();  
    this.getLocationsList();
    this.getShipmentsList();
  }

  isAssetTypedeletable(entity, entityName){
    const payload = {
      objectId: entity.id,
      rulesetId: this.ruleSetObject.id,
      tenantId: this.ruleSetObject.tenantId,
      objectType:entityName
    }
    this.rulesService.getAssetTypeDeleteValidation(payload).subscribe({
      next:(res:any) => {
        this.toastService.success(`AssetType has been removed from Ruleset`, this.ruleSetObject.name);
        this.detatchLocal(entity, entityName);
      },
      error: err => {
        if (err && err['error']) {
          this.toastService.error(err['error']['error-message']);
        }
      }
    })
  }

  onSearchCancel(formname){
    switch(formname) { 
      case "assetTypeSearchTerm": { 
        this.rulesetForm.get(formname).setValue('');
        this.showAssetTypesDropdown = !this.showAssetTypesDropdown;
         break; 
      } 
      case "ruleSearchTerm": { 
        this.rulesetForm.get(formname).setValue('');
        this.showRulesDropdown = !this.showRulesDropdown;
         break; 
      }
      case "shipmentSearchTerm": { 
        this.rulesetForm.get(formname).setValue('');
        this.showShipmentsDropdown = !this.showShipmentsDropdown;
         break; 
      }
      case "locationSearchTerm": { 
        this.rulesetForm.get(formname).setValue('');
        this.showLocationsDropdown = !this.showLocationsDropdown;
         break; 
      } 
      default: { 
        break;              
     }
    }
  }

 toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  checkAnyObjectSelect(){
    if(this.selectedAssetTypes.length>0 || this.selectedLocations.length>0 || this.selectedShipments.length>0
      || this.selectedRules.length>0){
        this.isOrgFieldDisable = true;
      }else{
        this.isOrgFieldDisable = false;
        this.rulesetOrgName.enable();
      }
  }
}
