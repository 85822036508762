<div class="cl-asset-summary">
  <div class="card pl-1">
    <div class="image-container">
      <img [src]="assetObject?.imageURL?assetObject?.imageURL:'assets/svgs/asset.svg'" class="image" />
    </div>
   
    <div class="asset-summary"> 
        <!-- FIXME: Need to render these fields are dynamic as per CDM defination.  -->
      <div class="overflow-y-auto overflow-x-hidden max-h-[360px] fields-grid">
      
          <div class=" font-bold" style="word-break:break-word">Name</div>
          <div class=" ellipsis" style="word-break:break-word" [title]="assetObject?.properties?.name">{{assetObject?.properties?.name}}</div>
      
          <div class=" font-bold"style="word-break:break-word">Asset ID</div>
          <div class=" ellipsis"style="word-break:break-word" [title]="assetObject?.externalId">{{assetObject?.externalId}}</div>
      
          <div class=" font-bold"style="word-break:break-word">Asset Type</div>
          <div  style="word-break:break-word">{{assetObject?.categoryName}}</div>
      
          <div class=" font-bold"style="word-break:break-word">Catalog Type</div>
          <div style="word-break:break-word">{{assetObject?.properties?.parentEntityTypeKey | titlecase}}</div>
      
        <ng-container  *ngIf="assetObject?.properties?.ruleSetName">
          <div class=" font-bold"style="word-break:break-word">RuleSet Name</div>
          <div class="" style="word-break:break-word">{{assetObject?.properties?.ruleSetName}}</div>
        </ng-container>
      
          <div class="font-bold"style="word-break:break-word">Monitored Status</div>
          <div style="word-break:break-word">{{assetObject?.monitoredStatus}}</div>
    
          <div class="font-bold"style="word-break:break-word">Status</div>
          <div style="word-break:break-word">{{assetObject?.status}}</div>
       
        <ng-container *ngIf="assetObject?.location">
          <div class="font-bold"style="word-break:break-word">Location</div>
          <!-- <div *ngIf="assetObject?.monitoredStatus?.toLowerCase() === 'monitored' && assetCurrentLocation?.length>0" style="word-break:break-word">Lat: {{assetCurrentLocation[assetCurrentLocation.length - 1].lat}} , Lon: {{assetCurrentLocation[assetCurrentLocation.length - 1].lng}}</div> -->
          <div *ngIf="assetObject?.monitoredStatus?.toLowerCase() != 'monitored'" style="word-break:break-word">{{assetObject?.location}}</div>
          <div *ngIf="assetObject?.monitoredStatus?.toLowerCase() === 'monitored'" style="word-break:break-word">{{assetObject?.location}}</div>
        </ng-container>        
         
        <ng-container
          *ngIf="assetObject?.monitoredStatus?.toLowerCase() === 'monitored' && assetObject?.location === 'Out Of Coverage' && assetObject?.lastLocation">
          <div class=" font-bold"style="word-break:break-word">Last Location</div>
          <div class="" style="word-break:break-word">{{assetObject?.lastLocation}}</div>
        </ng-container>
          <div class=" font-bold"style="word-break:break-word">Device ID</div>
          <div>
          <div class=""style="word-break:break-word" *ngIf="(assetObject?.properties?.eventPublisherMap | keyvalue)?.length > 0">
            <span *ngFor="let sensor of assetObject?.properties?.eventPublisherMap | keyvalue ;let i=index; let last = last">
              <span *ngIf="!assetObject.isSensorNotHavingLink" [routerLink]="'/sensordetails/' + sensor.key">
                <a href="javascript:void(0)" style="word-break: break-word;">{{sensor.key}}  <ng-container *ngIf="sensor.value?.['componentName']?.trim()">({{sensor.value?.['componentName']?.trim()}})</ng-container>
                </a>
              </span>
              <span *ngIf="assetObject.isSensorNotHavingLink">{{sensor.key}}
                <ng-container *ngIf="sensor.value?.['componentName']?.trim()">({{sensor.value?.['componentName']?.trim()}})</ng-container>
              </span>
              <span *ngIf="!last">, </span>
            </span>
          </div>
        </div>
        <ng-container  *ngIf="assetObject?.shipment">
          <div class=" font-bold"style="word-break:break-word">Active Shipment</div>
          <div class=" ellipsis" style="word-break:break-word">
            <a href="#/shipmentdetails/{{assetObject?.shipment?.id}}?type={{assetObject?.shipment?.type}}" [title]="assetObject?.shipment?.name">{{assetObject?.shipment?.name}}</a>
          </div>
        </ng-container>
        <ng-container *ngIf="assetObject?.sensorBindedDate">
          <div class=" font-bold"style="word-break:break-word">Bound</div>
          <div  style="word-break:break-word"[textContent]="datePipe.transform(assetObject?.sensorBindedDate)"></div>
        </ng-container>
          <!-- <div class=" font-bold">Organization</div>
          <div  style="word-break:break-word">{{assetObject?.properties?.organizationName}}</div>
        
          <div class=" font-bold">QR/Bar code</div>
          <div  style="word-break:break-word">{{assetObject?.qrBarCode}}</div>
        <ng-container *ngIf="assetObject?.sku">
          <div class=" font-bold">SKU</div>
          <div  style="word-break:break-word">{{assetObject?.sku}}</div>
        </ng-container>
        <ng-container  *ngIf="isCustodyAsset">
          <div class=" font-bold">Item Category</div>
          <div  style="word-break:break-word">{{assetObject?.itemCategory}}</div>
        </ng-container>  
        <ng-container *ngIf="isCustodyAsset">
          <div class=" font-bold">Cost</div>
          <div class="" style="word-break:break-word">{{assetObject?.cost}}</div>
        
          <div class=" font-bold">Condition</div>
          <div  style="word-break:break-word">{{assetObject?.condition}}</div>
       
          <div class=" font-bold">Lifecycle Status</div>
          <div  style="word-break:break-word">{{assetObject?.lifecycleStatus}}</div>
       
          <div class=" font-bold">Checked Out By</div>
          <div  style="word-break:break-word">{{assetObject?.checkedoutBy}}</div>
        
          <div class=" font-bold">Checked Out To</div>
          <div  style="word-break:break-word">{{assetObject?.checkedoutToUserId}}</div>
        
          <div class=" font-bold">Checked Out To User</div>
          <div  style="word-break:break-word">{{assetObject?.checkedoutTo}}</div>
       
          <div class=" font-bold">Checked Out At</div>
          <div  style="word-break:break-word">{{datePipe.transform(assetObject?.checkedoutAt)}}</div>
       
          <div class=" font-bold">From Party</div>
          <div style="word-break:break-word">{{assetObject?.fromParty}}</div>
      
      
          <div class=" font-bold">To Party</div>
          <div  style="word-break:break-word">{{assetObject?.toParty}}</div>
       
          <div class=" font-bold">Custody Audit Id</div>
          <div  style="word-break:break-word">{{assetObject?.custodyAuditId}}</div>
       
          <div class=" font-bold">Custody Audit Version</div>
          <div  style="word-break:break-word">{{assetObject?.custodyAuditVersion}}</div>
        
          <div class=" font-bold">Custody Audit Status</div>
          <div  style="word-break:break-word">{{assetObject?.custodyAuditStatus}}</div>
        
          <div class=" font-bold">Custody Audit By</div>
          <div  style="word-break:break-word">{{assetObject?.custodyAuditBy}}</div>
        
          <div class=" font-bold">Custody Audit Pending</div>
          <div  style="word-break:break-word">{{assetObject?.custodyAuditPending}}</div>

          <div class=" font-bold">Custody Audit Initiated At</div>
          <div  style="word-break:break-word">{{assetObject?.custodyAuditInitiatedAt | date: 'MM/dd/yyyy hh:mm:ss a'}}</div>
        
          <div class=" font-bold">Custody Audit Begun At</div>
          <div c style="word-break:break-word">{{assetObject?.custodyAuditBegunAt | date: 'MM/dd/yyyy hh:mm:ss a'}}</div>
          
          <div class=" font-bold">Custody Audit Completed At</div>
          <div  style="word-break:break-word">{{assetObject?.custodyAuditCompletedAt | date: 'MM/dd/yyyy hh:mm:ss a'}}</div>
       
          <div class=" font-bold">Last Custody Audited Id</div>
          <div  style="word-break:break-word">{{assetObject?.lastCustodyAuditId}}</div>
        
          <div class=" font-bold">Last Custody Audited By</div>
          <div  style="word-break:break-word">{{assetObject?.lastCustodyAuditBy}}</div>
        
          <div class=" font-bold">Last Custody Audited At</div>
          <div  style="word-break:break-word">{{datePipe.transform(assetObject?.lastCustodyAuditAt)}}</div>
        
          <div class=" font-bold">Last Custody Update At</div>
          <div  style="word-break:break-word">{{datePipe.transform(assetObject?.lastCustodyStatusUpdateAt)}}</div>
        
          <div class=" font-bold">Manufacturer</div>
          <div style="word-break:break-word">{{assetObject?.manufacturer}}</div>
        
          <div class=" font-bold">Storage Location</div>
          <div  style="word-break:break-word">{{assetObject?.warehouseLocation}}</div>
       
          <div class=" font-bold">Maintenance Date</div>
          <div  style="word-break:break-word">{{datePipe.transform(assetObject?.maintenanceDate)}}</div>
     
       </ng-container> -->
          <ng-container *ngFor="let cdmField of systemFields">
            <ng-container>
              <div class="font-bold"style="word-break:break-word" [textContent]="cdmField?.displayLabel"></div>
              <div  style="word-break:break-word"[textContent]="getExtendedAttributeValue(cdmField, assetObject?.properties)"></div>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let cdmField of userFields">
            <ng-container>
              <div class="font-bold"style="word-break:break-word" [textContent]="cdmField?.displayLabel"></div>
              <div  style="word-break:break-word"[textContent]="getExtendedAttributeValue(cdmField, assetObject?.properties)"></div>
            </ng-container>
          </ng-container>
      
          <div class=" font-bold"style="word-break:break-word">Created At</div>
          <div  style="word-break:break-word">{{datePipe.transform(assetObject?.createdAt)}}</div>
     
          <div class=" font-bold"style="word-break:break-word">Created By</div>
          <div style="word-break:break-word">{{assetObject?.createdBy}}</div>
      
          <div class=" font-bold"style="word-break:break-word">Modified At</div>
          <div  style="word-break:break-word">{{datePipe.transform(assetObject?.modifiedAt)}}</div>
       
          <div class=" font-bold"style="word-break:break-word">Modified By</div>
          <div  style="word-break:break-word">{{assetObject?.modifiedBy}}</div>
       
       </div>
    </div>
  </div>
</div>
