<div class="mb-4 -mt-1"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>
<div class="details-page-wrapper location-details-wrapper">
  <div class="flex justify-between">
    <div class="mt-2 mb-2"><span class="page-title">{{routeDeatils?.summary?.properties?.name}}</span></div>
    <div>
      <cl-recipe-actions [entityTypeId]="routeDeatils?.summary?.properties.entityTypeId"  [entityId]="routeDeatils?.summary?.properties.id" [parentEntityType]="routeDeatils?.summary?.properties.parentEntityTypeKey" ></cl-recipe-actions>
    </div>
  </div>
  <div class="details-header-wrapper">
    <div class="flex items-stretch flex-wrap">
      <div class="details-flex-card bgcolor2 ml0 mr10 overflow-scroll">
        <div class="cl-location-summary">
          <!--<div class="flex mt5">
                <div class="basis-1/5 font-bold">Weather</div>
                <div class="grow"> Temperature: 8˚C; chance of rain 3% </div>
              </div>-->
              <ng-container *ngFor="let cdmField of attributes | sortByOrder">
                <div *ngIf="cdmField?.isDisplayableOnDetails && getExtendedAttributeValue(cdmField, routeDeatils.summary?.properties)">
                  <div class="flex mt5" *ngIf="!customFields.includes(cdmField.name) && !modifiedFields.includes(cdmField.name)">
                    <div class="basis-1/5 font-bold" [textContent]="cdmField?.displayLabel"></div>
                    <div class="grow" [textContent]="getExtendedAttributeValue(cdmField, routeDeatils.summary?.properties)"></div>
                  </div>
                  <div class="flex mt5" *ngIf="cdmField.name=='name'">
                    <div class="basis-1/5 font-bold">Route Name</div>
                    <div class="grow">{{routeDeatils?.summary.properties.name}}</div>
                  </div>
                  <div class="flex mt5" *ngIf="cdmField.name=='shipments'">
                    <div class="basis-1/5 font-bold">Total Connected Shipments</div>
                    <div class="grow"> {{routeDeatils?.connectedShipments.nodes.length}} </div>
                  </div>
                  <div class="flex mt5" *ngIf="cdmField.name=='shipments'">
                    <div class="basis-1/5 font-bold">Connected Areas</div>
                    <div class="grow">{{routeDeatils?.connectedAreas.nodes.length}}
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="flex mt5" *ngIf="routeDeatils.summary?.properties?.createdAt">
                <div class="basis-1/5 font-bold">Created At</div>
                <div class="grow " ><span class="cursor-pointer"> {{datePipe.transform(routeDeatils.summary?.properties?.createdAt)}} </span></div>
              </div>
              <div class="flex mt5" *ngIf="routeDeatils.summary?.properties?.createdBy">
                <div class="basis-1/5 font-bold">Created By</div>
                <div class="grow " ><span class="cursor-pointer"> {{routeDeatils.summary?.properties?.createdBy}} </span></div>
              </div>
              <div class="flex mt5" *ngIf="routeDeatils.summary?.properties?.modifiedAt">
                <div class="basis-1/5 font-bold">Modified At</div>
                <div class="grow " ><span class="cursor-pointer"> {{datePipe.transform(routeDeatils.summary?.properties?.modifiedAt)}} </span></div>
              </div>
              <div class="flex mt5" *ngIf="routeDeatils.summary?.properties?.modifiedBy">
                <div class="basis-1/5 font-bold">Modified By</div>
                <div class="grow " ><span class="cursor-pointer"> {{routeDeatils.summary?.properties?.modifiedBy}} </span></div>
              </div>
        </div>
      </div>
      <div class="details-flex-card bgcolor2 mr0 ml10">
        <div *ngIf="showGraph">
         <div class="flex">
          <label class="category-dist-label font-bold">Shipment Distribution</label>
          <div class="asset-distibution select-value">
          <mat-select class="basic-input" [(ngModel)]="SelectedType" (selectionChange)="renderChartData($event.value)">
            <mat-option *ngFor="let c of chart | keyvalue" [value]="c.key">
              {{c.key}}
            </mat-option>
          </mat-select>
          </div>
         </div>        
         <cl-bar-chart [chartData]="chartData" [chartXData]="chartXData" [label]="''"></cl-bar-chart>
        </div>
   
        <!-- <div *ngIf="!loading && !showGraph" class="h-full flex items-center justify-center">No Data Available</div> -->
      </div>

    </div>
  </div>

  <div class="details-header-wrapper mt20">
    <div class="flex items-stretch flex-wrap">
      <div class="details-flex-card bgcolor2 ml0 mr0">
        <div class="flex justify-between" *ngIf="!loading && assetDist.length">
          <div class="action-panel-left self-start flex items-center color4">
            <span class="font-bold fs16 mr-2 color35">Shipments</span>
            <span (click)="toggleFilterPanel()" class="cursor-pointer flex items-center mr-2">
              <img class="filter-img mr-2 ml-2" src="assets/svgs/filter-options.svg" />Filter
              options</span>
            <form [formGroup]="searchFormGroup" novalidate>
              <cl-list-search [placeholder]="'Search by Name'" (search)="updateFilter($event)"
                [columns] = "['Name']"></cl-list-search>
            </form>
          </div>
          <div class="action-panel-right self-end asset-listing__action-panel-right relative">
            <cl-export-panel class="action-separator" (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)" [page]="'route'">
            </cl-grid-column-toggle>
          </div>
        </div>
        <div class="min-h-[150px]" [ngClass]="{'flex items-center justify-center': loading}">
          <div *ngIf="!loading && assetDist.length">
            <div class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth">
              <div class="grow-0" *ngIf="showFilterPanel">
                <cl-list-filter class="nav panel scroll-y grow-0" [hideTitle]="true" [showCounts]="true" [title]="''"
                  [fullHeight]="true" (panelsEmitter)="getPanelUpdate($event)" (toggleFilterPanel)="toggleFilterPanel()"
                  heightClass="none" [filters]="filters">
                </cl-list-filter>
              </div>
              <div class="grid-container grow relative">
                <div class="h-100 px-2" [style.width.px]="tableWidth">
                  <ngx-datatable class="h300" #table [rows]="assetDist" [columns]="columns" [columnMode]="'force'"
                    [loadingIndicator]="loading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                    [scrollbarV]="true" 
                    [sortType]="sortType" [selectionType]="selection"[reorderable]="false">
                  </ngx-datatable>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading && !assetDist.length" class="h-100 flex items-center justify-center">No Data Available
          </div>
          <div *ngIf="loading" class="h-100 flex items-center justify-center">Loading location info..</div>
        </div>
      </div>
    </div>
    <div class="mt20 rounded bgcolor2" *ngIf="!loading" >
      <div class="ht1 p10">Planned Route</div>
      <agm-map [fullscreenControl]='true'  [latitude]="lat" [longitude]="lng"
        [streetViewControl]="false" [zoomControl]="true" [fullscreenControlOptions]="mapOptions.positionOfActions"
        [zoomControlOptions]="mapOptions.positionOfActions" [styles]="mapOptions.styles" [mapTypeControl]='false'
        [minZoom]="2" [maxZoom]="20" [zoom]="20" [fitBounds]="true" *ngIf="!loading">
        <agm-marker *ngFor="let coordinate of actualRoute" [latitude]="coordinate.lat" [longitude]="coordinate.lng"
        [iconUrl]="coordinate.iconUrl" [agmFitBounds]="true">
        <agm-info-window>
            <div class="agm-info-window">
                <p><strong class="bold">Latitude : </strong> {{coordinate.lat}}</p>
                <p><strong class="bold">Longitude: </strong>{{coordinate.lng}}</p>
            </div>
        </agm-info-window>
        <agm-direction
        *ngFor="let dir of routeDirections"
        [visible]="dir.visible"
        [renderOptions]="options"
        [origin]="dir.origin"
        [destination]="dir.destination"
    >
    </agm-direction>
    <agm-polyline  *ngFor="let dir of lineDirections" [geodesic]="dir.travelMode=='Air'"  [strokeWeight]="3" [strokeColor]="'#0099dc'">
      <agm-polyline-point  [latitude]="dir.origin.lat" [longitude]="dir.origin.lng">
      </agm-polyline-point>
      <agm-polyline-point  [latitude]="dir.destination.lat" [longitude]="dir.destination.lng">
      </agm-polyline-point>
    </agm-polyline>
    </agm-marker>

      </agm-map>
    </div>
  </div>

  <ng-template #locationAssetTemplate let-row="row" let-value="value" let-i="index">
    <a class="font-12-bold" href="#/assetdetails/{{row.id}}">{{value}}</a>
  </ng-template>
</div>
