import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME, CL_INPUT_DEBOUNCE_TIME_HIGH } from "@cl/constants";
import { Entity, EsGeoBoundRequest, NavigatorAggApi, NavigatorConfig, SearchQueryRequest } from "@cl/models";
import { LvShowPanel, LvTogglePanel } from "@cl/ngxs/actions";
import { LvPanelsState, LvPanelsStateModel } from "@cl/ngxs/state";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import { PanelStatesService } from '../../utils/panel-states.service';
import { SearchApiService } from "../../services/search-api.service";
import _ from "lodash";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, tap } from "rxjs/operators";
import { NavigatorMapService } from "../../../navigator/service/navigator-map.service";
import { NavigatorUpdateFilterEsQuery } from "../../../navigator/state/navigator.actions";
import { NavigatorState } from "../../../navigator/state/navigator.state";
import { NavigatorStateModel } from "../../../navigator/state/navigator.state.model";

@UntilDestroy()
@Component({
  selector: "app-right-list",
  templateUrl: "./right-list.component.html",
  styleUrls: ["./right-list.component.scss"],
})
export class RightListComponent implements OnInit, AfterViewInit {
  @Select(NavigatorState) navigator$: Observable<NavigatorStateModel>;
  navigatorState: NavigatorStateModel;

  @Select(LvPanelsState) lvPanels$: Observable<LvPanelsStateModel>;
  p: LvPanelsStateModel;

  private navigatorConfig$ = new Subject<NavigatorConfig>();
  private geoBounds$ = new Subject<EsGeoBoundRequest>();
  private loadMore$ = new BehaviorSubject<boolean>(false);
  private rightList$ = new Subject<boolean>();

  searchFormGroup: FormGroup;

  private get fcSearchTerm() {
    return this.searchFormGroup.get("searchTerm");
  }

  @Output() selected = new EventEmitter<Entity>();

  // @Select(LiveMapState) lvState$: Observable<string[]>;
  @Input() activeEntity: Entity;
  @Output() searchEmitter: EventEmitter<any> = new EventEmitter();
  searchTerm = "";
  searchFields = [];

  searchBounds = {
    topLeftLat: String,
    topLeftLon: String,
    bottomRightLat: String,
    bottomRightLon: String,
  };

  sortReverse = false;
  searchInput = new FormControl();
  itemsInView;
  allLoaded = false;
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport;
  searchList = [];
  searchListcopy = [];
  scrollId = "";
  loading = false;
  currentAction;
  itemsPerPage = 18;
  itemHeight = 25;
  batch = 20;
  totalLoaded = 0;
  totalHits = 0;
  theEnd = false;
  offset = new BehaviorSubject(null);
  infinite: Observable<any[]>;
  pageFromScroll$;
  pageFromSearch$;
  pageFromResize$;
  // ccSettings$;
  showLinkIcons = false;
  nodeSearchTerm = "";
  assetList = [];
  monitoredAssetsOnly = false;
  navSearchQueries = [];
  private readonly destroyed$ = new Subject<boolean>();

  lv: any;

  constructor(
    private actions$: Actions,
    private store: Store,
    private _searchApiService: SearchApiService,
    private _panelStatesService: PanelStatesService,
    private navigatorMapService: NavigatorMapService
  ) {
    // this.ccSettings$ = this.store.select((state) => state.client_config.settings);

    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
  }
  ngOnInit() {
    this.subscribeNavigatorStateChange();
    this.subscribePanelsStateChange();
    this.subscribeToMapChange();
    // this.watchCCState();
    // this.watchLVState();
    // this.addSearchInputHandler();
  }

  ngAfterViewInit(): void {}

  // private watchCCState() {
  //   this.ccSettings$.pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME)).subscribe((cc: any) => {
  //     if (cc) {
  //       this.showLinkIcons = cc.features && cc.features.showLinkIcons;
  //       this.monitoredAssetsOnly = !cc.liveMap.showAssetsAsLocationCounts ? true : false;
  //     }
  //   });
  // }

  private subscribeNavigatorStateChange() {
    this.navigator$.pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME)).subscribe((state) => {
      this.navigatorState = state;

      if (state.navigatorConfig) this.navigatorConfig$.next(state.navigatorConfig);
      if (state?.mapDetails?.bounds) this.geoBounds$.next(state.mapDetails.bounds);
    });
  }

  private subscribePanelsStateChange() {
    this.lvPanels$.pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME)).subscribe((p) => {
      if (this.p?.rightList !== p?.rightList && !p?.rightList) {
        this.resetSearch();
      }

      if (p.rightList && !this.p.rightList) {
        this.patchGlobalSearchKeyword();
      }

      this.p = { ...p };

      this.rightList$.next(this.p.rightList);

      this.setItemsInView();
    });
  }

  private subscribeToMapChange() {
    const rightList$ = this.rightList$.pipe(distinctUntilChanged());
    // tap((_) => console.log("rightList$")));

    let reloadList = true;
    let loadedBy : 'FILTER' | 'LOAD_MORE' | 'INTERNAL_SEARCH';

    const filterChange$ = this.actions$.pipe(
      ofActionSuccessful(NavigatorUpdateFilterEsQuery),
      startWith(this.navigatorState?.filterEsQuery),
      debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH),
      tap((_) => {
        loadedBy = 'FILTER';
        reloadList = true;
      }),
      // tap((_) => console.log("filterChange$"))
    );

    const loadMore$ = this.loadMore$.pipe(
      tap((val) => {
        reloadList = !val;
        loadedBy = 'LOAD_MORE';
      })
      // tap((_) => console.log("loadMore$"))
    );

    const searchTerm$ = this.fcSearchTerm.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(CL_INPUT_DEBOUNCE_TIME),
      distinctUntilChanged(),
      tap((_) => {
        this.resetSearch(false);
        loadedBy = 'INTERNAL_SEARCH';
      })
      // tap((_) => console.log("searchTerm$"))
    );

    combineLatest([filterChange$, searchTerm$, loadMore$, rightList$])
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),

        // Trigger below events only if right list is shown
        filter((_) => this.p?.rightList),
        // tap((_) => console.log("loadingRightList$")),

        switchMap(([esFilter, searchTerm, loadMore]) => {
          const navigatorConfig = this.navigatorState.navigatorConfig;
          const filterEsQuery = _.cloneDeep(this.navigatorState.filterEsQuery);
          // filterEsQuery.globalQueryText = searchTerm;
          // if(!_.isEmpty(searchTerm)){
          //  filterEsQuery.globalQueryText = searchTerm;
          // }
          // else{
          //  filterEsQuery.globalQueryText = filterEsQuery.globalQueryText;
          // }
          
          if(!this.searchListcopy.length)
          this.searchListcopy = _.cloneDeep(this.searchList);
          if(loadedBy != 'INTERNAL_SEARCH' || searchTerm == '') {
            return this.loadNodes(navigatorConfig?.initialData, filterEsQuery, reloadList).pipe(map((res) => {
              if(searchTerm){
                if(!this.searchListcopy.length)
                this.searchListcopy = _.cloneDeep(this.searchList);
                this.loadNodesLocally(searchTerm)
              }
              return res;
            }))
          } else {
            return this.loadNodesLocally(searchTerm);
        }
        })
      )
      .subscribe();
  }

  private loadNodesLocally(searchTerm) {
    this.searchListcopy = [...this.searchListcopy];
    let list = this.searchListcopy.filter(data => {
      return data.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    })
    list = [...list];
    this.searchList = list;
    this.totalHits = this.searchListcopy.length;
    this.totalLoaded = list?.length;
    this.allLoaded = true;
    return this.searchList;
  }

  private loadNodes(searchApiDetails: NavigatorAggApi, payload: SearchQueryRequest, reload: boolean = true) {
    payload.scrollId = reload ? "" : this.scrollId;
    payload.scrollSize = 50;

    this.loading = true;

    return this.navigatorMapService.mapApi(searchApiDetails, payload).pipe(
      tap((res) => {
        if (reload) this.searchList = [];
        this.searchListcopy = [];
        this.searchList = this.searchList.concat(res.hits);
        this.scrollId = res._scroll_id;
        this.totalHits = res.totalHits;
        this.totalLoaded = this.searchList.length;
        this.allLoaded = !!(this.searchList.length >= res.totalHits);
      }),
      finalize(() => (this.loading = false))
    );
  }

  listScrolled(numShown) {
    if (this.loading || this.allLoaded) return;

    if (numShown > this.totalLoaded - 30) {
      this.loadMore$.next(true);
    }
  }

  getLoadMoreUpdate(message) {
    if (this.loading || this.allLoaded) return;
    this.loadMore$.next(true);
  }

  /**
   * Resets the search list
   */
  private resetSearch(resetSearch = true) {
    this.scrollId = "";
    this.assetList = [];
    this.totalLoaded = 0;
    this.totalHits = 0;
    this.allLoaded = false;

    if (resetSearch) {
      this.searchList = [];
      this.searchListcopy = [];
      this.fcSearchTerm.reset("");
    }
  }

  setPanels() {
    this.p = this._panelStatesService.getPanelPositions(this.p);
    this.setItemsInView();
  }

  onResize(event) {
    this.setPanels();
  }

  setItemsInView() {
    this.itemsInView = (this.p.panelHeight - 60) / 24;
  }

  togglePanel(panelName) {
    this.store.dispatch(new LvTogglePanel("rightList"));
  }

  showPanel(panel) {
    this.store.dispatch(new LvShowPanel("rightList", "rightList"));
    this.patchGlobalSearchKeyword();
  }

  private patchGlobalSearchKeyword() {
    // Initialize the search keyword with the search term in the global filters
    // const navigatorStateSnapshot = this.store.selectSnapshot<NavigatorStateModel>(NavigatorState);
    // const initialSearchKeyword = navigatorStateSnapshot?.filterEsQuery?.globalQueryText ?? "";
    // this.fcSearchTerm.patchValue(initialSearchKeyword);
  }

  selectListItem(item: Entity) {
    this.selected.emit(item);
    // this.searchEmitter.emit({ action: "selectListItem", item: item });
  }

  // watchLVState() {
  //   this.lvState$.pipe(takeUntil(this.destroyed$)).subscribe((lv: any) => {
  //     this.lv = { ...lv };
  //     this.currentAction = lv.currentAction;

  //     // update right list search term to main search term when it changes
  //     if (lv.nodeSearchTerm !== this.nodeSearchTerm) {
  //       this.searchTerm = lv.nodeSearchTerm;
  //     }
  //     this.nodeSearchTerm = lv.nodeSearchTerm;
  //     this.navSearchQueries = _.cloneDeep(lv.navigatorSearchQueries);
  //     if (lv.currentAction === "updateSearchBounds" && !_.isEqual(this.searchBounds, lv.searchBounds)) {
  //       this.searchBounds = { ...lv.searchBounds };
  //       this.resetSearch();
  //       this.getElasticBatch(true, lv.currentAction);
  //     }

  //     if (lv.currentAction === "getCounts") {
  //       this.resetSearch();
  //       this.getElasticBatch(true, lv.currentAction);
  //     }
  //   });
  // }
  // sortAlpha() {
  //   if (!this.lv.sortReverse) {
  //     this.searchList.sort((b, a) => a.name.localeCompare(b.name));
  //   } else {
  //     this.searchList.sort((a, b) => a.name.localeCompare(b.name));
  //   }
  //   this.searchList = [...this.searchList];
  //   this.store.dispatch(new LvToggleSortOrder());
  // }

  // addSearchInputHandler() {
  //   this.searchFormGroup.valueChanges
  //     .pipe(debounceTime(CL_INPUT_DEBOUNCE_TIME), distinctUntilChanged(), untilDestroyed(this))
  //     .subscribe((value: any) => {
  //       this.searchTerm = value.searchTerm;
  //       this.resetSearch();
  //       this.getElasticBatch(true, "searchInput");
  //     });
  // }

  // getElasticBatch(isNewList, called_from) {
  //   if (!this.p.rightList) {
  //     // console.warn('panel not open, skip search');
  //     return;
  //   }
  //   this.loading = true;
  //   // console.log('getElasticBatch', called_from);
  //   let params: any = {
  //     scrollId: this.scrollId,
  //     scrollSize: 50,
  //     globalQueryText: this.searchTerm,
  //     searchQueries: this.navSearchQueries,
  //   };
  //   // console.log('act', this.lv.currentAction)
  //   if (this.lv.currentAction === "updateSearchBounds") {
  //     params = {
  //       ...params,
  //       topLeftLat: this.searchBounds.topLeftLat,
  //       topLeftLon: this.searchBounds.topLeftLon,
  //       bottomRightLat: this.searchBounds.bottomRightLat,
  //       bottomRightLon: this.searchBounds.bottomRightLon,
  //     };
  //   }
  //   return this._searchApiService.elasticSearchWithBounds(params).subscribe((res) => {
  //     // console.log("got Batch?", params, res, res.hits.length, res.totalHits);
  //     this.loading = false;
  //     if (!res) {
  //       console.warn("No es response");
  //       return;
  //     }
  //     if (isNewList) {
  //       this.assetList = [...res.hits];
  //     } else {
  //       this.assetList = [...this.assetList, ...res.hits];
  //     }
  //     if (this.lv.sortReverse) {
  //       this.assetList = this.assetList.sort((b, a) => a.name.localeCompare(b.name));
  //     } else {
  //       this.assetList = this.assetList.sort((a, b) => a.name.localeCompare(b.name));
  //     }
  //     this.scrollId = res._scroll_id;
  //     this.totalHits = res.totalHits;
  //     this.searchList = this.uniqueList(this.assetList);
  //     this.totalLoaded = this.searchList.length;
  //     this.allLoaded = this.searchList.length >= res.totalHits ? true : false;
  //   });
  // }
  // uniqueList(list) {
  //   var exists = {};
  //   return list.filter((item) => {
  //     return exists.hasOwnProperty(item.uid) ? false : (exists[item.uid] = true);
  //   });
  // }

  // selectShipment(event, item) {
  //   event.stopPropagation();
  //   this.searchEmitter.emit({ action: "selectShipment", item: item });
  // }
  // selectLocation(event, item) {
  //   event.stopPropagation();
  //   this.searchEmitter.emit({ action: "selectLocation", item: item });
  // }
  // expandNode(event, item) {
  //   event.stopPropagation();
  //   this.searchEmitter.emit({ action: "expandNode", item: item });
  // }

  // itemHovered(item, isHovered) {
  //   this.searchEmitter.emit({ action: "footerNodeHovered", item: item });
  // }
  // isSelected(item) {
  //   let activeId = item.id || item.uid;
  //   return this.lv.activeEntity && activeId && (activeId === this.lv.activeEntity.uid || activeId === this.lv.activeEntity.id)
  //     ? "selected"
  //     : "";
  // }
}
