import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/common/constants';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, Observable, of, Subject, Subscription, takeUntil } from 'rxjs';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { RulesService } from '../rules.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import _ from 'lodash';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { UserService } from '@cl/user/user.service';
@Component({
  selector: 'cl-ruleset-list',
  templateUrl: './ruleset-list.component.html',
  styleUrls: ['./ruleset-list.component.scss']
})
export class RulesetListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('ruleNameTemplate') ruleNameTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  showingHits: number;
  totalHits: number;
  hidePlusButton: boolean = false;
  sidePanelsObj: any = {
    add: false,
    edit: false
  };
  actionBtnPolicies = {
    add: ['RECIPE_CREATE'],
    edit: ['RECIPE_UPDATE'],
    clone: ['RECIPE_CREATE'],
    delete: ['RECIPE_DELETE']
  };
  showFilterPanel = true;
  searchFormGroup: FormGroup;
  searchTerm: string = '';
  editDisabled: boolean = true;
  deleteDisabled: boolean = true;
  cloneDisabled: boolean = true;
  mainWidth: any;
  tableWidth: number;
  public rows: any = [];
  filters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  deleteInProgress: boolean = false;
  private event = new Subject<any>();

  private readonly destroyed$ = new Subject<boolean>();
  selectedRuleSet: any;
  triggerTemplates: any;
  columnKeys: any = [];
  newOrEdit: string;
  selectedRulesetId: any;
  originalData: any[];
  isOrgRestrictedUser:boolean;
  delayedRefreshStart = false;
  refreshTimer = 5;
  ruleSetUpdateInfo:boolean = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private csvExport: CsvExportsService,
    private rulesService: RulesService,
    private _utilsService: UtilsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private persistenceStore: PersistenceService,
    private formateDatePipe: FormateDatePipe,
    public _userService:UserService,
  ) {
    this.route.queryParams.subscribe(params=> {
      this.selectedRulesetId = params['rid'];
      if(this.selectedRulesetId) {
        this.location.replaceState(this.route.snapshot.url[0].path);
      }
    })
  }

  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get("searchTerm") as FormControl;
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  ngOnInit(): void {
    this.isOrgRestrictedUser = this._userService.getUser()?.orgAware;
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });

    this.getRuleSetsData();
    this.setContainerWidth();
  }

  ngAfterViewInit(): void {
    this.fcSearchTerm.valueChanges
      .pipe(takeUntil(this.destroyed$), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH), distinctUntilChanged())
      .subscribe((evt) => this.onSearchInput(evt));

      const eventSub: Subscription = this.getEvent().subscribe(evt => {
        if (evt.eventName === 'summary') {
          this.closeSidePanel();
          this.hidePlusButton = true;
          this.sidePanelsObj.summary = true;
          this.selectedRulesetId = evt.id;
        }
      });

      this.rawColumns = [
        {
          prop: 'selected',
          resizable: false,
          canAutoResize: false,
          sortable: false,
          name: '',
          width: 30,
          visible: true,
          headerCheckboxable: true,
          checkboxable: true
        },
        { name: 'Set Name', prop: 'name', minWidth: 180, cellTemplate: this.ruleNameTemplate, visible: true },
        { name: 'Organization', prop: 'organizationName', minWidth: 180, visible: true },
        { name: 'Created At', prop: 'createdAtFormatted', minWidth: 150, visible: true },
        { name: 'Created By', prop: 'createdBy', minWidth: 200, visible: true },
        { name: 'Modified At', prop: 'modifiedAtFormatted', minWidth: 150, visible: true },
        { name: 'Modified By', prop: 'modifiedBy', minWidth: 150, visible: true }

      ];
      const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('ruleSet', [...this.rawColumns]);
      this.rawColumns = [...rawColumns];
      this.columns = [...gridColumns];
      this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
      this._changeDetectorRef.detectChanges();
  }

  getRuleSetsData() {
    if(this.delayedRefreshStart){
      this.delayedRefreshStart = false
    }
    this.isLoading = true;
    const rulesetSub: Subscription = this.rulesService.getRulesetList().subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.totalHits = (res && res.length) || 0;
        this.showingHits = this.totalHits;
        res = res.map(item => {
          item.createdAtFormatted = item.createdAt ? this.formateDatePipe.transform(item.createdAt) : '';
          item.modifiedAtFormatted = item.createdAt ? this.formateDatePipe.transform(item.createdAt) : '';
          return item;
        })
        this.originalData = [...res];
        this.rows = res;
        if(this.selectedRulesetId){
          this.sidePanelsObj.summary = true;
          this.hidePlusButton = true;
        }
      },
      error: err => {
        console.error(err);
        this.isLoading = false;
      }
    })
    this.subscriptions.push(rulesetSub);
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'ruleSet', this.table._internalColumns);   
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
  }

  onSearchInput(evt: any) {
    this.searchTerm = this.searchFormGroup.value.searchTerm;
    this.rows = this.originalData.filter(row => row.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    this.showingHits = _.isEmpty(this.rows) ? 0 : this.rows.length;
  }

  addRuleSet() {
    this.hidePlusButton = true;
    this.selectedRuleSet = {};
    this.newOrEdit = 'new';
    this.sidePanelsObj.add = true;
  }

  editRuleSet() {
    this.hidePlusButton = true;
    this.newOrEdit = 'edit';
    this.sidePanelsObj.edit = true;
  }

  cloneRuleSet() {
    this.hidePlusButton = true;
    this.newOrEdit = 'clone';
    this.sidePanelsObj.clone = true;
  }

  deleteRuleSet() {
    let message;

    message = `Are you sure to delete this Rule Set : ${this.selectedRuleSet?.name}?` ;

    const dialogData = new ConfirmDialogModel("", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.deleteInProgress = true;
        this.rulesService.deleteRuleset(this.selectedRuleSet.id).subscribe({
          next: (res) => {
            this._utilsService.showMessage("Rule Set successfully deleted!", this.selectedRuleSet.name, 3000);
            this.deleteInProgress = false;
            this.delayedRefreshStart = true;
          },
          error: (err) => {
            this.deleteInProgress = false;
            console.error(err);
          }
        });
      }
    });
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 260;
    } else {
      this.tableWidth = this.mainWidth - 20;
    }
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  downloadReport(reportType: string) {
    console.log('reportType', reportType);
    if (reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Ruleset-list', _.map(this.columns, 'name'));
    }
  }

  getPanelUpdate(event: any) {

  }

  getId(row) {
    return row.id;
  }

  onSelect(rowData: any) {
    let selectedRows: any = rowData.selected;
    if (selectedRows.length === 1) {
      this.selectedRuleSet = rowData.selected[0];
      if(rowData.selected[0].organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
        this.editDisabled = true;
        this.deleteDisabled = true;
        this.cloneDisabled = true;
     }else{
      this.editDisabled = false;
      this.deleteDisabled = false;
      this.cloneDisabled = false;
     }
    } else {
      this.editDisabled = true;
      this.deleteDisabled = true;
      this.cloneDisabled = true;
    }
  }

  closeSidePanel(reloadList?: boolean) {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    this.hidePlusButton = false;
    if (reloadList && this.newOrEdit ==='new') {
      this.delayedRefreshStart = true;
    }else if(reloadList && this.newOrEdit ==='edit'){
      this.ruleSetUpdateInfo = true;
    }
  }

  loadRulesetSummary(id: string, event: any) {
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', id: id });
  }

  closemodel() {
    this.ruleSetUpdateInfo = !this.ruleSetUpdateInfo;
    this.delayedRefreshStart = true;
  }

}
