import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cl-monitor-card',
  templateUrl: './monitor-card.component.html',
  styleUrls: ['./monitor-card.component.scss']
})
export class MonitorCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
