import { BulkActionResponse, GraphUser, GraphUserList } from '../models/index';

export class UsInit {
  static readonly type = "[Users] Init";
  constructor() {}
}
export class UsGetCurrentUser {
  static readonly type = "[Users] Get Current User";
  constructor(public user: any) {}
}

export class UsInitUserProperties {
  static readonly type = "[Users] Init User Properties";
  constructor() {}
}

export class UsGetCurrentUserFromLocal {
  static readonly type = "[Users] Get Current User From Local";
  constructor() {}
}
export class UsGetTimeZones {
  static readonly type = "[Users] Get TimeZones";
  constructor() {}
}
export class UsFetchUsersList {
  static readonly type = "[Users] Fetch Users List";
  constructor(public reload: boolean = false) {}
}
export class UsGetElasticUsers {
  static readonly type = "[Users] Fetch Users ES Batch";
  constructor(public reload: boolean = false) {}
}

export class UsSelectUser {
  static readonly type = "[Users] Select User";
  constructor(public user: GraphUser) {}
}

export class UsToggleUser {
  static readonly type = "[Users] Toggle User";
  constructor(public user: GraphUser) {}
}

export class UsSearchUser {
  static readonly type = "[Users] Search User";
  constructor(public searchTerm: string) {}
}

export class UsListScrolled {
  static readonly type = "[Users] List Scrolled";
  constructor() {}
}

export class UsToggleAddUser {
  static readonly type = "[Users] Toggle Add User";
  constructor() {}
}

export class UsEditSelected {
  static readonly type = "[Users] Edit Selected";
  constructor(public user: GraphUser) {}
}
export class UsShowPanel {
  static readonly type = "[Users] Show Panel";
  constructor(public name: string) {}
}
export class UsHidePanel {
  static readonly type = "[Users] Hide Panel";
  constructor(public name: string) {}
}
export class UsCancelEdit {
  static readonly type = "[Users] Cancel Edit";
  constructor() {}
}
export class UsAddUser {
  static readonly type = "[Users] Add User";
  constructor(public userForm: any) {}
}

export class UsAddUserBulk {
  static readonly type = "[Users] Add User Bulk";
  constructor(public apiResponse: BulkActionResponse[]) {}
}

export class UsUpdateUser {
  static readonly type = "[Users] Update User";
  constructor(public user: any, public userForm: any, public fromMyAccount: boolean) {}
}
export class UsDeleteUsers {
  static readonly type = "[Users] Delete User";
  constructor(public userList: GraphUserList, public userIDs: string[]) {}
}

// export class UsDeleteSelected {
//   static readonly type = "[Users] Delete Selected";
//   constructor() {}
// }
export class UsResetPassword {
  static readonly type = "[Users] Reset User Password";
  constructor(public user: GraphUser) {}
}
export class UsToggleSingleUser {
  static readonly type = "[Users] Toggle Single User";
  constructor() {}
}
// export class UsRefreshUsersList {
//   static readonly type = "[Users] Refresh Users List";
//   constructor() {}
// }
export class UsClearAllSelected {
  static readonly type = "[Users] Clear all selected";
  constructor() {}
}

export class UsSelectAll {
  static readonly type = "[Users] Select All";
}
export class UsSetColumnSort {
  static readonly type = "[Users] Set Column sort";
  constructor(public column: any) {}
}

export class UsDownloadUsers {
  static readonly type = "[Users] Download Users";
  constructor(public columns: string[], public columnMapping: { [key: string]: string }) {}
}