import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'cl-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit {
  currentItem = 'appSettings';
  default_data = [
    {
      "section": "application",
      "keyprefix": "common",
      "prefix1": "common",
      "prefix2": "appSettings",
      "properties": [
        {
          "key": "userMenuSupportLogo",
          "value": "https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png",
          "isDefault": true,
          "displayLabel": "User Menu Support Logo",
          "description": "Hosted public URL of the image chosen for logo in user menu support",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "showUserMenuParkourSCLogo",
          "value": "true",
          "isDefault": true,
          "displayLabel": "Show User Menu as ParkourSC Logo",
          "description": "To show user menu as parkoursc logo",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "shipmentsMaxCount",
          "value": "",
          "isDefault": true,
          "displayLabel": "Shipments Max Count",
          "description": "Max Shipments allowed for performing a single operation on them",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "maxTagsPerShipment",
          "value": "",
          "isDefault": true,
          "displayLabel": "Max Tags per Shipment",
          "description": "Max number of tags allowed per shipment",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "biworkbenchurl",
          "value": "",
          "isDefault": true,
          "displayLabel": "BI Workbench URL",
          "description": "URL for launching BI Workbench page.",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        }
      ]
    },
    {
      "section": "email",
      "keyprefix": "common",
      "prefix1": "common",
      "prefix2": "appSettings",
      "properties": [
        {
          "key": "emailLogo",
          "value": "https://clf-webapp-storage.s3.amazonaws.com/cloudleaf/images/Parkour-email_header@2x.png",
          "isDefault": true,
          "displayLabel": "Email Logo image URL",
          "description": "Hosted public URL of the image chosen for logo in email templates",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "emailLogoWidth",
          "value": "500px",
          "isDefault": true,
          "displayLabel": "Email Logo image width",
          "description": "Width of the logo image used in email template. Max width of 500px is recommended. Please provide width in pixels without px suffix",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "emailLogoHeight",
          "value": "26px",
          "isDefault": true,
          "displayLabel": "Email logo image height",
          "description": "Height of the logo image used in email template. Please provide height in pixels without px suffix",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        }
      ]
    },
    {
      "section": "application features",
      "keyprefix": "feature",
      "prefix1": "common",
      "prefix2": "appSettings",
      "properties": [
        {
          "key": "biworkbenchenable",
          "value": "false",
          "isDefault": true,
          "displayLabel": "Enable/Disable BI Workbench feature",
          "description": "",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        }
      ]
    },
    {
      "section": "order",
      "keyprefix": "order",
      "prefix1": "common",
      "prefix2": "appSettings",
      "properties": [
        {
          "key": "defaultRentalPeriod",
          "value": "",
          "isDefault": true,
          "displayLabel": "Default rental period",
          "description": "Default rental period for the order line items that can be rented",
          "displayType": "days",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "preSubmissionOrderNotes",
          "value": "",
          "isDefault": true,
          "displayLabel": "Pre Submission Order Notes",
          "description": "Hint notes to appear prior to order submission",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "postSubmissionOrderNotes",
          "value": "",
          "isDefault": true,
          "displayLabel": "Post Submission Order Notes",
          "description": "Hint notes to appear after the order submission",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "currency",
          "value": "",
          "isDefault": true,
          "displayLabel": "Currency",
          "description": "Default currency to be used for order line items",
          "displayType": "dropdown",
          "dropdownValues": [
            {value: '', label: ''},
            {value: '$', label: 'USD ($)'}
          ],
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "allowUserSelectRentalPeriod",
          "value": "",
          "isDefault": true,
          "displayLabel": "Allow User to select the rental period",
          "description": "Allow user to change the rental period for the line items that can be rented",
          "displayType": "dropdown",
          "dropdownValues": [
            {value: 'false', label: 'False'},
            {value: 'true', label: 'True'}
          ],
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "rentalPriceType",
          "value": "",
          "isDefault": true,
          "displayLabel": "Rental Price Type",
          "description": "Rental Price Type for the order line items that can be rented",
          "displayType": "dropdown",
          "dropdownValues": [
            {value: 'flat', label: 'Flat rate'},
            {value: 'daily', label: 'Daily pricing'}
          ],
          "reset": false,
          "isallowDelete": false,
        }
      ]
    },
    {
      "section": "login",
      "keyprefix": "domain",
      "prefix1": "common",
      "prefix2": "appLoginSettings",
      "properties": [
        {
          "key": "applicationName",
          "value": "ParkourSC",
          "isDefault": true,
          "displayLabel": "Application title",
          "description": "To show the application name in browser tab",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "applicationFavicon",
          "value": "https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/favicon.ico",
          "isDefault": true,
          "displayLabel": "Application favico",
          "description": "To show the logo in browser tab",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "preLoginLogoURL",
          "value": "https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_logo.png",
          "isDefault": true,
          "displayLabel": "Pre-login pages logo image URL",
          "description": "Hosted public URL of the image chosen for logo in login pages",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "logoAltText",
          "value": "Parkoursc",
          "isDefault": true,
          "displayLabel": "Pre-login pages logo image alt Text",
          "description": "Alternative text to be appeared in case of logo failed to load, in login pages",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "bannerUrl",
          "value": "https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/parkoursc_login_banner.png",
          "isDefault": true,
          "displayLabel": "Banner Url",
          "description": "Hosted public URL of the image chosen for banner in login page",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        },
        {
          "key": "actionButtonColor",
          "value": "#0094d0",
          "isDefault": true,
          "displayLabel": "Action button color",
          "description": "Color of the buttons in pre login pages",
          "displayType": "text",
          "reset": false,
          "isallowDelete": false,
        }
      ]
    }
  ];

  AppSettingsDefinition = [];

  constructor() {
  }

  ngOnInit(): void {

  }
}
