import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PropertyService } from '@cl/property/property.service';
import * as _ from 'lodash';
import { Duration } from '../duration/duration.component';
@Component({
  selector: 'cl-threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.scss']
})
export class ThresholdComponent implements OnInit, OnChanges {
  @Input('input-threshold') inputThreshold: any;
  @Input('selected-threshold') selectedThreshold: any;
  @Output('on-update-threshold') onUpdateThreshold:EventEmitter<any> = new EventEmitter<any>();

  _limitMin = null;
  _limitMax = null;
  metadata: any = {};
  thresholdLevels: any = [];
  min: any = {};
  max: any = {};
  outputMeasure: any = {};
  thresholdType: any = '';
  criteria: any = null;
  constructor(private propertyService: PropertyService) { }
  _fnValidateCriteria(){
    this.criteria = null;
    var keys = _.map(_.map(this.thresholdLevels, 'enabled'), 'key')
    keys.forEach((key) => {
      if(this.outputMeasure[key]===true){
        this.criteria = 'okay';
      }
    });
  }
  fnTriggerInputChange(typeChange, level) {
    if (typeChange == 'input') {
      level.threshold = null;
      if((level.hasOwnProperty('min') && _.isNumber(this.outputMeasure[level.min.key])) || (level.hasOwnProperty('max') && _.isNumber(this.outputMeasure[level.max.key]))){
        level.threshold = 'okay';
      }
      this._fnSetMinMaxFields();
    } else if(typeChange == 'toggle') {
      this._fnValidateCriteria();
      level.threshold = null;
      if(level.hasOwnProperty('duration')){
        this.outputMeasure[level.duration.key] = null;
        level.durationThreshold = null;
      }
      if(level.hasOwnProperty('max')){
        this.outputMeasure[level.max.key] = null;
      }
      if(level.hasOwnProperty('min')){
        this.outputMeasure[level.min.key] = null;
      }
    }
    this._fnSendUpdatedThreshold(this.outputMeasure);
  };
  fnDurationChanged(level, dataObject, key, value: Duration){
    if(level.hasOwnProperty('duration') && level.duration.templateOptions.required){
      level.durationThreshold = value.duration;
    }
    dataObject[key] = value.duration;
    this._fnSendUpdatedThreshold(dataObject);
  };
  _fnSendUpdatedThreshold(data){
    data.criteria = this.criteria;
    this.onUpdateThreshold.emit(data);
  }
  _fnMin(data) {
     return _.min(_.compact(data));
  };
  _fnMax(data) {
    return _.max(_.compact(data));
  };
  _fnSetMinMaxFields(){
    this._fnUpdateDefaultMinMax();
    var excursion = _.find(this.thresholdLevels, {order: 1});
    var critical = _.find(this.thresholdLevels, {order: 2});
    var warning = _.find(this.thresholdLevels, {order: 3});
    if(!_.isEmpty(excursion)){
      if(excursion.hasOwnProperty('min')){
        this.min[excursion.min.key] = this._limitMin;
        if(excursion.hasOwnProperty('max')){
          this.min[excursion.max.key] = this._fnMax([this.outputMeasure[excursion.min.key], this._limitMin]);
        }
      }
      if(excursion.hasOwnProperty('max')){
        if(excursion.hasOwnProperty('min')){
          this.max[excursion.min.key] = this._fnMin([this.outputMeasure[excursion.max.key], this._limitMax]);
        }
        this.max[excursion.max.key] = this._limitMax;
      }
      /* this.min.excursionMin = _limitMin;
      this.min.excursionMax = _fnMax([this.outputMeasure.excursionMin, _limitMin]);
      this.max.excursionMin = _fnMin([this.outputMeasure.excursionMax, _limitMax]);
      this.max.excursionMax = _limitMax; */
    }
    if(!_.isEmpty(critical)){
      if(critical.hasOwnProperty('min')){
        this.min[critical.min.key] = this._fnMax[this._limitMin, (excursion && excursion.min ? this.outputMeasure[excursion.min.key]: null)];
        if(critical.hasOwnProperty('max')){
          this.min[critical.max.key] = this._fnMax([this.outputMeasure[critical.min.key], (excursion && excursion.min ? this.outputMeasure[excursion.min.key] : null), this._limitMin]);
        }
      }
      if(critical.hasOwnProperty('max')){
        if(critical.hasOwnProperty('min')){
          this.max[critical.min.key] = this._fnMin([(excursion && excursion.max ? this.outputMeasure[excursion.max.key]: null), this.outputMeasure[critical.max.key], this._limitMax]);
        }
        this.max[critical.max.key] = this._fnMin([(excursion && excursion.max ? this.outputMeasure[excursion.max.key]: null), this._limitMax]);
      }
      /*
      this.min.criticalMin = _fnMax([_limitMin, this.outputMeasure.excursionMin]);
      this.min.criticalMax = _fnMax([this.outputMeasure.criticalMin,this.outputMeasure.excursionMin, _limitMin]);
      this.max.criticalMin = _fnMin([this.outputMeasure.excursionMax, this.outputMeasure.criticalMax, _limitMax]);
      this.max.criticalMax = _fnMin([this.outputMeasure.excursionMax, _limitMax]); */
    }
    if(!_.isEmpty(warning)){
      if(warning.hasOwnProperty('min')){
        this.min[warning.min.key] = this._fnMax([this._limitMin, (excursion && excursion.min ? this.outputMeasure[excursion.min.key]: null), (critical && critical.min ? this.outputMeasure[critical.min.key]: null)]);
        if(warning.hasOwnProperty('max')){
          this.min[warning.max.key] = this._fnMax([(critical && critical.min ? this.outputMeasure[critical.min.key]: null), this.outputMeasure[warning.min.key], (excursion && excursion.min ? this.outputMeasure[excursion.min.key]: null), this._limitMin]);
        }
      }
      if(warning.hasOwnProperty('max')){
        if(warning.hasOwnProperty('min')){
          this.max[warning.min.key] = this._fnMin([(excursion && excursion.max ? this.outputMeasure[excursion.max.key]: null), this.outputMeasure[warning.max.key], (critical && critical.max ? this.outputMeasure[critical.max.key]:null), this._limitMax]);
        }
        this.max[warning.max.key] = this._fnMin([(critical && critical.max ? this.outputMeasure[critical.max.key] : null), (excursion && excursion.max ? this.outputMeasure[excursion.max.key]: null), this._limitMax]);
      }
      /*
      this.min.warningMin = _fnMax([_limitMin, this.outputMeasure.excursionMin, this.outputMeasure.criticalMin]);
      this.min.warningMax = _fnMax([this.outputMeasure.criticalMin,this.outputMeasure.warningMin, this.outputMeasure.excursionMin, _limitMin]);
      this.max.warningMin = _fnMin([this.outputMeasure.excursionMax, this.outputMeasure.warningEnable, this.outputMeasure.criticalMax, _limitMax]);
      this.max.warningMax = _fnMin([this.outputMeasure.criticalMax, this.outputMeasure.excursionMax, _limitMax]);*/
    }
  }
  _fnGetData(destination, source){
    const enableKeys = _.map(_.map(this.thresholdLevels, 'enabled'), 'key')
 
    for(var key in destination){
      if(source.hasOwnProperty(key) && destination.hasOwnProperty(key)){
        destination[key] = source[key];
        if(enableKeys.includes(key)){ 
          // To prevent only for enable properties are as strings
          if(source[key] === 'true') destination[key] = true; 
          if(source[key] === 'false') destination[key] = false; 
        }
      }
    }
    return destination;
  }
  _fnUpdatedSelectedThresholds() {
    if (!_.isEmpty(this.selectedThreshold)) {
      this.outputMeasure = this._fnGetData(this.outputMeasure, this.selectedThreshold);
      this._fnValidateCriteria();
      this.thresholdLevels.forEach((level) => {
        level.threshold = null;
        level.durationThreshold = null;
        if(level.duration && level.duration.templateOptions.required && this.outputMeasure[level.duration.key]){
          level.durationThreshold = 'okay';
        }
        let thresholdUnits = this.inputThreshold[1];
        if (level.min && this.outputMeasure[level.min.key]) {

          level.threshold = 'okay';
          if(!_.isEmpty(thresholdUnits.property_name)){
            if(level.min.key === 'excursionMin'){
              this.outputMeasure[level.min.key] = this.selectedThreshold.excursionMin_orig;
            }
            if(level.min.key === 'criticalMin'){
              this.outputMeasure[level.min.key] = this.selectedThreshold.criticalMin_orig;
            }
            if(level.min.key === 'warningMin'){
              this.outputMeasure[level.min.key] = this.selectedThreshold.warningMin_orig;
            }
          }
        }
        if (level.max && this.outputMeasure[level.max.key]) {
          level.threshold = 'okay';
          if(!_.isEmpty(thresholdUnits.property_name)){
            if(level.max.key === 'excursionMax'){
              this.outputMeasure[level.max.key] = this.selectedThreshold.excursionMax_orig;
            }
            if(level.max.key === 'criticalMax'){
              this.outputMeasure[level.max.key] = this.selectedThreshold.criticalMax_orig;
            }
            if(level.max.key === 'warningMax'){
              this.outputMeasure[level.max.key] = this.selectedThreshold.warningMax_orig;
            }
          }
        }
      });
    }
  }
  _fnUpdateDefaultMinMax() {
    this._limitMax = null;
    this._limitMin = null;
    if (this.thresholdType === 'Tilt:rxTilt') {
      this._limitMax = 180;
      this._limitMin = 0;
    }
  }
  _fnUpdateMetaData() {
    var thresholdUnits = this.inputThreshold[1];
    var measures = this.inputThreshold[0];
    this.thresholdLevels = _.sortBy(measures.Levels, 'order');
    this.thresholdType = measures.key;
    if(!_.isEmpty(thresholdUnits.property_name)){
      var temp = this.propertyService.getSetting(thresholdUnits.property_name);
      thresholdUnits.default = !_.isEmpty(temp) ? temp : thresholdUnits.default;
    }
    /* if(!_.isEmpty(this.selectedThreshold)){
      thresholdUnits.default = this.selectedThreshold.unit;   ///commented as per dvp2-11904
    } */
    this.metadata.unit = thresholdUnits.default;
    this.metadata.displayLabel = measures.label.replace('__KEY(unit)__', this.metadata.unit);
    this.outputMeasure = {};
    if(!_.isEmpty(thresholdUnits.default)){
      this.outputMeasure.unit = thresholdUnits.default;
      this.outputMeasure[measures.key] = this.metadata.displayLabel;
    }
  }
  _fnGetKeys(){
    this.max = {};
    this.min = {};
    this.thresholdLevels.forEach((level) => {
      if(level.hasOwnProperty('enabled')){
        this.outputMeasure[level.enabled.key] = false;
      }
      if(level.hasOwnProperty('duration')){
        this.outputMeasure[level.duration.key] = null;
      }
      if(level.hasOwnProperty('max')){
        this.outputMeasure[level.max.key] = null;
        this.max[level.max.key] = null;
        this.min[level.max.key] = null;
      }
      if(level.hasOwnProperty('min')){
        this.outputMeasure[level.min.key] = null;
        this.max[level.min.key] = null;
        this.min[level.min.key] = null;
      }
    });
  }
  init(){
    if (this.inputThreshold && this.inputThreshold.length === 2) {
      this.metadata = {};
      this._fnUpdateMetaData();
      this._fnGetKeys();
      this._fnUpdateDefaultMinMax();
      this._fnUpdatedSelectedThresholds();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }
  ngOnInit(): void {
    this.init();
  }
}
