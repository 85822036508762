import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CL_INPUT_DEBOUNCE_TIME } from '../../common/constants/index';
import { UserCategory, UserSearchResult } from '@cl/models';
import { listAnimation } from '@cl/animation';
import { SearchApiService } from '../../common/services/search-api.service';
import { UserApiService } from '../../common/services/user-api.service';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-users-search',
  templateUrl: './users-search.component.html',
  styleUrls: ['./users-search.component.scss'],
  animations: [
    listAnimation,
  ],
})
export class UsersSearchComponent implements OnInit, OnDestroy {

  @Input()
  selectButtonTitle = 'ENTER';

  @Input()
  inputPlaceholder = 'Type to search';

  @Input()
  disabled = false;

  @Input()
  progress = false;

  @Input()
  type: UserCategory[] = [];

  @Output()
  confirmUser = new EventEmitter<UserSearchResult>();


  usersList: UserSearchResult[] = [];
  loading = false;

  searchFc = new FormControl('', Validators.required);
  selectedUserFc = new FormControl(null, Validators.required);

  private readonly destroyed$ = new Subject<boolean>();

  constructor(
    private _searchApi: SearchApiService,
    private _usersApi: UserApiService,
  ) { }

  ngOnInit(): void {
    this.subscribeToSearchChange();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  listScrolled(event) {
    // console.log('list scrolled', event);
  }

  /**
   * Selects a user from the list
   */
  selectUser(user: UserSearchResult) {
    this.selectedUserFc.patchValue(user);
  }

  /**
   * Confirms the user selection and emits back the selected user to
   * its parent.
   */
  confirmUserSelection() {

    if(!this.selectedUserFc?.value?.id) {
      throw new Error('No user selected');
    }

    this.confirmUser.emit(this.selectedUserFc.value);
  }

  /**
   * Subscribes to search text change and gets the users list from API
   */
  private subscribeToSearchChange() {

    this.searchFc.valueChanges
      .pipe(
        takeUntil(this.destroyed$),

        startWith(''),

        // Clear previous selected value before new search
        tap(_ => this.selectedUserFc.reset()),

        debounceTime(CL_INPUT_DEBOUNCE_TIME),

        distinctUntilChanged(),

        tap(_ => this.loading = true),

        switchMap(
          keyword => this._usersApi.searchUsersInEs(keyword, this.type)
            .pipe(
              catchError((err) => {
                console.error('Unable to load users list', err);

                return of(null);
              })
            )
        ),

        tap(_ => this.loading = false),
      )
      .subscribe(res => {
        this.usersList = res?.users?.hits || [];
        console.log('users list', this.usersList);
        });
  }

  // private processSearchResult(res: UserSearchApiResponse) {
  //   this.usersList = res.users.hits;
  // }

}
