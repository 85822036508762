import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { AuthorizeService } from '@cl/@authorize/authorize.service';


@Directive({
  selector: '[clIsValidAction]'
})
export class IsValidActionDirective implements OnChanges {
  @Input() clIsValidAction: string[];
  constructor(private el: ElementRef,
    private _authService: AuthorizeService
    ) { }

  ngOnChanges() {
    let isValid = this._authService.isValidAction(this.clIsValidAction);
    if (isValid) {
      this.el.nativeElement.style.display = 'block';
    } else {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
