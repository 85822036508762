<div class="user-summary-panel">
  <cl-side-panel (closePanel)="closePanel()">
    <section class="cl-side-panel-title bgcolor32">
      <span class="black font-bold">{{ mode === "add" ? "Create" : "Edit" }} Subscription</span>
      <!-- <span class="close-icon" (click)="closePanel()">
        <i class="fa fa-times-circle-o mr-4"></i>
      </span> -->
    </section>
    
    <section class="cl-side-panel-content mt-2 update-class">
      <div class="parcel-shipment">
        <form [formGroup]="apiSubscriberForm" autocomplete="off">
          <div class="section-border">
            <div class="user-detail__item">
              <div class="form-label">
                <div class="grid grid-cols-2">
                   <div>
                    Name<span class="required">*</span>
                   </div>
                </div> 
              </div>
              <input type="text" class="basic-input" formControlName="subscriberName" name="subscriberName"
                  id="subscriberName">
              <div *ngIf="submitted && apiSubscriberForm.get('subscriberName').errors?.['required']" class="text-danger">
                <label class="red"
                  [ngClass]="(apiSubscriberForm.controls['subscriberName'].invalid && (apiSubscriberForm.controls['subscriberName'].dirty || apiSubscriberForm.controls['subscriberName'].touched)) ? 'visible' : 'invisible'">
                  Please enter subscription name!
                </label>
              </div>
            </div>
            <div class="user-detail__item">
              <div class="form-label">
                <div class="grid grid-cols-2">
                  <div>Callback URL
                    <span class="required">*</span></div>
                  <div>
                    <span><button type="button" class="btn-link mr15 mt0 info-label" title="{{helpTextCallBackUrl}}" data-toggle="tooltip" data-placement="right" (click)="openHelpDialog(helpTextCallBackUrl)">
                      <i class="fa fa-info-circle help-icon color4" style="font-size:initial"></i>
                    </button></span>
                  </div>
                </div>
              </div>
              <input type="text" class="basic-input" formControlName="callbackURL" name="callbackURL" id="callbackURL">
              <div *ngIf="submitted && apiSubscriberForm.get('callbackURL').errors?.['required']" class="text-danger">
                <label class="red"
                  [ngClass]="(apiSubscriberForm.controls['callbackURL'].invalid && (apiSubscriberForm.controls['callbackURL'].dirty || apiSubscriberForm.controls['callbackURL'].touched)) ? 'visible' : 'invisible'">
                  Please enter callback URL!
                </label>
              </div>
            </div>
            <div class="user-detail__item">
              <div class="form-label">
                <div class="grid grid-cols-2">
                  <div>Subscription Type<span class="required">*</span></div>
                  <div>
                    <span><button type="button" class="btn-link mr15 mt0 info-label" title="{{helpTextSubscriberType}}" data-toggle="tooltip" data-placement="right" (click)="openHelpDialog(helpTextSubscriberType)">
                      <i class="fa fa-info-circle help-icon color4" style="font-size:initial"></i>
                    </button></span>
                  </div>
                </div>
              </div> 
                 <mat-select formControlName="subscriberType" [class.disabled]="mode ==='edit'" [value]="selectedOption" placeholder="-- Select a Subscription Type --" (selectionChange)="checkType($event.value)">
                  <mat-option [value]="''"> -- Select a Subscription Type -- </mat-option>
                   <mat-option *ngFor="let type of subscriberType | keyvalue" [value]="type.key" [textContent]="type.value">
                   </mat-option>
                 </mat-select>
            </div>
            <div class="user-detail__item">
              <div class="form-label">
                Webhook Secret
                <span><button type="button" class="btn-link mr15 mt0 webhook-key" title="{{helpTextWebhookSecretKey}}" data-toggle="tooltip" data-placement="right" (click)="openHelpDialog(helpTextWebhookSecretKey)">
                  <i class="fa fa-info-circle help-icon color4" style="font-size:initial"></i>
                </button></span>
              </div>
                <label *ngIf="!updatesecretKey">
                  <b>{{this.webhookSecretKey}}</b>
                </label>
                <input type="text" class="basic-input" formControlName="webhooksecret" name="webhooksecret"
                  id="webhooksecret" *ngIf="updatesecretKey" style="padding-right:10px;">
                  <span style="margin-left: 47px;" *ngIf="!updatesecretKey"><button type="button" class="btn-link mt0">
                    <i class="fa fa-pencil color4" (click)="newSecretKey()" style="font-size:14px;"></i>
                  </button></span>
                  <span *ngIf="updatesecretKey" style="z-index: 999; margin-left: -17px;"><button type="button" class="btn-link mt0" title="Reset" data-toggle="tooltip" data-placement="right">
                    <i class="fa fa-history color4" (click)="cancelUpdatesecretKey()" style="font-size:14px;"></i>
                  </button></span>  
            </div>
          </div>
      <div class="card" formArrayName="customHeaders">
      <div class="card-header px-2" (click)="togglers.customHeadersField = !togglers.customHeadersField">
        Custom Headers
        <div class="float-right user-detail__item custom-toggle">
            <i
            class="fa fa-chevron-right float-right mt-2 cursor-pointer"
            [ngClass]="{
              'rotate-Icon': togglers.customHeadersField
            }"
          ></i>  
        </div>
       </div>
       <div class="card-body px-2 pt-2 card-body-scroll" [ngClass]="togglers.customHeadersField ? '' : 'hidden'">
        <div class="grid grid-cols-2">
          <div><span *ngIf="enableKeyvalSelection" class="pointer color4 fs12 ng-star-inserted" (click)="removeAllCustomHeader()"><i class="fa fa-trash"></i> Remove All </span></div>
          <div class="add-custom-header">
            <button class="p-4-10 border-general br2 mr-2 color4 bcolor4 bgcolor32">
            <i class="fa fa-plus-circle" style="cursor: pointer;" (click)="addCustomHeader()"><label class="icontext-font color4 ml-1"> Add Custom Header </label></i>
            </button>
          </div>
        </div>
        <div *ngIf="enableKeyvalSelection">
          <div class="ng-star-inserted" *ngFor="let customheader of customHeaders().controls; let i=index" [formGroupName]="i">
            <div class="included-shipments mt-1 border-valid">
              <div class="grid grid-cols-2">
                <div class="key-margin">Key</div>
                <div class="value-margin">Value</div>
              </div>
              <div class="grid grid-cols-3">
                <div class="key-input"><input type="text" class="basic-input" formControlName="key" name="key"/></div>
                <div class="value-input"><input type="text" class="basic-input" formControlName="value" name="value" /></div>
                <div class="custom-detele"><i class="fa fa-trash color4 deleteEvent" (click)="removeCustomHeader(i)"></i></div>
              </div>
            </div>
          </div>
        </div>
       </div>
      </div>

      <div class="card" formArrayName="selectedEntities" *ngIf="enableEntityEvent">
        <div class="card-header px-2" (click)="togglers.subscriberTypeField = !togglers.subscriberTypeField">
          Subscribed Events
          <div class="float-right user-detail__item event-toggle">
            <i
            class="fa fa-chevron-right float-right mt-2 cursor-pointer"
            [ngClass]="{
              'rotate-Icon': togglers.subscriberTypeField
            }"
          ></i>  
        </div>
         </div>
         <div class="card-body px-2 pt-2 card-body-scroll" [ngClass]="togglers.subscriberTypeField ? '' : 'hidden'">
          <div class="grid grid-cols-2">
            <div><span *ngIf="enableEntitySelection" class="pointer color4 fs12 ng-star-inserted" (click)="removeAllEntity()"><i class="fa fa-trash"></i> Remove All </span></div>
            <div class="float-right user-detail__item add-event">
              <button class="p-4-10 border-general br2 mr-2 color4 bcolor4 bgcolor32">
              <i class="fa fa-plus-circle" style="cursor: pointer;" (click)="addSelectedEntity()"> <label class="icontext-font color4 ml-1"> Add Events </label> </i></button>
            </div>
          </div>
         <div *ngIf="enableEntitySelection">
          <div class="ng-star-inserted" *ngFor="let customheader of selectedEntities().controls; let i=index" [formGroupName]="i">
            <div class="included-shipments mt-1 border-valid">
              <div class="grid grid-cols-2">
                <div class="entitie-label">Entities</div>
                <div class="event-label">Events</div>
              </div>
              <div class="grid grid-cols-3">
                <div class="entity-dropdown">
                  <mat-select formControlName="entity" [value]="selectedentityOption" placeholder="-- Select a Entity --">
                  <mat-option [value]="''"> -- Select a Entity -- </mat-option>
                    <mat-option *ngFor="let entity of updatedEntities | keyvalue" [value]="entity.key" [textContent]="entity.value">
                    </mat-option>
               </mat-select>
              </div>
                <div class="checkbox user-roles__item">
                  <span>
                    <mat-checkbox formControlName="CREATE" name="CREATE" class="mr-2"> 
                      CREATE
                    </mat-checkbox>
                  </span>
                  <span>
                    <mat-checkbox formControlName="UPDATE" name="UPDATE" class="mr-2"> 
                      UPDATE
                    </mat-checkbox>
                  </span>
                  <span>
                    <mat-checkbox formControlName="DELETE" name="DELETE" class="mr-2"> 
                      DELETE
                    </mat-checkbox>
                  </span>
                </div>
                <div class="event-trash"><i class="fa fa-trash color4" style="cursor:pointer;" (click)="removeselectedEntity(i)"></i></div>
              </div>
            </div>
          </div>
         </div>
          
         </div>
        </div>
        </form>
      </div>
     
    </section>
    <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
      <div class="flex">
        <div class="flex-col grow fs11 text-right">
          <a class="panel-button m-2" (click)="closePanel()">Cancel</a>
          <!-- <button class="panel-button white bgcolor4" [disabled]="isSaveInProgress || !apiSubscriberForm.valid" (click)="submitApiSubscriber()"> -->
            <button class="panel-button white bgcolor4" [disabled]="isSaveInProgress" (click)="submitApiSubscriber()">
            Save
            <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
          </button>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>