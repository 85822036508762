import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService } from "@cl/core";
import {
  AggregatedCountApiResponse,
  ElasticHitBucket,
  ElasticSearchQuery,
  ESSearchResults,
  NavigatorConfig,
  NavigatorFilter,
  PartialOf,
  SearchChildFilter,
  SearchQueryRequest
} from "@cl/models";
import { ConfigService } from "../../common/services/config.service";
import _ from "lodash";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable()
export class NavigatorFiltersService {
  private get config() {
    return this.env.clfGraphApp;
  }

  private get headers() {
    return new HttpHeaders({
     // token: this.config.token,
      "Content-Type": "application/json",
    });
  }

  private readonly gatewayBaseSearchQuery: ElasticSearchQuery = {
    childFilters: [],
    fieldName: "baseClass",
    queryOperator: "must_not",
    queryText: "Gateway",
  };

  constructor(private env: EnvironmentService, private http: HttpClient, private _configService: ConfigService) {}

  /**
   * @deprecated
   */
  parseNavigatorFiltersAsEsSearchRequest(
    navigatorConfig: NavigatorConfig,
    navigatorFilter: any,
    defaultQuery: SearchQueryRequest
  ): SearchQueryRequest {
    const searchRequest: SearchQueryRequest = {
      globalQueryText: navigatorFilter?.searchTerm ?? "",
      scrollId: "",
      scrollSize: 1000,
      searchQueries: this.parseDomainFilters(navigatorConfig, navigatorFilter.domains, navigatorFilter.filters),
    };

    // Is any filter selected
    if (!this.isFilterSelected(searchRequest) && !navigatorFilter?.searchTerm) {
      const defaultSearchRequest: SearchQueryRequest = {
        ...defaultQuery,
      };

      return defaultSearchRequest;
    }

    return searchRequest;
  }

  /**
   * Converts the UI structure of navigator filters to ES Search Query.
   * This also takes targeted and visible domains in consideration.
   *
   * @param navigatorConfig
   * @param navigatorFilter UI object structure of navigator filter
   * @param defaultQuery Default ES Search query if no filters selected
   * @param targetDomain Domain id to target otherwise empty.
   * @param visibleDomains Array of domain ids that are currently visible
   * @param domainsSubFilterMap Map of all domains and its sub filters if any
   */
  toEsQuery(
    navigatorConfig: NavigatorConfig,
    navigatorFilter: any,
    defaultQuery: SearchQueryRequest,
    targetDomain: string,
    visibleDomains: string[],
    domainsSubFilterMap: PartialOf<PartialOf<string>> = {}
  ): SearchQueryRequest {
    const searchRequest: SearchQueryRequest = {
      globalQueryText: navigatorFilter?.searchTerm ?? "",
      scrollSize: 1000,
      searchQueries: this.toEsBaseQuery(
        navigatorConfig,
        navigatorFilter.domains,
        navigatorFilter.filters,
        targetDomain,
        visibleDomains,
        domainsSubFilterMap
      ),
    };

    // If no filters selected
    // and no search term provided
    // and no domains targeted
    // then fallback to the specified default ES query
    if (searchRequest.searchQueries?.length === 0) {
      const defaultSearchRequest: SearchQueryRequest = {
        ...defaultQuery,
      };

      return defaultSearchRequest;
    }

    return searchRequest;
  }

  /**
   * Returns `true` if any filter is selected, otherwise `false`.
   */
  isFilterSelected(searchRequest: SearchQueryRequest): boolean {
    let isSelected = false;

    for (let i = 0; i < searchRequest.searchQueries.length; i++) {
      const query = searchRequest.searchQueries[i];
      if (Array.isArray(query?.childFilters) && query?.childFilters?.length > 0) {
        isSelected = true;
        break;
      }
    }

    return isSelected;
  }

  /**
   * Returns `true` if any filter is selected or the search keyword has a value,
   * otherwise `false`.
   */
  isFilterOrSearch(searchRequest: SearchQueryRequest) {
    return this.isFilterSelected(searchRequest) || !!searchRequest?.globalQueryText;
  }

  /**
   * Maps a UI domain filter object to ES base query structure.
   *
   * Note: It internally maps the child filters inside a searchQuery array
   */
  private toEsBaseQuery(
    navigatorConfig: NavigatorConfig,
    allDomains: any,
    allFilters: any,
    targetDomain: string,
    visibleDomains: string[],
    domainsSubFilterMap: PartialOf<PartialOf<string>> = {}
  ): ElasticSearchQuery[] {
    const searchQueries: ElasticSearchQuery[] = [];

    // All domains (asset)
    _.forOwn(allDomains, (domain, domainKey) => {

      if (visibleDomains.includes(domainKey)) {
        const domainConfig = navigatorConfig.domain[domainKey];
        const baseSearchQuery: ElasticSearchQuery = {
          childFilters: [],
          fieldName: "baseClass",
          queryOperator: "must",
          queryText: domainConfig.fieldName[domainKey],
        };

      // Do nothing if the domain is neither visible or targeted
      // if (targetDomain !== domainKey && !visibleDomains.includes(domainKey)) {
      //   baseSearchQuery.queryOperator = 'must_not'
      //   return;
      // }

      // For all domain level filter types (state)
      _.forOwn(domain, (domainFilterMap, domainFilterMapKey) => {
        const baseChildFilter: SearchChildFilter = {
          childFilters: [],
          fieldName: domainFilterMapKey,
          queryText: "",
          queryOperator: "should",
        };

        let queryText = [];

        // (available)
        _.forOwn(domainFilterMap, (domainFilter, domainFilterKey) => {
          const childFilters: string[] = domainFilter.childFilters;
          const hasChildFilters = Array.isArray(childFilters) && childFilters.length > 0;

          // console.log("DOMAIN_FILTER", domainFilter);

          if (domainFilter.$$active) {
            queryText.push(domainFilterKey);
          }

          if (domainFilter.$$active || hasChildFilters) {
            // If sub filters are there then add child filters
            if (hasChildFilters) {
              const childFilter: SearchChildFilter = {
                fieldName: domainsSubFilterMap[domainKey][domainFilterMapKey] ?? "sku",
                queryOperator: "should",
                queryText: childFilters.join(","),
              };

              const searchQuery: SearchChildFilter = {
                ...baseChildFilter,
                queryText: domainFilterKey,
                childFilters: [childFilter],
              };

              baseChildFilter.queryText = domainFilterKey;
              baseSearchQuery.childFilters.push(searchQuery);
            }
          }
        });
        // End (available)

        // Contains no childFilters hence query text is not added to searchQuery
        if (!baseChildFilter.queryText) {
          baseChildFilter.queryText = queryText.join(",");
          baseSearchQuery.childFilters.push(baseChildFilter);
        }

        if (baseChildFilter?.childFilters?.length <= 0) delete baseChildFilter.childFilters;
      });
      // End (state)

      // Map child filters to the current base query
      // Push other filters to the domain filter object only if the domain is targeted
      if (targetDomain === domainKey) {
        const filters = this.toEsSearchFilter(navigatorConfig, allFilters, domainKey);
        baseSearchQuery.childFilters.push(...filters);
      }

      // Remove empty child filter from the base search query
      if (baseSearchQuery.childFilters.length <= 0) {
        delete baseSearchQuery.childFilters;
      }

      searchQueries.push(baseSearchQuery);
     }
    });

     // to avoid gateway from API response, will be removed later
    //  searchQueries.push(this.gatewayBaseSearchQuery);
     return searchQueries;
  }

  private parseDomainFilters(navigatorConfig: NavigatorConfig, allDomains: any, allFilters: any): ElasticSearchQuery[] {
    const searchQueries: ElasticSearchQuery[] = [];

    // All domains (asset)
    _.forOwn(allDomains, (domain, domainKey) => {
      const domainConfig = navigatorConfig.domain[domainKey];

      const baseSearchQuery: ElasticSearchQuery = {
        childFilters: [],
        fieldName: "baseClass",
        queryOperator: "must",
        queryText: domainConfig.fieldName[domainKey],
      };

      // For all domain level filter types (state)
      _.forOwn(domain, (domainFilterMap, domainFilterMapKey) => {
        const baseChildFilter: SearchChildFilter = {
          childFilters: [],
          fieldName: domainFilterMapKey,
          queryText: "",
          queryOperator: "must",
        };

        let queryText = [];

        // (available)
        _.forOwn(domainFilterMap, (domainFilter, domainFilterKey) => {
          const childFilters: string[] = domainFilter.childFilters;
          const hasChildFilters = Array.isArray(childFilters) && childFilters.length > 0;

          if (domainFilter.$$active) {
            queryText.push(domainFilterKey);
          }

          if (domainFilter.$$active || hasChildFilters) {
            // If sub filters are there then add child filters
            if (hasChildFilters) {
              const childFilter: SearchChildFilter = {
                fieldName: "sku",
                queryOperator: "should",
                queryText: childFilters.join(","),
              };

              const searchQuery: SearchChildFilter = {
                ...baseChildFilter,
                queryText: domainFilterKey,
                childFilters: [childFilter],
              };

              baseChildFilter.queryText = domainFilterKey;
              baseSearchQuery.childFilters.push(searchQuery);
            }
          }
        });
        // End (available)

        // Contains no childFilters hence query text is not added to searchQuery
        if (!baseChildFilter.queryText) {
          baseChildFilter.queryText = queryText.join(",");
          baseSearchQuery.childFilters.push(baseChildFilter);
        }

        if (baseChildFilter?.childFilters?.length <= 0) delete baseChildFilter.childFilters;
      });
      // End (state)

      const filters = this.toEsSearchFilter(navigatorConfig, allFilters, domainKey);
      baseSearchQuery.childFilters.push(...filters);

      searchQueries.push(baseSearchQuery);
    });

    // Check if any active domain exists or not
    return searchQueries;
  }

  private toEsSearchFilter(navigatorConfig: NavigatorConfig, allFilters: any, domainKey: string): SearchChildFilter[] {
    const childFilters: SearchChildFilter[] = [];
    const filterConfig = navigatorConfig.filter;

    _.forOwn(allFilters, (filter, key) => {
      const fieldName = filterConfig[key].fieldName[domainKey];

      // If field name is not defined for the current domain then skip the filter for current domain
      if (!fieldName) return;

      const childFilter: SearchChildFilter = {
        fieldName: fieldName,
        queryOperator: "must",
        queryText: filter.join(","),
      };

      if (childFilter.queryText) childFilters.push(childFilter);
    });

    return childFilters;
  }

  flattenAggregatedCountApiResponse(aggRes: AggregatedCountApiResponse[]): AggregatedCountApiResponse[] {
    const res = _.cloneDeep(aggRes);

    for (let i = 0; i < res.length; i++) {
      const agg = res[i];

      for (let j = 0; j < agg.hits.length; j++) {
        const hit = agg.hits[j];
        hit.buckets = this.flattenBuckets(hit.buckets);
      }
    }

    return res;
  }

  /**
   * Flattens buckets, sub buckets hierarchy to buckets.
   */
  private flattenBuckets(buckets: ElasticHitBucket[], _level = 0, parentName = ""): ElasticHitBucket[] {
    const allBuckets: ElasticHitBucket[] = [];

    for (let i = 0; i < buckets.length; i++) {
      const bucket = buckets[i];

      const bucketToAdd = { ...bucket, subBucket: [], _level };
      if (parentName) bucketToAdd.parentName = parentName;

      // Add current bucket to the list
      if (bucket.key) allBuckets.push(bucketToAdd);

      // Add its sub buckets to list
      if (bucket?.subBucket?.length > 0) {
        allBuckets.push(...this.flattenBuckets(bucket.subBucket, _level + 1, bucket.key));
      }
    }

    return allBuckets;
  }

  prepareTag(filters: PartialOf<string[]>, domains: PartialOf<PartialOf<string[]>>) {
    const filter = {
      type: "filters",
    };
  }

  filterApi<T>(filterConfig: NavigatorFilter, scrollId: string = "", globalQueryText: string = ""): Observable<ESSearchResults<T>> {
    const { aggregateApi } = filterConfig;
    const payload = _.cloneDeep(aggregateApi.payload);
    const url = this.config.baseUrl + aggregateApi.url;

    // Initialize payload for put and post apis
    if (aggregateApi.type === "PUT" || aggregateApi.type === "POST") {
      payload.scrollId = scrollId;

      if (filterConfig.isSearchable) payload.globalQueryText = globalQueryText;
    }

    return this.http.request<ESSearchResults<any>>(aggregateApi.type, url, { body: payload, headers: this.headers }).pipe(
      tap((res) => {
        if (Array.isArray(res.hits) && res.hits.length > 0 && res.hits[0].clfMappingType === "USER") {
          res.hits.forEach((hit) => {
            hit.id = hit.id.startsWith("user_") ? hit.id.substring(5) : hit.id;
          });
        }
      })
    );
  }

  domainApi(filterConfig: NavigatorFilter, scrollId: string = "", globalQueryText: string = ""): Observable<AggregatedCountApiResponse[]> {
    const { aggregateApi } = filterConfig;
    const payload = _.cloneDeep(aggregateApi.payload);
    const url = this.config.baseUrl + aggregateApi.url;

    // Initialize payload for put and post apis
    if (aggregateApi.type === "PUT" || aggregateApi.type === "POST") {
      // payload.scrollId = scrollId;
      if (filterConfig.isSearchable) payload.globalQueryText = globalQueryText;
    }

    return this.http
      .request<AggregatedCountApiResponse[]>(aggregateApi.type, url, { body: payload, headers: this.headers })
      .pipe(map((res) => this.mapSubTypes(res)));
  }

  private mapSubTypes(aggRes: AggregatedCountApiResponse[]): AggregatedCountApiResponse[] {
    for (let i = 0; i < aggRes.length; i++) {
      const agg = aggRes[i];

      for (let j = 0; j < agg.hits.length; j++) {
        const hit = agg.hits[j];

        for (let k = 0; k < hit.buckets.length; k++) {
          const bucket = hit.buckets[k];
          if (Array.isArray(bucket.subBucket) && bucket.subBucket.length) {
            bucket.name = "sku";
          }
        }
      }
    }

    return aggRes;
  }
}
