export class AppGetNotifications {
  static readonly type = "[App] AppGetNotifications";
}

export class AppTriggerNotificationPolling {
  static readonly type = "[App] AppTriggerNotificationPolling";
}

export class AppLoadConfigurations {
  static readonly type = "[App] AppLoadConfigurations";
  constructor(public configurations: any[]) {}

}
