<div class="checkout-dialog">
  <div class="flex items-center justify-center">
    <div inlineSVG="/assets/svgs/assets-monitor_white.svg"></div>
  </div>

  <h3 class="dialog-title">{{title}}</h3>

  <button class="button mat-button dark w-100" (click)="confirm()">CONFIRM</button>
  <div class="align-center mt-2 foz13"><a (click)="cancel()">CANCEL</a></div>
</div>
