import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { EntityService } from '@cl/common/services/entity-list.service';
import _ from 'lodash';

@Component({
  selector: 'cl-sku-summary',
  templateUrl: './sku-summary.component.html',
  styleUrls: ['./sku-summary.component.scss']
})
export class SkuSummaryComponent implements OnInit {
  @Input() skuId: any;
  @Input() skuObject: any;
  @Output() closeSidePanel = new EventEmitter();
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  activePane: string = 'summary';
  loading: boolean;
  attributes: any;
  skuObject2: any;
  response:any;
  data: any;
  customFields=["createdBy","createdAt","modifiedBy","modifiedAt"]
  constructor(private entityService: EntityService, public datePipe: FormateDatePipe) { }

  ngOnInit(): void {
    if (this.skuId && this.skuObject != undefined) {
      this.loading = true;
      this.getExtendedAttributes('sku');
    }
  }
  getExtendedAttributes(sku) {
    this.entityService.getEntityCdmFields(sku).subscribe(data => {
      this.data = data;
      this.response = this.data.cdmFields;
      this.attributes=this.entityService.orderCdmFields(this.response)
      console.log(this.attributes,"atributes")
      this.customFields.forEach(element => {
        const removeIndex = this.attributes.findIndex( item => (item.name === element));
        this.attributes.splice( removeIndex, 1 );  
      }); 
      console.log(this.attributes,"this.attributes")
    })
  }
  closePanel() {
    this.closeSidePanel.emit();
  }
  getValue(x) {
    var z = this.skuObject2.x;
  }
  getExtendedAttributeValue(cdmField, properties) {
    if (cdmField.displayable) {
      if (this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1) {
        if (cdmField?.type?.toUpperCase() === 'DATE') {
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        } else {
          if(cdmField.name == 'organization'){
            return properties['organization']
          }else{
            return properties[cdmField.name];
          }
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }


}
