import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ShipmentParcelComponent } from './shipment-parcel/shipment-parcel.component';
import { ShipmentStandardComponent } from './shipment-standard/shipment-standard.component';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { finalize } from 'rxjs/operators';
import { ToastService } from '@cl/common/services/toast.service';
import { CatalogType } from '@cl/@types/extended-attributes.types';
import { ShipmentListStateService } from '../shipment-list-state.service';
import { ShipmentCompositeComponent } from './shipment-composite/shipment-composite.component';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'cl-shipment-side-panel',
  templateUrl: './shipment-side-panel.component.html',
  styleUrls: ['./shipment-side-panel.component.scss'],
})
export class ShipmentSidePanelComponent implements OnInit {
  @Input() mode: any;
  @Input() shipmentType: string;
  @Input() shipmentID: string;
  @Output() closeSidePanel = new EventEmitter<any>();
  @ViewChild(ShipmentStandardComponent) standard: ShipmentStandardComponent;
  @ViewChild(ShipmentParcelComponent) parcel: ShipmentParcelComponent;
  @ViewChild(ShipmentCompositeComponent) composite: ShipmentCompositeComponent;

  isDraftHide = false;
  isLoading = false;
  loadingAction: 'save' | 'draft' = 'save';
  currentIndex = 0;
  previousIndex = 0;
  selectedIndex = 1;

  shipmentCatalog!: CatalogType;
  catalogTypesList: CatalogType[] = [];
  catalogType: CatalogType;
  baseCatalogType: 'standardshipment' | 'parcelshipment' | 'compositeshipment' | 'dtpshipment' = 'compositeshipment';
  isCatalogTypeError = false;
  constructor(
    public dialog: MatDialog,
    private _shipmentService: ShipmentService,
    private _toastService: ToastService,
    private _shipmentListState: ShipmentListStateService,
    private translatePipe:TranslatePipe
  ) {}

  ngOnInit(): void {
    this.getShipmentTypesList();
  }

  async getShipmentTypesList() {
    this.catalogTypesList =
      await this._shipmentListState.getShipmentMultiCatalogTypesWithCDM();
      this.catalogTypesList.sort(function (a,b) {
        return a.name.toLowerCase()>b.name.toLowerCase()?1:-1
    })

    if(this.mode == 'edit'){
      this.changeCatalogType(this.shipmentType)
    }
  }

  onNext() {
    if(!this.catalogType){
      this.isCatalogTypeError = true;
      return;
    }
    this.isCatalogTypeError = false;
    this.changeCatalogType(this.catalogType.objectType);
  }

  async changeCatalogType(objectType:string) {
    const shipmentCatalog = this.catalogTypesList.find(type => type.objectType == objectType);
    if(!['parcelshipment','standardshipment', 'compositeshipment', 'dtpshipment'].includes(shipmentCatalog.catalogType?.toLowerCase())) return;
    this.shipmentCatalog = {...shipmentCatalog};
    this.baseCatalogType = shipmentCatalog.catalogType?.toLowerCase() as any;
  }

  closePanel(reload?) {
    if (reload) {
      this.closeSidePanel.emit({ action: 'reloadUsers' });
    } else {
      this.closeSidePanel.emit(null);
    }
  }
  async onSave(type: 'draft' | 'save') {
    const isDraft = type === 'draft';
    this.loadingAction = type;
    if (this.baseCatalogType == 'standardshipment') {
      const payload = this.standard.onSubmit(type);

      if (payload) {
        if (this.mode === 'edit') {
          this.updateShipment(payload, 'standard', isDraft);
        } else {
          this.createShipment(payload, 'standard', isDraft);
        }
      }
    } else if (this.baseCatalogType == 'parcelshipment') {
      const payload = await this.parcel.onSubmit(type);

      if (payload) {
        if (this.mode === 'edit') {
          this.updateShipment(payload, 'parcel', isDraft);
        } else {
          this.createShipment(payload, 'parcel', isDraft);
        }
      }
    } else if (this.baseCatalogType == 'dtpshipment') {
      const payload = await this.parcel.onSubmit(type);

      if (payload) {
        if (this.mode === 'edit') {
          this.updateShipment(payload, 'dtp', isDraft);
        } else {
          this.createShipment(payload, 'dtp', isDraft);
        }
      }
    } else if (this.baseCatalogType == 'compositeshipment'){
      const payload = this.composite.onSubmit();
      if(payload){
        if(this.mode == 'add'){
          this.createCompositeShipment(payload)
        }
        else if(this.mode == 'edit'){
          this.updateCompositeShipment(payload)
        }
      }
      
    }
  }

  updateCompositeShipment(payload){
    this.isLoading = true;
    this._shipmentService.updateShipment(payload,this.shipmentID, 'composite', false, this.shipmentCatalog.objectType)
    .pipe(finalize(() => (this.isLoading = false)))
    .subscribe(
      (res) => {
        this._toastService.success("Shipment updated Successfully")
        this.closePanel(true);
      },
      (error) => {
        try {
          const errorMessage = JSON.parse(error.error['error-message']);
          this.checkHavingOrganizationError(errorMessage);
          this.checkHavingPayloadError(errorMessage);
        } catch (error) {}
      }
    );
  }

  createCompositeShipment(payload){
    this.isLoading = true;
    this._shipmentService.createShipment(payload, 'composite', false, this.shipmentCatalog.objectType)
    .pipe(finalize(() => (this.isLoading = false)))
    .subscribe(
      (res) => {
        this._toastService.success("Shipment created Successfully")
        this.closePanel(true);
      },
      (error) => {
        try {
          const errorMessage = JSON.parse(error.error['error-message']);
          this.checkHavingOrganizationError(errorMessage);
          this.checkHavingPayloadError(errorMessage);
        } catch (error) {}
      }
    );
  }
  createShipment(payload: any, type: any, isDraft: boolean) {
    this.isLoading = true;
    this._shipmentService
      .createShipment(payload, type, isDraft,this.shipmentCatalog.objectType)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res) => {
          this._toastService.success("Shipment created Successfully")
          this.closePanel(true);
        },
        (error) => {
          try {
            const errorMessage = JSON.parse(error.error['error-message']);
            this.checkHavingOrganizationError(errorMessage);
            this.checkHavingPayloadError(errorMessage);
          } catch (error) {}
        }
      );
  }

  checkHavingPayloadError(error) {
    if (!error.payload_error || error.payload_error?.length == 0) return;
    const payloadErrors = [];
    error.payload_error.forEach((error) => {
      payloadErrors.push(error.errorString);
    });
    this._toastService.openCloseHandlerSnackBar(payloadErrors, ''); 
  }
  checkHavingOrganizationError(error) {
    if (!error.org_mismatch_error || error.org_mismatch_error?.length == 0)
      return;
    let orgErrors = error.org_mismatch_error;
    const entityTypeOrder = [
      'Asset',
      'Origin Area',
      'Destination Area',
      'Route',
      'Ruleset',
      'Device Profile',
      'Device',
      'Tracking Number',
    ];
    const orderedOrgErrors = new Array(entityTypeOrder.length)
      .fill(0)
      .map(() => new Array().fill(0));

    orgErrors.forEach((org) => {
      const entityOrder = entityTypeOrder.indexOf(org.entityType);
      if (entityOrder > -1) {
        let message = `- ${org.entityType}: `;
        if (org.externalId) message += `${org.externalId} - `;
        message += org.name;
        orderedOrgErrors[entityOrder].push(message);
      }
    });

    orgErrors = [].concat(...orderedOrgErrors); //Converting 2d array to 1d array

    this._toastService.openCloseHandlerSnackBar(orgErrors, (this.translatePipe.transform('logistics.shipmentMismatchErrorMessage')));
  }

  updateShipment(payload: any, type: any, isDraft: boolean) {
    this.isLoading = true;
    this._shipmentService
      .updateShipment(payload, this.shipmentID, type, isDraft,this.shipmentCatalog.objectType)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (res) => {
          this._toastService.success("Shipment updated Successfully")
          this.closePanel(true);
        },
        (error) => {
          try {
            const errorMessage = JSON.parse(error.error['error-message']);
            this.checkHavingOrganizationError(errorMessage);
            this.checkHavingPayloadError(errorMessage);
          } catch (error) {}
        }
      );
  }
}
