import { Injectable } from '@angular/core';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';

@Injectable({
  providedIn: 'root'
})
export class LocationUtilsService {
  locationStaticProperties: string[] = ['address', 'areaFenceType', 'baseClass', 'baseType', 'carrierHubCode', 'classType', 'coordinates', 'createdAt',
    'createdBy', 'deleted','description','externalId','fullAddress','modifiedAt','modifiedBy','name','namespace','parentLocation','physicalId','position',
    'productNamespace','rulesetId','size','tenantId','topLevelNamespace','type','versionNamespace','zoneNamespace']
    
  private _locationExtendedAttributes = {};
  private _locationCatalogsWithExtendedAttributes = [];

  constructor(private _extendedCatalogService: ExtendedCatalogAttributeService) { }

  async getLocationExtendedAttributes(
    locationType: string
  ) {
    if (!this._locationExtendedAttributes[locationType]) {
      this._locationExtendedAttributes[locationType] =
        await this._extendedCatalogService.getCatalogExtendedColumns(
          locationType
        );
    }
    return { ...this._locationExtendedAttributes[locationType] };
  }

  async getLocationCatalogsWithExtendedAttributes(includeCDMFields:boolean) {
    if (!this._locationCatalogsWithExtendedAttributes.length) {
      this._locationCatalogsWithExtendedAttributes =
        await this._extendedCatalogService.getCatalogExtendedMultiColumns('location',includeCDMFields);
    }
    return [ ...this._locationCatalogsWithExtendedAttributes ];
  }
}
