import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cl-api-subscription-summary-panel',
  templateUrl: './api-subscription-summary-panel.component.html',
  styleUrls: ['./api-subscription-summary-panel.component.scss'],
})
export class ApiSubscriptionSummaryPanelComponent implements OnInit {
  @Output() closeSidePanel: EventEmitter<void> = new EventEmitter();
  @Input() subscriptionObject: any;
  @Input() mode: any;

  entityEvents = [];

  constructor() {}

  ngOnInit(): void {
    const entities = {};
    this.subscriptionObject?.registeredEvents.forEach((d: string) => {
      let entity = '';
      let action = '';
      if (d.includes('_CREATE')) {
        entity = d.split('_CREATE')[0];
        action = 'CREATE';
      }

      if (d.includes('_UPDATE')) {
        entity = d.split('_UPDATE')[0];
        action = 'UPDATE';
      }

      if (d.includes('_DELETE')) {
        entity = d.split('_DELETE')[0];
        action = 'DELETE';
      }

      if (entities[entity]) {
        entities[entity].push(action);
      } else {
        entities[entity] = [action];
      }
    });

    this.entityEvents = Object.entries(entities);
  }
  closePanel() {
    this.closeSidePanel.emit();
  }
  saveRole() {}
}
