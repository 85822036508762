

<div class="sensor-febric  list-page-wrapper relative list-page main_continer">
    <div>
        <div class="section-border" >
            <div class="property_block submit_block" style="width: 5%;">
                <div >
                    <!-- <button
                    class="panel-button white bgcolor4 ml-2"
                    type="submit " (click)="submitData()" [disabled]="!propertyForm.dirty"
                  > 
                    
                  <span class="fa fa-file"></span> Save 
                </button> -->
                <button type="submit" (click)="submitData()" [disabled]='!propertyForm.dirty'
                        [ngClass]="[(!propertyForm.dirty) ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                        class="p-4-10 border-general br2 mr-2">
                        Save
                        <!-- <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="saveClicked"></i> -->
                    </button>
                </div>
            </div>
            <div class="property_block type_block" >
                <div class="section-title head-set">
                    Scope Type :<span class="mandatory"></span>
                </div>
                <div class="user-detail__item property_radio_wraper" >
                    
                    <mat-radio-group class="foz12 capitalize" aria-label="Select an option"  [(ngModel)]="scope_type">
                        <mat-radio-button style="width: 100px;"  (change)="loadData('domain')" [value]="true">Domain</mat-radio-button>
                        <mat-radio-button (change)="getTenantListData()" [value]="false">Tenant</mat-radio-button>
                    </mat-radio-group>

                </div>
            </div>
            <div class="property_block scop_block" >
                <div class="section-title head-set" >
                    Scope :<span class="mandatory"></span>
                </div>
                <div class="property_radio" >
                    <div *ngIf="scope_type==true">
                        <span class="">{{domain_name}}</span>
                    </div>

                    <div *ngIf="scope_type==false" class="user-detail__item">

                        <div class="dropdown-input">
                            <input [matAutocomplete]="tenant" class="basic-input drp-search" placeholder="Select Tenant"
                                type="search" style="margin: 0px;"  [(ngModel)]="tenant_name"  />
                            <mat-autocomplete (optionSelected)="onSelectTenant($event)"  #tenant="matAutocomplete" cdkScrollable>
                                <ng-container>
                                    <mat-option *ngFor="
                                      let tenant of tenant_list
                                      | search: tenant_name:'tenant.name'
                                      " [value]="tenant.name">{{ tenant.name }}
                                    </mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </div>
                    </div>

                </div>
            </div>
            

        </div>
    </div>
    <div class="form_container_main">
        <div *ngIf="isLoading" class="spinner_position">
            <div class="mt-3 flex justify-center">
              <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
            </div>
          </div>
        <form [formGroup]="propertyForm" class="property_Form" >
            <div class="property_form_wraper">
                <mat-accordion multi>
                    <mat-expansion-panel [expanded]="true" *ngFor="let data of default_data"  >
                      <mat-expansion-panel-header class="tenant-setting-expansion-header panelheader">
                        <mat-panel-title>
                          <div class="font-bold color-555">{{data.section}}</div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="tanant-item__container mt10 ">
                        <div class="tanant-item" *ngFor="let propertyItem of data.properties">
                          <div class="tanant-item__content">
                            <div class="tanant-item__content__title" style="text-transform:capitalize !important">{{propertyItem.displayLabel}}</div>
                            <!-- <div class="tanant-item__content__description"  style="padding-left:10px;margin-top: -5px"><button [title]="propertyItem.description" >
                                <i class="fa fa-question-circle color4"></i>
                              </button></div> -->
                              <div class="tanant-item__content__description">{{propertyItem.description}}</div>
                          </div>
                          <div class="tanant-item__form-field m-auto" *ngIf="propertyItem.displayType=='text'">
                            <span >
                              <input formControlName="{{propertyItem.key}}" (input)="onValueChangeEvent($event,propertyItem.key)" type="text"  [value]="propertyItem.value" class="basic-input">
                            </span>
                          </div>
                          <div class="tanant-item__form-field m-auto" *ngIf="propertyItem.displayType=='textArea'">
                            <span >
                                <textarea formControlName="{{propertyItem.key}}" ng-model="myTextarea"  [value]="propertyItem.value" class="basic-input" ></textarea>
                            </span>
                          </div>
                          <div class="tanant-item__form-field m-auto" *ngIf="propertyItem.displayType=='days'">
                            <span >
                                <div class="control_price_wrapper"  > 
                                    <input formControlName="{{propertyItem.key}}" (input)="onValueChangeEvent($event,propertyItem.key)" type="number" [value]="propertyItem.value"  min="1" max="31"  class="m-0 basic-input control_price" placeholder="0"/>
                                    <span  class="control_label">Days</span>
                                </div>
                            </span>
                          </div>
                          <div class="tanant-item__form-field m-auto" *ngIf="propertyItem.displayType=='dropdown'">
                            <span >
                                <select formControlName="{{propertyItem.key}}" (change)="onValueChangeEvent($event, propertyItem.key)"  [name]="propertyItem.key">
                                    <option *ngFor="let option of propertyItem.dropdownValues" [value]="option.value">{{option.label}}</option>
                                </select>
                            </span>
                          </div>
                          
                          <div class="m-auto-ml-5px" style="margin: none;" *ngIf="propertyItem.reset">
                            <button title="Reset" (click)="actionDialog($event,propertyItem.key,'reset')" >
                              <i class="fa fa-history color4"></i>
                            </button>
                          </div>
                          <div class="m-auto-ml-5px" style="margin: none;" *ngIf="propertyItem.isallowDelete">
                            <button title="Reset" (click)="actionDialog($event,propertyItem.key,'delete')" >
                              <i class="fa fa-trash-o color4"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      
                    </mat-expansion-panel>
                  </mat-accordion>
                
                <!-- <div class="">
                    <div class="px-7">
                        <div class="" >
                
                            <div class="card-body p-0" >
                                <div class="mb-2">
                                    <div *ngFor="
                                        let field of default_data;let ind=index" style="width: 100%;">
                                        <hr *ngIf="field.isSaparator">
                                        <div class="property_form_container" >
                                            <div class="property_form_container_label"  >
                                                {{field.displayLabel}}
                                            </div>
                                            <div class="property_form_info" >
                                                <i class="fa fa-question-circle" *ngIf="field.displayType!='textArea'" [title]="field.description" data-toggle="tooltip" data-placement="right"
                                                tooltip  aria-hidden="true"></i>
                                            </div>
                                            <div class="property_form_control"  *ngIf="field.displayType=='text'">
                                                <div class="text-center" >
                                                    <input formControlName="{{field.key}}" (input)="onValueChangeEvent($event,field.key)" type="text"  [value]="field.value"   class="basic-input" >
                                                </div>
                                            </div>
                                            <div class="property_form_control" style="margin: 5px;" *ngIf="field.displayType=='textArea'">
                                                <div class="text-center" >
                                                    <textarea formControlName="{{field.key}}" ng-model="myTextarea"  [value]="field.value" class="basic-input" ></textarea>
                                                </div>
                                            </div>
                                            <div class="property_form_control"  *ngIf="field.displayType=='days'">
                                                <div class="" >
                                                    <input formControlName="{{field.key}}" type="number" [value]="field.value"  min="1" max="31"  class="m-0 basic-input control_price" placeholder="0"/>
                                                    <span  class="control_label">Days</span>
                                                  </div>
                                            </div>
                                            <div class="property_form_icon" (click)="actionDialog($event,field.key,'reset')" *ngIf="field.displayType!='textArea' && field.reset" >
                                                <i class="fa fa-history"   aria-hidden="true"></i>
                                            </div>
                                            <div class="property_form_icon">
                                                <i class="fa fa-trash-o" (click)="actionDialog($event,field.key,'delete')" *ngIf="field.displayType!='textArea' && field.isallowDelete"  aria-hidden="true"></i>
                                            </div>
            
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
        
                </div> -->
            </div>
        
        
            
        </form>
    </div>
    
    


</div>