import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    transform(value: any, args: any): string {
        let limit;
        if (args && !args.length) {
             limit = args;
        } else if (args.length > 0) {
             limit = parseInt(args[0], 10);
        } else {
            limit = 50;
        }
        let trail = args.length > 1 ? args[1] : '...';
        if (value){
            return value.length > limit ? value.substring(0, (limit as any)) + trail : value;
        } else {
            return '';
        }
    }

}
