export enum CustodyActions {
  Checkout = "CHECKOUT",
  Transfer = "TRANSFER",
  InService = "SERVICE",
  CheckIn = "CHECKIN",
  Return = "RETURN",
  Confirm = "ACCEPT",
  CancelledCheckout = "CANCELLED_CHECKOUT",
  CancelledTransfer = "CANCELLED_TRANSFER",
  CancelledReturn = "CANCELLED_RETURN",
  AuditAsset = "AUDIT_ASSET",
  AuditUser = "AUDIT_USER",
}

export interface SensorTag {
  id: string;
  type: string;
  tenantId: string;
}

export interface Asset {
  id: string;
  externalId: string;
  type: string;
  typeName: string;
  name: string;
  tenantId: string;
  status: string;
  sortOrder: number;
  createdAt: number;
  modifiedAt: number;
  createdBy: string;
  shipmentId: string;
  shipmentName: string;
  bindFlow: boolean;
  categoryName: string;
  category: string;
}

export interface Position {
  lat: number;
  lon: number;
}

export interface TaggedAsset {
  currentViolations: any[];
  id: string;
  assetId: string;
  status: string;
  createdAt: number;
  modifiedAt: number;
  tenantId: string;
  sensorTag: SensorTag;
  asset: Asset;
  tenantName: string;
  shipmentId: string;
  shipmentName: string;
  currentConnectorId: string;
  lastSeen: number;
  position: Position;
  areaName: string;
  buildingName: string;
  lastBuildingName: string;
  lastAreaName: string;
  assetViolated: boolean;
  sourceType: string;
  currentSiteId: string;
  trackingId: string;
  trackingNumber: string;
  deleted: boolean;
}

export interface AssetSearchHit {
  cost: string;
  modifiedAt: string;
  warehouseLocation: string;
  maintenanceDate: string;
  externalId: string;
  baseClass: string;
  type: string;
  manufacturer: string;
  createdAt: string;
  condition: string;
  qrCode: string;
  createdBy: string;
  taggedAssetId: string;
  clfMappingType: string;
  name: string;
  tenantId: string;
  typeId: string;
  id: string;
  state: string;
  sku: string;
  classType: string;
  status: string;
  locationId: string;
  position: string;
  entityTypeKey:string;
}
