import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cl-export-panel',
  templateUrl: './export-panel.component.html',
  styleUrls: ['./export-panel.component.scss']
})
export class ExportPanelComponent implements OnInit {
  @Output() exportEmitter = new EventEmitter();
  public showToggleContainer: boolean = false;
  public exportType: string = 'pdf';
  public toolTip: string = 'Download table data';
  public columnList = [
    //{name: 'PDF', value: 'pdf'},
    {name: 'CSV', value: 'csv', tooltip: 'Only data for columns shown will be downloaded. Data values in downloaded file will be comma separated. A maximum of 100,000 records can be downloaded.'}
  ]

  constructor() { }

  toggleContainer() {
    this.showToggleContainer = !this.showToggleContainer;
  }

  exportChangeHandler(col: any) {
    this.exportType = col.value;
    this.exportEmitter.emit(this.exportType);
  }
  ngOnInit(): void {
  }

}
