import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cl-refresh-countdown',
  templateUrl: './refresh-countdown.component.html',
  styleUrls: ['./refresh-countdown.component.scss']
})
export class RefreshCountdownComponent implements OnInit, OnChanges {
  @Input() start: boolean;
  @Input() timer: number;
  @Input() actionMessage: string;
  @Output() refreshList = new EventEmitter();
  timeInstance: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.start) {
        this.timeInstance = setInterval(() => {
          this.updateTimer();
        }, 1000)
    }
  }

  updateTimer() {
    if (this.timer == 1) {
      clearInterval(this.timeInstance);
      this.refreshList.emit();
    } else {
      this.timer = this.timer - 1;
    }
  }

}
