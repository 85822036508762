import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssetCategory,
  Bisector,
  BulkActionResponse,
  CustodyActions,
  CustodyChainResponse,
  CustodyChainState,
  Entity,
  SensorCategory,
} from "../../common/models/index";
import { Observable, throwError } from "rxjs";
import { map, retry, shareReplay } from "rxjs/operators";
import { GraphResponse } from "../graph-response";
import { environment } from '../../../environments/environment';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Injectable({
  providedIn: "root",
})
export class GraphAPIService {
  allData: GraphResponse;

  private _assetStateValidation$: Observable<CustodyChainState>;

  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService
  ) {

  }

  private get headers() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      accept: "*/*",
    });
  }

  getRules() {
    let rules;
    return new Promise((resolve, reject) => {
      if (this.allData && this.allData.nodes && this.allData.nodes.length) {
        rules = this.allData.nodes.filter((node) => {
          return node.category === "rule";
        });
        resolve(rules);
      } else {
        this.getAllData(true).then((allData) => {
          if (allData && allData.nodes && allData.nodes.length) {
            rules = allData.nodes.filter((node) => {
              return node.category === "rule";
            });
            resolve(rules);
          } else {
            return [];
          }
          return null;
        });
      }
    });
  }
  getAllData(refresh: any): Promise<GraphResponse> {
    return new Promise((resolve, reject) => {
      if (!refresh && this.allData && this.allData.nodes && this.allData.nodes.length) {
        resolve(this.allData);
      } else {
        this.getAllGraphData().subscribe((res) => {
          // console.log('get Nodes got res', res);
          this.allData = res;
          resolve(res);
        });
      }
    });
  }
  getAllGraphData(): Observable<GraphResponse> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}database/all`;
    return this.http.get<GraphResponse>(url).pipe(
      map((res) => {
        return new GraphResponse().deserialize(res);
      })
    );
  }

  getVertexTree(el: any, depth: any): Observable<GraphResponse> {
    let params = new HttpParams()
      .set("id", el.id || el.uid)
      .set("depth", depth)
      .set("direction", "out");

    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}traverse`;

    return this.http.get<GraphResponse>(url, { params: params }).pipe(
      map((res) => {
        return new GraphResponse().deserialize(res);
      })
    );
  }
  getKLVertexTree(el: any, depth: any): Observable<GraphResponse> {
    let params = new HttpParams().set("id", el.id).set("type", el.clfEntityType).set("depth", depth).set("direction", "out");

    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}keylines/linkchart/traverse`;

    return this.http.get<GraphResponse>(url, {
      params: params
    });
  }
  getAllNodesByType(type: any): Observable<GraphResponse> {
    let url = `${this.apiProvider.getAPIUrl('clfgraphapp')}vertex/type/${type}`;
    return this.http.get<GraphResponse>(url);
  }
  getNodeDetails(id: any): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}vertex/${id}`;
    return this.http.get<any>(url);
  }

  getNode(id: any, type: any): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}type/${id}`;
    return this.http.get<any>(url);
  }
  getAddressString(entity: any) {
    if (!entity.addressObj) {
      return typeof entity.address === "string" ? entity.address : "";
    }
    let addressString = "";
    addressString += entity.addressObj.streetNumber ? entity.addressObj.streetNumber + " " : "";
    addressString += entity.addressObj.streetAddr ? entity.addressObj.streetAddr + ", " : "";
    addressString += entity.addressObj.city ? entity.addressObj.city + ", " : "";
    addressString += entity.addressObj.stateCode ? entity.addressObj.stateCode + " " : "";
    addressString += entity.addressObj.postalCode ? entity.addressObj.postalCode + ", " : "";
    addressString += entity.addressObj.countryCode ? entity.addressObj.countryCode : "";
    // console.log('addressString', addressString);
    return addressString;
  }
  deleteVertice(entity: Entity): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}vertex/${entity.uid}`;
    console.log("deleteVertice", url, entity.name);
    return this.http.delete<Entity>(url);
  }
  deleteGraphNode(type: any, uid: any): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}${type}/${uid}`;
    return this.http.delete<Entity>(url);
  }
  deleteEdge(link: Bisector): Observable<Bisector> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}edge/from${link.sourceID}/to/${link.targetID}`;
    return this.http.delete<Bisector>(url);
  }

  updateGraphNode(body: any, type: any, uid: any): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}${type}/${uid}`;
    return this.http.put<Entity>(url, body);
  }
  createGraphNode(entity: any, type: any): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}${type}`;
    return this.http.post<Entity>(url, entity);
  }

  createSite(payload: any): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('cloudos')}api/1/publish/entity/location/data`;
    return this.http.post(url, payload);
  }

  updateSite(payload: any, siteId: string) {
    const url = `${this.apiProvider.getAPIUrl('cloudos')}api/1/publish/entity/location/data/${siteId}`;
    return this.http.put(url, payload);
  }

  bootstrapEntity(entity: "asset", userId: string, email: string): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}tool/bootstrap/${entity}`;
    const params = { userId, email };
    return this.http.get<any>(url, { params, headers: this.headers });
  }

  createGraphLink(link: Bisector): Observable<Bisector> {
    const headers = {
      "Content-Type": "application/json",
    };
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}edge`;
    let body = {
      name: link.name,
      from: link.sourceID,
      to: link.targetID,
      type: link.template,
      classType: link.template,
    };
    console.log("create link", link, body, headers);
    return this.http.post<Bisector>(url, body, { headers: headers });
  }
  handleError(err: Response) {
    console.log("got err", err);
    return throwError(err.statusText);
  }

  // ------------------------------------------------------
  // #region Asset APIs
  // ------------------------------------------------------

  getAsset(assetId: string, nodeClass = ""): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/asset/${assetId}`;
    return this.http.get<Entity>(url).pipe(
      map((res) => {
        return new Entity().deserialize(res, nodeClass);
      })
    );
  }

  bindAsset(assetId: string, sensorId: string, componentId: string): Observable<Entity> {
    const body = {
      assetId: assetId,
      sensorId: sensorId,
      componentId: componentId
    };
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset`;
    return this.http.post<Entity>(url, body);
  }

  unbindAsset(taggedAssetId: string): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
    };

    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}taggedasset/${taggedAssetId}`;
    return this.http.delete<any>(url, { headers: headers });
  }

  deleteAssets(uids: any): Observable<Entity> {
    const body = {
      assetIds: uids,
    };
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/delete`;
    return this.http.post<Entity>(url, body);
  }

  get assetStateValidation$(): Observable<CustodyChainState> {
    if (!this._assetStateValidation$) {
      this._assetStateValidation$ = this.getAssetStateValidationEnums().pipe(
        // Cache the response for later usage
        shareReplay(1)
      );
    }

    return this._assetStateValidation$;
  }

  private getAssetStateValidationEnums(): Observable<CustodyChainState> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      accept: "*/*",
    });
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/state/validation`;

    return this.http.get<CustodyChainState>(url, {headers}).pipe(retry(1));
  }

  getAssetCustodyChain(taggedAssetId: string, limit = 5000): Observable<CustodyChainResponse> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/events/Asset/custody/${taggedAssetId}`;

    const params = {
      limit: `${limit}`,
    };

    return this.http.get<CustodyChainResponse>(url, { params, headers: this.headers });
  }

  checkoutAsset(payload: { assetId: string; userId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/checkout`;

    return this.http.post<Entity>(url, payload);
  }

  transferAsset(payload: { assetId: string; userId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/transfer`;

    return this.http.post<Entity>(url, payload);
  }

  transferAssetInService(payload: { assetId: string; userId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/service`;

    return this.http.post<Entity>(url, payload);
  }

  confirmReceiptAsset(payload: { assetId: string; condition: boolean }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/accept`;

    return this.http.post<Entity>(url, payload);
  }

  checkInAsset(payload: { assetId: string; condition: boolean }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/checkin`;

    return this.http.post<Entity>(url, payload);
  }

  returnAsset(payload: { assetId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/return`;
    return this.http.post<Entity>(url, payload);
  }

  revokeAsset(payload: { assetId: string }): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}asset/revoke`;

    return this.http.post<Entity>(url, payload);
  }

  bulkCoc(payload: any[], operation: CustodyActions) {
    const actionUrlMap = {
      [CustodyActions.Checkout]: "asset/checkout/bulk",
      [CustodyActions.Transfer]: "asset/transfer/bulk",
      [CustodyActions.InService]: "asset/service/bulk",
      [CustodyActions.Confirm]: "asset/accept/bulk",
      [CustodyActions.CheckIn]: "asset/checkin/bulk",
      [CustodyActions.Return]: "asset/return/bulk",
      [CustodyActions.CancelledCheckout]: "asset/revoke/bulk",
      [CustodyActions.CancelledReturn]: "asset/revoke/bulk",
      [CustodyActions.CancelledTransfer]: "asset/revoke/bulk",
    };

    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/${actionUrlMap[operation]}`;
    return this.http.post<Entity>(url, payload);
  }

  // ------------------------------------------------------
  // #endregion
  // ------------------------------------------------------

  // ------------------------------------------------------
  // #region Asset Categories CURD
  // ------------------------------------------------------

  getCategories(type: any): Observable<AssetCategory[]> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}category/type/${type}`;

    return this.http
      .get<AssetCategory[]>(url)
      .pipe(map((res) => res.sort((a, b) => ((a?.properties as any)?.name?.toLowerCase() < (b?.properties as any)?.name?.toLowerCase() ? -1 : 1))));
  }

  saveCategory(payload: AssetCategory): Observable<AssetCategory> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}category`;

    return this.http.post<AssetCategory>(url, payload, {
      headers: this.headers,
    });
  }

  updateCategory(categoryId: string, payload: AssetCategory): Observable<AssetCategory> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}category/${categoryId}`;

    return this.http.put<AssetCategory>(url, payload);
  }

  deleteCategory(categoryId: string): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}category/${categoryId}`;

    return this.http.delete<any>(url);
  }

  // ------------------------------------------------------
  // #endregion
  // ------------------------------------------------------

  // ------------------------------------------------------
  // #region Sensor APIs
  // ------------------------------------------------------

  getSensor(sensorId: string): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}sensor/${sensorId}`;
    return this.http.get<Entity>(url).pipe(
      map((res) => {
        return new Entity().deserialize(res, "sensor");
      })
    );
  }

  unbindSensors(sensorIds: string[]): Observable<BulkActionResponse> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset/${sensorIds}`;

    const payload = { sensorIds };

    return this.http.delete<BulkActionResponse>(url, {
      headers: this.headers
    });
  }

  unBindAssetFromSensor(sensorIds: any,taggedAssetId: any,): Observable<BulkActionResponse> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/taggedasset/${taggedAssetId}/sensor/${sensorIds}`;

    const payload = { sensorIds };

    return this.http.delete<BulkActionResponse>(url, {
      headers: this.headers
    });
  }

  bindGateway(payload: any): Observable<Entity> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/provision`;
    return this.http.post<Entity>(url, payload, {headers: this.headers});
  }
  
  unbindGateway(gatewayId: string): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/unprovision/${gatewayId}`;

    return this.http.delete<any>(url, {
      headers: this.headers
    });
  }

  loadSensorAnalysis() {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}sensor/analysis`;
    return this.http.post<any>(url, {});
  }
  deleteSensors(sensorIds: string[]): Observable<any> {
    const url = `${this.apiProvider.getAPIUrl('clfgraphapp')}sensor/delete`;

    const payload = { sensorIds };

    return this.http.post<any>(url, payload);
  }

  // ------------------------------------------------------
  // #endregion
  // ------------------------------------------------------
}
