import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

function b(a?: any) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : `${1e16}${1e16}`.replace(/[01]/g, b);
}

@Injectable()
export class CsrfTokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Generate token append to cookie and header

    const token = b();
    this.setCookie(token);

    const headers = request.headers.append("x-csrf-token", token);
    request = request.clone({ headers });
    // this.setHeader(request, token);

    return next.handle(request);
  }

  private setCookie(token: string): void {
    document.cookie = "CSRF-TOKEN=" + token + "; path=/graph-ui/; secure";
  }

  private setHeader(request: HttpRequest<unknown>, token: string) {
    request.headers.append("x-csrf-token", token);
    request = request.clone();
    console.log(request.headers);
  }
}
