import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions
} from "ng-apexcharts";

@Component({
  selector: 'cl-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input('chartData') data: ApexAxisChartSeries;
  @Input('chartXData') xData: any;
  @Input('label') label: string = '';
  public options: ApexChart;
  public dataLabels: ApexDataLabels;
  public xaxis: ApexXAxis = {
    categories: []
  };
  plotOptions: ApexPlotOptions;
  series: ApexAxisChartSeries;

  constructor() {
  }

  ngOnInit(): void {
    this.options = {
      type: "bar",
      height: 250
    };
    this.plotOptions = {
      bar: {
        horizontal: true
      }
    };
    this.dataLabels = {
      enabled: false
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.xData) {
      this.xaxis.categories = this.xData;
    }
  }

}
