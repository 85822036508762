import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CdmField } from '@cl/@types/extended-attributes.types';
import { CL_INPUT_DEBOUNCE_TIME_HIGH } from '@cl/constants';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { debounceTime, distinctUntilChanged, Subject, Subscription, takeUntil } from 'rxjs';
import { LIST_ACTION, ShipmentListStateService } from '../shipment-list-state.service';
import { UserService } from '@cl/user/user.service';
import { ToastService } from '@cl/common/services/toast.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'cl-shipment-list-actions',
  templateUrl: './shipment-list-actions.component.html',
  styleUrls: ['./shipment-list-actions.component.scss'],
})
export class ShipmentListActionsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  rawColumns: any = [];
  searchTerm = '';
  showMyContainer: boolean = false;
  status: boolean = false;
  tagsList: any;
  tagsCheckedCount: any = [];
  showManageTags: boolean = true;
  showRuleset: boolean = true;

  @Output() editClick = new EventEmitter<'basic' | 'composite'>();
  @Output() terminateClick = new EventEmitter<void>();
  @Output() completeClick = new EventEmitter<void>();
  @Output() deleteClick = new EventEmitter<void>();
  @Output() closeSidePanel = new EventEmitter<void>();
  @Output() reloadShipments = new EventEmitter<void>();

  // These properties are accessable to shipment-list component to change the status of edit button
  public isEditBtnDisabled = true;
  public isEnableManageTags = false;
  public isEnableTerminate = false;
  public isEnableComplete = false;
  public isEnableDelete = false;
  public shipmentData:any = {};

  actionBtnPolicies = {
    terminate: ['SHIPMENT_TERMINATE'],
    delete: ['SHIPMENT_DELETE'],
    create: ['SHIPMENT_CREATE'],
    edit: ['SHIPMENT_UPDATE'],
    ruleset: ['SHIPMENT_CREATE', 'SHIPMENT_UPDATE']
  };

  readonly catalogType = this._shipmentListState.catalogType;
  private readonly destroyed$ = new Subject<boolean>();
  searchFormGroup: FormGroup;
  listSubscription: Subscription;
  readonly searchInfoSystemAttributes = ['Name', 'Shipment ID', 'Shipper Name', 'Tracking ID', 'Order Number', 'Customer Name', 'Customer Ref Num', 'Carrier Name', 'Origin Name', 'Destination Name', 'Route Name', 'Tags']
  searchInfoAttributes = []
  isDTPUser = this.userService.isDTPUser()
  constructor(
    private _shipmentListState: ShipmentListStateService,
    private shipmentService: ShipmentService,
    private userService: UserService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.rawColumns = this._shipmentListState.settings.list.rawColumns;
    this.searchTerm = this._shipmentListState.settings.filters.search;

    this.listSubscription =
      this._shipmentListState.listSettingsObservable.subscribe((settings) => {
        if (settings.action === LIST_ACTION.COLUMNS_TOGGLED) {
          this.rawColumns = [...settings.list.rawColumns];
        }
      });
    
    this.searchFormGroup = new FormGroup({
      searchTerm: new FormControl(),
    });
    this.showTagsList();
    this.getSearchableAttributes()
  }

  async getSearchableAttributes() {
    const response = await this._shipmentListState.getShipmentMultiCatalogTypesWithCDM();
    const usercdmFields: CdmField[][] = response.map((res) => res.cdmFields);

    const userSearchableFields = new Set();
    usercdmFields.forEach((shipmentType) => {
      shipmentType.forEach((cdmField) => {
        if (cdmField.group === 'User' && cdmField.searchable) {
          userSearchableFields.add(cdmField.displayLabel);
        }
      });
    });
    this.searchInfoAttributes = [...this.searchInfoSystemAttributes,...Array.from(userSearchableFields)]
  }

  onCloseSidePanel(){
    this.closeSidePanel.emit(null)
  }

  ngAfterViewInit(): void {
    this.fcSearchTerm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH),
        distinctUntilChanged()
      )
      .subscribe((evt) => {
        this.onSearchInput(evt);
      });
  }

  onSearchInput(evt: any) {
    const searchTerm = this.searchFormGroup.value.searchTerm;
    this._shipmentListState.searchShipments(searchTerm);
  }

  updateFilter(term) {
    this.searchFormGroup.patchValue({
      searchTerm: term.toLowerCase(),
    });
  }

  get fcSearchTerm(): FormControl {
    return this.searchFormGroup.get('searchTerm') as FormControl;
  }
  downloadReport(ev: any) {
    if (ev.toString().toLowerCase() == 'csv') {
      this._shipmentListState.exportShipmentsCSV();
    }
  }

  gridColumnToggle(columns: any) {
    this._shipmentListState.gridColumnToggle(columns);
  }

  toggleFilterPanel() {
    this._shipmentListState.toggleFilters();
  }
  showTagsList() {
    this.status = !this.status;
    var payload = {
      object_type: '',
      _scroll_id: '',
      _scroll_size: '1000',
    };
    this.shipmentService.getTagsList(payload).subscribe((data) => {
      this.tagsList = data;
    });
  }

  checkedtag() {
    this.tagsCheckedCount = 0;
    this.tagsList.hits.forEach((item) => {
      if (item.checked) {
        this.tagsCheckedCount = this.tagsCheckedCount + 1;
      }
    });
  }
  clearCheckedTags() {
    if (this.tagsCheckedCount != 0) {
      this.tagsList.hits.forEach((e) => {
        e.checked = false;
        this.tagsCheckedCount = 0;
      });
    }
  }
  eventFromManageTags(data) {
    if (data.tags != '') {
      this._shipmentListState.reloadShipmentTable();
      this.showManageTags = true;
    } else {
      this.showManageTags = true;
    }
  }

  showEditPanel(shipmentType: 'basic' | 'composite') {
    this.editClick.emit(shipmentType);
  }

  onTerminate() {
    this.terminateClick.emit();
  }

  onDelete() {
    this.deleteClick.emit();
  }

  onComplete() {
    this.completeClick.emit();
  }

  eventFromRuelSet() {
    this.showRuleset = true;
  }
  toggleSearchColumns(){
    this._shipmentListState.toggleSearchColumns();
    if(this._shipmentListState.checkSearchColumnExist()){
      this._shipmentListState.updateSearchFilters([])
    }
    
  }

  applyRuleSet(applyRulesetPayload){
    this.shipmentService
      .applyRuleSet(applyRulesetPayload)
      .subscribe((data) => {
        this.formatRulesetStatus(data);
        this.reloadShipments.emit();
      });
      this.eventFromRuelSet()
  }

  formatRulesetStatus(data) {
    if(!data?.shipments?.length) return;

    const failedShipments = data.shipments.filter(shipment => shipment.status == "FAIL").map(shipment => `• ${shipment?.externalId}`);
    const totalShipmentsCount = data.shipments.length;
    const failedShipmentsCount = failedShipments.length;

    const successShipmentsCount = totalShipmentsCount - failedShipmentsCount;

    let successHeading = `Ruleset for all shipments updated successfully.`;
    let partialErrorHeading = `Ruleset for ${failedShipmentsCount} out of ${totalShipmentsCount} shipments failed to update. External IDs for failed shipments:`;
    let partialSuccessHeading = `Ruleset for ${successShipmentsCount} out of ${totalShipmentsCount} shipments updated successfully.`;

    let heading = successHeading;
    let body = [];
    let panelClass = ['toast-success'];
    let isAutoClose = true;

    if(failedShipmentsCount){
      body = failedShipments;
      isAutoClose = false;
      if(failedShipmentsCount == totalShipmentsCount){
        panelClass = ['toast-error'];
        heading = 'Ruleset for all shipments failed to update. External IDs for failed shipments:';
      }else{
        panelClass = ['toast-warning'];
        heading = `${partialSuccessHeading} <br>${partialErrorHeading}`
      }
    }
    this.toastService.openCloseHandlerSnackBar(body, heading, panelClass, isAutoClose);
  }
  ngOnDestroy(): void {
    this.destroyed$.complete();
    this._shipmentListState.reset()
    this.listSubscription.unsubscribe();
  }
}
