import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { HelpDialogComponent, helpDialogModel } from '../help-dialog/help-dialog.component';

@Component({
  selector: 'cl-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.scss']
})
export class ListSearchComponent implements OnInit {
  searchTearm = '';
  modelChanged: Subject<string> = new Subject<string>();

  @Input() placeholder = "Search";
  @Input() defalutSearchTerm:string = '';
  @Input() columns = [];
  helpText = 'Below columns are used for search';
  @Output() search = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.searchTearm = this.defalutSearchTerm;
    this.modelChanged
    .pipe(debounceTime(500), distinctUntilChanged()) 
            .subscribe(data=>{
              this.search.emit(this.searchTearm);
            });
  }

  tiggerSearchEvent(){
    this.modelChanged.next(this.searchTearm);
  }
  
  openHelpDialog(){
    const dialogData = new helpDialogModel("Search Help Info", this.helpText, this.columns);
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

  }
}
