import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { AssetUtilService } from '../../../asset/asset-util.service';
import { Asset } from '@cl/@types/asset.type';
import { UtilsService } from '../../utils/utils.service';
import { LocationsService } from '../../../locations/locations.service';
import _ from 'lodash';
import { LocationUtilsService } from '@cl/locations/location-utils.service';
import { PropertyService } from '@cl/property/property.service';
import moment from 'moment';
import { AssetBasicInformationProperties, CdmField } from '@cl/@types/location.types';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cl-location-summary',
  templateUrl: './location-summary.component.html',
  styleUrls: ['./location-summary.component.scss']
})
export class LocationSummaryComponent implements OnInit, OnChanges {
  @Input() locationId: any;
  @Input() locationType: any;
  @Output() closeSidePanel = new EventEmitter();
  @Output() filterSummary = new EventEmitter();
  @Input() entityTypeKey = '';

  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  customFields = ['name','source','destination','shipments'];
  customFieldsLocation = ['shipmentIB','shipmentOB','returnShipments','assets','areaFenceType','geoFenceType'];
  modifiedFields = ['createdBy','createdAt','modifiedBy','modifiedAt'];
  modifiedOrder=[];


  activePane: string = 'summary';
  locationObject: any;
  arrRouteSummary: any;
  visits: any[] = [];
  custodyList: any[] = [];
  assetCount: number;
  loading: boolean;
  formattedTotalTravelHrs: any;
  dateDisplayFormat:any;
  attributes:any;
  connectedArea:any;
  originEntityTypeKey:any;
  destinationEntityKey:any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private locationsService: LocationsService,
    private _locationUtils:LocationUtilsService,
    private _dynamicFormService: DynamicFormService,
    private pService: PropertyService,
    public datePipe : FormateDatePipe,
    private router: Router,
    private utils: UtilsService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.dateDisplayFormat = this.pService.getSetting('ui.dateFormat');
    this.dateDisplayFormat = this.dateDisplayFormat.replace('dd', 'DD');
    this.dateDisplayFormat = this.dateDisplayFormat.replace('a', 'A');

    if (this.locationId && this.locationType.toLowerCase() !== 'route') {
      this.loading = true;
      if(this.locationId === this.arrRouteSummary?.destinationId){
        this.entityTypeKey = this.destinationEntityKey;
      }
      if(this.locationId === this.arrRouteSummary?.originId){
        this.entityTypeKey = this.originEntityTypeKey;
      }
      this.locationsService.getLocationsAndAssets(this.locationId, this.entityTypeKey)
        .subscribe((response: any) => {
          this.getAssetCount(response.assetDistributionByArea);
          this.loading = false;          
          this.locationObject = this.calculateIBandOB(response)
          this.getExtendedAttributes(this.locationObject.summary.type);
          this._changeDetectorRef.detectChanges();
        });
    }
    if (this.locationId && this.locationType.toLowerCase() === 'route') {
      var type = '';
      if(this.locationType.toLowerCase() === 'route' && this.entityTypeKey == 'area'){
        type = this.locationType.toLowerCase();
      }else{
        type = this.entityTypeKey
      }
      this.loading = true;
      this.locationsService.getLocationsAndRoute(this.locationId, type)
        .subscribe((response: any) => {          
          this.loading = false;          
          this.getExtendedAttributes(response?.summary?.type);
          this.arrRouteSummary = response && response.summary.properties;
          this.connectedArea = response.connectedAreas.nodes;
          this.connectedArea.forEach((res)=>{
            if(this.arrRouteSummary?.originId === res.properties?.id){
              this.originEntityTypeKey = res.properties?.entityTypeKey;
            }
            if(this.arrRouteSummary?.destinationId === res.properties?.id){
              this.destinationEntityKey = res.properties?.entityTypeKey;
            }
          })
          this.arrRouteSummary.shipmentsLength = response?.connectedShipments?.nodes.length          
          if (this.arrRouteSummary && this.arrRouteSummary.locationDetails) {
            if(typeof this.arrRouteSummary.locationDetails == 'string'){
              this.arrRouteSummary.locationDetails = JSON.parse(
                this.arrRouteSummary.locationDetails
              );
            }
            this.calculateTotalRouteHours(this.arrRouteSummary.locationDetails)
          }
          this._changeDetectorRef.detectChanges();
        });        
    }
  }
  async getExtendedAttributes(location){
    const locationCatalog = await this._locationUtils.getLocationExtendedAttributes(location);
    const fields = locationCatalog.cdmFields
    this.attributes= this._dynamicFormService.orderFields([...fields],'order');
    this.attributes=  this.attributes.filter(inp=>inp.name != "coordinates")
    this.attributes.forEach(inp => {
      if(this.modifiedFields.includes(inp.name)){
         this.modifiedOrder.push(inp.order)
      }
    })
    
    this.modifiedOrder.sort()
    let order = this.modifiedOrder[0]-1;
    if(this.locationType.toLowerCase() !== 'route'){
      this.customFieldsLocation.forEach(field=>{
        if(!["areaFenceType","geoFenceType"].includes(field)){
          this.attributes.push({
            name:field,
            order:order,
            isDisplayableOnSummary:true,
            modifiedField:true
           })
        }
  
      })
    }else{
      this.attributes.push({
        name:"shipments",
        order:order,
        isDisplayableOnSummary:true,
        modifiedField:true
       })
      }

    this.attributes= this._dynamicFormService.orderFields([...this.attributes],'order');
  }
  isStaticPropertyAvailable(id: string){
    let staticProperties : string[] = this._locationUtils.locationStaticProperties;
    return !_.includes(staticProperties, id);
  }

  getExtendedAttributeValue(cdmField : CdmField, properties: AssetBasicInformationProperties){
    if(cdmField['modifiedField']){
      return true
    }else if(cdmField){
      if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
        if(cdmField?.type?.toUpperCase()==='DATE'){
          let data = properties[cdmField?.name];
          return this.datePipe.transform(data, 'default');
        } else {     
          if(cdmField?.name == 'position'){
            let lat = Number(properties[cdmField?.name]?.split(',')[0])
            let lan = Number(properties[cdmField?.name]?.split(',')[1])
            return lat?.toFixed(6)+","+lan?.toFixed(6)
          }
          if(cdmField?.name == 'parentLocation'){         
            return properties['buildingName']
          }
          if(cdmField?.name == 'organization'){
            return properties['organizationName']
          }else{
            return properties[cdmField?.name];
          }
        }
      }else{
        return '';
      }
    } else {
      return '';
    }
  }
 convertDateFormat(planned: any) {
    return planned ? moment(planned).format(this.dateDisplayFormat) : '';
  }

  getAssetCount(areaGroups) {
    let tmpAssetGrp: any;
    let tempAreaAsetCount = 0;
    for (let areaId in areaGroups) {
      tmpAssetGrp = areaGroups[areaId];
      tempAreaAsetCount = 0;
      tmpAssetGrp.forEach((item) => {        
        if (item.baseType && item.baseType.toLowerCase() == 'asset') {          
          tempAreaAsetCount++;
        }
      });
    }    
    this.assetCount = tempAreaAsetCount;
  }

  toggleActivePane(pane: string) {
    this.activePane = pane;
    this._changeDetectorRef.detectChanges();
  }

  closePanel() {
    this.closeSidePanel.emit();
  }

  calculateTotalRouteHours(locationDetails) {
    if (!locationDetails || !locationDetails.length) return;
    var layoverDays = 0;
    var layoverHrs = 0;
    var layoverMins = 0;
    var travelDays = 0;
    var travelHrs = 0;
    var travelMins = 0;
    locationDetails.forEach(function (item, index) {
      var travel_time = item.travel_time ? item.travel_time.split(':') : '';
      
      if(parseInt(travel_time[0])>0){
        travelDays = travelDays + parseInt(travel_time[0])
      }
      if(parseInt(travel_time[1])>0){
        travelHrs = travelHrs + parseInt(travel_time[1])
      }
      if(parseInt(travel_time[2])>0){
        travelMins = travelMins + parseInt(travel_time[2])
      }
      
      

      if (item.layover) {
        var layover_time = item.layover.split(':');
        if(parseInt(layover_time[0])>0){
          layoverDays = layoverDays + parseInt(layover_time[0])
        }
        if(parseInt(layover_time[1])>0){
          layoverHrs = layoverHrs + parseInt(layover_time[1])
        }
        if(parseInt(layover_time[2])>0){
          layoverMins = layoverMins + parseInt(layover_time[2])
        }
      }
    });

    var totalTravelMillSec = 60000 * ((travelDays * 24 * 60) + (travelHrs * 60) + (travelMins));
    var layOverTravelMillSec = 60000 * ((layoverDays * 24 * 60) + (layoverHrs * 60) + (layoverMins));

    this.formattedTotalTravelHrs = this.formatDuration((totalTravelMillSec + layOverTravelMillSec));
  }

  formatDuration(value, includeSeconds = false) {
    var days = Math.floor(value / 86400000);
    value = value % 86400000;
    var hours = Math.floor(value / 3600000);
    value = value % 3600000;
    var minutes = Math.floor(value / 60000);
    value = value % 60000;
    var seconds = Math.floor(value / 1000);
    return (
      (days ? days + " d, " : "") +
      (hours ? hours + " hr, " : "") +
      (minutes ? minutes + " min " : "") +
      (seconds && includeSeconds ? seconds + " sec " : "")
    );
  };
  calculateIBandOB(obj){
     if(obj.connectedShipments){
      let shipmentDirection = obj.connectedShipments.nodes;
     let shipmentIBCount = shipmentDirection.filter(e=>e.properties.direction == 'Inbound');
     let shipmentOBCount = shipmentDirection.filter(e=>e.properties.direction == 'Outbound');
     let shipmentReturnCount = shipmentDirection.filter(e=>e.properties.direction == 'Return');
     obj.IBCount = shipmentIBCount.length
     obj.OBCount = shipmentOBCount.length
     obj.returnCount = shipmentReturnCount.length
     }
    return obj
  }
  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  getSummary(id, areaEntityTypeKey){
    this.filterSummary.emit({'id':id,'type':'area', 'entityTypeKey': areaEntityTypeKey})
  }
  getFullAddress(value,source){
    let currentAddress =  value.filter(e=>e.areaName == source)
    return currentAddress[0].address;
  }
  navigateToShipments(direction:string){
    let url = `shipments?direction=${direction}`;
    if(direction == 'inbound' || direction == 'return'){
      url += `&destinationAreaId.keyword=${this.locationId}`
    }else if (direction == 'outbound'){
      url += `&originAreaId.keyword=${this.locationId}`
    }
    this.router.navigateByUrl(url)
   }
  
}
