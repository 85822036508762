import { Injectable } from '@angular/core';
import moment from 'moment';
import { ExtendedCatalogAttributeService } from '../services/extended-catalog-attribute.service';
import { PropertyService } from '@cl/property/property.service';

@Injectable({
  providedIn: 'root',
})
export class ShipmentUtilsService {
  private _shipmentExtendedAttributes = {};

  constructor(private _extendedCatalogService: ExtendedCatalogAttributeService, private propertyService:PropertyService) {}

  getShipmentDateRange(shipment, isForAssetInsights = false) {
    const dateRange = {
      startDate: 0,
      endDate: 0,
    };
    let shipmentCompleted = false;

    const now = moment().valueOf();

    let sDate = +shipment.actualDepTime
      ? +shipment.actualDepTime < +shipment.plannedDeparture
        ? +shipment.actualDepTime
        : +shipment.plannedDeparture
      : +shipment.plannedDeparture;
    if(!sDate){
      dateRange.startDate = +shipment.createdAt
    } else{
      dateRange.startDate =
        +shipment.createdAt < +sDate ? +shipment.createdAt : sDate;
    }
    

    if (shipment.completedTime) {
      shipmentCompleted = true;
      dateRange.endDate = +shipment.completedTime;
    } else if (['At Destination', 'In-Transit', 'Ready to Ship', 'Exception'].includes(shipment.statusCode)) {
      dateRange.endDate = now;
    } else if (shipment.plannedArrival) {
      dateRange.endDate = +shipment.plannedArrival;
    } else {
      dateRange.endDate = now;
    }

    if(!dateRange.startDate || isNaN(dateRange.startDate)){
      dateRange.startDate = '' as any;
    }
    if(!dateRange.endDate || isNaN(dateRange.endDate)){
      dateRange.endDate = '' as any;
    }

    if(isForAssetInsights){
      const isFullRangeForTF = this.propertyService.getSetting('ui.enviroment.daterange.fullrange');
      if(isFullRangeForTF === 'false'){
        const deltaTime = Math.abs(dateRange.endDate - dateRange.startDate) / (1000 * 60 * 60 * 24); //Convert into days
        if(deltaTime > 7){
        dateRange.startDate = dateRange.endDate - (7 * 24 * 60 * 60 * 1000) // Setting start date as 7 days before.
        }
      }
    }

    return {
      dateRange: dateRange,
      shipmentCompleted: shipmentCompleted,
    };
  }

  getExcursionIcon(type: string) {
    const mapIconsPath = 'assets/svgs/map-icons/';
    const alertIconsPath = 'assets/svgs/alerts/';
    if (
      [
        'carrier:checkpoint',
      ].includes(type.toLowerCase())
    )
      return alertIconsPath + 'multi.svg';
    if (
      [
        'temperature',
        '37c7bb40-b06a-11e3-8000-b70f3ab862a4:37c7bba1-b06a-11e3-8000-b70f3ab862a4',
      ].includes(type.toLowerCase())
    )
      return mapIconsPath + 'temperature.svg';
    if (
      [
        'cargotemp',
        'ambienttemp',
        'probetemp',
        'dewpoint',
        '37c7bb40-b06a-11e3-8000-b70f3ab862a4:cargotemp',
        '37c7bb40-b06a-11e3-8000-b70f3ab862a4:probetemp',
        '37c7bb40-b06a-11e3-8000-b70f3ab862a4:ambienttemp',
        '37c7bb40-b06a-11e3-8000-b70f3ab862a4:dewpoint',
        '37c7bb40-b06a-11e3-8000-b70f3ab862a4'
      ].includes(type.toLowerCase())
    )
      return alertIconsPath + 'temp.svg';
    if (
      [
        'door',
        'door:doorstatus',
        'complex:dooropenatorigin',
        'complex:dooropenatdestination',
      ].includes(type.toLowerCase())
    )
      return mapIconsPath + 'entry_exit.svg';
    if (['humidity', 'aa20:aa21','aa20'].includes(type.toLowerCase()))
      return mapIconsPath + 'humidity.svg';
    if (['pressure', 'pressure:rxpressure'].includes(type.toLowerCase()))
      return mapIconsPath + 'pressure.svg';
    if (
      [
        'tilt',
        'tilt:rxtilt',
        'vibration:rxvibration',
        'tilt:rxtiltmagnitudex',
        'tilt:rxtiltmagnitudez',
        'tilt:rxtiltmagnitudey',
      ].includes(type.toLowerCase())
    )
      return mapIconsPath + 'vibration.svg';
    if (
      [
        'shock',
        'shock:rxshockmagnitudex',
        'shock:rxshock',
        'shock:rxshockmagnitude',
        'shock:rxshockmagnitudez',
        'shock:rxshockmagnitudey',
      ].includes(type.toLowerCase())
    )
      return alertIconsPath + 'damaged.svg';
    if (['light', 'light:rxlight'].includes(type.toLowerCase()))
      return mapIconsPath + 'light.svg';
    if (
      ['time', 'location:etadelay', 'location:origindelay', 'location:arrivaldelay'].includes(
        type.toLowerCase()
      )
    )
      return alertIconsPath + 'dwell.svg';
    if (['180f:2a19'].includes(type.toLowerCase()))
      return alertIconsPath + 'battery.svg';
    if (
      [ 'predictivemetric',
        'predictivemetric:residualthermalcharge',
        'predictivemetric:residualthermaltime',
      ].includes(type.toLowerCase())
    )
      return alertIconsPath + 'thermaltime.svg';
    return alertIconsPath + 'multi.svg';
  }

  getKPIShipmentLink(type: string, id: string) {
    const knownShipmentURIs = [
      'Ready to Ship',
      'In-Transit',
      'At Destination',
      'Exception',
    ];


    if (type === 'Active Shipments') {
      if (knownShipmentURIs.includes(id)) return `#/shipments?statusCode=${id}`;
      return `#/shipments?statusCode=${knownShipmentURIs.join(',')}`;
    }
    if (
      type === 'Active Shipments with Excursions' ||
      type === 'Active Shipments with Delay'
    ) {
      return `#/shipments?excursions=${id}&statusCode=${knownShipmentURIs.join(
        ','
      )}`;
    }
    return '#';
  }

  async getShipmentExtendedAttributes(
    shipmentType: 'standardshipment' | 'parcelshipment' | 'dtpshipment'
  ) {
    if (!this._shipmentExtendedAttributes[shipmentType]) {
      this._shipmentExtendedAttributes[shipmentType] =
        await this._extendedCatalogService.getCatalogExtendedColumns(
          shipmentType
        );
    }
    return { ...this._shipmentExtendedAttributes[shipmentType] };
  }

  dwellTimeFormatter(
    time: number,
    includeSec: boolean,
    isHumanReadable: boolean,
    roundOffSeconds: boolean,
    outputLength?: number 
  ) {
    if (isNaN(time)) return '--';
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    if (roundOffSeconds) {
      if (seconds >= 30 && seconds < 60) {
        minutes = minutes + 1;
      }
    }

    let _formattedTime = '';
    if (isHumanReadable) {
      if (minutes > 0) {
        _formattedTime = this.formatTimeString(minutes, 'min');
      }

      if (hours > 0) {
        _formattedTime =
          this.formatTimeString(hours, 'hr') + '., ' + _formattedTime;
      }
      if (days > 0) {
        _formattedTime =
          this.formatTimeString(days, 'day') + '., ' + _formattedTime;
      }

      if (includeSec) {
        if (minutes > 0) {
          _formattedTime += '., ';
        }
        _formattedTime += this.formatTimeString(seconds, 'sec');
      } else if (days + hours + minutes === 0) {
        if (roundOffSeconds) {
          _formattedTime = '0 mins';
        } else {
          _formattedTime = this.formatTimeString(seconds, 'sec');
        }
      }

      if (outputLength) {
        let formatArr = _formattedTime.split('.,');
        if (formatArr.length > outputLength) {
          _formattedTime = formatArr.slice(0, outputLength).join('.,');
        }
      }
    } else {
      _formattedTime =
        (hours > 9 ? hours : '0' + hours) +
        ':' +
        (minutes > 9 ? minutes : '0' + minutes);
      if (days > 0) {
        _formattedTime = days + ' days ' + ' ' + _formattedTime;
      }
      if (includeSec) {
        _formattedTime += ':' + (seconds > 9 ? seconds : '0' + seconds);
      } else if (days + hours + minutes === 0) {
        if (roundOffSeconds) {
          _formattedTime = '0 mins';
        } else {
          _formattedTime += seconds > 9 ? seconds : '0' + seconds;
        }
      }
    }

    return _formattedTime;
  }

  formatTimeString(timeVal, unit) {
    let _formatted = '',
      _preformatted = '';
    _preformatted = timeVal < 10 ? '0' + timeVal : timeVal;
    if (timeVal > 1) {
      _formatted = _preformatted + ' ' + unit + 's';
    } else if (timeVal === 1) {
      _formatted = _preformatted + ' ' + unit;
    }
    return _formatted;
  }
}
