import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { CdmField } from '@cl/@types/extended-attributes.types';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { UserService } from '@cl/user/user.service';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormService {
  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService,
    private _userService: UserService
  ) {}

  public fetchDynamicApiData(url: string) {
    if (url.indexOf('cloudos') > -1) {
      url =
        this.apiProvider.getAPIUrl('cloudos') + url.replace('/cloudos/', '');
    } else if (url.indexOf('clfgraphapp') > -1) {
      url =
        this.apiProvider.getAPIUrl('clfgraphapp') +
        url.replace('/clfgraphapp/', '');
    }
    const obs$ = this.http.get(url);
    return firstValueFrom(obs$);
  }

  public orderFields(fields: any[], orderBy: string) {
    if (!fields.length) return [];

    fields = fields.sort((a: any, b: any) => b[orderBy] - a[orderBy]);

    let currentLargestOrder = fields[0][orderBy];

    const sortedArray: any = [];
    fields.forEach((field: any) => {
      if (field[orderBy] < 0) {
        field[orderBy] = ++currentLargestOrder;
      } else {
        sortedArray.unshift(field);
      }
    });

    fields.splice(0, sortedArray.length);
    fields.unshift(...sortedArray);

    return [...fields];
  }

  public createFormGroup(fields: InputField[]) {
    const group: any = {};

    fields.forEach((field) => {
      const validator = [];
      if (field.required) validator.push(Validators.required);
      if (field.regex) validator.push(Validators.pattern(field.regex));

      group[field.id] = new FormControl(
        {
          value: field.value,
          disabled: field.isDisabled,
        },
        validator
      );
    });

    return new FormGroup(group);
  }

  public async getFieldData(field: InputField, url: string) {
    const response: any = await this.fetchDynamicApiData(url).catch(() => {});
    let data = [];
    if (response?.hits) {
      data = response.hits.map((hit) => {
        return {
          label: hit[field.apiLabel],
          key: hit[field.apiKey],
        };
      });
    } else {
      if (response?.length > 0) {
        data = response.map((hit) => {
          return {
            label: hit[field.apiLabel],
            key: hit[field.apiKey],
          };
        });
      }
    }
    return [...data];
  }

  public getRenderableFields(cdmFields: CdmField[]) {
    return cdmFields.filter((field) => field.instanceUserCreatable);
  }

  public async generateInputFields(
    fields: CdmField[],
    isEdit: boolean = false,
    editedProps?: any
  ) {
    const inputFields = [];
    for (let field of fields) {
      const inputField: InputField = {
        type: field.type as InputType,
        value: field?.defaultValue || '',
        defaultValue: field?.defaultValue || '',
        required: field.required,
        id: field.name,
        displayLabel: field?.displayLabel || '',
        regex: field?.regex,
        isDynamicField: field?.isDynamicField === false ? false : true,
        isDisabled: isEdit && !field.instanceUserEditable,
        group: field.group,
      };

      if (field.type.toLowerCase() === 'string') inputField.type = 'text';
      if (field.type.toLowerCase() === 'enumeration') {
        inputField.type = 'select';

        inputField.dropdownConfig = {
          isClearable: !inputField.required,
        };

        if (typeof inputField.value === 'string') {
          inputField.options = field.value ? field.value.split(',') : [];
        } else if (Array.isArray(field.value)) {
          inputField.options = field.value;
        }

        if (
          field.apiUrl &&
          field.apiResponseSelectKey &&
          field.apiResponseSelectValue
        ) {
          inputField.apiUrl = field.apiUrl;
          inputField.apiKey = field.apiResponseSelectKey;
          inputField.apiLabel = field.apiResponseSelectValue;
          if (field.apiUrl.includes('<text>')) {
            inputField.isDynamicFetching = true;
          } else {
            inputField.options = await this.getFieldData(
              inputField,
              field.apiUrl
            );
          }
        }
        // This is common for all entity forms
        if (field.id == 'organization') {
          const isOrgRestricted = this._userService.getUser()?.orgAware;
          inputField.dropdownConfig = {
            isClearable: !isOrgRestricted,
          };
          if (isOrgRestricted && inputField.options.length > 0) {
            inputField.value = inputField.options[0];
          }
        }
      }

      if (field.type.toLowerCase() === 'date') {
        inputField.type = 'date';
        inputField.dateConfig = {
          date: null,
        };
      }
      if (
        field.type.toLowerCase() === 'decimal' ||
        field.type.toLowerCase() === 'integer'
      ) {
        inputField.type = 'number';
      }
      if (field.type.toLowerCase() === 'boolean') {
        inputField.type = 'toggle';
        inputField.options = field.value.split(',');
        if (inputField.options.length == 2) {
          inputField.value = field.defaultValue === inputField.options[0];
        } else {
          inputField.value = field.defaultValue.toLowerCase() === 'true';
        }
      }

      if (isEdit && editedProps) {
        inputField.value = editedProps[inputField.id];
        if (inputField.type === 'date' && inputField.value) {
          inputField.dateConfig = {
            date: {
              startDate: moment(+inputField.value),
              endDate: moment(+inputField.value),
            },
          };
        }
        if (inputField.type === 'toggle' && inputField.options.length == 2) {
          inputField.value = inputField.value == inputField.options[0];
        }
        if (
          inputField.type === 'select' &&
          inputField.options.length > 0 &&
          inputField.apiKey &&
          inputField.apiLabel
        ) {
          if (typeof inputField.options[0] == 'object') {
            const dropdownObj = (inputField.options as any).find(
              (option) => option.key == editedProps[inputField.id]
            );
            if (dropdownObj) {
              inputField.value = dropdownObj;
            }
          }
        }
      }

      if (
        ['text', 'date', 'number', 'select', 'toggle'].includes(inputField.type)
      ) {
        inputFields.push(inputField);
      }
    }
    return [...inputFields];
  }

  public getDynamicFormPayload(formData: FormGroup, inputFields: InputField[]) {
    const dynamicFormPayload = formData.getRawValue();
    const customToggleFields = inputFields.filter(
      (field) => field.type === 'toggle' && field.options.length == 2
    );
    if (customToggleFields) {
      customToggleFields.forEach((field) => {
        if (dynamicFormPayload[field.id] !== undefined) {
          dynamicFormPayload[field.id] = dynamicFormPayload[field.id]
            ? field.options[0]
            : field.options[1];
        }
      });
    }

    const objectTypeEnumerations = inputFields.filter(
      (field) => field.type == 'select'
    );

    if (objectTypeEnumerations) {
      objectTypeEnumerations.forEach((field) => {
        if (typeof dynamicFormPayload[field.id] == 'object') {
          dynamicFormPayload[field.id] = dynamicFormPayload[field.id]?.key || '';
        }
      });
    }

    return { ...dynamicFormPayload };
  }
}
