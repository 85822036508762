import { Injectable } from '@angular/core';
import { FilterList } from '@cl/@types/filter-list.type';
import { ParsedActionTemplate, Rule, RuleType, RuleTypes, TriggerGroup, UnParsedActionTemplate, UnParsedTriggerTemplate } from '@cl/@types/rule.type';
import { Site, Sites } from '@cl/@types/site.type';
import { UserGroup } from '@cl/@types/user-group.type';
import { WebHook } from '@cl/@types/webhook.type';
import { UserService } from '@cl/user/user.service';
import { TranslatePipe } from '@ngx-translate/core';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { RulesService } from './rules.service';

@Injectable({
  providedIn: 'root'
})
export class RuleUtilService {
  private allTriggerTemplates: UnParsedTriggerTemplate[];
  private updatedAllTriggerTemplates: RuleTypes = {};
  constructor(private ruleService: RulesService, private translatePipe: TranslatePipe, private userService : UserService) { }
  private getDistinctRuleTypes( data: UnParsedTriggerTemplate[]){
    let ruleTypes: RuleTypes = {}
    let ruleTypeNames : string[] = _.uniq(_.map(data, 'scope'));
    if(!_.isEmpty(ruleTypeNames)){
      ruleTypeNames.forEach((ruleTypeName: string) => {
        var temp =  ruleTypeName.charAt(0).toUpperCase() + ruleTypeName.substr(1).toLowerCase(); //TODO Need to check and remove it
        ruleTypes[ruleTypeName] = {
          key: ruleTypeName,
          name: temp + ' Rules',
          tlabel:'rules.'+temp+'_rule',
          tfLabel : this.translatePipe.transform('rules.'+temp+'_rules'),
          data: [],
          groups: []
        };
      });
    }
    return ruleTypes;
  };
  private getRuleTypeTriggerGroupsAndTriggers(allTriggers: UnParsedTriggerTemplate[], ruleTypes: RuleTypes){
    for(let ruleTypeName in ruleTypes){
      if(ruleTypes.hasOwnProperty(ruleTypeName)){       
          allTriggers.forEach((triggerDataItem: UnParsedTriggerTemplate) => {
          if(triggerDataItem.scope == ruleTypeName){
            if(!_.isEmpty(triggerDataItem.metricGroup)){
              var ruleType: RuleType = ruleTypes[ruleTypeName];
              var temp = _.find(ruleType.data, {key : triggerDataItem.metricGroup});
              if(_.isEmpty(temp)){
                ruleType.data.push({
                  name : triggerDataItem.metricGroupName,
                  key : triggerDataItem.metricGroup,
                  triggers : [],
                  checked : false,
                  value : 0
                });
                temp = _.find(ruleType.data, {key : triggerDataItem.metricGroup});
              }
              temp.triggers.push(triggerDataItem);
              temp.value = temp.value + triggerDataItem.recipeCount
              ruleType.groups = ruleType.data;
            }
          }
        });
      }
    }
    return ruleTypes;
  };
  getTriggerTemplatesData(): Promise<UnParsedTriggerTemplate[]>{
    return new Promise<UnParsedTriggerTemplate[]>((resolve) => {
      if(_.isEmpty(this.allTriggerTemplates)){
        firstValueFrom(this.ruleService.getTriggerTemplates())
        .then((response : UnParsedTriggerTemplate[]) => {
          this.allTriggerTemplates = response;
          resolve(response);
        })
        .catch(() => {
          resolve([]);
        });
      } else {
        resolve(this.allTriggerTemplates);
      }
    });
  }
  getTriggerTemplates(): Promise<RuleTypes>{
    return new Promise<RuleTypes>((resolve)=>{
      this.getTriggerTemplatesData()
      .then((response : UnParsedTriggerTemplate[]) => {
        this.updatedAllTriggerTemplates = this.getDistinctRuleTypes(this.allTriggerTemplates);
        this.updatedAllTriggerTemplates = this.getRuleTypeTriggerGroupsAndTriggers(this.allTriggerTemplates, this.updatedAllTriggerTemplates);
        resolve(this.updatedAllTriggerTemplates);
      })
      .catch(()=>{
        resolve({});
      });
    });
  }

  getFilterTriggerTemplates(): Promise<RuleTypes>{
    return new Promise<RuleTypes>((resolve)=>{
      this.getTriggerTemplatesData()
      .then((response : UnParsedTriggerTemplate[]) => {
        this.updatedAllTriggerTemplates = this.getDistinctRuleTypes(this.allTriggerTemplates);
        this.updatedAllTriggerTemplates = this.getFilterRuleTypeTriggerGroupsAndTriggers(this.allTriggerTemplates, this.updatedAllTriggerTemplates);
        resolve(this.updatedAllTriggerTemplates);
      })
      .catch(()=>{
        resolve({});
      });
    });
  }

  getRulesFilterList(ruleTypes : RuleTypes): FilterList[]{
    let temp : FilterList[] = [];
    for(let key in ruleTypes){
      if(ruleTypes.hasOwnProperty(key)){
        let ruleType: RuleType = ruleTypes[key];
        let filterItem : FilterList = {
          expand: true,
          filterType : ruleType.key,
          label: this.translatePipe.transform(ruleType.tlabel),
          list : [],
          type: 'multiselect'
        };
        ruleType.groups.forEach((group : TriggerGroup)=>{
          filterItem.list.push({
            checked : false,
            count : group.value,
            displayLabel: group.name,
            name : group.key,
            triggers : _.map(group.triggers, 'id')
          })
        });
        temp.push(filterItem);
      }
    }
    return temp;
  }
  getSites(){
    return new Promise((resolve) => {
      this.ruleService.getSites()
      .then((result: any)=>{
        resolve(result.sites);
      })
      .catch(()=>{
        resolve([]);
      });
    });
  }

  getRoutes() {
    return new Promise((resolve) => {
      this.ruleService.getRoutes()
        .then((result: any)=>{
          resolve(result.hits);
        })
        .catch(()=>{
          resolve([]);
        });
    });
  }

  getAreas() {
    return new Promise((resolve) => {
      this.ruleService.getAreas()
        .then((result: any)=>{
          resolve(result.hits);
        })
        .catch(()=>{
          resolve([]);
        });
    });
  }
  getUserGroups(selectedOrg): Promise<UserGroup[]>{
    return new Promise<UserGroup[]>((resolve)=>{
      const obs$ = this.userService.getOrgUserGroups(selectedOrg);
      firstValueFrom(obs$).then((result: UserGroup[])=>{
        resolve(result);
      })
      .catch(()=>{
        resolve([]);
      });;
    });
  }
  getTagSubtags(): Promise<any[]>{
    return new Promise<any[]>((resolve)=>{
      const obs$ = this.ruleService.getTagSubtags();
      firstValueFrom(obs$).then((result: any[])=>{
        resolve(result);
      })
      .catch(()=>{
        resolve([]);
      });;
    });
  }
  getWebHooks():Promise<WebHook[]>{
    return this.ruleService.getWebHooks();
  }
  saveRule(ruleObject : any): Promise<any>{
    return this.ruleService.saveRule(ruleObject);
  }
  updateRule(ruleObject : any): Promise<any>{
    return this.ruleService.updateRule(ruleObject);
  }

  getActionTemplates(triggerId: string):Promise<ParsedActionTemplate[]>{
    return new Promise<ParsedActionTemplate[]>((resolve)=>{
      this.ruleService.getActionTemplatesData(triggerId)
      .then((response: UnParsedActionTemplate[])=>{
        if(!_.isEmpty(response)){
          response = response.map((item: ParsedActionTemplate)=>{
            item.inputFormJSON = JSON.parse(item.inputForm);
            return item;
          });
          resolve(response);
        }else{
          resolve([]);
        }
      })
      .catch(()=>{
        resolve([]);
      });
    })
  }
  deleteRule(ruleId: string):Promise<boolean>{
    return new Promise<boolean>((resolve)=>{
      this.ruleService.deleteRule(ruleId)
      .then(()=>{
        resolve(true);
      })
      .catch(()=>{
        resolve(false);
      });
    });
  }

  private getFilterRuleTypeTriggerGroupsAndTriggers(allTriggers: UnParsedTriggerTemplate[], ruleTypes: RuleTypes){
    for(let ruleTypeName in ruleTypes){
      if(ruleTypes.hasOwnProperty(ruleTypeName)){
        const key = 'id';
        const uniqueTriggerItem = [...new Map(allTriggers.map(item =>
          [item[key], item])).values()];       
          uniqueTriggerItem.forEach((triggerDataItem: UnParsedTriggerTemplate) => {
          if(triggerDataItem.scope == ruleTypeName){
            if(!_.isEmpty(triggerDataItem.metricGroup)){
              var ruleType: RuleType = ruleTypes[ruleTypeName];
              var temp = _.find(ruleType.data, {key : triggerDataItem.metricGroup});
              if(_.isEmpty(temp)){
                ruleType.data.push({
                  name : triggerDataItem.metricGroupName,
                  key : triggerDataItem.metricGroup,
                  triggers : [],
                  checked : false,
                  value : 0
                });
                temp = _.find(ruleType.data, {key : triggerDataItem.metricGroup});
              }
              temp.triggers.push(triggerDataItem);
              temp.value = temp.value + triggerDataItem.recipeCount
              ruleType.groups = ruleType.data;
            }
          }
        });
      }
    }
    return ruleTypes;
  };
}
