<ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <div class="flex flex-col black fs16" *ngIf="!droppedFile">
      <div>Drop {{panelObj?.fileType}} file here</div>
      <div class="text-center">or</div>
      <div><a class="color4" (click)="openFileSelector()">Select File</a> to upload</div>
    </div>
    <div class="text-center" *ngIf="droppedFile">
        {{selectedFileName}} <button (click)="removeFile()"><i class="fa fa-trash color21"></i></button>
    </div>
    </ng-template>
</ngx-file-drop>

<div  class="flex items-center justify-center mt-4">
    <button #downloadRef class="color4 mr-2" (click)="downloadTemplate()">
        <i class="fa fa-download mr-2" aria-hidden="true"></i>
        Download
    </button> our {{panelObj?.fileType}} template
</div>
<div class="bgcolor32 mb-4 mt-4 p-2 max-h-[200px] overflow-y-auto" *ngIf="showResponse">
    <div *ngFor="let res of bulkResponseArr" class="mb-4 flex fs14 color16">
        <div class="basis-2/4 break-all font-bold capitalize fs12">{{res.key}}</div>
        <div class="basis-2/4 break-all max-w-[150px] fs12">
            <a *ngIf="res.link" href="{{res.val}}">Download error file</a>
            <span *ngIf="!res.link">{{res.val}}</span>
        </div>
    </div>
</div>