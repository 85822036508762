<div class="mt-2 mb-2">
    <span class="page-title">Bulk Operations</span>
</div>
<!-- <cl-table-surtitle [rows]="showingHits" [selected]="selected.length" [total]="totalHits" [pageName]="'Locations'">
</cl-table-surtitle> -->
<div class="sensor-febric wt-ngx-grid-wrapper h-calc-40 list-page-wrapper relative list-page px-2">
    <div class="flex justify-between pt-1 pb-1">
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer color4 list-page_filter-toggle flex items-center">
              <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
              filter options
            </span>
            <cl-list-search [placeholder]="'Search by Name'" (search)="updateFilter($event)" [columns]="['Name']">
            </cl-list-search>
        </div>

        <div class="sensor-febric__action-panel-right action-panel-right self-end flex items-center mr-10" >
            <cl-export-panel  (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
                [page]="'location'">
            </cl-grid-column-toggle>
            <!-- <button  title="Export the Bulk List" ngClass='color4 bcolor4 bgcolor32' class="p-4-10 border-general br2 mb-1.5" >Export</button> -->
        </div>
    </div>
    <div class="h-calc-40">
        <main class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth - 10">
            <div class="grow-0" [ngClass]="{'w-0 invisible': !showFilterPanel}">
                <cl-list-filter class="grow-0 nav panel scroll-y" [hideTitle]="true" [showCounts]="false" [title]="''"
                    [fullHeight]="true" (panelsEmitter)="getPanelUpdate($event)"
                    (toggleFilterPanel)="toggleFilterPanel()" heightClass="none" [filters]="filters">
                </cl-list-filter>
            </div>
            <div class="grid-container grow relative">
                <div *ngIf="delayedRefreshStart">
                    <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                        (refreshList)="getBulkOperationsList()"></cl-refresh-countdown>
                </div>

                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div class="h-100" [style.width.px]="tableWidth">
                    <ngx-datatable class="h-calc-40" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true"  [scrollbarH]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [selectAllRowsOnPage]="false" (scroll)="onScroll($event.offsetY)" (select)='onSelect($event)'[reorderable]="false" #table>
                    </ngx-datatable>
                </div>
            </div>
        </main>
    </div>
    <ng-template #BulkNameTemplate let-row="row" let-value="value" let-i="index">
        <ng-container *ngIf="isShowSummary ; else title">
            <a class="font-12-bold"  (click)="loadSummaryPanel(row, $event)">{{value}}</a>
        </ng-container>
        <ng-template #title>
                  <span>{{value}}</span>
        </ng-template>
    </ng-template>
    <ng-template #dateTemplate let-row="row" let-value="value">
        {{value | formateDate:'default'}}
    </ng-template>
    <!-- <ng-template #NotificationOn let-row="row" let-value="value">
        {{value.notifiedOn | formateDate:'default'}}
    </ng-template>
    <ng-template #NotificationTo let-row="row" let-value="value" let-i="index">
            <span>{{ value.notifiedTo }}</span>
    </ng-template> -->
    <div *ngIf="showSidePanel == true">
        <cl-bulk-summary [bulkId]="bulkObject?.id" [bulkType]="bulkObject?.classType" (closeSidePanel)="closeSidePanel()" ></cl-bulk-summary>
    </div>
</div>
