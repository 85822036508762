<!-- <div class="align-center">
    <h1 *ngIf="title" mat-dialog-title>
        {{title}}
    </h1>

    <div mat-dialog-content>
        <p class="foz14">{{message}}</p>
    </div>
</div> -->

<h1 mat-dialog-title class="title">{{title}}</h1>
<div mat-dialog-content>
    {{message}}
    <ul class="columns">
        <li *ngFor="let column of columns">{{column}}</li>
    </ul>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>