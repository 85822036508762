import { Injectable } from '@angular/core';

@Injectable()
export class ClientConfigService {
  clientNames = ["cloudleaf", "ericsson", "takeda"];
  clients = {
    cloudleaf: {
      title: "Cloudleaf SCN",
      login: {
        logo: "./assets/svgs/logos/Cloudleaf_Primary_Blue_RGB.svg",
      },
      header: {
        logo: "./assets/svgs/logos/Cloudleaf_Logo_White.svg",
        tagline: "Supply Chain Navigator",
        showDtDropdownSelector: true,
        showNotifications: true,
        showAlerts: true,
      },
      theme: {
        mainColorDark: "#054ea7",
        mainColorGrad: "#00aff6",
        mainColor: "#4d9ade",
        mainColorMed: "#66c2eb",
        mainColorLight: "#c4e3f1",
        mainColorLighter: "#b9e3f7",
        mainColorLightest: "#f3faff",
        mainColorTrans: "rgba(0, 153, 221, 0.85)",
        mainColorTransLight: "rgba(0, 153, 221, 0.7)",
        themeMainColor: "#4d9ade",
        themeMainColorMed: "#66c2eb",
        themeMainColorTrans: "rgba(0, 153, 221, 0.85)",
        themeMainColorLight: "#c4e3f1",
        themeMainColorLighter: "#b9e3f7",
      },
      nav: {
        token: {
          NAVIGATOR: true,
          DIGITAL_MODEL: true,
          MANAGE_ASSETS: true,
          RULES_ENGINE: true,
          MAPPING_ADMIN: true,
          ADMIN: true,
        },
        path: {
          "/navigator": true,
          "/assets/manage-assets": true,
          "/mapping-admin": true,
          "/sensors": true,
          "/admin/users": true,
          "/assets/asset-details": true,
          "/business-rules/builder": true,
          "/business-rules/template": true,
          "/master-data/create": true,
          "/account": true,
        },
      },
      features: {
        chain_of_custody: true,
        showLinkIcons: true,
      },
      cards: {
        showBookmarks: true,
        list: [
          {
            name: "tenancy",
            component: "tenancy-card",
            isFavorite: true,
            title: "Snapshot",
          },
          {
            name: "Counters",
            component: "list-card",
            title: "Tool Availibility",
            link: {
              text: "manage assets",
              path: "/home/manage-assets",
            },
          },
          {
            name: "KPIs",
            component: "list-card",
            title: "Transfer Status",
            isFavorite: true,
            link: {
              path: "/home/mapping-admin",
              text: "manage locations",
            },
          },
          {
            name: "Trends",
            component: "graph-card",
            title: "Excursion Trends",
            // ,
            // link: {
            //     path: "/home/reports",
            //     text: "View reports"
            // }
          },
        ],
      },
      liveMap: {
        showViewingModeToggle: true,
        showAssetsAsLocationCounts: false,
        showAlerts: true,
        mapGroups: {
          location: true,
          users: true,
          incident: true,
          inventory: true,
          shipment: true,
          asset: true,
        },
        showTargets: true,
        infoType: "asset-states",
        showTooltips: true,
      },
      mapping_admin: {
        includedFilters: {
          entities: false,
          entityTypes: {
            Location: true,
            Geofence: true,
            Area: true,
          },
          mapFilters: false,
        },
      },
      sensor_fabric: {
        showCards: true,
      },
    },
    ericsson: {
      title: "Ericsson Tool Tracker",
      login: {
        logo: "assets/svgs/logos/Ericsson_Dark_Blue.svg",
      },
      header: {
        logo: "assets/svgs/logos/Ericsson_logo.svg",
        tagline: "",
        showDropdown: false,
        centerLogo: true,
        logoWidth: 190,
        showNotifications: true,
        showAlerts: true,
      },
      theme: {
        mainColorDark: "#013f6a",
        mainColorGrad: "rgba(11, 92, 198, .8)",
        mainColor: "#4d9ade",
        mainColorMed: "#6ba7ff",
        mainColorLight: "#c4e3f1",
        mainColorLighter: "#bed6fa",
        mainColorLightest: "#f0f6ff",
        mainColorTrans: "rgba(50, 130, 239, 0.85)",
        mainColorTransLight: "rgba(50, 130, 239, 0.75)",
        themeMainColor: "#4d9ade",
        themeMainColorMed: "#6ba7ff",
        themeMainColorLight: "#c4e3f1",
        themeMainColorLighter: "#bed6fa",
        themeMainColorTrans: "rgba(50, 130, 239, 0.85)",
      },
      features: {
        chain_of_custody: true,
        showLinkIcons: false,
      },
      liveMap: {
        showAssetsAsLocationCounts: false,
        showViewingModeToggle: true,
        showAlerts: false,
        mapGroups: {
          location: true,
          users: true,
          incident: false,
          asset: true,
        },
        showTooltips: false,
        showTargets: false,
      },
      nav: {
        token: {
          NAVIGATOR: true,
          DIGITAL_MODEL: false,
          MANAGE_ASSETS: true,
          RULES_ENGINE: false,
          MAPPING_ADMIN: true,
          ADMIN: true,
        },
        path: {
          "/navigator": true,
          "/assets/manage-assets": true,
          "/assets/asset-details": true,
          "/mapping-admin": true,
          "/sensors": true,
          "/admin/users": true,
          "/account": true,
        },
      },
      navRestricted: {
        token: {
          MANAGE_ASSETS: true,
        },
        path: {
          "/assets/manage-assets": true,
          "/home/assets/asset-details": true,
          "/account": true,
        },
      },
      cards: {
        showBookmarks: false,
        list: [
          {
            name: "tenancy",
            component: "tenancy-card",
            title: "Snapshot",
          },
          {
            name: "Counters",
            component: "readouts-card",
            title: "Tool Availibility",
            isFaded: false,
          },
          {
            name: "KPIs",
            component: "readouts-card",
            title: "Transfer Status",
            isFaded: false,
          },
          // ,
          // {
          //   name: "Trends",
          //   component: "graph-card",
          //   title: "Excursion Trends",
          //   isFaded: true,
          // },
        ],
      },
      mapping_admin: {
        hideFirstStep: true,
        includedFilters: {
          entities: true,
          entityTypes: {
            Location: true,
            Area: false,
          },
          mapFilters: true,
        },
      },
      sensor_fabric: {
        showCards: false,
      },
      users: {
        hideSingleUserAdder: false,
      },
    },
    takeda: {
      title: "Takeda Pharma",
      // 'login': {
      //     'logo': 'assets/svgs/logos/takeda_logo.svg'
      // },
      header: {
        logo: "assets/svgs/logos/takeda_logo.svg",
        tagline: "",
        showDropdown: false,
        centerLogo: false,
        logoWidth: 140,
        showNotifications: true,
        showAlerts: true,
      },
      // 'theme': {
      // 'mainColorDark': '#A9193D',
      // 'mainColorGrad': '#2776e3',
      // 'themeMainColor': '#ed1c24', //red
      // 'themeMainColorMed': '#f46d64',
      // 'themeMainColorLight': '#f46d64',
      // 'themeMainColorTrans': 'rgba(244, 109, 100, 0.85)',

      // 'themeMainColor': '#00c3bc', //  sea foam
      // 'themeMainColorMed': '#30dfd7',
      // 'themeMainColorLight': '#5ce6e0',
      // 'themeMainColorLighter': '#e1fdfc',
      // 'themeMainColorTrans': 'rgba(0, 195, 188, 0.65)'
      // },
      liveMap: {
        showViewingModeToggle: true,
        mapGroups: {
          location: true,
          incident: true,
          asset: true,
          inventory: true,
          shipment: true,
        },
        showTargets: true,
        showTooltips: true,
      },
      mapping_admin: {},
      nav: {
        token: {
          NAVIGATOR: true,
          DIGITAL_MODEL: true,
          MANAGE_ASSETS: true,
          RULES_ENGINE: true,
          MAPPING_ADMIN: true,
          ADMIN: true,
        },
        path: {
          "/navigator": true,
          "/assets/manage-assets": true,
          "/assets/asset-details": true,
          "/mapping-admin": true,
          "/sensors": true,
          "/admin/users": true,
          "/account": true,
        },
      },
      cards: {
        showBookmarks: false,
        list: [
          {
            name: "tenancy",
            component: "tenancy-card",
            title: "Snapshot",
          },
          {
            name: "Counters",
            component: "list-card",
            title: "Inventory Tracking",
            isFaded: false,
          },
          {
            name: "KPIs",
            component: "list-card",
            title: "Transfer Status",
            isFaded: false,
          },
          {
            name: "Trends",
            component: "graph-card",
            title: "Excursion Trends",
            isFaded: true,
          },
        ],
      },
    },
  };
  client;
  index;
  clientMap = {
    SCNITUser: "takeda",
  };
  constructor() {
    this.client = "cloudleaf";
    this.index = 1;
  }
  setClient(client) {
    client = this.clientMap[client] || client;
    this.client = this.clients[client] ? client : this.client;
    // this.setTheme();
    this.index = this.clientNames.indexOf(this.client);
    return this.clients[this.client];
  }
  nextClient() {
    this.index = this.index + 1 > this.clientNames.length - 1 ? 0 : this.index + 1;
    this.client = this.clientNames[this.index];
    // this.setTheme();
    return this.clients[this.client];
  }

  getClientConfig(type) {
    return this.clients[this.client][type] ? this.clients[this.client][type] : this.clients["cloudleaf"][type];
  }
  setTheme() {
    let root = document.documentElement;
    // document.title = this.clients[this.client]["title"];
    if (this.clients[this.client]["theme"] && this.clients[this.client]["theme"]["themeMainColor"]) {
      root.style.setProperty("--themeMainColor", this.clients[this.client]["theme"]["themeMainColor"]);
      root.style.setProperty("--themeMainColorMed", this.clients[this.client]["theme"]["themeMainColorMed"]);
      root.style.setProperty("--themeMainColorLight", this.clients[this.client]["theme"]["themeMainColorLight"]);
      root.style.setProperty("--themeMainColorLighter", this.clients[this.client]["theme"]["themeMainColorLighter"]);
      root.style.setProperty("--themeMainColorTrans", this.clients[this.client]["theme"]["themeMainColorTrans"]);
    } else {
      root.style.setProperty("--themeMainColor", this.clients["cloudleaf"]["theme"]["themeMainColor"]);
      root.style.setProperty("--themeMainColorMed", this.clients["cloudleaf"]["theme"]["themeMainColorMed"]);
      root.style.setProperty("--themeMainColorLight", this.clients["cloudleaf"]["theme"]["themeMainColorLight"]);
      root.style.setProperty("--themeMainColorLighter", this.clients["cloudleaf"]["theme"]["themeMainColorLighter"]);
      root.style.setProperty("--themeMainColorTrans", this.clients["cloudleaf"]["theme"]["themeMainColorTrans"]);
    }
    if (this.clients[this.client]["theme"] && this.clients[this.client]["theme"]["mainColor"]) {
      root.style.setProperty("--mainColorDark", this.clients[this.client]["theme"]["mainColorDark"]);
      root.style.setProperty("--mainColorGrad", this.clients[this.client]["theme"]["mainColorGrad"]);
      root.style.setProperty("--mainColor", this.clients[this.client]["theme"]["mainColor"]);
      root.style.setProperty("--mainColorMed", this.clients[this.client]["theme"]["mainColorMed"]);
      root.style.setProperty("--mainColorLight", this.clients[this.client]["theme"]["mainColorLight"]);
      root.style.setProperty("--mainColorLighter", this.clients[this.client]["theme"]["mainColorLighter"]);
      root.style.setProperty("--mainColorLightest", this.clients[this.client]["theme"]["mainColorLightest"]);
      root.style.setProperty("--mainColorTrans", this.clients[this.client]["theme"]["mainColorTrans"]);
    } else {
      root.style.setProperty("--mainColorDark", this.clients["cloudleaf"]["theme"]["mainColorDark"]);
      root.style.setProperty("--mainColorGrad", this.clients["cloudleaf"]["theme"]["mainColorGrad"]);
      root.style.setProperty("--mainColor", this.clients["cloudleaf"]["theme"]["mainColor"]);
      root.style.setProperty("--mainColorMed", this.clients["cloudleaf"]["theme"]["mainColorMed"]);
      root.style.setProperty("--mainColorLight", this.clients["cloudleaf"]["theme"]["mainColorLight"]);
      root.style.setProperty("--mainColorLighter", this.clients["cloudleaf"]["theme"]["mainColorLighter"]);
      root.style.setProperty("--mainColorLightest", this.clients["cloudleaf"]["theme"]["mainColorLightest"]);
      root.style.setProperty("--mainColorTrans", this.clients["cloudleaf"]["theme"]["mainColorTrans"]);
    }
  }
}
