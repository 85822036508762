<div class="mt-2 mb-2">
    <span class="page-title">Admin - System Users</span>
</div>
<div class="mb-1 list-page-count" *ngIf="rows.length > 0">Showing <span class="font-bold">{{rows.length}}</span> of {{rows.length}} tenants</div>
<div class="wt-ngx-grid-wrapper relative list-page px-2">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list">
            <div class="add-button" (click)="addSFTPUser()" *ngIf="!showSummaryPanel">
              <span class="fa fa-plus" ></span>
            </div>
            <div class="add-button" *ngIf="showSummaryPanel" (click)="closeSidePanel()">
                <span class="fa fa-close"></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
            <button [disabled]="editDisabled"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2 mr-2" (click)="deleteUser()">
                <i class="fa fa-trash"></i>
                Delete
                <span class="fa fa-circle-o-notch fa-pulse" *ngIf="isLoading"></span>
            </button>
        </div>
    </div>
    <div class="h-calc-60">
            <div class="grid-container h-100 grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
                <div *ngIf="delayedRefreshStart">
                  <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                  (refreshList)="delayedRefreshStart = false; getSFTPUsers()"
                  ></cl-refresh-countdown>
              </div>
                <div class="h-100 " [style.width.px]="tableWidth">
                    <ngx-datatable  class="h-100" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [selectAllRowsOnPage]="false" (select)='onSelect($event)'>
                    </ngx-datatable>
                </div>
            </div>
    </div>

    <div class="summery-pannel" *ngIf="showSummaryPanel === true">
        <div id="navbarSupportedContent1" class="toggle-container overflow-hidden">
            <div class="toggle-container__header top-alignment">
                <section class="cl-side-panel-title bgcolor32 justify-between items-center">
                    <div class="grow flex items-center">
                        Create SFTP User
                    </div>
                </section>
            </div>
            <div class="toggle-container__sub-header"></div>
            <div class="form-label">User Name</div>
            <div class="report-view">
                <input class="input-margin" type="text" placeholder="Enter Display Name" [(ngModel)]="userName" />
                <div class="pl0 pr0 pt5 text-right">
                    <!-- <div [ngClass]="!userName ? 'no-pointer':''">
                    <i class="fa fa-plus-circle color4" (click)="createSftpUser()" [ngClass]="[!userName ? 'color37 bcolor36 bgcolor38 no-pointer' : 'color4 bcolor4 bgcolor32 pointer']"></i>
                    <a href="javascript:void(0)" class="fs12 ml0" (click)="createSftpUser()">Add</a>-->
                    <!-- ngIf: loading -->
                    <!-- </div> -->

                    <div class="flex-col grow fs11 text-right btn-margin">
                        <a class="panel-button m-2" (click)="closeSidePanel()">Cancel</a>
                        <!-- <button class="panel-button white bgcolor4" [disabled]="isSaveInProgress || !apiSubscriberForm.valid" (click)="submitApiSubscriber()"> -->
                          <button class="panel-button white bgcolor4" [disabled]="isSaveInProgress" (click)="createSftpUser()">
                          Add
                          <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
                        </button>
                      </div>
                </div>
            </div>
            <div class="overflow-auto max-h-[440px]">
               
            </div>
        </div>
    </div>
</div>
<ng-template #sftpUserDateTemplate let-row="row" let-value="value" >
    {{value | formateDate:'default'}}
</ng-template>

<div class="popover__wrapper" *ngIf="userCreated">
    <div class="popover__content" [ngClass]="status ? 'display-content' : ''">
        <div class="pop">
            <div class="model-header">
                <span class="font-bold fs14 header-font-color">SFTP User created successfully!</span>
            </div>
            <div class="model-body">
                <div class="lease-summary pl20">
                  <div class="grid-30-70 mb15">
                    <span class="font-14 font-bold content-font-color">User ID</span>
                    <span class="font-14 content-font-color">{{createdUserId}}</span>
                 </div>
                 <div class="grid-30-70 mb15">
                    <span class="font-14 font-bold content-font-color">Password</span>
                    <span class="font-14 content-font-color">{{password}}</span>
                 </div>
                <div class="mb15 mr15">
                    <span class="font-14 content-font-color"> Note: Please save and download your SFTP credentials, note that this is the only time you will be able to access these credentials, they won't be accessible once this popup is closed. </span>
                 </div>
                </div>
            </div>
            <div class="model-footer">
                <button class="panel-button white bgcolor4 mr-4" href="javascript:void(0)" (click)="download()">Download</button>
                <button class="panel-button white bgcolor4" href="javascript:void(0)"
                    (click)="closemodel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
