<div class="role-panel update-panel-css">
  <cl-side-panel class="role-side-panel summary-view" (closePanel)="closePanel()">
    <section class="cl-side-panel-title bgcolor32">
      <span class="black font-bold">CONFIGURE</span>
      <span class="close-icon" (click)="closePanel()">
        <i class="fa fa-times-circle-o mr-4"></i>
      </span>
    </section>
    <section class="cl-side-panel-content mt-2 update-class">
      <form [formGroup]="deviceProfileForm" autocomplete="off">
        <div class="user-summary">
          <div class="user-summary-content">
            <div class="form-label">Name<span class="required">*</span></div>
            <div class="mb-3">
              <input type="text" class="basic-input" formControlName="eventProfileName" name="eventProfileName"
                id="eventProfileName" [class.disabled]="mode ==='edit'">
              <label class="red"
                [ngClass]="(deviceProfileForm.controls['eventProfileName'].invalid && (deviceProfileForm.controls['eventProfileName'].dirty || deviceProfileForm.controls['eventProfileName'].touched)) ? 'visible' : 'invisible'">
                Please enter name!
              </label>
            </div>
          </div>
          <!-- organization -->
          <div class="user-summary-content">
            <div class="form-label">Please Select Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog></div>
             <div class="mb-3 organization">
               <mat-select formControlName="deviceOrgName" [value]="selectedOption" [disabled]="isOrgAware && deviceProfileForm.value.deviceOrgName=='ANY'">
                <mat-option [value]="''" *ngIf="!isOrgAware"> ----Select Organization---- </mat-option>
                <mat-option  *ngIf="!isOrgAware" [value]="'ANY'" [textContent]="'(Any)'" ></mat-option>
                 <mat-option *ngFor="let org of orgList" [value]="org.id" [textContent]="org.name">
                 </mat-option>
               </mat-select>
               <mat-icon  *ngIf="deviceProfileForm.value.deviceOrgName && !isOrgAware" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
             </div>
             <p *ngIf="deviceProfileForm.value.deviceOrgName=='ANY'" class="orgcontent"><span>Warning</span>: data will be seen by all users including those outside your org</p>
       </div>
          <!-- <div class="user-summary-content" *ngIf="hasScopeAllPolicy">
            <div class="form-label">Tenant<span class="required">*</span></div>
            <div class="mb-3">
              <mat-select formControlName="tenantId" name="tenantId" id="tenantId"
              (selectionChange)="getSensorTypes($event.value);">
              <mat-option [value]="''"> ----Select Tenant---- </mat-option>
              <mat-option *ngFor="let tenant of tenantList" [value]="tenant.identifier">{{tenant.name}}</mat-option>
            </mat-select>
              <label class="red"
                [ngClass]="(deviceProfileForm.controls['tenantId'].invalid && (deviceProfileForm.controls['tenantId'].dirty || deviceProfileForm.controls['tenantId'].touched)) ? 'visible' : 'invisible'">
                Please select tenant!
              </label>
            </div>
          </div> -->
          <div class="user-summary-content mb-3">
            <div class="form-label">Sensor Type<span class="required">*</span></div>
            <div class="mb-3">
              <mat-select formControlName="sensorType" name="sensorType" id="sensorType"
                (selectionChange)="getServicesBySensorType($event.value);">
                <mat-option [value]="''"> ----Select Sensor Type---- </mat-option>
                <mat-option *ngFor="let sensorType of sensorTypes;" [value]="sensorType">{{sensorType}}</mat-option>
              </mat-select>
              <label class="red"
                [ngClass]="(deviceProfileForm.controls['sensorType'].invalid && (deviceProfileForm.controls['sensorType'].dirty || deviceProfileForm.controls['sensorType'].touched)) ? 'visible' : 'invisible'">
                Please select sensor type!
              </label>
            </div>
          </div>
          <div class="user-summary-content mb-3">
            <div class="form-label" *ngIf="deviceConfigData.length || (servicesData | keyvalue)?.length">Device
              Configuration</div>
            <div formGroupName="eventConfig">
              <div *ngFor="let service of servicesData | keyvalue">
                <!-- {{service['value']['eventConfig'] | json}} -->
                <div *ngIf="service.value['metadata'] && service.value['metadata']['supports']">
                  <!-- TODO:: has to be checked -->
                  <div *ngIf="service['value']['eventConfig']">
                    <span>
                      <mat-slide-toggle [formControlName]="'is' + service.value['metadata']['name'] + 'Configured'"
                        (change)="resetEventValue(service.value['eventConfig']['excursion']['configured'], service.value['metadata']['name'], 'excursion')">
                        {{service['value']['metadata']['name']}} Thresholds (in
                        {{service['value']['metadata']['unit']}})
                      </mat-slide-toggle>
                    </span>
                  </div>
                  <ng-container
                    *ngIf="fcEventConfig.get('is' + service['value']['metadata']['name'] + 'Configured')?.value">
                    <div class="css-grid mt-2 grid-20-20-60 gap-1">
                      <ng-container *ngIf="service.value['metadata']['limits'] !== 'max'">
                        <p>Below</p>
                        <input class="basic-input m-0 pr-1" type="number" [min]="service.value['metadata']['min']"
                          [max]="service.value['metadata']['max']"
                          [formControlName]="'min' + service.value['metadata']['name']" />
                      </ng-container>
                      <ng-container *ngIf="service.value['metadata']['limits'] !== 'min'">
                        <p>Above</p>
                        <input class="basic-input m-0 pr-1" type="number" [min]="service.value['metadata']['min']"
                          [max]="service.value['metadata']['max']"
                          [formControlName]="'max' + service.value['metadata']['name']" />
                      </ng-container>

                      <ng-container
                        *ngIf="service.value['metadata']['min'] === null && service.value['metadata']['max'] === null">
                        <p>Below</p>
                        <input class="basic-input m-0 pr-1" type="number"
                          [formControlName]="'min' + service.value['metadata']['name']" />
                        <div>
                          <p>Above</p>
                          <input class="basic-input m-0 pr-1" type="number"
                            [formControlName]="'max' + service.value['metadata']['name']" />
                        </div>
                      </ng-container>

                    </div>
                    <ng-container *ngIf="service.value['metadata']['min'] !== service.value['metadata']['max']">
                      <ng-container *ngTemplateOutlet="eventConfigErrorMessages; context: { $implicit: service}">
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="user-summary-content mb-3" *ngIf="deviceConfigData.length">
            <div formArrayName="deviceConfig">

              <!-- Device config iterator -->
              <ng-container *ngFor="let fc of fcDeviceConfig.controls; let index = index;">

                <!-- deviceConfig[index] -->
                <ng-container [formGroupName]="index">
                  <!-- deviceConfig[index].characteristics -->
                  <ng-container formArrayName="characteristics">
                    <ng-container
                      *ngFor="let fcCharacteristics of fc.get('characteristics')['controls']; let i = index;">
                      <ng-container *ngIf="(fcCharacteristics.get('_deviceConfig').value) as _deviceConfig">
                        <!-- {{_deviceConfig | json}} -->

                        <!-- deviceConfig[index].characteristics[i] -->
                        <ng-container [formGroupName]="i">

                          <!-- deviceConfig[index].characteristics[i].configParams -->
                          <ng-container formGroupName="configParams">
                            <div class="pos-rel mb-2" *ngIf="fcCharacteristics.get('configParams') as configParams">
                              <!-- shaock samples has to be enabled only if shock is enabled -->
                              <ng-container *ngIf="(_deviceConfig.name !== 'accSamplingInterval' || isAccSamplingInterval ) && _deviceConfig.name !== 'shock_sample' || (_deviceConfig.name === 'shock_sample' &&  fcEventConfig.get('is' + _deviceConfig.serviceIdentifier + 'Configured')?.value)">
                              <!-- <label class="capitalize foz14 font-bold required-form" for="eventProfileName">{{_deviceConfig?.uiLabel}}</label> -->
                                <mat-slide-toggle formControlName="enabled" color="primary"
                                  (change)="onDeviceConfigToggle($event, fcCharacteristics)" [disabled]="!_deviceConfig.canBeDisabled"
                                  >
                                  {{_deviceConfig?.uiLabel}}
                                </mat-slide-toggle>
                              </ng-container>

                               <!-- shaock samples has to be enabled only if shock is enabled -->
                              <ng-container *ngIf="(_deviceConfig.name !== 'accSamplingInterval' || isAccSamplingInterval ) && _deviceConfig.name !== 'shock_sample' || (_deviceConfig.name === 'shock_sample' &&  fcEventConfig.get('is' + _deviceConfig.serviceIdentifier + 'Configured')?.value)">
                              <!-- Show config params section only if selected device config is enabled -->
                                <ng-container *ngIf="configParams.value.enabled">
                                  <ng-container [ngSwitch]="_deviceConfig._uom.type">
                                    <!-- Uom with valueSet -->
                                    <ng-container *ngSwitchCase="UomTypeCustom.Select">
                                      <div class="css-grid grid-20-40-40 mt-2">
                                        <span class="text-xs">
                                         {{ fnGetInputLabelBySensorType(_deviceConfig?.uiLabel,deviceProfileForm.controls['sensorType'].value) }}
                                        </span>
                                        <div class="pos-rel w-100">
                                          <mat-select formControlName="value"
                                            (valueChange)="configParamsSelectionChanged(fcCharacteristics)">
                                            <mat-option [value]="''"> ----select value---- </mat-option>
                                            <mat-option *ngFor="let item of _deviceConfig._uom.list" [value]="item.value">
                                              {{item?.label}}
                                            </mat-option>
                                          </mat-select>
                                        </div>
                                      </div>

                                      <!-- configParamsErrorMessages template defined at the bottom -->
                                      <ng-container *ngTemplateOutlet="configParamsErrorMessages; context: { $implicit: configParams }">
                                      </ng-container>
                                    </ng-container>
                                    <!-- /Uom with valueSet -->

                                    <!-- /Uom without valueSet -->
                                    <ng-container *ngSwitchCase="UomTypeCustom.Radio">
                                      <div class="css-grid mt-2 grid-20-20-60 gap-1">
                                        <span class="text-xs">
                                          {{ fnGetInputLabelBySensorType(_deviceConfig?.uiLabel,deviceProfileForm.controls['sensorType'].value) }}
                                        </span>
                                        <input class="basic-input m-0 pr-1" type="number" [min]="_deviceConfig._uom.min"
                                          [max]="_deviceConfig._uom.max" formControlName="value" />
                                        <mat-radio-group class="ml-1" aria-label="Select option" formControlName="uom"
                                          (change)="configParamsRadioChanged(fcCharacteristics)">
                                          <mat-radio-button *ngFor="let item of _deviceConfig._uom.list" class="ml-2"
                                            [value]="item.value">
                                            {{item?.label}}
                                          </mat-radio-button>
                                        </mat-radio-group>
                                      </div>
                                      <!-- configParamsErrorMessages template defined at the bottom -->
                                      <ng-container *ngTemplateOutlet="configParamsErrorMessages; context: { $implicit: configParams }">
                                      </ng-container>
                                    </ng-container>
                                    <!-- /Uom without valueSet -->

                                    <!-- /Uom  checkbox -->
                                    <ng-container *ngSwitchCase="UomTypeCustom.Checkbox">
                                      <div class="css-grid mt-2 grid-20-20-60 gap-1" formGroupName="multiSelectFormGroup">
                                        <div *ngFor="let item of _deviceConfig._uom.list">
                                          <mat-checkbox [formControlName]="item.value" [disabled]="!item.canBeDisabled">
                                            {{item?.label}}
                                          </mat-checkbox>
                                        </div>
                                      </div>

                                      <!-- configParamsErrorMessages template defined at the bottom -->
                                      <ng-container *ngTemplateOutlet="configParamsErrorMessages; context: { $implicit: configParams }">
                                      </ng-container>
                                    </ng-container>
                                    <!-- /Uom checkbox -->

                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </div>
                          </ng-container>
                          <!-- /deviceConfig[index].characteristics[i].configParams -->

                        </ng-container>
                        <!-- /deviceConfig[index].characteristics[i] -->

                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <!-- /deviceConfig[index].characteristics -->

                </ng-container>
                <!-- /deviceConfig[index] -->

              </ng-container>
              <!-- /Device config iterator -->
            </div>
          </div>
        </div>
      </form>
    </section>
    <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
      <div class="flex">
        <div class="flex-col grow fs11 text-right">
          <a class="panel-button m-2" (click)="closePanel()">Cancel</a>
          <button class="panel-button white bgcolor4" [disabled]="isSaveInProgress || !deviceProfileForm.valid" (click)="submitEventProfiler()">Save
            <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
          </button>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>
<ng-template #eventConfigErrorMessages let-service>
  <mat-error class="text-xs">
    values must be within {{service.value['metadata']['min']}} to
    {{service.value['metadata']['max']}}
  </mat-error>
</ng-template>
<ng-template let-configParams #configParamsErrorMessages>
  <mat-error class="text-xs" *ngIf="configParams.get('value')?.invalid && configParams.get('value')?.errors.required">
    Device config value is required
  </mat-error>
  <mat-error class="text-xs" *ngIf="configParams.get('value')?.valid && configParams.get('uom')?.invalid && configParams.get('uom')?.errors.required">
    Device config unit is required
  </mat-error>
  <mat-error class="text-xs" *ngIf="configParams.get('value')?.invalid && configParams.get('value')?.errors.range">
    Device config unit range should be {{configParams.get('value')?.errors?.range?.min}} or Device config unit range should not be > {{configParams.get('value')?.errors?.range?.max}}
  </mat-error>
</ng-template>