import { Injectable } from "@angular/core";
import { Card, CardComponentType, DtAnalysis, DtAnalysisItemList } from "@cl/models";
import { IconSvgService } from "../../common/services/icon-svg.service";
import _ from "lodash";
import { ShipmentUtilsService } from "@cl/common/utils/shipment-utils.service";
import { UtilsService } from "@cl/common/utils/utils.service";
import { AuthorizeService } from "@cl/@authorize/authorize.service";

@Injectable()
export class NavigatorCardsService {
  constructor(private _iconSvgService: IconSvgService, private _shipmentUtils: ShipmentUtilsService, private _utils: UtilsService, private authorizeService: AuthorizeService) {}

  actionBtnPolicies = {
    'Active Shipments': ['SHIPMENT_SUMMARY'],
    'Active Shipments with Delay': ['SHIPMENT_SUMMARY'],
    'Active Shipments with Excursions': ['SHIPMENT_SUMMARY'],
    'Assets': ['ASSET_SUMMARY'],
    'Sensors': ['SENSORTAG_SUMMARY'],
    'Snapshot': ['SHIPMENT_SUMMARY', 'ASSET_SUMMARY', 'LOCATION_SUMMARY']
  };
  
  mapDtAnalysisToCards(dtAnalysis: DtAnalysis): Card[] {
    const cards: Card[] = [];

    const kpi = _.cloneDeep(dtAnalysis);

    // No KPI data found, hance no cards exists
    if (_.size(kpi) <= 0) return cards;

    let index = 0;

    _.forOwn(kpi, (value, key) => {
      if(!this.isValidCard(key)) return;

      const component = this.getCardComponent(value, key);
      const name = component.split("-")[0];

      // Create new card
      const card: Card = {
        component,
        name,
        title: key,
        isFaded: false,
        isFavorite: false,
        showBookmark: false,
        data: {
          list: [],
        },
        order: index++,
      };

      if (key === 'Active Shipments'){
        let total = 0;
        value.forEach(item => {
          if(item.name === 'Total'){
            total = +item.value;
          }
          item.link = this._shipmentUtils.getKPIShipmentLink(key,item.name);
          item.color = this._utils.getBarColor(item.name)
        });
        card.data['total'] = total;

        if(total === 0){
          card.data['emptyMessage'] = 'No Shipments'
        }
      }

      if (key === 'Active Shipments with Excursions'){
        let total = 0;
        value.forEach(item => {
          if(item.value){
            total += +item.value;
          }
          item.link = this._shipmentUtils.getKPIShipmentLink(key,(item as any).id)
          item.color = this._utils.getBarColor((item as any).id);
          item.icon = this._shipmentUtils.getExcursionIcon((item as any).canonicalProperty);
        })
        card.data['total'] = total;
        card.data['isHavingIcons'] = true; 

        if(total === 0){
          card.data['emptyMessage'] = 'No Shipments'
        }
      }

      if(key === 'Active Shipments with Delay'){
        let total = 0;
        value.forEach(item => {
          total += +item.value;
          item.link = this._shipmentUtils.getKPIShipmentLink(key,(item as any).id);
        })

        if(total === 0) card.data['emptyMessage'] = 'No Shipments'
      }

      card.data.list = value.map((item) => {
        // item.name = item.name.replace('Inventory ', '');
        if (Array.isArray(item.value)) {
          const graphValues = item.value;
          const val = +graphValues[graphValues.length - 1].value - +graphValues[0].value;

          item.values = graphValues.map((item, index) => [index, +item.value]);

          item.value = val + "";
        } else if (component === "tenancy-card") {
          const mgClass = item?.name?.toLowerCase();

          item.count = +item.value;
          item.icon = "/assets/svgs/cl-icons/" + this._iconSvgService.getSVG(mgClass) + ".svg";
        }
        item.status = +item.value < 0 ? "red" : "green";
        item.status = +item.value === 0 ? " hide" : item.status;
        if (item.unit && item.unit.length > 1) {
          item.unit = " " + item.unit;
        }

        return item;
      });

      // Sort the data list item
      card.data.list.sort((a, b) => (+a.order > +b.order ? 1 : -1));

      if(key==='Active Shipments with Excursions'){
        card.data.list.sort((a, b) => (a.name > b.name ? 1 : -1));
      } 
      cards.push(card);
    });

    return cards;
  }

  /**
   * Returns the Dt analysis card component type. Defines the type of the card.
   * If its a graph, tenancy or other type of cards.
   */
  private getCardComponent(dtAnalysisItem: DtAnalysisItemList, key: string = ''): CardComponentType {
    const item = dtAnalysisItem[0];
    if(!item) return "list-card" // To prevent dtAnalysisItem is an empty array.
    if (item.unit === "%" && !Array.isArray(item.value)) return "readouts-card";
    else if (Array.isArray(item.value)) return "graph-card";
    else if (item.unit === "" && key === 'Snapshot') return "tenancy-card";
    else if (['Active Shipments','Active Shipments with Excursions'].includes(key) ) return "visual-bar-card";
    else return "list-card";
  }

  private isValidCard(cardKey){
    return this.authorizeService.isValidAction(this.actionBtnPolicies[cardKey])
  }
}
