import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from './../../@core/shell/material.module';

import { ButtonTabGroupComponent } from './button-tab-group.component';
import { ButtonTabComponent } from './button-tab.component';

@NgModule({
  declarations: [
    ButtonTabGroupComponent,
    ButtonTabComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    ButtonTabGroupComponent,
    ButtonTabComponent,
  ]
})
export class ButtonTabGroupModule { }
