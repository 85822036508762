<div class="align-center">
    <div class="text-right">
        <i class="fa fa-times-circle-o"  aria-label="close dialog" mat-dialog-close></i>
    </div>
    <div class="flex justify-center">
       <img *ngIf="type=='success'" width="50px" src="assets/svgs/circle-icons-blue/check.svg">
    </div>
    <h1 *ngIf="type" class="text-sm font-bold my-3" mat-dialog-title>
        {{type | uppercase }}!
    </h1>
    <div mat-dialog-content>
        <p class="text-sm font-bold">{{message}}</p>
    </div>
</div>