<div class="mt-2 mb-2">
  <span class="page-title">{{'common.Shipments'|translate}}</span>
</div>
<div class="mb-1 list-page-count h-5">
  <span *ngIf="!isLoading">
    Showing <span class="font-bold">{{ loadedRecords }}</span> of
    {{ totalRecords }}</span
  >
</div>

<div class="wt-ngx-grid-wrapper list-page-wrapper relative list-page px-2">
  <cl-shipment-list-actions
    #shipmentActions
    (editClick)="onEditClick($event)"
    (terminateClick)="onConfirm('terminate')"
    (deleteClick)="onConfirm('delete')"
    (completeClick)="onConfirm('complete')"
    (closeSidePanel) = "showSidePanel = false"
    (reloadShipments)="reloadShipmentsWithTimer()"
  >
    <div
      class="md-add-button list add-shipment-btn" style="z-index: 999" 
      [clIsValidAction]="actionBtnPolicies.create"
    >
      <div class="add-button" (click)="addShipment()" *ngIf="!showSidePanel">
        <span class="fa fa-plus"></span>
      </div>
      <div class="add-button" (click)="closeSidePanel()" *ngIf="showSidePanel == true && mode !== 'view'">
        <span class="fa fa-close"></span>
      </div>
    </div>
  </cl-shipment-list-actions>
  <div class="h-calc-40">
    <main class="flex flex-row h-100 overflow-y-hidden">
      <div class="grow-0" [ngClass]="isFiltersOpen ? '':'hidden'">
        <cl-shipments-fliter></cl-shipments-fliter>
      </div>
      <div class="grid-container grow relative">
        <div *ngIf="isLoading">
          <div class="spin-pos">
            <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
          </div>
        </div>
        <div *ngIf="delayedRefreshStart">
          <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
          (refreshList)="reloadShipments()"
          ></cl-refresh-countdown>
        </div>
        <div class="h-100" [style.width.px]="tableWidth">
          <cl-shipment-list-table
            (enableManageTags)="shipmentActions.isEnableManageTags = $event"
            (editShipment)="onEditShipment($event)"
            (enableTerminate)="onEnableTerminate($event)"
            (enableComplete)="onEnableComplete($event)"
            (enableDelete)="onEnableDelete($event)"
            (showShipmentSummary)="showSummary($event)"
            [shipmentList]="shipmentList"
          ></cl-shipment-list-table>
          <div class="flex flex-row items-center justify-center">
            <button
            *ngIf="totalRecords>50"
              class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
              [ngClass]="{ bcolor9: isLoading || isAllLoaded }"
              [disabled]="isLoading || isAllLoaded"
              (click)="getShipmentsList(true)"
            >
              <span class="mat-button__ripple"></span>
              <span
                class="mat-button__label color4"
                [ngClass]="{ color9: isLoading || isAllLoaded }"
                >{{'common.loadNext'|translate}}</span
              >
              <span *ngIf="isLoading"
                ><i class="fa fa-circle-o-notch fa-1x fa-pulse color4"></i
              ></span>
            </button>
          </div>
        </div>
        <div class="h-100" *ngIf="isLoading">
          <div class="spin-pos">
            <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div class="summery-pannel" *ngIf="showSidePanel">
    <cl-shipment-side-panel
      *ngIf="mode == 'add' || mode == 'edit'"
      [mode]="mode"
      [shipmentType]="editedShipmentType"
      [shipmentID]="editedShipmentID"
      (closeSidePanel)="closeSidePanel($event)"
    ></cl-shipment-side-panel>
    <cl-shipment-summary
      *ngIf="mode == 'view'"
      [shipmentID]="summaryShipmentID"
      [shipmentType]="summaryShipmentType"
      (closeSidePanel)="closeSidePanel($event)"
    ></cl-shipment-summary>
  </div>
</div>
