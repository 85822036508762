import { Injectable } from '@angular/core';
import { GatewayStatus } from '@cl/@enums/gateway-status.enum';
import { EventTab, Gateway, GatewayAttribute, GatewayAttributeDetail, GatewayAttributeEvent, GatewayEvent, GatewayEvents, GatewayHealthStatus, GatewayParsedHealthStatus, GatewaySummary } from '@cl/@types/gateway.type';
import { TimesincePipe } from '@cl/common/pipes/timesince.pipe';
import { UserService } from '@cl/user/user.service';
import { LoggedInUserType } from '@cl/@types/logged-in-user.type';
import * as _ from 'lodash';
import * as moment from 'moment';
import { GatewayService } from './gateway.service';
import { GatewayType } from '@cl/@enums/gateway-type.enum';
import { GatewayDeviceType } from '@cl/@enums/gateway-device-type.enum';
import { GatewayPower } from '@cl/@enums/gateway-power.enum';
import { CdmField } from '@cl/@types/asset.type';

@Injectable({
  providedIn: 'root'
})
export class GatewayUtilService {
  private RSSI_SHORT: string = 'SHORT';
  private RSSI_MEDIUM: string = 'MEDIUM';
  private RSSI_LARGE: string = 'LARGE';
  private RSSI_CUSTOM: string = 'CUSTOM';

  private defaultRSSIRanges: any[] = [
    { key: this.RSSI_SHORT, value: -55 },
    { key: this.RSSI_MEDIUM, value: -70 },
    { key: this.RSSI_LARGE, value: -95 },
    { key: this.RSSI_CUSTOM, value: null }
  ];
  private zsRSSIRanges : any[] = [
    { key: this.RSSI_SHORT, value: -65 },
    { key: this.RSSI_MEDIUM, value: -75 },
    { key: this.RSSI_CUSTOM, value: null }
  ];

  private rssiRanges_type = {
    'DEFAULT': this.defaultRSSIRanges,
    'MOBILE_RECEIVER': this.defaultRSSIRanges,
    'SOFT_RECEIVER': this.defaultRSSIRanges,
    'CLOUD_CONNECTOR': this.defaultRSSIRanges,
    'MICRO_CLOUD_CONNECTOR': this.zsRSSIRanges,
    'LOCATION_MARKER': this.zsRSSIRanges
  };

  private receiverTypes: string[] = ['OMNI_RECEIVER', 'MOBILE_RECEIVER', 'SOFT_RECEIVER', 'LOCATION_MARKER', 'CLOUD_CONNECTOR', 'MICRO_CLOUD_CONNECTOR'];
  private tabColors: any = {
    'R': 'red',
    'Y': 'yellow',
    'G': null
  };

  private batteryColors: any = {
    'R': 'red',
    'Y': 'yellow',
    'G': 'green',
    'B': 'black'
  };
  overallStatus: any = {
    'RED': 'Offline',
    'GREEN': 'Online',
    'YELLOW' : 'Attention'
  }
  private dataDictionary: any = {
    'receiver': {
      //System device events (CC)
      'system.restart.time': {
        'description': 'Timestamps of the reboots',
        'name': 'device reboots',
        'displayLabel': 'Device Reboots',
        'displayType': 'graph',
        'showOnlyValue': true,
        'filterSpec': "momentSecs\;yyyy-MM-dd hh:mm:ss a",
        'dependencyMetric': 'system.restart.reason',
        'swapXYValues': true,
        'staticYValue': 1,
        'rank': 1
      },
      'system.cpu': {
        'description': 'cpu % utilization',
        'name': 'cpu % util',
        'displayLabel': 'CPU Utilization',
        'displayType': 'graph',
        'rank': 2
      },
      'system.load': {
        'description': 'linux load indicator',
        'name': 'load',
        'displayLabel': 'Load',
        'displayType': 'graph',
        'rank': 3
      },
      'system.hdd': {
        'description': 'drive % utilization',
        'name': 'drive % util',
        'displayLabel': 'Flash Mem Utilization',
        'displayType': 'graph',
        'rank': 4
      },
      'system.ram': {
        'description': 'ram % utilization',
        'name': 'ram % util',
        'displayLabel': 'RAM Utilization',
        'displayType': 'graph',
        'rank': 5
      },
      'system.start.boots': {
        'description': 'lifetime OS boot counter',
        'name': 'boot count',
        'displayLabel': 'Boot count',
        'displayType': 'graph',
        'rank': 6
      },
      'system.start.time': {
        'description': 'Epoch time of last reboot (ms)',
        'name': 'start time',
        'displayLabel': 'Start time',
        'displayType': 'text',
        'showOnlyValue': true,
        'filterSpec': "moment\;yyyy-MM-dd hh:mm:ss a",
        'rank': 7
      },
      'system.start.uptime': {
        'description': 'Time since last reboot (secs)',
        'name': 'uptime<-reboot',
        'displayLabel': 'Reboot - Uptime',
        'displayType': 'graph',
        'rank': 8
      },
      'system.temperature': {
        'description': 'device temperature (degrees C)',
        'name': 'device temp',
        'displayLabel': 'Temperature',
        'displayType': 'graph',
        'rank': 9
      },
      'system.uptime': {
        'description': 'Time since last App start (secs)',
        'name': 'uptime<-App',
        'displayLabel': 'App - Uptime',
        'displayType': 'graph',
        'rank': 10
      }, //as per NOC Enhancements remove the UP time CLOUD-2320
      'system.version.config': {
        'description': 'date of the Cloud Platform config update (epoch seconds when packaged)',
        'name': 'OS version date',
        'displayLabel': 'Last OS Update',
        'displayType': 'text',
        'showOnlyValue': true,
        'filterSpec': "momentSecs\;yyyy-MM-dd hh:mm:ss a",
        'rank': 11
      },
      'system.version.md5': {
        'description': 'version hash of the Zone Sensor(powered) App (md5)',
        'name': 'App version hash',
        'displayLabel': 'App version hash',
        'displayType': 'text',
        'rank': 12
      },
      'system.version.build': {
        'description': 'version of the Zone Sensor(powered) App (md5)',
        'name': 'App version',
        'displayLabel': 'App version',
        'displayType': 'text',
        'rank': 13
      },

      //Net device events (CC)
      'net.resets': {
        'description': 'Total network interface resets',
        'name': 'net resets',
        'displayLabel': 'Network Resets',
        'displayType': 'graph',
        'rank': 1
      },
      'net.ping.clf': {
        'description': 'Total # successful pings to cloud',
        'name': 'net pings',
        'displayLabel': 'Network Pings',
        'displayType': 'graph',
        'rank': 2
      },
      'net.ping.miss': {
        'description': 'Total # missed pings to cloud',
        'name': 'net ping misses',
        'displayLabel': 'Network Ping Misses',
        'displayType': 'graph',
        'rank': 3
      },
      'net.ping._clf': {
        'description': '\u0394 # successful pings to cloud',
        'name': '\u0394 net pings',
        'displayLabel': '\u0394 Network Pings',
        'displayType': 'graph',
        'rank': 4
      },
      'net.ping._miss': {
        'description': '\u0394 # missed pings to cloud',
        'name': '\u0394 net ping misses',
        'displayLabel': '\u0394 Network Ping Misses',
        'displayType': 'graph',
        'rank': 5
      },
      'net._resets': {
        'description': '\u0394 network interface resets',
        'name': '\u0394 net resets',
        'displayLabel': '\u0394 Network Resets',
        'displayType': 'graph',
        'rank': 6
      },
      'net.traffic._down': {
        'description': '\u0394 net traffic to device from cloud (bytes)',
        'name': '\u0394 net traffic down',
        'displayLabel': '\u0394 Down Traffic',
        'displayType': 'graph',
        'rank': 7
      },
      'net.traffic._up': {
        'description': '\u0394 net traffic from device to cloud (bytes)',
        'name': '\u0394 net traffic up',
        'displayLabel': '\u0394 Up Traffic',
        'displayType': 'graph',
        'rank': 8
      },
      'net.traffic.down': {
        'description': 'Total net traffic to device from cloud (bytes)',
        'name': 'net traffic down',
        'displayLabel': 'Down Traffic',
        'displayType': 'graph',
        'rank': 9
      },
      'net.traffic.up': {
        'description': 'Total net traffic from device to cloud (bytes)',
        'name': 'net traffic up',
        'displayLabel': 'Up Traffic',
        'displayType': 'graph',
        'rank': 10
      },
      'net.uptime': {
        'description': 'Time since network has come up (secs)',
        'name': 'net uptime',
        'displayLabel': 'Network Uptime',
        'displayType': 'graph',
        'rank': 11
      },
      'net.wlan.address': {
        'description': 'IP address of wifi interface',
        'name': 'wifi IP addr',
        'displayLabel': 'Wifi Ip',
        'displayType': 'text',
        'rank': 12
      },
      'net.wlan.essid': {
        'description': 'Network name of connected wifi',
        'name': 'wifi name',
        'displayLabel': 'Wifi Name',
        'displayType': 'text',
        'rank': 13
      },
      'net.wlan.level': {
        'description': 'Wifi signal strength',
        'name': 'wifi strength',
        'displayLabel': 'Wifi Strength',
        'displayType': 'graph',
        'rank': 14
      },
      'net.wlan.link': {
        'description': 'Wifi signal integrity',
        'name': 'wifi integrity',
        'displayLabel': 'Wifi Integrity ',
        'displayType': 'graph',
        'rank': 15
      },
      'net.wlan.noise': {
        'description': 'wifi signal noise',
        'name': 'wifi noise',
        'displayLabel': 'Wifi Noise',
        'displayType': 'graph',
        'rank': 16
      },
      'net.wlan.hwaddr': {
        'description': 'Hardware address',
        'name': 'wifi hardware address',
        'displayLabel': 'Wifi MAC Id',
        'displayType': 'text',
        'rank': 17
      },
      'net.eth.address': {
        'description': 'IP address',
        'name': 'Ethernet address',
        'displayLabel': 'Ethernet',
        'displayType': 'text',
        'rank': 18
      },
      "net.cellular.address": {
        'description': 'Cellular IP address',
        'name': 'Cellular address',
        'displayLabel': 'Cellular IP',
        'displayType': 'text',
        'rank': 20
      },
      "net.wlan.address.backhaul": {
        'description': 'IP address of wifi interface',
        'name': 'wifi IP addr',
        'displayLabel': 'Wifi Ip',
        'displayType': 'text',
        'rank': 19
      },
      "net.eth.address.backhaul": {
        'description': 'IP address',
        'name': 'Ethernet address',
        'displayLabel': 'Ethernet',
        'displayType': 'text',
        'rank': 20
      },

      //Net MQTT device events (CC)
      'net.mqtt.resets': {
        'description': 'Total # mqtt connection resets',
        'name': 'mqtt resets',
        'displayLabel': 'MQTT Resets',
        'displayType': 'graph',
        'rank': 1
      },
      'net.mqtt.fails': {
        'description': 'Total # mqtt msgs that failed to be sent',
        'name': 'mqtt fails',
        'displayLabel': 'MQTT Failures',
        'displayType': 'graph',
        'rank': 2
      },
      'net.mqtt._count': {
        'description': '\u0394 # msgs sent across mqtt connection',
        'name': '\u0394 mqtt count',
        'displayLabel': '\u0394 MQTT Count',
        'displayType': 'graph',
        'rank': 3
      },
      'net.mqtt._resets': {
        'description': '\u0394 # mqtt connection resets',
        'name': '\u0394 mqtt resets',
        'displayLabel': '\u0394 MQTT Resets',
        'displayType': 'graph',
        'rank': 4
      },
      'net.mqtt._fails': {
        'description': '\u0394 # mqtt msgs that failed to be sent',
        'name': '\u0394 mqtt fails',
        'displayLabel': '\u0394 MQTT Failures',
        'displayType': 'graph',
        'rank': 5
      },
      'net.mqtt.count': {
        'description': 'Total # msgs sent across mqtt connection',
        'name': '	mqtt count',
        'displayLabel': 'MQTT Count',
        'displayType': 'graph',
        'rank': 6
      },
      'net.mqtt.uptime': {
        'description': 'Time since last mqtt connection (secs)',
        'name': 'mqtt uptime',
        'displayLabel': 'MQTT Uptime',
        'displayType': 'graph',
        'rank': 7
      },

      //Ble device events (CC)
      'ble.resets': {
        'description': 'Total ble radio resets',
        'name': 'ble resets',
        'displayLabel': 'BLE Resets',
        'displayType': 'graph',
        'rank': 1
      },
      'ble.crc': {
        'description': 'Total ble frame crc errors',
        'name': 'ble errors',
        'displayLabel': 'BLE Errors',
        'displayType': 'graph',
        'rank': 2
      },
      'ble._cxn': {
        'description': '\u0394 ble connects',
        'name': '\u0394 ble connects',
        'displayLabel': '\u0394 BLE Connects',
        'displayType': 'graph',
        'rank': 3
      },
      'ble._discxn': {
        'description': '\u0394 ble anomalous disconnects',
        'name': '\u0394 ble disconnects',
        'displayLabel': '\u0394 BLE Disconnects',
        'displayType': 'graph',
        'rank': 4
      },
      'ble._ads': {
        'description': '\u0394 ble ads (all devices)',
        'name': '\u0394 ble ads',
        'displayLabel': '\u0394 BLE ads',
        'displayType': 'graph',
        'rank': 5
      },
      'ble.ads': {
        'description': 'Total ble ads (all devices)',
        'name': 'ble ads',
        'displayLabel': 'BLE ads',
        'displayType': 'graph',
        'rank': 6
      },
      'ble.ads._reg': {
        'description': '\u0394 ble ads from registered sensors',
        'name': '\u0394 ble ads(reg)',
        'displayLabel': '\u0394 BLE ads - regular',
        'displayType': 'graph',
        'rank': 7
      },
      'ble.ads.reg': {
        'description': 'Total ble ads from registered sensors',
        'name': '	ble ads(reg)',
        'displayLabel': 'BLE ads - regular',
        'displayType': 'graph',
        'rank': 8
      },
      'ble._crc': {
        'description': '\u0394 ble frame crc errors',
        'name': '\u0394 ble errors',
        'displayLabel': '\u0394 BLE Errors',
        'displayType': 'graph',
        'rank': 9
      },
      'ble.cxn': {
        'description': 'Total ble connects',
        'name': 'ble connects',
        'displayLabel': 'BLE Connects',
        'displayType': 'graph',
        'rank': 10
      },
      'ble.discxn': {
        'description': 'Total ble anomalous disconnects',
        'name': 'ble disconnects',
        'displayLabel': 'BLE Disconnects',
        'displayType': 'graph',
        'rank': 11
      },
      'ble._resets': {
        'description': '\u0394 ble radio resets',
        'name': '\u0394 ble resets',
        'displayLabel': '\u0394 BLE Resets',
        'displayType': 'graph',
        'rank': 12
      },
      'ble.uptime': {
        'description': 'Time since ble radio since last reset',
        'name': 'ble uptime',
        'displayLabel': 'BLE Uptime',
        'displayType': 'graph',
        'rank': 13
      },
      'ble._reg': {
        'description': '\u0394 ble ads from registered CloudLeaf sensors',
        'name': '\u0394 ble ads(reg)',
        'displayLabel': '\u0394 BLE ads - regular',
        'displayType': 'graph',
        'rank': 14
      },
      'ble.reg': {
        'description': 'Total ble ads from registered CloudLeaf sensors',
        'name': 'ble ads(reg)',
        'displayLabel': 'BLE ads - regular',
        'displayType': 'graph',
        'rank': 15
      },
      'ble.table._report': {
        'description': '\u0394 # times ble table reported',
        'name': '\u0394 # ble table reports',
        'displayLabel': '\u0394 BLE Table reports',
        'displayType': 'graph',
        'rank': 16
      },
      'ble.table.report': {
        'description': '# times ble table reported',
        'name': '# ble table reports',
        'displayLabel': 'BLE Table reports',
        'displayType': 'graph',
        'rank': 17
      }
    },
    'poi-b': {
      //System device events (poi-b)
      'system.uptime': {
        'description': 'Time since zone sensor(battery) connected to zone sensor(powered) (secs)',
        'name': 'uptime',
        'displayLabel': 'Uptime',
        'displayType': 'graph',
        'rank': 1
      },
      'system.load': {
        'description': 'Measure of fullness of ble table',
        'name': 'load',
        'displayLabel': 'Load',
        'displayType': 'graph',
        'rank': 2
      },
      'system.battery': {
        'description': '% battery',
        'name': '% battery',
        'displayLabel': 'Battery',
        'displayType': 'graph',
        'rank': 3
      },
      //Ble device events (poi-b)
      'ble.rssi': {
        'description': 'ble rssi (signal strength) to zone sensor(powered)',
        'name': 'ble rssi',
        'displayLabel': 'RSSI',
        'displayType': 'graph',
        'rank': 4
      },
      'ble.cxn': {
        'description': 'Total ble connects to zone sensor(powered)',
        'name': 'ble connects',
        'displayLabel': 'BLE Connects',
        'displayType': 'graph',
        'rank': 5
      },
      'ble._cxn': {
        'description': '\u0394 ble connects to zone sensor(powered)',
        'name': '\u0394 ble connects',
        'displayLabel': '\u0394 BLE Connects',
        'displayType': 'graph',
        'rank': 6
      },
      'ble.discxn': {
        'description': 'Total ble anomalous disconnects from zone sensor(powered)',
        'name': 'ble disconnects',
        'displayLabel': 'BLE Disconnects',
        'displayType': 'graph',
        'rank': 7
      },
      'ble._discxn': {
        'description': '\u0394 ble anomalous disconnects from zone sensor(powered)',
        'name': '\u0394 ble disconnects',
        'displayLabel': '\u0394 BLE Disconnects',
        'displayType': 'graph',
        'rank': 8
      },
      'ble.ads': {
        'description': 'Total ble ads from CloudLeaf sensors',
        'name': 'ble ads',
        'displayLabel': 'BLE ads',
        'displayType': 'graph',
        'rank': 9
      },
      'ble._ads': {
        'description': '\u0394 ble ads from CloudLeaf sensors',
        'name': '\u0394 ble ads',
        'displayLabel': '\u0394 BLE ads',
        'displayType': 'graph',
        'rank': 10
      },
      'ble.ads.reg': {
        'description': 'Total ble ads from registered CloudLeaf sensors',
        'name': 'ble ads(reg)',
        'displayLabel': 'BLE ads - regular',
        'displayType': 'graph',
        'rank': 11
      },
      'ble.ads._reg': {
        'description': '\u0394 ble ads from registered CloudLeaf sensors',
        'name': '\u0394 ble ads(reg)',
        'displayLabel': '\u0394 BLE ads - regular',
        'displayType': 'graph',
        'rank': 12
      },
      'ble.table.avg': {
        'description': 'Average entries in ble table',
        'name': 'ble table avg',
        'displayLabel': 'BLE Table Average',
        'displayType': 'graph',
        'rank': 13
      },
      'ble.table.min': {
        'description': 'Minimum entries in ble table',
        'name': 'ble table min',
        'displayLabel': 'BLE Table Min',
        'displayType': 'graph',
        'rank': 14
      },
      'ble.table.max': {
        'description': 'Maximum entries in ble table',
        'name': 'ble table max',
        'displayLabel': 'BLE Table Max',
        'displayType': 'graph',
        'rank': 15
      },
      'ble.traffic.up': {
        'description': 'Total ble traffic from zone sensor(battery) to zone sensor(powered) (bytes)',
        'name': 'ble traffic up',
        'displayLabel': 'BLE Traffic Up',
        'displayType': 'graph',
        'rank': 16
      },
      'ble.traffic._up': {
        'description': '\u0394 ble traffic from zone sensor(battery) to zone sensor(powered) (bytes)',
        'name': '\u0394 ble traffic up',
        'displayLabel': '\u0394 BLE Traffic Up',
        'displayType': 'graph',
        'rank': 17
      },
      'ble.traffic.down': {
        'description': 'Total ble traffic to zone sensor(battery) from zone sensor(powered) (bytes)',
        'name': 'ble traffic down',
        'displayLabel': 'BLE Traffic Down',
        'displayType': 'graph',
        'rank': 18
      },
      'ble.traffic._down': {
        'description': '\u0394 ble traffic to zone sensor(battery) from zone sensor(powered) (bytes)',
        'name': '\u0394 ble traffic down',
        'displayLabel': '\u0394 BLE Traffic Down',
        'displayType': 'graph',
        'rank': 19
      },
      'ble._reg': {
        'description': '\u0394 ble ads from registered CloudLeaf sensors',
        'name': '\u0394 ble ads(reg)',
        'displayLabel': '\u0394 BLE ads - regular',
        'displayType': 'graph',
        'rank': 20
      },
      'ble.reg': {
        'description': 'Total ble ads from registered CloudLeaf sensors',
        'name': 'ble ads(reg)',
        'displayLabel': 'BLE ads - regular',
        'displayType': 'graph',
        'rank': 21
      },
      'ble.table._report': {
        'description': '\u0394 # times ble table reported',
        'name': '\u0394 # ble table reports',
        'displayLabel': '\u0394 BLE Table reports',
        'displayType': 'graph',
        'rank': 22
      },
      'ble.table.report': {
        'description': '# times ble table reported',
        'name': '# ble table reports',
        'displayLabel': 'BLE Table reports',
        'displayType': 'graph',
        'rank': 23
      }
    }
  };

  private xLables: any = {
    "system.cpu": "Percentage",
    "system.hdd": "Percentage",
    "system.load": "Percentage",
    "system.ram": "Percentage",
    "system.battery": "%",
    "system.start.boots": "Count",
    "system.start.time": "Epoch time in seconds",
    "system.start.uptime": "Time in seconds",
    "system.temperature": "Fahrenheit",
    "system.uptime": "Time in seconds",
    "net._resets": "Count",
    "net.ping._clf": "Count",
    "net.ping._miss": "Numeric",
    "net.ping.clf": "Count",
    "net.ping.miss": "Count",
    "net.resets": "Count",
    "net.traffic._down": "rx bytes per second",
    "net.traffic._up": "tx bytes per second",
    "net.traffic.down": "rx bytes per second",
    "net.traffic.up": "tx bytes per second",
    "net.uptime": "Time in seconds",
    "net.wlan.level": "dBm",
    "net.wlan.link": "Percentage",
    "net.wlan.noise": "Numeric",
    "net.mqtt._count": "Count",
    "net.mqtt._fails": "Count",
    "net.mqtt._resets": "Count",
    "net.mqtt.count": "Count",
    "net.mqtt.fails": "Count",
    "net.mqtt.resets": "Count",
    "net.mqtt.uptime": "Time in seconds",
    "ble._ads": "Count",
    "ble._crc": "Numeric",
    "ble._cxn": "Count",
    "ble._discxn": "Numeric",
    "ble._resets": "Numeric",
    "ble.ads": "Count",
    "ble.ads._reg": "Numeric",
    "ble.ads.reg": "Count",
    "ble.crc": "Count",
    "ble.cxn": "Count",
    "ble.discxn": "Count",
    "ble.resets": "Count",
    "ble.uptime": "Time in seconds",
    "ble._reg": "Count",
    "ble.reg": "Count"
  };

  private reasonCodes: any = {
    "0": {
      "reasonCode": 0,
      "appExit": "",
      "shortDesc": "Indeterminant",
      "desc": "Source unknown"
    },
    "1": {
      "reasonCode": 1,
      "appExit": "",
      "shortDesc": "Rebooted",
      "desc": "Reboot Issued"
    },
    "2": {
      "reasonCode": 2,
      "appExit": "",
      "shortDesc": "Network Watchdog",
      "desc": "Network WatchDog - Unable to Ping Cloud Url"
    },
    "3": {
      "reasonCode": 3,
      "appExit": "",
      "shortDesc": "MQTT Watchdog",
      "desc": "MQTT WatchDog - Mqtt Publish messages or pings are not flowing through"
    },
    "4": {
      "reasonCode": 4,
      "appExit": "",
      "shortDesc": "Kernel Watchdog",
      "desc": "Kernal WatchDog "
    },
    "5": {
      "reasonCode": 5,
      "appExit": "",
      "shortDesc": "App Watchdog",
      "desc": "Boot Watchdog - App didn't feed any watchdogs for 1st 5 minutes"
    },
    "6": {
      "reasonCode": 6,
      "appExit": "",
      "shortDesc": "OTA Update (App)",
      "desc": "OTA App Update"
    },
    "7": {
      "reasonCode": 7,
      "appExit": "",
      "shortDesc": "OTA Update (Full)",
      "desc": "OTA Full Update - OS and App"
    },
    "8": {
      "reasonCode": 8,
      "appExit": "",
      "shortDesc": "OTA Update (OS)",
      "desc": "OTA OS Update"
    },
    "9": {
      "reasonCode": 9,
      "appExit": "",
      "shortDesc": "Power Loss",
      "desc": "Power outage [unknown] [kernel panic]"
    },
    "10": {
      "reasonCode": 10,
      "appExit": "",
      "shortDesc": "TBD: Kernel Panic",
      "desc": "Future OS releases should be able to flag this"
    },
    "31": {
      "reasonCode": 31,
      "appExit": "0",
      "shortDesc": "No Error",
      "desc": "EXIT_NO_ERROR"
    },
    "32": {
      "reasonCode": 32,
      "appExit": "1",
      "shortDesc": "General Error",
      "desc": "EXIT_GENERAL"
    },
    "33": {
      "reasonCode": 33,
      "appExit": "2",
      "shortDesc": "Usage Error",
      "desc": "EXIT_MISUSE"
    },
    "34": {
      "reasonCode": 34,
      "appExit": "3",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_MAIN_LOOP_INIT"
    },
    "35": {
      "reasonCode": 35,
      "appExit": "4",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_MAIN_LOOP_DONE"
    },
    "36": {
      "reasonCode": 36,
      "appExit": "5",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_CURL_ALLOC"
    },
    "37": {
      "reasonCode": 37,
      "appExit": "6",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_CURL_INIT"
    },
    "38": {
      "reasonCode": 38,
      "appExit": "7",
      "shortDesc": "Gateway Deleted",
      "desc": "EXIT_GW_DELETED"
    },
    "39": {
      "reasonCode": 39,
      "appExit": "8",
      "shortDesc": "TagEvent Read Failure",
      "desc": "EXIT_TAGEVENT_OPEN_READ"
    },
    "40": {
      "reasonCode": 40,
      "appExit": "9",
      "shortDesc": "TagEvent Write Failure",
      "desc": "EXIT_TAGEVENT_OPEN_WRITE"
    },
    "41": {
      "reasonCode": 41,
      "appExit": "10",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_ACCESS_MUTEX"
    },
    "42": {
      "reasonCode": 42,
      "appExit": "11",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_MAC_ETH0"
    },
    "43": {
      "reasonCode": 43,
      "appExit": "12",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_CFG_ALLOC"
    },
    "44": {
      "reasonCode": 44,
      "appExit": "13",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_TAGDB_ALLOC"
    },
    "45": {
      "reasonCode": 45,
      "appExit": "14",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_TAGDB_INIT"
    },
    "46": {
      "reasonCode": 46,
      "appExit": "15",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_NET_SERVICE"
    },
    "47": {
      "reasonCode": 47,
      "appExit": "16",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_CXN_MANAGER"
    },
    "48": {
      "reasonCode": 48,
      "appExit": "17",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_CXN_MAP"
    },
    "49": {
      "reasonCode": 49,
      "appExit": "18",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_CXN_PROGRESS"
    },
    "50": {
      "reasonCode": 50,
      "appExit": "19",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_CXN_OPS"
    },
    "51": {
      "reasonCode": 51,
      "appExit": "20",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_CXN_THREAD"
    },
    "52": {
      "reasonCode": 52,
      "appExit": "21",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_REST_ALLOC"
    },
    "53": {
      "reasonCode": 53,
      "appExit": "22",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_REST_INIT"
    },
    "54": {
      "reasonCode": 54,
      "appExit": "23",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_MQTT_ALLOC"
    },
    "55": {
      "reasonCode": 55,
      "appExit": "24",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_MQTT_Q_ALLOC"
    },
    "56": {
      "reasonCode": 56,
      "appExit": "25",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_LEAF_ALLOC"
    },
    "57": {
      "reasonCode": 57,
      "appExit": "26",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_LEAF_TX"
    },
    "58": {
      "reasonCode": 58,
      "appExit": "27",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_LEAF_RX"
    },
    "59": {
      "reasonCode": 59,
      "appExit": "28",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_LEAF_RADIO"
    },
    "60": {
      "reasonCode": 60,
      "appExit": "29",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_LEAF_THREAD"
    },
    "61": {
      "reasonCode": 61,
      "appExit": "30",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_TIMER_THREAD"
    },
    "62": {
      "reasonCode": 62,
      "appExit": "31",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_HEARTBEAT_THREAD"
    },
    "63": {
      "reasonCode": 63,
      "appExit": "32",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_TAGHB_THREAD"
    },
    "64": {
      "reasonCode": 64,
      "appExit": "33",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_DOWNLOAD_THREAD"
    },
    "65": {
      "reasonCode": 65,
      "appExit": "34",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_UPDATE_THREAD"
    },
    "66": {
      "reasonCode": 66,
      "appExit": "35",
      "shortDesc": "App Initialization Failure",
      "desc": "EXIT_AF_RADIO_START"
    },
    "157": {
      "reasonCode": 157,
      "appExit": "126",
      "shortDesc": "",
      "desc": "EXIT_CANNOT_EXECUTE"
    },
    "158": {
      "reasonCode": 158,
      "appExit": "127",
      "shortDesc": "OTA Execution Error",
      "desc": "EXIT_COMMAND_NOT_FOUND"
    },
    "159": {
      "reasonCode": 159,
      "appExit": "128",
      "shortDesc": "",
      "desc": "EXIT_INVALID_ARGUMENT"
    },
    "160": {
      "reasonCode": 160,
      "appExit": "129",
      "shortDesc": "",
      "desc": "EXIT_SIGHUP"
    },
    "161": {
      "reasonCode": 161,
      "appExit": "130",
      "shortDesc": "",
      "desc": "EXIT_SIGINT"
    },
    "162": {
      "reasonCode": 162,
      "appExit": "131",
      "shortDesc": "",
      "desc": "EXIT_SIGQUIT"
    },
    "163": {
      "reasonCode": 163,
      "appExit": "132",
      "shortDesc": "",
      "desc": "EXIT_SIGILL"
    },
    "164": {
      "reasonCode": 164,
      "appExit": "133",
      "shortDesc": "",
      "desc": "EXIT_SIGTRAP"
    },
    "165": {
      "reasonCode": 165,
      "appExit": "134",
      "shortDesc": "",
      "desc": "EXIT_SIGABRT"
    },
    "166": {
      "reasonCode": 166,
      "appExit": "135",
      "shortDesc": "",
      "desc": "EXIT_SIGEMT"
    },
    "167": {
      "reasonCode": 167,
      "appExit": "136",
      "shortDesc": "",
      "desc": "EXIT_SIGFPE"
    },
    "168": {
      "reasonCode": 168,
      "appExit": "137",
      "shortDesc": "",
      "desc": "EXIT_SIGKILL"
    },
    "169": {
      "reasonCode": 169,
      "appExit": "138",
      "shortDesc": "",
      "desc": "EXIT_SIGBUS"
    },
    "170": {
      "reasonCode": 170,
      "appExit": "139",
      "shortDesc": "",
      "desc": "EXIT_SIGSEGV"
    },
    "171": {
      "reasonCode": 171,
      "appExit": "140",
      "shortDesc": "",
      "desc": "EXIT_SIGSYS"
    },
    "172": {
      "reasonCode": 172,
      "appExit": "141",
      "shortDesc": "",
      "desc": "EXIT_SIGPIPE"
    },
    "173": {
      "reasonCode": 173,
      "appExit": "142",
      "shortDesc": "",
      "desc": "EXIT_SIGALRM"
    },
    "174": {
      "reasonCode": 174,
      "appExit": "143",
      "shortDesc": "",
      "desc": "EXIT_SIGTERM"
    },
    "175": {
      "reasonCode": 175,
      "appExit": "144",
      "shortDesc": "",
      "desc": "EXIT_SIGUSR1"
    },
    "176": {
      "reasonCode": 176,
      "appExit": "145",
      "shortDesc": "",
      "desc": "EXIT_SIGUSR2"
    },
    "177": {
      "reasonCode": 177,
      "appExit": "146",
      "shortDesc": "",
      "desc": "EXIT_SIGCHLD"
    },
    "178": {
      "reasonCode": 178,
      "appExit": "147",
      "shortDesc": "",
      "desc": "EXIT_SIGPWR"
    },
    "179": {
      "reasonCode": 179,
      "appExit": "148",
      "shortDesc": "",
      "desc": "EXIT_SIGWINCH"
    },
    "180": {
      "reasonCode": 180,
      "appExit": "149",
      "shortDesc": "",
      "desc": "EXIT_SIGURG"
    },
    "181": {
      "reasonCode": 181,
      "appExit": "150",
      "shortDesc": "",
      "desc": "EXIT_SIGPOLL"
    },
    "182": {
      "reasonCode": 182,
      "appExit": "151",
      "shortDesc": "",
      "desc": "EXIT_SIGSTOP"
    },
    "183": {
      "reasonCode": 183,
      "appExit": "152",
      "shortDesc": "",
      "desc": "EXIT_SIGTSTP"
    },
    "184": {
      "reasonCode": 184,
      "appExit": "153",
      "shortDesc": "",
      "desc": "EXIT_SIGCONT"
    },
    "185": {
      "reasonCode": 185,
      "appExit": "Stopped (tty input)",
      "shortDesc": "",
      "desc": ""
    },
    "186": {
      "reasonCode": 186,
      "appExit": "Stopped (tty output)",
      "shortDesc": "",
      "desc": ""
    },
    "187": {
      "reasonCode": 187,
      "appExit": "Virtual Timer Expired",
      "shortDesc": "",
      "desc": ""
    },
    "188": {
      "reasonCode": 188,
      "appExit": "Profiling Timer Expired",
      "shortDesc": "",
      "desc": ""
    },
    "189": {
      "reasonCode": 189,
      "appExit": "CPU time limit exceeded",
      "shortDesc": "",
      "desc": ""
    },
    "190": {
      "reasonCode": 190,
      "appExit": "File size limit exceeded",
      "shortDesc": "",
      "desc": ""
    },
    "191": {
      "reasonCode": 191,
      "appExit": "All LWPs blocked",
      "shortDesc": "",
      "desc": ""
    },
    "192": {
      "reasonCode": 192,
      "appExit": "Virtual Interprocessor Interrupt for Threads Library",
      "shortDesc": "",
      "desc": ""
    },
    "193": {
      "reasonCode": 193,
      "appExit": "Asynchronous I/O",
      "shortDesc": "",
      "desc": ""
    },
    "286": {
      "reasonCode": 286,
      "appExit": "Exit status out of range",
      "shortDesc": "",
      "desc": ""
    }
  };
  rssiRanges: any;
  constructor(private timesincePipe: TimesincePipe, private userService: UserService,private gatewayService: GatewayService) {
    this.rssiRanges = _.map(this._fnDefaultRSSIRanges(), 'key');
  }
  getDefaultRSSIRanges() : any {
    return {
      DEFAULTMAXRANGE: -40,
      DEFAULTMINRANGE: -100,
      MAXRANGE_ZS: -40,
      MINRANGE_ZS: -100
    }
  }

  getRSSIRanges(type: string): any {
    return type ? (this.rssiRanges_type[type] || this.rssiRanges_type['DEFAULT']) : this.rssiRanges_type['DEFAULT'];
  }

  // reasonCodes () : any {
  //   return {
  //     reasonCodes: _reasonCodeObj
  //   }
  // }

  // this.xlablesGateway  () : any {
  //   return {
  //     getXlabels: function () {
  //       return xLables;
  //     }
  //   }
  // }
  getYAxeslabel(key : string) : string {
    if(!_.isEmpty(this.xLables) && !_.isEmpty(key)){
      let temp: string = this.xLables[key];
      if(!_.isEmpty(temp)){
        return temp.toUpperCase();
      }else{
        return '';
      }
    }else{
      return '';
    }
  }

  _fnDefaultRSSIRanges() : any{
    return this.defaultRSSIRanges;
  }

  filterByReceiverType(receiver) : any{
    return ((receiver.type === "OMNI_RECEIVER" || receiver.type === "CLOUD_CONNECTOR") && (receiver.status === "PROVISIONED"));
  }

  getReceiverTypeLabels () : any {
    return {
      CLOUD_CONNECTOR: 'Gateway',
      MICRO_CLOUD_CONNECTOR: 'Zone Sensor(powered)',
      MOBILE_RECEIVER: 'Soft Gateway'
    };
  }

  getReasonCodes() {
    return this.reasonCodes;
  }

  /**Updates the receiver object with attributes */
  setupReceiverData (receiver) {

    if (receiver.attributes === null) {
      receiver.attributes = {};
      receiver.attributes.batteryPowered = true;
    }
    receiver.attributes.batteryPowered = (receiver.attributes.batteryPowered !== "false");

    if (receiver.attributes.rssiRange && isNaN(receiver.attributes.rssiRange)) {
      receiver.attributes.rssiRangeDisplay = receiver.attributes.rssiRange;
    } else if (receiver.attributes.rssiRangeDisplay === "CUSTOM") {
      receiver.attributes.rssiRangeValue = Number(receiver.attributes.rssiRange || receiver.attributes.rssiRangeValue);
    } else if (receiver.attributes.rssiRangeDisplay !== "CUSTOM") {
      //Fix for CLOUD-1315. Do nothing, as the provisioning of the Micro CC would require the rssiRangeValue, be passed as it is.
      //receiver.attributes.rssiRangeValue = null;
    } else {
      receiver.attributes.rssiRangeDisplay = "CUSTOM";
      receiver.attributes.rssiRangeValue = Number(receiver.attributes.rssiRange);

    }
  }
  timeSince(tSince): any {
    return this.timesincePipe.transform(tSince);
  }
  getNew (areaId) {
    let receiver: any = {};
    receiver.attributes = {};
    receiver.attributes.batteryPowered = true;
    receiver.areaId = areaId;
    receiver.type = "OMNI_RECEIVER";
    receiver.attributes.rssiRange = _.map(this._fnDefaultRSSIRanges(), 'key')[0];

    return receiver;
  }

  _getTheSpyderData(gateway: Gateway) {
    let hasGPSData = false
    let mapData: any[] = [];
    if (gateway && gateway.attribute && gateway.attribute.backhaul === 'cellular' && gateway.healthStatus) {
      var netEvents = gateway.healthStatus.tabs.net.events;
      var gpsObj = _.filter(netEvents, function (event) {
        return event.name === 'net.gps';
      });
      if (gpsObj && gpsObj[0] && gpsObj[0].value) {
        hasGPSData = true;
        var tempObj = { value: gpsObj[0].value };
        if (_.findIndex(mapData, tempObj) === -1) {
          mapData.push(tempObj);
        }
      } else {
        hasGPSData = false;
      }
    } else {
      hasGPSData = false;
    }
    return { hasGPSData : hasGPSData, mapData : mapData };
  }

  private getNetTraffic(val) : string {
    if (val === 'N/A') {
      return 'N/A';
    }
    if (val < 1000) {
      return val + 'B';
    } else if (val < 1000000) {
      return Math.round(val / 1000) + 'KB';
    } else {
      return Math.round(val / 1000000) + 'MB';
    }
  }
  private updateEvent(healthStatus, event) {
    let deviceType = healthStatus.type;

    //find event from healthStatus by name
    let eventName = event.name;
    let events = [];
    if (eventName.indexOf('system.') === 0) {
      events = healthStatus.tabs.system.events;
    } else if (eventName.indexOf('net.mqtt.') === 0) {
      events = healthStatus.tabs['net.mqtt'].events;
    } else if (eventName.indexOf('net.') === 0) {
      events = healthStatus.tabs.net.events;
    } else if (eventName.indexOf('ble.') === 0) {
      events = healthStatus.tabs.ble.events;
    }

    let oldEvent = null;
    for (let i = 0; i < events.length; i++) {
      if (events[i].name === eventName) {
        if (eventName === "net.wlan.address.backhaul" || eventName === "net.eth.address.backhaul") {
          break;
        } else {
          oldEvent = events[i];
          break;
        }
      }
      _.remove(events, {
        name: 'net.wlan.address.backhaul'
      });
      _.remove(events, {
        name: 'net.eth.address.backhaul'
      });
    }

    if (oldEvent) {
      oldEvent.time = event.time;
      oldEvent.value = event.value;
    } else {
      //convert time
      event['time'] = event['time'];
      if (this.dataDictionary[deviceType][event.name]) {
        event['displayName'] = this.dataDictionary[deviceType][event.name]['displayLabel'];
        event['description'] = this.dataDictionary[deviceType][event.name]['description'];
        event['rank'] = this.dataDictionary[deviceType][event.name]['rank'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].displayType) {
        event['displayType'] = this.dataDictionary[deviceType][event.name]['displayType'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].filterSpec) {
        event['filterSpec'] = this.dataDictionary[deviceType][event.name]['filterSpec'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].showOnlyTimeStamp) {
        event['showOnlyTimeStamp'] = this.dataDictionary[deviceType][event.name]['showOnlyTimeStamp'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].showOnlyValue) {
        event['showOnlyValue'] = this.dataDictionary[deviceType][event.name]['showOnlyValue'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].dependencyMetric) {
        event['dependencyMetric'] = this.dataDictionary[deviceType][event.name]['dependencyMetric'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].swapXYValues) {
        event['swapXYValues'] = this.dataDictionary[deviceType][event.name]['swapXYValues'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].staticXValue) {
        event['staticXValue'] = this.dataDictionary[deviceType][event.name]['staticXValue'];
      }
      if (this.dataDictionary[deviceType][event.name] && this.dataDictionary[deviceType][event.name].staticYValue) {
        event['staticYValue'] = this.dataDictionary[deviceType][event.name]['staticYValue'];
      }

      if (event.name !== "net.wlan.address" && event.name !== "net.eth.address") {
        oldEvent = event;
        events.push(oldEvent);
      }
    }
  }
  private updateStatusObjectInGateway(statusObject: GatewayAttributeDetail, tempValue:any) {
    if (!tempValue){
      return null;
    }
    if (!statusObject) {
      statusObject = { cssClass: null, value: null};
    }
    if (tempValue === 'GREEN') {
      statusObject.value = 'UP';
      statusObject.cssClass = 'success';
    } else if (tempValue === 'YELLOW') {
      statusObject.value = 'WARN';
      statusObject.cssClass = 'warning';
    } else if (tempValue === 'RED') {
      statusObject.value = 'DOWN';
      statusObject.cssClass = 'danger';
    } else if (_.isNumber(tempValue)) {
      if (tempValue >= 90) {
        statusObject.value = tempValue;
        statusObject.cssClass = 'danger';
      } else if (tempValue >= 80) {
        statusObject.value = tempValue;
        statusObject.cssClass = 'warning';
      } else {
        statusObject.value = tempValue;
        statusObject.cssClass = 'success';
      }
    } else {
      statusObject.value = tempValue;
      statusObject.cssClass = 'default';
    }
  }

  private setGatewayUpdatedEvents(receiver: Gateway, events: GatewayAttributeEvent[] ){
    let healthStatus: GatewayParsedHealthStatus = receiver.healthStatus;
    /**
    * CPU - heartbeat:system.cpu
    * RAM - heartbeat:system.ram
    * Load - heartbeat:system.load
    * HDD - heartbeat:system.hdd
    */
    for (let j: number = 0, event: any; j < events.length; j++) {
      event = events[j];
      event.deviceid = receiver.id;
      event.devicetype = receiver.deviceType;

      if (event.module === 'heartbeat') {
        if (event.name === 'system.cpu') {
          this.updateStatusObjectInGateway(healthStatus.cpu, Math.round(event.value));
        } else if (event.name === 'system.ram') {
          this.updateStatusObjectInGateway(healthStatus.ram, Math.round(event.value));
        } else if (event.name === 'system.load') {
          healthStatus.load = event.value;

        } else if (event.name === 'system.hdd') {
          this.updateStatusObjectInGateway(healthStatus.hdd, Math.round(event.value));
        }else if (event.name === 'ble.traffic._down'  && receiver.isPoib) { //net traffic
          healthStatus.netTrafficDown = this.getNetTraffic(event.value);
        } else if (event.name === 'ble.traffic._up' && receiver.isPoib) { //net traffic
          healthStatus.netTrafficUp = this.getNetTraffic(event.value);
        } else if (event.name === 'net.traffic._down' && !receiver.isPoib) { //net traffic
          healthStatus.netTrafficDown = this.getNetTraffic(event.value);
        } else if (event.name === 'net.traffic._up' && !receiver.isPoib) { //net traffic
          healthStatus.netTrafficUp = this.getNetTraffic(event.value);
        }else if (event.name === 'system.uptime') { //lastUpdateTime
          healthStatus['systemUptime'] = event.time;
        } else if (event.name === 'net.wlan.address.backhaul') { //Backhaul
          healthStatus['backhaulType'] = "WiFi";
          healthStatus['backhaulValue'] = event.value ? event.value : 'N/A';  //Backhaul
        } else if (event.name === 'net.eth.address.backhaul') {
          healthStatus['backhaulType'] = "Ethernet";
          healthStatus['backhaulValue'] = event.value ? event.value : 'N/A';
        } else if (event.name === 'net.cellular.address.backhaul') {
          healthStatus['backhaulType'] = "Cellular";
          healthStatus['backhaulValue'] = event.value ? event.value : 'N/A';
        } else  if (event.name === "system.battery" && receiver.isPoib) {
          event.value = event.value ? event.value + "%" : 'N/A';
        }
        this.updateEvent(healthStatus, event);
      } else if (event.module === 'status') {
        if (event.name === "bleStatus") {
          healthStatus['bleStatus'] = event.value;
        } else if (event.name === "netStatus") {
          healthStatus['netStatus'] = event.value;
        } else if (event.name === "overallStatus") {
          healthStatus['overallStatus'] = event.value;
        } else if (event.name === "net.tabColor") {
          healthStatus['tabs']['net']['tabColor'] = event.value;
        } else if (event.name === "ble.tabColor") {
          healthStatus['tabs']['ble']['tabColor'] = event.value;
        } else if (event.name === "net.mqtt.tabColor") {
          healthStatus['tabs']['net.mqtt']['tabColor'] = event.value;
        } else if (event.name === "system.tabColor") {
          healthStatus['tabs']['system']['tabColor'] = event.value;
        } else if (event.name === "netStatus.cause") {
          healthStatus['tabs']['net']['cause'] = '';
          healthStatus['tabs']['net.mqtt']['cause'] = '';
          if (event.value.indexOf('mqtt') > 0) {
            healthStatus['tabs']['net.mqtt']['cause'] = this.dataDictionary.receiver[event.value] ? this.dataDictionary.receiver[event.value].displayLabel : '';
          } else if (event.value.indexOf('net') > 0) {
            healthStatus['tabs']['net']['cause'] = this.dataDictionary.receiver[event.value] ? this.dataDictionary.receiver[event.value].displayLabel : '';
          } else {
          }
        } else if (event.name === "bleStatus.cause") {
          healthStatus['tabs']['ble']['cause'] = this.dataDictionary.receiver[event.value] ? this.dataDictionary.receiver[event.value].displayLabel : '';
        } else if (event.name === "overallStatus.cause" && !receiver.isPoib) {
          healthStatus['tabs']['system']['cause'] = this.dataDictionary.receiver[event.value] ? this.dataDictionary.receiver[event.value].displayLabel : '';
        }else {
        }
      }
    }
  }
  private updateGatewayHealthStatusAndEvents(gateway : Gateway, metricsData : GatewayHealthStatus) : Promise<GatewayParsedHealthStatus>{
    return new Promise((resolve, reject) => {
    let healthStatus : GatewayParsedHealthStatus = gateway.healthStatus;
    for (var j = 0, metric; j < metricsData.metrics.length; j++) {
      metric = metricsData.metrics[j];
      if (metric.group === 'com.cloudleaf.metrics.deviceHealth') {
        var nestedMetrics = metric.metrics;
        if (gateway.isPoib) {
          healthStatus.batteryStatusColor = this.batteryColors[nestedMetrics.batteryStatusColorPoi_b];
        }
        //tab colors
        healthStatus.tabs.ble.tabColor = this.tabColors[nestedMetrics['ble.tabColor']];
        healthStatus.tabs.system.tabColor = this.tabColors[nestedMetrics['system.tabColor']];
        //net and mqtt tabs only apply for none POI-B

        //bleStatus
        this.updateStatusObjectInGateway(healthStatus.bleStatus, nestedMetrics.bleStatus);
        healthStatus.bleStatus.cause = nestedMetrics['bleStatus.cause'];

        //netStatus
        if (gateway.isPoib) {
          this.updateStatusObjectInGateway(healthStatus.netStatus, '-');
          healthStatus.netStatus.cause = "";
        } else {
          this.updateStatusObjectInGateway(healthStatus.netStatus, nestedMetrics.netStatus);
          healthStatus.netStatus.cause = nestedMetrics['netStatus.cause'];
          healthStatus.tabs.net.tabColor = this.tabColors[nestedMetrics['net.tabColor']];
          healthStatus.tabs['net.mqtt'].tabColor = this.tabColors[nestedMetrics['net.mqtt.tabColor']];
        }

        //overallStatus
        this.updateStatusObjectInGateway(healthStatus.overallStatus, nestedMetrics.overallStatus);
        healthStatus.overallStatus.cause = nestedMetrics['overallStatus.cause'];
      }
    }
    let promise = gateway.isPoib ? this.gatewayService.getPOIBState(gateway.id) : this.gatewayService.getGatewayEvents(gateway.id);
    promise
      .then((gatewayEvents: GatewayEvents)=>{
        healthStatus.allEvents = _.cloneDeep(gatewayEvents.events); //TODO need to check the clone time and use it
        gatewayEvents.events = _.filter(gatewayEvents.events, (event) => {return event.module === 'heartbeat' || event.module === 'status' });
        this.setGatewayUpdatedEvents(gateway, gatewayEvents.events);
        resolve(healthStatus);
      })
      .catch(()=>{
        resolve(healthStatus);
      });
    });
  }
  private isPoib(gatewaySummary?: GatewaySummary, gatewayHealthStatus?: GatewayHealthStatus): boolean{
    let isPOIB: boolean = false;
    let tempMetric: any = _.find(gatewayHealthStatus?.metrics, {group : 'com.cloudleaf.metrics.deviceHealth'});
    let isLocationMarker = gatewaySummary?.type === 'LOCATION_MARKER';
    if(isLocationMarker){
      if(gatewaySummary?.attributes && gatewaySummary?.attributes?.batteryPowered === "true"){
        isPOIB = true;
      }
      if(isLocationMarker && tempMetric?.metrics?.batteryStatusColorPoi_b){
        isPOIB = true;
      }
    }
    return isPOIB;
  }
  private getDraftHealthStatusObject(isPoib : boolean): GatewayParsedHealthStatus{
    return {
      'type': isPoib ? GatewayDeviceType.POIB : GatewayDeviceType.RECEIVER,
      'bleStatus': null,
      'netStatus': null,
      'overallStatus': null,
      'cpu': { cssClass : null, value: null},
      'ram': { cssClass : null, value: null},
      'hdd': { cssClass : null, value: null},
      'netTrafficDown': null,
      'netTrafficUp': null,
      'tabs': {
        'ble': { name: 'BLE', tabColor: null, events: []},
        'net': { name: 'NET', tabColor: null, events: []},
        'net.mqtt': { name: 'NET MQTT', tabColor: null, events: []},
        'system': { name: 'SYSTEM', tabColor: null, events: []}
      }
    }
  }
  private getGatewayAttribute(gatewaySummary : GatewaySummary, gatewayEvents ?: GatewayAttributeEvent[]) : GatewayAttribute{
    let attribute : GatewayAttribute = {};
    attribute.model = gatewaySummary.attributes.model;
    attribute.sku = gatewaySummary.attributes.sku;
    attribute.power = GatewayPower[gatewaySummary.type];
    attribute.physicalId = gatewaySummary.physicalId;
    attribute.rssiRange = gatewaySummary.attributes.rssiRange;
    attribute.appVersion = gatewaySummary.attributes.version ?? this.getEventValue('system.version.build', gatewayEvents);
    attribute.hardwareVersion = gatewaySummary.attributes.hwrev;
    attribute.backhaul  = gatewaySummary.attributes.backhaul?gatewaySummary.attributes.backhaul:"N/A";
    return attribute;
  }
  private getGatewayEventTabs(gatewayObject : Gateway) : EventTab[]{
    let tempTabs : EventTab[] = [];
    let tabs = gatewayObject.healthStatus.tabs;
    tempTabs.push(tabs.system);
    if (tabs && tabs.net && tabs.net.events && tabs.net.events) {
      _.remove(tabs.system.events, { name: 'system.version.build' })
    }
    if (tabs && tabs.net && tabs.net.events && tabs.net.events) {
      gatewayObject.attribute.wifiSSID = _.find(tabs.net.events, { name: 'net.wlan.essid' })?.value;
      _.remove(tabs.net.events, { name: 'net.wlan.essid' });
    }
    if (gatewayObject.attribute && gatewayObject.attribute.backhaul === 'wifi') {
      _.remove(tabs.net.events, { name: 'net.eth.address' })
    } else if (gatewayObject.attribute && gatewayObject.attribute.backhaul === 'ethernet') {
      _.remove(tabs.net.events, { name: 'net.wlan.address' })
    }

    if (!gatewayObject.isPoib) {
      tempTabs.push(tabs.net);
      tempTabs.push(tabs['net.mqtt']);
    }
    tempTabs.push(tabs.ble);
    return tempTabs;
  }
  private getEventValue(eventName: string, gatewayEvents : GatewayAttributeEvent[]): any{
    let event: GatewayAttributeEvent = _.find(gatewayEvents, {name : eventName});
    return event?.value;
  }
  private getGateway(gatewaySummary: GatewaySummary, gatewayHealthStatus : GatewayHealthStatus, properties, externalAttr): Promise<Gateway>{
    return new Promise<Gateway>((resolve, reject) => {
      let tempGWObject: Gateway;
      let cdmFields = externalAttr?.cdmFields?.filter(field => field.displayable && field?.group?.toLowerCase() === 'user');
      cdmFields = _.orderBy(cdmFields, 'order', 'asc');
      try{
        let user:LoggedInUserType = this.userService.getUser();
        let tempDate: Date = gatewaySummary.lastReported ? new Date(gatewaySummary.lastReported) : null; //This is the gateway unprovisioned date timestamp;
        let isPOIB = this.isPoib(gatewaySummary, gatewayHealthStatus);
        tempGWObject = {
          id : gatewaySummary.identifier,
          name : gatewaySummary.name,
          status : GatewayStatus[gatewaySummary.status],
          imageUrl : 'assets/images/gateway-logo.png',
          isPoib : isPOIB,
          deviceType : isPOIB ? GatewayDeviceType.POIB : GatewayDeviceType.RECEIVER,
          type: GatewayType[gatewaySummary.type],
          area : gatewaySummary.area,
          building : gatewaySummary.building,
          position : gatewaySummary.position,
          provisionedAt: gatewaySummary.provisionedAt ? new Date(gatewaySummary.provisionedAt) : null,
          unprovisioned: tempDate,
          lastReported : tempDate,
          tenantId : gatewaySummary.tenantId,
          tenantName : user.tenantName,
          healthStatus : this.getDraftHealthStatusObject(isPOIB),
          properties : properties,
          cdmFields: cdmFields
        };
        this.updateGatewayHealthStatusAndEvents(tempGWObject, gatewayHealthStatus).then((result: GatewayParsedHealthStatus) => {
          tempGWObject.healthStatus = result;
          tempGWObject.attribute = this.getGatewayAttribute(gatewaySummary, result.allEvents);
          tempGWObject.eventTabs = this.getGatewayEventTabs(tempGWObject);
          resolve(tempGWObject);
        });
      }catch(e){
        resolve(tempGWObject);
      }
    });
  }
  getGatewayObject(gatewayId : string, gatewayType=''): Promise<Gateway>{
    return new Promise<Gateway>((resolve, reject)=>{
      Promise.all([
        this.gatewayService.getGatewayDetails(gatewayId),
        this.gatewayService.getGatewayHealth(gatewayId),
        this.gatewayService.getGatewayProps(gatewayId, gatewayType),
        this.gatewayService.getGatewayCatalog(),
      ])
      .then((responses: [GatewaySummary, GatewayHealthStatus, any, CdmField]) => {
        this.getGateway(responses[0], responses[1], responses[2], responses[3]).then((gateway: Gateway)=>{
          resolve(gateway);
        });
      })
      .catch(() => {
        reject();
      });
    });
  }
  getGatewaySelectedAttributeEventData(selectedEvent : GatewayEvent, startDate : moment.Moment, endDate: moment.Moment) : Promise<[number, string][]>{
    return new Promise<[number, string][]>((resolve) => {
      this.gatewayService.getGatewayAttributeEvents(selectedEvent.deviceid,
        selectedEvent.name, selectedEvent.devicetype, selectedEvent.module,
        startDate.valueOf(), endDate.valueOf(), 400)
      .then((response : GatewayEvents)=>{
        let temp : [number, string][] = _.map(response?.events, (event: GatewayEvent)=>{
          return [event.time, event.value];
        });
        resolve(temp);
      })
      .catch(()=>{
        resolve([]);
      });
    });
  }
}


