import { Pipe, PipeTransform } from '@angular/core';
import { GatewayUtilService } from '@cl/sensor-fabric/gateway/gateway-util.service';

@Pipe({
  name: 'gatewayOverallStatus'
})
export class GatewayOverallStatusPipe implements PipeTransform {
  overallStatus: any;
  constructor(private gatewayUtilService: GatewayUtilService){
    this.overallStatus = this.gatewayUtilService.overallStatus;
  }
  transform(value: any, ...args: unknown[]): string {
    if(this.overallStatus.hasOwnProperty(value)){
      return this.overallStatus[value];
    }else{
      return 'N/A';
    }
  }

}
