import { AgmCoreModule } from "@agm/core";
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { AgmSnazzyInfoWindowModule } from "@agm/snazzy-info-window";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { GoogleChartsModule } from "angular-google-charts";
import { InlineSVGModule } from "ng-inline-svg";
// import { MaterialModule } from "@cl/material_remove/material.module";
import { MaterialModule } from './../@core/shell/material.module';
import { DragAndDropDirective } from "../common/directives/drag-and-drop.directive";
import { MakeDraggableDirective } from "../common//directives/make-draggable.directive";
import { ButtonTabGroupModule } from "./button-tab-group/button-tab-group.module";
import { CardCarouselComponent } from "./components/card-carousel/card-carousel.component";
import { CardDropdownComponent } from "./components/card-carousel/cards/card-dropdown/card-dropdown.component";
import { CarouselCardComponent } from "./components/card-carousel/cards/card.component";
import { GraphCardComponent } from "./components/card-carousel/cards/graph-card/graph-card.component";
import { ListCardComponent } from "./components/card-carousel/cards/list-card/list-card.component";
import { VisualBarCardComponent } from "./components/card-carousel/cards/visual-bar-card/visual-bar-card.component";
import { MonitorCardComponent } from "./components/card-carousel/cards/monitor-card/monitor-card.component";
import { ReadoutsCardComponent } from "./components/card-carousel/cards/readouts-card/readouts-card.component";
import { TenancyCardComponent } from "./components/card-carousel/cards/tenancy-card/tenancy-card.component";
import { CarouselControlsComponent } from "./components/card-carousel/carousel-controls/carousel-controls.component";
import { ClGridARouterLinkPipe } from "./components/cl-grid/cl-grid-a-router-link.pipe";
// import { ClGridComponent } from "./components/cl-grid/cl-grid.component";
import { GridItemsCountComponent } from "./components/cl-grid/grid-items-count/grid-items-count.component";
import { BannerIconComponent } from "./components/icons/banner-icon/banner-icon.component";
import { DigitStripComponent } from "./components/readout/digit-strip/digit-strip.component";
import { ReadoutComponent } from "./components/readout/readout/readout.component";
import { SearchListComponent } from "./components/search-list/search-list.component";
import { LoadMoreComponent } from "./load-more/load-more.component";
import { BeaconAnimComponent } from "./map/beacon-anim/beacon-anim.component";
import { HoverIconComponent } from "./map/hover-icon/hover-icon.component";
import { MapLinkComponent } from "./map/map-link/map-link.component";
import { MapMarkerComponent } from "./map/map-marker/map-marker.component";
import { UsersSearchComponent } from "./users-search/users-search.component";
import { AssetSummaryComponent } from "@cl/common/components/asset-summary/asset-summary.component";
import { AssetCapabilitiesComponent } from "@cl/asset/asset-details/asset-capabilities/asset-capabilities.component";
import { AlertIconComponent } from "@cl/common/components/alert-icon/alert-icon.component";
import { AssetLocationComponent } from "@cl/asset/components/asset-location/asset-location.component";
import { AssetCustodyComponent } from "@cl/asset/components/asset-custody/asset-custody.component";
import { LocationSummaryComponent } from "@cl/common/components/location-summary/location-summary.component";
import { CocPanelComponent } from "@cl/asset/components/coc-panel/coc-panel.component";
// import { UnescapePipe } from '../common/pipes/unescape.pipe';
import { TruncatePipe } from '../common/pipes/truncate.pipe';
import { StateActionMapPipe } from '../common/pipes/state-action-map.pipe';
import { StartCasePipe } from '../common/pipes/start-case.pipe';
import { MultiSelectUserSearchComponent } from './multi-select-user-search/multi-select-user-search.component';
import { RefreshCountdownComponent } from '@cl/common/components/refresh-countdown/refresh-countdown.component';
import { ShipmentSummaryComponent } from "@cl/common/components/shipment-summary/shipment-summary.component";
import { ShipmentSummaryDataComponent } from "@cl/shipments/components/shipment-summary-data/shipment-summary-data.component";
import { ShipmentRouterPlanComponent } from "@cl/shipments/components/shipment-router-plan/shipment-router-plan.component";
import { ShipmentTrackingProgressComponent } from "@cl/shipments/components/shipment-tracking-progress/shipment-tracking-progress.component";
import { ShipmentTrackerComponent } from "@cl/shipments/components/shipment-tracker/shipment-tracker.component";
import { HtmlTooltipComponent } from "@cl/common/components/html-tooltip/html-tooltip.component";
import { SearchPipe } from "@cl/shipments/pipes/search.pipe";
import { FilterPipe } from "@cl/shipments/pipes/filter.pipe";
import { DeviceSummaryComponent } from "@cl/common/components/device-summary/device-summary.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormateDatePipe } from "@cl/common/pipes/formate-date.pipe";

@NgModule({
  declarations: [
    UsersSearchComponent,
    LoadMoreComponent,
    BeaconAnimComponent,
    HoverIconComponent,
    // MapComponent,
    MapMarkerComponent,
    MapLinkComponent,
    CardCarouselComponent,
    CarouselControlsComponent,
    CarouselCardComponent,
    ReadoutComponent,
    DigitStripComponent,
    TenancyCardComponent,
    MonitorCardComponent,
    ReadoutsCardComponent,
    BannerIconComponent,
    CardDropdownComponent,
    // TenancyDropdownComponent,
    ListCardComponent,
    VisualBarCardComponent,
    GraphCardComponent,
    // LineChartComponent,
    SearchListComponent,
    MakeDraggableDirective,
    DragAndDropDirective,
    ClGridARouterLinkPipe,
    GridItemsCountComponent,
    AssetSummaryComponent,
    AssetCapabilitiesComponent,
    AlertIconComponent,
    AssetLocationComponent,
    AssetCustodyComponent,
    RefreshCountdownComponent,
    LocationSummaryComponent,
    ShipmentSummaryComponent,
    ShipmentSummaryDataComponent,
    ShipmentRouterPlanComponent,
    ShipmentTrackingProgressComponent,
    ShipmentTrackerComponent,
    CocPanelComponent,
    HtmlTooltipComponent,
    // UnescapePipe,
    TruncatePipe,
    StateActionMapPipe,
    StartCasePipe,
    SearchPipe,
    FilterPipe,
    MultiSelectUserSearchComponent,
    DeviceSummaryComponent,
    FormateDatePipe
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    // PipesModule,
    InlineSVGModule,
    RouterModule,
    GoogleChartsModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    DragDropModule,
    
    // CustomFiltersModule
  ],
  exports: [
    TranslateModule,
    HtmlTooltipComponent,
    ButtonTabGroupModule,
    UsersSearchComponent,
    LoadMoreComponent,
    BeaconAnimComponent,
    HoverIconComponent,
    // MapComponent,
    MapMarkerComponent,
    MapLinkComponent,
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    CardCarouselComponent,
    CarouselControlsComponent,
    CarouselCardComponent,
    ReadoutComponent,
    DigitStripComponent,
    TenancyCardComponent,
    MonitorCardComponent,
    ReadoutsCardComponent,
    BannerIconComponent,
    CardDropdownComponent,
    // TenancyDropdownComponent,
    ListCardComponent,
    VisualBarCardComponent,
    GraphCardComponent,
    // LineChartComponent,
    SearchListComponent,
    // TooltipContentComponent,
    // SideFiltersComponent,
    // InputAutocompleteComponent,
    // TimeEditorWidgetComponent,
    // RightListComponent,
    // SearchBoxComponent,
    // TagDisplayComponent,
    // ClGridComponent,
    // InfiniteListComponent,
    // UserSummaryComponent,
    // SimpleListComponent,
    // LocationWidgetComponent,
    // GeofenceWidgetComponent,
    // PasswordStrengthComponent,
    // UsersFilterComponent,
    // LocationsFilterComponent,
    // VersionInfoComponent,
    // GraphMapComponent,
    MakeDraggableDirective,
    DragAndDropDirective,
    // LocationDetailsComponent,
    // ShipmentListsComponent,
    // ShipmentDetailsComponent,
    // AlertDetailsComponent,
    // InventoryDetailsComponent,
    // InspectorLinkOutComponent,
    // CheckoutAndTransferComponent,
    // CustodyConditionsComponent,
    // SideNavComponent,
    // NavSectionComponent,
    // NavItemComponent,
    // EntityBrowserComponent,
    // SearchAdderComponent,
    // NodeInspectorComponent,
    // ConditionsPanelComponent,
    // SensorSearchListComponent,
    GridItemsCountComponent,
    AssetSummaryComponent,
    AssetCapabilitiesComponent,
    AlertIconComponent,
    AssetLocationComponent,
    AssetCustodyComponent,
    RefreshCountdownComponent,
    LocationSummaryComponent,
    ShipmentSummaryComponent,
    ShipmentRouterPlanComponent,
    ShipmentTrackingProgressComponent,
    ShipmentSummaryDataComponent,
    ShipmentTrackerComponent,
    CocPanelComponent,
    // UnescapePipe,
    TruncatePipe,
    StateActionMapPipe,
    StartCasePipe,
    SearchPipe,
    FilterPipe,
    DeviceSummaryComponent,
    FormateDatePipe
  ]
})

export class SharedModule {
}
