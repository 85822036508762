import { Component, EventEmitter, Input, OnChanges, Output, AfterViewInit, OnInit } from '@angular/core';
import { RuleUtilService } from '@cl/business-rules/rule-util.service';
import * as _ from 'lodash';
import {TriggerGroup, Rule, RuleType, RuleTypes, UnParsedTriggerTemplate, ParsedActionTemplate } from '@cl/@types/rule.type';
import { CategoryService } from '@cl/common/services/category.service';
import { Category } from '@cl/@types/category.type';
import { Site } from '@cl/@types/site.type';
import { UserGroup } from '@cl/@types/user-group.type';
import { WebHook } from '@cl/@types/webhook.type';
import { PropertyService } from '@cl/property/property.service';
import { Duration } from '@cl/common/components/duration/duration.component';
import { NgForm } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastService } from '@cl/common/services/toast.service';
import { UserService } from '@cl/user/user.service';
import { OrganizationService } from '@cl/common/services/organization.service';
import { ContentDialogComponent } from '@cl/common/components/content-dialog/content-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cl-add-rule',
  templateUrl: './add-rule.component.html',
  styleUrls: ['./add-rule.component.scss']
})
export class AddRuleComponent implements OnChanges, AfterViewInit, OnInit {
  loading: boolean = true;
  @Input('ruleObject') ruleObject: any;
  @Output('closeSidePanel') close: EventEmitter<boolean> = new EventEmitter<boolean>();
  rule: Rule = { title: null, ruleType: null, triggerGroup: null, trigger: null, organization: null, triggerParams: {}, action: null, actionParams: {}, actionFormList: [], ruleContext:'intransit'};
  ruleTypes: RuleTypes =  {
    asset:  { key: '', name: '', tlabel: '', tfLabel : '', data: [], groups: [] },
    shipment: { key: '', name: '', tlabel: '', tfLabel : '', data: [], groups: [] },
    device: { key: '', name: '', tlabel: '', tfLabel : '', data: [], groups: [] },
    location: { key: '', name: '', tlabel: '', tfLabel : '', data: [], groups: [] }
  };
  ruleTypeNames : string[];
  categories : {name: string, value: string}[] = [];
  areas: {name: string, value: string}[] = [];
  allAreas= [];
  sites: {name: string, value: string}[] = [];
  routes: any;
  userGroups: {name: string, value: string}[] = [];
  webHooksList: {name: string, value: string}[] = [];
  tagsList: {name: string, value: string}[] = [];
  subTagsList: {name: string, value: string}[] = [];
  tagSubTagsList: {name: string, value: string}[] = [];
  disableRuleType: boolean = true;
  isNew: boolean = true;
  listOfTriggerGroups: TriggerGroup [] = [];
  listOfTriggers : UnParsedTriggerTemplate[]= [];
  actionTemplates: ParsedActionTemplate[] = [];
  isSaveTriggered : boolean = false;
  formattedThreshold : any = {};
  thresholdMeasure : any = null;
  selectedThresholdMeasure : any = null;
  durationMeasuresToShow : string[] = ['D', 'h', 'm'];
  organization: any;
  orgList: any;
  orgCount: number;
  selectedOption: any = '';
  tenantList: any;
  orgId: any;
  userType: boolean;
  isOrgRestrictedUser: boolean;
  ruleContext: any;
  ruleContextNames: any;
  ruleContextType:boolean;
  isChecked:boolean;
  checkIntransit: string;
  isUpdate:boolean = false;
  constructor(private ruleUtilService : RuleUtilService,
    private categoryService : CategoryService,
    private propertyService: PropertyService,
    private translatePipe : TranslatePipe,
    private userService : UserService,
    private toastService: ToastService,
    private organizationService: OrganizationService,
    public dialog: MatDialog) {
  }
  ngOnInit(){
    this.tenantList = JSON.parse(sessionStorage.getItem('tenantList'));
    this.userType = this.userService.isDirectUser();
    this.getOrgList();
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
  }
  getOrgList(){
    this.organizationService.getOrgList().subscribe((data: any) => {
      this.orgList = data;
      this.orgCount = this.orgList.length;
      if(this.isNew && this.orgCount>0){
        this.selectedOption = this.orgList[0]?.id;
        this.organization = this.selectedOption;
      }  
    });
  }
  closeEditor(refresh?: boolean) {
    if(refresh){
      this.close.emit(refresh);
    }else{
      this.close.emit();
    }
  }
  fnRuleTypeChanged(){
    this.rule.triggerGroup = null;
    this.rule.trigger = null;
    this.rule.triggerParams = {};
    this.formattedThreshold = {};
    this.selectedThresholdMeasure = null;
    this.thresholdMeasure = null;
    this.rule.action = null;
    this.rule.actionParams = {};
    this.listOfTriggerGroups = _.sortBy(this.ruleTypes[this.rule.ruleType].groups, 'name');
    this.listOfTriggers = [];
    this.rule.actionFormList = [];
  };
  getSelectedTriggerGroup(): TriggerGroup{
    return _.find(this.listOfTriggerGroups, {key: this.rule.triggerGroup});
  }
  fnTriggerGroupChanged(){
    this.rule.trigger = null;
    this.rule.triggerParams = {};
    this.formattedThreshold = {};
    this.selectedThresholdMeasure = null;
    this.thresholdMeasure = null;
    this.rule.action = null;
    this.rule.actionParams = {};
    let selectedTriggerGroup: TriggerGroup =  this.getSelectedTriggerGroup();
    if(!_.isEmpty(selectedTriggerGroup)){
      this.listOfTriggers = _.sortBy(selectedTriggerGroup.triggers, 'metricName');
    }
  };
  fnTriggerChanged(callback?: Function){
  if(callback === undefined){
      this.isUpdate = true;
    }
    this.loading = true;
    this.rule.triggerParams = {};
    this.formattedThreshold = {};
    this.selectedThresholdMeasure = null;
    this.thresholdMeasure = null;
    this.rule.action = null;
    this.rule.actionParams = {};
    this.actionTemplates = [];
    this.rule.actionFormList = [];
    this.ruleContextNames = Object.keys(this.rule.trigger.inputForm);
    if(this.ruleContextNames.indexOf('all') === -1 && this.rule?.ruleType === 'asset'){
      this.ruleContextType = true;
    }else{
      if(this.ruleContextNames.indexOf('intransit') > -1){
        this.isChecked = true;
      }
      this.ruleContextType = false
    }
    this.rule.trigger.inputFormJSON = this.rule.trigger.inputFormStr ? JSON.parse(this.rule.trigger.inputFormStr) : [];
    this.ruleUtilService.getActionTemplates(this.rule.trigger.id).then((result: ParsedActionTemplate[]) => {
      result = result.map((item: ParsedActionTemplate)=>{
        item.inputFormJSON = JSON.parse(item.inputForm);
        return item;
      });
      this.actionTemplates = result;
      this._fnConfigureTriggerParams();
      this._fnConfigureActionParams();
      if(callback){
        callback();
      }
      this.loading = false;
    });
  };
  _fnConfigureTriggerParams(){
    var trigger = this.rule.trigger;
    trigger.inputFormJSON.forEach((triggerFormField) => {
      var tOptions = triggerFormField.templateOptions;
      if(triggerFormField.type === 'hidden'){
        this.rule.triggerParams[triggerFormField.key] = tOptions.value;
      }
      if(triggerFormField.type === 'select' || triggerFormField.type === 'multi-select'){
        if(_.includes(tOptions.optionsKey, '__SITES__')){
          tOptions.options = this.sites;
        }else if(_.includes(tOptions.optionsKey, '__CATEGORY__')){
          tOptions.options = this.categories;
        }else if(_.includes(tOptions.optionsKey, '__AREAS__')){
          if(tOptions.optionsKey === '__AREAS__(route)'){
            // tOptions.options = _.uniqBy(_.flatten(_.map(this.routes,'areas')), 'value');
            tOptions.options = this.allAreas
          } else {
            // tOptions.options = _.uniqBy(_.flatten(_.map(this.sites,'areas')), 'value');
            tOptions.options = this.allAreas
          }
        }else if(_.includes(tOptions.optionsKey, '__ASSET_STATES__')){
          tOptions.options = this._fnGetCategoryStates();
        } else if(_.includes(tOptions.optionsKey, '__TAG__')){
          tOptions.options = this.tagSubTagsList;
        } else if(_.includes(tOptions.optionsKey, '__ROUTES__')){
          tOptions.options = this.routes;
        }
        if(tOptions.hasOwnProperty('additionalOptions')){
          tOptions.options = tOptions.options.concat(tOptions.additionalOptions);
        }
        tOptions.options = _.sortBy(tOptions.options, 'name');
      }
    });
  };

  _fnConfigureRuleContext(event){
    this.loading = true;
    var trigger = this.rule.trigger.inputForm; 
    var ruleContextObject:any;
    if(trigger.hasOwnProperty(event)){
      let objVal = Object.entries(trigger);
      objVal.map((data)=>{
        if(data[0] == event){
          ruleContextObject = data[1];
        }
      })
    }
    if(!_.isEmpty(ruleContextObject)){
      this.rule.trigger.inputFormJSON = JSON.parse(ruleContextObject);
      this._fnConfigureTriggerParams();
      this.loading = false;
    }
  };

  _fnConfigureActionParams(){
    var actions = this.actionTemplates;
    var trigger = this.rule.trigger;
    actions.forEach((action) => {
      action.inputFormJSON.forEach((actionFormField) => {
        if(_.includes(['input', 'textarea', 'hidden'], actionFormField.type)){
          if(!_.includes(actionFormField.defaultValue, 'TR_HINT')){
            actionFormField.value = actionFormField.defaultValue;
          }else {
            var key = ((actionFormField.defaultValue.split('__TR_HINT(')[1]).split(')__'))[0];
            actionFormField.value = trigger[key];
          }
        }
        if(actionFormField.type === 'multi-select'){
          if(_.includes(actionFormField.templateOptions.optionsKey, '__GROUPS__')){
            actionFormField.templateOptions.options = _.isEmpty(this.userGroups) ? this.userGroups : _.sortBy(this.userGroups, 'name');
          }
          if(_.includes(actionFormField.templateOptions.optionsKey, '__APISUBSCRIBERS__')){
            actionFormField.templateOptions.options = _.isEmpty(this.webHooksList) ? this.webHooksList : _.sortBy(this.webHooksList, 'name');
          }
        }
        if(actionFormField.type === 'select'){
          if(_.includes(actionFormField.templateOptions.optionsKey, '__ASSET_STATES__')){
            var states = this._fnGetCategoryStates();
            actionFormField.templateOptions.options = _.isEmpty(states) ? states : _.sortBy(states, 'name');
          }
        }
      });
    });
  };
  _fnGetCategoryStates(){
    /* var fields = CustomizationDataservice.getAllFields();
    var actualData = [], counter = 0;
    for(var key in fields){
      if(fields.hasOwnProperty(key)){
        var categoryObject = _.find(this.categories, {value: key});
        if (!_.isEmpty(fields[key]) && !_.isEmpty(categoryObject)) {
          fields[key].forEach((item)=>{
            if(item.name === 'state'){
              var temp =item.type.enum.enums;
              temp.forEach((item)=>{
                item.group = categoryObject.name;
                item.value = item.label;
                item.index = counter;
                actualData.push({
                  categoryId: key,
                  value: item.label,
                  name: item.name
                })
              });
            }
          });
        }
      }
    }
    return _.uniqBy(actualData, 'name'); */
    return [];
  };
  fnActionTypeChanged(){
    if(_.isEmpty(this.rule.action)){
      this.rule.actionParams = {};
    }
    this._fnGetSelectedOrg()
    this.rule.actionFormList = _.map(this.rule.action,'inputFormJSON');
    this.rule.actionFormList = _.uniqBy(_.flatten(this.rule.actionFormList), 'key');
    for(var actionParamKey in this.rule.actionParams){
      var  actionFormValue = this.rule.actionFormList.some(user => user.key === actionParamKey);
      if(!actionFormValue){
        delete this.rule.actionParams[actionParamKey];
      }
    }
    this.rule.actionFormList.forEach((actionParam)=>{
      if(!_.isEmpty(actionParam.value)){
        if(actionParam.type === 'hidden' || actionParam.type === 'input' || actionParam.type === 'textarea'){
          this.rule.actionParams[actionParam.key] = actionParam.value;
        }
      }
    });
  };

  fnTriggerParamsSelectChange(key: string, ignoreAndClean?:boolean){
    if(key !== 'area'){
      this.fnUpdateAreas(key, ignoreAndClean)
    }
  }

  fnUpdateAreas(key: string, ignoreAndClean?:boolean){
    var temp = _.find(this.rule.trigger.inputFormJSON,(item) => {
      if((item.type === 'select' || item.type === 'multi-select') && !_.isEmpty(item.templateOptions.optionsKey) && (key.toLowerCase() !== 'area' ? item.templateOptions.optionsKey.indexOf(key)>-1 : item.templateOptions.optionsKey.toLowerCase().indexOf(key)>-1) ){
       return true;
      }else{
       return false;
      }
    });
    if(!_.isEmpty(temp) && !_.isEmpty(temp.templateOptions.optionsKey)&& temp.templateOptions.optionsKey.indexOf('__AREAS__')>-1){
      if(ignoreAndClean){
        this.rule.triggerParams[temp.key] = null;
      }

      let tempAreas = this.allAreas;
      if(key === 'area' && !this.rule.triggerParams[key] && this.ruleObject){
        this.rule.triggerParams[key] = _.find(tempAreas, (option)=>{
          return option.value.toString() === this.ruleObject.triggerParams[key].toString();
        });
        temp.templateOptions.options = this.rule.triggerParams[key];
      }else{
        tempAreas = this.rule.triggerParams[key]?.areas ? this.rule.triggerParams[key].areas : this.allAreas;
        temp.templateOptions.options = _.isEmpty(tempAreas) ? tempAreas : _.sortBy(tempAreas, 'name');
      }
      var tOptions = temp.templateOptions;
      if(tOptions.hasOwnProperty('additionalOptions')){
        tOptions.options = tOptions.options.concat(tOptions.additionalOptions);
        tOptions.options = _.sortBy(tOptions.options, 'name');
      }
    }
    if(!_.isEmpty(temp) && !_.isEmpty(temp.templateOptions.optionsKey)&& temp.templateOptions.optionsKey.indexOf('__TAG__')>-1){
      if(ignoreAndClean){
        this.rule.triggerParams[temp.key] = null;
      }
     /* if(this.rule.triggerParams[key].name === 'Carrier Milestone'){
        temp.templateOptions.options = this.tagsList;
      } else if(this.rule.triggerParams[key].name === 'Carrier Sub-Milestone'){
        temp.templateOptions.options = this.subTagsList;
      } */
      
      if(this.rule.triggerParams['eventSelect'].value === 'tag'){
          temp.templateOptions.options =  _.sortBy(this.tagsList, 'name');
          this.tagSubTagsList = this.tagsList;
        } else if(this.rule.triggerParams['eventSelect'].value === 'subtag'){
          temp.templateOptions.options = _.sortBy(this.subTagsList, 'name');;
          this.tagSubTagsList = this.subTagsList;
        }

        if(this.rule.triggerParams['event'] && this.tagSubTagsList?.length){
          const selectedValues = this.rule.triggerParams['event'].map(item => item.value);
          this.rule.triggerParams['event'] = this.tagSubTagsList.filter(item => selectedValues.includes(item.value))
        }
    }
  };
  fnIsTriggerGroupDisabled(){
    return _.isEmpty(this.rule.ruleType);
  };
  fnGetThresholdUI(){
    this.formattedThreshold = {};
    this.selectedThresholdMeasure = null;
    this.thresholdMeasure = null;
    var sid = this.rule.triggerParams.metric.value;
    this.thresholdMeasure = this.rule.trigger.inputFormJSON[1][sid];
    if(!_.isEmpty(this.thresholdMeasure[0].Levels)){
      var temp = this.propertyService.getSetting(this.thresholdMeasure[1].property_name);
      if(!_.isEmpty(temp)){
        if(!_.isEmpty(this.ruleObject)){
          temp =  this.ruleObject.triggerParams.unit || temp;
        }
        this.thresholdMeasure[1].default = temp
      }
    } else if (!_.isEmpty(this.thresholdMeasure[0].status)){
      var hiddenFields = _.filter(this.thresholdMeasure, {type: 'hidden'});
      if(hiddenFields.length>0){
        hiddenFields.forEach((hiddenField) => {
          this.rule.triggerParams[hiddenField.key] = hiddenField.templateOptions.value;
        });
      }
    }
  };
  fnUpdateThreshold(formattedThreshold: any) {
    this.formattedThreshold = formattedThreshold;
    this.rule.triggerParams = this._fnGetConditionData(this.rule.triggerParams, this.formattedThreshold);
  };
  fnUpdateDelayValue(dataObject: any, key: string, value: Duration){
    dataObject[key] = value.duration;
  };
  _fnUpdateJSONKeyValue(listItem, triggerParams) {
    listItem.templateOptions.updatedValue = {};
    for (let key in listItem.templateOptions.value) {
      if (listItem.templateOptions.value.hasOwnProperty(key)) {
        var tempKey = listItem.templateOptions.value[key].split('__')[1];
        if (triggerParams[tempKey] != null && triggerParams[tempKey] != undefined && triggerParams[tempKey] != '') {
          try {
            listItem.templateOptions.updatedValue[key] = JSON.parse(triggerParams[tempKey]);
          } catch (err) {
            listItem.templateOptions.updatedValue[key] = triggerParams[tempKey];
          }
        }
      }
    }
    if (_.isEmpty(listItem.templateOptions.updatedValue)) {
      triggerParams[listItem.key] = null;
    } else {
      triggerParams[listItem.key] = listItem.templateOptions.updatedValue;
    }
    if (!_.isEmpty(triggerParams[listItem.key]) && _.isPlainObject(triggerParams[listItem.key])) {
      triggerParams[listItem.key] = JSON.stringify(triggerParams[listItem.key]);
    }
  }
  _fnUpdateTextKeyValue(listItem, triggerParams){
    listItem.templateOptions.updatedValue = {};
    let tempKey = listItem.templateOptions.value.split('__')[1];
    if (triggerParams[tempKey] != null && triggerParams[tempKey] != undefined && triggerParams[tempKey] != '') {
      try {
        listItem.templateOptions.updatedValue = JSON.parse(triggerParams[tempKey]);
      } catch (err) {
        listItem.templateOptions.updatedValue = triggerParams[tempKey];
      }
    }
    if (_.isEmpty(listItem.templateOptions.updatedValue)) {
      triggerParams[listItem.key] = null;
    } else {
      triggerParams[listItem.key] = listItem.templateOptions.updatedValue;
    }
    if (!_.isEmpty(triggerParams[listItem.key])) {
      try{
        triggerParams[listItem.key] = JSON.stringify(triggerParams[listItem.key]);
      }catch(err){
        triggerParams[listItem.key] = triggerParams[listItem.key];
      }
    }
  }
  _fnUpdateJsonTypeHiddenTriggerParams(trigger, triggerParams){
    let list = _.filter(trigger.inputFormJSON, {type: 'jsonTypeHidden'})
    if(!_.isEmpty(list)){
      list.forEach((listItem)=>{
        if(_.isEmpty(listItem.dependentKey)){
          this._fnUpdateJSONKeyValue(listItem, triggerParams);
        }else{
          if(triggerParams[listItem.dependentKey]){
            if(typeof triggerParams[listItem.dependentKey] === 'string'){
              if(String(true) === triggerParams[listItem.dependentKey]){
                this._fnUpdateJSONKeyValue(listItem, triggerParams);
              }
            }else{
              this._fnUpdateJSONKeyValue(listItem, triggerParams);
            }
          }
        }
      });
    }
    return triggerParams;
  };
  _fnUpdateTextTypeHiddenTriggerParams(trigger, triggerParams){
    let list = _.filter(trigger.inputFormJSON, {type: 'textTypeHidden'})
    if(!_.isEmpty(list)){
      list.forEach((listItem) => {
        if(_.isEmpty(listItem.dependentKey)){
          this._fnUpdateTextKeyValue(listItem, triggerParams);
        }else{
          if(triggerParams[listItem.dependentKey]){
            this._fnUpdateTextKeyValue(listItem, triggerParams);
          }else{
            triggerParams[listItem.key] = null;
          }
        }
      });
    }
    return triggerParams;
  };
  _fnGetConditionData(destination, source){
    for(let key in source){
      if(source.hasOwnProperty(key)){
        destination[key] = source[key];
      }
    }
    return destination;
  }

  async _fnGetSelectedOrg(){
    // this.rule.organization = this.organization ? this.organization : '';
    this.orgId =  this.rule.organization;
    if(!this.rule.actionFormList) return;
    const orgGroups = await this.ruleUtilService.getUserGroups(this.orgId)

    const notifyGroups = this.rule.actionFormList.filter(action => action.key.toLowerCase().includes('notifyinggroup'));
    notifyGroups.forEach(group => {
      group.templateOptions.options = orgGroups.map(org => {
        return {
          name:org.userGroupName,
          value:org.userGroupName
        }
      })
    })
  }

  _fnGetRulePayload(){
    let rule = _.cloneDeep(this.rule);
    let temp = this.ruleObject;
    for(let key in rule.triggerParams){
      if(rule.triggerParams.hasOwnProperty(key)){
        if(_.isPlainObject(rule.triggerParams[key])){
          rule.triggerParams[key] = rule.triggerParams[key].value;
        }else if(_.isArray(rule.triggerParams[key])){
          if(_.isPlainObject(rule.triggerParams[key][0])){
            rule.triggerParams[key] = _.map(rule.triggerParams[key], 'value');
          }
          rule.triggerParams[key] = JSON.stringify(rule.triggerParams[key]);
        }else {
          rule.triggerParams[key] = rule.triggerParams[key];
        }
      }
    }
    for(let key in rule.actionParams){
      if(rule.actionParams.hasOwnProperty(key)){
        if(_.isPlainObject(rule.actionParams[key])){
          rule.actionParams[key] = rule.actionParams[key].value ? rule.actionParams[key].value : JSON.stringify(rule.actionParams[key]);
        }else if(_.isArray(rule.actionParams[key])){
          if(_.isPlainObject(rule.actionParams[key][0])){
            rule.actionParams[key] = _.map(rule.actionParams[key], 'value');
          }
          rule.actionParams[key] = JSON.stringify(rule.actionParams[key]);
        }else {
          rule.actionParams[key] = rule.actionParams[key].toString();
        }
      }
    }
    rule.triggerParams = this._fnGetConditionData(rule.triggerParams, this.formattedThreshold);
    rule.triggerParams = this._fnUpdateJsonTypeHiddenTriggerParams(rule.trigger, rule.triggerParams);
    rule.triggerParams = this._fnUpdateTextTypeHiddenTriggerParams(rule.trigger, rule.triggerParams);
    var payload ={
      actionParams: rule.actionParams,
      actionTemplateIds: rule.action.map((action)=>{return action.id}),
      active: this.isNew ? true : temp.active,
      application : this.ruleContext || 'intransit', //rule.trigger.application,
      triggerApplication : rule.trigger.application,
      createdAt: this.isNew ? null : temp.createdAt,
      id: this.isNew ? null : temp.id,
      modifiedAt: this.isNew ? null : temp.modifiedAt,
      ruleIds: this.isNew ? []: temp.ruleIds,
      ruleSetName: this.isNew ? [] : temp.rulesetName,
      scope: rule.trigger.scope,
      tenantId: this.isNew ? this.userService.getEffectiveTenantId() : temp.tenantId,
      title: rule.title,
      triggerGroup: rule.triggerGroup,
      triggerParams: rule.triggerParams,
      triggerTemplateId: rule.trigger.id,
      userId: this.isNew ? null: temp.userId,
      organization: rule.organization || ''
    };
    return payload;
  };
  saveOrUpdateRule(form: NgForm){
    this.isSaveTriggered = true;
    this.loading = true;
    let status = form.valid;
    if(status){
      let ruleObject = this._fnGetRulePayload();
      let promise = null;
      if(this.isNew){
        delete ruleObject['id'];
        promise = this.ruleUtilService.saveRule(ruleObject)
      }else{
        promise = this.ruleUtilService.updateRule(ruleObject);
      }
      promise.then(()=>{
        if(this.isNew){
          this.toastService.success(this.translatePipe.transform('rules.SUCCESS_RULE_CREATE'));
        }else{
          this.toastService.success(this.translatePipe.transform('rules.SUCCESS_RULE_UPDATE'));
        }
        this.loading = false;
        this.closeEditor(true);
      }).catch(()=>{
        this.loading = false;
      });
    }else{
      this.loading = false;
    }
  };

  private getAllTriggerTemplates() : UnParsedTriggerTemplate []{
    let data: RuleType [] = Object.values(this.ruleTypes);
    let groups: TriggerGroup[] = _.flatten(data.map((item: RuleType) => item.groups));
    let triggerTemplates : UnParsedTriggerTemplate [] = _.flatten(groups.map((item: TriggerGroup)=>  item.triggers));
    return triggerTemplates;
  }
  private getRuleObject(tempRuleObject: any){
    if(!tempRuleObject) return;
    this.rule.title = tempRuleObject.title;
    this.ruleContext = tempRuleObject.application;
    let triggerObject: UnParsedTriggerTemplate = _.find(this.getAllTriggerTemplates(), {metric: tempRuleObject.triggerParams.metric});
    this.rule.ruleType = tempRuleObject.scope;
    this.fnRuleTypeChanged();
    this.rule.triggerGroup = tempRuleObject.triggerGroup;
    this.fnTriggerGroupChanged();
    this.rule.trigger = _.find(this.listOfTriggers, {metric: triggerObject.metric});
    this.rule.organization = tempRuleObject.organization;
    this.fnTriggerChanged(()=>{
      this.rule.action = _.filter(this.actionTemplates, (action)=>{
          return _.includes(tempRuleObject.actionTemplateIds, action.id);
      });
      this.fnActionTypeChanged();
      var storedTriggerParams = _.cloneDeep(tempRuleObject.triggerParams);
      for(var key in tempRuleObject.triggerParams){
        if(tempRuleObject.triggerParams.hasOwnProperty(key)){
          try{
            var triggerFormField = _.find(this.rule.trigger.inputFormJSON, {key: key});
            this._fnConfigureRuleContext(this.ruleContext);
            if(!_.isEmpty(triggerFormField)
              && triggerFormField.type === 'select'
              && !_.isEmpty(triggerFormField.templateOptions.options)){
              var optionsKey = triggerFormField.templateOptions.optionsKey;
              if(_.isEmpty(optionsKey) || (!_.isEmpty(optionsKey) && optionsKey.indexOf('__(') === -1)){
                this.rule.triggerParams[key] = _.find(triggerFormField.templateOptions.options, (option)=>{
                  return option.value.toString() === tempRuleObject.triggerParams[key].toString();
                });
              }
              this.fnUpdateAreas(key);
              if(!_.isEmpty(optionsKey) && (optionsKey === '__AREAS__(route)')){
                this.fnUpdateAreas(key);
              }
            }else if(!_.isEmpty(triggerFormField)
              && triggerFormField.type === 'multi-select'
              && !_.isEmpty(triggerFormField.templateOptions.options)){
                tempRuleObject.triggerParams[key] = typeof tempRuleObject.triggerParams[key] === 'string' ? JSON.parse(tempRuleObject.triggerParams[key]) : tempRuleObject.triggerParams[key];
              this.rule.triggerParams[key] = _.filter(triggerFormField.templateOptions.options, (option)=>{
                return _.includes(tempRuleObject.triggerParams[key], option.value)
              });
              if(_.includes(Object.keys(tempRuleObject.triggerParams), 'shipmentState')){
                this.rule.triggerParams[key] = tempRuleObject.triggerParams[key];
                this.rule.triggerParams[triggerFormField.key] = tempRuleObject.triggerParams[key];
              }
              this.fnUpdateAreas(key);
            }else{
              tempRuleObject.triggerParams[key] = JSON.parse(JSON.stringify(tempRuleObject.triggerParams[key]));
              this.rule.triggerParams[key] = tempRuleObject.triggerParams[key];
            }
          }catch(e){
            this.rule.triggerParams[key] = tempRuleObject.triggerParams[key];
          }
        }
      }
      for(var key in tempRuleObject.triggerParams){
        if(tempRuleObject.triggerParams.hasOwnProperty(key)){
          var triggerFormField = _.find(this.rule.trigger.inputFormJSON, {key: key});
          if(!_.isEmpty(triggerFormField)
            && triggerFormField.type === 'select'
            && !_.isEmpty(triggerFormField.templateOptions.options)){
            var optionsKey = triggerFormField.templateOptions.optionsKey;
            if(!_.isEmpty(optionsKey) && optionsKey.indexOf('__(') > -1){
              var parentKey = optionsKey.split('__(')[1].split(')')[0];
              if(!_.isEmpty(this.rule.triggerParams[parentKey]) && !_.isEmpty(this.rule.triggerParams[parentKey].areas)){
                this.rule.triggerParams[key] = _.find(this.rule.triggerParams[parentKey].areas, (option)=>{
                  return option.value.toString() === storedTriggerParams[key].toString();
                });
              }
            }
          }

          if(!_.isEmpty(triggerFormField)
            && triggerFormField.type === 'multi-select'
            && !_.isEmpty(triggerFormField.templateOptions.options)){
            var optionsKey = triggerFormField.templateOptions.optionsKey;
            if(!_.isEmpty(optionsKey) && optionsKey.indexOf('__(') > -1){
              var parentKey = optionsKey.split('__(')[1].split(')')[0];
              if(!_.isEmpty(this.rule.triggerParams[parentKey]) && !_.isEmpty(this.rule.triggerParams[parentKey].areas)){
                this.rule.triggerParams[key] = _.filter(this.rule.triggerParams[parentKey].areas, (option)=>{
                  return storedTriggerParams[key].includes(option.value.toString());
                });
              }
            }
          }
        }
      }
      var formField = _.find(this.rule.trigger.inputFormJSON, {key: 'metric'});
      if(!_.isEmpty(formField) && formField.type === 'select-and-customize'){
        this.rule.triggerParams.metric = _.find(formField.templateOptions.options, {value: this.rule.triggerParams.metric});
        this.fnGetThresholdUI();
        this.selectedThresholdMeasure = _.clone(tempRuleObject.triggerParams);
        delete this.selectedThresholdMeasure['metric'];
      }
      formField = _.find(this.rule.trigger.inputFormJSON, {key: 'thresholdMetric'});
      if(!_.isEmpty(formField) && formField.type=='threshold'){
        this.selectedThresholdMeasure = _.clone(tempRuleObject.triggerParams);
      }
      for(var key in tempRuleObject.actionParams){
        if(tempRuleObject.actionParams.hasOwnProperty(key)){
          try{
            var actionFormField = _.find(this.rule.actionFormList, {key: key});
            if(!_.isEmpty(actionFormField)
              && actionFormField.type === 'select'
              && !_.isEmpty(actionFormField.templateOptions.options)){
              this.rule.actionParams[key] = _.find(actionFormField.templateOptions.options, (option)=>{
                return option.value.toString() == tempRuleObject.actionParams[key].toString();
              });
            }else if(!_.isEmpty(actionFormField)
              && actionFormField.type === 'multi-select'
              && !_.isEmpty(actionFormField.templateOptions.options)){
                tempRuleObject.actionParams[key] = typeof tempRuleObject.actionParams[key] === 'string' ? JSON.parse(tempRuleObject.actionParams[key]) : tempRuleObject.actionParams[key];
              this.rule.actionParams[key] = _.filter(actionFormField.templateOptions.options, (option)=>{
                return _.includes(tempRuleObject.actionParams[key], option.value)
              });
            }else{
              tempRuleObject.actionParams[key] = typeof tempRuleObject.actionParams[key] === 'string' ? JSON.parse(tempRuleObject.actionParams[key]) : tempRuleObject.actionParams[key];
              this.rule.actionParams[key] = tempRuleObject.actionParams[key];
            }
          }catch(e){
            this.rule.actionParams[key] = tempRuleObject.actionParams[key];
          }
        }
      }
    });
  };
  getInitialRule() {
    this.isNew = _.isEmpty(this.ruleObject) || (!_.isEmpty(this.ruleObject) && _.isEmpty(this.ruleObject.id)) ? true : false;
    if(this.isNew){
      if(_.isEmpty(this.ruleObject)){
        this.loading = false;
      }else {
        this.getRuleObject(this.ruleObject);
      }
    }else{
      this.getRuleObject(this.ruleObject);
    }
    this.loading = false;
  }
  private setRuleTypes(response: RuleTypes) {
    if(!_.isEmpty(response)){
      this.ruleTypes = response;
    }
    this.ruleTypeNames = Object.keys(this.ruleTypes);
  }
  private setCategories(response: Category[]){
    if(!_.isEmpty(response)){
      this.categories = response.map((data) => {
        return {
          name : data.name,
          value : data.id
        };
      });
    }
  }
  setSites(sites: any[]) {
    if(!_.isEmpty(sites)){
      this.sites = sites.map((item) => {
        var temp = item.areas.map((data) => {
          return {
            name: data.name,
            value: data.identifier
          };
        });
        return {
          name: item.name,
          value: item.identifier,
          areas : temp
        };
      });
      /*this.sites = sites.map( (item) => {
        return {
          name: item.name,
          value: item.id
        }
      })*/
    }
  }
  setRoutes(routes: any[]) {
    if(!_.isEmpty(routes)){
      this.routes = routes.map((item) => {
        let locations = item.locationDetails ? JSON.parse(item.locationDetails) : [];
        let temp = locations.map((data) => {
          let name = data.areaName ? data.areaName : (data.areaItem ? data.areaItem.fullAddress : '');
          return {
            name: name,
            value: data.areaId
          };
        });
        return {
          name: item.name,
          value: item.id,
          areas: temp
        }
      })
    }
  }
  setAreas(areas: any[]) {
    if(!_.isEmpty(areas)){
      this.allAreas = areas.map((item) => {
        return {
          name: item.name,
          value: item.id
        }
      })
    }
  }
  setUserGroups(response: UserGroup[]) {
    if(!_.isEmpty(response)){
      this.userGroups = response.map((userGroupData : UserGroup) => {
        return {
          name : userGroupData.userGroupName,
          value : userGroupData.userGroupName
        };
      });
    }
  }
  setWebHooks(response: WebHook[]) {
    if(!_.isEmpty(response)){
      this.webHooksList = response.map((data: WebHook) => {
        return {
          name : data.subscriptionName,
          value : data.id
        };
      });
    }
  }
  setTagSubTags(response: any[]) {
    let tagResults = response || [];
    if(!_.isEmpty(tagResults)){
      var tempTagList = _.uniq(_.map(tagResults,'tag'));
          var tempSubTagList = _.uniq(_.map(tagResults,'sub_tag'));
          this.tagsList = tempTagList.map(function(item){
            return {
              name: item,
              value: item
            };
          });
          this.subTagsList = tempSubTagList.map(function(item){
            return {
              name: item,
              value: item
            };
          });
          this.tagSubTagsList = _.uniqBy(this.tagsList.concat(this.subTagsList),'value');
    }
  }
  private setMorphedData(responses: [RuleTypes, Category[], any[], UserGroup[], WebHook[], any[], any[], any[]]) {
    this.setRuleTypes(responses[0]);
    this.setCategories(responses[1]);
    this.setSites(responses[2]);
    this.setUserGroups(responses[3]);
    this.setWebHooks(responses[4]);
    this.setTagSubTags(responses[5]);
    this.setRoutes(responses[6]);
    this.setAreas(responses[7]);
  }
  getData(){
    this.categories = [];
    this.areas = [];
    this.sites = [];
    this.userGroups = [];
    this.webHooksList = [];
    this.tagsList = [];
    this.subTagsList = [];
    this.tagSubTagsList = [];
    Promise.all([
      this.ruleUtilService.getTriggerTemplates(),
      this.categoryService.getCategories(),
      this.ruleUtilService.getSites(),
      this.ruleUtilService.getUserGroups(this.organization),
      this.ruleUtilService.getWebHooks(),
      this.ruleUtilService.getTagSubtags(),
      this.ruleUtilService.getRoutes(),
      this.ruleUtilService.getAreas()
    ])
    .then((responses : [RuleTypes, Category[], Site[], UserGroup[], WebHook[], any[], any[], any[]]) => {
      this.setMorphedData(responses);
      this.disableRuleType = false;
      // this.getInitialRule();
      this.isNew = _.isEmpty(this.ruleObject) || (!_.isEmpty(this.ruleObject) && _.isEmpty(this.ruleObject.id)) ? true : false;
      this.loading = false;
    }).catch(()=>{
      this.ruleTypeNames = Object.keys(this.ruleTypes);
      this.disableRuleType = true;
      this.loading = false;
    });
  }
  loadRule(): void{
    if(this.loading){
      window.setTimeout(()=>{
        this.loadRule();
      }, 0);
    } else {
      // this.getInitialRule();
      this.getRuleObject(this.ruleObject)
    }
  }
  ngAfterViewInit(): void {
    if(!_.isEmpty(this.ruleObject)){
      this.isNew = false;
    }
    this.getData();
  }
  ngOnChanges(changes: any): void {
    if(!_.isEmpty(this.ruleObject)){
      this.organization = this.ruleObject.organization;
    }
    if(changes.hasOwnProperty('ruleObject') && !_.isEmpty(changes.ruleObject.currentValue)){
      this.disableRuleType = false;
      this.loadRule();
    }
  }
  compareActionParams(action1, action2){
    return action1 && action2 ? action1?.value === action2?.value : action1 === action2
  }

  onOrgclose(){
    this.selectedOption = '';
    this.rule.organization = '';
  }
}