<span class="dropdown cl-duration" [ngClass]="{'no-pointer': disabled}">
  <input [name]="durationName" type="hidden" [(ngModel)]="is" [required]="required"/>
  <button class="color4" type="button" [disabled]="disabled" (click)="open=true;">
    <span *ngIf="!is">
      <i class="fa fa-clock-o"></i>&nbsp;
      <span>Set<span [ngClass]="{'pl-1 pr-1': placeholder, 'pl-1': !placeholder}" [textContent]="placeholder"></span>duration</span>
    </span>
    <span *ngIf="is">
      <i class="fa fa-clock-o"></i>&nbsp;
      <span><span [ngClass]="{'pr-1': placeholder}" [textContent]="placeholder"></span><span [textContent]="selectedDuration"></span></span>
    </span>
  </button>
  <div class="dropdown-menu" *ngIf="show" [ngClass]="{'show': open}" [ngStyle]="{ 'width' : (((show.length * 60)+20) + 'px' )}"
  style="min-width: auto !important;top: 20px;" (mouseleave)="open=false">
    <div class="d-flex">
      <div class="inline-block text-center" *ngIf="displayElements.Y">
        <label [textContent]="'YY'"></label>
        <input type="number" min="1" [(ngModel)]="duration.prefix2" class="m-0 pl-1 p-0 w-[60px] basic-input font-12-normal" placeholder="0"/>
      </div>
      <div class="inline-block text-center" *ngIf="displayElements.M">
        <label [textContent]="'MM'"></label>
        <input type="number" min="1" [(ngModel)]="duration.prefix4" class="m-0 pl-1 p-0 w-[60px] basic-input font-12-normal" placeholder="0"/>
      </div>
      <div class="inline-block text-center" *ngIf="displayElements.D">
        <label [textContent]="'Days'"></label>
        <input type="number" min="1" max="31" [(ngModel)]="duration.prefix6" class="m-0 pl-1 p-0 w-[60px] basic-input font-12-normal" placeholder="0"/>
      </div>
      <div class="inline-block text-center" *ngIf="displayElements.h">
        <label [textContent]="'Hrs'"></label>
        <input type="number" min="1" max="23" [(ngModel)]="duration.prefix9" class="m-0 pl-1 p-0 w-[60px] basic-input font-12-normal" placeholder="0"/>
      </div>
      <div class="inline-block text-center" *ngIf="displayElements.m">
        <label [textContent]="'Mins'"></label>
        <input type="number" min="1" max="59" [(ngModel)]="duration.prefix11" class="m-0 pl-1 p-0 w-[60px] basic-input font-12-normal" placeholder="0"/>
      </div>
      <div class="inline-block text-center" *ngIf="displayElements.s">
        <label [textContent]="'Sec'"></label>
        <input type="number" min="1" max="59" [(ngModel)]="duration.prefix13" class="m-0 pl-1 p-0 w-[60px] basic-input font-12-normal" placeholder="0"/>
      </div>
    </div>
    <div class="text-center color-33">
      <a href="javascript:void(0)" (click)="fnSetDuration()" [textContent]="'SET'"></a>
    </div>
  </div>
</span>
