import { Component, Input, OnInit } from '@angular/core';
import { Sensor, SensorBasicInformationProperties } from '@cl/@types/sensor.type';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import * as _ from 'lodash';
import { CdmField } from '@cl/@types/asset.type';

@Component({
  selector: 'cl-sensor-summary',
  templateUrl: './sensor-summary.component.html',
  styleUrls: ['./sensor-summary.component.scss']
})
export class SensorSummaryComponent implements OnInit {
  @Input('sensor') sensorObject: any;

  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  constructor(public datePipe : FormateDatePipe) { }
  ngOnInit(): void {
  }

  onImgError(event: any) {
    event.target.src = `https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/tag_default.jpg`;
  }

  getExtendedAttributeValue(cdmField: CdmField, properties: SensorBasicInformationProperties){
    if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
      if(cdmField?.type?.toUpperCase()==='DATE'){
        let data = properties[cdmField.name];
        return this.datePipe.transform(data, 'default');
      } else {
        return properties[cdmField.name];
      }
    }else{
      return '';
    }
  }
}
