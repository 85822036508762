<div class="mt-2 mb-2">
  <span class="page-title">Locations</span>
</div>
<cl-table-surtitle
  [rows]="showingHits"
  [selected]="selected?.length"
  [total]="totalHits"
  [pageName]="'Locations'"
>
</cl-table-surtitle>
<div
  class="sensor-febric wt-ngx-grid-wrapper h-calc-40 list-page-wrapper relative list-page px-2"
>
  <div
    *ngIf="!hideAdd"
    class="md-add-button list add-shipment-btn"
    style="z-index: 999"
    [clIsValidAction]="actionBtnPolicies.create"
  >
    <div
      class="add-button"
      (click)="addLocation()"
      *ngIf="!showSidePanel || mode == 'view'"
    >
      <span class="fa fa-plus"></span>
    </div>
    <div
      class="add-button"
      (click)="closeSidePanel()"
      *ngIf="showSidePanel == true && mode !== 'view'"
    >
      <span class="fa fa-close"></span>
    </div>
  </div>
  <div class="flex justify-between pt-1 pb-1">
    <div class="action-panel-left self-start flex items-center">
      <span
        (click)="toggleFilterPanel()"
        class="cursor-pointer color4 list-page_filter-toggle flex items-center"
      >
        <i
          class="fa fa-sliders mr-2 ml-3 font-18-normal"
          aria-hidden="true"
        ></i>
        filter options
      </span>
      <cl-list-search
        [placeholder]="'Search by Name'"
        (search)="updateFilter($event)"
        [columns]="['Name']"
      >
      </cl-list-search>
    </div>
    <div class="action-panel-middle flex">
      <button
        [clIsValidAction]="actionBtnPolicies.edit"
        [disabled]="isEnableEdit"
        title="Edit the selected Location"
        [ngClass]="[
          isEnableEdit
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]"
        class="p-4-10 border-general br2 mr-2"
        (click)="showEditPanel()"
      >
        <i class="fa fa-pencil"></i>
        Edit
      </button>
      <button
        [clIsValidAction]="actionBtnPolicies.delete"
        [disabled]="isEnableDelete"
        title="Delete the selected Location"
        [ngClass]="[
          isEnableDelete
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]"
        class="p-4-10 border-general br2 mr-2"
        (click)="deleteSelectedLocations()"
      >
        <i class="fa" [ngClass]="'fa-trash'"></i>
        Delete
      </button>
    </div>

    <div
      class="sensor-febric__action-panel-right action-panel-right self-end flex items-center mr-10"
      (click)="closeSidePanel()"
    >
      <!--temp hide-->
      <!-- <span
        class="dark-blue-text action-separator colimn-serach-btn"
        (click)="showHideFilter()"
        ><i class="fa fa-search-plus color4"></i
      ></span> -->
      <div><button (click)="showHideFilter()" class="toggler-example align-grid-menu p-2 border-0 py-2 colsearch-menu">
        <span class="dark-blue-text" title="Search data by table columns">
          <i _ngcontent-bum-c250="" class="fa fa-search-plus color4"></i>
        </span>
      </button>
    </div>
      <cl-export-panel
        (exportEmitter)="downloadReport($event)"
      ></cl-export-panel>
      <cl-grid-column-toggle
        [columnList]="rawColumns"
        (toggleColumns)="gridColumnToggle($event)"
        [page]="'location'"
      >
      </cl-grid-column-toggle>
    </div>
  </div>
  <div class="h-calc-40">
    <main
      class="flex flex-row h-100 overflow-y-hidden"
      [style.width.px]="mainWidth - 10"
    >
      <div class="grow-0" [ngClass]="{ 'w-0 invisible': !showFilterPanel }">
        <cl-list-filter
          class="grow-0 nav panel scroll-y"
          [hideTitle]="true"
          [showCounts]="true"
          [DisableCustomCounts]="['Asset Types']"
          [title]="''"
          [fullHeight]="true"
          (panelsEmitter)="getPanelUpdate($event)"
          (toggleFilterPanel)="toggleFilterPanel()"
          heightClass="none"
          [filters]="filters"
        >
        </cl-list-filter>
      </div>
      <div class="grid-container grow relative">
        <div *ngIf="delayedRefreshStart">
          <cl-refresh-countdown
            [start]="delayedRefreshStart"
            [timer]="refreshTimer"
            (refreshList)="getLocationList()"
          ></cl-refresh-countdown>
        </div>

        <div *ngIf="isLoading">
          <div class="spin-pos">
            <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
          </div>
        </div>
        <div class="h-100" [style.width.px]="tableWidth">
          <ngx-datatable
            class="h-calc-40"
            [rows]="rows"
            [columns]="columns"
            [columnMode]="'force'"
            [loadingIndicator]="isLoading"
            [headerHeight]="headerHeight"
            [rowHeight]="rowHeight"
            [scrollbarV]="true"
            (reorder)="columnReordered($event)"
            [scrollbarH]="true"
            [selected]="selected"
            [sortType]="sortType"
            [selectionType]="selection"
            [selectAllRowsOnPage]="false"
            (scroll)="onScroll($event.offsetY)"
            (select)="onSelect($event)"
            [reorderable]="false"
            #table
          >
          </ngx-datatable>
          <ng-template #clearTemplate>
            <span
              class="fa fa-times-circle-o mr-4 clear-filter"
              *ngIf="showFilter"
              (click)="closeFilter()"
            ></span>
          </ng-template>
          <ng-template #hdrTpl let-column="column" let-sort="sortFn">
            <span class="header-sort-span" (click)="sort()">
              {{ column.name }}
            </span>
            <span class="header-filter-span input-filter-parent">
              <input
                *ngIf="showFilter"
                [(ngModel)]="columnFilters[column.prop]"
                (input)="filterInput($event)"
                class="input-filter"
                type="{{ column.prop == 'phone_call_date' ? 'date' : 'text' }}"
              />
            </span>
          </ng-template>

          <div class="flex flex-row items-center justify-center">
            <button
              class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
              [ngClass]="{ bcolor9: isLoading || allLoaded }"
              (click)="getLoadMoreUpdate()"
              [disabled]="isLoading || allLoaded"
            >
              <span class="mat-button__ripple"></span>
              <span
                class="mat-button__label color4"
                [ngClass]="{ color9: isLoading || allLoaded }"
                >load next 50</span
              >
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
  <ng-template
    #locationNameTemplate
    let-row="row"
    let-value="value"
    let-i="index"
  >
    <a class="font-12-bold" href="javascript:void(0);" (click)="loadSummaryPanel(row, $event)">{{
      value
    }}</a>
  </ng-template>
  <ng-template #dateTemplate let-row="row" let-value="value">
    {{ value | formateDate : "default" }}
  </ng-template>

  <ng-template #catalogTypeTemplate let-row="row" let-value="value">
    {{ value | titlecase }}
  </ng-template>

  <div class="summery-pannel" *ngIf="showSidePanel == true"></div>
  <div *ngIf="showSidePanel == true">
    <cl-location-summary
      *ngIf="mode == 'view'"
      [entityTypeKey]="roleObject?.entityTypeKey"
      [locationId]="roleObject?.id"
      [locationType]="roleObject?.parentEntityTypeKey"
      (closeSidePanel)="closeSidePanel()"
      (filterSummary)="filterSummary($event)"
    ></cl-location-summary>
    <cl-location-side-panel
      *ngIf="mode == 'add' || mode == 'edit'"
      [mode]="mode"
      [editLocation]="selected[0]"
      (closeSidePanel)="closeSidePanel($event)"
    ></cl-location-side-panel>
  </div>
</div>
