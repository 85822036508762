<div class="mt-2 mb-2">
    <span class="page-title">Business Rules</span>
</div>
<cl-table-surtitle [rows]="showingHits" [selected]="selected.length" [total]="totalHits" [pageName]="'Rules'"></cl-table-surtitle>
<div class="wt-ngx-grid-wrapper list-page-wrapper relative list-page px-2">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list">
            <div class="add-button" (click)="addRule()"
                [clIsValidAction]="actionBtnPolicies.add"
                *ngIf="!hidePlusButton">
                <span class="fa fa-plus"></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer color4 list-page_filter-toggle flex items-center">
                <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
              filter options</span>
            <form [formGroup]="searchFormGroup" novalidate>
                <cl-list-search [placeholder]="'Search by Rule Name'" (search)="updateFilter($event)"
                        [columns] = "['Rule Name']"></cl-list-search>
            </form>
            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general br2" (click)="editRule()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>
            <button [disabled]="deleteDisabled"
                [clIsValidAction]="actionBtnPolicies.delete"
                [ngClass]="[deleteDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general ml-2 br2" (click)="deleteRule()">
                <i class="fa fa-trash"></i>
                Delete
            </button>
            <button [disabled]="editDisabled"
                [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]="[editDisabled ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                class="p-4-10 border-general ml-2 br2" (click)="cloneRule()">
                <i class="fa fa-clone"></i>
                Clone
            </button>
        </div>
        <div class="sensor-febric__action-panel-right action-panel-right self-end flex items-center">
            <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'rule'">
            </cl-grid-column-toggle>
        </div>
    </div>
    <div class="h-calc-40">
        <main class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth - 10">
            <div class="grow-0" *ngIf="showFilterPanel">
                <cl-list-filter class="nav panel scroll-y grow-0" [hideTitle]="true" [showCounts]="true" [title]="''"
                [fullHeight]="true" (panelsEmitter)="getPanelUpdate($event)" (toggleFilterPanel)="toggleFilterPanel()" heightClass="none" [filters]="filters">
                </cl-list-filter>
            </div>
            <div class="grid-container grow relative">
                <div *ngIf="delayedRefreshStart">
                    <cl-refresh-countdown [start]="delayedRefreshStart" [timer]="refreshTimer"
                    (refreshList)="loadRulesData()"
                    ></cl-refresh-countdown>
                </div>
                <div class="h-100" [style.width.px]="tableWidth">
                    <ngx-datatable class="h-calc-40" [rows]="rows" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarH]="true" (reorder) = "columnReordered($event)" #table
                        [scrollbarV]="true" [selected]="selected" [sortType]="sortType" [selectionType]="selection"
                        [rowIdentity]="getId" [selectAllRowsOnPage]="false" (select)='onSelect($event)' [reorderable]="false">
                    </ngx-datatable>
                </div>
                <div class="h-100" *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
            </div>
        </main>
    </div>
    <ng-template #enableDisableRuleTemplate let-row="row" let-value="value" let-i="index">
      <span class="pl-1 relative top-[-2px]">
        <mat-slide-toggle [(ngModel)]="row.active" (change)="enableOrDisable(row)"></mat-slide-toggle>
      </span>
    </ng-template>
    <ng-template #ruleNameTemplate let-row="row" let-value="value" let-i="index">
      <span [textContent]="value" class="font-12-bold"></span>
    </ng-template>

    <div *ngIf="sidePanelsObj.add">
        <cl-add-rule [ruleObject]="selectedRule" (closeSidePanel)="closeSidePanel($event)"></cl-add-rule>
    </div>
</div>
