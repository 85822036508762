import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { DeviceProfileService } from '../device-profile.service';
import { UserService } from '@cl/user/user.service';
import { ToastService } from '@cl/common/services/toast.service';
import { ActivatedRoute } from '@angular/router';
import { SearchApiService } from '@cl/common/services/search-api.service';
import { DeviceStatusLabels, GatewayFilterLabels } from '@cl/@types/gateway.type';
import { FilterGroup } from '@cl/models';
import { PersistenceService } from '@cl/common/services/persistence.service';
import { Location } from '@angular/common';
@Component({
  selector: 'cl-device-profile-list',
  templateUrl: './device-profile-list.component.html',
  styleUrls: ['./device-profile-list.component.scss']
})
export class DeviceProfileListComponent implements OnInit, AfterViewInit {
  @ViewChild('deviceProfileTemplate') deviceProfileTemplate: TemplateRef<any>;
  @ViewChild('table') table;
  
  filters: any[] = [];
  initialFilters: any[] = [];
  public tableRowData: any = [];
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showSummaryPanel = false;
  mode = 'add';
  editDisabled = true;
  groupObject = {};
  allprofileObject = [];
  columnKeys: string[] = [];
  actionBtnPolicies = {
    add: ['EVENTPROFILER_CREATE'],
    edit: ['EVENTPROFILER_UPDATE'],
    delete: ['EVENTPROFILER_DELETE'],
    summary: ['EVENTPROFILER_SUMMARY'] //'EVENTPROFILER_DETAIL'
  };
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  private event = new Subject<any>();
  selectedProfile = {};
  profileName: string = '';
  private overallStatusFilter = [
    { key: "all", value: 0, subBucket: [] },
    { key: "green", value: 0, subBucket: [] },
    { key: "red", value: 0, subBucket: [] },
    { key: "yellow", value: 0, subBucket: [] },
    { key: "N/A", value: 0, subBucket: [] }
  ];
  rawFilters: any;
  mainWidth: any;
  tableWidth: number;
  showFilterPanel = true;

  scrollSize = 50;
  private payLoadForDeviceProfileList = {
    "scrollId": "",
    "scrollSize": this.scrollSize,
    "globalQueryText": "",
    "searchQueries": [
      {
        "filterType": "baseClass",
        "filterText": "device",
        "queryOperator": "should",
        "queryType": "match",
        "childFilters": []
      }
    ],
    "sortField": [

    ]
  };
  selectedFilters: any = {};
  isOrgRestrictedUser:boolean;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    public dialog: MatDialog,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private userService: UserService,
    private deviceProfileService: DeviceProfileService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private _searchApiService: SearchApiService,
    private persistenceStore:PersistenceService,
    private location: Location,
  ) {
     this.route.queryParams.subscribe(params=> {
      this.profileName = params['did'];
      if(this.profileName) {
        this.location.replaceState(this.route.snapshot.url[0].path);
      }
    })
  }

  ngOnInit(): void {
    // this.getDeviceFilters();
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
    this.getFilters();
    this.getDeviceProfiles();
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.mode = 'view';
        this.showSummaryPanel = true;
        this.groupObject = evt.profileObject;
      }
    });
    this.subscriptions.push(eventSub);
  }

  ngAfterViewInit(): void {
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: false,
        checkboxable: true,
        width: 30,
        visible: true
      },
      { name: 'Name', prop: 'eventProfileName', width: 180, cellTemplate: this.deviceProfileTemplate, visible: true },
      { name: 'Organization', prop: 'organizationName', minWidth: 180, visible: true },
      { name: 'Sensor Type', prop: 'sensorType', width: 180, visible: true },
      { name: 'Mapped Sensors', prop: 'formattedMappedDevices', width: 180, visible: true },
      { name: 'Configured Events', prop: 'name', width: 180, visible: true }
    ];
    if(this.isSuperAdmin()){
      this.rawColumns.push({
        name: 'Tenant', prop: 'tenantName', width: 180, visible: true
      })
    }
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('profile', [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
    this.table.recalculate();
    this._changeDetectorRef.detectChanges();
  }

  private isSuperAdmin() {
    return this.userService.getPolicies().includes('SCOPE_ALL');
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'profile', this.table._internalColumns);   
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  getDeviceProfiles(): void {
    this.isLoading = true;
    let groupData:any = [];
    const deviceProfileDataSubscription: Subscription = this.deviceProfileService.getAllDeviceProfiles().subscribe((data:any)=>{
      data.forEach((d:any) => {
        d.formattedMappedDevices = this.formMappedDevice(d.mappedDevices);
        d.formattedEventName = this.formatEventName(d);
        groupData.push(d);
      });
      this.rows =[...groupData];
      this.tableRowData = [...this.rows];
      this.getProfileObject();
      this.isLoading = false;

    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(deviceProfileDataSubscription);
  }

  getFilteredDeviceProfiles(param1){
    let groupData:any = [];
    this.deviceProfileService.deviceProfileFilter(param1).subscribe((data:any)=>{
      if(data){
        data.forEach((d:any) => {
          d.formattedMappedDevices = this.formMappedDevice(d.mappedDevices);
          d.formattedEventName = this.formatEventName(d);
          groupData.push(d);
        });
        this.rows =[...groupData];
        this.tableRowData = [...this.rows];
        this.getProfileObject();
        this.isLoading = false;
  
      }
    },(error) => {
      console.error(error);
      this.isLoading = false;
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getProfileObject(){
    if(this.profileName) {
      this.mode = 'view';
      this.showSummaryPanel = true;
      this.groupObject = this.tableRowData.find((row: any) => row.eventProfileName === this.profileName);
    }
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  loadSummaryPanel(profileObject:any, event:any){
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', profileObject: profileObject });
  }

  private formMappedDevice(mappedDevice){
    let deviceList = "";
      let arrDeviceList = _.map(mappedDevice, 'id');
      deviceList = _.first(arrDeviceList);
      let deviceSize = _.size(arrDeviceList);

      if (deviceSize > 1) {
        deviceList += " & " + (deviceSize - 1) + " more";
      }
      return deviceList;
  }

  private formatEventName(entity) {
    let events = "";
    if (entity.eventConfig) {
      events += (entity.eventConfig.isShockConfigured === "true" || entity.eventConfig.isShockConfigured === true) ? "Shock," : "";
      events += (entity.eventConfig.isBatteryConfigured === "true" || entity.eventConfig.isBatteryConfigured === true) ? "Battery," : "";
      events += (entity.eventConfig.isLightConfigured === "true" || entity.eventConfig.isLightConfigured === true) ? "Light," : "";
      events += (entity.eventConfig.isPressureConfigured === "true" || entity.eventConfig.isPressureConfigured === true) ? "Pressure," : "";
      events += (entity.eventConfig.isTemperatureConfigured === "true" || entity.eventConfig.isTemperatureConfigured === true) ? "Temperature," : "";
      events += (entity.eventConfig.isHumidityConfigured === "true" || entity.eventConfig.isHumidityConfigured === true) ? "Humidity," : "";
      events = events.slice(0, -1);
    }

    return events;
  }


  onSelect(rowData: any) {
    this.selected = [...rowData.selected];
    if(this.selected.length == 1 && this.selected[0].default !== true){
      if(rowData.selected[0].organizationName == '(Any)' && this.isOrgRestrictedUser ==true){
        this.editDisabled = true;
      }else{
        this.editDisabled = false;
      }
    }else{
      this.editDisabled = true;
    }
    this.groupObject = this.selected[0];
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'device-profile-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(event:any) {
    const val = event.target.value.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.tableRowData];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.eventProfileName.toLowerCase().indexOf(val) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.rows = temp;
  }
  closeSidePanel(cbAction?){
    this.showSummaryPanel = false;
    this.selected = [];
    this.editDisabled = true;
    if(cbAction && cbAction.action == 'reloadProfiles') {
      this.delayedRefreshStart = true;
    }
  }

  addDeviceProfile(){
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  editDeviceProfile(){
    this.mode = 'edit';
    this.showSummaryPanel = true;
  }

  deleteDeviceProfile(){
    this.isLoading = true;
    let message = `Are you sure you want to delete this profile?: `+this.selected[0].eventProfileName;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let profileName = this.selected[0].eventProfileName;
       const deleteDeviceProfileSub:Subscription = this.deviceProfileService.deleteDeviceProfile(profileName).subscribe(data=>{
        this.getDeviceProfiles();
          let action = 'Profile deleted successfully';
          this.toastService.success(action, this.selected[0].eventProfileName);
          this.selected = [];
          this.isLoading = false;
          this.delayedRefreshStart = true;
          this.editDisabled = true;
        }, (error) => {
            console.error(error);
            let action = 'Something went wrong';
           this.toastService.error(action, this.selected[0].eventProfileName);
           this.isLoading = false;
          });

          this.subscriptions.push(deleteDeviceProfileSub);
      }else{
        this.isLoading = false;
      }
    });
  }


  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 268;
    } else {
      this.tableWidth = this.mainWidth - 18;
      
    }
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
    setTimeout(() => {
      this.table?.recalculate();
    }, 10);
  }

  getPanelUpdate(event: any) {
    this.payLoadForDeviceProfileList.scrollId = '';
    let filterEvent = event.filter;
    if (event.action === 'clearFilters') {
      this.selectedFilters = {};
      this.filters = _.cloneDeep(this.initialFilters);
    } else {
      if (filterEvent.selectionType === 'singleselect') {
        if (filterEvent.name === 'all') {
          delete this.selectedFilters[filterEvent.filterGroupName];
        } else if (filterEvent.name === 'N/A') {
          this.selectedFilters[filterEvent.filterGroupName] = ['na'];
        } else {
          this.selectedFilters[filterEvent.filterGroupName] = [filterEvent.name];
        }
      } else {
        if (filterEvent.checked) {
          if (!this.selectedFilters[filterEvent.filterGroupName]) {
            this.selectedFilters[filterEvent.filterGroupName] = [];
          }
          this.selectedFilters[filterEvent.filterGroupName].push(filterEvent.name);
        } else {
          let index = this.selectedFilters[filterEvent.filterGroupName].indexOf(filterEvent.name);
          this.selectedFilters[filterEvent.filterGroupName].splice(index, 1);
        }
      }
    }
    this.createPayloadAndReloadDeviceProfile()
  }

  private formFilters(data) {
    this.filters = data.map((entry: any) => {
      let filter: FilterGroup = {
        list: [],
        filterType: entry.labelKey,
        label: entry.label,
        type: "multiselect",
      };
      filter.list = entry.options.map(list => ({
        name: list.id,
          checked: !!false,
          count: list.count,
          displayLabel: list.name,
          label: list.name
      }));
      filter.enableCollapsing = filter.list.length > 10;
      filter.collapsed = filter.list.length > 10;
      filter.expand = true;
      return filter;
    })
    this.initialFilters = _.cloneDeep(this.filters);
  }

  getFilters(){
    this.deviceProfileService.getDeviceProfileFilter().subscribe(res=>{
      this.formFilters(res);
    })
  }

  createPayloadAndReloadDeviceProfile() {
    let childFilters = [];
    Object.keys(this.selectedFilters).forEach(key => {
      if (this.selectedFilters[key].length) {
        childFilters.push(new Object({
          "filterType": key,
          "filterText": this.selectedFilters[key].join()
        }));
      }
    });
    (childFilters.length>0)? this.getFilteredDeviceProfiles(childFilters) : this.getDeviceProfiles();
    
  }
}
