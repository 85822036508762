<div class="cl-characteristic-list ml-3">
  <div class="row align-items-start" *ngIf="isMeasuresNotEmptyWithValue">
    <div class="col-2 table-cell overflow-y-auto overflow-x-hidden h-[400px]" [ngClass]="graph.children.length ==1?'':'graph-height'">
      <div class="p10 cursor-pointer cl-active-indication font-14-bold left-align"
        *ngFor="let measure of measures" (click)="changeMeasure(measure)"
        [ngClass]="{'bgcolor4 color2 cl-active-indicator rounded-end': activeMeasure.measureLabel === measure.measureLabel, 'bgcolor2 color4': activeMeasure.measureLabel !== measure.measureLabel}">
        <!-- <span *ngIf="measure.voilated"><img class="inline -ml-8 mr-3" width="20" height="20" src="{{measure.voilatedIcon}}"></span>   -->
        <div [ngClass]="measure.measureLabel ==='RSSI'?'float-child-1-device':'float-child-1'"><cl-alert-icon [alertIconObject]="formatAlertObject(measure)"></cl-alert-icon></div>  
        <div class="float-child-2">
            <span [textContent]="measure.measureLabel"></span><br/>
            <span class="rt1 font-11-normal" [ngClass]="{'color2': activeMeasure.measureLabel === measure.measureLabel,
            'color34': activeMeasure.measureLabel !== measure.measureLabel}"><span [innerHTML]="measure|measure"></span>
            as of <span>{{measure.timeOfCapture | formateDate}}</span></span>
          </div>
      </div>
    </div>
    <div #graph class="col-10">
      <ng-container *ngIf="activeMeasure?.config?.displayType === 'graph'">
        <section *ngIf="data?.length>0">
          <cl-line-chart [label]="activeMeasure.measureLabel" [data]="data" [tooltip]="tooltipCustionHandler" [unit]="activeMeasure.uom" [options]="options" [annotations]="annotations"></cl-line-chart>
        </section>
        <div *ngIf="data?.length===0">No Data Available</div>
      </ng-container>
      <ng-content select=".table-class"></ng-content>
    </div>
  </div>
</div>

