<div class="cl-threshold-condition">
  <div>
    <span class="font-13-normal" [textContent]="metadata.displayLabel"></span>
    <span class="color21">*</span>
  </div>
  <div *ngIf="thresholdLevels.length > 0">
    <div *ngFor="let level of thresholdLevels">
      <div>
        <mat-slide-toggle (change)="fnTriggerInputChange('toggle', level)" [(ngModel)]="outputMeasure[level.enabled.key]"
          [required]="level.enabled.templateOptions.required" matTooltip="{{level.enabled.templateOptions.tlabel | translate}}">
        </mat-slide-toggle>
        <span class="pl-1">
          <span [textContent]="level.enabled.templateOptions.label | translate"></span>
        </span>
      </div>
      <div class="pl-3">
        <div class="mt-2" *ngIf="level.min">
          <span>
            <span [textContent]="level.min.templateOptions.tlabel | translate"></span>
          </span>
          <input type="number" [min]="min[level.min.key]" [max]="max[level.min.key]" [name]="level.min.key"
            [(ngModel)]="outputMeasure[level.min.key]" [placeholder]="level.min.templateOptions.placeholder"
            [disabled]="!outputMeasure[level.enabled.key]" (change)="fnTriggerInputChange('input', level)"
            class="w-[200px] basic-input font-12-normal"/>
        </div>
        <div class="mt-2" *ngIf="level.max">
          <span>
            <span [textContent]="level.max.templateOptions.tlabel | translate"></span>
          </span>
          <input type="number" [min]="min[level.max.key]" [max]="max[level.max.key]" [name]="level.max.key"
            [(ngModel)]="outputMeasure[level.max.key]" [placeholder]="level.max.templateOptions.placeholder"
            [disabled]="!outputMeasure[level.enabled.key]" (change)="fnTriggerInputChange('input', level)"
            class="w-[200px] basic-input font-12-normal"/>
        </div>
        <div class="mt-2" *ngIf="level.duration">
          <span>
            <span [textContent]="level.duration.templateOptions.tlabel | translate"></span>
          </span>
          <span class="ml-2">
            <cl-duration [disabled]="!outputMeasure[level.enabled.key]"  (selection-change)="fnDurationChanged(level, outputMeasure, level.duration.key, $event)"
              [show]="['D','h','m']" [is]="outputMeasure[level.duration.key]" [placeholder]="level.duration.templateOptions.placeholder">
            </cl-duration>
          </span>
          <span class="ml-1">&nbsp;</span>
        </div>
      </div>
      <!--<div>
        <input type="hidden" *ngIf="outputMeasure[level.enabled.key]" [(ngModel)]="level.threshold" [required]="(level.min || level.max)"/>
        <input type="hidden" *ngIf="level.duration.templateOptions.required" [(ngModel)]="level.durationThreshold" [required]="outputMeasure[level.enabled.key]"/>
      </div> -->
    </div>
  </div>
</div>
