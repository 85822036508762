import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CatalogType } from '@cl/@types/extended-attributes.types';
import { CompositeShipment } from '@cl/@types/shipment.types';
import { OrganizationService } from '@cl/common/services/organization.service';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { UserService } from '@cl/user/user.service';
import { combineLatest } from 'rxjs';
import { ShipmentListStateService } from '../../shipment-list-state.service';
import { InputField } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';

@Component({
  selector: 'cl-shipment-composite',
  templateUrl: './shipment-composite.component.html',
  styleUrls: ['./shipment-composite.component.scss'],
})
export class ShipmentCompositeComponent implements OnInit, OnDestroy {
  @Input() shipmentID: string;
  @Input() isEdit: boolean;
  @Input() shipmentCatalog: CatalogType;
  @ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;

  shipmentForm: FormGroup;
  shipment = {
    name: '',
    externalId: '',
    organization: '',
  };
  shipmentRawData = {};
  regularExpression = /^[A-Za-z0-9@&()_:',.;+"/\s-]+$/;
  submitted: boolean = false;
  orgList: any = [];
  isOrgRestrictedUser;
  isAddShipmentDisabled = true;
  subShipments: {
    id: string;
    name: string;
    statusCode: string;
    origin: string;
    destination: string;
    eta?: number;
    actualArrivalTime?: number;
    isExpanded: boolean;
    catalogType: string;
  }[] = [];
  dynamicInputFields:InputField[] = [];

  constructor(
    private shipmentsService: ShipmentService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private shipmentListStateService: ShipmentListStateService,
    private _dynamicFormService: DynamicFormService
  ) {}

  ngOnInit(): void {
    if (this.shipmentID && this.isEdit) {
      this.getShipmentDetails();
    }else{
      this.getExtendedAttributes();
    }
    this.shipmentListStateService.changeCSActiveState(true);
    this.shipmentListStateService.selectedRowsForCS.subscribe((records) => {
      this.isAddShipmentDisabled = records.length == 0;
    });
    this.isOrgRestrictedUser = this.userService.getUser()?.orgAware;
    this.initializePreLoadedList();
    this.buildForm();
  }

  getShipmentDetails() {
    this.shipmentsService
      .getCompositeShipmentDetails(this.shipmentID, 'compositeshipment')
      .subscribe((res: CompositeShipment) => {
        this.shipment = {
          name: res.name,
          externalId: res.externalId,
          organization: res.organization,
        };
        this.shipmentRawData = res;
        this.subShipments = res.subShipments.map((shipment) => {
          return {
            catalogType: shipment.entityTypeKey,
            destination: shipment.destinationAreaName,
            id: shipment.id,
            isExpanded: false,
            name: shipment.name,
            origin: shipment.originAreaName,
            statusCode: shipment.status,
            eta: shipment.eta,
            actualArrivalTime: shipment.actualArrivalTime
          };
        });
        this.getExtendedAttributes()
        this.buildForm();
      });
  }

  buildForm() {
    this.shipmentForm = this.formBuilder.group({
      name: [this.shipment.name, Validators.required],
      externalId: [this.shipment.externalId],
      organization: [this.shipment.organization],
    });
  }

  async getExtendedAttributes(){
    const fields = this.shipmentCatalog.cdmFields.filter(field => field.group === 'User' && field.instanceUserCreatable);

    const orderedFields = this._dynamicFormService.orderFields([...fields],'order');
    this.dynamicInputFields = await this._dynamicFormService.generateInputFields(orderedFields,this.isEdit,this.shipmentRawData);
  }

  addToShipment() {
    const records = this.shipmentListStateService.selectedRowsForCS.getValue();
    records.forEach((item) => {
      if (!this.subShipments.find((subShipment) => subShipment.id == item.id)) {
        this.subShipments.push({
          id: item.id,
          name: item.name,
          statusCode: item.statusCode,
          origin: item.origin,
          destination: item.destination,
          eta: item.eta,
          isExpanded: false,
          catalogType: item.entityTypeKey,
          actualArrivalTime: item.actualArrivalTime
        });
      }
    });
  }

  removeSubShipment(shipmentId) {
    const index = this.subShipments.findIndex(
      (shipment) => shipment.id == shipmentId
    );
    if (index > -1) {
      this.subShipments.splice(index, 1);
    }
  }

  getAlphabet(index: number) {
    if (index > 25) return ' ';
    return String.fromCharCode(index + 'A'.charCodeAt(0));
  }

  drop(event: CdkDragDrop<typeof this.subShipments>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  restrictedCharactersOnly(event) {
    var kcode = event.key;
    if (this.validateName(kcode)) {
      return true;
    } else {
      return false;
    }
  }
  public validateName(name: string) {
    return this.regularExpression.test(String(name));
  }

  initializePreLoadedList = () => {
    const orgListAPI = this.organizationService.getOrgList();
    const shipmentLists = combineLatest({
      orgList: orgListAPI,
    }).subscribe(
      (data: any) => {
        this.orgList = [...data.orgList];
        if(this.isOrgRestrictedUser){
        this.shipmentForm
          .get('organization')
          .setValue(this.orgList && this.orgList[0]?.id);}
        if (this.isEdit) {
          // this.buildForm();
        }
      },
      (error) => {}
    );
  };

  onOrgclose() {
    this.shipmentForm.get('organization').setValue('');
  }

  ngOnDestroy(): void {
    this.shipmentListStateService.changeCSActiveState(false);
  }

  onSubmit() {
    this.submitted = true;
    const dynamicFormGroup = this.dynamicForm?.onSubmit();


    const form = this.shipmentForm.getRawValue();

    if ((this.shipmentForm.invalid || this.subShipments.length == 0) || (dynamicFormGroup && dynamicFormGroup.invalid)) return null;

    const subShipments = this.subShipments.map((shipment) => {
      return {
        id: shipment.id,
        catalogType: shipment.catalogType,
      };
    });

    let payload = {
      name: form.name,
      externalId: form.externalId,
      organization: form.organization || null,
      subShipments,
    };

    if(dynamicFormGroup){
      payload = {...payload, ...dynamicFormGroup.getRawValue() }
    }

    return payload;
  }
}
