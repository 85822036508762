import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthorizeService } from '@cl/@authorize/authorize.service';
import { CustomRoute } from '@cl/@types/custom-route.type';
import { ToastService } from '@cl/common/services/toast.service';
import { Observable, map, filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteGuard implements CanActivate {
  constructor(private authorizeService : AuthorizeService, private toastService: ToastService ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let currentConfig: any = route.routeConfig;
      return this.authorizeService.isValidRouteIfPoliciesLoaded(currentConfig)
        .pipe(
          map((status: boolean) => {
            if(!status) this.toastService.openCloseHandlerSnackBar([], 'Access not allowed')
            
            return status
          })
        );
  }

}
