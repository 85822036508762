import { Injectable } from '@angular/core';
import {
  ClearAllFilters,
  ClearFilters,
  EnableFilter,
  FilterNodesByTerm,
  GetCounts,
  InitCurrentAction,
  InitMapGroups,
  // LoadUsers,
  LvHidePanel,
  LvMapUserAction,
  LvSelectLocation,
  LvSelectNode,
  LvSelectUser,
  LvShowPanel,
  LvToggleLocation,
  LvToggleSortOrder,
  LvToggleUser,
  LvUpdateTags,
  RemoveTag,
  ResetMap,
  SearchByTerm,
  SingleFilter,
  StepBack,
  StepForward,
  SwitchViewMode,
  ToggleCanvasInfoGraph,
  ToggleFilter,
  ToggleFilterCategory,
  ToggleLinks,
  ToggleSortByType,
  ToggleSubFilter,
  UpdateCounts,
  UpdateSearchBounds
} from "@cl/ngxs/actions";
import { Action, State, StateContext, Store } from "@ngxs/store";
import * as _ from "lodash";
import { combineLatest } from "rxjs";
import { tap } from "rxjs/operators";
import { GraphAPIService} from "../common/services/graph-api.service";
import { MapFilterService } from "../common/services/map-filter.service";
import { SearchApiService } from "../common/services/search-api.service";
import { UserApiService } from "../common/services/user-api.service";
import { ClientConfigState } from "@cl/state/client-config.state";

@State<any>({
  name: "live_map_state",
  defaults: {
    stateList: [],
    stateIndex: 0,
    isMapMode: true,
    mapGroups: [],
    initialMapGroups: [],
    searchTerm: "",
    nodeSearchTerm: "",
    isFilterAction: true,
    currentAction: "init",
    isLoaded: false,
    sortByType: false,
    showInfoGraph: "",
    showLinks: true,
    searchParams: {},
    searchFields: [],
    markers: [],
    tags: [],
    searchBounds: {},
    locAssetCounts: {},
    totalAssets: 0,
    activeEntity: null,
    selectedUser: null,
    selectedUsers: {},
    selectedLocations: {},
    assetFilters: null,
    sortReverse: false,
    clearGroup: "",
  },
})
@Injectable()
export class LiveMapState {
  constructor(
    private store: Store,
    private _mapFilterService: MapFilterService,
    private _searchApiService: SearchApiService,
    private _graphAPIService: GraphAPIService,
    private _userApiService: UserApiService
  ) {}

  static getTags(state: any) {
    return state.tags;
  }
  @Action(InitCurrentAction)
  initCurrentAction(ctx: StateContext<any>, action: InitCurrentAction) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentAction: "init",
      isFilterAction: false,
    });
  }

  // @Action(LoadUsers)
  // LoadUsers(ctx: StateContext<any>, action: LoadUsers) {
  //   const state = ctx.getState();
  //   this._userApiService
  //     .getUsers("FIELD_TECHNICIAN")
  //     .pipe(take(1))
  //     .subscribe((res: any[]) => {
  //       ctx.patchState({
  //         users: [...res],
  //         currentAction: "LoadUsers",
  //       });
  //     });
  // }
  @Action(LvToggleSortOrder)
  lvToggleSortOrder(ctx: StateContext<any>, action: LvToggleSortOrder) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      sortReverse: !state.sortReverse,
      currentAction: "lvToggleSortOrder",
    });
  }
  @Action(InitMapGroups)
  initMapGroups(ctx: StateContext<any>, action: InitMapGroups) {
    const state = ctx.getState();
    const clientMapGroups = this.store.selectSnapshot(ClientConfigState.getClientMapGroups);
    const mgs = action.mapGroups.slice();
    // .map((mg) => {
    //   mg.selectedFilters["count"] = 0;
    //   if (mg.class === "asset" && _.size(state.assetFilters)) {
    //     mg.filters = _.cloneDeep(state.assetFilters);
    //   }
    //   return mg;
    // })
    // .filter((mg) => {
    //   return clientMapGroups[mg.class] ? true : false;
    // });
    let res = this._mapFilterService.getSearchFields(mgs, {}, {}, "must");

    const searchFields = state.searchFields?.length > 0 ? state?.searchFields : res.searchFields;

    ctx.setState({
      ...state,
      initialMapGroups: _.cloneDeep(mgs),
      mapGroups: _.cloneDeep(mgs),
      isFilterAction: true,
      searchFields: _.cloneDeep(searchFields),
      isLoaded: true,
      currentAction: "initMapGroups",
    });
    return ctx.dispatch(new GetCounts(true, false));
  }

  @Action(LvSelectNode)
  lvSelectNode(ctx: StateContext<any>, action: LvSelectNode) {
    const state = ctx.getState();
    // const stateList = _.cloneDeep(state.stateList);
    // stateList[state.stateIndex].activeEntity = { ...action.node };

    ctx.setState({
      ...state,
      currentAction: "lvSelectNode",
      selectedUser: null,
      activeEntity: { ...action.node },
      isFilterAction: false,
      // stateList: stateList
    });
    ctx.dispatch(new LvShowPanel("inspector", "lvSelectNode"));
    return this.pushHistoryStack(ctx, null, action.node);
  }
  @Action(LvSelectLocation)
  lvSelectLocation(ctx: StateContext<any>, action: LvSelectLocation) {
    const state = ctx.getState();
    // added toggle action for location select
    let toggleActive = !state.activeEntity || state.activeEntity.uid !== action.node.uid ? true : false;
    let activeLoc = toggleActive ? { ...action.node } : null;

    let res = this._mapFilterService.getSearchFields(
      state.mapGroups,
      state.selectedUsers,
      toggleActive ? { [action.node.uid]: action.node } : state.selectedLocations,
      "must"
    );
    ctx.setState({
      ...state,
      currentAction: "lvSelectLocation",
      searchFields: _.cloneDeep(res.searchFields),
      selectedUser: null,
      activeEntity: activeLoc,
      tags: [...res.tags],
      isFilterAction: true,
    });
    ctx.dispatch(new GetCounts(false, false));
    ctx.dispatch(new LvShowPanel("rightList", "lvSelectUser"));

    // Don't show location summary
    // if (toggleActive) {
    //     return ctx.dispatch(new LvShowPanel('inspector', 'lvSelectNode'))
    // } else {
    return ctx.dispatch(new LvHidePanel("inspector"));
    // }
  }
  @Action(LvMapUserAction)
  lvMapUserAction(ctx: StateContext<any>, action: LvMapUserAction) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentAction: "lvMapUserAction",
      isFilterAction: false,
    });
    return ctx.dispatch(new LvShowPanel("rightList", "lvMapUserAction"));
  }
  @Action(LvSelectUser)
  lvSelectUser(ctx: StateContext<any>, action: LvSelectUser) {
    const state = ctx.getState();
    let userId = action.user.id;
    const selectedItems = _.cloneDeep(state.selectedItems);
    // added toggle action for user select

    let toggleOn = !state.selectedUser || state.selectedUser.id !== action.user.id ? true : false;
    let res = this._mapFilterService.getSearchFields(
      state.mapGroups,
      toggleOn ? { [userId]: action.user } : state.selectedUsers,
      state.selectedLocations,
      "must"
    );
    let selectedUser = toggleOn ? { ...action.user } : null;
    let usersAssets$;
    if (!selectedUser) {
      delete selectedItems[action.user.uid];
    } else {
      selectedItems[selectedUser.id] = { ...selectedUser };
    }
    ctx.setState({
      ...state,
      currentAction: "lvSelectUser",
      searchFields: _.cloneDeep(res.searchFields),
      selectedUser: selectedUser,
      selectedItems: { ...selectedItems },
      tags: [...res.tags],
      isFilterAction: true,
    });
    if (selectedUser) {
      usersAssets$ = this._userApiService.getUserAssets(userId).pipe(
        tap((res) => {
          ctx.setState({
            ...ctx.getState(),
            selectedUser: {
              ...action.user,
              assetList: res.hits,
            },
            isFilterAction: false,
          });
        })
      );
      return combineLatest([
        ctx.dispatch(new GetCounts(false, false)),
        usersAssets$,
        ctx.dispatch(new LvShowPanel("rightList", "lvSelectUser")),
        ctx.dispatch(new LvShowPanel("inspector", "lvSelectUser")),
      ]);
    } else {
      return combineLatest([ctx.dispatch(new GetCounts(false, false)), ctx.dispatch(new LvHidePanel("inspector"))]);
    }
  }
  @Action(LvToggleUser)
  lvToggleUser(ctx: StateContext<any>, action: LvToggleUser) {
    const state = ctx.getState();
    let userId = action.user.id;
    const selectedUsers = { ...state.selectedUsers };
    if (selectedUsers[userId]) {
      delete selectedUsers[userId];
    } else {
      selectedUsers[userId] = action.user;
    }
    let res = this._mapFilterService.getSearchFields(state.mapGroups, selectedUsers, state.selectedLocations, "must");
    ctx.setState({
      ...state,
      currentAction: "lvToggleUser",
      searchFields: _.cloneDeep(res.searchFields),
      selectedUsers: selectedUsers,
      tags: [...res.tags],
      // mapGroups: this.updateSelectedItems(state.mapGroups, selectedUsers, 'users'),
      isFilterAction: true,
    });
    return combineLatest([
      ctx.dispatch(new LvShowPanel("rightList", "LvToggleUser")),
      ctx.dispatch(new GetCounts(false, false)),
      ctx.dispatch(new LvUpdateTags(res.tags)),
    ]);
  }

  // updateSelectedItems(mgs, selectedItems, type) {
  //     return mgs.map(mg => {
  //         return (mg.class === type) ? { ...mg, selectedItems: { ...selectedItems } } : mg;
  //     })
  // }
  @Action(LvToggleLocation)
  lvToggleLocation(ctx: StateContext<any>, action: LvToggleLocation) {
    const state = ctx.getState();
    let locationId = action.location.properties.id;
    const selectedLocations = { ...state.selectedLocations };
    if (selectedLocations[locationId]) {
      delete selectedLocations[locationId];
    } else {
      selectedLocations[locationId] = action.location;
    }

    let res = this._mapFilterService.getSearchFields(state.mapGroups, state.selectedUsers, selectedLocations, "must");
    ctx.setState({
      ...state,
      currentAction: "lvToggleLocation",
      searchFields: _.cloneDeep(res.searchFields),
      selectedLocations: selectedLocations,
      tags: [...res.tags],
      isFilterAction: true,
      // mapGroups: this.updateSelectedItems(state.mapGroups, selectedLocations, 'location')
    });
    return combineLatest([
      ctx.dispatch(new LvShowPanel("rightList", "lvToggleLocation")),
      ctx.dispatch(new GetCounts(false, false)),
      ctx.dispatch(new LvUpdateTags(res.tags)),
    ]);
  }

  @Action(UpdateCounts)
  updateCounts(ctx: StateContext<any>, action: UpdateCounts) {
    const state = ctx.getState();
    const countsObj = this._searchApiService.parseAggregateCounts(action.counts);
    const mgs = this._searchApiService.updateMgCounts(countsObj, state.mapGroups);

    ctx.setState({
      ...state,
      initialMapGroups: _.cloneDeep(mgs),
      mapGroups: _.cloneDeep(mgs),
      currentAction: "updateCounts",
    });
  }
  @Action(SingleFilter)
  singleFilter(ctx: StateContext<any>, action: SingleFilter) {
    const state = ctx.getState();
    let mapGroups = _.cloneDeep(state.initialMapGroups); // reset
    mapGroups = this._mapFilterService.singleFilter(state.mapGroups, action.mapGroup);

    let res = this._mapFilterService.getSearchFields(mapGroups, state.selectedUsers, state.selectedLocations, "must");

    ctx.setState({
      ...state,
      currentAction: "singleFilter",
      searchFields: _.cloneDeep(res.searchFields),
      tags: res.tags,
      isFilterAction: true,
      mapGroups: _.cloneDeep(mapGroups),
    });
    ctx.dispatch(new GetCounts(false, false));
    return ctx.dispatch(new LvUpdateTags(res.tags));
  }
  @Action(EnableFilter)
  enableFilter(ctx: StateContext<any>, action: EnableFilter) {
    const state = ctx.getState();
    let mapGroups = this._mapFilterService.enableFilterType(state.mapGroups, action.mapGroup);

    let res = this._mapFilterService.getSearchFields(mapGroups, state.selectedUsers, state.selectedLocations, "must");

    ctx.setState({
      ...state,
      currentAction: "enableFilter",
      isFilterAction: true,
      mapGroups: _.cloneDeep(mapGroups),
      searchFields: _.cloneDeep(res.searchFields),
      tags: res.tags,
    });
    ctx.dispatch(new GetCounts(false, false));
    return ctx.dispatch(new LvUpdateTags(res.tags));
  }
  @Action(ToggleFilter)
  toggleFilter(ctx: StateContext<any>, action: ToggleFilter) {
    const state = ctx.getState();
    let mapGroups = this._mapFilterService.toggleFilterType(state.mapGroups, action.mapGroup);

    let res = this._mapFilterService.getSearchFields(mapGroups, state.selectedUsers, state.selectedLocations, "must");

    ctx.setState({
      ...state,
      isFilterAction: true,
      currentAction: "toggleFilter",
      mapGroups: _.cloneDeep(mapGroups),
      searchFields: _.cloneDeep(res.searchFields),
      tags: res.tags,
    });
    ctx.dispatch(new GetCounts(false, false));
    return ctx.dispatch(new LvUpdateTags(res.tags));
  }
  @Action(ToggleFilterCategory)
  ToggleFilterCategory(ctx: StateContext<any>, action: ToggleFilterCategory) {
    const state = ctx.getState();
    let mapGroups = this._mapFilterService.toggleFilterCategory(state.mapGroups, action.mapGroup, action.category, "");
    let res = this._mapFilterService.getSearchFields(mapGroups, state.selectedUsers, state.selectedLocations, "must");
    ctx.setState({
      ...state,
      isFilterAction: true,
      currentAction: "toggleFilterCategory",
      mapGroups: _.cloneDeep(mapGroups),
      searchFields: _.cloneDeep(res.searchFields),
      tags: res.tags,
    });
    ctx.dispatch(new LvShowPanel("rightList", "ToggleFilterCategory"));
    ctx.dispatch(new GetCounts(false, false));
    return ctx.dispatch(new LvUpdateTags(res.tags));
  }
  @Action(ToggleSubFilter)
  ToggleSubFilter(ctx: StateContext<any>, action: ToggleSubFilter) {
    const state = ctx.getState();
    let mapGroups = this._mapFilterService.toggleFilterCategory(state.mapGroups, action.mapGroup, action.parentFilter, action.subFilter);
    let res = this._mapFilterService.getSearchFields(mapGroups, state.selectedUsers, state.selectedLocations, "must");
    ctx.setState({
      ...state,
      isFilterAction: true,
      currentAction: "toggleSubFilter",
      mapGroups: _.cloneDeep(mapGroups),
      searchFields: _.cloneDeep(res.searchFields),
      tags: res.tags,
    });
    ctx.dispatch(new LvShowPanel("rightList", "ToggleFilterCategory"));
    ctx.dispatch(new GetCounts(false, false));
    return ctx.dispatch(new LvUpdateTags(res.tags));
  }
  @Action(RemoveTag)
  removeTag(ctx: StateContext<any>, action: RemoveTag) {
    const state = ctx.getState();
    let mapGroups = [...state.mapGroups],
      selectedUsers = { ...state.selectedUsers },
      selectedLocations = { ...state.selectedLocations };
    if (action.tag.class === "asset") {
      mapGroups = this._mapFilterService.toggleFilterCategory(
        state.mapGroups,
        action.mapGroup,
        action.tag.parentTag ? action.tag.parentTag : action.category,
        action.tag.parentTag ? action.category : ""
      );
    } else if (action.tag.class === "users") {
      let userId = action.tag.id;
      if (selectedUsers[userId]) {
        delete selectedUsers[userId];
      }
    } else if (action.tag.class === "location") {
      let locationId = action.tag.id;
      if (selectedLocations[locationId]) {
        delete selectedLocations[locationId];
      }
    }

    let res = this._mapFilterService.getSearchFields(mapGroups, selectedUsers, selectedLocations, "must");
    ctx.setState({
      ...state,
      isFilterAction: true,
      currentAction: "removeTag",
      mapGroups: _.cloneDeep(mapGroups),
      searchFields: _.cloneDeep(res.searchFields),
      selectedUsers: _.cloneDeep(selectedUsers),
      selectedLocations: _.cloneDeep(selectedLocations),
      tags: res.tags,
    });
    ctx.dispatch(new GetCounts(false, false));
    return ctx.dispatch(new LvUpdateTags(res.tags));
  }
  @Action(SwitchViewMode)
  switchViewMode(ctx: StateContext<any>, action: SwitchViewMode) {
    const state = ctx.getState();
    const isMapMode = action.mode === "map" ?? !state.isMapMode;
    ctx.setState({
      ...state,
      currentAction: "switchViewMode",
      isFilterAction: false,
      isMapMode,
    });
  }
  @Action(ClearAllFilters)
  clearAllFilters(ctx: StateContext<any>, action: ClearAllFilters) {
    const state = ctx.getState();
    this._mapFilterService.setFilterObjects(state.initialMapGroups);
    let res = this._mapFilterService.getSearchFields(state.initialMapGroups, {}, {}, "must");
    ctx.setState({
      ...state,
      isFilterAction: true,
      currentAction: "clearAllFilters",
      searchFields: _.cloneDeep(res.searchFields),
      mapGroups: _.cloneDeep(state.initialMapGroups),
      tags: [],
      selectedLocations: {},
      selectedLocation: {},
      selectedUsers: {},
      selectedUser: null,
    });
    ctx.dispatch(new GetCounts(false, false));
    return ctx.dispatch(new LvUpdateTags([]));
  }
  @Action(ClearFilters)
  clearFilters(ctx: StateContext<any>, action: ClearFilters) {
    const state = ctx.getState();
    let selectedLocations, selectedLocation, selectedUsers, selectedUser, activeEntity;
    if (action.mgClass === "location") {
      selectedLocations = {};
      selectedLocation = null;
      activeEntity = state.activeEntity && state.activeEntity.nodeClass === "location" ? null : state.activeEntity;
      selectedUsers = { ...state.selectedUsers };
      selectedUser = { ...state.selectedUser };
    } else if (action.mgClass === "users") {
      selectedLocations = { ...state.selectedLocations };
      selectedLocation = { ...state.selectedLocation };
      selectedUsers = {};
      selectedUser = null;
    }
    let mapGroups = this._mapFilterService.clearFilters(state.mapGroups, action.mgName);
    let res = this._mapFilterService.getSearchFields(mapGroups, selectedUsers, selectedLocations, "must");
    ctx.setState({
      ...state,
      isFilterAction: true,
      currentAction: "clearFilters",
      clearGroup: action.mgClass,
      searchFields: _.cloneDeep(res.searchFields),
      tags: res.tags,
      mapGroups: _.cloneDeep(mapGroups),
      selectedLocations: { ...selectedLocations },
      selectedLocation: { ...selectedLocation },
      selectedUsers: { ...selectedUsers },
      selectedUser: { ...selectedUser },
      activeEntity: { ...activeEntity },
    });
    ctx.dispatch(new GetCounts(false, false));
    ctx.dispatch(new LvHidePanel("inspector"));
    return ctx.dispatch(new LvUpdateTags(res.tags));
  }
  @Action(StepBack)
  stepBack(ctx: StateContext<any>, action: StepBack) {
    const state = ctx.getState();
    const selectedIndex = state.stateIndex > 0 ? state.stateIndex - 1 : state.stateIndex;
    return this.setStateFromHistory(ctx, selectedIndex, "stepBack");
  }
  @Action(StepForward)
  stepForward(ctx: StateContext<any>, action: StepForward) {
    const state = ctx.getState();
    const selectedIndex = state.stateIndex < state.stateList.length - 1 ? state.stateIndex + 1 : state.stateIndex;
    return this.setStateFromHistory(ctx, selectedIndex, "stepForward");
  }
  private setStateFromHistory(ctx: StateContext<any>, selectedIndex, currentAction) {
    const state = ctx.getState();
    const selectedState = state.stateList[selectedIndex];
    ctx.setState({
      ...state,
      stateIndex: selectedIndex,
      currentAction: currentAction,
      tags: [...selectedState.tags],
      searchFields: _.cloneDeep(selectedState.searchFields),
      mapGroups: _.cloneDeep(selectedState.mapGroups),
      searchTerm: selectedState.searchTerm,
      nodeSearchTerm: selectedState.nodeSearchTerm,
      selectedLocations: { ...selectedState.selectedLocations },
      selectedLocation: { ...selectedState.selectedLocation },
      selectedUsers: { ...selectedState.selectedUsers },
      selectedUser: { ...selectedState.selectedUser },
      activeEntity: { ...selectedState.activeEntity },
    });
    setTimeout(() => {
      const nextState = ctx.getState();
      if (!_.size(selectedState.selectedUser) && !_.size(nextState.activeEntity)) {
        ctx.dispatch(new LvHidePanel("inspector"));
      } else {
        ctx.dispatch(new LvShowPanel("inspector", "setStateFromHistory"));
      }
    }, 100);
    ctx.dispatch(new GetCounts(false, true));
    return ctx.dispatch(new LvUpdateTags(selectedState.tags));
  }
  @Action(ResetMap)
  resetMap(ctx: StateContext<any>, action: ResetMap) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      stateIndex: 0,
      stateList: [],
      nodeSearchTerm: "",
      activeEntity: null,
      currentAction: "resetMap",
    });

    return ctx.dispatch(new ClearAllFilters());
  }
  @Action(SearchByTerm)
  searchByTerm(ctx: StateContext<any>, action: SearchByTerm) {
    const state = ctx.getState();
    let searchTerm = action.searchTerm === "openRightPanel" ? state.searchTerm : action.searchTerm;
    ctx.setState({
      ...state,
      isFilterAction: false,
      searchTerm: searchTerm,
      currentAction: "searchByTerm",
    });

    if (action.searchTerm !== "openRightPanel") {
      ctx.dispatch(new GetCounts(false, false));
    }
    return ctx.dispatch(new LvShowPanel("rightList", "searchByTerm"));
  }
  @Action(ToggleSortByType)
  toggleSortByType(ctx: StateContext<any>, action: ToggleSortByType) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      sortByType: !state.sortByType,
      currentAction: "toggleSortByType",
    });
  }
  @Action(ToggleCanvasInfoGraph)
  toggleCanvasInfoGraph(ctx: StateContext<any>, action: ToggleCanvasInfoGraph) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showInfoGraph: !state.showInfoGraph,
      currentAction: "toggleCanvasInfoGraph",
    });
  }
  @Action(ToggleLinks)
  toggleLinks(ctx: StateContext<any>, action: ToggleLinks) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showLinks: !state.showLinks,
      currentAction: "toggleLinks",
    });
  }
  @Action(GetCounts)
  getCounts(ctx: StateContext<any>, action: GetCounts) {
    const state = ctx.getState();

    const { body, finalSearchFields, params } = this.frameEsSearchQuery(ctx, action.isInit);

    if (!action.isStepAction) {
      this.pushHistoryStack(ctx, finalSearchFields, state.activeEntity);
    } else {
      // NOTE: This is needed so that same search params can be passed right-list-component
      ctx.setState({
        ...ctx.getState(),
        navigatorSearchQueries: finalSearchFields,
      });
    }
    return this._searchApiService.getCounts(params, body as any).pipe(
      tap((res) => {
        const locAssetCounts = {};

        res[0].hits[0].buckets.forEach((el) => {
          locAssetCounts[el.key] = el.value;
        });
        ctx.setState({
          ...ctx.getState(),
          locAssetCounts: { ...locAssetCounts },
          currentAction: "getCounts",
          totalAssets: action.isInit ? res[0].totalHits : state.totalAssets,
        });
      })
    );
  }
  private pushHistoryStack(ctx: StateContext<any>, finalSearchFields, activeEntity) {
    const state = ctx.getState();
    let stateList = state.stateList.slice();
    if (state.stateList.length - 1 > state.stateIndex) {
      // trim statelist if in middle of stepping back when new action occurs
      stateList = stateList.slice(0, state.stateIndex);
    }
    ctx.setState({
      ...ctx.getState(),
      stateList: [
        ...stateList,
        {
          tags: [...state.tags],
          searchFields: _.cloneDeep(state.searchFields),
          mapGroups: _.cloneDeep(state.mapGroups),
          searchTerm: state.searchTerm,
          nodeSearchTerm: state.nodeSearchTerm,
          selectedLocations: { ...state.selectedLocations },
          selectedLocation: { ...state.selectedLocation },
          selectedUsers: { ...state.selectedUsers },
          selectedUser: { ...state.selectedUser },
          activeEntity: { ...activeEntity },
        },
      ],
      stateIndex: stateList.length,
      navigatorSearchQueries: finalSearchFields,
    });
  }
  private frameEsSearchQuery(ctx: StateContext<any>, isInit = false) {
    const state = ctx.getState();

    const params = {
      aggField: "locationId",
      clfMappingType: "asset",
    };

    const searchFields = _.cloneDeep(state.searchFields);
    const assetSearchFields = searchFields.filter((s) => s.queryText === "Asset")[0];
    const usersSearchFields = searchFields.filter((s) => s.queryText === "Users")[0];
    const locationSearchFields = searchFields.filter((s) => s.queryText === "Location")[0];

    const locationIdsChildFields = locationSearchFields.childFilters.filter((c) => c.fieldName === "locationId");
    locationSearchFields.childFilters = locationSearchFields.childFilters.filter((c) => c.fieldName !== "locationId");

    assetSearchFields.childFilters = assetSearchFields.childFilters.concat(...(locationIdsChildFields || []));
    assetSearchFields.childFilters = assetSearchFields.childFilters.concat(...(usersSearchFields.childFilters || []));

    const finalSearchFields = [
      assetSearchFields,
      //locationSearchFields,
    ];

    let body: any = {
      scrollSize: "1000000",
      globalQueryText: state.searchTerm,
      searchQueries: finalSearchFields, // state.searchFields,
    };
    if (!isInit) {
      body.globalQueryText = state.searchTerm;
      body.searchQueries = finalSearchFields; // state.searchFields;
    }
    if (state.currentAction === "lvUserAction") {
      body = {
        ...body,
        topLeftLat: state.searchBounds.topLeftLat,
        topLeftLon: state.searchBounds.topLeftLon,
        bottomRightLat: state.searchBounds.bottomRightLat,
        bottomRightLon: state.searchBounds.bottomRightLon,
      };
    }

    return {
      params,
      body,
      finalSearchFields,
    };
  }

  @Action(FilterNodesByTerm)
  filterNodesByTerm(ctx: StateContext<any>, action: FilterNodesByTerm) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentAction: "filterNodesByTerm",
      nodeSearchTerm: action.nodeSearchTerm,
      searchTerm: action.nodeSearchTerm,
    });
    ctx.dispatch(new LvShowPanel("rightList", "filterNodesByTerm"));
    return ctx.dispatch(new GetCounts(false, false));
  }
  @Action(UpdateSearchBounds)
  updateSearchBounds(ctx: StateContext<any>, action: UpdateSearchBounds) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      currentAction: "updateSearchBounds",
      searchBounds: action.bounds,
    });
  }
}
