import { Deserializable } from "./deserializable";
// import { UtilsService } from '../services/utils.service';
import { Entity } from "./entity";

export class Bisector implements Deserializable {
  public id: number;
  uid: string;
  bisectorID: string;
  public rid: string;
  public name = "connection";
  public template: string;
  public description: string;
  public inVRid: string;
  public outVRid: string;
  public type = "link";
  public linkType: string;
  public graphType: string;
  public connectionType: string;
  public createdBy: string;
  public lastModified: string;
  public active: boolean;
  public hovered: boolean;
  public path: any;
  public source: number;
  public sourceType: string;
  public sourceID: string;
  public target: number;
  public targetID: string;
  public targetType: string;
  addedForRoute: boolean;
  inGroup: boolean;
  needsEdit = true;
  groupUID: string;
  x: number;
  x1: number;
  x2: number;
  y: number;
  dx: number;
  dx2: number;
  dy: number;
  dy2: number;
  fx: number;
  fx2: number;
  fy: number;
  fy2: number;
  midX: number;
  midY: number;
  time: string;
  value: any;
  properties: any;
  // constructor(private _utilsService: UtilsService) {}

  deserialize(input: any) {
    const link = this.convertEdge(input);
    //  console.log("link", link);
    Object.assign(this, link);
    return this;
  }

  convertEdge(edge: any) {
    const link = Object.assign({}, this);
    if (edge.properties) {
      link.name = edge.properties.name || link.name;
      if (edge.properties.type) {
        link.linkType = edge.properties.type.toLowerCase();
      }
      link.value = edge.properties.value;
      link.time = edge.properties.time;
    }
    link.properties = edge.properties;
    link.sourceID = edge.sourceID || edge.id1;
    link.targetID = edge.targetID || edge.id2;
    link.x = 0;
    link.y = 0;
    link.x1 = 0;
    link.x2 = 0;
    link.dx = 0;
    link.dx2 = 0;
    link.dy = 0;
    link.dy2 = 0;
    link.fx = 0;
    link.fx2 = 0;
    link.fy = 0;
    link.fy2 = 0;
    link.midX = 0;
    link.midY = 0;


    return link;
  }
}
