import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";
import { PropertyService } from '@cl/property/property.service';
import { environment } from '@cl/env';
import _ from 'lodash';
import { AppAuthService } from '@cl/authenticate/app-auth.service';
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor( private http: HttpClient, 
               private apiProvider: ApiProviderService,
               private _propertyService: PropertyService,
               public _authService: AppAuthService,
  ) { }

  getBaseUrl() {
    let baseUrl = this._propertyService.getConfigurationInBulkSettings('common.biworkbenchurl');
    if (_.isEmpty(baseUrl)) {
      baseUrl = (window.location.hostname === 'localhost') ? environment.localCluster.replace('graph', 'superset') : (window.location.origin).replace('web', 'superset');
    }
    return baseUrl;
  }

  getSafeUrl(url) {
    let token = this._authService.getAuthToken();
    let safe_url = '';
    let baseUrl = this.getBaseUrl();

    if (url.includes('/superset/')) {
      safe_url = _.replace(url, '/superset/', '/parkour_sso?token=' + token + '&redirect=/superset/');
    } else {
      safe_url = baseUrl + 'parkour_sso?token=' + token + '&redirect=/superset/' + url;
    }
    return safe_url;
  }

  getMlWorkBenchBaseUrl(){
    let baseUrl = this._propertyService.getConfigurationInBulkSettings('common.mlworkbenchurl');
    if (_.isEmpty(baseUrl)) {
      baseUrl = 'https://dbc-160bc53d-b222.cloud.databricks.com/';
    }
    return baseUrl;
  }

  getDashboardFilters() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/filter/category`);
  }

  getDashboardListData(payload:any) {
    if(!payload){
      payload = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/list`, payload);
  }

  getDashboardById(id) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/id/${id}`);
  }

  addDashboard(payload:any){
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/`, payload);
  }

  editDashboard(id, payload){
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/id/${id}`, payload);
  }

  deleteDashboardById(id){
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/id/${id}`);
  }

  setHomePageDashboard(id){
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/home/${id}`, '');
  }
  
  getHomePageDashboard() {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/1/dashboards/home/`);
  }
}
