import { ChangeDetectionStrategy, Component, HostBinding, Input, Output, ViewEncapsulation, EventEmitter } from "@angular/core";
import { AppNotification, NotificationResolutionStatus, NotificationStatus } from "@cl/models";
import { AlertsResolveDialogComponent } from '../../../../common/components/alerts-resolve-dialog/alerts-resolve-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'cl-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "app-notification-item grid px-2 py-1",
  }
})
export class NotificationItemComponent {
  readonly NotificationResolvedStatus = NotificationResolutionStatus;
  @Input() notification: AppNotification;
  @Output() onCloseNotificationPanel = new EventEmitter<any>();

  @HostBinding("class.font-bold")
 
  get notificationStatus() {
    return this.notification?.status === NotificationStatus.UnRead;
  }
  constructor(
    public dialog: MatDialog
  ){

  }
  openResolve(notification){
    const dialogRef = this.dialog.open(AlertsResolveDialogComponent, {
      width: '520px',
      data: notification,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(!_.isEmpty(result)){
        console.log(result);
        this.onCloseNotificationPanel.emit(result);
      }
    });
  }




}
