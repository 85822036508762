import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { NotificationService } from '../../../notification/notification.service';

@Component({
  selector: 'cl-alerts-resolve-dialog',
  templateUrl: './alerts-resolve-dialog.component.html',
  styleUrls: ['./alerts-resolve-dialog.component.scss']
})
export class AlertsResolveDialogComponent implements OnInit {

  NOT_AN_ISSUE : Boolean = false;
  resolveComment : string;
  selectedAlerts : any = [];
  resolveFormValid :boolean = false;
  
  constructor(
    private notificationService : NotificationService,
    public dialogRef: MatDialogRef<AlertsResolveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  isFormFieldChanged(event){
    if((this.NOT_AN_ISSUE && this.resolveComment) || this.resolveComment){
      this.resolveFormValid = true;
    } else {
      this.resolveFormValid = false;
    }
  }

  resolveAlerts () {
    let payload = [];
     payload.push(new Object({
      "contextId": this.data.contextId,
      "propName": this.data.propertyName,
      "objectId": this.data.objectId,
      "recipeId": this.data.recipeId,
      "userActions": Object.assign({}, {}, {'NOT_AN_ISSUE' : this.NOT_AN_ISSUE}, {'notes': this.resolveComment }),
    }));
    this.notificationService.resolveAlerts(payload).subscribe((res: any) => {
      if(typeof res == 'string'){
        if(res.toLowerCase() == 'ok'){
          this.dialogRef.close(this.data);
        }}
    }, (error) => {

    })
  }



}
