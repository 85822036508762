<div class="user-summary-panel">
    <cl-side-panel (closePanel) = "closePanel()">
        <section class="cl-side-panel-title side-panel-header-bg">
            <span *ngIf="mode === 'view'" class="black font-bold">User Summary</span>
            <span *ngIf="mode === 'edit'" class="black font-bold">Edit User</span>
            <span *ngIf="mode === 'add'" class="black font-bold">Add User</span>
            <span class="close-icon" (click)="closePanel()">
                <i class="fa fa-times-circle-o mr-4"></i>
            </span>
        </section>
        <section class="cl-side-panel-content" *ngIf="mode === 'view'">
            <div class="section-label">User Details</div>
            <div class="user-detail">
                <div class="user-detail__item">
                    <div class="user-detail__item__label">User ID</div>
                    <div class="user-detail__item__value">{{userObject?.userId}}</div>
                </div>
                <div class="user-detail__item" *ngIf="userType==='NORMAL_USER'">
                    <div class="user-detail__item__label">Name</div>
                    <div class="user-detail__item__value">{{userObject?.name}}</div>
                </div>
                <div class="user-detail__item" *ngIf="userType==='APP_API_USER'">
                  <div class="user-detail__item__label">Name</div>
                  <div class="user-detail__item__value">{{userObject?.name}}</div>
              </div>
                <div class="user-detail__item" *ngIf="userType==='NORMAL_USER'">
                    <div class="user-detail__item__label">Primary Email</div>
                    <div class="user-detail__item__value">{{userObject?.email}}</div>
                </div>
                <div class="user-detail__item" *ngIf="userType==='NORMAL_USER'">
                  <div class="user-detail__item__label">Phone</div>
                  <div class="user-detail__item__value" *ngIf="userObject?.phoneNumber">{{userObject?.countryCode + '' +userObject?.phoneNumber}}</div>
              </div>
                <div class="user-detail__item" *ngIf="userType==='NORMAL_USER'">
                  <div class="user-detail__item__label">Manager Name</div>
                  <div class="user-detail__item__value">{{userObject?.userObject?.properties?.managerName}}</div>
              </div>
              <div class="user-detail__item" *ngIf="userType==='NORMAL_USER'">
                  <div class="user-detail__item__label">Manager Email</div>
                  <div class="user-detail__item__value">{{userObject?.userObject?.properties?.managerEmail}}</div>
              </div>
            </div>

            <div class="user-roles" *ngIf="userObject?.roles">
                <div class="section-label">Roles</div>
                <form [formGroup]="userDetailsForm" class="m-4">
                  <div formArrayName="roles">
                    <div  class="user-roles__item" *ngFor="let role of userRoleArray; let i=index">
                      <mat-checkbox [formControlName]="i" (change)="tiggerChangeDetection()" [disabled]="true">
                          <label class="ml10 user-roles__item__label label-overflow ellipsis">{{role}}</label>
                      </mat-checkbox>
                    </div>
                  </div>
                </form>
            </div>
        </section>
        <ng-template #normalUser>
          <form [formGroup]="editUserForm" (submit)="saveUser()">
            <div class="user-detail">
                <div class="user-detail__item">
                    <!-- <div class="form-label"><span class="required">*</span>User Type</div>
                    <mat-radio-group class="foz12 capitalize" aria-label="Select an option" formControlName="category">
                        <mat-radio-button *ngFor="let category of userTypeArray" [value]="category.id">{{category.name}}</mat-radio-button>
                    </mat-radio-group> -->

                    <div class="form-label">User ID<span class="required">*</span></div>
                    <input type="text" class="basic-input" [ngClass]="mode === 'edit'?'disabled': ''" placeholder="User id" formControlName="id" [readonly]="mode === 'edit'?true:false">
                    <label class="invisible">&nbsp</label>
                </div>

                <!--<div class="user-detail__item">
                    <div class="form-label">Username</div>
                    <input type="text" class="basic-input"
                    placeholder="Username" formControlName="email">
                </div>-->

                <div class="user-detail__item">
                    <div class="form-label">Name<span class="required">*</span></div>
                    <input type="text" class="basic-input"
                    placeholder="Display name for this user" formControlName="name">
                    <label class="invisible">&nbsp</label>
                </div>

                <div class="user-detail__item">
                    <div class="form-label">Primary Email<span class="required">*</span></div>
                    <input type="email" class="basic-input"
                    placeholder="Primary email" formControlName="email">
                    <label class="red"
                    [ngClass]="(editUserForm.controls['email'].invalid && (editUserForm.controls['email'].dirty || editUserForm.controls['email'].touched)) ? 'visible' : 'invisible'">
                     Please enter valid email!
                    </label>
                </div>

                <div class="user-detail__item">
                  <div class="form-label">Phone</div>
                  <div class="phone-number-container">
                    <div class="country-code">
                        <input type="text" class="basic-input" (focus)="openDropDone=true" (click)="openDDDiv=true; openDropDone=true;" placeholder="Code" formControlName="countryCode">
                        <i class="fa fa-solid fa-caret-down dropdown-icon"></i>
                        <ul class="type-ahead-suggestions dropdown-menu" [ngClass]="{open: openDropDone}">
                          <li class="type-ahead-suggestions__item" (click)="setCountryCode(code); openDDDiv=true;" *ngFor="let code of filteredCodeList">{{code.name+'('+code.Iso+')'}}</li>
                        </ul>
                    </div>
                    <div class="phone-number">
                        <input type="tel" class="basic-input" placeholder="Phone number" formControlName="phoneNumber">
                    </div>
                    </div>
                  <label class="red"
                    [ngClass]="((editUserForm.controls['phoneNumber'].invalid && (editUserForm.controls['phoneNumber'].dirty || editUserForm.controls['phoneNumber'].touched )) || (editUserForm.controls['countryCode'].value !== '' && editUserForm.controls['phoneNumber'].value ==='')) ? 'visible' : 'invisible'">
                    Please enter valid mobile number!
                  </label>
              </div>

                <div class="user-detail__item">
                  <div class="form-label">Manager Name</div>
                  <input type="text" class="basic-input"
                  placeholder="Manager name" formControlName="managerName">
                  <label class="red"
                  [ngClass]="(editUserForm.controls['managerName'].invalid && (editUserForm.controls['managerName'].dirty || editUserForm.controls['managerName'].touched)) ? 'visible' : 'invisible'">
                  Please enter valid manager name!
                  </label>
              </div>

              <div class="user-detail__item">
                  <div class="form-label">Manager Email</div>
                  <input type="email" class="basic-input"
                  placeholder="Manager email" formControlName="managerEmail">
                  <label class="red"
                  [ngClass]="(editUserForm.controls['managerEmail'].invalid && (editUserForm.controls['managerEmail'].dirty || editUserForm.controls['managerEmail'].touched)) ? 'visible' : 'invisible'">
                  Please enter valid email!
                  </label>
              </div>
                <div class="user-detail__item">
                    <div class="form-label">Enabled<span class="required">*</span></div>
                    <mat-radio-group class="foz12 capitalize" aria-label="Select an option" formControlName="enabled">
                        <mat-radio-button  [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="user-detail__item">
                  <div class="form-label">UI Profile<span class="required">*</span></div>
                  <mat-select formControlName="userProfile" [value]="userDefaultProfile" (selectionChange)="getSelectedProfileDetail($event.value)">
                    <mat-option *ngFor="let profile of profileLists;" [value]="profile.id">{{profile.profileName}}</mat-option>
                  </mat-select>
              </div>

                <div class="user-detail__item">
                    <div class="form-label">Time Zone<span class="required">*</span></div>
                    <mat-select formControlName="timeZone">
                      <input type="text" class="dropdown-input" placeholer="Search" (input)="filterOptions($event)" />
                      <mat-option *ngFor="let tz of timeZonesValues;" [value]="tz">{{tz}}</mat-option>
                    </mat-select>
                </div>
                <div class="user-detail__item checkbox" formArrayName="rolesList">
                    <div class="form-label">Roles<span class="required">*</span>:</div>
                    <div  class="user-roles__item" *ngFor="let role of userRoleList; let i=index">
                      <mat-checkbox [formControlName]="i" (change)="tiggerChangeDetection()">
                          <label class="ml10 user-roles__item__label ellipsis label-overflow" [title]="role.name">{{role.name}}</label>
                      </mat-checkbox>
                    </div>
                </div>
                <div class="user-detail__item checkbox" formArrayName="notificationChannelsArray">
                    <div class="form-label">Notification Channels</div>
                    <div  class="user-notification" *ngFor="let notification of notificationChannels; let i=index">
                        <div class="noyification-item" [formGroupName]="i">
                            <div class="form-label">{{notification.groupName}}:</div>
                            <div class="notification-checkbox">
                                <mat-checkbox formControlName="email" (change)="tiggerChangeDetection()">
                                    <label class="ml10 user-notification__label">Email</label>
                                </mat-checkbox>
                                <mat-checkbox formControlName="sms" (change)="tiggerChangeDetection()">
                                    <label class="ml10 user-notification__label">SMS</label>
                                </mat-checkbox>
                                <mat-checkbox formControlName="web" (change)="tiggerChangeDetection()">
                                    <label class="ml10 user-notification__label">In App</label>
                                </mat-checkbox>
                            </div>
                        </div>
                </div>
                </div>
            </div>
          </form>
        </ng-template>
        <ng-template #apiUser>
          <form [formGroup]="apiUserForm" (submit)="saveUser()">
            <div class="user-detail">
                <div class="user-detail__item">
                    <div class="form-label">Name<span class="required">*</span></div>
                    <input type="text" class="basic-input" placeholder="Name" formControlName="name">
                </div>

                <div class="user-detail__item checkbox" formArrayName="apiRolesList">
                    <div class="form-label">Roles<span class="required">*</span>:</div>
                    <div  class="user-roles__item" *ngFor="let role of userRoleList; let i=index">
                            <mat-checkbox [formControlName]="i" (change)="tiggerChangeDetection()">
                                <label class="ml10 user-roles__item__label">{{role.name}}</label>
                            </mat-checkbox>
                    </div>
                </div>
                <!-- <div class="user-detail__item">
                    <div class="form-label">Enabled:</div>
                    <mat-radio-group class="foz12 capitalize" aria-label="Select an option" formControlName="enabled">
                        <mat-radio-button  [value]="true">Yes</mat-radio-button>
                        <mat-radio-button [value]="false">No</mat-radio-button>
                    </mat-radio-group>
                </div> -->
            </div>
          </form>
        </ng-template>

        <section class="cl-side-panel-content" *ngIf="mode === 'edit' && userType==='NORMAL_USER'">
          <ng-container>
            <ng-template *ngTemplateOutlet="normalUser;">
            </ng-template>
          </ng-container>
        </section>
        <section class="cl-side-panel-content" *ngIf="mode === 'edit' && userType==='APP_API_USER'">
          <ng-container>
            <ng-template *ngTemplateOutlet="apiUser;">
            </ng-template>
          </ng-container>
        </section>

        <section class="cl-side-panel-content" *ngIf="mode === 'add'">
          <mat-tab-group  (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Regular User">
              <ng-container >
                <ng-template *ngTemplateOutlet="normalUser;">
                </ng-template>
              </ng-container>
            </mat-tab>
            <mat-tab label="API User">
              <ng-container >
                <ng-template *ngTemplateOutlet="apiUser;">
                </ng-template>
              </ng-container>
            </mat-tab>
          </mat-tab-group>
        </section>

        <section class="cl-side-panel-footer bgcolor33" *ngIf="mode === 'edit' || mode === 'add'">
            <div class="flex">
                <div class="flex-col grow fs11 text-right" >
                    <a class="panel-button m-2" (click)="closePanel()">Cancel</a>
                    <button *ngIf="userType==='NORMAL_USER'" class="panel-button white bgcolor4" [disabled]="isSaveInProgress || !editUserForm.valid || !checkIfRoleSelected() || ((editUserForm.controls['countryCode'].value !== '' && editUserForm.controls['phoneNumber'].value ===''))" (click)="saveUser()">{{(mode === 'edit'?'Update':'Save')}}
                        <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
                    </button>
                    <button *ngIf="userType==='APP_API_USER'" class="panel-button white bgcolor4" [disabled]="isSaveInProgress || !apiUserForm.valid || !checkIfRoleSelected()" (click)="saveUser()">{{(mode === 'edit'?'Update':'Save')}}
                      <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isSaveInProgress"></i>
                  </button>
                </div>
            </div>
        </section>
    </cl-side-panel>
</div>

