<div class="mt-2 mb-2">
    <span class="page-title">Admin - Policies</span>
</div>
<cl-table-surtitle [rows]="rows.length" [selected]="selected.length" [total]="totalRowCount" [pageName]="'Policies'"></cl-table-surtitle>
<div class="wt-ngx-grid-wrapper relative list-page px-2">
    <div class="flex justify-between w-calc-60 pt-1 pb-1">
        <div class="md-add-button list" [clIsValidAction]="actionBtnPolicies.add" *ngIf="!showSummaryPanel">
            <div class="add-button" (click)="addPolicy()">
                <span class="fa fa-plus"></span>
            </div>
        </div>
        <div class="action-panel-left self-start flex items-center">
            <span (click)="toggleFilterPanel()" class="cursor-pointer list-page_filter-toggle color4 flex items-center">
              <i class="fa fa-sliders mr-2 ml-3 font-18-normal" aria-hidden="true"></i>
              filter options
            </span>
            <cl-list-search [placeholder]="'Search by Policy Name'" (search)="updateFilter($event)"
                [columns] = "['Policy Name']"></cl-list-search>
            <button [disabled]="selected.length != 1 || selected[0].policyType == 'System Policy'"
                [clIsValidAction]="actionBtnPolicies.edit"
                [ngClass]='[selected.length != 1 || selected[0].policyType == "System Policy" ? "color37 bcolor36 bgcolor38" : "color4 bcolor4 bgcolor32"]'
                class="p-4-10 border-general  br2 mr-2" (click)="editPolicy()">
                <i class="fa fa-pencil"></i>
                Edit
            </button>
            <button [disabled]="selected.length != 1 || selected[0].policyType == 'System Policy'"
                [clIsValidAction]="actionBtnPolicies.delete"
                [ngClass]='[selected.length != 1 || selected[0].policyType == "System Policy" ? "color37 bcolor36 bgcolor38" : "color4 bcolor4 bgcolor32"]'
                class="p-4-10 border-general br2 mr-2" (click)="deletePolicy()">
                <i class="fa fa-trash"></i>
                Delete
                <span class="fa fa-circle-o-notch fa-pulse" *ngIf="isLoading"></span>
            </button>
        </div>
        <div class="user__action-panel-right action-panel-right self-end flex items-center mr10 mb10">
            <cl-export-panel (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)"
            [page]="'policy'">
            </cl-grid-column-toggle>
        </div>
    </div>

    <div class="h-calc-40">
        <main class="flex flex-row h-100 overflow-y-hidden"  [style.width.px]="mainWidth - 10">
            <div class="grow-0 nav panel scroll-y" *ngIf="showFilterPanel">
                <cl-list-filter class="policy__listing__filter" [hideTitle]="true" [showCounts]="false" [title]="''"
                    heightClass="none" (panelsEmitter)="getPanelUpdate($event)"
                    (toggleFilterPanel)="toggleFilterPanel()" [filters]="filters" [actionCount]="actionCount">
                </cl-list-filter>
            </div>
            <div class="grid-container grow relative">
                <div *ngIf="isLoading">
                    <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>

                <div class="h-100" #tableWrapper [style.width.px]="tableWidth" *ngIf="isShowTable">
                    <ngx-datatable  class="h-calc-40" [rows]="rows" (reorder) = "columnReordered($event)" [columns]="columns" [columnMode]="'force'"
                        [loadingIndicator]="isLoading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                        [scrollbarV]="true" [selected]="selected" [rowIdentity]="getId" [sortType]="sortType"
                        [selectionType]="selection" [selectAllRowsOnPage]="false" (select)='onSelect($event)'[reorderable]="false" #policyTable>
                    </ngx-datatable>
                </div>

                <ng-template #policyNameTemplate let-row="row" let-value="value" let-i="index">
                    <a class="font-12-bold" href (click)="loadSummaryPanel(row, $event)">{{value}}</a>
                </ng-template>
            </div>
        </main>

    </div>


    <div class="policy-list summery-pannel" *ngIf="showSummaryPanel == true">
        <cl-policy-side-panel [policyObject]="SelectedPolicy" [mode]="mode" (closeSidePanel)="closeSidePanel($event)">
        </cl-policy-side-panel>
    </div>
</div>
