import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { UserService } from '../../user.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { TenantService } from '../../../tenant/tenant.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { PersistenceService } from '@cl/common/services/persistence.service';

@Component({
  selector: 'cl-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: ['./user-role-list.component.scss']
})
export class UserRoleListComponent implements OnInit, AfterViewInit {
  @ViewChild('userRoleNameTemplate') userRoleNameTemplate: TemplateRef<any>;
  @ViewChild('table') table;

  public tableRowData: any = [];
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  isShareLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  private event = new Subject<any>();
  showSummaryPanel = false;
  editDisabled = true;
  shareDisabled = true;
  roleObject:any ={};
  mode = 'add';
  selectedRowArray = [];
  columnKeys: string[] = [];
  selectAll: boolean = false;
  actionBtnPolicies = {
    add: ['ROLE_CREATE'],
    edit: ['ROLE_UPDATE'],
    delete: ['ROLE_DELETE']
  }
  showTenants: boolean = false;
  tenants = [];
  totalRowCount = 0;
  private tenantsCopy = [];
  initialLoad: boolean = true;
  tenantId = this.userService.getEffectiveTenantId();
  constructor(
    private userService: UserService,
    private el: ElementRef,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private tenantService: TenantService,
    private formateDatePipe: FormateDatePipe,
    private persistenceStore : PersistenceService
  ) {
  }

  ngOnInit(): void {
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'summary') {
        this.mode = 'view';
        this.showSummaryPanel = true;
        this.roleObject = evt.roleObject;
      }
    });
    this.subscriptions.push(eventSub);
  }

  ngAfterViewInit(): void {
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        //headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true
      },
      { name: 'Role Name', prop: 'name', width: 180, cellTemplate: this.userRoleNameTemplate, visible: true },
      { name: 'Policies', prop: 'policyHtml', width: 250, visible: true },
      { name: 'Shared', prop: 'shared', width: 50, visible: true },
      { name: 'Created At', prop: 'createdAtFormatted', width: 50, visible: true },
      { name: 'Created By', prop: 'createdBy', width: 150, visible: true },
      { name: 'Modified At', prop: 'modifiedAtFormatted', width: 50, visible: true },
      { name: 'Modified By', prop: 'modifiedBy', width: 150, visible: true }
    ];
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('role', [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
    this.columnKeys.forEach((keys:any) => {
      if(keys.prop == 'policyHtml'){
        keys.prop = 'policiesFormatted';
      }
    });
    this.getUserRoles();
  }
  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }
  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  private getUserRoles(): void {
    this.isLoading = true;
    let roleData:any = [];
    const userRoleDataSubscription: Subscription = this.userService.postUserRoles(null).subscribe((data:any)=>{
      data.forEach((d:any) => {
        let policies = '';
        d.createdAtFormatted = d.createdAt ? this.formateDatePipe.transform(d.createdAt) : '';
        d.modifiedAtFormatted = d.modifiedAt ? this.formateDatePipe.transform(d.modifiedAt) : '';
        d.policiesFormatted = _.map(d.policies, 'name').join(';');
        d.shared = d.roleProperties?.roleScope === 'SHARED' ? 'Yes' : 'No';
        if(d.policies && d.policies.length>0){
          if(d.policies.length === 1){
            policies = d.policies[0].name;
          }else if(d.policies.length === 2){
            policies = d.policies[0].name+', ' + d.policies[1].name;
          }else if(d.policies.length > 2){
            let policyArray:any = [];
            d.policies.forEach((p:any) => {
              policyArray.push(p.name);
            });
            policies = policyArray[0]+', ' + policyArray[1] + ', & <span class="member-others" title="'+ policyArray.join(', ')+'">'+(d.policies.length-2)+' others</span>';
          }
        }
        d.policyHtml = policies;
        roleData.push(d);
      });
      this.rows =[...roleData];
      if(this.initialLoad == true){
        this.totalRowCount = this.rows.length;
        this.initialLoad = false;
      }
       this.tableRowData = [...this.rows];
       this.checkForSlectedRows();
      this.isLoading = false;

    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(userRoleDataSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  checkForSlectedRows() {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...this.selectedRowArray);
   }

  onSelect(rowData: any) {
    this.selectedRowArray = rowData.selected;
    if(this.selectedRowArray.length != 1){
      this.showSummaryPanel = false;
    }
    if(this.selectedRowArray.length == 1 && this.selectedRowArray[0].editable){
      this.editDisabled = false;
    }else{
      this.editDisabled = true;
    }

    let shareAccessData = this.shareRoleAccessCheck(this.selectedRowArray);

    if(this.selectedRowArray.length == 1 && this.selectedRowArray[0].shared === 'Yes') {
      this.shareDisabled = false;
    } else {
      this.shareDisabled = true;
    }
    this.roleObject = this.selectedRowArray[0];
   // this.checkForSlectedRows();
  }

  shareRoleAccessCheck(selectedRows) {
    return selectedRows.filter(t=>this.tenantId === t.tenantId);
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'User-roles-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    const val = term.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.tableRowData];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(val) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.rows = temp;
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'role', this.table._internalColumns);   
  }

  closeSidePanel(){
    this.showSummaryPanel = false;
    this.selectedRowArray = [];
    this.editDisabled = true;
    this.shareDisabled = true;
    this.getUserRoles();
  }

  addRole(){
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  editRole(){
    this.mode = 'edit';
    this.showSummaryPanel = true;
  }

  getId(row) {
    return row.id;
  }

  deleteRole(){
    this.isLoading = true;
    let message = `Are you sure you want to delete this role?: `+this.selectedRowArray[0].name;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
       const deleteSub:Subscription = this.userService.deleteItemFromList('role',this.selectedRowArray[0].id).subscribe(data=>{
        this.getUserRoles();
          let action = 'Role deleted successfully';
          this.openSnackBar(action, this.selectedRowArray[0].name);
          this.selected = [];
          this.selectedRowArray = [];
          this.isLoading = false;
        }, (error) => {
            console.error(error);
            let action = 'Something went wrong';
           this.openSnackBar(action, this.selectedRowArray[0].name);
           this.isLoading = false;
          });

          this.subscriptions.push(deleteSub);
      }else{
        this.isLoading = false;
      }
    });
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  loadSummaryPanel(roleObject:any, event:any){
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', roleObject: roleObject });
  }

  toggleContainer() {
    this.showTenants = !this.showTenants;
    if (this.showTenants) {
      this.getTenants();
    }
  }

  private getTenants() {
    this.tenantService.sharableTenants(this.tenantId).subscribe((data: any) => {
      // this.tenantService.getAllTenants().subscribe((data:any) => {
        console.log(this.roleObject);
      data.forEach((objData) => {
        objData.checked = this.roleObject.sharedTenants?.find(obj =>  obj === objData.tenantId);
      });
      this.tenants = data;
      this.tenantsCopy = _.cloneDeep(this.tenants);
    })
  }

  searchTenancies(event) {
    const val = event.target.value.toLowerCase().trim();
    if(val) {
      this.tenants = this.tenantsCopy.filter(t => t.tenantName.toLowerCase().indexOf(val) > - 1 )
    } else {
      this.tenants = this.tenantsCopy;
      this.selectAll = false;
    }
  }

  selectSome() {
    return this.tenants.filter(t => t.checked).length > 0 && !this.selectAll;
  }

  checkAll(selectAll: boolean) {
    this.selectAll = selectAll;
    if (this.tenants == null) {
      return;
    }
    this.tenants.forEach(t => (t.checked = selectAll));
  }

  updateAllComplete() {
    this.selectAll = this.tenants != null && this.tenants.every(t => t.checked);
  }

  applyRules() {
    this.isShareLoading = true;
    let tenantIds = [];
    this.tenants.forEach(t => {
      if(t.checked){
        tenantIds.push(t.tenantId);
      }
    });

    let payload = { sharedRoles:this.selectedRowArray.reduce((obj,item)=>
                    Object.assign(obj, { [item.id]: tenantIds }), {})
                  }
    this.userService.shareRoles(payload).subscribe(data => {
      let action = 'Sharing of roles has been updated successfully.';
      this.openSnackBar(action, '');
      this.isShareLoading = false;
      this.showTenants = !this.showTenants;
      this.getUserRoles();
    }, error =>{
      console.error(error);
      let action = 'Something went wrong';
      this.openSnackBar(action, '');
      this.isShareLoading = false;
    })

  }
}


