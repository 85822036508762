import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router} from '@angular/router';
import { GatewayStatus } from '@cl/@enums/gateway-status.enum';
import { Gateway } from '@cl/@types/gateway.type';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { TimesincePipe } from '@cl/common/pipes/timesince.pipe';
import { GraphAPIService } from '@cl/common/services/graph-api.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { PropertyService } from '@cl/property/property.service';
import * as _ from 'lodash';
import { GatewayUtilService } from '../gateway-util.service';

@Component({
  selector: 'cl-gateway-details',
  templateUrl: './gateway-details.component.html',
  styleUrls: ['./gateway-details.component.scss']
})
export class GatewayDetailsComponent implements OnInit {
  gatewayObject: any;
  refreshInterval: any;
  timeout: any;
  bindIcon = "fa-link";
  bindAction = "bind";
  bindDisabled: boolean;
  sidePanelsObj: any = {
    bind: false
  };
  gatewayIcon: any;
  breadCrumbItems: BreadCrumbItem[];
  mapOptions: any = {
    gestureHandling: 'cooperative',
    styles: [
      {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
        ]
      }
    ],
    positionOfActions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    }
  };
  overallStatusClass: string;
  gatewayHealthTimer: any;
  lastReported: Date;
  lastReportedStr: string;
  actionBtnPolicies = {
    bind: ['RECEIVER_UPDATE'],
    unbind: ['RECEIVER_DELETE']
  }

  constructor(private propertyService: PropertyService,
    private route: ActivatedRoute,
    private router: Router,
    private gatewayUtilService: GatewayUtilService,
    private _utils: UtilsService,
    private dialog: MatDialog,
    private _graphAPIService: GraphAPIService,
    private _snackBar: MatSnackBar,
    private timesincePipe: TimesincePipe
    ) {
    this.breadCrumbItems = [
      {'name': 'Previous', isBack: true, path: null},
      {'name': 'Gateway Details', path: null}
    ]
    this.gatewayIcon = {
      url: 'assets/svgs/organization.svg'
    }
    this.refreshInterval = this.propertyService.getSetting('ui.receiver.detail.refreshInterval');
    this.refreshInterval = 1000 * this.refreshInterval;
    this.gatewayObject = { id : null, name : '', status : GatewayStatus.UNPROVISIONED, tenantId : null, tenantName : null, deviceType : null, type : null, isPoib: false, entityTypeKey: null};
    this.getGatewayId();
  }
  private getGatewayId() {
    this.route.paramMap.subscribe((params) => {
      this.gatewayObject.id = params.get('id');
    });
  }
  private getGatewayDetails(){
    this.route.paramMap.subscribe((params) => {
      this.gatewayObject.entityTypeKey = params.get('entityTypeKey');
    });
    this.gatewayUtilService.getGatewayObject(this.gatewayObject.id, this.gatewayObject.entityTypeKey)
    .then((result: Gateway) => {
      this.gatewayObject = result;
      let healthStatus: any = this.gatewayObject.healthStatus.overallStatus;
      if (this.gatewayHealthTimer) {
        clearInterval(this.gatewayHealthTimer);
      }
      this.gatewayHealthTimer = setInterval(() => {
        let uptime = this.gatewayObject.healthStatus.systemUptime;
        if (uptime) {
          uptime = uptime + 1000;
          this.lastReportedStr = this.timesincePipe.transform(uptime);
        }
      }, 1000);
      if (healthStatus === 'GREEN') {
        this.overallStatusClass = 'fa-check-circle color20';
      } else if (healthStatus === 'YELLOW') {
        this.overallStatusClass = 'fa-exclamation-circle color22';
      } else {
        this.overallStatusClass = 'fa-exclamation-circle color21';
      }
      if (this.gatewayObject.status === 'PROVISIONED') {
        this.bindAction = "Unbind";
        this.bindIcon = "fa-unlink";
      } else {
        this.bindAction = "Bind";
        this.bindIcon = "fa-link";
      }
      this.scheduleGetStatuses();
    });
  }
  ngOnInit(): void {
    this.getGatewayDetails();
  }

  scheduleGetStatuses() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.getGatewayDetails()
    }, this.refreshInterval);
  }

  closeSidePanel(currentAction?: string) {
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    if (currentAction === 'bindSuccess') {
      this.getGatewayDetails();
    }
  }
  bindOrUnbind() {
    if (this.bindAction === "Unbind") {
      this.unbindSelected();
    } else {
      this.sidePanelsObj['bind'] = true;
    }
  }

  unbindSelected() {
    const selectedGateway = this.gatewayObject;
    let message = `Are you sure you want to unbind this Gateway?: ` + selectedGateway?.name;
    // }

    const dialogData = new ConfirmDialogModel("", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let currentIcon = this.bindIcon;
        this.bindIcon = 'fa-circle-o-notch fa-pulse';
        this.bindDisabled = true;
        this._graphAPIService.unbindGateway(this.gatewayObject.id).subscribe({
          next: (res) => {
            let selectedId = this.gatewayObject.id;
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
            if (res['id'] === selectedId) {
              this.openSnackBar("Unbind Gateway", res.properties.name);
              // this.getGatewayDetails();
              this.router.navigate(["/gateways"]);
            } else {
              this._utils.showMessage("Failed to unbind Gateway");
            }
          },
          error: (err) => {
            console.error(err);
            this._utils.showMessage("Failed to unbind Gateway");
            this.bindIcon = currentIcon;
            this.bindDisabled = true;
          }
        });
      }
    });
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }
}
