import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CL_API_DELAY, CL_API_DELAY_SHORT, CL_INPUT_DEBOUNCE_TIME } from "../common/constants/index";
import { BulkUserOperationPayload, GraphUser, IUserState, UserCategory, UserSearchApiResponse } from "@cl/models";
import {
  AppGetNotifications,
  HidePanel,
  UsAddUser,
  UsAddUserBulk,
  UsCancelEdit,
  UsClearAllSelected,
  UsDeleteUsers,
  UsDownloadUsers,
  UsEditSelected,
  UsGetCurrentUser,
  UsGetCurrentUserFromLocal,
  UsGetElasticUsers,
  UsGetTimeZones,
  UsHidePanel,
  UsInit,
  UsInitUserProperties,
  UsListScrolled,
  UsResetPassword,
  UsSearchUser,
  UsSelectAll,
  UsSelectUser,
  UsSetColumnSort,
  UsShowPanel,
  UsToggleAddUser,
  UsToggleSingleUser,
  UsToggleUser,
  UsUpdateUser,
} from "@cl/ngxs/actions";
import { Action, Actions, ofActionSuccessful, Selector, State, StateContext, Store } from "@ngxs/store";
import * as _ from "lodash";
import { combineLatest, EMPTY, of, throwError, timer } from "rxjs";
import { catchError, debounceTime, delay, finalize, map, switchMap, tap } from "rxjs/operators";
import { UserApiService } from "../common/services/user-api.service";
import { UtilsService } from "../common/utils/utils.service";
import { ClientConfigState } from "./client-config.state";


const DEFAULT_USER = {
  type: "NORMAL_USER",
  enabled: "true",
  tenantId: "",
  roles: ["805674e3-2343-4275-af74-947ab8a62468"],
  id: "",
  name: "",
  userDevices: [
    {
      deviceIdentifier: "", // email
      transport: "email",
    },
  ],
  timezone: "America/Chicago",
  notificationChannels: {
    Conditions: {
      email: true,
      sms: true,
      web: true,
    },
    "Device Health": {
      email: true,
      sms: true,
      web: true,
    },
    "Presence And Movement": {
      email: false,
      sms: false,
      web: false,
    },
    "Time Delays": {
      email: false,
      sms: false,
      web: false,
    },
    Inventory: {
      email: false,
      sms: false,
      web: false,
    },
  },
};

const DEFAULT_USER_STATE: IUserState = {
  users: [],
  currentUser: {} as any,
  timeZones: [],
  allUsersList: [],
  activeUser: null,
  nodeToAdd: {
    name: "",
    id: "",
    type: "",
  },
  selectedItems: {},
  addingUser: false,
  editingUser: false,
  currentAction: "",
  headerTitle: "Add User",
  userAdderOffset: 0,
  singleUserSelected: true,
  allLoaded: false,
  loading: false,
  deleteInProgress: false,
  passwordResetInProgress: false,
  saveOrUpdateInProgress: false,
  scrollId: "",
  totalUsers: 0,
  loadedUsers: 0,
  searchTerm: "",
  bulkDeletionResponse: null,
  userProperties: {},
};

@State<IUserState>({
  name: "user_state",
  defaults: DEFAULT_USER_STATE,
})
@Injectable()
export class UserState {
  @Selector()
  static currentUser(_state: IUserState) {
    return _state.currentUser;
  }

  @Selector()
  static userProperties(_state: IUserState) {
    return _state.userProperties;
  }

  constructor(private store: Store, private actions$: Actions, private _userApi: UserApiService, private _utils: UtilsService) {
    this.hookUserListReload();
    this.hookUserAddedClose();
  }

  private hookUserListReload() {
    this.actions$
      .pipe(
        ofActionSuccessful(
          UsInit,
          // SetTenantId,
          UsAddUser,
          UsAddUserBulk,
          UsUpdateUser,
          UsResetPassword,
          UsDeleteUsers,
          UsListScrolled,
          UsSearchUser
        ),

        catchError(() => EMPTY),

        debounceTime(CL_INPUT_DEBOUNCE_TIME),

        switchMap((action) => {
          const reload = !(action instanceof UsListScrolled);

          return this.store.dispatch(new UsGetElasticUsers(reload));
        }),

        map((state) => state.user_state)
      )
      .subscribe();
  }

  private hookUserAddedClose() {
    this.actions$
      .pipe(ofActionSuccessful(UsUpdateUser, UsAddUser, UsAddUserBulk))
      .subscribe((res) => this.store.dispatch([new HidePanel("editor"), new UsCancelEdit()]));
  }

  @Action(UsInit)
  usInit({ setState, getState }: StateContext<IUserState>, action: UsInit) {
    const state = getState();

    setState({
      ...DEFAULT_USER_STATE,
      currentAction: "usInit",
      activeUser: state.activeUser,
      currentUser: state.currentUser,
      userProperties: state.userProperties,
      userAdderOffset: state.userAdderOffset,
    });
  }

  @Action(UsInitUserProperties)
  usInitUserProperties({ getState, patchState }: StateContext<IUserState>, action: UsInitUserProperties) {
    return this._userApi.getUserProperties().pipe(
      catchError((err) => of({})),

      tap((res) => {
        patchState({
          currentAction: "usInitUserProperties",
          userProperties: res,
        });
      })
    );
  }

  @Action(UsGetCurrentUserFromLocal)
  usGetCurrentUserFromLocal({ patchState }: StateContext<IUserState>, action: UsGetCurrentUserFromLocal) {
    let currentUser: GraphUser = null;
    try {
      currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch {}

    patchState({
      currentUser,
      currentAction: "usGetCurrentUserFromLocal",
    });
  }

  @Action(UsGetElasticUsers)
  usGetElasticUsers({ patchState, setState, getState }: StateContext<IUserState>, action: UsGetElasticUsers) {
    const state = getState();

    // If everything is loaded and user didn't opted to reload the list
    // then no need to call the api return
    if (state.allLoaded && !action.reload) {
      return EMPTY;
    }

    patchState({
      loading: true,
      // allLoaded: false,
      currentAction: "usGetElasticUsers_InProgress",
    });

    const scrollId = action.reload ? "" : state.scrollId;

    return this._userApi
      .searchUsersInEs(
        state.searchTerm,
        [UserCategory.FieldTechnician, UserCategory.FieldServiceManager, UserCategory.ToolRoomManager],
        scrollId,
        50,
        "properties",
        { currentUser: "true" }
      )
      .pipe(
        catchError((err) => {
          console.error("Error while loading users list", err);
          return of({ users: { hits: [] }, roles: { hits: [] } } as UserSearchApiResponse);
        }),

        tap((res) => {
          const state = getState();
          const existingUsers = action.reload ? [] : _.cloneDeep(state.users);
          const users = [...existingUsers, ...res.users.hits];
          const totalUsers = res.users.totalHits;
          const loadedUsers = users.length;
          const allLoaded = loadedUsers >= res.users.totalHits;
          const selectedItems = action.reload ? {} : state.selectedItems;

          // Ensure to maintain the selected users status on soft reload
          if (!action.reload) {
            users.forEach((user) => (user.checked = state?.selectedItems && state?.selectedItems[user.id]?.checked));
          }

          setState({
            ...getState(),
            users,
            allUsersList: _.cloneDeep(users),
            selectedItems,
            totalUsers,
            loadedUsers,
            allLoaded,
            currentAction: "usGetElasticUsers",
            scrollId: res.users._scroll_id,
          });
        }),

        finalize(() => patchState({ loading: false }))
      );
  }
  // @Action(UsFetchUsersList)
  // usFetchUsersList({ patchState, setState, getState }: StateContext<IUserState>, action: UsFetchUsersList) {

  //   patchState({
  //     loading: true,
  //     allLoaded: false,
  //     currentAction: 'usFetchUsersList_InProgress'
  //   });

  //   return this._userApi.getUsers('all')
  //     .pipe(
  //       catchError((err) => {
  //         console.error('Error while loading users list', err);
  //         return of([]);
  //       }),

  //       tap(usersList => {

  //         const state = getState();

  //         const existingUsers = action.reload ? [] : _.cloneDeep(state.users);
  //         const users         = [...existingUsers, ...usersList];
  //         const totalUsers    = users.length;
  //         const loadedUsers   = users.length;
  //         const allLoaded     = true;
  //         const selectedItems = action.reload ? {} : state.selectedItems;

  //         // Ensure to maintain the selected users status on soft reload
  //         if (!action.reload) {
  //           users.forEach(user => user.checked = state?.selectedItems && state?.selectedItems[user.id]?.checked);
  //         }

  //         setState({
  //           ...getState(),
  //           users,
  //           allUsersList: _.cloneDeep(users),
  //           selectedItems,
  //           totalUsers,
  //           loadedUsers,
  //           allLoaded,
  //           currentAction: 'usFetchUsersList'
  //         });
  //       }),

  //       finalize(() => patchState({ loading: false }))
  //     );
  // }
  @Action(UsGetCurrentUser)
  usGetCurrentUser({ getState, patchState, dispatch }: StateContext<IUserState>, action: UsGetCurrentUser) {
    const state = getState();

    // if (!state.currentUser || !state.currentUser.name) {
    return this._userApi.getUser(action.user.username).pipe(
      tap((user: any) => {
        if (user.userDevices && user.userDevices[0]["transport"] === "email") {
          user = {
            ...user,
            email: user.userDevices[0]["deviceIdentifier"],
          };
        }
        if (user.userDevices && user.userDevices.length) {
          user.userDevices.forEach((ud) => {
            if (ud["transport"] === "email") {
              user = {
                ...user,
                email: ud["deviceIdentifier"],
              };
            } else if (ud["transport"] === "sms") {
              user = {
                ...user,
                phone: ud["deviceIdentifier"],
              };
            }
          });
        }
        patchState({
          currentAction: "usGetCurrentUser",
          currentUser: _.cloneDeep({
            ...action.user,
            ...user,
            initials: user.id.substring(0, 2),
          }),
        });
      }),

      tap(_ => dispatch(new AppGetNotifications())),
    );
    // }
  }
  @Action(UsGetTimeZones)
  usGetTimeZones({ getState, patchState }: StateContext<any>, action: UsGetTimeZones) {
    const state = getState();
    if (!state.timeZones || !state.timeZones.length) {
      this._userApi.getTimezones().subscribe(function (res) {
        let _timeZones = res[0];
        var results = [];
        for (var key in _timeZones) {
          if (_timeZones.hasOwnProperty(key)) {
            results.push({
              key: key,
              timezomeValue: _timeZones[key],
            });
          }
        }
        patchState({
          currentAction: "usGetTimeZones",
          timeZones: [...results],
        });
      });
    }
  }
  @Action(UsSetColumnSort)
  usSetColumnSort(ctx: StateContext<any>, action: UsSetColumnSort) {
    let state = ctx.getState();
    let columnToSortBy = { ...state.columnToSortBy } || {};
    ctx.setState({
      ...state,
      currentAction: "usSetColumnSort",
      columnToSortBy:
        action.column.label === columnToSortBy.label
          ? { ...action.column, reverseSort: !columnToSortBy.reverseSort }
          : { ...action.column, reverseSort: false },
    });
  }
  @Action(UsClearAllSelected)
  usClearAllSelected(ctx: StateContext<any>, action: UsClearAllSelected) {
    const state = ctx.getState();
    // const toggleAll = !state.toggleAll;
    // let selectedItems = { ...state.selectedItems };
    // let users = state.users.map(item => {
    //   selectedItems[item.id] = toggleAll ? item : false;
    //   return {
    //     ...item,
    //     checked: toggleAll
    //   }
    // })
    ctx.setState({
      ...state,
      // toggleAll: toggleAll,
      selectedItems: {},
      // users: [...users],
      currentAction: "usClearAllSelected",
    });
  }

  @Action(UsSelectAll)
  selectAllUsers({ getState, patchState }: StateContext<any>, action: UsSelectAll) {
    const { users = [] } = getState();
    const selectedItems = users.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, { });
    patchState({ selectedItems, currentAction: "usSelectAll"});
  }

  @Action(UsListScrolled)
  usListScrolled(ctx: StateContext<any>, action: UsListScrolled) {
    const state = ctx.getState();

    if (state.loading || state.allLoaded) {
      console.warn("Already loading");
      return;
    }

    if (!state.scrollId) {
      console.warn("no scroll id?");
    }

    ctx.patchState({
      // loading: true,
      currentAction: "usListScrolled",
    });
  }

  @Action(UsToggleUser)
  usToggleUser({ getState, setState }: StateContext<IUserState>, action: UsToggleUser) {
    const state = getState();

    // Find the current toggled user
    const users = _.cloneDeep(state.users);
    const filteredUsers = users.filter((user) => user.id === action?.user?.id);

    if (filteredUsers.length !== 1) {
      throw new Error("Unable to toggle user, cannot find user");
    }

    const user = filteredUsers[0];
    const userId = user.id;

    const selectedItems = _.cloneDeep(state.selectedItems);

    if (selectedItems[userId]) {
      // Deselect user
      user.checked = false;
      delete selectedItems[userId];
    } else {
      // Select user
      user.checked = true;
      selectedItems[userId] = _.cloneDeep(user);
    }

    setState({
      ...getState(),
      currentAction: "usToggleUser",
      selectedItems: { ...selectedItems },
      activeUser: { ...user },
      users,
    });
  }

  @Action(UsSearchUser)
  usSearchUser({ getState, setState }: StateContext<IUserState>, action: UsSearchUser) {
    const state = getState();

    setState({
      ...getState(),
      currentAction: "usSearchUser",
      searchTerm: action.searchTerm,
    });
  }

  @Action(UsSelectUser)
  usSelectUser({ getState, setState }: StateContext<IUserState>, action: UsSelectUser) {
    const state = getState();
    const selectedItems = _.cloneDeep(state.selectedItems);
    let activeUser = state.activeUser && action.user && action.user.id === state.activeUser.id ? null : action.user;

    if (!activeUser) {
      delete selectedItems[action.user.id];
    } else {
      selectedItems[activeUser.id] = { ...activeUser };
    }
    setState({
      ...state,
      addingUser: false,
      activeUser: activeUser,
      nodeToAdd: { ...activeUser } as any,
      headerTitle: "Edit User",
      currentAction: "usSelectUser",
      selectedItems: { ...selectedItems },
      singleUserSelected: true,
    });
  }

  @Action(UsDeleteUsers)
  deleteUsers({ patchState, getState, setState }: StateContext<IUserState>, action: UsDeleteUsers) {
    patchState({
      deleteInProgress: true,
      currentAction: "usDeleteUsers_InProgress",
    });

    const payload: BulkUserOperationPayload = {
      userIds: action.userIDs,
    };

    return this._userApi.deleteUsers(payload).pipe(
      delay(CL_API_DELAY_SHORT),

      tap((res) => {
        setState({
          ...getState(),
          currentAction: "usDeleteUsers",
          activeUser: null,
          selectedItems: {},
          bulkDeletionResponse: res,
        });
      }),

      finalize(() => patchState({ deleteInProgress: false }))
    );
  }

  @Action(UsResetPassword)
  usResetPassword({ patchState }: StateContext<IUserState>, action: UsResetPassword) {
    patchState({
      passwordResetInProgress: true,
    });

    return this._userApi.resetPassword(action.user?.id).pipe(
      delay(CL_API_DELAY_SHORT),

      tap((res) => {
        patchState({
          currentAction: "usResetPassword",
        });
      }),

      finalize(() => patchState({ passwordResetInProgress: false }))
    );
  }

  @Action(UsAddUserBulk)
  usAddUserBulk({ patchState, dispatch }: StateContext<IUserState>, action: UsAddUserBulk) {
    patchState({
      currentAction: "usAddUserBulk",
    });

    return combineLatest([dispatch(new UsToggleAddUser()), dispatch(new UsHidePanel("editor"))]);
  }

  @Action(UsEditSelected)
  usEditSelected(ctx: StateContext<any>, action: UsEditSelected) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      addingUser: false,
      editingUser: true,
      currentAction: "usEditSelected",
      headerTitle: "Edit User",
      nodeToAdd: _.cloneDeep(action.user),
      singleUserSelected: true,
    });

    return timer(200).pipe(switchMap((_) => ctx.dispatch(new UsShowPanel("editor"))));
  }

  @Action(UsCancelEdit)
  usCancelEdit(ctx: StateContext<any>, action: UsCancelEdit) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      userAdderOffset: 0,
      nodeToAdd: { ...DEFAULT_USER },
      addingUser: false,
      editingUser: false,
      currentAction: "usCancelEdit",
    });
  }

  @Action(UsShowPanel)
  usShowPanel(ctx: StateContext<any>, action: UsShowPanel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      userAdderOffset: -500,
      currentAction: "usShowPanel",
    });
  }

  @Action(UsHidePanel)
  usHidePanel(ctx: StateContext<any>, action: UsHidePanel) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      userAdderOffset: 0,
      currentAction: "usHidePanel",
    });
  }

  @Action(UsToggleAddUser)
  usToggleAddUser(ctx: StateContext<any>, action: UsToggleAddUser) {
    const state = ctx.getState();

    let mode = state.addingUser || state.editingUser;

    ctx.setState({
      ...state,
      addingUser: !mode,
      editingUser: false,
      singleUserSelected: true,
      currentAction: "usToggleAddUser",
      headerTitle: "Add User",
      nodeToAdd: { ...DEFAULT_USER },
    });
  }

  @Action(UsAddUser)
  usAddUser(ctx: StateContext<any>, action: UsAddUser) {
    const state = ctx.getState();
    // let user = this.updateUserFromForm(
    //   _.cloneDeep(state.nodeToAdd),
    //   action.userForm
    // );

    const user = this.prepareUserPayload(action.userForm);

    ctx.patchState({
      currentAction: "usAddUserInProgress",
      saveOrUpdateInProgress: true,
    });

    return this._userApi.addUser(user).pipe(
      tap((res) => {
        const state = ctx.getState();

        ctx.patchState({
          snackMessage: "User added",
          currentAction: "usAddUser",
          itemName: user.id,
          activeEntity: { ...res },
          nodeToAdd: { ...user },
        });
      }),
      catchError((err) => {
        let message = "Failed to add user";
        if (err instanceof HttpErrorResponse && err.error["error-message"]) {
          message = err.error["error-message"];
        }
        this._utils.showMessage(message, user.login_id);
        return throwError(err);
      }),
      finalize(() =>
        ctx.patchState({
          currentAction: "usAddUser",
          saveOrUpdateInProgress: false,
        })
      )
    );
  }

  @Action(UsToggleSingleUser)
  usToggleSingleUser(ctx: StateContext<any>, action: UsToggleSingleUser) {
    const state = ctx.getState();

    ctx.patchState({
      currentAction: "usToggleSingleUser",
      singleUserSelected: !state.singleUserSelected,
    });
  }

  // updateUserDevices(user, userForm) {
  //   if (!user || !user.userDevices) {
  //     return []
  //   }
  //    return user.userDevices.map(ud => {
  //       if (ud.transport === 'email') {
  //         ud.deviceIdentifier = userForm.email;
  //       } else if (ud.transport === 'sms') {
  //         ud.deviceIdentifier = userForm.phone;
  //       }
  //     });
  // }
  @Action(UsUpdateUser)
  usUpdateUser(ctx: StateContext<IUserState>, action: UsUpdateUser) {
    const state = ctx.getState();
    const user = this.prepareUserPayload(action.userForm);

    ctx.patchState({
      currentAction: "usUpdateUserInProgress",
      saveOrUpdateInProgress: true,
    });

    return this._userApi.updateUser(user).pipe(
      delay(CL_API_DELAY),

      tap((res) => {
        if (!action.fromMyAccount) {
          ctx.patchState({
            currentAction: "usUpdateUser",
          });
        } else {
          ctx.dispatch(new UsGetCurrentUser(state.currentUser));
        }
      }),

      catchError((err) => {
        let message = "Failed to update user";
        if (err instanceof HttpErrorResponse && err.error["error-message"]) {
          message = err.error["error-message"];
        }
        this._utils.showMessage(message, user.login_id);
        return throwError(err);
      }),

      finalize(() => ctx.patchState({ saveOrUpdateInProgress: false }))
    );
  }

  private updateUserFromForm(user, userForm) {
    user.id = userForm.value.userId || user.userId;
    user.tenantId = this.store.selectSnapshot(ClientConfigState.getTenantId);
    user.name = userForm.value.name;
    user.type = userForm.value.category;
    user.category = userForm.value.category;
    user.enabled = userForm.value.enabled;

    return user;
  }

  private prepareUserPayload(userForm: any) {
    const id = userForm.userId || userForm.login_id || userForm.id;

    const user = _.cloneDeep({
      // ...state.nodeToAdd,
      ...userForm,
      id,
      userId: id,
      tenantId: this.store.selectSnapshot(ClientConfigState.getTenantId),
      // userDevices: this.updateUserDevices(user, userForm)
      category: userForm.role || userForm.category,
      email: userForm.email,
      name: userForm.name,
      reporting_region: userForm.reporting_region || "",
      phone: userForm.phone || "",
      manager: userForm.manager || "",
      manager_email: userForm.manager_email || "",
      timezone: (userForm.timezone || "").replace(/ *\([^)]*\) */g, ""),
      optIn: userForm.optIn || "",
      locationId: userForm.locationId || "",
    });

    return user;
  }

  @Action(UsDownloadUsers)
  usDownloadUsers({ patchState, getState, setState }: StateContext<any>, action: UsDownloadUsers) {
    const state = getState();

    patchState({
      currentAction: "usDownloadUsers_InProgress",
    });

    const scrollId = "";

    return this._userApi
      .downloadUsers(
        state.searchTerm,
        [UserCategory.FieldTechnician, UserCategory.FieldServiceManager, UserCategory.ToolRoomManager],
        scrollId,
        50,
        "properties",
        { currentUser: "true" }
      )
      .pipe(
        catchError((err) => {
          console.error("Error while downloading users list", err);
          return throwError(err);
        }),

        tap((res) => {
          patchState({
            currentAction: "usDownloadUsers",
          });
        }),

        finalize(() => patchState({ loading: false }))
      );
  }
}