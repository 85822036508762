import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { MaterialModule } from '../@core/shell/material.module';
import { AngularKeylinesModule, SharedModule } from "@cl/shared";
import { NgxsModule } from "@ngxs/store";
import { InlineSVGModule } from "ng-inline-svg";
import { ModeSwitchComponent } from "./horizontal-filters/mode-switch/mode-switch.component";
import { KeylinesUtilService } from "./keylines/keylines-util.service";
import { KeylinesComponent } from "./keylines/keylines.component";
import { LiveMapState } from "./live-map.state";
import { LvPanelsState } from "./lv-panels.state";
import { NavigatorCarouselComponent } from "./navigator-carousel/navigator-carousel.component";
import { DomainFilterBoxComponent } from "./navigator-filters/domain-filter-box/domain-filter-box.component";
import { DomainFilterListComponent } from "./navigator-filters/domain-filter-list/domain-filter-list.component";
import { FilterBoxComponent } from "./navigator-filters/filter-box/filter-box.component";
import { FilterListComponent } from "./navigator-filters/filter-list/filter-list.component";
import { NavigatorFiltersComponent } from "./navigator-filters/navigator-filters.component";
import { MapMarkerComponent } from "./navigator-map/map-marker/map-marker.component";
import { MapComponent } from "./navigator-map/map/map.component";
import { NavigatorMapComponent } from "./navigator-map/navigator-map.component";
import {
  ServerClusteredMapMarkerComponent,
} from "./navigator-map/server-clustered-map-marker/server-clustered-map-marker.component";
import { ServerClusteredMapComponent } from "./navigator-map/server-clustered-map/server-clustered-map.component";
import { NavigatorComponent } from "./navigator.component";
import { ResetButtonsComponent } from "./reset-buttons/reset-buttons.component";
import { NavigatorCardsService } from "./service/navigator-cards.service";
import { NavigatorFiltersService } from "./service/navigator-filters.service";
import { NavigatorMapService } from "./service/navigator-map.service";
import { NavigatorState } from "./state/navigator.state";
import { RightListComponent } from "./../common/components/right-list/right-list.component";
import { SearchBoxComponent } from "./../common/components/search-box/search-box.component";

// const routes: Routes = [
//   {
//     path: "",
//     component: NavigatorComponent,
//     children: [
//       {
//         path: "map",
//         pathMatch: "full",
//         component: NavigatorMapComponent,
//       },
//       {
//         path: "graph",
//         pathMatch: "full",
//         component: LvGraphComponent,
//       },
//       {
//         path: "",
//         pathMatch: "full",
//         redirectTo: "map",
//       },
//     ],
//   },
// ];

@NgModule({
  declarations: [
    NavigatorComponent,
    ModeSwitchComponent,
    ResetButtonsComponent,
    NavigatorCarouselComponent,
    KeylinesComponent,
    NavigatorFiltersComponent,
    FilterBoxComponent,
    FilterListComponent,
    DomainFilterBoxComponent,
    DomainFilterListComponent,
    NavigatorMapComponent,
    MapComponent,
    MapMarkerComponent,
    ServerClusteredMapComponent,
    ServerClusteredMapMarkerComponent,
    RightListComponent,
    SearchBoxComponent,
  ],
  imports: [
    NgxsModule.forFeature([NavigatorState, LiveMapState, LvPanelsState]),
    MaterialModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    // RouterModule.forChild(routes),
    AgmCoreModule,
    AngularKeylinesModule,
    RouterModule
  ],
  exports: [
    ModeSwitchComponent,
    ResetButtonsComponent,
    NavigatorComponent,
    NavigatorCarouselComponent,
  ],
  providers: [KeylinesUtilService, NavigatorCardsService, NavigatorFiltersService, NavigatorMapService],
})
export class NavigatorModule {
}
