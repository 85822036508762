export class InitCurrentAction {
    static readonly type = '[Live Map] Init Current Action';
    constructor() { }
}
export class InitMapGroups {
    static readonly type = '[Live Map] Init Map Groups';
    constructor(public mapGroups: any, public selectedDt: any) { }
}
export class UpdateCounts {
    static readonly type = '[Live Map] Update Counts';
    constructor(public counts: any) { }
}
export class LvSelectNode {
    static readonly type = '[Live Map] Select Node';
    constructor(public node: any) { }
}
export class LvSelectLocation {
    static readonly type = '[Live Map] Select Location';
    constructor(public node: any) { }
}
export class SingleFilter {
    static readonly type = '[Live Map] Single Filter';
    constructor(public mapGroup: string) { }
}
export class ToggleFilter {
    static readonly type = '[Live Map] Toggle Filter';
    constructor(public mapGroup: string) { }
}
export class ToggleFilterCategory {
    static readonly type = '[Live Map] Toggle Filter Category';
    constructor(public mapGroup: string, public category: string) { }
}
export class ToggleSubFilter {
    static readonly type = '[Live Map] Toggle Sub Filter';
    constructor(public mapGroup: string, public subFilter: string, public parentFilter: string) { }
}
export class RemoveTag {
  static readonly type = "[Live Map] Remove Tag";
  constructor(
    public tag: any,
    public mapGroup: string,
    public category: string
  ) {}
}
export class EnableFilter {
    static readonly type = '[Live Map] Enable Filter';
    constructor(public mapGroup: string) { }
}
export class ClearFilters {
    static readonly type = '[Live Map] Clear  Filters';
    constructor(public mgName: string, public mgClass: string) { }
}
export class ClearAllFilters {
    static readonly type = '[Live Map] Clear All  Filters';
    constructor() { }
}
export class ResetMap {
    static readonly type = '[Live Map] Reset Map';
    constructor() { }
}
export class StepBack {
    static readonly type = '[Live Map] Step Back';
    constructor() { }
}
export class StepForward {
    static readonly type = '[Live Map] Step Forward';
    constructor() { }
}
export class SwitchViewMode {
  static readonly type = "[Live Map] Switch View Mode";
  constructor(public readonly mode?: string) {}
}
export class SearchByTerm {
    static readonly type = '[Live Map] Search by Term';
    constructor(public searchTerm: string) { }
}
export class ToggleSortByType {
    static readonly type = '[Live Map] Toggle Sort By Type';
    constructor() { }
}
export class ToggleCanvasInfoGraph {
    static readonly type = '[Live Map] Toggle Canvas Info Graph';
    constructor(public infoGraph: string) { }
}
export class ToggleLinks {
    static readonly type = '[Live Map] Toggle Links';
    constructor() { }
}
export class FilterMapNodes {
    static readonly type = '[Live Map] Filter Map Nodes';
    constructor() { }
}
export class GetCounts {
  static readonly type = "[Live Map] Get Counts";
  constructor(public isInit: boolean, public isStepAction: boolean) {}
}
export class FilterNodesByTerm {
    static readonly type = '[Live Map] Filter Nodes by Search Term';
    constructor(public nodeSearchTerm: any) { }
}
export class UpdateSearchBounds {
    static readonly type = '[Live Map] Update Search Bounds';
    constructor(public bounds: any) { }
}

export class LvSelectUser {
    static readonly type = '[Live Map] Select User';
    constructor(public user: any) { }
}
export class LvToggleUser {
    static readonly type = '[Live Map] Toggle User';
    constructor(public user: any) { }
}

export class LvToggleLocation {
    static readonly type = '[Live Map] Toggle Location';
    constructor(public location: any) { }
}
export class LvMapUserAction {
    static readonly type = '[Live Map] Map User interaction';
    constructor() { }
}

export class LvToggleSortOrder {
    static readonly type = '[Live Map] Toggle Sort Order';
    constructor() { }
}
export class LoadUsers {
    static readonly type = '[Live Map] Load Users';
    constructor() { }
}