<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title bgcolor32 justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/gateway.svg" />
            <span class="black font-bold">Bind Gateway</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content flex flex-col">
        <a href="#/gatewayDetails/{{gatewayObject?.id}}" class="fs14 mt-4 ml-4 mb-2">{{gatewayObject?.name}}</a>
        <div class="fs14 pl-4 pr-4">To selected Area</div>
        <div class="pl-4 pr-4">
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Name</div>
                <div class="basis-2/4"><a href="#/locationdetails/{{selectedLocation?.id}}">{{selectedLocation?.name}}</a></div>
            </div>
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Type</div>
                <div class="basis-2/4">{{selectedLocation?.type}}</div>
            </div>
            <div class="flex fs12 color16 mt-1">
                <div class="basis-2/4 font-bold">Status</div>
                <div class="basis-2/4">
                    {{selectedLocation?.status | startCase}}
                </div>
            </div>
        </div>
        <form [formGroup]="bindForm" novalidate class="pl-4 pr-4 grow relative">
            <input type="search" formControlName="searchTerm" class="basic-input mt5 pt5 pr2 ml-0 mr-0 w-100"
            placeholder="Search Location" value="">
            <div class="spin-pos" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
            <cdk-virtual-scroll-viewport itemSize="20" class="list-viewport h-calc-120">
                <div class="search-list-item-container ba" *cdkVirtualFor="let item of locationList" (click)="selectListItem(item)">
                    <span class="search-list-item justify-between flex items-center" [class.item-select]="selectedLocation?.id === item?.id">
                        <span>{{item.name | truncate:24}}</span>
                        <i class="fa fa-check mr10 color4" aria-hidden="true" *ngIf="selectedLocation?.id === item?.id"></i>
                    </span>
                </div>
            </cdk-virtual-scroll-viewport>
        </form>
    </section>
    <section class="cl-side-panel-footer bgcolor33 pr-4 pl-4">
        <div class='flex justify-end items-center'>
            <a formnovalidate class=" m-2" (click)="closePanel()">Cancel</a>
            <button class="panel-button white bgcolor4" [disabled]="!selectedLocation?.id" (click)="bindGateway()">
                Bind
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="bindClicked"></i>
            </button>
        </div>
    </section>
</div>