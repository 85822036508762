import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { UtilsService } from '@cl/common/utils/utils.service';
import { AnalyticsService } from '../../analytics.service';
import { AuthorizeService } from '@cl/@authorize/authorize.service';
import { ToastService } from '@cl/common/services/toast.service';
import { UserService } from '@cl/user/user.service';

@Component({
  selector: 'cl-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

  @ViewChild('dashboardIdTemplate', { static: true }) dashboardIdTemplate: TemplateRef<any>;
  @ViewChild('isHomePageTemplate') isHomePageTemplate: TemplateRef<any>;
  private event = new Subject<any>();
  sidePanelSummaryObj: boolean = false;
  selectedDashboard: any = [];
  public tableRowData: any = [];
  public rows: any = [];
  filters: any[] = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public isDeleteLoading: boolean = false;
  readonly headerHeight = 30;
  readonly rowHeight = 30;
  public SelectionType = SelectionType;
  public sortType: SortType = SortType.multi;
  showFilterPanel = true;
  actionCount: number = 0;
  SelectedDashboard: any = {};
  mode = 'add';
  showSummaryPanel = false;
  private selectedDashboardType: any[] = [];
  actionBtnPolicies = {
    isSuperAdmin: ['SCOPE_ALL', 'ANALYTICS_CREATESHARE'],
    changeAnalyticsHome: ['SCOPE_ALL', 'SCOPE_TENANT']
  };
  disableAnalyticsHome: boolean = false;
  mainWidth: any;
  tableWidth: number;
  columnKeys: string[] = [];
  filterArray = [];
  loginUserTenantId: string = '';
  isSuperAdmin: boolean = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    public dialog: MatDialog,
    private toastService: ToastService,
    private _utilsService: UtilsService,
    private _analyticsService: AnalyticsService,
    private _authService: AuthorizeService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.loginUserTenantId = this.userService.getEffectiveTenantId();
    this.disableAnalyticsHome = this._authService.isValidAction(this.actionBtnPolicies.isSuperAdmin, true);
    this.getDashboardList(null);
    this.getEntityFilters();
    this.setContainerWidth();
    this.isSuperAdmin = this.superAdminCheck(); //TODO:: this has to be changed to on policy basis, its a temp fix

  }

  ngAfterViewInit(): void {
    const eventSub: Subscription = this.getEvent().subscribe(evt => {
      if (evt.eventName === 'librarySummary') {
        this.closeLibrarySummarySidePanel();
        this.showFilterPanel = false;
        this.sidePanelSummaryObj = true;
        this.showSummaryPanel = false;
        this.selectedDashboard = evt.dashboardData;
      }
      if (evt.eventName === 'setHomePage') {
        this.setHomepage(evt.dashboardData);
      }
    });
    this.subscriptions.push(eventSub);

    this.rawColumns = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: false,
        checkboxable: true,
        width: 30,
        visible: true,
        frozenLeft: true
      },
      { name: 'Name', prop: 'dashboardName', width: 80, frozenLeft: true, cellTemplate: this.dashboardIdTemplate, visible: true },
      { name: 'Description', prop: 'description', width: 80, visible: true },
      { name: 'Type', prop: 'category', width: 50, visible: true },
      { name: 'Owner', prop: 'owner', width: 50, visible: true },
      { name: 'Last Update By', prop: 'modifiedBy', width: 50, visible: true },
      { name: 'Last Update Date', prop: 'modifiedAtFormatted', width: 50, visible: true },
      { name: 'Analytics Home', prop: 'isHomePage', cellTemplate: this.isHomePageTemplate, width: 50, visible: true, sortable: false }
    ];
    let columns: any = _.cloneDeep(this.rawColumns);
    this.columns = columns.filter((col: any) => col.visible);
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name } });
    this._changeDetectorRef.detectChanges();
  }

  private superAdminCheck() {
    let policies = this.userService.getPolicies();
    return policies.includes('SCOPE_ALL') || policies.includes('ANALYTICS_CREATESHARE');
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }

  loadDashboardSummary(dashboard: any, event: any) {
    event.preventDefault();
    this.setEvent({ 'eventName': 'librarySummary', dashboardData: dashboard });
  }

  closeLibrarySummarySidePanel() {
    this.sidePanelSummaryObj = false;
    this.showFilterPanel = true;
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 260;
    } else {
      this.tableWidth = this.mainWidth - 20;
    }
  }

  private getEntityFilters(): void {
    const getEntityFilterData: Subscription = this._analyticsService.getDashboardFilters().subscribe((data: any) => {
      this.filterArray = [];
      let listArray: any = [];
      data.forEach((d: any) => {
        listArray.push(new Object({
          'checked': false,
          'name': d,
          'filterType': 'dashboardType',
        }));
      });
      this.filterArray.push(new Object({
        //'expand': true,
        'collapsed': false,
        'enableCollapsing': false,
        'filterType': 'dashboardType',
        'label': 'Type',
        'type': 'multiselect',
        'list': listArray
      }));

      this.filters = [...this.filterArray];
    }, (error) => {
      console.error(error);
      this.isLoading = false;
    });

    this.subscriptions.push(getEntityFilterData);
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  getDashboardList(payload: any): void {
    this.isLoading = true;
    const dashboardListData: Subscription = this._analyticsService.getDashboardListData(payload).subscribe({
      next: (res: any) => {
        let dashboardList = res && res.dashboards ? res.dashboards : [];
        dashboardList.forEach((d: any) => {
          d.modifiedAtFormatted = d.modifiedAt ? this._utilsService.transformDate(d.modifiedAt, 'MM/dd/yyyy') : '';
          d.isHomePage = (d.id === res.homeDashboardId);
          d.disableAnalyticsHome = this.disableAnalyticsHome ? (d.tenant_id === this.loginUserTenantId) : this.disableAnalyticsHome ;
          d.title = !d.disableAnalyticsHome ? "Not have enough permission to change Analytics Home" : "Analytics Home"
        });
        this.rows = [...dashboardList];
        this.tableRowData = [...this.rows];
        this.isLoading = false;
      }, error: err => {
        console.error(err);
        this.isLoading = false;
      }
    });

    this.subscriptions.push(dashboardListData);
  }

  onSelect(rowData: any) {
    this.selected = [...rowData.selected];
    if (this.selected.length == 1) {
      this.SelectedDashboard = this.selected[0];
    } else {
      this.showSummaryPanel = false;
    }
  }

  updateFilter(event: any) {
    const val = event.target.value.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.tableRowData];
    // filter our data
    if (val) {
      temp = tableRowData.filter(function (d: any) {
        return (d.dashboardName.toLowerCase().indexOf(val) !== -1 || d.description.toLowerCase().indexOf(val) !== -1);
      });
    } else {
      temp = tableRowData;
    }
    // update the rows
    this.rows = temp;
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
  }

  getPanelUpdate(event: any) {
    if (event.action && event.action == "clearFilters") {
      this.selectedDashboardType = [];
      this.filters = [...this.filterArray];
    } else {
      let filterEvent = event.filter;
      if (filterEvent.checked) {
        if (filterEvent.filterType === 'dashboardType') {
          this.selectedDashboardType.push(filterEvent.name);
        }
      } else {
        if (filterEvent.filterType === 'dashboardType') {
          let index = this.selectedDashboardType.indexOf(filterEvent.name);
          this.selectedDashboardType.splice(index, 1);
        }
      }
    }
    this.createPayloadAndReloadDashboardList();
  }

  createPayloadAndReloadDashboardList() {
    let payLoad = new Object({
      category: this.selectedDashboardType
    });
    this.getDashboardList(payLoad);
  }

  closeSidePanel() {
    this.showSummaryPanel = false;
    this.getDashboardList(null);
  }

  getId(row) {
    return row.id;
  }

  addDashboard() {
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  editDashboard() {
    this.mode = 'edit';
    if (this.selected[0].disableAnalyticsHome) {
      this.showSummaryPanel = true;
    } else {
      this.toastService.error("Not have enough permission to edit dashboard", this.selected[0].dashboardName);
    }
  }

  deleteDashboard() {
    if (!this.selected[0].isHomePage && this.selected[0].disableAnalyticsHome) {
      this.isDeleteLoading = true;
      let message = `Are you sure you want to delete this dashboard?: ` + this.selected[0].dashboardName;
      const dialogData = new ConfirmDialogModel("", message);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData,
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          const deleteSub: Subscription = this._analyticsService.deleteDashboardById(this.selected[0].id).subscribe({
            next: (res: any) => {
              this.getDashboardList(null);
              this.toastService.success('Dashboard deleted successfully', this.selected[0].dashboardName);
              this.selected = [];
              this.isDeleteLoading = false;
            }, error: err => {
              console.error(err);
              this.toastService.error(err.error['error-message'], this.selected[0].dashboardName);
              this.isDeleteLoading = false;
            }
          });
          this.subscriptions.push(deleteSub);
        } else {
          this.isDeleteLoading = false;
        }
      });
    } else {
      let msg = !this.selected[0].disableAnalyticsHome ? "Not have enough permission to delete dashboard." : "Unable to delete, selected dashboard is set as home page."
      this.toastService.error(msg);
    }
  }

  loadSetHomePage(dashboard: any, event: any) {
    this.setEvent({ 'eventName': 'setHomePage', dashboardData: dashboard });
  }

  setHomepage(selectedData: any) {
    this.isLoading = true;
    const setHomePageDashboardsub: Subscription = this._analyticsService.setHomePageDashboard(selectedData.id).subscribe({
      next: res => {
        this.getDashboardList(null);
        this.toastService.success('Home dashboard saved successfully', selectedData.dashboardName);
        this.isLoading = false;
      }, error: err => {
        console.log(err);
        this.toastService.error(err.error['error-message'], selectedData.dashboardName);
        this.getDashboardList(null);
        this.isLoading = false;
      }
    });
    this.subscriptions.push(setHomePageDashboardsub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
