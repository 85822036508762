import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import Auth0Lock from 'auth0-lock';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from "@cl/env";
import { ToastService } from "@cl/common/services/toast.service";
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";
import { UrlUtilsService } from '@cl/common/services/url-utils.service';

@Injectable({
  providedIn: 'root'
})
export class AppAuthService {

  auth0Options = {
    theme: {
      logo: "https://clf-webapp-storage.s3.amazonaws.com/cloud_web_images/white-background-1.png",
      primaryColor: '#2879DE'
    },
    allowShowPassword: true,
    avatar: null,
    sso: true,
    rememberLastLogin: false,
    forgotPasswordLink: '/#/resetpassword',
    autoParseHash: false,
    container: 'login-form-container',
    auth: {
      redirectUrl: '',
      responseType: 'token id_token',
      //audience: https://${environment.auth0.domain}/userinfo,
      sso: true,
      params: {
        scope: 'openid profile email',
      }
    },
    autoclose: true,
    oidcConformant: true,
    allowedConnections:[],
  };
  fetchingConfig = true;
  lock: any;
  redirectURL = '';
  session: any = {};
  authenticated: boolean = false;
  baseUrl: any = {};
  sessionCheckInterval: any;
  authConfig: any;
  firstLaunch = true;
  isLocalHost = false;
  enterPriseConnections: any;
  doPassConnection = false;
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private toaster: ToastService,
    private webTracker: WebtrackerService,
    private urlUtil: UrlUtilsService
  ) {
    var hostname = window.location.hostname;
    var protocol = window.location.protocol;
    var port = (window.location.port == '') ? '' : ":" + window.location.port;
    var redirectStr = protocol + "//" + hostname + port;
    this.isLocalHost = (window.location.hostname === 'localhost');
    this.redirectURL = redirectStr + "/";
    this.auth0Options.auth.redirectUrl = this.redirectURL;

    //Getting the enterprise connection name and setting allowedConnections
    let enterpriseConnection = hostname.split(".")[0];
    //checking and removing -web
    if (enterpriseConnection.indexOf("-web") > 0) {
      enterpriseConnection = enterpriseConnection.split("-web")[0];
    }
    // else if(hostname.includes('qopper')){
    //   enterpriseConnection = 'test2';
    // }
    /*
    * This crude way has to be implemented as the search params were not being detected by the time the execution context is here at the first time.
    * */
    let qPrms = window.location.hash.split('?');
    if(qPrms[1]) {
      const params = JSON.parse('{"' + decodeURI(qPrms[1].replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
      if(params && params['t']){
        let value = params['t'];
        if (value && value.split("/")[2]) {
          enterpriseConnection = enterpriseConnection + "-" + value.split("/")[2];
        }
        if(value) {
          this.doPassConnection = true;
        }
      }
    }

    this.auth0Options.allowedConnections = ["Username-Password-Authentication", enterpriseConnection];
    this.enterPriseConnections = enterpriseConnection;
    this.getBaseURL(protocol, hostname, port);
     if((localStorage.getItem('manualAuthCheck')) ) {
       this.firstLaunch = false;
     } else {
       this.firstLaunch = true;
       localStorage.setItem('manualAuthCheck', 'true');
     }
  }

  getAuth0Config() {
    return new Observable((obs:any) => {
      if(this.isLocalHost) {
        this.fetchingConfig = false;
        this.lock = new Auth0Lock(
          environment.auth0ClientId,
          environment.auth0Domain,
          this.auth0Options
        );
        this.authConfig = {'auth0ClientId': environment.auth0ClientId, 'auth0Domain': environment.auth0Domain};
        this.webTracker.setTrackingConfig(environment.GTM_TRACKER);
        this.postLocksetup();
        obs.next();
        obs.complete();
      } else {
        this.fetchingConfig = true;
        this.http.get(
          'assets/data/auth-config.json',
          {
            headers: new HttpHeaders({
              'Skip-Auth': 'true',
            })
          }
        ).subscribe((auth0Config: any) => {
          this.lock = new Auth0Lock(
            auth0Config.auth0ClientId,
            auth0Config.auth0Domain,
            this.auth0Options
          );
          this.authConfig = auth0Config;
          this.webTracker.setTrackingConfig(auth0Config.GTM_TRACKER);
          this.fetchingConfig = false;
          this.postLocksetup();
          obs.next();
          obs.complete();
        });
      }
    })
  }

  postLocksetup() {
    this.lock.on('authenticated', (authResult: any) => {
      this.session = authResult;
      this.authenticated = true;
    });
    this.lock.on('authorization_error', error => {
      console.log('something went wrong', error);
      this.authenticated = false;
    });
  }

  authCheck() {
    const self = this;
      this.lock.checkSession(this.auth0Options, function (error, authResult) {
        if (error || !authResult) {
          self.delayedLogout();
        } else {
          self.session = authResult;
        }
      });
  }

  private randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  manualAuthCheck() {
    let NONCE = this.randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    let URL = `https://${this.authConfig.auth0Domain}/authorize?client_id=${this.authConfig.auth0ClientId}&redirect_uri=${encodeURI(this.redirectURL)}&nonce=${NONCE}&response_type=token%20id_token&scope=openid%20profile%20email`;
    if(this.doPassConnection) {
      URL += '&connection='+this.enterPriseConnections;
    }
    window.location.href = URL;
  }

  setupPeriodicAuthCheck() {
    const CHECK_INTERVAL = 60000; //check For Every Minute
    this.sessionCheckInterval = setInterval(() => {
      this.authCheck();
    }, CHECK_INTERVAL);
  }

  preserveAuth() {
    var hash = window.location.hash;
    let self = this;
    this.lock.resumeAuth(hash, function (error, authResult) {
      if (error) {
        console.log('Could not parse hash', error);
      }
      if(authResult) {
        self.session = authResult;
      }
    });
  }

  login() {
    if(!this.fetchingConfig){
      this.lock.show();
      return true
    } else {
      return false;
    }
  }

  hideLockWidget() {
    this.lock.hide()
  }

  delayedLogout() {
    const logoutTimer = 8; //8 seconds
    const self = this;
    this.toaster.openTimerSnackBar('Your session is expired, you will be signed out shortly in', logoutTimer);
    setTimeout(() =>{
      self.logout();
    }, logoutTimer);
  }

  logout() {
    this.session = {};
    clearInterval(this.sessionCheckInterval);
    localStorage.removeItem('manualAuthCheck');
    this.lock.logout({returnTo: this.redirectURL + '#/login'})
  }

  isAuthenticated() {
    let self = this;
    return new Observable((obs:any) => {
      if(this.authenticated) {
        obs.next(true);
        obs.complete();
      } else {
        if(this.firstLaunch && this.doPassConnection) {
          this.manualAuthCheck();
        } else {
          this.lock.checkSession({}, function (error, authResult) {
            if (error || !authResult) {
              localStorage.removeItem('manualAuthCheck');
              obs.next(false);
              obs.complete();
            } else {
              // user has an active session, so we can use the accessToken directly.
              /*lock.getUserInfo(authResult.accessToken, function (error, profile) {
                console.log(error, profile);
              });*/
              self.session = authResult;
              self.authenticated = true;
              obs.next(true);
              obs.complete();
            }
          });
        }

      }
    });
  }

  getSession() {
    return this.session;
  }

  getAuthToken() {
    return this.session.accessToken;
  }

  getParkourOrCloudleafSubdomain(hostname, returnDomain) {
    let host = hostname ? hostname : window.location.hostname;
    let subdomain;
    let domain;
    if(host.indexOf('cloudleaf') > -1){
      subdomain = host.split('.cloudleaf.io')[0];
      domain = '.cloudleaf.io';
    }
    else if(host.indexOf('parkoursc') > -1){
      subdomain = host.split('.parkoursc.com')[0];
      domain = '.parkoursc.com';
    }else {
      const domainSubDomain = this.urlUtil.parseDomainSubDomain();
      subdomain = domainSubDomain.subdomain;
      domain = domainSubDomain.domain;
    }
    return returnDomain ? domain : subdomain;
  }

  getBaseURL(protocol, hostname, port) {
    var subdomain;
    var prima;
    if(hostname === 'localhost') {
      hostname = environment.localCluster;
      port = '';
      protocol = 'https:';
      subdomain = this.getParkourOrCloudleafSubdomain(hostname, false);
      prima = subdomain.split('-app')[0];
    } else {
      subdomain = this.getParkourOrCloudleafSubdomain(hostname, false);
      prima = subdomain.split('-web')[0];
    }
    this.baseUrl.prima = prima;
    this.baseUrl.protocol = protocol;
    this.baseUrl.port = port;
  }

  getCloudOSBaseURL() {
    var cloudHostName = this.baseUrl.prima + this.getParkourOrCloudleafSubdomain(null, true);
    cloudHostName = this.baseUrl.protocol + "//" + cloudHostName + this.baseUrl.port;
    return cloudHostName + "/cloudos/";
  }

  getGraphAppBaseURL() {
    var graphHostName = this.baseUrl.prima+'-graph'+ this.getParkourOrCloudleafSubdomain(null, true);
    graphHostName = this.baseUrl.protocol + "//" + graphHostName + this.baseUrl.port;
    return graphHostName + "/clfgraphapp/";
  }

  verifyPrimaryEmail(payload: any) {
    const url = `${this.getCloudOSBaseURL()}api/1/user/verifyPrimaryEmail`;
    return this.http.post(url, payload, { headers: { 'Content-Type': 'application/json', 'Skip-Auth': 'true' } });
  }

  sendResetPasswordLink(payload: any) {
    const url = `${this.getGraphAppBaseURL()}api/1/user/resetPassword`;
    return this.http.post(url, payload, { headers: { 'Content-Type': 'application/json', 'Skip-Auth': 'true' } });
  }

  submitNewPassword(payload: any) {
    const url = `${this.getCloudOSBaseURL()}api/1/user/forgotPasswordChange`;
    return this.http.post(url, payload, { headers: { 'Content-Type': 'application/json', 'Skip-Auth': 'true' } });
  }
}
