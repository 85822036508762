import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { UserService } from '../user.service';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { MatDialog } from '@angular/material/dialog';
import { MalOpenBindPanel } from '@cl/common/actions';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { ToastService } from '@cl/common/services/toast.service';
import { PersistenceService } from '@cl/common/services/persistence.service';

@Component({
  selector: 'cl-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {
  @ViewChild('userIdTemplate') userIdTemplate: TemplateRef<any>;
  @ViewChild('listTable') listTable: any;

  public tableRowData: any = [];
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showSummaryPanel:boolean = false;
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  private event = new Subject<any>();
  columnKeys: string[] = [];
  searchTearm = '';
  private userGroup;
  private userList;
  private userRoleList;
  public tenantType=false;

  actionBtnPolicies = {
    add: ['USER_CREATE', 'ROLE_SUMMARY'],
    edit: ['USER_UPDATE', 'ROLE_SUMMARY'],
    delete: ['USER_DELETE'],
    summary: ['USER_DETAIL']
  }
  sidePanelsObj: any = {
    summary: false,
    bulk: false
  };
  selectedUser = {};
  editDisabled = true;
  deleteDisabled = true;
  resetDisabled = true;
  selectedRowData:any = [];
  mode = 'view';
  bulkactions: any = [
    {name: 'Bulk add', policies: ['USER_CREATE', 'ROLE_SUMMARY']},
    {name: 'Bulk delete', policies: ['USER_DELETE']}
  ];
  bulkPanelObj: BulkPanelModel;
  isResetting = false;
  showFilterPanel = true;
  mainWidth: any;
  tableWidth: number;
  filters: any[] = [];
  private selectedUserType:any[]=[];
  filterArray = [];
  actionCount: number = 0;
  totalRowCount = 0;
  initialLoad: boolean = true;
  private userType = {
    'collapsed':false,
    'enableCollapsing':false,
    'filterType':'User Type',
    'label':'User Type',
    'type':'multiselect',
    'list':[{
      'checked':false,
      'name':'Normal User',
      'entityId':'NORMAL_USER',
      'filterType':'User Type',
    },
    {
      'checked':false,
      'name':'API User',
      'entityId':'APP_API_USER',
      'filterType':'User Type',
    }
    ]
  };

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private el: ElementRef,
    public dialog: MatDialog,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private toastService: ToastService,
    private persistenceStore:PersistenceService
  ) {
  }

  ngOnInit(): void {
    this.getusers();
    this.getUserTypeFilters();
    this.setContainerWidth();
    this.tenantType = this.userService.isDirectUser();
  const eventSub: Subscription = this.getEvent().subscribe(evt => {
    if (evt.eventName === 'summary') {
      this.mode = 'view';
      this.showSummaryPanel = true;
      this.selectedUser = evt.userObject;
    }
  });
  this.subscriptions.push(eventSub);
  }

  ngAfterViewInit(): void {
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 30,
        visible: true,

      },
      { name: 'User ID', prop: 'userId', width: 120, cellTemplate: this.userIdTemplate, visible: true },
      { name: 'Name', prop: 'name', width: 200, visible: true },
     // { name: 'Email', prop: 'email', width: 150, visible: true },
      { name: 'Type', prop: 'formattedType', width: 150, visible: true },
      { name: 'Manager', prop: 'managerName', width: 150, visible: true },
      { name: 'Manager Email', prop: 'managerEmail', width: 150, visible: true },
      { name: 'Status', prop: 'status', width: 120, visible: true },
      { name: 'Roles', prop: 'roles', width: 150, visible: true },
      { name: 'Groups', prop: 'groups', width: 120, visible: true }];
      const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('user', [...this.rawColumns]);
      this.rawColumns = [...rawColumns];
      this.columns = [...gridColumns];
      this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
      this._changeDetectorRef.detectChanges();
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.showFilterPanel) {
      this.tableWidth = this.mainWidth - 262;
    } else {
      this.tableWidth = this.mainWidth - 12;
    }
  }

  columnReordered(e){      
    this.persistenceStore.setColumnOrder(e.column.prop,e.prevValue,e.newValue, 'user', this.listTable._internalColumns);   
  }

  getUserTypeFilters() {
    this.filterArray = [];
    this.filterArray.push(this.userType);
    this.filters = [...this.filterArray];
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  getusers(): void {
    this.isLoading = true;
    const userListData: Subscription = combineLatest([this.userService.getUserGroups(), this.userService.getUserList(), this.userService.getUserRolesList()]).subscribe((data:any)=>{
      this.userGroup = data[0];
      this.userList = data[1].reverse();
      // let userListData = data[1].filter(function (d:any) {
      //   return (d.type.indexOf('API_USER') == -1);
      // });
      this.userRoleList = data[2];
      this.createData(this.userList);
    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(userListData);
  }

  private createData(userList) {
    this.rows = this.createUserData(userList, this.userGroup, this.userRoleList);
    if(this.initialLoad == true){
      this.totalRowCount = this.rows.length;
      this.initialLoad = false;
    }
    this.tableRowData = [...this.rows];
    this.isLoading = false;
    this.updateFilter(this.searchTearm);
  }

  toggleFilterPanel() {
    this.showFilterPanel = !this.showFilterPanel;
    this.setContainerWidth();
    setTimeout(() => {
      this.listTable?.recalculate();
    }, 10);
  }

  getPanelUpdate(event:any){
    if(event.action && event.action == "clearFilters"){
      this.selectedUserType = [];
      this.filters = [...this.filterArray];
    }else{
      let filterEvent = event.filter;
      if(filterEvent.checked){
          if(filterEvent.filterType === 'User Type'){
            this.selectedUserType.push(filterEvent.entityId);
          }
      }else{
        if(filterEvent.filterType === 'User Type'){
          let index = this.selectedUserType.indexOf(filterEvent.entityId);
          this.selectedUserType.splice(index, 1);
        }
      }
    }
      this.createPayloadAndReloadPolicyList();
  }

  createPayloadAndReloadPolicyList(){
    let filteredList;
    if(this.selectedUserType.length === 1) {
      filteredList = this.userList.filter( (d:any) => {
        return (d.type.indexOf(this.selectedUserType[0]) > -1);
      });
    } else {
      filteredList = this.userList;
    }
    this.createData(filteredList);
  }

  private createUserData(userList:any, userGroup:any, roleList:any){
    let finalUserData:any = [];
    let allUsers = [...userList];
    let allUserRoles = [...roleList];

    let getRoles = (roles:any)=>{
      let currentUserRole = '';
      roles.forEach((role:any) => {
        allUserRoles.forEach((userRole:any) => {
          if(userRole.id === role){
            if(currentUserRole){
              currentUserRole += ','+userRole.name;
            }else{
              currentUserRole += userRole.name;
            }
          }
        });
      });
      return currentUserRole;
    }

    let getGroups = (id:any)=>{
      let currentUserGroup = '';
      userGroup.forEach((group:any) => {
        if(group.userids.indexOf(id) > -1){
          if(currentUserGroup){
            currentUserGroup += ','+group.userGroupName;
          }else{
            currentUserGroup += group.userGroupName;
          }
        }
      });
      return currentUserGroup;
    }

    let getStatus = (devices)=>{
      let status = '';
      devices.forEach(device => {
        if(device.transport == 'email'){
          status = device.status;
        }
      });
      return status;
    }

    let getUserType = (type) => {
      let userType = '';
      if(type === 'NORMAL_USER'){
        userType = 'Normal User';
      } else if(type === 'APP_API_USER'){
        userType = 'API User';
      }
      return userType;
    }

    allUsers.forEach((user:any) => {
      finalUserData.push(new Object({
        'userId':user.id,
        'name':user.name,
        'formattedType': getUserType(user.type),
        'type': user.type,
        'managerName': user.properties.managerName,
        'managerEmail':user.properties.managerEmail,
        'status':!_.isEmpty(user.userDevices)?getStatus(user.userDevices):'',
        'roles':!_.isEmpty(user.roles)?getRoles(user.roles):'',
        'groups':getGroups(user.id.replace('user_', '')),
        'userObject':user
      }));
    });
    return finalUserData;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  onSelect(rowData: any , event?:any) {
    this.selectedRowData = rowData.selected;
    if(this.selectedRowData.length === 1 ){
      if( this.selectedRowData[0]?.userObject?.userTenantAccessType =="DIRECT" && this.tenantType){
        this.editDisabled = false;
        this.deleteDisabled = false;
        this.resetDisabled = false;
        if(this.selectedRowData[0]?.type.indexOf('API_USER') > -1){
          this.resetDisabled = true;
        }
      }

      if(this.selectedRowData[0]?.userObject?.properties?.isFederated == 'true'){
        this.deleteDisabled = true;
        this.resetDisabled = true;
      }
  
    }else{
      this.deleteDisabled =true
      this.editDisabled = true;
      this.resetDisabled = true;
      this.showSummaryPanel = false;
    }
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'Users-list', _.map(this.columns, 'name'));
    }
  }

  updateFilter(term) {
    this.searchTearm = term;
    const val = this.searchTearm.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.tableRowData];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(val) !== -1 || d.userId.toLowerCase().indexOf(val) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.rows = temp;
  }

  setEvent(state: any) {
    this.event.next(state);
  }

  getEvent(): Observable<any> {
    return this.event.asObservable();
  }


  loadSummaryPanel(userObject:any, event:any){
    event.preventDefault();
    this.setEvent({ 'eventName': 'summary', userObject: userObject });
  }

  editUser(){
    this.mode = 'edit';
    this.selectedUser = this.selectedRowData[0];
    this.showSummaryPanel = true;
  }

  addUser(){
    if(this.showSummaryPanel){
      this.mode = '';
      this.showSummaryPanel = false;
    }else{
      this.mode = 'add';
      this.showSummaryPanel = true;
    }
  }

  closeSidePanel(cbAction?){
    this.showSummaryPanel = false;
    for (let panel in this.sidePanelsObj) {
      this.sidePanelsObj[panel] = false;
    }
    if(cbAction && cbAction.action == 'reloadUsers') {
      this.delayedRefreshStart = true;
      this.resetSelection();
    }
  }

  private resetSelection() {
    this.selected = [];
    this.editDisabled = true;
    this.deleteDisabled =true;
    this.resetDisabled = true;
  }

  bulkPanelHandler(panelObj: BulkPanelModel) {
    this.bulkPanelObj = panelObj;
    this.closeSidePanel();
    this.sidePanelsObj.bulk = true;
  }

  deleteUser(){
    let message = `Are you sure you want to delete this user?: `+this.selectedRowData[0].name;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      this.isLoading = true;
      if (dialogResult) {
       const deleteuserSub:Subscription = this.userService.deleteItemFromList('user',this.selectedRowData[0].userId).subscribe(data=>{
          this.getusers();
          let action = 'User deleted successfully';
          this.toastService.success(action, this.selectedRowData[0].name);
          this.selected = [];
          this.selectedRowData = [];
          this.isLoading = false;
          this.showSummaryPanel = false;
        }, (error) => {
            console.error(error);
            let action = 'Something went wrong';
           this.toastService.error(error.error["error-message"] || action, this.selectedRowData[0].name);
           this.isLoading = false;
          });

          this.subscriptions.push(deleteuserSub);
      }else{
        this.isLoading = false;
      }
    });
  }

  resetPassword(){
    this.isResetting = true;
    let message = `Are you sure you want to reset the user?: `+this.selectedRowData[0].name;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
       const resetuserSub:Subscription = this.userService.resetPasswordUser(this.selectedRowData[0].userObject).subscribe(data=>{
          this.getusers();
          let action = 'User reset successful';
          this.toastService.success(action, this.selectedRowData[0].name);
          this.selected = [];
          this.selectedRowData = [];
          this.isResetting = false;
        }, (error) => {
            console.error(error);
            let action = 'Something went wrong';
           this.toastService.error(action, this.selectedRowData[0].name);
           this.isResetting = false;
          });

          this.subscriptions.push(resetuserSub);
      }else{
        this.isResetting = false;
      }
    });
  }

  getId(row) {
    return row.userId;
  }

  operationInprogressState(event){
    this.deleteDisabled =event;
    this.editDisabled = event;
    this.resetDisabled = event;
  }
}
