import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnChanges,
  AfterViewInit
} from '@angular/core';
import { CatalogType, CdmField } from '@cl/@types/extended-attributes.types';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { EntityService } from '@cl/common/services/entity-list.service';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { ToastService } from '@cl/common/services/toast.service';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { SkuService } from '../sku.service';

@Component({
  selector: 'cl-sku-add-panel',
  templateUrl: './sku-add-panel.component.html',
  styleUrls: ['./sku-add-panel.component.scss'],
})
export class SkuAddPanelComponent implements OnInit, AfterViewInit{
  @Input() isEdit: boolean = false;
  @Input() skuId: string;
  @Output() closeSidePanel = new EventEmitter<boolean>();
  @ViewChild('formRenderer') formRenderer!: DynamicFormRendererComponent;
  tabSelectedEvent = new BehaviorSubject<number>(0);

  isCreating = false;
  isDetailsFetching = false;

  fields: CdmField[] = [];
  inputFields: InputField[] = [];

  skuCatalog!: CatalogType;
  skuDetails;
  catalogType: CatalogType;
  catalogTypesList: any;
  isCatalogTypeError = false;
  skuCatalogCDMFields: any;
  withField:boolean = true;
  constructor(
    private _dynamicFormService: DynamicFormService,
    private _extendedAttributeService: ExtendedCatalogAttributeService,
    private _toastService: ToastService,
    private _skuService: SkuService,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    // this.renderForm();
  }

  ngAfterViewInit(): void {
    if (!this.isEdit) {
      // this.changeCatalogType();
      this.getSKUTypesList();
    }else{
      this.isEdit = true;
      this.getSKUDetails();
    }
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.renderForm();
  // }

  async renderForm(catalogType) {
    this.getCDMFields(catalogType);
  }

  async getCDMFields(skuType) {
    this.skuCatalog =
      await this._extendedAttributeService.getCatalogExtendedColumns(skuType);

    let fields: CdmField[] = this.skuCatalog.cdmFields.filter(
      (field) => field.instanceUserCreatable
    );

    this.fields = this._dynamicFormService.orderFields([...fields], 'order');

    this.inputFields = await this._dynamicFormService.generateInputFields(this.fields,this.isEdit,this.skuDetails?.properties);
  }

  closePanel(isReload) {
    this.closeSidePanel.emit(isReload);
  }

  async onSave(catalog) {
    const formData = this.formRenderer.onSubmit();
    if (formData.valid) {
      const dynamicFormPayload = formData.getRawValue();
      const customToggleFields = this.inputFields.filter(
        (field) => field.type === 'toggle' && field.options.length == 2
      );

      if (customToggleFields) {
        customToggleFields.forEach((field) => {
          if (dynamicFormPayload[field.id] !== undefined) {
            dynamicFormPayload[field.id] = dynamicFormPayload[field.id]
              ? field.options[0]
              : field.options[1];
          }
        });
      }

      const payload = {
        ...dynamicFormPayload,
        type: this.skuCatalog.additionalProperties.entityTypeKey,
        typeId: this.skuCatalog.id,
      };

      this.isCreating = true;

      try {
        if (this.isEdit) {
          await this._skuService.updateSKU(payload, this.skuId, payload.type);
        } else {
          await this._skuService.createSKU(payload, payload.type);
        }
        this.closePanel(true);
        this._toastService.success(
          `SKU ${this.isEdit ? 'updated' : 'created'} successfully`
        );
      } catch (error) {}

      this.isCreating = false;
    }
  }

  async getSKUTypesList() {
    // this.skuCatalog = await this._extendedAttributeService.getCatalogExtendedColumns('sku');
    this.catalogTypesList =
      await this._extendedAttributeService.getCatalogExtendedDeviceColumns('product',this.withField,
        'sku'
      );
      this.catalogTypesList.sort(function (a,b) {
        return a.name.toLowerCase()>b.name.toLowerCase()?1:-1
    })
  }

  async getSKUDetails() {
    this.isDetailsFetching = true;
    if (this.isEdit) {
      this.skuDetails = await this._skuService.getSKU(this.skuId);
    }
    await this.changeCatalogType(this.skuDetails.type);
    this.isDetailsFetching = false;
  }

  async changeCatalogType(objectType: string) {
    this.renderForm(objectType);
  }
 

  onNext() {
    if(!this.catalogType){
      this.isCatalogTypeError = true;
      return;
    }
    this.isCatalogTypeError = false;
    this.changeCatalogType(this.catalogType.objectType);
    this.tabSelectedEvent.next(0);
  }
}
