<time>{{notification.creationTime | formateDate:'default'}}</time>
<div class="notification-details css-grid grid-flow-col gap-2">
  <i class="fa fa-info-circle"></i>
  <p [title]="notification.altBody" [ngClass]="notification.status=='UNREAD'?'unread-item':''">{{notification?.altBody}}</p>
  <span class="justify-self-center align-center">
    <ng-container [ngSwitch]="notification.resolved">
      <i *ngSwitchCase="NotificationResolvedStatus.Resolved" class="fa fa-check-circle fa-lg green">
      </i>
      <span class="text-muted" *ngSwitchCase="NotificationResolvedStatus.NoAction">
        No action required
      </span>
      <span class="font-normal cl-action" (click)="openResolve(notification)" *ngSwitchCase="NotificationResolvedStatus.Open">
        Action Required
      </span>
    </ng-container>
  </span>
</div>