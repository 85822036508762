import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { ExtendedCatalogAttributeService } from '@cl/common/services/extended-catalog-attribute.service';
import { ToastService } from '@cl/common/services/toast.service';
import { GatewayService } from '../gateway.service';
import { MatTabGroup } from '@angular/material/tabs';
import { BehaviorSubject, finalize } from 'rxjs';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { CatalogType } from '@cl/@types/extended-attributes.types';

@Component({
  selector: 'cl-gateway-add-panel',
  templateUrl: './gateway-add-panel.component.html',
  styleUrls: ['./gateway-add-panel.component.scss']
})
export class GatewayAddPanelComponent implements OnInit {
  @Input() isEdit: boolean = false;  
  @Input() gatewayId:string;
  @Input() entityTypeKey:string;
  @Output() closeAddGatewaySidePanel = new EventEmitter<boolean>();
  gatewayCatalog:any;
  gatewayDetailsObj: any;
  inputFields:any;
  fields:any;
  catalogTypesList:any;
  isDetailsFetching :boolean;  
  isCreatingGateway:boolean=false;
  showRSSIValue: boolean = false;
  submitted:boolean = false;  
  catalogType: CatalogType;
  isCatalogTypeError = false;

  tabSelectedEvent = new BehaviorSubject<number>(0);
  bulkFormData: FormData;

  bulkConfig: BulkPanelModel = {
    fileType: 'CSV',
    appName: 'clfgraphapp',
    method: 'POST',
    templateUrl: '',
    uploadUrl: '', //Based upon the CatalogType selection it will change
    isDynamicFile: true,
  };
  downloadedCSVFields: string[] = [];
  rssiValue:any
  withField:boolean = true;

  @ViewChild('formRenderer') formRenderer!: DynamicFormRendererComponent;
  constructor(private _dynamicFormService: DynamicFormService,
    private _extendedAttributeService: ExtendedCatalogAttributeService,
    private _toastService: ToastService,
    private _gatewayService:GatewayService,
    private _dialog: MatDialog,
    private _bulkService: BulkActionsService
    ) { }

    formGroup = new FormGroup({
      rssiValue: new FormControl('')
    })

  ngOnInit(): void {
    this.tabSelectedEvent.subscribe((tab) => {
      this.submitted = false;
      if (tab == 1) {
        this.bulkConfig.uploadUrl = `api/2/bulkDataJob/${this.catalogType.objectType}/create/file`;
      }
    })
    if (this.isEdit && this.gatewayId) {
      this.getGatewayDetails();
    } else {      
      this.getDeviceTypesList();
    //this.changeCatalogType('gateway');
    }     
  }

  async getGatewayDetails() {
    this.isDetailsFetching = true;
    this.gatewayDetailsObj = await this._gatewayService.getSelectedGatewayDetails(
      this.gatewayId, this.entityTypeKey
    );
    await this.changeCatalogType('gateway',true);
    this.isDetailsFetching = false;
  }

  async changeCatalogType(objectType: string, isEdit=false) {
    this.isDetailsFetching = true;
    this.gatewayCatalog =
      await this._extendedAttributeService.getCatalogExtendedColumns(
        objectType
      );

    const fields = this._dynamicFormService.getRenderableFields(
      this.gatewayCatalog.cdmFields
    );

    this.fields = this._dynamicFormService.orderFields([...fields], 'order');
    /* this.fields.splice(4,0,{        
      id: 'rssiValue',
      defaultValue: 'feild',
      displayLabel: 'RSSI Value',
      required: false,
      type: 'STRING',
      value: '',
      isDynamicField: false,
      instanceUserEditable: true
    }) */  ////as part of DVP2-11920 this change will go on RT38
    
    this.downloadedCSVFields = this.fields.map(field => {
      let label = field.displayLabel;
      if(field.required){
        label += '*'
      }else if(field.displayLabel.endsWith('*')){
        label += '*'
      }
      return `"${label}"`
    })

    if(this.gatewayDetailsObj?.properties){
      this.gatewayDetailsObj['properties']['attributes'] = JSON.parse(this.gatewayDetailsObj?.properties?.attributes);
      this.gatewayDetailsObj.properties['rssiRange'] = this.gatewayDetailsObj?.properties?.attributes?.rssiRangeDisplay;
      if(this.gatewayDetailsObj?.properties?.attributes?.rssiRangeDisplay){
        if(this.gatewayDetailsObj?.properties?.attributes?.rssiRangeDisplay.toLowerCase().indexOf('custom')>-1){
         this.showRSSIValue=true;
        }
        this.gatewayDetailsObj.properties['rssiValue'] = Number(this.gatewayDetailsObj?.properties?.attributes?.rssiRange);
        if(this.gatewayDetailsObj?.properties?.type==='Zone Sensor'){
          this.fields.map(item=>{
            if(item.name==='rssiRange')
            item.value='SHORT (-65), MEDIUM (-75), CUSTOM';
          })
        }
        // const formattedFields = this.fields.splice(4,0,{        
        //   id: 'rssiValue',
        //   defaultValue: 'feild',
        //   displayLabel: 'RSSI Value',
        //   required: true,
        //   type: 'text',
        //   value: '',
        //   isDynamicField: false,
        //   instanceUserEditable: true
        // })
      }
    }
   
      this.inputFields = await this._dynamicFormService.generateInputFields(
        this.fields,
        this.isEdit,
        this.gatewayDetailsObj?.properties
      );
      if(this.inputFields.filter(item=>item.id==='rssiRange').length>0){
         this.inputFields.splice(4,0,{
            id: 'rssiValue',
            defaultValue: 'feild',
            displayLabel: 'custom rssiValue',
            required: true,
            type: 'text',
            value: this.gatewayDetailsObj?.properties ? this.gatewayDetailsObj?.properties?.attributes?.rssiRange:'',
            isDynamicField: false
          });
        }

          this.formGroup = this._dynamicFormService.createFormGroup(this.inputFields);
       
          this.isDetailsFetching = false;
  }
  closePanel(isReload){
    this.closeAddGatewaySidePanel.emit(isReload);
  } 

  get tabSelected() {
    return this.tabSelectedEvent.getValue();
  }

  onTabClick(tab: MatTabGroup) {
    if (tab.selectedIndex == this.tabSelected) return;
    const currentIndex = tab.selectedIndex;
    const form = this.formRenderer?.onSubmit();
    if (form?.touched || this.bulkFormData) {
      tab.selectedIndex = this.tabSelected;
      this._dialog
        .open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Discard',
            message: `Are you sure want discard   ${
              this.bulkFormData ? 'File' : 'Gateway'
            } ?`,
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            tab.selectedIndex = currentIndex;
            this.tabSelectedEvent.next(currentIndex);
            return;
          }
          tab.selectedIndex = this.tabSelected;
        });
      return;
    }
    this.tabSelectedEvent.next(currentIndex);
  }

  async onBulkUpload() {
    const payload: any = {
      file: this.bulkFormData,
      ...this.bulkConfig,
    };
    if (!payload.file) {
      this._toastService.info('select file to upload');
      return;
    }
    this.submitted = true;
    this._bulkService
      .uploadBulkActionFile(payload)
      .pipe(finalize(() => (this.submitted = false)))
      .subscribe({
        next: (res: any) => {
          this.closePanel(false);
          this._toastService.openCloseHandlerSnackBar(
            [`Gateway data is being processed and will load in the background. A notification will be sent when the data processing and loading is completed.`]
          ,'',['toast-success']);  
        },
      });
  }


  async onSave() {
    if (this.tabSelected === 1) {
      this.onBulkUpload();
      return;
    }

    this.submitted=true;
    const formData = this.formRenderer?.onSubmit();
    if (formData?.valid) {
      const dynamicFormPayload = this._dynamicFormService.getDynamicFormPayload(
        formData,
        this.inputFields
      );
      const payload = { 
        ...dynamicFormPayload,  
          attributes: {
              rssiRangeDisplay: dynamicFormPayload.rssiRange,
              rssiRange: this.formGroup.value?.rssiValue?this.formGroup.value?.rssiValue+'':'',
              rssiValue: this.formGroup.value?.rssiValue?this.formGroup.value?.rssiValue.toString():''
          }, 
          rssiRange:undefined,  
          rssiValue:undefined       
      };  
      this.isCreatingGateway = true;
      try {
        if (this.isEdit) {
          await this._gatewayService.updateGateway(
            payload,            
            this.gatewayId,
            this.entityTypeKey
          );
        } else {
          await this._gatewayService.createGateway(
            payload,this.catalogType.objectType
          );
        }
        this.closePanel(true);
        this._toastService.success(
          `Gateway ${this.isEdit ? 'updated' : 'created'} successfully`
        );
      } catch (error) {}

      this.isCreatingGateway = false;
    }
  }

  inputBlur(e){
    let checkCustom:any = Object.values(e)[Object.keys(e).indexOf('rssiRange')];
    let updatedVal;
    if(checkCustom){
      updatedVal= checkCustom.replace(/\s+/g,' ').trim();
    }
    if(Object.keys(e).indexOf('rssiRange')>-1 && updatedVal=='CUSTOM'){
      this.showRSSIValue=true;
    this.formGroup.addControl('rssiValue', new FormControl('', Validators.required))
    }else if(Object.keys(e).indexOf('rssiRange')>-1 && Object.values(e)[Object.keys(e).indexOf('rssiRange')] && updatedVal!=='CUSTOM'){
      this.showRSSIValue=false;
      this.formGroup.removeControl('rssiValue');
    } 
    if(Object.keys(e).indexOf('type')>-1){
      if(this.formGroup.controls['rssiRange']?.value?.toLowerCase()!=='custom'){
        this.formGroup.removeControl('rssiValue');
        const formattedInput =  this.inputFields.splice(this.inputFields.findIndex(item=>item.id==='rssiValue'),0);
      }
      this.inputFields.map((item:any)=>{
        if(item.id==='rssiRange' && e.type === 'Zone Sensor')
        item.options=['SHORT (-65)', 'MEDIUM (-75)', 'CUSTOM'];
        else if(item.id==='rssiRange' && e.type === 'Gateway')
        item.options=['SHORT (-55)','MEDIUM (-70)','LARGE (-95)','CUSTOM'];
      })
      this.formGroup = this._dynamicFormService.createFormGroup(this.inputFields);     
    }
  } 

  async getDeviceTypesList() {
    this.catalogTypesList =
      await this._extendedAttributeService.getCatalogExtendedDeviceColumns('device',this.withField,
        'gateway'
      );
      this.catalogTypesList.sort(function (a,b) {
        return a.name.toLowerCase()>b.name.toLowerCase()?1:-1
    })
  }

  onNext() {
    if(!this.catalogType){
      this.isCatalogTypeError = true;
      return;
    }
    this.changeCatalogType(this.catalogType.objectType);
    this.tabSelectedEvent.next(0);
  }
  
}
