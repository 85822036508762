<div class="list-serach">
    <input 
    type="text" 
    class="list-page__search-field" 
    [placeholder]="placeholder" 
    [(ngModel)]="searchTearm" 
    (input)="tiggerSearchEvent()" />
    <i class="fa fa-times reset-search-icon" [ngClass]="columns.length>3?'help-icon-enable':''" *ngIf="searchTearm" (click)="searchTearm = '';tiggerSearchEvent()"></i>
    <span class="search-button cursor-pointer fa fa-search" (click)="tiggerSearchEvent()"></span>
    <i class="fa fa-info-circle help-icon color4 text-lg ml-2" [title]="helpText + ': ' + columns.join(', ')" *ngIf="columns.length>3" (click)="openHelpDialog()"></i>
</div>

