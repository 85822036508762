<div class="mt-2 mb-2">
  <span class="page-title">Products</span>
</div>
<cl-table-surtitle
  [rows]="showingHits"
  [selected]="selected.length"
  [total]="totalHits"
  [pageName]="pageName"
></cl-table-surtitle>
<div
  class="sensor-febric wt-ngx-grid-wrapper list-page-wrapper relative list-page px-2"
>
  <div class="flex justify-between pt-1 pb-1">
    <div class="action-panel-left self-start flex items-center">
      <span
        (click)="toggleFilterPanel()"
        class="cursor-pointer color4 list-page_filter-toggle flex items-center"
      >
        <i
          class="fa fa-sliders mr-2 ml-3 font-18-normal"
          aria-hidden="true"
        ></i>
        filter options
      </span>
      <cl-list-search
        [placeholder]="'Search'"
        (search)="updateFilter($event)"
        [columns]="['SKU', 'Description', 'Manufacturer/Vendor']"
      ></cl-list-search>
      <span class="action-separator mr-2" [clIsValidAction]="actionBtnPolicies.edit || actionBtnPolicies.delete"></span>
      <button
        [disabled]="editDisabled"
        title="Edit the selected SKU"
        [ngClass]="[
          editDisabled
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]"
        [clIsValidAction]="actionBtnPolicies.edit"
        class="p-4-10 border-general br2"
        (click)="openCreateSidePanel(true); closeSummaryPanel()"
      >
        <i class="fa fa-pencil"></i>
        Edit
      </button>
      <button
        [disabled]="deleteDisabled"
        [clIsValidAction]="actionBtnPolicies.delete"
        title="Delete all selected products"
        [ngClass]="[
          deleteDisabled
            ? 'color37 bcolor36 bgcolor38'
            : 'color4 bcolor4 bgcolor32'
        ]"
        class="p-4-10 border-general br2 ml10"
        (click)="deleteSkuDialog()"
      >
        <i class="fa fa-trash"></i>
        Delete
      </button>
      <button class="color4 ml10" (click)="showBulk()" [clIsValidAction]="actionBtnPolicies.create">
        Bulk Add
      </button>
    </div>
    <div class="sensor-febric__action-panel-right action-panel-right self-end flex items-center mr-16">
      <!--temp hide-->
      <!-- <span class="dark-blue-text action-separator colimn-serach-btn" (click)="showHideFilter()"><i
          class="fa fa-search-plus color4"></i></span> -->
      <div><button (click)="showHideFilter()" class="toggler-example align-grid-menu p-2 border-0 py-2 colsearch-menu">
            <span class="dark-blue-text" title="Search data by table columns">
              <i _ngcontent-bum-c250="" class="fa fa-search-plus color4"></i>
            </span>
          </button>
      </div>
      <cl-export-panel
        (exportEmitter)="downloadReport($event)"
      ></cl-export-panel>
      <cl-grid-column-toggle
        [columnList]="rawColumns"
        (toggleColumns)="gridColumnToggle($event)"
        [page]="'sensor'"
      >
      </cl-grid-column-toggle>
    </div>
    <div
      class="md-add-button list add-shipment-btn"
      style="z-index: 999"
      *ngIf="!showSummaryPanel"
      [clIsValidAction]="actionBtnPolicies.create"
    >
      <div
        class="add-button"
        *ngIf="!showSidePanel || mode == 'summary'"
        (click)="openCreateSidePanel(false)"
      >
        <span class="fa fa-plus"></span>
      </div>
      <div
        class="add-button"
        (click)="closeSidePanel()"
        *ngIf="showSidePanel && mode !== 'summary'"
      >
        <span class="fa fa-close"></span>
      </div>
    </div>
  </div>
  <div class="h-calc-40">
    <main
      class="flex flex-row h-100 overflow-y-hidden"
      [style.width.px]="mainWidth - 10"
    >
      <div class="grow-0" *ngIf="showFilterPanel">
        <cl-list-filter
          class="nav panel scroll-y grow-0"
          [hideTitle]="true"
          [showCounts]="false"
          [title]="''"
          heightClass="none"
          (panelsEmitter)="getPanelUpdate($event)"
          (toggleFilterPanel)="toggleFilterPanel()"
          [filters]="filters"
          [caseSenstiveFilter]="'type'"
          [actionCount]="actionCount"
        >
        </cl-list-filter>
      </div>
      <div class="grid-container grow relative">
        <div *ngIf="isLoading">
          <div class="spin-pos">
            <i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i>
          </div>
        </div>
        <div *ngIf="delayedRefreshStart">
          <cl-refresh-countdown
            [start]="delayedRefreshStart"
            [timer]="refreshTimer"
            (refreshList)="getSkuList()"
          ></cl-refresh-countdown>
        </div>
        <div class="h-100" [style.width.px]="tableWidth">
          <ngx-datatable
            #skuList
            class="h-calc-40"
            [rows]="rows"
            [columns]="columns"
            [columnMode]="'force'"
            [loadingIndicator]="isLoading"
            [headerHeight]="headerHeight"
            [rowHeight]="rowHeight"
            [scrollbarV]="true"
            [scrollbarH]="true"
            [selected]="selected"
            [sortType]="sortType"
            [selectionType]="selection"
            [rowIdentity]="getId"
            [selectAllRowsOnPage]="false"
            (scroll)="onScroll($event.offsetY)"
            (select)="onSelect($event)"
            [reorderable]="false"
            #table
          >
          </ngx-datatable>
          <ng-template #clearTemplate>
            <span
              class="fa fa-times-circle-o mr-4 clear-filter"
              *ngIf="showFilter"
              (click)="closeFilter()"
            ></span>
          </ng-template>
          <ng-template #hdrTpl let-column="column" let-sort="sortFn">
            <span class="header-sort-span" (click)="sort()">
              {{ column.name }}
            </span>
            <span class="header-filter-span input-filter-parent">
              <input
                *ngIf="showFilter"
                [(ngModel)]="columnFilters[column.prop]"
                (input)="filterInput($event)"
                class="input-filter"
                type="{{ column.prop == 'phone_call_date' ? 'date' : 'text' }}"
              />
            </span>
          </ng-template>

          <div class="flex flex-row items-center justify-center">
            <button
              class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
              [ngClass]="{ bcolor9: isLoading || allLoaded }"
              (click)="getLoadMoreUpdate()"
              [disabled]="isLoading || allLoaded"
            >
              <span class="mat-button__ripple"></span>
              <span
                class="mat-button__label color4"
                [ngClass]="{ color9: isLoading || allLoaded }"
                >load next 50</span
              >
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>

  <ng-template #dateTemplate let-row="row" let-value="value">
    {{ value | formateDate : "default" }}
  </ng-template>
  <ng-template #skuNameTemplate let-row="row" let-value="value" let-i="index">
    <a
      class="font-12-bold"
      href
      (click)="loadSummaryPanel(row, $event); closeSidePanel()"
      >{{ value }}</a
    >
  </ng-template>
  <div class="summery-pannel" *ngIf="showSummaryPanel == true">
    <cl-sku-summary
      [skuId]="roleObject?.id"
      [skuObject]="roleObject"
      (closeSidePanel)="closeSummaryPanel()"
    >
    </cl-sku-summary>
  </div>
  <div class="summery-pannel" *ngIf="showSidePanel">
    <cl-sku-add-panel
      *ngIf="mode === 'create' || mode === 'edit'"
      [skuId]="skuId"
      [isEdit]="mode === 'edit'"
      (closeSidePanel)="closeSidePanel($event)"
    ></cl-sku-add-panel>
  </div>
</div>
<div *ngIf="showBulkAdd" class="bulk-panel">
  <cl-bulk-action-panel
    [panelObj]="bulkPanelObj"
    (closeSidePanel)="closeBulkSidePanel()"
    [downloadedCSVFileName]="'sku_add_template'"
    [fields]="downloadedCSVFields"
    (refreshParentList)="reloadSkuList()"
  ></cl-bulk-action-panel>
</div>
