import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MeasureType, WebUIParsedConfig, AlertIcon } from '@cl/@types/measure.type';
import * as _ from 'lodash';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { UtilsService } from '@cl/common/utils/utils.service';

@Component({
  selector: 'cl-characteristic-list',
  templateUrl: './characteristic-list.component.html',
  styleUrls: ['./characteristic-list.component.scss']
})
export class CharacteristicListComponent implements OnChanges {
  @Input('measures') measures: MeasureType[];
  @Input('data') data: ApexAxisChartSeries | any;
  @Output('change') measureUpdated: EventEmitter<MeasureType>;
  @Input('tooltip') tooltipCustionHandler: Function;
  @Input('annotations') annotations: any;
  options: WebUIParsedConfig = { dateRanges: [], displayType: '', groupBy: '', is3Axes: false, isBinary: false, isConditionDataPlot: false, order: 0, rangedDefault: '', showIn: '', title: '', unitsfx: ''};
  activeMeasure: MeasureType;
  isMeasuresNotEmptyWithValue: boolean = false;
  constructor( public _utils: UtilsService) {
    this.measureUpdated = new EventEmitter<MeasureType>();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('measures') && !_.isEmpty(this.measures)){
      this.measures = _.filter(this.measures, function(measure) {
        return measure.config['displayType'] ===  'graph';
    });
    this.changeMeasure(this.measures[0]);
    }
  }
  changeMeasure(measure: MeasureType){
    this.activeMeasure = measure;
    this.setCustomChartOptions();
    this.measureUpdated.emit(measure);
    this.verifyMeasuresEmpty();
  }
  private setCustomChartOptions(): void{
    this.options = this.activeMeasure.config;
  }
  private verifyMeasuresEmpty() {
    let list: MeasureType[] = _.filter(this.measures, (item) => {
      return !_.isUndefined(item.value) && !_.isNull(item.value);
    });
    this.isMeasuresNotEmptyWithValue = !_.isEmpty(list);
  }
  formatAlertObject(measure: any): AlertIcon {
    let alertIconObject: AlertIcon = {
      iconColor: measure.violated ? '#FF2500' : '#2879DE',
      measure:  measure.measureServiceId != 'PredictiveMetric'  ? measure.measureLabel : measure.cId,
      width: 24,
      height: 24
    }
    return alertIconObject;
  }
}
