<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title side-panel-header-bg justify-between items-center">
        <div class="grow flex items-center">
            <img [src]="panelObj?.icon" />
            <span class="black font-bold">{{panelObj?.title}}</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content flex flex-col">
        <!-- below code is to be removed as to create reusable component 'bulk-action-component' -->
        <cl-bulk-action [panelObj]="panelObj" [showResponse]="showResponse" [bulkResponseArr]="bulkResponseArr" (emitFormData)="getFormData($event)" [fields]="fields" [downloadedCSVFileName]="downloadedCSVFileName"></cl-bulk-action>

    </section>
    <section class="cl-side-panel-footer bgcolor33 pr-4 pl-4">
        <div class='flex justify-end items-center'>
            <a formnovalidate class=" m-2" (click)="closePanel()">Cancel</a>
            <button class="panel-button white bgcolor4"
                [disabled]="uploadClicked"
                (click)="uploadBulk()">
                Upload
                <i class="fa fa-circle-o-notch fa-pulse white" *ngIf="isUploadInProgress"></i>
            </button>
        </div>
    </section>
</div>
