<div class="user-summary-panel">
  <cl-side-panel (closePanel)="closePanel()">
    <section class="cl-side-panel-title side-panel-header-bg py-1.5 px-2">
      <span *ngIf="mode === 'edit'" class="black font-bold"
        >{{'logistics.editShipment'|translate}}</span
      >
      <span *ngIf="mode === 'add'" class="black font-bold"
        >{{'logistics.newShipment'|translate}}</span
      >
    </section>
    <section class="cl-side-panel-content pr-1 mr-1">
      <div class="user-detail__item  p-0" style="width: 100%;" *ngIf="!shipmentCatalog?.name; else shipmentForms">
        <label class="form-label font-bold pb-1.5 px-2 mb-0">{{'logistics.shipmentType'|translate}}</label>
        <div class="pb-1.5 px-2 ">
          <mat-select 
            class="basic-input px-2 " style="width: 100%;"
            placeholder="Select Shipment Type"
            [(ngModel)]="catalogType"
          >
            <mat-option
              [value]="catalogType"
              *ngFor="let catalogType of catalogTypesList"
              >{{ catalogType.name }}</mat-option
            >
          </mat-select>
          <span *ngIf="isCatalogTypeError" class="text-danger">Please Select Shipment Type</span>
        </div>
      </div>
      <ng-template #shipmentForms>
        <div class=" sticky top-0 bg-white z-[999]">
          <span class="flex items-center px-2 h-6">
            <span
              class="black font-bold text-lg pr-2  py-1.5 pointer color4 "
              *ngIf="mode == 'add'"
              (click)="shipmentCatalog = undefined"
            >
              <i class="fa fa-chevron-circle-left"></i>
            </span>
            <label
              class="black text-sm truncate pr-2"
              [title]="shipmentCatalog?.name"
            >
              {{ mode == 'add' ? "New" : "Edit" }} {{ shipmentCatalog?.name }}
            </label>
          </span>
          <div class="pb-1.5 px-2">
            Fields marked with<span class="text-red-500 text-base font-bold"> * </span>are required
          </div>
        </div>
        <cl-shipment-standard
          *ngIf="baseCatalogType == 'standardshipment'"
          (isDraftHideEmitter)="isDraftHide = $event"
          [shipmentID]="shipmentID"
          [isEdit]="this.mode === 'edit'"
          [shipmentCatalog]="shipmentCatalog"
        ></cl-shipment-standard>

        <cl-shipment-parcel
          *ngIf="['parcelshipment', 'dtpshipment'].includes(baseCatalogType)"
          (isDraftHideEmitter)="isDraftHide = $event"
          [shipmentID]="shipmentID"
          [isEdit]="this.mode === 'edit'"
          [shipmentCatalog]="shipmentCatalog"
        ></cl-shipment-parcel>

        <cl-shipment-composite
          *ngIf="baseCatalogType == 'compositeshipment'"
          [shipmentID]="shipmentID"
          [isEdit]="this.mode === 'edit'"
          [shipmentCatalog]="shipmentCatalog"
        ></cl-shipment-composite>
      </ng-template>
    </section>

    <section
      class="cl-side-panel-footer bgcolor33 p-2"
      *ngIf="mode === 'edit' || mode === 'add'"
    >
      <div class="flex">
        <div class="flex-col grow text-right">
          <a class="m-2" (click)="closePanel()" title="cancels the changes and closes the form">{{'common.cancel'|translate}}</a>
          <button
            [disabled]="isLoading"
            [ngClass]="isLoading ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor4 bgcolor32'"
            *ngIf="!isDraftHide && shipmentCatalog && shipmentCatalog.catalogType !== 'compositeshipment'"
            class="panel-button white ml-2 p-4-10 border-general br2"
            (click)="onSave('draft')"
            [title]="
              'A ' +
              shipmentCatalog?.name +
              ' Shipment can be saved in Draft status without providing all of the required details'
            "
          >
            {{'logistics.saveDraft'|translate}}
            <span
              *ngIf="isLoading && loadingAction === 'draft'"
              class="fa fa-circle-o-notch fa-pulse"
              [ngClass]="{ visible: isLoading, hidden: !isLoading }"
            ></span>
          </button>
          <button
            [disabled]="isLoading"
            [ngClass]="isLoading ? 'color37 bcolor36 bgcolor38' : ''"
            class="panel-button white bgcolor4 ml-2"
            (click)="shipmentCatalog ? onSave('save') : onNext()"
            [title]="
              'Saving a ' +
              shipmentCatalog?.name +
              ' Shipment will save and immediately publish the shipment to allow for tracking and tracing'
            "
          >
            {{ shipmentCatalog ? ('common.save'|translate) : ('common.next'|translate) }}
            <span
              *ngIf="isLoading && loadingAction === 'save'"
              class="fa fa-circle-o-notch fa-pulse white"
              [ngClass]="{ visible: isLoading, hidden: !isLoading }"
            ></span>
          </button>
        </div>
      </div>
    </section>
  </cl-side-panel>
</div>
