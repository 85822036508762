import { Component, Input, OnInit } from '@angular/core';
import { Sensor, SensorStatusLabels } from '@cl/@types/sensor.type';
import * as _ from 'lodash';

@Component({
  selector: 'cl-sensor-conditions',
  templateUrl: './sensor-conditions.component.html',
  styleUrls: ['./sensor-conditions.component.scss']
})
export class SensorConditionsComponent implements OnInit {
  @Input('sensor') sensorObject: Sensor;
  constructor() { }
  getSensorOverallStatus(): string {
    return _.isEmpty(this.sensorObject?.attributes?.overallStatus) ? 'N/A' : SensorStatusLabels[this.sensorObject?.attributes?.overallStatus];
  }
  getBattery(){
    return _.isNumber(this.sensorObject?.attributes?.battery) ? (this.sensorObject?.attributes?.battery + this.sensorObject?.attributes?.batteryUom) : 'N/A';
  }
  getRSSI(){
    return _.isNumber(this.sensorObject?.attributes?.rssi) ? (this.sensorObject?.attributes?.rssi + this.sensorObject?.attributes?.rssiUom) : 'N/A';
  }
  ngOnInit(): void {
  }
}
