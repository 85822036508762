import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { firstValueFrom, of } from 'rxjs';
import { UserService } from '@cl/user/user.service';
import { PropertyService } from '@cl/property/property.service';
@Injectable({
  providedIn: 'root',
})
export class ApiSubscriptionService {
  private hmac;

  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService,
    private _userService: UserService,
    private _propertyService: PropertyService
  ) {}

  get webhookSecretKey() {
    if (!this.hmac) {
      this.hmac = this._propertyService.getSetting('tenant.webhook.hmac');
    }
    return this.hmac;
  }

  set webhookSecretKey(hmac: string) {
    this.hmac = hmac;
  }

  getApiSubscriptions(): any {
    const obs$ = this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/list`
    );
    return firstValueFrom(obs$);
  }

  updateWebhookSubscriptionKey(webhookKey: string): any {
    const tenantId = this._userService.getTenantId();
    const obs$ = this.http.patch(
      `${this.apiProvider.getAPIUrl(
        'cloudos'
      )}api/1/webhookSubscription/macid?tenantId=${tenantId}`,
      {
        'tenant.webhook.hmac': webhookKey,
      }
    );

    return firstValueFrom(obs$);
  }

  getWebhookSubscriptionKey(): any {
    const obs$ = this.http.get(
      `${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/macid`
    );
    return firstValueFrom(obs$);
  }

  getSubscriberType(val:any) {
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/${val}`);
    return firstValueFrom(obs$);
  }

  createSubscriber(payload){
      return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription`, payload);
  }

  // getSubscriberDetails(){

  // }

  updateSubscriber(data, subscriberId:any){
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/id/${subscriberId}`, data);
  }

  deleteSubscriptions(payload){
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
  return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/bulk/events`, options);
  }

  updateStatus(payload){
    return this.http.patch(`${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/bulk/events`, payload);
  }

  getApiSubscriptionData(val:any) {
    const obs$ = this.http.get<any>(`${this.apiProvider.getAPIUrl('cloudos')}api/1/webhookSubscription/id/${val}`);
    return firstValueFrom(obs$);
  }
}
