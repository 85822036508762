import { Injectable } from '@angular/core';
import * as _ from 'lodash';

// import * as dtMapData from '../../common/json/mocks/dtMapData.json';

import { DtApiService } from './dt-api.service';
import { CloudleafService } from './cloudleaf.service';
import { DataMethodsService } from './data-methods.service';
import { AppDataService } from './app-data.service';
import { IconSvgService } from './icon-svg.service';

// import * as dtGraphs from '../../mocks/dtGraphs.json';
interface KlData {
  type: '',
  items: [],
  combos: []
}
@Injectable({
  providedIn: 'root'
})
export class DtDataService {
  dts$;
  dtCounts$ = {};
  dtGraphs$ = {};
  dtsObj = {};
  selectedDt;
  dtMapDataSets$ = {};
  dtMapData = {};
  assetsByCategory = {};
  domainQuestions;
  answers = {};
  _data = {
    allNodes: [],
    nodes: [],
    nodesObj: {},
    links: [],
    bisectorsObj: {},
    colors: [],
    activeObj: {},
    activeEntity: {}
  };
  private activeEntity;
  useMocks = false;
  co = { // canvas options
    comboMode: false,
    showLinks: true,
    showTopLocsByAlert: false
  }
  constructor(
    private _dtApiService: DtApiService,
    private _cloudleafService: CloudleafService,
    private _iconSvgService: IconSvgService,
    private _dataMethodsService: DataMethodsService,
    private _appDataService: AppDataService
  ) { }

  setData(graphData) {
    this._data = graphData;
    if (graphData) {
      this.activeEntity = graphData.activeEntity;
    }
    return graphData;
  }
  setSelectedDt(dt) {
    this.selectedDt = dt
    this.dtsObj[dt] = dt;
    return dt;
  }
  setCanvasOptions(co) {
    this.co = Object.assign({}, co);
  }
  getCanvasOptions() {
    return this.co;
  }
  mapBaseTypes(countsObj) {
    let counts = [];
    _.forOwn(countsObj, (val, key) => {
      key = (key === 'excursion') ? 'incident' : key;
      val = { ...val, baseType: key }
      counts.push(val);
    });
    return counts;
  }

  // getDtGraph(id, refresh) {
  //   if (refresh || !this.dtGraphs$ || !this.dtGraphs$[id]) {
  //     this.dtGraphs$[id] = this._dtApiService.getDtGraph(id);
  //   }
  //   return this.dtGraphs$[id]
  // }

  processMapData(res, args, assetList) {
    if (res && res.nodes) {
      res.asset.data.nodes = res.asset.data.nodes || [];
      res.asset.data.nodes = [...res.asset.data.nodes, ...assetList];
      this._data.nodes = [...res.nodes, ...assetList]
      this._data.nodesObj = {};
      assetList.forEach( (el, i) => {
        this._data.nodesObj[el.properties.id] = el;
      });
      this._data.bisectorsObj = {};
      args = args || {};
      if (args.showAlerts) {
        const formattedData = this._cloudleafService.formatGraphData(res, this._data, { findAlerts: args.showAlerts });
        this._data = Object.assign({}, this._data, formattedData);
        this._appDataService.filterComplimentaryLinks(this._data);
      }
    }
    return this._data;
  }
  updateActiveEntity(node) {
    this.activeEntity = { ...this.activeEntity, ...node }
    return this.activeEntity;
  }
  setActiveEntityUID(uid, type) {
    if (!uid) {
      this.activeEntity = null;
    } else {
      [this._data, this.activeEntity] = this._dataMethodsService.setActiveEntityUID(uid, type, this._data, this.activeEntity);
      // this.moveActiveToTop(uid);
      if (this.activeEntity) {
        this.activeEntity.x = 400;
        this.activeEntity.y = 250;
      }
    }
    return this.activeEntity;
  }
  addEntityAsActive(newNode, type) {
    this.activeEntity = Object.assign({}, newNode);
    this._data.activeEntity = this.activeEntity;
    this._data.nodesObj[this.activeEntity.uid] = this.activeEntity;
    // this.moveActiveToTop(uid);
    return [this._data, this.activeEntity];
  }
  getActiveEntity() {
    return this.activeEntity;
  }
  getGraphData() {
    return this._data;
  }
  setGraphData(graphData) {
    this._data = graphData;
  }
  updateNode(newObj) {
    if (!newObj || _.isEmpty(newObj)) {
      return this._data
    }
    const uid = newObj.uid;
    const updatedNode = Object.assign({}, this._data.nodesObj[uid], newObj);
    this._data.nodesObj[uid] = updatedNode;
    this.setActiveEntityUID(uid, "node");

    this.updateNodeArrayItem(updatedNode);
    return this._data;
  }
  updateNodeArrayItem(thisNode) {
    this._data.nodes.forEach((node, i) => {
      if (node.uid === thisNode.uid) {
        this._data.nodes[i] = Object.assign({}, node, thisNode);
      }
    });
  }
  updateBinode(mess) {
    const binode = mess.binode;
    // console.log('updateBinode', binode);
    if (binode) {
      this._data.bisectorsObj[binode.bisectorID] = Object.assign(
        {},
        this._data.bisectorsObj[binode.bisectorID],
        mess.binode
      );
      this.updateBisectorArray(this._data.bisectorsObj[binode.bisectorID]);
    }
  }
  updateBisectorArray(thisBisector) {
    this._data.links.forEach((bisector, i) => {
      if (bisector.bisectorID === thisBisector.bisectorID) {
        this._data.links[i] = Object.assign({}, thisBisector);
      }
    });
  }
  getBisectors() {
    return this._data.links;
  }
  getAssetsByCategory(id, refresh) {
    const promise = new Promise((resolve, reject) => {
      if (!refresh && this.assetsByCategory && this.assetsByCategory[id] > 0) {
        // console.log('resolve saved dtGraph', this.dtGraph);
        resolve(this.assetsByCategory[id]);
      }
      // else {
      //   this._dtApiService.getDomainQuestion(id, true, 'category', 'asset').subscribe(res => {
      //     if (res) {
      //       this.assetsByCategory[id] = Object.assign({}, res);
      //       resolve(res);
      //     }
      //   })
      // }
    });
    return promise;
  }
  getLists(graphData) {
    const listsObj = {
      mockEvents: [],
      shipmentsByIncident: [],
      locationsByIncident: [],
      mostAlerts: 0
    },
      locationsByIncidentObj = {};

    let targetNode;
    _.forOwn(graphData.nodesObj, (val, key) => {
      if (val.nodeClass === 'incident' ||
        (val.nodeClass === 'shipment' && val.properties.status === 'Completed')) {
        listsObj.mockEvents.push(val)
      } else if (val.nodeClass === 'shipment' && val.alertsObj) {
        listsObj.shipmentsByIncident.push(val)
      } else if (val.nodeClass === 'location' && val.targetIDs) {
        _.forOwn(val.targetIDs, (targetVal, targetKey) => {
          targetNode = graphData.nodesObj[targetKey];
          if (targetNode && targetNode.alertsObj) {
            val.alertsAtLoc = val.alertsAtLoc ? val.alertsAtLoc + targetNode.alertsObj.count : targetNode.alertsObj.count;
            locationsByIncidentObj[val.uid] = Object.assign({}, val);
          }
        })
      }
    })
    // console.log('locationsByIncidentObj', locationsByIncidentObj);
    _.forOwn(locationsByIncidentObj, (val, key) => {
      listsObj.locationsByIncident.push(val);
      if (val['alertsAtLoc'] > listsObj.mostAlerts) {
        listsObj.mostAlerts = val['alertsAtLoc'];
      }
    })
    listsObj.shipmentsByIncident.sort((a, b) => {
      return b.alertsObj.count - a.alertsObj.count
    })
    listsObj.locationsByIncident.sort((a, b) => {
      return b.alertsAtLoc - a.alertsAtLoc
    })
    const rFactor = 150 * (listsObj.mostAlerts / 100);
    listsObj.locationsByIncident.forEach((loc, i) => {
      listsObj.locationsByIncident[i].radius = 4 + Math.round(loc.alertsAtLoc * rFactor);
    })
    return listsObj;
  }
  getNodeListChildren(nodeList, graphData, tiers) {
    const nodes = [];
    let targetNode;
    nodeList.forEach(loc => {
      _.forOwn(loc.targetIDs, (val, key) => {
        targetNode = graphData.nodesObj[key];
        nodes.push(targetNode);
        if (tiers === 2) {
          _.forOwn(targetNode.targetIDs, (targetVal, targetKey) => {
            nodes.push(graphData.nodesObj[targetKey]);
          })
        }
      })
    })
    return nodes;
  }
  resetRadiusVals() {
    _.forOwn(this._data.nodesObj, (val, key) => {
      this._data.nodesObj[key].radius = 8;
    })
    return this._data;
  }
  // getDomainQuestions(refresh) {
  //   const promise = new Promise((resolve, reject) => {
  //     if (!refresh && this.domainQuestions) {
  //       // console.log('resolve saved dtGraph', this.dtGraph);
  //       resolve(this.domainQuestions);
  //     } else {
  //       this._dtApiService.getDomainQuestions().subscribe(res => {
  //         if (res) {
  //           this.domainQuestions = Object.assign({}, res);
  //           // console.log('resolve  domainQuestions', res);
  //           resolve(res);
  //         }
  //       })
  //     }
  //   });
  //   return promise;
  // }
  // answerQuestion(q, id, refresh) {
  //   const promise = new Promise<KlData>((resolve, reject) => {
  //     if (!refresh && this.answers && this.answers[id] && this.answers[id][q.label]) {
  //       // console.log('resolve saved dtGraph', this.dtGraph);
  //       resolve(this.answers[id][q.label]);
  //     } else {
  //       this._dtApiService.getDomainQuestion(id, true, q.fieldName, q.entityClass).subscribe(res => {
  //         if (res) {
  //           this.answers[id] = this.answers[id] || {}
  //           this.answers[id][q.label] = Object.assign({}, res);
  //           // console.log('resolve  answers', res);
  //           resolve(res);
  //         }
  //       })
  //     }
  //   });
  //   return promise;
  // }

  setTenancyData(mgs) {
    let tenancyData = [];
    // console.log('setTenancyData', data);
    mgs.forEach(mg => {
      if (mg.class === 'asset' || mg.class === 'inventory' ||
        mg.class === 'location' || mg.class === 'shipment') {
        tenancyData.push({
          name: mg.class === 'location' ? 'Sites' : mg.name,
          count: mg.count,
          icon: '/assets/svgs/cl-icons/' + this._iconSvgService.getSVG(mg.class) + '.svg'
        })
      }
    })
    return tenancyData;
  }
  setTenancyDataFromKPI(mgs) {
    let tenancyData = [];
    // console.log('setTenancyData', data);
    mgs.forEach(mg => {
      const mgClass = mg?.name?.toLowerCase();

      if (mgClass === 'assets' || mgClass === 'inventory' ||
        mgClass === 'sites' || mgClass === 'shipment') {
        tenancyData.push({
          name: mg.name,
          count: mg.value,
          icon: '/assets/svgs/cl-icons/' + this._iconSvgService.getSVG(mgClass) + '.svg'
        })
      }
    })
    return tenancyData;
  }
  clearTenancyData(tenancyData) {
    if (tenancyData && tenancyData.length) {
      tenancyData.forEach(td => {
        td.count = 0;
      })
    }
    return tenancyData;
  }
  clearMetricData(metricData) {
    _.forOwn(metricData, (val, key) => {
      if (val.list && val.list.length) {
        val.list.forEach(md => {
          md.count = 0;
        })
      }
    })
    return metricData;
  }
  setMetricData(mgs) {
    let metricData = {};
    mgs.forEach(mg => {
      if (mg.class === 'asset' || mg.class === 'inventory' ||
        mg.class === 'incident' || mg.class === 'shipment') {
        if (mg.filters && mg.filters.length) {
          metricData[mg.class] = metricData[mg.class] || {};
          metricData[mg.class].list = metricData[mg.class].list || [];
          mg.filters.forEach(filt => {
            if (filt.list && filt.list.length) {
              filt.list.forEach(item => {
                metricData[mg.class].list.push({
                  label: item.name,
                  count: item.count
                })
              })
            }
          })
        }
      }
    })
    let total;
    _.forOwn(metricData, (el: any, key) => {
      el.total = 0;
      el.unit = '%';
      el.list.forEach(item => {
        el.total += item.count;
      })
      el.list.forEach(item => {
        total += item.count;
        item.percentage = Math.round((item.count / el.total) * 100 - .1);
      })
    })
    return metricData;
  }
}
