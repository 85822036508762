import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';

// import { Card } from "@cl/models";
// import { ClientConfigState } from '@cl/ngxs/state';
// import { Observable } from 'rxjs';

@Component({
  selector: "cl-card-carousel",
  templateUrl: "./card-carousel.component.html",
  styleUrls: ["./card-carousel.component.scss"],
  host: {
    "(window:resize)": "onResize($event)",
  },
})
export class CardCarouselComponent implements OnInit, OnChanges {
  @Input() DTs;
  @Input() selectedDt;
  @Input() tenancyData;
  @Input() cards;
  @Output() tenancyEmitter = new EventEmitter();
  favesObj: any = {};
  currentCardIndex: number = 0;
  numCards: number;
  numScreens: number;
  screenNum: number;
  windowWidth: number;
  offset: number;
  $carousel;
  randomInterval;
  countsObj = {};
  cardList = [];
  clientConfig$;
  cc_crds;
  numCardsClass = "";
  cardSize:number;
  constructor(private store: Store) {
    this.clientConfig$ = this.store.select(state => state.client_config.settings.cards)
  }

  ngOnInit() {
    this.watchCCState();
    this.$carousel = document.getElementById("fullframe");
    this.screenNum = this.getScreenNum();
    
  }
  watchCCState() {
    this.clientConfig$.subscribe((cc: any) => {
      this.cc_crds = { ...cc }

    })
  }
  ngOnChanges() {
    // console.log('selectedDt', this.selectedDt);
    // console.log('this.kpiData', this.kpiData);
    // this.updateCardValues(this.metricData);
    this.numCards = this.cards && this.cards.length ? this.cards.length : 0;
    this.numScreens = this.calcNumScreens();
    this.selectedDt = Object.assign({}, this.selectedDt);
    this.cards = this.sortCards(this.cards);
    this.numCardsClass = 'num-cards-' + this.cards.length;
    this.getFittedCardsPercentage();
  }
  getTenancyUpdate(message) {
    this.tenancyEmitter.emit(message);
  }

  sortCards(cards) {
    if (!cards || !cards.length) {
      return cards;
    }
    cards = cards.map((card) => {
      let showBookmarks = this.cc_crds && this.cc_crds.showBookmarks ? true : false;
      return { ...card, showBookmark: showBookmarks };
    });
    return cards.sort((a, b) => {
      let bNum = b.isFavorite ? 1 : 0;
      let aNum = a.isFavorite ? 1 : 0;
      return bNum - aNum;
    });
  }
  onFaveToggled(card) {
    this.cards.forEach((cd, i) => {
      if (cd.name === card.name) {
        this.cards[i].isFavorite = card.isFavorite;
      }
      this.favesObj[cd.name] = cd.isFavorite ? true : false;
    });
    this.cards = this.sortCards(this.cards);
    localStorage.setItem("cardFaves", this.favesObj);
  }
  controlsRightArrowClicked(evt) {
    let numScreens = this.calcNumScreens();
    if (this.screenNum < numScreens - 1) {
      this.screenNum++;
      this.setCarouselPosition();
    }
  }
  controlsLeftArrowClicked(evt) {
    if (this.screenNum > 0) {
      this.screenNum--;
      this.setCarouselPosition();
    }
  }
  controlsDotSelected(dot) {
    if (dot != this.screenNum) {
      this.screenNum = dot;
      this.setCarouselPosition();
    }
  }

  setCarouselPosition() {
    if (!this.$carousel) return;
    this.windowWidth = window.innerWidth;
    this.offset = -this.screenNum * this.windowWidth;
    this.$carousel.className("left", this.offset + "px");
  }

  getScreenNum() {
    if (!this.$carousel) return null;
    let leftVal: string = this.$carousel.className("left");
    let leftNum: number = +leftVal.replace("px", "");
    this.windowWidth = window.innerWidth;
    return Math.floor(leftNum / this.windowWidth);
  }

  onResize(event) {
    this.calcNumScreens();
    this.getFittedCardsPercentage();
  }

  getFittedCardsPercentage(){
    const windowWidth: number = window.innerWidth;
    const cardMinWidth = 316;
    const totalCards = this.cards?.length || 1;
    let cardsWidth = ((cardMinWidth ) * totalCards) < windowWidth ? windowWidth/totalCards : cardMinWidth;

    if(totalCards == 1){
      cardsWidth -= 8;
    }else if(totalCards == 3){
      cardsWidth -= 2;
    }

    this.cardSize = cardsWidth;
  }

  calcNumScreens(): number {
    var numScreens;
    this.windowWidth = window.innerWidth;
    if (this.windowWidth > 1024) {
      numScreens = Math.ceil(this.numCards / 4);
    } else if (this.windowWidth > 768) {
      numScreens = Math.ceil(this.numCards / 3);
    } else if (this.windowWidth > 550) {
      numScreens = Math.ceil(this.numCards / 2);
    } else {
      numScreens = this.numCards;
    }
    if (this.numScreens !== numScreens) {
      this.numScreens = numScreens;
      this.screenNum = 0;
      this.getScreenNum();
      this.setCarouselPosition();
    }
    return numScreens;
  }
}
