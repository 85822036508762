import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { SelectionType, SortType } from '@swimlane/ngx-datatable';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from "@cl/common/services/csv-exports.service";
import { UtilsService } from '@cl/common/utils/utils.service';
import { TenantService } from '../tenant.service';

@Component({
  selector: 'cl-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent implements OnInit, AfterViewInit {

  public tableRowData: any = [];
  public rows: any = [];
  public columns: any = [];
  public rawColumns: any = [];
  public selected: any = [];
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = false;
  public scrollId: string = '';
  readonly headerHeight = 25;
  readonly rowHeight = 25;
  public selection: SelectionType = SelectionType.checkbox;
  public sortType: SortType = SortType.multi;
  showSummaryPanel = false;
  mode = 'add';
  editDisabled = true;
  groupObject = {};
  allUserObject = [];
  columnKeys: string[] = [];
  actionBtnPolicies = {
    add: ['TENANT_CREATE'],
    edit: ['TENANT_UPDATE'],
    delete: ['TENANT_DELETE']
  };
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  mainWidth:number;
  tableWidth:number;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private el: ElementRef,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private csvExport: CsvExportsService,
    private _utilsService: UtilsService,
    private tenantService: TenantService
  ) {
  }

  ngOnInit(): void {
    this.getTenants();
  }

  ngAfterViewInit(): void {
    this.rawColumns =  [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: false,
        checkboxable: true,
        width: 30,
        visible: true
      },
      { name: 'Customer', prop: 'name', width: 180, visible: true }
    ];
    const {gridColumns, rawColumns} = this._utilsService.updateColumnsList('tenant', [...this.rawColumns]);
    this.rawColumns = [...rawColumns];
    this.columns = [...gridColumns];
    this.columnKeys = this.columns.map(col => { return { prop: col.prop, name: col.name }});
    // this._changeDetectorRef.detectChanges();
    this.setContainerWidth();
  }

  openSnackBar(action: any, name: any) {
    this._snackBar.open(action, name, {
      duration: 3000,
    });
  }

  gridColumnToggle(columns: any) {
    columns = columns.filter((col: any) => col.visible);
    this.columns = [...columns];
  }

  getTenants(): void {
    this.isLoading = true;
    let groupData:any = [];
    const tenantDataSubscription: Subscription = this.tenantService.getAllTenants().subscribe((data:any)=>{
      data.forEach((d:any) => {
        groupData.push(d);
      });
      this.rows =[...groupData];
      this.tableRowData = [...this.rows];
      this.isLoading = false;

    }, (error) => {
        console.error(error);
        this.isLoading = false;
      });

    this.subscriptions.push(tenantDataSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  onSelect(rowData: any) {
    this.selected = [...rowData.selected];

    // if(this.selected.length != 1){
    //   this.showSummaryPanel = false;
    // }
    if(this.selected.length == 1){
      this.editDisabled = false;
    }else{
      this.editDisabled = true;
    }
    this.groupObject = this.selected[0];
  }

  downloadReport(reportType: string) {
    if(reportType === 'csv') {
      const csvData = this._utilsService.getSelectedElementsFromArray(this.rows, _.map(this.columns, 'prop'));
      this.csvExport.formatAndDownloadCSVForGrid(csvData, 'tenant-list', _.map(this.columns, 'name'));
    }
  }
  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    this.tableWidth = this.mainWidth - 12;
  }
  updateFilter(event:any) {
    const val = event.target.value.toLowerCase().trim();
    let temp;
    const tableRowData = [...this.tableRowData];
    // filter our data
    if(val){
       temp = tableRowData.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(val) !== -1);
      });
    }else{
      temp = tableRowData;
    }
    // update the rows
    this.rows = temp;
  }
  closeSidePanel(cbAction?){
    this.showSummaryPanel = false;
    this.selected = [];
    this.editDisabled = true;
    if(cbAction && cbAction.action == 'reloadUsers') {
      this.delayedRefreshStart = true;
    }
  }

  addTenant(){
    this.mode = 'add';
    this.showSummaryPanel = !this.showSummaryPanel;
  }

  editTenant(){
    this.mode = 'edit';
    this.showSummaryPanel = true;
  }

  deleteTenant(){
    this.isLoading = true;
    let message = `Are you sure you want to delete this tenant?: `+this.selected[0].name;

    const dialogData = new ConfirmDialogModel("", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let tenantId = this.selected[0].identifier;
       const deleteTenantSub:Subscription = this.tenantService.deleteTenant(tenantId).subscribe(data=>{
        this.getTenants();
          let action = 'Tenant deleted successfully';
          this.openSnackBar(action, this.selected[0].name);
          this.selected = [];
          this.isLoading = false;
        }, (error) => {
            console.error(error);
            let action = 'Something went wrong';
           this.openSnackBar(action, this.selected[0].name);
           this.isLoading = false;
          });

          this.subscriptions.push(deleteTenantSub);
      }else{
        this.isLoading = false;
      }
    });
  }
}
