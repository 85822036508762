<div class="wt__loader wt__flex-row" [hidden]="!loadingStats">
    <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
</div>
<div *ngIf="data.emptyMessage" class="wt__no-data-message py-6">
    <div>{{data.emptyMessage}}</div>
</div>
<div *ngIf="!data.emptyMessage" class="readouts-container overflow-y-scroll m-10-20 px-1 pt-2 mt-2">
    <div class="grid foz14 gap-x-4 gap-y-2 gridcols2">
        <ng-container *ngFor="let item of data?.list">
            <span class="foz14  justify-self-end" matTooltip="{{ item.name }}" matTooltipClass="tooltip-styles">
                {{ item.name | truncate : 18}}
            </span>
            <div class="justify-self-end">
                <span class="foz14" *ngIf="!item.link">{{ item.value}}{{item.unit}}</span>
                <a *ngIf="item.link" [href]="item.link">{{ item.value}}{{item.unit}}</a>
            </div>
            <!-- <cl-readout class="cl-digit-readouts" [name]="item.name" [totalVal]="item.value"></cl-readout> -->
        </ng-container>
    </div>
</div>
