import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { VendorAccountService } from '../vendor-account.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UtilsService } from '@cl/common/utils/utils.service';
import { UserService } from '@cl/user/user.service';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { ToastService } from '@cl/common/services/toast.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()

@Component({
  selector: 'cl-vendor-account-side-panel',
  templateUrl: './vendor-account-side-panel.component.html',
  styleUrls: ['./vendor-account-side-panel.component.scss'],
})
export class VendorAccountSidePanelComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  // @ViewChildren('.datepicker-calendar-icon-custom', {}) pickerDirective: QueryList<DaterangepickerDirective>;
  @Input() selectedAccount: any;
  @Input() mode: any;
  @Output() closeSidePanel = new EventEmitter<any>();
  isLoading = false;
  private subscriptions: Subscription[] = [];
  isSaveInProgress = false;
  accountId = '';
  accountDetails: any;
  statusEnums = ['ACTIVE', 'SUSPENDED'];
  accountType = { label: 'Device Integration', key: 'device_integration_type' };
  editAccountPayload = {};
  authorizationType = '';
  vendorList = [];
  properties = [];
  locale : any = {
    format: 'MM/DD/YYYY',
    displayFormat: 'MM/DD/YYYY hh:mm:ss',
    weekLabel: 'W',
    customRangeLabel: 'Custom range',
    direction: 'ltr'
  };
  minDate = moment();
  invisibleFields = ['password', 'apikey', 'tokenCreatedAt', 'tokenExpiryTime'];
  auxProps = {};
  props = [];

  addAccountPayLoad = {
    nickname:'',
    accountType: '',
    vendorId: '',
    authInfo: {
    }
  };

  constructor(
    private fb: FormBuilder,
    private vendorAccountService: VendorAccountService,
    private _utilsService: UtilsService,
    private userService: UserService,
    private toastService: ToastService
  ) {}
  accountForm = this.fb.group({
    nickname: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(4)]],
    accountType: [{value: this.accountType.label, disabled: true}],
    vendorId: ['', Validators.required],
  });

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.mode == 'edit' || this.mode == 'view') {
      this.accountId = this.selectedAccount?.id;
      if(this.accountId){
        this.getAccountDetails();
      }
    } else {
      this.getVendorList();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private addControls(key, value) {
    const fg = this.accountForm;
    if(key){
      if(!value.optional){
        fg.addControl(key, new FormControl('', Validators.required));
        this.addValidators(value, this.accountForm.get(key));
      }
      else{
        this.accountForm.addControl(key, new FormControl(''));
      }

      fg.controls[key].valueChanges.pipe(debounceTime(100), untilDestroyed(this))
      .subscribe((val) => {
        if(value.optional){
        if(val) {
          this.addValidators(value, this.accountForm.get(key));
        } else {
          this.accountForm.get(key).clearValidators();
        }}
        fg.controls[key].updateValueAndValidity();
      })
    }

    // if(value.conditions?.allowedChars?.regex) {
    //   let regex = value.conditions?.allowedChars?.regex;
    //   this.accountForm.get(key).addValidators(Validators.pattern(regex));
    // }
    if(this.mode === 'edit' && key === 'password') {
      fg.removeControl(key);
    }
    fg.updateValueAndValidity();
  }

  private addValidators(value, fc) {
    if(value.dataType === 'String' && (value.conditions?.length || value.conditions?.allowedChars?.length)) {
      let min = value.conditions?.allowedChars?.length.min ? value.conditions?.allowedChars?.length.min : value.conditions?.length.min;
      let max = value.conditions?.allowedChars?.length.max ? value.conditions?.allowedChars?.length.max : value.conditions?.length.max;
      fc.addValidators(Validators.minLength(min));
      fc.addValidators(Validators.maxLength(max));
    }
    if(value.dataType === 'Number' && (value.conditions?.length || value.conditions?.allowedChars?.length)) {
      let min = value.conditions?.allowedChars?.length.min ? value.conditions?.allowedChars?.length.min : value.conditions?.length.min;
      let max = value.conditions?.allowedChars?.length.max ? value.conditions?.allowedChars?.length.max : value.conditions?.length.max;
      fc.addValidators(Validators.min(min));
      fc.addValidators(Validators.max(max));
    }
  }

  private getAccountDetails() {
    this.vendorAccountService
      .getAccountDetails(this.accountId)
      .subscribe((data: any) => {
        this.accountDetails = data;
        if(this.mode === 'view'){
          this.removeAuxProps();
          this.getProps(this.accountDetails.vendorId);
        }
        this.accountDetails.accountType = this.accountType.label;
        this.accountDetails.createdAtFormatted = this.accountDetails.createdAt
          ? this._utilsService.transformDate(
              this.accountDetails.createdAt,
              'MM/dd/yyyy'
            )
          : '';
        this.accountDetails.modifiedAtFormatted = this.accountDetails.modifiedAt
          ? this._utilsService.transformDate(
              this.accountDetails.modifiedAt,
              'MM/dd/yyyy'
            )
          : '';
        this.accountForm.controls['vendorId'].disable();
        this.getVendorList(this.accountDetails);
      });
  }

  private removeAuxProps() {
    this.auxProps = _.clone(this.accountDetails?.authInfo);
    if(this.auxProps.hasOwnProperty('username')) {
      delete this.auxProps['username'];
    }
    if(this.auxProps.hasOwnProperty('password')) {
      delete this.auxProps['password'];
    }
    if(this.auxProps.hasOwnProperty('apiKey')) {
      delete this.auxProps['apiKey'];
    }

  }

  openDatepicker() {
    this.pickerDirective.open();
  }

  private getVendorList(accountDetails?) {
    let tenantId = this.userService.getEffectiveTenantId();
    this.vendorAccountService.getVendorList(tenantId).subscribe((data: any) => {
      this.vendorList = data;
      this.authorizationType = accountDetails?.vendorId && this.getAuthorizationtype(accountDetails?.vendorId);
        if(this.authorizationType) {
          if(this.mode === 'add' || this.mode === 'edit'){
            this.getProperties(accountDetails?.vendorId);
          } else {
            if(!_.isEmpty(this.accountDetails)){
              this.accountForm.patchValue(this.accountDetails);
            }
          }
        }
    });
  }

  private getAuthorizationtype(vendorId) {
    return this.vendorList.find((vendor) => vendor.id === vendorId)
      .vendorAuthorizationType;
  }

  onVendorChange(vendorId) {
    let selectedVendorId = this.accountForm.value.vendorId;
    this.authorizationType = this.getAuthorizationtype(selectedVendorId);
    this.getProperties(vendorId);
  }

  private getProps(vendorId) {
    this.vendorAccountService.getProperties(vendorId).subscribe((data: any) => {
      this.props = data;
      this.props.forEach(prop => {
        let value = JSON.parse(prop.value);
        if(this.auxProps[prop.name] && value?.dataType === 'Date'){
          this.auxProps[prop.name] = this.auxProps[prop.name]
          ? this._utilsService.transformDate(
            this.auxProps[prop.name],
              'MM/dd/yyyy'
            )
          : '';
        }
      })

    })
  }

  private getProperties(vendorId) {
    this.properties = [];
    let props = [];

    this.vendorAccountService.getProperties(vendorId).subscribe((data: any) => {
      props = data;
      props.forEach((prop: any) =>
         {
          prop.value = JSON.parse(prop.value);
            if(prop.value.uiRequired) {
            // if(this.accountForm.get(prop['name']))
              // this.accountForm.controls[prop['name']].reset();
            if(this.mode === 'edit' && this.invisibleFields.indexOf(prop.name) < 0) {
              this.addControls(prop.name, prop.value);
             } else {
              this.addControls(prop.name, prop.value);
            }

            if(this.accountDetails?.tokenCreatedAt) {
              this.accountDetails.tokenCreatedAt = moment(this.accountDetails?.tokenCreatedAt);
            }
            if(this.accountDetails?.tokenExpiryTime) {
              this.accountDetails.tokenExpiryTime = moment(this.accountDetails?.tokenExpiryTime);
            }

            if(this.mode === 'edit') {
              Object.entries(this.accountDetails?.authInfo).forEach(([key, value]) => {
                if(key === prop.name) {
                  this.accountForm.patchValue(this.accountDetails?.authInfo);
                }
              })
            }
          }
       }
      );

      let finalProps = [];

      if(this.mode === 'edit'){
        props.forEach((prop: any) => {
          if(this.invisibleFields.indexOf(prop.name) < 0){
            finalProps.push(prop);
          }
        })
      } else {
        finalProps = props;
      }

      this.properties = _.orderBy(finalProps, ['value.order'],['asc']);

      // let propertiesCopy = Object.keys(props).map(b => {return {...props[b], key: b};});

      if(!_.isEmpty(this.accountDetails)){
        this.accountForm.patchValue(this.accountDetails);
      }
    })
  }

  datesUpdated(datesUpdated, key){
    if(this.accountForm.controls[key]){
      this.accountForm.controls[key].setValue(datesUpdated.startDate?.valueOf());
    }
  }

  closePanel(reload?) {
    if (reload) {
      this.closeSidePanel.emit({ action: 'reloadAccounts' });
    } else {
      this.closeSidePanel.emit(null);
    }
  }

  private createPayload() {
    if (this.mode === 'add') {
      this.addAccountPayLoad['nickname'] = this.accountForm.value.nickname;
      this.addAccountPayLoad['accountType'] = this.accountType.key;
      this.addAccountPayLoad['vendorId'] = this.accountForm.value.vendorId;
      this.properties.forEach(prop => {
        if(this.accountForm.value[prop?.name])
        this.addAccountPayLoad['authInfo'][prop?.name] = this.accountForm.value[prop?.name];
        // if(this.authorizationType == 'API_Key_Based'){
        //   if(prop?.name === 'tokenCreatedAt' || prop?.name === 'tokenExpiryTime'){
        //     this.addAccountPayLoad[prop?.name] = this.accountForm.value[prop?.name];
        //   } else {
        //     this.addAccountPayLoad['authInfo'][prop?.name] = this.accountForm.value[prop?.name];
        //   }
        // } else {
        //   this.addAccountPayLoad['authInfo'][prop?.name] = this.accountForm.value[prop?.name];
        // }
      })
    } else if (this.mode === 'edit') {
      this.editAccountPayload['nickname'] = this.accountForm.value.nickname;
      this.properties.forEach(prop => {
        if(this.invisibleFields.indexOf(prop?.name) < 0){
          if(this.accountForm.value[prop?.name]){
            this.editAccountPayload[prop?.name] = this.accountForm.value[prop?.name];
          }
        }
      })
    }
  }

  saveAccount() {
    this.createPayload();
    this.isSaveInProgress = true;
    const createAccontSubs: Subscription = this.vendorAccountService
      .createVendorAccount(this.addAccountPayLoad)
      .subscribe(
        (data) => {
          this.closePanel(true);
          let action = 'Account created successfully';
          this.toastService.success(action, this.accountForm.value.nickname);
        },
        (error:any) => {
          console.log(error);
          this.isSaveInProgress = false;

          let action = error?.error && error?.error['error-message'] ? error?.error['error-message'] : 'Something went wrong' ;
          this.toastService.error(action);
        }
      );
    this.subscriptions.push(createAccontSubs);
  }


  updateAccount() {
    this.createPayload();
    this.isSaveInProgress = true;
    const createAccontSubs: Subscription = this.vendorAccountService
      .updateVendorAccount(this.accountId, this.editAccountPayload)
      .subscribe(
        (data) => {
          this.closePanel(true);
          let action = 'Account updated successfully';
          this.toastService.success(action, this.accountForm.value.nickname);
        },
        (error) => {
          console.log(error);
          this.isSaveInProgress = false;

          let action = error?.error && error?.error['error-message'] ? error?.error['error-message'] : 'Something went wrong' ;
          this.toastService.error(action);
        }
      );
    this.subscriptions.push(createAccontSubs);
  }
}
