import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { Asset, CustodyEvent } from '@cl/@types/asset.type';
import { AssetListService } from '@cl/asset/asset-list/asset-list.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { StateActionMapPipe } from '@cl/common/pipes/state-action-map.pipe';
import { ChainOfCustodyService } from '@cl/common/services/chain-of-custody.service';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cl-asset-custody',
  templateUrl: './asset-custody.component.html',
  styleUrls: ['./asset-custody.component.scss']
})
export class AssetCustodyComponent implements OnInit, OnChanges, OnDestroy {
  @Input('asset') assetObject: Asset;
  @Input('panel') panel: string;
  subscriptions: Subscription[] = [];
  assetId: string;
  delayedRefreshStart: boolean = false;
  refreshTimer: number = 5;
  exportKeys: string[] = [
    'fromParty',
    'toParty',
    'eventTimeFormatted',
    'stateFormatted',
    'note'
  ];
  exportLabels: string[] = [
    'From',
    'To',
    'Event Time',
    'Operation',
    'Performed By'
  ]

  constructor(
    private assetListService: AssetListService,
    private cocService: ChainOfCustodyService,
    private _utilsService: UtilsService,
    private csvExport: CsvExportsService,
    private formateDatePipe: FormateDatePipe) { }

  ngOnInit(): void {
    this.listenToCOCEvents();
  }

  ngOnChanges(): void {
    if (this.assetObject && this.assetObject.taggedAssetId) {
      this.assetId = this.assetObject.id;
      this.getAssetCustody();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getAssetCustody() {
    this.assetListService.getAssetCustody(this.assetId).subscribe({
      next: (res: CustodyEvent[]) => {
        if (res && res.length) {
          res = res.map((custody: any) => {
            custody.eventTimeFormatted = this.formateDatePipe.transform(custody.eventTime, 'default');
            custody.stateFormatted = StateActionMapPipe.transform(custody.state);
            return custody;
          })
          this.assetObject.custodyList = res;
        } else {
          this.assetObject.custodyList = [];
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  listenToCOCEvents() {
    const eventSub: Subscription = this.cocService.getCOCEvent().subscribe(evt => {
      if (evt.actionCompleted) {
        this.delayedRefreshStart = true;
      }
    });
    this.subscriptions.push(eventSub);
  }

  downloadCOCCsv(event) {
    event.preventDefault();
    let dateFormatted = this.formateDatePipe.transform(new Date(), 'default');
    let fileName = `${this.assetObject.externalId}_Custody_${dateFormatted}`;
    const csvData = this._utilsService.getSelectedElementsFromArray(this.assetObject.custodyList, this.exportKeys);
    this.csvExport.formatAndDownloadCSVForGrid(csvData, fileName, this.exportLabels);
  }

}
