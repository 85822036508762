/**
 * @returns `true` if the host is `localhost` or `127.0.0.1`, otherwise `false`.
 */
export function isLocalhost(): boolean {
  const hostName = window.location.hostname;

  return hostName === "localhost" || hostName === "127.0.0.1";
}

export function newRandom(min: number = Number.MIN_VALUE, max: number = Number.MAX_VALUE) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
