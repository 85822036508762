import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppAuthService } from '@cl/authenticate/app-auth.service';
import { UserService } from '@cl/user/user.service';
import _ from 'lodash';
import { AnalyticsService } from '../analytics.service';

@Component({
  selector: 'cl-bi-workbench',
  templateUrl: './bi-workbench.component.html',
  styleUrls: ['./bi-workbench.component.scss']
})

export class BiWorkbenchComponent implements OnInit {
  public isLoading: boolean = false;
  urlSafe: SafeResourceUrl = "";
  token: string = '';
  baseUrl: any = '';
  loginUserTenantId: string = '';
  constructor(
    public sanitizer: DomSanitizer,
    public _authService: AppAuthService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.token = this._authService.getAuthToken();
    this.loginUserTenantId = this.userService.getEffectiveTenantId();
    this.baseUrl = this.analyticsService.getBaseUrl();
    this.getMonitorHomePage();
    
  }
  getMonitorHomePage() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + '/parkour_sso?token=' + this.token + '&redirect=/dashboard/list&tenant_id='+this.loginUserTenantId);
  }

 
}
