import { Injectable } from '@angular/core';
import { CcUpdateClientLogoUrl, SetClient, SetTenantId, ToggleClient } from "@cl/ngxs/actions";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as _ from "lodash";
import { ClientConfigService } from "../common/services/client-config.service";
import { ClientConfigStateModel } from "./client-config.state.model";

const DEFAULT_STATE: ClientConfigStateModel = {
  tenantId: "",
  clientLogoUrl: "",
  settings: {
    title: "Cloudleaf SCN",
    login: {
      logo: "./assets/svgs/logos/Cloudleaf_Primary_Blue_RGB.svg",
    },
    header: {
      logo: "./assets/svgs/logos/Cloudleaf_Logo_White.svg",
      tagline: "Supply Chain Navigator",
      showDropdown: true,
    },
    theme: {
      mainColorDark: "#054ea7",
      mainColorGrad: "#00aff6",
      mainColor: "#2879DE",
      mainColorMed: "#66c2eb",
      mainColorLight: "#98d6f1",
      mainColorLighter: "#b9e3f7",
      mainColorLightest: "#f3faff",
      mainColorTrans: "rgba(0, 153, 221, 0.85)",
      mainColorTransLight: "rgba(0, 153, 221, 0.7)",
      themeMainColor: "#2879DE",
      themeMainColorMed: "#66c2eb",
      themeMainColorLight: "#98d6f1",
      themeMainColorLighter: "#b9e3f7",
    },
    liveMap: {
      showViewingModeToggle: true,
      mapGroups: {
        location: true,
        incident: true,
        inventory: true,
        shipment: true,
        asset: true,
        users: true,
      },
      showTargets: true,
    },
    nav: {
      token: {
        NAVIGATOR: true,
        DIGITAL_MODEL: true,
        MANAGE_ASSETS: true,
        RULES_ENGINE: true,
        MAPPING_ADMIN: true,
        ADMIN: true,
      },
      path: {
        "/navigator": true,
        "/assets/manage-assets": true,
        "/mapping-admin": true,
        "/sensors": true,
        "/admin/users": true,
      },
    },
  },
};

@State<ClientConfigStateModel>({
  name: "client_config",
  defaults: DEFAULT_STATE,
})
@Injectable()
export class ClientConfigState {
  constructor(private _cc: ClientConfigService) {}
  @Selector()
  static getTenantId(state: any) {
    return state.tenantId;
  }
  static getClientMapGroups(state: any) {
    return state.client_config.settings.liveMap.mapGroups;
  }
  static getClientLiveMap(state: any) {
    return state.client_config.settings.liveMap;
  }

  @Action(SetTenantId)
  setTenantId(ctx: StateContext<any>, action: SetTenantId) {
    // const state = ctx.getState();
    ctx.patchState({
      tenantId: action.tenantId,
      currentAction: "setTenantId",
    });
  }

  @Action(SetClient)
  setClient(ctx: StateContext<any>, action: SetClient) {
    // const state = ctx.getState();
    // TODO default client should be set for user
    const settings = this._cc.setClient("ericsson");
    ctx.patchState({
      settings: _.cloneDeep(settings),
      currentAction: "setClient",
    });
  }

  @Action(ToggleClient)
  toggleClient(ctx: StateContext<any>, action: ToggleClient) {
    // const state = ctx.getState();
    const settings = this._cc.nextClient();
    ctx.patchState({
      settings: _.cloneDeep(settings),
      currentAction: "toggleClient",
    });
  }

  @Action(CcUpdateClientLogoUrl)
  updateClientLogoUrl({ getState, patchState }: StateContext<ClientConfigStateModel>, action: CcUpdateClientLogoUrl) {
    patchState({ clientLogoUrl: action.logoUrl });
  }

  // @Action(GetValidSubRoutes)
  // getValidSubRoutes(ctx: StateContext<any>, action: GetValidSubRoutes) {
  //   // const state = ctx.getState();
  //   const settings = this._cc.nextClient();
  //   ctx.patchState({
  //     settings: _.cloneDeep(settings),
  //     currentAction: "getValidSubRoutes",
  //   });
  // }
}
