import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { NotificationDialogComponent } from '@cl/common/components/notification-dialog/notification-dialog.component';
import { ToastService } from '@cl/common/services/toast.service';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { UserService } from '@cl/user/user.service';
import { json, tree } from 'd3';

@Component({
  selector: 'cl-shipment-details-share',
  templateUrl: './shipment-details-share.component.html',
  styleUrls: ['./shipment-details-share.component.scss'],
})
export class ShipmentDetailsShareComponent implements OnInit {
  @Output() data = new EventEmitter<any>();
  @Output() close = new EventEmitter<boolean>();
  @Input() entityType: string = 'SHIPMENT';
  @Input() entityId: string ='';
  @Input() entityTypeKey: string ='';
  @Input() entityName: string='';
  @Input() parentEntityTypeKey = ''
  test: boolean = false;
  public checkbox: boolean = false;
  public completeShipment: boolean = true;
  public limitedShipment: boolean = false;
  public userEmail: string = '';
  public validEmail: boolean = true;
  public popUpopen: boolean = false;
  public emailError:string=''
  public isDTPuser:boolean=false;
  public isDTPShipment = true;
  constructor(
    private eRef: ElementRef,
    public shipmentService: ShipmentService,
    public UserService: UserService,
    public dialog: MatDialog,
    public toast: ToastService
  ) {}
  public shipment;
  ngOnInit(): void {
    setTimeout(() => {
      this.test = true;
    }, 100);
    this.checkDTPuser();
  }
  @HostListener('document:click', ['$event'])
  clickout(event: { target: any }) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.test) {
        this.onClose();
      }
    }
  }
  checkDTPuser(){
  this.isDTPuser= this.UserService.isDTPUser();
  this.isDTPShipment = this.parentEntityTypeKey == 'dtpshipment'
  if(this.isDTPuser && this.isDTPShipment){
    this.completeShipment =false;
    this.limitedShipment = true;
  }
  if(this.entityType=="COMPOSITE"){
    this.completeShipment =true;
    this.limitedShipment = false;
  }
  }
  public onchange(x: any) {
    if (x.value == 'complete') {
      this.completeShipment = true;
      this.limitedShipment = false;
    } else {
      this.completeShipment = false;
      this.limitedShipment = true;
    }
  }
  public onSend(): void {
    if (this.userEmail && this.validateEmail(this.userEmail) && this.checkEmailsLength(this.userEmail)) {
      this.popUpopen = true;
      this.close.emit(true);
      this.dialog
        .open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Confirm',
            message:
              'You are about to share an ' +
              (this.limitedShipment ? 'restricted' : 'unrestricted') +
              ' view of the shipment ' +
              this.entityName +
              ' to ' +
              this.userEmail,
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            this.popUpopen = false;
            this.validEmail = true;
            let payload = {
              appId: 1,
              emails: this.userEmail.split(','),
              entityId: this.entityId,
              entityName: this.entityName,
              entityType: this.entityType,
              entityTypeKey:this.entityTypeKey,
              expiryTime: 0,
              restricted: this.limitedShipment,
              sendCopy: this.checkbox,
            };
            this.onClose();
            this.shipmentService.sendEmail(payload).subscribe((res: any) => {
              if(res){
                this.dialog
                .open(NotificationDialogComponent, {
                  maxWidth: '400px',
                  data: {
                    type:'success',
                    message: 'Shipment tracking information has been sent.'
                   },
                })
              }
            });
          }else{
           this.onCancel();
          }
        });
    } else {
      this.validEmail = false;
      if(!this.userEmail){
        this.emailError ="Atleast one email address is required"
      }
    else if(!this.validateEmail(this.userEmail)){
      this.emailError ="The Email Address includes an invalid email address. Please separate email addresses by commas, and do not use angle brackets (< or >)"
     }
    else if(this.checkEmailsLength){
       this.emailError="Maximum of 10 email addresses are allowed"
     }
    }
  }
  public validateEmail(email: string) {
    const regularExpression =
    /^(([a-zA-Z0-9_+\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*,\s*|\s*$))*$/;
    return regularExpression.test(String(email).toLowerCase());
  }
  public checkEmailsLength(email: string){
   return email.split(',').length <=10
  }
  public emailFocus(): void {
    this.validEmail = true;
  }
  public onClose(): void {
    if (!this.popUpopen) {
      this.close.emit(true);
    }
  }
  public onCancel(): void {
    this.userEmail = '';
    this.validEmail = true;
    this.close.emit(true);
  }
}
