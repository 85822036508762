import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TenantUtilService } from "@cl/tenant/tenant-util.service";
import { UserService } from "@cl/user/user.service";
import * as _ from "lodash";
import { Observable } from "rxjs";

@Injectable()
export class TenentTokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private tenantUtilService: TenantUtilService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if(request.url.indexOf('graph-ui/auth0/token')<0){
      let currentUserTenant = this.userService.getEffectiveTenantId();
      let persistedTenant: string = this.tenantUtilService.getPersistedTenant();
      if(!_.isEmpty(persistedTenant) || !_.isEmpty(currentUserTenant)){
        const headers = request.headers.append("X-Clf-Tenant-Id", persistedTenant ?? currentUserTenant);
        request = request.clone({ headers });
      }
    }
    return next.handle(request);
  }
}
