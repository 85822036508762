import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SkuService {
  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService
  ) {}

  createSKU(payload, type) {
    const obs$ = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product?type=${type || ''}`,
      payload
    );
    return firstValueFrom(obs$);
  }

  updateSKU(payload, skuId, type) {
    const obs$ = this.http.put(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product/${skuId}?type=${type || ''}`,
      payload
    );
    return firstValueFrom(obs$);
  }

  getSKU(skuId) {
    const obs$ = this.http.get(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product/${skuId}`
    );
    return firstValueFrom(obs$);
  }

  deleteSku(payload) {
    const obs$ = this.http.post(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/2/product/delete`,
      payload
    );
    return firstValueFrom(obs$);
  }
}
