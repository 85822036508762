<div class="align-center">
    <h1 *ngIf="title" mat-dialog-title>
        {{title}}
    </h1>

    <div mat-dialog-content>
        <p class="foz14" [innerHTML]="message"></p>
    </div>

    <div mat-dialog-actions class="just-center">
        <button mat-button (click)="onDismiss()">No</button>
        <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
    </div>
</div>