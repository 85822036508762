import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbItem } from '@cl/common/components/breadcrumb/breadcrumb.component';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { EntityService } from '@cl/common/services/entity-list.service';
import { InventoryService } from '@cl/inventory/inventory.service';

@Component({
  selector: 'cl-sku-details',
  templateUrl: './sku-details.component.html',
  styleUrls: ['./sku-details.component.scss']
})
export class SkuDetailsComponent implements OnInit {
  breadCrumbItems: BreadCrumbItem[];
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];
  summaryObj: any
  skuId: string;
  origDistrib: any;
  skuCatalogCDMFields: any;
  data:any;
  customFields=["createdBy","createdAt","modifiedBy","modifiedAt"]
  constructor(private entityService: EntityService, private route: ActivatedRoute, public datePipe: FormateDatePipe) {
    this.breadCrumbItems = [
      {'name': 'Previous', isBack: true, path: null},
      {'name': 'SKU Details', path: null}
    ];
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.skuId = paramMap.get('id');
      this.getSkuSummary();
    });
  }
  getSkuSummary() {
    this.entityService.getSkuSummary(this.skuId).subscribe((response: any) => {
      this.summaryObj = response;      
      if (response?.summary?.type) {
        this.getExtendedAttributes(response.summary.type)
      } else {
        this.getExtendedAttributes(response?.type)
      }
    })
  }
  async getExtendedAttributes(sku) {
    this.entityService.getEntityCdmFields(sku).subscribe((data: any) => {
      this.data = data
      this.skuCatalogCDMFields = this.entityService.orderCdmFields(this.data?.cdmFields)
      this.customFields.forEach(element => {
        const removeIndex = this.skuCatalogCDMFields.findIndex( item => (item.name === element));
        this.skuCatalogCDMFields.splice( removeIndex, 1 );  
      }); 
    })
  }
  getSkuValue(cdmField, properties) {
    if (cdmField.displayable) {
      if (this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1) {
        if (cdmField?.type?.toUpperCase() === 'DATE') {
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        } else {
          return properties[cdmField.name];
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  stringTruncate(value: string, size: number = 15): string {
    if (!value) return '';

    const limit = size > 0 ? size : 15;
    return value.length > limit
      ? value.substring(0, limit).trim() + '...'
      : value;
  }
}
