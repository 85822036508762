import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CL_INPUT_DEBOUNCE_TIME } from "@cl/constants";
import { Coordinates, Entity, NavigatorAggApi, NavigatorConfig, Panels, SearchQueryRequest } from "@cl/models";
import { LvHidePanel, LvInitPanels, LvTogglePanel } from "@cl/ngxs/actions";
import { ClientConfigState, LvPanelsState } from "@cl/ngxs/state";
import { AgmClustersService } from "../../common/services/agm-clusters.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, Select, Store } from "@ngxs/store";
import _ from "lodash";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, tap } from "rxjs/operators";
import * as C from "../../common/action-list.service";
import { NavigatorMapService } from "../service/navigator-map.service";
import { NavigatorUpdateLoadingStatus, NavigatorUpdateMapDetails } from "../state/navigator.actions";
import { NavigatorState } from "../state/navigator.state";
import { NavigatorStateModel } from "../state/navigator.state.model";
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";
import { UserService } from '@cl/user/user.service';
import { ToastService } from "@cl/common/services/toast.service";

@UntilDestroy()
@Component({
  selector: "app-navigator-map",
  templateUrl: "./navigator-map.component.html",
  styleUrls: ["./navigator-map.component.scss"],
})
export class NavigatorMapComponent implements OnInit, OnDestroy {
  @Select(LvPanelsState) lvPanels$: Observable<any>;
  @Select(NavigatorState) navigator$: Observable<NavigatorStateModel>;
  navigatorState: NavigatorStateModel;
  navigatorConfig: NavigatorConfig;

  @Select(ClientConfigState) clientConfig$: Observable<any>;
  private clientConfigState: any;

  // currentCenter: Coordinates = { lat: 33.07556420358085, lng: -96.83251200742326 };
  currentCenter: Coordinates = { lat: 27.212603, lng: -8.876380 };

  p: Panels;

  fullScreenMode = false;

  nodes: Entity[] = [];
  clusterCalculatorFn: Function;
  targetDomain: string = "";
  visibleDomains: string[] = [];
  locationId: any;
  assetId: any;
  shipmentId:any;
  catalogType:string;
  toggleSummary: boolean = false;

  constructor(
    private actions$: Actions,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private navigatorMapService: NavigatorMapService,
    private agmClusterService: AgmClustersService,
    private webTracker: WebtrackerService,
    private _userService: UserService,
    private toaster: ToastService
  ) {
    this.store.dispatch(new LvInitPanels(this.p));
  }

  ngOnInit(): void {
    this.watchPanels();
    this.watchNavigatorState();
    this.watchClientConfigState();

    setTimeout(() => {
      const fullScreen = this.route.snapshot.queryParams['fullScreen'];
      this.p = this.store.selectSnapshot(LvPanelsState);

      if (fullScreen && this.p?.carousel) {
        this.toggleFullscreen(false);
      }
    }, CL_INPUT_DEBOUNCE_TIME);
  }

  ngOnDestroy(): void {
    if (this.p?.rightList) this.store.dispatch(new LvHidePanel("rightList"));

    // Clear map bounds

    this.store.dispatch([
      new NavigatorUpdateMapDetails({
        bounds: {
          bottomRightLat: undefined,
          bottomRightLon: undefined,
          topLeftLat: undefined,
          topLeftLon: undefined,
        },
      }),
      new LvHidePanel("inspector"),
      new NavigatorUpdateLoadingStatus({ map: false }),
    ]);
  }

  /**
   * Event handler for list item selected of right side.
   */
  listItemSelected(item: Entity) {
    // this.toggleSummaryPanel();
    this.toggleSummary = true;
    this.catalogType = item.properties.entityTypeKey
    if(item.properties.baseClass.toLowerCase() == 'location') {
      if(this._userService.getPolicies().includes('LOCATION_DETAIL')){
        this.p.rightListOffset = -650;
        this.locationId = item.properties.id;
        this.assetId = null;
        this.shipmentId = null;
        this.webTracker.captureEvent('locate_windowAppear', {'type': 'Location', 'id': this.locationId});
      }else{
        this.toaster.error('Access not allowed')
      }
    } else if(item.properties.baseClass.toLowerCase() == 'asset') {
      if(this._userService.getPolicies().includes('ASSET_DETAIL')){
        this.p.rightListOffset = -650;
        this.assetId = item.properties.id;
        this.locationId = null;
        this.shipmentId = null;
        this.webTracker.captureEvent('locate_windowAppear', {'type': 'Asset', 'id': this.assetId});
      }else{
        this.toaster.error('Access not allowed')
      }
    } else if(item.properties.baseClass.toLowerCase() == 'shipment') {
      if(this._userService.getPolicies().includes('SHIPMENT_DETAIL')){
        this.p.rightListOffset = -650;
        this.shipmentId = item.properties.id;
        this.assetId = null;
        this.locationId = null;
        this.webTracker.captureEvent('locate_windowAppear', {'type': 'Shipment', 'id': this.shipmentId});
      }else{
        this.toaster.error('Access not allowed')
      }
    }
    this.store.dispatch(
      new NavigatorUpdateMapDetails({
        activeEntity: item
      }, {assetId: this.assetId},
      {locationId: this.locationId},
      {shipmentId:this.shipmentId})
    );
  }

  getSearchUpdate(message) {
    const uid = message.item.id || message.item.uid;
    switch (message.action) {
      case C.FILTER_SEARCH_LIST:
        break;
      case C.FOOTER_NODE_HOVERED:
        // this.hoveredNode = message.item;
        break;
      case C.SELECT_LIST_ITEM:
        // this.store.dispatch(new LvSelectNode(message.item));
        break;
      case C.SELECT_SHIPMENT:
        break;
      case C.SELECT_LOCATION:
        break;
      case C.EXPAND_NODE:
        break;
    }
  }

  getPanelUpdate(message) {
    // if (message.action === "hidePanel") {
    //   this.store.dispatch(new LvHidePanel(message.name));
    // }
  }

  getInspectorUpdate(message) {
    let node = message.member || message.item;
    switch (message.action) {
      case C.SWAP_ACTIVE:
        // this.store.dispatch(new LvSelectNode(node));
        break;
      case C.SELECT_SHIPMENT:
        break;
      case C.SELECT_LOCATION:
        break;
      case C.EXPAND_NODE:
        break;
    }
  }

  toggleFullscreen(changeRoute = true) {
    this.fullScreenMode = !this.fullScreenMode;

    if (this?.p?.carousel) {
      this.store.dispatch(new LvHidePanel("inspector"));
    }
    this.store.dispatch(new LvTogglePanel("carousel"));

    if (changeRoute) {
      const queryParams: any = {};
      if (this.fullScreenMode) queryParams.fullScreen = true;

      this.router.navigate([], { queryParams });
    }
  }

  private watchPanels() {
    this.lvPanels$.pipe(untilDestroyed(this)).subscribe((p: any) => {
      this.p = { ...p };
    });
  }

  /**
   * Update target and visible domains for the map. It also updates the agm cluster service.
   */
  private updateTargetAndVisibleDomains(target: string = "", visible: string[] = []) {
    this.targetDomain = target ?? "";
    this.visibleDomains = visible ?? [];

    if (!this.agmClusterService.mainFilters) this.agmClusterService.mainFilters = {};
    this.agmClusterService.mainFilters.targeted = this.targetDomain;
  }

  private watchNavigatorState() {
    this.navigator$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.navigatorState = { ...state };

      if (this.navigatorState?.navigatorConfig && !this.navigatorConfig) {
        this.navigatorConfig = this.navigatorState.navigatorConfig;
        this.updateCurrentCenter(this.currentCenter);
      }

      // Update target and visible domain
      const { mapDetails } = this.navigatorState;
      if (mapDetails) {
        const { targetDomain = "", visibleDomain = [] } = mapDetails;

        this.updateTargetAndVisibleDomains(targetDomain, visibleDomain);
      }
    });

    /*this.actions$
      .pipe(
        untilDestroyed(this),
        ofActionSuccessful(NavigatorLoadFilterConfig, NavigatorUpdateFilterEsQuery),

        // For each of above mentioned actions, get the current state data
        switchMap((_) => this.store.selectOnce<NavigatorStateModel>(NavigatorState)),

        // Load nodes only if navigatorConfig object, google map is loaded and the state has prepared ESQuery
        filter((state) => !!(state?.navigatorConfig?.initialData && state.filterEsQuery && state?.mapDetails?.bounds)),

        // Have some delay between each actions
        debounceTime(CL_INPUT_DEBOUNCE_TIME),

        // Prepare ES search payload
        map((state) => {
          // console.log("[Loading Map]", state.navigatorConfig.initialData, state.filterEsQuery);
          const { mapDetails, filterEsQuery } = state;
          const { bounds } = mapDetails;

          const payload: SearchQueryRequest = {
            ...filterEsQuery,
            ...bounds,
          };

          return { payload, initialData: state.navigatorConfig.initialData };
        }),

        // Don't load nodes for the same payload
        distinctUntilChanged((a, b) => _.isEqual(a.payload, b.payload)),

        // Turn on map loading spinner
        tap((_) => this.store.dispatch(new NavigatorUpdateLoadingStatus({ map: true }))),

        // Load nodes
        switchMap((res) => this.loadNodes(res.initialData, res.payload)),

        // Turn off map loading spinner
        tap((_) => this.store.dispatch(new NavigatorUpdateLoadingStatus({ map: false }))),

        // Ensure the subscription chain does not breaks
        catchError((err) => {
          console.warn("[NavigatorMap] Error in subscription chain", err);
          return this.store.dispatch(new NavigatorUpdateLoadingStatus({ map: false }));
        }),
      )
      .subscribe();*/
  }

  private watchClientConfigState() {
    this.clientConfig$.pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME)).subscribe((state) => {
      this.clientConfigState = state;

      if (this.clientConfigState?.settings.liveMap) {
        this.clusterCalculatorFn = this.agmClusterService.getCalculator(this.clientConfigState.settings.liveMap);
      }
    });
  }

  private loadNodes(searchApiDetails: NavigatorAggApi, payload: SearchQueryRequest) {
    // console.log("🚀 ~ NavigatorMapComponent ~ loadNodes ~ loadNodes");

    return this.navigatorMapService.mapApi(searchApiDetails, payload).pipe(
      tap((nodes) => {
        // For map mode only nodes with location is needed, hence filter out remaining
        this.nodes = nodes.hits.filter((n) => _.isNumber(n?.locus?.lat));
      }),
      catchError((err) => {
        return of({ hits: [] });
      })
    );
  }

  /**
   * Parses the coordinates and updates the current center field
   */
  private updateCurrentCenter(coordinates) {
    if (!coordinates) return;

    this.currentCenter = {
      lat: coordinates.lat,
      lng: coordinates.lng || coordinates.long,
    };
  }

  closeSidePanel(type) {
    this.toggleSummary = false;
    this.p.rightListOffset = -250;
    if(type == 'asset') {
      this.navigatorState.assetId = '';
    } else if(type == 'location') {
      this.navigatorState.locationId = '';
    } else if(type == 'shipment') {
      this.navigatorState.shipmentId = '';
    }
  }
}
