<span class="cl__banner" [ngClass]='{"selected":isFave}'>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 15 26">
    <defs>
      <polygon id="bookmark-on-a" points="0 0 9.1 0 9.1 14 4.55 11.623 0 14"/>
    </defs>
    <g >
      <use xlink:href="#bookmark-on-a"/>
      <path  stroke-width=".25" d="M0.25,0.25 L0.25,13.5873293 L4.55,11.340853 L8.85,13.5873293 L8.85,0.25 L0.25,0.25 Z"/>
    </g>
  </svg>
</span>
