import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {MatAccordion} from '@angular/material/expansion';
import { combineLatest, Subscription } from 'rxjs';
import { TenantSettingService } from './tenant-setting.service';
import { ToastService } from "@cl/common/services/toast.service";
import { RulesService } from '@cl/business-rules/rules.service';
import { PropertyService } from "@cl/property/property.service";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../common/components/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'cl-tenant-settings',
  templateUrl: './tenant-settings.component.html',
  styleUrls: ['./tenant-settings.component.scss']
})
export class TenantSettingsComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('tenentSttingForm', {static: true}) tenentSttingForm: NgForm;
  private subscriptions: Subscription[] = [];
  tenentSettingData:any;
  factoryResetData:any;
  createdData:any;
  resetFactoryClicked = false;
  saveClicked = false;
  enableSave = false;
  originalSettings: any;
  rulesetOption = [];

   AppSettingsDefinition = [
     {"General":[
      {
        "displayLabel": "Password expiry (days)",
        "description": "The number of days before a user is required to reset their password",
        'min': 1,
        "type": "number",
        "reset": true,
        "mappingKey":"password.expiryindays",
        "required":true
      },
      {
        "displayLabel": "Auto logout for inactivity (minutes)",
        "description": "Value in minutes for session logout when user did not interact with applicaiton",
        "reset": true,
        "mappingKey":"ui.sessionTimeout",
        "type":"select",
        "options":[
          {"value":"0", "label":"None"},
          {"value":"15", "label":"15"},
          {"value":"60", "label":"60"},
        ]
      },
     ]},
     {"Notification":[
      {
        "displayLabel": "Sensor Health Check Interval",
        "description": "Provide the duration (in minutes) for the sensor health check interval. The number of missed sensor health check intervals (i.e. multiples of this duration) will be used to determine if a warning or critical notification needs to be provided for that sensor",
        "min": 10,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tag.battery.interval.minutes"
      },
      {
        "displayLabel": "Missed Sensor Health Check Intervals for Warning Notification",
        "description": "Number of missed sensor health check interval durations that should result in a Warning notification",
        "min": 2,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tag.battery.warn.miss"
      },
      {
        "displayLabel": "Missed Sensor Health Check Intervals for Critical Notification",
        "description": "Number of missed sensor health check interval durations that should result in a Critical notification",
        "min": 4,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tag.battery.critical.miss"
      },
      {
        "displayLabel": "Sensor Battery Warning Threshold (%)",
        "description": "Provide the threshold value (as a %, values from 0 - 100) below which a sensory battery warning notification will be provided",
        "min": 0,
        "max": 100,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tag.battery.warn.value"
      },
      {
        "displayLabel": "Sensor Battery Critical Threshold (%)",
        "description": "Provide the threshold value (as a %, values from 0 - 100) below which a sensory battery critical notification will be provided",
        "min": 0,
        "max": 100,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tag.battery.critical.value"
      },
      {
        "displayLabel": "Bulk Job Notification Email",
        "description": "This value controls the email address to which notifications should be sent",
        "type": "mail",
        "reset": true,
        "mappingKey":"tenant.default.bulknotification.email"
      },
      {
        "displayLabel": "System Generated Notification Email",
        "description": "This value controls the email address from which notifications should be received",
        "type": "mail",
        "reset": true,
        "mappingKey":"tenant.default.notification.email"
      },
     ]},
     {"Units and Display Formats":[
      {
        "displayLabel": "Trends & Analytics Duration",
        "description": "Default duration for fetching the Trends & Analytics",
        "reset": true,
        "mappingKey":"ui.trendsNAnalytics.defaultDateDays",
        "type":"select",
        "options":[
          {"value":"7", "label":"7 Days"},
          {"value":"15", "label":"15 Days"},
          {"value":"21", "label":"21 Days"},
          {"value":"30", "label":"30 Days"},
          {"value":"45", "label":"45 Days"},
          {"value":"60", "label":"60 Days"},
        ]
      },
      {
        "displayLabel": "Sampling Limit",
        "description": "This value will decide the sample rate per total records for the data appearing in Logger report and Environmental Conditions graphs",
        'min': 0,
        'max': 5000,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"ui.reports.samplingLimit"
      },
      {
        "displayLabel": "Date Format",
        "description": "Default dateformat to be displayed",
        "reset": true,
        "mappingKey":"ui.dateFormat",
        "type":"select",
        "options":[
          {"value":"MMM dd h:mm:ss a", "label":"MMM dd h:mm:ss a"},
          {"value":"MMM dd H:mm:ss", "label":"MMM dd H:mm:ss"},
          {"value":"MMM dd YYYY h:mm:ss a", "label":"MMM dd YYYY h:mm:ss a"},
          {"value":"MMM dd YYYY H:mm:ss", "label":"MMM dd YYYY H:mm:ss"},
          {"value":"MM/dd h:mm:ss a", "label":"MM/dd h:mm:ss a"},
          {"value":"MM/dd H:mm:ss", "label":"MM/dd H:mm:ss"},
          {"value":"MM/dd/YYYY H:mm:ss", "label":"MM/dd/YYYY H:mm:ss"},
          {"value":"dd MMM h:mm:ss a", "label":"dd MMM h:mm:ss a"},
          {"value":"dd MMM H:mm:ss", "label":"dd MMM H:mm:ss"},
          {"value":"dd MMM YYYY h:mm:ss a", "label":"dd MMM YYYY h:mm:ss a"},
          {"value":"dd MMM YYYY H:mm:ss", "label":"dd MMM YYYY H:mm:ss"},
          {"value":"dd/MM h:mm:ss a", "label":"dd/MM h:mm:ss a"},
          {"value":"dd/MM H:mm:ss", "label":"dd/MM H:mm:ss"},
          {"value":"dd/MM/YYYY h:mm:ss a", "label":"dd/MM/YYYY h:mm:ss a"},
          {"value":"dd/MM/YYYY H:mm:ss", "label":"dd/MM/YYYY H:mm:ss"},
        ]
      },
      {
        "displayLabel": "Temperature units (C/F)",
        "description": "Default temperature unit",
        "reset": true,
        "mappingKey":"ui.temp.unit",
        "type":"select",
        "options":[
          {"value":"C", "label":"Celsius °C"},
          {"value":"F", "label":"Fahrenheit °F"},
        ]
      },      {
        "displayLabel": "Order of shipment Carrier Events",
        "description": "Determines the default chronological order of carrier milestones for parcel shipments.",
        "type":"select",
        "options":[
          {"value":"false", "label":"Reverse Chronological(default)"},
          {"value":"true", "label":"Chronological"}
        ],
        "reset": true,
        "mappingKey":"intransit.ui.showCarrierEventsReverseChronologicalOrder"
      },
     ]}
     ,
     {"Business Rules":[
      {
        "displayLabel": "Global Shipment Rule Set",
        "description": "A user defined rule set that is applied to all newly created shipments by default.",
        "type": "ruleSet",
        "options":this.rulesetOption,
        "reset": true,
        "mappingKey":"rulesetId"
      },
     ]},
     {"Others" : [

      {
        "displayLabel": "Max Location radius at rest",
        "description": "",
        "unit": "miles",
        "min": 0.2,
        "type": "number",
        "step":0.1,
        "reset": true,
        "required": true,
        "mappingKey":"intransit.ui.shipment.area.violation.distance.max_loc_radius_at_rest"
      },
      {
        "displayLabel": "Arrival Delay period",
        "description": "",
        "type": "arrivalDelay",
        "min":0,
        "reset": true,
        "required": true,
        "mappingKey":"intransit.ui.shipment.metric.arrival_max_delay_period",
        "options":[
          {"value":"mins", "label":"mins"},
          {"value":"hrs", "label":"hours"},
          {"value":"days", "label":"days"},
        ]
      },
      {
        "displayLabel": "Customer Support Contact Email",
        "description": "This value controls the email address to which customer support requests to be sent",
        "type": "mail",
        "reset": true,
        "mappingKey":"customersupport.contact.email"
      },

      {
        "displayLabel": "Notifications refresh Interval (seconds)",
        "description": "Notification refresh interval",
        "type": "number",
        "reset": true,
        "required": true,
        "min":0,
        "mappingKey":"ui.footer.refreshInterval"
      },
      {
        "displayLabel": "Max Map zoom level (number)",
        "description": "Max zoom level to be allowed in maps",
        'min': 0,
        "type": "number",
        "required": true,
        "reset": true,
        "mappingKey":"ui.maps.maxMapZoom",
        'max': 22,
      },
      {
        "displayLabel": "Limit the report's time window to hours",
        "description": "Enable the max limit of time selection to hours of a day, in reports ",
        "type": "radio",
        "options":[
          {"value":"true", "label":"Yes"},
          {"value":"false", "label":"No"},
        ],
        "reset": true,
        "mappingKey":"ui.reports.limitToHours"
      },
      {
        "displayLabel": "Areas visibility in Dwell time section",
        "description": "Enabling/Disabling this setting will change the default visibility of all areas in the list of dwell time section in the Activity card of Asset details page",
        "type": "radio",
        "options":[
          {"value":"true", "label":"Yes"},
          {"value":"false", "label":"No"},
        ],
        "reset": true,
        "mappingKey":"ui.dwellTabZoneVisibility"
      },
      {
        "displayLabel": "Custom Meta Key",
        "description": "Values of this setting is filled and used by various channels,for example: the state definitions are passed saved in this, which shall be used by Web UI, to reference the state labels and colors",
        "reset": false,
        "mappingKey":"CLFRESERVED.CUSTOM_META",
        "type":"text",
      },
      {
        "displayLabel": "Temperature report Plot Y-Axis Min (˚C)",
        "description": "Value is in ˚C and controls the Min value of Y-Axis for the graph in temperature report",
        "min": -70,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tempReport.plotMinY"
      },
      {
        "displayLabel": "Temperature report Plot Y-Axis Max (˚C)",
        "description": "Value is in ˚C and controls the Max value of Y-Axis for the graph in temperature report",
        "min": -20,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tempReport.plotMaxY"
      },
      {
        "displayLabel": "Data interval of Temperature report",
        "description": "This value controls the interval between the data to be displayed in graph and table",
        "min": 5,
        "type": "number",
        "reset": true,
        "required": true,
        "mappingKey":"tempReport.dataInterval"
      },
     ]},
   ];

  constructor(private tenantSettingService:TenantSettingService, private toaster: ToastService, private rulesService: RulesService,
    private propertyService: PropertyService,  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getTenentData();
    this.getRuleSetsData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getKey(object){
    return Object.keys(object);
  }

  getTenentData(){
    const tenentData: Subscription = combineLatest([this.tenantSettingService.getTenantSettingData(), this.tenantSettingService.getFactoryData()]).subscribe((data:any)=>{
      this.tenentSettingData = {...data[0]};
      this.factoryResetData = {...data[1]};
      this.factoryResetData['ui.dateFormat'] = this.factoryResetData['ui.dateFormat']?.replaceAll("DD","dd");
      //console.log(this.tenentSettingData,'this.tenentSettingData');
      //console.log(this.factoryResetData,'this.factoryResetData');

      this.createFormData(this.tenentSettingData, this.factoryResetData);
      let self = this;
      /*setTimeout( () => {
        self.tenentSttingForm.valueChanges.subscribe((selectedValue)=>{
          console.log(selectedValue);
        });
      }, 1000);*/
    });
    this.subscriptions.push(tenentData);
  }

  createFormData(tenentSettingData, factoryResetData){
    let tenentData = {...tenentSettingData};
    let factoryData = {...factoryResetData};
    Object.keys(tenentData).forEach(element => {
      factoryData[element] = tenentData[element];
    });
    this.originalSettings = {...factoryData};
    this.createdData = {...factoryData};

    if(this.createdData['intransit.ui.shipment.metric.arrival_max_delay_period']){
      let arrivalDelayData =this.createdData['intransit.ui.shipment.metric.arrival_max_delay_period'].split(' ');
      this.createdData['arrival-delay-input'] = arrivalDelayData[0];
      this.createdData['arrival-delay-select'] = arrivalDelayData[1];
    }

    this.tenentSttingForm.control.patchValue({...this.createdData});
  }

/*  registerCheckForSave() {
    this.tenentSttingForm.valueChanges.subscribe((selectedValue)=>{
      console.log(selectedValue);
    });
  }*/

  checkForSaveEnabling($ev, key) {
    //if(this.originalSettings[key] !== $ev) {
    this.enableSave = true;    
    //}
    if ($ev.target.name == 'arrival-delay-select' || $ev.target.name == 'arrival-delay-input') {
      this.tenentSttingForm.control.patchValue({
        'intransit.ui.shipment.metric.arrival_max_delay_period': this.tenentSttingForm.value['arrival-delay-input'] + ' ' + this.tenentSttingForm.value['arrival-delay-select']
      });
    }
  }

  isFormValid(formData) {
    let batteryCriticalVal = formData['tag.battery.critical.value'] || 0;
    let batteryWarningVal = formData['tag.battery.warn.value'] || 0;
    if ( (this.originalSettings.hasOwnProperty('tag.battery.warn.value') && (parseInt(batteryCriticalVal, 10) >= parseInt(batteryWarningVal, 10)))) {
      this.toaster.warning('Critical threshold should be less than warning threshold level for sensor battery alerts');
      return false;
    }
    let batteryCriticalMiss = formData['tag.battery.critical.miss'] || 0;
    let batteryWarningMiss = formData['tag.battery.warn.miss'] || 0;
    if ((this.originalSettings.hasOwnProperty('tag.battery.warn.miss') && (parseInt(batteryCriticalMiss, 10) <= parseInt(batteryWarningMiss, 10)))) {
      this.toaster.warning('Missed critical interval should be greater than missed warning intervals');
      return false;
    }
    return true;
  }

  onSubmitTenentForm(formData){
    if(!this.saveClicked){
      this.saveClicked  = true;
      if(!this.isFormValid(formData)) {
        this.saveClicked  = false;
        return;
      }

      let factoryData = {...this.factoryResetData};
      Object.keys(formData).forEach(element => {
        factoryData[element] = formData[element];
      });
      if(this.tenentSettingData['CLFRESERVED.CUSTOM_META']){
        factoryData['CLFRESERVED.CUSTOM_META'] = this.tenentSettingData['CLFRESERVED.CUSTOM_META'];
      }
      const saveTenetDataSubs = this.tenantSettingService.saveTenentSettingData(factoryData).subscribe(data=>{
        this.saveClicked  = false;
        this.enableSave = false;
        this.toaster.success('Updated Tenant settings are now saved');
        this.propertyService.updateTenantSettings(factoryData);
      }, err =>{
        this.saveClicked  = false;
        this.enableSave = true;
      });
      this.subscriptions.push(saveTenetDataSubs);
    }
  }

  resetToFactoryDefaults() {
    if(!this.resetFactoryClicked) {
      this.resetFactoryClicked = true;
      this.tenantSettingService.getFactoryData().subscribe( (response: any) => {
        let factoryData = {...response};
        Object.keys(factoryData).forEach(element => {
          if(factoryData[element] === null && element !== "CLFRESERVED.CUSTOM_META") {
            delete factoryData[element];
          }
        });
        this.tenentSttingForm.control.patchValue({...factoryData});
        this.resetFactoryClicked = false;
        this.toaster.success('Tenant settings are now reset to factory defaults')
      })
    }
  }

  resetProperty(key) {
    this.saveClicked = true;
    let message = `Do you want to reset the setting to default?`;
    const dialogData = new ConfirmDialogModel("Reset Property", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.factoryResetByUser(key);
      }
    });
  }

  factoryResetByUser(key) {
    let factoryDefaults = this.factoryResetData;
    let tenentData = this.tenentSettingData;
    if (key) {
      if (factoryDefaults && factoryDefaults.hasOwnProperty(key)) {
        tenentData[key] = factoryDefaults[key];
        this.createFormData(tenentData, factoryDefaults)
        console.log("tenentSttingForm:", this.tenentSttingForm.value[key], "factoryDefaults:", factoryDefaults[key]);
        this.saveClicked = false;
        this.onSubmitTenentForm(this.tenentSttingForm.value);
      }
    }
  }

  getRuleSetsData() {
    const rulesetSub: Subscription = this.rulesService.getRulesetList().subscribe(
      (res: any) => {
        this.rulesetOption = res;
      }, err => {
        console.log(err);
      }
    );
    this.subscriptions.push(rulesetSub);
  }
}
