import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AssetListService } from '../asset-list.service';
import { WebtrackerService } from "@cl/@core/shell/webtracker.service";
import { ToastService } from '@cl/common/services/toast.service';
import _, { isEmpty } from 'lodash';
import { AssetsStateService } from '@cl/asset/asset-state.service';


@Component({
  selector: 'app-asset-binder',
  templateUrl: './asset-binder.component.html',
  styleUrls: ['./asset-binder.component.scss']
})
export class AssetBinderComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() assetObject: any;
  @Output() closeSidePanel = new EventEmitter();
  @ViewChild(CdkVirtualScrollViewport)
  virtualScroll: CdkVirtualScrollViewport;
  searchTerm = '';
  searchFields = [];
  searchInput = new FormControl();
  bindForm: FormGroup;
  sensorList = [];
  selectedSensorList = [];
  scrollId = "";
  loading = false;
  total = 0;
  pageFromScroll$;
  pageFromSearch$;
  pageFromResize$;
  title = "Bind Asset";
  selectedSensor: any;
  bindClicked: boolean = false;
  bindFlowCheck = null;
  monitoredStatus: string;
  bindbtnText: string = 'Bind';
  bindDisabled: boolean = true;
  bindflowStatus: boolean = false;
  binFlowcheckVal:boolean;
  assetComponent = [];
  assetComponentList: any;
  showComponent: boolean = false;
  constructor(
    private store: Store,
    private fb: FormBuilder,
    private assetListService: AssetListService,
    private webTracker: WebtrackerService,
    private toaster: ToastService,
    private assetState: AssetsStateService
    ) {
    this.bindForm = new FormGroup({
      searchTerm: new FormControl()
    });
  }

  ngOnChanges(): void{
    if(isEmpty(this.assetObject)){
      this.bindflowStatus = false;
    }
  }
  ngOnInit(): void {
    if (!this.sensorList.length && !this.loading) {
      this.getElasticBatch('newList');
    }
    this.assetListService.getAsset(this.assetObject.id).subscribe(res =>{
      if(res.properties.bindflow == false){
        this.bindflowStatus = true;
        this.bindFlowCheck = true;
      }
    })
    this.monitoredStatus = this.assetObject.monitoredStatus.toLowerCase();
    this.getComponentList(this.assetObject.entityTypeKey.toLowerCase());
  }

  ngAfterViewInit(): void {
    this.addSearchInputHandler();
  }

  addSearchInputHandler() {
    this.bindForm.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(
      (value: any) => {
        console.log('form changed to: ', value);
        this.searchTerm = value.searchTerm;
        this.scrollId = "";
        this.getElasticBatch('newList');
      }
    );
  }

  selectListItem(sensorItem) {
    sensorItem.checked = !sensorItem.checked;
    if(sensorItem.checked){
      this.selectedSensorList.push(sensorItem);
    } else {
      let index = this.selectedSensorList.findIndex(item => item.id === sensorItem.id);
      this.selectedSensorList.splice(index,1);
    }
    this.selectedSensor = this.selectedSensorList;
    this.checkUnbind();
    this.webTracker.captureEvent('bindAsset_multiple_sensor_select',{'sensorId': sensorItem.id});
  }

  closePanel(isReload:boolean = false) {
    this.closeSidePanel.emit(isReload);
  }

  bindAsset() {
    this.bindClicked = true;
    let finalSensorList = [];
    if(this.bindflowStatus == true){
      this.binFlowcheckVal = false;
    }else{
      this.binFlowcheckVal = this.bindFlowCheck ? false : true;
    }
    if(this.selectedSensorList.length > 0){
      this.selectedSensorList.forEach((data: any, index: any) => {
        if((this.assetObject.entityTypeKey.toLowerCase() ===  'apss'|| this.assetObject.entityTypeKey.toLowerCase() === 'apsd') && this.assetComponent[index]=== undefined){
          this.toaster.error('Please select component', 'Error', 2000);
          this.bindClicked = false;
        }else if(this.assetObject.entityTypeKey.toLowerCase() !==  'apss' && this.assetObject.entityTypeKey.toLowerCase() !== 'apsd'){
          finalSensorList.push(new Object({
            'assetId' : this.assetObject.id,
            'sensorId' : data.id,
            'bindflow' : this.binFlowcheckVal
          }))
      }else if((this.assetObject.entityTypeKey.toLowerCase() ===  'apss'|| this.assetObject.entityTypeKey.toLowerCase() === 'apsd') && this.assetComponent[index] !== undefined){
        finalSensorList.push(new Object({
          'assetId' : this.assetObject.id,
          'sensorId' : data.id,
          'bindflow' : this.binFlowcheckVal,
          'component_id': this.assetComponent[index]
        }))
        this.bindClicked = true;
      }
      });
      if(!_.isEmpty(finalSensorList)){
        this.assetState.multiBindAsset(finalSensorList).subscribe(res => {}, err => {
          this.bindClicked = false;
        });
        this.webTracker.captureEvent('bindAsset_bind',{'sensorList': finalSensorList});
      }
      
      
    }else if(this.selectedSensorList.length == 0){
      this.assetListService.updateAssetBindFlow(finalSensorList,
        this.assetObject.id,
        this.binFlowcheckVal).subscribe((res: any)=> {
          this.bindClicked = false;
          if(this.selectedSensorList.length == 0){
             this.toaster.success('Bind flow has updated successfully');
             this.closePanel(true);
            // this.store.dispatch([new MalSearchByFilter(true)])
            // this.assetState.searchByFilter(true);
          }
          this.bindDisabled = true;
          this.bindbtnText = 'Bind';
      });
    }
  }

  listScrolled() {
  }

  getElasticBatch(source) {
    this.loading = true;

    const params = {
      "scrollId": this.scrollId,
      "scrollSize": 20,
      "globalQueryText": this.searchTerm,
      "searchQueries": [{
        "fieldName": "parentEntityTypeKey",
        "queryText": "sensor,tracker",
        "queryOperator": "should",
        "queryType": "match",
        "childFilters": [
          {
            "fieldName": "status",
            "queryText": "READY",
            "queryOperator": "should"
          },{
          "fieldName": "deleted",
          "queryText": "true",
          "queryOperator": "must_not"
        }
        ]
      }]
    }

    return this.assetListService.globalSearch(params)
      .subscribe((res) => {
        this.loading = false;
        if (source === 'newList') {
          this.sensorList = res.hits.slice();
        } else {
          this.sensorList = this.sensorList.concat(res.hits);
        }
        this.sensorList.forEach((data: any, index: any) => {
          data.checked = false;
          if(this.selectedSensorList.length){
            this.selectedSensorList.forEach(el => {
              if(el.id == data.id){
                data.checked = true;
              }
            })
          }
        });
        this.total += res.hits.length;
        this.scrollId = res._scroll_id;
      })
  }


  checkUnbind(){
    if((this.bindFlowCheck == true && this.selectedSensorList.length<=0) ||
    (this.bindflowStatus == true && this.selectedSensorList.length<=0)){
      this.bindbtnText = 'Save';
      this.bindDisabled = false;
    }else if(this.selectedSensorList.length>0){
      this.bindbtnText = 'Bind';
      this.bindDisabled = false;
    }else{
      this.bindDisabled = true;
      this.bindbtnText = 'Bind';
    }

    if(this.bindFlowCheck == false){
      this.bindflowStatus = false;
    }
  }

  getComponentList(assetType){
    this.assetListService.getAssetComponents(assetType).then((res) => {
      if(!_.isEmpty(res.components)){
        this.showComponent = true;
        this.assetComponentList = res.components;
      }
    })
  }
}
