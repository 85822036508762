import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DefaultUrlSerializer } from "@angular/router";
import { CL_INPUT_DEBOUNCE_TIME } from "@cl/constants";
import { AggregatedCountApiResponse, ElasticHit, NavigatorConfiguration, NavigatorFilter, PartialOf } from "@cl/models";
import { AppState } from "@cl/ngxs/state";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import _ from "lodash";
import { combineLatest, Observable, Subject } from "rxjs";
import { debounceTime, filter, startWith, switchMap, tap } from "rxjs/operators";
import { NavigatorFiltersService } from "../../service/navigator-filters.service";
import { FilterApiParams } from "../filter-box/filter-box.component";


@UntilDestroy()
@Component({
  selector: "app-domain-filter-box",
  templateUrl: "./domain-filter-box.component.html",
  styleUrls: ["../filter-box/filter-box.component.scss", "./domain-filter-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainFilterBoxComponent),
      multi: true,
    },
  ],
})
export class DomainFilterBoxComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
  @Input() domain: NavigatorFilter;
  @Output() activeFiltersCountChange = new EventEmitter<any>();
  @Output() totalRecords = new EventEmitter<number>();
  @Output() filterdata = new EventEmitter<any>();
  footerLabel: string = '';
  @Select(AppState.mapUIConfigurations) configuration$: Observable<NavigatorConfiguration[]>;
  configuraton: NavigatorConfiguration[];

  searchForm: FormGroup;
  private get newSearchForm() {
    return this.fb.group({
      searchTerm: [""],
      domains: this.fb.group({}),
    });
  }

  private get fcSearchTerm() {
    return this.searchForm.get("searchTerm");
  }

  private get fcDomains() {
    return this.searchForm.get("domains") as FormGroup;
  }

  filtersList: AggregatedCountApiResponse[] = [];
  activeFilters: PartialOf<string[]> = {};
  inActiveFilters: PartialOf<string[]> = {};
  activeFiltersCount = 0;
  totalDomainItemCount = 0;
  uiLayoutColumns: { rows: ElasticHit[] }[] = [];
  apiParams = new FilterApiParams();
  originalFilter: any = {};
  filterSelected: boolean = false;
  domainFilterLabel: any = {};

  /** EsQuery aggField sub filter key map */
  aggSubFilterMap: PartialOf<string> = {};

  // Targeting and visibility
  @Input() visible = true;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Input() targeted = false;
  @Output() toggleTargeted = new EventEmitter<boolean>();

  private updatingFormControlInProgress = false;

  private readonly listScrolled$ = new Subject<boolean>();

  clearDisabled = false;
  menuOpened = false;

  disabled = false;
  onChange: any = (obj: any) => {};
  onTouch: any = () => {};

  flatDomainList: AggregatedCountApiResponse[] = [];
  formFieldsRenderedTrigger = new Subject<boolean>()
  totalControls = 0;
  bufferSize = 50;
  isDestroyed = false;

  disabledFields = ['drafts', 'pending', 'planned', 'incomplete', 'unmonitored', 'completed']


  constructor(private fb: FormBuilder, private navigatorFilters: NavigatorFiltersService, private cd: ChangeDetectorRef) {
    this.searchForm = this.newSearchForm;
    this.configuration$.pipe(
      untilDestroyed(this),
      debounceTime(CL_INPUT_DEBOUNCE_TIME)).subscribe(l=>{
      this.configuraton=l;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.hookDomainApi();
    this.hookDomainSelectionChange();
    // if(this.domain.id === 'location') {
    //   this.visible = true;
    // }

    this.formFieldsRenderedTrigger.subscribe(isCompleted => {    
      if(isCompleted){
        this.cd.detectChanges()
        this.originalFilter = this.fcDomains.value;
        this.updatingFormControlInProgress = false;

        this.parseAggFieldsToUILayout(this.flatDomainList);
        this.apiParams.loading = false;
      }
    })
  }

  ngOnDestroy(): void {
    this.listScrolled$.complete();
    this.formFieldsRenderedTrigger.complete()
    this.isDestroyed = true;
  }

  trackByIdFn = (_: number, item: any) => item?.id;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    // console.log("🚀 ~ DomainFilterBoxComponent ~ writeValue ~ obj", obj);
    // console.log("🚀 ~ DomainFilterBoxComponent ~ writeValue ~ this.searchForm", this.searchForm);
    this.fcDomains.patchValue(obj);
  }

  /**
   * Disables the filter box
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Clear domain selections
   */
  clearAll(): void {
    this.fcDomains.patchValue(this.originalFilter);
  }

  /**
   * Emits event to reset all other targeted domains and target or disable target for
   * current domain.
   */
  resetAndToggleTargeted() {
    this.toggleTargeted.emit(!this.targeted);
  }

  /**
   * Sets the targeted of the current filter.
   */
  setTargeted(val: boolean) {
    this.targeted = val;
  }

  /**
   * Toggles current visibility flag for the current domain.
   */
  toggleVisibility(val?) {
    // this.visible = val;
    try {
      let tempVal = val!=null ? val : !this.visible;
      this.setVisibility(tempVal);
      this.visibleChange.emit(this.visible);
    } catch (e) {}
  }


  /**
   * Sets the visibility of the current domain filter.
   */
  setVisibility(val: boolean) {
    // If current domain is targeted then it cannot be set as invisible
    if (this.targeted && !val) throw new Error("Cannot set visibility to false for a targeted domain");

    this.visible = val;
  }

  /**
   * Checks if the domain filter api contains sub-filters. If the aggField value contains separator
   * with ; then it has sub filters.
   */
  prepareSubFilters() {
    if (!this.domain?.aggregateApi?.url) return null;

    const url = this.domain.aggregateApi.url;
    const urlSerializer = new DefaultUrlSerializer();
    const urlTree = urlSerializer.parse(url);
    const aggField = urlTree.queryParamMap.get("aggField");

    // Not an ES query containing aggField
    if (!aggField) return null;

    // Check if it contains sub filters
    const aggFieldValues = aggField.split(",");
    const aggSubFilterMap: PartialOf<string> = {};

    for (let i = 0; i < aggFieldValues.length; i++) {
      const aggFieldVal = aggFieldValues[i];

      // Does not contain sub filters
      if (!aggFieldVal.includes(";")) continue;

      const values = aggFieldValues[i].split(";");
      const key = values[0];
      const value = values[1];

      aggSubFilterMap[key] = value;
    }

    this.aggSubFilterMap = aggSubFilterMap;

    return aggSubFilterMap;
  }

  findEvent(event) {
    // if(event.checked){
    //   setTimeout(() => {
    //     this.store.dispatch(new NavigatorLastAction('filterSelected'))
    //   }, 2000);
    // }
    this.filterSelected = true;
  }

  private hookDomainSelectionChange() {
    // CAUTION: Too cryptic code starts below
    // don't update without checking the payload, that too with nested filters
    this.fcDomains.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),
        filter((_) => !this.updatingFormControlInProgress)
      )
      .subscribe((allDomains) => {
        this.activeFilters = {};
        this.inActiveFilters = {};
        this.activeFiltersCount = 0;

        const activeFilters = {};

        _.forOwn(allDomains, (domain, type) => {
          if (!activeFilters[type]) activeFilters[type] = {};

          let parentActiveCount = 0;

          _.forOwn(domain, (val: any, key) => {
            if (!activeFilters[type][key]) activeFilters[type][key] = { $$active: false, childFilters: [] };
            let activeItemCount = 0;

            _.forOwn(val, (v, k) => {
              if (k === "$$active") activeFilters[type][key][k] = v;
              else if (v) activeFilters[type][key].childFilters.push(k);

              if (v) {
                this.activeFiltersCount++;
                activeItemCount++;
                parentActiveCount++;
              }
            });

            if (activeItemCount === 0) delete activeFilters[type][key];
          });

          if (parentActiveCount === 0) delete activeFilters[type];
        });

        // Update active filters
        this.onChange(activeFilters);
        this.activeFiltersCountChange.emit({filterCount: this.activeFiltersCount, filterSelected: this.filterSelected});

        // Set the domain to visible if any filter has been selected
        if (!this.visible && this.activeFiltersCount > 0) {
          this.toggleVisibility();
        }
      });
  }

  /**
   *
   */
  private hookDomainApi() {
    const listScrolled$ = this.listScrolled$.pipe(startWith(true));

    // On any of these events
    combineLatest([listScrolled$])
      .pipe(
        untilDestroyed(this),
        debounceTime(CL_INPUT_DEBOUNCE_TIME),
        tap((_) => (this.apiParams.loading = true)),
        switchMap((_) => this.navigatorFilters.domainApi(this.domain, this.apiParams.scrollId, this.fcSearchTerm.value))
        // tap((_) => (this.apiParams.loading = false))
      )
      .subscribe(
        (res) => {
          this.filtersList = res;
          const flatDomainList = this.navigatorFilters.flattenAggregatedCountApiResponse(res);
          this.totalDomainItemCount = flatDomainList.length > 0 ? flatDomainList[0].totalHits : 0;
          this.totalRecords.emit(this.totalDomainItemCount);
          this.flatDomainList = flatDomainList;
          this.prepareDomainFormFields(flatDomainList);
        },
        (err) => {
          this.apiParams.loading = false;
        }
      );
  }

  /**
   * Converts filter list api response to form fields.
   */
  private prepareDomainFormFields(domainList: AggregatedCountApiResponse[]) {
    this.updatingFormControlInProgress = true;

    // Get existing fields and their values
    const existingDomains = this.fcDomains.value;

    // Create new set of domains
    const newDomains = domainList.reduce((acc, curr) => {
      curr.hits.forEach((hit) => {
        if (!acc[hit.name]) acc[hit.name] = {};

        hit.buckets.forEach((bucket) => {
          if (!bucket.parentName) acc[hit.name][bucket.key] = { $$active: false };
          else acc[hit.name][bucket.parentName][bucket.key] = false;
        });
      });

      // acc[curr.id] = existingDomains[curr.id] ?? false;
      return acc;
    }, {});

    // Clear existing domain form and update with the current
    Object.keys(existingDomains).forEach((domain) => this.fcDomains.removeControl(domain));

    this.generateForm(newDomains)
  }
  
  generateForm(newDomains: {}) {
    const entries = Object.entries(newDomains);
    this.totalControls = entries.length;
    
    entries.forEach((entry, index) => {
      const innerEntires = Object.entries(entry[1]);
      const parentFormGroup = this.fb.group({});
      this.generateControlsInBatch(entry[0], innerEntires, 0, parentFormGroup, index+ 1);
    })
  }
  generateControlsInBatch(
    key: string,
    innerEntires: any[],
    currentBatch: number,
    parentFormGroup: FormGroup,
    controlIndex: number
  ) {
    if(this.isDestroyed) return;
    if (currentBatch < innerEntires.length) {
      const end = Math.min(currentBatch + this.bufferSize, innerEntires.length);
      for (let i = currentBatch; i < end; i++) {
        const entry = innerEntires[i];
        const subLevelFormGroup = this.fb.group({});
        _.forOwn(entry[1], (v, k) => {
          subLevelFormGroup.addControl(k, new FormControl(v));
        });

        parentFormGroup.addControl(entry[0], subLevelFormGroup)
      }
      currentBatch = end;

      setTimeout(
        () =>
          this.generateControlsInBatch(
            key,
            innerEntires,
            currentBatch,
            parentFormGroup,
            controlIndex
          ),
        0
      );
    } else {
      this.fcDomains.addControl(key, parentFormGroup);
      this.formFieldsRenderedTrigger.next(this.totalControls == controlIndex)
    }
  }

  private prepareAllFiltersPayload() {
    let temp = this.uiLayoutColumns[0]?.rows?.map(data => {
      return {
        name: data.name,
        filters: _.map(data.buckets, 'key').join(',')
      }
    })
    this.filterdata.emit({ "domainId" : this.domain.id,"filterData" : temp??[] });
  }

  private parseAggFieldsToUILayout(aggFields: AggregatedCountApiResponse[]) {
    const uiLayoutColumns: { rows: ElasticHit[] }[] = [];
    const domainLabels = this.domain['aggregateApi']['label'];
    let colIndex = 0;
    for (let i = 0; i < aggFields.length; i++) {
      const aggField = aggFields[i];

      for (let j = 0; j < aggField.hits.length; j++) {
        const hit = aggField.hits[j];

        // let colIndex = i;
        if (!uiLayoutColumns[colIndex]) uiLayoutColumns.push({ rows: [] });

        let rowLength = 10;
        if (uiLayoutColumns.length > 0) {
          rowLength = uiLayoutColumns[uiLayoutColumns.length - 1].rows.reduce((a: number, c) => a + c.buckets.length, 0);
        }
        hit.displayLabel =  (domainLabels && domainLabels[hit.name]) ? domainLabels[hit.name] : hit.name;
        // if (rowLength + hit.buckets.length < 9) colIndex = i - 1;
        uiLayoutColumns[colIndex].rows.push({ ...hit });
        // originalHit.buckets.forEach((b) => (b._subBucketVisible = false));

        if (rowLength + hit.buckets.length > 6) colIndex++;
      }
    }

    this.uiLayoutColumns = uiLayoutColumns;

    // console.log("🚀 ~ DomainFilterBoxComponent ~ parseAggFieldsToUILayout ~ this.uiLayoutColumns", this.uiLayoutColumns);
    this.prepareSubFilters();

    this.prepareAllFiltersPayload();

    return uiLayoutColumns;
  }

  private reset() {
    this.apiParams = new FilterApiParams();
    this.filtersList = [];
  }
}
