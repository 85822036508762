import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { CustomRoute } from '@cl/@types/custom-route.type';
import * as _ from 'lodash';
import { UserService } from '../user/user.service';
import {Subject, Observable} from "rxjs";
import { PropertyService } from '@cl/property/property.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeService {
  policies: string[] = [];
  isFieldTechUser = false; //TODO::hack for fieldTechUser, remove & replace it once UIProfile implemented
  private policyEvent = new Subject<any>();
  static DEFAULT_PERMISSION: string = 'ANY';
  uiPages:any;
  constructor(private _userService: UserService, private _propertyService:PropertyService) {
    //this.setUserData();
  }

  setPolicyLoadedEvent(state: any) {
    this.policyEvent.next(state);
  }

  getPolicyLoadedEvent(): Observable<any> {
    return this.policyEvent.asObservable();
  }

  setUserData(){
    this.policies = this._userService.getPolicies();
    this.setPolicyLoadedEvent('policiesLoaded');
    this.isFieldTechUser = this._userService.isFieldTechUser(); //TODO::hack for fieldTechUser, remove & replace it once UIProfile implemented
  }

  isValidRouteIfPoliciesLoaded(route: CustomRoute) {
    return new Observable((obs: any) => {
      let routePolicies = route?.permission?.policies;
      if(routePolicies.length===1 && routePolicies[0]=== AuthorizeService.DEFAULT_PERMISSION && !this.isFieldTechUser){
        obs.next(true);
        obs.complete();
      } else if(this.policies.length) {
        obs.next(this.isValidRoute(route));
        obs.complete();
      } else {
        this.getPolicyLoadedEvent().subscribe((event)=>{
          if(event === 'policiesLoaded') {
            obs.next(this.isValidRoute(route));
            obs.complete();
          }
        });
      }
    });
  }

  isValidRoute(route: CustomRoute){
    let status: boolean;
    let routePolicies = route?.permission?.policies;
    let uiPages = this._userService.getUserUipages();
    if(uiPages.indexOf(route?.pageKey) ===-1 && route?.pageKey !== 'themes'){ //FIXME: HACK for checking themes from policies instead of uiprofiles
      return false;
    }
    if (!_.isEmpty(routePolicies)) {
      status = false;
      if (this.isFieldTechUser) { //TODO::hack for fieldTechUser, remove & replace it once UIProfile implemented
        return route.path ? ((route.path.indexOf('assets') > -1) || (route.path.indexOf('assetdetails') > -1)) : false;
      } else if (routePolicies.length === 1 && routePolicies[0] === AuthorizeService.DEFAULT_PERMISSION) {
          status = true;
      } else {
        if (route.path === 'biworkbench'){
          status =  this._propertyService.getConfigurationInBulkSettings('feature.biworkbenchenable') === 'false' ? false : this.isValidAction(routePolicies, true);
        } else if (route.path === 'mlworkbench') {
          status = this._propertyService.getConfigurationInBulkSettings('feature.mlworkbenchenable') === 'false' ? false : this.isValidAction(routePolicies, true);
        } else if(route.path == 'navigator'){
          status = this.isValidAction(routePolicies, true);
        } else {
          status = this.isValidAction(routePolicies);
        }
      }
    }
    return status;
  }

  isValidAction(actions: string[], allowOr?: boolean) {
    let valid;
    if(allowOr){
      valid = actions.some(action => this.policies.includes(action));
    } else {
      valid = actions.every(action => this.policies.includes(action));
    }
    return valid;
  }
}
