import { Component, Input, OnChanges, Output, SimpleChanges, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { PropertyService } from '@cl/property/property.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

export interface DatePickerRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}
@Component({
  selector: 'cl-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss']
})
export class CustomDatePickerComponent implements OnChanges {
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;

  @Input('dateRange') selectedDateRange: DatePickerRange;
  @Input('ranges') ranges: any;
  @Input() datePickerClass: any;
  @Input() displayFormat:any;
  @Input() singleDatePicker: any;
  @Input() disablePreviousdates: any;
  timePicker24Hour: boolean = true;
  @Input ('minDate') minDate: moment.Moment;
  @Input ('maxDate') maxDate: moment.Moment;
  @Input() placeholder = '';
  @Input() localValue;
  @Input() opened =false;

  
  addClass: any;

  locale : any = {
    format: 'MM/DD/YYYY',
    displayFormat: 'MM/DD/YYYY hh:mm:ss',
    weekLabel: 'W',
    customRangeLabel: 'Custom range',
    direction: 'ltr'
  };
  @Output('dateChanged') onDateChange: EventEmitter<DatePickerRange> = new EventEmitter<DatePickerRange>();

  constructor(private pService: PropertyService) {
  }

  datesUpdated(daterange: any){
     if(this.opened){
      this.onDateChange.emit(this.selectedDateRange);
      this.localValue = this.selectedDateRange.startDate.format(this.displayFormat);
    }
  }

  openDatepicker() {
    this.pickerDirective.open();
    this.opened =true;
  }

  ngOnChanges(changes: any) {
    if(changes.opened?.currentValue == true){
       if(changes.selectedDateRange?.currentValue){
        this.localValue = this.selectedDateRange.startDate.format(this.displayFormat);
      }
    }
    let dateformat:any = this.pService.getSetting('ui.dateFormat');
    let updatedDate = dateformat.replace('dd', 'DD')
    
    if(dateformat){
      this.timePicker24Hour = !updatedDate.includes('a');
      this.locale.displayFormat = _.cloneDeep((this.displayFormat || updatedDate).slice());
      this.addClass = _.cloneDeep((this.datePickerClass || []).slice());
      if(this.disablePreviousdates){
        this.minDate = moment(this.disablePreviousdates);
      }else{
        this.minDate = null
      }
    }
  }
}

