import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { LocationUtilsService } from '@cl/locations/location-utils.service';
import { CdmField } from '@cl/@types/extended-attributes.types';
//import { Maps, ShipmentService } from '@cl/shipments/shipment.service';

@Component({
  selector: 'cl-location-site',
  templateUrl: './location-site.component.html',
  styleUrls: ['./location-site.component.scss'],
})
export class LocationSiteComponent implements OnInit, OnChanges {
  @Output() closePanel = new EventEmitter();
  @Output() selectedPosition = new EventEmitter();
  @Output() markerChanged = new EventEmitter();
  @Output() emitSelectedRadius = new EventEmitter();
  @Output() emitSelectedPolygonPoints = new EventEmitter();
  @Output() emitTabIndex = new EventEmitter();
  @Input() orgList: any;
  @Input() isOrgRestrictedUser: boolean;
  @Input('changedRadius') changedRadius: number;
  @Input('editSite') editSite: any;
  @Input('zoom') zoom: number;
  @Input('mode') mode: string;
  @Input('polygonPts') polygonPts = [];
  @Input('mapClickedPosition') mapClickedPosition: any;
  @Input() objectType: any;
  @ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;
  @ViewChild('addressTemp') addressTemp: TemplateRef<any>;
  @ViewChild('selectShapeTool') selectShapeTool: TemplateRef<any>;
  @ViewChild('position') position: TemplateRef<any>;
  @ViewChild('areaFenceTypeTemp') areaFenceTypeTemp: TemplateRef<any>;
  @ViewChild('cityTemp') cityTemp: TemplateRef<any>;
  @ViewChild('stateTemp') stateTemp: TemplateRef<any>;
  @ViewChild('countryTemp') countryTemp: TemplateRef<any>;
  @ViewChild('zipTemp') zipTemp: TemplateRef<any>;
  @ViewChild('orgTemp') orgTemp: TemplateRef<any>;

  siteForm: FormGroup;
  username = '';
  markers: string[] = ['circle', 'polygon', 'point'];
  selectedMarker: any;
  regex = '^[A-Za-z0-9 @ & ( ) _  : \' , . ; + " / -]+$';
  siteTypes: string[] = [
    'Service centers',
    'Hubs',
    'Return Locations',
    'Pickups Locations',
  ];
  staticFields: string[] = [
    'areaFenceType',
    'zip',
    'country',
    'state',
    'city',
    'position',
    'size',
    'address',
    'organization',
  ];
  fieldTypes: string[] = [];
  tabSelected = 0;
  currentIndex = 0;
  previousIndex = 0;

  // for bulk upload
  @Input() bulkObj: any;
  @Input() showResponse: boolean;
  @Input() bulkResponseArr: any[];
  @Output() emitFormData = new EventEmitter();
  fileFormData: any;
  initializedData: any = {};
  dynamicFormData: any = {};

  dynamicInputFields: InputField[] = [];
  downloadedCSVFields: string[] = [];
  submitted: boolean = false;

  constructor(
    public dialog: MatDialog,
    private _locationUtils: LocationUtilsService,
    private _dynamicFormService: DynamicFormService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.mode == 'add') {
      this.buildEmptyForm();
    } else if (this.mode == 'edit' && this.editSite) {
      let position = this.editSite.position;
      if (!position) {
        if (
          this.editSite.coordinates &&
          JSON.parse(this.editSite.coordinates).geometry?.coordinates
        ) {
          position =
            JSON.parse(this.editSite.coordinates).geometry.coordinates[1] +
            ',' +
            JSON.parse(this.editSite.coordinates).geometry.coordinates[1];
        }
      }
      let areaFenceType = this.editSite.areaFenceType;
      let radius = '';
      if (areaFenceType) {
        if (
          this.editSite.coordinates &&
          JSON.parse(this.editSite.coordinates).properties?.type
        ) {
          areaFenceType = JSON.parse(this.editSite.coordinates).properties
            ?.type;
          if (areaFenceType.toLowerCase() == 'circle') {
            radius = Number(
              JSON.parse(this.editSite.coordinates).properties?.radius
                ? JSON.parse(this.editSite.coordinates).properties?.radius
                : Math.round(Number(this.editSite.size))
            ).toFixed(2);
          }
        }
      }
      let address = this.editSite.fullAddress;
      if (!address) {
        if (
          this.editSite.coordinates &&
          JSON.parse(this.editSite.coordinates).address
        ) {
          address = JSON.parse(this.editSite.coordinates).address;
        }
      }
      this.siteForm = new FormGroup({
        organization: new FormControl(this.editSite.organization),
        address: new FormControl(address, [
          Validators.required,
          // Validators.pattern(this.regex),
        ]),
        areaFenceType: new FormControl(areaFenceType.toLowerCase(), [
          Validators.required,
        ]),
        position: new FormControl(position, [
          Validators.required,
          Validators.pattern(this.regex),
        ]),
        radius: new FormControl(radius),
        city: new FormControl(this.editSite.city, [
          Validators.required,
          // Validators.pattern(this.regex),
        ]),
        postalcode: new FormControl(this.editSite.zip, [
          Validators.required,
          Validators.pattern(this.regex),
        ]),
        state: new FormControl(this.editSite.state, [
          Validators.required,
          // Validators.pattern(this.regex),
        ]),
        country: new FormControl(this.editSite.country, [
          // Validators.pattern(this.regex),
        ]),
      });
      this.siteForm.value.position
        ? this.selectedPosition.emit({
            lat: Number(this.siteForm.value.position.split(',')[0]),
            lng: Number(this.siteForm.value.position.split(',')[1]),
          })
        : '';
      this.siteForm.value.areaFenceType
        ? this.markerChanged.emit(this.siteForm.value.areaFenceType)
        : '';
      if (this.siteForm.value.areaFenceType.toLowerCase() == 'circle')
        this.emitSelectedRadius.emit(
          JSON.parse(this.editSite.coordinates).properties?.radius
            ? Math.round(
                JSON.parse(this.editSite.coordinates).properties?.radius
              )
            : Math.round(Number(this.editSite.size.split(' ')[0]))
        );
      if (areaFenceType?.toLowerCase() == 'polygon')
        this.emitSelectedPolygonPoints.emit(
          JSON.parse(this.editSite.coordinates).geometry?.coordinates
        );
    }
    this.getExtendedAttributes();
    this.initializedData = { ...this.siteForm.value };
  }

  buildEmptyForm() {
    this.siteForm = new FormGroup({
      organization: new FormControl(this.orgList[0]?.id),
      address: new FormControl(null, [
        Validators.required,
        // Validators.pattern(this.regex),
      ]),
      areaFenceType: new FormControl(null, [Validators.required]),
      position: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.regex),
      ]),
      radius: new FormControl(this.changedRadius),
      city: new FormControl(null, [
        Validators.required,
        // Validators.pattern(this.regex),
      ]),
      postalcode: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.regex),
      ]),
      state: new FormControl(null, [
        Validators.required,
        // Validators.pattern(this.regex),
      ]),
      country: new FormControl(null, ),
    });
    // this.getSelectedMarker();
    this.emitTabIndex.emit(this.tabSelected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['changedRadius']) {
      this.siteForm?.get('radius').patchValue(this.changedRadius.toFixed(2));
    }

    if (changes['mapClickedPosition']) {
      var self = this;
      this.siteForm?.patchValue({
        position:
          this.mapClickedPosition.lat + ',' + this.mapClickedPosition.lng,
      });
      if (this.mapClickedPosition) {
        new google.maps.Geocoder().geocode(
          {
            location: new google.maps.LatLng(
              self.mapClickedPosition.lat,
              self.mapClickedPosition.lng
            ),
          },
          function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                self.siteForm?.patchValue({
                  address: results[0].formatted_address,
                });
                self.setAddressFields(results[0]);
              }
            }
          }
        );
      }
    }
  }

  checkIspolygonAdded(){
    if(this.siteForm.value.areaFenceType=="polygon"){
      return this.polygonPts.length > 0 
    }
    return true
   }

  submitForm() {
    this.submitted = true;
    if (this.siteForm.valid && this.checkIspolygonAdded()) {
      if (this.dynamicForm) {
        console.log(this.dynamicForm)
        if (this.dynamicForm?.onSubmit().valid) {
          return this.preparePayload();
        }
      } else {
        return this.preparePayload();
      }
    } else {
      this.validateAllFormFields(this.siteForm);
      this.dynamicForm.onSubmit()
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  handleAddressChange(address: any) {
    this.siteForm.patchValue({ address: address.formatted_address });
    this.siteForm.patchValue({
      position:
        address.geometry.location.lat() + ',' + address.geometry.location.lng(),
    });
    this.setAddressFields(address);
    this.selectedPosition.emit({
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    });
  }

  setAddressFields(place: any) {
    place.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        if (addType == 'locality' || addType == 'sublocality_level_1')
          this.siteForm.patchValue({ city: add.long_name });
        if (addType == 'administrative_area_level_1')
          this.siteForm.patchValue({ state: add.long_name });
        if (addType == 'country')
          this.siteForm.patchValue({ country: add.long_name });
        if (addType == 'postal_code')
          this.siteForm.patchValue({ postalcode: add.long_name });
      });
    });
  }

  getSelectedMarker(event) {
    this.markerChanged.emit(event.value.toLowerCase());
    this.siteForm?.get('areaFenceType').patchValue(event.value);
  }
  convertTitleCase(str) {
    return str
      ?.split(' ')
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
  }

  convertPolygonJsonToArray() {
    if (this.polygonPts.length == 0) return [];
    const points = this.polygonPts.map((point) => [point.lng, point.lat]);
    return [[...points, points[0]]];
  }

  preparePayload(): any {
    const polyPoints = this.convertPolygonJsonToArray();

    let coordinates = {
      type: 'Feature',
      geometry: {
        type:
          this.siteForm.value.areaFenceType.toLowerCase() == 'polygon'
            ? this.convertTitleCase(this.siteForm.value.areaFenceType)
            : 'Point',
        coordinates:
          this.siteForm.value.areaFenceType.toLowerCase() == 'circle' ||
          this.siteForm.value.areaFenceType.toLowerCase() == 'point'
            ? [
                this.siteForm.value.position.split(',')[1],
                this.siteForm.value.position.split(',')[0],
              ]
            : polyPoints,
      },
      properties: {
        type: this.convertTitleCase(this.siteForm.value.areaFenceType),
        radius:
          this.siteForm.value.areaFenceType.toLowerCase() == 'circle'
            ? this.siteForm.value.radius
            : undefined,
      },
      address: this.siteForm.value.address,
      zoomLevel: this.zoom,
    };
    let payload = {
      organization: this.siteForm.value.organization,
      coordinates: JSON.stringify(coordinates),
      address: this.siteForm.value.address,
      city: this.siteForm.value.city,
      state: this.siteForm.value.state,
      zip: this.siteForm.value.postalcode,
      country: this.siteForm.value.country,
      areaFenceType: this.convertTitleCase(this.siteForm.value.areaFenceType),
      position: this.siteForm.value.position,
      catalogType: 'Site',
      objectType: this.objectType,
    };
    const dynamicFormGroup = this.dynamicForm?.onSubmit();
    if (dynamicFormGroup) {
      payload = { ...dynamicFormGroup.getRawValue(), ...payload };
    }
    return payload;
  }

  onSelectedTabChange(event) {
    this.tabSelected = event.index;
    this.previousIndex = event.index;
    this.emitTabIndex.emit(event.index);
    if (this.tabSelected == 0) {
    } else {
      this.fileFormData = null;
    }
  }

  getUploadFormData(event) {
    this.fileFormData = event;
    this.emitFormData.emit(event);
  }

  checkFormDataAvaliable(): boolean {
    const form = this.dynamicForm?.form.getRawValue();
    let hasValues = false;
    if (this.previousIndex == 0) {
      let currentData = { ...form, ...this.siteForm.value };
      let isChange = false;
      for (let key in this.initializedData) {
        if (currentData[key] !== this.initializedData[key]) {
          isChange = true;
        }
      }
      hasValues = isChange;
    } else {
      hasValues = this.fileFormData ? true : false;
    }
    return hasValues;
  }
  onTabClick(tab: any) {
    this.currentIndex = tab.selectedIndex;
    if (this.checkFormDataAvaliable()) {
      tab.selectedIndex = this.previousIndex;
      let discardData = this.fileFormData ? 'File' : 'Site';
      this.dialog
        .open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Discard',
            message: 'Are you sure want discard ' + discardData + '?',
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            tab.selectedIndex = this.currentIndex;
            this.previousIndex = this.currentIndex;
            this.tabSelected = this.currentIndex;
            this.emitTabIndex.emit(this.tabSelected);
            this.siteForm.reset();
            this.dynamicForm?.form?.reset();
            this.siteForm
              ?.get('radius')
              .patchValue(this.changedRadius.toFixed(2));
            this.siteForm?.get('organization').patchValue(this.orgList[0]?.id);

            return;
          }
          tab.selectedIndex = this.previousIndex;
        });
      return;
    }
    tab.selectedIndex = this.currentIndex;
    this.tabSelected = this.currentIndex;
    this.emitTabIndex.emit(this.tabSelected);
  }

  async getExtendedAttributes() {
    const siteCatalog = await this._locationUtils.getLocationExtendedAttributes(
      this.objectType
    );

    const fields = this._dynamicFormService.getRenderableFields(
      siteCatalog.cdmFields
    );

    const orderedFields: CdmField[] = this._dynamicFormService.orderFields(
      [...fields],
      'order'
    );

    this.downloadedCSVFields = orderedFields.map((field) => {
      let label = field.displayLabel;
      if (field.required) {
        label += '*';
      } else if (field.displayLabel.endsWith('*')) {
        label += '*';
      }
      return `"${label}"`;
    });

    //const userFields = orderedFields.filter(field => field.group == 'User')
    this.dynamicInputFields =
      await this._dynamicFormService.generateInputFields(
        orderedFields,
        this.mode == 'edit',
        this.editSite
      );
    this.dynamicInputFields = this.dynamicInputFields.filter(
      (inp) => inp.id != 'coordinates'
    );
    this.dynamicInputFields.forEach((inp) => {
      if (inp.id == 'areaFenceType') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.required = false;
        inp.template = this.selectShapeTool;
      }
      if (inp.id == 'organization') {
        inp.isDynamicField = false;
        inp.required = false;
        inp.template = this.orgTemp;
      }
      if (inp.id == 'address') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.template = this.addressTemp;
        inp.required = false;
      }
      if (inp.id == 'size') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.template = this.areaFenceTypeTemp;
        inp.required = false;
      }
      if (inp.id == 'position') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.template = this.position;
        inp.required = false;
      }
      if (inp.id == 'city') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.template = this.cityTemp;
        inp.required = false;
      }
      if (inp.id == 'state') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.template = this.stateTemp;
        inp.required = false;
      }
      if (inp.id == 'country') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.template = this.countryTemp;
        inp.required = false;
      }
      if (inp.id == 'zip') {
        this.fieldTypes.push(inp.id);
        inp.isDynamicField = false;
        inp.template = this.zipTemp;
        inp.required = false;
      }
      if (inp.id == 'name') {
        // this.fieldTypes.push(inp.id);
        inp.isDynamicField = true;
        // inp.template = this.zipTemp;
        inp.required = true;
      }
      if (!this.staticFields.includes(inp.id)) {
        this.initializedData[inp.id] = inp.value;
      }
    });
    this.cd.detectChanges();
  }
  onOrgclose() {
    this.siteForm.get('organization').setValue('');
  }
}
