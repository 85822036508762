<div class="mb-4"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>
<div class="details-page-wrapper location-details-wrapper">
  <div class="flex justify-between">
    <div class="mt-2 mb-2"><span class="page-title">{{summaryObj?.properties?.name}}</span></div>
    <div>
      <cl-recipe-actions [entityTypeId]="summaryObj?.properties.entityTypeId" [entityId]="summaryObj?.properties.id" [parentEntityType]="summaryObj?.properties.parentEntityTypeKey" ></cl-recipe-actions>
    </div>
  </div>
  <div class="details-header-wrapper">
    <div class="flex items-stretch flex-wrap">
      <div class="details-flex-card bgcolor2 ml0 mr10">
        <div class="cl-location-summary h-80 pr-1 overflow-auto">
          <ng-container *ngFor="let cdmField of attributes | sortByOrder">
            <div  *ngIf="cdmField?.isDisplayableOnDetails">
            <div class="flex mt5" *ngIf="!customFields.includes(cdmField.name) && !modifiedFields.includes(cdmField.name)" >
              <div class="basis-1/5 font-bold" [textContent]="cdmField?.displayLabel"></div>
              <div class="grow basis-4/5" style="word-break: break-word;" [textContent]="getExtendedAttributeValue(cdmField, summaryObj?.properties)"></div>
            </div>
            <div class="flex mt5" *ngIf="cdmField.name=='position'">
              <div class="basis-1/5 font-bold">Position(lan,lon)</div>
              <div class="grow basis-4/5" style="word-break: break-word;">
                <span id="copySource">{{summaryObj?.properties?.position}}</span>
                <i class="fa fa-copy color4 ml10 cursor-pointer" *ngIf="summaryObj?.properties?.position" title="Copy Lat/Lng to Clipboard"
                  (click)="copyLatLng($event)"></i>
              </div>
              </div>
              <div class="flex mt5" *ngIf="cdmField.name=='shipmentIB' && summaryObj?.properties?.parentEntityTypeKey  != 'gatewayzone'">
                <div class="basis-1/5 font-bold">Inbound Shipments</div>
                <div class="grow basis-4/5" style="word-break: break-word;"><span class="color4 cursor-pointer" (click)="navigateToShipments('inbound')"> {{summaryObj?.IBCount}} </span></div>
              </div>
              <div class="flex mt5" *ngIf="cdmField.name=='shipmentOB' && summaryObj?.properties?.parentEntityTypeKey != 'gatewayzone'">
                <div class="basis-1/5 font-bold">Outbound Shipments</div>
                <div class="grow basis-4/5" style="word-break: break-word;"><span class="color4 cursor-pointer" (click)="navigateToShipments('outbound')"> {{summaryObj?.OBCount}} </span></div>
              </div>
              <div class="flex mt5" *ngIf="cdmField.name=='returnShipments' && summaryObj?.properties?.parentEntityTypeKey != 'gatewayzone'">
                <div class="basis-1/5 font-bold">Return Shipments</div>
                <div class="grow basis-4/5" style="word-break: break-word;"><span class="color4 cursor-pointer" (click)="navigateToShipments('return')"> {{summaryObj?.returnCount}} </span></div>
              </div>
              <div class="flex mt5" *ngIf="cdmField.name=='assets'">
                <div class="basis-1/5 font-bold">Total Assets</div>
                <div class="grow basis-4/5" style="word-break: break-word;"> {{assetDist.length}} </div>
              </div>
              <div class="flex mt5" *ngIf="cdmField.name=='areaFenceType'">
                <div class="basis-1/5 font-bold">Area Fence Type</div>
                <div *ngIf="summaryObj?.properties?.areaFenceType.toLowerCase()=='circle'" class="grow basis-4/5" style="word-break: break-word;"> Radius </div>
                <div *ngIf="summaryObj?.properties?.areaFenceType.toLowerCase()!='circle'" class="grow basis-4/5" style="word-break: break-word;"> {{summaryObj?.properties?.areaFenceType}} </div>
              </div>
              <div class="flex mt5" *ngIf="cdmField.name=='geoFenceType'">
                <div class="basis-1/5 font-bold">Geo fence type</div>
                <div *ngIf="summaryObj?.properties?.areaFenceType.toLowerCase()=='circle'" class="grow basis-4/5" style="word-break: break-word;"> Radius </div>
                <div *ngIf="summaryObj?.properties?.areaFenceType.toLowerCase()!='circle'" class="grow basis-4/5" style="word-break: break-word;"> {{summaryObj?.properties?.areaFenceType}} </div>
              </div>

              </div>
          </ng-container>
          <div class="flex mt5" *ngIf="summaryObj?.properties?.createdAt">
            <div class="basis-1/5 font-bold">Created At</div>
            <div class="grow basis-4/5" ><span class="cursor-pointer"> {{datePipe.transform(summaryObj?.properties?.createdAt)}} </span></div>
          </div>
          <div class="flex mt5" *ngIf="summaryObj?.properties?.createdBy">
            <div class="basis-1/5 font-bold">Created By</div>
            <div class="grow basis-4/5" ><span class="cursor-pointer"> {{summaryObj?.properties?.createdBy}} </span></div>
          </div>
          <div class="flex mt5" *ngIf="summaryObj?.properties?.modifiedAt">
            <div class="basis-1/5 font-bold">Modified At</div>
            <div class="grow basis-4/5" ><span class="cursor-pointer"> {{datePipe.transform(summaryObj?.properties?.modifiedAt)}} </span></div>
          </div>
          <div class="flex mt5" *ngIf="summaryObj?.properties?.modifiedBy">
            <div class="basis-1/5 font-bold">Modified By</div>
            <div class="grow basis-4/5" ><span class="cursor-pointer"> {{summaryObj?.properties?.modifiedBy}} </span></div>
          </div>
          </div>
      </div>
      <div class="details-flex-card bgcolor2 mr0 ml10">
        <div *ngIf="showGraph">
          <label class="category-dist-label font-bold">Asset Distribution</label>
          <div class="asset-distibution">
            <input type="text" class="basic-input" [value]="assetDistributionDropDown" readonly (click)="assetDistributionDropDownOpen = !assetDistributionDropDownOpen">
            <span class="asset-distibution__icon bgcolor4" (click)="assetDistributionDropDownOpen = !assetDistributionDropDownOpen">
              <i class="fa fa-solid" [ngClass]="assetDistributionDropDownOpen?'fa-angle-up':'fa-angle-down'"></i>
            </span>
            <div class="multi-select-dropdown" [ngClass]="{'open': assetDistributionDropDownOpen}">
              <div  class="asset-distibution__item" *ngFor="let cat of assetCategories.list">
                  <mat-checkbox (change)="changeAssetDistribChart($event, cat)">
                      <label class="ml10">{{cat}}</label>
                  </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <cl-bar-chart *ngIf="showGraph" [chartData]="chartData" [chartXData]="chartXData" [label]="''"></cl-bar-chart>
        <div *ngIf="!loading && !showGraph" class="h-full flex items-center justify-center">No Data Available</div>
        <div *ngIf="loading" class="h-full flex items-center justify-center">Loading Assets..</div>
      </div>
    </div>
  </div>

  <div class="details-header-wrapper mt20">
    <div class="flex items-stretch flex-wrap">
      <div class="details-flex-card bgcolor2 ml0 mr0">
        <div class="flex justify-between" *ngIf="!loading && assetDistArray.length">
          <div class="action-panel-left self-start flex items-center color4">
            <span class="font-bold fs16 mr-2 color35">Assets</span>
            <span (click)="toggleFilterPanel()" class="cursor-pointer flex items-center mr-2">
              <img class="filter-img mr-2 ml-2" src="assets/svgs/filter-options.svg" />Filter
              options</span>
            <form [formGroup]="searchFormGroup" novalidate>
              <cl-list-search [placeholder]="'Search by Name'" (search)="updateFilter($event)"
                [columns] = "['Name']"></cl-list-search>
            </form>
          </div>
          <div class="action-panel-right self-end asset-listing__action-panel-right relative">
            <cl-export-panel class="action-separator" (exportEmitter)="downloadReport($event)"></cl-export-panel>
            <cl-grid-column-toggle [columnList]="rawColumns" (toggleColumns)="gridColumnToggle($event)">
            </cl-grid-column-toggle>
          </div>
        </div>
        <div class="min-h-[150px]" [ngClass]="{'flex items-center justify-center': loading || !assetDistArray.length}">
          <div *ngIf="!loading && assetDistArray.length">
            <div class="flex flex-row h-100 overflow-y-hidden" [style.width.px]="mainWidth">
              <div class="grow-0" *ngIf="showFilterPanel">
                <cl-list-filter class="nav panel scroll-y grow-0" [hideTitle]="true" [showCounts]="true" [title]="''"
                  [fullHeight]="true" (panelsEmitter)="getPanelUpdate($event)" (toggleFilterPanel)="toggleFilterPanel()"
                  heightClass="none" [filters]="filters">
                </cl-list-filter>
              </div>
              <div class="grid-container grow relative">
                <div class="h-100" [style.width.px]="tableWidth">
                  <ngx-datatable class="h300" #table [rows]="assetDist" [columns]="columns" [columnMode]="'force'"
                    [loadingIndicator]="loading" [headerHeight]="headerHeight" [rowHeight]="rowHeight"
                    [scrollbarV]="true" [selected]="selectedAsset" (select)='onAssetSelect($event)'
                    [sortType]="sortType" [selectionType]="selection" [reorderable]="false">
                  </ngx-datatable>
                </div>
                <div class="h-100" *ngIf="dataFilterInProgress">
                  <div class="spin-pos"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading && !assetDist.length" class="h-100 flex items-center justify-center">No Data Available
          </div>
          <div *ngIf="loading" class="h-100 flex items-center justify-center">Loading location info..</div>
        </div>
      </div>
    </div>
    <div class="mt20 rounded bgcolor2">
      <div class="ht1 p10">Location</div>
      <agm-map [latitude]="location?.position?.lat" [longitude]="location?.position?.lon" [streetViewControl]="false"
        [gestureHandling]="mapOptions.gestureHandling" [scrollwheel]="null" [fullscreenControl]="true"
        [fullscreenControlOptions]="mapOptions.positionOfActions" [zoomControl]="true"
        [zoomControlOptions]="mapOptions.positionOfActions" [minZoom]="2" [maxZoom]="20" [zoom]="5"
        [styles]="mapOptions.styles" [backgroundColor]="'none'" [disableDefaultUI]="true" [showDefaultInfoWindow]="true"
        [keyboardShortcuts]="false">
        <agm-marker [latitude]="location?.position?.lat" [longitude]="location?.position?.lon"
          [iconUrl]="'assets/svgs/organization.svg'" [agmFitBounds]="true">
        </agm-marker>
        <agm-marker *ngFor="let area of location?.areas" [latitude]="area?.position?.lat"
          [longitude]="area?.position?.lon" [iconUrl]="'assets/svgs/organization.svg'" [agmFitBounds]="true">
        </agm-marker>
        <agm-marker *ngFor="let coordinate of connectedLocations" [latitude]="coordinate.lat" [longitude]="coordinate.lng"
        [iconUrl]="coordinate.iconUrl" [agmFitBounds]="true">
        <agm-info-window>
            <div class="agm-info-window">
                <p *ngIf="coordinate.date"><strong>Date:</strong> {{coordinate.date | date : "MM/dd/yyyy hh:mm:ss"
                    }}</p>
                <p><strong class="bold">Latitude : </strong> {{coordinate.lat}}</p>
                <p><strong class="bold">Longitude: </strong>{{coordinate.lng}}</p>
            </div>
        </agm-info-window>
    </agm-marker>
      </agm-map>
    </div>
  </div>

  <ng-template #locationAssetTemplate let-row="row" let-value="value" let-i="index">
    <a class="font-12-bold" href="#/assetdetails/{{row.id}}">{{value}}</a>
  </ng-template>
</div>
