import { ElasticSearchQuery } from '@cl/common/models/index';

export enum ASSET_ACTIONS {
  INITIALIZED,
  LOADING,
  LOADED,
  ASSETS_SCROLLED,
  CLEAR_SELECTION,
  INIT_FILTERS,
  UPDATE_COUNTS,
  CLEAR_FILTERS,
  // SEARCH_BY_FILTER,
  FILTERS_UPDATED,
  // SEARCH_BY_TERM,
  TOGGLE_ITEM,
  ASSETS_DELETED,
  ASSET_BOUND,
  ASSET_UNBOUND,
  ASSET_UNBOUND_INPROGRESS,
  SCROLLID_UPDATED,
  PROCESSING
}

export interface AssetState {
  sortField?: any;
  searchTerm: string;
  filterSet: any;
  categories: any[];
  initialFilterSet: InitialFilterSet[];
  // searchList: any[];
  searchFields: ElasticSearchQuery[];
  scrollId: string;
  // totalAssets: number;
  // numAssets: number;
  // allLoaded: boolean;
  activeEntity?: any;
  // Not used yet
  // selectedItems: SelectedFilters;
  // assetList: any[];
  // used for detect the new changes into observer
  currentAction: ASSET_ACTIONS;
  // used for selected action from coc menu
  // currentCocAction?: CustodyActions;
  //   addingAsset: boolean;
  //   editingAsset: boolean;
  //   bindingAsset: boolean;
  //   bulkBind: string;
  //   headerTitle: string;
  //   singleAssetSelected: boolean;
  nodeToEdit: NodeToEdit;
  // selectedSensor: any;
  // loading: boolean;
  //   addCategory: boolean;
  //   editCategory: boolean;
  //   catPanelNum: number;
  //   assetListColumnDefs: string;
  //   assetBootstrapped: boolean;
  //   selectedSensorList: any[];
  // custodyActionInProgress?: CustodyActions;
  // Used for showing ccc panel in asset summary
  // showCocPanel: boolean;
  // dialogPayload?: any;
  // isFilterAction?: boolean;
  // isLoaded?: boolean;

  error?: string


  // TODO: need to handle later
  modificationInProgress: boolean;
  searchFieldFilter: {name: string, value: string}[]
  action: ASSET_ACTIONS;
}

export interface NodeToEdit {
  name: string;
  assetId: string;
  category: string;
  svgName: string;
  uid: string;
  id: string;
}

export interface InitialFilterSet {
  name: string;
  class: string;
  filterType: string;
  fieldName: string;
  order: number;
  enabled: boolean;
  count: number;
  filters: any[];
  selectedFilters: SelectedFilters;
}

export interface FilterSet {
  name: string;
  class: string;
  filterType: string;
  fieldName: string;
  count: number;
  enabled: boolean;
  filters: any[];
  selectedFilters: SelectedFilters;
}

export interface SelectedFilters {}
