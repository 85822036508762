<div class="cl-side-panel bcolor9 border flex flex-col">
    <section class="cl-side-panel-title side-panel-header-bg justify-between items-center">
        <div class="grow flex items-center">
            <img src="assets/svgs/circle-icons-white/entry_exit.svg" />
            <span class="black font-bold">Rule Set Summary</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
    </section>
    <section class="cl-side-panel-content relative">
        <div class="p-4">
            <div class="fs14 color16 mt-1">
                <div class="font-14-bold color1">Rule Set Name</div>
                <div class="font-14-normal">{{rulesetObj?.name}}</div>
            </div>
            <div class="mt-2" *ngIf="rulesetObj?.rulesArr?.length">
                <div class="font-14-bold color1">Rules</div>
                <div class="pl-2" *ngFor="let rule of rulesetObj?.rulesArr">
                    <div class="font-12-normal">{{rule}}</div>
                </div>
            </div>
            <div class="mt-2" *ngFor="let entity of rulesetObj?.contextArr">
                <div class="font-14-bold color1 capitalize">{{entity.name}}</div>
                <div class="pl-2" *ngFor="let item of entity.values">
                    <div class="font-12-normal">{{item.name}}</div>
                </div>
            </div>
        </div>
    </section>
</div>
