import { Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { TaggedAssetCharacteristicValue } from '@cl/@types/characteristic-value.type';
import { AssetService } from '@cl/asset/asset.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MeasureType } from '../../../../@types/measure.type';
import { SensorUtilService } from '../../sensor-util.service';
import { Sensor, SensorTaggedAssetAssignment } from '../../../../@types/sensor.type';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { GatewayService } from '@cl/sensor-fabric/gateway/gateway.service';
import { GatewaySummary } from '@cl/@types/gateway.type';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { UtilsService } from '@cl/common/utils/utils.service';
export interface SensorActivityTab{
  id: number;
  name: string;
  measures: MeasureType [];
}
export interface GatewayFilterItem{
  id: string;
  name: string;
  checked: boolean;
  color: string;
}
@Component({
  selector: 'cl-sensor-activity',
  templateUrl: './sensor-activity.component.html',
  styleUrls: ['./sensor-activity.component.scss']
})
export class SensorActivityComponent implements OnChanges {
  @Input('sensor') sensorObject: Sensor;
  @ViewChild('taggedAssetBoundDate') taggedAssetBoundDateTemplate: TemplateRef<any>;
  @ViewChild('taggedAssetBoundTime') taggedAssetBoundTimeTemplate: TemplateRef<any>;
  @ViewChild('assetName') assetNameTemplate: TemplateRef<any>;
  eventTypes: {name: string, checked: boolean, graphProps: any}[] = [
    { name: 'entry', checked: true, graphProps: { shape: "square", size: 6, fillColor: '#2e9149', strokeColor: '#fff'} },
    { name: 'exit', checked: true, graphProps: {shape: "square", size: 6, fillColor: '#FF0B39', strokeColor: '#fff'} },
    { name: 'proximal', checked: true, graphProps: {shape: "circle", size: 6, fillColor: '', strokeColor: '#fff'} }
  ];
  selectedEventTypes: any[];
  assetsTabName : string = 'Assets';
  rssiCharacteristic : string = 'rxRSSI';
  private originalTabs : SensorActivityTab [] = [
    { id : 1, name: this.assetsTabName, measures : []}
  ];
  tabs: SensorActivityTab[];
  columnDefs: any[];
  activeTabIndex: number = 0;
  activeTab: any;
  isGatewayMenuOpen: boolean = false;
  isEventsMenuOpen: boolean = false;
  currentMeasure: MeasureType;
  selectedDateRange: {startDate: moment.Moment, endDate: moment.Moment};
  ranges: any;
  data: ApexAxisChartSeries;
  receivers: GatewayFilterItem[] = [];
  selectedReceivers: any[];
  rssiEventsData: any[] = [];
  tooltipCustomizer : Function ;
  loading: boolean = true;
  maxDate: moment.Moment;
  preventDates = null;
  constructor(private assetService : AssetService,
    private sensorUtilService: SensorUtilService,
    private gatewayService: GatewayService,
    private formateDatePipe: FormateDatePipe,
    private utilsService : UtilsService
    ) {
    this.tabs = [];
    this.data = null;
    this.ranges = {};
    this.selectedDateRange = { startDate: moment(), endDate: moment()};
  }
  private setRssiToolTip(){
    this.tooltipCustomizer = ({series, seriesIndex, dataPointIndex, w}) =>{
      let tooltipHtml = null;
      if(!_.isEmpty(this.data)){
        let tempData: any = this.data[seriesIndex].data[dataPointIndex];
        tooltipHtml = '<div class="p-2 table">'
        + '<div class="text-center border-b-2 font-bold"">'+ tempData.areaName + ' (' + tempData.receiverName +')' +'</div>'
        + '<div class="table-row-group">'
        + '<div class="table-row"><span>Event :</span><span class="font-bold">'+ tempData.eventType +'</span></div>'
        + '<div class="table-row"><span>Value :</span><span class="font-bold">'+ tempData.value + this.currentMeasure.uom +'</span></div>'
        + '<div class="table-row"><span>Time :</span><span class="font-bold">'+ this.formateDatePipe.transform(tempData.time, 'default') +'</span></div>'
        + '</div>'
        + '</div>';
      }
      return tooltipHtml;
    }
  }
  private getRssiEvents(){
    this.sensorUtilService.getRssiEvents(this.sensorObject.asset.taggedAssetId,
      this.selectedDateRange.startDate.valueOf(),
      this.selectedDateRange.endDate.valueOf(),
      this.eventTypes)
      .then((result: any) => {
        this.rssiEventsData = _.compact(result);
        this.getGatewayNames();
      });
  }
  private getGatewayNames(){
    let data = _.compact(_.uniq(_.map(this.rssiEventsData, 'receiverId')));
    data.forEach((receivedId: string)=>{
      this.receivers.push({id: receivedId, name: '', checked: true, color: ''});
    });
    const promises = [];
    this.receivers.forEach((receiver: any)=>{
      promises.push(this.gatewayService.getGatewayDetails(receiver.id));
    });
    Promise.all(promises)
    .then((result: any) => {
      this.updateGatewayNames(result);
    });
  }
  private updateGatewayNames(result: GatewaySummary[]){
    let colors : string[] = this.utilsService.chartColors();
    this.receivers = this.receivers.map((receiver: any, index: number) => {
      let temp: GatewaySummary = _.find(result, {identifier: receiver.id});
      receiver.name = temp.name;
      receiver.color = colors[index];
      return receiver.name ? receiver : null;
    });
    this.receivers = _.compact(this.receivers);
    this.setRssiGraphData();
  }

  setReceiversNameToRSSiEventsData(){
    this.rssiEventsData.forEach((receiver:any)=>{
      let selectedReceiver = _.find(this.receivers, {id: receiver.receiverId});
      if(!_.isEmpty(selectedReceiver)){
        receiver['receiverName'] = selectedReceiver.name;
        if(receiver.eventType && receiver.eventType === 'proximal' && receiver.receiverId === selectedReceiver.id) {
          receiver.dataPointStyle.fillColor = selectedReceiver.color;
        }
      }
    });
  }
  setRssiGraphData(){
    let selectedEventTypes = _.compact(_.map(_.filter(this.eventTypes, {checked: true}), 'name'));
    let selectedReceivers = _.filter(this.receivers, {checked: true});
    let selectedReceiverIds = _.compact(_.map(selectedReceivers, 'id'));
    this.setReceiversNameToRSSiEventsData();
    let tempData = _.filter(this.rssiEventsData, (item) => {
      if(_.includes(selectedEventTypes, item.eventType) && _.includes(selectedReceiverIds, item.receiverId)){
        return true;
      }else{
        return false;
      }
    });
    this.data = [];
    if(!_.isEmpty(tempData)){
      tempData = _.sortBy(tempData, 'time');
      selectedReceivers.forEach((receiver:GatewayFilterItem)=>{
        let receiverData = _.filter(tempData, {'receiverId': receiver.id})
        this.data.push({
          name: receiver.name,
          data: receiverData,
          color: receiver.color
        });
      });
      this.setRssiToolTip();
    }
    this.loading = false;
  }

  private updateTabs() {
    this.tabs = [];
    let lastTab: SensorActivityTab = this.originalTabs[0];
    if(!_.isEmpty(this.sensorObject?.asset) && !_.isEmpty(this.sensorObject?.asset?.measures)){

      let groups: string[] = [];
      this.sensorObject.asset.measures.forEach((measure: MeasureType)=>{
        if(_.isEmpty(measure.config.groupBy)){
          measure.config.groupBy = 'Other Conditions';
        }
        groups.push(measure.config.groupBy);
      });
      groups = Array.from(new Set(groups));
      groups.forEach((groupName: string, index: number)=>{
        let tab: SensorActivityTab = {id: (index+1), name: groupName, measures : []};
        let tempMeasures: any[] = _.filter(this.sensorObject.asset.measures, (measure : MeasureType)=>{
          return measure.config.groupBy === groupName;
        });
        tab.measures = tempMeasures;
        this.tabs.unshift(tab);
      });
    }
    lastTab.id = this.tabs.length+1;
    this.tabs.push(lastTab);
    let tempIndex = this.tabs.length-1;
    this.navigate(tempIndex, this.tabs[tempIndex]);
  }
  private setColumnsForSensorAssignments(){
    this.columnDefs = [
      { name: 'Date', prop: 'updatedDate', visible: true, width: 100, cellTemplate: this.taggedAssetBoundDateTemplate },
      { name: 'Time', prop: 'updatedDate', visible: true, width: 100, cellTemplate: this.taggedAssetBoundTimeTemplate },
      { name: 'Activity', prop: 'status', visible: true, width: 100 },
      { name: 'Asset Name', prop: 'assetName', visible: true, width: 100, cellTemplate: this.assetNameTemplate },
      { name: 'Asset ID', prop: 'assetExternalId', visible: true, width: 100},
      { name: 'User', prop: 'createdBy', visible: true, width: 100 }
    ];
  }
  private getDataForSensorAssignments(){
    if(this.activeTab.name == this.assetsTabName){
      this.setColumnsForSensorAssignments();
      this.sensorUtilService.getSensorAssignments(this.sensorObject.id)
      .then((result: SensorTaggedAssetAssignment[])=>{
        result = _.orderBy(result, 'updatedDate', 'desc');
        this.sensorObject.assignments = result;
        this.loading = false;
      });
    }
  }
  measureChanged(measure: MeasureType): void{
    this.loading = true;
    this.currentMeasure = measure;
    this.ranges = measure.config.dateRanges;
    this.data = null;
    this.receivers = [];
    this.tooltipCustomizer = null;

    if(this.currentMeasure?.measureCharacteristicId === this.rssiCharacteristic ){
      this.selectedDateRange = { startDate: moment().subtract(5, 'days'), endDate: moment()};
      this.maxDate = moment();
      this.preventDates = moment().subtract(5, 'days');
    }else if(!_.isEmpty(this.currentMeasure?.measureCharacteristicId)){
      this.selectedDateRange = { startDate: moment().subtract(4, 'hours'), endDate: moment()};
      this.maxDate = null;
      this.preventDates = null;
    }
    this.getActiveMeasureData();
  }
  datesUpdated(datesUpdated){
    this.loading = true;
    this.selectedDateRange = datesUpdated;
    this.data = null;
    this.receivers = [];
    this.tooltipCustomizer = null;
    this.getActiveMeasureData();
  }
  private getActiveMeasureData(): void{
    if(this.currentMeasure?.measureCharacteristicId === this.rssiCharacteristic ){
      if(!_.isEmpty(this.sensorObject) && !_.isEmpty(this.sensorObject.asset)){
        this.getRssiEvents();
      }
    }else if(!_.isEmpty(this.currentMeasure?.measureCharacteristicId)){
      this.getMeasureData();
    }
  }
  private getMeasureData(){
    if(!_.isEmpty(this.currentMeasure) && this.currentMeasure.config.deviceMetric){
      if(this.currentMeasure.measureCharacteristicId === this.sensorUtilService.batteryCharacteristicId){
        this.sensorUtilService.getDeviceEventsByRange(this.sensorObject.id,
          this.currentMeasure,
          'tag.batterylevel',
          this.selectedDateRange.startDate.valueOf(),
          this.selectedDateRange.endDate.valueOf())
        .then((result: [[number, number]])=>{
          if(_.isEmpty(result)){
            this.data = [];
          }else{
            this.data = [ { name: this.currentMeasure.measureLabel, data: result } ];
            this.customToolTip();
          }
          this.loading = false;
        }).catch(()=>{
          this.loading = false;
        });
      }
    }else{
      if(this.sensorObject?.asset?.taggedAssetId && !_.isEmpty(this.currentMeasure)){
        this.assetService.getTaggedAssetCharacteristicValuesByRange(this.sensorObject.asset.taggedAssetId,
          this.currentMeasure.measureServiceId,
          this.currentMeasure.measureCharacteristicId,
          this.selectedDateRange.startDate.valueOf(),
          this.selectedDateRange.endDate.valueOf(),
          500)
          .then((response : TaggedAssetCharacteristicValue) => {
            if(response?.data && !_.isEmpty(response.data)){
              this.updateAndSetData(response);
            }
            this.loading = false;
          }).catch(()=>{
            this.loading = false;
          });
      }
    }
  }

  private updateAndSetData(response: TaggedAssetCharacteristicValue) {
    let tempMeasure: MeasureType = _.cloneDeep(this.currentMeasure);
    let temp : [number, number][]= response.data.map((item: any) => {
      return [item.time, this.sensorUtilService.getValue(item.value, tempMeasure)];
    });
    if(_.isEmpty(temp)){
      this.data = [];
    }else{
      this.data = [ { name: this.currentMeasure.measureLabel, data: temp } ];
      // this.customToolTip();
    }
  }

  private customToolTip(){
    this.tooltipCustomizer = ({series, seriesIndex, dataPointIndex, w}) =>{
      let tooltipHtml = null;
       if(!_.isEmpty(this.data)){
        let tempData: any = this.data[seriesIndex].data[dataPointIndex];
        tooltipHtml = '<div class=" ">'
        + '<div class="font-bold bgcolor38 p-2 text-center">'+ this.sensorObject.id +'</div>'
        + '<div class="p-2 flex items-center"><span class="inline-block w-3 h-3  rounded-full bgcolor4"></span> <span class="pl-2">'+ this.data[0].name +' :</span><span class="font-bold pl-2">'+ tempData[1] + this.currentMeasure.uom +'</span></div>'
        + '<div class="p-2 flex items-center"><span class="pl-5">Time :</span><span class="font-bold pl-2">'+ this.formateDatePipe.transform(tempData[0], 'default') +'</span></div>'
        + '</div>';
      }
      return tooltipHtml;
    }
  }

  navigate(index, tab){
    this.activeTabIndex = index;
    this.activeTab = tab;
    this.getDataForSensorAssignments();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasOwnProperty('sensorObject')){
      this.updateTabs();
    }
  }
}

