import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { ToastService } from '@cl/common/services/toast.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { LocationsService } from '@cl/locations/locations.service';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { LocationUtilsService } from '@cl/locations/location-utils.service';
import { CdmField } from '@cl/@types/extended-attributes.types';
import { MatSelectChange } from '@angular/material/select';
import { ContentDialogComponent } from '@cl/common/components/content-dialog/content-dialog.component';
//import { Maps, ShipmentService } from '@cl/shipments/shipment.service';

@Component({
  selector: 'cl-location-area',
  templateUrl: './location-area.component.html',
  styleUrls: ['./location-area.component.scss']
})
export class LocationAreaComponent implements OnInit, OnChanges {
  @Output() closePanel = new EventEmitter();
  @Output() selectedPosition = new EventEmitter();
  @Output() markerChanged = new EventEmitter();
  @Output() refreshPolygons = new EventEmitter();
  @Output() emitSelectedRadius = new EventEmitter();
  @Output() emitSelectedPolygonPoints = new EventEmitter();
  @Output() emitTabIndex = new EventEmitter();
 
  @Input('changedRadius') changedRadius:number;
  @Input('mode') mode:string;
  @Input('zoom') zoom:number;
  @Input('polygonPts') polygonPts = [];
  @Input('editArea') editArea:any;
  @Input('mapClickedPosition') mapClickedPosition: any;
  @Input() orgList:any;
  @Input() isOrgRestrictedUser: boolean;
  @Input() objectType:any;
  // for bulk upload
  @Input() bulkObj:any;
  @Input() showResponse: boolean;
  @Input() bulkResponseArr: any[];
  @Output() emitFormData = new EventEmitter();address
  @ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;
  @ViewChild('selectShapeTool') selectShapeTool:  TemplateRef<any>;
  @ViewChild('addressTemp') addressTemp:  TemplateRef<any>;
  @ViewChild('markertypeTemp') markertypeTemp:  TemplateRef<any>;
  @ViewChild('areaPlacement') areaPlacement:  TemplateRef<any>;
  @ViewChild('position') position:  TemplateRef<any>;
  @ViewChild('areatypeTemp') areatypeTemp:  TemplateRef<any>
  @ViewChild('orgTemp') orgTemp:  TemplateRef<any>;


  fileFormData:any;
  initializedData: any = {};

  areaForm: FormGroup;
  username = '';
  markers: string[] = ['circle', 'polygon','point'];
  selectedMarker:any;
  regex = "^[A-Za-z0-9 @ & ( ) _  : ' , . ; + \" / -]+$";
  areaTypes: string[] = ['WorkArea', 'Warehouse', 'Airport', 'Seaport', 'Railway Station'];
  fieldTypes: string[] =[];
  staticFields: string[] = [
    'areaFenceType',
    'parentLocation',
    'position',
    'size',
    'address',
    'organization',
    'type'
  ];
  sites = [];
  tabSelected = 0;
  currentIndex = 0;
  previousIndex = 0;

  isCarrierHubCodeHidden = true;
  isLatLong =false;

  dynamicInputFields:InputField[] = [];
  downloadedCSVFields: string[] = [];
areaname: any;

  constructor(private locationService: LocationsService, public dialog: MatDialog, private _locationUtils: LocationUtilsService,
    private _dynamicFormService: DynamicFormService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
      if(this.mode == 'add'){
        this.buildEmptyForm();
      } else if(this.mode == 'edit' && this.editArea){
        let position = this.editArea.position;
        if(!position) {
          if(this.editArea.coordinates && JSON.parse(this.editArea.coordinates).geometry?.coordinates) {
            position = JSON.parse(this.editArea.coordinates).geometry.coordinates[1] + ',' + JSON.parse(this.editArea.coordinates).geometry.coordinates[1];
          }
        }
        let markerType = this.editArea.areaFenceType;
        let radius = '';
        if(markerType) {
          if(this.editArea.coordinates && JSON.parse(this.editArea.coordinates).properties?.type) {
            markerType = JSON.parse(this.editArea.coordinates).properties?.type;
            if(markerType.toLowerCase() == 'circle') {
              radius = Number(JSON.parse(this.editArea.coordinates).properties?.radius?JSON.parse(this.editArea.coordinates).properties?.radius:Math.round(Number(this.editArea.size))).toFixed(2);
            }
          }
        }
        let address = this.editArea.fullAddress;
        if(!address) {
          if(this.editArea.coordinates && JSON.parse(this.editArea.coordinates).address) {
            address = JSON.parse(this.editArea.coordinates).address;
          }
        }
        
        this.areaForm = new  FormGroup({
          site : new FormControl(this.editArea.parentLocation),
          organization:new FormControl(this.editArea.organization),
          address : new FormControl(address,[Validators.required]),
          markertype : new FormControl(markerType.toLowerCase(),[Validators.required]),
          position : new FormControl(position,[Validators.required, Validators.pattern(this.regex)]),
          radius : new FormControl(radius),
          areatype : new FormControl(this.editArea.areaType,[Validators.required, Validators.pattern(this.regex)]),
          carrierHubCode: new FormControl(this.editArea?.carrierHubCode)
        });
        this.areaForm.value.position?this.selectedPosition.emit({lat:Number(this.areaForm.value.position.split(',')[0]), lng:Number(this.areaForm.value.position.split(',')[1])}):'';
        this.areaForm.value.markertype?this.markerChanged.emit(this.areaForm.value.markertype):'';
        if(this.areaForm.value.markertype.toLowerCase() == 'circle')
        this.emitSelectedRadius.emit(JSON.parse(this.editArea.coordinates).properties?.radius?Math.round(JSON.parse(this.editArea.coordinates).properties?.radius):this.editArea.size?Math.round(Number(this.editArea.size?.split(' ')[0])):500);
        if(this.areaForm.value.markertype.toLowerCase() == 'polygon')
        this.emitSelectedPolygonPoints.emit(JSON.parse(this.editArea.coordinates).geometry?.coordinates?JSON.parse(this.editArea.coordinates).geometry?.coordinates:this.editArea.areaLocation.coordinates[0]);
        this.getExtendedAttributes();
        this.onAreaTypeChanged()
      } 

      this.locationService.getSites().subscribe(sites=> {
        this.sites = sites['hits'].filter(e=>e.name);
        this.sites.unshift({});
        if(this.mode == 'edit' && this.editArea){
          this.areaForm.get('site').patchValue(this.sites.find(site => site.id == this.editArea.parentLocation))
        }
      })

      this.areaForm.get('radius').disable();
      this.areaForm.get('position').disable();
      this.initializedData = { ...this.areaForm.value };
      
  }

  buildEmptyForm() {
    this.areaForm = new  FormGroup({
      site : new FormControl(null),
      organization:new FormControl(this.orgList[0]?.id),
      address : new FormControl(null,[Validators.required]),
      markertype : new FormControl(null,[Validators.required]),
      position : new FormControl(null,[Validators.required, Validators.pattern(this.regex)]),
      radius : new FormControl(this.changedRadius),
      areatype : new FormControl(null,[Validators.required, Validators.pattern(this.regex)]),
      carrierHubCode: new FormControl(null)
    });
    // this.getSelectedMarker();
    this.emitTabIndex.emit(this.tabSelected);
    this.getExtendedAttributes();
    this.onAreaTypeChanged()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['changedRadius'] && this.changedRadius){
      setTimeout(() => {
        this.areaForm.get('radius').patchValue(Number(this.changedRadius).toFixed(2));
      }, 100);
    }
    if(changes['mapClickedPosition']) {
      var self = this;
      this.areaForm?.patchValue({position: this.mapClickedPosition.lat + ',' + this.mapClickedPosition.lng});
      if(this.mapClickedPosition) {
        new google.maps.Geocoder().geocode({ 'location': new google.maps.LatLng(self.mapClickedPosition.lat, self.mapClickedPosition.lng) }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                self.areaForm?.patchValue({address:results[0].formatted_address});
              }
            }
        });
        this.areaForm.get('site').patchValue({});
        this.areaForm.get('address').enable();
      }
    }
  }
  submitted: boolean = false;

  submitForm(): any {
    this.submitted = true;
    this.checkLatLong()
    if (this.areaForm.valid && this.checkIspolygonAdded() ) {      
      if(this.dynamicForm){
        if(this.dynamicForm?.onSubmit().valid){
          return this.preparePayload();
        }
      } else {
        return this.preparePayload();
      }
      
    } else {
       this.validateAllFormFields(this.areaForm); 
       this.dynamicForm.onSubmit()
    }
   
  }
  checkIspolygonAdded(){
    if(this.areaForm.value.markertype=="polygon"){
      return this.polygonPts.length > 0 
    }
    return true
   }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }
  handleAddressChange(address: any) {
    this.areaForm.patchValue({address: address.formatted_address});
    this.areaForm.patchValue({position: address.geometry.location.lat() + ',' + address.geometry.location.lng()});
    this.selectedPosition.emit({lat:address.geometry.location.lat(), lng:address.geometry.location.lng()})
    this.checkLatLong()
  }

  getSelectedMarker() {
    this.markerChanged.emit(this.areaForm.value.markertype.toLowerCase());
  }
  getSelectedSite() {
    if(this.areaForm.value.site.name) {
      this.areaForm.get('position').enable();
      this.areaForm.get('address').enable();
      this.areaForm.get('address').patchValue(this.areaForm.value.site.fullAddress);
      if(this.areaForm.value.site.position)
        this.areaForm.get('position').patchValue(String(this.areaForm.value.site.position));      
      else if(JSON.parse(this.areaForm.value.site.coordinates).geometry.coordinates)
        this.areaForm.get('position').patchValue(JSON.parse(this.areaForm.value.site.coordinates).geometry.coordinates[1]+','+JSON.parse(this.areaForm.value.site.coordinates).geometry.coordinates[0]);
      else if(this.areaForm.value.site.siteLocation)
        this.areaForm.get('position').patchValue(this.areaForm.value.site.siteLocation.coordinates[1]+','+this.areaForm.value.site.siteLocation.coordinates[0]);
     
      if(this.areaForm.get('address').value){
        this.areaForm.get('address').disable();
      }
      this.selectedPosition.emit({lat:Number(this.areaForm.value.position?.split(',')[0]), lng:Number(this.areaForm.value.position?.split(',')[1])})
      this.areaForm.get('position').disable();
      this.checkLatLong();
    } else {
      this.areaForm.get('address').enable();
    }
    this.checkLatLong()
  }

  onSelectedTabChange(event) {
      this.tabSelected = event.index;
      this.previousIndex = event.index;
      this.emitTabIndex.emit(event.index);
      if(this.tabSelected == 0){
        } else {
        this.fileFormData = null;
      }
  }

  onAreaTypeChanged(){
    const areaType = this.areaForm.get('areatype').value;
    const carrierHubCode = this.areaForm.get('carrierHubCode');
    if(areaType?.toLowerCase() == 'airport'){
      carrierHubCode?.setValidators([Validators.required]);
      this.isCarrierHubCodeHidden = false;
    }else{
      carrierHubCode?.clearValidators();
      carrierHubCode?.patchValue(null)
      this.isCarrierHubCodeHidden = true;
    }
  }
  convertTitleCase(str){
   return str.split(' ')
   .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
   .join(' ');
  }

  convertPolygonJsonToArray() {
    if (this.polygonPts.length == 0) return [];
    const points = this.polygonPts.map(point => [point.lng,point.lat]);
    return [[...points,points[0]]]
  }

  preparePayload(): any {
    this.areaForm.get('position').enable();
    this.areaForm.get('address').enable();
    const polyPoints = this.convertPolygonJsonToArray();
    let coordinates= {
      "type": "Feature",
      "geometry": {
          "type": (this.areaForm.value.markertype.toLowerCase() == 'polygon')? this.convertTitleCase(this.areaForm.value.markertype) :'Point',
          "coordinates": (this.areaForm.value.markertype.toLowerCase() == 'circle' || this.areaForm.value.markertype.toLowerCase() == 'point')?[
            this.areaForm.value.position?this.areaForm.value.position.split(',')[1]:'',
            this.areaForm.value.position?this.areaForm.value.position.split(',')[0]:''
          ]:polyPoints
      },
      "properties": {
          "type": this.convertTitleCase(this.areaForm.value.markertype),
          "radius": this.areaForm.value.markertype.toLowerCase() == 'circle'?Math.round(this.changedRadius):undefined
      },
      "address": this.areaForm.value.address,
      "zoomLevel": this.zoom
  }
    let payload = {
      "parentLocation": this.areaForm.value.site?this.areaForm.value.site.id:'',
      "organization": this.areaForm.value.organization,
      "coordinates": JSON.stringify(coordinates)  ,
      "address": this.areaForm.value.address,
      "areaFenceType": this.convertTitleCase(this.areaForm.value.markertype),
      "areaType": this.areaForm.value.areatype,
      "position": this.areaForm.value.position,
      "size": this.areaForm.value.radius,
      "carrierHubCode": this.areaForm.value.carrierHubCode,
      "catalogType":'Area',
      "objectType": this.objectType,
      "type":this.areaForm.value.areatype
  }
  this.areaForm.get('position').disable();
  this.areaForm.get('address').disable();
  const dynamicFormGroup = this.dynamicForm?.onSubmit();
  if(dynamicFormGroup){
    payload = { ...dynamicFormGroup.getRawValue() ,...payload}
  }
  return payload;
  }

  getUploadFormData(event) {
    this.fileFormData = event;
    this.emitFormData.emit(event);
  }
  checkFormDataAvaliable(): boolean {
    const form = this.dynamicForm?.form.getRawValue();
    let hasValues = false;
    if (this.previousIndex == 0) {
      let currentData = { ...form, ...this.areaForm.value };
      let isChange = false;
      for (let key in this.initializedData) { 
        if (currentData[key] !== this.initializedData[key]) {
          isChange = true;
        }
      }
      hasValues = isChange;
    } else {
      hasValues = this.fileFormData ? true : false;
    }
    return hasValues;
  }
  onTabClick(tab: any) {
    this.currentIndex = tab.selectedIndex;
    if (this.checkFormDataAvaliable()) {
      tab.selectedIndex = this.previousIndex;
      let discardData = this.fileFormData?'File':'Area';
      this.dialog
        .open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Discard',
            message: 'Are you sure want discard '+discardData+'?',
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            tab.selectedIndex = this.currentIndex;
            this.previousIndex = this.currentIndex;
            this.tabSelected = this.currentIndex;
            this.emitTabIndex.emit(this.tabSelected)
            this.areaForm.reset();
            this.dynamicForm?.form?.reset();
            this.areaForm
              ?.get('radius')
              .patchValue(this.changedRadius.toFixed(2));
            this.areaForm?.get('organization').patchValue(this.orgList[0]?.id);
            return;
          }
          tab.selectedIndex = this.previousIndex;
        });
      return;
    }
    tab.selectedIndex = this.currentIndex;
    this.tabSelected = this.currentIndex;
    this.emitTabIndex.emit(this.tabSelected)
  }

  async getExtendedAttributes(){
    const areaCatalog = await this._locationUtils.getLocationExtendedAttributes(this.objectType);

    const fields = this._dynamicFormService.getRenderableFields(
      areaCatalog.cdmFields
    );

    const orderedFields:CdmField[] = this._dynamicFormService.orderFields([...fields],'order');

    this.downloadedCSVFields = orderedFields.map(field => {
      let label = field.displayLabel;
      if(field.required){
        label += '*'
      }else if(field.displayLabel.endsWith('*')){
        label += '*'
      }
      return `"${label}"`
    })
    this.dynamicInputFields = await this._dynamicFormService.generateInputFields(orderedFields,this.mode == 'edit',this.editArea)
    this.dynamicInputFields=  this.dynamicInputFields.filter(inp=>inp.id != "coordinates")
    this.dynamicInputFields.forEach(inp=>{
      if(inp.id == "areaFenceType"){
        this.fieldTypes.push(inp.id)
          inp.isDynamicField =false;
          inp.required =false
          inp.template = this.selectShapeTool;
      }
      if(inp.id == "organization"){
        inp.isDynamicField =false;
        inp.required =false
        inp.template = this.orgTemp;
    }
      if(inp.id == "address"){
        this.fieldTypes.push(inp.id)
        inp.isDynamicField =false;
        inp.template = this.addressTemp;
        inp.required =false
      }
      if(inp.id == "size"){
        this.fieldTypes.push(inp.id)
        inp.isDynamicField =false;
        inp.template = this.markertypeTemp;
        inp.required =false
      }
      if(inp.id == "position"){
        this.fieldTypes.push(inp.id)
        inp.isDynamicField =false;
        inp.template = this.position;
        inp.required =false
      }
      if(inp.id == "parentLocation"){
        this.fieldTypes.push(inp.id)
        inp.isDynamicField =false;
        inp.template = this.areaPlacement;
        inp.required =false
      }
      if(inp.id == "type"){
        this.fieldTypes.push(inp.id)
        inp.isDynamicField =false;
        inp.template = this.areatypeTemp;
        inp.required =false
      }
      if (!this.staticFields.includes(inp.id)) {
        this.initializedData[inp.id] = inp.value;
      }
    })
    this.cd.detectChanges();
  }
  onOrgclose(){
    this.areaForm.get('organization').setValue('');
  }
  checkLatLong(){
    if(!this.areaForm.get('position').value){
     this.isLatLong =true
    }else{
      this.isLatLong =false
    }
  }
}
