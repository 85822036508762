import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { AssetStatus, CL_INPUT_DEBOUNCE_TIME_HIGH } from "@cl/constants";
import { AssetSearchHit, ESSearchResults, Sensor } from "@cl/models";
import { HidePanel, SfBindSensor, SfCancelBind, SfSelectAsset, SfSensorBound } from "@cl/ngxs/actions";
import { UtilsService } from "@cl/common/utils/utils.service";
import { SearchApiService } from "@cl/common/services/search-api.service";
import { GraphAPIService } from "@cl/common/services/graph-api.service"
import { Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, takeUntil, tap } from "rxjs/operators";
import { ToastService } from "@cl/common/services/toast.service";
import { AssetListService } from '../../../../asset/asset-list/asset-list.service';
import _ from "lodash";

@Component({
  selector: 'cl-sensor-binder',
  templateUrl: './sensor-binder.component.html',
  styleUrls: ['./sensor-binder.component.scss']
})
export class SensorBinderComponent implements OnInit, OnDestroy {
  @Input() sensorObject: any;
  @Output() closeSidePanel = new EventEmitter();
  bindClicked: boolean = false;
  selectedAsset: AssetSearchHit;

  bindForm: FormGroup;
  assetList: AssetSearchHit[] = [];
  loading = false;

  unbindInProgress = false;

  private scrollId = "";
  private total = 0;
  private subscribedToInputChanges = false;
  private readonly destroyed$ = new Subject<boolean>();

  private get fcSearchTerm(): AbstractControl {
    return this.bindForm?.get("searchTerm");
  }
  assetComponent: any;
  assetComponentList: any;
  showComponent: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _searchApiService: SearchApiService,
    private _graphApi: GraphAPIService,
    private _utilService: UtilsService,
    private _toastService: ToastService,
    private _assetListService: AssetListService
  ) {
    this.bindForm = this.fb.group({
      searchTerm: [""],
    });
  }

  ngOnInit(): void {
    this.subscribeToSearchTermChanges();
  }

  closePanel(statusAction?: string) {
    this.closeSidePanel.emit(statusAction);
  }


  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  selectListItem(asset: AssetSearchHit) {
    this.selectedAsset = asset;
    this.getComponentList(this.selectedAsset.entityTypeKey);
  }

  bindAsset() {
    const assetId = this.selectedAsset.id;
    const sensorId = this.sensorObject.id;
    const componentId = this.assetComponent;
    this.bindClicked = true;
    this.unbindInProgress = true;
    if(!_.isEmpty(this.assetComponentList) && this.assetComponent === undefined){
      this._toastService.error('Please select component', 'Error', 2000);
      this.bindClicked = false;
      this.unbindInProgress = false;
    }else{
      this._graphApi
      .bindAsset(assetId, sensorId, componentId)
      .pipe(finalize(() => (this.unbindInProgress = false, this.bindClicked = false)))
      .subscribe(
        (res) => {
          if(this.sensorObject?.isTracker) {
            this._utilService.showMessage("Tracker bound", sensorId);
          } else {
            this._utilService.showMessage("Sensor bound", sensorId);
          }
          this.closePanel('bindSuccess');
        },
        (err) => {
          if(err.error['error-message']){
            this._toastService.error(err.error['error-message']);
            return;
          }
          if(this.sensorObject?.isTracker) {
            this._utilService.showMessage("Unable to bind tracker", sensorId);
          } else {
            this._utilService.showMessage("Unable to bind sensor", sensorId);
          }
        }
      );
    }
    
    

    // this.store.dispatch(new SfBindSensor()).subscribe(
    //   (_) => {
    //     // const assetName = this.sf?.selectedAsset?.properties?.name;
    //     // const sensorName = this.sf?.nodeToEdit?.properties?.name;
    //     // const msg = `${sensorName} bound to ${assetName}`;
    //     // this._utilService.showMessage(msg);
    //   },
    //   (err) => {
    //     this._utilService.showMessage("Unable to bind sensor");
    //   }
    // );
  }


  private subscribeToSearchTermChanges() {
    if (this.subscribedToInputChanges) {
      return;
    }

    this.fcSearchTerm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        startWith(""),
        debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH),
        distinctUntilChanged(),
        tap((_) => (this.scrollId = "")),
        switchMap((searchTerm) => this.getUnmonitoredAssets(searchTerm))
      )
      .subscribe((assets) => {
        this.assetList = assets;
      });

    this.subscribedToInputChanges = true;
  }

  private getUnmonitoredAssets(searchTerm: string, newSearch: boolean = true): Observable<AssetSearchHit[]> {
    this.loading = true;

    const params = {
      scrollId: this.scrollId,
      scrollSize: 50,
      globalQueryText: searchTerm,
      searchQueries: [
        {
          fieldName: "baseClass",
          queryText: "Asset",
          queryOperator: "should",
          queryType: "match",
          childFilters: [],
        },
      ],
    };

    return this._searchApiService.globalSearch<ESSearchResults<AssetSearchHit>>(params, false).pipe(
      finalize(() => (this.loading = false)),
      catchError((err) => of({ total: 0, _scroll_id: "", hits: [] })),
      tap((res) => {
        this.total += res.hits.length;
        this.scrollId = res._scroll_id;
      }),
      map((res) => res.hits)
    );
  }

  getComponentList(assetType){
    this._assetListService.getAssetComponents(assetType).then((res) => {
      if(!_.isEmpty(res.components)){
        this.showComponent = true;
        this.assetComponentList = res.components;
      }else{
        this.showComponent = false;
        this.assetComponent = '';
      }
    })
  }
}
