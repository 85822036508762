import { CustodyActions } from '@cl/common/models/assets';

export enum ASSET_COC_ACTIONS {
  INITIALIZED,
  TOGGLE_COC_PANEL,
  CUSTODY_ACTION_EMIT,
  COC_STARTED,
  COC_COMPLETED,
}

export interface AssetCOCState {
  currentAction: ASSET_COC_ACTIONS;
  currentCocAction?: CustodyActions;
  custodyActionInProgress?: CustodyActions;
  showCocPanel: boolean;
  dialogPayload?: any;
}
