import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ShipmentService } from '@cl/shipments/shipment.service';

type ActivePane = 'summary' | 'content' | 'tracking';
type ViewMode = 'condensed' | 'enlarged';
@Component({
  selector: 'cl-shipment-summary',
  templateUrl: './shipment-summary.component.html',
  styleUrls: ['./shipment-summary.component.scss'],
})
export class ShipmentSummaryComponent implements OnInit, OnChanges {
  @Input() viewMode: ViewMode = 'enlarged';
  @Input() shipmentID: any;
  @Input() shipmentType: any;
  @Output() closeSidePanel = new EventEmitter();
  shipmentData: any;
  isLoading:boolean =false;
  parcelIncludedAssets: {
    assets: { assetId: string; assetName: string }[];
    trackingID: string;
    isOpen: boolean;
  }[] = [];
  activePane: ActivePane = 'summary';
  openToggler = true;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _shipmentService: ShipmentService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['shipmentID']) return;
    this.isLoading =true
    this.shipmentData =undefined;
    this._shipmentService.getShipmentData(this.shipmentID,this.shipmentType).subscribe(
      (data) => {
        this.isLoading =false;
        this.shipmentData = data;
        this.parcelIncludedAssets = [];
        if (['parcelshipment', 'dtpshipment'].includes(this.shipmentData?.shipmentNode?.properties?.parentEntityTypeKey)) {
          const assets = this.shipmentData.shipmentNode.properties.assets;

          assets.forEach((asset) => {
            const assetIndex = this.parcelIncludedAssets.findIndex(
              (incAsset) => asset.trackingNumber === incAsset.trackingID
            );

            if (assetIndex > -1) {
              this.parcelIncludedAssets[assetIndex].assets.push({
                assetId: asset?.assetId,
                assetName: asset?.assetName,
              });
            } else {
              this.parcelIncludedAssets.push({
                assets: [
                  {
                    assetId: asset?.assetId,
                    assetName: asset?.assetName,
                  },
                ],
                trackingID: asset.trackingNumber,
                isOpen: true,
              });
            }
          });
        }
      },
      (err) => {
        this.closePanel();
      }
    );
  }

  closePanel() {
    this.closeSidePanel.emit();
  }
  toggler() {
    this.openToggler = !this.openToggler;
  }
  toggleActivePane(pane: ActivePane) {
    this.activePane = pane;
    this._changeDetectorRef.detectChanges();
  }
}
