import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Asset } from '@cl/@types/asset.type';
import _ from 'lodash';

@Component({
  selector: 'cl-asset-history',
  templateUrl: './asset-history.component.html',
  styleUrls: ['./asset-history.component.scss']
})
export class AssetHistoryComponent implements OnInit, OnChanges {
  @Input('asset') assetObject: Asset;
  @Input('dateRange') dateRange: any;
  @Output () hideDatePicker = new EventEmitter();
  historyTabs = [
    'Location',
    'Custody'
  ];
  activeTab = 'Location';
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (!_.isEmpty(this.assetObject)) {
      if (this.assetObject?.monitoredStatus && this.assetObject?.monitoredStatus !== 'MONITORED') {
        this.historyTabs = ['Custody'];
        this.changeActiveTab('Custody');
      } else {
        this.historyTabs = [
          'Location',
          'Custody'
        ];
        this.changeActiveTab(this.activeTab);
      }
    }
  }


  changeActiveTab(activeTab: string): void {
    this.activeTab = activeTab;
    if (activeTab === 'Custody') {
      this.hideDatePicker.emit(true);
    } else {
      this.hideDatePicker.emit(false);
    }
  }

}
