import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShipmentListActionsComponent } from './shipment-list-actions/shipment-list-actions.component';
import {
  LIST_ACTION,
  ShipmentListStateService,
} from './shipment-list-state.service';
import { ShipmentListTableComponent } from './shipment-list-table/shipment-list-table.component';
import { Subscription } from 'rxjs';
import { ShipmentService } from '../shipment.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
import { ToastService } from '@cl/common/services/toast.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@cl/user/user.service';
import { AsyncDownloadCsvService } from '@cl/common/services/async-download-csv.service';
import * as _ from 'lodash'
type mode = 'add' | 'edit' | 'view' | '';
@Component({
  selector: 'cl-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss'],
})
export class ShipmentListComponent implements OnInit, OnDestroy {
  public isLoading: boolean = false;
  public allLoaded: boolean = false;

  @ViewChild(ShipmentListTableComponent)
  shipmentTable: ShipmentListTableComponent;
  @ViewChild('shipmentActions') shipmentActions: ShipmentListActionsComponent;

  shipmentList = [];
  showSidePanel = false;

  totalRecords: number;
  loadedRecords: number;
  isAllLoaded = false;
  delayedRefreshStart = false;
  refreshTimer = 5;

  isFiltersOpen: boolean;
  tableWidth: number;
  mainWidth: number;

  mode: mode = '';

  editedShipmentType: any;
  editedShipmentID: any;

  listSubscription: Subscription;
  summaryShipmentID: string;
  summaryShipmentType: string;

  terminateShipmentIds: {id: string, entityType: string}[];
  completeShipmentIds: {id: string, entityType: string}[];
  deleteShipmentIds: {id: string, entityType: string}[];

  editedShipmentCatagory: 'basic' | 'composite' = 'basic'; 
  actionBtnPolicies = {
    create: ['SHIPMENT_CREATE'],
  };

  isDTPUser = this._userService.isDTPUser();

  constructor(
    private _shipmentListState: ShipmentListStateService,
    private _shipmentService: ShipmentService,
    private _dialog: MatDialog,
    private exportCSV: CsvExportsService,
    private _toastService: ToastService,
    private formateDatePipe: FormateDatePipe,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private asyncDownloadCSV: AsyncDownloadCsvService
  ) {}

  ngOnInit(): void {
    this.isFiltersOpen = this._shipmentListState.settings.filters.isOpen;

    this._route.queryParams.subscribe((params) => {
      const { statusCode, excursions, direction } = params;

      const childFilters = [];

      if (statusCode) {
        childFilters.push({
          id: 'statusCode',
          values: statusCode.split(','),
        });
      }

      if (excursions) {
        childFilters.push({
          id: 'excursions',
          values: excursions.split(','),
        });
      }

      // TODO: direction, destination and origin filters should use later while navigating from location page to shipment listing
      if(direction){
        childFilters.push({
          id:'direction',
          values: direction.split(',')
        })
      }

      if(params['originAreaId.keyword']){
        childFilters.push({
          id: 'originAreaId.keyword',
          values: params['originAreaId.keyword'].split(',')
        })
      }
      
      if(params['destinationAreaId.keyword']){
        childFilters.push({
          id: 'destinationAreaId.keyword',
          values: params['destinationAreaId.keyword'].split(',')
        })
      }

      if (childFilters.length > 0) {
        this._shipmentListState.updateFilters(childFilters);
      } else {
        const currentFilters =
          this._shipmentListState.settings.filters.childFiltersQuerys;
        if (currentFilters.length > 0) {
          this.getShipmentsList();
        } else {
          const defaultStatusFilters = [
            'At Destination',
            'Drafts',
            'In-Transit',
            'Planned',
            'Ready to Ship',
            'Exception',
            'Incomplete',
            'Pending',
          ];

          const queryFilers = [
            {
              id: 'statusCode',
              values: defaultStatusFilters,
            },
          ];
          this._shipmentListState.updateFilters(queryFilers);
        }
      }
    });

    this.listSubscription =
      this._shipmentListState.listSettingsObservable.subscribe((settings) => {
        if (settings.action === LIST_ACTION.FILTERS_TOGGLED) {
          this.isFiltersOpen = settings.filters.isOpen;
          this.setContainerWidth();
        }
        if (
          settings.action === LIST_ACTION.FILTERS_UPDATED ||
          settings.action === LIST_ACTION.LOAD_MORE
        ) {
          this.getShipmentsList(settings.action === LIST_ACTION.LOAD_MORE);
        }
        if (settings.action === LIST_ACTION.EXPORT_CSV) {
          const payload = this._shipmentService.getShipmentsPayload(false);
          payload.scrollSize = 10_000;
          this.asyncDownloadCSV.initializeDownloading('shipment', _.cloneDeep(payload), [...settings.list.selectedColumns], this.totalRecords, true)
        }
      });
  }


  async getShipmentsList(isScrollLoad: boolean = false) {
    if (isScrollLoad && this.isAllLoaded) return;

    if (this.isLoading) {
      setTimeout(() => {
        this.getShipmentsList(isScrollLoad);
      }, 100);
      return;
    }

    this.isLoading = true;
    const [res, error] = await this._shipmentService.getShipments(isScrollLoad);
    this.shipmentTable?.deselectRows();
    this.isLoading = false;
    if (error) return;

    this._shipmentListState.updateScrollId(res._scroll_id);

    if (isScrollLoad) this.shipmentList.push(...res.hits);
    else this.shipmentList = res.hits;

    this.renderModifiedData();

    this.shipmentList = [...this.shipmentList];
    this.totalRecords = res.totalHits;
    this.loadedRecords = this.shipmentList.length;
    this.isAllLoaded = this.loadedRecords === this.totalRecords;
  }

  // This method is used for modifing the tags, direct to residense and tracking numbers for listing table
  renderModifiedData() {
    this.shipmentList.forEach((shipment) => {
      // We can change direct to residense 'true' to Yes 'false' to No
      if (shipment.directToResidence) {
        shipment.directToResidence =
          shipment.directToResidence === 'true'
            ? 'Yes'
            : shipment.directToResidence === 'false'
            ? 'No'
            : shipment.directToResidence;
      }

      // For Tracking number
      if (shipment.trackingNumbers) {
        try {
          shipment.trackingNumbers = JSON.parse(shipment.trackingNumbers).join(
            ','
          );
        } catch (error) {}
      }

      // For tags
      let tags = '';

      if (shipment.tags && shipment.tags.length > 0) {
        let tmpTags = [...shipment.tags];
        tags = tmpTags.map((tag) => tag.name).join(', ');
        if (tmpTags.length > 2) {
          const preTags = tmpTags
            .splice(0, 2)
            .map((tag) => tag.name)
            .join(', ');
          tags =
            preTags +
            ' & <span class="" title="' +
            tags +
            '">' +
            tmpTags.length +
            ' others</span>';
        }
      }
        
      shipment.tagsHtml = tags;
    });
  }

  addShipment() {
    this.mode = 'add';
    this.showSidePanel = true;
  }

  closeSidePanel(cbAction?) {
    this.showSidePanel = false;
    if (cbAction) {
      this.reloadShipmentsWithTimer()
    }
  }

  ngOnDestroy(): void {
    this.listSubscription.unsubscribe();
  }

  setContainerWidth() {
    this.mainWidth = window.innerWidth - 20;
    if (this.isFiltersOpen) {
      this.tableWidth = this.mainWidth - 260;
    } else {
      this.tableWidth = this.mainWidth - 10;
    }
  }
  showSummary(event) {
    this.summaryShipmentID = event.value.id;
    this.summaryShipmentType = event.value.entityTypeKey;
    this.mode = 'view';
    this.showSidePanel = true;
  }

  onEditShipment(evt: { isDisabled: boolean; selectedRow: any }) {
    this.shipmentActions.isEditBtnDisabled = evt.isDisabled;
    this.shipmentActions.shipmentData = evt.selectedRow;
    this.summaryShipmentType = evt.selectedRow?.entityTypeKey;
  }

  onEnableTerminate(evt: { isEnable: boolean; selectedRows: any }) {
    this.shipmentActions.isEnableTerminate = evt.isEnable;

    if (evt.isEnable && evt.selectedRows) {
      let terminateShipmentIds = evt.selectedRows.map((row) => {
        return {id: row.id, entityType: row.entityTypeKey}
      });
      this.terminateShipmentIds = terminateShipmentIds;
    } else {
      this.terminateShipmentIds = [];
    }
  }

  onEnableComplete(evt: { isEnable: boolean; selectedRows: any }) {
    this.shipmentActions.isEnableComplete = evt.isEnable;

    if (evt.isEnable && evt.selectedRows) {
      let completeShipmentIds = evt.selectedRows.map((row) => {
        return {id: row.id, entityType: row.entityTypeKey}
      });
      this.completeShipmentIds = completeShipmentIds;
    } else {
      this.completeShipmentIds = [];
    }
  }

  onEnableDelete(evt: { isEnable: boolean; selectedRows: any }) {
    this.shipmentActions.isEnableDelete = evt.isEnable;

    if (evt.isEnable && evt.selectedRows) {
      let deleteShipmentIds = evt.selectedRows.map((row) => {
        return {id: row.id, entityType: row.entityTypeKey}
      });
      this.deleteShipmentIds = deleteShipmentIds;
    } else {
      this.deleteShipmentIds = [];
    }
  }

  onEditClick(shipmentType: 'basic' | 'composite') {
    this.editedShipmentCatagory = shipmentType;
    this.editedShipmentType = this.editedShipmentCatagory == 'basic' ? this.shipmentActions.shipmentData.entityTypeKey : this.shipmentActions.shipmentData?.compositeShipmentType;
    this.editedShipmentID = this.editedShipmentCatagory == 'basic' ? this.shipmentActions.shipmentData.id : this.shipmentActions.shipmentData?.payloadOf;
    this.mode = '';
    setTimeout(() => {
      this.mode = 'edit';
    }, 1);
    this.showSidePanel = true;
  }

  onConfirm(type: 'complete' | 'delete' | 'terminate') {
    this._dialog
      .open(ConfirmDialogComponent, {
        maxWidth: '400px',
        data: {
          message: `Are you sure want to ${type} shipment?`,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (type === 'terminate') {this.terminateShipment();}
          if (type === 'complete') {this.completeShipment();}
          if (type === 'delete') {this.deleteShipment();}
        }
      });
  }

  async terminateShipment() {
    await this._shipmentService.terminateShipment(this.terminateShipmentIds[0].id, this.terminateShipmentIds[0].entityType);
    this.reloadShipmentsWithTimer();
  }

   deleteShipment() {
     this._shipmentService.deleteShipment(this.deleteShipmentIds[0].id, this.deleteShipmentIds[0].entityType).subscribe((res:any)=>{
        this._toastService.success(res?.status,'Success',5000);
        this.reloadShipmentsWithTimer();
     })
   
  }

  async completeShipment() {
    await this._shipmentService.completeShipment(this.completeShipmentIds[0].id, this.completeShipmentIds[0].entityType);
    this.reloadShipmentsWithTimer(10);
  }

  reloadShipmentsWithTimer(time = 5) {
    this.refreshTimer = time;
    this.delayedRefreshStart = true;
  }

  reloadShipments() {
    if(this.delayedRefreshStart){
      this.delayedRefreshStart = false
    }
    this.getShipmentsList();
  }
}
