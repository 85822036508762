import { Injectable } from '@angular/core';
import * as _ from "lodash";
import * as moment from "moment";
import { DtDataService } from "./dt-data.service";
import { MapFilterService } from "./map-filter.service";
import { CombinedMapIconService } from "./combined-map-icon.service";
@Injectable({
  providedIn: "root",
})
export class AgmClustersService {
  dateRow = ``;
  days = ["M", "T", "W", "Th", "F", "S", "Su"];
  today;
  mainFilters;
  multiAlert = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g fill="none" fill-rule="evenodd" transform="translate(0 0), scale(0.5 0.5)">
  <circle cx="20" cy="20" r="20" fill="#FF2525" stroke="#D3D3D3"/>
  <g fill="none" fill-rule="evenodd"  transform="translate(-1 -1), scale(.45 .45)">
  <path fill="#FFF" d="M45.72,18.18c-5,0-9,6.62-9,15.08,0,12.18,7.26,22,7.57,22.42l1.42,1.9,1.43-1.9c.31-.41,7.57-10.24,7.57-22.42C54.72,24.8,50.77,18.18,45.72,18.18Zm0,33.16c-2-3.38-5.43-10.27-5.43-18.08,0-6.79,2.86-11.52,5.43-11.52s5.44,4.73,5.44,11.52C51.16,41.05,47.73,48,45.72,51.34Z"/>
  <path fill="#FFF" d="M45.27,61.65a7.37,7.37,0,0,0,0,14.71,7.37,7.37,0,0,0,0-14.71Zm0,11.14a3.82,3.82,0,0,1,0-7.58,3.82,3.82,0,0,1,0,7.58Z"/>
  </g>
  </g></svg>`;
  counterFunctions = {
    countMainNode: (loc, countsObj) => {
      if (this.mainFilters.targeted === "inventory" && loc.label.nodeClass === "inventory") {
        countsObj[loc.label.nodeClass] += +loc.label.objCounters["inventory"];
      } else {
        countsObj[loc.label.nodeClass] = countsObj[loc.label.nodeClass] ? countsObj[loc.label.nodeClass] + 1 : 1;
      }
      return countsObj;
    },
    inventory: (loc, countsObj) => {
      if (loc.label.targetedType) {
        countsObj["inventory"] +=
          loc.label.objCounters["inventory"] && loc.label.inventoryObj && loc.label.inventoryObj.show
            ? +loc.label.objCounters["inventory"]
            : 0;
      } else {
        countsObj["inventory"] += loc.label.nodeClass === "inventory" && loc.label.count ? +loc.label.count - 1 : 0;
      }
      return countsObj;
    },
    asset: (loc, countsObj) => {
      // countsObj['asset'] += loc.label.nodeClass === 'asset' && loc.label.count ? +loc.label.count - 1 : 0;
      if (loc.label.assetObj && loc.label.assetObj.show) {
        // countsObj["asset"] += +loc.label.objCounters["asset"];
        countsObj["asset"] += +loc.label.assetObj.count;
      }
      return countsObj;
    },
    shipment: (loc, countsObj) => {
      if (loc.label.shipmentObj && loc.label.shipmentObj.show) {
        countsObj["shipment"] += +loc.label.objCounters["shipment"];
      }
      return countsObj;
    },
    location: (loc, countsObj) => {
      if (loc.label.locationObj && loc.label.locationObj.show) {
        countsObj["location"] += +loc.label.objCounters["location"];
      }
      return countsObj;
    },
    incident: (loc, countsObj) => {
      if (loc.label.alertsObj && loc.label.alertsObj.show) {
        if (!loc.label.alertsObj.noneSelected && loc.label.alertsObj.filteredSubCounts) {
          // For alerts roll-ups
          Object.entries(loc.label.alertsObj.filteredSubCounts).forEach(([key, val]) => {
            // alertsByType[key] = alertsByType[key] ? alertsByType[key] + val : val;
            countsObj["alerts"] += +val;
          });
        } else {
          countsObj["alerts"] += loc.label.alertsObj.show ? loc.label.alertsObj.count : 0;
          // Object.entries(loc.label.alertsObj.filterCounts).forEach(([key, val]) => {
          //   alertsByType[key] = alertsByType[key] ? alertsByType[key] + val : val;
          // })
        }
        // if (loc.label.alertsObj.days) { // For alerts chart
        //   Object.entries(loc.label.alertsObj.days).forEach(([key, val]) => {
        //     alertsObj[key] = alertsObj[key] ? alertsObj[key] + val : val;
        //   })
        // }
      }
      return countsObj;
    },
  };
  constructor(
    private _combinedMapIconService: CombinedMapIconService,
    private _mapFilterService: MapFilterService,
    private _dtDataService: DtDataService
  ) {}

  getDateRow() {
    let numDays = 14,
      index = 0;
    if (!this.today) {
      this.today = moment().day();
    }
    if (!this.dateRow) {
      this.dateRow = `<div class="days-row">`;
      index = this.today;
      while (numDays--) {
        this.dateRow += `<span>${this.days[index]}</span>`;
        index = index > 5 ? 0 : index + 1;
      }
      this.dateRow += `</div>`;
    }

    return this.dateRow;
  }
  getCalculator(cclv) {
    const self = this;
    return function (markers, numStyles) {
      let index = 0,
        dv = 0,
        locText,
        countsObj = {
          alerts: 0,
          inventory: 0,
          asset: 0,
          shipment: 0,
          location: 0,
        };
      const countFns = [];
      self.mainFilters = self._mapFilterService.mainFilters || {
        asset: true,
        location: true,
      };
      _.forOwn(self.mainFilters, (val, key) => {
        if (val && self.counterFunctions[key]) {
          countFns.push(self.counterFunctions[key]);
        }
      });
      countFns.push(self.counterFunctions["countMainNode"]);
      markers.forEach(function (loc) {
        if (!loc.label) {
          return;
        }
        countFns.forEach((fn) => {
          countsObj = fn(loc, countsObj);
        });
      });

      locText = self.getLocText(markers, countsObj, cclv);

      dv = countsObj["site"];
      if (!isNaN(dv)) {
        let tries = 20;
        while (dv !== 0 && tries--) {
          dv = parseInt(dv / 10 + "", 10);
          index++;
        }
      }
      return {
        text: locText,
        index: index,
      };
    };
  }
  countMainTypes(countsObj, mainTypes, numBlobs) {
    if (countsObj["asset"] > 0) {
      mainTypes["asset"] = countsObj["asset"];
      numBlobs++;
    }
    if (countsObj["inventory"] > 0) {
      mainTypes["inventory"] = countsObj["inventory"];
      numBlobs++;
    }
    if (countsObj["shipment"] > 0) {
      mainTypes["shipment"] = countsObj["shipment"];
      numBlobs++;
    }

    if (countsObj["incident"] > 0) {
      // If using alerts as markers, they are counted as incident
      countsObj["alerts"] = countsObj["incident"];
    }
    return [mainTypes, numBlobs, countsObj];
  }
  getLocText(markers, countsObj, cclv) {
    let locText = "<div class='cluster-container'>",
      numBlobs = 0,
      hasTooltip = false,
      tooltipList = "",
      mainTypes = {},
      labelText = "",
      co = this._dtDataService.getCanvasOptions();

    const targeted = markers[0].label.targetedType,
      alertsObj = {},
      inventoryByType = {},
      names = {};

    if (markers.length === 1) {
      names[markers[0].label.nodeClass] = markers[0].label.name;
    }
    [mainTypes, numBlobs, countsObj] = this.countMainTypes(countsObj, mainTypes, numBlobs);
    if (numBlobs === 0) {
      if (countsObj["alerts"] > 0) {
        locText += this.getBlob(countsObj["alerts"], "alert");
      } else if (countsObj["location"] > 0) {
        locText += this.getBlob(countsObj["location"], "loc");
      }
    } else {
      if (numBlobs > 0 && countsObj["alerts"] > 0) {
        locText += `<span class='cluster-excursion-tab  num-items-${numBlobs}'>${this.multiAlert}</span>`;
      }
      if (numBlobs === 1) {
        // Round blob counter
        Object.entries(mainTypes).forEach(([name, val]) => {
          locText += this.getBlob(val, name);
          // if (co.showTopLocsByAlert) {
          //   locText += this.getAlertVisBlob(val, name);
          // }
        });
      } else if (numBlobs > 1) {
        // Use stack cluster counter
        Object.entries(mainTypes).forEach(([name, val]) => {
          locText += `<span class='cluster-stack-count ${name}-blob num-items-${numBlobs}'>${val}</span>`;
        });
      }
    }
    if (countsObj["location"] > 0) {
      labelText =
        countsObj["location"] > 1 ? countsObj["location"] + " Sites " : names["location"] ? names["location"] : markers[0].label.name; //'1 Site';
    } else if (countsObj["shipment"] > 0) {
      labelText = countsObj["shipment"] > 1 ? countsObj["shipment"] + " Shipments " : names["shipment"] ? names["shipment"] : "1 Shipment";
    } else if (countsObj["zone"] > 0) {
      labelText += countsObj["zone"] > 1 ? countsObj["zone"] + " Zones " : names["zone"];
    }

    // console.log("counts", countsObj, labelText, mainTypes, numBlobs);

    if (!cclv.showTooltips) {
      hasTooltip = false;
    } else if (cclv.infoType) {
      locText += `<div class='cluster-tooltip-container'><div class='cluster-tooltip'>`;
      locText += this.getInfoChart(cclv.infoType, countsObj, alertsObj, markers);
      locText += "</div></div>";
    } else if (countsObj["alerts"] > 0) {
      // include tooltip
      locText += `<div class='cluster-tooltip-container'><div class='cluster-tooltip'>`;
      locText += this.getAlertsChart(countsObj, alertsObj);
      locText += "</div></div>";
    } else if (countsObj["inventory"] > 0) {
      const content = this.getTrendsInfograph(countsObj["inventory"]);
      if (content) {
        locText += `<div class='cluster-tooltip-container'><div class='cluster-tooltip'>`;
        locText += content;
        locText += "</div></div>";
      }
    } else if (targeted === "inventory" && _.size(inventoryByType)) {
      tooltipList += `<h4 class="m-0 lh-24px">Inventory by Type</h4>`;
      tooltipList += this.getTypeList(inventoryByType);
      hasTooltip = true;
    } else if (markers && markers.length) {
      //(targeted === 'shipment' || targeted === 'location') &&
      hasTooltip = true;
      tooltipList += this.getLocList(markers);
    }

    if (hasTooltip) {
      locText += `<div class='cluster-tooltip-container marker-list'><div class='cluster-tooltip'><div class="scroll-y-bar h-100">`;
      locText += tooltipList;
      locText += "</div></div></div>";
    }
    if (countsObj["asset"] > 0) {
      locText += `</span>`;
    }
    if (labelText) {
      locText += `<div class='cluster-loc-count' (click)="clusterClicked($event)"><span>${labelText}</span></div>`;
    }
    locText += "</div>";

    return locText;
  }
  getBlob(val, name) {
    let blobHeight = ("" + val).length * 7;
    blobHeight = blobHeight > 18 ? blobHeight : 18;
    return `<span class='cluster-blob-count ${name}-blob' style="height:${blobHeight}px; width:${blobHeight}px; line-height:${blobHeight}px">${val}</span>`;
  }
  getAlertVisBlob(val, name) {
    let blobHeight = ("" + val).length * 7;
    blobHeight = blobHeight > 18 ? blobHeight : 18;
    return `<span class='cluster-blob-vis-alert ${name}-blob' style="height:${blobHeight}px; width:${blobHeight}px; line-height:${blobHeight}px">${val}</span>`;
  }
  getAlertsChart(countsObj, alertsObj) {
    let alertsList = "",
      val;
    const useMockData = false;
    const chartColor = countsObj["alerts"] > 6 ? " red" : countsObj["alerts"] > 4 ? " yellow" : " green";
    const exText = countsObj["alerts"] === 1 ? " Incident" : " Incidents";
    if (!useMockData && _.size(alertsObj)) {
      _.forOwn(alertsObj, (val, date) => {
        alertsList += `<span class="tooltip-graph-bar" style="right:${+date * 13 + 4}px; height:${val}px"> <span>${val}</span> </span>`;
      });
    } else if (useMockData) {
      for (let i = 0; i < countsObj["alerts"]; i++) {
        val = Math.floor(Math.random() * 50) + 1;
        alertsList += `<span class="tooltip-graph-bar" style="right:${
          +Math.floor(Math.random() * 14) * 13 + 4
        }px; height:${val}px"> <span>${val}</span> </span>`;
      }
    }
    const statsTemplate = ` <div class="h4"> <span class='${chartColor} ml-5px'><i class='fa fa-line-chart'></i></span>
                        <span class="ml-5px">${countsObj["alerts"]} ${exText}</span></div>
                        <div  class="tooltip-chart">${alertsList}</div>
                        ${this.getDateRow()}
                        <h4 class="weekly-trend">Bi-weekly trend</h4>`;
    return statsTemplate;
  }
  getTypeList(inventoryByType) {
    let listTemplate = `<ul class="m-0 scroll-y h-calc-20">`;
    _.forOwn(inventoryByType, (val, key) => {
      listTemplate += `<li class="foz12 css-grid grid-70-30 align-center" >
      <span>${key}</span>
      <span>${val}</span></li>`;
    });
    listTemplate += `</ul>`;

    return listTemplate;
  }
  getLocList(locs) {
    let url,
      shortList = locs.slice(0, 12),
      name,
      uid;
    let listTemplate = `<ul class="m-0">`;
    shortList.forEach((item) => {
      uid = item.uid || item.label.uid;
      name = item.name || item.label.name;
      url = "#/home/live-view/navigator?uid=" + uid;
      // if (item.label.nodeClass === 'inventory') {
      //   listTemplate += `<li class="foz12 css-grid grid-70-30 align-center" >
      // <a  href='${url}' ">${item.label.name}</a>
      // <span>${item.label.objCounters['inventory']}</span></li>`;
      // } else {
      //
      listTemplate += `<li class="foz12" ><a  href='${url}' ">${name}</a></li>`;
      // }
    });
    listTemplate += `</ul>`;

    return listTemplate;
  }
  getInfoChart(infoType, countsObj, alertsObj, locs) {
    if (infoType === "asset-states") {
      return this.getAssetStatesInfograph(locs);
    }
    return null;
  }
  getAssetStatesInfograph(locs) {
    let catCounts = {};
    let title = '<div class="foz14  leading-5">Asset States</div>';
    locs.forEach((loc) => {
      if (loc.label && loc.label.assetObj && loc.label.assetObj.stateCounts) {
        // catCounts.Total += loc.label.assetObj.count;
        _.forOwn(loc.label.assetObj.stateCounts, (val, key) => {
          if (val) {
            catCounts[key] = catCounts[key] || 0;
            catCounts = { ...catCounts, [key]: catCounts[key] + val };
          }
        });
      }
    });
    return title + this.getTypeList(catCounts);
  }
  getTrendsInfograph(invCount) {
    let valuepercent = Math.ceil(Math.random() * 10) * (Math.round(Math.random()) ? 1 : -1);
    let valueIconColor = "green";
    if (valuepercent < 0) {
      valueIconColor = "red";
    }
    let icon1 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9"> <polygon  points="31.7 6 35.3 6 31.7 9.6 27.7 5.6 23 10.3 24.4 11.7 27.7 8.4 31.7 12.4 36.7 7.4 36.7 11 38.7 11 38.7 4 31.7 4" transform="translate(-23 -4) "/></svg>';
    const listTemplate = ` <div class="h4"> <span class='green ml-5px'><i class='fa fa-line-chart'></i></span>
                             <span class="ml-5px"> Inventory Stats</span></div>
                             <div  class="tooltip-chart stats">
                                 <div style="width: 50%; float:left" class='align-center'>
                                     <ul> ${(Math.random() * 3).toFixed(1) + 1}m </ul> <ul>  value  </ul>
                                     <ul>  ${valuepercent}%
                                           <div class='variance-icon ${valueIconColor} inline'>  <span>${icon1} </span> </div>
                                     </ul>
                                 </div>
                                 <div style="width: 50%; float:right" class='align-center'>
                                    <ul>  ${(Math.random() * 3 + 4).toFixed(1)}%  </ul> <ul>  ageing  </ul>
                                    <ul>   ${Math.floor(Math.random() * 10) + 1}%
                                         <div class='variance-icon red inline'>  <span>${icon1} </span> </div>
                                      </ul>
                                 </div>
                             </div>
                             <h4 class="weekly-trend">weekly trend</h4>`;
    return listTemplate;
  }
}
