import { AgmMap, LatLngBounds } from "@agm/core";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { CL_INPUT_DEBOUNCE_TIME, CL_INPUT_DEBOUNCE_TIME_HIGH } from "@cl/constants";
import { Coordinates, NavigatorMapZoomDetails, SearchQueryRequest, ServerCluster, ServerClusterList } from "@cl/models";
import { MapFilterService } from "../../../common/services/map-filter.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionSuccessful, Select, Store } from "@ngxs/store";
import _ from "lodash";
import * as ngeohash from "ngeohash";
import { combineLatest, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";
import { NavigatorMapService } from "../../service/navigator-map.service";
import {
  NavigatorResetMap,
  NavigatorUpdateLoadingStatus,
  NavigatorUpdateMapDetails,
  NavigatorFilteredNodes,
  NavigatorLastAction,
  NavigatorSetMapCenter
} from "../../state/navigator.actions";
import { NavigatorState } from "../../state/navigator.state";
import { NavigatorStateModel} from "../../state/navigator.state.model";
// import LatLng = google.maps.LatLng;


@UntilDestroy()
@Component({
  selector: "app-server-clustered-map",
  templateUrl: "./server-clustered-map.component.html",
  styleUrls: ["./server-clustered-map.component.scss"],
})
export class ServerClusteredMapComponent implements OnInit {
  @ViewChild(AgmMap, { static: false }) agmMap: AgmMap;

  @Input() targetDomain = "";
  @Input() visibleDomains = new Array<string>();
  @Input() mapZoom: NavigatorMapZoomDetails; //43.5142765,-48.7785884
  @Input() currentCenter: Coordinates = { lat: 27.212603, lng: -8.876380 };

  animateWhenReady = "DROP";
  showInfoWindow = false;
  readonly mapTypeControlOptions: google.maps.MapTypeControlOptions = {
    position: google.maps.ControlPosition.BOTTOM_LEFT,
  };
  readonly zoomControlOptions: google.maps.ZoomControlOptions = {
    position: google.maps.ControlPosition.BOTTOM_RIGHT,
  };
  readonly streetViewControl = false;

  /** Google map instance */
  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;

  clusters: ServerClusterList = [];

  @Select(NavigatorState)
  private navigatorState$: Observable<NavigatorStateModel>;
  private readonly loadNode$ = new Subject<string>();

  constructor(
    private store: Store,
    private actions$: Actions,
    private navigatorMapService: NavigatorMapService,
    private mapFilterService: MapFilterService,
  ) { }

  ngOnInit(): void {
    this.onMapReset();
    this.loadMapNodes();
  }

  mapReady(evt) {
    this.map = evt;
    this.map.data.setStyle({
      strokeColor: "#2879DE",
      fillColor: "#d8ebf9",
      fillOpacity: 0.65,
      strokeOpacity: 0.75,
      strokeWeight: 2,
      zIndex: 1,
    });

    this.listenForMapBoundsChange();
    this.loadNode$.next("");
  }

  zoomInToCluster(cluster: ServerCluster) {
    this.zoomIn();
    this.map.setCenter(new google.maps.LatLng(cluster.lat, cluster.lng));
  }

  zoomIn() {
    this.map.setZoom(this.map.getZoom() + 2);
  }

  /**
   * Subscribes to map bounds change event.
   */
  private listenForMapBoundsChange() {
    this.agmMap.boundsChange.pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME_HIGH))
      .subscribe((bounds: LatLngBounds) => {
        const parsedBounds = this.mapFilterService.formatBoundsForSearch(bounds);
        this.store.dispatch([new NavigatorUpdateMapDetails({ bounds: parsedBounds }), new NavigatorLastAction('mapPane')]);
      });
  }

  private setZoom(zoom: number) {
    if (!this.map) return;

    this.map.setZoom(zoom);
  }

  private setCenter(latLng: google.maps.LatLng) {
    if (!this.map) return;

    this.map.setCenter(latLng);
  }

  private assertAndSetCenter() {
    if (this.currentCenter.lat && this.currentCenter.lng) {
      this.setCenter(new google.maps.LatLng({ lat: +this.currentCenter.lat, lng: +this.currentCenter.lng }));
    } else {
      console.warn("bad center", this.currentCenter);
    }
  }

  private onMapReset() {
    // Reset map state
    this.actions$.pipe(untilDestroyed(this), ofActionSuccessful(NavigatorResetMap, NavigatorSetMapCenter), debounceTime(CL_INPUT_DEBOUNCE_TIME)).subscribe((_) => {
      // Pan the map to default coordinates and reset zoom
      this.assertAndSetCenter();
      this.setZoom(2);
    });
  }

  private getPrecisionForZoomLevel(zoom) {
    if (zoom >= 0 && zoom <= 6) {
      return 3;
    }  /*else if (zoom >= 5 && zoom <= 6) {
      return 3;
    } */else if (zoom >= 7 && zoom <= 9) {
      return 4;
    } else if (zoom >= 10 && zoom <= 12) {
      return 5;
    } else if (zoom >= 13 && zoom <= 15) {
      return 6;
    } else if (zoom >= 16 && zoom <= 18) {
      return 7;
    } else { //if (zoom >= 19 && zoom <= 22)
      return 8;
    }
  }

  private loadMapNodes() {
    const payload$ = this.navigatorState$.pipe(
      untilDestroyed(this),
      debounceTime(CL_INPUT_DEBOUNCE_TIME),
      filter(state => !!(state?.filterEsQuery && state?.mapDetails)),
      map(state => {
        const { filterEsQuery, mapDetails } = state;
        const { bounds } = mapDetails;

        return {
          ...filterEsQuery,
          ...bounds,
          //precision: Math.ceil(this.map.getZoom() / 2).toString(),
          precision: this.getPrecisionForZoomLevel(this.map.getZoom()).toString(),
        } as SearchQueryRequest;
      }),
      distinctUntilChanged(_.isEqual),
    );

    combineLatest([payload$, this.loadNode$])
      .pipe(
        untilDestroyed(this),
        // Clear map if there is none of the domain/filters are toggled visible
        filter((_: any) => {
        if (this.visibleDomains.length <= 0) {
          this.clusters = [];
          return false;
        }
        return true;
        }),
        // Turn on map loading spinner
        tap(() => this.store.dispatch(new NavigatorUpdateLoadingStatus({ map: true }))),


        switchMap(([payload]) => this.navigatorMapService.serverClusteredMap(payload)),

        // Turn on map loading spinner
        tap(() => this.store.dispatch(new NavigatorUpdateLoadingStatus({ map: false }))),
      )
      .subscribe(res => {

        /**
         * Maps AggregatedApiResponse type to ServerClusterList type
         */
        try {
          this.clusters = res?.hits[0]?.buckets
            .filter(c => c?.subBucket?.length >= 1)
            .map(c => {
              const geoHash = ngeohash.decode(c.key);
              return new ServerCluster(
                c.key,
                c.value,
                geoHash.latitude,
                geoHash.longitude,
                (c.subBucket ?? []).map(s => ({ key: s.key, count: s.value })),
              );
            });
            this.store.dispatch(new NavigatorFilteredNodes(res?.totalHits));
            // if(!this.visibleDomains.length){
            //   this.clusters = [];
            // }
        } catch (e) {
          console.error("[ERROR]", e);
        }
        // console.log(res, this.clusters);
      });
  }
}
