<div class="pr-[1px]">
<span *ngIf="!hideTitle" class="nav panel-header side-nav-header bb-light">
  <h3 class="align-center">{{ title }}</h3>
  <span *ngIf="enableToggle" (click)="togglePanel()" class="cursor-pointer mr-3"><i class="fa fa-times-circle-o"
      aria-hidden="true"></i></span>
</span>
<!-- <div *ngIf="!dataLoading" class="h-100"> -->
<div *ngIf="!enableEdit">
  <div class="flex justify-between p-0-3 ml10 mr10 items-center mb-2">
    <div class="fs12 font-12-bold"><span *ngIf="!hideFilterBy">{{'common.filterBy'|translate}}</span></div>
    <div class="flex justify-end items-center">
      <a *ngIf="!clearDisabled" class="font-11-normal justify-self-end mr-2" (click)="clearAll()">{{'common.clearAll'|translate}}</a>
      <span *ngIf="clearDisabled" class="fade-2 font-11-normal justify-self-end mr-2">{{'common.clearAll'|translate}}</span>
      <span (click)="togglePanel()" class="cursor-pointer ml10"><i class="fa fa-times-circle-o"
          aria-hidden="true"></i></span>
    </div>
  </div>
  <!-- <hr /> -->
  <form *ngIf="enableSearchBox" [formGroup]="searchFormGroup" novalidate class="add-attribute-form">
    <input type="search" class="basic-input" (input)="onSearchInput($event)" placeholder="Search" value=""
      formControlName="searchTerm" />
  </form>
</div>

<div class="block cl-list-filter" *ngIf="mgClass !== 'location' && mgClass !== 'users'" [ngClass]="{ 'h-300px': isNavigator }">
  <div [ngClass]="heightClass" class="scroll-y pos-rel">
    <div *ngIf="isNavigator" class="middle-border"></div>
    <div *ngFor="let filterGroup of listFilters" [ngClass]="getFloatClass(filterGroup)">
      <h4 *ngIf="!hideLabel" class="side-panel-header-bg pl-4 pr-4 grid grid-cols-auto-max-content align-items-center pointer filter-type"
        (click)="filterGroup.expand = !filterGroup.expand">
        <span class="font-12-bold">{{ filterGroup.label }}</span>
        <i class="fa fa-chevron-down collapse-icon fs12"
          [title]="filterGroup?.expand ? 'Collapse filter list' : 'Expand filter list'"
          [class.expand]="!filterGroup.expand">
        </i>
      </h4>
      <div *ngIf="filterGroup.expand" class="overflow-hidden mb10"
        [ngClass]="[(filterGroup?.list?.length > 5 ? 'max-h-[160px] overflow-y-auto' : 'overflow-hidden'),filterGroup?.type === 'multiselect'?'ml-4':'']" >
        <!-- Multiselect filter -->
        <ng-container *ngIf="filterGroup?.type === 'multiselect'">
          <nav *ngIf="!enableEdit" class="filter-list "
            [ngClass]="[filterGroup.list?.length > 5 ? 'h-[160px] overflow-y-auto' : 'h-100']">
            <!-- Filter list -->
            <div *ngFor="let filter of filterGroup.list">            <div *ngIf="!showIcon || !filter.svgName">
                <span class="css-grid mr10" [class.grid-70-30]="showCounts"
                  [class.grid-cols-1]="!showCounts">
                  <mat-checkbox class="foz12 mb-1 mt-0" [ngClass]="caseSenstiveFilter === filterGroup?.filterType ? '': 'capitalize'"
                    (change)="toggleFilter(filter, filterGroup)" [(ngModel)]="filter.checked">
                    <div *ngIf="svgName" [inlineSVG]="'/assets/svgs/cl-circle-icons/' + svgName + '.svg'"
                      class="summary-icon"></div>
                    <span class="font-12-normal" *ngIf="filter?.displayLabel && filter?.displayLabel?.length > 20"
                      matTooltip="{{ filter?.displayLabel }}" matTooltipClass="tooltip-styles">
                      {{ filter?.displayLabel | truncate: 24 }}
                    </span>
                    <span class="font-12-normal" *ngIf="filter?.displayLabel && filter?.displayLabel?.length <= 20">
                      {{ filter?.displayLabel }}
                    </span>
                    <span class="font-12-normal" *ngIf="filter?.name?.length > 20 && !filter?.displayLabel"
                      matTooltip="{{ filter?.label }}" matTooltipClass="tooltip-styles">
                      {{ filter?.label || filter?.name | truncate: 24 }}
                    </span>
                    <span class="font-12-normal" *ngIf="filter?.name?.length <= 20 && !filter?.displayLabel">
                      {{ filter?.label || filter?.name }}
                    </span>
                  </mat-checkbox>
                  <span *ngIf="showCounts && filterGroup.showCount" class="justify-self-end font-11-bold">({{ filter?.count }})</span>
                </span>
                <div *ngIf="filter?.subList && filter?.subList?.length" class="infinite-list-container">
                  <cdk-virtual-scroll-viewport itemSize="35" class="h-100 scroll-y">
                    <div *cdkVirtualFor="let subFilt of filter?.subList; trackBy: trackByIdFn; templateCacheSize: 0"
                      class="ml-3" (click)="$event.stopPropagation()">
                      <span class="css-grid grid-70-30">
                        <mat-checkbox class="foz12 capitalize mb-1 mt-0" (change)="toggleSubFilter(subFilt, filter)"
                          [(ngModel)]="subFilt.checked">
                          <div *ngIf="svgName" [inlineSVG]="'/assets/svgs/cl-circle-icons/' + svgName + '.svg'"
                            class="summary-icon"></div>
                          <span *ngIf="subFilt?.name?.length > 18" matTooltip="{{ subFilt?.label }}"
                            matTooltipClass="tooltip-styles">
                            {{ subFilt?.label || subFilt?.name | truncate: 18 }}
                          </span>
                          <span *ngIf="subFilt?.name?.length <= 18">
                            {{ subFilt?.label || subFilt?.name }}
                          </span>
                        </mat-checkbox>
                        <span *ngIf="subFilt?.count" class="justify-self-end foz12">({{ subFilt?.count }})</span>
                      </span>
                    </div>
                  </cdk-virtual-scroll-viewport>
                </div>
              </div>
            </div>
            <!-- / Filter list -->
          </nav>
        </ng-container>
        <ng-container *ngIf="filterGroup?.type === 'dateRangeFilter'">
          <cl-date-range-filter (panelsEmitter)="getDateRangePanelUpdate($event)" [dateRangeFilters]="dateRangeFilters"></cl-date-range-filter>
      </ng-container>
        <ng-container *ngIf="filterGroup?.type === 'singleselect'">
          <mat-radio-group class="single-select-filter" [(ngModel)]="filterGroup.selected">

            <mat-radio-button *ngFor="let filter of filterGroup.list" class="foz12 capitalize mb-1 mt-0"
              (change)="toggleFilter(filter, filterGroup, 'singleselect', $event)" [value]="filter?.name">
              <span class="css-grid mr10" [class.grid-70-30]="showCounts"
                [class.grid-cols-1]="!showCounts">
                <div *ngIf="svgName" [inlineSVG]="'/assets/svgs/cl-circle-icons/' + svgName + '.svg'"
                  class="summary-icon"></div>
                <span class="font-12-normal" *ngIf="filter?.displayLabel && filter?.displayLabel?.length > 20"
                  matTooltip="{{ filter?.displayLabel }}" matTooltipClass="tooltip-styles">
                  {{ filter?.displayLabel | truncate: 24 }}
                </span>
                <span class="font-12-normal" *ngIf="filter?.displayLabel && filter?.displayLabel?.length <= 20">
                  {{ filter?.displayLabel }}
                </span>
                <span class="font-12-normal" *ngIf="filter?.name?.length > 20 && !filter?.displayLabel"
                  matTooltip="{{ filter?.label }}" matTooltipClass="tooltip-styles">
                  {{ filter?.label || filter?.name | truncate: 24 }}
                </span>
                <span class="font-12-normal" *ngIf="filter?.name?.length <= 20 && !filter?.displayLabel">
                  {{ filter?.label || filter?.name }}
                </span>
                <span *ngIf="showCounts && filterGroup.showCount" class="justify-self-end font-11-bold">({{ filter.count }})</span>
              </span>
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>
        <ng-container *ngIf="filterGroup?.type === 'currencyrange'">
          <cl-currency-range (rangeChanged)="onRangeChanged($event, filterGroup)"></cl-currency-range>
        </ng-container>
        <ng-container *ngIf="filterGroup?.type === 'daterange'">
          <cl-date-picker [dateRange]="selectedDateRange" [ranges]="ranges" (dateChanged)="datesUpdated($event, filterGroup)"
          [datePickerClass]="datePickerClass"></cl-date-picker>
        </ng-container>
        <!-- / Multiselect filter -->
      </div>
    </div>
  </div>
</div>
</div>