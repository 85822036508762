import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CL_INPUT_DEBOUNCE_TIME } from "@cl/constants";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-search-box",
  templateUrl: "./search-box.component.html",
  styleUrls: ["./search-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBoxComponent),
      multi: true,
    },
  ],
})
export class SearchBoxComponent implements OnInit, ControlValueAccessor {
  @Input() searchTerm = "";
  @Input() placeholder = "Assets, SKUs, Manufacturers";
  @Output() search = new EventEmitter<string>();

  fcSearch = new FormControl("");

  onChange: any = (obj: any) => {};
  onTouch: any = () => {};

  constructor() {}

  ngOnInit(): void {
    this.fcSearch.valueChanges.pipe(untilDestroyed(this), debounceTime(CL_INPUT_DEBOUNCE_TIME)).subscribe((val) => this.onChange(val));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: string): void {
    this.searchTerm = obj;
    this.fcSearch.patchValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fcSearch.disable() : this.fcSearch.enable();
  }
}
