<div class="summary-section-bg p-1 mt-1 fs14 color16 border-1 summary-section-border">
  <table>
    <tr>
      <td class="font-bold w-40" style="word-break:break-word">Status:</td>
      <td style="word-break:break-word">
        {{ shipmentData?.shipmentNode?.properties?.statusWithAssetCount || "" }}
      </td>
    </tr>
    <tr>
      <td style="word-break:break-word"class="font-bold w-40">Actual Departure:</td>
      <td style="word-break:break-word">{{ depatureTime }}</td>
      <td class="pb-1 px-2">
        <div style="word-break:break-word"
          class="px-2"
          [ngClass]="
            getDiffTimes(
              shipmentData?.shipmentNode?.properties?.plannedDeparture,
              shipmentData?.shipmentNode?.properties?.actualDepTime
            ) > 0
              ? 'bgcolor20 color2 '
              : 'bgcolor21 color2 '
          "
        >
          {{ depatureDiff }}
        </div>
      </td>
    </tr>
    <tr>
      <td style="word-break:break-word"class="font-bold w-40">
        {{ isArrival ? "Actual Arrival" : "ETA" }}:
      </td>
      <td style="word-break:break-word">{{ arrivalTime }}</td>
      <td class="pb-1 px-2">
        <div style="word-break:break-word"
          class="px-2"
          [ngClass]="
            getDiffTimes(
              shipmentData?.shipmentNode?.properties?.plannedArrival,
              isArrival
                ? shipmentData?.shipmentNode?.properties?.actualArrivalTime
                : shipmentData?.shipmentNode?.properties?.eta
            ) > 0
              ? 'bgcolor20 color2 '
              : 'bgcolor21 color2 '
          "
        >
          {{ arrivalDiff }}
        </div>
      </td>
    </tr>
  </table>
</div>
<div class="border-1 summary-section-border mt-2">
  <ng-container *ngFor="let record of summaryData">
    <div
      class="flex m-1 fs14 color16"
      *ngIf="((record.value && record.value !== '') || isShowAllFields )" 
    >
    <!-- && !(record.isDtpRestricted && isDTPShipment && isDTPUser) -->
      <div class="font-bold w-40 flex-none">
        <span style="word-break:break-word"[matTooltipPosition]="'right'" [matTooltip]="record.label">{{stringTruncate(record.label, 20)}}</span>
      </div>
      <div>
        <a style="word-break:break-word" [href]="record.link" *ngIf="record.link">{{ record.value }}</a>
        <span style="word-break:break-word"*ngIf="!record.link">
          {{ record.value }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
<div class="fs14 color16 my-1" *ngIf="notes.note !== ''">
  <div class="font-bold w-40" style="word-break:break-word">Notes:</div>
  <div class="border-1 summary-section-border grow p-1">
    <div>{{ notes.note }}</div>
    <div class="italic fs12" *ngIf="!(isDTPShipment && isDTPUser) ">
      Last Updated By: {{ notes.notesModifiedBy }} at
      {{ notes.notesModifiedAt }}
    </div>
  </div>
</div>
