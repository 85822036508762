import { Component, OnInit } from '@angular/core';
import { UserService } from '@cl/user/user.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { AnalyticsService } from '@cl/analytics/analytics.service';

@Component({
  selector: 'cl-ml-workbench',
  templateUrl: './ml-workbench.component.html',
  styleUrls: ['./ml-workbench.component.scss']
})
export class MlWorkbenchComponent implements OnInit {

  public isLoading: boolean = false;
  private subscriptions: Subscription[] = [];
  baseUrl: any = '';
  urlSafe: SafeResourceUrl = "";
  loginUserTenantId: string = '';

  constructor(
    public sanitizer: DomSanitizer,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
    this.baseUrl = this.analyticsService.getMlWorkBenchBaseUrl();
    this.getMonitorHomePage();
    //this.loginUserTenantId = this.userService.getEffectiveTenantId();
  }

  getMonitorHomePage() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl);
  }

  openMlWorkbench(){
    window.open(this.baseUrl, "_blank");
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
