import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from '@cl/common/components/confirm-dialog/confirm-dialog.component';
import { LocationsService } from '@cl/locations/locations.service';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { InputField, InputType } from '@cl/@types/form-rendering-engine.type';
import { DynamicFormRendererComponent } from '@cl/common/components/dynamic-form-renderer/dynamic-form-renderer.component';
import { LocationUtilsService } from '@cl/locations/location-utils.service';
import { CdmField } from '@cl/@types/extended-attributes.types';

@Component({
  selector: 'cl-location-gateway-zone',
  templateUrl: './location-gateway-zone.component.html',
  styleUrls: ['./location-gateway-zone.component.scss']
})
export class LocationGatewayZoneComponent implements OnInit, OnChanges {
  @Output() selectedPosition = new EventEmitter();
  @Output() closePanel = new EventEmitter();
  @Input('editGateway') editGateway:any;
  @Output() emitTabIndex = new EventEmitter();
  @Input('zoom') zoom:number;
  @Input('mode') mode:string;
  @Input() orgList:any;
  @Input() isOrgRestrictedUser: boolean;
  @Input('mapClickedPosition') mapClickedPosition: any;
  @Input() objectType:any;
  @ViewChild('dynamicForm') dynamicForm: DynamicFormRendererComponent;
  @ViewChild('addressTemp') addressTemp:  TemplateRef<any>;
  @ViewChild('positionTemp') positionTemp:  TemplateRef<any>;
  @ViewChild('siteTemp') siteTemp:  TemplateRef<any>;
  @ViewChild('orgTemp') orgTemp:  TemplateRef<any>;
  gatewayForm: FormGroup;
  username = '';
  selectedMarker:any;
  regex = "^[A-Za-z0-9 @ & ( ) _  : ' , . ; + \" / -]+$";
  sites = [];
  fieldTypes: string[] =[];
  staticFields: string[] = [
    'position',
    'parentLocation',
    'address',
    'organization',
  ];

  tabSelected = 0;
  currentIndex = 0;
  previousIndex = 0

  // for bulk upload
  @Input() bulkObj:any;
  @Input() showResponse: boolean;
  @Input() bulkResponseArr: any[];
  @Output() emitFormData = new EventEmitter();
  fileFormData:any;
  initializedData: any = {};

  dynamicInputFields:InputField[] = [];
  downloadedCSVFields: string[] = [];
  submitted: boolean = false;

  constructor(private locationService: LocationsService, public dialog: MatDialog, private _locationUtils: LocationUtilsService,
    private _dynamicFormService: DynamicFormService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if(this.mode == 'add'){
      this. buildEmptyForm(); 
     } else if(this.mode == 'edit' && this.editGateway){
        this.gatewayForm = new  FormGroup({
          organization:new FormControl(this.editGateway.organization),
          zonename : new FormControl(this.editGateway.name,[Validators.required, Validators.pattern(this.regex)]),
          parentlocation : new FormControl(this.editGateway.parentLocation,[Validators.required]),
          address : new FormControl(this.editGateway.fullAddress,[Validators.required]),
          position : new FormControl(this.editGateway.position,[Validators.required, Validators.pattern(this.regex)]),
        });
        this.gatewayForm.value.position?this.selectedPosition.emit({lat:Number(this.gatewayForm.value.position.split(',')[0]), lng:Number(this.gatewayForm.value.position.split(',')[1])}):'';
     }

     this.locationService.getSites().subscribe(sites=> {
      this.sites = sites['hits'].filter(e=>e.name);
      this.sites.unshift({});
      if(this.mode == 'edit' && this.editGateway){
        this.gatewayForm.get('parentlocation').patchValue(this.sites.find(parentlocation => parentlocation.id == this.editGateway.parentLocation))
        this.gatewayForm.get('address').disable();
        this.gatewayForm.get('position').disable();
      }
    })

    this.getExtendedAttributes();
    this.initializedData = { ...this.gatewayForm.value };
  }

  buildEmptyForm() {
    this.gatewayForm = new  FormGroup({
      organization:new FormControl(this.orgList[0]?.id),
      parentlocation : new FormControl(null,[Validators.required]),
      address : new FormControl(null,[Validators.required]),
      position : new FormControl(null,[Validators.required, Validators.pattern(this.regex)])
    }); 
    this.emitTabIndex.emit(this.tabSelected);  
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes['mapClickedPosition']) {
      var self = this;
      this.gatewayForm?.patchValue({position: this.mapClickedPosition.lat + ',' + this.mapClickedPosition.lng});
      if(this.mapClickedPosition) {
        new google.maps.Geocoder().geocode({ 'location': new google.maps.LatLng(self.mapClickedPosition.lat, self.mapClickedPosition.lng) }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                self.gatewayForm?.patchValue({address:results[0].formatted_address});
              }
            }
        });
        this.gatewayForm.get('parentlocation').patchValue({});
        this.gatewayForm.get('address').enable();
      }
    }
  }

  submitForm(): any {
    this.submitted = true;
    if (this.gatewayForm.valid ) {
      if(this.dynamicForm){
        if(this.dynamicForm?.onSubmit().valid){
          return this.preparePayload();
        }
      } else {
        return this.preparePayload();
      }
      
    } else {
      this.validateAllFormFields(this.gatewayForm); 
      this.dynamicForm.onSubmit()
      return null;
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }
  handleAddressChange(address: any) {
    this.gatewayForm.patchValue({address: address.formatted_address});
    this.gatewayForm.patchValue({position: address.geometry.location.lat() + ',' + address.geometry.location.lng()});
    this.selectedPosition.emit({lat:address.geometry.location.lat(), lng:address.geometry.location.lng()})
  }

  getSelectedSite() {
    if(this.gatewayForm.value.parentlocation.name) {
      this.gatewayForm.get('position').enable();
      this.gatewayForm.get('address').enable();
      this.gatewayForm.get('address').patchValue(this.gatewayForm.value.parentlocation.fullAddress);
      if(this.gatewayForm.value.parentlocation.position)
        this.gatewayForm.get('position').patchValue(String(this.gatewayForm.value.parentlocation.position));      
      else if(JSON.parse(this.gatewayForm.value.parentlocation.coordinates).geometry.coordinates)
        this.gatewayForm.get('position').patchValue(JSON.parse(this.gatewayForm.value.parentlocation.coordinates).geometry.coordinates[1]+','+JSON.parse(this.gatewayForm.value.parentlocation.coordinates).geometry.coordinates[0]);
      else if(this.gatewayForm.value.parentlocation.siteLocation)
        this.gatewayForm.get('position').patchValue(this.gatewayForm.value.parentlocation.siteLocation.coordinates[1]+','+this.gatewayForm.value.parentlocation.siteLocation.coordinates[0]);
     
      if(this.gatewayForm.get('address').value){
        this.gatewayForm.get('address').disable();
      }
      this.selectedPosition.emit({lat:Number(this.gatewayForm.value.position?.split(',')[0]), lng:Number(this.gatewayForm.value.position?.split(',')[1])})
      this.gatewayForm.get('position').disable();
    } else {
      this.gatewayForm.get('address').enable();
    }
  }
  preparePayload(): any {
    let payload = {
      "organization": this.gatewayForm.value.organization,
      "coordinates": this.gatewayForm.value.parentlocation?this.gatewayForm.value.parentlocation.coordinates:'' ,
      "parentLocation": this.gatewayForm.value.parentlocation?this.gatewayForm.value.parentlocation.id:'',
      "address": this.gatewayForm.get('address').value,
      "position": this.gatewayForm.get('position').value,
      "catalogType":'gatewayzone',
      "objectType": this.objectType
  }
  const dynamicFormGroup = this.dynamicForm?.onSubmit();
  if(dynamicFormGroup){
    payload = {...dynamicFormGroup.getRawValue(), ...payload}
  }
  return payload;
  }

  onSelectedTabChange(event) {
    this.tabSelected = event.index;
    this.previousIndex = event.index;
    this.emitTabIndex.emit(event.index);
    if(this.tabSelected == 0){
      } else {
      this.fileFormData = null;
    }
}

  getUploadFormData(event) {
    this.fileFormData = event;
    this.emitFormData.emit(event);
  }

  checkFormDataAvaliable(): boolean {
    const form = this.dynamicForm?.form.getRawValue();
    let hasValues = false;
    if (this.previousIndex == 0) {
      let currentData = { ...form, ...this.gatewayForm.value };
      let isChange = false;
      for (let key in this.initializedData) {
        if (currentData[key] !== this.initializedData[key]) {
          isChange = true;
        }
      }
      hasValues = isChange;
    } else {
      hasValues = this.fileFormData ? true : false;
    }
    return hasValues;
  }
  onTabClick(tab: any) {
    this.currentIndex = tab.selectedIndex;
    if (this.checkFormDataAvaliable()) {
      tab.selectedIndex = this.previousIndex;
      let discardData = this.fileFormData?'File':'Gateway Zone';
      this.dialog
        .open(ConfirmDialogComponent, {
          maxWidth: '400px',
          data: {
            title: 'Discard',
            message: 'Are you sure want discard '+discardData+'?',
          },
        })
        .afterClosed()
        .subscribe((res) => {
          if (res) {
            tab.selectedIndex = this.currentIndex;
            this.previousIndex = this.currentIndex;
            this.tabSelected = this.currentIndex;
            this.emitTabIndex.emit(this.tabSelected)
            this.gatewayForm.reset();
            this.dynamicForm?.form?.reset();
            this.gatewayForm?.get('organization').patchValue(this.orgList[0]?.id);
            return;
          }
          tab.selectedIndex = this.previousIndex;
        });
      return;
    }
    tab.selectedIndex = this.currentIndex;
    this.tabSelected = this.currentIndex;
    this.emitTabIndex.emit(this.tabSelected)
  }

  async getExtendedAttributes(){
    const gatewayZoneCatalog = await this._locationUtils.getLocationExtendedAttributes(this.objectType);

    const fields = this._dynamicFormService.getRenderableFields(
      gatewayZoneCatalog.cdmFields
    );

    const orderedFields:CdmField[] = this._dynamicFormService.orderFields([...fields],'order');

    this.downloadedCSVFields = orderedFields.map(field => {
      let label = field.displayLabel;
      if(field.required){
        label += '*'
      }else if(field.displayLabel.endsWith('*')){
        label += '*'
      }
      return `"${label}"`
    })


    this.dynamicInputFields = await this._dynamicFormService.generateInputFields(orderedFields,this.mode == 'edit',this.editGateway)
    this.dynamicInputFields=  this.dynamicInputFields.filter(inp=>inp.id != "coordinates")
    this.dynamicInputFields.forEach(inp=>{
      if(inp.id == "organization"){
        inp.isDynamicField =false;
        inp.required =false
        inp.template = this.orgTemp;
    }
      if(inp.id == "parentLocation"){
        this.fieldTypes.push(inp.id)
          inp.isDynamicField =false;
          inp.required =false
          inp.template = this.siteTemp;
      }
      if(inp.id == "address"){
        this.fieldTypes.push(inp.id)
        inp.isDynamicField =false;
        inp.template = this.addressTemp;
        inp.required =false
      }
      if(inp.id == "position"){
        this.fieldTypes.push(inp.id)
        inp.isDynamicField =false;
        inp.template = this.positionTemp;
        inp.required =false
      }
      if (!this.staticFields.includes(inp.id)) {
        this.initializedData[inp.id] = inp.value;
      }
    })

    this.cd.detectChanges();
  }
  onOrgclose(){
    this.gatewayForm.get('organization').setValue('');
  }
}
