import { Component, OnInit, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'cl-timer-snack',
  templateUrl: './timer-snack.component.html',
  styleUrls: ['./timer-snack.component.scss']
})
export class TimerSnackComponent implements OnInit {
  timeInstance: any;
  timer: number;
  message: string;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.timer =  (data.timer) ? data.timer : 1000;
    this.message = (data.message) ? data.message :'refreshing in';
    this.timeInstance = setInterval(() => {
      this.updateTimer();
    }, 1000)
  }

  ngOnInit(): void {
  }

  updateTimer() {
    if (this.timer == 1) {
      clearInterval(this.timeInstance);
    } else {
      this.timer = this.timer - 1;
    }
  }

}
