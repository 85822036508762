import { Component, Input, OnInit } from '@angular/core';
import { Asset, AssetBasicInformationProperties, CdmField } from '@cl/@types/asset.type';
import { AssetService } from '@cl/asset/asset.service';
import { AssetUtilService } from '@cl/asset/asset-util.service';
import { FormateDatePipe } from '@cl/common/pipes/formate-date.pipe';
import * as _ from 'lodash';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { AssetsStateService } from '@cl/asset/asset-state.service';
@Component({
  selector: 'cl-details-summary',
  templateUrl: './details-summary.component.html',
  styleUrls: ['./details-summary.component.scss']
})
export class DetailsSummaryComponent implements OnInit {
  @Input('asset') assetObject: Asset;
  @Input() assetCurrentLocation:any;
  isCustodyAsset: boolean = false;
  cdmAllowedTypes = ['STRING', 'DATE', 'DECIMAL', 'ENUMERATION', 'INTEGER', 'BOOLEAN'];

  preDefinedColumns = ['name', 'externalId', 'type', 'imageURL', 'monitoredStatus', 'status', 'createdBy','createdAt','modifiedBy','modifiedAt'];
  userFields = [];
  systemFields = [];
  constructor(public datePipe : FormateDatePipe,
    private assetService: AssetService,
    private assetUtilService: AssetUtilService,
    private _dynamicFormService: DynamicFormService,
    private assetState: AssetsStateService
    ) { }

  ngOnInit(): void {
    console.log('ng init occurred');
    this.assetState.resetAction();
  }

  ngOnChanges() {
    if(this.assetObject?.properties?.parentEntityTypeKey.toLowerCase() === 'custodyasset'){
      this.isCustodyAsset = true;
    }
    if(this.assetObject?.cdmFields){
      const fields = this.assetObject.cdmFields.filter(cdmField => cdmField.isDisplayableOnDetails && !this.preDefinedColumns.includes(cdmField.name));
      let userFields = fields.filter(field => field.group?.toLowerCase() == 'user');
      let systemFields = fields.filter(field => field.group?.toLowerCase() == 'system');
      this.userFields = this._dynamicFormService.orderFields(userFields, 'order');
      this.systemFields = this._dynamicFormService.orderFields(systemFields, 'order');
    }
  }

  isStaticPropertyAvailable(id: string){
    let staticProperties : string[] = this.assetUtilService.assetStaticProperties;
    return !_.includes(staticProperties, id);
  }
  getExtendedAttributeValue(cdmField : CdmField, properties: AssetBasicInformationProperties){
    if(cdmField.isDisplayableOnDetails){
      if(this.cdmAllowedTypes.indexOf(cdmField?.type?.toUpperCase()) > -1){
        if(cdmField?.type?.toUpperCase()==='DATE'){
          let data = properties[cdmField.name];
          return this.datePipe.transform(data, 'default');
        } else {
          if(cdmField.name == 'organization') return properties['organizationName']
          return properties[cdmField.name];
        }
      }else{
        return '';
      }
    } else {
      return '';
    }
  }
}
