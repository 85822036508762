<div class="parcel-shipment">
    <form #form="ngForm" [formGroup]="parcelShipmentForm" action="">
        <div class="section-border">
            <div class="section-title">General Shipment Details</div>
            <div class="user-detail__item">
                <div class="form-label">Name <span class="mandatory">*</span></div>
                <input type="text"  (keypress)="restrictedCharactersOnly($event)" [pattern]="regularExpression" formControlName="name" class="basic-input" (change)="checkNames()"
                    (input)="nameErrorValidation=false" placeholder="Provide a unique name">
                <div *ngIf="(submitted && parcelShipmentForm.get('name').errors) || shipmentNameRequired" class="text-danger">
                    Shipment Name is required.
                </div>
                <div *ngIf="nameErrorValidation && !parcelShipmentForm.get('name').errors" class="text-danger">
                    {{nameErrorText}}
                </div>
                <div *ngIf="parcelShipmentForm.get('name').errors?.['pattern']"  class="text-danger" >
                    Please enter a valid Shipment name.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">External ID</div>
                <input type="text"  (keypress)="restrictedCharactersOnly($event)" [pattern]="regularExpression" formControlName="externalId" (change)="checkExternelID()"
                    (input)="externalIdErrorValidation=false" class="basic-input" placeholder="Enter a unique ID ">
                <div *ngIf="externalIdErrorValidation && !parcelShipmentForm.get('externalId').errors"
                    class="text-danger">
                    {{externalIdErrorText}}
                </div>
                <div *ngIf="parcelShipmentForm.get('externalId').errors?.['pattern']"  class="text-danger" >
                    Please enter a valid external Id.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Order Number</div>
                <input type="text"  (keypress)="restrictedCharactersOnly($event)" [pattern]="regularExpression" formControlName="orderNum" class="basic-input" placeholder="Enter order number">
                <div *ngIf="parcelShipmentForm.get('orderNum').errors?.['pattern']"  class="text-danger" >
                    Please enter a valid order number.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Shipper Name</div>
                <input class="basic-input"  [pattern]="regularExpression"  placeholder="Enter shipper name"
                    formControlName='shipperName'>
                <div *ngIf="parcelShipmentForm.get('shipperName').errors?.['pattern']"  class="text-danger" >
                    Please enter a valid shipper name.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Carrier Name <span class="mandatory">*</span></div>
                <div class="dropdown-input">
                    <input [matAutocomplete]="carrier" class="basic-input drp-search" placeholder="Select carrier"
                        formControlName="carrierCode" type="search"  (blur)="onCloseField('carrierCode')"/>
                    <mat-autocomplete #carrier="matAutocomplete"  (optionSelected)="onSelectField($event, 'carrierCode')" [displayWith]="displayFn" cdkScrollable>
                        <ng-container>
                            <mat-option *ngFor="
                          let cr of carrierCodesList
                            | search: parcelShipmentForm.value.carrierCode:'carrierName'
                        " [value]="cr">{{ cr.carrierName }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
                <div *ngIf="submitted && parcelShipmentForm.get('carrierCode').errors" class="text-danger">
                    Carrier Name is required.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Mode of Transport</div>
                <div>
                    <mat-select class="basic-input" placeholder="Select mode of transport"
                        formControlName="modeoftransport">
                        <ng-container>
                            <mat-option value="Parcel">Parcel</mat-option>
                            <mat-option value="Air">Air</mat-option>
                            <mat-option value="Multimodal">Multimodal</mat-option>
                            <mat-option value="Ocean / Marine">Ocean / Marine</mat-option>
                            <mat-option value="Rail">Rail</mat-option>
                            <mat-option value="Road">Road</mat-option>
                            <mat-option value="Road - FTL (Full Truck Load)">Road - FTL (Full Truck Load)</mat-option>
                            <mat-option value="Road - LTL (Less than Truck Load)">Road - LTL (Less than Truck Load)
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Customer Name</div>
                <input class="basic-input" [pattern]="regularExpression" placeholder="Enter Customer Name"
                    formControlName='custName'>
              <div *ngIf="parcelShipmentForm.get('custName').errors?.['pattern']"  class="text-danger" >
              Please enter a valid customer name.
            </div>
            </div>
            <div class="user-detail__item relative">
                <div class="form-label">Customer Code <i (click)="openHelpDialog()" class="fa fa-info-circle help-icon color4 text-base mr-1 cursor-pointer" title="Note: list of customer codes is visible to all users who can create/edit shipments. New customer codes will be added to list of values automatically. Customer codes are case insensitive"></i></div>
                <input [matAutocomplete]="auto1" placeholder="Start typing to search or add"    (blur)="onCloseCustField('custCode')" [pattern]="regularExpression" class="basic-input"
                    formControlName='custCode'>
                    <button class="add-org-icon add-button add-org" *ngIf="customerCodeAddEnable && this.parcelShipmentForm.value.custCode.length>2"  (click)="addCustomerCode()">
                        <span class="fa fa-plus" (click)="addCustomerCode()"></span>
                      </button>
                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectField($event, 'custCode')"  >
                    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!isLoading">
                        <mat-option *ngFor="let coustomer of coustomerCodes" [value]="coustomer">
                            <span>{{ coustomer }}</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
                <div
                *ngIf="customerCodeAddEnable && this.parcelShipmentForm.value.custCode.length>2"
                  class="text-danger"
                >
                Customer Code not found. Add value?
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Customer Order Reference</div>
                <input type="text"  (keypress)="restrictedCharactersOnly($event)" [pattern]="regularExpression" formControlName="custOrdRef" class="basic-input"
                    placeholder="Enter customer order reference">
                    <div *ngIf="parcelShipmentForm.get('custOrdRef').errors?.['pattern']"  class="text-danger" >
                        Please enter a valid customer order reference.
                    </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Recipient Name</div>
                <input type="text" (keypress)="restrictedCharactersOnly($event)"  [pattern]="regularExpression" formControlName="recipientName" class="basic-input" placeholder="Enter name">
                <div *ngIf="parcelShipmentForm.get('recipientName').errors?.['pattern']"  class="text-danger" >
                    Please enter a valid recipient name.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Organization<cl-org-info-icon-dialog></cl-org-info-icon-dialog></div>
                <div class="relative">
                    <mat-select
                      class="basic-input"
                      placeholder="Select organization"
                      formControlName="organization"
                    >
                      <ng-container>
                        <mat-option *ngFor="let org of orgList"
                        [value]="org.id">{{org.name}}</mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-icon  *ngIf="parcelShipmentForm.value.organization && !isOrgRestrictedUser" class="select-icon-close" (click)="onOrgclose()">close</mat-icon>
                  </div>
            </div>
        </div>
        <div class="card" *ngIf="dynamicInputFields.length > 0">
            <div class="card-header px-2" (click)="togglers.additionalFields = !togglers.additionalFields">
              Additional Shipment Details
              <i
                class="fa fa-chevron-right float-right mt-1 cursor-pointer"
                [ngClass]="{
                  'rotate-Icon': togglers.additionalFields
                }"
              ></i>
            </div>
            <div
              class="card-body bg-white p-0"
              [ngClass]="togglers.additionalFields ? '' : 'hidden'"
            >
              <cl-dynamic-form-renderer [columnMode]="'Two'" [inputFields]="dynamicInputFields" #dynamicForm></cl-dynamic-form-renderer>
            </div>
          </div>
        <div class="section-border">
            <div class="section-title">Direct to Residence? <span class="mandatory">*</span></div>
            <div class="user-detail__item">
                <mat-radio-group class="foz12 capitalize" aria-label="Select an option"
                    formControlName="directToResidence">
                    <mat-radio-button value="true">Yes</mat-radio-button>
                    <mat-radio-button value="false">No</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="submitted && parcelShipmentForm.get('directToResidence').errors" class="text-danger">
                    Direct to residence is required.
                </div>
            </div>
        </div>
        <div class="section-border">
            <div class="section-title">Rules and Monitoring Options</div>
            <div class="user-detail__item">
                <div class="form-label">Rule Set</div>
                <div class="dropdown-input">
                    <input [matAutocomplete]="ruleSet" class="basic-input drp-search" type="search"
                        placeholder="Select rule set" formControlName='rulesetId'  (blur)="onCloseField('rulesetId')">
                    <mat-autocomplete #ruleSet="matAutocomplete" [displayWith]="displayFn"  (optionSelected)="onSelectField($event, 'rulesetId')">
                        <ng-container>
                            <mat-option *ngFor="let rs of ruleSets | search: parcelShipmentForm.value.rulesetId;"
                                [value]="rs">{{rs.name}}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Device Profile</div>
                <div class="dropdown-input">
                    <input [matAutocomplete]="sensor" class="basic-input drp-search " type="search"
                        placeholder="Select device profile" formControlName='sensorProfile' (blur)="onCloseField('sensorProfile')">
                    <mat-autocomplete #sensor="matAutocomplete" (optionSelected)="onSelectField($event, 'sensorProfile')">
                        <ng-container>
                            <mat-option
                                *ngFor="let s of activeSensorProfiles | search: parcelShipmentForm.value.sensorProfile;"
                                [value]="s">{{s}}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
        <div class="section-border">
            <div class="section-title">Origin and Destination Details</div>
            <div class="user-detail__item full-width">
                <mat-radio-group class="foz12 capitalize" formControlName="direction" aria-label="Select an option">
                    <mat-radio-button value="Outbound">Outbound</mat-radio-button>
                    <mat-radio-button value="Inbound">Inbound</mat-radio-button>
                    <mat-radio-button value="Return">Return</mat-radio-button>
                </mat-radio-group>
                <div class="row pt-2">
                    <div class="col-6 c-border-left">
                        <div class="user-detail__item full-width pl-0 h-28">
                            <div class="form-label" *ngIf="parcelShipmentForm.value.isExistingOrigin">Origin Area or
                                Address</div>
                            <div class="form-label" *ngIf="!parcelShipmentForm.value.isExistingOrigin">Origin Address or
                                Area</div>
                            <div class="form-check form-switch">
                                <input class="form-check-input toggler" (change)="resetDynamicRoutes('source')"
                                    formControlName="isExistingOrigin" type="checkbox" id="flexSwitchCheckChecked">
                                <label class="form-check-label" for="flexSwitchCheckChecked">Use pre-loaded
                                    origin</label>
                            </div>
                            <div class="dropdown-input" *ngIf="parcelShipmentForm.value.isExistingOrigin">
                                <input [matAutocomplete]="fromA" class="basic-input" placeholder="Select origin area" [(ngModel)]="source"
                                [ngModelOptions]="{standalone: true}" (blur)="onSourcechange('source')"  (input)="onChangeRoute('new')">
                                <mat-autocomplete #fromA="matAutocomplete" [displayWith]="displayFn"
                                    (optionSelected)="selectedOption($event, 'source')">
                                    <ng-container>
                                        <mat-option
                                            *ngFor="let area of areas | search: source:'name';"
                                            [value]="area">{{area.name}}
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </div>
                            <input placeholder="search for location" autocorrect="off" autocapitalize="off"
                                spellcheck="off" type="text" (blur)="onSourcechange('sourceArea')" (keypress)="allowAlphaNumericOnly($event)" [(ngModel)]="sourceAreaName"  
                                [ngModelOptions]="{standalone: true}"  class="basic-input" ngx-google-places-autocomplete #searcha (onAddressChange)="handleAddressChange($event, 'sourceArea')" 
                                *ngIf="!parcelShipmentForm.value.isExistingOrigin" (input)="onChangeRoute('new')"
                                />
                                <p class="text-danger m-0" *ngIf="submitted && 
                                ((this.parcelShipmentForm.value.isExistingOrigin && 
                                !source?.name && 
                                !this.parcelShipmentForm.value.sourceArea) || 
                                (!this.parcelShipmentForm.value.isExistingOrigin && 
                                !this.parcelShipmentForm.value.sourceArea)) && !this.parcelShipmentForm.value.routeId
                                ">
                                Please enter Origin
                              </p>                               </div>
                        <div class="user-detail__item full-width pl-0 h-28">
                            <div class="form-label">Destination Address or Area</div>
                            <div class="form-check form-switch">
                                <input class="form-check-input toggler" formControlName="isExistingDestination" (change)="resetDynamicRoutes('destination')" 
                                    type="checkbox" id="existingDestination">
                                <label class="form-check-label" for="existingDestination"> Use pre-loaded
                                    destination</label>
                            </div>
                            <div class="dropdown-input" *ngIf="parcelShipmentForm.value.isExistingDestination">
                                <input [matAutocomplete]="desA" class="basic-input"
                                    placeholder="Select destination area" [(ngModel)]="destination" 
                                    [ngModelOptions]="{standalone: true}" (blur)="onSourcechange('destination')"  (input)="onChangeRoute('new')">
                                <mat-autocomplete #desA="matAutocomplete"
                                    (optionSelected)="selectedOption($event, 'destination')" [displayWith]="displayFn">
                                    <ng-container>
                                        <mat-option
                                            *ngFor="let area of areas | search: destination:'name';"
                                            [value]="area">{{area.name}}
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </div>
                            <input placeholder="search for location" autocorrect="off" autocapitalize="off" 
                                spellcheck="off" type="text" (keypress)="allowAlphaNumericOnly($event)" [(ngModel)]="destinationAreaName" 
                                [ngModelOptions]="{standalone: true}" (blur)="onSourcechange('destinationArea')"  class="basic-input" ngx-google-places-autocomplete #searchb (onAddressChange)="handleAddressChange($event, 'destinationArea')"
                                *ngIf="!parcelShipmentForm.value.isExistingDestination" (input)="onChangeRoute('new')"
                               />
                               <p class="text-danger m-0" *ngIf="submitted && 
                               ((this.parcelShipmentForm.value.isExistingDestination && 
                               !destination?.name && 
                               !this.parcelShipmentForm.value.destinationArea) || 
                               (!this.parcelShipmentForm.value.isExistingDestination && 
                               !this.parcelShipmentForm.value.destinationArea)) && !this.parcelShipmentForm.value.routeId
                               ">
                               Please enter Destination
                             </p>                            
                        </div>
                        <div class="oR">
                            <span> OR </span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="user-detail__item pl-2 mt--72 full-width">
                            <div class="form-label">Route</div>
                            <div class="dropdown-input">
                                <input [matAutocomplete]="exRoute"  type="search" (input)="onChangeRoute('existing');getRoutesbySearch()"
                                    class="basic-input drp-search" placeholder="Select route" formControlName='routeId'>
                                <mat-autocomplete #exRoute="matAutocomplete" [displayWith]="displayFn"
                                     (closed)="onCloseRoute()"
                                     (optionSelected)="selectedOption($event, 'route')">
                                    <mat-option *ngIf="isLoading" class="is-loading"
                                    >Loading...</mat-option
                                  >
                                  <mat-option
                                    *ngIf="!existingRoutes.length && !isLoading"
                                    class="is-loading"
                                    >No routes found</mat-option
                                  >
                                    <ng-container *ngIf="!isLoading">
                                        <mat-option
                                            *ngFor="let route of existingRoutes"
                                            [value]="route">{{route.name}}
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="section-border">
            <div class="section-title">Scheduling</div>
            <div class="user-detail__item">
                <div class="form-label">Planned Departure </div>
                <cl-custom-date-picker
                [dateRange]="departureDateRange"
                [displayFormat]="dateDisplayFormat"
                (dateChanged)="depDateUpdated($event, 'plannedDeparture')"
                [ranges]="null"
                [singleDatePicker]="true"
                [datePickerClass]="datePickerClass"
                [opened]="datePickerOpenedDeparture"
              ></cl-custom-date-picker>
                <div *ngIf="submitted && parcelShipmentForm.get('plannedDeparture').errors" class="text-danger">
                    Planned Departure is required.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Planned Arrival </div>
                <cl-custom-date-picker
                [dateRange]="arrivalDateRange"
                [disablePreviousdates]="preventBeforeArrivalDate"
                [displayFormat]="dateDisplayFormat"
                (dateChanged)="depDateUpdated($event, 'plannedArrival')"
                [ranges]="null"
                [singleDatePicker]="true"
                [datePickerClass]="datePickerClass"
                [opened]="datePickerOpenedArrival"
                >
              </cl-custom-date-picker>
                <div *ngIf="submitted && parcelShipmentForm.get('plannedArrival').errors" class="text-danger">
                    Planned Arrival is required.
                </div>
            </div>
            <div class="user-detail__item">
                <div class="form-label">Planned ETA</div>
                <div class="fs-13 w-break">estimate is provided based on shipment details
                    {{convertDateforETA(dateforETA)}}</div>
            </div>
        </div>
        <div class="section-border">
            <div class="section-title">Add Assets <span class="text-danger font-medium ml-4"
                    *ngIf="submitted && parcelShipTaggedAssetList.length == 0">At least one asset is required</span>
            </div>
            <div class="px-7">
                <div class="card">
                    <div class="card-header" (click)="toggler('categories')">
                        Asset Types (<span *ngIf="selectedCategiries.length != 0">Selected
                            {{selectedCategiries?.length}} of </span>{{filteredAssetTypes.length}}) <span
                            class="mandatory">*</span>
                        <i class="fa fa-chevron-right float-right mt-1" [ngClass]="{
                            'rotate-Icon': parcelShipmentForm.value.showCategories == true
                          }"></i>
                    </div>
                    <div class="summary-section-bg p-0" *ngIf="parcelShipmentForm.value.showCategories">
                        <div class="user-detail__item full-width">
                            <mat-radio-group class="foz12 capitalize" formControlName="assetType"
                                aria-label="Select an option" (change)="onChangeMonitorAssetType()">
                                <mat-radio-button value="MONITORED">Monitored</mat-radio-button>
                                <mat-radio-button value="UNMONITORED">Unmonitored</mat-radio-button>
                                <mat-radio-button value="ALL">All</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="!assetTypesLoading">
                            <div class="user-detail__item p-0 w-47">
                                <cl-list-search [placeholder]="'Search types'"
                                    (search)="updateFilter($event, 'assetTypes')">
                                </cl-list-search>
                            </div>
                            <div class="card-body-scroll">
                                <div class="user-detail__item checkbox">
                                    <div class="user-roles__item"
                                        *ngFor="let category of filteredAssetTypes | search:searchAssetTypes">
                                        <mat-checkbox class="foz12 asset-type-checkbox capitalize mb-1 mt-0"
                                            (change)="getInventoryAssets($event,category, null, false)"
                                            [(ngModel)]="category.checked" matTooltipClass="tooltip-styles"
                                            matTooltip="{{ category?.properties?.description }}"
                                            matTooltipClass="tooltip-styles" [disabled]="
                                            parcelShipmentForm.value.assetType === 'ALL'
                                            ? category.value === 0
                                            : category[parcelShipmentForm.value.assetType] === 0"
                                            [ngModelOptions]="{standalone: true}">
                                            <label class="user-roles__item__label">
                                                <span class="asset-key">
                                                    {{ category.properties.name }}</span>
                                                <span class="count">
                                                    ({{
                                                    parcelShipmentForm.value.assetType === "ALL"
                                                    ? category.value
                                                    : category[parcelShipmentForm.value.assetType]
                                                    }})</span>
                                            </label>
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row items-center justify-center" *ngIf="assetTypesLoading">
                            <button class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button bcolor9"
                                [disabled]="assetTypesLoading" (click)="getInventoryAssets(null, null, null, true)">
                                <span class="mat-button__ripple"></span>
                                <span class="mat-button__label color4 color9">Loading...</span>
                                <span *ngIf="assetTypesLoading"><i
                                        class="fa fa-circle-o-notch fa-1x fa-pulse color4"></i></span>
                            </button>
                        </div>
                        <div class="mb-2" *ngIf="assetTypesLoading"></div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" (click)="toggler('selected-assets')">
                        <span> Select Assets (<span *ngIf="selectedAssets.length != 0">Selected
                                {{selectedAssets.length}} of </span>{{totalAssets}})</span>
                        <i class="fa fa-chevron-right float-right mt-1" [ngClass]="{
                            'rotate-Icon': parcelShipmentForm.value.showAssets == true
                          }"></i>
                    </div>
                    <div class="summary-section-bg p-0 px-1" *ngIf="parcelShipmentForm.value.showAssets">
                        <div class="user-detail__item px-0 w-47 pt-0">
                            <cl-list-search [placeholder]="'Search assets'"
                                (search)="getInventoryAssets(null,null,$event,false)">
                            </cl-list-search>
                        </div>
                        <div class="user-detail__item pt-1 w-53">
                            <div class="float-right">
                                <button [disabled]="selectedAssets.length == 0"
                                    [ngClass]="[selectedAssets.length == 0 ? 'color37 bcolor36 bgcolor38' : 'color4 bcolor4 bgcolor32']"
                                    class="p-4-10 border-general br2 mr-2" (click)="addToShipment()">
                                    <i class="fa" [ngClass]="'fa-plus-circle'"></i> Add to Shipment</button>
                            </div>
                        </div>
                        <div class="user-detail__item py-0" *ngIf="inventoryAssets.length != 0">
                            <mat-checkbox class="foz12 capitalize" [checked]="allComplete"
                                (change)="onAllInventoryAssets($event.checked)" [indeterminate]="someComplete()">
                                <span class="font-12-normal color4" matTooltipClass="tooltip-styles">
                                    Select All
                                </span>
                            </mat-checkbox>
                        </div>
                        <div class="card-body-scroll">
                            <div class="user-detail__item checkbox pt-0">
                                <div class="user-roles__item w-33" *ngFor="let asset of inventoryAssets">
                                    <div class="asset-checkbox ml-0" *ngIf="asset.isAdded" title="{{asset?.assetName || asset?.name}}">
                                        <span class="checked ellipsis"><i class="fa" [ngClass]="'fa-check-circle'"></i>
                                            {{asset?.assetName || asset?.name}}</span>
                                    </div>
                                    <div class="asset-checkbox" *ngIf="!asset.isAdded">
                                        <mat-checkbox class="foz12 capitalize mb-1 mt-0"
                                            (change)="onChangeAssets($event,asset)" [(ngModel)]="asset.checked"
                                            [ngModelOptions]="{standalone: true}">
                                            <span class="font-12-normal" matTooltip="{{ asset?.name || asset?.name }}"
                                                matTooltipClass="tooltip-styles">
                                                {{asset?.assetName || asset?.name}}
                                            </span>
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-row items-center justify-center"
                                *ngIf="totalAssets > 50 || assetsLoading">
                                <button class="mat-button mat-stroked-button bcolor4 border p-2 load-next-button"
                                    [disabled]="totalAssets <= inventoryAssets.length"
                                    [ngClass]="{'bcolor9': assetsLoading || totalAssets <= inventoryAssets.length }"
                                    (click)="getInventoryAssets(null, null, null, true)">
                                    <span class="mat-button__ripple"></span>
                                    <span class="mat-button__label color4"
                                        [ngClass]="{'color9': assetsLoading || totalAssets <= inventoryAssets.length}">load
                                        next
                                        50</span>
                                    <span *ngIf="assetsLoading"><i
                                            class="fa fa-circle-o-notch fa-1x fa-pulse color4"></i></span>
                                </button>
                            </div>
                            <div class="mb-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-7" *ngIf="parcelShipTaggedAssetList.length != 0">
                <div class="card">
                    <div class="card-header" (click)="toggler('included-shipments')">
                        Included in shipments ({{parcelShipTaggedAssetList.length}})
                        <i class="fa fa-chevron-right float-right mt-1" [ngClass]="{
                            'rotate-Icon': parcelShipmentForm.value.showIncludedAssets === true
                          }"></i>
                    </div>
                    <div class="summary-section-bg px-2 pt-2 card-body-scroll"
                        *ngIf="parcelShipmentForm.value.showIncludedAssets">
                        <span (click)="deleteIncludedAsset('deleteAll')" class="pointer color4 fs12"><i class="fa"
                                [ngClass]="'fa-trash'"></i> Remove All
                        </span>
                        <div *ngFor="let item of parcelShipTaggedAssetList">

                            <div class="included-shipments mt-1" [ngClass]="{
                            'border-valid': item.trackingNumber || !finishAssetsAdding,
                            'border-invalid': !item.trackingNumber && finishAssetsAdding
                          }">

                                <div class="list d-flex flex-row align-items-center">
                                    <div>
                                        <div> {{item.assetName || item.name}} <br>
                                            ID: {{item.externalId}} <br>
                                        </div>
                                    </div>
                                    <div *ngIf="!item.isEdit" class="pl-3">
                                        Device ID: <span class="truncate inline-block w-36 -mb-1.5" title="{{displaySensorIds(item.sensorId)}}">{{displaySensorIds(item.sensorId)}} </span><br>
                                        Tracking ID &nbsp;: {{item.trackingNumber}}
                                    </div>
                                    <div *ngIf="item.isEdit">
                                        <div *ngIf="item.carrierAssetType == 'MONITORED'" class="pb-1">
                                            Device ID : <span class="ml-3 truncate inline-block w-36 -mb-1.5" title="{{displaySensorIds(item.sensorId)}}">{{displaySensorIds(item.sensorId)}} </span>
                                        </div>
                                        <div class="user-detail__item full-width pt-0 px-0"
                                            *ngIf="item.carrierAssetType == 'UNMONITORED'">
                                            <div class="tracking-flex">
                                                <label>Device ID: </label>
                                                <input type="search" [matAutocomplete]="auto" name="sensorId"
                                                    class="basic-input inp-search" [(ngModel)]="item.sensorId" (keypress)="allowAlphaNumericOnly($event)"
                                                    placeholder="Device ID " (input)="getSensors(item.sensorId)"
                                                    [ngModelOptions]="{standalone: true}">
                                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                                    <mat-option *ngIf="isLoading" class="is-loading">Loading...
                                                    </mat-option>
                                                    <ng-container *ngIf="!isLoading">
                                                        <mat-option *ngFor="let sensor of sensorsList"
                                                            [value]="sensor.id">
                                                            <span>{{ sensor.id }}</span>
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-autocomplete>
                                            </div>
                                        </div>
                                        <div class="user-detail__item full-width pt-0 px-0">
                                            <div class="tracking-flex">
                                                <label>Tracking ID:<span class="mandatory">*</span></label>
                                                <input type="search" class="basic-input inp-search"
                                                    placeholder="Enter tracking ID" [(ngModel)]="item.trackingNumber"
                                                    [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button (click)="editIncludedAsset(item)">
                                            <i class="fa fa-pencil" *ngIf="!item.isEdit"></i>
                                            <i class="fa fa-check text-primary"
                                                *ngIf="item.isEdit && item.trackingNumber"></i>
                                        </button> &nbsp;
                                        <button (click)="deleteIncludedAsset(item)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-detail__item full-width" *ngIf="parcelShipTaggedAssetList.length != 0">
                <button class="p-4-10 border-general br2 mr-2 color4 bcolor4 bgcolor32"
                    (click)="onFinish()">Finish</button>
            </div>
        </div>
        <!-- <div class="card">
            <div class="card-header px-2" (click)="toggler('tags')">
                Tag this Shipment
                <i class="fa fa-chevron-right float-right mt-1" [ngClass]="{
                        'rotate-Icon': parcelShipmentForm.value.showTags == true
                      }"></i>
            </div>
            <div class="card-body bg-white px-2" *ngIf="parcelShipmentForm.value.showTags">
                <cl-new-shipment-tags
          [selectedTags]="shipment.tags"
          [shipmentTagsData]="shipmentData"
          (sendDataToapply)="tagsfun($event)"
          *ngIf="shipmentData != undefined || draftval == true || shipmentID == undefined"
        ></cl-new-shipment-tags>
            </div>
        </div> -->
        <div class="card">
            <div class="card-header px-2" (click)="toggler('notes')">
                Notes
                <i class="fa fa-chevron-right float-right mt-1" [ngClass]="{
                    'rotate-Icon': parcelShipmentForm.value.showNotes == true
                  }"></i>
            </div>
            <div class="card-body bg-white p-0" *ngIf="parcelShipmentForm.value.showNotes">
                <div class="user-detail__item notes">
                    <div class="form-label">Add a Note</div>
                    <textarea name="notes" maxlength="5000" formControlName="notes" class="basic-input full-width"
                        id="notes" rows="4" [pattern]="regularExpression"></textarea>
                        <div class="flex">
                            <div
                            *ngIf="parcelShipmentForm.get('notes').errors?.['pattern']"
                            class="text-danger"
                            >
                            Please enter a valid notes.
                            </div>
                            <div class="ml-auto">
                                <span
                                    [ngClass]="{
                                    'text-danger': parcelShipmentForm.value.notes.length == 5000
                                    }"
                                    >{{ parcelShipmentForm.value.notes.length }}</span
                                >/5000
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </form>
</div>
