<div class="cl-side-panel bcolor9 border flex flex-col">
  <section class="cl-side-panel-title side-panel-header-bg">
    <div class="grow flex items-center">
      <img src="assets/svgs/circle-icons-white/inventory.svg" />
      <span class="black font-bold">SKU - Summary</span>
    </div>
    <i class="fa fa-times-circle-o mr-4" (click)="closePanel()"></i>
  </section>
  <section class="cl-side-panel-content pl-2 pr-2 pb-1.5 pt-1.5">
    <ng-container *ngIf="response">
      <ng-container *ngFor="let cdmField of attributes">
        <div
          class="flex fs14 color16 mt-1"
          *ngIf="
            cdmField?.displayable &&
            getExtendedAttributeValue(cdmField, skuObject)
          "
        >
          <div
            class="basis-2/5 font-bold"
            [textContent]="cdmField?.displayLabel"
          ></div>
          <div
            class="basis-3/5"
            [textContent]="getExtendedAttributeValue(cdmField, skuObject)"
          ></div>
        </div>
      </ng-container>
    </ng-container>
    <div class="flex fs14 color16 mt-1" *ngIf="response">
      <div class="basis-2/5 font-bold">Created By</div>
      <div class="basis-3/5">{{ skuObject.createdBy }}</div>
    </div>
    <div class="flex fs14 color16 mt-1" *ngIf="response">
      <div class="basis-2/5 font-bold">Created At</div>
      <div class="basis-3/5">
        {{ skuObject.createdAt | formateDate : "default" }}
      </div>
    </div>
    <div class="flex fs14 color16 mt-1" *ngIf="response">
      <div class="basis-2/5 font-bold">Modified By</div>
      <div class="basis-3/5">{{ skuObject.modifiedBy }}</div>
    </div>
    <div class="flex fs14 color16 mt-1" *ngIf="response">
      <div class="basis-2/5 font-bold">Modified At</div>
      <div class="basis-3/5">
        {{ skuObject.modifiedAt | formateDate : "default" }}
      </div>
    </div>
  </section>
  <div class="flex justify-end fixedbtn">
    <button class="panel-button color4 bgcolor2 bcolor4 bw-1 m-3 z-10">
      <a href="#/skudetails/{{ skuId }}" class="color4"
        >SKU Details
        <i class="fa fa-arrow-right ml-1 color4"></i>
      </a>
    </button>
  </div>
</div>
