<div class="cl-sensory-summary">
  <div class="row">
    <div class="page-title" [textContent]="sensorObject?.id"></div>
  </div>
  <div class="row align-items-start mt-2" *ngIf="sensorObject.isTracker !== undefined && sensorObject.isTracker !== null && !sensorObject.isTracker">
    <div class="col-4 pl0">
      <img [src]="sensorObject?.imageUrl" (error)="onImgError($event)" class="img-fluid max-h-[200px]"/>
    </div>
    <div class="col-8 summary-props">
      <div class="row align-items-start">
        <div class="col-6 pl0">
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Name:</span>&nbsp;<span class="basis-1/2" style="word-break:break-word" matTooltip="{{ sensorObject?.name }}" matTooltipClass="tooltip-styles" [textContent]="sensorObject?.name | truncate:20"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Zone:</span>&nbsp;<span class="basis-1/2" style="word-break:break-word" [textContent]="sensorObject?.asset?.zoneName"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Bound Asset:</span>&nbsp;<span class="basis-1/2" style="word-break:break-word"><a *ngIf="sensorObject?.asset?.id" href="javascript:void(0)" [routerLink]="'/assetdetails/' + sensorObject?.asset?.id" [textContent]="sensorObject?.asset?.name"></a></span>
          </div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Model Number:</span>&nbsp;<span class="basis-1/2" style="word-break:break-word"[textContent]="sensorObject?.model"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Model Number:</span>&nbsp;<span class="basis-1/2" style="word-break:break-word"[textContent]="sensorObject?.model"></span></div>
      <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Organization:</span>&nbsp;<span class="basis-1/2" style="word-break:break-word"[textContent]="sensorObject?.organizationName"></span></div>
      <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Catalog Type:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.parentEntityTypeKey| titlecase"></span></div>
    </div>
        <div class="col-6 pl0">
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Status:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.status"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Last Seen:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.asset?.lastSeen| date:'MMM d y h:mm:ss a'"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">GW:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.gatewayName"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Owner:</span>&nbsp;<span class="basis-1/2" style="word-break:break-word"[textContent]="sensorObject?.owner"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Type:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.type"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Sensor Type:</span>&nbsp;<span  class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.entityType | titlecase"></span></div>
          </div>
      </div>
      <div class="row align-items-start"> 
          <div class="col-6 pl0" *ngFor="let cdmField of sensorObject?.cdmFields | sortByOrder">
          <div class="mb-2 flex">
            <span class="text-bold basis-1/3"style="word-break:break-word" [textContent]="cdmField?.displayLabel"></span>:&nbsp;
            <span class="basis-1/2"style="word-break:break-word"[textContent]="getExtendedAttributeValue(cdmField, sensorObject?.properties)"></span>
          </div>
        </div>
        <div class="col-6 pl0">
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Created At:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="datePipe.transform(sensorObject?.properties?.createdAt)"></span></div>
        </div>
        <div class="col-6 pl0">
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Modified At:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="datePipe.transform(sensorObject?.properties?.modifiedAt)"></span></div>
        </div>
        <div class="col-6 pl0">
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Created By:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.createdBy"></span></div>
        </div>
        <div class="col-6 pl0">
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Modified By:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.modifiedBy"></span></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-start mt-2" *ngIf="sensorObject.isTracker">
    <div class="col-4 pl0">
      <img [src]="sensorObject?.imageUrl" (error)="onImgError($event)" class="img-fluid max-h-[200px]"/>
    </div>
    <div class="col-8 summary-props">
      <div class="row align-items-start">
        <div class="col-6 pl0">
          <div class="mb-1 ellipsis w-full flex"><span class="text-bold basis-1/3"style="word-break:break-word">Name:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word" matTooltip="{{ sensorObject?.name }}" matTooltipClass="tooltip-styles" [textContent]="sensorObject?.name | truncate:20">hjdhfjdsdhfdjsfhsdfhdsfh hljhhhhhhhhhhhhhhhhhhhhh</span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Bound Asset:</span>&nbsp;
            <a href="javascript:void(0)" class="basis-1/2"[routerLink]="'/assetdetails/' + sensorObject?.asset?.id"style="word-break:break-word" [textContent]="sensorObject?.asset?.name" [title]="sensorObject?.asset?.name"></a>
          </div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Type:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.type"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Vendor:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.vendor"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Model:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.model"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Organization:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.organizationName"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Catalog Type:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.parentEntityTypeKey| titlecase"></span></div>
          </div>

        <div class="col-6 pl0">
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">SKU:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.sku"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">FW Version:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.fwVersion"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Device Profile:</span>&nbsp;<a class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.eventProfileName" [title]="sensorObject?.eventProfileName" [routerLink]="['/deviceprofiles', {name:sensorObject?.eventProfileName}]"></a></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Account:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word" [textContent]="sensorObject?.accountName" [title]="sensorObject?.accountName"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Status:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.status"></span></div>
          <div class="mb-2 flex" *ngIf="sensorObject?.watchListStatus"><span class="text-bold basis-1/3"style="word-break:break-word">Watch Status:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.watchListStatus"></span></div>
          <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Tracker Type:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.entityType | titlecase"></span></div>  
        </div>
    </div>
    <div class="row align-items-start">
      <div class="col-6 pl0" *ngFor="let cdmField of sensorObject?.cdmFields">
        <div class="mb-2 flex">
          <span class="text-bold basis-1/3"style="word-break:break-word" [textContent]="cdmField?.displayLabel"></span>:&nbsp;
          <span class="basis-1/2"style="word-break:break-word"[textContent]="getExtendedAttributeValue(cdmField, sensorObject?.properties)"></span>
        </div>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col-6 pl0">
        <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Created At:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="datePipe.transform(sensorObject?.properties?.createdAt)"></span></div>
        <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Modified At:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="datePipe.transform(sensorObject?.properties?.modifiedAt)"></span></div>
        </div>
      <div class="col-6 pl0">
        <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Created By:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.createdBy"></span></div>
        <div class="mb-2 flex"><span class="text-bold basis-1/3"style="word-break:break-word">Modified By:</span>&nbsp;<span class="basis-1/2"style="word-break:break-word"[textContent]="sensorObject?.properties?.modifiedBy"></span></div>
        </div>
    </div>
  </div>
  </div>
</div>
