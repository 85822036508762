import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { Sensor } from 'projects/clf-share/src/types/sensor.type';


@Injectable({
  providedIn: 'root',
})

export class ExtendedCatalogAttributeService {
  constructor(
    private http: HttpClient,
    private apiProvider: ApiProviderService
  ) {}


  // get list of extended columns

  getCatalogExtendedColumns(catalogName: string): Promise<any> {
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/catalog/${catalogName}`
    );
    return firstValueFrom(obs$);
  }


  async getCatalogExtendedMultiColumns(catalog:string,withFields = true){
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/catalog/subtype/${catalog}?withFields=${withFields}`
    );
    return firstValueFrom(obs$);
    // return firstValueFrom(of(AssetAttributesList))
  }

  async getCatalogExtendedDeviceColumns(catalog:string,withFields, catalogType){
    const obs$ = this.http.get<any>(
      `${this.apiProvider.getAPIUrl('clfgraphapp')}api/graph/catalog/subtype/${catalog}?withFields=${withFields}&catalogType=${catalogType}`
    );
    return firstValueFrom(obs$);
    // return firstValueFrom(of(AssetAttributesList))
  }


}
