import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cl-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})
export class SidePanelComponent implements OnInit {
  @Output() closePanel = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  closeSidePanel() {
    this.closePanel.emit();
  }
}
