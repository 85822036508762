import { DtAnalysis, MenuGroup, SearchQueryRequest } from "@cl/models";
import { LastNavigatorAction, NavigatorLoadingStatus, NavigatorMapDetails } from "./navigator.state.model";

export class NavigatorLoadDtAnalysis {
  static readonly type = "[Navigator] NavigatorLoadDtAnalysis";
  constructor(public readonly dtId: string) {}
}

export class NavigatorUpdateCards {
  static readonly type = "[Navigator] NavigatorUpdateCards";
  constructor(public readonly dtAnalysis: DtAnalysis) {}
}

export class NavigatorLoadDts {
  static readonly type = "[Navigator] NavigatorLoadDts";
}

export class NavigatorLoadFilterConfig {
  static readonly type = "[Navigator] NavigatorLoadFilterConfig";
}

export class NavigatorUpdateFilterEsQuery {
  static readonly type = "[Navigator] NavigatorUpdateFilterEsQuery";
  constructor(public readonly searchRequest: SearchQueryRequest) {}
}

export class NavigatorUpdateLoadingStatus {
  static readonly type = "[Navigator] NavigatorUpdateLoadingStatus";
  constructor(public readonly loadingStatus: NavigatorLoadingStatus) {}
}

export class NavigatorUpdateMapDetails {
  static readonly type = "[Navigator] NavigatorUpdateMapDetails";
  constructor(public readonly mapDetails: NavigatorMapDetails, public readonly assetId?: any,
    public readonly locationId?: any,public readonly shipmentId?: any) {}
}

export class NavigatorResetMap {
  static readonly type = "[Navigator] NavigatorResetMap";
}

export class NavigatorUpdateHeaderMenu {
  static readonly type = "[Navigator] NavigatorUpdateHeaderMenu";
  constructor(public readonly menuGroup: MenuGroup[]) {}
}

export class NavigatorFilteredNodes {
  static readonly type = "[Navigator] NavigatorFilteredNodes";
  constructor(public readonly filteredNodes: number) {}
}

export class NavigatorLastAction {
  static readonly type = "[Navigator] NavigatorLastAction";
  constructor(public readonly lastAction: LastNavigatorAction) {}
}

export class NavigatorSetMapCenter {
  static readonly type = "[Navigator] NavigatorSetMapCenter";
}
