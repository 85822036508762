<div class="personal-details-container">
    <div class="personal-details-title">Personal Details</div>
    <div class="personal-details-container__content" *ngIf="editDisabled">
        <div class="personal-detail-item">
            <div class="profile-logo">{{getNameInitials()}}</div>
            <div class="edit-profile">
                <a (click)="enableFormEdit()">Edit Profile</a>
            </div>
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">First and Last Name</div>
            <div class="personal-detail-item__value">{{currentUser?.name}}</div>
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">User ID</div>
            <div class="personal-detail-item__value">{{currentUser?.id}}</div>
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">Email</div>
            <div class="personal-detail-item__value">{{getEmail()}}</div>
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">Phone Number (for SMS alerts)</div>
            <div class="personal-detail-item__value">{{getPhoneNumber()}}</div>
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">Time Zone</div>
            <div class="personal-detail-item__value">{{currentUser?.timezone}}</div>
        </div>

        <div class="personal-detail-item">
            <div class="icon">
                <i class="fa fa-times-circle-o red" *ngIf="!currentUser?.properties.isoptedforsms"></i>
                <i class="fa fa-check-circle-o green" *ngIf="currentUser?.properties.isoptedforsms"></i>
            </div>
            <div class="opt-in-sms">Opt-in for SMS</div>
        </div>
    </div>

    <div class="personal-details-container__content" *ngIf="!editDisabled">
        <div class="personal-detail-item">
            <div class="profile-logo">{{getNameInitials()}}</div>
        </div>
        <form [formGroup]="personalDetailForm">
        <div class="personal-detail-item">
            <div class="personal-detail-item__title">First and Last Name <span class="red">*</span></div>
            <input type="text" class="basic-input" placeholder="First & Last Name" formControlName="name">
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">User ID</div>
            <input type="text" class="basic-input disabled" placeholder="UserId" formControlName="id">
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">Email <span class="red">*</span></div>
            <input type="email" class="basic-input" placeholder="Primary Email" formControlName="email">
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">Phone Number (for SMS alerts)</div>
            <div class="phone-number-container">
                <div class="country-code">
                    <input type="text" class="basic-input" (focus)="openDropDoen=true" placeholder="Code" formControlName="countryCode">
                    <i class="fa fa-solid fa-caret-down dropdown-icon"></i>
                    <ul class="type-ahead-suggestions dropdown-menu" [ngClass]="{open: openDropDoen}">
                      <li class="type-ahead-suggestions__item" (click)="setCountryCode(code)" *ngFor="let code of filteredCodeList">{{code.name+'('+code.Iso+')'}}</li>
                    </ul>
                </div>
                <div class="phone-number">
                    <input type="text" class="basic-input" placeholder="Phone number" formControlName="phoneNumber">
                    <span class="valiadtion-message" *ngIf="personalDetailForm.controls['phoneNumber'].getError('pattern')">Please enter valid phone number</span>
                </div>
            </div>
        </div>

        <div class="personal-detail-item">
            <mat-checkbox formControlName="optInEnabled" >
                <label class="ml10 user-notification__label">Opt-in for SMS</label>
            </mat-checkbox>
        </div>

        <div class="personal-detail-item">
            <div class="personal-detail-item__title">Time Zone</div>
            <mat-select formControlName="timeZone">
                <mat-option *ngFor="let tz of timeZoneKeys;" [value]="tz">{{timeZoneList[tz]}}</mat-option>
            </mat-select>
        </div>
        <div class="personal-detail-item">
            <div class="personal-detail-item__value">Fields marked with <span class="red">*</span> are required</div>
        </div>
    </form>
    </div>

    <div class="flex" *ngIf="!editDisabled">
        <div class="flex-col grow fs11 text-center" >
            <button class="p-4-10 color4 bcolor4 border-general bgcolor32 br2 m-2" (click)="saveUser()" [disabled]="!personalDetailForm.valid" [ngClass]='{"color37 bcolor36 bgcolor10": !personalDetailForm.valid}'>Save Changes<span class="fa fa-circle-o-notch fa-pulse white" *ngIf="isLoading"></span></button>
            <a class="m-2" (click)="editDisabled = true">Cancel</a>
        </div>
    </div>
</div>
