import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, Output, EventEmitter, OnDestroy, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@cl/common/services/toast.service';
import { UserService } from '@cl/user/user.service';
import { combineLatest, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ApiUserDialogComponent } from '../api-user-dialog/api-user-dialog.component';
import countryCodeList from '../../../@data/country-with-iso-code.json';
import lodash from 'lodash';
import _ from 'lodash';

@Component({
  selector: 'cl-user-detail-summary',
  templateUrl: './user-detail-summary.component.html',
  styleUrls: ['./user-detail-summary.component.scss']
})
export class UserDetailSummaryComponent implements OnInit,OnChanges,OnDestroy {
  @Input() userObject: any;
  @Input() mode: any;
  @Output() closeSidePanel = new EventEmitter<any>();
  @Output() operationInprogress = new EventEmitter<any>();
  userRoleArray = [];
  userRoleList = [];
  timeZoneList = {};
  timeZoneKeys = [];
  timeZonesValues = [];
  notificationChannels = [];
  isSaveInProgress = false;
  currentUser:any;
  private subscriptions: Subscription[] = [];
  userType = 'NORMAL_USER';
  filteredCodeList = [];
  openDropDone = false;
  openDDDiv = false;
  shipmentRoleReadOnly:boolean;
  checkdefaultShipmentRole:boolean = false;
userPayLoadObject ={
  "type":"NORMAL_USER",
  "enabled":false,
  "tenantId":"",
  "module":"",
  "roles":[
  ],
  "id":"test123",
  "name":"test",
  "userDevices":[
    {
      "deviceIdentifier": "",
      "transport": "email",
    },
    {
      "deviceIdentifier": "",
      "transport": "sms",
    }
  ],
  "properties": {
    'managerName': '',
    'managerEmail': ''
  },
  "timezone":"",
  "notificationChannels":{},
  "uiProfileId":"",
  "uiProfileName":""
};

apiUserPayload = {
  "type":"APP_API_USER",
  "tenantId":"",
  "roles":[
  ],
  "id":""
}
profileLists:any;
userDefaultProfile:any;
loggedInUserProfileId:any;
loggedInUserProfileName:any;
selectedUserProfile:any;
checkShipmentUiProfile:any = "335b02ef-e990-47f1-900c-c60d6f5e4e3e";
showDTPShipmentRole:boolean = false;
isDisable:boolean=true;
dtpRoleIndex = -1;
  constructor(private fb: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private userService:UserService,
    private toastService: ToastService,
    public dialog: MatDialog,
    private renderer: Renderer2
    ) { 
      this.renderer.listen('window', 'click', (e: Event) => {
        if (!this.openDDDiv && this.openDropDone) {
          this.openDropDone = false;
        }
        this.openDDDiv = false;
      });
    }

  userDetailsForm = this.fb.group({
    roles: this.fb.array([
    ])
  });

  editUserForm = this.fb.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['',[Validators.required, Validators.email]],
      countryCode:[''],
      phoneNumber:['',[Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      managerName: ['', Validators.pattern('^[A-Za-z0-9? ]+$')],
      managerEmail: ['', Validators.email],
      //primary_email: [''],
      rolesList: this.fb.array([
      ]),
      enabled:['',Validators.required],
      userProfile:['',Validators.required],
      timeZone:['', [Validators.required]],
      notificationChannelsArray: this.fb.array([
      ])
  });

  apiUserForm = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    apiRolesList: this.fb.array([
    ]),
    enabled:[''],
  });

  ngOnInit(): void {
    const countyCodeFormSubs:Subscription = this.editUserForm.controls['countryCode'].valueChanges.subscribe((data:any)=>{
      this.checkForTypeahead(data);
    });
    this.subscriptions.push(countyCodeFormSubs);
    this.getLoggedInUserData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCountryCodeList();
    if(this.userObject?.userObject?.type){
      this.userType = this.userObject?.userObject?.type;
    }
    if(this.userObject.roles){
      this.userRoleArray = this.userObject.roles.split(',');
      this.roles.clear();
      this.userRoleArray.forEach(item => {
        this.addRoles();
      });
    }
    if(this.mode ==='edit'){
      this.apiUserForm.controls['name'].disable();
    }

    if(this.mode =='edit' || this.mode == 'view'){
      this.userObject.userObject?.userDevices.forEach(element => {
        if(element.transport == 'email'){
          this.userObject.email = element.deviceIdentifier;
        }
        if(element.transport == 'sms'){
          let combinedPhoneNumber = element.deviceIdentifier?element.deviceIdentifier:'No Phone number';
          if(combinedPhoneNumber && combinedPhoneNumber != 'No Phone number'){
            this.userObject.phoneNumber = combinedPhoneNumber.slice(combinedPhoneNumber.length - 10);
            this.userObject.countryCode = combinedPhoneNumber.replace(this.userObject.phoneNumber,'');
          }
        }
      });
    }
    this.getAdduserData();
  }

  get roles() {
    return this.userDetailsForm.get('roles') as FormArray;
  }

  get rolesList() {
    return this.editUserForm.get('rolesList') as FormArray;
  }

  get apiRolesList() {
    return this.apiUserForm.get('apiRolesList') as FormArray;
  }

  get notificationChannelsArray() {
    return this.editUserForm.get('notificationChannelsArray') as FormArray;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  checkForTypeahead(data){
    let temp = [];
    if(data){
      temp = countryCodeList.filter(function (d:any) {
        return (d.name.toLowerCase().indexOf(data.toLowerCase()) !== -1 || d.Iso.toLowerCase().indexOf(data.toLowerCase()) !== -1);
      });
      this.filteredCodeList = [...temp];
    }else{
      this.filteredCodeList = [...countryCodeList];
    }
  }


  private getAdduserData(): void {
    //this.isSaveInProgress = true;
    if(this.userRoleList.length == 0){
      const addUserData: Subscription = combineLatest([this.userService.getUserRoleList(), this.userService.getUserTimezoneList(), this.userService.getUserTemplateGroup()]).subscribe((data:any)=>{
        this.userRoleList = [...data[0]];
        this.timeZoneList = data[1];
        this.notificationChannels = [...data[2]];
      this.editUserForm.controls['timeZone'].setValue(this.timeZoneList[ (this.userService.getTimeZone() || Intl.DateTimeFormat().resolvedOptions().timeZone)])
        this.timeZoneKeys = Object.keys(this.timeZoneList);
        this.timeZonesValues = Object.values(this.timeZoneList);
        this.userRoleList.forEach(item => {
          if(item.name === 'ROLE_DTP_LIMITED_USER'){
            this.dtpRoleIndex = this.rolesList.length;
            this.rolesList.push(this.fb.control({value:false, disabled: this.isDisable}));
          }
          this.addEditUserRoles();
          this.addEditApiUserRoles();
        });

        this.notificationChannels.forEach(item => {
          this.addNotificationChannelsArray();
        });

        this.getUserDetail();
        this.isSaveInProgress = false;
      }, (error) => {
          console.error(error);
          this.isSaveInProgress = false;
        });
      this.subscriptions.push(addUserData);
    }else{
      this.getUserDetail();
    }
  }

  getCountryCodeList(){
    this.filteredCodeList = [...countryCodeList];
   }

  setCountryCode(code){
    this.editUserForm.patchValue({
      countryCode:code.Iso
    });
    this.openDropDone = false;
  }

  onTabChange(event) {
    if(event?.index ===0) {
      this.userPayLoadObject.type = 'NORMAL_USER';
      this.userType = 'NORMAL_USER';
    } else {
      this.userType = 'APP_API_USER';
      this.apiUserPayload.type = 'APP_API_USER';
    }
  }

  addRoles() {
    this.roles.push(this.fb.control(true));
  }

  addEditUserRoles(){
    this.rolesList.push(this.fb.control(false));
  }

  addEditApiUserRoles(){
    this.apiRolesList.push(this.fb.control(false));
  }

  addNotificationChannelsArray(){
    this.notificationChannelsArray.push(this.fb.group({
      'email':[true],
      'sms':[true],
      'web':[true]
    }));
  }

  tiggerChangeDetection(){
    this._changeDetectorRef.detectChanges();
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(ApiUserDialogComponent, {
      width: '500px',
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  saveUser(){
    this.operationInprogressStatus(true);
    this.createPayloadForAddUser();
    if(this.mode == 'edit'){
      let payload = {};
      if(this.userType === 'NORMAL_USER') {
        this.userPayLoadObject.module = this.currentUser.module.toLowerCase();
        this.userPayLoadObject.uiProfileId = this.selectedUserProfile?.id?this.selectedUserProfile?.id:this.currentUser.uiProfileId;
        this.userPayLoadObject.uiProfileName = this.selectedUserProfile?.uiProfileName?this.selectedUserProfile?.uiProfileName:this.currentUser.uiProfileName;
        payload = this.userPayLoadObject;
      } else {
        payload = this.apiUserPayload;
      }

      this.userService.editUser(payload, this.userObject.userId).subscribe((data:any)=>{
        this.operationInprogressStatus(false);
        this.closePanel(true);
        let action = 'User updated successfully';
        this.toastService.success(action, this.userObject.userId);
      }, error=>{
        this.operationInprogressStatus(false);
        this.toastService.error(error.error['error-message'], 'error');
      });
    }else if(this.mode == 'add'){
      let payload = {};
      try{
        if(this.userType === 'NORMAL_USER'){
          // delete this.userPayLoadObject.uiProfileId;
          // delete this.userPayLoadObject.uiProfileName;
          payload = this.userPayLoadObject;
        } else {
          payload = this.apiUserPayload;
        }
        this.userService.addUser(payload).subscribe((data:any)=>{
          this.operationInprogressStatus(false);
          let action = 'User added successfully';
          this.toastService.success(action, this.userPayLoadObject.id);
          this.closePanel(true);
          if(this.userType.indexOf('API_USER') > -1){
            this.openDialog(data);
          }

        }, error=>{
          this.operationInprogressStatus(false);
          this.toastService.error(error.error['error-message'], 'error');
        });
      }catch(e){
        console.log(e);
      }

    }
  }

  checkIfRoleSelected(){
    let roles;
    if(this.userType ==='NORMAL_USER' && this.showDTPShipmentRole){
      return true;
    }
    else{
      if(this.userType ==='NORMAL_USER')
      roles = this.editUserForm.value.rolesList;
        else if(this.userType ==='APP_API_USER')
          roles = this.apiUserForm.value.apiRolesList;
          
        let isRoleSelected = false;
        roles.forEach((element, index) => {
          if(element ==="true" || element === true){
            isRoleSelected = true;
          }
        });
        return isRoleSelected;
    }
  }

  createPayloadForAddUser(){
    if(this.userType === 'NORMAL_USER') {
      let form = this.editUserForm.getRawValue();
      this.userPayLoadObject.enabled = (form.enabled === true || form.enabled === "true");
      this.userPayLoadObject.tenantId = this.userService.getEffectiveTenantId();
      form.rolesList.forEach((element, index) => {
        if(element ==="true" || element === true){
        this.userPayLoadObject.roles.push(this.userRoleList[index].id);
        }
      });
      this.userPayLoadObject.id = form.id.toLowerCase();
      this.userPayLoadObject.name = form.name;
      if(this.mode == 'edit'){
        this.currentUser.userDevices.forEach(device => {
          if(device.transport == "email"){
            this.userPayLoadObject.userDevices[0] = device;
          }
          if(device.transport == "sms"){
            this.userPayLoadObject.userDevices[1] = device;
          }
        });
      }
      let deviceIdentifier = form.email;
      if (this.mode === 'edit' && this.currentUser?.properties?.isFederated) {
        this.currentUser?.userDevices.forEach(element => {
          if (element?.transport == 'email') {
            deviceIdentifier = element?.deviceIdentifier;
          }
        });
      }

      this.userPayLoadObject.userDevices[0].deviceIdentifier = deviceIdentifier;
      if(this.userPayLoadObject.userDevices.length>1){
        this.userPayLoadObject.userDevices[1].deviceIdentifier = form?.countryCode + '' + form?.phoneNumber;
        if(!this.userPayLoadObject.userDevices[1].deviceIdentifier){
          this.userPayLoadObject.userDevices.splice(1, 1);
        }
      }
      
      this.userPayLoadObject.properties.managerName = form?.managerName;
      this.userPayLoadObject.properties.managerEmail = form?.managerEmail;

      this.userPayLoadObject.timezone = this.getKeyByValue(form.timeZone);
      this.userPayLoadObject.uiProfileId = this.selectedUserProfile?.id?this.selectedUserProfile?.id:this.loggedInUserProfileId;
      this.userPayLoadObject.uiProfileName = this.selectedUserProfile?.profileName?this.selectedUserProfile.profileName:this.loggedInUserProfileName;
      form.notificationChannelsArray.forEach((element, index) => {
      this.userPayLoadObject.notificationChannels[this.notificationChannels[index].groupName] = element;
      });
    } else {
      let form = this.apiUserForm.value;
      this.apiUserPayload.tenantId = this.userService.getEffectiveTenantId();
      form.apiRolesList.forEach((element, index) => {
        if(element ==="true" || element === true){
         this.apiUserPayload.roles.push(this.userRoleList[index].id);
        }
      });

      if(this.mode === 'add') {
        this.apiUserPayload.id = form.name;
      } else if(this.mode === 'edit'){
        if(form.id){
          this.apiUserPayload.id = form.id;
        }
      }
    }

  }

  getUserDetail(){
    if(this.mode == 'edit'){
      const userdetailSubscription:Subscription = this.userService.getUserDetails(this.userObject.userId).subscribe((data:any)=>{
        this.patchEditUserFrom(data);
        this.currentUser = data;
        this.userDefaultProfile = data.uiProfileId;
        if(this.userDefaultProfile == this.checkShipmentUiProfile){
          this.showDTPShipmentRole = true;
        }
       if(data?.properties?.isFederated){
          this.editUserForm.controls['name'].disable();
          this.editUserForm.controls['email'].disable();
        }
      });
      this.subscriptions.push(userdetailSubscription);
    }
  }

  patchEditUserFrom(data:any){
    let email = '';
    let countryCode = '';
    let phoneNumber = '';
    let roleList = [];
    let notification = [];
    data.userDevices.forEach(element => {
      if(element.transport == 'email'){
        email = element.deviceIdentifier;
      }
      if(element.transport == 'sms'){
        let combinedPhoneNumber = element.deviceIdentifier?element.deviceIdentifier:'No Phone number';
          if(combinedPhoneNumber && combinedPhoneNumber != 'No Phone number'){
            const isoCodes = this.filteredCodeList.map(item => item.Iso);
            const iso = isoCodes.find(item => combinedPhoneNumber.startsWith(item));
            phoneNumber = combinedPhoneNumber.split(iso)[1];
            countryCode = iso;
          }
      }

    });

    this.userRoleList.forEach(element => {
      if(data.roles && (data.roles.indexOf(element.id) >-1 || data.roles.indexOf(element.name) >-1)){
        roleList.push(true);
      }else{
        roleList.push(false);
      }
    });

    this.notificationChannels.forEach(element => {
      notification.push(new Object({
        'email':data.notificationChannels[element.groupName]?data.notificationChannels[element.groupName].email:false,
          'sms':data.notificationChannels[element.groupName]?data.notificationChannels[element.groupName].sms:false,
          'web':data.notificationChannels[element.groupName]?data.notificationChannels[element.groupName].web:false
      }));
    });

    this.editUserForm.patchValue({
      id:data.id,
      name:data.name,
      email:email,
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      managerName: data.properties.managerName,
      managerEmail: data.properties.managerEmail,
      enabled:data.enabled,
      timeZone: this.getValueByKey(data.timezone ? data.timezone : (this.userService.getTimeZone() || Intl.DateTimeFormat().resolvedOptions().timeZone)),
      rolesList:[...roleList],
      notificationChannelsArray : [...notification]
    });

    this.apiUserForm.patchValue({
      id: data.id,
      name:data.name,
      apiRolesList:[...roleList]
    });

  }

  closePanel(reload?){
    if(reload){
      this.closeSidePanel.emit({action: 'reloadUsers' });
    } else {
      this.closeSidePanel.emit(null)
    }
  }
  operationInprogressStatus(status){
    this.isSaveInProgress = status;
    this.operationInprogress.emit(status);
  }

  public filterOptions(filter): void {
    let filterValue = filter.target.value;
    let dataValues = Object.values(this.timeZoneList);
    this.timeZonesValues = dataValues.filter(
      (item: any) => (item.toLowerCase().includes(filterValue.toLowerCase()))
    );
  }

  getKeyByValue(value: string) {
    let data = this.timeZoneList
    return Object.keys(data)[Object.values(data).indexOf(value)];
  }

  getValueByKey(key: string) {
    return this.timeZoneList[key];
  }

  getLoggedInUserData(){
    this.userService.getLoggedInUserData().then(res=>{
      this.loggedInUserProfileId = res.uiProfileId;
      this.loggedInUserProfileName = res.uiProfileName;
      this.getUserProfileList();
    })
  }

  getUserProfileList(){
    this.userService.getUserProfileList().subscribe((res:any)=>{
      this.profileLists = res;
      res.forEach(element => {
        if(element.id == this.loggedInUserProfileId && this.mode == 'add'){
          this.userDefaultProfile = this.loggedInUserProfileId;
        }
      });
    })
  }

  getSelectedProfileDetail(data){
    this.profileLists.forEach(item =>{
      if(item.id == data){
        this.selectedUserProfile = item;
        if(item.id == this.checkShipmentUiProfile){
          this.showDTPShipmentRole = true;
          this.shipmentRoleReadOnly = true;
          this.checkdefaultShipmentRole = true;
          if(this.dtpRoleIndex > -1) this.rolesList.at(this.dtpRoleIndex).patchValue(true);
        }else{
          this.showDTPShipmentRole = false;
          this.shipmentRoleReadOnly = false;
          this.checkdefaultShipmentRole = false;
          if(this.dtpRoleIndex > -1) this.rolesList.at(this.dtpRoleIndex).patchValue(false);
        }
      }
    })
  }
}
