import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '@cl/common/services/toast.service';
import { UserService } from '@cl/user/user.service';


@Component({
  selector: 'cl-recipe-actions',
  templateUrl: './recipe-actions.component.html',
  styleUrls: ['./recipe-actions.component.scss']
})
export class RecipeActionsComponent implements OnInit {
  recipesOptions:any=[];
  constructor(private userService:UserService ,private toast:ToastService) { }
  @Input() entityTypeId;
  @Input() parentEntityType;
  @Input() entityId;
  ngOnInit(): void {

  }
  ngOnChanges(): void {
   if(this.entityTypeId){
    this.getRecipes();
   }
  }
  ActionHandler(action:any) {
  let payload = {
    recipeId:action.id,
    entityTypeId:this.entityTypeId,
    objectId:this.entityId,
    baseClass:this.parentEntityType
}
this.userService.activateRecipes(payload).subscribe(res=>{
this.toast.success(`${action.title} got triggered successfully`)
})
  }
  getRecipes(){
   this.userService.getAllRecipes(this.entityTypeId).subscribe(res=>{
     this.recipesOptions =res
   })
  }
}
