<div class="html-tooltip">
  <span class="tooltip-anchor">
    <ng-content select=".tooltip-anchor"></ng-content>
  </span>
  <!-- <div  class="tooltip-popper" #popperElement>
    <ng-content select=".tooltip-popper"></ng-content>
  </div> -->
  <div class="tooltip-popper-bottom" #popperElement *ngIf="position=='bottom' else  tooltippopper ;" >
    <ng-content  select=".tooltip-popper-bottom"></ng-content>
  </div>
  <ng-template  #tooltippopper>
  <div class="tooltip-popper" #popperElement>
    <ng-content  select=".tooltip-popper"></ng-content>
  </div>
</ng-template>
</div>

