import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngxs/store";
import { NavigatorResetMap } from "../state/navigator.actions";

@Component({
  selector: "app-reset-buttons",
  templateUrl: "./reset-buttons.component.html",
  styleUrls: [ "./reset-buttons.component.scss" ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetButtonsComponent {
  // lv;
  // atLastState;
  // @Select(LiveMapState) lvState$: Observable<string[]>;
  // private readonly destroyed$ = new Subject<boolean>();

  constructor(private store: Store) {}

  // ngOnInit(): void {
  //   this.watchLVState();
  // }
  // ngOnDestroy() {
  //   this.destroyed$.next();
  //   this.destroyed$.complete();
  // }
  // watchLVState() {
  //   this.lvState$.pipe(takeUntil(this.destroyed$)).subscribe((lv: any) => {
  //     this.lv = { ...lv };
  //     this.atLastState = (lv.stateIndex >= lv.stateList.length - 1) ? true : false;
  //   });
  // }
  // stepBack() {
  //   this.store.dispatch(new StepBack());
  // }
  // stepForward() {
  //   this.store.dispatch(new StepForward());
  // }
  resetMap() {
    this.store.dispatch(new NavigatorResetMap());
  }
}
