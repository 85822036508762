import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BulkPanelModel, FileExtensions } from '@cl/@types/bulk-action.type';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import FileSaver from 'file-saver';
import { DynamicFormService } from '@cl/common/services/dynamic-form.service';
import { CsvExportsService } from '@cl/common/services/csv-exports.service';
@Component({
  selector: 'cl-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.scss']
})
export class BulkActionComponent implements OnInit {

  @Input() panelObj: BulkPanelModel;
  @Input() showResponse: boolean;
  @Input() bulkResponseArr: any[];
  @Input() fields: string[];
  @Input() downloadedCSVFileName: string = '';

  @Output() emitFormData = new EventEmitter<FormData>();
  @ViewChild('downloadRef') public downloadRef: ElementRef<HTMLElement>;
  uploadClicked: boolean = false;
  files: NgxFileDropEntry[] = [];
  droppedFile: boolean;
  selectedFileName: string;
  formData: FormData;
  
  constructor(
    private _utils: UtilsService,
    private _dynamicFormService: DynamicFormService,
    private _csvDownload: CsvExportsService
  ) { }

  ngOnInit(): void {
  }

  dropped(files: NgxFileDropEntry[]) {
    if (files.length === 1) {
      const selectedFile = files[0];
      if (selectedFile.fileEntry.isFile && this.isFileAllowed(selectedFile.fileEntry.name)) {
        this.droppedFile = true;
        this.selectedFileName = selectedFile.fileEntry.name;
        const fileEntry = selectedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.formData = new FormData();
          this.formData.append('file', file, this.selectedFileName);
          this.emitFormData.emit(this.formData);
        });
      } else {
        this._utils.showMessage(`Supported file type is ${this.panelObj.fileType}`);
      }
    } else {
      this._utils.showMessage("Please Upload exactly one file");
    }

  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const regex = /(?:\.([^.]+))?$/;
    const extension = <any>regex.exec(fileName);
    if (extension && (extension[0] === FileExtensions[this.panelObj.fileType])) {
      isFileAllowed = true;
    }
    return isFileAllowed;
  }

  downloadTemplate() {
    if (this.panelObj.isDynamicFile) {
      if(this.fields.length > 0){
        this.generateDynamicCSV()
      }
    } else if (this.panelObj?.templateUrl) {
      FileSaver.saveAs(
        `assets/bulk_templates/${this.panelObj?.templateUrl}`,
        this.panelObj?.templateUrl
      );
    }
  }

  removeFile(){
    this.droppedFile = false;
    this.selectedFileName = '';
    this.formData = null;
    this.emitFormData.emit(this.formData)
  }
  generateDynamicCSV() {
    const csvColumns = {};
    this.fields.forEach((field) => {
      csvColumns[field] = '';
    });

    this._csvDownload.formatAndDownloadCSVForGrid(
      [csvColumns],
      this.downloadedCSVFileName
    );
  }
}
