<ngx-datatable
  class="h-calc-40"
  #table
  [rows]="shipmentList"
  [columns]="columns"
  [columnMode]="'force'"
  [loadingIndicator]="true"
  [headerHeight]="headerHeight"
  [rowHeight]="rowHeight"
  [scrollbarV]="true"
  [scrollbarH]="true"
  [selected]="selected"
  [sortType]="sortType"
  [selectionType]="selection"
  [selectAllRowsOnPage]="false"
  [rowIdentity]="getId"
  (select)="onSelect($event)"
  (scroll)="onScroll($event.offsetY)"
  (reorder) = "columnReordered($event)"
  >
  <!-- [reorderable]="false"  need to add this snippet in 37.0.16 -->
</ngx-datatable>

<ng-template
  #shipmentNameTemplate
  let-row="row"
  let-value="value"
  let-i="index">
<span>
<img width="16" height="16" class="inline" *ngIf="row.excursions && row.excursions.length>0" [src]="showIcon(row)">
<span class="mr-4" *ngIf="!row.excursions || row.excursions.length==0" ></span>
<a href="javscript:void(0);" class="font-12-bold ml-2" (click)="showSummary(row, $event)">{{booleanFormate(value)}}</a>
</span>
</ng-template>


<ng-template
  #compositeShipmentTemplate
  let-row="row"
  let-value="value"
  let-i="index">
  <a href="#/shipmentdetails/{{row.id}}?compositeShipmentId={{row.payloadOf}}&type={{row.entityTypeKey}}&cstype={{row.compositeShipmentType}}" class="font-12-bold">{{value}}</a>
</ng-template>

<ng-template #shipmentDateTemplate let-row="row" let-value="value">
  {{ value | formateDate: "default" }}
</ng-template>

<ng-template #shipmentUserDefineDateTemplate let-row="row" let-value="value">
  {{ value | formateDate: "excludeTime" }}
</ng-template>

<ng-template #shipmentAssetCount let-row="row" let-value="value">
  {{ row?.assets?.length || 0 }}
</ng-template>

<ng-template #catalogTypeTemplate let-row="row" let-value="value" >
  {{value | titlecase}}
</ng-template>

<ng-template #clearSearchTemplate>
  <span class="fa fa-times-circle-o mr-4 clear-filter cursor-pointer" *ngIf="showColumnSearch" (click)="closeFilter()"></span>
</ng-template>

<ng-template #searchBoxTemplate let-column="column" let-sort="sortFn">
  <!-- FIXME: draggable class should be remove while disabling reorder fields -->
  <span class="header-sort-span draggable" (click)="sort()">
    {{ column.name }}
  </span>
  <span class="header-filter-span input-filter-parent">
    <input
      [(ngModel)]="columnFilters[column.prop]" 
      (input)="filterInput($event)"
      *ngIf="showColumnSearch"
      class="input-filter"
    />
  </span>
</ng-template>
