import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BulkPanelModel } from '@cl/@types/bulk-action.type';
import { BulkActionsService } from '@cl/common/services/bulk-actions.service';
import { OrganizationService } from '@cl/common/services/organization.service';
import { ToastService } from '@cl/common/services/toast.service';
import { UtilsService } from '@cl/common/utils/utils.service';
import { LocationUtilsService } from '@cl/locations/location-utils.service';
import { LocationsService } from '@cl/locations/locations.service';
import { UserService } from '@cl/user/user.service';
import _ from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { LocationAddEditComponent } from './location-add-edit/location-add-edit.component';
import { LocationAreaComponent } from './location-area/location-area.component';
import { LocationGatewayZoneComponent } from './location-gateway-zone/location-gateway-zone.component';
import { LocationRouteComponent } from './location-route/location-route.component';
import { LocationSiteComponent } from './location-site/location-site.component';
declare const google: any;
@Component({
  selector: 'cl-location-side-panel',
  templateUrl: './location-side-panel.component.html',
  styleUrls: ['./location-side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationSidePanelComponent implements OnInit {

  @Input('mode') mode: string = '';
  @Input('editLocation') editLocation:any;
  @Output() closeSidePanel = new EventEmitter();
  @ViewChild(LocationAreaComponent) area:LocationAreaComponent;
  @ViewChild(LocationSiteComponent) site:LocationSiteComponent;
  @ViewChild(LocationGatewayZoneComponent) zone:LocationGatewayZoneComponent;
  @ViewChild(LocationRouteComponent) route:LocationRouteComponent;
  @ViewChild(LocationAddEditComponent) location:LocationAddEditComponent;

  selectedLocation: any;
  mapClickedPosition: any;
  locationType: string = 'Location';
  markerType: string = 'point';
  changedRadius: number = 500;
  radiusInMeters: number = 500;
  //locationTypes:string[] = ['Location', 'Area', 'Gateway Zone', 'Route', 'Site'];
  locationTypes:any[] = [{catalogType:"location",name:"Location" ,objectType:"location"}];
  catalogTypes:any = [];
  isLoading = false;
  apiStarted = false;
  routeDirections:any =[];
  pointDirections:any =[];
  lineDirections:any =[];
  options = {
    suppressMarkers: true
  }

  initialPolygonPts = [];
  map: google.maps.Map;
  zoom: number = 0;
  showMap:boolean = true;
  lat;
  lng;
  origin = {};
  destination = {};
  travelMode: any = 'TRANSIT';
  transitOptions: any = {
    modes: ['TRAIN'],
    routingPreference: 'FEWER_TRANSFERS',
  };
  originIcon: any = '../../../../assets/svgs/map-icons/site-pin-origin.svg';
  destinationIcon: any = '../assets/svgs/map-icons/route-destination.svg';
  wayPointIcon:any = '../assets/svgs/organization.svg';
  markerOptions: any = {
    origin: {
      icon: '../../../../assets/svgs/map-icons/site-pin-origin.svg',
      label: null
    },
    destination: {
      icon: '../assets/svgs/map-icons/route-destination.svg'
    }
  }
  render_options: any = 
  {
    suppressMarkers: true,
    draggable: true,
    label: null,
    polylineOptions: { 
      strokeColor: '#cc33ff', 
      strokeOpacity :1,
      strokeWeight: 5,
      zIndex: 1,
      geodesic: false,
      clickable: false,
      editable: false,
     } 
    }
  waypoints: any = [
    {location: { lat: 39.0921167, lng: -94.8559005 }},
    {location: { lat: 41.8339037, lng: -87.8720468 }}
 ];
  polygonPts: any[] = [];
  mapOptions: any = {
    gestureHandling: 'cooperative',
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    positionOfActions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
  };

  selectedTabIndex = 0;
  bulkResponseArr: any[];
  bulkAction: BulkPanelModel = {fileType:'CSV', appName:'cloudos', method:'POST'};
  bulkAreas: BulkPanelModel = {...this.bulkAction, uploadUrl:'api/1/bulkDataJob/area/create/file', isDynamicFile: true};
  bulkSites: BulkPanelModel = {...this.bulkAction, uploadUrl:'api/1/bulkDataJob/site/create/file', isDynamicFile: true};
  bulkGatewayZones: BulkPanelModel = {...this.bulkAction, uploadUrl:'api/1/bulkDataJob/gatewayzone/create/file', isDynamicFile: true};
  bulkFormData:any;
  showResponse: boolean = false;
  subscriptions: Subscription[] = [];
  isOrgRestrictedUser;
  orgList;

  isCatalogTypeError = false;
  constructor(private toastService: ToastService, private locationService: LocationsService, private cd: ChangeDetectorRef,
    private bulkService: BulkActionsService, private _locationUtils: LocationUtilsService,private organizationService: OrganizationService,    public _userService:UserService,) { }

  ngOnInit(): void {
    if(this.editLocation && this.mode == 'edit') {
      this.selectedLocation = {catalogType:this.editLocation.parentEntityTypeKey.toLowerCase(),name:this.editLocation.name,objectType:this.editLocation.entityTypeKey}
      this.locationType = this.selectedLocation.catalogType;
    }
    this.getLocationCatalogs();
    const shipmentLists = combineLatest({
      orgList: this.organizationService.getOrgList()
    }).subscribe(
      (data: any) => {
        this.orgList = [...data.orgList];
      })
     this.subscriptions.push(shipmentLists);
     this.isOrgRestrictedUser = this._userService.getUser()?.orgAware;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  initDrawingManager = (map: any) => {
    const self = this;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [],
      },
      polygonOptions: {
        draggable: false,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };

    let drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);

    google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON)    {
          self.updatePointList(event.overlay.getPath());
        }
        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          drawingManager.setDrawingMode(null);
          drawingManager.setOptions({
            drawingControl: false,
          });
          event.overlay.setMap(null);
          self.cd.detectChanges();
        }
      }
    );
  }

  polyChange(evt){
    const pointList = evt.newArr[0];

    const bounds = new google.maps.LatLngBounds();
    pointList.forEach(point => {
      const mapLatLong = new google.maps.LatLng(point.lat,point.lng)
      bounds.extend(mapLatLong)
    })
    const {lat,lng} = bounds.getCenter().toJSON();
    this.polygonPts = [...pointList]
    this.lat = lat;
    this.lng = lng;
    this.mapClickedPosition = {lat, lng};
  }

  updatePointList(path) {
    this.initialPolygonPts = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      const {lat,lng} = path.getAt(i).toJSON()
      this.initialPolygonPts.push(
        {
          lat: lat,
          lng: lng
        }
      );
      this.lat = lat;
      this.lng = lng;
      this.mapClickedPosition = {lat, lng};
    }

    const bounds = new google.maps.LatLngBounds();
    this.initialPolygonPts.forEach(point => {
      const mapLatLong = new google.maps.LatLng(point.lat,point.lng)
      bounds.extend(mapLatLong)
    })
    const {lat,lng} = bounds.getCenter().toJSON();
    this.polygonPts = [...this.initialPolygonPts];
    this.lat = lat;
    this.lng = lng;
  }

  async getLocationCatalogs() {
    this.catalogTypes = await this._locationUtils.getLocationCatalogsWithExtendedAttributes(false);
    this.catalogTypes.forEach((catalogType) => {
      this.locationTypes.push({catalogType:catalogType.catalogType?catalogType.catalogType.toLowerCase():'area',name:catalogType.name ,objectType:catalogType.objectType});
    });

  }

  ngDoCheck() {
    if(this.isLoading){
      this.apiStarted = true;
    }
    if(this.apiStarted && !this.isLoading){
      this.apiStarted = false;
      this.cd.detectChanges();
    }
  }

  closePanel(reload?) {
    this.closeSidePanel.emit(reload);
    this.editLocation = undefined;
  }

  getSelectedTabIndex(event) {
    this.selectedTabIndex = event;
  }
  onNext() {
    if (!this.selectedLocation) {
      this.isCatalogTypeError = true;
      return;
    }
    this.isCatalogTypeError = false;
    this.locationType = this.selectedLocation.catalogType;
  }
  onSave() {
      switch(this.locationType) {     
        case 'location': {
          this.toastService.info('Please select location type');
          break; 
        }
        case 'area': { 
           if(this.mode == 'add') {
            if(this.selectedTabIndex ==1){
              this.uploadBulk(this.bulkAreas)
            } else {
              this.saveArea();             
            }
           } else if(this.mode == 'edit'){
             this.updateArea();
           }
           break; 
        }
        case 'site': { 
         if(this.mode == 'add') {
          if(this.selectedTabIndex ==1){
            this.uploadBulk(this.bulkSites)
          } else {
            this.saveSite();             
          }
         } else if(this.mode == 'edit'){
           this.updateSite();
         }
         break; 
        break; 
     }
     case 'route': { 
       if(this.mode == 'add') {
         this.saveRoute();
       } else {
         this.updateRoute();
       }
       break; 
      }  
        case 'gatewayzone': { 
         if(this.mode == 'add') {
          if(this.selectedTabIndex ==1){
            this.uploadBulk(this.bulkGatewayZones)
          } else {
            this.saveGatewayZone();           
          }
         } else if(this.mode == 'edit'){
           this.updateGatewayZone();
         }
         break; 
        }    
        default: { 
          if(this.locationType != 'Location'){
            if(this.mode == 'add') {
              this.saveLocation();  
             } else if(this.mode == 'edit'){
               this.updateLocation();
             }
           } 
           
           break; 
        } 
      }
   
  }

  selectedPosition(position:any) {
    this.lat = position.lat;
    this.lng = position.lng;
    if(this.zoom < 6) {
      this.zoom = 15;
    }
    this.cd.detectChanges();
  }

  saveLocation() {
    let payload = this.location?.submitForm()
      if(payload) {
        this.isLoading = true;
        this.locationService.saveLocation(payload, this.locationType).subscribe(result=>{
          this.isLoading = false;
          this.toastService.success(this.locationType +' is created successfully');
          this.closePanel({action:'reloadLocations'});
        }, error =>{
          this.isLoading = false;
        })
      } 
   
  }
  updateLocation() {
    let payload = this.location?.submitForm()
    if(payload) {
      this.isLoading = true;
      this.locationService.updateLocation(payload,payload.objectType, this.editLocation.id).subscribe(result=>{
        this.isLoading = false;
        this.toastService.success(this.locationType +' is updated successfully');
        this.closePanel({action:'reloadLocations'});
      }, error =>{
        this.isLoading = false;
      })
    } 
  }

  saveArea() {
    let payload = this.area?.submitForm()
      if(payload) {
        this.isLoading = true;
        this.locationService.saveLocation(payload, payload.objectType).subscribe(result=>{
          this.isLoading = false;
          this.toastService.success('Area is created successfully');
          this.closePanel({action:'reloadLocations'});
        }, error =>{
          this.isLoading = false;
        })
      } 
   
  }
  updateArea() {
    let payload = this.area?.submitForm()
    if(payload) {
      this.isLoading = true;
      this.locationService.updateLocation(payload, payload.objectType, this.editLocation.id).subscribe(result=>{
        this.isLoading = false;
        this.toastService.success('Area is updated successfully');
        this.closePanel({action:'reloadLocations'});
      }, error =>{
        this.isLoading = false;
      })
    } 
  }

  saveSite() {
    let payload = this.site?.submitForm()
    if(payload) {
      this.isLoading = true;
      this.locationService.saveLocation(payload, payload.objectType).subscribe(result=>{
        this.isLoading = false;
        this.toastService.success('Site is created successfully');
        this.closePanel({action:'reloadLocations'});
      }, error =>{
        this.isLoading = false;
      })
    } 
  }

  updateSite() {
    let payload = this.site?.submitForm()
    if(payload) {
      this.isLoading = true;
      this.locationService.updateLocation(payload, payload.objectType, this.editLocation.id).subscribe(result=>{
        this.isLoading = false;
        this.toastService.success('Site is updated successfully');
        this.closePanel({action:'reloadLocations'});
      }, error =>{
        this.isLoading = false;
      })
    } 
  }

  saveGatewayZone() {
    let payload = this.zone?.submitForm()
      if(payload) {
        this.isLoading = true;
        this.locationService.saveLocation(payload, payload.objectType).subscribe(result=>{
          this.isLoading = false;
          this.toastService.success('Gateway Zone is created successfully');
          this.closePanel({action:'reloadLocations'});
        }, error =>{
          this.isLoading = false;
        })
    } 
   
  }
  updateGatewayZone() {
    let payload = this.zone?.submitForm()
    if(payload) {
      this.isLoading = true;
      this.locationService.updateLocation(payload, payload.objectType, this.editLocation.id).subscribe(result=>{
        this.isLoading = false;
        this.toastService.success('Gateway Zone is updated successfully');
        this.closePanel({action:'reloadLocations'});
      }, error =>{
        this.isLoading = false;
      })
    } 
  }

  onMarkerChanged(selectedMarker:any){
    this.markerType = selectedMarker;
    if(this.markerType == 'polygon') {
      const map = _.cloneDeep(this.map)
      this.initDrawingManager(map)
    }
    if(this.zoom < 5 && this.lat && this.lng) {
      this.zoom = 15;
    }
  }

  goBack() {
    this.locationType = 'Location';
    this.clearMap();
  }
  getChangedRadius(changedRadius: number) {
    this.changedRadius = changedRadius;
    
  }

  getSelectedRadius(radius:any) {
    //if(this.markerType == 'circle') {
      this.radiusInMeters = radius?radius:500;
      this.changedRadius = this.radiusInMeters;
    //}
  }

  getSelectedPolygon(polygon:any) {
    if(this.markerType != 'polygon') return;

    polygon = polygon[0] || []

    this.clearPolygons();
    polygon.forEach(p=> {
      this.polygonPts.push({lat:p[1], lng:p[0]})
    })
    this.polygonPts.pop()
    this.initialPolygonPts = [...this.polygonPts];
  }

  clearPolygons() {
    this.initialPolygonPts = [];
    this.polygonPts = [];
  }
  mapClick(event) {
    if(this.markerType == 'polygon'){
      // let ll = new google.maps.LatLng(event.coords.lat, event.coords.lng);
      // this.polygonPts = [...this.polygonPts, ll];
    } else {
      this.mapClickedPosition = {lat: event.coords.lat, lng: event.coords.lng};
      this.lat = event.coords.lat;
      this.lng = event.coords.lng;
    }
    
  }

  clearMap() {
    this.lat = 0;
    this.lng = 0;
    this.zoom = 3;
    this.markerType = 'point';
    this.clearPolygons();
  }

  getUploadFormData(event) {
    this.bulkFormData = event;
  }

  uploadBulk(bulkObj:BulkPanelModel) {
    bulkObj.uploadUrl = `api/1/bulkDataJob/${this.selectedLocation?.objectType}/create/file`;
    const payload:any = {
      file: this.bulkFormData,
      ...bulkObj
    }
    if(!payload.file){
       this.toastService.info('select file to upload')
       return;
    }
    this.isLoading = true;
    this.bulkService.uploadBulkActionFile(payload).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        this.cd.detectChanges();
        if(res){
        this.bulkResponseArr = [];
        if (res.hasOwnProperty('uploaded')) {
          this.showResponse = true;

          for (let key in res) {
            if (key === 'errorfile') {
              if (res.failed) {
                this.bulkResponseArr.push({ key: key, val: res[key], link: true });
              }
            } else {
              this.bulkResponseArr.push({ key: key, val: res[key], link: false });
            }
          }
        } else if (Array.isArray(res)) {
            this.showResponse = true;
            res.forEach(item => {
              for (let key in item) {
                this.bulkResponseArr.push({ key: key, val: item[key], link: false });
              }
            });
        } else {
          this.toastService.openCloseHandlerSnackBar(
            [`${this.selectedLocation?.name} data is being processed and will load in the background. A notification will be sent when the data processing and loading is completed.`]
          ,'',['toast-success']);  
           this.closePanel();
        }
      }else {
         this.toastService.info('No response')
      }
      },
      error: (err) => {
        this.isLoading = false;
        let errMessage: string = err && err.error && err.error['error-message'];
        this.toastService.error(errMessage);
      }
    })
  }
  saveRoute() {
    let payload = this.route?.submitForm()
    let valid = true;
    payload?.locationDetails.forEach(ele =>{
      if(ele.ErrorMsg){
       valid =false
      }
    })
      if(payload && valid) {
        this.isLoading = true;
        this.locationService.saveRoute(payload, payload.objectType).subscribe(result=>{
          this.isLoading = false;
          this.toastService.success('Route is created successfully');
          this.closePanel({action:'reloadLocations'});
        }, error =>{
          this.isLoading = false;
        })
    }
  }
  updateRoute() {
    let payload = this.route?.submitForm()
    let valid = true;
    payload?.locationDetails.forEach(ele =>{
      if(ele.ErrorMsg){
       valid =false
      }
    })
    if(payload && valid) {
        this.isLoading = true;
        this.locationService.updateRoute(payload,payload.objectType).subscribe(result=>{
          this.isLoading = false;
          this.toastService.success('Route is updated successfully');
          this.closePanel({action:'reloadLocations'});
        }, error =>{
          this.isLoading = false;
        })
    }
  }
  onSelectedRoute(position:any) {
    this.lineDirections=[]
    this.pointDirections =[]
    this.routeDirections =[]
    this.zoom =3;
    let length = position.length -1;
    position.forEach((pos,index) => {
       if(pos.sourceArea?.coordinates){
        if(index !=length){
          let data ={
            travelMode :pos.travelMode,
            override:pos.isShowOverrideTravelMode,
            origin :{
              lat: Number(JSON.parse(position[index].sourceArea.coordinates).geometry?.coordinates[1]),
              lng: Number(JSON.parse(position[index].sourceArea.coordinates).geometry?.coordinates[0]),
            },
            destination :{
              lat: Number(JSON.parse(position[index+1].sourceArea.coordinates).geometry?.coordinates[1]),
              lng: Number(JSON.parse(position[index+1].sourceArea.coordinates).geometry?.coordinates[0]),
            },
            visible: setTimeout(() =>{ true }, 3000)
          }
         if(pos.travelMode=='Road' && !pos.isShowOverrideTravelMode){
          this.routeDirections.push(data)
         } else{
          this.lineDirections.push(data)
         }
         }
          let coordinates =JSON.parse(pos.sourceArea.coordinates);
          let locus = {
            lat: Number(coordinates?.geometry?.coordinates[1]),
            lng: Number(coordinates?.geometry?.coordinates[0]),
            iconUrl: index == 0 ? this.originIcon  : index == length? this.destinationIcon : this.wayPointIcon
          }
          this.pointDirections.push(locus)
        }
    })
  }
}
