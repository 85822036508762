import { Injectable } from "@angular/core";
import { AppGetNotifications, AppLoadConfigurations, AppTriggerNotificationPolling } from "@cl/ngxs/actions";
import { AppNotification, NotificationLevel, NotificationRequestPayload, NotificationStatusCount } from "@cl/models";
import { NavigatorLoadFilterConfig } from "@cl/navigator/state/navigator.actions";
import { NavigatorApiService } from "../common/services/navigator-api.service";
import { NotificationsApiService } from "../common/services/notifications-api.service";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import moment from "moment";
import { tap } from "rxjs/operators";
import { AppStateModel } from "./app.model";
import { UserState } from "./user.state";
import { UserService } from "@cl/user/user.service";
import { AssetListService } from "@cl/asset/asset-list/asset-list.service";
import _ from "lodash";

const DEFAULT_APP_STATE: AppStateModel = {
  notifications: [],
  notificationPollingTimeInMs: 120000, // Poll every 2 minutes
  notificationPollingEnabled: false,
  configurations: [],
};

export enum AppConfigObjectNames {
  MapUI = "map-ui",
  HeaderMenus = "headerExtraMenus",
  BrandingLogoUrl = "branding_image_url",
}

@State<AppStateModel>({
  name: "app_state",
  defaults: DEFAULT_APP_STATE,
})
@Injectable()
export class AppState {
  @Selector()
  static infoNotifications(state: AppStateModel) {
    return state.notifications.filter((n) => n.level === NotificationLevel.Info);
  }

  @Selector()
  static warningNotifications(state: AppStateModel) {
    return state.notifications.filter((n) => n.level === NotificationLevel.Warning);
  }

  @Selector()
  static criticalNotifications(state: AppStateModel) {
    return state.notifications.filter((n) => n.level === NotificationLevel.Critical);
  }

  @Selector()
  static configurations(state: AppStateModel) {
    return state.configurations;
  }

  @Selector()
  static mapUIConfigurations(state: AppStateModel) {
    return state.configurations.filter((c) => c.name === 'map-ui');
  }

  @Selector()
  static getConfigurations(state: AppStateModel, configName: AppConfigObjectNames) {
    return state.configurations.find((c) => c.name === configName);
  }

  get currentUser() {
    return this.store.selectSnapshot(UserState.currentUser);
  }

  private pollingInterval: NodeJS.Timeout;

  constructor(
    private notificationsApi: NotificationsApiService,
    private store: Store,
    private userService: UserService,
    private assetListService: AssetListService,
    private navigatorApi: NavigatorApiService) {}

  getReadUnreadCounts(notifications: AppNotification[]): NotificationStatusCount {
    const counts = new NotificationStatusCount();

    notifications.forEach((n) => {
      counts[n.status]++;
    });

    return counts;
  }

  @Action(AppGetNotifications)
  getNotifications({ patchState, getState }: StateContext<AppStateModel>, action: AppGetNotifications) {
    const now = moment();
    const from = now.subtract(7, "days");
    const to = now;

    const payload: NotificationRequestPayload = {
      from: from.valueOf(),
      to: new Date().getTime(),
      fromPage: 0,
      pageSize: 30,
      userId: this.userService.getUserId(),
    };

    return this.notificationsApi.getNotifications(payload).pipe(
      tap((notifications) => {
        patchState({ notifications });

        const { notificationPollingEnabled } = getState();
        if (!notificationPollingEnabled) this.store.dispatch(new AppTriggerNotificationPolling());
      })
    );
  }

  @Action(AppTriggerNotificationPolling)
  triggerNotificationPolling({ getState, patchState }: StateContext<AppStateModel>, action: AppTriggerNotificationPolling) {
    const { notificationPollingEnabled, notificationPollingTimeInMs } = getState();

    if (notificationPollingEnabled) return;

    this.pollingInterval = setInterval(() => this.store.dispatch(new AppGetNotifications()), notificationPollingTimeInMs);

    patchState({
      notificationPollingEnabled: true,
    });
  }

  @Action(AppLoadConfigurations)
  loadConfigurations({ patchState, dispatch }: StateContext<AppStateModel>, action: AppLoadConfigurations) {
    if (!_.isEmpty(action.configurations)) {
      patchState({
        configurations: action.configurations,
      });
    }
    dispatch(new NavigatorLoadFilterConfig());
  }
}
