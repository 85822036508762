import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
//import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { ApiProviderService } from '@cl/@core/shell/api-provider.service';
import { UserService } from '@cl/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  constructor(private httpClient: HttpClient, private utilService:UtilService,private apiProvider: ApiProviderService,private _userService: UserService) { }
  public isUserAuthenticated = new BehaviorSubject(false); 
  public updateRecipeList = new BehaviorSubject(false);
  getRecipeList(tenantId){   
    
    //return this.httpClient.get(`https://test2-behaviorengine.parkoursc.com/api/v1/simulate/types/${tenantId}/recipes`)
    return this.httpClient.get(`${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/simulate/types/${tenantId}/recipes`)
    
  }

  getEntitiesList(tenantId){
    //return this.httpClient.get(`https://test2-model.parkoursc.com/api/v1/namespaces/${tenantId}/products`)
    return this.httpClient.get(`${this.apiProvider.getAPIUrl('model')}api/v1/namespaces/${tenantId}/products`)
  }
  getEntityByEntityId(tenantId, entityId){
    //return this.httpClient.get(`https://test2-model.parkoursc.com/api/v1/receipes?tenantId=${tenantId}&entityId=${entityId}`)
    return this.httpClient.get(`${this.apiProvider.getAPIUrl('model')}api/v1/receipes?tenantId=${tenantId}&entityId=${entityId}`)
  } 

  saveEntity(reqObj){
    //return this.httpClient.post(`https://test2-model.parkoursc.com/api/v1/receipes`,reqObj)
    return this.httpClient.post(`${this.apiProvider.getAPIUrl('model')}api/v1/receipes`,reqObj)
  }

  validateRecipeQCode(reqObj){
    //return this.httpClient.post(`https://test2-model.parkoursc.com/api/v1/receipes/validate`,reqObj);
    return this.httpClient.post(`${this.apiProvider.getAPIUrl('model')}api/v1/receipes/validate`,reqObj);
  }

  deleteRecipe(reqObj){
    //return this.httpClient.post(`https://test2-model.parkoursc.com/api/v1/receipes/delete`,reqObj);
    return this.httpClient.post(`${this.apiProvider.getAPIUrl('model')}api/v1/receipes/delete`,reqObj);
  }

  getTopicList(){
    const tenantId = this._userService.getTenantId();
    return this.httpClient.get(`${this.apiProvider.getAPIUrl('behaviorengine')}api/v1/${tenantId}/eventTypes`);
    //return this.httpClient.get("http://test2-behaviorengine.parkoursc.com/api/v1/cloudleaf/eventTypes")
  }
}
