<div class="pb20 cl-gateway-details details-page-wrapper relative">
  <div>
    <div class="mb-4 -mt-1"><cl-breadcrumb [menu]="breadCrumbItems"></cl-breadcrumb></div>
    <div class="flex justify-between">
      <div class="flex flex-row mb-2">
        <button
              [clIsValidAction]="bindAction === 'Bind' ? actionBtnPolicies.bind : actionBtnPolicies.unbind"
              class="p-4-10 color4 bcolor4 border-general bgcolor32 br2" (click)="bindOrUnbind()">
              <i class="fa " [ngClass]='bindIcon'></i>
              {{bindAction}}
        </button>
      </div>
      <div>
        <cl-recipe-actions [entityTypeId]="gatewayObject?.properties?.properties?.entityTypeId"  [entityId]="gatewayObject?.properties?.properties?.id" [parentEntityType]="gatewayObject?.properties?.properties?.parentEntityTypeKey" ></cl-recipe-actions>
      </div>
    </div>
    <div class="flex">
      <div class="rounded-start rounded-end bgcolor2 ml0 mr10 col-7 p20 cl-shadow">
          <cl-gateway-summary [gateway]="gatewayObject" [lastUpdated]="lastReportedStr"></cl-gateway-summary>
      </div>
      <div class="rounded-start rounded-end bgcolor2 mr0 ml10 col p20 cl-shadow">
        <div class="ht2 text-center"><span>Location:</span>&nbsp;&nbsp;<span [textContent]="gatewayObject?.building?.name"></span></div>
        <div class="rt1 row align-items-start fs14">
          <div class="col text-start"><span class="font-bold">GW Zone: </span><span [textContent]="gatewayObject?.area?.name"></span></div>
          <div class="col text-end"><span class="font-bold">LAT/LONG: </span>&nbsp;<span [textContent]="gatewayObject?.position?.lat"></span><span *ngIf="gatewayObject?.position?.lat">,&nbsp;</span> <span [textContent]="gatewayObject?.position?.lon"></span></div>
        </div>
        <agm-map [latitude]="gatewayObject?.position?.lat" [longitude]="gatewayObject?.position?.lon"
            [streetViewControl]="false" [gestureHandling]="mapOptions.gestureHandling" [scrollwheel]="null"
            [fullscreenControl]="true" [fullscreenControlOptions]="mapOptions.positionOfActions"
            [zoomControl]="true" [zoomControlOptions]="mapOptions.positionOfActions" [minZoom]="2" [maxZoom]="20"
            [styles]="mapOptions.styles" [backgroundColor]="'none'" [disableDefaultUI]="true" [showDefaultInfoWindow]="true"
            [keyboardShortcuts]="false">
            <agm-marker  [latitude]="gatewayObject?.position?.lat" [longitude]="gatewayObject?.position?.lon"
              [iconUrl]="gatewayIcon" [agmFitBounds]="true">
              <agm-info-window> <span>Latitude: {{gatewayObject?.position?.lat}}</span><br/><span>Longitude: {{gatewayObject?.position?.lon}}</span></agm-info-window>
            </agm-marker>
          </agm-map>
      </div>
    </div>
  </div>
  <div class="flex h150 justify-content-evenly mt20">
    <div class="bgcolor2 rounded-end rounded-start cl-shadow col mr15 p20 text-center">
      <div class="font-bold color15 fs16">Overall Status</div>
      <div class="color10 fs10"><span>last updated:</span>&nbsp;<span>{{lastReportedStr}}</span></div>
      <div class="text-center pt20 fs34">
        <div><span class="font-bold align-bottom">
          <i class="fa text-[16px]" [ngClass]="overallStatusClass"></i>
        </span>&nbsp;<span class="color13" [textContent]="(gatewayObject?.healthStatus?.overallStatus | gatewayOverallStatus)"></span></div>
      </div>
    </div>
    <div class="bgcolor2 rounded-end rounded-start cl-shadow col mr15 p20 text-center">
      <div class="font-bold color15 fs16">Traffic</div>
      <div class="color10 fs10"><span>last updated:</span>&nbsp;<span [textContent]="lastReportedStr"></span></div>
      <div class="text-left">
        <div><span class="font-bold">Traffic Upstream:</span>&nbsp;<span class="color13" [textContent]="gatewayObject?.healthStatus?.netTrafficUp"></span></div>
        <div><span class="font-bold">Traffic Downstream:</span>&nbsp;<span class="color13" [textContent]="gatewayObject?.healthStatus?.netTrafficDown"></span></div>
        <div><span class="font-bold">Load Score:</span>&nbsp;<span class="color13" [textContent]="gatewayObject?.healthStatus?.load"></span></div>
      </div>
    </div>
    <div class="bgcolor2 rounded-end rounded-start cl-shadow col p20 text-center">
      <div class="font-bold color15 fs16">Profile Info</div>
      <div class="color10 fs10"><span>last updated:</span>&nbsp;<span [textContent]="lastReportedStr"></span></div>
      <div class="text-left"><span class="font-bold">RSSI Range:</span>&nbsp;<span class="color13" [textContent]="gatewayObject?.attribute?.rssiRange">-40 - +45 dbm</span></div>
    </div>
    <div class="bgcolor2 rounded-end rounded-start cl-shadow col mr15 p20 text-center invisible">
      <div class="font-bold color15 fs16">RSSI</div>
      <div class="color10 fs10"><span>last updated:</span>&nbsp;<span [textContent]="lastReportedStr"></span></div>
      <div class="ht1 color15 font-bold pt20"><span>-29</span> <span>DBM</span></div>
      <div class="fs11 color15"><span>Signal strength: </span><span>Excellent</span></div>
    </div>
    <div class="bgcolor2 rounded-end rounded-start cl-shadow col mr15 p20 text-center invisible">
      <div class="font-bold color15 fs16">Active Sensors</div>
      <div class="color10 fs10"><span>last updated:</span>&nbsp;<span [textContent]="lastReportedStr | timesince"></span></div>
      <div class="ht1 color15 font-bold pt20">189</div>
    </div>
  </div>
  <div class="rounded-start rounded-end bgcolor2">

  </div>
  <div class="ht2 mt20 mb5 ml5 page-title">Activity</div>
  <div class="cl-shadow bgcolor2 mr0 rounded-start rounded-end bgcolor2 p10">
    <cl-gateway-activity [gateway]="gatewayObject"></cl-gateway-activity>
  </div>

  <div *ngIf="sidePanelsObj.bind">
    <cl-gateway-binder [gatewayObject]="gatewayObject" (closeSidePanel)="closeSidePanel($event)"></cl-gateway-binder>
  </div>
</div>
