<main class="main navigator-map-wrapper" [style.width.px]="p.contentWidth">
  <div class="fullscreen-button options-button " (click)="toggleFullscreen()" matTooltip="Show/Hide Info Cards"
    matTooltipClass="tooltip-styles">
    <i *ngIf="p.carousel" class="fa fa-sm fa-expand"></i>
    <i *ngIf="!p.carousel" class="fa fa-sm fa-compress"></i>
  </div>
  <app-reset-buttons class="reset-buttons"></app-reset-buttons>

  <!--<nv-map
    [currentCenter]="currentCenter"
    [clusterCalculatorFn]="clusterCalculatorFn"
    [style.width.px]="p.mainWidth"
    [nodes]="nodes"
    [targetDomain]="targetDomain"
    [visibleDomains]="visibleDomains"
    [mapZoom]="navigatorConfig?.mapZoom">
  </nv-map>-->

  <app-server-clustered-map
    [currentCenter]="currentCenter"
    [style.width.px]="p.mainWidth"
    [targetDomain]="targetDomain"
    [visibleDomains]="visibleDomains"
    [mapZoom]="navigatorConfig?.mapZoom">
  </app-server-clustered-map>
</main>

<app-right-list class="aside panel attributes"
  [style.transform]="'translate(' + p.rightListOffset + 'px, 0px )'"
  (selected)="listItemSelected($event)"
  [activeEntity]="navigatorState?.mapDetails?.activeEntity"
  (searchEmitter)="getSearchUpdate($event)">
</app-right-list>

<!-- <app-inspector class="aside panel inspector"
  [style.height.px]="p.panelHeight"
  [style.transform]="'translate(' + p.inspectorOffset + 'px, 0px )'"
  (panelsEmitter)="getPanelUpdate($event)"
  (inspectorEmitter)="getInspectorUpdate($event)"
  [activeEntity]="navigatorState?.mapDetails?.activeEntity"
  [p]="p"
  isLiveView="true">
</app-inspector> -->

<div *ngIf="navigatorState?.assetId">
  <cl-asset-summary [assetId]="navigatorState?.assetId" (closeSidePanel)="closeSidePanel('asset')">
  </cl-asset-summary>
</div>

<div *ngIf="navigatorState?.locationId">
  <cl-location-summary [locationId]="navigatorState?.locationId" [locationType]="'Location'" (closeSidePanel)="closeSidePanel('location')">
  </cl-location-summary>
</div>

<div *ngIf="navigatorState?.shipmentId">
  <cl-shipment-summary [viewMode]="'condensed'" [shipmentID]="navigatorState?.shipmentId" [shipmentType]="this.catalogType"  (closeSidePanel)="closeSidePanel('shipment')"></cl-shipment-summary>
</div>
