import { Injectable } from '@angular/core';
import * as _ from "lodash";
@Injectable({
  providedIn: "root",
})
export class MapService {
  mapLat: Number = 33.07556420358085;
  mapLon: Number = -96.83251200742326;
  placeholderMarker = {
    name: "Ericsson HQ",
    template: "location",
    locus: {
      lat: this.mapLat,
      lon: this.mapLon,
    },
  };

  constructor() {}
  prepareCoordinates(geofence) {
    let coordinates;
    if (geofence.locationRadius) {
      coordinates = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: geofence.locationCenterCoords.split(","),
          properties: { radius: geofence.locationRadius },
          zoomLevel: 16,
          address: geofence.address,
        },
      };
    } else if (geofence.polygonArray) {
      coordinates = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [[geofence.polygonArray]],
        },
        properties: {},
        zoomLevel: 16,
        address: geofence.address,
      };
    }
    return coordinates;
  }
  validateCoords(node) {
    let valid = false;
    if (
      node.locus &&
      node.locus.lat &&
      node.locus.lat !== -360 &&
      node.locus.lng &&
      node.locus.lng !== -360 &&
      !isNaN(+node.locus.lat) &&
      !isNaN(+node.locus.lng)
    ) {
      valid = true;
    } else {
      // console.warn("invalid locus", node);
    }
    return valid;
  }
  addGeoFenceBounds(loc, mapBounds) {
    let rd, coords, radius;
    // if (_.size(loc.geofence)) {
    if (
      (loc.locus && !loc.geofence) ||
      (loc.geofence && loc.geofence.geofenceType === "circle")
    ) {
      radius =
        loc.geofence && loc.geofence.locationRadius
          ? loc.geofence.locationRadius
          : 100;
      rd = radius / 111320;
      if (this.validateCoords(loc)) {
        coords = new google.maps.LatLng(loc.locus.lat + rd, loc.locus.lon);
        mapBounds.extend(coords);
        coords = new google.maps.LatLng(loc.locus.lat - rd, loc.locus.lon);
        mapBounds.extend(coords);
        coords = new google.maps.LatLng(loc.locus.lat, loc.locus.lon + rd);
        mapBounds.extend(coords);
        coords = new google.maps.LatLng(loc.locus.lat, loc.locus.lon - rd);
        mapBounds.extend(coords);
      }
    } else if (
      loc.geofence &&
      loc.geofence.polygonArray &&
      loc.geofence.polygonArray.length
    ) {
      loc.geofence.polygonArray.forEach((p) => {
        if (this.validateCoords(loc)) {
          coords = new google.maps.LatLng(p.lat, p.lng);
          mapBounds.extend(coords);
        }
      });
    }
    // }
    return mapBounds;
  }
  filterDupes(nodes) {
    const exists = {};
    return nodes.filter((node) => {
      if (exists[node.uid]) {
        return false;
      }
      exists[node.uid] = true;
      return true;
    });
  }
  filterAndBound(markers, includeBounds, mapBounds) {
    let coords, include;
    mapBounds = mapBounds || new google.maps.LatLngBounds();
    markers = markers.filter((node) => {
      if (
        !node.locus ||
        !node.locus.lat ||
        isNaN(node.locus.lat) ||
        !node.locus.lon ||
        isNaN(node.locus.lon) ||
        node.locus.lat === -360 ||
        node.locus.lon === -360
      ) {
        include = false;
      } else {
        include = true;
        if (includeBounds) {
          coords = new google.maps.LatLng(node.locus.lat, node.locus.lon);
          mapBounds.extend(coords);
        }
      }
      return include;
    });
    return [markers, mapBounds];
  }
  filterMarkers(
    nodes,
    graphData,
    showAllLocations,
    nodeSearchTerm,
    isMapMode,
    usePlaceholder
  ) {
    let filteredLinks = [],
      coords,
      isFiltered = true;
    if (!nodes || !nodes.length) {
      return usePlaceholder ? [[this.placeholderMarker], []] : [[], []];
    }
    let mapBounds = new google.maps.LatLngBounds();
    let withLocus = 0,
      withoutLocus = 0;
    // Checking for valid markers, also last line here hides locations with no inventory or alert values
    let filteredMarkers = nodes.filter((node) => {
      if (
        isMapMode &&
        (!node.locus ||
          !node.locus.lat ||
          isNaN(node.locus.lat) ||
          !node.locus.lon ||
          isNaN(node.locus.lon) ||
          node.locus.lat === -360 ||
          node.locus.lon === -360)
      ) {
        // console.warn('missing locus or dupe: ', node);
        withoutLocus++;
        return false;
      }

      // if (nodeSearchTerm) {
      //   isFiltered =
      //     node.name && node.name.toLowerCase().indexOf(nodeSearchTerm) > -1
      //       ? true
      //       : false;
      // }
      withLocus++;
      coords = new google.maps.LatLng(node.locus.lat, node.locus.lon);
      mapBounds.extend(coords);
      // node.locus && node.locus.lat && !isNaN(node.locus.lat) &&
      //   node.locus.lon && !isNaN(node.locus.lon)
      //   &&
      // if (node.locationObj) {
      //   console.log("loc", node.locationObj);
      // }

      return isFiltered &&
        (!isMapMode ||
          showAllLocations ||
          node.nodeClass !== "location" ||
          (node.nodeClass === "location" &&
            (node.inventoryObj || node.alertsObj)))
        ? true
        : false;
    });
    // console.log('showAllLocations', showAllLocations);
    // console.log('with/without', withLocus, withoutLocus, filteredMarkers);
    if (!graphData) {
      return [filteredMarkers, filteredLinks, mapBounds];
    }
    if (graphData.links && graphData.links.length) {
      filteredLinks = graphData.links.filter((link, i) => {
        let isValid = true;
        if (link && link.sourceID === link.targetID) {
          isValid = false;
        } else if (link && link.path && link.path.length) {
          link.path.forEach((locus, j) => {
            if (
              !locus ||
              !locus.lat ||
              isNaN(locus.lat) ||
              !locus.lon ||
              isNaN(locus.lon)
            ) {
              isValid = false;
            }
          });
        } else {
          isValid = false;
        }
        return isValid;
      });
    }
    return [filteredMarkers, filteredLinks, mapBounds];
  }

  filterInBounds(nodes, bounds) {
    let boundsList = [],
      remainderList = [],
      isFiltered;
    if (!bounds || !bounds.Sa || !bounds.Ya || bounds.Ya.i > bounds.Ya.j) {
      boundsList = nodes.slice();
    } else {
      boundsList = nodes.filter((node) => {
        isFiltered =
          node.locus &&
          node.locus.lat > bounds!.Ya!.i &&
          node.locus.lat < bounds!.Ya!.j &&
          node.locus.lon > bounds!.Sa!.i &&
          node.locus.lon < bounds!.Sa!.j
            ? true
            : false;
        return isFiltered;
      });
      remainderList = nodes.filter((node) => {
        isFiltered =
          node.locus &&
          node.locus.lat > bounds!.Ya!.i &&
          node.locus.lat < bounds!.Ya!.j &&
          node.locus.lon > bounds!.Sa!.i &&
          node.locus.lon < bounds!.Sa!.j
            ? false
            : true;
        return isFiltered;
      });
    }
    // boundsList = boundsList.concat(this.getSpacers(1, '-- on map --'))
    boundsList = boundsList.concat(this.getSpacers(1, ""));
    boundsList = boundsList.concat(this.getSpacers(1, "-- off map --"));
    boundsList = boundsList.concat(remainderList);
    return boundsList.concat(this.getSpacers(4, ""));
  }
  getSpacers(num, name) {
    const spacers = [],
      spacer = {
        name: name,
        nodeClass: "spacer",
        eventType: "spacer",
        template: "spacer",
        isTitle: false,
      };
    if (name) {
      spacer["isTitle"] = true;
    }
    for (let i = num; i > 0; i--) {
      spacers.push(spacer);
    }
    return spacers;
  }

  markerLatLngEdited(m) {
    // console.log('markerLocationEdited', m);
    if (!m || !m.coords) {
      console.warn("no coords sent", m);
    }
    const latlng = new google.maps.LatLng(m.coords.lat, m.coords.lng);
    let marker = _.cloneDeep(m.marker)
    const promise = new Promise((resolve, reject) => {
      this.reverseGeoCode(latlng).then(
        (resAddress) => {
          // console.log('got address', resAddress);
          marker = {
            ...marker,
            addressObj: resAddress,
            locus: {
              lat: m.coords.lat,
              lon: m.coords.lng || m.coords.lon
            }
          }
          resolve(marker);
        },
        (err) => {
          console.error("Error  fetching address", err);
          reject(marker);
        }
      );
    });
    return promise;
  }

  reverseGeoCode(latLng) {
    const geocoder = new google.maps.Geocoder();
    let resultAddress,
      address = {
        streetNumber: "",
        stateCode: "",
        postalCode: "",
        streetAddr: "",
        region: "",
        city: "",
        countryCode: "",
      };
    const promise = new Promise((resolve, reject) => {
      geocoder.geocode(
        {
          location: latLng,
        },
        (result, status) => {
          if (result && result.length) {
            resultAddress = this.parseGoogleAddress(result[0]);
            address.streetAddr = resultAddress.streetNumber || "";
            address.streetAddr += " " + resultAddress.streetAddr;
            address.postalCode = resultAddress.postalCode;
            address.countryCode = resultAddress.countryCode;
            address.stateCode = resultAddress.stateCode;
            address.city = resultAddress.city;
            resolve(address);
          }
        }
      );
    });

    return promise;
  }

  parseGoogleAddress(addy) {
    if (!addy || !addy.address_components) {
      return null;
    }

    let address_components = addy.address_components;
    // console.log('address_components', address_components);
    let addressTypes = {
      streetNumber: ["street_number"],
      postalCode: ["postal_code"],
      streetAddr: [
        "street_address",
        "route",
        "establishment",
        "point_of_interest",
        "transit_station",
      ],

      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      stateCode: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      countryCode: ["country"],
    };

    let address = {
      // streetNumber: "",
      postalCode: "",
      streetAddr: "",
      region: "",
      city: "",
      countryCode: "",
    };
    address_components.forEach(function (component) {
      _.forOwn(addressTypes, function (typesArray, key) {
        if (typesArray.indexOf(component.types[0]) !== -1) {
          address[key] = component.long_name;
        } else if (typesArray.indexOf(component.types[1]) !== -1) {
          address[key] = component.long_name;
        } else if (typesArray.indexOf(component.types[2]) !== -1) {
          address[key] = component.long_name;
        }
      });
    });
    return address;
  }

  forceRefreshForGroup(mapGroup, map) {
    if (mapGroup !== "Shipments" && mapGroup !== "Locations") {
      // setTimeout(() => {
      //   map.setZoom(map.getZoom() + .1)
      //   setTimeout(() => {
      //     map.setZoom(map.getZoom() - .1);
      //   }, 1);
      // }, 1);
    }
  }
}
