<div class="user-search">
  <form class="relative">
    <input class="p-2 basic-input" type="search" [ngClass]="{ 'disabled': disabled }" [formControl]="searchFc"
      placeholder="Employee Name or ID" [disabled]="disabled" />
    <div class="spin-pos" *ngIf="loading"><i class="fa fa-circle-o-notch fa-pulse fa-4x color4"></i></div>
    <cdk-virtual-scroll-viewport (scrolledIndexChange)="listScrolled($event)" itemSize="22"
      [@listAnimation]="usersList?.length" class="list-viewport">

      <div class="search-list-item-container ba" [ngClass]="{ 'disabled': disabled }"
        *cdkVirtualFor="let user of usersList" (click)="selectUser(user)">
        <span class="search-list-item justify-between flex items-center"
          [ngClass]="{ 'item-select': selectedUserFc?.value?.id === user?.id}">
          <span class="ellipsis" matTooltip="Select user" matTooltipClass="tooltip-styles">
            {{user?.name}} ({{user?.id}})
          </span>
          <i class="fa fa-check mr10 color4" aria-hidden="true" *ngIf="user?.id == selectedUserFc?.value?.id"></i>
        </span>
      </div>
    </cdk-virtual-scroll-viewport>

    <!-- <app-load-more [loading]="loading" allLoaded="true" hideText="true"></app-load-more> -->

    <button class="button mat-button dark w-100" [ngClass]="{ 'disabled-button': selectedUserFc?.invalid || disabled }"
      [disabled]="selectedUserFc?.invalid || disabled" (click)="confirmUserSelection()">
      <ng-content></ng-content>
      <i class="fa fa-circle-o-notch fa-pulse" *ngIf="progress"></i>
    </button>
  </form>
</div>