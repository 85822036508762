import { Injectable } from "@angular/core";
import { ApiProviderApp } from "@cl/models";
import { Store } from "@ngxs/store";
import { TokenService } from "../../common/services/token.service";
import { ClientConfigState } from "../../state/client-config.state";

declare type AvailableClusters =
  | "dev30-app" /* Dev environment1 */
  | "dev31-app" /* Dev environment2 */
  | "test11-app"
  | "ett"
  | "ett-app" /* Test environments */
  | "prod31-app"
  | "eftm"
  | "eftm-app"; /* Production environments */

export interface EnvironmentConfig {
  appName: string;
  baseUrl: string;
  appUrl: string;
  contextPath: string;
  url: string;
  token: string;
  tenantId: string;
}

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  /**
   * Current local environment
   */
  readonly localCluster: AvailableClusters = "dev30-app";

  get cloudOs(): EnvironmentConfig {
    return this.getEnv("cloudos", "/api");
  }

  get clfGraphApp(): EnvironmentConfig {
    return this.getEnv("clfgraphapp", "/api/graph/");
  }

  get clfBatch(): EnvironmentConfig {
    return this.getEnv("clfbatch", "/api");
  }

  get clfGraphUI(): EnvironmentConfig {
    return this.getEnv("clfgraphui", "/api/graph/");
  }

  private get tenantId(): string {
    return this.store.selectSnapshot(ClientConfigState.getTenantId);
  }

  private _availableEnv: ApiProviderApp[] = [];

  constructor(private store: Store, private tokenService: TokenService) {}

  updateBaseApiProviderUrls(apiProvidersUrls: ApiProviderApp[]): void {
    this._availableEnv = apiProvidersUrls;
  }

  private getEnv(appName: string, url: string = ""): EnvironmentConfig {
    const env = this._availableEnv.find((e) => e.appName === appName);

    if (!env) {
      throw new Error(`No environment found for app "${appName}"`);
    }

    return {
      appUrl: env.appUrl,
      appName: env.appName,
      contextPath: env.contextPath,
      baseUrl: env.contextPath?env.appUrl + "/" + env.contextPath :env.appUrl,
      tenantId: this.tenantId,
      token: this.tokenService.token,
      url: env.contextPath?env.appUrl + "/" + env.contextPath + url:env.appUrl + url,
    };
  }
}
