import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

export function startCaseTransform(value: string = '', ...args: any[]): string {
  return _.startCase(_.lowerCase(value));
}


@Pipe({
  name: 'startCase'
})
export class StartCasePipe implements PipeTransform {

  transform(value: string = '', ...args: any[]): string {
    return startCaseTransform(value);
  }

}
