import { PartialOf } from "./core";
import { LabelKeyMap } from "./ui";

export interface NotificationRequestPayload {
  from?: number;
  to?: number;
  userId?: string;
  fromPage?: number;
  pageSize?: number;
  application?: string;
  freeText?: string;
  markRead?: boolean;
  [key: string]: any;
}

export enum NotificationSeverity {
  Info = "Info",
  Critical = "Critical",
  Warning = "Warning",
}

export enum NotificationLevel {
  Info = "Info",
  Critical = "Critical",
  Warning = "Warning",
}

export enum NotificationStatus {
  Read = "READ",
  UnRead = "UNREAD",
}

interface StatusCounts {
  [NotificationStatus.Read]: number;
  [NotificationStatus.UnRead]: number;
}

export class NotificationStatusCount implements StatusCounts {
  READ = 0;
  UNREAD = 0;
}

export enum NotificationResolutionStatus {
  Open = "Open",
  NoAction = "No Action",
  Resolved = "Resolved",
}

export interface AppNotification {
  /** Only for UI purpose, list checked status */
  checked?: boolean;
  userId: string;
  transport: string;
  creationTime: any;
  time: any;
  categoryId?: any;
  type?: any;
  source?: any;
  tenantId: string;
  level: string;
  severity: NotificationSeverity;
  status: NotificationStatus;
  deliveryTime: any;
  subject: string;
  body: string;
  altBody: string;
  /** Only for UI purpose */
  id?: string;
  objectId: string;
  objectType: string;
  contextId: string;
  contextType: string;
  violationType: string;
  violationTypeGroup: string;
  propertyName: string;
  propertyValue?: any;
  address: string;
  elasticDocId: string;
  resolved: NotificationResolutionStatus;
  taggedAssetExtId?: any;
  manualActionRequired: string;
  application: string;
  objectName: string;
  contextName?: any;
  recipeId: string;
  gps: string;
  notifAddress: string;
  threshold?: any;
  delay?: any;
  delayTolerance?: any;
  unit?: any;
  rulesetRule: string;
  scrollId?: any;
}
export interface NotificationsFilters extends PartialOf<PartialOf<LabelKeyMap[]>> {}
