import _ from "lodash";
import { isLocalhost } from "./utils";

// Root builder class will define the domain
// Sub builder class defines context path
// Sub builder class defines the actual API

class ApiDomain {}

class ApiContextPath {}

class Api {
  private readonly _base = "/api";
  private readonly _baseV1 = this._base + "/1";
  private readonly _baseV2 = this._base + "/2";
  private readonly _baseGraph = this._base + "/graph";
  private readonly _baseSearch = this._base + "/search";
  private readonly _baseTool = this._baseGraph + "/tool";

  get path(): string {
    return this._host + this._contextPath;
  }

  constructor(private _host: string, private _contextPath: string) {}

  //#region Users API

  // /**
  //  * `/api/graph/users`
  //  */
  // getUsers(): string {
  //   return this._build(this._baseGraph + "/users");
  // }

  // /**
  //  * `/api/graph/tool/user/<userId>`
  //  */
  // deleteUser(userId: string): string {
  //   const url = this._baseTool + "/user/" + userId;
  //   return this._build(url);
  // }

  // /**
  //  * `/api/graph/tool/user/delete`
  //  */
  // deleteBulkUser(): string {
  //   return this._build(this._baseTool + "/user/delete");
  // }

  //#endregion

  private _build(url: string): string {
    return this.path + url;
  }
}

declare type Apps = "-app" | "-graph" | "";
declare type ContextPaths = "graph-ui" | "clfgraphapp" | "cloudos" | "";

export class ApiBuilder {
  private static readonly _protocol = "https://";
  private static readonly _localEnv = "dev31-app";
  private static readonly _localDomain = "cloudleaf.io";
  private static readonly _localhost =
    ApiBuilder._localEnv + "." + ApiBuilder._localDomain;

  private static readonly _cache: { [key: string]: ApiBuilder } = {};

  private constructor(host: string);
  private constructor(host: string, domain: string);
  private constructor(private _host: string, private _domain?: string) {}

  /**
   * Host domain to work with
   */
  private static get host(): string {
    const host = isLocalhost()
      ? ApiBuilder._localhost
      : window.location.hostname;
    return ApiBuilder._protocol + host;
  }

  /**
   * Defines the base domain to make an API call. Or in other words
   * it defines the host to make an api call
   */
  static app(app: Apps): ApiBuilder {
    // Return if already cached
    if (
      ApiBuilder._cache[app] &&
      ApiBuilder._cache[app] instanceof ApiBuilder
    ) {
      return ApiBuilder._cache[app];
    }

    const host = ApiBuilder.host;
    const pathSegments = host.split(".");

    // Remove app name from the url
    // E.g. It will transform path segments `['dev30-app', 'cloudleaf', 'io']` => `['dev30', 'cloudleaf', 'io']`
    pathSegments[0] = pathSegments[0].split("-")[0];

    // Update the app if specified
    if (!_.isEmpty(app)) {
      pathSegments[0] += app;
    }

    // Transform the path segments to a url
    let newHost = pathSegments.join(".");

    // Create new instance of ApiBuilder and cache it for further quick access
    const apiBuilder = new ApiBuilder(newHost);
    ApiBuilder._cache[app] = apiBuilder;

    return apiBuilder;
  }

  contextPath(context: ContextPaths): Api {
    let contextPath: string = context;

    if (!_.isEmpty(context)) {
      contextPath = "/" + context;
    }

    return new Api(this._host, contextPath);
  }
}
