import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { CompositeShipment } from '@cl/@types/shipment.types';
import { ToastService } from '@cl/common/services/toast.service';
import { ShipmentService } from '@cl/shipments/shipment.service';
import { UserService } from '@cl/user/user.service';

@Component({
  selector: 'cl-shipment-composite-cards',
  templateUrl: './shipment-composite-cards.component.html',
  styleUrls: ['./shipment-composite-cards.component.scss'],
})
export class ShipmentCompositeCardsComponent implements OnInit, OnChanges {
  @Input() shipmentId: string;
  @Input() compositeShipmentData: CompositeShipment;
  @Output() refreshShipment = new EventEmitter<void>();

  isHideCardTip = false;
  public toggle: boolean = false;
  isDTPUser = this._userService.isDTPUser();
  actionBtnPolicies = {
    share: ['SHIPMENT_SHARE'],
   };
  constructor(
    private router: Router,
    private shipmentService: ShipmentService,
    private toastService: ToastService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.compositeShipmentData && this.shipmentId) {
      const shipment = this.compositeShipmentData.subShipments.find(
        (shipment) => shipment.id == this.shipmentId
      );
      if (shipment.status == 'Drafts') {
        const nonDraftedShipment = this.compositeShipmentData.subShipments.find(
          (shipment) => shipment.status !== 'Drafts'
        );
        if (nonDraftedShipment) {
          this.onSelect(nonDraftedShipment.id,nonDraftedShipment.entityTypeKey);
        }
      }
    }
  }

  getAlphabet(index: number) {
    if (index > 25) return ' ';
    return String.fromCharCode(index + 'A'.charCodeAt(0));
  }
  onSelect(shipmentId: string,type:string) {
    return this.router.navigateByUrl(
      `/shipmentdetails/${shipmentId}?compositeShipmentId=${this.compositeShipmentData?.id}&type=${type}&cstype=${this.compositeShipmentData.entityTypeKey}`
    );
  }

  onCardsScroll() {
    this.isHideCardTip = true;
    setTimeout(() => {
      this.isHideCardTip = false;
    }, 1000);
  }

  refreshData(shipmentId: string, shipmentType: string) {
    this.shipmentService
      .refreshShipment(shipmentId, shipmentType)
      .subscribe((res) => {
        this.toastService.openTimerSnackBar('Page will reloaded in ', 5);
        setTimeout(() => {
          this.refreshShipment.emit();
        }, 5000);
      });
  }
  public fnToggle() {
    this.toggle = !this.toggle;
  }
  public onClose(close: boolean) {
    if (close && this.toggle) {
      this.toggle = false;
    } else {
      this.toggle = true;
    }
  }
}
