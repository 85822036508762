<div class="h-[4px] top-bar"> </div>
<div class="p5 pl10 pr10 cl-header bgcolor27 mt-0">
  <div class="row">
    <div class="col col-sm-7 pr0">
      <div class="row">
        <div *ngIf="groupList.length>0" class="col-1 burger-menu-width">
          <span class="cl-hamburger pointer rounded h40 fs10 text-center color4 bcolor30" (click)="menuIconClick()"  [matMenuTriggerFor]="moduleMenu">
            <span class="cl-hb-icon pointer" [ngClass]="{'open' : isMenuOpen}">
              <span class="cl-hb-line"></span>
              <span class="cl-hb-line"></span>
              <span class="cl-hb-line"></span>
              <span class="cl-hb-line"></span>
            </span>
            <span class="cl-hb-title text-center fs11">MENU</span>
          </span>
          <mat-menu #moduleMenu="matMenu" class="mt5" (closed)="isMenuOpen=false">
            <div class="mih30 cl-menu-list">
              <ul *ngFor="let groupListItem of groupList; let i = index" class="list-unstyled">
                <li class="rt1 bgcolor31 pl10 pr10 pt-[3px] pb-[3px] cl-ml-header m0" [textContent]="groupListItem" [ngStyle]="{'margin-top': i === 0 ? '-12px': ''}"></li>
                <li *ngFor="let itemValue of modifiedMenuList.get(groupListItem)" class="m0 cl-ml-item" (click)="navigateOrToggleMenu($event, itemValue)" [ngClass]="{'hidden': itemValue.hidden}">
                  <div class="pl15 pr5 cl-ml-item gridcols3 grid gap-0" [ngClass]="{'cursor-pointer': !itemValue.disabled, 'ml-[20px]': itemValue.level1Parent}">
                    <cl-menu-icon disabled="{{itemValue.disabled}}" type="{{itemValue.icon}}"></cl-menu-icon>
                    <!--<img [src]="itemValue.icon" *ngIf="itemValue.icon !== 'focusEye'" class="img-fluid cl-icon" [ngClass]="{'anchor-link': !itemValue.disabled, 'color37': itemValue.disabled}"/>-->
                    <span class="rt1" [ngClass]="{'anchor-link': !itemValue.disabled, 'color37': itemValue.disabled}" [textContent]="itemValue.label"></span>
                      <i *ngIf="itemValue.toggled && itemValue.isLevel1Menu" class="fa fa-chevron-right anchor-link ml-auto mr-2 font-10-normal"></i>
                      <i *ngIf="!itemValue.toggled && itemValue.isLevel1Menu" class="fa fa-chevron-down anchor-link ml-auto mr-2 font-10-normal"></i>
                      <div *ngIf="itemValue.isBeta" class="mr-2 beta-tag">
                        <span class="bgcolor4 text-white p-0.5 px-1 rounded-full text-[10px]">Beta</span>
                      </div>
                    </div>
                </li>
              </ul>
            </div>
          </mat-menu>
        </div>
        <div class="col">
          <div class="d-flex justify-content-start align-items-center h-35px" style="width: 282px;">
            <div>
              <img [src]="tenant?.logo" style="height: 35px;"/>
            </div>
            <div class="pl-2 pr-2">
              <span *ngIf="tenants?.length>0" class="fa anchor-link pointer"
                [ngClass]="{'fa-chevron-down': ((!selectedTenant && !tenant?.childLogo) || menuOpened), 'fa-chevron-right': (selectedTenant || tenant?.childLogo)}"
                [matMenuTriggerFor]="tenantMenu" (menuOpened)="tenantMenuOpened()"
                (menuClosed)="tenantMenuClosed()"></span>
              <mat-menu #tenantMenu="matMenu" xPosition="before" yPosition="below">
                <div class="rounded p-0 m-0">
                  <div style="display:contents" *ngFor="let tenantAssigned of tenants">
                    <div  mat-menu-item *ngIf="selectedTenant?.tenantId !== tenantAssigned?.tenantId"
                    class="bgcolor2 anchor-link color2-hover bgcolor4-hover" style="width:193px;"
                    (click)="tenantChanged(tenantAssigned)" [textContent]="tenantAssigned?.name"></div>
                  </div>
                  <div *ngIf="selectedTenant" mat-menu-item
                    class="bgcolor2 anchor-link color2-hover bgcolor4-hover" style="width:193px;"
                    (click)="tenantChanged(null)" [textContent]="tenant.name"></div>
                </div>
              </mat-menu>
            </div>
            <div>
              <img *ngIf="selectedTenant" [src]="selectedTenant?.childLogo" style="height: 20px;"/>
              <img *ngIf="!selectedTenant && (tenant && tenant?.childLogo)" [src]="tenant?.childLogo" style="height: 20px;"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col col-sm-5 pl0 text-end flex items-center justify-end">
      <!-- <span class="ml20 fa fa-search color4 mr20"></span> -->
      <cl-alerts *ngIf="showAlerts" class="mr20"></cl-alerts>
      <cl-notifications *ngIf="showNotifications"></cl-notifications>

      <span class="ml20" *ngIf="isShowUserMenuParkourSCLogo === true">
        <img src="assets/images/ParkourSC-Icon.png" [matMenuTriggerFor]="profileMenu" class="img-fluid rounded h40 inline"/>
      </span>
      <div class="col-1 burger-menu-width ml20" *ngIf="isShowUserMenuParkourSCLogo === false">
        <span class="cl-hamburger pointer rounded h40 fs10 text-center color4 bcolor30" [matMenuTriggerFor]="profileMenu">
          <i class="fa fa-user-circle text-white text-[30px] mt-1"></i>
        </span>
      </div>
      <mat-menu #profileMenu="matMenu">
        <div class="cl-mat-menu cl-mat-menu-dropdown">
          <div class="pl15 pt-1 pr15 color13"><span class="font-bold font-12-bold"[textContent]="user?.name"></span><br/><span class="font-11-normal" [textContent]="emailId"></span></div>
          <div mat-menu-item class="pl15 pr15 mt-4 color13" (click)="navigateToMyAccount()"><span class="fa fa-gear"></span><span class="pl10 font-11-normal">account settings</span></div>
          <div mat-menu-item class="pl15 pr15 mb-4 color13" (click)="logout()"><span class="fa fa-sign-out"></span><span class="pl10 font-11-normal">sign out</span></div>
          <div class="hl"></div>
          <div>
            <img [src]="userMenuSupportLogo" class="p-4 w200" />
            <div class="pl15 pr15 color13 suport-icon" mat-menu-item (click)="loadSupport();" ><span class="fa fa-question-circle-o"></span><span class="pl10 font-11-normal">support</span></div>
            <div class="pl15 pr15 color13" mat-menu-item><span>
            <img src="assets/images/info-circle.png" class="img-fluid rounded h40 inline info-icon"/>
            </span><span class="pl10 font-11-normal">{{tenantDetails}}</span></div>
            <!--<div class="pl15 pr15 color13" mat-menu-item><span class="fa fa-check-circle-o"></span><span class="pl10 font-11-normal">terms & conditions</span></div>-->
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
