import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cl-org-mismatch-snackbar',
  templateUrl: './org-mismatch-snackbar.component.html',
  styleUrls: ['./org-mismatch-snackbar.component.scss']
})
export class OrgMismatchSnackbarComponent implements OnInit, OnDestroy {

  routeSubscription!: Subscription;

  constructor(  public snackBarRef: MatSnackBarRef<any>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,private router: Router) { }

  ngOnInit(): void {
    this.routeSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.snackBarRef.dismiss()
        }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe()
  }
}
