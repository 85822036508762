<div class="wt__loader wt__flex-row" [hidden]="!loadingStats">
  <i class="fa fa-circle-o-notch fa-spin fa-3x"></i>
</div>
<div [hidden]="!gotData" class="wt__no-data-message">
  <div>No data available</div>
</div>
<div class="readouts-container">
  <div *ngFor="let item of items" class="readout">
    <div class=" align-center mt-2">
      <!-- <cl-readout class="cl-digit-readouts" [name]="item.name" [totalVal]="item.value"></cl-readout> -->
      <span class="foz24 medium-type">{{item.value | number : '1.0-1'}} </span>
      <span class="foz24 medium-type">{{item.unit}}</span>
    </div>
    <p class="foz-13" title="{{ item.name }}">
      {{ item.name | stateActionMap }}
    </p>
  </div>
</div>
