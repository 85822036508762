<div class="mt-2 mb-2 display-flex">
    <span class="page-title">Ml Workbench</span>
    <span (click)="openMlWorkbench()" class="ml-5px">
        <img width="18" height="18" src="assets/svgs/circle-icons-white/arrow.svg" title="Open Ml Workbench in new tab"/>
    </span>
</div>
<div *ngIf="urlSafe!==''" class="iframe-div">
    <iframe width="100%" height="100%" [src]="urlSafe" frameborder="0" seamless
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
</div>
<div *ngIf="urlSafe===''" class="iframe-div">
    <h3>No Ml-Workbench available</h3>
</div>