<div [ngClass]="{'coc-panel-top': expandCOCPanel}">
    <div class="cl-side-panel-title bgcolor32 justify-between items-center">
        <div class="grow flex items-center pointer" (click)="expandCOCPanel = !expandCOCPanel">
            <mat-icon class="color4" [ngClass]="{'rotate90': expandCOCPanel}">chevron_right</mat-icon>
            <span class="black font-bold">{{showMultiSelectUserSearch?'Select Users to Audit':'Chain of Custody'}}</span>
        </div>
        <i class="fa fa-times-circle-o mr-4" (click)="closeCoc()"></i>
    </div>
    <div *ngIf="expandCOCPanel" class=" p-4">
        <div *ngIf="showAssetCondition">
            <form [formGroup]="conditionsForm" (ngSubmit)="confirmAssetCondition()">
                <h4 class="emphasized text-left">Asset condition</h4>
                <mat-radio-group formControlName="condition" class="css-grid grid-50-50">
                    <mat-radio-button *ngFor="let condition of availableConditions" [value]="condition.value">
                        {{condition.label}}
                    </mat-radio-button>
                </mat-radio-group>


                <button class="button mat-button dark w-100 mt-2" type="submit"
                    [ngClass]="{ 'disabled-button': conditionsForm?.invalid }" [disabled]="conditionsForm?.invalid">
                    ENTER
                    <i class="fa fa-circle-o-notch fa-pulse" *ngIf="checkoutTransferInProgress"></i>
                </button>
            </form>
        </div>
        <div *ngIf="showUserSearch">
            <app-users-search (confirmUser)="confirmSelection($event)" [progress]="checkoutTransferInProgress" [type]="userTypes">
                <span>{{userSearchTitleText}}</span>
            </app-users-search>
        </div>

        <div *ngIf="showMultiSelectUserSearch">
            <cl-multi-select-user-search (confirmUser)="confirmMultiSelectUserSelection($event)" [progress]="checkoutTransferInProgress" [type]="userTypes">
                <span>{{userSearchTitleText}}</span>
            </cl-multi-select-user-search>
        </div>

    </div>

</div>
