import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiProviderService } from "@cl/@core/shell/api-provider.service";

@Injectable({
  providedIn: 'root'
})
export class VendorAccountService {
  constructor(private http: HttpClient, private apiProvider: ApiProviderService) { }

  getAccountList = function (searchText) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/data/list?searchText=${searchText}`);
  };

  getAccountDetails = function (accountId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/${accountId}`);
  };

  createVendorAccount = function (data: any) {
    if(!data){
      data = {};
    }
    return this.http.post(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/`, data);
  };


  updateVendorAccount = function (id, data:any) {
    if(!data){
      data = {};
    }
    return this.http.put(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/authInfo/${id}`, data);
  };

  deleteVendorAccount = function (accountId) {
    let data = null;
    return this.http.delete(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/${accountId}`);
  }

  getVendorList = function (tenantId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/vendors/${tenantId}`);
  }

  getAccountTypes = function () {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/accountTypes`);
  }

  getProperties = function(vendorId) {
    return this.http.get(`${this.apiProvider.getAPIUrl('cloudos')}api/2/vendorCredential/authPropeties/${vendorId}`);
  }

}
