<cl-side-panel>
  <section class="cl-side-panel-title side-panel-header-bg">
    <span class="black font-bold">API Subscription Details</span>
    <!-- <span class="close-icon ml-auto" (click)="closePanel()">
      <i class="fa fa-times-circle-o mr-4"></i>
    </span> -->
  </section>
  <section class="cl-side-panel-content mx-1 px-1">
    <div>
      <div class="my-2.5">
        <div class="font-bold">Name</div>
        <div class="">{{ subscriptionObject.subscriptionName }}</div>
      </div>
      <div class="my-2.5">
        <div class="font-bold">Subscription Type</div>
        <div class="">{{ subscriptionObject.webhookType }}</div>
      </div>
      <div class="my-2.5">
        <div class="font-bold">Callback Urls</div>
        <div class="">{{ subscriptionObject.registeredUrls }}</div>
      </div>
      <div class="my-2.5">
        <div class="font-bold">Status</div>
        <div class="">{{ subscriptionObject.status }}</div>
      </div>
      <div class="my-2.5">
        <div class="font-bold">Created</div>
        <div class="">{{ subscriptionObject.createdAt }}</div>
      </div>
      <div class="my-2.5">
        <div class="font-bold">Created By</div>
        <div class="">{{ subscriptionObject.createdBy }}</div>
      </div>
      <div class="my-2.5">
        <div class="font-bold">Updated</div>
        <div class="">{{ subscriptionObject.modifiedAt }}</div>
      </div>
      <div class="my-2.5">
        <div class="font-bold">Updated By</div>
        <div class="">{{ subscriptionObject.modifiedBy }}</div>
      </div>
    </div>
    <ng-container *ngIf="subscriptionObject?.registeredEvents">
      <hr class="mb-1.5" />
      <div class="font-bold">Subscription Entity Events</div>
      <div class="mt-2">
        <div class="grid gridcols2 gap-x-3">
          <span class="font-bold">Selected Entities</span>
          <span class="font-bold">Selected Events</span>

          <ng-container *ngFor="let entity of entityEvents">
            <div class="text-base">{{ entity[0] }}</div>
            <div class="flex gap-3">
              <span>
                <mat-checkbox
                  [checked]="entity[1].includes('CREATE')"
                  [disabled]="true"
                ></mat-checkbox>
                Create</span
              >
              <span>
                <mat-checkbox
                  [checked]="entity[1].includes('UPDATE')"
                  [disabled]="true"
                ></mat-checkbox>
                Update</span
              >
              <span>
                <mat-checkbox
                  [checked]="entity[1].includes('DELETE')"
                  [disabled]="true"
                ></mat-checkbox>
                Delete</span
              >
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </section>
</cl-side-panel>
